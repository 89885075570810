import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@appcues/sonar';
import {
  PageHeader,
  PageReturn,
  PageTitle,
  ContentStatus,
  PageSubheader,
  PageActions,
  PageDescription,
} from '@studio/legacy-components';
import { format } from 'next/lib/date';
import { ContentStateShape } from 'next/lib/shapes';

import { ExperimentShape } from 'next/entities/experiment';
import ExperimentBadge from 'next/components/ExperimentBadge';
import SchedulingTooltipMessage from 'next/components/ExperienceScheduling/SchedulingTooltipMessage';
import { shouldRenderScheduleStatus } from 'next/components/ExperienceScheduling/validation';
import PlatformBadge from 'next/components/PlatformBadge';
import { ScheduleType } from 'next/components/ExperienceScheduling/types';
import { BadgeContainer, Name, NameWrapper } from './styled';

export function Header({
  name,
  state,
  published,
  returnUrl,
  createdAt,
  createdByName,
  updatedAt,
  updatedByName,
  renderActions,
  title,
  appId,
  experiment,
  schedule,
}) {
  const hasScheduleStatusToShow = shouldRenderScheduleStatus(schedule, state);

  return (
    <PageHeader>
      <PageDescription>
        <PageTitle>
          <PageReturn to={returnUrl} title={title} />
          <NameWrapper>
            <Name title={name}>{name}</Name>
          </NameWrapper>
          <BadgeContainer>
            <ContentStatus state={state} published={published} />

            {hasScheduleStatusToShow && (
              <Tooltip
                content={<SchedulingTooltipMessage schedule={schedule} />}
                delayDuration={0}
              >
                <ContentStatus state="SCHEDULED" published={false} />
              </Tooltip>
            )}

            <ExperimentBadge
              experimentId={experiment?.id}
              experimentName={experiment?.name}
              experimentState={experiment?.state}
              flowState={state}
            />
            <PlatformBadge appId={appId} />
          </BadgeContainer>
        </PageTitle>
        <PageSubheader>
          Created {createdByName ? `by ${createdByName}` : ''} on{' '}
          {format(createdAt, 'MMM D, YYYY')}{' '}
          {updatedAt &&
            `| Updated ${
              updatedByName ? `by ${updatedByName}` : ''
            } on ${format(updatedAt, 'MMM D, YYYY')}`}
        </PageSubheader>
      </PageDescription>
      <PageActions>{renderActions?.()}</PageActions>
    </PageHeader>
  );
}

export const HeaderPropTypes = {
  name: PropTypes.string,
  state: ContentStateShape,
  published: PropTypes.bool,
  returnUrl: PropTypes.string,
  createdAt: PropTypes.number,
  createdByName: PropTypes.string,
  updatedAt: PropTypes.number,
  updatedByName: PropTypes.string,
  renderActions: PropTypes.func,
  title: PropTypes.string,
  appId: PropTypes.string,
  experiment: ExperimentShape,
  schedule: ScheduleType,
};

Header.propTypes = HeaderPropTypes;

export default Header;
