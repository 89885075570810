import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import SchedulingStartAlert from 'next/components/ExperienceScheduling/SchedulingStartAlert';
import { selectSchedule } from 'next/entities/schedules';

import PublishingModal from 'components/ModalContainer/PublishingModal';
import TargetingDetailsTable from 'components/ModalContainer/FlowPublishingModal/TargetingDetailsTable';
import { selectAccountRule } from 'reducers/account/rules';
import { selectAccountStepConditions } from 'reducers/account/conditions';
import { PUBLISH_ACTIONS } from 'constants/modalStatuses';
import { getConfirmActionMessageForType } from 'constants/modalMessages';
import FlowCarousel from 'components/Common/FlowCarousel';
import { selectStepGroups } from 'reducers/account/flows';
import { getFlowPublishingApiPromise } from 'helpers/publishing-api';
import getContentAction from 'actions/publishing';
import { callApi as fetchRule } from 'actions/account/rules';

const Container = styled.div`
  margin: 0 -24px;
`;

const SchedulingAlertWrapper = styled.div`
  margin: 0 1rem;
`;

export const FlowPublishingModal = props => {
  const {
    publishActionType,
    rule,
    conditions,
    allSteps,
    className,
    onLoad,
    schedule,
  } = props;

  useEffect(() => void onLoad(), []);

  const showPublishRollup = [
    PUBLISH_ACTIONS.PUBLISH,
    PUBLISH_ACTIONS.PUSH,
  ].includes(publishActionType);

  return (
    <PublishingModal
      {...props}
      publishToApi={getFlowPublishingApiPromise}
      large={showPublishRollup}
      title={showPublishRollup ? 'Confirm Publish' : ''}
      renderPrompt={(currentContent, actionType) => {
        if (showPublishRollup) {
          return (
            <Container className={className}>
              <SchedulingAlertWrapper>
                <SchedulingStartAlert
                  schedule={schedule}
                  experienceType="Flow"
                />
              </SchedulingAlertWrapper>
              <FlowCarousel
                className="flowCarousel"
                journey={currentContent}
                allSteps={allSteps}
                showHoverOptions={false}
                versionId="latest"
              />
              <TargetingDetailsTable rule={rule} clauses={conditions} />
            </Container>
          );
        }
        return (
          <h2 className="confirm-message">
            {getConfirmActionMessageForType(actionType, currentContent.type)}
          </h2>
        );
      }}
    />
  );
};

const mapStateToProps = (state, { currentContent: { id } }) => ({
  rule: selectAccountRule(state, id),
  conditions: selectAccountStepConditions(state, id),
  allSteps: selectStepGroups(state),
  schedule: selectSchedule(state),
});

const mapDispatchToProps = (dispatch, { currentContent: { id } }) => ({
  onLoad: () => dispatch(fetchRule({ id })),
  onAction: (actionType, { content_id, content_type }) =>
    dispatch(
      getContentAction(actionType, { id: content_id, type: content_type })
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styled(FlowPublishingModal)`
  .flowCarousel {
    height: 250px;
    overflow-x: hidden;
  }
`);
