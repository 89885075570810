import { put, getContext, call } from 'redux-saga/effects';
import { replaceAccountStats } from 'actions/account/stats';

export function* fetchAccountStats() {
  try {
    const redis = yield getContext('redis');
    const response = yield call(redis.getStats);
    yield put(replaceAccountStats(response));
  } catch (error) {
    yield call(reportError, error);
  }
}
