import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  H1,
  Page,
  PageActions,
  PageBody,
  PageHeader,
  PageTitle,
} from '@studio/legacy-components';
import { Shape } from '@studio/conditions';
import { readTags, Shape as TagShape } from 'next/entities/tags';
import { Shape as CreatorShape } from 'next/entities/users';
import { Shape as SegmentShape, readSegments } from 'next/entities/segments';
import {
  readFlowAssociationsForDomains,
  readCreators,
} from 'next/lib/selectors';
import {
  readMobileFlows,
  Shape as MobileFlowShape,
} from 'next/entities/experiences';
import { readFlows, Shape as FlowShape } from 'next/entities/flows';
import { readRules } from 'next/entities/rules';
import { asRequest } from 'next/entities/requests';
import { replace as replacePage } from 'next/entities/page';
import { AppShape, readApps } from 'next/entities/apps';
import { Loading } from 'next/components/Listing';

import {
  selectFeature,
  FLOW_PERFORMANCE,
  OPM_NEW_NAV,
} from 'next/entities/features';
import useTitle from 'next/hooks/use-title';
import { HelpBar } from 'next/components/HelpBar';
import CreateExperience from './CreateExperience';
import FlowsMoreMenu from './FlowsMoreMenu';
import PageList from './PageList';

import PageWithFlowPerformance from './PageWithFlowPerformance';

/**
 * FIXME: There is a brief FOUC on initial load where it shows the empty state
 *        before loading. This appears to be due to how we have the request
 *        selector set up where it returns `loading: undefined`.
 */

const FlowsListingPage = ({
  hasFlowPerformance,
  hasOPMNewNav,
  creators = {},
  domainAssociations = {},
  flows = {},
  mobileFlows = {},
  onLoad,
  segments = {},
  tags = {},
  apps = {},
  rules = {},
}) => {
  useTitle('Flows | Appcues');
  useEffect(() => {
    onLoad?.();
  }, [onLoad]);

  const { loading: loadingCreators = true } = creators;
  const { loading: loadingDomainAssociations = true } = domainAssociations;
  const { loading: loadingFlows = true } = flows;
  const { loading: loadingMobileFlows = true } = mobileFlows;
  const { loading: loadingSegments = true } = segments;
  const { loading: loadingTags = true } = tags;
  const { loading: loadingApps = true } = apps;
  const { loading: loadingRules = true } = rules;

  const loading =
    loadingCreators ||
    loadingDomainAssociations ||
    loadingFlows ||
    loadingFlows ||
    loadingMobileFlows ||
    loadingSegments ||
    loadingTags ||
    loadingApps ||
    loadingRules;

  if (hasFlowPerformance) {
    return (
      <PageWithFlowPerformance
        loading={loading}
        creators={creators}
        domainAssociations={domainAssociations}
        flows={flows}
        mobileFlows={mobileFlows}
        segments={segments}
        tags={tags}
        apps={apps}
        rules={rules}
        hasOPMNewNav={hasOPMNewNav}
      />
    );
  }

  return (
    <Page>
      <PageHeader>
        <PageTitle>
          <H1>Flows</H1>
        </PageTitle>

        <PageActions>
          <FlowsMoreMenu />
          <CreateExperience />
        </PageActions>
      </PageHeader>

      <PageBody>
        <HelpBar section="flows" />
        {loading && <Loading />}
        {!loading && (
          <PageList
            creators={creators.data}
            domainAssociations={domainAssociations.data}
            flows={{ ...flows.data, ...mobileFlows.data }}
            segments={segments.data}
            tags={tags.data}
            apps={apps.data}
          />
        )}
        {/* TODO: Handle error state */}
      </PageBody>
    </Page>
  );
};

FlowsListingPage.propTypes = {
  hasFlowPerformance: PropTypes.bool,
  hasOPMNewNav: PropTypes.bool,
  domainAssociations: asRequest(
    PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
  ),
  flows: asRequest(PropTypes.objectOf(FlowShape)),
  mobileFlows: asRequest(PropTypes.objectOf(MobileFlowShape)),
  tags: asRequest(PropTypes.objectOf(TagShape)),
  apps: asRequest(PropTypes.objectOf(AppShape)),
  segments: asRequest(PropTypes.objectOf(SegmentShape)),
  creators: asRequest(PropTypes.objectOf(CreatorShape)),
  rules: asRequest(PropTypes.objectOf(Shape)),
  onLoad: PropTypes.func,
};

const mapStateToProps = state => ({
  creators: readCreators(state),
  domainAssociations: readFlowAssociationsForDomains(state),
  rules: readRules(state),
  flows: readFlows(state),
  mobileFlows: readMobileFlows(state),
  segments: readSegments(state),
  tags: readTags(state),
  apps: readApps(state),
  hasFlowPerformance: selectFeature(state, FLOW_PERFORMANCE),
  hasOPMNewNav: selectFeature(state, OPM_NEW_NAV),
});

const mapDispatchToProps = (dispatch, { match: { path } }) => ({
  onLoad: () => dispatch(replacePage({ path })),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlowsListingPage);
