import styled from 'styled-components';
import { Stack } from '@appcues/sonar';

export const Container = styled.button`
  width: 100%;
  border: none;
  border-radius: var(--border-radius-small);
  box-shadow: var(--elevation-100);
  padding: var(--spacing-small);
  background: var(--background-level-1);
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: var(--elevation-200);
  }
`;

export const ButtonBody = styled(Stack).attrs({
  spacing: 'x-small',
  xAlign: 'start',
})`
  justify-content: center;
`;

export const IconContainer = styled(Stack).attrs({
  xAlign: 'center',
  yAlign: 'center',
})`
  background: var(--color-blue-100);
  border-radius: var(--border-radius-small);
  padding: var(--spacing-regular);
  width: 56px;
`;

export const ButtonTitle = styled.span`
  text-transform: uppercase;
  font-size: var(--font-size-small);
  color: var(--color-neutral-700);
`;

export const ButtonSubTitle = styled.span`
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-semibold);
  text-align: left;
`;
