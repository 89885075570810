import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@studio/legacy-components';
import SelectSegmentDropDown from 'components/Common/SelectSegmentDropDown';
import AudienceBaseClause from 'components/Common/Clauses/AudienceBase';
import { SubClause } from 'components/Common/Clauses/styled';

const OPERATOR_OPTIONS = [
  { value: false, label: 'matches' },
  { value: true, label: "doesn't match" },
];

export default function SegmentClause(props) {
  const { clause, segmentBlacklist, updateClause } = props;

  const selectedOperator = !clause.negative
    ? OPERATOR_OPTIONS[0]
    : OPERATOR_OPTIONS[1];

  return (
    <AudienceBaseClause {...props}>
      <SubClause>
        <SelectSegmentDropDown
          value={clause.segment}
          blacklist={segmentBlacklist}
          onChange={value => {
            updateClause(clause.id, { segment: value });
          }}
        />

        <Select
          onChange={({ value }) => {
            updateClause(clause.id, { negative: value });
          }}
          options={OPERATOR_OPTIONS}
          value={selectedOperator}
        />
      </SubClause>
    </AudienceBaseClause>
  );
}

SegmentClause.propTypes = {
  clause: PropTypes.shape({
    conditionName: PropTypes.string,
    id: PropTypes.string,
    negative: PropTypes.bool,
    parentId: PropTypes.string,
    segment: PropTypes.string,
  }),
  segmentBlacklist: PropTypes.arrayOf(PropTypes.string),
  updateClause: PropTypes.func,
};
