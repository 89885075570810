import React from 'react';
import { CButton } from '@appcues/component-library';
import useToggle from 'next/hooks/use-toggle';
import CreateEventModal from 'components/Insights/Explorer/CreateEventModal';
import { useBuilderStatus } from 'hooks/useBuilderStatus';

export const CreateEventManager = () => {
  const [isCreateEventModalShowing, toggleCreateEventModal] = useToggle();
  const { isReady, verify } = useBuilderStatus();

  const handleClick = async () => {
    try {
      await verify();
      toggleCreateEventModal();
    } catch {
      // no-op
    }
  };

  return (
    <>
      <CButton onClick={handleClick} disabled={!isReady}>
        Create Track Event
      </CButton>
      {isCreateEventModalShowing && (
        <CreateEventModal onClose={toggleCreateEventModal} />
      )}
    </>
  );
};

export default CreateEventManager;
