import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CDialog } from '@appcues/component-library';
import { removeAndFlushIntegration } from 'actions/account/integrations';

function ConfirmRemoveIntegrationModal({ actions, integration }) {
  const onClickRemoveIntegration = () => {
    actions.removeAndFlushIntegration(integration.id);
    actions.hideModal();
  };

  return (
    <CDialog
      type="negative"
      title={`Remove ${integration.name} integration?`}
      onPrimaryAction={onClickRemoveIntegration}
      primaryActionText="Remove"
      onSecondaryAction={actions.hideModal}
    />
  );
}

ConfirmRemoveIntegrationModal.propTypes = {
  actions: PropTypes.shape({
    hideModal: PropTypes.func.isRequired,
  }).isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: {
    ...ownProps.actions,
    ...bindActionCreators(
      {
        removeAndFlushIntegration,
      },
      dispatch
    ),
  },
});

export default connect(null, mapDispatchToProps)(ConfirmRemoveIntegrationModal);
