import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { ButtonLink, Icon } from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';
import DeleteTranslationModal from 'components/StepSettings/Localization/DeleteTranslationModal';

const PrefixIcon = styled(Icon)`
  margin-left: 1em;
`;

const DeleteManager = ({ flowId, translationId, updatedAt, disabled }) => {
  const [isDeleteModalShowing, toggleDeleteModal] = useToggle();

  return (
    <>
      {isDeleteModalShowing && (
        <DeleteTranslationModal
          flowId={flowId}
          translationId={translationId}
          onClose={toggleDeleteModal}
        />
      )}
      Uploaded {moment(updatedAt).format('lll')}
      {!disabled && (
        <ButtonLink onClick={toggleDeleteModal}>
          <PrefixIcon icon="times" />
        </ButtonLink>
      )}
    </>
  );
};

DeleteManager.propTypes = {
  flowId: PropTypes.string,
  translationId: PropTypes.string,
  updatedAt: PropTypes.number,
  disabled: PropTypes.bool,
};

DeleteManager.defaultProps = {
  disabled: false,
};

export default DeleteManager;
