import { getContext, call, put, takeEvery } from 'redux-saga/effects';

import {
  send,
  resolve,
  reject,
  patterns,
} from 'actions/account/published-rules';
import { reportError } from 'helpers/error-reporting';

export function* getPublishedRules() {
  try {
    yield put(send());
    const { getAllPublishedRules } = yield getContext('api');
    const { data: publishedRules } = yield call(getAllPublishedRules);

    yield put(
      resolve(
        publishedRules.reduce((acc, rule) => {
          const { id } = rule;
          acc[id] = rule;
          return acc;
        }, {})
      )
    );
  } catch (error) {
    yield put(reject(error));
    yield call(reportError, error);
  }
}

export default function* versionedFlows() {
  yield takeEvery(patterns.callApi, getPublishedRules);
}
