import React from 'react';
import styled from 'styled-components';
import { EmptyState } from '@studio/legacy-components';
import EmptyPinsIllustration from './EmptyPinsIllustration';
import CreatePinManager from './CreatePinManager';

const Wrapper = styled.div`
  max-width: 620px;
  margin: 48px auto;
`;

export default function Empty() {
  return (
    <Wrapper>
      <EmptyState
        action={<CreatePinManager />}
        headline="Build a Pin"
        image={<EmptyPinsIllustration />}
      >
        Pins are persistent experiences that can show tooltips or launch a Flow
        to educate your users.
      </EmptyState>
    </Wrapper>
  );
}
