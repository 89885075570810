import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { H5 } from '@studio/legacy-components';
import ThemeEditSection from 'components/Themes/ThemeEdit/ThemeEditTab/ThemeEditSection';

const Wrapper = styled.div``;

const Section = styled.section`
  padding-bottom: 24px;
  padding-right: 24px;
`;

const Heading = styled(H5)`
  margin-top: 20px;
  border-bottom: 1px solid var(--text-secondary);
`;

const ThemeEditTab = ({
  sections,
  draftTheme,
  setDraftTheme,
  hasSectionHeading = true,
}) => {
  return (
    <Wrapper>
      {sections.map(section => (
        <Section key={section.title}>
          {hasSectionHeading && <Heading>{section.title}</Heading>}
          <ThemeEditSection
            contentArray={section.options}
            draftTheme={draftTheme}
            setDraftTheme={setDraftTheme}
          />
        </Section>
      ))}
    </Wrapper>
  );
};

ThemeEditTab.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          category: PropTypes.string,
          label: PropTypes.string,
          name: PropTypes.string,
          options: PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string,
              value: PropTypes.string,
            })
          ),
          type: PropTypes.string,
        })
      ),
    })
  ),
  draftTheme: PropTypes.shape({
    id: PropTypes.string,
    customFontUrl: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    typekitId: PropTypes.string,
    projectId: PropTypes.string,
  }),
  setDraftTheme: PropTypes.func,
  hasSectionHeading: PropTypes.bool,
};

export default ThemeEditTab;
