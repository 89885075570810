import qs from 'qs';
import { call, takeLatest } from 'redux-saga/effects';
import { navigate } from 'next/lib/history';
import { isSpoofing, spoof, despoof, switchAccount } from 'next/lib/auth';
import { setSpoof } from 'next/lib/crx';
import { INITIALIZE } from 'next/root/actions';
import { SPOOF_STARTED, SPOOF_STOPPED } from './actions';

/**
 * TODO: Add toast notifications on success/failure
 */

/**
 * Account related query params
 */
const SPOOFING_KEY = 'view_as';
const SWITCHING_KEY = 'account';

const scrape = param => {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const { [param]: value, ...rest } = query;

  if (!value) {
    return null;
  }

  window.history.replaceState(null, '', `?${qs.stringify(rest)}`);

  return value;
};

function* startSpoof({ payload: id }) {
  try {
    yield call(spoof, id);
    yield call(navigate, '/');
  } catch {
    // TODO: Handle error properly
  }
}

function* stopSpoof() {
  try {
    yield call(despoof);
    yield call(navigate, '/');
  } catch {
    // TODO: Handle error properly
  }
}

/**
 * Check whether a spoofing ID or account ID query param has been included in
 * the URL. If so, trigger a spoof or account switch on load and reload the
 * page.
 *
 * NOTE: The account switching logic isn't _really_ related to spoofing and
 *       really this operation is more related to general auth. But since this
 *       saga exists as part of spoofing, the account switching is included here
 *       as well.
 *
 *       Also, an optimization that can be made is preventing FOUC when either
 *       param is used and blocking the app rendering. Currently, the
 *       application will load then reload if either param exists which is fine
 *       but may not be the best UX
 */
function* initialize({ payload: auth }) {
  try {
    const { accountId } = auth;

    const spoofId = yield call(scrape, SPOOFING_KEY);
    const switchId = yield call(scrape, SWITCHING_KEY);
    const spoofing = yield call(isSpoofing);

    if (spoofId != null && spoofId !== accountId) {
      if (spoofing) {
        yield call(despoof);
      }

      yield call(spoof, spoofId);
      yield call(navigate);
    } else if (switchId != null && switchId !== accountId) {
      yield call(switchAccount, switchId);
      yield call(navigate);
    } else {
      yield call(setSpoof, { accountId, spoofing });
    }
  } catch {
    // TODO: Handle error
  }
}

export default function* saga() {
  yield takeLatest(SPOOF_STARTED, startSpoof);
  yield takeLatest(SPOOF_STOPPED, stopSpoof);
  yield takeLatest(INITIALIZE, initialize);
}
