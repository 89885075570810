import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Select } from '@studio/legacy-components';
import { selectFlowTargetingOptions } from 'reducers/account/flows';

export const SelectStepGroup = ({ value, options = [], onChange }) => {
  const dropdownOptions = [
    ...options,
    ...(value && !options.some(({ value: optValue }) => optValue === value)
      ? [{ label: value, value }]
      : []),
  ];

  const selected = dropdownOptions.find(option => option.value === value);

  return (
    <Select
      isDisabled={dropdownOptions.length === 0}
      onChange={option => onChange(option.value)}
      options={dropdownOptions}
      placeholder="Select a Flow Step Group"
      value={selected}
    />
  );
};

SelectStepGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

const mapStateToProps = state => ({
  options: selectFlowTargetingOptions(state),
});

export default connect(mapStateToProps)(SelectStepGroup);
