import { createLifecycleFor } from 'actions/account/collections';

export const TYPE = 'groups';

export const {
  patterns,
  read,
  callApi,
  readOne,
  reject,
  resolve,
  update,
  flush,
  replace,
  insert,
  add,
  remove,
  prune,
} = createLifecycleFor(TYPE);
