import React, { Component } from 'react';
import { Button } from '@appcues/component-library';
import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';
import Input from 'components/Common/Forms/Input';

class InputNumber extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value: nextValue } = nextProps;
    const { value: currentValue } = this.state;
    const hasValueChanged = currentValue !== nextValue;
    if (hasValueChanged) {
      this.setState({ value: nextValue });
    }
  }

  handleDecrement = () => {
    const { value, minValue, onChange } = this.props;
    const newValue = value - 1;

    if (newValue < minValue) {
      onChange(minValue);
      return;
    }
    onChange(newValue);
  };

  handleIncrement = () => {
    const { value, maxValue, onChange } = this.props;
    const newValue = value + 1;

    if (newValue > maxValue) {
      onChange(maxValue);
      return;
    }
    onChange(newValue);
  };

  render() {
    const { className, minValue, maxValue, disabled, onChange } = this.props;
    const { value } = this.state;

    const inputOptions = {
      ...(minValue || minValue === 0 ? { min: minValue } : {}),
      ...(maxValue ? { max: maxValue } : {}),
      ...(disabled ? { disabled } : {}),
    };

    return (
      <div className={`${className} number-input-wrapper`}>
        <Button
          className="number-buttons"
          onClick={() => this.handleDecrement()}
        >
          <Icon icon="minus" aria-hidden="true" />
        </Button>
        <Input
          {...inputOptions}
          className="number-input"
          type="number"
          value={value}
          onChange={event => {
            const updatedValue = Number.parseInt(event.target.value, 10);
            if (Number.isNaN(updatedValue)) {
              this.setState({ value: '' });
            } else {
              this.setState({ value: updatedValue });
            }
          }}
          onBlur={event => {
            let updatedValue = Number.parseInt(event.target.value, 10);
            if (Number.isNaN(updatedValue) || updatedValue <= minValue) {
              updatedValue = minValue || 0;
            } else if (updatedValue >= maxValue && maxValue) {
              updatedValue = maxValue;
            }
            onChange(updatedValue);
          }}
        />
        <Button
          className="number-buttons"
          onClick={() => this.handleIncrement()}
        >
          <Icon icon="plus" aria-hidden="true" />
        </Button>
      </div>
    );
  }
}

export default styled(InputNumber)`
  &.number-input-wrapper {
    display: flex;
    input[type='number'] {
      width: 60px;
      max-width: 100px;
      padding: 0 8px;
      margin: 0 1px;
      border: 1px solid #bbbbbb;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .number-buttons {
      border-width: 1px;

      &:first-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      &:last-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }

      .fa {
        vertical-align: middle;
      }
    }
  }
`;
