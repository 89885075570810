import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, Td, Tr } from '@studio/legacy-components';

const ClickableRow = styled(Tr)`
  height: 40px;

  &:focus,
  &:hover {
    outline: none;
    cursor: pointer;
    background-color: var(--table-row-expanded);
  }

  ${({ $expanded }) =>
    $expanded &&
    `
    background-color: var(--table-row-expanded);
  `}
`;

const IndicatorColumn = styled(Td)`
  min-width: 40px;
  padding: 0;
  text-align: center;
  width: 40px;
`;

const Indicator = styled(Icon)`
  color: var(--icon);
  margin: 0 auto;
`;

const ExpandableRow = ({
  id,
  children,
  expandable,
  isExpanded = false,
  onClick,
}) => {
  const handleInteraction = () => {
    onClick(id);
  };

  const handleKeyDown = ({ key }) => {
    if (key === 'Enter' || key === 'Space') {
      handleInteraction();
    }
  };

  return (
    <>
      <ClickableRow
        $expanded={isExpanded}
        onClick={handleInteraction}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex="0"
      >
        <IndicatorColumn>
          <Indicator
            expanded={isExpanded}
            icon={`chevron-${isExpanded ? 'down' : 'right'}`}
            title={isExpanded ? 'Expanded Row' : 'Collapsed Row'}
          />
        </IndicatorColumn>
        {children}
      </ClickableRow>
      {isExpanded && expandable}
    </>
  );
};

ExpandableRow.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  expandable: PropTypes.node.isRequired,
  isExpanded: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default ExpandableRow;
