import { call, getContext, put, take, takeLeading } from 'redux-saga/effects';

import { patterns as experiencePatterns } from 'next/entities/experiences';
import { patterns as pinPatterns } from 'next/entities/pins';
import { replacePagePatternFor } from 'next/entities/page';

import { reject, replace, readOneVersion } from './actions';
import { getCacheKey } from './lib';

function* fetchVersionedExperience(action) {
  try {
    const {
      payload: { id, version },
    } = action;

    const api = yield getContext('api');
    const { experience } = yield call(api.getVersionedExperience, id, version);

    yield put(replace({ ...experience, id: getCacheKey(id, version) }));
  } catch (error) {
    yield put(reject(error));
  }
}

function* fetchPublishedVersion() {
  const {
    payload: { id, publishedAt },
  } = yield take(experiencePatterns.replace);

  if (publishedAt) {
    yield call(fetchVersionedExperience, readOneVersion(id, publishedAt));
  }
}

function* fetchPublishedPinVersion() {
  const {
    payload: { id, publishedAt },
  } = yield take(pinPatterns.replace);

  if (publishedAt) {
    yield call(fetchVersionedExperience, readOneVersion(id, publishedAt));
  }
}

export default function* saga() {
  // Page actions
  yield takeLeading(
    [replacePagePatternFor('/mobile/flows/:experienceId/analytics')],
    fetchPublishedVersion
  );

  yield takeLeading(
    [replacePagePatternFor('/pins/:pinId/analytics')],
    fetchPublishedPinVersion
  );
}
