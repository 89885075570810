import React from 'react';
import PropTypes from 'prop-types';

import { H4, CPanel, CBanner } from '@appcues/component-library/';
import ReadableConditionsProvider from 'components/ReadableConditionsProvider/ReadableConditionsProvider';
import { ConditionsWrapper } from './styled';

export default function ClausePanel({ conditions }) {
  return (
    <CPanel>
      <H4 marginBottom={16} type="tertiary">
        Conditions
      </H4>
      {conditions && (
        <>
          <p>
            <strong>Users where...</strong>
          </p>
          <ConditionsWrapper>
            <ReadableConditionsProvider conditions={conditions} />
          </ConditionsWrapper>
        </>
      )}
      {!conditions && (
        <CBanner
          type="negative"
          text="This segment has no conditions. Edit the segment to define them."
        />
      )}
    </CPanel>
  );
}

ClausePanel.propTypes = {
  conditions: PropTypes.object,
  steps: PropTypes.object,
  segments: PropTypes.object,
  checklists: PropTypes.object,
};
