import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CTable } from '@appcues/component-library';
import { Link } from '@studio/legacy-components';
import Loader from 'components/Common/Loader';
import ErrorState from 'components/Common/ErrorState';
import {
  selectPublishedFlows,
  selectAreFlowsSynced,
} from 'reducers/account/flows';
import { fetchFlowErrorReportData } from 'actions/analytics';
import { selectFlowErrorReportData } from 'reducers/analytics';
import { numberWithCommas } from 'utils/textFormatting';
import { getPercentage } from 'utils/numbers';

const countErrors = data => {
  const stepChildErrors = data['appcues:step_child_error'] || 0;
  const stepErrors = data['appcues:step_error'] || 0;
  const stepChildRecovery = data['appcues:step_child_recovered'] || 0;
  return Math.max(stepChildErrors - stepChildRecovery, 0) + stepErrors;
};

function FlowNameCell({ flow }) {
  return (
    <strong>
      <Link to={`/flows/${flow.flow_id}/analytics`}>{flow.name}</Link>
    </strong>
  );
}

const FlowIssueReport = ({
  loaded = false,
  flowErrorReportData = [],
  flows = {},
  fetchFlowErrorReportData: initialize,
}) => {
  const [tableData, setTableData] = useState([]);

  const buildTableData = () => {
    return flowErrorReportData.data
      .filter(({ id }) => flows[id])
      .map(result => {
        const flow = flows[result.id];
        const errorCount = countErrors(result);
        const flowAttempted =
          Number.parseInt(result['appcues:flow_attempted'], 10) || 0;

        return {
          name: flow.name,
          flow_id: flow.id,
          flow_attempted_count: flowAttempted,
          issue_count: errorCount,
          issue_rate: getPercentage(errorCount, flowAttempted),
        };
      });
  };

  useEffect(initialize, []);
  useEffect(() => {
    if (loaded && flowErrorReportData.meta.synced) {
      setTableData(buildTableData());
    }
  }, [loaded, flowErrorReportData.meta.synced, flows]);

  if (!loaded || !flowErrorReportData.meta.synced) {
    return <Loader margin="4em" />;
  }

  if (flowErrorReportData.meta.errored) {
    return <ErrorState margin="4em" />;
  }

  return (
    <CTable
      data={tableData}
      defaultSortColumn="flow_name"
      defaultSortAscending
      columns={[
        {
          Header: 'Flow',
          accessor: 'name',
          sortable: true,
          minWidth: 200,
          maxWidth: 420,
          Cell: flow => <FlowNameCell flow={flow} />,
        },
        {
          Header: 'Flow Attempted',
          accessor: 'flow_attempted_count',
          sortable: true,
          alignment: 'right',
          minWidth: 100,
          Cell: flow => numberWithCommas(flow.flow_attempted_count),
        },
        {
          Header: 'Issue Count',
          accessor: 'issue_count',
          sortable: true,
          alignment: 'right',
          minWidth: 100,
          Cell: flow => numberWithCommas(flow.issue_count),
        },
        {
          Header: 'Issue Rate',
          accessor: 'issue_rate',
          sortable: true,
          alignment: 'right',
          minWidth: 100,
          Cell: flow => `${(flow.issue_rate * 100).toFixed(0)}%`,
        },
      ]}
    />
  );
};

FlowIssueReport.propTypes = {
  fetchFlowErrorReportData: PropTypes.func.isRequired,
  loaded: PropTypes.bool,
  flows: PropTypes.object,
  flowErrorReportData: PropTypes.shape({
    data: PropTypes.array,
    meta: PropTypes.shape({
      synced: PropTypes.bool,
      errored: PropTypes.bool,
    }),
  }),
};

function mapStateToProps(state) {
  return {
    flowErrorReportData: selectFlowErrorReportData(state),
    flows: selectPublishedFlows(state),
    loaded: selectAreFlowsSynced(state),
  };
}

const mapDispatchToProps = { fetchFlowErrorReportData };

export default connect(mapStateToProps, mapDispatchToProps)(FlowIssueReport);
