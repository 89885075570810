import React from 'react';
import PropTypes from 'prop-types';

const PreventSort = ({ children }) => {
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  return <div onClick={event => event.stopPropagation()}>{children}</div>;
};

PreventSort.propTypes = {
  children: PropTypes.node,
};

export default PreventSort;
