import styled from 'styled-components';

const Label = styled.div`
  background-color: white;
  padding: 1em;
  margin: 1em;
  border-radius: 6px;
  box-shadow: 0 12px 34px 0 rgba(71, 88, 114, 0.2);
`;

export default Label;
