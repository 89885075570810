import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  platform: PropTypes.oneOf([
    'ios',
    'android',
    'flutter',
    'react-native',
    'xamarin',
    'ionic',
  ]),
  state: PropTypes.oneOf(['enabled', 'disabled']),
  createdAt: PropTypes.string,
  createdBy: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
});
