import { SPOOF_STARTED, SPOOF_ERRORED } from 'constants/spoofing';

export default function spoofing(state = '', { type, payload }) {
  switch (type) {
    case SPOOF_STARTED: {
      const { id, initializing } = payload;
      return initializing ? id : state;
    }

    case SPOOF_ERRORED:
      return '';

    default:
      return state;
  }
}
