/* global LISTSERV_API_URL */

export default function createListservClient(auth) {
  const baseUrl = `${LISTSERV_API_URL}/v1`;

  const fetchListserv = async path => {
    const jwt = await auth.getToken();
    const accountId = await auth.getAccountId();
    return fetch(`${baseUrl}/accounts/${accountId}/${path}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });
  };

  return {
    getInstalledDomains: async () => {
      const response = await fetchListserv('domains');

      if (!response.ok) {
        throw new Error(`Failed to fetch installed domains`);
      }

      return response.json();
    },
  };
}
