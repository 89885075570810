import styled from 'styled-components';

export default styled.input`
  font-family: Mulish;
  box-sizing: border-box;
  padding: 6px;
  border: 1px solid
    ${props =>
      props.invalid ? props.theme['$red-dark'] : props.theme['$gray-3']};
  border-radius: 4px;
  min-height: 20px;
  font-size: 1em;
  transition: border 0.1s;

  &::placeholder {
    color: ${props => props.theme['$gray-30']};
    font-style: italic;
  }

  &:focus {
    border: 1px solid
      ${props => {
        return props.invalid ? props.theme['$red-dark'] : props.theme.$blue;
      }};
    outline: none;
    transition: border 0.1s;
  }

  &:disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.38);
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type='number'] {
    -moz-appearance: textfield;
  }
`;
