export const PERMISSIONS_OPTIONS = [
  {
    label: 'Admin',
    value: 'account-admin',
    description: 'Full control of API keys',
  },
  {
    label: 'Publisher',
    value: 'account-publisher',
    description: 'Edit, publish, and view data from API',
  },
  {
    label: 'Editor',
    value: 'account-editor',
    description: 'Edit and view data from API',
  },
  {
    label: 'Read Only',
    value: 'account-readonly',
    description: 'View data from API',
  },
];
