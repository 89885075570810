import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box } from '@appcues/component-library';
import { getGravatarUrlFromEmail } from 'helpers/user';
import { selectUser } from 'reducers/user';
import { selectAccountUser } from 'reducers/account/users';

const AvatarImg = ({ avatarUrl, email, ...imgProps }) => (
  <Box
    is="img"
    borderRadius="50%"
    alt="avatar"
    src={avatarUrl || getGravatarUrlFromEmail(email)}
    {...imgProps}
  />
);

AvatarImg.propTypes = {
  userId: PropTypes.string,
  avatarUrl: PropTypes.string,
  email: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
AvatarImg.defaultProps = {
  userId: '',
  avatarUrl: '',
  email: '',
  width: 80,
};

const mapStateToProps = (state, ownProps) => {
  const { userId } = ownProps;
  const userMeta =
    (userId
      ? (selectAccountUser(state, userId) || {}).meta
      : selectUser(state)) || {};
  return {
    avatarUrl: userMeta.avatarUrl,
    email: userMeta.email,
  };
};

export default connect(mapStateToProps)(AvatarImg);
