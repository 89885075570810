const alphaNumericRegEx = /^[\da-z]+$/i;

export function isAlphaNumericString(string) {
  if (!string) {
    return false;
  }

  const stringWithoutSpaces = string.replace(/\s/g, '');

  return alphaNumericRegEx.test(stringWithoutSpaces);
}

export function stripNonAlphaNumericChars(string) {
  return string.replace(/\W/g, ' ');
}

export function checkIsBlank(input) {
  return input.replace(/\s/g, '') === '';
}

export function checkHasProtocol(input, checkHttps = false) {
  try {
    const url = new URL(input);
    if (checkHttps) {
      return url.protocol === 'https:';
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch {
    return false;
  }
}

export function checkIsDuplicate(input, testUrls) {
  return testUrls.includes(input);
}

export function isNonEmptyString(str) {
  return str && typeof str === 'string';
}

export function isObject(obj) {
  return obj && typeof obj === 'object';
}
