import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { matchPath } from 'react-router-dom';
import { history } from 'next/lib/history';
import { routes } from 'next/pages/Routes';

export const addToErrReportContext = newContext => {
  if (!newContext || typeof newContext !== 'object') {
    throw new TypeError('Invalid context data passed to addToErrReportContext');
  }

  Sentry.configureScope(scope => {
    const user = scope.getUser();
    Sentry.setUser({ ...user, ...newContext });
  });
};

export const addToErrReportTags = newTag => {
  if (!newTag || typeof newTag !== 'object') {
    throw new TypeError('Invalid context data passed to addToErrReportTags');
  }
  Sentry.setTags(newTag);
};

export const reportError = (error, info) => {
  // eslint-disable-next-line no-console
  console.error(error);
  if (Sentry && Sentry.captureException) {
    Sentry.captureException(error, { extra: { info: JSON.stringify(info) } });
  }
};

const SENTRY_DSN =
  'https://f8b92206489e44de85c4d2740a7fb9ef@o10683.ingest.sentry.io/1250866';

export const configureErrorReporting = (commitHash, env) => {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: commitHash,
    environment: env || 'staging',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(
          history,
          routes,
          matchPath
        ),
      }),
    ],
    tracesSampleRate: 0.25,
  });
};
