import { getMasterClause, groupOperators } from '@appcues/libcues';

/**
 * Takes in a list of clauses, and returns a nested structure that the React UI likes.  For example...
 *
 * [
 *  { id: 1, booleanOperator: "or" },
 *  { id: "1-1", parentId: 1, operator: "regex", value: "\/\w+\/foo" }
 * ]
 *
 * ...becomes...
 *
 * {
 *  id: 1,
 *  booleanOperator: "or",
 *  children: [
 *      { id: "1-1", parentId: 1, position: 0, operator: "regex", value: "\/\w+\/foo" }
 *  ]
 * }
 *
 * @param clauses
 * @param masterClause
 * @returns {*}
 */

export function getVerboseNestedCondition(clauses, maybeMasterClause) {
  const masterClause = maybeMasterClause || { ...getMasterClause(clauses) };

  if (masterClause.operator === groupOperators.BOOLEAN) {
    const childClauses = clauses.filter(it => {
      return it.parentId === masterClause.id;
    });

    masterClause.children = childClauses.map(cc => {
      return getVerboseNestedCondition(clauses, cc);
    });
  }

  return masterClause;
}
