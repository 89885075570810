import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DateRangeDropdown } from '@studio/legacy-components';
import { provide } from 'next/components/Hacks';
import { selectHistoricalLimit } from 'next/lib/selectors';
import { restrict, validate } from './date-limits';
import FooterMessage from './FooterMessage';

export function BoundedDateRangeDropdown({
  disabled,
  duration,
  limit,
  onApply,
  options,
  value,
}) {
  const [message, setMessage] = useState();
  const [restrictions, setRestrictions] = useState({});

  useEffect(() => {
    setRestrictions(restrict(value, { limit, duration }));
  }, [duration, limit, value]);

  /**
   * NOTE: Currently the change handler is only triggered after the user blurs
   *       the input when typing so if the user inputs an invalid date, calendar
   *       will close before the footer is shown. However, the input will still
   *       be marked as an error and the footer will be visible when the user
   *       reopens the calendar.
   */

  // Validate and update restrictions based on next dates
  const handleChange = next => {
    setMessage(validate(next, { limit, duration }));
    setRestrictions(restrict(next, { limit, duration }));
  };

  // Only validate on day click to handle dates that are disabled
  const handleDayClick = next => {
    setMessage(validate(next, { limit, duration }));
  };

  // Mark date range as invalid if error message exists
  const invalid = Boolean(message);

  // Common props for both start and end date inputs
  const inputs = {
    footer: typeof message === 'string' && (
      <FooterMessage duration={duration} limit={limit} message={message} />
    ),
    inputProps: { error: invalid },
  };

  return (
    <DateRangeDropdown
      disabled={disabled}
      error={invalid}
      onApply={onApply}
      onChange={handleChange}
      onDayClick={handleDayClick}
      options={options}
      endProps={{
        ...inputs,
        dayPickerProps: {
          disabledDays: [restrictions.end],
        },
      }}
      startProps={{
        ...inputs,
        dayPickerProps: {
          disabledDays: [restrictions.start],
        },
      }}
      value={value}
    />
  );
}

BoundedDateRangeDropdown.propTypes = {
  ...DateRangeDropdown.propTypes,
  duration: PropTypes.number,
  limit: PropTypes.number,
};

const mapStateToProps = (state, { limit }) => ({
  limit: limit ?? selectHistoricalLimit(state),
});

export default provide(mapStateToProps)(BoundedDateRangeDropdown);
