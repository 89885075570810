import PropTypes from 'prop-types';
import pick from 'lodash.pick';

/**
 * Full shape of survey data model
 */
const Shape = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      fieldAnswers: PropTypes.arrayOf(
        PropTypes.shape({
          fieldId: PropTypes.string,
          value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
            PropTypes.instanceOf(Date),
          ]),
        })
      ),
      sessionId: PropTypes.string,
      timestamp: PropTypes.string,
      userId: PropTypes.string,
    })
  ),
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      fieldId: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

/**
 * Allowed fields for storage
 */
const allowed = ['answers', 'labels'];

/**
 * Survey parsing function
 *
 * @param {Object<keyof Survey>} survey - Object with survey fields
 * @return {Object<keyof Survey>} Object with parsed survey fields
 */
export const parse = survey => pick(survey, allowed);

export default PropTypes.shape(parse(Shape));
