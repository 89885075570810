import React, { Component } from 'react';
import ReactSimpleRange from 'react-simple-range';

import { Button } from '@appcues/component-library';
import FieldGroup from 'components/Common/Forms/FieldGroup';
import SatisfactionSurveyCard from 'components/SatisfactionSurveys/SatisfactionSurveyCard';
import { SATISFACTION_TARGETING_CATEGORY } from 'constants/satisfaction/data';
import * as clauseTransforms from 'transforms/clauses';

class SatisfactionSampleSettings extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { targetingOptionView } = this.props;
    const willSamplingViewOpen =
      targetingOptionView !== SATISFACTION_TARGETING_CATEGORY.SAMPLING &&
      nextProps.targetingOptionView ===
        SATISFACTION_TARGETING_CATEGORY.SAMPLING;

    if (willSamplingViewOpen) {
      window.scrollTo(0, 730);
    }
  }

  updateSamplingClause = (value, clause) => {
    const { clauses, updateSatisfaction } = this.props;
    const updatedClauses = clauseTransforms.updateClause(clauses, clause.id, {
      operator: '<=',
      value,
    });

    updateSatisfaction({ clauses: updatedClauses });
  };

  render() {
    const {
      targetingOptionView,
      setTargetingOptionView,
      sessionRandomizerClause,
      saveSatisfaction,
    } = this.props;
    return (
      <FieldGroup className="satisfaction-form-block sample-settings">
        <SatisfactionSurveyCard
          disableHover
          className="satisfaction-form-card"
          header="How many users will be sampled:"
        >
          <div className="satisfaction-card-top">
            {targetingOptionView ===
            SATISFACTION_TARGETING_CATEGORY.SAMPLING ? null : (
              <p>{`${Number.parseInt(
                sessionRandomizerClause.value,
                10
              )}% of users that visit your site per day`}</p>
            )}
            <Button
              onClick={() =>
                setTargetingOptionView(SATISFACTION_TARGETING_CATEGORY.SAMPLING)
              }
              className="button-primary"
            >
              {targetingOptionView === SATISFACTION_TARGETING_CATEGORY.SAMPLING
                ? 'Done'
                : 'Edit'}
            </Button>
          </div>
          {targetingOptionView === SATISFACTION_TARGETING_CATEGORY.SAMPLING && (
            <div className="satisfaction-form-card-bottom">
              <hr />
              <div className="satisfaction-card-setting sampling-range">
                <p className="settings-label sampling-range">{`Percentage of current users: ${Number.parseInt(
                  sessionRandomizerClause.value,
                  10
                )}%`}</p>
                <ReactSimpleRange
                  label
                  min={1}
                  max={100}
                  sliderSize={10}
                  trackColor="#00B2E5"
                  thumbColor="#00B2E5"
                  value={Number.parseInt(sessionRandomizerClause.value, 10)}
                  onChange={e =>
                    this.updateSamplingClause(e.value, sessionRandomizerClause)
                  }
                  onChangeComplete={() => saveSatisfaction()}
                />
              </div>
            </div>
          )}
        </SatisfactionSurveyCard>
      </FieldGroup>
    );
  }
}

export default SatisfactionSampleSettings;
