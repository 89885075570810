import { createSelector } from 'reselect';
import { createCollectionReducer } from 'next/lib/collections';
import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

export const selectEventNameLabels = state => state[TYPE];

export const selectEventNameLabelsByName = createSelector(
  [selectEventNameLabels],
  eventNameLabels => {
    return Object.values(eventNameLabels || {}).reduce((acc, curr) => {
      acc[curr.name] = curr;
      return acc;
    }, {});
  }
);
