import { createCollectionActions } from 'next/lib/collections';

export const TYPE = 'surveys';

export const {
  patterns,
  read,
  readOne,
  reject,
  resolve,
  update,
  flush,
  replace,
  insert,
  add,
  remove,
  prune,
} = createCollectionActions(TYPE);
