import {
  getFlattenedClausesFromNestedConditions,
  conditionNames,
} from '@appcues/libcues';
import { setSegmentCustomSegment } from 'helpers/segments';
import { STEP_TYPE_IDS } from 'constants/stepTypes';

/**
 * Determines the step on which to target flow completion for the preset
 *
 * @param currentFlow
 * @param stepGroups
 * @returns string
 */

export const determineFlowCompletionStepId = (currentFlow, stepGroups) => {
  const currentFlowSteps = Object.values(currentFlow.steps || {});
  const sortedFlowSteps = currentFlowSteps.sort(
    (item1, item2) => item1.index - item2.index
  );

  const completionStep =
    currentFlow.type === STEP_TYPE_IDS.JOURNEY
      ? sortedFlowSteps.find(
          subStep =>
            stepGroups &&
            stepGroups[subStep.id] &&
            stepGroups[subStep.id].actionType !== STEP_TYPE_IDS.REDIRECT
        )
      : currentFlow;

  const completionId = completionStep ? completionStep.id : currentFlow.id;
  return completionId;
};

export const getItemClauses = checklistItem => {
  return checklistItem && checklistItem.conditions
    ? getFlattenedClausesFromNestedConditions(checklistItem.conditions)
    : setSegmentCustomSegment([]);
};

export const getItemAction = (checklistItem, type) => {
  if (
    checklistItem &&
    checklistItem.actions &&
    checklistItem.actions.length > 0
  ) {
    const { actions } = checklistItem;
    return actions.find(
      action =>
        action && action.attributes && action.attributes.actionType === type
    );
  }
  return null;
};

/**
 * Given a checklist item, the flow it is linked to, and all stepGroups,
 * determine if the checklist item is set to be marked as completed
 * when the provided flow's last stepGroup has been completed.
 *
 * @param checklistItem
 * @param currentFlow
 * @param stepGroups
 * @returns {boolean}
 */

export const determineFlowCompletedOnly = (
  checklistItem,
  currentFlow,
  stepGroups
) => {
  if (!currentFlow) return false;

  const clauses = getItemClauses(checklistItem);
  const completionId = determineFlowCompletionStepId(currentFlow, stepGroups);

  return (
    completionId &&
    clauses.length === 2 &&
    clauses.filter(
      clause =>
        clause.conditionName === 'content' && clause.content === completionId
    ).length === 1
  );
};

export const determineDefaultPropertyClauseOnly = checklistItem => {
  const clauses = getItemClauses(checklistItem);

  return (
    clauses.length === 2 &&
    clauses.filter(
      clause =>
        clause.conditionName === conditionNames.PROPERTIES &&
        clause.property === '' &&
        clause.value === ''
    ).length === 1
  );
};
