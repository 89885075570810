import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CTable } from '@appcues/component-library';
import { Link } from '@studio/legacy-components';
import Loader from 'components/Common/Loader';
import ErrorState from 'components/Common/ErrorState';
import {
  selectPublishedFlows,
  selectAreFlowsSynced,
} from 'reducers/account/flows';
import {
  selectAccountGoals,
  selectAccountGoalsSynced,
} from 'reducers/account/goals';
import { fetchFlowPerformanceReportData } from 'actions/analytics';
import { selectFlowPerformanceReportData } from 'reducers/analytics';
import { numberWithCommas } from 'utils/textFormatting';
import { getPercentage } from 'utils/numbers';

function FlowNameCell({ flow }) {
  return (
    <strong>
      <Link to={`/flows/${flow.flow_id}/analytics`}>{flow.flow_name}</Link>
    </strong>
  );
}

function GoalNameCell({ flow }) {
  return (
    <strong>
      <Link to={`/goals/${flow.goal_id}`}>{flow.goal_name}</Link>
    </strong>
  );
}

const FlowPerformanceReport = ({
  loaded = false,
  flows = {},
  goals = {},
  flowPerformanceReportData = [],
  fetchFlowPerformanceReportData: initialize,
}) => {
  const [tableData, setTableData] = useState([]);

  const buildTableData = () => {
    return flowPerformanceReportData.data
      .filter(result => flows[result.flow_id] && goals[result.goal_id])
      .map(result => {
        const flow = flows[result.flow_id];
        const goal = goals[result.goal_id];

        return {
          flow_name: flow.name,
          flow_id: flow.id,
          goal_id: goal.id,
          goal_name: goal.name,
          flow_started_count: Number.parseInt(
            result['appcues:flow_started'].count,
            10
          ),
          flow_completed_count: result['appcues:flow_completed'].count,
          completion_rate: getPercentage(
            result['appcues:flow_completed'].count,
            result['appcues:flow_started'].count
          ),
          goal_met_count: Number.parseInt(
            result['appcues:flow_completed']['appcues:goal_reached'],
            10
          ),
          goal_met_percentage: getPercentage(
            result['appcues:flow_completed']['appcues:goal_reached'],
            result['appcues:flow_completed'].count
          ),
        };
      });
  };

  useEffect(initialize, []);
  useEffect(() => {
    if (loaded && flowPerformanceReportData.meta.synced) {
      setTableData(buildTableData());
    }
  }, [loaded, flows, goals, flowPerformanceReportData]);

  if (!loaded || !flowPerformanceReportData.meta.synced) {
    return <Loader margin="4em" />;
  }

  if (flowPerformanceReportData.meta.errored) {
    return <ErrorState margin="4em" />;
  }

  return (
    <CTable
      defaultSortColumn="flow_name"
      defaultSortAscending
      data={tableData}
      columns={[
        {
          Header: 'Flow',
          accessor: 'flow_name',
          sortable: true,
          minWidth: 200,
          Cell: flow => <FlowNameCell flow={flow} />,
        },
        {
          Header: 'Flow Started',
          accessor: 'flow_started_count',
          sortable: true,
          alignment: 'right',
          minWidth: 100,
          Cell: flow => numberWithCommas(flow.flow_started_count) || 0,
        },
        {
          Header: 'Completion Rate',
          accessor: 'completion_rate',
          sortable: true,
          alignment: 'right',
          minWidth: 100,
          Cell: flow => `${(flow.completion_rate * 100).toFixed(0)}%`,
        },
        {
          Header: 'Goal',
          accessor: 'goal_name',
          sortable: true,
          minWidth: 200,
          Cell: flow => <GoalNameCell flow={flow} />,
        },
        {
          Header: 'Goal Met',
          accessor: 'goal_met_count',
          sortable: true,
          alignment: 'right',
          minWidth: 100,
          Cell: flow => numberWithCommas(flow.goal_met_count) || 0,
        },
        {
          Header: 'Goal Met %',
          accessor: 'goal_met_percentage',
          sortable: true,
          alignment: 'right',
          minWidth: 100,
          Cell: flow => `${(flow.goal_met_percentage * 100).toFixed(0)}%`,
        },
      ]}
    />
  );
};

FlowPerformanceReport.propTypes = {
  fetchFlowPerformanceReportData: PropTypes.func.isRequired,
  loaded: PropTypes.bool,
  flows: PropTypes.object,
  goals: PropTypes.object,
  flowPerformanceReportData: PropTypes.shape({
    data: PropTypes.array,
    meta: PropTypes.shape({
      synced: PropTypes.bool,
      errored: PropTypes.bool,
    }),
  }),
};

function mapStateToProps(state) {
  return {
    flowPerformanceReportData: selectFlowPerformanceReportData(state),
    flows: selectPublishedFlows(state),
    goals: selectAccountGoals(state),
    loaded: selectAreFlowsSynced(state) && selectAccountGoalsSynced(state),
  };
}

const mapDispatchToProps = { fetchFlowPerformanceReportData };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlowPerformanceReport);
