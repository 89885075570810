import {
  REQUEST_RESOLVED,
  ITEM_UPDATED,
  ITEM_INSERTED,
  ITEM_REPLACED,
  ITEM_REMOVED,
  ITEM_DROPPED,
} from './action-types';

/**
 * NOTE: Most of the reducers below are not optimistic and a result of server
 *       responses EXCEPT `ITEM_UPDATED` and `ITEM_REMOVED`. This means it's
 *       possible that the update/remove fails due to server errors leaving the
 *       UI in an out-of-sync state. As of now, we do not have any rollback/undo
 *       functionality to fix those states but that is something we can
 *       introduce in the future or consider moving to a fully non-optimistic
 *       design.
 */

/**
 * Create a reducer for an entity type. Entities typically represent a single
 * data model such as feature flags or the current account.
 *
 * @param {string} entity - The type of entity
 * @return {function} Entity scoped reducer
 */
export function createEntityReducer(entity) {
  return (state = {}, { type, payload, meta }) => {
    if (meta && meta.type === entity) {
      switch (type) {
        case REQUEST_RESOLVED:
        case ITEM_INSERTED:
        case ITEM_REPLACED:
          return payload;

        // Optimistic update
        case ITEM_UPDATED:
          return {
            ...state,
            ...payload,
          };

        default:
          return state;
      }
    }

    return state;
  };
}

/**
 * Create a reducer for a collection type. Collections typically represent
 * groups of entities such as users or flows.
 *
 * @param {string} entity - The type of entity for the collection
 * @return {function} Entity scoped reducer
 */
export function createCollectionReducer(entity) {
  return (state = {}, { type, payload, meta }) => {
    if (meta && meta.type === entity) {
      switch (type) {
        case REQUEST_RESOLVED:
          if (meta.partial) {
            return {
              ...state,
              ...payload,
            };
          }
          return payload;

        case ITEM_INSERTED:
        case ITEM_REPLACED:
          return {
            ...state,
            [payload.id]: payload,
          };

        // Optimistic update
        case ITEM_UPDATED:
          return {
            ...state,
            [payload.id]: {
              ...state[payload.id],
              ...payload.delta,
            },
          };

        // Optimistic remove
        case ITEM_DROPPED:
        case ITEM_REMOVED: {
          const { [payload.id]: omit, ...rest } = state;
          return rest;
        }

        default:
          return state;
      }
    }

    return state;
  };
}
