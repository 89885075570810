import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Select } from '@studio/legacy-components';
import AudienceBaseClause from 'components/Common/Clauses/AudienceBase';
import { SubClause } from 'components/Common/Clauses/styled';
import { selectChecklistTargetingOptions } from 'entities/checklists';

const STATUS_OPTIONS = [
  { label: 'is in progress', value: 'in_progress' },
  { label: 'has been skipped', value: 'skipped' },
  { label: 'has been completed', value: 'completed\ndismissed' },
  {
    label: 'has not been seen',
    value: null,
  },
];

export function ChecklistsClause(props) {
  const { clause, options = [], updateClause } = props;

  const handleChecklistChange = ({ value: checklist }) =>
    updateClause(clause.id, { checklist });

  const handleStatusChange = ({ value: status }) =>
    updateClause(clause.id, { status });

  const checklistOptions = [...options];

  if (
    clause.checklist &&
    !checklistOptions?.some(
      ({ value: checklist }) => checklist === clause.checklist
    )
  ) {
    checklistOptions.push({ label: clause.checklist, value: clause.checklist });
  }

  return (
    <AudienceBaseClause {...props}>
      <SubClause>
        <Select
          placeholder="Select a Checklist"
          value={checklistOptions.find(
            ({ value: checklist }) => checklist === clause.checklist
          )}
          options={checklistOptions.sort((a, b) =>
            a.label.localeCompare(b.label)
          )}
          onChange={handleChecklistChange}
        />

        <Select
          value={STATUS_OPTIONS.find(
            ({ value: status }) => status === (clause.status || null)
          )}
          options={STATUS_OPTIONS}
          onChange={handleStatusChange}
        />
      </SubClause>
    </AudienceBaseClause>
  );
}

ChecklistsClause.propTypes = {
  clause: PropTypes.shape({
    conditionName: PropTypes.string,
    id: PropTypes.string,
    parentId: PropTypes.string,
    checklist: PropTypes.string,
    status: PropTypes.string,
  }),
  updateClause: PropTypes.func,
  options: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  options: selectChecklistTargetingOptions(state),
});

export default connect(mapStateToProps)(ChecklistsClause);
