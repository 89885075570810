import styled from 'styled-components';

export default styled.textarea`
  box-sizing: border-box;
  padding: 6px;
  border: 2px solid
    ${props => (props.isError ? props.theme.$red : props.theme['$gray-3'])};
  border-radius: 4px;
  font-size: 1em;

  height: 100px;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  resize: ${({ resize = false }) => (resize ? 'vertical' : 'none')};
  &::placeholder {
    color: ${props => props.theme['$gray-30']};
    font-style: italic;
  }

  &:focus {
    border: 2px solid ${props => props.theme.$blue};
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.38);
  }
`;
