/* global APPCUES_API_URL */
import createFetchClient from './client-factory';

export default function createApiClient(auth) {
  const fetchEndUserApi = createFetchClient(auth, `${APPCUES_API_URL}/v1`);

  return {
    getUserSegments: userId =>
      fetchEndUserApi(`users/${userId}/segment_memberships`),
    getUserQualifiedContent: userId =>
      fetchEndUserApi(`users/${userId}/qualified_content`),
    resetUserFlowHistory: userId =>
      fetchEndUserApi(`users/${userId}/reset_frequency`, {
        method: 'POST',
      }),
    getProfileAttributes: () => fetchEndUserApi('profile_attributes'),
    getEventNames: () => fetchEndUserApi('event_names'),
    getGroupAttributes: () => fetchEndUserApi('group_attributes'),
    exportSegment: (id, data) =>
      fetchEndUserApi(`segments/${id}/export`, {
        method: 'POST',
        body: JSON.stringify(data),
      }),
  };
}
