import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { OpenInBuilderManager } from 'next/components/OpenInBuilder';

import { openExperience } from 'next/lib/builder';
import { selectDefaultTheme, Shape as ThemeShape } from 'next/entities/themes';

import CreateExperienceModal from './CreateExperienceModal';

const STATES = {
  IDLE: 'IDLE',
  CREATE: 'CREATE',
  IN_FLIGHT: 'IN_FLIGHT',
  OPEN_IN_BUILDER: 'OPEN_IN_BUILDER',
  ERROR: 'ERROR',
};

export const CreateExperienceManager = ({
  defaultTheme,
  onCreate,
  renderTrigger,
  type,
  title,
  omitTheme,
}) => {
  const [experience, setExperience] = useState();
  const [state, setState] = useState(STATES.IDLE);

  const handleOpen = () => setState(STATES.CREATE);

  const handleClose = () => setState(STATES.IDLE);

  const handleSubmit = async values => {
    try {
      setState(STATES.IN_FLIGHT);

      const newExperience = await onCreate({
        name: values.name,
        previewUrl: values.previewUrl,
        theme: values.theme,
        tagIds: values.tagIds,
      });

      setExperience(newExperience);
      setState(STATES.OPEN_IN_BUILDER);
    } catch {
      setState(STATES.ERROR);
    }
  };

  const handleNavigate = () =>
    openExperience({
      experience: experience.id,
      url: experience.previewUrl,
      // Since pins is the default experience type in the builder, we don't need to pass the pattern in that case.
      ...(type !== 'pins' && { pattern: type }),
    });

  return (
    <>
      <CreateExperienceModal
        visible={[STATES.CREATE, STATES.IN_FLIGHT, STATES.ERROR].includes(
          state
        )}
        disabled={state === STATES.IN_FLIGHT}
        hasError={state === STATES.ERROR}
        onSubmit={handleSubmit}
        onClose={handleClose}
        defaultTheme={defaultTheme}
        omitTheme={omitTheme}
        title={title}
      />
      <OpenInBuilderManager
        type={type}
        visible={state === STATES.OPEN_IN_BUILDER}
        onClose={handleClose}
        onNavigate={handleNavigate}
      />
      {renderTrigger?.(handleOpen)}
    </>
  );
};

CreateExperienceManager.propTypes = {
  defaultTheme: ThemeShape,
  onCreate: PropTypes.func,
  renderTrigger: PropTypes.func,
  type: PropTypes.oneOf(['pins', 'banner']),
  omitTheme: PropTypes.bool,
  title: PropTypes.string,
};

const mapStateToProps = state => ({
  defaultTheme: selectDefaultTheme(state),
});

export default connect(mapStateToProps)(CreateExperienceManager);
