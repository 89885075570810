import styled from 'styled-components';
import { H2, Button } from '@studio/legacy-components';
import ComingSoonImg from 'next/assets/images/coming-soon.svg';

export const ComingSoonImage = styled.img.attrs({
  src: ComingSoonImg,
})`
  width: 122px;
`;

export const ComingSoonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px;
  gap: 16px;
  ${H2} {
    font-weight: var(--semi-bold);
    font-size: var(--large);
  }
  ${Button} {
    :hover {
      color: var(--white);
    }
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
`;
