import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  getNestedConditionsFromFlattenedClauses,
  clauseKeys,
} from '@appcues/libcues';

import { Notice, NOTICE_TYPES, Button } from '@appcues/component-library';
import BaseModal from 'components/ModalContainer/BaseModal';
import GoalsList from 'components/ModalContainer/DefineGoalModal/GoalsList';
import CreateGoalPanel from 'components/Common/CreateGoalPanel';

import { setGoalCustomSegment, computeGoalsLimit } from 'helpers/goals';
import { createGoalForRule, update } from 'actions/account/goals';
import { hideModal } from 'actions/currentModal';
import { selectAccountGoals } from 'reducers/account/goals';
import { SUPPORT_EMAIL } from 'constants/externalAppcuesResources';
import { selectAccountMeta } from 'reducers/account/meta';

class DefineGoalModal extends Component {
  constructor(props) {
    super(props);

    const { accountMeta, allGoals } = props;
    const maxNumberOfActiveGoals = computeGoalsLimit(accountMeta);
    const numberOfActiveGoals = Object.keys(allGoals)
      .map(goalId => allGoals[goalId])
      .filter(goal => !goal.disabled).length;
    const canActivateOrAddGoal = numberOfActiveGoals < maxNumberOfActiveGoals;

    this.state = {
      name: '',
      error: null,
      clauses: setGoalCustomSegment([]),
      listGoals: !canActivateOrAddGoal,
      canActivateOrAddGoal,
      numberOfActiveGoals,
      maxNumberOfActiveGoals,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { allGoals } = this.props;
    const { maxNumberOfActiveGoals } = this.state;

    const prevNumberOfActiveGoals = Object.keys(allGoals)
      .map(goalId => allGoals[goalId])
      .filter(goal => !goal.disabled).length;
    const numberOfActiveGoals = Object.keys(nextProps.allGoals)
      .map(goalId => nextProps.allGoals[goalId])
      .filter(goal => !goal.disabled).length;
    const canActivateOrAddGoal = numberOfActiveGoals < maxNumberOfActiveGoals;

    if (prevNumberOfActiveGoals !== numberOfActiveGoals) {
      this.setState({ numberOfActiveGoals, canActivateOrAddGoal });

      if (!canActivateOrAddGoal) {
        this.setState({ listGoals: true });
      }
    }
  }

  handleTitleChange = ({ target: { value } }) => {
    this.setState({ name: value });
  };

  handleClausesChange = clauses => {
    this.setState({ clauses });
  };

  handleOK = () => {
    // TODO: nearly identical behavior between this function and onClickSave
    // in GoalsCreate component. Should abstract, maybe through composition?
    return new Promise((resolve, reject) => {
      const { onCreate, allGoals, stepId } = this.props;
      const { name, clauses } = this.state;

      if (name) {
        const matchingGoal = Object.values(allGoals || {}).find(goal => {
          return `${goal.name}`.toLowerCase() === name.toLowerCase();
        });

        if (matchingGoal) {
          const message = `A goal with the name "${name}" already exists.`;
          this.setState({ error: message });
          reject(new Error(message));
        } else {
          const nestedConditions = getNestedConditionsFromFlattenedClauses(
            clauses,
            null,
            clauseKeys
          );
          onCreate(name, nestedConditions, stepId);
          resolve();
        }
      } else {
        const message = 'Please enter a name for your new goal.';
        this.setState({ error: message });
        reject(new Error(message));
      }
    });
  };

  handleToggleGoalsList = () => {
    this.setState(({ listGoals }) => ({ listGoals: !listGoals }));
  };

  render() {
    const {
      name,
      error,
      clauses,
      listGoals,
      numberOfActiveGoals,
      canActivateOrAddGoal,
      maxNumberOfActiveGoals,
    } = this.state;
    const { className, allGoals, stepId, onChange, onHide } = this.props;
    const handleSetGoal = (/* goalId */) => {
      // XXX: goalActions.setGoal is undefined
      // goalActions.setGoal(stepId, goalId);
      onHide();
    };

    const goalsArray = Object.keys(allGoals).map(goalId => allGoals[goalId]);

    const upgradeMailToURL = `mailto:${SUPPORT_EMAIL}?subject=I%27d%20like%20to%20upgrade%20for%20more%20goals&body=Hey%20Appcues%2C%0A%0AI%27m%20currently%20limited%20to%20${maxNumberOfActiveGoals}%20flows%20on%20my%20current%20plan.%20Please%20upgrade%20me!%0A%0AThanks!`;

    return (
      <BaseModal
        modalClass="goal-modal page-container"
        className={className}
        title="Define a New Goal"
        fullScreen
        okText="Save"
        onClickOK={this.handleOK}
        hideOKButton={!canActivateOrAddGoal}
        showCancelButton
        {...this.props}
      >
        {error && <Notice type={NOTICE_TYPES.warning}>{error}</Notice>}

        {canActivateOrAddGoal && (
          <Notice
            type={NOTICE_TYPES.default}
            actions={[
              <Button
                key="toggle-goals"
                onClick={this.handleToggleGoalsList}
                className="button-primary-alt"
              >
                {listGoals ? 'Hide Goals' : 'View Goals'}
              </Button>,
              <Button
                key="upgrade"
                href={upgradeMailToURL}
                target="_blank"
                className="button-primary"
              >
                Upgrade
              </Button>,
            ]}
          >
            <strong>Note: </strong>
            You&apos;re using <strong>{numberOfActiveGoals}</strong> of{' '}
            <strong>{maxNumberOfActiveGoals}</strong> tracked goals. Upgrade
            your plan to track more at a time.
          </Notice>
        )}

        {!canActivateOrAddGoal && (
          <Notice
            type={NOTICE_TYPES.warning}
            actions={[
              <Button
                key="upgrade"
                href={upgradeMailToURL}
                target="_blank"
                className="button-primary"
              >
                Upgrade
              </Button>,
            ]}
          >
            <strong>Limit reached: </strong>
            Your plan comes with <strong>{maxNumberOfActiveGoals}</strong>{' '}
            tracked goals. Reuse an existing goal, disable goals you don&apos;t
            use below, or upgrade your plan to track more at a time.
          </Notice>
        )}

        {listGoals && (
          <div>
            <div className="goals-list-container">
              <GoalsList
                className="goals-list"
                canActivateGoal={canActivateOrAddGoal}
                goals={goalsArray}
                setGoal={handleSetGoal}
                activateGoal={({ id }) => onChange(id, { disabled: false })}
                deactivateGoal={({ id }) => onChange(id, { disabled: true })}
              />
            </div>
            {canActivateOrAddGoal && <hr />}
          </div>
        )}

        {canActivateOrAddGoal && (
          <CreateGoalPanel
            name={name}
            clauses={clauses}
            allGoals={allGoals}
            stepId={stepId}
            onNameChange={this.handleTitleChange}
            onClausesChange={this.handleClausesChange}
          />
        )}
      </BaseModal>
    );
  }
}

function mapStateToProps(state) {
  return {
    allGoals: selectAccountGoals(state),
    accountMeta: selectAccountMeta(state),
    account: state.account,
  };
}

const mapDispatchToProps = {
  onCreate: createGoalForRule,
  onChange: update,
  onHide: hideModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styled(DefineGoalModal)`
  margin: 20px auto !important;

  .modal-dialog {
    border-radius: 8px !important;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25) !important;
  }

  .modal-body {
    padding: 0 !important;
  }

  .modal-background {
    display: block !important;
  }

  .modal-buttons {
    .button-success {
      background-color: ${props => props.theme.$blue};
      border-color: ${props => props.theme.$blue};
      &:not(.disabled):hover {
        background-color: #1a91ce;
        border-color: #188bc5;
      }
    }
  }

  .goal-limit-notification,
  .goal-limit-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .goal-limit-notification {
    justify-content: space-between;
  }

  .goal-limit-buttons {
    .button:not(:last-child) {
      margin-right: 10px;
    }
  }

  .goals-list-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .goals-list {
    width: 75%;
  }
`);
