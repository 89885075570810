import React, { Component } from 'react';

import { Button } from '@appcues/component-library';
import styled from 'styled-components';
import FieldGroup from 'components/Common/Forms/FieldGroup';
import Textarea from 'components/Common/Forms/Textarea';
import Panel from 'components/Common/Panel';
import SatisfactionSurveyCard from 'components/SatisfactionSurveys/SatisfactionSurveyCard';

import {
  PREVIEW_TABS,
  SATISFACTION_CREATION_STEP_TITLES,
} from 'constants/satisfaction/data';
import { VIEWED_APP_PAGE_STEP } from 'constants/events';

class SatisfactionContentEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questionOneExpanded: false,
      questionTwoExpanded: false,
    };

    this.questionOneRef = React.createRef();
    this.questionTwoRef = React.createRef();

    this.toggleQuestionOne = this.toggleQuestionOne.bind(this);
    this.toggleQuestionTwo = this.toggleQuestionTwo.bind(this);
    this.toggleAutoCollapseFeedbackFormSetting =
      this.toggleAutoCollapseFeedbackFormSetting.bind(this);
    this.handleContentChange = this.handleContentChange.bind(this);
  }

  componentDidMount() {
    const { trackEvent } = this.props;

    trackEvent(VIEWED_APP_PAGE_STEP, {
      step: SATISFACTION_CREATION_STEP_TITLES.CONTENT,
    });

    window.scrollTo(0, 0);
  }

  handleContentChange(contentCard) {
    const { questionOneExpanded, questionTwoExpanded } = this.state;
    const { updateSatisfaction, saveSatisfaction, toggleSurveyPreview } =
      this.props;

    if (contentCard.shouldExpandQuestionOne === false && questionOneExpanded) {
      updateSatisfaction({
        questionOne: this.questionOneRef.current.value || ' ',
      }).then(saveSatisfaction);
    }

    if (contentCard.shouldExpandQuestionTwo === false && questionTwoExpanded) {
      updateSatisfaction({
        questionTwo: this.questionTwoRef.current.value || ' ',
      }).then(saveSatisfaction);
    }

    if (contentCard.shouldExpandQuestionOne) {
      toggleSurveyPreview(false, PREVIEW_TABS.SURVEY);
    } else if (contentCard.shouldExpandQuestionTwo) {
      toggleSurveyPreview(false, PREVIEW_TABS.FEEDBACK);
    } else {
      toggleSurveyPreview(true);
    }

    this.setState({
      questionOneExpanded: contentCard.shouldExpandQuestionOne,
      questionTwoExpanded: contentCard.shouldExpandQuestionTwo,
    });
  }

  toggleQuestionOne() {
    const { questionOneExpanded } = this.state;
    this.handleContentChange({
      shouldExpandQuestionOne: !questionOneExpanded,
      shouldExpandQuestionTwo: false,
    });
  }

  toggleQuestionTwo() {
    const { questionTwoExpanded } = this.state;
    this.handleContentChange({
      shouldExpandQuestionTwo: !questionTwoExpanded,
      shouldExpandQuestionOne: false,
    });
  }

  toggleAutoCollapseFeedbackFormSetting() {
    const {
      satisfactionId,
      setAutoCollapseFeedbackSetting,
      autoCollapseFeedbackForm,
    } = this.props;

    setAutoCollapseFeedbackSetting(satisfactionId, !autoCollapseFeedbackForm);
  }

  render() {
    const { className, questionOne, questionTwo, autoCollapseFeedbackForm } =
      this.props;
    const { questionOneExpanded, questionTwoExpanded } = this.state;

    return (
      <Panel
        name="Edit Your NPS Questions"
        description="Pro Tip: Increase your NPS response rate by keeping questions clear and concise."
        className={`${className} satisfaction-form page-container`}
      >
        <FieldGroup className="satisfaction-form-block">
          <SatisfactionSurveyCard
            disableHover
            className="satisfaction-form-card"
            header="Your NPS survey question"
          >
            <div className="satisfaction-card-top">
              {!questionOneExpanded && <p>{questionOne}</p>}
              <Button
                onClick={this.toggleQuestionOne}
                className="button-primary"
              >
                {!questionOneExpanded ? 'Edit' : 'Done'}
              </Button>
            </div>
            {questionOneExpanded && (
              <Textarea
                ref={this.questionOneRef}
                defaultValue={questionOne}
                placeholder="On a scale of 0-10, how likely are you to recommend this product to a friend or colleague?"
              />
            )}
          </SatisfactionSurveyCard>
        </FieldGroup>
        <FieldGroup className="satisfaction-form-block">
          <SatisfactionSurveyCard
            disableHover
            className="satisfaction-form-card"
            header="Your NPS feedback question"
          >
            <div className="satisfaction-card-top">
              {!questionTwoExpanded && <p>{questionTwo}</p>}
              <Button
                onClick={this.toggleQuestionTwo}
                className="button-primary"
              >
                {!questionTwoExpanded ? 'Edit' : 'Done'}
              </Button>
            </div>
            {questionTwoExpanded && (
              <div>
                {autoCollapseFeedbackForm && (
                  <div className="checkbox-control">
                    <input
                      type="checkbox"
                      id="auto-collapse-checkbox"
                      className="auto-collapse-checkbox"
                      checked={autoCollapseFeedbackForm}
                      onChange={this.toggleAutoCollapseFeedbackFormSetting}
                    />
                    <label htmlFor="auto-collapse-checkbox">
                      Automatically collapse the feedback form after score is
                      submitted
                    </label>
                  </div>
                )}
                <Textarea
                  ref={this.questionTwoRef}
                  defaultValue={questionTwo}
                  placeholder="e.g. Gimme all yer candy"
                />
              </div>
            )}
          </SatisfactionSurveyCard>
        </FieldGroup>
      </Panel>
    );
  }
}

export default styled(SatisfactionContentEdit)`
  &.panel {
    width: 55%;
    justify-content: center;
    flex-direction: column;
  }

  .checkbox-control {
    margin-top: 10px;
    display: flex;
    align-items: center;

    input {
      width: 18px;
      height: 18px;
      background-color: #00b2e5;
    }
  }
`;
