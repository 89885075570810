import React, { useState } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';

import { CPanel, CTab } from '@appcues/component-library';

import { DocumentTitle } from 'next/hooks/use-title';
import EmptyState from 'components/Common/EmptyState';
import PropertiesList from 'components/Settings/PropertiesAndEvents/PropertiesList';

import {
  selectProfileCustomProperties,
  selectProfileAutoProperties,
} from 'selectors/profile-attributes';
import { emptyStatePropsMap } from 'components/Settings/PropertiesAndEvents';

const TABS = {
  AUTO_PROPERTIES: 'auto-properties',
  CUSTOM_PROPERTIES: 'custom-properties',
};

const PARAMS = {
  customProperties: TABS.CUSTOM_PROPERTIES,
  autoProperties: TABS.AUTO_PROPERTIES,
};

export const Properties = ({ autoProperties, customProperties }) => {
  const { tabState } = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const [tabId, setTabId] = useState(
    PARAMS[tabState] || TABS.CUSTOM_PROPERTIES
  );

  const CustomProperties = () => {
    return Object.keys(customProperties).length > 0 ? (
      <CPanel>
        <PropertiesList properties={customProperties} />
      </CPanel>
    ) : (
      <EmptyState {...emptyStatePropsMap.customProperties} />
    );
  };

  const AutoProperties = () => {
    return Object.keys(autoProperties).length > 0 ? (
      <CPanel>
        <PropertiesList properties={autoProperties} isAutoProperties />
      </CPanel>
    ) : (
      <EmptyState {...emptyStatePropsMap.autoProperties} />
    );
  };

  const onChangeTab = id => setTabId(id);

  return (
    <>
      <CTab.Bar value={tabId} onChange={onChangeTab} marginBottom={16}>
        <CTab id={TABS.CUSTOM_PROPERTIES}>Custom properties</CTab>
        <CTab id={TABS.AUTO_PROPERTIES}>Auto-properties</CTab>
      </CTab.Bar>
      {tabId === TABS.CUSTOM_PROPERTIES && <CustomProperties />}
      {tabId === TABS.AUTO_PROPERTIES && <AutoProperties />}
      <DocumentTitle title="Properties | Settings | Appcues" />
    </>
  );
};

function mapStateToProps(state) {
  return {
    autoProperties: selectProfileAutoProperties(state),
    customProperties: selectProfileCustomProperties(state),
  };
}

export default connect(mapStateToProps)(Properties);
