import React from 'react';
import { CButton, H3, P, Flex, CPanel } from '@appcues/component-library';
import { Link } from 'react-router-dom';

import EmpytChartsImage from 'assets/images/empty-charts.svg';

const EmptyCharts = () => (
  <CPanel>
    <Flex
      paddingTop={48}
      paddingRight={54}
      paddingBottom={48}
      paddingLeft={36}
      alignItems="center"
      justifyContent="space-between"
    >
      <div>
        <H3 marginBottom={24}>No saved charts yet</H3>
        <P marginBottom={40}>
          You can create your first saved chart by exploring events.
        </P>
        <Link to="/insights/explorer">
          <CButton type="positive">Explore Events</CButton>
        </Link>
      </div>
      <img src={EmpytChartsImage} alt="No charts saved" />
    </Flex>
  </CPanel>
);

export default EmptyCharts;
