import {
  call,
  getContext,
  put,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';
import { takeEveryPromised, takeLeadingPromised } from 'next/lib/effects';
import { replacePagePatternFor } from 'next/entities/page';
import toast from 'next/lib/toast';

import {
  flush,
  insert,
  patterns,
  prune,
  reject,
  replace,
  resolve,
  pinPublished,
  pinUnpublished,
  PIN_CLONED,
  PIN_ARCHIVED,
  PIN_UNARCHIVED,
  PIN_PUBLISHED,
  PIN_UNPUBLISHED,
  PIN_PUBLISH_REQUESTED,
  PIN_UNPUBLISH_REQUESTED,
  PIN_REVERT_REQUESTED,
  PIN_REVERTED,
  pinReverted,
} from './actions';
import { parse } from './shape';

/**
 * Normalize experiences summary response as collection
 *
 * @param {Response} response - Raw experiences summary response
 * @return {Collection} Experiences collection
 */
const transform = response =>
  response.reduce((acc, { experience }) => {
    acc[experience.id] = parse(experience);
    return acc;
  }, {});

function* fetchPins() {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getPins);
    yield put(resolve(transform(response)));
  } catch (error) {
    yield put(reject(error));
  }
}

function* fetchPin({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    const { experience } = yield call(api.getPin, id);
    yield put(replace(experience));
  } catch (error) {
    yield put(reject(error));
  }
}

function* createPin({ payload }) {
  try {
    const { id: omit, ...pin } = payload;
    const api = yield getContext('api');
    const { experience } = yield call(api.createPin, pin);
    yield put(insert(experience));

    return experience;
  } catch (error) {
    yield put(reject(error));
    throw error;
  }
}

function* updatePin({ payload: { id, delta } }) {
  try {
    const api = yield getContext('api');
    const pin = yield call(api.updatePin, id, delta);
    yield put(flush(id));
    return pin;
  } catch (error) {
    yield put(reject(error));
    throw error;
  }
}

function* deletePin({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    yield call(api.deletePin, id);
    yield put(prune(id));
  } catch (error) {
    yield put(reject(error));
  }
}

function* publishPin({ payload: { id } }) {
  const api = yield getContext('api');
  yield call(api.publishPin, id);
  yield put(pinPublished(id));
}

function* revertPin({ payload: { id, version } }) {
  try {
    const api = yield getContext('api');
    yield call(api.revertPin, id, version);
    yield put(pinReverted(id));
  } catch (error) {
    yield put(reject(error));
  }
}

function* unpublishPin({ payload: { id } }) {
  const api = yield getContext('api');
  yield call(api.unpublishPin, id);
  yield put(pinUnpublished(id));
}

function* clonePin({ payload: { id: baseId } }) {
  try {
    const api = yield getContext('api');
    const { id } = yield call(api.clonePin, baseId);
    /**
     * NOTE: Ideally, we would likely want to use something like `insert` here
     *       using the response from the clone endpoint. However, other
     *       experiences like flows currently just return the ID of the cloned
     *       experience so the only way to retrieve the newly cloned experience
     *       is by trigger a re-read of the data. Since we are currently
     *       listening for `flush` actions to re-trigger that read, that is used
     *       here for now.
     */
    yield put(flush(id));
  } catch (error) {
    yield put(reject(error));
  }
}

function* archivePin({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    yield call(api.archiveExperience, id);
    yield put(flush(id));
  } catch (error) {
    yield put(reject(error));
  }
}

function* unarchivePin({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    yield call(api.unarchiveExperience, id);
    yield put(flush(id));
  } catch (error) {
    yield put(reject(error));
  }
}

function* showToast() {
  yield call(toast.success, 'Changes saved.');
}

export default function* saga() {
  yield takeEvery([patterns.read, patterns.flush], fetchPins);
  yield takeEvery(
    [patterns.readOne, PIN_PUBLISHED, PIN_UNPUBLISHED, PIN_REVERTED],
    fetchPin
  );
  yield takeLeadingPromised(patterns.create, createPin);
  yield takeLeadingPromised(patterns.update, updatePin);
  yield takeLeading(patterns.remove, deletePin);
  yield takeEvery(patterns.flush, showToast);
  yield takeLeading(PIN_CLONED, clonePin);
  yield takeLeading(PIN_ARCHIVED, archivePin);
  yield takeLeading(PIN_UNARCHIVED, unarchivePin);

  yield takeEveryPromised(PIN_PUBLISH_REQUESTED, publishPin);
  yield takeEveryPromised(PIN_UNPUBLISH_REQUESTED, unpublishPin);
  yield takeEveryPromised(PIN_REVERT_REQUESTED, revertPin);

  // Page actions
  yield takeLeading(
    [
      replacePagePatternFor('/pins/:pinId/settings'),
      replacePagePatternFor('/banners/:experienceId/settings'),
      replacePagePatternFor('/pins/:pinId/analytics'),
    ],
    fetchPin
  );
  yield takeLeading([replacePagePatternFor('/pins')], fetchPins);
}
