import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CPage, CRow } from '@appcues/component-library';
import styled from 'styled-components';
import isEqual from 'lodash.isequal';
import useDateRangeQuery from 'next/hooks/use-date-range-query';
import useQueryString from 'next/hooks/use-query-string';
import useToggle from 'next/hooks/use-toggle';
import TimestampRefresh from 'next/components/TimestampRefresh';
import BoundedDateRangeDropdown from 'next/components/BoundedDateRangeDropdown';
import useTitle from 'next/hooks/use-title';
import { asPromised } from 'utils/as-promised';
import { createChart } from 'actions/account/charts';
import { clear } from 'actions/analytics-v2';
import IndexPage from 'components/Common/IndexPage';
import SelectSegmentDropDown from 'components/Common/SelectSegmentDropDown';
import { selectAccountMetaSynced } from 'reducers/account/meta';
import { selectAccountFeaturesSynced } from 'reducers/account/features';
import { selectDateRangeOptions } from 'selectors/timeframe-options';
import {
  parseEvents,
  serializeEvents,
  validateSelectedEvents,
} from 'components/Insights/utils';
import Loader from 'components/Common/Loader';
import SegmentWarningNotice from 'components/nps/dashboard/SegmentWarningNotice';
import Sources from './Sources';
import DeprecatedChart from './Chart/DeprecatedChart';
import SaveChartModal from './Chart/SaveChartModal';
import CreateEventManager from './CreateEventManager';

// NOTE: Sigh... remove the negative margin in CRow making it impossible to span
//       the full width properly...
const PageContainer = styled(CPage.Container)`
  ${CRow} {
    margin-left: 0;
    margin-right: 0;
  }
`;

const StyledCRow = styled(CRow)`
  align-items: center;

  > div {
    padding-right: 1em;
  }
`;

export const InsightsExplorer = ({
  disableSegmentFilter = false,
  options,
  onRefresh,
  onSave,
}) => {
  useTitle('Events Explorer | Appcues');
  const [updated, setUpdated] = useState(Date.now());

  const [{ range, start, end }, setDateRange] = useDateRangeQuery(options, {
    range: 7,
  });

  const [selectedEvents, setSelectedEvents] = useQueryString('ev', [], {
    parse: parseEvents,
    serialize: serializeEvents,
    validate: validateSelectedEvents,
  });

  const [chartType, setChartType] = useQueryString('ct', 'line', {
    validate: val => ['line', 'column'].includes(val),
  });

  const [isSaveModalShowing, toggleSaveModal] = useToggle();

  const [segmentId, setSegmentId] = useQueryString('si', null);

  const handleSelectEvent = event => {
    const matchingEvent = (selectedEvents || []).find(selectedEvent =>
      isEqual(selectedEvent, event)
    );
    if (matchingEvent) {
      setSelectedEvents(current => current.filter(it => it !== matchingEvent));
    } else {
      setSelectedEvents(current => [...current, event]);
    }
  };

  const handleClearEvents = source => {
    setSelectedEvents(current =>
      current.filter(event => event.source !== source)
    );
  };

  const startTime = start.getTime();
  const endTime = end.getTime();

  const handleRefresh = () => {
    setUpdated(Date.now());
    onRefresh();
  };

  const handleSaveChart = name => {
    // FIXME: Current and previous quarter will be saved as fixed dates since
    //        trailing days expects a number of days and we have no way to store
    //        other types of ranges
    const dates =
      typeof range === 'number'
        ? { mode: 'TRAILING', trailingDays: range }
        : { mode: 'FIXED', startTime: start, endTime: end };

    return onSave({
      name,
      segmentId,
      events: selectedEvents,
      chartType,
      ...dates,
    });
  };

  return (
    <IndexPage
      title="Events Explorer"
      subHeaderActions={<CreateEventManager />}
    >
      {isSaveModalShowing && (
        <SaveChartModal onClose={toggleSaveModal} onSubmit={handleSaveChart} />
      )}
      <PageContainer>
        <StyledCRow marginBottom="1em">
          <BoundedDateRangeDropdown
            value={{ range, start, end }}
            options={options}
            onApply={setDateRange}
          />

          {!disableSegmentFilter && (
            <SelectSegmentDropDown
              value={segmentId}
              onChange={setSegmentId}
              isClearable
            />
          )}
          <TimestampRefresh endTime={updated} onClick={handleRefresh} />
        </StyledCRow>

        <SegmentWarningNotice
          segmentId={segmentId}
          selected
          start={startTime}
        />

        <CRow>
          <DeprecatedChart
            startTime={startTime}
            endTime={endTime}
            segmentId={segmentId}
            selectedEvents={selectedEvents}
            chartType={chartType}
            onSelectEvent={handleSelectEvent}
            onSelectChartType={setChartType}
            onSaveChart={toggleSaveModal}
          />
        </CRow>
        <CRow>
          <Sources
            startTime={startTime}
            endTime={endTime}
            segmentId={segmentId}
            selectedEvents={selectedEvents}
            onSelectEvent={handleSelectEvent}
            onClearEvents={handleClearEvents}
          />
        </CRow>
      </PageContainer>
    </IndexPage>
  );
};

InsightsExplorer.propTypes = {
  disableSegmentFilter: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  onRefresh: PropTypes.func,
  onSave: PropTypes.func,
};

export const SyncedInsightsExplorer = ({
  disableSegmentFilter,
  onRefresh,
  onSave,
  options = [],
  synced,
}) => {
  if (!synced) {
    return <Loader />;
  }

  return (
    <InsightsExplorer
      disableSegmentFilter={disableSegmentFilter}
      onRefresh={onRefresh}
      onSave={onSave}
      options={options}
    />
  );
};

SyncedInsightsExplorer.propTypes = {
  ...InsightsExplorer.propTypes,
  synced: PropTypes.bool,
};

const mapStateToProps = state => ({
  options: selectDateRangeOptions(state),
  synced: selectAccountMetaSynced(state) && selectAccountFeaturesSynced(state),
});

const mapDispatchToProps = dispatch => ({
  onRefresh: () => dispatch(clear()),
  onSave: chartData => asPromised(dispatch, createChart(chartData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyncedInsightsExplorer);
