import { buffers, channel } from 'redux-saga';
import {
  delay,
  take,
  fork,
  actionChannel,
  put,
  race,
} from 'redux-saga/effects';

/**
 * Certain sagas, namely FLUSH sagas, shouldn't always fire (ex. they shouldn't
 * continually fire while a user is typing).  Therefore in some situations, we'll
 * actually invalidate a saga if another matching one fires before some
 * number of milliseconds.
 *
 * This should prevent "old" flushes from syncing back and wreaking havoc
 * on users typing on the settings page/elsewhere.
 *
 * @param saga      The saga to be auto-cancelled
 * @param ms        How long should we wait before we're no longer eligible for cancel?
 * @param pattern   Which event will cancel us?
 * @param args
 */

export function* intelligentAutoCancel(saga, ms, pattern, ...args) {
  const { response } = yield race({
    response: delay(ms),
    cancel: take(pattern),
  });

  if (response) {
    yield fork(saga, ...args);
  }
}

function* startThrottling(throttleChannel, ms, saga, ...args) {
  while (true) {
    const action = yield take(throttleChannel);
    yield fork(intelligentAutoCancel, saga, ms, action.type, ...args, action);
    yield delay(ms);
  }
}

/**
 * Acts like redux saga's throttle helper except that it allows you to specify a
 * property name of the action's payload to "group by". For example, if we call
 *
 *   yield throttle(500, 'ACTION_NAME', testSaga);
 *
 * The the following sequence will result in dropped actions:
 *   yield put({ type: 'ACTION_NAME', payload: { type: 'cat1' } });
 *   yield put({ type: 'ACTION_NAME', payload: { type: 'cat2' } });
 *   yield put({ type: 'ACTION_NAME', payload: { type: 'cat3' } });
 *
 * Using throttleBy you can specify to throttle the ACTION_NAME by the `type`
 * property, which means that `testSaga` would be called once every 500ms for
 * each incoming ACTION_NAME action with a different type value. Because it's
 * a saga and not an actual redux saga side effect object, it should be called
 * with a fork effect:
 *
 *   yield fork(throttleBy, 'type', 500, 'ACTION_NAME', testSaga);
 *
 * @param {String} propertyName            The property name to group actions by.
 * @param {Number} ms                      The throttling period.
 * @param {String|Array|Function} pattern  Same pattern values that can be passed
 *                                         to the normal `throttle`
 * @param {Function} saga                  The saga to run for the action.
 * @param {[type]} args...                 Additional args to call the saga with.
 */
export function* throttleBy(propertyName, ms, pattern, saga, ...args) {
  // Maintain a list of existing property-value-specific channels that we use
  // to distribute actions based on the value of `propertyName` in the payload.
  const channels = {};

  // Take all of the actions matching the pattern and DON'T DROP ANY (using an
  // expanding buffer).
  const masterChannel = yield actionChannel(pattern, buffers.expanding(10));
  while (true) {
    const action = yield take(masterChannel);

    // For each action that comes in, we should check the value of the prop
    // indicated by `propertyName`. If we have an existing channel for it,
    // then put the action on the channel. If we don't, create a new channel
    // with a sliding buffer of size 1 (so we only keep the latest action)
    // and start a helper saga that TAKEs actions off that channel and calls
    // the specified saga at most every `ms` milliseconds.
    const value = action.payload[propertyName];
    if (value) {
      let chan = channels[value];
      if (!chan) {
        chan = channel(buffers.sliding(1));
        channels[value] = chan;
        yield fork(startThrottling, chan, ms, saga, ...args);
      }
      // Always put the action on the channel.
      yield put(chan, action);
    }
  }
}
