import styled from 'styled-components';
import { Table, Th, Td } from '@studio/legacy-components';
import { Text, Row } from '@appcues/sonar';

export const UITable = styled(Table)`
  ${Th}:nth-child(1), ${Td}:nth-child(1) {
    width: 262px;
  }

  ${Th}:nth-child(2), ${Td}:nth-child(2) {
    max-width: 218px;
  }

  ${Th}:nth-child(3), ${Td}:nth-child(3) {
    max-width: 259px;
  }

  ${Th}:nth-child(4), ${Td}:nth-child(4) {
    width: 209px;
  }

  ${Td}:nth-child(2), ${Td}:nth-child(3) {
    white-space: unset;
    word-break: break-word;
  }
`;

export const LoadingWrapper = styled(Row).attrs({
  xAlign: 'center',
  yAlign: 'center',
})`
  min-height: 400px;
`;

export const TruncatedText = styled(Text)`
  white-space: nowrap;
  max-width: 50ch;
  overflow: hidden;
  text-overflow: ellipsis;

  // Needed for cases where wrapper text has internal elements like span, link, etc.
  * {
    white-space: nowrap;
    max-width: 50ch;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const NoResultsMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: var(--spacing-regular);
  padding: var(--spacing-regular);
`;
