import * as actionTypes from 'constants/actionTypes';

export const cancelSubscription = (stripeId, subscriptionId) => {
  return {
    type: actionTypes.CANCEL_SUBSCRIPTION,
    payload: {
      stripeId,
      subscriptionId,
    },
  };
};

export const trackCancelSubscription = (fields, timestamp) => {
  return {
    type: actionTypes.TRACK_CANCEL_SUBSCRIPTION,
    payload: {
      fields,
      timestamp,
    },
  };
};

export const setPaymentFailed = message => {
  return {
    type: actionTypes.PAYMENT_FAILED,
    payload: {
      message,
    },
  };
};

export const setPaymentSuccess = () => {
  return {
    type: actionTypes.PAYMENT_SUCCESS,
  };
};
