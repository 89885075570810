import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PrismaticMessageEvent } from '@prismatic-io/embedded';
import toast from 'next/lib/toast';
import { selectAccountId } from 'reducers/account/meta';
import prismaticClient, { isPrismaticEnabled } from 'clients/prismatic-api';
import { PremiumIntegrationCard } from './PremiumIntegrationCard';

const PremiumSecition = ({ premiumIntegrations, accountId }) => {
  const [isLoadingPrismatic, setIsLoadingPrismatic] = useState(false);
  const [prismaticIntegrations, setPrismaticIntegrations] = useState(null);

  const loadIntegrations = useCallback(() => {
    setIsLoadingPrismatic(true);
    if (!accountId) return;
    prismaticClient
      .getIntegrations(accountId)
      .then(({ data }) => {
        setPrismaticIntegrations(data);
      })
      .catch(() => {
        toast.error('Failed to load integrations.');
      })
      .finally(() => {
        setIsLoadingPrismatic(false);
      });
  }, [accountId]);

  const subscribeToPrismaticEvents = useCallback(() => {
    if (!accountId) return;

    window.addEventListener('message', event => {
      if (
        event.data.event === PrismaticMessageEvent.INSTANCE_CREATED ||
        event.data.event === PrismaticMessageEvent.INSTANCE_DELETED ||
        event.data.event ===
          PrismaticMessageEvent.INSTANCE_CONFIGURATION_CLOSED ||
        event.data.event === PrismaticMessageEvent.INSTANCE_DEPLOYED
      ) {
        loadIntegrations(accountId);
      }
    });
  }, [accountId]);

  useEffect(loadIntegrations, [accountId]);

  useEffect(() => {
    if (!accountId) return () => {};

    subscribeToPrismaticEvents();
    return () => {
      window.removeEventListener('message', subscribeToPrismaticEvents);
    };
  }, [accountId]);

  return (
    <>
      {premiumIntegrations.map(integration => {
        const isEnabled =
          prismaticIntegrations &&
          isPrismaticEnabled(integration.id, prismaticIntegrations);

        return (
          <PremiumIntegrationCard
            integration={integration}
            isEnabled={isEnabled}
            isLoadingPrismatic={isLoadingPrismatic}
          />
        );
      })}
    </>
  );
};

const mapStateToProps = state => {
  return {
    accountId: selectAccountId(state),
  };
};

export const PremiumIntegrationSection =
  connect(mapStateToProps)(PremiumSecition);
