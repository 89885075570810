import { createCollectionReducer } from './account/collections';

export const TYPE = 'newBilling';

export default createCollectionReducer(TYPE);

export const selectBillingData = state => state.newBilling;

export const selectPaymentTaxes = state => state.newBilling.taxes;

export const selectLoadingTaxes = state => state.newBilling.loading;

export const selectClientSecret = state => state.newBilling.clientSecret;

export const selectUpcomingInvoice = state => state.newBilling.upcomingInvoice;

export const selectInvoicePreview = state => state.newBilling.invoicePreview;

export const selectPaymentInfo = state => state.newBilling.paymentInfo;
