import React from 'react';
import { H4, P } from '@appcues/component-library';

const Text = ({ children }) => <P light>{children}</P>;
const SubHeader = ({ children }) => (
  <H4 type="tertiary" textTransform="uppercase">
    {children}
  </H4>
);

export const ExplorerDescription = () => (
  <>
    <Text>
      Explorers are new to your product and need to be convinced of the value.
      They might have started a free trial, or gone through a demo with your
      team. Focus on telling a great story about why your product is worth
      using, and bring them to an &lsquo;aha&rsquo; moment.
    </Text>
    <SubHeader>How To Define</SubHeader>
    <Text>
      Target brand new users who haven&apos;t yet done key behaviors indicating
      activation. You might use the date they signed up, or other events and
      property values indicating they are new.
    </Text>
  </>
);

export const BeginnerDescription = () => (
  <>
    <Text>
      Beginners are convinced. They get how your product can solve their needs —
      and they&apos;re excited to make it happen! Focus on helping them reach
      the value you promised, and teach them skills and concepts to ensure
      they&apos;re successful.
    </Text>
    <SubHeader>How To Define</SubHeader>
    <Text>
      Select users who have reached activation — an event or behavior showing
      commitment and interest in taking real action in your product. You might
      exclude users who haven&apos;t yet formed a habit or reached consistent
      value.
    </Text>
  </>
);

export const RegularsDescription = () => (
  <>
    <Text>
      Regulars have formed a habit and are using your product frequently. Keep
      them engaged with announcements of product improvements, ensure they
      discover all the value you have to offer, and make on-demand learning
      available.
    </Text>
    <SubHeader>How To Define</SubHeader>
    <Text>
      Target users who are showing signs of frequent usage, engagement with key
      features, or who indicate they&apos;ve reached a steady state of value.
      You may want to send new properties or events for key feature usage or
      frequency.
    </Text>
  </>
);

export const ChampionsDescription = () => (
  <>
    <Text>
      Champions are your advocates. They&apos;re out there telling the world
      about your product because they love it. This is the dream. Once here,
      encourage them to be public in their support (referrals are key to healthy
      growth). Give them opportunities to both deepen their knowledge, and share
      theirs with you.
    </Text>
    <SubHeader>How To Define</SubHeader>
    <Text>
      Select users who are telling you that they&apos;re fans and engaging with
      your product frequently. They might be users with a high score on an NPS
      customer satisfaction survey, or just loyal users logging in every single
      day.
    </Text>
  </>
);
