import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Icon } from '@studio/legacy-components';
import { getThemeFor } from 'utils';

export const Thumbnail = styled.div`
  display: flex;
  flex-direction: column;
  align-content: middle;
  justify-content: center;
  text-align: center;
  max-width: 200px;
  margin-top: 10px;

  ${Icon} {
    color: var(--white);
  }

  .thumbnail-caption {
    margin-top: 10px;
  }
  .thumbnail-figure {
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    width: 150px;
    height: 150px;
    flex-direction: column;
    text-align: center;
  }
  .thumbnail-figure-icon-wrapper {
    display: block;
    position: relative;
    background-color: ${getThemeFor('$dark-tint-70')};
    padding: 10px;
    border-radius: 6px;
    top: calc(50% - 20px);
    text-align: center;
    margin: 0 auto;
    width: 40px;
  }
  .thumbnail-figure-overlay {
    cursor: pointer;
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    backdrop-filter: blur(3px);
    background-color: ${getThemeFor('$dark-tint-70')};
  }
  .image-service-image {
    max-width: 100%;
    max-height: 100%;
  }
  &:focus,
  &:hover {
    .thumbnail-figure-overlay {
      display: block;
      opacity: 1;
    }
  }
`;

const PreviewThumbnail = ({ children, stepIndex, href, onClick }) => {
  return (
    <Thumbnail data-testid="preview-thumbnail" title="Edit Step">
      <figure className="thumbnail-figure">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          data-testid="preview-thumbnail-link"
          data-href={href}
          tabIndex="0"
          role="link"
          className="thumbnail-figure-overlay"
          onClick={onClick}
        >
          <div className="thumbnail-figure-icon-wrapper">
            <Icon icon="pencil-alt" />
          </div>
        </div>
        {children}
      </figure>
      <figcaption
        className="thumbnail-caption"
        data-testid="preview-thumbnail-caption"
      >
        Step {stepIndex + 1}
      </figcaption>
    </Thumbnail>
  );
};

PreviewThumbnail.propTypes = {
  children: PropTypes.any,
  stepIndex: PropTypes.number,
  href: PropTypes.string,
};

export default PreviewThumbnail;
