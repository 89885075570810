import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Icon, Link, Td, Tr } from '@studio/legacy-components';
import { encodeEventNameUrl } from 'components/Insights/utils';
import EventToggle from 'components/Insights/Explorer/Common/EventToggle';
import { SelectColumn, NameColumn } from 'components/Insights/Common/Styled';

const EnabledIcon = styled(Icon).attrs(() => ({
  icon: 'check-circle',
}))`
  color: var(--status-active);
`;

const DisabledIcon = styled(Icon).attrs(() => ({
  icon: 'minus-circle',
}))`
  color: var(--status-inactive);
`;

const BuilderEventRow = ({
  name = '',
  status,
  events,
  users,
  timestamp,
  disabled = false,
  checked = false,
  onSelect = () => null,
}) => {
  const eventName = name.replace(/appcues_custom:/, '');
  const { search } = useLocation();

  return (
    <Tr>
      <SelectColumn>
        <EventToggle
          label={`Select builder event: ${eventName}`}
          checked={checked}
          disabled={disabled}
          onClick={onSelect}
        />
      </SelectColumn>
      <NameColumn>
        <Link
          to={{
            pathname: `/insights/explorer/builder/${encodeURI(
              encodeEventNameUrl(name)
            )}`,
            search,
          }}
          title={`${eventName} detail page`}
        >
          {eventName}
        </Link>
      </NameColumn>
      <Td>
        {status ? (
          <>
            <EnabledIcon /> Enabled
          </>
        ) : (
          <>
            <DisabledIcon /> Disabled
          </>
        )}
      </Td>
      <Td>{events ? events.toLocaleString() : '-'}</Td>
      <Td>{users ? users.toLocaleString() : '-'}</Td>
      <Td>{timestamp ? moment(timestamp).format('lll') : '-'}</Td>
    </Tr>
  );
};

BuilderEventRow.propTypes = {
  name: PropTypes.string,
  status: PropTypes.bool,
  events: PropTypes.number,
  users: PropTypes.number,
  timestamp: PropTypes.number,
  enabled: PropTypes.bool,
  checked: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default BuilderEventRow;
