import { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { checkDomainPermission } from 'next/lib/crx';
import { getChromeExtensionVersion } from 'utils/chromeExtension';

/**
 * Helper function to make sure the current crx version
 * it greater or equal 6
 * @param {string} version
 * @returns {boolean}
 */
const isNewExtension = version => {
  if (!version) return false;

  const majorVersion = Number.parseInt(version.split('.')[0], 10);

  return majorVersion >= 6;
};

export const RenderWithoutDomainPermission = ({
  children,
  onNavigate,
  visible = true,
  onClose,
}) => {
  const [hasDomainPermission, setHasDomainPermission] = useState(true);

  useLayoutEffect(() => {
    (async () => {
      try {
        /* global CRX_ID */
        const crxVersion = await getChromeExtensionVersion(CRX_ID);

        if (!isNewExtension(crxVersion)) {
          setHasDomainPermission(false);
          return;
        }

        const hasPermission = await checkDomainPermission();

        if (hasPermission && visible) {
          onNavigate();
          onClose();
        }

        setHasDomainPermission(hasPermission);
      } catch {
        setHasDomainPermission(false);
      }
    })();
  }, [onNavigate, visible]);

  if (!hasDomainPermission) {
    return children;
  }

  return null;
};

RenderWithoutDomainPermission.propTypes = {
  children: PropTypes.node,
  onNavigate: PropTypes.func,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default RenderWithoutDomainPermission;
