import { useEffect } from 'react';
import PropTypes from 'prop-types';

function useTitle(title = 'Appcues') {
  useEffect(() => {
    document.title = title;

    return () => {
      document.title = 'Appcues';
    };
  }, [title]);
}

export default useTitle;

export function DocumentTitle({ title }) {
  useTitle(title);

  return null;
}

DocumentTitle.propTypes = {
  title: PropTypes.string,
};
