import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CTextInput } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';

const SearchBar = styled.div`
  position: relative;
  input {
    padding: 7px 2.5em;
    &::placeholder {
      text-transform: capitalize;
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1em;
  justify-content: space-between;
  pointer-events: none;
  * {
    pointer-events: all;
  }
  ${Icon} {
    color: var(--text-secondary);
  }
`;

const StyledInput = styled(CTextInput)`
  ${({ isLightTheme }) =>
    isLightTheme &&
    `
    border: 1px solid #D6DBE7;
    background: #ffffff;
  `}
`;

const EventSearchBar = ({ placeholder, onChange, isLightTheme }) => {
  const [value, setValue] = useState('');

  const handleKeyDown = ({ key }) => {
    if (key === 'Enter') {
      onChange(value);
    }
  };

  const handleClear = () => {
    setValue('');
    onChange('');
  };

  return (
    <SearchBar>
      <Overlay>
        <Icon icon="search" title="Search events" />
        {value.length > 0 && (
          <Icon
            icon="times"
            pointerEvents="auto"
            cursor="pointer"
            title="Clear search"
            onClick={handleClear}
          />
        )}
      </Overlay>
      <StyledInput
        value={value}
        placeholder={placeholder}
        onChange={setValue}
        onKeyDown={handleKeyDown}
        isLightTheme={isLightTheme}
      />
    </SearchBar>
  );
};

EventSearchBar.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  isLightTheme: PropTypes.bool,
};

EventSearchBar.defaultProps = {
  isLightTheme: false,
};

export default EventSearchBar;
