import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ImageSkeleton } from '@studio/legacy-components';

import {
  Shape as ScreenshotShape,
  ExperienceScreenshotShape,
} from 'next/entities/screenshots';

/**
 * NOTE: It might be worth considering keeping all of the fetching logic in this
 *       component without going through Redux. Given the screenshot information
 *       isn't something that necessarily needs to be globally accessible, we
 *       can likely create some Atom pattern or utilize Context to keep the
 *       image fetching/caching/management localized which may in turn improve
 *       performance as well.
 */

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const Image = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

const Loading = styled(ImageSkeleton)`
  height: 100%;
  width: 100%;
`;

export const Screenshot = ({
  className,
  force = false,
  onLoad,
  url,
  step,
  deferred,
  locale,
  ...restProps
}) => {
  useEffect(() => {
    const generate = step && (!url || force);
    if (generate && !deferred) {
      onLoad({ force, step, locale, ...restProps });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [force, onLoad, Boolean(step), deferred, ...Object.values(restProps)]);

  return (
    <Wrapper className={className}>
      {url ? <Image src={url} /> : <Loading />}
    </Wrapper>
  );
};

Screenshot.propTypes = {
  className: PropTypes.string,
  force: PropTypes.bool,
  onLoad: PropTypes.func,
  url: PropTypes.string,
  step: PropTypes.oneOfType([ScreenshotShape, ExperienceScreenshotShape]),
  deferred: PropTypes.bool,
  locale: PropTypes.string,
};
