import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { H3, Panel, PanelBody } from '@studio/legacy-components';
import { ChartWrapper } from 'components/Insights/Explorer/Chart';
import NpsChart from './NpsChart';

const Title = styled(H3)`
  margin-bottom: 64px;
`;

const ScoreOverTime = ({ startTime, endTime, segmentId, npsId }) => {
  return (
    <Panel>
      <PanelBody>
        <Title>NPS score history</Title>
        <ChartWrapper>
          <NpsChart
            npsId={npsId}
            startTime={startTime}
            endTime={endTime}
            segmentId={segmentId}
          />
        </ChartWrapper>
      </PanelBody>
    </Panel>
  );
};

ScoreOverTime.propTypes = {
  startTime: PropTypes.number,
  endTime: PropTypes.number,
  npsId: PropTypes.string,
  segmentId: PropTypes.string,
};

export default ScoreOverTime;
