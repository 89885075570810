import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';

const CoolTip = props => {
  const {
    className,
    position,
    wrap,
    width,
    containerWidth,
    children,
    icon,
    tip,
    disabled,
    ...boxProps
  } = props;

  return (
    <Wrapper
      className={className}
      containerWidth={containerWidth}
      disabled={disabled}
    >
      {children || icon}
      <Tip
        wrap={wrap}
        width={width}
        className={`tooltip-tip tooltip-pos-${position}`}
        {...boxProps}
      >
        {tip}
      </Tip>
    </Wrapper>
  );
};

export default CoolTip;

CoolTip.propTypes = {
  position: PropTypes.string,
  wrap: PropTypes.string,
  width: PropTypes.string,
  containerWidth: PropTypes.string,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

CoolTip.defaultProps = {
  position: 'top',
  wrap: 'pre-wrap',
  width: '200px',
  icon: <Icon icon="info-circle" />,
  disabled: false,
};

const Wrapper = styled.span`
  display: inline-block;
  position: relative;
  ${({ containerWidth }) =>
    containerWidth ? `width: ${containerWidth}` : null}
  /* Depending, you may need to include the following in the specific Component using the tooltip:
  position: absolute;
  z-index: 1; */
  &:hover .tooltip-tip {
    display: ${props => (props.disabled ? 'none' : 'block')};
    cursor: pointer;
  }
`;

const Tip = styled(Box)`
  background: rgba(8, 23, 49, 0.9);
  border-radius: ${props => props.theme['$border-radius']};
  color: #fff;
  display: none;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  padding: 10px 16px;
  position: absolute;
  text-align: center;
  text-transform: none;
  white-space: ${props => props.wrap};
  width: ${props => props.width};
  z-index: 1000;
  box-sizing: content-box !important;

  &.tooltip-pos-top {
    /* arrow */
    &:after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-top-color: rgba(8, 23, 49, 0.9);
      border-width: 10px;
      margin-left: -10px;
    }
    /* position */
    bottom: calc(100% + 13px);
    right: calc(-100px + 25%);
  }
  &.tooltip-pos-bottom {
    /* arrow */
    &:after {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: rgba(8, 23, 49, 0.9);
      border-width: 10px;
      margin-left: -10px;
    }
    /* position */
    top: calc(100% + 13px);
    right: calc(-97px + 25%);
  }
  &.tooltip-pos-left {
    /* arrow */
    &:after {
      top: 50%;
      transform: translateY(-50%);
      left: 105%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-left-color: rgba(8, 23, 49, 0.9);
      border-width: 10px;
      margin-left: -10px;
    }
    /* position */
    top: 50%;
    right: calc(100% + 13px);
    transform: translateY(-50%);
  }
  &.tooltip-pos-right {
    /* arrow */
    &:after {
      top: 50%;
      transform: translateY(-50%);
      right: 100%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-right-color: rgba(8, 23, 49, 0.9);
      border-width: 10px;
      margin-left: -10px;
    }
    /* position */
    top: 50%;
    left: calc(100% + 13px);
    transform: translateY(-50%);
  }
`;
