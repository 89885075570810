import { createSelector } from 'reselect';
import { createCollectionReducer } from 'reducers/account/collections';
import { selectIsSynced } from 'reducers/account/requests';

const TYPE = 'segments';

export default createCollectionReducer(TYPE);

export const selectAccountSegments = state => state.account.segments;
export const selectAccountSegment = (state, segmentId) =>
  selectAccountSegments(state)[segmentId];
export const selectAccountSegmentsSynced = state => selectIsSynced(state, TYPE);
export const selectLifecycleSegment = (state, userLifecycleStage) =>
  Object.values(selectAccountSegments(state)).find(
    ({ meta }) => meta && meta.userLifecycleStage === userLifecycleStage
  );

export const selectAccountSegmentsDigest = createSelector(
  [selectAccountSegments],
  segments => {
    return Object.keys(segments || {}).reduce((acc, segmentId) => {
      const { id, name, meta = {} } = segments[segmentId];
      acc[segmentId] = {
        id,
        name,
        meta,
      };
      return acc;
    }, {});
  }
);
export const selectSegmentTargetingOptions = state =>
  Object.values(selectAccountSegments(state)).map(({ id, name }) => ({
    label: name || id,
    value: id,
  }));
