import { createCollectionActions } from 'next/lib/collections';

export const TYPE = 'versionedExperiences';

export const { patterns, reject, replace } = createCollectionActions(TYPE);

export const READ_ONE_VERSION = `@versionedExperiences/READ_ONE_VERSION`;
export const readOneVersion = (id, version) => ({
  type: READ_ONE_VERSION,
  payload: { id, version },
});
