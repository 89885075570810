import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseModal from 'components/ModalContainer/BaseModal';
import {
  getConfirmActionMessageForType,
  progressiveVerbs,
} from 'constants/modalMessages';
import { modalStatuses, PUBLISH_ACTIONS } from 'constants/modalStatuses';

const Modal = styled(BaseModal)`
  .confirm-message {
    text-align: center;
  }
`;

export default function PublishingModal(props) {
  const {
    accountApiKey,
    accountId,
    currentContent,
    onAction,
    publishActionType,
    publishToApi,
    renderPrompt,
    stepId,
    userId,
  } = props;

  const [requestState, setRequestState] = useState(modalStatuses.PROMPT);

  const handleOk = () => {
    const data = {
      account_id: accountId,
      account_api_key: accountApiKey,
      content_id: stepId,
      content_type: currentContent.type,
      user_id: userId,
    };

    if (publishActionType === PUBLISH_ACTIONS.REVERT) {
      data.to_version_id =
        currentContent.type === 'checklist'
          ? currentContent.updatedAt
          : currentContent.publishedAt;
    }

    setRequestState(modalStatuses.PENDING);
    return publishToApi(publishActionType, data)
      .then(response => {
        onAction(publishActionType, data);
        setRequestState(modalStatuses.SUCCESS);
        return response;
      })
      .catch(() => {
        setRequestState(modalStatuses.ERROR);
        throw new Error('Failed to publish');
      });
  };

  const name =
    currentContent.type === 'checklist'
      ? currentContent.internalName
      : currentContent.name;

  switch (requestState) {
    case modalStatuses.ERROR:
      return (
        <Modal okText="Okay" {...props}>
          <h2 className="confirm-message">{`Error publishing ${name}.\n`}</h2>
          <p>
            Please check your internet connection. If the problem persists,
            contact support.
          </p>
        </Modal>
      );

    case modalStatuses.PENDING:
      return (
        <Modal modalClass="pending-modal" {...props}>
          <h2 className="confirm-message">{`${progressiveVerbs[publishActionType]} ${name}`}</h2>
        </Modal>
      );

    case modalStatuses.PROMPT:
      return (
        <Modal
          okText="Continue"
          modalClass="confirm-modal"
          onClickOK={handleOk}
          {...props}
        >
          {renderPrompt ? (
            renderPrompt(currentContent, publishActionType)
          ) : (
            <h2 className="confirm-message">
              {getConfirmActionMessageForType(
                publishActionType,
                currentContent.type
              )}
            </h2>
          )}
        </Modal>
      );

    default:
      return <div />;
  }
}

PublishingModal.propTypes = {
  publishToApi: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};
