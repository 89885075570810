import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

import { H3, Text, Overflow } from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';
import { format } from 'next/lib/date';

import { fetchUpcomingInvoice } from 'actions/newBilling';
import {
  Plan as PlanShape,
  UpcomingInvoice as UpcomingInvoiceShape,
} from 'components/Subscription/shape';
import { ENTITLEMENTS_CAPTIONS } from 'constants/entitlements';
import { selectUpcomingInvoice } from 'reducers/newBilling';
import { formatCurrency, getIntervalShortVersion } from 'utils/billing';

import {
  CostBreakdownLink,
  CostLineItem,
  CostBreakdownModalWrapper,
  DoneButton,
  HorizontalDivider,
  NextBillingText,
  SubtotalPriceWrapper,
  TaxText,
} from './styled';

export const CostBreakdownModalManager = ({
  plan,
  onLoad,
  upcomingInvoice = {},
}) => {
  const [visible, toggle] = useToggle(false);

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <>
      <CostBreakdownLink onClick={toggle}>Cost breakdown</CostBreakdownLink>

      <CostBreakdownModalWrapper
        visible={visible}
        onClose={toggle}
        key="dialog"
      >
        <H3>Cost breakdown</H3>
        <Overflow>
          <CostLineItem>
            <Text bold>{plan.description}</Text>
            <Text bold>
              {formatCurrency(plan.price, false)}/
              {getIntervalShortVersion(plan.interval)}
            </Text>
          </CostLineItem>
          {upcomingInvoice.items?.map(({ id, quantity, unitPrice }) => (
            <CostLineItem key={`addon-${id}`}>
              <Text>{`+${quantity} ${pluralize(
                ENTITLEMENTS_CAPTIONS[id],
                quantity
              )}`}</Text>
              <Text bold>
                {formatCurrency(unitPrice * quantity, false)}/
                {getIntervalShortVersion(plan.interval)}
              </Text>
            </CostLineItem>
          ))}
          <HorizontalDivider />
          <CostLineItem>
            <Text>Subtotal</Text>
            <SubtotalPriceWrapper>
              <Text bold>
                USD {formatCurrency(upcomingInvoice.subtotal, false)}/
                {getIntervalShortVersion(plan.interval)}
              </Text>
              {upcomingInvoice.total === upcomingInvoice.subtotal && (
                <TaxText>Plus tax</TaxText>
              )}
            </SubtotalPriceWrapper>
          </CostLineItem>
          {upcomingInvoice.subtotal !== upcomingInvoice.total && (
            <CostLineItem>
              <Text>Total minus discounts</Text>
              <SubtotalPriceWrapper>
                <Text bold>
                  {formatCurrency(upcomingInvoice.total)}/
                  {getIntervalShortVersion(plan.interval)}
                </Text>
                <TaxText>Plus tax</TaxText>
              </SubtotalPriceWrapper>
            </CostLineItem>
          )}
        </Overflow>
        <NextBillingText>
          We will bill you {formatCurrency(upcomingInvoice.total)} plus tax at
          the start of your next {plan.interval.replace('yearly', 'annual')}{' '}
          billing period on{' '}
          {format(upcomingInvoice.next_payment_attempt * 1000, 'MM/DD/YYYY')}.
        </NextBillingText>
        <DoneButton onClick={toggle}>Done</DoneButton>
      </CostBreakdownModalWrapper>
    </>
  );
};

CostBreakdownModalManager.propTypes = {
  onClose: PropTypes.func,
  plan: PlanShape,
  upcomingInvoice: UpcomingInvoiceShape,
};

const mapStateToProps = state => {
  return {
    upcomingInvoice: selectUpcomingInvoice(state),
  };
};

const mapDispatchToProps = {
  onLoad: fetchUpcomingInvoice,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CostBreakdownModalManager);
