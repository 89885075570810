import React from 'react';

import { CSkeleton } from '@appcues/component-library';
import { getPercentageStrRoundedEnds } from 'utils/numbers';
import { numberWithAbbrevMillions } from 'utils/textFormatting';

export default function PercentOfUsersDisplay({
  userPercentage,
  totalUsers,
  loaded,
}) {
  const pluralUsersSuffix = totalUsers > 1 ? 's' : '';

  if (!loaded) {
    return <CSkeleton width="8em" height="1em" />;
  }

  if (!totalUsers) {
    return 'users (last 7 days)';
  }

  const { percentLabel, wasRounded } =
    getPercentageStrRoundedEnds(userPercentage);
  return `${
    wasRounded ? '' : '~'
  }${percentLabel}% of ${numberWithAbbrevMillions(
    totalUsers
  )} total user${pluralUsersSuffix} (last 7 days)`;
}
