import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { H3, Text } from '@appcues/component-library';
import { getThemeFor } from 'utils';

/**
 * create consistent transition style for properties
 *
 * @param {...string} properties - properties with transitions
 * @returns {string} comma delimited transitions
 */
const ease = (...properties) =>
  properties.map(property => `${property} 200ms ease-in-out`).join(', ');

const Container = styled.div`
  align-items: center;
  background: ${getThemeFor('$gray-0')};
  border: 1px solid ${getThemeFor('$gray-3')};
  border-radius: ${getThemeFor('$border-radius')};
  box-shadow: none;
  display: flex;
  height: 64px;
  justify-content: space-between;
  margin-bottom: 16px;
  max-width: 36rem;
  padding: 12px 16px;
  transition: ${ease('border', 'box-shadow', 'padding')};
  width: 100%;

  ${({ selected }) =>
    selected &&
    css`
      border-color: ${getThemeFor('$purple')};
      border-width: 2px;
      box-shadow: ${getThemeFor('$box-shadow-2')};
      padding: 11px 15px;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      h3,
      span {
        color: ${getThemeFor('$gray-3')};
      }
    `}

  &:hover {
    border-color: ${getThemeFor('$purple')};
    cursor: pointer;
  }
`;

const AccountSwitcher = ({
  name,
  onClick,
  selected = false,
  disabled = false,
}) => (
  <Container
    data-testid="app-switcher"
    onClick={onClick}
    selected={selected}
    disabled={disabled}
  >
    <H3>{name}</H3>
    {selected && <Text>Currently in use</Text>}
  </Container>
);

AccountSwitcher.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default AccountSwitcher;
