import React from 'react';
import PropTypes from 'prop-types';
import { Table, Spinner, Pagination, SubTitle, Link } from '@appcues/sonar';
import { Shape as EventUserProfilesShape } from 'next/entities/event-user-profiles';
import { PIN_BUTTON_TYPE } from 'next/entities/experiences';
import NoData from 'next/assets/images/no-data.svg';
import { format } from 'next/lib/date';
import { getPropertyDisplayName } from 'next/lib/profile-attributes';

import {
  CapitalizedCell,
  Cell,
  HeaderWrapper,
  LoadingWrapper,
  NoResultsMessage,
  Wrapper,
} from './RecentUsersNew.styled';

const getFormattedTimestamp = (timestamp, type, stepType) => {
  const formattedTimestamp = format(timestamp, 'MMM DD, YYYY [at] h:mmA');
  if (type === 'Pin') {
    const prefix = stepType === PIN_BUTTON_TYPE ? 'Clicked' : 'Opened';
    return `${prefix} ${formattedTimestamp}`;
  }
  return formattedTimestamp;
};

export function RecentUsersNew({
  type,
  recentUsers,
  totalResults,
  userProfileIdentifier,
  loading,
  pageIndex,
  pageSize,
  setPagination,
  ariaLabel,
  sortDirection,
  toggleSortDirection,
}) {
  const hasData = userProfileIdentifier && totalResults > 0;

  return (
    <Wrapper>
      <HeaderWrapper>
        <SubTitle>Recent Users</SubTitle>
      </HeaderWrapper>
      {loading && !hasData && (
        <LoadingWrapper>
          <Spinner aria-label="loading spinner" />
        </LoadingWrapper>
      )}
      {!loading && !hasData && (
        <NoResultsMessage>
          <img src={NoData} alt="No data found" />
          No results, please try different filters.
        </NoResultsMessage>
      )}
      {hasData && (
        <Table.Container>
          <Table.Root aria-label={ariaLabel}>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>
                  {getPropertyDisplayName(userProfileIdentifier)}
                </Table.Th>
                {type === 'Pin' && <Table.Th>Pin Name</Table.Th>}
                <Table.Th
                  sortable
                  sortDirection={sortDirection}
                  onClick={toggleSortDirection}
                >
                  Event Time
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {/* When paginating or changing sort direction show the loading overlay */}
              {loading && <Table.LoadingSpinner />}
              {recentUsers.map(
                ({
                  userId,
                  profile,
                  event: { timestamp, stepName, stepType },
                }) => (
                  <Table.Tr key={userId + timestamp}>
                    <Cell>
                      <Link
                        href={`/audience/users/${encodeURIComponent(
                          userId
                        )}/properties`}
                      >
                        {profile[userProfileIdentifier] ?? '-'}
                      </Link>
                    </Cell>
                    {type === 'Pin' && (
                      <CapitalizedCell>{stepName}</CapitalizedCell>
                    )}
                    <Cell>
                      {getFormattedTimestamp(timestamp, type, stepType)}
                    </Cell>
                  </Table.Tr>
                )
              )}
            </Table.Tbody>
          </Table.Root>

          <Table.Footer>
            <Pagination
              totalResults={totalResults ?? 0}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPagination={setPagination}
              disabled={loading}
            />
          </Table.Footer>
        </Table.Container>
      )}
    </Wrapper>
  );
}

RecentUsersNew.propTypes = {
  recentUsers: EventUserProfilesShape,
  totalResults: PropTypes.number,
  userProfileIdentifier: PropTypes.string,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  setPagination: PropTypes.func,
  ariaLabel: PropTypes.string,
  toggleSortDirection: PropTypes.func,
  sortDirection: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
};
