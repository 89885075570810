import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, CButton, CTable, Caption } from '@appcues/component-library';
import { ExternalLink, Icon, Link } from '@studio/legacy-components';

import Panel from 'components/Common/Panel';
import TranslationManager from './TranslationManager';
import PreviewManager from './PreviewManager';
import DownloadManager from './DownloadManager';
import DelocalizeManager from './DelocalizeManager';

const ConfigureButton = styled(CButton)`
  ${Link} {
    --link-color: var(--pleather);
    --link-font-weight: inherit;
    --link-hover-color: var(--oh-hi-dark);
    --link-text-decoration: none;
    --link-visited-color: var(--pleather);

    ${Icon} {
      color: var(--sharkbait-ooh-la-la);
      margin-left: 0;
      margin-right: 8px;
    }
  }
`;

const LeftButtons = styled.div`
  display: flex;
  gap: 16px;

  ${CButton} {
    width: 100%;
  }
`;

export const LocalizationPanel = ({ flowId, locales }) => {
  const sortedLocales = useMemo(
    () => Object.values(locales).sort((a, b) => (a.name < b.name ? -1 : 1)),
    [locales]
  );

  return (
    <Panel name="Localization">
      <Caption>
        Add languages to support, then download the flow content and upload
        translations. &nbsp;
        <ExternalLink href="https://docs.appcues.com/article/584-localizing-content">
          How does localization work?
        </ExternalLink>
      </Caption>
      <Flex alignItems="center" justifyContent="space-between" marginY="1em">
        <LeftButtons>
          <DownloadManager flowId={flowId} />
          <DelocalizeManager flowId={flowId} />
        </LeftButtons>
        <ConfigureButton>
          <Link to="/settings/localization">
            <Icon icon="cog" />
            Configure languages
          </Link>
        </ConfigureButton>
      </Flex>
      <CTable
        marginTop={10}
        panel
        data={sortedLocales}
        columns={[
          {
            Header: 'Language',
            accessor: it => it.name,
            Cell: ({ name }) => name,
          },
          {
            Header: 'Translation file',
            Cell: function TranslationCell({ id }) {
              return (
                <TranslationManager flowId={flowId} localeId={id} key={id} />
              );
            },
          },
          {
            Header: 'Flow preview',
            Cell: function PreviewCell({ id, name }) {
              return (
                <PreviewManager
                  language={name}
                  flowId={flowId}
                  localeId={id}
                  key={id}
                />
              );
            },
          },
        ]}
      />
    </Panel>
  );
};

LocalizationPanel.propTypes = {
  flowId: PropTypes.string.isRequired,
  locales: PropTypes.objectOf(PropTypes.string),
};

export default LocalizationPanel;
