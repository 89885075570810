import styled from 'styled-components';
import { connect } from 'react-redux';
import { BeaconLink } from '@studio/navigation';
import { selectUser } from 'reducers/user';

const mapStateToProps = state => ({
  user: selectUser(state),
});

export default styled(connect(mapStateToProps)(BeaconLink))``;
