import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CButton, H2, CCheckbox } from '@appcues/component-library';
import { requestDomainPermission, setAccount } from 'next/lib/crx';
import { illustration } from 'next/components/OpenInBuilder';
import useVerifyExtension from 'next/hooks/use-verify-extension';
import { RenderWithoutDomainPermission } from 'next/components/RenderWithoutDomainPermission';
import { selectAccount } from 'reducers/account/meta';
import * as persistence from 'helpers/persistence';
// eslint-disable-next-line no-restricted-imports
import authClient from 'helpers/auth-client';
import { openFlowInBuilder } from 'utils/url';

import Modal from 'components/Common/UI/Modal/Modal';

export const OpenInBuilderModal = ({
  stepGroupId,
  stepChildId,
  onClose,
  url,
  accountId,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const { hasPermission } = useVerifyExtension();

  const handleClose = () => {
    if (isChecked) {
      persistence.setItem('dontShowOpenInBuilderModal', true);
    }
    onClose();
  };

  const handleCheck = () => {
    setIsChecked(!isChecked);
  };

  const handleOpenFlow = () => {
    openFlowInBuilder(stepGroupId, stepChildId, url);
    handleClose();
  };

  const handleNavigation = async () => {
    if (!hasPermission) {
      await requestDomainPermission();
    }

    const jwt = await authClient.getToken();
    setAccount({ accountId, jwt });

    handleOpenFlow();
  };

  const onNavigate = useCallback(() => {
    handleNavigation();
  }, []);

  return (
    <RenderWithoutDomainPermission onNavigate={onNavigate}>
      <Modal onClose={handleClose} type="medium">
        <Modal.Content>
          <IllustrationContainer>
            <Image src={illustration} />
          </IllustrationContainer>
          <TextContainer>
            <H2>Opening Your Page</H2>
            <p>
              When the page loads, remember to&nbsp;
              <strong>
                click the <br />
                Appcues Builder Chrome Extension&nbsp;
              </strong>
              and your flow will open.
            </p>
          </TextContainer>

          <Footer>
            <CButton type="primary" onClick={handleNavigation}>
              Continue
            </CButton>

            <CCheckbox
              label="Don't show again"
              checked={isChecked}
              onClick={handleCheck}
            />
          </Footer>
        </Modal.Content>
      </Modal>
    </RenderWithoutDomainPermission>
  );
};

OpenInBuilderModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  url: PropTypes.string,
  stepGroupId: PropTypes.string,
  stepChildId: PropTypes.string,
  accountId: PropTypes.string,
};

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  label {
    margin-top: 18px !important;
  }
`;

const Image = styled.img`
  width: 160px;
  height: 160px;
`;

const IllustrationContainer = styled.div`
  text-align: center;
  margin: 40px 0px 40px 0px;
`;

const TextContainer = styled.div`
  text-align: center;
  margin: 0px 48px 40px 48px;

  p {
    margin-top: 16px;
  }
`;

const mapStateToProps = state => ({
  accountId: selectAccount(state)?.id,
});

export default connect(mapStateToProps)(OpenInBuilderModal);
