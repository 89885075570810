import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Link, Icon } from '@studio/legacy-components';
import { getLeafNodes } from '@studio/conditions';
import { selectFlow } from 'reducers/account/flows';

/* @todo the next three functions are copied directly from
     components/Priorities/FlowTableRow.  we should try to find
     a way to share them without relying on global utils
 */

const operators = {
  '==': 'equals',
  '!=': "doesn't equal",
  '*': 'contains',
  '!*': "doesn't contain",
  '^': 'starts with',
  '!^': "doesn't start with",
  $: 'ends with',
  '!$': "doesn't end with",
  regex: 'matches',
};

const getConditionsDescription = conditions => {
  const { and, or } = conditions;
  return (and || or)
    .map(clause => {
      if (clause.and || clause.or) {
        return `(${getConditionsDescription(clause)})`;
      }

      const {
        url: { operator, value },
      } = clause;

      if (operator === 'regex' && value === '.*') {
        return 'Any page';
      }

      return `${operators[operator]}: ${value}`;
    })
    .join(` ${and ? 'and' : 'or'} `);
};

const Priority = styled.td`
  padding-right: 6px;
`;

const Details = styled.td``;

const FlowInformation = styled.div`
  float: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 550px;
  margin-right: 6px;
`;

const Name = styled.span``;

const PageTargeting = styled.span`
  :before {
    content: '|';
    margin: 0 4px;
  }
`;

const Row = styled.tr`
  line-height: 2em;
  color: var(--text-secondary);

  ${props => props.$emphasized && `font-weight: var(--bold);`}

  ${Priority}, ${Name} {
    ${props => props.$emphasized && `color: var(--text-primary);`}
  }
`;

export const FlowPrioritiesTableRow = ({
  flowId,
  name,
  sortPriority,
  conditions,
  selected,
}) => {
  const rowRef = useRef(null);

  useEffect(() => {
    if (selected && rowRef.current) {
      const {
        current,
        current: { parentNode, offsetTop },
      } = rowRef;

      const { height: rowHeight } = getComputedStyle(current);
      const { height: parentHeight } = getComputedStyle(parentNode);

      parentNode.scrollTop =
        offsetTop -
        (Number.parseInt(parentHeight, 10) - Number.parseInt(rowHeight, 10)) /
          2;
    }
  }, [sortPriority, selected]);

  const urlConditions = conditions?.and?.find(branch =>
    getLeafNodes(branch).some(leaf => leaf.url)
  );

  const pageTargeting = urlConditions
    ? getConditionsDescription(urlConditions)
    : 'any page';

  return (
    <Row $emphasized={selected} ref={rowRef}>
      <Priority>{sortPriority}</Priority>
      <Details>
        <FlowInformation>
          <Name title={name || flowId}>{name || flowId}</Name>
          <PageTargeting title={pageTargeting}>{pageTargeting}</PageTargeting>
        </FlowInformation>
        <Link
          target="_blank"
          title="Open Flow Settings"
          to={`/flows/${flowId}/settings#flow-priority`}
        >
          <Icon icon="external-link-alt" />
        </Link>
      </Details>
    </Row>
  );
};

FlowPrioritiesTableRow.propTypes = {
  flowId: PropTypes.string,
  name: PropTypes.string,
  sortPriority: PropTypes.number,
  conditions: PropTypes.object,
  selected: PropTypes.bool,
};

const mapStateToProps = (state, { flowId }) => ({
  name: selectFlow(state, flowId)?.name,
});

export default connect(mapStateToProps)(FlowPrioritiesTableRow);
