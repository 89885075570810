/**
 * A promise timeout function to wait for the provided timeout time.
 * @param {number} timeoutMs Timeout in milliseconds.
 * @returns
 */
const waitFor = timeoutMs => {
  return new Promise(resolve => {
    setTimeout(resolve, timeoutMs);
  });
};
/**
 * Takes a function that returns a promise and runs it until it resolves for the desired
 * number of retries, with a delay time between each unsuccessful run.
 * If all runs were unsuccessful, the thrown error is thrown.
 * @param {Function} promiseFunc A function that returns the promise to retry.
 * @param {Object[]} args The arguments to pass to `promiseFunc`.
 * @param {number} retries Number of times to retry.
 * @param {number} timeout Delay between each run.
 * @returns
 */

export const callPromiseWithRetry = async (
  promiseFunc,
  args,
  retries,
  timeout = 500
) => {
  try {
    const resp = await promiseFunc(...args);
    return resp;
  } catch (error) {
    if (retries <= 1) throw error;
    await waitFor(timeout);
    const resp = await callPromiseWithRetry(
      promiseFunc,
      args,
      retries - 1,
      timeout
    );
    return resp;
  }
};
