import { useMemo, useCallback, useState } from 'react';

/**
 * @typedef {Object} Option
 * @property {boolean} enabled - If filter is enabled
 * @property {React.ReactNode} label - Filter name that will render as label
 * @property {function} filterFn - Filter functions to be applied
 */

/**
 * @typedef {Object} Group
 * @property {Array.<Option>} options - Group options
 * @property {string} name - Group name
 */

/**
 * @typedef {Object.<string, Group>} Filters
 */

/**
 * @typedef {Object} FiltersUtils
 * @property {function} handleFilterChange - Handle filter change
 * @property {function} handleFilterClear - Handle filter clear
 * @property {Array.<Array.<function>>} filtersfn - Functions to be applied
 * @property {Filters} filters - Filters updated
 * @property {number} enabledFiltersCount - Number of enabled filters
 */

const cleanOptions = options =>
  Object.entries(options).reduce(
    (optionAcc, [key, option]) => ({
      ...optionAcc,
      [key]: { ...option, enabled: false },
    }),
    {}
  );

/**
 * @name useFilters
 * @function
 * @param {Filters} initialFilters - Default filters
 * @returns {FiltersUtils} Object containing filter utils
 */
export default function useFilters(initialFilters) {
  const [filters, updateFilters] = useState(initialFilters);
  const [enabledFiltersCount, filtersfn] = useMemo(() => {
    const fns = Object.keys(filters).reduce((acc, group) => {
      const filtersFns = Object.values(filters[group].options)
        .filter(option => option.enabled)
        .map(option => option.filterFn);

      const defaultHiddenFns = Object.values(filters[group].options)
        .filter(option => option.defaultHidden && !option.enabled)
        .map(option => item => !option.filterFn(item));

      const groupedFunctions = [
        ...acc,
        [...filtersFns, ...(filtersFns.length === 0 ? defaultHiddenFns : [])],
      ];
      return groupedFunctions.filter(
        groupedFunction => groupedFunction.length > 0
      );
    }, []);

    const count = fns.flat().length;
    return [count, fns];
  }, [filters]);

  const handleFilterChange = (group, option, isEnabled) => {
    updateFilters(currFilters => {
      const newFilters = { ...currFilters };
      newFilters[group].options[option].enabled = isEnabled;
      return newFilters;
    });
  };

  const handleFilterClear = groupKey => {
    updateFilters(currFilters => {
      return groupKey
        ? {
            ...currFilters,
            [groupKey]: {
              ...currFilters[groupKey],
              options: cleanOptions(currFilters[groupKey].options),
            },
          }
        : Object.entries(currFilters).reduce(
            (acc, [group, groupFilters]) => ({
              ...acc,
              [group]: {
                ...groupFilters,
                options: cleanOptions(groupFilters.options),
              },
            }),
            {}
          );
    });
  };

  const filterFunction = useCallback(
    item =>
      enabledFiltersCount === 0 ||
      filtersfn.every(fns => {
        return fns.some(fn => fn(item));
      }),
    [enabledFiltersCount, filtersfn]
  );

  return {
    handleFilterChange,
    handleFilterClear,
    filters,
    updateFilters,
    filterFunction,
  };
}
