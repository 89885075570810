import React from 'react';
import * as yup from 'yup';
import { CBanner, CForm, Text, CButton } from '@appcues/component-library';
import { ExternalLink } from '@studio/legacy-components';
import Modal from 'components/Common/UI/Modal/Modal';

const CUSTOM_FONT_DOCS = 'https://docs.appcues.com/article/53-custom-fonts';

const EditTypekitIdModal = ({ value, onSubmit, onClose }) => {
  return (
    <Modal
      title="Add font"
      components={{ Footer: false }}
      maxHeight="85vh"
      onClose={onClose}
    >
      <CForm
        onSubmit={values => {
          onSubmit(values);
          onClose();
        }}
        initialValues={{
          value,
        }}
        validationSchema={yup.object().shape({
          value: yup.string(),
        })}
      >
        <Modal.Content>
          <CForm.Row marginTop={16}>
            <div>
              <CForm.Field
                label="Adobe Font ID (Formerly Typekit)"
                name="value"
                placeholder="abc1def"
              />
              <CBanner marginBottom={28}>
                <CBanner.Content>
                  <Text>
                    Adobe fonts are only supported in custom CSS. After
                    configuring, you must add it to your CSS using a{' '}
                    <code>font-face</code> declaration. Custom Adobe fonts do
                    not show up in the fonts list.
                    <br />
                    <br />
                    <ExternalLink href={CUSTOM_FONT_DOCS}>
                      Using Adobe fonts guide
                    </ExternalLink>
                  </Text>
                </CBanner.Content>
              </CBanner>
            </div>
          </CForm.Row>
        </Modal.Content>
        <Modal.Footer>
          <CButton onClick={onClose}>Cancel</CButton>
          <CForm.SubmitButton type="positive">Save</CForm.SubmitButton>
        </Modal.Footer>
      </CForm>
    </Modal>
  );
};

export default EditTypekitIdModal;
