import { createLifecycleFor } from 'actions/account/collections';

export const TYPE = 'surveys';

export const {
  patterns,
  read,
  readOne,
  reject,
  resolve,
  update,
  flush,
  replace,
  insert,
  add,
  remove,
  prune,
} = createLifecycleFor(TYPE);

export const FETCH_SURVEYS = 'FETCH_SURVEYS';

export const fetchSurveys = (id, start, end, propertyNames) => ({
  type: FETCH_SURVEYS,
  payload: { id, start, end, propertyNames },
});
