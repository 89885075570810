import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CDialog } from '@appcues/component-library';
import { deleteTranslation } from 'actions/account/translations';

export const DeleteTranslationModal = ({ onSubmit, onClose }) => (
  <CDialog
    title="Delete Translation"
    type="negative"
    onPrimaryAction={onSubmit}
    primaryActionText="Delete"
    onSecondaryAction={onClose}
    secondaryActionText="Cancel"
  >
    Any users who had been targeted for that language will now default to the
    original content of your flow
  </CDialog>
);

DeleteTranslationModal.propTypes = {
  flowId: PropTypes.string,
  translationId: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

DeleteTranslationModal.defaultProps = {
  onSubmit: () => false,
};

const mapDispatchToProps = (dispatch, { flowId, translationId, onClose }) => ({
  onSubmit: () =>
    dispatch(deleteTranslation({ flowId, translationId })) && onClose(),
});

export default connect(null, mapDispatchToProps)(DeleteTranslationModal);
