import { createLifecycleFor } from 'actions/account/collections';
import * as actionTypes from 'constants/actionTypes';

export const { send, resolve, update, reject } =
  createLifecycleFor('newBilling');

export const fetchPaymentTaxes = payload => ({
  type: actionTypes.PAYMENT_TAXES_FETCHED,
  payload,
});

export const createSubscription = payload => ({
  type: actionTypes.SUBSCRIPTION_CREATED,
  payload,
});

export const findOrCreateStripeCustomer = payload => ({
  type: actionTypes.STRIPE_CUSTOMER_FETCHED,
  payload,
});

export const findSubscription = payload => ({
  type: actionTypes.SUBSCRIPTION_FETCHED,
  payload,
});

export const deleteSubscription = payload => ({
  type: actionTypes.SUBSCRIPTION_DELETED,
  payload,
});

export const fetchUpcomingInvoice = () => ({
  type: actionTypes.UPCOMING_INVOICE_FETCHED,
});

export const createInvoicePreview = payload => ({
  type: actionTypes.INVOICE_PREVIEW_CREATED,
  payload,
});

export const createSetupIntent = payload => ({
  type: actionTypes.SETUP_INTENT_CREATED,
  payload,
});

export const fetchPaymentInfo = payload => ({
  type: actionTypes.PAYMENT_INFO_FETCHED,
  payload,
});

export const updatePaymentInfo = payload => ({
  type: actionTypes.PAYMENT_INFO_UPDATED,
  payload,
});
