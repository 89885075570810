import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  H1,
  Page,
  PageActions,
  PageBody,
  PageHeader,
  PageTitle,
} from '@studio/legacy-components';
import { Shape } from '@studio/conditions';
import ExperienceList from 'next/components/ExperienceList';
import { readTags, Shape as TagShape } from 'next/entities/tags';
import { Shape as CreatorShape } from 'next/entities/users';
import { Shape as SegmentShape, readSegments } from 'next/entities/segments';
import {
  readExperienceAssociationsForDomains,
  readCreators,
} from 'next/lib/selectors';
import {
  Shape as ExperienceShape,
  readBannersExperiences,
} from 'next/entities/experiences';
import { readRules } from 'next/entities/rules';
import { Shape as ThemeShape, readThemes } from 'next/entities/themes';
import { asRequest } from 'next/entities/requests';
import { replace as replacePage } from 'next/entities/page';
import { Loading } from 'next/components/Listing';
import { HelpBar } from 'next/components/HelpBar';
import useTitle from 'next/hooks/use-title';
import ExperienceContext from 'next/components/ExperienceContext';
import ExperiencesMoreMenu from 'next/components/ExperiencesMoreMenu';
import CreateBannerManager from './CreateBannerManager';
import Empty from './Empty';

const BannerListingPage = ({
  creators = {},
  domainAssociations = {},
  experiences = {},
  onLoad,
  segments = {},
  tags = {},
  rules = {},
  themes = {},
}) => {
  useTitle('Banners | Appcues');
  useEffect(() => {
    onLoad?.();
  }, [onLoad]);

  const { loading: loadingCreators = true } = creators;
  const { loading: loadingDomainAssociations = true } = domainAssociations;
  const { loading: loadingExperiences = true } = experiences;
  const { loading: loadingSegments = true } = segments;
  const { loading: loadingTags = true } = tags;
  const { loading: loadingRules = true } = rules;
  const { loading: loadingThemes = true } = themes;

  const loading =
    loadingCreators ||
    loadingDomainAssociations ||
    loadingExperiences ||
    loadingSegments ||
    loadingTags ||
    loadingRules ||
    loadingThemes;

  const experienceData = useMemo(
    () => ({
      type: 'banner',
      routePath: '/banners',
    }),
    []
  );

  return (
    <ExperienceContext.Provider value={experienceData}>
      <Page>
        <PageHeader>
          <PageTitle>
            <H1>Banners</H1>
          </PageTitle>

          <PageActions>
            <ExperiencesMoreMenu />
            <CreateBannerManager />
          </PageActions>
        </PageHeader>

        <PageBody>
          <HelpBar section="banners" />
          {loading && <Loading />}

          {!loading && (
            <ExperienceList
              creators={creators.data}
              domainAssociations={domainAssociations.data}
              experiences={experiences.data}
              segments={segments.data}
              tags={tags.data}
              emptyState={<Empty />}
            />
          )}

          {/* TODO: Handle error state */}
        </PageBody>
      </Page>
    </ExperienceContext.Provider>
  );
};

BannerListingPage.propTypes = {
  domainAssociations: asRequest(
    PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
  ),
  experiences: asRequest(PropTypes.objectOf(ExperienceShape)),
  tags: asRequest(PropTypes.objectOf(TagShape)),
  segments: asRequest(PropTypes.objectOf(SegmentShape)),
  creators: asRequest(PropTypes.objectOf(CreatorShape)),
  rules: asRequest(PropTypes.objectOf(Shape)),
  themes: asRequest(PropTypes.objectOf(ThemeShape)),
  onLoad: PropTypes.func,
};

const mapStateToProps = state => ({
  creators: readCreators(state),
  domainAssociations: readExperienceAssociationsForDomains(state, 'banner'),
  rules: readRules(state),
  experiences: readBannersExperiences(state),
  segments: readSegments(state),
  tags: readTags(state),
  themes: readThemes(state),
});

const mapDispatchToProps = (dispatch, { match: { path } }) => ({
  onLoad: () => dispatch(replacePage({ path })),
});

export default connect(mapStateToProps, mapDispatchToProps)(BannerListingPage);
