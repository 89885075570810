import { call, put, getContext, takeEvery } from 'redux-saga/effects';
import toast from 'next/lib/toast';
import { reportError } from 'helpers/error-reporting';
import { patterns, resolve, insert } from './actions';

export function* getAllEventLabels() {
  try {
    const api = yield getContext('api');
    const { data = [] } = yield call(api.getAllEventLabels);
    yield put(
      resolve(
        data.reduce((acc, meta) => {
          acc[meta.id] = meta;
          return acc;
        }, {})
      )
    );
  } catch (error) {
    yield call(reportError, error);
  }
}

export function* createEventLabel(action) {
  try {
    const { payload: delta } = action;
    const api = yield getContext('api');
    const { data: eventLabel } = yield call(api.createEventLabel, delta);
    yield put(insert(eventLabel));
    yield call(toast.success, 'Event label updated successfully');
  } catch (error) {
    yield call(reportError, error);
  }
}

export function* updateEventLabel(action) {
  try {
    const {
      payload: { id, delta },
    } = action;

    const api = yield getContext('api');
    yield call(api.updateEventLabel, id, delta);
    yield call(toast.success, 'Event label updated successfully');
  } catch (error) {
    yield call(reportError, error);
  }
}

export default function* saga() {
  yield takeEvery(patterns.create, createEventLabel);
  yield takeEvery(patterns.update, updateEventLabel);
}
