/* global CRX_ID */

import qs from 'qs';

export function getCurrentPath() {
  return window && window.location && window.location.pathname;
}

export function includesCrxParam(routeProps) {
  return (
    !!/standalone/g.test(window.location.search) ||
    routeProps.match.params.isInCRX
  );
}

export function getFormattedMetaInstalledDomains(installedDomains) {
  const installedDomainsList = Object.keys(installedDomains || {}).map(
    // firebase URL formatting replaces "." in URL with "__".  i.e. google__com becomes google.com
    domain => {
      return domain.replace(/__/g, '.');
    }
  );

  return installedDomainsList;
}

function scrapeQueryParam(param) {
  const query =
    qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
  const value = query[param];

  if (!value) return null;

  delete query[param];
  const queryString = qs.stringify(query);

  window.history.replaceState({}, '', `?${queryString}`);

  return value;
}

export function scrapeSpoofId() {
  return scrapeQueryParam('view_as');
}

export function scrapeAcceptInviteToAccountId() {
  return scrapeQueryParam('acceptInviteToAccountId');
}

export function scrapeLoginToAccountId() {
  return scrapeQueryParam('loginToAccountId');
}

export function openFlowInBuilder(stepGroupId, stepChildId, url) {
  const encodedUrl = encodeURIComponent(url);
  const crxUrl = stepChildId
    ? `chrome-extension://${CRX_ID}/static/open.html?stepGroupId=${stepGroupId}&stepChildId=${stepChildId}&url=${encodedUrl}`
    : `chrome-extension://${CRX_ID}/static/open.html?stepGroupId=${stepGroupId}&url=${encodedUrl}`;
  window.open(crxUrl, '_blank');
}
