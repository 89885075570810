import React from 'react';
import PropTypes from 'prop-types';
import { Table, Thead, Tbody, Tr } from '@studio/legacy-components';

import QueryResults from 'components/Common/QueryResults';
import Pagination, {
  PaginationMessage,
} from 'components/Common/UI/Pagination/Pagination';
import { APPLICATION_EVENTS_LABEL } from 'components/Insights/constants';
import TableOverlay from 'components/Insights/Explorer/Common/TableOverlay';
import {
  AddEventTooltip,
  TitleTooltip,
} from 'components/Insights/Explorer/Common/SourceTooltips';
import useClientsideTable from 'hooks/useClientsideTable';
import {
  ResultsWrapper,
  SelectHeader,
  TableWrapper,
  ControlHeader,
  ClearEvents,
  PaginationWrapper,
  StatsHeader,
  EventNameHeader,
} from 'components/Insights/Common/Styled';
import ApplicationEventRow from './ApplicationEventRow';

export const Application = ({
  results,
  pageSize = 10,
  selectedEvents,
  enableSelect,
  searchTerm = '',
  onSelect,
  onClearEvents,
}) => {
  const {
    isEmpty,
    currentIndex,
    orderByColumn,
    orderByDirection,
    results: resultPage,
    totalResults,
    handleSort,
    handleCurrentIndex,
  } = useClientsideTable({
    data: results,
    searchTerm,
    pageSize,
    defaultOrderColumn: 'events',
    defaultSortDirection: 'desc',
    filterPredicate: ({ name }) =>
      name.toLowerCase().includes(searchTerm.toLowerCase()),
  });

  return (
    <>
      <ResultsWrapper>
        <TableOverlay
          isEmpty={isEmpty}
          results={resultPage}
          type={APPLICATION_EVENTS_LABEL}
        />
        <TableWrapper>
          <Table>
            <Thead>
              <Tr>
                {resultPage?.length > 0 ? (
                  <SelectHeader>
                    {selectedEvents?.some(
                      evt => evt.source === 'application'
                    ) ? (
                      <ClearEvents
                        onClick={() => {
                          onClearEvents('application');
                        }}
                      />
                    ) : (
                      <AddEventTooltip />
                    )}
                  </SelectHeader>
                ) : (
                  <ControlHeader />
                )}
                <EventNameHeader
                  sortable
                  sorted={orderByColumn === 'name' ? orderByDirection : null}
                  onClick={() => {
                    handleSort('name');
                  }}
                >
                  Event Name
                </EventNameHeader>
                <StatsHeader
                  sortable
                  sorted={orderByColumn === 'events' ? orderByDirection : null}
                  onClick={() => {
                    handleSort('events');
                  }}
                >
                  <TitleTooltip
                    tip="The number of total event occurrences."
                    title="Total Events"
                  />
                </StatsHeader>
                <StatsHeader
                  sortable
                  sorted={orderByColumn === 'users' ? orderByDirection : null}
                  onClick={() => {
                    handleSort('users');
                  }}
                >
                  <TitleTooltip
                    tip="The number of unique users who triggered the event."
                    title="Unique Users"
                  />
                </StatsHeader>
                <StatsHeader
                  sortable
                  sorted={
                    orderByColumn === 'most_recent_timestamp'
                      ? orderByDirection
                      : null
                  }
                  onClick={() => {
                    handleSort('most_recent_timestamp');
                  }}
                >
                  Last Triggered
                </StatsHeader>
              </Tr>
            </Thead>
            <Tbody>
              {(resultPage || []).map(
                ({ name, events, users, most_recent_timestamp }) => {
                  const checked = (selectedEvents || []).some(
                    ({ event: selectedEventName }) => selectedEventName === name
                  );
                  return (
                    <ApplicationEventRow
                      key={name}
                      name={name}
                      events={events}
                      users={users}
                      timestamp={most_recent_timestamp}
                      disabled={!enableSelect}
                      checked={checked}
                      onSelect={() =>
                        onSelect({ source: 'application', event: name })
                      }
                    />
                  );
                }
              )}
            </Tbody>
          </Table>
        </TableWrapper>
      </ResultsWrapper>
      {resultPage && resultPage.length > 0 && (
        <PaginationWrapper>
          <Pagination
            pageCount={Math.ceil(totalResults / pageSize)}
            currentIndex={currentIndex}
            onChange={handleCurrentIndex}
          />
          <PaginationMessage
            currentIndex={currentIndex}
            pageSize={pageSize}
            total={totalResults}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

Application.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      events: PropTypes.number,
      users: PropTypes.number,
      most_recent_timestamp: PropTypes.number,
    })
  ),
  pageSize: PropTypes.number,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  enableSelect: PropTypes.bool,
  onSelect: PropTypes.func,
  onClearEvents: PropTypes.func,
};

export const ApplicationWithAnalytics = ({
  startTime,
  endTime,
  searchTerm,
  segmentId,
  pageSize = 10,
  selectedEvents,
  enableSelect,
  onSelect,
  onClearEvents,
}) => {
  const conditions = [
    ['event', 'not like', 'appcues:%'],
    ['event', 'not like', 'appcues_custom:%'],
    ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
  ];

  return (
    <QueryResults
      query={{
        conditions,
        dimensions: ['name'],
        metrics: ['events', 'users', 'most_recent_timestamp'],
        start_time: startTime,
        end_time: endTime,
        meta: {
          tags: {
            feature: 'Event analytics',
            page: 'Event explorer',
            component: 'ApplicationWithAnalytics',
            description: 'Application event source single results page',
          },
        },
      }}
    >
      {results => {
        return (
          <Application
            results={results}
            pageSize={pageSize}
            selectedEvents={selectedEvents}
            enableSelect={enableSelect}
            onSelect={onSelect}
            searchTerm={searchTerm}
            onClearEvents={onClearEvents}
          />
        );
      }}
    </QueryResults>
  );
};

ApplicationWithAnalytics.propTypes = {
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  segmentId: PropTypes.string,
  searchTerm: PropTypes.string,
  pageSize: PropTypes.number,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  enableSelect: PropTypes.bool,
  onSelect: PropTypes.func,
  onClearEvents: PropTypes.func,
};

export default ApplicationWithAnalytics;
