import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Table, Th, Thead, Tr, Tooltip } from '@studio/legacy-components';

import { InfoIcon, TableWrapper } from 'components/Insights/Common/Styled';
import QueryResults from 'components/Common/QueryResults';
import { numberWithCommas } from 'utils/textFormatting';

import {
  getQueryConditionsForSelectedEvents,
  removeRecoveredErrors,
  getEventTotalsFromResults,
} from 'components/Insights/utils';
import SelectedEventRow from 'components/Insights/Explorer/Chart/SelectedEventRow';
import { TitleTooltip } from 'components/Insights/Explorer/Common/SourceTooltips';

const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: var(--bold);
`;

const Visitors = styled.span`
  font-weight: var(--normal);
`;

const Wrapper = styled(TableWrapper)`
  margin-top: 1em;
  margin-bottom: -25px;
`;

const RemoveHeader = styled(Th)`
  background-color: var(--table-header-hover);
  width: 40px;
  max-width: 40px;
  padding: 0;
`;

const NameHeader = styled(Th)`
  background-color: var(--table-header-hover);
  height: 42px;
  width: 420px;
`;

const StatsHeader = styled(Th)`
  background-color: var(--table-header-hover);
  width: 175px;
`;

export const SelectedEventsTable = ({
  results,
  totalUsers,
  flowResults = [],
  selectedEvents = [],
  onSelectEvent = () => null,
  shouldHideCheckbox,
}) => {
  const unrecoveredResults = results && removeRecoveredErrors(results);
  const flowUnrecoveredResults = results && removeRecoveredErrors(flowResults);

  return (
    <Wrapper>
      <Table>
        <Thead>
          <Tr>
            {!shouldHideCheckbox && <RemoveHeader />}
            <NameHeader>Event Name</NameHeader>
            <StatsHeader>
              <TitleTooltip
                tip="The number of total event occurrences."
                title="Total Events"
              />
            </StatsHeader>
            <StatsHeader>
              <TitleTooltip
                tip="The number of unique users who triggered the event."
                title="Unique Users"
              />
            </StatsHeader>
            <StatsHeader>
              <Tooltip
                size="m"
                placement="top"
                wrapped
                persist
                label={
                  <TooltipWrapper>
                    The percentage of users who triggered this event compared to
                    the total number of visitors.
                    {totalUsers && (
                      <Visitors>
                        Number of Visitors: {numberWithCommas(totalUsers)}
                      </Visitors>
                    )}
                  </TooltipWrapper>
                }
              >
                <InfoIcon /> % Users
              </Tooltip>
            </StatsHeader>
          </Tr>
        </Thead>
        <TransitionGroup component="tbody">
          {(selectedEvents || []).map(selectedEvent => {
            const { source, event, id, stepId } = selectedEvent;

            const filteredResult =
              source === 'flow' ? flowUnrecoveredResults : unrecoveredResults;

            const { events, users } = getEventTotalsFromResults(
              filteredResult || [],
              {
                eventId: id,
                eventStepId: stepId,
                eventName: event,
              }
            );

            return (
              <CSSTransition
                appear
                key={source + event + id + stepId}
                classNames="slide"
                timeout={200}
              >
                <SelectedEventRow
                  name={event}
                  source={source}
                  id={id}
                  stepId={stepId}
                  events={events}
                  users={users}
                  totalUsers={totalUsers}
                  onSelect={() => onSelectEvent(selectedEvent)}
                  shouldHideCheckbox={shouldHideCheckbox}
                />
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </Table>
    </Wrapper>
  );
};

SelectedEventsTable.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      events: PropTypes.number,
      users: PropTypes.number,
      flow_id: PropTypes.string,
      checklist_id: PropTypes.string,
    })
  ),
  flowResults: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      events: PropTypes.number,
      users: PropTypes.number,
      flow_id: PropTypes.string,
    })
  ),
  totalUsers: PropTypes.number,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  onSelectEvent: PropTypes.func,
  shouldHideCheckbox: PropTypes.bool,
};

const SelectedEventsTableWithAnalytics = ({
  startTime,
  endTime,
  segmentId,
  selectedEvents,
  onSelectEvent,
  shouldHideCheckbox,
}) => (
  <QueryResults
    query={{
      conditions: [
        ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
      ],
      metrics: ['users'],
      start_time: startTime,
      end_time: endTime,
      meta: {
        tags: {
          feature: 'Event analytics',
          page: 'Event explorer',
          component: 'SelectedEventsTable',
          description: 'Total segment users for period',
        },
      },
    }}
  >
    {([{ users: totalUsers }] = [{}]) => (
      <QueryResults
        query={{
          conditions: [
            ...getQueryConditionsForSelectedEvents(selectedEvents),
            ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
          ],
          dimensions: [
            'name',
            'flow_id',
            'checklist_id',
            'experience_id',
            'step_id',
            'goal_id',
          ],
          metrics: ['events', 'users'],
          start_time: startTime,
          end_time: endTime,
          meta: {
            tags: {
              feature: 'Event analytics',
              page: 'Event explorer',
              component: 'SelectedEventsTable',
              description: 'Selected event consolidated results',
            },
          },
        }}
      >
        {results => (
          <QueryResults
            query={{
              conditions: [
                ...getQueryConditionsForSelectedEvents(selectedEvents),
                ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
              ],
              dimensions: ['name', 'flow_id'],
              metrics: ['events', 'users'],
              start_time: startTime,
              end_time: endTime,
              meta: {
                tags: {
                  feature: 'Event analytics',
                  page: 'Event explorer',
                  component: 'SelectedEventsTable',
                  description: 'Selected event consolidated results',
                },
              },
            }}
          >
            {flowResults => (
              <SelectedEventsTable
                results={results}
                flowResults={flowResults}
                totalUsers={totalUsers}
                selectedEvents={selectedEvents}
                onSelectEvent={onSelectEvent}
                shouldHideCheckbox={shouldHideCheckbox}
              />
            )}
          </QueryResults>
        )}
      </QueryResults>
    )}
  </QueryResults>
);

SelectedEventsTableWithAnalytics.propTypes = {
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  segmentId: PropTypes.string,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  onSelectEvent: PropTypes.func,
  shouldHideCheckbox: PropTypes.bool,
};

export default SelectedEventsTableWithAnalytics;
