/* global REDUX_DEVTOOLS */

import { applyMiddleware, createStore, compose } from 'redux';
import promise from 'redux-promise';
import createSagaMiddleware from 'redux-saga';
import { createReduxEnhancer } from '@sentry/react';
import reducer from 'reducers';

import createApiClient from 'helpers/api-client';
import createEndUserClient from 'clients/end-user-client';
import createEstimationClient from 'clients/estimation-api';
import createAnalyticsClient from 'helpers/analytics-client';
import createAccountsClient from 'clients/accounts';
import createBillingClient from 'clients/billing';
import createAnalyticsV2Client from 'clients/analytics-v2';
import createImageServiceClient from 'clients/image-service';
import createRbfClient from 'clients/rbf';
import createIntegrationsClient from 'clients/integrations';
import createListservClient from 'clients/listserv';
import createRedisClient from 'clients/redis';
import createImageLambdaClient from 'clients/image-lambda-service';

// eslint-disable-next-line no-restricted-imports
import authClient from 'helpers/auth-client';
import { requestNpsCsvExport } from 'helpers/csv-exports';

import { SAGAS } from 'sagas';
import { supportsUserTimingApi } from 'utils/timing';
import { addPerformanceMeasure } from 'actions/metrics';
import createCustomerAPIV2Client from 'clients/customer-api-v2';
import createFirebaseAuthClient from 'clients/firebase-auth';
import actionTimingMiddleware from './actionTimingMiddleware';

/**
 * Hydrate initial state with existing data
 *
 * @param {object} data - Existing data
 * @param {object<boolean>} data.features - Feature flags
 * @return {object} Hydrated initial state
 */
const hydrate = ({ features = {} }) => ({
  account: {
    features: {
      synced: true,
      features,
    },
  },
});

export default function configureStore({ features } = {}) {
  const state = hydrate({ features });

  const accountsClient = createAccountsClient(authClient);
  const billingClient = createBillingClient(authClient);
  const apiClient = createApiClient(authClient);
  const endUserClient = createEndUserClient(authClient);
  const analyticsClient = createAnalyticsClient(authClient);
  const analyticsV2Client = createAnalyticsV2Client(authClient);
  const estimationClient = createEstimationClient(authClient);
  const imageServiceClient = createImageServiceClient(authClient);
  const imageLambdaClient = createImageLambdaClient(authClient);
  const rbfClient = createRbfClient(authClient);
  const integrationsClient = createIntegrationsClient(authClient);
  const listservClient = createListservClient(authClient);
  const redisClient = createRedisClient(authClient);
  const customerAPIV2Client = createCustomerAPIV2Client(authClient);
  const firebaseAuthClient = createFirebaseAuthClient();

  const sagaMiddleware = createSagaMiddleware({
    context: {
      accounts: accountsClient,
      analytics: analyticsClient,
      analyticsV2: analyticsV2Client,
      api: apiClient,
      apiV2: customerAPIV2Client,
      billing: billingClient,
      endUserApi: endUserClient,
      auth: authClient,
      csv: { requestNpsCsvExport },
      estimation: estimationClient,
      image: imageServiceClient,
      imageLambda: imageLambdaClient,
      integrations: integrationsClient,
      rbf: rbfClient,
      listserv: listservClient,
      redis: redisClient,
      firebaseAuth: firebaseAuthClient,
    },
  });

  const sentryReduxEnhancer = createReduxEnhancer({});

  let composeEnhancers = compose;
  let middlewares = [sagaMiddleware, promise];

  if (REDUX_DEVTOOLS && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }

  // Add action timing middleware
  /* global COLLECT_TIMING_REPORTS */
  if (COLLECT_TIMING_REPORTS && supportsUserTimingApi()) {
    middlewares = [...middlewares, actionTimingMiddleware];
  }

  const store = createStore(
    reducer,
    state,
    composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer)
  );

  SAGAS.forEach(saga => sagaMiddleware.run(saga));

  store.dispatch(addPerformanceMeasure('action.app.bootstrap'));

  return store;
}
