import React from 'react';
import {
  AnalyticsMetricCard,
  AnalyticsMetricGroup,
} from 'next/components/AnalyticsMetricCard';

const OverviewSection = props => {
  const {
    checklistAnalytics: {
      data: { checklistShown, checklistCompleted, checklistSkipped },
    },
  } = props;

  const percentCompleted =
    !checklistShown || checklistShown === 0
      ? '0%'
      : `${((checklistCompleted / checklistShown) * 100).toFixed(1)}%`;
  const percentSkipped =
    !checklistShown || checklistShown === 0
      ? '0%'
      : `${((checklistSkipped / checklistShown) * 100).toFixed(1)}%`;

  return (
    <AnalyticsMetricGroup columns={3}>
      <AnalyticsMetricCard
        title="Users reached"
        metric={checklistShown.toLocaleString()}
        description="Users that saw this checklist"
      />
      <AnalyticsMetricCard
        title="Users completed"
        metric={percentCompleted}
        description="Users who saw and completed all items"
      />

      <AnalyticsMetricCard
        title="Users skipped"
        metric={percentSkipped}
        description="Users who saw and skipped this checklist"
      />
    </AnalyticsMetricGroup>
  );
};

export default OverviewSection;
