import {
  CANCELED,
  REQUEST_READ,
  REQUEST_READ_ONE,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
  REQUEST_RESET,
  NEW_ITEM,
  ITEM_CREATED,
  ITEM_UPDATED,
  ITEM_DROPPED,
  ITEM_REMOVED,
  ITEM_INSERTED,
  ITEM_REPLACED,
  ITEM_FLUSHED,
  ITEM_PRUNED,
} from './action-types';

const createPatternCreator =
  type =>
  (actionType, meta = {}) =>
  action =>
    action.type === actionType &&
    action.meta &&
    action.meta.type === type &&
    Object.keys(meta).every(key => meta[key] === action.meta[key]);

/**
 * Returns action creators tied to a specific data type
 *
 * @param {boolean} collection - Whether collection type
 * @param {string} type - Entity data type
 * @return {Actions} Entity action creators
 */
const createActions =
  ({ collection }) =>
  type => {
    const meta = { type, collection };

    const createPatternFor = createPatternCreator(type);

    return {
      /**
       * UI Actions
       *
       * The following actions are dispatched from the UI e.g. components to
       * initiate sagas.
       */

      /**
       * Action describing that some action is being canceled. For example,
       * this can be bound to a e.g. cancel button to halt the progress of a
       * running saga.
       */
      cancel: () => ({
        type: CANCELED,
        meta,
      }),

      /**
       * Action describing that a request status needs to be reset.
       * If an id is provided it will reset the status of that specific item
       * For example, if submitting a form has errored and after leaving that space
       * and coming back to it the state should start fresh instead of keeping the
       * error state
       */
      reset: id => ({
        type: REQUEST_RESET,
        payload: { id },
        meta,
      }),

      /**
       * Action describing the request for data from an API for an
       * collection. Commonly used to fetch data on initial mount of a
       * page.
       *
       * @method {GET}
       * @triggers {resolve|append}
       */
      read: payload => ({
        type: REQUEST_READ,
        payload,
        meta,
      }),

      /**
       * Action describing the request for data from an API for a
       * single item of a collection. Commonly used to fetch data
       * on initial mount of a page.
       *
       * @method {GET}
       * @triggers {append|insert|replace}
       */
      readOne: id => ({
        type: REQUEST_READ_ONE,
        payload: { id },
        meta,
      }),

      /**
       * Action describing that the process for creating a new item has started.
       * For example, this action can be used when entering the intermediary step
       * of creating an item such as a wizard or multi-step process, but before
       * the actual creation has been triggered. Most scenarios may not require
       * this action and can use `create` directly.
       */
      add: item => ({
        type: NEW_ITEM,
        payload: item,
        meta,
      }),

      /**
       * Action describing the creation of an item. Commonly bound to elements
       * like save buttons to kick of sagas that will ask the API to create the
       * item given the provided properties.
       *
       * @method {POST}
       * @triggers {insert}
       */
      create: item => ({
        type: ITEM_CREATED,
        payload: item,
        meta,
      }),

      /**
       * Action describing the update of an entity or item in a collection.
       * Similar to `create`, this action is commonly bound to elements like
       * update or save buttons to update properties of an existing item. Note
       * that the action is variadic to support both entities and collection. This
       * means that if an ID is provided as the first parameter and the updated
       * properties (or delta) is provided as the second, it is assumed this type
       * is a collection. Otherwise if only a single argument of delta is
       * provided, then it is assume this type is an entity.
       *
       * @method {PUT|PATCH}
       * @triggers {replace|flush}
       */
      update: (...args) => {
        if (collection) {
          const [id, delta] = args;
          return {
            type: ITEM_UPDATED,
            payload: { id, delta },
            meta,
          };
        }

        const [payload] = args;
        return {
          type: ITEM_UPDATED,
          payload,
          meta,
        };
      },

      /**
       * Action describing the removal of an item from the Redux store that does
       * not imply a deletion on the server.  Commonly used in combination with
       * readOne to cleanup the store after a user leaves a page.
       */
      drop: id => ({
        type: ITEM_DROPPED,
        payload: { id },
        meta,
      }),

      /**
       * Action describing the removal of an item. Commonly bound to elements
       * performing destructive actions. Similar to `update`, providing an ID as
       * the first and only parameter indicates this type is a collection and the
       * omission indicates the type is an entity.
       *
       * @method {DELETE}
       * @triggers {prune}
       */
      remove: id => ({
        type: ITEM_REMOVED,
        payload: { id },
        meta,
      }),

      /**
       * Server/Saga Actions
       *
       * The following actions are dispatched within sagas typically before
       * interacting with a server or in response to results from a server.
       */

      /**
       * Action describing the return of an entity or collection from the server.
       * This action is typically used when fetching the entirety of an entity or
       * collection such as the initial load of a listing page or detail page.
       *
       * @method {GET}
       * @listens {read}
       */
      resolve: data => ({
        type: REQUEST_RESOLVED,
        payload: data,
        meta: { ...meta, partial: false },
      }),

      /**
       * Action describing the return of part of an entity or collection
       * from an API request. This action is very similar to `resolve` above
       * except that rather than receiving everything, it may only have received
       * e.g. updated values of an entity or another page of a paginated
       * collection.
       *
       * @method {GET}
       * @listens {read}
       */
      append: data => ({
        type: REQUEST_RESOLVED,
        payload: data,
        meta: { ...meta, partial: true },
      }),

      /**
       * Action describing the failure of some execution within a saga. Typically
       * called in the catch block of a saga to indicate that an error has
       * occurred so the UI can handle it appropriately. Note that the action is
       * variadic to support both entities and collection. This means that if an
       * ID is provided as the first parameter and the error is provided as the
       * second, it is assumed this type is a collection. Otherwise if only a
       * single argument of error is provided, then it is assume this type is an
       * entity.
       */
      reject: (...args) => {
        if (collection) {
          const [id, error] = args;
          return {
            type: REQUEST_REJECTED,
            payload: { id, error },
            meta,
          };
        }

        const [error] = args;
        return {
          type: REQUEST_REJECTED,
          payload: { error },
          meta,
        };
      },

      /**
       * Action describing that an item has been created by the server and inserted
       * into the store. Optionally takes a `parent` descriptor if the item has a
       * parent entity associated with it.
       *
       * @method {POST}
       * @listens {create}
       */
      insert: (item, parent) => ({
        type: ITEM_INSERTED,
        payload: item,
        meta: { ...meta, parent },
      }),

      /**
       * Action describing that an item has been replaced by the server. Similar
       * to `insert` above except this is typically used to describe the
       * replacement of an existing entity or item in a collection while `insert`
       * is for the first time the item has been created.
       *
       * @method {PUT|PATCH}
       * @listens {update}
       */
      replace: item => ({
        type: ITEM_REPLACED,
        payload: item,
        meta,
      }),

      /**
       * Action describing that an item has been updated by the server. Similar to
       * `replace` except this action is reserved for partial updates rather than
       * full updates or replacements. For example, if an entity or item in a
       * collection has some properties that were updated, the saga would respond
       * with `flush` versus if the entire entity or object was replaced.
       *
       * @method {PUT|PATCH}
       * @listens {update}
       */
      flush: id => ({
        type: ITEM_FLUSHED,
        payload: { id },
        meta,
      }),

      /**
       * Action describing that an item has been removed from the server and/or
       * the store.
       *
       * @method {DELETE}
       * @listens {remove}
       */
      prune: id => ({
        type: ITEM_PRUNED,
        payload: { id },
        meta,
      }),

      /**
       * Entity/Collection Scoped Patterns
       *
       * The following are pattern matchers that can be used to determine whether
       * a dispatched action matches this entity's or collection's actions. Since
       * all entities/collections have these common action types, the matchers
       * here will deeply check the meta object to ensure the type also matches as
       * well as any other expected fields.
       */
      patterns: {
        cancel: createPatternFor(CANCELED),
        reset: createPatternFor(REQUEST_RESET),
        read: createPatternFor(REQUEST_READ),
        readOne: createPatternFor(REQUEST_READ_ONE),
        resolve: createPatternFor(REQUEST_RESOLVED, { partial: false }),
        append: createPatternFor(REQUEST_RESOLVED, { partial: true }),

        reject: createPatternFor(REQUEST_REJECTED),

        add: createPatternFor(NEW_ITEM),
        create: createPatternFor(ITEM_CREATED),
        update: createPatternFor(ITEM_UPDATED),
        drop: createPatternFor(ITEM_DROPPED),
        remove: createPatternFor(ITEM_REMOVED),

        insert: createPatternFor(ITEM_INSERTED),
        replace: createPatternFor(ITEM_REPLACED),
        flush: createPatternFor(ITEM_FLUSHED),
        prune: createPatternFor(ITEM_PRUNED),
      },
    };
  };

export const createEntityActions = createActions({ collection: false });
export const createCollectionActions = createActions({ collection: true });
