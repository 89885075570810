import { createCollectionActions } from 'next/lib/collections';

export const TYPE = 'screenshots';

export const { insert, patterns, reject, resolve } =
  createCollectionActions(TYPE);

export const READ_ONE_EXPERIENCE = '@screenshot/READ_ONE_EXPERIENCE';
export const readOneExperience = payload => ({
  type: READ_ONE_EXPERIENCE,
  payload,
});

export const READ_ONE_STEP_CHILD = '@screenshot/READ_ONE_STEP_CHILD';
export const readOneStepChild = payload => ({
  type: READ_ONE_STEP_CHILD,
  payload,
});
