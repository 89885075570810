import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import {
  H3,
  Link,
  Panel,
  PanelActions,
  PanelFooter,
  PanelHeader,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  Th,
  Dropdown,
  MenuList,
} from '@studio/legacy-components';
import { Loading } from 'next/components/Listing';
import { RESET_BETA, RESET } from 'next/entities/features';
import { ACCOUNT_PERMISSIONS } from 'constants/accountManagement/permissions';
import { profileAttributeLabelShape } from 'entities/profile-attribute-labels';
import { selectAccountFeature } from 'reducers/account/features';
import { selectUserRole } from 'reducers/account/users';
import { selectUserId } from 'reducers/user';
import {
  LoaderWrapper,
  NoResultsMessage,
  NoResultsMessageEmphasis,
  TableWrapper,
} from 'components/Audience/styled';

import NoData from 'assets/images/no-data.svg';
import Pagination, {
  PaginationMessage,
} from 'components/Common/UI/Pagination/Pagination';
import { selectAttributesColumnsByName } from 'selectors/profile-attributes';
import { formatPropertyValue } from 'utils';
import ColumnsModalManager from './ColumnsModalManager/ColumnsModalManager';

import {
  TooltipIcon,
  UserListTitle,
  URLMark,
  TooltipContentWrapper,
  TruncateText,
  Dots,
  UsersTable,
  UpgradeBadge,
  SearchInput,
  TableCell,
} from './styled';
import ResetFlowModal from './ResetFlowModal';

export const List = ({
  users,
  attributes = [{ name: 'user_id' }, { name: 'last_seen' }],
  sortColumn,
  sortDirection,
  pageSize = 25,
  currentPage,
  totalUsers,
  matchingUsers,
  searchTerm = '',
  onChangeSort,
  onChangePage,
  onChangeSearch,
  onChangeColumns,
  hasResetFlowHistoryBeta,
  hasResetFlowHistory,
  canResetFlowHistory,
}) => {
  const isLoading = !users;
  const hasNoResults = !isLoading && totalUsers === 0;
  const hasNoMatches = !hasNoResults && users?.length === 0;

  const [selectedUserId, setSelectedUserId] = useState(null);

  const debouncedSearch = debounce(onChangeSearch, 500);

  const handleClickColumnHeader = column => () => onChangeSort(column);

  // The user identifier is always the first column and lastSeen is always the last column, so
  // we exclude these from the options that are shown in the columns manager.
  const columnsManagerValues = attributes.slice(1, -1);

  const localizedTotalUsers = Number.isInteger(totalUsers)
    ? totalUsers?.toLocaleString()
    : '-';

  const localizedMatchingUsers = Number.isInteger(matchingUsers)
    ? matchingUsers?.toLocaleString()
    : '-';

  const percentMatch =
    Number.isInteger(matchingUsers) &&
    Number.isInteger(totalUsers) &&
    100 * (matchingUsers / totalUsers);

  const localizedPercentMatch = Number.isFinite(percentMatch)
    ? percentMatch > 0 && percentMatch < 1
      ? '< 1'
      : Math.round(percentMatch)
    : '-';

  const columns = attributes.map(({ name, label }) => {
    if (name === 'user_id') {
      return {
        sortKey: name,
        label: 'User ID',
      };
    }

    if (name === 'last_seen') {
      return {
        sortKey: name,
        label: 'Last updated',
      };
    }

    return {
      sortKey: `p:${name}`,
      label: label || name,
    };
  });

  return (
    <>
      <Panel>
        <PanelHeader>
          <UserListTitle>
            <H3>
              {localizedMatchingUsers} User{matchingUsers !== 1 && 's'} of{' '}
              {localizedTotalUsers} ({`${localizedPercentMatch}%`})
            </H3>
            <Tooltip
              placement="top"
              label={
                <TooltipContentWrapper>
                  Information on this page is refreshed every 1 hour. If you
                  need to find a more recently created user, you can add their
                  User ID to this URL and access their profile:
                  https://studio.appcues.com/audience/users/
                  <URLMark>[userID]</URLMark>
                </TooltipContentWrapper>
              }
              persist
              wrapped
            >
              <TooltipIcon />
            </Tooltip>
          </UserListTitle>
          <PanelActions>
            <SearchInput
              type="search"
              placeholder="Search selected columns"
              aria-label="Search selected columns"
              defaultValue={searchTerm}
              icon={faSearch}
              onChange={e => {
                debouncedSearch(e.target.value);
              }}
            />
            <ColumnsModalManager
              defaultValue={columnsManagerValues}
              onSave={onChangeColumns}
            />
          </PanelActions>
        </PanelHeader>
        <TableWrapper>
          {isLoading && (
            <LoaderWrapper>
              <Loading />
            </LoaderWrapper>
          )}
          {hasNoResults && (
            <NoResultsMessage>
              <img src={NoData} alt="No data found" />
              We don’t have data for you yet
            </NoResultsMessage>
          )}
          {hasNoMatches && (
            <NoResultsMessage>
              <img src={NoData} alt="No data found" />
              <NoResultsMessageEmphasis>
                No results found
              </NoResultsMessageEmphasis>
              Make sure you&apos;ve selected the correct columns to search on
              that property
            </NoResultsMessage>
          )}
          {users?.length > 0 && (
            <UsersTable>
              <Thead>
                <Tr>
                  {columns.map(({ sortKey, label }) => (
                    <Th
                      title={label}
                      key={sortKey}
                      sortable
                      sorted={sortColumn === sortKey ? sortDirection : null}
                      onClick={handleClickColumnHeader(sortKey)}
                    >
                      <TruncateText>{label}</TruncateText>
                    </Th>
                  ))}
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {users?.map(userAttrs => {
                  const dropdownOptions = [
                    {
                      as: Link,
                      to: `/users/${userAttrs.user_id}`,
                      label: 'View profile',
                      icon: 'user',
                    },
                  ];

                  if (hasResetFlowHistoryBeta && canResetFlowHistory) {
                    dropdownOptions.push({
                      label: 'Reset Flow history',
                      description: !hasResetFlowHistory && (
                        <UpgradeBadge label="Upgrade" icon="magic" />
                      ),
                      icon: 'history',
                      disabled: !hasResetFlowHistory,
                      ...(hasResetFlowHistory && {
                        onClick: () => {
                          setSelectedUserId(userAttrs.user_id);
                        },
                      }),
                    });
                  }

                  return (
                    <Tr key={userAttrs.user_id}>
                      {columns.map(({ sortKey }, columnIndex) => {
                        let { [sortKey]: value } = userAttrs;

                        if (sortKey === 'p:userId') {
                          value = userAttrs.user_id;
                        }

                        let formattedValue = formatPropertyValue(
                          sortKey,
                          value
                        );

                        let hoverValue = formattedValue;

                        if (sortKey === 'last_seen') {
                          formattedValue =
                            moment().diff(value, 'months') < 1
                              ? moment(value).fromNow()
                              : moment(value).format('lll');
                          hoverValue = moment(value).format('lll');
                        }

                        return (
                          <TableCell key={sortKey} title={hoverValue}>
                            {columnIndex === 0 ? (
                              <Link
                                to={`/users/${encodeURIComponent(
                                  userAttrs.user_id
                                )}`}
                              >
                                {formattedValue}
                              </Link>
                            ) : (
                              formattedValue
                            )}
                          </TableCell>
                        );
                      })}
                      <Td>
                        <Dropdown
                          attachment={<MenuList options={dropdownOptions} />}
                          aria-label="user options"
                        >
                          <Dots icon="ellipsis-h" />
                        </Dropdown>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </UsersTable>
          )}
        </TableWrapper>
        {users?.length > 0 && (
          <PanelFooter>
            <Pagination
              pageCount={Math.ceil(matchingUsers / pageSize)}
              currentIndex={currentPage}
              onChange={onChangePage}
            />
            <PaginationMessage
              total={matchingUsers}
              currentIndex={currentPage}
              pageSize={pageSize}
            />
          </PanelFooter>
        )}
      </Panel>
      <ResetFlowModal
        visible={Boolean(selectedUserId)}
        onClose={() => void setSelectedUserId(null)}
        userId={selectedUserId}
      />
    </>
  );
};

List.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      user_id: PropTypes.string,
      last_seen: PropTypes.number,
    })
  ),
  attributes: PropTypes.arrayOf(profileAttributeLabelShape),
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.string,
  totalUsers: PropTypes.number,
  matchingUsers: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  searchTerm: PropTypes.string,
  onChangeSort: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeSearch: PropTypes.func,
  onChangeColumns: PropTypes.func,
  hasResetFlowHistoryBeta: PropTypes.bool,
  hasResetFlowHistory: PropTypes.bool,
  canResetFlowHistory: PropTypes.bool,
};

const mapStateToProps = (state, { attributesNames }) => {
  const userRole = selectUserRole(state, selectUserId(state));
  return {
    attributes: attributesNames.map(name =>
      selectAttributesColumnsByName(state, name)
    ),
    hasResetFlowHistory: selectAccountFeature(state, RESET),
    hasResetFlowHistoryBeta: selectAccountFeature(state, RESET_BETA),
    canResetFlowHistory: userRole !== ACCOUNT_PERMISSIONS.EDITOR,
  };
};

export default connect(mapStateToProps)(List);
