import { createCollectionActions } from 'next/lib/collections';

export const TYPE = 'rules';

export const {
  patterns,
  read,
  readOne,
  reject,
  resolve,
  replace,
  update,
  flush,
} = createCollectionActions(TYPE);
