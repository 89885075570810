import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { S5_SEGMENTS_LIST } from 'next/entities/features';
import SegmentsEdit from 'components/SegmentsEdit';
import SegmentsView from 'components/SegmentsView';

import { ACCOUNT_PERMISSIONS } from 'constants/accountManagement/permissions';
import { selectUserRole } from 'reducers/account/users';
import { selectUserId } from 'reducers/user';
import { selectAccountFeature } from 'reducers/account/features';

const BASE_PATH = '/segments';

const Segments = ({ match: { path = BASE_PATH } = {}, hasS5SegmentsList }) => {
  const userId = useSelector(selectUserId);
  const role = useSelector(state => selectUserRole(state, userId));
  const canEditSegments = role !== ACCOUNT_PERMISSIONS.EDITOR;

  return (
    <Switch>
      <Redirect exact from="/segments" to="/audience/segments" />
      {hasS5SegmentsList && (
        <Redirect exact from="/audience/segments" to="/audience/segments-v2" />
      )}
      {hasS5SegmentsList ? (
        <Redirect
          exact
          from="/segments/:segmentId/view"
          to="/segments-v2/:segmentId/view"
        />
      ) : (
        <Route path={`${path}/:segmentId/view`} component={SegmentsView} />
      )}
      {canEditSegments &&
        (hasS5SegmentsList ? (
          <Redirect
            exact
            from="/segments/:segmentId/edit"
            to="/segments-v2/:segmentId/edit"
          />
        ) : (
          <Route path={`${path}/:segmentId/edit`} component={SegmentsEdit} />
        ))}
    </Switch>
  );
};

Segments.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
  hasS5SegmentsList: PropTypes.bool,
};

const mapStateToProps = state => ({
  hasS5SegmentsList: selectAccountFeature(state, S5_SEGMENTS_LIST),
});

export default connect(mapStateToProps)(Segments);
