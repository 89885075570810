import { getContext, call, put } from 'redux-saga/effects';
import { takeLeadingByProp } from 'effects/takeLeadingByProp';
import { patterns, send, append, reject } from 'actions/analytics-v2';
import { reportError } from 'helpers/error-reporting';
import { getCacheKey } from 'utils/caching';

export function* getAnalytics({ payload: { query } }) {
  try {
    const api = yield getContext('analyticsV2');
    yield put(send());
    const { results } = yield call(api.getAnalytics, { query });
    const key = getCacheKey(query);
    yield put(append({ [key]: { results } }));
  } catch (error) {
    yield call(reportError, error);
    yield put(reject(error));
  }
}

export default function* analyticsV2Sagas() {
  yield takeLeadingByProp(
    ({ payload: { query } }) => getCacheKey(query),
    patterns.callApi,
    getAnalytics
  );
}
