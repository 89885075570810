import React, { PureComponent } from 'react';
import debounce from 'lodash.debounce';
import styled from 'styled-components';
import Recase from 'recase';
import { Icon } from '@studio/legacy-components';
import ChecklistPreviewError from 'components/ChecklistsEdit/ChecklistPreviewError';
import ErrorBoundary from 'components/Common/ErrorBoundary';

const CHECKLIST_DIAGNOSTIC_PADDING = 60;

class ChecklistPreview extends PureComponent {
  constructor(props) {
    super(props);

    this.debouncedUpdatePreviewBounds = debounce(this.updatePreviewBounds, 100);
    this.debouncedChecklistContentUpdate = debounce(
      this.updateChecklistContent,
      400
    );
  }

  state = {
    isOpen: true,
    isPastHeaderLine: false,
    previewTransition: 0,
  };

  componentDidMount() {
    const { checklist } = this.props;
    const scriptContainer = this.$sdkScriptContainer;
    const headEl = scriptContainer.contentDocument.head;

    window.addEventListener('scroll', this.debouncedUpdatePreviewBounds);
    window.addEventListener('resize', this.debouncedUpdatePreviewBounds);

    this.setState({
      topPreviewLength:
        document.querySelector('.header-bottom') &&
        document.querySelector('.header-bottom').offsetTop,
    });

    // Use the scroll handler to init based on scroll position on load.
    this.updatePreviewBounds();

    const sdkScriptEl = scriptContainer.contentDocument.createElement('script');
    sdkScriptEl.type = 'text/javascript';
    sdkScriptEl.addEventListener('load', () => {
      const injectContent = this.prepareInjectedContent(checklist);
      scriptContainer.contentWindow.Appcues.injectContent(injectContent);
    });
    /* global APPCUES_INJECTABLE_SDK_URL */
    sdkScriptEl.src = APPCUES_INJECTABLE_SDK_URL;
    headEl.append(sdkScriptEl);
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen, isPastHeaderLine } = this.state;
    const isUpdatingBackground =
      prevState.isOpen !== isOpen ||
      prevState.isPastHeaderLine !== isPastHeaderLine;

    if (!isUpdatingBackground) {
      this.debouncedChecklistContentUpdate();
    } else {
      this.updateChecklistContent();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.debouncedUpdatePreviewBounds);
    window.removeEventListener('resize', this.debouncedUpdatePreviewBounds);
  }

  updateChecklistContent = () => {
    const { checklist, adaptContainerHeight } = this.props;
    const scriptContainer = this.$sdkScriptContainer;

    if (scriptContainer.contentWindow.Appcues) {
      const injectContent = this.prepareInjectedContent(checklist);
      scriptContainer.contentWindow.Appcues.injectContent(injectContent);

      if (adaptContainerHeight) {
        setTimeout(() => {
          scriptContainer.style.height = `${
            scriptContainer.contentDocument
              .querySelector('appcues-checklists')
              .querySelector('iframe')
              .contentDocument.querySelector('.checklist').offsetHeight +
            CHECKLIST_DIAGNOSTIC_PADDING
          }px`;
        }, 0);
      }
    }
  };

  /**
  * Adds scroll and resize event handler to make the preview-wrapper "sticky"
  * Once user scrolls past <hr> under the header line, preview then sticks to the right hand side of the page.
  * topPreviewLength is calculated under componentDidMount() and represents the pixel value of the distance between
   the top of the page and the bottom of the header.
  * previewTop represents the `top` css attribute of preview-wrapper. This is changed once the user scrolls past the header.
  */
  updatePreviewBounds = () => {
    const { previewToggle, topPreviewLength } = this.state;

    if (previewToggle) {
      this.setState({ previewTransition: 0 });
    }

    if (window.scrollY > topPreviewLength) {
      this.setState({ isPastHeaderLine: true });
    } else {
      this.setState({ isPastHeaderLine: false, previewTransition: 0 });
    }
  };

  // onClick event for the preview tab
  previewClick = () => {
    this.setState(({ isOpen }) => ({
      isOpen: !isOpen,
      previewTransition: 0.3,
    }));
  };

  prepareInjectedContent(data) {
    const { completionEdit, dismissEdit } = this.props;

    const items = Object.values(data.items || {});
    const complete = completionEdit ? 'completed' : null;
    const dismiss = dismissEdit ? true : null;

    const checkedItems = completionEdit
      ? items
          .map(item => {
            return { ...item, state: 'complete' };
          })
          .sort((item1, item2) => item1.index - item2.index)
      : items
          .map(item => {
            return { ...item, state: 'incomplete' };
          })
          .sort((item1, item2) => item1.index - item2.index);

    // Send checklist data to SDK and override the beacon offsets so it doesn't
    // leave the page
    const rawInjectedContent = {
      ...data,
      items: checkedItems,
      styles: {
        ...data.styles,
        type: 'beacon',
        beaconVerticalOffset: 0,
        beaconHorizontalOffset: 0,
      },
      type: 'checklist',
      progress_state: complete,
      shouldShowConfirmDismiss: dismiss,
    };

    // We don't want to snake case the step IDs. Add them as exceptions.
    const recase = Recase.create({
      exceptions: Object.keys(data.items || {}).reduce((memo, stepId) => {
        return { ...memo, [stepId]: stepId };
      }, {}),
    });
    return recase.snakeCopy(rawInjectedContent);
  }

  render() {
    const { isOpen, isPastHeaderLine, previewTransition } = this.state;

    const checklistContainer = this.$sdkScriptContainer;

    return (
      <ChecklistPreviewBackground
        className="preview-wrapper"
        isOpen={isOpen}
        isPastHeaderLine={isPastHeaderLine}
        previewTransition={previewTransition}
      >
        <ErrorBoundary render={ChecklistPreviewError}>
          <ChecklistPreviewWrapper checklistContainer={checklistContainer}>
            <iframe
              title="SDK script container"
              className="sdk-script-container"
              ref={el => {
                this.$sdkScriptContainer = el;
              }}
            />
          </ChecklistPreviewWrapper>
        </ErrorBoundary>
        <ChecklistPreviewTab
          className="hover-tab"
          isPastHeaderLine={isPastHeaderLine}
          onClick={this.previewClick}
        >
          Preview &nbsp;
          <Icon icon={isOpen ? 'angle-down' : 'angle-up'} />
        </ChecklistPreviewTab>
      </ChecklistPreviewBackground>
    );
  }
}

const ChecklistPreviewBackground = styled.div`
  flex: 1 1 33%;
  margin-left: 0;
  position: ${props => (props.isPastHeaderLine ? 'fixed' : 'relative')};
  top: ${props => (props.isPastHeaderLine ? 0 : -46)}px;
  right: ${props =>
    props.isPastHeaderLine ? (props.isOpen ? 0 : '-401px') : 0};
  height: ${props => (props.isPastHeaderLine ? '100%' : '735px')};
  text-align: center;
  transition: ${props => props.previewTransition}s;
  z-index: 2;
`;

const ChecklistPreviewTab = styled.div`
  visibility: ${props => (props.isPastHeaderLine ? 'visible' : 'hidden')};
`;
/*
this.setState({
  previewPosition: `fixed`,
  previewTop: 0,
  previewHeight: "auto",
  previewRight: isOpen ? 0 : "-400px",
  previewHoverTab: `visible`,
  previewHeader: `visible`
});

this.setState({
  previewPosition: `relative`,
  previewTop: -46,
  previewRight: 0,
  previewHeight: "735px",
  previewHoverTab: `hidden`,
  previewHeader: `hidden`
});
*/
const ChecklistPreviewWrapper = styled.div`
  height: 100%;
  background-color: ${props => props.theme['$gray-50']};
  border-left: 1px solid ${props => props.theme['$gray-50']};
  border-radius: 6px;
  position: relative;
  z-index: 2;

  .sdk-script-container {
    border: none;
    width: 100%;
    height: 100%;
    min-width: 400px;
  }
`;

export default ChecklistPreview;
