import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Button,
  ButtonGroup,
  InputClickToCopy,
  Heading,
} from '@studio/legacy-components';
import { selectKey } from 'entities/keys';

import {
  CopySecretWrapper,
  SecretsLabel,
  Message,
  TermsCheckbox,
} from './styled';

export const CopySecret = ({
  apiKey,
  hasError,
  hasAcceptedTerms,
  secret,
  onAcceptTerms,
  onClose,
}) => {
  return (
    <CopySecretWrapper>
      <Heading>API Key and Secret</Heading>
      <SecretsLabel className="fs-mask">
        API Key
        <InputClickToCopy text={apiKey} />
      </SecretsLabel>
      <SecretsLabel className="fs-mask">
        Secret
        <InputClickToCopy text={secret} />
      </SecretsLabel>
      <TermsCheckbox
        onChange={onAcceptTerms}
        checked={hasAcceptedTerms}
        error={hasError}
      >
        <Message>
          I understand this Secret will not be available again and have copied
          it.
        </Message>
      </TermsCheckbox>
      <ButtonGroup right>
        <Button kind="primary" onClick={onClose}>
          Done
        </Button>
      </ButtonGroup>
    </CopySecretWrapper>
  );
};

CopySecret.propTypes = {
  apiKey: PropTypes.string,
  hasAcceptedTerms: PropTypes.bool,
  hasError: PropTypes.bool,
  secret: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onAcceptTerms: PropTypes.func,
};

const mapStateToProps = (state, { apiKey }) => {
  const { secret = '' } = selectKey(state, apiKey) || {};

  return {
    apiKey,
    secret,
  };
};

export default connect(mapStateToProps)(CopySecret);
