import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useToggle from 'next/hooks/use-toggle';
import { getThemeFor } from 'utils';
import UserProfileModal from './UserProfileModal';

const StyledLinkButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${getThemeFor('$brand-info')};
  font-weight: bold;
  text-align: left;
`;

const UserProfileModalManager = ({ children, userId, identity, timestamp }) => {
  const [showModal, toggleShowModal] = useToggle();
  return (
    <>
      <UserProfileModal
        visible={showModal}
        userId={userId}
        identity={identity}
        timestamp={timestamp}
        onClose={toggleShowModal}
      />
      <StyledLinkButton onClick={toggleShowModal}>{children}</StyledLinkButton>
    </>
  );
};

UserProfileModalManager.propTypes = {
  children: PropTypes.node,
  userId: PropTypes.string,
  identity: PropTypes.objectOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.number, PropTypes.object])
  ),
  timestamp: PropTypes.string,
};

export default UserProfileModalManager;
