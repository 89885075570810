import HTTPError from 'errors/HTTPError';

export default (auth, baseUrl) =>
  async (path, options = {}) => {
    const token = await auth.getToken();
    const accountId = await auth.getAccountId();

    const response = await fetch(
      `${baseUrl}/accounts/${accountId}${path ? `/${path}` : ''}`,
      {
        ...options,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json',
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      return { data };
    }
    const { error: errMsg } = await response.json();
    throw new HTTPError(response, errMsg);
  };
