import React from 'react';
import styled from 'styled-components';

const RadioLabel = styled.label`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  margin-top: 2px;
`;

const RadioInput = styled.input`
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  background-color: white;
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-top: 1px;

  &:checked {
    border: 7px solid ${props => props.theme.$blue};
  }

  &:not(:checked) {
    border: 2px solid ${props => props.theme['$gray-30']};
  }
`;

const RadioTextWrapper = styled.div`
  color: ${props => (props.checked ? 'inherit' : props.theme['$gray-70'])};
  margin-left: 10px;
  max-width: 90%;

  label {
    color: ${props =>
      props.checked ? 'inherit' : `${props.theme['$gray-70']} !important}`};
  }
`;

const RadioText = styled.div`
  font-weight: ${props => (props.bolded ? 'bold' : 'normal')};
  padding-bottom: 0.25em;
`;

const RadioDescription = styled.div`
  font-size: 16px;
  color: ${props => props.theme['$gray-70']};
  font-weight: normal;
  width: 100%;
  padding-bottom: 0.875em;
`;

const RadioButton = ({ checked, label, name, onChange, optionDescription }) => {
  return (
    <RadioLabel>
      <RadioInput
        checked={checked}
        name={name}
        onChange={onChange}
        type="radio"
      />
      <RadioTextWrapper checked={checked}>
        <RadioText bolded={optionDescription}>{label}</RadioText>
        <RadioDescription>{optionDescription}</RadioDescription>
      </RadioTextWrapper>
    </RadioLabel>
  );
};
export default RadioButton;
