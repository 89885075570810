import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Icon, Label, Link, Select } from '@studio/legacy-components';
import { SelectOptionsShape } from 'next/lib/shapes';
import { selectThemesOptions } from 'next/lib/selectors-options';

import { ThemeLabelWrapper } from './styled';

export const ThemesField = ({ value, onChange, options }) => (
  <Label as="div">
    <ThemeLabelWrapper>
      <Label htmlFor="themes">Theme</Label>
      <Link to="/themes" target="_blank">
        All Themes <Icon icon="external-link-alt" />
      </Link>
    </ThemeLabelWrapper>

    <Select
      inputId="themes"
      kind="default"
      name="theme"
      options={options}
      placeholder="Select Theme"
      portal
      onChange={option => onChange(option)}
      value={options.find(({ value: themeId }) => themeId === value)}
    />
  </Label>
);

ThemesField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: SelectOptionsShape,
};

const mapStateToProps = state => ({
  options: selectThemesOptions(state),
});

export default connect(mapStateToProps)(ThemesField);
