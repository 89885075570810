import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  Icon,
  Modal,
  Heading,
  Description,
  Overflow,
  Link,
} from '@studio/legacy-components';

import Loader from 'components/Common/Loader';
import { getOptGroup, getPropertyDisplayName } from 'utils';
import { selectAccountUserProfile } from 'reducers/account/userProfiles';
import { fetchUserProfile } from 'actions/userProfiles';
import CoolTip from 'components/Common/CoolTip';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
`;

const Table = styled.table`
  position: relative;
  border-collapse: collapse;
  width: 100%;
`;

const HeaderCell = styled.th`
  position: sticky;
  top: 0;
  padding: 15px;
  background-color: white;
  width: 25%;

  &:hover {
    // until tooltips are moved into portals, the active cell needs to be raised above it siblings
    z-index: 1;
  }

  :before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-top: 1px solid var(--myst);
  }

  :after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid var(--myst);
  }

  ${Icon} {
    color: var(--text-secondary);
    margin-right: 0.5em;
  }
`;

const TableCell = styled.td`
  vertical-align: middle;
  padding: 15px;
  white-space: normal;
  word-break: break-word;
  ${props => props.bold && `font-weight: var(--bold);`}
`;

const PropertyTypeCell = styled(TableCell)`
  text-transform: capitalize;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${Link} {
    margin-right: 40px;
    &:hover {
      color: var(--see-more-link-color);
    }
  }
`;

export const UserProfileModal = ({
  visible = true,
  userId,
  profile,
  identity,
  timestamp,
  onLoad,
  onClose,
}) => {
  useEffect(() => {
    if (onLoad) {
      onLoad(userId);
    }
  }, [onLoad, userId]);

  return (
    <Modal size="xl" visible={visible} onClose={onClose}>
      <ModalHeader>
        <Heading>User profile</Heading>
        <Description>{userId}</Description>
      </ModalHeader>
      <Overflow>
        {!profile && (
          <LoaderWrapper>
            <Loader margin="1em" />
          </LoaderWrapper>
        )}
        {profile && (
          <Table>
            <thead>
              <tr>
                <HeaderCell>Property name</HeaderCell>
                <HeaderCell>
                  <CoolTip
                    tip="Current profile stats will be bolded if changed from the time of the event."
                    position="bottom"
                  >
                    <Icon icon="info-circle" />
                    Current value
                  </CoolTip>
                </HeaderCell>
                <HeaderCell>
                  <CoolTip
                    tip="Profile at the time of the event."
                    position="bottom"
                  >
                    <Icon icon="info-circle" />
                    {moment(timestamp).format('M/DD/YY')} value
                  </CoolTip>
                </HeaderCell>
                <HeaderCell>Type</HeaderCell>
              </tr>
            </thead>
            <tbody>
              {Object.keys(profile).map(attribute => (
                <tr key={attribute}>
                  <TableCell>{getPropertyDisplayName(attribute)}</TableCell>
                  <TableCell
                    bold={
                      identity &&
                      identity[attribute] &&
                      profile[attribute] !== identity[attribute]
                    }
                  >
                    {typeof profile[attribute] === 'object'
                      ? JSON.stringify(profile[attribute])
                      : profile[attribute]}
                  </TableCell>
                  <TableCell>
                    {identity && identity[attribute]
                      ? typeof identity[attribute] === 'object'
                        ? JSON.stringify(identity[attribute])
                        : identity[attribute]
                      : '-'}
                  </TableCell>
                  <PropertyTypeCell>{getOptGroup(attribute)}</PropertyTypeCell>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Overflow>
    </Modal>
  );
};

UserProfileModal.propTypes = {
  visible: PropTypes.bool,
  userId: PropTypes.string,
  profile: PropTypes.objectOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.number, PropTypes.object])
  ),
  identity: PropTypes.objectOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.number, PropTypes.object])
  ),
  timestamp: PropTypes.string,
  onLoad: PropTypes.func,
  onClose: PropTypes.func,
};

const mapStateToProps = (state, { userId }) => ({
  profile: selectAccountUserProfile(state, userId),
});

const mapDispatchToProps = {
  onLoad: fetchUserProfile,
};

const ConnectedUserProfileModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileModal);

export default ConnectedUserProfileModal;
