import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Tr, Td, Link } from '@studio/legacy-components';
import { getLeafNodes } from '@studio/conditions';
import { selectFlow } from 'reducers/account/flows';

const operators = {
  '==': 'equals',
  '!=': "doesn't equal",
  '*': 'contains',
  '!*': "doesn't contain",
  '^': 'starts with',
  '!^': "doesn't start with",
  $: 'ends with',
  '!$': "doesn't end with",
  regex: 'matches',
};

const getConditionsDescription = conditions => {
  const { and, or } = conditions;
  return (and || or)
    .map(clause => {
      if (clause.and || clause.or) {
        return `(${getConditionsDescription(clause)})`;
      }

      const {
        url: { operator, value },
      } = clause;

      if (operator === 'regex' && value === '.*') {
        return 'Any page';
      }

      return `${operators[operator]}: ${value}`;
    })
    .join(` ${and ? 'and' : 'or'} `);
};

const FlowCell = styled(Td)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
`;

const PageCell = styled(Td)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
`;

export const FlowTableRow = ({
  flowId,
  name,
  conditions,
  sortPriority,
  updatedAt,
}) => {
  const urlConditions = conditions?.and?.find(branch =>
    getLeafNodes(branch).some(leaf => leaf.url)
  );

  return (
    <Tr>
      <FlowCell>
        <Link
          smooth
          to={`/flows/${flowId}/settings#flow-priority`}
          title={name}
        >
          {name || flowId}
        </Link>
      </FlowCell>
      <PageCell
        title={urlConditions && getConditionsDescription(urlConditions)}
      >
        {urlConditions ? getConditionsDescription(urlConditions) : 'Any page'}
      </PageCell>
      <Td>{sortPriority || 0}</Td>
      <Td>{moment(updatedAt).format('lll')}</Td>
    </Tr>
  );
};

FlowTableRow.propTypes = {
  flowId: PropTypes.string,
  name: PropTypes.string,
  conditions: PropTypes.object,
  sortPriority: PropTypes.number,
  updatedAt: PropTypes.string,
};

const mapStateToProps = (state, { flowId }) => ({
  name: selectFlow(state, flowId)?.name,
});

export default connect(mapStateToProps)(FlowTableRow);
