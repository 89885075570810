import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propertyOperators } from '@appcues/libcues';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Select, Input, DateInput } from '@studio/legacy-components';
import { millisecondsPerDay } from 'constants/account/conditions';
import { isTimestamp } from 'utils';
import { prepareClauseUpdates } from 'utils/conditions';
import { selectAccountProfileAttributes } from 'selectors/profile-attributes';
import AudienceBaseClause from 'components/Common/Clauses/AudienceBase';
import SelectProfileAttribute from 'components/Common/SelectProfileAttribute';
import AutosuggestProfileAttribute from 'components/Common/AutosuggestProfileAttribute';
import PropertyTextArea from 'components/Common/Clauses/PropertyTextArea';
import {
  SubClause,
  AdditionalInfo,
  ClauseWrapper,
  RelativeDate,
} from 'components/Common/Clauses/styled';

// properties without open-ended inputs
const PRESET_PROPERTIES = {
  _deviceType: ['desktop', 'mobile'],
  _operatingSystem: ['Mac', 'Windows', 'iOS', 'Android', 'other'],
  _browser: [
    'Microsoft Edge',
    'Internet Explorer',
    'Chrome',
    'Firefox',
    'UC Browser',
    'Android',
    'Safari',
    'BlackBerry',
    'Apple CoreMedia',
    'iPad',
    'iPhone',
    'iPod Touch',
    'Nintendo',
    'Opera',
    'PhantomJS',
    'PlayStation Portable',
    'PlayStation',
    'QuickTime',
    'Safari',
    'Xbox',
    'other',
  ],
};

export class PropertyClause extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdditionalInfo: undefined,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { clause } = nextProps;
    const { showAdditionalInfo } = this.state;

    if (showAdditionalInfo == null) {
      this.setState({
        showAdditionalInfo:
          clause.property &&
          clause.operator &&
          [propertyOperators.IN, propertyOperators.NOT_IN].includes(
            clause.operator
          ) &&
          (`${clause.value}` || '').split('\n').length < 2,
      });
    }
  }

  render() {
    const { clause, profileAttributes, updateClause } = this.props;
    const { showAdditionalInfo } = this.state;

    const propertyMeta = Object.values(profileAttributes || {}).find(
      attr => attr.name === clause.property
    );

    const firstSuggestion =
      propertyMeta && propertyMeta.values ? propertyMeta.values[0] : '';
    const isPropertyTimestamp =
      Object.values(profileAttributes || {}).find(
        it => it.name === clause.property
      ) && isTimestamp(firstSuggestion);

    const isPresetProperty = Object.keys(PRESET_PROPERTIES).includes(
      clause.property
    );

    // build a list of all the valid operators given the property

    let operatorOptions = [];

    if (isPresetProperty) {
      operatorOptions = [
        { value: propertyOperators.EQUALS, label: 'is' },
        { value: propertyOperators.NOT_EQUALS, label: "isn't" },
      ];
    } else {
      operatorOptions = [
        { value: propertyOperators.EQUALS, label: 'equals' },
        { value: propertyOperators.NOT_EQUALS, label: "doesn't equal" },
        { value: propertyOperators.CONTAINS, label: 'contains' },
        { value: propertyOperators.NOT_CONTAINS, label: "doesn't contain" },
        { value: propertyOperators.STARTS_WITH, label: 'starts with' },
        {
          value: propertyOperators.NOT_STARTS_WITH,
          label: "doesn't start with",
        },
        { value: propertyOperators.ENDS_WITH, label: 'ends with' },
        { value: propertyOperators.NOT_ENDS_WITH, label: "doesn't end with" },
        { value: propertyOperators.EXISTS, label: 'exists' },
        { value: propertyOperators.NOT_EXISTS, label: "doesn't exist" },
        { value: propertyOperators.IN, label: 'is one of' },
        { value: propertyOperators.NOT_IN, label: "isn't one of" },
        { value: propertyOperators.GREATER_THAN, label: 'is greater than' },
        { value: propertyOperators.LESS_THAN, label: 'is less than' },
        { value: propertyOperators.REGEX, label: 'matches regex' },
      ];

      if (isPropertyTimestamp) {
        operatorOptions = [
          {
            value: propertyOperators.GREATER_THAN_X_DAYS_AGO,
            label: 'occurred more than',
          },
          {
            value: propertyOperators.LESS_THAN_X_DAYS_AGO,
            label: 'occurred less than',
          },
          {
            value: propertyOperators.WITHIN_X_DAYS_AGO,
            label: 'will occur within the next',
          },
          {
            value: propertyOperators.GREATER_THAN_TIME,
            label: 'occurred after',
          },
          { value: propertyOperators.LESS_THAN_TIME, label: 'occurred before' },
          ...operatorOptions,
        ];
      }
    }

    const presetOptions = isPresetProperty
      ? PRESET_PROPERTIES[clause.property].map(value => ({
          label: value,
          value,
        }))
      : [];

    const onValueChange = newValue => {
      updateClause(clause.id, { value: newValue });
    };

    const onRelativeTimeValueChange = e => {
      const numDays = Number.parseInt(e.target.value, 10);
      if (e.target.value === '' || numDays) {
        updateClause(clause.id, {
          value: numDays ? numDays * millisecondsPerDay : '',
        });
      }
    };

    const selectedOption = operatorOptions.find(
      operator => operator.value === clause.operator
    );

    const valueAsNumber = Number.parseInt(clause.value, 10);
    const parsedTime = valueAsNumber / millisecondsPerDay;
    const timeValue = clause.value ? parsedTime : '';

    return (
      <ClauseWrapper>
        <AudienceBaseClause {...this.props}>
          <SubClause>
            <SelectProfileAttribute
              value={clause.property}
              onChange={({ value }) => {
                if (Object.keys(PRESET_PROPERTIES).includes(value)) {
                  updateClause(clause.id, {
                    property: value,
                    value: PRESET_PROPERTIES[value][0],
                  });
                } else if (isPresetProperty) {
                  updateClause(clause.id, { property: value, value: '' });
                } else {
                  updateClause(clause.id, { property: value });
                }
              }}
            />

            <Select
              onChange={({ value }) => {
                updateClause(clause.id, prepareClauseUpdates(clause, value));

                this.setState({
                  showAdditionalInfo: [
                    propertyOperators.IN,
                    propertyOperators.NOT_IN,
                  ].includes(value),
                });
              }}
              options={operatorOptions}
              value={selectedOption}
            />

            {(() => {
              switch (clause.operator) {
                case propertyOperators.EXISTS:
                case propertyOperators.NOT_EXISTS:
                  break;
                case propertyOperators.GREATER_THAN_X_DAYS_AGO:
                case propertyOperators.LESS_THAN_X_DAYS_AGO:
                  return (
                    <RelativeDate>
                      <Input
                        type="text"
                        name="value"
                        aria-label="value"
                        value={timeValue}
                        onChange={onRelativeTimeValueChange}
                      />
                      {parsedTime === 1 ? 'day' : 'days'} ago
                    </RelativeDate>
                  );
                case propertyOperators.WITHIN_X_DAYS_AGO:
                  return (
                    <RelativeDate>
                      <Input
                        type="text"
                        name="value"
                        aria-label="value"
                        value={timeValue}
                        onChange={onRelativeTimeValueChange}
                      />
                      {parsedTime === 1 ? 'day' : 'days'}
                    </RelativeDate>
                  );
                case propertyOperators.IN:
                case propertyOperators.NOT_IN:
                  return (
                    <Button
                      kind="secondary"
                      onClick={() => {
                        this.setState(previous => ({
                          showAdditionalInfo: !previous.showAdditionalInfo,
                        }));
                      }}
                    >
                      {(() => {
                        const numValues = (`${clause.value}` || '').split(
                          '\n'
                        ).length;
                        const units = numValues > 1 ? 'values' : 'value';

                        if (showAdditionalInfo) {
                          return `Hide ${numValues} ${units}`;
                        }
                        return `Show ${numValues} ${units}`;
                      })()}
                    </Button>
                  );
                case propertyOperators.LESS_THAN_TIME:
                case propertyOperators.GREATER_THAN_TIME:
                  return (
                    <DateInput
                      future
                      value={new Date(valueAsNumber)}
                      onChange={date =>
                        updateClause(clause.id, {
                          value: moment(date).startOf('day').format('x'),
                        })
                      }
                      portal
                      placement="top"
                    />
                  );
                default:
                  if (isPropertyTimestamp) {
                    const hasValue =
                      clause.value !== undefined ? clause.value : '';

                    return (
                      <Input
                        type="text"
                        value={hasValue}
                        name="value"
                        aria-label="value"
                        onChange={({ target: { value } }) =>
                          onValueChange(value)
                        }
                      />
                    );
                  }
                  if (isPresetProperty) {
                    return (
                      <Select
                        onChange={({ value }) => {
                          updateClause(clause.id, { value });
                        }}
                        options={presetOptions}
                        value={presetOptions.find(
                          ({ value }) => clause.value === value
                        )}
                      />
                    );
                  }
                  return (
                    <AutosuggestProfileAttribute
                      value={clause.value !== undefined ? clause.value : ''}
                      property={clause.property}
                      onChange={onValueChange}
                    />
                  );
              }
              return null;
            })()}
          </SubClause>
        </AudienceBaseClause>

        {showAdditionalInfo && (
          /**
           * Note that though values are stored in Firebase as `\n` delimited, we allow users to
           * paste text separated by either `\n` or comma.  In the rare case that somebody needs
           * to specify a comma in one of the values, we allow them to escape it as `\,`.
           *
           * For the reasons above, we convert all `\,` to character codes (&#44;) before
           * saving, and convert them back before showing to the user.
           */

          <AdditionalInfo>
            <PropertyTextArea value={clause.value} onChange={onValueChange} />
          </AdditionalInfo>
        )}
      </ClauseWrapper>
    );
  }
}

PropertyClause.propTypes = {
  clause: PropTypes.shape({
    id: PropTypes.string,
    operator: PropTypes.string,
    property: PropTypes.string,
    value: PropTypes.string,
  }),
  profileAttributes: PropTypes.shape({
    name: PropTypes.string,
  }),
  updateClause: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    profileAttributes: selectAccountProfileAttributes(state),
  };
}

export default connect(mapStateToProps)(PropertyClause);
