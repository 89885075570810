import React from 'react';
import PropTypes from 'prop-types';

import {
  Caption,
  ExternalLink,
  H2,
  Overflow,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Text,
} from '@studio/legacy-components';

import {
  IssueLineItem,
  InstallationIssuesNotice,
  InstallationIssuesBody,
  InstallationIssuesWrapper,
  MauHigherReason,
} from 'components/Subscription/styled';

const InstallationIssues = ({
  overallAnonymousPercent,
  overallIdentifiedPercent,
}) => {
  return (
    <InstallationIssuesWrapper>
      <Text>Is your MAU count higher than expected?</Text>

      <Popover>
        <PopoverTrigger asChild>
          <MauHigherReason>Find out why</MauHigherReason>
        </PopoverTrigger>
        <PopoverContent align="end" size="large">
          <InstallationIssuesBody>
            <Text>
              MAU count higher than you would expect? The following might help
              determine what is going on
            </Text>
            <Text bold>User breakdown</Text>
            <Overflow>
              <IssueLineItem>
                <H2>{overallIdentifiedPercent}%</H2>
                <Caption>logged-in users</Caption>
              </IssueLineItem>
              <IssueLineItem>
                <H2>{overallAnonymousPercent}%</H2>
                <Caption>anonymous users</Caption>
              </IssueLineItem>
            </Overflow>
            <InstallationIssuesNotice>
              <Text>
                If you don't want to show Appcues to anonymous users, you (or a
                developer on your team) can remove the{' '}
                <Caption bold>Appcues.anonymous()</Caption> call from your
                installation.{' '}
                <ExternalLink href="https://docs.appcues.com/article/438-anonymous-users">
                  Learn more.
                </ExternalLink>
              </Text>
            </InstallationIssuesNotice>
          </InstallationIssuesBody>
        </PopoverContent>
      </Popover>
    </InstallationIssuesWrapper>
  );
};

InstallationIssues.propTypes = {
  overallAnonymousPercent: PropTypes.number,
  overallIdentifiedPercent: PropTypes.number,
};

export default InstallationIssues;
