import {
  takeEvery,
  put,
  call,
  getContext,
  take,
  all,
} from 'redux-saga/effects';
import toast from 'next/lib/toast';
import { reportError } from 'helpers/error-reporting';
import { update as updateRule } from 'actions/account/rules';
import {
  GOAL_DELETED,
  GOAL_CREATED_FOR_RULE,
  send,
  resolve,
  reject,
  insert,
  replace,
  remove,
  patterns,
  create,
} from 'actions/account/goals';

export function* fetchGoals() {
  try {
    const api = yield getContext('api');
    yield put(send());
    const { data: allGoals } = yield call(api.getAllGoals);
    yield put(
      resolve(
        allGoals.reduce((acc, goal) => {
          const { id } = goal;
          acc[id] = goal;
          return acc;
        }, {})
      )
    );
  } catch (error) {
    yield put(reject(error));
    yield call(reportError, error);
  }
}

export function* createGoal(action) {
  try {
    const {
      payload: { name, conditions },
    } = action;

    const api = yield getContext('api');
    const { data: goal } = yield call(api.createGoal, {
      name,
      conditions,
      goalFormatVersion: 2,
      defaultGoal: false,
      goalVersion: 1,
    });

    yield put(insert(goal));
    yield call(toast.success, 'Goal created successfully');
  } catch (error) {
    yield call(toast.error, 'Failed to create goal');
    yield call(reportError, error);
  }

  // /* global GOAL_CREATED_MODAL_ID */
  // if (window.Appcues && GOAL_CREATED_MODAL_ID) {
  //   window.setTimeout(() => {
  //     window.Appcues.show(GOAL_CREATED_MODAL_ID);
  //   }, 1000);
  // }
}

export function* updateGoal(action) {
  try {
    const {
      payload: { id, delta },
    } = action;

    const api = yield getContext('api');
    const { data: goal } = yield call(api.updateGoal, id, delta);

    yield put(replace(goal));
    yield call(toast.success, 'Goal updated successfully');
  } catch (error) {
    yield call(toast.error, 'Failed to update goal');
    yield call(reportError, error);
  }
}

export function* deleteGoal(action) {
  try {
    const {
      payload: { id },
    } = action;
    const api = yield getContext('api');
    yield call(api.deleteGoal, id);
    yield put(remove(id));
    yield call(toast.success, 'Goal deleted successfully');
  } catch (error) {
    yield call(toast.error, 'Failed to delete goal');
    yield call(reportError, error);
  }
}

export function* createGoalForRule(action) {
  try {
    const {
      payload: { name, conditions, ruleId },
    } = action;

    const [
      {
        payload: { id },
      },
    ] = yield all([
      take(patterns.insert),
      call(createGoal, create({ name, conditions })),
    ]);

    yield put(updateRule(ruleId, { goals: [id] }));
  } catch (error) {
    yield call(reportError, error);
    yield call(toast.error, 'Failed to create goal');
  }
}

export function* goals() {
  yield takeEvery(patterns.create, createGoal);
  yield takeEvery(patterns.update, updateGoal);
  yield takeEvery(GOAL_DELETED, deleteGoal);
  yield takeEvery(GOAL_CREATED_FOR_RULE, createGoalForRule);
}

export default goals;
