import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card } from '@studio/legacy-components';
import { history } from 'next/lib/history';
import { asRequest } from 'next/entities/requests';
import { Shape as FlowShape } from 'next/entities/flows';
import { Shape as PinShape } from 'next/entities/experiences';
import { getFirstStepIds } from 'next/entities/screenshots';
import { Shape as SegmentShape } from 'next/entities/segments';
import { Shape as TagShape } from 'next/entities/tags';
import { Screenshot } from 'next/components/Screenshot';
import { AppShape } from 'next/entities/apps';
import Cell from './Cell';
import Row from './Row';

// XXX: Temporary placeholder
const Placeholder = styled.div`
  align-items: center;
  display: flex;
  height: 96px;
  justify-content: center;
  width: 180px;

  ${({ view }) => view === 'grid' && 'height: 120px;'}
`;

export function Experience({
  actions,
  domains,
  experience,
  metric,
  segments,
  tags,
  type,
  view,
  app,
  deferred,
  redirectTo,
  settingsLink,
}) {
  const { id, published, publishedAt } = experience;

  // Conditionally change where the link/click goes depending on the state of
  // the experience. Specifically, if the experience is published then take them to
  // analytics. Otherwise, take them to settings.
  const to = useMemo(() => {
    const path = published ? 'analytics' : 'settings';
    const link = type === 'mobile' ? `mobile/flows` : type;

    return redirectTo ?? `/${link}/${id}/${path}`;
  }, [id, published, type, redirectTo]);

  const handleClick = useCallback(() => {
    history.push(to);
  }, [to]);

  const { step, state, version } = useMemo(
    () => ({
      step: getFirstStepIds(type, experience),
      state: published ? 'PUBLISHED' : 'DRAFT',
      version: published ? new Date(publishedAt).getTime() : null,
    }),
    [type, experience, published, publishedAt]
  );

  return (
    <Card
      key={id}
      forwardedAs="li"
      image={
        <Placeholder view={view}>
          <Screenshot
            step={step}
            state={state}
            version={version}
            deferred={deferred}
          />
        </Placeholder>
      }
      kind={view}
      onClick={handleClick}
      status={published ? 'active' : 'inactive'}
    >
      {view === 'list' && (
        <Row
          actions={actions}
          experience={experience}
          domains={domains}
          metric={metric}
          segments={segments}
          tags={tags}
          to={to}
          type={type}
          app={app}
          settingsLink={settingsLink ?? redirectTo}
        />
      )}

      {view === 'grid' && (
        <Cell
          actions={actions}
          experience={experience}
          tags={tags}
          to={to}
          type={type}
          settingsLink={settingsLink ?? redirectTo}
        />
      )}
    </Card>
  );
}

Experience.propTypes = {
  actions: PropTypes.node,
  domains: asRequest(PropTypes.arrayOf(PropTypes.string)),
  experience: PropTypes.oneOfType([FlowShape, PinShape]),
  metric: PropTypes.node,
  segments: asRequest(PropTypes.arrayOf(SegmentShape)),
  tags: asRequest(PropTypes.arrayOf(TagShape)),
  view: PropTypes.oneOf(['list', 'grid']),
  type: PropTypes.oneOf(['flows', 'pins', 'mobile', 'banner', 'launchpad']),
  app: AppShape,
  deferred: PropTypes.bool,
  redirectTo: PropTypes.string,
  settingsLink: PropTypes.string,
};

export default Experience;
