import React from 'react';
import styled from 'styled-components';

const { Spinner } = require('@appcues/sonar');

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: calc(var(--nav-width) / 2);
  display: flex;
  justify-content: center;
`;

const FormSpinner = () => {
  return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  );
};

export default FormSpinner;
