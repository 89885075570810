import { v4 as uuidv4 } from 'uuid';
import { createCollectionReducer } from 'reducers/account/collections';
import { selectIsSynced } from 'reducers/account/requests';
import {
  ITEM_UPDATED,
  ITEM_REMOVED,
  TYPE,
  ITEMS_SWAPPED,
  ITEM_ADDED,
} from './actions';

import ITEM_TEMPLATE from './item-template.json';

/**
 * Checklist items are stored as an object, but represent
 * an ordered array and must include contiguous sequnetial
 * indeces.  Additionally, the SDK expects a `dependsOn`
 * key which points to the previous item like a linked-list
 */

const normalizeItems = (items = {}) => {
  const sortedItems = Object.values(items).sort(
    ({ index: a }, { index: b }) => a - b
  );

  return sortedItems
    .map((item, i) => ({
      ...item,
      index: i,
      dependsOn: [...(i > 0 ? [sortedItems[i - 1].id] : [])],
    }))
    .reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
};

const reducer = createCollectionReducer(TYPE);

export default function (state, action) {
  switch (action.type) {
    case ITEM_UPDATED: {
      const {
        payload: { id, itemId, delta },
      } = action;

      const {
        [id]: checklist,
        [id]: {
          items,
          items: { [itemId]: item },
        },
      } = state;

      return {
        ...state,
        [id]: {
          ...checklist,
          items: {
            ...items,
            [itemId]: {
              ...item,
              ...delta,
            },
          },
        },
      };
    }

    case ITEM_REMOVED: {
      const {
        payload: { id, itemId },
      } = action;

      const {
        [id]: checklist,
        [id]: {
          items: { [itemId]: omit, ...items },
        },
      } = state;

      return {
        ...state,
        [id]: {
          ...checklist,
          items: normalizeItems(items),
        },
      };
    }

    case ITEMS_SWAPPED: {
      const {
        payload: { id, item1Id, item2Id },
      } = action;

      const {
        [id]: checklist,
        [id]: {
          items,
          items: { [item1Id]: item1, [item2Id]: item2 },
        },
      } = state;

      return {
        ...state,
        [id]: {
          ...checklist,
          items: normalizeItems({
            ...items,
            [item1Id]: { ...item1, index: item2.index },
            [item2Id]: { ...item2, index: item1.index },
          }),
        },
      };
    }

    case ITEM_ADDED: {
      const {
        payload: { id },
      } = action;

      const {
        [id]: checklist,
        [id]: { items = {} },
      } = state;

      const { length: numItems } = Object.keys(items);

      const newItemId = uuidv4();

      return {
        ...state,
        [id]: {
          ...checklist,
          items: normalizeItems({
            ...items,
            [newItemId]: {
              ...ITEM_TEMPLATE,
              id: newItemId,
              index: numItems,
            },
          }),
        },
      };
    }

    default:
      return reducer(state, action);
  }
}

export const selectAccountChecklists = state => state.account[TYPE];
export const selectAccountChecklistsSynced = state =>
  selectIsSynced(state, TYPE);

export const selectValidChecklists = state => {
  const allChecklists = selectAccountChecklists(state) || {};
  return Object.values(allChecklists).filter(
    checklist => typeof checklist.published !== 'undefined'
  );
};

export const selectAccountChecklist = (state, checklistId) =>
  selectAccountChecklists(state)[checklistId];

export const selectChecklistTargetingOptions = state =>
  Object.values(selectAccountChecklists(state)).map(({ id, internalName }) => ({
    label: internalName || id,
    value: id,
  }));
