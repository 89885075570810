import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CPage, H2, CCol, CRow, Text } from '@appcues/component-library';
import { BackArrow, Header } from './styled';

// This is a temporary hack to make old page headers look like new one
const TitleBar = styled(CPage.TitleBar)`
  min-height: 108px;
  position: sticky;
  top: 0;
  z-index: 10;
`;

export default function IndexPage({
  title,
  description,
  subHeaderActions,
  children,
  onClickBack,
}) {
  const hasTitleOrDescAndSubHeaderActions =
    title || (description && subHeaderActions);

  return (
    <>
      {hasTitleOrDescAndSubHeaderActions && (
        <TitleBar data-no-print>
          <CPage.TitleBar.Content>
            <CCol>
              {onClickBack ? (
                <Header onClick={onClickBack}>
                  <BackArrow icon="chevron-left" />
                  <H2>{title}</H2>
                </Header>
              ) : (
                <CRow>
                  <H2>{title}</H2>
                </CRow>
              )}
              {description && (
                <CRow marginTop="0.5em" fontColor="$gray-1">
                  <Text color="$gray-5">{description}</Text>
                </CRow>
              )}
            </CCol>
          </CPage.TitleBar.Content>
          {subHeaderActions && (
            <CPage.TitleBar.Actions>{subHeaderActions}</CPage.TitleBar.Actions>
          )}
        </TitleBar>
      )}
      {children}
    </>
  );
}

IndexPage.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  subHeaderActions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
