import { useEffect, useRef } from 'react';

/**
 * returns a flag that indicates if the component has already been mounted
 * (a first render occured).
 * As long as this hook is used first, the ref value will be immediately
 * reflected in the next effect
 * @returns
 */
const useIsMounted = () => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
  }, []);

  return mounted.current;
};

export default useIsMounted;
