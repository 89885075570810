import React from 'react';
import PropTypes from 'prop-types';
import { Row } from '@appcues/component-library';

import Badge from './Badge';

export const CreatedUpdatedHeader = ({
  createdAt,
  createdByUserId,
  updatedAt,
  updatedByUserId,
}) => (
  <Row marginTop={16} marginBottom={8} alignItems="center">
    {createdAt && (
      <Badge action="Created" timestamp={createdAt} id={createdByUserId} />
    )}
    {updatedAt && updatedAt !== createdAt && (
      <Badge action="Updated" timestamp={updatedAt} id={updatedByUserId} />
    )}
  </Row>
);

CreatedUpdatedHeader.propTypes = {
  createdAt: PropTypes.number,
  createdByUserId: PropTypes.string,
  updatedAt: PropTypes.number,
  updatedByUserId: PropTypes.string,
};

export default CreatedUpdatedHeader;
