import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@studio/legacy-components';
import languages from 'constants/languages';
import { getChosenLanguageLabel } from 'helpers/locales';
import AudienceBaseClause from 'components/Common/Clauses/AudienceBase';
import { SubClause } from 'components/Common/Clauses/styled';

const OPERATOR_OPTIONS = [
  { value: false, label: 'is' },
  { value: true, label: "isn't" },
];

const LANGUAGE_OPTIONS = languages.map(language => {
  const label = getChosenLanguageLabel(language);
  return { value: language.code, label };
});

function LanguageClause(props) {
  const { clause, updateClause } = props;

  const selectedOperator = OPERATOR_OPTIONS.find(
    ({ value }) => value === !!clause.negative
  );

  const selectedLanguage = LANGUAGE_OPTIONS.find(
    ({ value }) => value === clause.value
  );

  return (
    <AudienceBaseClause {...props}>
      <SubClause>
        <Select
          onChange={({ value }) => {
            updateClause(clause.id, { negative: value });
          }}
          options={OPERATOR_OPTIONS}
          value={selectedOperator}
        />
        <Select
          onChange={({ value }) => {
            updateClause(clause.id, { value });
          }}
          options={LANGUAGE_OPTIONS}
          value={selectedLanguage}
        />
      </SubClause>
    </AudienceBaseClause>
  );
}

LanguageClause.propTypes = {
  clause: PropTypes.shape({
    id: PropTypes.string,
    negative: PropTypes.bool,
    value: PropTypes.string,
  }),
  updateClause: PropTypes.func,
};

export default LanguageClause;
