import {
  getNestedConditionsFromFlattenedClauses,
  clauseKeys,
} from '@appcues/libcues';
import { selectAccountRule, selectAccountRules } from 'reducers/account/rules';
import { selectAccountStepConditions } from 'reducers/account/conditions';

export const selectRuleAndConditions = (state, flowId) => {
  const rule = selectAccountRule(state, flowId);
  const clauses = selectAccountStepConditions(state, flowId);

  if (!rule) {
    return null;
  }

  return {
    ...rule,
    conditions: getNestedConditionsFromFlattenedClauses(
      clauses,
      null,
      clauseKeys
    ),
  };
};

export const selectRulesAndConditions = state => {
  const rules = selectAccountRules(state);
  if (!rules) {
    return null;
  }
  return Object.keys(rules).reduce((acc, ruleId) => {
    acc[ruleId] = selectRuleAndConditions(state, ruleId);
    return acc;
  }, {});
};
