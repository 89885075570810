import React from 'react';
import { Text } from '@appcues/sonar';
import { formatScheduleDate } from './dates';
import { ScheduleType } from './types';

const SchedulingTooltipMessage = ({ schedule }) => {
  const hasStartAndEnd = schedule.startDate && schedule.endDate;
  const hasStartOnly = schedule.startDate && !schedule.endDate;
  const hasEndOnly = !schedule.startDate && schedule.endDate;

  if (hasStartAndEnd) {
    return (
      <Text colorToken="foreground-tooltip">
        Scheduled to publish on {formatScheduleDate(schedule.startDate)}
        <br />
        {schedule.endDate &&
          `and expire on ${formatScheduleDate(schedule.endDate)}`}
      </Text>
    );
  }

  if (hasStartOnly) {
    return (
      <Text colorToken="foreground-tooltip">
        Scheduled to publish on {formatScheduleDate(schedule.startDate)}
      </Text>
    );
  }

  if (hasEndOnly) {
    return (
      <Text colorToken="foreground-tooltip">
        Scheduled to expire on {formatScheduleDate(schedule.endDate)}
      </Text>
    );
  }

  return null;
};

SchedulingTooltipMessage.propTypes = {
  schedule: ScheduleType,
};

export default SchedulingTooltipMessage;
