import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip, Button, Link } from '@studio/legacy-components';
import { CButton } from '@appcues/component-library';
import PublishDropdown from 'components/Common/Publishing/PublishDropdown';
import ButtonWithTooltip from 'components/Common/ButtonWithTooltip';
import {
  PUBLISHING_MODAL,
  FLOW_PUBLISHING_MODAL,
  TEST_FLOW_MODAL,
} from 'constants/globals';
import { ACCOUNT_PERMISSIONS } from 'constants/accountManagement/permissions';
import { STEP_TYPE_IDS, STEP_TYPES } from 'constants/stepTypes';
import { PUBLISH_ACTIONS } from 'constants/modalStatuses';
import { showModal } from 'actions/currentModal';
import { selectAccountMeta } from 'reducers/account/meta';
import getContentAction from 'actions/publishing';
import { selectIsInstalled } from 'reducers/account/installedDomains';

export const PERMISSIONS_TOOLTIP_TXT =
  'Contact a team admin or publisher to publish.';

export const HAS_EXPERIMENT_TOOLTIP_TXT =
  'To publish changes or unpublish the Flow, end the experiment.';

export const TooltipLabel = styled.div`
  width: 200px;
  white-space: initial;
`;

export function Publishing({
  accountMeta,
  conditions,
  contentStatus,
  disabledTooltipTxt,
  enableTestMode = false,
  existingTestFlowId,
  flowName = '',
  isDisabled,
  isPublished,
  installed,
  lastUpdatedAtTimestamp,
  onAction,
  previewUrl = '',
  publishToApi,
  role,
  showModal: show,
  showTestOptions = false,
  stepId,
  stepType,
  hasLiveExperiment,
  checklist,
}) {
  const confirmAction = publishActionType => {
    const publishingModalType =
      stepType === STEP_TYPE_IDS.JOURNEY
        ? FLOW_PUBLISHING_MODAL
        : PUBLISHING_MODAL;

    show(publishingModalType, {
      publishActionType,
      stepId,
      onAction,
      publishToApi,
    });
  };

  const confirmPublishAction = () => {
    confirmAction(PUBLISH_ACTIONS.PUBLISH);
  };

  const confirmUnpublishAction = () => {
    confirmAction(PUBLISH_ACTIONS.UNPUBLISH);
  };

  const handleTestFlowClick = () => {
    const stepProperties = STEP_TYPES[stepType] || {};
    const { contentType } = stepProperties;

    show(TEST_FLOW_MODAL, {
      stepId,
      contentType,
      existingTestFlowId,
      previewUrl,
      conditions,
      flowName,
      accountMeta,
    });
  };

  const { publishedAndDirty } = contentStatus;
  const cannotPublish = role === ACCOUNT_PERMISSIONS.EDITOR || isDisabled;

  const getTooltipTxt = () => {
    if (isDisabled && disabledTooltipTxt) {
      return disabledTooltipTxt;
    }
    if (isPublished && hasLiveExperiment) {
      return HAS_EXPERIMENT_TOOLTIP_TXT;
    }
    if (!installed) {
      return (
        <>
          In order to publish live experiences to your users, you'll have to
          install Appcues directly into your codebase. Don't worry...it's super
          easy.{' '}
          <Link to="/settings/installation">Click here to learn how.</Link>
        </>
      );
    }
    return PERMISSIONS_TOOLTIP_TXT;
  };

  const lastChangedAt = moment(lastUpdatedAtTimestamp).fromNow();

  const publishOptions = [
    {
      label: 'Push Changes',
      value: PUBLISH_ACTIONS.PUBLISH,
      disabled: cannotPublish,
      icon: 'upload',
      altText: 'Push changes option',
    },
    {
      label: 'Discard Changes',
      value: PUBLISH_ACTIONS.REVERT,
      disabled: cannotPublish,
      icon: 'times',
      altText: 'Discard changes option',
    },
    {
      label: 'Unpublish',
      value: PUBLISH_ACTIONS.UNPUBLISH,
      disabled: cannotPublish,
      icon: 'level-down-alt',
      altText: 'Unpublish flow option',
    },
  ];

  const coolTipProps = {
    marginRight: '18px',
  };

  const isTestable = showTestOptions && enableTestMode;
  return (
    <>
      {isTestable && (
        <Tooltip
          wrapped
          persist
          placement="bottom"
          disabled={installed}
          label={
            <TooltipLabel>
              In order to test how your users will interact with live
              experiences, you'll have to install Appcues directly into your
              codebase. Don't worry...it's super easy.{' '}
              <Link to="/settings/installation">Click here to learn how.</Link>
            </TooltipLabel>
          }
        >
          <CButton
            isDisabled={!installed}
            type="warning"
            onClick={handleTestFlowClick}
          >
            Test
          </CButton>
        </Tooltip>
      )}
      {!isPublished && !publishedAndDirty && (
        <Tooltip
          wrapped
          persist
          placement={checklist ? 'left' : 'bottom'}
          label={<TooltipLabel>{getTooltipTxt()}</TooltipLabel>}
          disabled={!cannotPublish && installed}
        >
          <Button
            onClick={confirmPublishAction}
            disabled={cannotPublish || !installed}
            type="positive"
          >
            Publish
          </Button>
        </Tooltip>
      )}
      {isPublished && !publishedAndDirty && (
        <ButtonWithTooltip
          isDisabled={cannotPublish || hasLiveExperiment}
          buttonText="Unpublish"
          tooltipText={getTooltipTxt()}
          onClick={confirmUnpublishAction}
          coolTipProps={coolTipProps}
        />
      )}
      {publishedAndDirty && (
        <PublishDropdown
          options={publishOptions}
          confirmAction={confirmAction}
          optionDetail={lastChangedAt}
          publishDisabled={cannotPublish || hasLiveExperiment}
          isPublished={isPublished}
          disabledTooltipTxt={getTooltipTxt()}
        />
      )}
    </>
  );
}

Publishing.propTypes = {
  contentStatus: PropTypes.shape({
    publishedAndDirty: PropTypes.bool,
  }),
  enableTestMode: PropTypes.bool,
  isPublished: PropTypes.bool,
  lastUpdatedAtTimestamp: PropTypes.number,
  showTestOptions: PropTypes.bool,
  stepType: PropTypes.string,
  role: PropTypes.string,
  areInstalledDomainsSynced: PropTypes.bool,
  isDisabled: PropTypes.bool,
  disabledTooltipTxt: PropTypes.string,
  stepId: PropTypes.string,
  installedDomains: PropTypes.arrayOf(PropTypes.string),
  previewUrl: PropTypes.string,
  existingTestFlowId: PropTypes.string,
  flowName: PropTypes.string,
  conditions: PropTypes.object,
  accountMeta: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  onAction: PropTypes.func,
  publishToApi: PropTypes.func,
  hasLiveExperiment: PropTypes.bool,
  installed: PropTypes.bool,
  checklist: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    accountMeta: selectAccountMeta(state),
    installed: selectIsInstalled(state),
  };
}

const mapDispatchToProps = {
  showModal,
  onAction: (actionType, { content_id, content_type }) =>
    getContentAction(actionType, { id: content_id, type: content_type }),
};

export default connect(mapStateToProps, mapDispatchToProps)(Publishing);
