import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Button, H2, Select } from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';
import { selectAccountChecklist } from 'entities/checklists';
import CharacterCount from 'components/Common/Forms/CharacterCount';
import ColorPicker from 'components/Common/ColorPicker';
import FieldGroup from 'components/Common/Forms/FieldGroup';
import Input from 'components/Common/Forms/Input';
import BeaconOffset from 'components/ChecklistsEdit/BeaconOffset';

export const defaultBeaconTextColor = '#FFFFFF';

export const dropdownOptions = [
  { label: 'Bottom Left', value: 'Bottom Left' },
  { label: 'Bottom Right', value: 'Bottom Right' },
];

const Wrapper = styled.div`
  ${Select} + ${Button} {
    margin-left: 10px;
  }
`;

export function BeaconFields({
  beaconText,
  beaconTextColor,
  beaconTextError,
  beaconVerticalOffset,
  beaconHorizontalOffset,
  position,
  resetBeaconTextError,
  updateChecklistStyles,
  updateChecklistStylesDropdown,
  updateChecklistStylesSlider,
  updateLimitedChecklistTextToken,
}) {
  const [collapsed, toggle] = useToggle(true);

  const updateChecklistBeaconTextColor = input => {
    updateChecklistStyles('beaconTextColor', input);
  };

  const updateChecklistBeaconTextColorSlider = hex => {
    updateChecklistStylesSlider('beaconTextColor', hex);
  };

  const updateChecklistBeaconText = input => {
    updateLimitedChecklistTextToken('beaconText', 20, input);
  };

  const updateChecklistBeaconPosition = input => {
    updateChecklistStylesDropdown('position', input);
  };

  const updateBeaconHorizontalOffset = value => {
    const data = { target: { value: Number(value) || 0 } };
    updateChecklistStyles('beaconHorizontalOffset', data);
  };

  const updateBeaconVerticalOffset = value => {
    const data = { target: { value: Number(value) || 0 } };
    updateChecklistStyles('beaconVerticalOffset', data);
  };

  const selectedPosition = dropdownOptions.find(
    ({ value }) => value === position
  );

  return (
    <Wrapper>
      <H2>Beacon</H2>
      <FieldGroup title="Position">
        <Select
          onChange={updateChecklistBeaconPosition}
          options={dropdownOptions}
          value={selectedPosition}
        />
        <Button kind="secondary" onClick={toggle}>
          Adjust
        </Button>
      </FieldGroup>
      <BeaconOffset
        collapsed={collapsed}
        beaconVertical={beaconVerticalOffset}
        beaconHorizontal={beaconHorizontalOffset}
        updateBeaconHorizontalOffset={updateBeaconHorizontalOffset}
        updateBeaconVerticalOffset={updateBeaconVerticalOffset}
      />
      <FieldGroup title="Beacon Text">
        <Input
          type="text"
          value={beaconText}
          placeholder="Get Started"
          invalid={beaconTextError}
          onBlur={resetBeaconTextError}
          onChange={updateChecklistBeaconText}
        />
        <CharacterCount input={beaconText} maxLength={20} />
      </FieldGroup>
      <FieldGroup title="Beacon Text Color">
        <ColorPicker
          color={beaconTextColor}
          defaultColor={defaultBeaconTextColor}
          onChangeSlider={updateChecklistBeaconTextColorSlider}
          onChangeInput={updateChecklistBeaconTextColor}
        />
      </FieldGroup>
    </Wrapper>
  );
}

BeaconFields.propTypes = {
  beaconText: PropTypes.string,
  beaconTextColor: PropTypes.string,
  beaconTextError: PropTypes.string,
  beaconVerticalOffset: PropTypes.number,
  beaconHorizontalOffset: PropTypes.number,
  position: PropTypes.oneOf(['Bottom Left', 'Bottom Right']),
  resetBeaconTextError: PropTypes.func,
  updateChecklistStyles: PropTypes.func,
  updateChecklistStylesDropdown: PropTypes.func,
  updateChecklistStylesSlider: PropTypes.func,
  updateLimitedChecklistTextToken: PropTypes.func,
};

const mapStateToProps = (state, { checklistId }) => {
  const checklist = selectAccountChecklist(state, checklistId);
  const beaconVerticalOffset =
    checklist.styles && checklist.styles.beaconVerticalOffset;
  const beaconHorizontalOffset =
    checklist.styles && checklist.styles.beaconHorizontalOffset;
  const beaconText = checklist.textTokens && checklist.textTokens.beaconText;
  const beaconTextColor = checklist.styles && checklist.styles.beaconTextColor;
  const position = checklist.styles && checklist.styles.position;

  return {
    beaconHorizontalOffset,
    beaconText,
    beaconTextColor,
    beaconVerticalOffset,
    position,
  };
};

export default connect(mapStateToProps)(BeaconFields);
