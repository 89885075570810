import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Label, Select } from '@studio/legacy-components';
import { SelectOptionsShape } from 'next/lib/shapes';
import { selectTagsOptions } from 'next/lib/selectors-options';

export const TagsField = ({ onChange, options }) => (
  <Label>
    Tags
    <Select
      isClearable
      isMulti
      kind="default"
      name="tags"
      placeholder="Select Tags (Optional)"
      portal
      onChange={selectedOptions => {
        onChange(selectedOptions.map(({ value }) => value));
      }}
      options={options}
    />
  </Label>
);

TagsField.propTypes = {
  onChange: PropTypes.func,
  options: SelectOptionsShape,
};

const mapStateToProps = state => ({
  options: selectTagsOptions(state),
});

export default connect(mapStateToProps)(TagsField);
