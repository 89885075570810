import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Icon } from '@studio/legacy-components';

import CoolTip from 'components/Common/CoolTip';

import { selectLocale } from 'reducers/account/locales';
import { deleteLocale } from 'actions/account/locales';
import { asPromised } from 'utils/as-promised';
import DeleteLocaleFailedDialog from './DeleteLocaleFailedDialog';
import DeleteLocaleDialog from './DeleteLocaleDialog';

export const DeleteLocaleManager = ({ disabled, confirm, onClick }) => {
  const [isConfirmDialogShowing, setConfirmDialog] = useState(false);
  const [isFailDialogShowing, setFailDialog] = useState(false);

  const handleDelete = useCallback(() => {
    onClick()
      .then(() => setConfirmDialog(false))
      .catch(() => setFailDialog(true));
  }, [onClick]);

  return (
    <>
      {isConfirmDialogShowing && (
        <DeleteLocaleDialog
          onSubmit={handleDelete}
          onClose={() => setConfirmDialog(false)}
        />
      )}
      {isFailDialogShowing && (
        <DeleteLocaleFailedDialog onClose={() => setFailDialog(false)} />
      )}
      <CoolTip
        tip="Languages that are used by published flows cannot be deleted"
        disabled={!disabled}
      >
        <StyledIcon
          data-test-id="styled-icon"
          icon="times"
          titleText={!disabled && 'Delete'}
          disabled={disabled}
          onClick={confirm ? () => setConfirmDialog(true) : handleDelete}
        />
      </CoolTip>
    </>
  );
};

const StyledIcon = styled(Icon)`
  color: ${({ theme, disabled }) =>
    disabled ? theme['$gray-3'] : theme['$gray-4']};
  width: 12px;
  &:not([disabled]):hover {
    color: ${({ theme }) => theme['$gray-6']};
  }
`;

DeleteLocaleManager.propTypes = {
  disabled: PropTypes.bool,
  confirm: PropTypes.bool,
  onClick: PropTypes.func,
};

const mapStateToProps = (state, { id }) => {
  const locale = selectLocale(state, id);
  return {
    disabled: locale.hasPublishedTranslations,
    confirm: locale.hasTranslations,
  };
};

const mapDispatchToProps = (dispatch, { id }) => ({
  onClick: () => asPromised(dispatch, deleteLocale(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteLocaleManager);
