import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { H3, P, Box, Flex } from '@appcues/component-library';
import toast from 'next/lib/toast';
import SegmentCard from 'components/Common/SegmentCard';
import { selectAccountId, selectAccountUuid } from 'reducers/account/meta';
import { createLifecycleSegments } from 'helpers/content-api';
import { reportError } from 'helpers/error-reporting';
import { navigate } from 'actions/routing';
import { numberWithAbbrevMillions } from 'utils/textFormatting';
import { EXPLORERS, BEGINNERS, REGULARS, CHAMPIONS } from 'constants/segments';
import LearnMoreButton from './LearnMoreButton';

const cardSortOrder = [EXPLORERS, BEGINNERS, REGULARS, CHAMPIONS];

// return segments with the meta prop that indicates they're lifecycle
const filterAndSortSegments = segments => {
  const segmentArr = Object.values(segments);
  const filtered = segmentArr.filter(segment => {
    return segment.meta && segment.meta.userLifecycleStage;
  });
  return filtered.sort((first, second) => {
    const firstStage = first.meta.userLifecycleStage;
    const secondStage = second.meta.userLifecycleStage;
    if (
      cardSortOrder.indexOf(firstStage) < cardSortOrder.indexOf(secondStage)
    ) {
      return -1;
    }
    return 1;
  });
};

export const LifecycleSegmentCard = styled(SegmentCard)`
  display: inline-block;
  flex: 1;
  min-width: 0;
  margin-right: 40px;
  position: relative;

  .svg-inline--fa.fa-caret-right {
    color: ${props => props.theme['$gray-4']};
    display: inline-block;
    position: absolute;
    right: -25px;
    top: calc(50% - 6px);
  }

  &:after {
    display: none;
    font-family: 'Font Awesome\ 5 Free';
    content: '\f0da';
    font-weight: 900;
  }

  &:last-child {
    margin-right: 0;

    .svg-inline--fa.fa-caret-right {
      display: none;
      content: '';
    }
  }
`;

export function LifecycleSegments({
  accountApiKey,
  accountId,
  actions,
  marginTop,
  segments,
  userCountsBySegment = {},
}) {
  const [isCreatingSegments, setIsCreatingSegments] = useState(false);

  const handleSegmentCreationSuccess = (result, stageOfCardClicked) => {
    const { navigate: navigateTo } = actions;
    const segmentsCreated = result.data.segments;
    const idOfSegmentClicked = segmentsCreated.reduce((acc, segment) => {
      if (acc) {
        return acc;
      }

      if (segment.meta.userLifecycleStage === stageOfCardClicked) {
        return segment.id;
      }
      return null;
    }, null);

    if (!idOfSegmentClicked) {
      throw new ReferenceError(
        'Could not figure out which placeholder segment was clicked'
      );
    }

    toast.success('All set!');
    navigateTo(`/segments/${idOfSegmentClicked}/edit`);
  };

  const handlePlaceholderClick = stageOfCardClicked => {
    return new Promise((resolve, reject) => {
      if (isCreatingSegments) {
        return; // don't allow repeat triggers of segment creation
      }

      toast.show('Hang on, setting up segments...');

      setIsCreatingSegments(true);

      createLifecycleSegments({
        account_id: accountId,
        account_api_key: accountApiKey,
      })
        .then(result => {
          // it would be nice if the card could handle navigating after
          // segments have been created -- but they get remounted once
          // the placeholders are replaced by real segments
          handleSegmentCreationSuccess(result, stageOfCardClicked);
          resolve();
        })
        .catch(error => {
          const message =
            "Oops! We couldn't create your segments. Try again later.";
          toast.show(message);
          reportError(error);
          reject(new Error(message));
        });
    });
  };

  const showingPlaceholderSegments = false;
  const lifecycleSegments = filterAndSortSegments(segments);

  const cards = lifecycleSegments.map(segment => {
    return (
      <LifecycleSegmentCard
        aria-label="Lifecycle Segment Card"
        segmentId={segment.id}
        key={segment.id}
        isPlaceholderLifecycleSegment={showingPlaceholderSegments}
        handlePlaceholderClick={
          showingPlaceholderSegments ? handlePlaceholderClick : null
        }
      />
    );
  });

  if (cards.length === 0) {
    return null;
  }

  const id = Object.keys(userCountsBySegment)[0] || null;
  const userCounts = id ? userCountsBySegment[id].count : 0;

  return (
    <Flex flexDirection="column" marginTop={marginTop}>
      <Flex
        is="header"
        marginBottom={24}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <H3 float="left" lineHeight="40px">
            Flywheel segments
          </H3>
          <P
            type="tertiary"
            float="left"
            lineHeight="40px"
            marginBottom={0}
            marginLeft={10}
          >
            {(userCounts || userCounts === 0) &&
              `(${numberWithAbbrevMillions(
                userCounts
              )} total users in the last 7
                days)`}
          </P>
        </Box>
        <LearnMoreButton />
      </Flex>
      <Box
        className="lifecycle_segments--card_row"
        is="div"
        display="flex"
        marginBottom="45px"
        opacity={isCreatingSegments ? '0.5' : '1.0'}
      >
        {cards}
      </Box>
    </Flex>
  );
}

function mapStateToProps(state) {
  return {
    accountId: selectAccountId(state),
    accountApiKey: selectAccountUuid(state),
  };
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      navigate,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LifecycleSegments);
