import zendeskSupportImg from 'assets/images/zendesk-support-integration.png';
import hubspotImg from 'assets/images/hubspot-integration.png';
import slackImg from 'assets/images/slack-integration.png';
import marketoImg from 'assets/images/marketo-integration.png';

export const INTEGRATION_MESSAGING = {
  'hubspot-two-way-integration': {
    integrationAvailable: 'The HubSpot Two-way integration is now available.',
    integrationInProgress:
      "To configure the HubSpot integration, please follow the steps in the popup. If you're blocking popups, try disabling popups for this site and try again.",
    alt: 'Appcues <> Hubspot Integration',
    src: hubspotImg,
  },
  'zendesk-support-two-way-integration': {
    integrationAvailable:
      'The Two-Way Zendesk Support Integration is now available.',
    integrationInProgress:
      "To configure the Zendesk integration, please follow the steps in the popup. If you're blocking popups, try disabling popups for this site and try again.",
    alt: 'Appcues <> Zendesk Support Integration',
    src: zendeskSupportImg,
  },
  'slack-integration': {
    integrationAvailable: 'Slack integration is now available.',
    integrationInProgress:
      "To configure the Slack integration, please follow the steps in the popup. If you're blocking popups, try disabling popups for this site and try again.",
    alt: 'Appcues <> Slack Integration',
    src: slackImg,
  },
  marketo: {
    integrationAvailable: 'Marketo integration is now available.',
    integrationInProgress:
      "To configure the Marketo integration, please follow the steps in the popup. If you're blocking popups, try disabling popups for this site and try again.",
    alt: 'Appcues <> Marketo Integration',
    src: marketoImg,
  },
};
