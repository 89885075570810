import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import { Notice } from '@appcues/component-library';
import {
  Button,
  ButtonGroup,
  Caption,
  ExternalLink,
  Select,
} from '@studio/legacy-components';
import BaseModal from 'components/ModalContainer/BaseModal';
import { requestNpsCsvExport } from 'helpers/csv-exports';
import { REQUESTED_CSV_EXPORT, CLICKED_BUTTON } from 'constants/events';
import { EXTRACTION_V2 } from 'constants/features';
import { selectAccountFeature } from 'reducers/account/features';

const NPS_CSV_EXPORT_DATE_RANGE_OPTIONS = [
  { value: 30, label: 'Last 30 days' },
  { value: 90, label: 'Last 90 days' },
  { value: 180, label: 'Last 180 days' },
  // { value: 365, label: "Last 365 days" }
];

/*
  NOTES for converting this to the standard CSV export modal:

  I spent some time trying this, but it would take a bit of effort.

  Here's the deal:
  - The standard CSV export modal is just called ExportModal
    (see ModalContainer/ExportModal/index.js)
  - It's really only made for checklist or flow exports; to support
    NPS, we need to send csvType = 'nps' so the Extraction API
    knows what to do with it, but it only uses a boolean switch,
    so no dice.
  - The UI of the export modal also needs to change by type:
    for NPS, we want to show only Full or Responses, and the copy
    for the responses should change to be more appropriate, i.e.:
    "NPS Survey Responses" rather than "Just Form Responses".

  Not too bad, but not worth refactoring right at this moment.

  Other than that, changing the showModal constant in
  SatisfactionFeedback/index.js to the EXPORT_MODAL gets
  you pretty far.
*/

const Wrapper = styled.div`
  ${Select} {
    margin-bottom: 1em;
  }
`;

const Content = styled.div`
  > * {
    margin-bottom: 1em;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

class ExportNPSResultsToCSVModal extends Component {
  state = {
    numOfDaysToStartExportFrom: 30,
    isRequestingCsv: false,
    exportResult: null,
  };

  trackNeedHelpUnderstandingEvents = () => {
    const { actions } = this.props;
    actions.trackEvent(CLICKED_BUTTON, {
      buttonLabel: 'Need help understanding NPS event data',
    });
  };

  handleSubmit = async () => {
    const {
      actions: { trackEvent },
      stepId,
      accountApiKey,
      accountId,
      email,
      useV2Extraction,
    } = this.props;
    const { numOfDaysToStartExportFrom } = this.state;

    trackEvent(REQUESTED_CSV_EXPORT, {
      stepId,
      accountId,
      contentType: 'NPS',
    });

    this.setState({
      isRequestingCsv: true,
    });

    try {
      const exportResult = await requestNpsCsvExport({
        startDate: moment()
          .utc()
          .subtract(numOfDaysToStartExportFrom, 'days')
          .startOf('day')
          .valueOf(),
        accountApiKey,
        accountId,
        email,
        useV2: useV2Extraction,
      });

      this.setState({
        exportResult,
        isRequestingCsv: false,
      });
    } catch (error) {
      this.setState({
        exportResult: {
          error,
        },
        isRequestingCsv: false,
      });
    }
  };

  handleExportStartDateChange = selectedOption => {
    this.setState({ numOfDaysToStartExportFrom: selectedOption.value });
  };

  render() {
    const {
      actions: { hideModal },
      email,
    } = this.props;
    const { numOfDaysToStartExportFrom, isRequestingCsv, exportResult } =
      this.state;

    const selected = NPS_CSV_EXPORT_DATE_RANGE_OPTIONS.find(
      ({ value }) => value === numOfDaysToStartExportFrom
    );

    return (
      <BaseModal
        modalClass="export-modal"
        shouldHideButtonStyle
        hideOKButton
        title="Export a CSV"
        {...this.props}
      >
        <Wrapper>
          {exportResult && exportResult.error && (
            <Notice type="warning">
              Sorry, we had trouble exporting your CSV. Can you please try
              again?
            </Notice>
          )}
          {exportResult && !exportResult.error && (
            <Notice type="success">
              Great - we&apos;re building your export and will send it to{' '}
              {email} in 5 to 10 minutes.
            </Notice>
          )}
          <Content>
            <div>
              Send yourself a CSV export of NPS events so you can analyze how
              many NPS surveys were shown, what scores were left, and what
              qualitative feedback was provided by your users.
            </div>

            <div>
              <p>
                <strong>Date range:</strong>
              </p>
              <Select
                onChange={this.handleExportStartDateChange}
                options={NPS_CSV_EXPORT_DATE_RANGE_OPTIONS}
                portal
                value={selected}
              />
              <ButtonGroup right>
                <Button
                  disabled={isRequestingCsv}
                  kind="positive"
                  onClick={this.handleSubmit}
                >
                  Email My CSV
                </Button>
                <Button kind="secondary" onClick={hideModal}>
                  Cancel
                </Button>
              </ButtonGroup>
            </div>

            <Caption>
              Need help understanding event names?{' '}
              <ExternalLink
                href="https://docs.appcues.com/article/349-nps-events-and-properties"
                onClick={this.trackNeedHelpUnderstandingEvents}
              >
                Click here.
              </ExternalLink>
            </Caption>
          </Content>
        </Wrapper>
      </BaseModal>
    );
  }
}

const mapStateToProps = state => ({
  useV2Extraction: selectAccountFeature(state, EXTRACTION_V2),
});

export default connect(mapStateToProps)(ExportNPSResultsToCSVModal);
