const GRID = 'GRID';
const LIST = 'LIST';

export const FLOW_LAYOUT = {
  GRID,
  LIST,
};

export const FLOW_STATE = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  ARCHIVED: 'ARCHIVED',
};

export const FLOW_STATUS = {
  [FLOW_STATE.DRAFT]: {
    label: 'Draft',
    value: FLOW_STATE.DRAFT,
  },
  [FLOW_STATE.PUBLISHED]: {
    label: 'Live',
    value: FLOW_STATE.PUBLISHED,
  },
  [FLOW_STATE.ARCHIVED]: {
    label: 'Archived',
    value: FLOW_STATE.ARCHIVED,
  },
};

export const FLOW_ACTIONS_HANDLER = {
  NAVIGATE: 'navigate',
  OPEN_IN_NEW_TAB: 'open-in-new-tab',
  CONTENT_API: 'content-api',
};

export const FLOW_ACTION_TYPES = {
  BUILD: 'build',
  EDIT: 'edit',
  ANALYTICS: 'analytics',
  SETTINGS: 'settings',
  CLONE: 'clone',
  ARCHIVE: 'archive',
};

export const FLOW_ACTION_TYPES_ARCHIVED = {
  UNARCHIVE: 'unarchive',
  DELETE: 'delete',
};

export const FLOW_ACTIONS = {
  [FLOW_ACTION_TYPES.BUILD]: {
    label: 'Open in Builder',
    action: FLOW_ACTION_TYPES.BUILD,
    handler: FLOW_ACTIONS_HANDLER.OPEN_IN_NEW_TAB,
    icon: 'edit',
    excludeOnLockedFlows: true,
  },
  [FLOW_ACTION_TYPES.EDIT]: {
    label: 'Edit',
    action: FLOW_ACTION_TYPES.EDIT,
    handler: FLOW_ACTIONS_HANDLER.NAVIGATE,
    icon: 'edit',
    excludeOnFlows: true,
  },
  [FLOW_ACTION_TYPES.ANALYTICS]: {
    label: 'Analytics',
    action: FLOW_ACTION_TYPES.ANALYTICS,
    handler: FLOW_ACTIONS_HANDLER.NAVIGATE,
    icon: 'chart-bar',
    hasHref: true,
  },
  [FLOW_ACTION_TYPES.SETTINGS]: {
    label: 'Settings',
    action: FLOW_ACTION_TYPES.SETTINGS,
    handler: FLOW_ACTIONS_HANDLER.NAVIGATE,
    icon: 'cog',
    hasHref: true,
  },
  [FLOW_ACTION_TYPES.CLONE]: {
    label: 'Clone',
    action: FLOW_ACTION_TYPES.CLONE,
    handler: FLOW_ACTIONS_HANDLER.CONTENT_API,
    icon: 'copy',
  },
  [FLOW_ACTION_TYPES.ARCHIVE]: {
    label: 'Archive',
    action: FLOW_ACTION_TYPES.ARCHIVE,
    handler: FLOW_ACTIONS_HANDLER.CONTENT_API,
    icon: 'archive',
  },
};

export const FLOW_ACTIONS_ARCHIVED = {
  [FLOW_ACTION_TYPES_ARCHIVED.UNARCHIVE]: {
    label: 'Restore',
    action: FLOW_ACTION_TYPES_ARCHIVED.UNARCHIVE,
    handler: FLOW_ACTIONS_HANDLER.CONTENT_API,
    icon: 'upload',
  },
  [FLOW_ACTION_TYPES_ARCHIVED.DELETE]: {
    label: 'Delete',
    action: FLOW_ACTION_TYPES_ARCHIVED.DELETE,
    handler: FLOW_ACTIONS_HANDLER.CONTENT_API,
    icon: 'trash-alt',
  },
};

export const SORT_TYPES = {
  NAME_AZ: 'alpha-asc',
  RECENT_UPDATED: 'updated-desc',
  RECENT_CREATED: 'created-desc',
};

export const SORT_TYPES_VALUES = Object.values(SORT_TYPES);
