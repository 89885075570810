import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Thead, Tbody, Tr } from '@studio/legacy-components';

import { selectNonArchivedCustomEvents } from 'reducers/account/customEvents';
import { callApi as fetchCustomEvents } from 'actions/account/customEvents';

import QueryResults from 'components/Common/QueryResults';
import Pagination, {
  PaginationMessage,
} from 'components/Common/UI/Pagination/Pagination';
import { BUILDER_EVENTS_LABEL } from 'components/Insights/constants';
import BuilderEventRow from 'components/Insights/Explorer/Sources/BuilderEventRow';
import TableOverlay from 'components/Insights/Explorer/Common/TableOverlay';
import {
  ResultsWrapper,
  SelectHeader,
  TableWrapper,
  ControlHeader,
  ClearEvents,
  PaginationWrapper,
  StatsHeader,
  EventNameHeader,
  StatusHeader,
} from 'components/Insights/Common/Styled';
import {
  AddEventTooltip,
  TitleTooltip,
} from 'components/Insights/Explorer/Common/SourceTooltips';
import useClientsideTable from 'hooks/useClientsideTable';

/**
 * Given an object with keys representing event names and a result from
 * the Analytics API, returns a merged result-set
 *
 * @param customEvents
 * @param results
 * @returns {{name: *, status: boolean, most_recent_timestamp: number, events: number, users: number}[]|null}
 */

export const mergeEventsAndResults = (customEvents, results) =>
  results
    ? Object.keys(customEvents).map(eventId => {
        const { name: eventName, enabled } = customEvents[eventId];
        const {
          name = eventName,
          events = 0,
          users = 0,
          most_recent_timestamp = 0,
        } = results.find(({ name: resultName }) => eventName === resultName) ||
        {};
        return {
          name,
          enabled,
          events,
          users,
          most_recent_timestamp,
        };
      })
    : null;

export const Builder = ({
  results,
  searchTerm = '',
  pageSize = 10,
  selectedEvents = [],
  enableSelect = true,
  onSelect,
  onClearEvents,
}) => {
  const {
    isEmpty,
    currentIndex,
    orderByColumn,
    orderByDirection,
    results: resultPage,
    totalResults,
    handleSort,
    handleCurrentIndex,
  } = useClientsideTable({
    data: results,
    searchTerm,
    pageSize,
    defaultOrderColumn: 'events',
    defaultSortDirection: 'desc',
    filterPredicate: ({ name }) =>
      name.match(new RegExp(`appcues_custom:(.*)${searchTerm}(.*)`, 'i')),
  });

  return (
    <>
      <ResultsWrapper>
        <TableOverlay
          isEmpty={isEmpty}
          results={resultPage}
          type={BUILDER_EVENTS_LABEL}
        />
        <TableWrapper>
          <Table>
            <Thead>
              <Tr>
                {resultPage?.length > 0 ? (
                  <SelectHeader>
                    {selectedEvents?.some(evt => evt.source === 'builder') ? (
                      <ClearEvents
                        onClick={() => {
                          onClearEvents('builder');
                        }}
                      />
                    ) : (
                      <AddEventTooltip />
                    )}
                  </SelectHeader>
                ) : (
                  <ControlHeader />
                )}
                <EventNameHeader
                  sortable
                  sorted={orderByColumn === 'name' ? orderByDirection : null}
                  onClick={() => {
                    handleSort('name');
                  }}
                >
                  Event Name
                </EventNameHeader>
                <StatusHeader
                  sortable
                  sorted={orderByColumn === 'enabled' ? orderByDirection : null}
                  onClick={() => {
                    handleSort('enabled');
                  }}
                >
                  Status
                </StatusHeader>
                <StatsHeader
                  sortable
                  sorted={orderByColumn === 'events' ? orderByDirection : null}
                  onClick={() => {
                    handleSort('events');
                  }}
                >
                  <TitleTooltip
                    tip="The number of total event occurrences."
                    title="Total Events"
                  />
                </StatsHeader>
                <StatsHeader
                  sortable
                  sorted={orderByColumn === 'users' ? orderByDirection : null}
                  onClick={() => {
                    handleSort('users');
                  }}
                >
                  <TitleTooltip
                    tip="The number of unique users who triggered the event."
                    title="Unique Users"
                  />
                </StatsHeader>
                <StatsHeader
                  sortable
                  sorted={
                    orderByColumn === 'most_recent_timestamp'
                      ? orderByDirection
                      : null
                  }
                  onClick={() => {
                    handleSort('most_recent_timestamp');
                  }}
                >
                  Last Triggered
                </StatsHeader>
              </Tr>
            </Thead>
            <Tbody>
              {(resultPage || []).map(
                ({ name, enabled, events, users, most_recent_timestamp }) => {
                  const checked = (selectedEvents || []).some(
                    ({ event: selectedEventName }) => selectedEventName === name
                  );
                  return (
                    <BuilderEventRow
                      key={name}
                      name={name}
                      status={enabled}
                      events={events}
                      users={users}
                      timestamp={most_recent_timestamp}
                      disabled={!enableSelect}
                      checked={checked}
                      onSelect={() =>
                        onSelect({ source: 'builder', event: name })
                      }
                    />
                  );
                }
              )}
            </Tbody>
          </Table>
        </TableWrapper>
      </ResultsWrapper>
      {resultPage && resultPage.length > 0 && (
        <PaginationWrapper>
          <Pagination
            pageCount={Math.ceil(totalResults / pageSize)}
            currentIndex={currentIndex}
            onChange={handleCurrentIndex}
          />
          <PaginationMessage
            currentIndex={currentIndex}
            pageSize={pageSize}
            total={totalResults}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

Builder.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      events: PropTypes.number,
      users: PropTypes.number,
      most_recent_timestamp: PropTypes.number,
    })
  ),
  searchTerm: PropTypes.string,
  pageSize: PropTypes.number,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  enableSelect: PropTypes.bool,
  onSelect: PropTypes.func,
  onClearEvents: PropTypes.func,
};

export const BuilderWithAnalytics = ({
  events,
  startTime,
  endTime,
  searchTerm,
  segmentId,
  pageSize = 10,
  selectedEvents,
  enableSelect,
  onSelect,
  onLoad,
  onClearEvents,
}) => {
  useEffect(() => void onLoad(), []);
  return (
    <QueryResults
      query={{
        conditions: [
          ['event', 'like', 'appcues_custom:%'],
          ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
        ],
        dimensions: ['name'],
        metrics: ['events', 'users', 'most_recent_timestamp'],
        start_time: startTime,
        end_time: endTime,
        meta: {
          tags: {
            feature: 'Event analytics',
            page: 'Event explorer',
            component: 'BuilderWithAnalytics',
            description: 'Builder event source',
          },
        },
      }}
    >
      {results => {
        const mergedResults = results && mergeEventsAndResults(events, results);

        return (
          <Builder
            results={mergedResults}
            startTime={startTime}
            endTime={endTime}
            searchTerm={searchTerm}
            pageSize={pageSize}
            selectedEvents={selectedEvents}
            enableSelect={enableSelect}
            onSelect={onSelect}
            onClearEvents={onClearEvents}
          />
        );
      }}
    </QueryResults>
  );
};

BuilderWithAnalytics.propTypes = {
  events: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      enabled: PropTypes.bool,
    })
  ),
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  segmentId: PropTypes.string,
  searchTerm: PropTypes.string,
  pageSize: PropTypes.number,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  enableSelect: PropTypes.bool,
  onSelect: PropTypes.func,
  onLoad: PropTypes.func,
  onClearEvents: PropTypes.func,
};

const mapStateToProps = state => ({
  events: selectNonArchivedCustomEvents(state),
});

const mapDispatchToProps = {
  onLoad: fetchCustomEvents,
};

const ConnectedBuilderWithAnalytics = connect(
  mapStateToProps,
  mapDispatchToProps
)(BuilderWithAnalytics);

export default ConnectedBuilderWithAnalytics;
