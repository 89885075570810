import React from 'react';
import { CardCvcElement } from '@stripe/react-stripe-js';
import styled from 'styled-components';

const StyledCardCvc = styled(CardCvcElement)`
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--input);
  border-radius: 6px;
  color: var(--input-text);
  font-size: var(--regular);
  font-weight: var(--normal);
  height: 40px;
  outline: none;
  padding: 10px 14px;

  &.StripeElement--invalid {
    border: solid 1px var(--danger-dark);
  }
`;

const CardCVC = ({ onChange, className }) => (
  <StyledCardCvc
    onChange={onChange}
    options={{
      placeholder: '',
      classes: {
        base: className,
      },
    }}
  />
);

export default CardCVC;
