import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { Caption } from '@studio/legacy-components';
import { TooltipWrapper, TooltipHeader, TooltipTitle } from '@studio/charts';

const Body = styled.dl`
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.dt`
  font-weight: var(--bold);
`;

const Value = styled.dd`
  color: var(--tooltip-header-color);
  font-weight: var(--bold);
`;

const StyledTooltipHeader = styled(TooltipHeader)`
  ${TooltipTitle} {
    margin-bottom: 2px;
  }
`;

const NPS_PERIOD_DAYS = 30;

const NpsTooltip = ({ day, value }) => {
  // We must consider the given day as a whole day when subtracting days for NPS
  const wholeDay = moment(day).utc().add(1, 'day').startOf('day');

  return (
    <TooltipWrapper>
      <StyledTooltipHeader>
        <TooltipTitle size="m">
          {moment(day).utc().format('ddd, MMM D, YYYY')}
        </TooltipTitle>
        <Caption>
          Score calculated from{' '}
          {moment(wholeDay)
            .subtract(NPS_PERIOD_DAYS, 'days')
            .utc()
            .format('MMM D')}{' '}
          to {moment(day).utc().format('MMM D')}
        </Caption>
      </StyledTooltipHeader>
      <Body>
        <Label>NPS Score</Label>
        <Value>{value}</Value>
      </Body>
    </TooltipWrapper>
  );
};

NpsTooltip.propTypes = {
  day: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default NpsTooltip;
