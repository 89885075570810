import React from 'react';
import styled from 'styled-components';

const StatSection = props => {
  const { children, header } = props;
  return (
    <StatSectionWrapper>
      <StatSectionHeader className="override">{header}</StatSectionHeader>
      {children}
    </StatSectionWrapper>
  );
};

const StatSectionHeader = styled.h3`
  &.override {
    text-transform: uppercase;
    color: ${props => props.theme['$gray-30']};
    margin-bottom: 16px;
  }
`;

const StatSectionWrapper = styled.div`
  margin-bottom: 32px;
`;

export default StatSection;
