import { createCollectionReducer } from 'next/lib/collections';
import { selectRequestStatus } from 'next/entities/requests';
import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

export const selectInstalled = state => {
  return state[TYPE];
};

export const selectInstalledDomain = (state, hostName) => {
  const installed = selectInstalled(state) || {};
  return installed[hostName];
};

export const selectIsInstalled = state => {
  const installed = selectInstalled(state) || {};
  return Object.values(installed).length > 0;
};

export const readInstalled = state => ({
  ...selectRequestStatus(state, TYPE),
  data: selectInstalled(state),
});
