import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CSkeleton } from '@appcues/component-library';

import {
  selectAreTranslationsSynced,
  selectFlowTranslationByLocale,
} from 'reducers/account/translations';
import { selectFlow } from 'reducers/account/flows';

import DeleteManager from 'components/StepSettings/Localization/DeleteManager';
import UploadManager from 'components/StepSettings/Localization/UploadManager';

export const TranslationManager = ({
  synced,
  disabled,
  translation,
  localeId,
  flowId,
}) => {
  if (!synced) {
    return <CSkeleton width="10em" height="1em" />;
  }

  if (translation) {
    return (
      <DeleteManager
        flowId={flowId}
        translationId={translation.id}
        updatedAt={translation.updated_at}
        disabled={disabled}
      />
    );
  }

  return (
    <UploadManager flowId={flowId} localeId={localeId} disabled={disabled} />
  );
};

TranslationManager.propTypes = {
  synced: PropTypes.bool,
  disabled: PropTypes.bool,
  translation: PropTypes.shape({
    id: PropTypes.string,
    updated_at: PropTypes.number,
  }),
  localeId: PropTypes.string,
  flowId: PropTypes.string.isRequired,
};

TranslationManager.defaultProps = {
  disabled: false,
};

const mapStateToProps = (state, { flowId, localeId }) => {
  const { published, lockedForLocalization } = selectFlow(state, flowId);
  return {
    synced: selectAreTranslationsSynced(state),
    translation: selectFlowTranslationByLocale(state, localeId),
    disabled: published || !lockedForLocalization,
  };
};

export default connect(mapStateToProps)(TranslationManager);
