import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { H5, Select } from '@studio/legacy-components';
import ThemeFontDropdown from 'components/Themes/ThemeEdit/ThemeEditTab/ThemeFontDropdown';
import ThemeDropDown from 'components/Themes/ThemeEdit/ThemeEditTab/ThemeDropdown';
import ThemeColorPicker from 'components/Themes/ThemeEdit/ThemeEditTab/ThemeColorPicker';
import ThemeInput from 'components/Themes/ThemeEdit/ThemeEditTab/ThemeInput';
import ThemeSlider from 'components/Themes/ThemeEdit/ThemeEditTab/ThemeSlider';
import { withSkeleton } from 'components/Common/WithSkeleton';
import {
  DEFAULT_COMPONENT_MARGIN_TOP,
  DEFAULT_COMPONENT_WIDTH,
  HOVER_PROPERTIES,
  PRIMARY_BORDER,
  PRIMARY_BORDER_COLOR,
  PRIMARY_BORDER_WIDTH,
  PRIMARY_HOVER_BORDER,
  PRIMARY_HOVER_BORDER_COLOR,
  PRIMARY_HOVER_BORDER_WIDTH,
  SECONDARY_BORDER,
  SECONDARY_BORDER_COLOR,
  SECONDARY_BORDER_WIDTH,
  SECONDARY_HOVER_BORDER,
  SECONDARY_HOVER_BORDER_COLOR,
  SECONDARY_HOVER_BORDER_WIDTH,
} from 'constants/account/themes';
import InputWithSuffix from './ThemeInputWithSuffix';
import UnitInput from './UnitInput';

const Wrapper = styled.div`
  ${H5} {
    color: var(--text-secondary);
    margin-top: 40px;
  }

  ${Select} {
    max-width: 170px;
    min-width: 170px;
  }
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const Label = styled.span`
  font-size: var(--x-small);
  font-weight: var(--bold);
`;

const Control = styled.div``;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${DEFAULT_COMPONENT_WIDTH};
`;

const inputProps = {
  maxWidth: '100%',
  height: '30px',
  marginTop: DEFAULT_COMPONENT_MARGIN_TOP,
};

const dropdownProps = {
  width: '170px',
  height: '40px',
};

const InputField = withSkeleton(ThemeInput, inputProps);
const FontDropDownField = withSkeleton(ThemeFontDropdown, dropdownProps);
const DropDownField = withSkeleton(ThemeDropDown, dropdownProps);
const ColorPickerField = withSkeleton(ThemeColorPicker, dropdownProps);

const ThemeEditSubSection = ({ label, draftTheme, setDraftTheme }) => {
  const { fields, category } = label;
  return fields.map(subLabel => {
    if (subLabel.type === 'inputwithsuffix') {
      return (
        <Row key={subLabel.name}>
          <Label>{subLabel.label}</Label>
          <UnitInput
            draftTheme={draftTheme}
            label={subLabel}
            setDraftTheme={setDraftTheme}
            unit="px"
          />
        </Row>
      );
    }
    return (
      <NonInputFields
        key={`${category}-${subLabel.label}-container`}
        label={subLabel}
        draftTheme={draftTheme}
        setDraftTheme={setDraftTheme}
        category={category}
      />
    );
  });
};

export const onBeforeSetDraft = ({
  draftTheme,
  borderWidth,
  borderColor,
  label,
}) => {
  const { name, hover } = label;
  let prevBorderColor;
  let prevBorderWidth;
  let border;
  const buttonType = name?.toLowerCase();

  // primary buttons
  if (buttonType?.includes('primary')) {
    if (hover) {
      prevBorderColor = PRIMARY_HOVER_BORDER_COLOR;
      border = PRIMARY_HOVER_BORDER;
      prevBorderWidth = PRIMARY_HOVER_BORDER_WIDTH;
    } else {
      prevBorderWidth = PRIMARY_BORDER_WIDTH;
      border = PRIMARY_BORDER;
      prevBorderColor = PRIMARY_BORDER_COLOR;
    }
  }
  // secondary buttons
  else if (buttonType?.includes('secondary')) {
    if (hover) {
      prevBorderWidth = SECONDARY_HOVER_BORDER_WIDTH;
      border = SECONDARY_HOVER_BORDER;
      prevBorderColor = SECONDARY_HOVER_BORDER_COLOR;
    } else {
      prevBorderWidth = SECONDARY_BORDER_WIDTH;
      border = SECONDARY_BORDER;
      prevBorderColor = SECONDARY_BORDER_COLOR;
    }
  }

  // set border if border color and width has been defined
  if (
    (draftTheme[prevBorderColor] && borderWidth) ||
    (draftTheme[prevBorderWidth] && borderColor)
  ) {
    const newBorder =
      draftTheme[prevBorderColor] && borderWidth
        ? `${borderWidth} solid ${draftTheme[prevBorderColor]}`
        : `${draftTheme[prevBorderWidth]} solid ${borderColor}`;

    return { [border]: newBorder };
  }
  return {};
};

export const NonInputFields = ({
  label,
  draftTheme,
  setDraftTheme,
  category,
}) => {
  const themeId = draftTheme ? draftTheme.id : '';
  const hover = HOVER_PROPERTIES.has(label.name);
  return (
    <Row key={label.name}>
      <Label>{label.label}</Label>

      <Control>
        {label.type === 'fontdropdown' && (
          <FontDropDownField
            themeId={themeId}
            value={draftTheme[label.name]}
            defaultOptions={label.options}
            customFontUrl={draftTheme.customFontUrl}
            typekitId={draftTheme.typekitId || draftTheme.projectId || ''}
            setDraftTheme={setDraftTheme}
            name={label.name}
            // draftTheme is required for withSkeleton :facepalm:
            draftTheme={draftTheme}
          />
        )}
        {label.type === 'dropdown' && (
          <DropDownField
            draftTheme={draftTheme}
            setDraftTheme={setDraftTheme}
            label={label}
          />
        )}
        {label.type === 'slider-px' && (
          <ThemeSlider
            draftTheme={draftTheme}
            setDraftTheme={setDraftTheme}
            label={label}
            unit="pixel"
            max={20}
          />
        )}
        {label.type === 'slider-opacity' && (
          <ThemeSlider
            draftTheme={draftTheme}
            setDraftTheme={setDraftTheme}
            label={label}
            unit="percentage"
            max={100}
          />
        )}
        {label.type.includes('colorpicker') && (
          <ColorPickerField
            draftTheme={draftTheme}
            setDraftTheme={setDraftTheme}
            label={label}
          />
        )}
        {label.type === 'slider-cornerRadius' && (
          <ThemeSlider
            draftTheme={draftTheme}
            setDraftTheme={setDraftTheme}
            label={label}
            unit="pixel"
            max={100}
          />
        )}
        {label.type === 'borderColor' && (
          <FlexRow>
            <InputWithSuffix
              draftTheme={draftTheme}
              setDraftTheme={setDraftTheme}
              label={{
                ...label,
                name:
                  category === 'primary'
                    ? hover
                      ? PRIMARY_HOVER_BORDER_WIDTH
                      : PRIMARY_BORDER_WIDTH
                    : hover
                    ? SECONDARY_HOVER_BORDER_WIDTH
                    : SECONDARY_BORDER_WIDTH,
                hover,
              }}
              width={70}
              minWidth="auto"
              marginTop={0}
              placeholder="-"
              suffix="px"
              onBeforeSetDraft={onBeforeSetDraft}
              styleTheme="light"
            />
            <ColorPickerField
              draftTheme={draftTheme}
              setDraftTheme={setDraftTheme}
              label={{
                ...label,
                name:
                  category === 'primary'
                    ? hover
                      ? PRIMARY_HOVER_BORDER_COLOR
                      : PRIMARY_BORDER_COLOR
                    : hover
                    ? SECONDARY_HOVER_BORDER_COLOR
                    : SECONDARY_BORDER_COLOR,
                hover,
              }}
              width={80}
              onBeforeSetDraft={onBeforeSetDraft}
            />
          </FlexRow>
        )}
      </Control>
    </Row>
  );
};

NonInputFields.propTypes = {
  label: PropTypes.shape({
    category: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    type: PropTypes.string,
  }),
  draftTheme: PropTypes.shape({
    id: PropTypes.string,
    customFontUrl: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    typekitId: PropTypes.string,
    projectId: PropTypes.string,
  }),
  setDraftTheme: PropTypes.func,
};

export const ThemeEditSection = ({
  contentArray,
  draftTheme,
  setDraftTheme,
}) => {
  return (
    <Wrapper>
      {contentArray.map(label => {
        if (label.category) {
          return (
            <Fragment key={label.category}>
              <H5 type="tertiary">{label.category}</H5>
              <ThemeEditSubSection
                label={label}
                draftTheme={draftTheme}
                setDraftTheme={setDraftTheme}
              />
            </Fragment>
          );
        }
        return label.type === 'input' ? (
          <InputField
            draftTheme={draftTheme}
            setDraftTheme={setDraftTheme}
            label={label}
            key={label.name}
          />
        ) : (
          <NonInputFields
            key={`${label.name}-container`}
            label={label}
            draftTheme={draftTheme}
            setDraftTheme={setDraftTheme}
          />
        );
      })}
    </Wrapper>
  );
};

ThemeEditSection.propTypes = {
  contentArray: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      label: PropTypes.string,
      name: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        })
      ),
      type: PropTypes.string,
    })
  ),
  draftTheme: PropTypes.shape({
    id: PropTypes.string,
    customFontUrl: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    typekitId: PropTypes.string,
    projectId: PropTypes.string,
  }),
  setDraftTheme: PropTypes.func,
};

export default ThemeEditSection;
