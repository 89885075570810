import React, { useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CPage, CSkeleton } from '@appcues/component-library';

import { StatusLabel } from '@studio/legacy-components';
import useTitle from 'next/hooks/use-title';
import { selectCustomEventByName } from 'reducers/account/customEvents';
import { selectAccountUserFullName } from 'reducers/account/users';
import { callApi as fetchCustomEvents } from 'actions/account/customEvents';
import IndexPage from 'components/Common/IndexPage';
import { decodeEventNameUrl } from 'components/Insights/utils';
import { ExportManager } from 'components/Insights/Common/ExportManager';

import SingleMetricContent from './SingleMetricContent';
import { HeaderWrapper, HeaderTitle, SubHeaderColumn } from './styled';
import OpenTrackEventButton from './OpenTrackEventButton';

export const BuilderMetric = ({
  eventName,
  customEvent,
  createdByUserName,
  enabledByUserName,
  disabledByUserName,
  onLoad,
}) => {
  const title = eventName.replace('appcues_custom:', '');
  useTitle(`${title} | Events Explorer | Appcues`);
  useEffect(() => {
    onLoad();
  }, []);

  return (
    <IndexPage
      title={
        !customEvent ? (
          <CSkeleton height={20} width={400} />
        ) : (
          <HeaderWrapper>
            <HeaderTitle>{title}</HeaderTitle>
            <StatusLabel
              active={customEvent.enabled}
              label={customEvent.enabled ? 'Enabled' : 'Disabled'}
            />
          </HeaderWrapper>
        )
      }
      description={
        !customEvent ? (
          <CSkeleton height={18} width={400} />
        ) : (
          <HeaderWrapper>
            <SubHeaderColumn>
              Track event created{' '}
              {createdByUserName ? `by ${createdByUserName} ` : ''}
              {customEvent.createdAt &&
                moment(customEvent.createdAt).format('ll')}
            </SubHeaderColumn>
            <SubHeaderColumn>
              {customEvent.enabled ? (
                <>
                  {enabledByUserName || customEvent.enabledAt ? 'Enabled ' : ''}
                  {enabledByUserName ? `by ${enabledByUserName} ` : ''}
                  {customEvent.enabledAt &&
                    moment(customEvent.enabledAt).fromNow()}
                </>
              ) : (
                <>
                  {disabledByUserName || customEvent.disabledAt
                    ? 'Disabled '
                    : ''}
                  {disabledByUserName ? `by ${disabledByUserName} ` : ''}
                  {customEvent.disabledAt &&
                    moment(customEvent.disabledAt).fromNow()}
                </>
              )}
            </SubHeaderColumn>
          </HeaderWrapper>
        )
      }
      subHeaderActions={
        <>
          <ExportManager events={[{ source: 'builder', event: eventName }]} />
          <OpenTrackEventButton event={eventName} />
        </>
      }
    >
      <CPage.Container>
        <SingleMetricContent eventName={eventName} source="builder" />
      </CPage.Container>
    </IndexPage>
  );
};

BuilderMetric.propTypes = {
  customEvent: PropTypes.shape({
    enabled: PropTypes.bool,
  }),
  eventName: PropTypes.string,
  createdByUserName: PropTypes.string,
  enabledByUserName: PropTypes.string,
  disabledByUserName: PropTypes.string,
  onLoad: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { encodedEventName },
    },
  } = ownProps;

  const eventName = decodeEventNameUrl('builder', encodedEventName);
  const customEvent = selectCustomEventByName(state, { eventName });

  if (!customEvent) {
    return {
      eventName,
    };
  }

  return {
    customEvent,
    eventName,
    createdByUserName: selectAccountUserFullName(state, customEvent.createdBy),
    enabledByUserName: selectAccountUserFullName(state, customEvent.enabledBy),
    disabledByUserName: selectAccountUserFullName(
      state,
      customEvent.disabledBy
    ),
  };
};

const mapDispatchToProps = {
  onLoad: fetchCustomEvents,
};

const ConnectedBuilderMetric = connect(
  mapStateToProps,
  mapDispatchToProps
)(BuilderMetric);

ConnectedBuilderMetric.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventName: PropTypes.string,
    }),
  }),
};

export default ConnectedBuilderMetric;
