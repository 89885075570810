import React from 'react';
import styled from 'styled-components';

const Loader = ({ className, ...props }) => {
  return (
    <main className={`${className} loader`} {...props} data-testid="loader">
      <div className="loader">
        <div className="loader-pulsate loader-pulsate-1" />
        <div className="loader-pulsate loader-pulsate-2" />
      </div>
    </main>
  );
};

export default styled(Loader)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => (props.margin ? 'auto' : '100%')};
  height: ${props => (props.margin ? 'auto' : '100vh')};
  margin: ${props => props.margin || 0};

  .loader {
    width: 2em;
  }
  .loader-pulsate {
    width: 2em;
    height: 2em;
    margin-top: -1em;
    border-radius: 0.25em;
    background-color: rgba(180, 178, 177, 0.3);
    position: absolute;
  }
  .loader-pulsate-1 {
    animation: 1.32s ease-in-out 0.1s infinite alternate pulsateA;
  }
  .loader-pulsate-2 {
    animation: 1.3s ease-in-out 0s infinite alternate pulsateB;
  }

  @keyframes pulsateA {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes pulsateB {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
`;
