import PropTypes from 'prop-types';

export default PropTypes.shape({
  email: PropTypes.string,
  fullname: PropTypes.string,
  id: PropTypes.string,
  isAdmin: PropTypes.bool,
  job: PropTypes.string,
  signedUp: PropTypes.bool,
});
