/* globals CRX_ID */

import React from 'react';
import { connect } from 'react-redux';
import {
  CBanner,
  CLogo,
  CButton,
  CPanel,
  Text,
  P,
} from '@appcues/component-library';
import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';
import useTitle from 'next/hooks/use-title';
import {
  selectHasCheckedForChromeExtension,
  selectHasChromeExtensionInstalled,
  selectHasChrome,
  selectChromeExtensionVersion,
} from 'reducers/chromeExtension';
import {
  CHROME_INSTALL_MODAL_ID,
  CRX_INSTALL_MODAL_ID,
} from 'constants/chromeExtension/modals';
import { selectFlow, selectAreFlowsSynced } from 'reducers/account/flows';
import { editStepInChromeExtension } from 'actions/chromeExtension';
import Loader from 'components/Common/Loader';
import { asPromised } from 'utils/as-promised';

const VERSION_5_0_33 = '5.0.33';

const BUILDER_CHROME_STORE_URL = `https://chrome.google.com/webstore/detail/appcues-builder/${CRX_ID}`;

function EditStepInChrome({
  hasCheckedForExtension,
  hasExtensionInstalled,
  hasChrome,
  previewUrl,
  loading,
  stepGroupId,
  stepChildId,
  onEditClick,
  flowName,
  chromeExtensionVersion,
}) {
  useTitle('Install Appcues Builder | Appcues');
  React.useEffect(() => {
    if (!window.Appcues) {
      return;
    }
    if (!hasChrome) {
      window.Appcues.show(CHROME_INSTALL_MODAL_ID);
    } else if (!hasExtensionInstalled) {
      window.Appcues.show(CRX_INSTALL_MODAL_ID);
    }
  }, [hasChrome, hasExtensionInstalled]);

  return loading ? (
    <Loader />
  ) : (
    <CPanel.BG flexDirection="column">
      <Panel alignItems="center">
        <Logo type="icon" />
        <Space />
        {hasCheckedForExtension && (!hasExtensionInstalled || !hasChrome) ? (
          <>
            <Banner type="warning">
              It seems you haven&apos;t installed the Flow Builder Chrome
              extension yet. Would you like to install the builder now?
            </Banner>
            <Space />
            <ExternalLinkButton
              onClick={() => window.open(BUILDER_CHROME_STORE_URL)}
            >
              Take me to the chrome store!
            </ExternalLinkButton>
          </>
        ) : (
          <>
            <Banner type="info">
              Due to recent Chome updates, we cannot open a flow directly.
            </Banner>
            <Space />
            <LeftAlign>
              <P>
                Taking you to{' '}
                <InlineText bold truncate>
                  {previewUrl}
                </InlineText>
              </P>
              {chromeExtensionVersion > VERSION_5_0_33 ? (
                <div>
                  When the page loads, click on the Flow Builder extension and
                  your flow will open.
                </div>
              ) : (
                <div>
                  <div>When the page loads,</div>
                  <OrderedList>
                    <li>Click on the Flow Builder extension</li>
                    <li>
                      Select{' '}
                      <InlineText truncate>&quot;{flowName}&quot;</InlineText>{' '}
                      from the dropdown
                    </li>
                  </OrderedList>
                </div>
              )}
            </LeftAlign>
            <Space />
            <ExternalLinkButton
              onClick={() => {
                if (chromeExtensionVersion > VERSION_5_0_33) {
                  onEditClick(stepGroupId, stepChildId).then(() => {
                    document.location = previewUrl;
                  });
                } else {
                  document.location = previewUrl;
                }
              }}
            >
              Take me there!
            </ExternalLinkButton>
          </>
        )}
      </Panel>
    </CPanel.BG>
  );
}
const ExternalLinkButton = ({ onClick, children }) => (
  <BigButton role="link" type="primary" onClick={onClick}>
    <Icon icon="globe" aria-hidden />
    {children}
  </BigButton>
);
const BigButton = styled(CButton)`
  height: 48px;
`;
const Logo = styled(CLogo)`
  width: 40px;
  margin: 0;
`;
const Space = styled.div`
  height: 40px;
`;
const Banner = styled(CBanner)`
  width: 400px;
`;
const LeftAlign = styled.div`
  width: 100%;
  align-items: flex-start;
`;
const Panel = styled(CPanel)`
  padding: 80px 104px;
  flex-basis: 400px;
  max-width: 800px;
`;
const InlineText = styled(Text)`
  display: inline;
`;
const OrderedList = styled.ol`
  margin-top: 8px;
  margin-left: 16px;
`;

const mapDispatchToProps = dispatch => ({
  onEditClick: values =>
    asPromised(dispatch, editStepInChromeExtension(values)),
});

function mapStateToProps(state, ownProps) {
  const { stepGroupId, stepChildId } = ownProps.match.params;
  const { name: flowName, previewUrl } = selectFlow(state, stepGroupId) || {};
  return {
    flowName,
    stepGroupId,
    stepChildId,
    previewUrl,
    hasCheckedForExtension: selectHasCheckedForChromeExtension(state),
    hasExtensionInstalled: selectHasChromeExtensionInstalled(state),
    hasChrome: selectHasChrome(state),
    chromeExtensionVersion: selectChromeExtensionVersion(state),
    loading: !selectAreFlowsSynced(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditStepInChrome);
