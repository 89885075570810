import { createCollectionReducer } from 'reducers/account/collections';
import { selectIsSynced } from 'reducers/account/requests';
import { selectRulesWithGoal } from './rules';
import { selectFlowsWithGoal } from './flows';

const TYPE = 'goals';

export default createCollectionReducer(TYPE);

export const selectAccountGoals = state => state.account.goals;
export const selectAccountGoal = (state, goalId) =>
  selectAccountGoals(state)[goalId];
export const selectAccountGoalsSynced = state => selectIsSynced(state, TYPE);

/*
 * Select the goals with the flows connected to them
 * @param {Object} state - the redux state
 * @returns {Object} - the goals with the flows connected to them
 */
export const selectGoalsWithFlowConnected = state => {
  const rulesWithGoal = selectRulesWithGoal(state);

  return rulesWithGoal.reduce((acc, rule) => {
    acc[rule.goals] = selectFlowsWithGoal(state, { goalId: rule.goals[0] });
    return acc;
  }, {});
};
