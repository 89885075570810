import React from 'react';
import styled from 'styled-components';
import { getThemeFor } from 'utils';
import EmptyEventsByUserImg from 'assets/images/empty-events-by-user.svg';

const EmptyEventsContainer = styled.div`
  display: grid;
  place-items: center;
`;

const Title = styled.h3`
  margin: 12px 0 18px;
  font-size: 1.25rem;
  font-weight: ${getThemeFor('$font-weight-l')};
  line-height: 1.5;
  color: ${getThemeFor('$gray-8')};
`;

const Message = styled.p`
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: ${getThemeFor('$font-weight-m')};
  color: ${getThemeFor('$gray-8')};
`;

const EmptyEventsByUser = () => (
  <EmptyEventsContainer>
    <img src={EmptyEventsByUserImg} alt="No data" />
    <Title>No data</Title>
    <Message>This event has not been triggered yet.</Message>
  </EmptyEventsContainer>
);

export default EmptyEventsByUser;
