/* global BUNDLER_URL */

export async function rebundleAccount(accountId) {
  const response = await fetch(`${BUNDLER_URL}/bundle/accounts/${accountId}`, {
    method: 'post',
    mode: 'cors',
  });

  if (response.status !== 200) {
    throw new Error('Bundler notification failed.');
  }

  // The bundler does not return anything useful for this request;
  // it simply acknowledges it was received.
  return true;
}
