export const APP_ENABLED = 'enabled';
export const APP_DISABLED = 'disabled';

export const APPCUES_IOS_SDK = 'https://github.com/appcues/appcues-ios-sdk';
export const APPCUES_ANDROID_SDK =
  'https://github.com/appcues/appcues-android-sdk';

export const APPCUES_FLUTTER_PLUGIN =
  'https://github.com/appcues/appcues-flutter-plugin';
export const APPCUES_XAMARIN_BINDING =
  'https://github.com/appcues/appcues-xamarin-binding';
export const APPCUES_IONIC_CAPACITOR_PLUGIN =
  'https://github.com/appcues/appcues-capacitor-plugin';
export const APPCUES_REACT_NATIVE_MODULE =
  'https://github.com/appcues/appcues-react-native-module';

export const getPlatformData = platform => {
  switch (platform) {
    case 'ios':
      return {
        platformIcon: ['fab', 'apple'],
        platformName: 'iOS',
        platformAppLabel: 'App bundle ID',
        sdkUrl: APPCUES_IOS_SDK,
      };
    case 'android':
      return {
        platformIcon: ['fab', 'android'],
        platformName: 'Android',
        platformAppLabel: 'App package ID',
        sdkUrl: APPCUES_ANDROID_SDK,
      };
    case 'react-native':
      return {
        platformIcon: ['fab', 'react'],
        platformName: 'React Native',
        platformAppLabel: 'App package ID',
        sdkUrl: APPCUES_REACT_NATIVE_MODULE,
      };
    case 'flutter':
      return {
        platformIcon: ['fa', 'mobile'],
        platformName: 'Flutter',
        platformAppLabel: 'App package ID',
        sdkUrl: APPCUES_FLUTTER_PLUGIN,
      };
    case 'xamarin':
      return {
        platformIcon: ['fa', 'mobile'],
        platformName: 'Xamarin',
        platformAppLabel: 'App package ID',
        sdkUrl: APPCUES_XAMARIN_BINDING,
      };
    case 'ionic':
      return {
        platformIcon: ['fa', 'mobile'],
        platformName: 'Ionic',
        platformAppLabel: 'App package ID',
        sdkUrl: APPCUES_IONIC_CAPACITOR_PLUGIN,
      };
    default:
      return {};
  }
};

export const PLATFORM_OPTIONS = [
  {
    label: 'iOS',
    value: 'ios',
  },
  {
    label: 'Android',
    value: 'android',
  },
];
/**
 * Returns collections of active mobile platforms
 *
 * @param {object} features
 * @returns
 */

export const enabledFeaturePlatformOptions = (features = {}) => {
  const enabledPlatformOptions = [...PLATFORM_OPTIONS];

  if (features.REACT_NATIVE_PLATFORM) {
    enabledPlatformOptions.push({
      label: 'React Native',
      value: 'react-native',
    });
  }

  if (features.FLUTTER_PLATFORM) {
    enabledPlatformOptions.push({
      label: 'Flutter',
      value: 'flutter',
    });
  }

  if (features.XAMARIN_PLATFORM) {
    enabledPlatformOptions.push({
      label: 'Xamarin',
      value: 'xamarin',
    });
  }

  if (features.IONIC_PLATFORM) {
    enabledPlatformOptions.push({
      label: 'Ionic',
      value: 'ionic',
    });
  }

  return enabledPlatformOptions;
};

export const getPlatformOptions = () => {
  const platformOptions = [
    ...PLATFORM_OPTIONS,
    {
      label: 'React Native',
      value: 'react-native',
    },
    {
      label: 'Flutter',
      value: 'flutter',
    },
    {
      label: 'Xamarin',
      value: 'xamarin',
    },
    {
      label: 'Ionic',
      value: 'ionic',
    },
  ];

  return platformOptions;
};
