/**
 * Regex pattern for extracting year/month from YYYY-MM-DD format
 */
const YEAR_MONTH_PATTERN = /^\d{4}-\d{2}/;

/**
 * Regroup daily analytics results to monthly
 *
 * NOTE: To keep the logic here simple, some assumptions are made about the
 *       Analytics API. Specifically:
 *       - Each bucket will contain daily data
 *       - Each bucket is dense in the sense that they contain all metrics
 *         requested, even if they zero for that bucket
 *       - Can support up to a second dimension of grouping
 *
 * @param {DataPoint[]} results - Results from Analytics API
 * @param {string} secondary - Secondary dimension to also group by
 * @return {DataPoint[]} Monthly grouped analytics results
 */
export const groupByMonth = (results, secondary) =>
  // Convert keyed results back to just the list of data points
  Object.values(
    results.reduce((acc, { day, ...rest }) => {
      // Extract subdimension from data point if provided
      const { [secondary]: subdimension, ...metrics } = rest;

      // Extract the year/month without the day
      const [month] = day.match(YEAR_MONTH_PATTERN);

      // Create key based on month and optionally the second dimension
      const key = secondary ? `${month}${subdimension}` : month;

      // If this is a new bucket, assign the data point directly
      if (acc[key] == null) {
        acc[key] = { month, ...rest };
      }
      // Otherwise iterate over metrics and sum the numeric values
      // NOTE: This makes the assumption that if there are other properties in
      //       the data point that they will typically not be numeric e.g.
      //       strings and thus skipped over allowing the first point's values
      //       to take precedence.
      else {
        acc[key] = Object.entries(metrics).reduce(
          (accumulator, [metric, value]) => {
            if (typeof value === 'number') {
              accumulator[metric] += value;
            }
            return accumulator;
          },
          acc[key]
        );
      }

      return acc;
    }, {})
  );
