import { css } from 'styled-components';
import {
  darken as polishedDarken,
  lighten as polishedLighten,
  transparentize as polishedTransparentize,
} from 'polished';

export const darken = (amount, color = '#848484') =>
  polishedDarken(amount, color);

export const lighten = (amount, color = '#848484') =>
  polishedLighten(amount, color);

export const transparentize = (amount, color = '#848484') =>
  polishedTransparentize(amount, color);

export const buttonVariant = (
  color = '#848484',
  background = 'rgba(0,0,0,0)',
  border = '#BBBBBB'
) => css`
  color: ${color};
  background-color: ${background};
  border-color: ${border};

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    color: ${color};
    background-color: ${darken(0.1, background)};
    border-color: ${darken(0.1, border)};
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: ${lighten(0.25, background)};
      border-color: ${lighten(0.25, border)};
      color: ${darken(0.05, color)};
    }
  }
`;
