import React from 'react';
import styled, { withTheme } from 'styled-components';
import NumberPicker from 'components/Common/NumberPicker';

const BeaconOffsetContainer = styled.div`
  display: ${props => (props.collapsed ? 'none' : 'flex')};
  color: ${props => props.theme['$gray-50']};
`;

const Icon = styled.i`
  padding: 5px;
  padding-top: 7px;
`;
const Label = styled.div`
  padding: 10px 20px;
  border-left: 5px solid ${props => props.theme['$gray-3']};
`;

const Offset = styled.div`
  display: flex;
  padding: 5px 10px;
  align-items: center;
`;

const OffsetsContainer = styled.div`
  display: flex;
  padding-left: 55px;
`;

const Units = styled.div`
  padding: 5px;
  padding-left: 7px;
`;

function BeaconOffset({
  collapsed,
  beaconHorizontal,
  beaconVertical,
  updateBeaconHorizontalOffset,
  updateBeaconVerticalOffset,
}) {
  return (
    <BeaconOffsetContainer collapsed={collapsed}>
      <Label>Adjust Position</Label>
      <OffsetsContainer>
        <Offset>
          <Icon icon="arrows-alt-h" />
          <NumberPicker
            value={beaconHorizontal}
            onChange={updateBeaconHorizontalOffset}
          />
          <Units className="pixels">px</Units>
        </Offset>
        <Offset>
          <Icon icon="arrows-alt-v" />
          <NumberPicker
            value={beaconVertical}
            onChange={updateBeaconVerticalOffset}
          />
          <Units className="pixels">px</Units>
        </Offset>
      </OffsetsContainer>
    </BeaconOffsetContainer>
  );
}

export default withTheme(BeaconOffset);
