import React, { useState } from 'react';
import styled from 'styled-components';
import { Notice, H4, Box } from '@appcues/component-library';
import { getFilteredClauseTree } from '@appcues/libcues';
import { Icon } from '@studio/legacy-components';
import GroupClause from 'components/Common/Clauses/Group';
import Radio from 'components/Common/UI/Forms/Radio';
import { defaultOperators } from 'constants/account/conditions';
import { filterUrlTargetingClauses } from 'utils/conditions';

const URLContent = styled.div`
  width: 840px;
  min-width: 540px;
`;

const HelpCode = styled.span`
  background-color: #f5f7fa;
  display: inline-block;
  border-radius: 3px;
  padding: 2px;
`;

const HelpCodeHighlight = styled.span`
  font-weight: 500;
  display: inline-block;
  padding: 2px;
  color: #0d7ef9;
  background-color: #c9ecff;
  border-radius: 2px;
`;

export default function URLTargeting({
  stepId,
  className,
  clauses,
  rule,
  addClause,
  updateClause,
  deleteClause,
  setDisplayAnyPage,
  setDisplaySpecificPage,
}) {
  const [deletedClauses, setDeletedClauses] = useState(null);

  const urlTargetingClauses = getFilteredClauseTree(
    filterUrlTargetingClauses,
    clauses
  );

  const isDisplayAnyPage =
    urlTargetingClauses.length === 0 ||
    getFilteredClauseTree(clause => {
      return (
        clause.operator === defaultOperators.REGEX && clause.value === '.*'
      );
    }, clauses).length === 2;

  const containsProtocol =
    !isDisplayAnyPage &&
    urlTargetingClauses.some(clause => {
      const httpPos = String(clause.value).indexOf('http');
      return httpPos === 0 || httpPos === 1;
    });

  return (
    <div className={`${className} url-targeting`}>
      <H4>Which page should we show this content on?</H4>
      <Radio
        label="Any page"
        onClick={() => {
          if (!isDisplayAnyPage) {
            setDeletedClauses(urlTargetingClauses);
          }

          setDisplayAnyPage(stepId);
        }}
        checked={isDisplayAnyPage}
      />
      <Radio
        label="At a specific path"
        onClick={() => {
          if (isDisplayAnyPage) {
            setDisplaySpecificPage(stepId, deletedClauses);
          }
        }}
        checked={!isDisplayAnyPage}
      />
      <Box marginY={15} className="option">
        <URLContent className="content">
          {(() => {
            if (!isDisplayAnyPage) {
              return (
                <div className="url-targeting">
                  <GroupClause
                    clauses={urlTargetingClauses}
                    rule={rule}
                    isRootNode
                    addGroupClause={false}
                    dissolveGroupClause={() => {}}
                    addClause={(parentId, clause) =>
                      addClause(stepId, parentId, clause)
                    }
                    updateClause={(clauseId, changes) =>
                      updateClause(stepId, clauseId, changes)
                    }
                    replaceClause={false}
                    deleteClause={clauseId => deleteClause(stepId, clauseId)}
                  />
                  <div className="help-text">
                    Matches the path after the domain, e.g.:&nbsp;
                    <HelpCode>
                      http//www.example.com/
                      <HelpCodeHighlight>[path]</HelpCodeHighlight>
                    </HelpCode>
                  </div>

                  {containsProtocol && (
                    <Notice className="alert-error">
                      <Icon icon="warning" />
                      &nbsp; Include only the path portion, not the full
                      http://... URL
                    </Notice>
                  )}
                </div>
              );
            }
            return null;
          })()}
        </URLContent>
      </Box>
    </div>
  );
}
