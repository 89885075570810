import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Notice, NOTICE_TYPES } from '@appcues/component-library';
import { Button, Creatable } from '@studio/legacy-components';
import AutosuggestProfileAttribute from 'components/Common/AutosuggestProfileAttribute';
import {
  checkIsBlank,
  checkHasProtocol,
  checkIsDuplicate,
} from 'utils/validation';
import { errorMessages } from 'constants/urlInput';

const InputWrapper = styled.div`
  display: flex;
  max-width: 700px;

  ${Creatable} {
    flex-grow: 1;
    max-width: 100%;
  }

  ${Creatable} + ${Button} {
    margin-left: 8px;
  }
`;

const ErrorWrapper = styled.div`
  max-width: 700px;
`;

export default function AddUrlStep({ handleSubmit, testUrls }) {
  const [input, setInput] = useState('');
  const [error, setError] = useState(null);

  const validateInput = value => {
    const blank = checkIsBlank(value);
    const duplicate = checkIsDuplicate(value, testUrls);
    const hasProtocol = checkHasProtocol(value);

    if (blank) {
      setError(errorMessages.BLANK);
    } else if (duplicate) {
      setError(errorMessages.DUPLICATE);
    } else if (!hasProtocol) {
      setError(errorMessages.NOPROTOCOL);
    }

    return !blank && !duplicate && hasProtocol;
  };

  const handleInputChange = value => {
    setInput(value);
  };

  const handleAddUrl = () => {
    if (validateInput(input)) {
      handleSubmit(input);
      setInput('');
      setError(null);
    }
  };

  return (
    <>
      <InputWrapper>
        <AutosuggestProfileAttribute
          value={input}
          property="_lastPageUrl"
          onChange={handleInputChange}
        />
        <Button kind="positive" onClick={handleAddUrl}>
          Test
        </Button>
      </InputWrapper>

      <ErrorWrapper>
        {error && <Notice type={NOTICE_TYPES.warning}>{error}</Notice>}
      </ErrorWrapper>
    </>
  );
}

AddUrlStep.propTypes = {
  handleSubmit: PropTypes.func,
  testUrls: PropTypes.arrayOf(PropTypes.string),
};
