import React from 'react';
import { FrequencyShape } from '@studio/conditions';
import styled from 'styled-components';

export const FrequencyText = styled.span``;

const FREQUENCY_TEXT = {
  never: 'users will not see this Flow until it is manually triggered',
  once: 'users will see this Flow once',
  every_time:
    'users will see this Flow every time they match all of the other criteria',
};

export const FrequencyRule = ({ value = 'never' }) => (
  <FrequencyText>{FREQUENCY_TEXT[value]}</FrequencyText>
);

FrequencyRule.propTypes = {
  value: FrequencyShape,
};
