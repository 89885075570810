import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CPage, CButton } from '@appcues/component-library';
import { ContentStatus, H1 } from '@studio/legacy-components';
import { DocumentTitle } from 'next/hooks/use-title';
import ChildPage from 'components/Common/ChildPage';
import StatsSection from 'components/ChecklistAnalytics/StatsSection';
import * as currentModalActions from 'actions/currentModal';
import {
  selectAccountId,
  selectAccountMeta,
  selectAccountMetaSynced,
} from 'reducers/account/meta';
import { navigate } from 'actions/routing';
import { fetchChecklistAnalytics } from 'actions/analytics';
import Loader from 'components/Common/Loader';
import {
  selectAccountChecklist,
  selectAccountChecklistsSynced,
} from 'entities/checklists';
import { selectContentAnalytics } from 'reducers/analytics';
import { EXPORT_MODAL } from 'constants/globals';
import { selectAccountSegmentsSynced } from 'reducers/account/segments';

export class ChecklistAnalytics extends Component {
  componentDidMount() {
    this.requestAnalytics();
  }

  componentDidUpdate() {
    this.requestAnalytics();
  }

  requestAnalytics = (retry = false) => {
    const { accountLoaded, checklistAnalytics, checklistId, actions } =
      this.props;

    if (
      accountLoaded &&
      !checklistAnalytics.meta.fetching &&
      (!(checklistAnalytics.meta.synced || checklistAnalytics.meta.errored) ||
        retry) &&
      actions.fetchChecklistAnalytics
    ) {
      actions.fetchChecklistAnalytics(checklistId);
    }
  };

  navigateToEdit = () => {
    const { actions, checklistId } = this.props;
    const editLink = `/checklists/${checklistId}/edit`;

    actions.navigate(editLink);
  };

  openExportModal = () => {
    const { actions, checklistId, checklist } = this.props;

    actions.showModal(EXPORT_MODAL, {
      exportType: 'checklist_id',
      exportId: checklistId,
      createdAt: checklist.createdAt,
    });
  };

  render() {
    const { checklist, loaded } = this.props;

    if (!loaded) {
      return <Loader />;
    }

    const isPublished = checklist.published;
    return (
      <ChildPage
        components={{
          Title: () => (
            <>
              <H1>{checklist.internalName}</H1>
              <ContentStatus published={isPublished} />
            </>
          ),
        }}
        breadcrumbContents={{
          label: 'Checklists',
          path: '/checklists',
          child: {
            label: `${checklist.internalName}`,
          },
        }}
        subHeaderActions={
          <>
            <CButton onClick={this.openExportModal}>Export CSV</CButton>
            <CButton type="primary" onClick={this.navigateToEdit}>
              Edit Checklist
            </CButton>
          </>
        }
      >
        <DocumentTitle
          title={`${checklist.internalName} | Checklist | Appcues`}
        />
        <CPage.Container>
          <StatsSection
            {...this.props}
            requestAnalytics={this.requestAnalytics}
          />
        </CPage.Container>
      </ChildPage>
    );
  }
}

function mapStateToProps(state, routeProps) {
  const { checklistId } = routeProps.match.params;
  const checklist = selectAccountChecklist(state, checklistId);
  const accountId = selectAccountId(state);
  const meta = selectAccountMeta(state);
  const checklistAnalytics = selectContentAnalytics(state, checklistId);

  const defaultProps = {
    accountId,
    checklistId,
    accountLoaded: selectAccountMetaSynced(state),
    analyticsLoaded: checklistAnalytics.meta.synced,
    loaded:
      selectAccountChecklistsSynced(state) &&
      selectAccountMetaSynced(state) &&
      selectAccountSegmentsSynced(state),
    meta,
    checklist,
    checklistAnalytics,
    analyticsError: checklistAnalytics.meta.errored,
  };

  return defaultProps;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...currentModalActions, fetchChecklistAnalytics, navigate },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistAnalytics);
