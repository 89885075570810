import { useCallback, useEffect, useState } from 'react';
import { checkDomainPermission } from 'next/lib/crx';
import { getChromeExtensionVersion } from 'utils/chromeExtension';

/**
 * Verifies if the Appcues Chrome extension is installed and has permission to access the
 * current domain. On focus, it checks again.
 * @returns {[boolean, boolean]}
 */
export default function useVerifyExtension() {
  const [hasCRXInstalled, setHasCRXInstalled] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [hasVerified, setHasVerified] = useState(false);

  const checkPermission = async () => {
    try {
      const permission = await checkDomainPermission();
      setHasPermission(permission);
    } catch {
      setHasPermission(false);
    }
  };

  const checkExtension = async () => {
    try {
      /* global CRX_ID */
      const version = await getChromeExtensionVersion(CRX_ID);
      setHasCRXInstalled(!!version);
    } catch {
      setHasCRXInstalled(false);
    }
  };

  const onFocus = useCallback(async () => {
    await checkExtension();
    checkPermission();
    setHasVerified(true);
  }, []);

  useEffect(() => {
    checkExtension();
    checkPermission();
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, [onFocus]);

  return { hasCRXInstalled, hasPermission, hasVerified };
}
