import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BaseModal from 'components/ModalContainer/BaseModal';
import { titleCase } from 'utils';
import { update } from 'actions/account/segments';
import SegmentUsageBreakdown from 'components/Common/SegmentUsageBreakdown';

const Modal = styled(BaseModal)`
  input {
    margin-bottom: 10px;

    &::placeholder {
      color: #bbbbbb;
      font-style: italic;
    }
  }
`;

export function UpdateSegmentModal(props) {
  const { segmentType, segmentId, changes, onSave } = props;
  const handleOK = () => {
    onSave(segmentId, changes);
  };

  return (
    <Modal
      title={`Save Changes to ${titleCase(segmentType)}`}
      okText={`Yes, Update ${titleCase(segmentType)}`}
      modalClass="update-segment-modal"
      onClickOK={handleOK}
      scrollBody
      {...props}
    >
      <p>
        Changes to this segment will immediately affect the following Appcues
        content.
      </p>
      <p>Are you sure you&apos;d like to continue?</p>
      <SegmentUsageBreakdown segmentId={segmentId} />
    </Modal>
  );
}

UpdateSegmentModal.propTypes = {
  segmentType: PropTypes.oneOf(['bookmark', 'segment']).isRequired,
  segmentId: PropTypes.string.isRequired,
  changes: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  onSave: update,
};

export default connect(() => ({}), mapDispatchToProps)(UpdateSegmentModal);
