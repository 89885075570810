import React from 'react';

const RedirectIcon = () => {
  return (
    <svg>
      <path d="M18,6.48 C18,6.675 17.9363839,6.84375 17.8091518,6.98625 L12.6662946,12.74625 C12.5390625,12.88875 12.3883929,12.96 12.2142857,12.96 C12.0401786,12.96 11.8895089,12.88875 11.7622768,12.74625 C11.6350446,12.60375 11.5714286,12.435 11.5714286,12.24 L11.5714286,9.36 L9.32142857,9.36 C8.66517857,9.36 8.07756696,9.3825 7.55859375,9.4275 C7.03962054,9.4725 6.52399554,9.553125 6.01171875,9.669375 C5.49944196,9.785625 5.05412946,9.945 4.67578125,10.1475 C4.29743304,10.35 3.94419643,10.610625 3.61607143,10.929375 C3.28794643,11.248125 3.02008929,11.626875 2.8125,12.065625 C2.60491071,12.504375 2.44252232,13.02375 2.32533482,13.62375 C2.20814732,14.22375 2.14955357,14.9025 2.14955357,15.66 C2.14955357,16.0725 2.16629464,16.53375 2.19977679,17.04375 C2.19977679,17.08875 2.20814732,17.176875 2.22488839,17.308125 C2.24162946,17.439375 2.25,17.53875 2.25,17.60625 C2.25,17.71875 2.22154018,17.8125 2.16462054,17.8875 C2.10770089,17.9625 2.02901786,18 1.92857143,18 C1.82142857,18 1.72767857,17.93625 1.64732143,17.80875 C1.60044643,17.74125 1.55691964,17.65875 1.51674107,17.56125 C1.4765625,17.46375 1.43136161,17.35125 1.38113839,17.22375 C1.33091518,17.09625 1.29575893,17.00625 1.27566964,16.95375 C0.425223214,14.81625 0,13.125 0,11.88 C0,10.3875 0.177455357,9.13875 0.532366071,8.13375 C1.6171875,5.11125 4.546875,3.6 9.32142857,3.6 L11.5714286,3.6 L11.5714286,0.72 C11.5714286,0.525 11.6350446,0.35625 11.7622768,0.21375 C11.8895089,0.07125 12.0401786,0 12.2142857,0 C12.3883929,0 12.5390625,0.07125 12.6662946,0.21375 L17.8091518,5.97375 C17.9363839,6.11625 18,6.285 18,6.48 Z" />
    </svg>
  );
};

export default RedirectIcon;
