import React from 'react';
import PropTypes from 'prop-types';

import ThemeCard from './ThemeCard';

const ThemesList = ({ themes, itemRole }) => {
  return Object.values(themes)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(theme => {
      return (
        <ThemeCard
          theme={theme}
          themeId={theme.id}
          key={theme.id}
          role={itemRole}
        />
      );
    });
};

ThemesList.propTypes = {
  themes: PropTypes.object.isRequired,
  itemRole: PropTypes.string,
};

ThemesList.defaultProps = {
  themes: {},
  itemRole: '',
};

export default ThemesList;
