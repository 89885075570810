import { getUserRole } from 'helpers/account-management';
import * as actionTypes from 'constants/actionTypes';
import { USER_REMOVED } from 'constants/account/users';

const initialState = {
  synced: false,
  users: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REPLACE_ACCOUNT_USERS:
      return {
        synced: true,
        users: action.payload,
      };
    case actionTypes.REPLACE_ACCOUNT_USER: {
      const updatedUser = action.payload;
      const updatedUsers = {
        ...state.users,
        [updatedUser.id]: updatedUser,
      };

      return {
        synced: state.synced,
        users: updatedUsers,
      };
    }
    case USER_REMOVED: {
      const { id } = action.payload;
      const { [id]: omit, ...nextUsers } = state.users;
      return {
        ...state,
        users: nextUsers,
      };
    }
    case actionTypes.REPLACE_ACCOUNT_USER_META: {
      const users = {
        ...state.users,
        [action.payload.id]: {
          ...state.users[action.payload.id],
          meta: action.payload.meta,
          synced: true,
        },
      };

      return {
        ...state,
        users,
      };
    }
    case actionTypes.CLEAR_ACCOUNT_DATA:
      return initialState;
    default:
      return state;
  }
}

export const selectAccountUsers = state => state.account.users.users;
export const selectAccountUsersSynced = state => state.account.users.synced;
export const selectAccountUser = (state, userId) =>
  selectAccountUsers(state)[userId];

export const selectUserRole = (state, userId) => {
  const accountUser = selectAccountUser(state, userId);
  return getUserRole(accountUser);
};

export const selectAccountUserFullName = (state, userId) => {
  const user = selectAccountUser(state, userId);
  return user && user.meta && user.meta.fullname;
};
