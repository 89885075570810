import React from 'react';
import PropTypes from 'prop-types';

import { PublishingBanner } from '@studio/settings-panels';
import ExperimentBanner from 'next/components/ExperimentBanner';
import MobilePublishingManager from './MobilePublishingManager';

export function MobileBanner({ id, hasActiveExperiment }) {
  return (
    <MobilePublishingManager id={id}>
      {hasActiveExperiment ? ExperimentBanner : PublishingBanner}
    </MobilePublishingManager>
  );
}

MobileBanner.propTypes = {
  id: PropTypes.string.isRequired,
  hasActiveExperiment: PropTypes.bool,
};

export default MobileBanner;
