import { Section, Text, Link } from '@appcues/sonar';
import styled from 'styled-components';

export const UsersList = styled.ul`
  list-style: none;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const User = styled.li`
  padding: 8px;
  padding: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr 0.2fr;
  align-items: center;
  border-bottom: 1px solid #dce4f2;
  gap: 16px;
`;

export const Identifier = styled(Link)`
  width: 100%;
  overflow: hidden;
  > span {
    width: 100%;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const DateWrapper = styled.div`
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UserSection = styled(Section)`
  width: 50%;
`;

export const SpinnerWrapper = styled.div`
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const EmptyHelperText = styled(Text)`
  text-align: center;
`;
