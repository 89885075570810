import styled from 'styled-components';
import { Section, Table, Alert, Button } from '@appcues/sonar';

export const Wrapper = styled(Section)`
  width: 100%;
  min-height: 232px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-large);
  // We override the padding here to match the padding of the CPanel component. When this component
  // is built in studio5 we should use the default padding from the Section component for all sections.
  padding: var(--spacing-large);
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TextWrapper = styled.span`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 4px;
`;

export const Cell = styled(Table.Td)`
  min-width: 142px;
  max-width: 264px;
`;

export const HeaderCell = styled(Table.Th)`
  min-width: 142px;
  max-width: 264px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  padding: var(--spacing-large);
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--spacing-large);
`;

export const NoResultsMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(var(--table-wrapper-min-height) - 38px);
  flex-direction: column;
  gap: var(--spacing-regular);
  margin-top: var(--spacing-regular);
`;

export const TableHeaderContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TableHeaderLabelWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 174px;
`;

export const AlertAction = styled(Alert.Action)`
  justify-content: flex-end;
`;

export const ButtonLink = styled(Button)`
  &:is(:active, :hover) {
    text-decoration: none;
  }
`;
