import {
  takeEvery,
  all,
  put,
  select,
  call,
  getContext,
} from 'redux-saga/effects';
import toast from 'next/lib/toast';
import { reportError } from 'helpers/error-reporting';

import * as satisfactionActionTypes from 'constants/satisfaction/actionTypes';
import { FLUSH_SATISFACTION_TO_FIREBASE } from 'constants/actionTypes';

import { trackEvent } from 'actions/events';
import { selectUserId } from 'reducers/user';
import {
  replaceAccountSatisfaction,
  safisfactionCreated,
} from 'actions/satisfaction';

import { replaceRule } from 'sagas/account/rule-conditions';

/**
 * Creates a new satisfaction survey with rule and saves in Firebase.
 *
 * @param action
 */

export function* createSatisfactionWithRule() {
  try {
    const api = yield getContext('api');

    // does acct already have an nps?
    const { data: npsArr } = yield call(api.getAllNps);
    if (npsArr.length > 0) {
      throw new Error('Account already has an NPS survey.');
    }

    const {
      data: { nps, rule },
    } = yield call(api.createNps);

    yield all([
      put(replaceAccountSatisfaction({ [nps.id]: nps })),
      call(replaceRule, rule),
      put(
        trackEvent('Created a Satisfaction Survey', {
          // FIXME: `name` field removed from payload as it was not referencing
          //        any value within scope - maybe `nps.name`?
          satisfaction_id: nps.id,
        })
      ),
    ]);
    yield put(safisfactionCreated(nps.id));
  } catch (error) {
    reportError(error);
    yield call(
      toast.error,
      'Oops, something went wrong. Please reload the page and try again.'
    );
  }
}

/**
 * Applies the specified changes to the survey,
 * then persists the new value to Firebase
 *
 * @param action
 */

function* updateSatisfactionAndFlushSatisfaction(action) {
  const { satisfactionId, changes } = action.payload;

  const updatedAt = Date.now();
  const userId = yield select(selectUserId);

  yield put({
    type: satisfactionActionTypes.UPDATE_SATISFACTION_IN_STORE,
    payload: {
      satisfactionId,
      userId,
      updatedAt,
      changes,
    },
  });

  yield put({
    type: FLUSH_SATISFACTION_TO_FIREBASE,
    payload: {
      satisfactionId,
    },
  });

  yield put(
    trackEvent('Updated a Satisfaction Survey', {
      satisfaction_id: satisfactionId,
    })
  );
}

function* setAutoCollapseFeedbackSetting(action) {
  const { satisfactionId, autoCollapseFeedbackForm } = action.payload;

  const changes = {
    autoCollapseFeedbackForm,
  };

  yield call(updateSatisfactionAndFlushSatisfaction, {
    type: satisfactionActionTypes.UPDATE_SATISFACTION_AND_FLUSH_SATISFACTION,
    payload: {
      satisfactionId,
      changes,
    },
  });
}

export function* satisfaction() {
  yield takeEvery(
    satisfactionActionTypes.CREATE_SATISFACTION_WITH_RULE,
    createSatisfactionWithRule
  );
  yield takeEvery(
    satisfactionActionTypes.UPDATE_SATISFACTION_AND_FLUSH_SATISFACTION,
    updateSatisfactionAndFlushSatisfaction
  );
  yield takeEvery(
    satisfactionActionTypes.SET_AUTO_COLLAPSE_FEEDBACK_SETTING,
    setAutoCollapseFeedbackSetting
  );
}

export default satisfaction;
