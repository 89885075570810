import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CRow, CCol, P } from '@appcues/component-library';
import { getLanguageFromCode } from 'helpers/locales';
import { getThemeFor } from 'utils';
import CoolTip from 'components/Common/CoolTip';
import { selectAttributesColumnsByName } from 'selectors/profile-attributes';
import DeleteLocaleManager from 'components/Settings/Localization/DeleteLocaleManager';

const TRUNCATED_LIMIT = 3;

const Header = styled(CRow)`
  width: 100%;
  border-bottom: 1px solid ${getThemeFor('$gray-2')};
  padding: 5px;
`;

const Pill = styled.span`
  display: inline-flex;
  margin-right: 5px;
  padding: 4px;
  border-radius: 4px;
  background-color: ${getThemeFor('$gray-1')};
  border: 1px solid ${getThemeFor('$gray-3')};
`;

const ValuesTooltip = ({ values, length }) => (
  <CoolTip tip={values.join(', ')}>{` +${values.length - length}`}</CoolTip>
);

export const DisplayClauses = ({ conditions }) => {
  const {
    and: [
      {
        properties: { property, value },
      },
    ],
  } = conditions;

  const values = value.split('\n');
  const valuesHead = values.slice(0, TRUNCATED_LIMIT);
  const isBrowserLang = property === '_lastBrowserLanguage';

  const languages =
    isBrowserLang && valuesHead.map(code => getLanguageFromCode(code));

  const displayVals = isBrowserLang ? languages : valuesHead;

  return (
    <div>
      {displayVals.map((displayVal, i) => (
        <Pill data-testid={`pill-${i}`} key={displayVal}>
          {displayVal}
        </Pill>
      ))}
      {values.length > TRUNCATED_LIMIT && (
        <ValuesTooltip values={values} length={TRUNCATED_LIMIT} />
      )}
    </div>
  );
};

const Rows = ({ locales }) =>
  locales.map(({ conditions, name, id }) => {
    return (
      <ConnectedRow key={id} conditions={conditions} name={name} id={id} />
    );
  });

const Row = ({ conditions, name, id, propertyName }) => {
  return (
    <CRow
      key={name}
      marginTop="10px"
      marginBottom="10px"
      between="xs"
      middle="xs"
    >
      <CCol xs={3}>
        <P marginLeft={5}>{name}</P>
      </CCol>
      <CCol xs={3}>
        <P>{propertyName}</P>
      </CCol>
      <CCol xs={5}>
        <DisplayClauses conditions={conditions} />
      </CCol>
      <CCol xs={1}>
        <DeleteLocaleManager id={id} />
      </CCol>
    </CRow>
  );
};

const mapStateToProps = (state, { conditions }) => {
  const {
    and: [
      {
        properties: { property },
      },
    ],
  } = conditions || { and: [{ properties: {} }] };
  const attribute = selectAttributesColumnsByName(state, property) || {};

  return {
    propertyName: attribute.label,
  };
};

const ConnectedRow = connect(mapStateToProps)(Row);

export default function LocalesTable({ locales }) {
  return (
    <div>
      <Header fullWidth data-testid="locales-table-header">
        <CCol xs={3}>
          <P bold>Language</P>
        </CCol>
        <CCol xs={3}>
          <P bold>Property</P>
        </CCol>
        <CCol xs={6}>
          <P bold>Targeting Criteria</P>
        </CCol>
      </Header>
      <div data-testid="locales-table-rows">
        <Rows locales={locales} />
      </div>
    </div>
  );
}

const conditionsShape = PropTypes.shape({
  and: PropTypes.arrayOf({
    properties: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
});

LocalesTable.propTypes = {
  locales: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      conditions: conditionsShape,
    })
  ),
};

DisplayClauses.propTypes = {
  conditions: conditionsShape,
};
