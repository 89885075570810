/* global DEBUG_NEXT */

import { local } from 'next/lib/storage';

// Localstorage key for storing whether the DevTools are explicitly enabled
export const ENABLED_KEY = 'appcues:devtools';

/**
 * Check whether to enable DevTools
 *
 * @param {User} user - User meta
 * @param {boolean} spoofing - Whether user is spoofing
 * @return {boolean} Whether DevTools are enabled
 */
export const enabled = (user, spoofing = false) => {
  // Check if user is an admin or is spoofing
  const admin = user?.isAdmin || spoofing;

  // Check if the DevTools are explicitly enabled or disabled
  const state = local.get(ENABLED_KEY);
  const disable = state === false;
  const enable = state === true;

  switch (true) {
    // Disable for explicitly turned off
    case disable:
      return false;

    // Enable for local development
    case DEBUG_NEXT:
      return true;

    // Enable if enabled and admin
    default:
      return enable && admin;
  }
};
