import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Text, Flex } from '@appcues/component-library';

import { Link, Td } from '@studio/legacy-components';
import ExpandableRow from 'components/Insights/Explorer/Common/ExpandableRow';
import PinExpandedRow from 'components/Insights/Explorer/Common/PinExpandedRow';
import {
  ExternalLinkIcon,
  SelectedIcon,
  LiveIcon,
  DraftIcon,
  ExpandedNameColumn,
} from 'components/Insights/Common/Styled';

export const PinRow = ({
  id,
  name,
  published,
  pins,

  totalEvents,
  most_recent_timestamp,
  selectedEvents,
  enableSelect,
  isExpanded,
  onSelect,
  onClick,
}) => {
  // Prevent event bubbling up to the clickable accordion div
  const handleLinkClick = useCallback(e => {
    e.stopPropagation();
    return e;
  }, []);

  const rowChecked = (selectedEvents || []).some(
    ({ id: rowId }) => rowId === id
  );

  return (
    <ExpandableRow
      id={id}
      isExpanded={isExpanded}
      onClick={onClick}
      expandable={pins.map(
        ({
          stepId,
          stepName,
          type,
          eventType,
          events,
          users,
          most_recent_timestamp: timestamp,
        }) => {
          const checked = (selectedEvents || []).some(
            ({
              id: selectedPinId,
              event: selectedEvent,
              stepId: selectedStepId,
            }) =>
              selectedPinId === id &&
              selectedEvent === eventType &&
              selectedStepId === stepId
          );

          return (
            <PinExpandedRow
              key={stepId}
              id={id}
              source="pin"
              stepId={stepId}
              stepName={stepName}
              type={type}
              eventType={eventType}
              events={events}
              users={users}
              most_recent_timestamp={timestamp}
              parentName={name}
              enableSelect={enableSelect}
              checked={checked}
              onSelect={onSelect}
            />
          );
        }
      )}
    >
      <ExpandedNameColumn>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex maxWidth="80%">
            <Text is="div" truncate>
              {name}
            </Text>
            <div>
              {rowChecked && !isExpanded && (
                <SelectedIcon overpin="visible" title="checkmark" />
              )}
            </div>
          </Flex>

          <Link
            to={`/pins/${id}/analytics`}
            onClick={handleLinkClick}
            onKeyDown={handleLinkClick}
            target="_blank"
            rel="noopener noreferrer"
            title="Pin Analytics Page"
          >
            <ExternalLinkIcon />
          </Link>
        </Flex>
      </ExpandedNameColumn>
      <Td>
        {published ? (
          <>
            <LiveIcon /> <Text marginLeft="5px">Live</Text>
          </>
        ) : (
          <>
            <DraftIcon /> <Text marginLeft="5px">Draft</Text>
          </>
        )}
      </Td>
      <Td>{totalEvents ? totalEvents.toLocaleString() : '-'}</Td>
      <Td> </Td>
      <Td>
        {most_recent_timestamp
          ? moment(most_recent_timestamp).format('lll')
          : '-'}
      </Td>
    </ExpandableRow>
  );
};

PinRow.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  onSelect: PropTypes.func,
  enableSelect: PropTypes.bool,
  name: PropTypes.string,
  totalUsers: PropTypes.number,
  totalEvents: PropTypes.number,
  most_recent_timestamp: PropTypes.string,
  pins: PropTypes.arrayOf(
    PropTypes.shape({
      stepId: PropTypes.string,
      stepName: PropTypes.string,
      type: PropTypes.string,
      eventType: PropTypes.string,
      users: PropTypes.number,
      most_recent_timestamp: PropTypes.string,
    })
  ),

  id: PropTypes.string,
  published: PropTypes.bool,
};

export default PinRow;
