import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip } from '@appcues/sonar';
import { CPage, CRow, CPanel, Text } from '@appcues/component-library';
import {
  ContentStatus,
  H1,
  FULL_RANGE_OPTIONS,
  Select,
} from '@studio/legacy-components';

import styled from 'styled-components';
import useDateRangeQuery from 'next/hooks/use-date-range-query';
import TimestampRefresh from 'next/components/TimestampRefresh';
import BoundedDateRangeDropdown from 'next/components/BoundedDateRangeDropdown';
import { ExperimentShape } from 'next/entities/experiment';
import ExperimentBadge from 'next/components/ExperimentBadge';
import useTitle from 'next/hooks/use-title';
import { selectSchedule, fetchSchedules } from 'next/entities/schedules';
import { ScheduleType } from 'next/components/ExperienceScheduling/types';
import SchedulingTooltipMessage from 'next/components/ExperienceScheduling/SchedulingTooltipMessage';
import { shouldRenderScheduleStatus } from 'next/components/ExperienceScheduling/validation';

import {
  WEB_FLOWS_ANALYTICS_SEGMENT_FILTER,
  SURVEYS_RESPONSE,
} from 'next/entities/features';
import { selectAccountFeature } from 'reducers/account/features';
import { selectFlowHistoricalLimit } from 'selectors/timeframe-options';
import ChildPage from 'components/Common/ChildPage';
import { selectFlowHistory } from 'reducers/account/flowVersions';
import Loader from 'components/Common/Loader';
import { selectSegmentTargetingOptions } from 'reducers/account/segments';
import { clear } from 'actions/analytics-v2';
import { selectFlow, selectAreFlowsSynced } from 'reducers/account/flows';
import EmptyState from './EmptyState';
import FlowAnalyticsActions from './FlowAnalyticsActions';
import FlowActivityChart from './FlowActivityChart';
import PreviewCarousel from './PreviewCarousel';
import StepByStepTable from './StepByStepTable';
import RecentUsersTable from './RecentUsersTable';
import IssuesTable from './IssuesTable';
import Scoreboard from './Scoreboard';
import SurveyResponses from './SurveyResponses';
import { Filters } from './styled';

const StyledCPanel = styled(CPanel)`
  h2 {
    color: var(--foreground-primary);
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-small);
  }
`;

const BadgeContainer = styled.div`
  display: flex;
  gap: var(--spacing-small);
`;

export const Headline = styled(H1)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FlowAnalytics = ({
  flow,
  experiment,
  limit,
  loaded,
  onRefresh,
  options = FULL_RANGE_OPTIONS,
  hasSegmentFilter,
  segmentsOptions = [],
  schedule,
  onLoad,
  hasSurveys,
  flowHistory,
}) => {
  useTitle(`${flow ? flow.name : ''} | Analytics | Appcues`);
  const [updated, setUpdated] = useState(Date.now());
  const hasScheduleStatusToShow = shouldRenderScheduleStatus(
    schedule,
    flow?.state
  );
  const [segmentId, setSegmentId] = useState(null);
  const [{ range, start, end }, setDateRange] = useDateRangeQuery(options, {
    range: 7,
  });
  const selected = segmentsOptions.find(option => option.value === segmentId);

  // using layout effect
  // to load data as fast as possible
  // and avoid flickering
  useLayoutEffect(() => {
    onLoad?.();
  }, [onLoad]);

  if (!loaded || !flow) {
    return <Loader />;
  }

  const startTime = start.getTime();
  const endTime = end.getTime();

  const handleRefresh = () => {
    setUpdated(Date.now());
    onRefresh();
  };

  return (
    <ChildPage
      history={flowHistory}
      createdAt={flow.createdAt}
      createdByUserId={flow.createdBy}
      updatedAt={flow.updatedAt}
      updatedByUserId={flow.updatedBy}
      breadcrumbContents={{
        label: 'Flows',
        path: '/flows',
        child: {
          label: `${flow.name}`,
        },
      }}
      components={{
        Title: function Header() {
          return (
            <>
              <Headline title={flow.name}>{flow.name}</Headline>
              <BadgeContainer>
                <ContentStatus
                  state={flow.BadgeContainerstate}
                  published={flow.published}
                />

                {hasScheduleStatusToShow && (
                  <Tooltip
                    content={<SchedulingTooltipMessage schedule={schedule} />}
                    delayDuration={0}
                  >
                    <ContentStatus state="SCHEDULED" published={false} />
                  </Tooltip>
                )}

                <ExperimentBadge
                  experimentId={experiment?.id}
                  experimentName={experiment?.name}
                  experimentState={experiment?.state}
                  flowState={flow.state}
                />
              </BadgeContainer>
            </>
          );
        },
      }}
      subHeaderActions={
        <FlowAnalyticsActions
          flowId={flow.id}
          isLocalized={flow.lockedForLocalization}
          createdAt={flow.createdAt}
        />
      }
    >
      <CPage.Container>
        {flow.publishedAt && (
          <CRow>
            <Filters>
              <BoundedDateRangeDropdown
                limit={limit}
                onApply={setDateRange}
                options={options}
                value={{ range, start, end }}
              />

              {hasSegmentFilter && (
                <Select
                  icon="users"
                  onChange={option => setSegmentId(option?.value ?? null)}
                  options={segmentsOptions}
                  placeholder="Select a segment..."
                  value={selected}
                  isClearable
                />
              )}
            </Filters>
            <TimestampRefresh endTime={updated} onClick={handleRefresh} />
          </CRow>
        )}
        <CRow marginTop="16px">
          <CPanel>
            <Text type="tertiary" marginBottom="10px" alignSelf="end">
              {flow.publishedAt
                ? 'Displaying most recently published version'
                : 'Displaying most recent draft'}
            </Text>
            <PreviewCarousel flowId={flow.id} />
          </CPanel>
        </CRow>
        {flow.publishedAt ? (
          <>
            <CRow marginTop="30px">
              <StyledCPanel title="Flow analytics">
                <p>Key metrics about your flow performance</p>
                <FlowActivityChart
                  flowId={flow.id}
                  startTime={startTime}
                  endTime={endTime}
                  segmentId={segmentId}
                />
              </StyledCPanel>
            </CRow>
            <CRow marginTop="30px">
              <Scoreboard
                flowId={flow.id}
                startTime={startTime}
                endTime={endTime}
                segmentId={segmentId}
              />
            </CRow>
            <CRow marginTop="30px">
              <StepByStepTable
                flowId={flow.id}
                startTime={startTime}
                endTime={endTime}
                segmentId={segmentId}
              />
            </CRow>
            <CRow marginTop="30px">
              <IssuesTable
                flowId={flow.id}
                startTime={startTime}
                endTime={endTime}
                segmentId={segmentId}
              />
            </CRow>
            {hasSurveys && (
              <CRow marginTop="30px">
                <SurveyResponses
                  flowId={flow.id}
                  start={new Date(startTime).toISOString()}
                  end={new Date(endTime).toISOString()}
                />
              </CRow>
            )}
            <CRow marginTop="30px">
              <RecentUsersTable
                flowId={flow.id}
                startTime={startTime}
                endTime={endTime}
                segmentId={segmentId}
              />
            </CRow>
          </>
        ) : (
          <CRow marginTop="30px">
            <EmptyState />
          </CRow>
        )}
      </CPage.Container>
    </ChildPage>
  );
};

FlowAnalytics.propTypes = {
  flow: PropTypes.shape({
    createdAt: PropTypes.number,
    createdBy: PropTypes.string,
    id: PropTypes.string,
    lockedForLocalization: PropTypes.bool,
    name: PropTypes.string,
    published: PropTypes.bool,
    publishedAt: PropTypes.number,
    state: PropTypes.string,
    updatedAt: PropTypes.number,
    updatedBy: PropTypes.string,
  }),
  experiment: ExperimentShape,
  limit: PropTypes.number,
  loaded: PropTypes.bool,
  onRefresh: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  schedule: ScheduleType,
  onLoad: PropTypes.func,
  hasSurveys: PropTypes.bool,
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { stepId: flowId },
    },
  }
) => {
  const flow = selectFlow(state, flowId);
  return {
    flow,
    experiment: flow?.experiment,
    limit: selectFlowHistoricalLimit(state),
    loaded: selectAreFlowsSynced(state),
    schedule: selectSchedule(state),
    hasSurveys: selectAccountFeature(state, SURVEYS_RESPONSE),
    segmentsOptions: selectSegmentTargetingOptions(state),
    hasSegmentFilter: selectAccountFeature(
      state,
      WEB_FLOWS_ANALYTICS_SEGMENT_FILTER
    ),
    flowHistory: selectFlowHistory(state, flowId),
  };
};

function mapDispatchToProps(
  dispatch,
  {
    match: {
      params: { stepId },
    },
  }
) {
  return {
    onRefresh: clear,
    onLoad: () => dispatch(fetchSchedules(stepId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FlowAnalytics);
