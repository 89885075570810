import { createCollectionActions } from 'next/lib/collections';

export const TYPE = 'accountUsers';

export const INVITE_TEAM_MEMBER = '@accountUsers/INVITE_TEAM_MEMBER';

export const inviteTeamMember = ({ email, role }) => {
  return {
    type: INVITE_TEAM_MEMBER,
    payload: { email, role },
  };
};

export const { reject, replace, resolve, drop, readOne, patterns, update } =
  createCollectionActions(TYPE);
