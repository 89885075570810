import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CDialog } from '@appcues/component-library';

const Text = styled.p`
  line-height: 24px;
`;

export default function DeleteGoalModal({
  actions,
  deleteGoal,
  goalId,
  goalName,
}) {
  const handleClickDelete = () => {
    deleteGoal(goalId);
    actions.hideModal();
  };

  return (
    <CDialog
      title={`Delete "${goalName}"?`}
      type="negative"
      primaryActionText="Delete goal"
      onPrimaryAction={handleClickDelete}
      onSecondaryAction={actions.hideModal}
    >
      <Text>
        Deleting a goal will permanently remove it from your account and prevent
        you from viewing any results associated with it.
      </Text>
      <br />
      <Text>Are you sure you&apos;d like to delete this goal?</Text>
    </CDialog>
  );
}

DeleteGoalModal.propTypes = {
  goalId: PropTypes.string.isRequired,
  goalName: PropTypes.string.isRequired,
  deleteGoal: PropTypes.func.isRequired,
  actions: PropTypes.shape({ hideModal: PropTypes.func.isRequired }).isRequired,
};
