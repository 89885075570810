import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Link, Td, Tr } from '@studio/legacy-components';
import { encodeEventNameUrl } from 'components/Insights/utils';
import EventToggle from 'components/Insights/Explorer/Common/EventToggle';

import { SelectColumn } from 'components/Insights/Common/Styled';

export const ExpandedRow = ({
  id,
  eventName,
  eventType,
  parentName,
  events,
  users,
  source,
  most_recent_timestamp,
  enableSelect,
  checked,
  onSelect,
}) => {
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  if (!searchParams.has('id')) {
    searchParams.append('id', id);
  }

  const singleMetricUrl = `/insights/explorer/${source}/${encodeEventNameUrl(
    eventType
  )}`;
  return (
    <Tr>
      <SelectColumn>
        <EventToggle
          label={`Select ${source} event: ${parentName} ${eventName.toLowerCase()}`}
          checked={checked}
          disabled={!checked && !enableSelect}
          onClick={() => {
            onSelect({
              source,
              event: eventType,
              id,
            });
          }}
        />
      </SelectColumn>
      <Td>
        <Link
          to={{
            pathname: singleMetricUrl,
            search: searchParams.toString(),
          }}
          title={`${parentName} ${eventName.toLowerCase()} detail page`}
        >
          {eventName}
        </Link>
      </Td>
      <Td />
      <Td>{events ? events.toLocaleString() : '-'}</Td>
      <Td>{users ? users.toLocaleString() : '-'}</Td>
      <Td>
        {most_recent_timestamp
          ? moment(most_recent_timestamp).format('lll')
          : '-'}
      </Td>
    </Tr>
  );
};

ExpandedRow.propTypes = {
  id: PropTypes.string,
  source: PropTypes.string,
  eventName: PropTypes.string,
  eventType: PropTypes.string,
  parentName: PropTypes.string,
  events: PropTypes.number,
  users: PropTypes.number,
  most_recent_timestamp: PropTypes.number,
  enableSelect: PropTypes.string,
  checked: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default ExpandedRow;
