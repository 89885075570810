import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { navigate } from 'actions/routing';
import { updateAccountMeta } from 'actions/account/meta';

class SegmentEnabled extends Component {
  componentDidMount() {
    const { onLoad, navigate: navigateTo } = this.props;
    onLoad();
    navigateTo(`/${window.location.search}`);
  }

  render() {
    return false;
  }
}

SegmentEnabled.propTypes = {
  onLoad: PropTypes.func,
  navigate: PropTypes.func,
};

const mapDispatchToProps = {
  onLoad: () => updateAccountMeta({ segmentEnabled: Date.now() }),
  navigate: location => navigate(location),
};

export default connect(null, mapDispatchToProps)(SegmentEnabled);
