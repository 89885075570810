import { call, put, takeEvery, getContext } from 'redux-saga/effects';
import toast from 'next/lib/toast';
import {
  send,
  resolve,
  reject,
  insert,
  remove,
  patterns,
  TRANSLATION_UPLOADED,
  TRANSLATION_DELETED,
  TRANSLATIONS_REQUESTED,
  REMOVE_TRANSLATIONS,
  translationsDownloaded,
  translationsRemoved,
} from 'actions/account/translations';
import { promisaga } from 'utils/as-promised';
import { downloadFile } from 'utils/filesystem';
import { reportError } from 'helpers/error-reporting';

export function* fetchTranslations({ payload: { flowId } }) {
  try {
    yield put(send());
    const api = yield getContext('api');
    const {
      data: { translations },
    } = yield call(api.getTranslationMetaForFlow, flowId);
    const result = translations.reduce((acc, translation) => {
      acc[translation.id] = translation;
      return acc;
    }, {});
    yield put(resolve(result));
  } catch (error) {
    yield put(reject(error));
    yield call(reportError, error);
  }
}

export function* createTranslation({
  payload: { flowId, localeId, translation },
}) {
  try {
    const api = yield getContext('api');
    const { data } = yield call(
      api.uploadTranslation,
      flowId,
      localeId,
      translation
    );
    yield put(insert(data));
    yield call(toast.success, 'Successfully uploaded');
  } catch (error) {
    yield call(reportError, error);
    throw error;
  }
}

export function* deleteTranslation({ payload: { flowId, translationId } }) {
  try {
    const api = yield getContext('api');
    yield call(api.deleteTranslation, flowId, translationId);
    yield put(remove(translationId));
  } catch (error) {
    yield call(reportError, error);
    yield call(toast.error, 'Could not delete translation - please try again');
  }
}

export function* downloadAllTranslations({ payload: { flowId, format } }) {
  try {
    const api = yield getContext('api');
    yield call(api.localizeFlow, flowId);
    const { data: blob } = yield call(api.downloadTranslations, flowId, format);
    yield call(downloadFile, blob, flowId, 'zip');
    yield put(translationsDownloaded(flowId));
  } catch (error) {
    yield call(reportError, error);
    yield call(toast.error, 'Could not download content - please try again');
  }
}

export function* removeAllTranslations({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    yield call(api.delocalizeFlow, id);
    yield put(translationsRemoved(id));
  } catch (error) {
    yield call(reportError, error);
    yield call(toast.error, 'Failed to delete translations');
  }
}

export default function* saga() {
  yield takeEvery(patterns.callApi, fetchTranslations);
  yield takeEvery(TRANSLATION_UPLOADED, promisaga(createTranslation));
  yield takeEvery(TRANSLATION_DELETED, deleteTranslation);
  yield takeEvery(TRANSLATIONS_REQUESTED, downloadAllTranslations);
  yield takeEvery(REMOVE_TRANSLATIONS, removeAllTranslations);
}
