import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Definition,
  DescriptiveList,
  ExternalLink,
  H3,
  StatusLabel,
  Term,
  TextClickToCopy,
} from '@studio/legacy-components';
import { AppShape } from 'next/entities/apps';
import AppMenu from './AppMenu';
import { APP_DISABLED, getPlatformData } from './lib';
import {
  Header,
  Body,
  InfoColumn,
  ActionsColumn,
  PlatformIcon,
} from './styled';
import EditAppModal from './EditAppModal';
import DisableModal from './DisableModal';
import EnableModal from './EnableModal';
import DebuggerModal from './DebuggerModal';

export const AppCard = ({ app = {}, installed, hasPushEnabled }) => {
  const { id: appId, state, platform, name } = app;
  const { platformIcon, platformName, sdkUrl } = getPlatformData(platform);

  const [activeModal, setActiveModal] = useState(null);

  const handleOpenModal = modal => {
    setActiveModal(modal);
  };

  const handleCloseModal = () => {
    setActiveModal(null);
  };

  return (
    <Card>
      <Header>
        <H3 title={name}>{name}</H3>
        <AppMenu
          app={app}
          onOptionClick={handleOpenModal}
          hasPushEnabled={hasPushEnabled}
        />
      </Header>
      <Body>
        <InfoColumn>
          <DescriptiveList>
            <Term>Platform</Term>
            <Definition>
              <PlatformIcon icon={platformIcon} />
              {platformName}
            </Definition>
            <Term>App ID</Term>
            <Definition title={appId}>
              <TextClickToCopy text={appId} icon />
            </Definition>
          </DescriptiveList>
        </InfoColumn>
        <ActionsColumn>
          {state === APP_DISABLED ? (
            <StatusLabel icon="minus-circle" label="Disabled" />
          ) : installed ? (
            <StatusLabel icon="check-circle" active label="Enabled" />
          ) : (
            <Button forwardedAs={ExternalLink} href={sdkUrl} kind="positive">
              Set up
            </Button>
          )}
        </ActionsColumn>
      </Body>
      <EditAppModal
        app={app}
        visible={activeModal === 'edit'}
        onClose={handleCloseModal}
      />
      <DisableModal
        appId={appId}
        onClose={handleCloseModal}
        visible={activeModal === 'disable'}
      />
      <EnableModal
        appId={appId}
        onClose={handleCloseModal}
        visible={activeModal === 'enable'}
      />
      <DebuggerModal
        appId={appId}
        onClose={handleCloseModal}
        visible={activeModal === 'debugger'}
      />
    </Card>
  );
};

AppCard.propTypes = {
  app: AppShape,
  installed: PropTypes.bool,
  hasPushEnabled: PropTypes.bool,
};

export default AppCard;
