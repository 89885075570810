import { take, takeEvery, cancel, call } from 'redux-saga/effects';

/**
 * takeUntil is an effect that perputually takes one action until another
 * one is recieved.  For examle, lazy-loading collections entity-by-entity
 * until the entire collection is synced
 *
 * @param takePattern
 * @param takeWorker
 * @param untilPattern
 * @returns {ForkEffect}
 */

export function takeUntil(takePattern, takeWorker, untilPattern) {
  return call(function* listener() {
    const takeWatcher = yield takeEvery(takePattern, takeWorker);
    const interruptWatcher = yield take(untilPattern);
    yield cancel(takeWatcher);
    return interruptWatcher;
  });
}
