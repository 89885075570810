import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { propertyOperators } from '@appcues/libcues';
import { Select, Input, DateInput } from '@studio/legacy-components';
import { millisecondsPerDay } from 'constants/account/conditions';

const OPTIONS = [
  { value: 'ever', label: 'previously' },
  { value: propertyOperators.SINCE, label: 'since' },
  {
    value: propertyOperators.LESS_THAN_X_DAYS_AGO,
    label: 'in the last',
  },
];

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  padding: 10px 0;

  > * {
    margin-right: 10px;
    max-width: 200px;
  }

  ${Select} {
    min-width: 200px;
  }
`;

export default function UserHistoryDateRange({ clause, updateClause }) {
  const operator = clause.operator || 'ever';
  const times = clause.times == null ? 1 : clause.times;
  const valueAsTrailingDays = clause.value
    ? clause.value / millisecondsPerDay
    : '';

  const selectedOperator = OPTIONS.find(option =>
    operator === 'since' && clause.value === '0'
      ? option.value === 'ever'
      : option.value === operator
  );

  const handleTimesChange = ({ target: { valueAsNumber: nextTimes } }) => {
    updateClause(clause.id, {
      times: Number.isNaN(nextTimes) ? '' : nextTimes,
    });
  };

  const handleTimesBlur = ({ target: { valueAsNumber: nextTimes } }) => {
    if (Number.isNaN(nextTimes)) {
      updateClause(clause.id, { times: 1 });
    }
  };

  const handleOperatorChange = ({ value: nextOperator }) => {
    switch (nextOperator) {
      case 'ever':
        updateClause(clause.id, {
          operator: null,
          value: null,
        });
        break;

      case propertyOperators.SINCE:
        updateClause(clause.id, {
          operator: propertyOperators.SINCE,
          value: moment().startOf('day').valueOf(),
        });
        break;

      case propertyOperators.LESS_THAN_X_DAYS_AGO:
        updateClause(clause.id, {
          operator: propertyOperators.LESS_THAN_X_DAYS_AGO,
          value: millisecondsPerDay,
        });
        break;

      default:
        break;
    }
  };

  const handleDateValueChange = date => {
    updateClause(clause.id, {
      value: moment(date).startOf('day').valueOf(),
    });
  };

  const handleNumericValueChange = ({
    target: { valueAsNumber: nextValue },
  }) => {
    updateClause(clause.id, {
      value: Number.isNaN(nextValue) ? '' : nextValue * millisecondsPerDay,
    });
  };

  const handleNumericValueBlur = ({ target: { valueAsNumber: nextValue } }) => {
    if (Number.isNaN(nextValue)) {
      updateClause(clause.id, {
        value: millisecondsPerDay,
      });
    }
  };

  return (
    <Wrapper>
      <span>At least</span>

      <Input
        type="number"
        name="times"
        aria-label="times"
        value={times}
        onChange={handleTimesChange}
        onBlur={handleTimesBlur}
      />

      <span>{times === 1 ? 'time' : 'times'}</span>

      <Select
        name="operator"
        value={selectedOperator}
        options={OPTIONS}
        onChange={handleOperatorChange}
      />

      {operator === propertyOperators.SINCE && (
        <DateInput
          future
          name="value"
          value={new Date(Number.parseInt(clause.value, 10))}
          onChange={handleDateValueChange}
        />
      )}

      {operator === propertyOperators.LESS_THAN_X_DAYS_AGO && (
        <>
          <Input
            type="number"
            name="value"
            aria-label="value"
            value={valueAsTrailingDays}
            onChange={handleNumericValueChange}
            onBlur={handleNumericValueBlur}
          />
          {valueAsTrailingDays === 1 ? ' day' : ' days'}
        </>
      )}
    </Wrapper>
  );
}

UserHistoryDateRange.propTypes = {
  clause: PropTypes.shape({
    id: PropTypes.string,
    operator: PropTypes.string,
    times: PropTypes.number,
    value: PropTypes.string,
  }),
  readOnly: PropTypes.bool,
  updateClause: PropTypes.func,
};
