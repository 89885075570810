import { useState, useEffect } from 'react';
import { launchpadApiClient } from 'next/lib/clients/launchpad-api';

const useLaunchpad = userId => {
  const [launchpadData, setLaunchpadData] = useState();
  useEffect(() => {
    launchpadApiClient.getLaunchpad(userId).then(setLaunchpadData);
  }, [userId]);
  return launchpadData;
};

export default useLaunchpad;
