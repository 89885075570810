import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { CPage, CSkeleton } from '@appcues/component-library';
import { Button, ContentStatus, Icon } from '@studio/legacy-components';
import useTitle from 'next/hooks/use-title';
import { selectAccountChecklist } from 'entities/checklists';
import { selectAccountUserFullName } from 'reducers/account/users';
import IndexPage from 'components/Common/IndexPage';

import { CHECKLIST_EVENTS } from 'components/Insights/constants';
import { decodeEventNameUrl } from 'components/Insights/utils';
import { ExportManager } from 'components/Insights/Common/ExportManager';
import SingleMetricContent from './SingleMetricContent';
import { HeaderWrapper, HeaderTitle, SubHeaderColumn } from './styled';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const ChecklistMetric = ({
  encodedEventName,
  id,
  checklist,
  createdByUserName,
  updatedByUserName,
}) => {
  const eventName = decodeEventNameUrl('checklist', encodedEventName);
  const { label = '' } =
    CHECKLIST_EVENTS.find(item => item.type === eventName) || {};
  const title = `${checklist?.internalName}: ${label}`;
  useTitle(`${title} | Events Explorer | Appcues`);

  return (
    <IndexPage
      title={
        !checklist ? (
          <CSkeleton height={20} width={400} />
        ) : (
          <HeaderWrapper>
            <HeaderTitle>{title}</HeaderTitle>
            <ContentStatus published={checklist.published} />
          </HeaderWrapper>
        )
      }
      description={
        !checklist ? (
          <CSkeleton height={18} width={400} />
        ) : (
          <HeaderWrapper>
            <SubHeaderColumn>
              Checklist created{' '}
              {createdByUserName ? `by ${createdByUserName} ` : ''}
              {checklist.createdAt && moment(checklist.createdAt).format('ll')}
            </SubHeaderColumn>
            <SubHeaderColumn>
              {updatedByUserName && checklist.updatedAt ? 'Updated ' : ''}
              {updatedByUserName ? `by ${updatedByUserName} ` : ''}
              {checklist.updatedAt && moment(checklist.updatedAt).fromNow()}
            </SubHeaderColumn>
          </HeaderWrapper>
        )
      }
      subHeaderActions={
        <>
          <ExportManager
            events={[{ source: 'checklist', event: eventName, id }]}
          />
          <StyledLink to={`/checklists/${id}/analytics`} target="_blank">
            <Button kind="secondary">
              <Icon icon="external-link-alt" />
              Checklist details
            </Button>
          </StyledLink>
        </>
      }
    >
      <CPage.Container>
        <SingleMetricContent eventName={eventName} id={id} source="checklist" />
      </CPage.Container>
    </IndexPage>
  );
};

ChecklistMetric.propTypes = {
  checklist: PropTypes.shape({
    internalName: PropTypes.string,
    published: PropTypes.bool,
  }),
  encodedEventName: PropTypes.string,
  id: PropTypes.string,
  createdByUserName: PropTypes.string,
  updatedByUserName: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { encodedEventName },
    },
    location: { search },
  } = ownProps;

  const { id } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const checklist = selectAccountChecklist(state, id);

  if (!checklist) {
    return {
      encodedEventName,
      id,
    };
  }

  return {
    checklist,
    encodedEventName,
    id,
    createdByUserName: selectAccountUserFullName(state, checklist.createdBy),
    updatedByUserName: selectAccountUserFullName(state, checklist.updatedBy),
  };
};

const ConnectedChecklistMetric = connect(mapStateToProps)(ChecklistMetric);

ConnectedChecklistMetric.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      encodedEventName: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default ConnectedChecklistMetric;
