import React, { useRef, useContext, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Box,
  CIconButton,
  H2,
  P,
  H5,
  Caption,
} from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';
import useOnKeyDown from 'hooks/useOnKeyDown';
import ModalBase from './ModalBase';

const Context = React.createContext();

const Modal = ({
  backgroundColor,
  className,
  children,
  id,
  onClose,
  isOpen,
  title,
  subtitle,
  label,
  effect,
  type,
  ariaLabel,
  width,
}) => {
  const modalNode = useContext(Context);
  useOnKeyDown('Escape', onClose);

  const computedWidth =
    type === 'full' ? '100%' : type === 'small' ? '30%' : '40%';
  const height =
    type === 'full' ? '100%' : type === 'small' ? '300px' : '500px';
  const hasHeader = label || title || subtitle;
  return modalNode
    ? ReactDOM.createPortal(
        <ModalBase
          id={id}
          className={className}
          visible={isOpen}
          width={width || computedWidth}
          height={height}
          effect={effect}
          onClickAway={onClose}
          ariaLabel={ariaLabel || title}
        >
          <ModalInnerContainer backgroundColor={backgroundColor}>
            <CloseButton type={type} onClose={onClose} />
            {hasHeader && (
              <ModalHeader>
                <div className="header-content">
                  <H5 type="tertiary">{label}</H5>
                  <H2>{title}</H2>
                  <P>{subtitle}</P>
                </div>
              </ModalHeader>
            )}
            {children}
          </ModalInnerContainer>
        </ModalBase>,
        modalNode
      )
    : null;
};

export function ModalProvider({ children }) {
  const modalRef = useRef();
  const [context, setContext] = useState();

  useEffect(() => {
    setContext(modalRef.current);
  }, []);

  return (
    <>
      <Context.Provider value={context}>{children}</Context.Provider>
      <div ref={modalRef} />
    </>
  );
}

const CloseButton = ({ type, onClose }) => (
  <CIconButton className="close-button" border="none" onClick={onClose}>
    <Icon icon="times" title="close" fontSize={20} />
    {type === 'full' && <Caption>Esc</Caption>}
  </CIconButton>
);

const grayBackgroundColor = ({ backgroundColor }) =>
  backgroundColor === 'gray' &&
  css`
    && > * {
      background: ${({ theme }) => theme['$gray-1']};
    }
  `;

const ModalInnerContainer = styled.div`
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  .close-button {
    z-index: 3;
    position: absolute;
    top: 25px;
    right: 20px;
    flex-direction: column;
  }

  ${grayBackgroundColor}
`;

const ModalHeader = styled.header`
  order: 0;
  display: block;
  position: relative;
  height: auto;
  min-height: 120px;
  padding: 15px;
  text-align: center;
  -webkit-backface-visibility: hidden;
  z-index: 2;
  .header-content {
    margin-top: 5px;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
`;
const ModalContent = styled.main`
  display: block;
  box-sizing: border-box;
  flex: 1;
  order: 1;
  z-index: 0;
  padding: 15px;
  overflow-y: scroll;
  overflow: auto;
  background: linear-gradient(white 99%, rgba(200, 200, 200, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 99%) 0 100%,
    radial-gradient(
      farthest-corner at 50% 0,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
        farthest-corner at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 10px, 100% 10px, 100% 2px, 100% 2px;
  background-attachment: local, local, scroll, scroll;
  .container {
    margin: 0 auto;
    max-width: 800px;
  }
`;

const ModalFooter = styled.footer`
  width: 100%;
  background-color: white;
  bottom: 0;
  border-radius: 12px;
  z-index: 10;
  order: 9;
  padding: 15px;
  text-align: center;
  button {
    margin-left: 10px;
  }
`;

Modal.Footer = ({ children }) => <ModalFooter>{children}</ModalFooter>;
Modal.Content = ({ children, ...props }) => (
  <ModalContent>
    <Box {...props}>{children}</Box>
  </ModalContent>
);
Modal.Footer = ModalFooter;
Modal.displayName = 'Modal';
Modal.Footer.displayName = 'Modal.Footer';
Modal.Content.displayName = 'Modal.Content';

Modal.propTypes = {
  backgroundColor: PropTypes.oneOf(['white', 'gray']),
  className: PropTypes.string,
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  label: PropTypes.string,
  effect: PropTypes.oneOf([
    'fadeInDown',
    'fadeInUp',
    'fadeInLeft',
    'fadeInRight',
  ]),
  type: PropTypes.oneOf(['small', 'medium', 'full']),
  onClickAway: PropTypes.func,
};

Modal.defaultProps = {
  backgroundColor: 'white',
  isOpen: true,
  effect: 'fadeInUp',
  type: 'medium',
  onClickAway: null,
};
export default Modal;
