import { put, call, getContext } from 'redux-saga/effects';
import { reportError } from 'helpers/error-reporting';
import { send, resolve, reject } from './actions';

export function* fetchGates() {
  try {
    const api = yield getContext('api');
    yield put(send());

    const { data } = yield call(api.getAccountGates);

    const res = data ? Object.assign({}, ...data) : {};

    yield put(resolve(res));
  } catch (error) {
    yield call(reportError, error);
    yield put(reject(error));
  }
}
