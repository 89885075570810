export const determineTopFlows = (flowStats, steps, number) => {
  const parentFlowIds = Object.keys(flowStats || {}).filter(
    step => steps && steps[step] && !steps[step].parentId
  );

  const sortedFlowStats = parentFlowIds.sort((a, b) => {
    if (flowStats[b]['all-time-unique'] && flowStats[a]['all-time-unique']) {
      return (
        flowStats[b]['all-time-unique'].reached -
        flowStats[a]['all-time-unique'].reached
      );
    }
    if (flowStats[a]['all-time-unique']) {
      return -1;
    }
    return 1;
  });

  const iteratorLength =
    sortedFlowStats.length < number ? sortedFlowStats.length : number;

  const topFlows = [];

  for (let i = 0; topFlows.length < iteratorLength; i++) {
    const currentFlowId = sortedFlowStats[i];
    const currentFlow = steps[currentFlowId];

    if (currentFlow) {
      topFlows.push(currentFlow);
    }
  }

  return topFlows;
};
