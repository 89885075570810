import { createCollectionReducer } from 'reducers/account/collections';

export const TYPE = 'versionedFlows';
export default createCollectionReducer(TYPE);

export const selectVersionedFlow = (state, { flowId, versionId }) => {
  return state.account[TYPE][`${flowId}+${versionId}`];
};

export const selectVersionedStepChildren = (state, { flowId, versionId }) => {
  const flow = selectVersionedFlow(state, { flowId, versionId }) || {};

  return Object.values(flow.steps || {})
    .sort((a, b) => a.index - b.index)
    .reduce((acc, stepGroup) => {
      const { id: stepId, stepType, steps = {}, hotspots = {} } = stepGroup;

      [...Object.values(steps), ...Object.values(hotspots)]
        .sort((a, b) => a.index - b.index)
        .forEach(stepChild => {
          const { id, createdAt } = stepChild;

          acc.push({
            flowId,
            stepId,
            id,
            stepType,
            createdAt,
          });
        });

      return acc;
    }, []);
};
