import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  filterAudienceTargetingClauses,
  getFilteredClauseTree,
  getFlattenedClausesFromNestedConditions,
} from '@appcues/libcues';
import { selectSchedule } from 'next/entities/schedules';
import { formatScheduleDate } from 'next/components/ExperienceScheduling/dates';

import TargetingDetailsRow from 'components/ModalContainer/FlowPublishingModal/TargetingDetailsTable/TargetingDetailsRow';
import { getFrequencyConditions } from 'transforms/migrations';
import {
  filterABGroupClauses,
  filterDomainTargetingClauses,
  filterUrlTargetingClauses,
} from 'utils/conditions';
import { selectAccountFeature } from 'reducers/account/features';
import { AB_TESTING } from 'constants/features';

const Container = styled.div`
  margin: 1rem;
`;

const Table = styled.table`
  width: 100%;
  margin-top: 0.5rem;
`;

const Cell = styled.td`
  padding: 0.35em 1em;
  vertical-align: bottom;
  &:first-of-type {
    font-weight: bold;
  }
`;

export function TargetingDetailsTable({
  clauses,
  hasABTesting,
  rule,
  schedule,
}) {
  const urlClauses = getFilteredClauseTree(filterUrlTargetingClauses, clauses);

  const domainClauses = getFilteredClauseTree(
    filterDomainTargetingClauses,
    clauses
  );

  const frequencyClauses = getFlattenedClausesFromNestedConditions(
    getFrequencyConditions(rule.id, rule.frequency)
  );

  const audienceClauses = getFilteredClauseTree(
    filterAudienceTargetingClauses,
    clauses
  );

  const abGroupClauses = getFilteredClauseTree(filterABGroupClauses, clauses);

  return (
    <Container>
      <Table>
        <tbody>
          <TargetingDetailsRow
            label="Page"
            clauses={urlClauses}
            omitTailPropertyNames
          />
          <TargetingDetailsRow
            label="Frequency"
            clauses={frequencyClauses}
            omitTailPropertyNames
          />
          <TargetingDetailsRow
            label="Domains"
            clauses={domainClauses}
            omitTailPropertyNames
          />
          <TargetingDetailsRow
            label="Audience"
            clauses={audienceClauses}
            omitTailPropertyNames={false}
          />
          {hasABTesting && (
            <TargetingDetailsRow
              label="A/B Group"
              clauses={abGroupClauses}
              omitTailPropertyNames={false}
            />
          )}
          {schedule?.startDate && (
            <tr>
              <Cell>Publish date</Cell>
              <Cell>{formatScheduleDate(schedule?.startDate)}</Cell>
            </tr>
          )}
          {schedule?.endDate && (
            <tr>
              <Cell>Unpublish date</Cell>
              <Cell>{formatScheduleDate(schedule?.endDate)}</Cell>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    hasABTesting: selectAccountFeature(state, AB_TESTING),
    schedule: selectSchedule(state),
  };
}

export default connect(mapStateToProps)(TargetingDetailsTable);
