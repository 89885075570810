import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PROMOTERS, PASSIVES, DETRACTORS } from 'components/nps/constants';

const Wrapper = styled.div`
  --score-icon-size: 36px;

  align-items: center;
  border-radius: calc(var(--score-icon-size) / 2);
  border-style: solid;
  border-width: 1px;
  font-size: var(--medium-large);
  font-weight: var(--bold);
  display: flex;
  height: var(--score-icon-size);
  justify-content: center;
  width: var(--score-icon-size);

  ${({ kind }) => {
    switch (kind) {
      case PROMOTERS:
        return `
          background-color: var(--nps-promoters-light);
          border-color: var(--nps-promoters);
          color: var(--nps-promoters);
        `;

      case DETRACTORS:
        return `
          background-color: var(--nps-detractors-light);
          border-color: var(--nps-detractors);
          color: var(--nps-detractors);
        `;

      case PASSIVES:
      default:
        return `
          background-color: var(--nps-passives-light);
          border-color: var(--nps-passives);
          color: var(--nps-passives);
        `;
    }
  }}
`;

function ScoreIcon({ className, score }) {
  const kind = score >= 9 ? PROMOTERS : score >= 7 ? PASSIVES : DETRACTORS;
  return (
    <Wrapper className={className} kind={kind}>
      {score}
    </Wrapper>
  );
}

ScoreIcon.propTypes = {
  className: PropTypes.string,
  score: PropTypes.number,
};

export default styled(ScoreIcon)``;
