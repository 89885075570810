import isEmpty from 'lodash.isempty';

import { PLAN_TYPES, PRICES, BILLING_INTERVALS } from 'constants/plans';
import { SUPPORTED_ENTITLEMENTS } from 'constants/entitlements';

export const BILLING_FIELDS = ['line1', 'country', 'city', 'postalCode'];

export const getSlackChurnString = (cancellationInfo, fields) => {
  const {
    accountCreated,
    accountId,
    billingEmail,
    expectedChurnDate,
    companyName,
    requestDate,
    planName,
    subscriptionStart,
    userEmail,
  } = cancellationInfo;
  const {
    competitorInfo,
    leaveReason,
    qualitativeFeedback,
    replacementInfo,
    useAgainInfo,
  } = fields;

  const slackMessage = `*Churn Alert*\nAccount ID: ${accountId}\nCompany Name: ${companyName}\nBilling Contact: ${billingEmail}\nRequestor Contact: ${userEmail}\nPlan Name:${planName}\nChurn Request Date: ${requestDate}\nAppcues Account Created Date: ${accountCreated}\nSubscription Start Date: ${subscriptionStart}\nExpected Churn Date: ${expectedChurnDate}\n\n*Churn Feedback Responses*\nChurn Reason: ${leaveReason}\nChurn Reason Detail: ${qualitativeFeedback}\nAppcues Replacement: ${replacementInfo}\nCompetitor:${competitorInfo}\nWould come back to Appcues?: ${useAgainInfo}`;

  return slackMessage;
};

export const calculatePlanPrice = ({ planInterval, plan, planLimit }) => {
  // Prices will be undefined for legacy plans
  const prices =
    (PRICES[planInterval] && PRICES[planInterval][planLimit]) ?? {};
  const isHighVolume = planLimit === 'high-volume';
  const isEnterprise = plan.name === 'Enterprise';

  const hasPrice = !isEnterprise && !isHighVolume;

  return hasPrice
    ? plan.price || prices[plan.pricePlanId || plan.id]
    : "Let's talk";
};

// The calculations within this method include only self-service plans
export const calculatePlanPriceFromLocation = location => {
  const params = new URLSearchParams(location.search);
  const planInterval = params.get('pi');
  const planLimit = Number.parseInt(params.get('pl'), 10);
  const planType = params.get('pp');

  const plan = PLAN_TYPES.find(p => p.id === planType);
  const { multiplier } = BILLING_INTERVALS[planInterval];

  const rawPrice = Number.parseFloat(
    calculatePlanPrice({ planInterval, plan, planLimit }),
    10
  );
  const monthlyPrice = calculatePlanPrice({
    planInterval: 'monthly',
    plan,
    planLimit,
  });

  return {
    ...plan,
    planType,
    planLimit,
    planInterval,
    period: BILLING_INTERVALS[planInterval].displayPeriod,
    cadence: BILLING_INTERVALS[planInterval].displayName,
    rawPrice,
    price: rawPrice * multiplier,
    savings: monthlyPrice * multiplier - rawPrice * multiplier,
  };
};

// The calculations within this method include legacy plans
export const calculatePlanPriceFromBasicInfo = ({
  planType,
  planInterval,
  planLimit,
}) => {
  const plan = PLAN_TYPES.find(p => p.id === planType);
  const { multiplier } = BILLING_INTERVALS[planInterval];

  let rawPrice;
  let monthlyPrice;
  let savings;

  if (planInterval && plan && planLimit) {
    rawPrice = Number.parseFloat(
      calculatePlanPrice({ planInterval, plan, planLimit }),
      10
    );

    monthlyPrice = calculatePlanPrice({
      planInterval: 'monthly',
      plan,
      planLimit,
    });

    savings = monthlyPrice * multiplier - rawPrice * multiplier;
  }

  return {
    ...plan,
    cadence: BILLING_INTERVALS[planInterval].displayName,
    planType,
    planLimit,
    planInterval,
    period: BILLING_INTERVALS[planInterval].displayPeriod,
    price: rawPrice * multiplier,
    rawPrice,
    savings,
  };
};

export const formatNumber = price => {
  if (!price) return '$ -';
  return new Intl.NumberFormat('en-US', {}).format(price);
};

export const formatCurrency = (price, forceDecimals = true) => {
  if (!price) return '$ -';
  if (price % 1 === 0 && !forceDecimals) return `$${formatNumber(price)}`;

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(price);
};

export const STRIPE_ERROR_MAP = {
  incomplete_cvc: 'Security code is required',
  incomplete_number: 'Card number is required',
  incomplete_expiry: 'Expiration date is required',
  invalid_expiry_year_past: 'Expiration year is the past',
  invalid_number: 'Card number is invalid',
};

export const getIntervalShortVersion = planInterval => {
  if (planInterval === 'monthly') return 'mo';
  if (planInterval === 'yearly' || planInterval === 'annual') return 'yr';
  return '';
};

export const buildQueryParams = (planData, selectedEntitlements) => {
  const searchParams = new URLSearchParams();

  searchParams.set('pp', planData.id);
  searchParams.set('pl', planData.planLimit);
  searchParams.set('pi', planData.planInterval);

  if (selectedEntitlements) {
    SUPPORTED_ENTITLEMENTS.forEach(entitlement => {
      if (selectedEntitlements[entitlement]) {
        searchParams.set(entitlement, selectedEntitlements[entitlement]);
      }
    });
  }

  return searchParams.toString();
};

/**
 * @param {object} selectedEntitlements Object with entitlements selected, which is what the user
 * wants to purchase.
 * @param {object} entitlementsProcessed Object with entitlements pricing and units information
 * @returns a transformed object that we will send to the customer API. It looks as follows
 * {
 *   entitlement_A: {
 *     purchased_units: X
 *   },
 *   entitlement_B: {
 *     purchased_units: X
 *   }
 * }
 */
export const formatAddonsForPurchase = (
  selectedEntitlements = {},
  entitlementsProcessed
) =>
  SUPPORTED_ENTITLEMENTS.map(addon => ({
    [addon]: {
      purchased_units: entitlementsProcessed
        ? entitlementsProcessed[addon].purchasedUnits +
          selectedEntitlements[addon]
        : 0,
    },
  })).reduce((acc, curr) => ({ ...acc, ...curr }), {});

export const haveValuesChanged = (newState, submittedValues) => {
  if (isEmpty(submittedValues)) return true;
  return Object.keys(submittedValues).some(
    key => newState[key] !== submittedValues[key]
  );
};
