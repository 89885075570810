import React from 'react';
import ContentEditable from 'react-contenteditable';
import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';

// NOTE: Hardcoded for heading style.
// TODO: Make generic (any type of text, not just headings)

const AppcuesContentEditable = props => {
  return (
    <EditableWrapper>
      <ContentEditable {...props} />
      <Icon icon="pencil-alt" />
    </EditableWrapper>
  );
};

const EditableWrapper = styled.div`
  display: flex;
  align-items: center;

  i {
    align-items: center;
    color: ${props => props.theme['$gray-30']};
    display: inline-flex;
    height: 16px;
    padding-right: 10px;
  }

  .fa-pencil-alt {
    opacity: 0;
  }

  &:hover {
    .fa-pencil-alt {
      opacity: 1;
    }
  }
`;

export default styled(AppcuesContentEditable)`
  min-width: 150px;
  border: none;
  outline: none;
  background-color: transparent;
  border-bottom: ${props =>
    props.error ? `1px dashed ${props.theme.$red}` : '1px dashed transparent'};
  transition-property: border-bottom-color, color;
  transition: 0.1s ease;
  cursor: text;
  position: relative;
  padding-right: 40px;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 36px;
  font-weight: 300;
  color: ${props => props.theme['$gray-70']};
  max-width: 475px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:after {
    display: ${props => (props.error ? 'none' : 'inherit')};
  }

  &[contenteditable='true'] {
    &:active,
    &:focus {
      color: ${props => props.theme.$blue};
      border-bottom-color: ${props =>
        props.error ? props.theme.$red : props.theme['$gray-3']};
      white-space: pre-line;
    }

    &:hover,
    &:active,
    &:focus {
      &:after {
        opacity: 1;
      }
    }

    &:empty:before {
      content: attr(placeholder);
      display: block; /* For Firefox */
      opacity: 0.5;
      font-weight: 300;
      color: ${props => props.theme['$gray-30']};
    }
  }
`;
