import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as yup from 'yup';
import styled from 'styled-components';
import { CDialog, CForm, CButton } from '@appcues/component-library';
import { CheckboxSelect } from '@studio/legacy-components';
import { modalStatuses, PUBLISH_ACTIONS } from 'constants/modalStatuses';
import { getFlowPublishingApiPromise } from 'helpers/publishing-api';
import { navigate } from 'actions/routing';
import { hideModal } from 'actions/currentModal';
import { create as createFlow } from 'actions/account/flows';
import OpenInBuilderModal from 'components/OpenInBuilder/OpenInBuilderModal';
import { selectGate, ONLY_CUSTOM_BUTTONS } from 'entities/gates';
import { selectFlows } from 'reducers/account/flows';
import { buildDeepLinkToStepChild } from 'utils/steps';
import * as persistence from 'helpers/persistence';
import { openFlowInBuilder } from 'utils/url';
import { selectChromeExtensionVersion } from 'reducers/chromeExtension';
import { canOpenInBuilder } from 'utils/chromeExtension';

const GroupWrapper = styled.div`
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledFooter = styled(CDialog.Footer)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Text = styled.p`
  line-height: 24px;
  padding: 4px 0;
`;

export class CreateFlowModal extends Component {
  state = {
    requestState: modalStatuses.PROMPT,
    name: '',
    flowUrl: null,
    flowId: null,
  };

  handleSubmit = ({ name, previewUrl, tagSelections }) => {
    const {
      accountApiKey,
      accountId,
      userId,
      allSteps,
      chromeExtensionVersion,
      customButtonsEnabled,
      createFlow: create,
      hideModal: hide,
      navigate: navigateTo,
    } = this.props;

    const dontShowOpenInBuilderModal =
      persistence.getItem('dontShowOpenInBuilderModal') === 'true';

    const tags = tagSelections ? tagSelections.map(({ value }) => value) : [];

    const data = {
      account_id: accountId,
      account_api_key: accountApiKey,
      user_id: userId,
      name,
      preview_url: previewUrl,
      format_version: customButtonsEnabled ? 2 : 1,
      tags,
    };

    const isLatestVersion = canOpenInBuilder(chromeExtensionVersion);

    this.setState({ requestState: modalStatuses.PENDING, name });

    getFlowPublishingApiPromise(PUBLISH_ACTIONS.CREATE, data)
      .then(({ data: { flow } }) => {
        create(flow);

        if (isLatestVersion) {
          const url = buildDeepLinkToStepChild(flow, null, allSteps);
          this.setState({ flowUrl: url });
          if (dontShowOpenInBuilderModal) {
            openFlowInBuilder(flow.id, null, url);
            hide();
          } else {
            this.setState({
              flowId: flow.id,
              requestState: modalStatuses.SUCCESS,
            });
          }
        } else {
          hide();
          navigateTo(`/edit-in-chrome/stepGroup/${flow.id}`, true);
        }
      })
      .catch(error => {
        this.setState({ requestState: modalStatuses.ERROR });
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  render() {
    const { tags, hideModal: hide } = this.props;
    const { requestState, flowUrl, flowId, name } = this.state;

    const tagOptions = Object.values(tags).map(tag => {
      return { label: tag.name, value: tag.id };
    });

    switch (requestState) {
      case modalStatuses.PROMPT:
        return (
          <CDialog title="Create Flow" components={{ Footer: false }}>
            Just some information first and we&apos;ll start building!
            <GroupWrapper>
              <CForm
                onSubmit={this.handleSubmit}
                initialValues={{
                  name: '',
                  previewUrl: '',
                  tagSelections: '',
                }}
                validationSchema={yup.object().shape({
                  name: yup.string().required('Required'),
                  previewUrl: yup
                    .string()
                    .url('Must be a valid URL')
                    .required('Required'),
                })}
              >
                <CForm.Field
                  label="Name"
                  name="name"
                  placeholder="Welcome tour"
                />
                <CForm.Field
                  label="Start building this flow on"
                  name="previewUrl"
                  placeholder="https://appcues.com/"
                />
                <CForm.Field
                  label="Tags"
                  name="tagSelections"
                  placeholder="Select tags"
                  isMultiSelect
                >
                  <CheckboxSelect
                    options={tagOptions}
                    placeholder="Select tags"
                    portal
                  />
                </CForm.Field>
                <StyledFooter>
                  <CButton onClick={hide}>Cancel</CButton>
                  <CForm.SubmitButton
                    type="positive"
                    disabledUntilDirty={false}
                  >
                    Create
                  </CForm.SubmitButton>
                </StyledFooter>
              </CForm>
            </GroupWrapper>
          </CDialog>
        );
      case modalStatuses.PENDING:
        return (
          <CDialog title={`Creating "${name}"`} components={{ Footer: false }}>
            <Text>Getting everything set up for you to build!</Text>
          </CDialog>
        );
      case modalStatuses.ERROR:
        return (
          <CDialog title="Something went wrong" primaryActionText="Okay">
            <Text> We weren&apos;t able to create your flow.</Text>
            <Text>
              {' '}
              Try again, and if the problem persists please contact support.
            </Text>
          </CDialog>
        );
      case modalStatuses.SUCCESS:
        return (
          <OpenInBuilderModal
            url={flowUrl}
            onClose={hide}
            stepGroupId={flowId}
          />
        );
      default:
        return null;
    }
  }
}

CreateFlowModal.propTypes = {
  accountApiKey: PropTypes.string,
  accountId: PropTypes.string,
  allSteps: PropTypes.objectOf(PropTypes.object),
  chromeExtensionVersion: PropTypes.string,
  createFlow: PropTypes.func,
  customButtonsEnabled: PropTypes.bool,
  hideModal: PropTypes.func,
  navigate: PropTypes.func,
  tags: PropTypes.shape({
    createdAt: PropTypes.number,
    createdBy: PropTypes.string,
    id: PropTypes.string,
    index: PropTypes.number,
    name: PropTypes.string,
  }),
  userId: PropTypes.string,
};

function mapStateToProps(state) {
  const allSteps = selectFlows(state);
  return {
    allSteps,
    chromeExtensionVersion: selectChromeExtensionVersion(state),
    customButtonsEnabled: selectGate(state, ONLY_CUSTOM_BUTTONS),
  };
}

export default connect(mapStateToProps, { createFlow, hideModal, navigate })(
  CreateFlowModal
);
