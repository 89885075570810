import { createSelector } from 'reselect';
import stringify from 'fast-json-stable-stringify';
import { selectFlows } from 'reducers/account/flows';
import { selectAccountChecklists } from 'entities/checklists';
import { groupByMonth } from 'components/Common/QueryResults/processors';
import { removeRecoveredErrors } from 'components/Insights/utils';
import {
  CHECKLIST_EVENTS,
  FLOW_EVENTS,
  MOBILE_EVENTS,
  BANNER_EVENTS,
  PIN_EVENTS,
  GOAL_EVENTS,
} from 'components/Insights/constants';
import { selectAccountAnalytics } from 'reducers/account/analytics-v2';
import {
  selectPins,
  selectMobileFlows,
  selectExperiences,
} from 'entities/experiences';
import { selectAccountGoals } from 'reducers/account/goals';

export const setOf = key => arr =>
  // NOTE: Coercing Set to array via spreading currently not supported due to
  //       our babel configurations using core-js for IE support.
  // eslint-disable-next-line unicorn/prefer-spread
  Array.from(new Set(arr.map(({ [key]: val }) => val)));

export const splitBy = key => arr =>
  setOf(key)(arr).map(value => arr.filter(({ [key]: val }) => val === value));

export const flatten = arr => arr.flat();

export const removeDailyRecoveredErrors = resultsByDay =>
  resultsByDay.map(removeRecoveredErrors);

/**
 * Reverse engineers query.conditions to derive a unique
 * fingerprint for each selectedEvent's results
 *
 * @param conditions
 * @returns {string[]}
 */
export const getEventIdentifiers = ({ conditions: [[, ...conditions]] }) =>
  // NOTE: Coercing Set to array via spreading currently not supported due to
  //       our babel configurations using core-js for IE support.
  // eslint-disable-next-line unicorn/prefer-spread
  Array.from(
    new Set(
      conditions
        .map(condition => {
          const [key, ...restCondition] = condition;
          if (['and', 'or'].includes(key)) {
            return restCondition.reduce((acc, [type, , val]) => {
              acc[type === 'event' ? 'name' : type] = Array.isArray(val)
                ? val[0]
                : val;
              return acc;
            }, {});
          }
          const [, val] = restCondition;
          return { [key === 'event' ? 'name' : key]: val };
        })
        .map(stringify)
    )
  );

/**
 * Accepts an analytics result representing metrics bucketed by a time
 * frequency dimension and converts them to a Highcharts series.
 *
 * @param results
 * @param eventIdentifiers
 * @param userEventNames
 * @param metricColors
 * @returns {{color: string, fillOpacity: number, data: *, marker: {fillColor: string, symbol: string}, name: string, type: string}[]}
 */
export const resultsToSeries = (
  frequency,
  results,
  eventIdentifiers,
  userEventNames = [],
  metricColors = [
    '#9D9DFF',
    '#6FDDDB',
    '#FF97BC',
    '#FFBB61',
    '#7cb5ec',
    '#434348',
    '#90ed7d',
    '#f7a35c',
    '#8085e9',
    '#f15c80',
  ]
) => {
  const allResultDays = setOf(frequency)(results);
  return eventIdentifiers.map((eventIdentifier, n) => {
    const parsedIdentifier = JSON.parse(eventIdentifier);
    return {
      name: eventIdentifier,
      color: metricColors[n % metricColors.length],
      fillOpacity: 0.4,
      marker: {
        symbol: 'circle',
        fillColor: metricColors[n],
      },
      pointPadding: 0,
      borderWidth: 0,
      data: allResultDays.map(resultDay => {
        // we want one data point per selectedEvent per day (x),
        // even if no events were recorded that day (y=0)
        const { events = 0, users = 0 } =
          results.find(
            ({ [frequency]: x, ...restProps }) =>
              x === resultDay &&
              Object.keys(parsedIdentifier).every(
                key => parsedIdentifier[key] === restProps[key.toLowerCase()]
              )
          ) || {};
        return {
          x: Date.parse(resultDay),
          y: userEventNames.includes(parsedIdentifier.name) ? users : events,
        };
      }),
    };
  });
};

export const makeUniqueSelectAugmentedChartSeries = () =>
  createSelector(
    (state, { query }) => selectAccountAnalytics(state, query),
    (_, { query }) => query,
    (_, { frequency }) => frequency,
    selectFlows,
    selectAccountChecklists,
    selectPins,
    selectMobileFlows,
    selectExperiences,
    selectAccountGoals,
    (
      rawResults,
      query,
      frequency,
      flows,
      checklists,
      pins,
      mobile,
      experiences,
      goals
    ) => {
      if (!rawResults) {
        return null;
      }

      const results =
        frequency === 'month' ? groupByMonth(rawResults, 'name') : rawResults;

      const split = splitBy(frequency);

      const resultSeries = resultsToSeries(
        frequency,
        flatten(removeDailyRecoveredErrors(split(results))),
        getEventIdentifiers(query),
        CHECKLIST_EVENTS.map(({ type }) => type)
      );

      return resultSeries.map(({ name, ...series }) => {
        const parsedName = JSON.parse(name);
        console.log({ parsedName });
        if (parsedName.goal_id) {
          const { goal_id, name: eventName } = parsedName;
          console.log({ goals });
          const { name: goalName } = goals?.[goal_id] || {};
          return {
            name: `${goalName || goal_id}: ${
              GOAL_EVENTS.find(({ type }) => type === eventName).label
            }`,
            ...series,
          };
        }

        if (parsedName.flow_id) {
          const { flow_id: flowId, name: eventName } = parsedName;
          const { name: flowName } = flows[flowId] || {};
          return {
            name: `${flowName || flowId}: ${
              FLOW_EVENTS.find(({ type }) => type === eventName).label
            }`,
            ...series,
          };
        }
        if (parsedName.experience_id && !parsedName.step_id) {
          const { experience_id: flowId, name: eventName } = parsedName;
          const { name: flowName } = mobile[flowId] || {};
          return {
            name: `${flowName || flowId}: ${
              MOBILE_EVENTS.find(({ type }) => type === eventName).label
            }`,
            ...series,
          };
        }
        if (parsedName.checklist_id) {
          const { checklist_id: checklistId, name: eventName } = parsedName;
          const { internalName: checklistName } = checklists[checklistId] || {};
          return {
            name: `${checklistName || checklistId}: ${
              CHECKLIST_EVENTS.find(({ type }) => type === eventName).label
            }`,
            ...series,
          };
        }
        if (parsedName.experience_id && parsedName.step_id) {
          const values =
            parsedName.experience_type === 'pin' ? pins : experiences;

          if (Object.keys(values).length === 0) return {};

          const { name: experienceName, steps } =
            values[parsedName.experience_id] || {};

          const { name: stepName } =
            steps.find(({ id }) => id === parsedName.step_id) || {};

          const eventsMap = {
            pin: PIN_EVENTS,
            banner: BANNER_EVENTS,
            persistent: PIN_EVENTS,
          };

          const labelStepName =
            parsedName.experience_type !== 'banner'
              ? `${stepName || parsedName.step_id} :`
              : '';

          return {
            name: `${
              experienceName || parsedName.experience_id
            } : ${labelStepName} ${
              eventsMap[parsedName.experience_type]?.find(
                ({ type }) => type === parsedName.name
              ).label
            }`,
            ...series,
          };
        }
        return {
          name: parsedName.name?.replace(/appcues_custom:/, ''),
          ...series,
        };
      });
    }
  );
