import React from 'react';
import styled from 'styled-components';

import { Icon } from '@studio/legacy-components';
import OpenInBuilderButton from 'components/OpenInBuilder/OpenInBuilderButton';

export const StepChildHover = ({ className, stepGroupId, stepChildId }) => {
  return (
    <OpenInBuilderButton
      stepGroupId={stepGroupId}
      stepChildId={stepChildId}
      render={handleClick => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          role="button"
          tabIndex={0}
          aria-label="Open in builder"
          className={className}
          data-testid="step-child-hover"
          onClick={handleClick}
        >
          <div className="circleContainer" id="circle-container">
            <StepChildHoverCircle className="circle">
              <Icon icon="edit" />
            </StepChildHoverCircle>
            Open in Builder
          </div>
        </div>
      )}
    />
  );
};

const StepChildHoverCircle = styled.div`
  border-radius: 50%;
  border-color: white;
  border-style: solid;
  border-width: 1px;

  width: 40px;
  height: 40px;
  margin-bottom: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: white;
  }
`;

export default styled(StepChildHover)`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  width: 100%;
  height: 100%;

  opacity: 0.95;
  background-color: #00b2e5;
  border-radius: 6px;

  color: white;

  a {
    opacity: 1;
    color: #ffffff;
    text-decoration: none;
  }

  .circleContainer {
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    font-weight: 600;
    line-height: 1.5em;

    :hover > .circle {
      background-color: #ffffff;

      svg {
        fill: #00b2e5;
      }
    }
  }
`;
