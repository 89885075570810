import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { usePagination } from '@appcues/sonar';
import { getCacheKey } from 'next/lib/caching';
import { asPromised } from 'utils/as-promised';
import {
  selectEventUserProfileResults,
  selectTotalResults,
  callApi as fetchEventUserProfiles,
} from 'entities/event-user-profiles';
import { selectAccountMeta } from 'reducers/account/meta';
import EventsTable from './EventsTable';

const contentTypeToProfileForEventFilterContentType = {
  banner: 'BANNER',
  checklist: 'CHECKLIST',
  flow: 'WEB_FLOW',
  mobile: 'MOBILE_FLOW',
  nps: 'NPS',
  pin: 'PIN',
  goals: 'GOAL',
};
export function EventsTableProvider({
  names,
  stepIds,
  searchTerm,
  contentType,
  contentId,
  startTime,
  endTime,
  userProfileIdentifier,
  onLoad,
  getTotalResults,
  getUserProfiles,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const { pageIndex, pageSize, setPagination } = usePagination();
  const [sortDirection, setSortDirection] = useState('DESC');
  const toggleSortDirection = useCallback(() => {
    setSortDirection(prev => (prev === 'ASC' ? 'DESC' : 'ASC'));

    setPagination(prev => ({ ...prev, pageIndex: 0 }));
  }, [setPagination]);
  const queryParams = useMemo(
    () => ({
      eventNames: names,
      stepId: stepIds?.[0],
      propertyNames: [userProfileIdentifier],
      contentId,
      contentType: contentTypeToProfileForEventFilterContentType[contentType],
      startTime: new Date(startTime).toISOString(),
      endTime: new Date(endTime).toISOString(),
      direction: sortDirection,
      limit: pageSize,
      offset: pageIndex * pageSize,
      searchString: searchTerm === '' ? undefined : searchTerm,
    }),
    [
      userProfileIdentifier,
      contentId,
      contentType,
      startTime,
      endTime,
      sortDirection,
      pageSize,
      pageIndex,
      searchTerm,
    ]
  );
  const cacheKey = getCacheKey(queryParams);
  const totalResults = getTotalResults?.(cacheKey);
  const userProfiles = getUserProfiles?.(cacheKey);

  useEffect(() => {
    const load = async () => {
      // use the cached data if it exists
      if (userProfiles) return;

      setIsLoading(true);
      await onLoad?.(queryParams, cacheKey);
      setIsLoading(false);
    };

    if (cacheKey) load();

    return () => setIsLoading(false);
  }, [userProfiles, onLoad, queryParams, cacheKey]);

  return (
    <EventsTable
      isLoading={isLoading}
      userProfileIdentifier={userProfileIdentifier}
      contentType={contentType}
      contentId={contentId}
      sortDirection={sortDirection}
      toggleSortDirection={toggleSortDirection}
      userProfiles={userProfiles}
      totalResults={totalResults}
      pageIndex={pageIndex}
      pageSize={pageSize}
      setPagination={setPagination}
    />
  );
}

EventsTableProvider.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string),
  stepIds: PropTypes.arrayOf(PropTypes.string),
  startTime: PropTypes.number,
  endTime: PropTypes.number,
  searchTerm: PropTypes.string,
  contentType: PropTypes.string,
  contentId: PropTypes.string,
  userProfileIdentifier: PropTypes.string,
  getUserProfiles: PropTypes.func,
  getTotalResults: PropTypes.func,
  onLoad: PropTypes.func,
};

const mapStateToProps = state => ({
  userProfileIdentifier:
    selectAccountMeta(state)?.userProfileIdentifier || 'userId',
  getUserProfiles: cacheKey => selectEventUserProfileResults(state, cacheKey),
  getTotalResults: cacheKey => selectTotalResults(state, cacheKey),
});

const mapDispatchToProps = dispatch => ({
  onLoad: (queryParams, cacheKey) =>
    asPromised(dispatch, fetchEventUserProfiles({ queryParams, cacheKey })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsTableProvider);
