import { createCollectionActions } from 'next/lib/collections';

export const TYPE = 'accounts';

export const { patterns, reject, resolve } = createCollectionActions(TYPE);

export const ACCOUNT_SWITCHED = 'ACCOUNT_SWITCHED';
export const switchAccount = id => ({
  type: ACCOUNT_SWITCHED,
  payload: id,
});
