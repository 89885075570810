import { useEffect } from 'react';

// eslint-disable-next-line no-restricted-imports
import authClient from 'helpers/auth-client';

// this exists so that there can be a /logout route in the app
// that logs out correctly. It is preferable to call the authClient's
// logoutUser method directly (as the logout button does) and save the
// overhead of rendering a react component
const LogoutView = ({ history }) => {
  useEffect(() => {
    history.replace('/');
    authClient.logout();
  }, []);
  return null;
};

export default LogoutView;
