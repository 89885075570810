import styled from 'styled-components';
import { RadioControl, RadioLabel } from '@studio/legacy-components';

export const RadioControlWithDescription = styled(RadioControl)`
  padding: 12px 4px;
  align-items: flex-start;

  ${RadioLabel} {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

export const RadioText = styled.span``;

export const FieldWrapper = styled.div`
  margin-top: 8px;
`;
