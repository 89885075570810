import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CCheckbox } from '@appcues/component-library';

import { ButtonLink, Icon } from '@studio/legacy-components';
import FlowFrequencyModalManager from 'next/components/FlowFrequencyModalManager';

import { update as updateRule } from 'actions/account/rules';
import { updateAccountMeta } from 'actions/account/meta';

import { selectAccountRule } from 'reducers/account/rules';
import { selectAccountMeta } from 'reducers/account/meta';

const ExclamationIcon = styled(Icon)`
  color: var(--warn-dark);
  margin-right: 8px;
`;

const FrequencyWarn = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0px;
`;

const ThrottleWrapper = styled.div`
  position: relative;
`;

export function ThrottleWarning({
  className,
  warnText,
  checkboxLabel,
  throttleExempt,
  onChangeThrottleExempt,
  throttleEnabled,
  throttleContentLimit,
  throttleContentTimespanMs,
  onUpdateAccount,
}) {
  return (
    <ThrottleWrapper className={className}>
      <FrequencyWarn>
        <ExclamationIcon icon="exclamation-circle" />
        <FlowFrequencyModalManager
          throttleEnabled={throttleEnabled}
          throttleContentLimit={throttleContentLimit}
          throttleContentTimespanMs={throttleContentTimespanMs}
          onSave={onUpdateAccount}
          trigger={<ButtonLink>Frequency limit is on</ButtonLink>}
        />
        , {warnText}
      </FrequencyWarn>
      <CCheckbox
        data-testid="throttle-exempt"
        onChange={({ target: { checked } }) => {
          onChangeThrottleExempt(checked);
        }}
        checked={throttleExempt}
        label={checkboxLabel}
      />
    </ThrottleWrapper>
  );
}

const mapStateToProps = (state, { ruleId }) => {
  const rule = selectAccountRule(state, ruleId);
  const meta = selectAccountMeta(state);

  return {
    throttleExempt: rule?.throttleExempt,
    throttleEnabled: meta?.throttleEnabled,
    throttleContentLimit: meta?.throttleContentLimit,
    throttleContentTimespanMs: meta?.throttleContentTimespanMs,
  };
};

const mapDispatchToProps = (dispatch, { ruleId }) => ({
  onChangeThrottleExempt: throttleExempt =>
    dispatch(updateRule(ruleId, { throttleExempt })),
  onUpdateAccount: changes => dispatch(updateAccountMeta(changes)),
});

ThrottleWarning.propTypes = {
  warnText: PropTypes.string.isRequired,
  checkboxLabel: PropTypes.string.isRequired,
  onChangeThrottleExempt: PropTypes.func,
  throttleExempt: PropTypes.bool,
  className: PropTypes.string,
  throttleContentLimit: PropTypes.number,
  throttleContentTimespanMs: PropTypes.number,
  throttleEnabled: PropTypes.bool,
  onUpdateAccount: PropTypes.func,
};

const ConnectedThrottleWarning = connect(
  mapStateToProps,
  mapDispatchToProps
)(ThrottleWarning);

export default ConnectedThrottleWarning;
