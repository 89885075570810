import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ErrorMsg, Label, Select } from '@studio/legacy-components';
import { SelectOptionsShape } from 'next/lib/shapes';
import { selectAppOptionsWithWeb } from 'next/lib/selectors-options';

export const AppField = ({ error, onBlur, onChange, options, value }) => (
  <Label>
    App
    <Select
      value={options.find(({ value: appId }) => appId === value)}
      autoFocus={false}
      error={Boolean(error)}
      kind="default"
      name="appId"
      onBlur={onBlur}
      options={options}
      onChange={option => onChange(option)}
      placeholder="Select App"
      portal
    />
    {error && <ErrorMsg>{error}</ErrorMsg>}
  </Label>
);

AppField.propTypes = {
  error: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: SelectOptionsShape,
  value: PropTypes.string,
};

const mapStateToProps = state => ({
  options: selectAppOptionsWithWeb(state),
});

export default connect(mapStateToProps)(AppField);
