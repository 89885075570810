import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ProgressBar from 'components/Common/UI/ProgressBar/ProgressBar';
import HoverBox from 'components/Common/UI/HoverBox/HoverBox';

const ERROR_COLOR = '$grapefruit';
const SHOWN_COLOR = '$gray-4';
const COMPLETED_COLOR = '$merfolk';

const KeyColor = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  background: ${({ color, theme }) => theme[color]};
  border-radius: 10px;
  margin-right: 10px;
`;

const KeyValue = styled.span`
  float: right;
  font-weight: bold;
`;

const KeyWrapper = styled.div`
  margin-bottom: 10px;
`;

const KeyItem = ({ color, title, value }) => (
  <KeyWrapper>
    <KeyColor color={color} />
    <span>{title}</span>
    <KeyValue>{value}</KeyValue>
  </KeyWrapper>
);

const percent = values => Math.round(100 * values);

export const StepByStepProgressBar = ({
  index,
  issues,
  shown,
  completed,
  originallyShown,
}) => {
  /**
   * @property dropoff = the remainder between shown steps and completed steps
   * @property completedPercent = A percentage of completed steps derived from the number originally shown steps
   * @property issuesPercent = A percentage of issues derived from the number of originally shown steps
   * @property shownPercentDifference = If issues arent greater than the number of successfully displayed flows, calculate the dropoff (sans issues) based on the originally shown steps
   */
  const dropOff = shown % completed;
  const completedPercent = percent(completed / originallyShown);
  const issuesPercent = percent(issues / originallyShown);
  const shownPercentDifference =
    issues > shown ? 0 : percent((dropOff - issues) / originallyShown);

  const values = [
    {
      metric: completedPercent,
      color: COMPLETED_COLOR,
    },
    { metric: issuesPercent, color: ERROR_COLOR },
    { metric: shownPercentDifference, color: SHOWN_COLOR },
  ];

  return (
    <HoverBox
      content={
        <>
          <KeyItem
            color={COMPLETED_COLOR}
            title="Completed"
            value={completed}
          />
          <KeyItem color={ERROR_COLOR} title="Issues" value={issues} />
          <KeyItem color={SHOWN_COLOR} title="Total Shown" value={shown} />
        </>
      }
      contentTitle={`Step ${index + 1}`}
    >
      <ProgressBar maxWidth="90%" values={values} />
    </HoverBox>
  );
};

StepByStepProgressBar.propTypes = {
  index: PropTypes.number.isRequired,
  issues: PropTypes.number.isRequired,
  shown: PropTypes.number.isRequired,
  originallyShown: PropTypes.number.isRequired,
  completed: PropTypes.number.isRequired,
};

KeyItem.propTypes = {
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default StepByStepProgressBar;
