import PropTypes from 'prop-types';

export default PropTypes.shape({
  inserted_at: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  key: PropTypes.string,
  name: PropTypes.string,
  last_seen_at: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  legacy: PropTypes.bool,
  role_id: PropTypes.string,
  state: PropTypes.string,
});
