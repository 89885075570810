import { call, getContext, put } from 'redux-saga/effects';
import { takeEveryPromised } from 'next/lib/effects';
import { patterns, append, reject } from './actions';

const getResponseData = response => {
  return response?.data?.endUserProfileListForEvent;
};

function* fetchEventUserProfiles({ payload: { queryParams, cacheKey } }) {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getEventUserProfiles, queryParams);
    yield put(append({ [cacheKey]: getResponseData(response) }));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  yield takeEveryPromised(patterns.read, fetchEventUserProfiles);
}
