/* global APPCUES_ACCOUNT_ID, APPCUES_API_URL */

import { createFetch } from 'next/lib/fetch';

function createLaunchpadApiClient(
  apiUrl = APPCUES_API_URL,
  accountId = APPCUES_ACCOUNT_ID
) {
  const doUnauthedFetch = createFetch(`${apiUrl}/v1`, {});
  return {
    async getLaunchpad(userId) {
      if (!userId) {
        return { launchpad: null, history: null };
      }

      const flowQualifyingUrl = 'https://studio.appcues.com/';
      const queryParams = new URLSearchParams({ url: flowQualifyingUrl });
      const launchPadUrl = `accounts/${accountId}/users/${userId}/launchpad/?${queryParams.toString()}`;
      const historyUrl = `accounts/${accountId}/users/${userId}/history`;

      return Promise.allSettled([
        doUnauthedFetch(launchPadUrl, { account: false, jwt: false }),
        doUnauthedFetch(historyUrl, { account: false, jwt: false }),
      ]).then(([launchpadResult, historyResult]) => {
        const data = {
          launchpad:
            launchpadResult.status === 'fulfilled'
              ? launchpadResult.value
              : null,
          history:
            historyResult.status === 'fulfilled' ? historyResult.value : null,
        };
        return data;
      });
    },
  };
}

export const launchpadApiClient = createLaunchpadApiClient();
