import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { CButton, H2 } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';
import useTitle from 'next/hooks/use-title';
import { showModal } from 'actions/currentModal';
import { navigate } from 'actions/routing';
import segmentIcon from 'assets/images/segment.svg';
import DashboardOverview from 'components/Installation/DashboardOverview';
import InstallCard from 'components/Installation/InstallCard';
import { INSTALL_OVERVIEW_URL } from 'constants/externalAppcuesResources';
import {
  INSTALL_SEGMENT_MODAL,
  SEGMENT_BLOCKED_MODAL,
} from 'constants/globals';
import { selectAccountMetaSynced } from 'reducers/account/meta';
import { selectIsInstalled } from 'reducers/account/installedDomains';

const WIZARD_LINK = 'installation/guide/overview';

const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderTitle = ({ isInstalled, ...props }) => (
  <H2
    type="secondary"
    marginTop={isInstalled ? 32 : 40}
    marginBottom={16}
    {...props}
  />
);

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 264px;
`;

const CardText = styled.span`
  display: flex;
  flex-grow: 10;
  padding-left: ${props => (props.isInstalled ? '18px' : '0')};
`;

const WizardIcon = styled.div`
  display: flex;
  color: var(--blurple);
  font-size: ${props => (props.isInstalled ? '32px' : '56px')};
  padding-top: ${props => (props.isInstalled ? '0' : '32px')};
  padding-left: ${props => (props.isInstalled ? '24px' : '0')};
  flex-grow: 1;
`;

const SegmentIcon = styled.img`
  display: flex;
  flex-grow: 1;
  width: ${props => (props.isInstalled ? '32px' : '64px')};
  height: ${props => (props.isInstalled ? '32px' : '60px')};
`;

const InstallationButton = styled(CButton)`
  margin-right: ${props => (props.isInstalled ? '24px' : '0')};
  margin-top: ${props => (props.isInstalled ? '0' : '28px')};
`;

const OverviewButton = styled(CButton)`
  margin-top: 40px;
  margin-bottom: 24px;
`;

function Installation({ actions, navigate: navigateTo, isInstalled, synced }) {
  useTitle('Installation | Settings | Appcues');
  const navigateToWizard = () => {
    actions.navigate(WIZARD_LINK);
  };

  const openSegmentModal = () => {
    // check to see if uBlock Origin or other Ad Blockers are enabled - if so, Segment will be accidentally blocked
    if (!window.segment) {
      actions.showModal(SEGMENT_BLOCKED_MODAL);
    } else {
      actions.showModal(INSTALL_SEGMENT_MODAL);
    }
  };

  return isInstalled
    ? synced && (
        <ScriptDetected
          isInstalled={isInstalled}
          navigate={navigateTo}
          navigateToWizard={navigateToWizard}
          openSegmentModal={openSegmentModal}
        />
      )
    : synced && (
        <NoScriptDetected
          isInstalled={isInstalled}
          navigateToWizard={navigateToWizard}
          openSegmentModal={openSegmentModal}
        />
      );
}

const NoScriptDetected = ({
  isInstalled,
  navigateToWizard,
  openSegmentModal,
}) => (
  <div>
    <StyledHeaderContainer>
      <HeaderTitle isInstalled={isInstalled}>
        Install Appcues to start publishing content!
      </HeaderTitle>
      <OverviewButton is="a" isExternal href={INSTALL_OVERVIEW_URL}>
        <Icon icon="info-circle" />
        Overview
      </OverviewButton>
    </StyledHeaderContainer>
    <FlexRow>
      <InstallCard isInstalled={isInstalled}>
        <SegmentIcon src={segmentIcon} isInstalled={isInstalled} />
        Install instantly with Segment
        <InstallationButton
          type="primary"
          onClick={openSegmentModal}
          isInstalled={isInstalled}
        >
          Connect
        </InstallationButton>
      </InstallCard>
      <InstallCard isInstalled={isInstalled}>
        <WizardIcon isInstalled={isInstalled}>
          <Icon icon="magic" />
        </WizardIcon>
        <span>Install with our step-by-step guide</span>
        <InstallationButton
          type="primary"
          onClick={navigateToWizard}
          isInstalled={isInstalled}
        >
          Let&apos;s Go!
        </InstallationButton>
      </InstallCard>
    </FlexRow>
  </div>
);

const ScriptDetected = ({
  isInstalled,
  navigate: navigateTo,
  navigateToWizard,
  openSegmentModal,
}) => (
  <div>
    <DashboardOverview navigate={navigateTo} header="Status" />
    <HeaderTitle>Installation Guide</HeaderTitle>
    <InstallCard isInstalled={isInstalled}>
      <WizardIcon isInstalled={isInstalled}>
        <Icon icon="magic" />
      </WizardIcon>

      <CardText>
        Open the guide to install on a new domain, add a user property or event,
        and more
      </CardText>
      <InstallationButton onClick={navigateToWizard} isInstalled={isInstalled}>
        Open Guide
      </InstallationButton>
    </InstallCard>
    <HeaderTitle>Install with Segment</HeaderTitle>
    <InstallCard isInstalled={isInstalled}>
      <SegmentIcon src={segmentIcon} isInstalled={isInstalled} />
      <CardText>If you use Segment, you can use it to install Appcues</CardText>
      <InstallationButton onClick={openSegmentModal} isInstalled={isInstalled}>
        Connect
      </InstallationButton>
    </InstallCard>
  </div>
);

const mapStateToProps = state => {
  const synced = selectAccountMetaSynced(state);
  const isInstalled = selectIsInstalled(state);

  return {
    synced,
    isInstalled,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        navigate,
        showModal,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Installation);
