import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import * as yup from 'yup';
import isEmpty from 'lodash.isempty';

import {
  Button,
  ButtonGroup,
  ErrorMsg,
  Heading,
  Input,
  NegativeNotice,
  Select,
} from '@studio/legacy-components';
import { selectAllApiKeys } from 'entities/keys';

import { CreateForm, ControlWrapper } from './styled';
import { PERMISSIONS_OPTIONS } from './constants';

export const CreateKeyForm = ({
  errors = {},
  hasSubmitError,
  inFlight,
  setTouched,
  setValues,
  touched = {},
  values = {},
  onClose,
  onSubmit,
}) => {
  const disabled = inFlight || isEmpty(touched) || !isEmpty(errors);

  const handleCreate = event => {
    event.preventDefault();

    const { name = '', role_id } = values;
    onSubmit({ name: name.trim(), role_id });
  };

  return (
    <CreateForm onSubmit={handleCreate}>
      <Heading>New API Key</Heading>
      {hasSubmitError && (
        <NegativeNotice>
          We&apos;re sorry, something went wrong on our end. Try again.
        </NegativeNotice>
      )}
      <ControlWrapper>
        <Input
          type="text"
          name="name"
          placeholder="Name"
          error={!!errors.name && !!touched.name}
          onChange={({ target: { value } }) =>
            setValues({ ...values, name: value })
          }
          // We are manually calling setTouched so the errors are only shown
          // for the fields the user has interacted with
          onBlur={() => setTouched({ ...touched, name: true })}
          values={values.name}
        />
        {errors.name && touched.name && <ErrorMsg>{errors.name}</ErrorMsg>}
      </ControlWrapper>
      <ControlWrapper>
        <Select
          name="role_id"
          options={PERMISSIONS_OPTIONS}
          placeholder="Select Access Permissions"
          error={!!errors.role_id && !!touched.role_id}
          onChange={({ value }) => {
            setValues({ ...values, role_id: value });
          }}
          onBlur={() => setTouched({ ...touched, role_id: true })}
          portal
        />
        {errors.role_id && touched.role_id && (
          <ErrorMsg>{errors.role_id}</ErrorMsg>
        )}
      </ControlWrapper>
      <ButtonGroup right>
        <Button kind="primary" type="submit" disabled={disabled}>
          Create
        </Button>
        <Button kind="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ButtonGroup>
    </CreateForm>
  );
};

CreateKeyForm.propTypes = {
  hasSubmitError: PropTypes.bool,
  inFlight: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,

  // Props injected by Formik HOC
  errors: PropTypes.shape({
    name: PropTypes.string,
    role_id: PropTypes.string,
  }),
  setTouched: PropTypes.func,
  setValues: PropTypes.func,
  touched: PropTypes.shape({
    name: PropTypes.bool,
    role_id: PropTypes.bool,
  }),
  values: PropTypes.shape({
    email: PropTypes.string,
    role_id: PropTypes.string,
  }),
};

const enhanceForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ name = '', role_id = '' }) => ({
    name,
    role_id,
  }),
  validate: ({ name = '' }, { keys }) => {
    const errors = {};
    const exists = keys.find(
      ({ name: current }) => current.toLowerCase() === name.toLowerCase().trim()
    );

    if (exists) {
      errors.name = 'API key name already exists.';
    }

    return errors;
  },
  validationSchema: yup.object().shape({
    name: yup.string().required('Name is required to proceed.'),
    role_id: yup.string().required('Access permission is required to proceed.'),
  }),
});

const mapStateToProps = state => ({
  keys: selectAllApiKeys(state),
});

export default connect(mapStateToProps)(enhanceForm(CreateKeyForm));
