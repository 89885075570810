/* global STUDIO_5_URL */

import { useLayoutEffect } from 'react';
import { isPreviewing } from 'next/lib/history';

// eslint-disable-next-line no-restricted-imports
import authClient from 'helpers/auth-client';

const STUDIO5_LOCAL = 'http://localhost:3008';
const STUDIO5_STAGING = 'https://studio5-staging.appcues.com';

const getStudio5Url = () => {
  const isDev = window.location.origin.match(/localhost/i);
  const isStaging = window.location.origin.match(/studio-staging/i);

  if (isStaging) {
    return STUDIO5_STAGING;
  }

  if (isDev) {
    return STUDIO5_LOCAL;
  }

  return STUDIO_5_URL;
};

export const RedirectToStudio5 = () => {
  useLayoutEffect(() => {
    /**
     *  On CloudFront, the result of a window.location.reload will effectively
     *  be a re-assignment to a different app, but on localhost it would be
     *  an infinite loop!
     */

    (async () => {
      // failure to wait for the auth handshake to complete can cause a redirect loop
      await authClient.getToken();

      const isLocalHost = /localhost/i.test(window.location.hostname);
      const shouldRedirect = isPreviewing() || isLocalHost;

      if (shouldRedirect) {
        const path = window.location.href.replace(window.location.origin, '');
        window.location.href = `${getStudio5Url()}${path}`;
      } else {
        window.location.reload();
      }
    })();
  });

  return null;
};
