import { createSelector } from 'reselect';
import { createCollectionReducer } from 'reducers/account/collections';

export const TYPE = 'customEvents';

export default createCollectionReducer(TYPE);

export const selectCustomEvents = state => (state.account || {})[TYPE];
export const selectCustomEvent = (state, { id }) =>
  (selectCustomEvents(state) || {})[id] || {};

export const selectNonArchivedCustomEvents = createSelector(
  selectCustomEvents,
  events =>
    events &&
    Object.keys(events)
      .filter(id => events[id].state !== 'ARCHIVED')
      .reduce((acc, id) => {
        acc[id] = events[id];
        return acc;
      }, {})
);

export const selectCustomEventByName = createSelector(
  selectCustomEvents,
  (_, { eventName }) => eventName,
  (events, eventName) => {
    if (!events) {
      return null;
    }

    const eventId = Object.keys(events).find(
      id => events[id].name === eventName
    );
    return events[eventId];
  }
);
