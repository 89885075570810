import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Select } from '@studio/legacy-components';
import { selectProfileAttributeDropdownOptions } from 'selectors/profile-attributes';

export const SelectProfileAttribute = ({
  value,
  options,
  readOnly,
  onChange,
  placeholder = 'Pick an option',
  ...props
}) => {
  const handleChange = option => {
    onChange({ value: option.value });
  };

  const selected = options
    .flatMap(group => group.options)
    .find(option => option.value === value);

  return (
    <Select
      isDisabled={readOnly}
      onChange={handleChange}
      options={options}
      placeholder={value || placeholder}
      value={selected}
      {...props}
    />
  );
};

SelectProfileAttribute.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
          isAutoProp: PropTypes.bool,
        })
      ),
    })
  ),
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

const mapStateToProps = (state, { isNPSAudienceSetting }) => {
  let options = selectProfileAttributeDropdownOptions(state);

  if (isNPSAudienceSetting) {
    options = options.map(group => ({
      ...group,
      options: group.options.filter(
        item =>
          !['_sessionRandomizer', '_sessionPageviews', '_deviceType'].includes(
            item.value
          )
      ),
    }));
  }

  return { options };
};

export default styled(connect(mapStateToProps)(SelectProfileAttribute))``;
