import { useState, useEffect, useCallback } from 'react';
import { sortByKey } from 'utils';

/**
 * Handles a list of objects and provides methods for local sorting and pagination
 * @param {object} config - The initial configuration
 *   @option {Array} data - the array of items to be managed.
 *   @option {String} searchTerm - The search criteria. Whenevr this changes, the list is computed again.
 *   @option {Number} pageSize - The amount of items per page.
 *   @option {String} defaultOrderColumn - The initial order by column. Must be a property of the objects in the data array.
 *   @option {String} defaultSortDirection - The initial sort direction. ("asc"|"desc")
 *   @option {function} filterProdicate - Function used to filter values
 * @returns {object}
 *   @option {Array|null} results - The chunk of items for the current index page or null if no data is provided
 *   @option {boolean} isEmpty - A flag that indicates if there is no data
 *   @option {Number} totalResults - Number of total items in data
 *   @option {Number} currentIndex - The current index being shown
 *   @option {Number} orderByColumn - The column used to sort
 *   @option {Number} orderByDirection - The direction used to sort ("asc"|"desc")
 *   @option {function} handleSort - function to update sorting and update the results chunk
 *   @option {function} handleCurrentIndex - function to update the current index and update the results chunk
 */
const useClientsideTable = ({
  data,
  searchTerm = '',
  pageSize,
  defaultOrderColumn = 'name',
  defaultSortDirection = 'asc',
  filterPredicate = () => true,
}) => {
  const [totalResults, setTotalResults] = useState(0);
  const [orderByColumn, setOrderByColumn] = useState(defaultOrderColumn);
  const [orderByDirection, setOrderByDirection] =
    useState(defaultSortDirection);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [results, setResults] = useState(null);

  useEffect(() => {
    setCurrentIndex(0);
  }, [searchTerm, orderByColumn, orderByDirection, pageSize]);

  useEffect(() => {
    if (data) {
      const allItems = data
        .filter(filterPredicate)
        .sort(sortByKey(orderByColumn, orderByDirection));
      const startIndex = currentIndex * pageSize;
      const slicedItems = allItems.slice(startIndex, startIndex + pageSize);

      setResults(slicedItems);
      setTotalResults(allItems.length);
    } else {
      setResults(null);
    }
  }, [
    data,
    orderByColumn,
    orderByDirection,
    currentIndex,
    searchTerm,
    pageSize,
  ]);

  const onSort = useCallback(
    column => {
      if (column === orderByColumn) {
        setOrderByDirection(orderByDirection === 'asc' ? 'desc' : 'asc');
      } else {
        setOrderByColumn(column);
      }
    },
    [orderByColumn, orderByDirection]
  );

  const onSetCurrentIndex = useCallback(
    page => {
      if (page !== currentIndex) {
        setCurrentIndex(page);
      }
    },
    [currentIndex]
  );

  return {
    results,
    isEmpty: data && data.length === 0 && !searchTerm,
    totalResults,
    currentIndex,
    orderByColumn,
    orderByDirection,
    handleSort: onSort,
    handleCurrentIndex: onSetCurrentIndex,
  };
};

export default useClientsideTable;
