export default function (auth) {
  return {
    getStats: async () => {
      const token = await auth.getToken();
      const accountId = await auth.getAccountId();

      /* global ANALYTICS_API_HOST */
      const response = await fetch(
        `${ANALYTICS_API_HOST}/v1/redis-stats?account_id=${accountId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.json();
    },
  };
}
