import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Thead, Tbody, Tr } from '@studio/legacy-components';

import { sortByKey } from 'utils';
import { selectNonArchivedPins } from 'entities/experiences';
import QueryResults from 'components/Common/QueryResults';
import Pagination, {
  PaginationMessage,
} from 'components/Common/UI/Pagination/Pagination';

import TableOverlay from 'components/Insights/Explorer/Common/TableOverlay';

import {
  ClearEvents,
  ControlHeader,
  EventNameHeader,
  PaginationWrapper,
  ResultsWrapper,
  SelectHeader,
  StatsHeader,
  StatusHeader,
  TableWrapper,
} from 'components/Insights/Common/Styled';

import {
  AddEventTooltip,
  TitleTooltip,
} from 'components/Insights/Explorer/Common/SourceTooltips';

import {
  PIN_BUTTON_TYPE,
  PIN_EVENTS,
  PIN_EVENTS_LABEL,
} from 'components/Insights/constants';
import useClientsideTable from 'hooks/useClientsideTable';
import PinRow from './PinRow';

/**
 * Given list of experience with analytics result, this will construct experience with individual with pin event for table
 * @param  experiences
 * @param  results
 * @returns
 */
export const mergeEventsAndResults = (experiences, results) =>
  (experiences || []).map(({ id, name, published, steps }) => {
    const analytics = (results || []).filter(
      result => result.experience_id === id
    );

    const pins = steps.map(({ id: stepId, name: stepName, type }) => {
      const eventType =
        type === PIN_BUTTON_TYPE
          ? 'appcues:v2:step_interaction'
          : 'appcues:v2:step_seen';

      const { events, most_recent_timestamp, users } = analytics.find(
        result => result.step_id === stepId && result.name === eventType
      ) || {
        events: 0,
        users: 0,
        most_recent_timestamp: 0,
      };

      return {
        stepId,
        stepName,
        type,
        eventType,
        events,
        users,
        most_recent_timestamp,
      };
    });

    const { most_recent_timestamp } = pins.sort(
      sortByKey('most_recent_timestamp', 'desc')
    )[0] || {
      most_recent_timestamp: 0,
    };

    return {
      totalEvents: analytics
        .filter(
          ({ name: eventName }) =>
            eventName === 'appcues:v2:step_interaction' ||
            eventName === 'appcues:v2:step_seen'
        )
        .reduce((acc, { events }) => acc + events, 0),
      most_recent_timestamp,
      totalUsers: 0,
      published,
      id,
      name,
      pins,
    };
  });

export const PinEvents = ({
  results,
  pageSize = 10,
  selectedEvents,
  enableSelect,
  searchTerm,
  onSelect,
  onClearEvents,
}) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const {
    isEmpty,
    currentIndex,
    orderByColumn,
    orderByDirection,
    results: pagePins,
    totalResults,
    handleSort,
    handleCurrentIndex,
  } = useClientsideTable({
    data: results,
    searchTerm,
    pageSize,
    defaultOrderColumn: 'totalEvents',
    defaultSortDirection: 'desc',
  });

  const handleExpandRow = clickedId => {
    if (expandedRow === clickedId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(clickedId);
    }
  };

  return (
    <>
      <ResultsWrapper>
        <TableOverlay
          isEmpty={isEmpty}
          results={results}
          type={PIN_EVENTS_LABEL}
        />
        <TableWrapper>
          <Table>
            <Thead>
              <Tr>
                {pagePins?.length > 0 ? (
                  <SelectHeader>
                    {selectedEvents?.some(evt => evt.source === 'pin') ? (
                      <ClearEvents
                        onClick={() => {
                          onClearEvents('pin');
                        }}
                      />
                    ) : (
                      <AddEventTooltip />
                    )}
                  </SelectHeader>
                ) : (
                  <ControlHeader />
                )}
                <EventNameHeader
                  sortable
                  sorted={orderByColumn === 'name' ? orderByDirection : null}
                  onClick={() => {
                    handleSort('name');
                  }}
                >
                  Pin Name
                </EventNameHeader>
                <StatusHeader
                  sortable
                  sorted={
                    orderByColumn === 'published' ? orderByDirection : null
                  }
                  onClick={() => {
                    handleSort('published');
                  }}
                >
                  Status
                </StatusHeader>
                <StatsHeader
                  sortable
                  sorted={
                    orderByColumn === 'totalEvents' ? orderByDirection : null
                  }
                  onClick={() => {
                    handleSort('totalEvents');
                  }}
                >
                  <TitleTooltip
                    tip="The number of total event ocurrences."
                    title="Total Events"
                  />
                </StatsHeader>
                <StatsHeader
                  sortable
                  sorted={
                    orderByColumn === 'totalUsers' ? orderByDirection : null
                  }
                  onClick={() => {
                    handleSort('totalUsers');
                  }}
                >
                  <TitleTooltip
                    tip="The number of unique users who triggered the event."
                    title="Unique Users"
                  />
                </StatsHeader>
                <StatsHeader
                  sortable
                  sorted={
                    orderByColumn === 'most_recent_timestamp'
                      ? orderByDirection
                      : null
                  }
                  onClick={() => {
                    handleSort('most_recent_timestamp');
                  }}
                >
                  Last Triggered
                </StatsHeader>
              </Tr>
            </Thead>
            <Tbody>
              {(pagePins || []).map(
                ({
                  name,
                  published,
                  totalUsers,
                  totalEvents,

                  most_recent_timestamp,
                  id,
                  users,
                  pins,
                }) => (
                  <PinRow
                    isExpanded={expandedRow === id}
                    onClick={handleExpandRow}
                    selectedEvents={selectedEvents}
                    onSelect={onSelect}
                    enableSelect={enableSelect}
                    key={id}
                    users={users}
                    name={name}
                    published={published}
                    id={id}
                    totalUsers={totalUsers}
                    totalEvents={totalEvents}
                    pins={pins}
                    most_recent_timestamp={most_recent_timestamp}
                  />
                )
              )}
            </Tbody>
          </Table>
        </TableWrapper>
      </ResultsWrapper>
      {pagePins && pagePins.length > 0 && (
        <PaginationWrapper>
          <Pagination
            pageCount={Math.ceil(totalResults / pageSize)}
            currentIndex={currentIndex}
            onChange={handleCurrentIndex}
          />
          <PaginationMessage
            currentIndex={currentIndex}
            pageSize={pageSize}
            total={totalResults}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

PinEvents.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      most_recent_timestamp: PropTypes.string,
      experience_id: PropTypes.string,
      step_id: PropTypes.string,
      users: PropTypes.number,
      events: PropTypes.number,
    })
  ),
  pageSize: PropTypes.number,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  enableSelect: PropTypes.bool,
  onSelect: PropTypes.func,
  searchTerm: PropTypes.string,
  onClearEvents: PropTypes.func,
};

export const mapStateToProps = (state, { searchTerm, results }) => {
  const pins = selectNonArchivedPins(state);
  const filtered = Object.values(pins).filter(({ name }) =>
    name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return {
    results: results && mergeEventsAndResults(filtered, results),
  };
};

const ConnectedPinEvents = connect(mapStateToProps)(PinEvents);

export const ConnectedPinEventsWithAnalytics = ({
  startTime,
  endTime,
  searchTerm,
  segmentId,
  pageSize = 10,
  selectedEvents,
  enableSelect,
  onSelect,
  onClearEvents,
}) => (
  <QueryResults
    query={{
      conditions: [
        [
          'event',
          'in',
          [
            ...PIN_EVENTS.map(({ type }) => type),
            'appcues:v2:experience_started',
          ],
        ],
        ['experience_type', '==', 'persistent'],
        ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
      ],
      dimensions: ['name', 'experience_id', 'step_id'],
      metrics: ['most_recent_timestamp', 'events', 'users'],
      start_time: startTime,
      end_time: endTime,
      meta: {
        tags: {
          feature: 'Event analytics',
          page: 'Event explorer',
          component: 'PinEventsWithAnalytics',
          description: 'Pin event source',
        },
      },
    }}
  >
    {results => (
      <ConnectedPinEvents
        results={results}
        pageSize={pageSize}
        searchTerm={searchTerm}
        startTime={startTime}
        endTime={endTime}
        selectedEvents={selectedEvents}
        enableSelect={enableSelect}
        onSelect={onSelect}
        onClearEvents={onClearEvents}
      />
    )}
  </QueryResults>
);

ConnectedPinEventsWithAnalytics.propTypes = {
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  searchTerm: PropTypes.string,
  segmentId: PropTypes.string,
  pageSize: PropTypes.number,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  enableSelect: PropTypes.bool,
  onSelect: PropTypes.func,
  onClearEvents: PropTypes.func,
};

export default ConnectedPinEventsWithAnalytics;
