import { createEntityReducer } from 'reducers/account/collections';
import { selectIsSynced, selectIsFetching } from 'reducers/account/requests';

const TYPE = 'installedDomains';

export default createEntityReducer(TYPE);

export const selectInstalledDomains = state => {
  return state.account.installedDomains.length > 0
    ? state.account.installedDomains.filter(Boolean)
    : [];
};

export const selectInstalledDomainsSynced = state =>
  selectIsSynced(state, TYPE);

export const selectIsInstalled = state =>
  selectInstalledDomains(state).length > 0;

export const selectInstalledDomainsLoading = state =>
  selectIsFetching(state, TYPE);
