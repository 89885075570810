import { createSelector } from 'reselect';
import { selectEventNames, selectEventNamesSynced } from 'entities/event-names';
import {
  selectEventLabels,
  selectEventLabelsSynced,
} from 'entities/event-labels';

export const selectAccountUserEvents = createSelector(
  selectEventNamesSynced,
  selectEventLabelsSynced,
  selectEventNames,
  selectEventLabels,
  (eventNamesSynced, eventLabelsSynced, eventNames, eventLabels) => {
    // In case one or both of eventNamesSynced & eventLabelsSynced is false
    // we want to return an object so not to break depending function
    if (!eventNamesSynced || !eventLabelsSynced) {
      return {};
    }

    return Object.entries(eventNames).reduce((acc, [id, event]) => {
      const label = Object.values(eventLabels).find(({ name }) => name === id);

      acc[label ? label.id : id] = {
        description: label ? label.description : '',
        id: label && label.id,
        label: id,
        lastSeenTs: event.lastSeenTs,
        name: id,
        showInUI: label ? label.showInUi : true,
      };

      return acc;
    }, {});
  }
);

export const selectAccountUserEvent = (state, eventId) =>
  selectAccountUserEvents(state)?.[eventId];

export const selectEventTargetingOptions = createSelector(
  selectAccountUserEvents,
  userEvents =>
    userEvents &&
    Object.entries(userEvents)
      .filter(([, { showInUI }]) => showInUI)
      .map(([, { name }]) => ({
        label: name.replace('appcues_custom:', ''),
        value: name,
      }))
);
