import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@studio/legacy-components';
import { create } from 'next/entities/experiences';
import CreateExperienceManager from 'next/components/CreateExperienceManager';

export const CreateBannerManager = ({ onCreate }) => {
  const handleBannerCreate = async values => {
    /*
     * Since a banner experience only have one step.
     * Here we add an basic banner step to be rendered in the builder.
     * The banner step is a primitive step with a floating trait and an animate trait.
     * The floating trait is used to position the banner.
     * The animate trait is used to animate the banner.
     * The content of the step is a div with the text "Add text here".
     */

    const newExperience = await onCreate({
      ...values,
      template: 'persistent',
      type: 'banner',
      traits: [
        {
          type: '@appcues/dismissible',
          config: {
            strategy: 'permanent',
          },
        },
        {
          type: '@appcues/persistent',
        },
      ],
      steps: [
        {
          type: 'banner',
          contentType: 'application/json',
          actions: {
            'dismiss-button': [
              {
                on: 'click',
                type: '@appcues/coreEvents.experience_dismissed',
              },
              {
                on: 'click',
                type: '@appcues/dismissible.dismiss',
              },
            ],
            banner: [
              {
                on: 'hooks/insert',
                type: '@appcues/coreEvents.step_seen',
              },
            ],
          },
          traits: [
            {
              type: '@appcues/inline',
              config: {
                target: {
                  selector: 'body',
                },
                placement: 'inner-left',
                rootDisplay: 'block',
                sticky: true,
              },
            },
            {
              type: '@appcues/rootStyle',
              config: {
                pointerEvents: 'none',
              },
            },
            {
              type: '@appcues/animate',
              config: {
                target: {
                  selector: '#banner',
                },
                fill: 'forwards',
                duration: 500,
                keyframes: [
                  {
                    paddingTop: 0,
                    paddingBottom: 0,
                  },
                  {
                    paddingTop: '10px',
                    paddingBottom: '10px',
                  },
                ],
              },
            },
            {
              type: '@appcues/animate',
              config: {
                target: {
                  selector: '#banner',
                },
                fill: 'forwards',
                duration: 700,
                keyframes: [
                  {
                    opacity: 0.1,
                  },
                  {
                    opacity: 1,
                  },
                ],
              },
            },
          ],
          content: {
            nodeId: crypto.randomUUID(),
            type: 'block',
            props: {
              blockType: 'Container',
              style: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
                right: 0,
                left: 0,
                zIndex: Number.MAX_SAFE_INTEGER,
                marginTop: '0px',
                marginBottom: '0px',
                marginLeft: '0px',
                marginRight: '0px',
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingLeft: '0px',
                paddingRight: '0px',
                backgroundColor: '#FFFFFF',
                borderRadius: '0',
                color: '#000000',
                fontFamily: 'Open Sans, sans-serif',
                boxSizing: 'border-box',
                willChange: 'height, opacity, padding-top, padding-bottom',
                pointerEvents: 'all',
              },
              children: [
                {
                  nodeId: crypto.randomUUID(),
                  type: 'block',
                  props: {
                    blockType: 'Container',
                    is: 'div',
                    style: {
                      justifyContent: 'initial',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginLeft: '25px',
                      marginRight: '25px',
                      width: 'auto',
                    },
                    children: [
                      {
                        nodeId: crypto.randomUUID(),
                        type: 'block',
                        version: 1,
                        props: {
                          blockType: 'RichText',
                          is: 'div',
                          style: {
                            margin: 0,
                          },
                          children: [
                            {
                              nodeId: crypto.randomUUID(),
                              type: 'primitive',
                              props: {
                                is: 'p',
                                children: [
                                  {
                                    nodeId: crypto.randomUUID(),
                                    type: 'primitive',
                                    props: {
                                      is: 'span',
                                      children: ['Add text here'],
                                    },
                                  },
                                ],
                                style: {
                                  margin: 0,
                                  textAlign: 'center',
                                },
                              },
                            },
                          ],
                        },
                      },
                      {
                        nodeId: crypto.randomUUID(),
                        type: 'block',
                        version: 1,
                        props: {
                          blockType: 'DismissButton',
                          is: 'button',
                          'data-ignore': true,
                          id: 'dismiss-button',
                          'aria-label': 'Dismiss banner',
                          style: {
                            color: '#000000',
                            width: 'auto',
                            height: '14px',
                            margin: 0,
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            alignSelf: 'center',
                            marginRight: '12px',
                            marginTop: '12px',
                            padding: 0,
                            position: 'absolute',
                            top: '0',
                            right: '0',
                          },
                          children: [
                            {
                              nodeId: crypto.randomUUID(),
                              type: 'primitive',
                              props: {
                                is: 'svg',
                                width: '100%',
                                height: '100%',
                                viewBox: '0 0 14 14',
                                fill: 'none',
                                xmlns: 'http://www.w3.org/2000/svg',
                                children: [
                                  {
                                    nodeId: crypto.randomUUID(),
                                    type: 'primitive',
                                    props: {
                                      is: 'path',
                                      d: 'M12.2944 0.292751C12.6851 -0.0975836 13.3164 -0.0975836 13.707 0.292751C14.0977 0.683398 14.0977 1.31656 13.707 1.7072L8.41609 6.97219L13.707 12.2944C14.0977 12.685 14.0977 13.3163 13.707 13.7069C13.3164 14.0976 12.6851 14.0976 12.2944 13.7069L6.97226 8.41602L1.70728 13.7069C1.31664 14.0976 0.683476 14.0976 0.292891 13.7069C-0.0976305 13.3163 -0.0976305 12.685 0.292891 12.2944L5.58469 6.97219L0.292891 1.7072C-0.0976305 1.31656 -0.0976305 0.683398 0.292891 0.292751C0.683476 -0.0975836 1.31664 -0.0975836 1.70728 0.292751L6.97226 5.58461L12.2944 0.292751Z',
                                      fill: 'currentColor',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
              id: 'banner',
              is: 'div',
            },
          },
        },
      ],
    });

    return newExperience;
  };

  return (
    <CreateExperienceManager
      omitTheme
      type="banner"
      title="Create a Banner Experience"
      onCreate={handleBannerCreate}
      renderTrigger={onClick => (
        <Button onClick={onClick}>Create a Banner</Button>
      )}
    />
  );
};

CreateBannerManager.propTypes = {
  onCreate: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  onCreate: create,
};

export default connect(null, mapDispatchToProps)(CreateBannerManager);
