import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { object, string } from 'yup';
import isEmpty from 'lodash.isempty';

import {
  Button,
  ButtonGroup,
  Modal,
  Heading,
  Input,
  Select,
  Label,
  ErrorMsg,
  NegativeNotice,
} from '@studio/legacy-components';

import { selectFeatures } from 'next/entities/features';

import useToggle from 'next/hooks/use-toggle';
import {
  create,
  readApp,
  reset,
  AppShape,
  selectApps,
} from 'next/entities/apps';

import { asRequest, NEW_ITEM_ID } from 'next/entities/requests';
import { CreateAppForm } from './styled';
import { enabledFeaturePlatformOptions } from './lib';

export const CreateAppManager = ({
  appStatus = {},
  errors = {},
  touched = {},
  values = {},
  setValues,
  setTouched,
  resetForm,
  onOpen,
  onSubmit,
  platformOptions,
}) => {
  const [open, toggle] = useToggle(false);
  const [inFlight, setInFlight] = useState(false);
  const { loading, error } = appStatus;

  useEffect(() => {
    if (open) {
      setInFlight(false);
      onOpen?.();
      resetForm();
    }
  }, [open, resetForm, onOpen]);

  useEffect(() => {
    if (inFlight && loading == null) {
      toggle();
    }

    // NOTE: inFlight is not part of the dependency check.
    //       If so, it will cause the modal to close before it must.
    //       This is due to using a placeholder ID when creating new items.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, toggle]);

  const disabled = loading || isEmpty(touched) || !isEmpty(errors);

  const handleSubmit = event => {
    event.preventDefault();
    const { name, platform } = values;

    setInFlight(true);
    onSubmit({
      name: name.trim(),
      platform,
    });
  };

  return (
    <>
      <Button kind="primary" onClick={toggle}>
        Add app
      </Button>
      <Modal visible={open} onClose={toggle}>
        <CreateAppForm onSubmit={handleSubmit}>
          <Heading>Add app</Heading>
          {error && (
            <NegativeNotice>
              We&apos;re sorry, something went wrong on our end. Try again.
            </NegativeNotice>
          )}
          <Label htmlFor="name">
            Name
            <Input
              id="name"
              type="text"
              name="name"
              placeholder="e.g. my app"
              maxLength={50}
              error={!!errors.name && !!touched.name}
              onChange={({ target: { value } }) =>
                setValues({ ...values, name: value })
              }
              onBlur={() => setTouched({ ...touched, name: true })}
              value={values.name}
            />
            {errors.name && touched.name && <ErrorMsg>{errors.name}</ErrorMsg>}
          </Label>
          <Label htmlFor="platform">
            Type
            <Select
              inputId="platform"
              name="platform"
              options={platformOptions}
              placeholder="Select app type"
              error={!!errors.platform && !!touched.platform}
              onChange={({ value }) => {
                setValues({ ...values, platform: value });
              }}
              onBlur={() => setTouched({ ...touched, platform: true })}
            />
            {errors.platform && touched.platform && (
              <ErrorMsg>{errors.platform}</ErrorMsg>
            )}
          </Label>
          <ButtonGroup right>
            <Button kind="primary" type="submit" disabled={disabled}>
              Save
            </Button>
            <Button kind="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ButtonGroup>
        </CreateAppForm>
      </Modal>
    </>
  );
};

const formShape = type => ({
  name: type,
  platform: type,
});

CreateAppManager.propTypes = {
  appStatus: asRequest(PropTypes.objectOf(AppShape)),
  onOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  platformOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  // formik HOC props
  errors: PropTypes.shape(formShape(PropTypes.string)),
  touched: PropTypes.shape(formShape(PropTypes.bool)),
  values: PropTypes.shape(formShape(PropTypes.string)),
  setValues: PropTypes.func,
  setTouched: PropTypes.func,
  resetForm: PropTypes.func,
};

const enhanceForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    name: '',
    platform: '',
  }),
  validate: ({ name = '', platform }, { apps, platformOptions }) => {
    const errors = {};
    const exists = Object.values(apps)
      .filter(({ platform: current }) => current === platform)
      .find(
        ({ name: current }) =>
          current.toLowerCase() === name.toLowerCase().trim()
      );

    if (exists) {
      const { label } = platformOptions.find(item => item.value === platform);
      errors.name = `This name is already used by another ${label} app.`;
    }

    return errors;
  },
  validationSchema: object().shape({
    name: string().required('Name is required.'),
    platform: string().required('Type is required.'),
  }),
});

export const CreateAppManagerForm = enhanceForm(CreateAppManager);

const mapStateToProps = state => ({
  appStatus: readApp(state, NEW_ITEM_ID),
  apps: selectApps(state),
  platformOptions: (() => {
    const features = selectFeatures(state);
    return enabledFeaturePlatformOptions(features);
  })(),
});

const mapDispatchToProps = dispatch => ({
  onOpen: () => dispatch(reset(NEW_ITEM_ID)),
  onSubmit: values => dispatch(create({ id: NEW_ITEM_ID, ...values })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAppManagerForm);
