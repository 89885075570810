import React from 'react';
import styled from 'styled-components';
import { CButton } from '@appcues/component-library';
import {
  getFlattenedClausesFromNestedConditions,
  getNestedConditionsFromFlattenedClauses,
  addClause,
  deleteClause,
  clauseKeys,
} from '@appcues/libcues';
import { updateClause } from 'transforms/clauses';
import { baseTriggerClauseAttributeConditions } from 'constants/account/conditions';
import SelectEvent from 'components/Common/SelectEvent';
import GroupClause from 'components/Common/Clauses/Group';

const Container = styled.div`
  padding: 5px 0;
  display: flex;
  flex-direction: column;
`;

const SelectEventRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > button {
    margin-left: 10px;
  }
`;

const TriggerClauseAttributesRow = styled.div`
  padding: 1em 0;
  > * {
    margin-top: 1em;
  }
`;

export default function ConfigureCustomEventTrigger({
  triggerClause,
  className,
  hasEventTriggeringWithAttributes,
  updateTriggerClause,
}) {
  const getTriggerConditionClauses = () => {
    const triggerConditions = triggerClause.conditions || {};
    return getFlattenedClausesFromNestedConditions(triggerConditions);
  };

  const handleSelectTriggerClauseEvent = ({ value: event }) => {
    updateTriggerClause({ event });
  };

  const handleClickToggleTriggerClauseConditions = () => {
    updateTriggerClause({
      conditions: triggerClause.conditions
        ? null
        : baseTriggerClauseAttributeConditions,
    });
  };

  const addTriggerConditionsClause = (parentId, clause) => {
    const triggerConditionClauses = getTriggerConditionClauses();
    updateTriggerClause({
      conditions: getNestedConditionsFromFlattenedClauses(
        addClause(triggerConditionClauses, null, clause),
        null,
        clauseKeys
      ),
    });
  };

  const updateTriggerConditionsClause = (clauseId, changes) => {
    const triggerConditionClauses = getTriggerConditionClauses();
    updateTriggerClause({
      conditions: getNestedConditionsFromFlattenedClauses(
        updateClause(triggerConditionClauses, clauseId, changes),
        null,
        clauseKeys
      ),
    });
  };

  const deleteTriggerConditionsClause = clauseId => {
    const triggerConditionClauses = getTriggerConditionClauses();
    updateTriggerClause({
      conditions: getNestedConditionsFromFlattenedClauses(
        deleteClause(triggerConditionClauses, clauseId),
        null,
        clauseKeys
      ),
    });
  };

  return (
    <Container className={className}>
      <SelectEventRow>
        <SelectEvent
          value={triggerClause.event}
          onChange={handleSelectTriggerClauseEvent}
        />
        {(!!triggerClause.conditions || hasEventTriggeringWithAttributes) && (
          <CButton onClick={handleClickToggleTriggerClauseConditions}>
            {triggerClause.conditions ? 'Remove' : 'Add'} Attributes
          </CButton>
        )}
      </SelectEventRow>
      {triggerClause.conditions && (
        <TriggerClauseAttributesRow>
          Only launch if event attributes match:
          <GroupClause
            clauses={getFlattenedClausesFromNestedConditions(
              triggerClause.conditions
            )}
            addClause={addTriggerConditionsClause}
            updateClause={updateTriggerConditionsClause}
            deleteClause={deleteTriggerConditionsClause}
            isRootNode
          />
        </TriggerClauseAttributesRow>
      )}
    </Container>
  );
}
