import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, Icon, H2 } from '@studio/legacy-components';
import { selectAccountChecklist } from 'entities/checklists';
import { selectUserId } from 'reducers/user';
import { selectAccountId } from 'reducers/account/meta';
import ColorPicker from 'components/Common/ColorPicker';
import CharacterCount from 'components/Common/Forms/CharacterCount';
import DefaultSuccessIcon from 'components/ChecklistsEdit/ChecklistConfiguration/DefaultSuccessIcon';
import EmojiSelect from 'components/Common/EmojiSelect';
import FieldGroup from 'components/Common/Forms/FieldGroup';
import ImageUploader from 'components/Common/ImageUploader';
import Input from 'components/Common/Forms/Input';
import Textarea from 'components/Common/Forms/Textarea';

const defaultSuccessColor = '#00D260';

const RemoveIcon = styled(Icon)`
  cursor: pointer;
  color: var(--disabled);
  font-size: var(--regular);
  margin-left: 8px;
  margin-right: 4px;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  max-width: 200px;
  align-items: center;
  border: 2px solid var(--disabled-light);
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
`;

const ConjunctionWrapper = styled.span`
  margin-left: 24px;
  margin-right: 24px;
  color: var(--disabled);
  font-style: italic;
`;

const ImageWrapper = styled.img`
  height: 28px;
  width: auto;
`;

export function CongratsContent({
  accountId,
  completionDismissText,
  completionDismissTextError,
  completionText,
  completionTitle,
  customSuccessImageUrl,
  resetCompletionState,
  setCompletionState,
  successColor,
  successImageCode,
  updateChecklistStyles,
  updateChecklistStylesDropdown,
  updateChecklistStylesSlider,
  updateChecklistTextToken,
  updateLimitedChecklistTextToken,
  userId,
}) {
  const updateChecklistSuccessCustomImage = item => {
    updateChecklistStylesDropdown('customSuccessImageUrl', item);
  };
  const onUploadEmoji = imgUrl => {
    updateChecklistSuccessCustomImage({ value: imgUrl });
    setCompletionState();
  };

  const removeChecklistSuccessCustomImage = () => {
    updateChecklistSuccessCustomImage({ value: null });
  };

  const handleChecklistSuccessColorUpdate = input => {
    updateChecklistStyles('successColor', input);
  };

  const handleChecklistSuccessSliderUpdate = hex => {
    updateChecklistStylesSlider('successColor', hex);
  };

  const handleChecklistSuccessImageUpdate = item => {
    updateChecklistStylesDropdown('successImageCode', item);
  };

  const handleChecklistCompletionTextUpdate = input => {
    updateChecklistTextToken('completionText', input);
  };

  const handleChecklistCompletionTitleUpdate = input => {
    updateChecklistTextToken('completionTitle', input);
  };

  const handleChecklistCongratulationsDismissTextUpdate = input => {
    updateLimitedChecklistTextToken('completionDismissText', 15, input);
  };

  return (
    <>
      <H2>Congratulations Content</H2>
      <FieldGroup title="Image" className="configuration-item">
        <EmojiSelect
          value={successImageCode || '1f389'}
          onChange={handleChecklistSuccessImageUpdate}
          disabled={!!customSuccessImageUrl}
          defaultImage={DefaultSuccessIcon}
          successColor={successColor}
          onFocus={setCompletionState}
        />
        <ConjunctionWrapper>or</ConjunctionWrapper>
        <ImageUploader
          userId={userId}
          accountId={accountId}
          onUpload={onUploadEmoji}
          disableClick={!!customSuccessImageUrl}
        >
          {customSuccessImageUrl ? (
            <RowWrapper>
              <ImageWrapper
                src={customSuccessImageUrl}
                className="success-image-preview"
              />
              <RemoveIcon
                icon="times"
                onClick={removeChecklistSuccessCustomImage}
              />
            </RowWrapper>
          ) : (
            <Button kind="secondary">Upload an Image</Button>
          )}
        </ImageUploader>
      </FieldGroup>
      <FieldGroup title="Congratulations Title" className="configuration-item">
        <Textarea
          type="text"
          className="checklist-textarea-long test-completion-title"
          value={completionTitle}
          onChange={handleChecklistCompletionTitleUpdate}
          onBlur={resetCompletionState}
          onFocus={setCompletionState}
          placeholder="Congratulations!"
        />
      </FieldGroup>
      <FieldGroup title="Congratulations Text" className="configuration-item">
        <Textarea
          type="text"
          className="checklist-textarea-long test-completion-text"
          value={completionText}
          onChange={handleChecklistCompletionTextUpdate}
          onBlur={resetCompletionState}
          onFocus={setCompletionState}
          placeholder="You’ve completed the first few steps. You can always come back if you need more help."
        />
      </FieldGroup>
      <FieldGroup
        title="Congratulations Dismiss Text"
        className="configuration-item"
      >
        <Input
          type="text"
          className="checklist-text-short"
          value={completionDismissText}
          onChange={handleChecklistCongratulationsDismissTextUpdate}
          onBlur={resetCompletionState}
          onFocus={setCompletionState}
          placeholder="I'm done"
          invalid={completionDismissTextError}
        />
        <CharacterCount input={completionDismissText} maxLength={15} />
      </FieldGroup>
      <FieldGroup className="configuration-item" title="Success Color">
        <ColorPicker
          color={successColor}
          defaultColor={defaultSuccessColor}
          onChangeSlider={handleChecklistSuccessSliderUpdate}
          onChangeInput={handleChecklistSuccessColorUpdate}
          onFocus={setCompletionState}
          onBlur={resetCompletionState}
        />
      </FieldGroup>
    </>
  );
}

CongratsContent.propTypes = {
  accountId: PropTypes.string,
  completionDismissText: PropTypes.string,
  completionDismissTextError: PropTypes.bool,
  completionText: PropTypes.string,
  completionTitle: PropTypes.string,
  customSuccessImageUrl: PropTypes.string,
  resetCompletionState: PropTypes.func,
  setCompletionState: PropTypes.func,
  successColor: PropTypes.string,
  successImageCode: PropTypes.string,
  updateChecklistStyles: PropTypes.func,
  updateChecklistStylesDropdown: PropTypes.func,
  updateChecklistStylesSlider: PropTypes.func,
  updateChecklistTextToken: PropTypes.func,
  updateLimitedChecklistTextToken: PropTypes.func,
  userId: PropTypes.string,
};

const mapStateToProps = (state, { checklistId }) => {
  const checklist = selectAccountChecklist(state, checklistId) ?? {};

  const completionDismissText =
    checklist.styles && checklist.textTokens.completionDismissText;
  const completionText =
    checklist.styles && checklist.textTokens.completionText;
  const completionTitle =
    checklist.styles && checklist.textTokens.completionTitle;
  const customSuccessImageUrl =
    checklist.styles && checklist.styles.customSuccessImageUrl;
  const successColor = checklist.styles && checklist.styles.successColor;
  const successImageCode =
    checklist.styles && checklist.styles.successImageCode;

  return {
    completionDismissText,
    completionText,
    completionTitle,
    customSuccessImageUrl,
    successColor,
    successImageCode,
    userId: selectUserId(state),
    accountId: selectAccountId(state),
  };
};

export default connect(mapStateToProps)(CongratsContent);
