import { createCollectionReducer } from 'reducers/account/collections';
import { TYPE } from './constants';

export default createCollectionReducer(TYPE);

export const selectGroupAttributes = state => state.account?.[TYPE];
export const selectGroupAttribute = (state, name) =>
  selectGroupAttributes(state)?.[name];
export const selectGroupAttributeOptions = state =>
  Object.keys(selectGroupAttributes(state) || {})
    .sort((a, b) => a.localeCompare(b))
    .map(attribute => ({
      label: attribute,
      value: attribute,
    }));
export const selectGroupAttributeTypeaheadOptions = (state, attribute) =>
  Object.keys(selectGroupAttribute(state, attribute) || {}).map(value => ({
    label: value,
    value,
  }));
