import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import qs from 'qs';
import { CButton, CModal } from '@appcues/component-library';
import { RenderWithoutDomainPermission } from 'next/components/RenderWithoutDomainPermission';

import OpenChromeIllustrationUrl from 'assets/images/open-chrome-extension.svg';

const ModalContent = styled.section`
  padding: 56px 48px 60px;
  text-align: center;
  white-space: normal;
`;

const Heading = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 16px;
`;

const Footer = styled.footer`
  margin-bottom: 0;
  text-align: center;
  width: 100%;
`;

const Illustration = styled.img`
  margin-bottom: 16px;
`;

const Description = styled.p`
  line-height: 21px;
  margin-bottom: 37px;
`;

export const OpenTrackEventModal = ({ event, url, onClose }) => {
  const handleClick = () => {
    /* global CRX_ID */
    window.open(
      `chrome-extension://${CRX_ID}/static/open-event.html?${qs.stringify({
        url,
        event,
      })}`
    );
    onClose();
  };

  return (
    <RenderWithoutDomainPermission onNavigate={handleClick}>
      <CModal title="" onClose={onClose} type="medium">
        <ModalContent>
          <Illustration
            src={OpenChromeIllustrationUrl}
            alt="illustrates Flow Builder extension in browser toolbar"
          />
          <Heading>Opening Your Page</Heading>
          <Description>
            When the page loads, remember to{' '}
            <strong>
              click the
              <br /> Appcues Builder extension
            </strong>{' '}
            and choose <strong>Track Event</strong>.
          </Description>
          <Footer>
            <CButton is="a" type="primary" onClick={handleClick}>
              Continue
            </CButton>
          </Footer>
        </ModalContent>
      </CModal>
    </RenderWithoutDomainPermission>
  );
};

OpenTrackEventModal.propTypes = {
  event: PropTypes.string,
  url: PropTypes.string,
  onClose: PropTypes.func,
};

export default OpenTrackEventModal;
