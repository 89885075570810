export const CLICKED_BUTTON = 'Clicked Button';
export const SAW_COMPONENT = 'Saw Component';

export const VIEWED_MODAL = 'Viewed Modal';
export const VIEWED_APP_PAGE_STEP = 'Viewed App Page Step';
export const REQUESTED_CSV_EXPORT = 'Requested CSV export';
export const UPDATED_FILTERS = 'Updated flow list filters';

export const OPENED_SALESFORCE_CONFIGURATION_WIZARD =
  'Opened Salesforce configuration wizard';
export const CANCELED_SALESFORCE_CONFIGURATION_WIZARD =
  'Canceled Salesforce configuration';

export const INTEGRATION_EVENTS = {
  'hubspot-two-way-integration': {
    OPENED_CONFIGURATION_WIZARD: 'Opened Hubspot configuration wizard',
    CANCELLED_CONFIGURATION_WIZARD: 'Canceled Hubspot configuration',
  },
  'zendesk-support-two-way-integration': {
    OPENED_CONFIGURATION_WIZARD: 'Opened Zendesk configuration wizard',
    CANCELLED_CONFIGURATION_WIZARD: 'Canceled Zendesk configuration',
  },
  'slack-integration': {
    OPENED_CONFIGURATION_WIZARD: 'Opened Slack configuration wizard',
    CANCELLED_CONFIGURATION_WIZARD: 'Canceled Slack configuration',
  },
  marketo: {
    OPENED_CONFIGURATION_WIZARD: 'Opened Marketo configuration wizard',
    CANCELLED_CONFIGURATION_WIZARD: 'Canceled Marketo configuration',
  },
};

export const UPDATED_TAGS_ON_STEP = 'Updated tags on step';

export const MANAGED_TAG = 'Managed Tag';
const CREATED_TAG = 'Created Tag';
const DELETED_TAG = 'Deleted Tag';
const EDITED_TAG = 'Edited Tag';

export const TagAction = {
  CREATED_TAG,
  EDITED_TAG,
  DELETED_TAG,
};

export const TEST_MODE_CLICK_OPEN_BTN = 'Clicked Open Test button';
export const TEST_MODE_COPY_TEST_URL = 'Copied test mode URL';
