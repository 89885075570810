import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BannerGroup } from '@studio/legacy-components';
import Navigation, { SpoofBanner } from 'next/components/Navigation';
import { PreviewBanner } from 'next/lib/devtools';
import FeaturesBanner from 'next/lib/devtools/features-banner';

const Wrapper = styled.div`
  display: flex;
  min-height: inherit;
`;

const NavWrapper = styled.div`
  flex-basis: var(--nav-width);
  flex-grow: 0;
  flex-shrink: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

/**
 * NOTE: This component is a temporary placeholder for the nav and the
 *       application pages until we completely make the navbar independent from
 *       old studio. Once that is done, we will rearrange this and probably
 *       hoist up the navbar. We are doing this so we can see how the new pages
 *       look with the new nav.
 */
const TemporaryLayout = ({ children }) => (
  <Wrapper>
    <NavWrapper id="navigation">
      <Navigation />
    </NavWrapper>
    <Content id="main">
      <BannerGroup>
        <PreviewBanner />
        <SpoofBanner />
        <FeaturesBanner />
      </BannerGroup>
      {children}
    </Content>
  </Wrapper>
);

TemporaryLayout.propTypes = {
  children: PropTypes.node,
};

export default TemporaryLayout;
