import React from 'react';
import { useDispatch } from 'react-redux';

import { CDialog, P } from '@appcues/component-library';
import { deleteAccountTheme } from 'actions/account/themes';

const DeleteThemeModal = ({ themeId, actions }) => {
  const dispatch = useDispatch();
  const handleDeleteTheme = () => {
    dispatch(deleteAccountTheme(themeId));
  };
  return (
    <CDialog
      title="Delete this theme?"
      onPrimaryAction={handleDeleteTheme}
      primaryActionText="Delete"
      onSecondaryAction={actions.hideModal}
      type="negative"
    >
      <P marginBottom="0px">Are you sure you want to delete this theme?</P>
      <P>This cannot be undone.</P>
    </CDialog>
  );
};

export default DeleteThemeModal;
