import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { H5, Caption, Tooltip, Icon } from '@studio/legacy-components';
import UnstyledCoolTip from 'components/Common/CoolTip';
import { PROMOTERS, PASSIVES, DETRACTORS } from 'components/nps/constants';

import NpsQueryResults from 'components/Common/QueryResults/NpsQueryResults';
import Loader from 'components/Common/Loader';

// style() so we can target component via selector
const CoolTip = styled(UnstyledCoolTip)``;

const Wrapper = styled.article`
  align-items: center;
  display: flex;
  padding: 24px 0;
`;

export const TooltipIcon = styled(Icon).attrs({
  icon: 'info-circle',
})`
  color: var(--text-secondary);
`;

const ScoreSection = styled.section`
  border-right: 1px solid var(--ui-vertical-separator);
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 32px;
  text-align: center;
`;

const ScoreWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const ScoreHeading = styled(H5)`
  color: var(--text-secondary);
  display: inline-block;
  font-size: var(--x-small);
  position: relative;

  ${CoolTip} {
    height: 1.8em;
    position: absolute;
    right: -18px;
    top: -1px;
  }
`;

const Metric = styled.div`
  color: var(--banner-text);
  font-size: 68px;
  line-height: 1;
  margin: 18px 0;
  text-align: center;
`;

const BreakdownSection = styled.section`
  flex-grow: 1;
  padding: 0 32px;
  text-align: center;
`;

const BreakdownBar = styled.span`
  display: inline-flex;
  font-weight: var(--bold);
  justify-content: center;
  align-items: center;
  height: 48px;
  width: ${({ $width }) => $width};
  ${({ $kind }) => {
    switch ($kind) {
      case PROMOTERS:
        return 'background-color: var(--nps-promoters);';
      case DETRACTORS:
        return 'background-color: var(--nps-detractors);';
      default:
        return `background-color: var(--nps-passives);`;
    }
  }}
`;

const BreakdownGraph = styled.div`
  color: var(--white);
  margin-bottom: 24px;
  position: relative;
  /* don't break lines if %s round to more than 100 */
  white-space: nowrap;

  ${BreakdownBar}:first-of-type {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  ${BreakdownBar}:last-of-type {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

const NoResultsMsg = styled.div`
  background-color: var(--nps-no-responses);
  border-radius: 6px;
  font-size: var(--large);
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Key = styled.dl`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const KeyEntry = styled.div`
  display: flex;
  align-items: center;
`;

const KeyColor = styled.dt`
  border-radius: 8px;
  height: 16px;
  margin-right: 8px;
  width: 16px;

  ${({ $kind }) => {
    switch ($kind) {
      case PROMOTERS:
        return 'background-color: var(--nps-promoters);';
      case DETRACTORS:
        return 'background-color: var(--nps-detractors);';
      case PASSIVES:
      default:
        return 'background-color: var(--nps-passives);';
    }
  }}
`;

const KeyDesc = styled.dd`
  display: flex;
  flex-direction: column;
`;

const KeyMetric = styled.div`
  color: var(--banner-text);
  font-size: var(--large);
  font-weight: var(--semi-bold);
  text-align: left;
`;

const LoaderWrapper = styled(Loader)`
  height: 240px;
`;

const safeGetPercentage = (respondentBucket, respondents) => {
  if (respondents === 0) {
    return 0;
  }

  return Math.round((respondentBucket / respondents) * 100);
};

const noResponsesProps = {
  score: '-',
  totalShown: 0,
  respondents: 0,
  promoters: 0,
  passives: 0,
  detractors: 0,
};
export const NpsBreakdown = ({ respondents, ...props }) => {
  const isEmpty = respondents === 0;
  const { score, totalShown, promoters, passives, detractors } =
    respondents > 0 ? props : noResponsesProps;

  const promoterPercent = safeGetPercentage(promoters, respondents);
  const passivePercent = safeGetPercentage(passives, respondents);
  const detractorPercent = safeGetPercentage(detractors, respondents);

  return (
    <Wrapper>
      <ScoreSection>
        <ScoreWrapper>
          <ScoreHeading>NPS SCORE</ScoreHeading>
          <Tooltip
            label="NPS = % Promoters - % Detractors"
            placement="top"
            size="m"
            wrapped
          >
            <TooltipIcon data-testid="tooltip-icon" />
          </Tooltip>
        </ScoreWrapper>
        <Metric>{score}</Metric>
        <Caption>{`${respondents} of ${totalShown} responding`}</Caption>
      </ScoreSection>
      <BreakdownSection>
        <BreakdownGraph>
          {!isEmpty && (
            <>
              {promoterPercent > 0 && (
                <BreakdownBar
                  $kind={PROMOTERS}
                  $width={`${promoterPercent}%`}
                  aria-label={`Promoters: ${promoterPercent}%`}
                >
                  {promoterPercent}%
                </BreakdownBar>
              )}
              {passivePercent > 0 && (
                <BreakdownBar
                  $kind={PASSIVES}
                  $width={`${passivePercent}%`}
                  aria-label={`Passives: ${passivePercent}%`}
                >
                  {passivePercent}%
                </BreakdownBar>
              )}
              {detractorPercent > 0 && (
                <BreakdownBar
                  $kind={DETRACTORS}
                  $width={`${detractorPercent}%`}
                  aria-label={`Detractors: ${detractorPercent}%`}
                >
                  {detractorPercent}%
                </BreakdownBar>
              )}
            </>
          )}
          {isEmpty && <NoResultsMsg>No responses yet</NoResultsMsg>}
        </BreakdownGraph>
        <Key>
          <KeyEntry>
            <KeyColor $kind={PROMOTERS} />
            <KeyDesc>
              <KeyMetric>{promoters}</KeyMetric>
              <Caption>Promoters</Caption>
            </KeyDesc>
          </KeyEntry>
          <KeyEntry>
            <KeyColor $kind={PASSIVES} />
            <KeyDesc>
              <KeyMetric>{passives}</KeyMetric>
              <Caption>Passives</Caption>
            </KeyDesc>
          </KeyEntry>
          <KeyEntry>
            <KeyColor $kind={DETRACTORS} />
            <KeyDesc>
              <KeyMetric>{detractors}</KeyMetric>
              <Caption>Detractors</Caption>
            </KeyDesc>
          </KeyEntry>
        </Key>
      </BreakdownSection>
    </Wrapper>
  );
};

NpsBreakdown.propTypes = {
  score: PropTypes.number.isRequired,
  totalShown: PropTypes.number.isRequired,
  respondents: PropTypes.number.isRequired,
  promoters: PropTypes.number.isRequired,
  passives: PropTypes.number.isRequired,
  detractors: PropTypes.number.isRequired,
};

const NpsBreakdownWithAnalytics = ({
  npsId,
  startTime,
  endTime,
  segmentId,
}) => (
  <NpsQueryResults
    npsId={npsId}
    startTime={startTime}
    endTime={endTime}
    segmentId={segmentId}
  >
    {results => {
      if (!results) {
        return <LoaderWrapper />;
      }

      const {
        npsScore,
        responseCount = 0,
        promoters,
        detractors,
        neutrals,
        shownCount,
      } = results || {};

      return (
        <NpsBreakdown
          score={Math.floor(npsScore)}
          respondents={responseCount}
          totalShown={shownCount}
          promoters={promoters}
          passives={neutrals}
          detractors={detractors}
        />
      );
    }}
  </NpsQueryResults>
);

export default NpsBreakdownWithAnalytics;
