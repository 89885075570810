/* global COMMITHASH, APPCUES_ENV */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Page, BannerGroup } from '@studio/legacy-components';
import { SpoofBanner } from 'next/components/Navigation';
import { NextStoreProvider } from 'next/components/Hacks';
import { PreviewBanner } from 'next/lib/devtools';
import FeaturesBanner from 'next/lib/devtools/features-banner';
import {
  CModalProvider,
  ModalProvider,
  ModalContainer,
} from 'components/legacy/Modal';
import WebRoutes from 'components/App/WebRoutes';
import Navbar from 'components/App/Navbar';
import Loader from 'components/Common/Loader';

import ErrorBoundary from 'components/Common/ErrorBoundary';
import RouteLevelErrorPage from 'components/Common/RouteLevelErrorPage';

import { selectUserSynced } from 'reducers/user';
import { checkLoginStatus } from 'actions/bootstrap';
import { configureErrorReporting } from 'helpers/error-reporting';

import './style.css';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const NavWrapper = styled.div`
  flex: 0 0 var(--nav-width);
`;

export const App = ({ loaded = false, onLoad }) => {
  useEffect(() => {
    onLoad?.();
  }, [onLoad]);

  if (!loaded) {
    return <Loader />;
  }

  return (
    <AppWrapper>
      <NavWrapper data-no-print id="navigation">
        <Navbar />
      </NavWrapper>
      <Page id="main">
        <BannerGroup>
          <PreviewBanner />
          <NextStoreProvider>
            <SpoofBanner />
            <FeaturesBanner />
          </NextStoreProvider>
        </BannerGroup>

        <ErrorBoundary render={RouteLevelErrorPage}>
          <ModalProvider>
            <CModalProvider>
              <WebRoutes />
              <ModalContainer />
            </CModalProvider>
          </ModalProvider>
        </ErrorBoundary>
      </Page>
    </AppWrapper>
  );
};

App.propTypes = {
  loaded: PropTypes.bool,
  onLoad: PropTypes.func,
};

const mapStateToProps = state => ({
  loaded: selectUserSynced(state),
});

const mapDispatchToProps = {
  onLoad: () => {
    configureErrorReporting(COMMITHASH, APPCUES_ENV);
    return checkLoginStatus();
  },
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
