import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const EmptyFlowsIllustration = ({ className }) => (
  <svg className={className} width="179" height="139" fill="none">
    <path
      d="M23.125 36.45c0-3.114 2.648-5.76 5.762-5.76h63.384c3.115 0 5.763 2.646 5.763 5.76v44.522c0 3.113-2.648 5.76-5.763 5.76H72.026v6.382c0 1.401-.779 2.491-2.025 3.114a3.39 3.39 0 01-3.582-.779l-8.72-8.717H28.886c-3.114 0-5.762-2.647-5.762-5.76V36.449z"
      fill="#fff"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
    />
    <path d="M36.362 48.592h31.615" stroke="#5C5CFF" strokeMiterlimit="10" />
    <path
      d="M36.744 60.423h44.25M36.744 70.386h44.25"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M80.143 52.894H37.595c-.511 0-.851-.331-.851-.83v-4.977c0-.498.34-.83.85-.83h42.549c.51 0 .85.332.85.83v4.978c0 .498-.34.83-.85.83z"
      fill="url(#paint0_linear)"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
    />
    <rect
      x="83.969"
      y="72.862"
      width="61.95"
      height="65.269"
      rx="2"
      fill="#fff"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
    />
    <path
      d="M83.97 74.862a2 2 0 012-2h57.949a2 2 0 012 2v19.02h-61.95v-19.02z"
      fill="#D7D7FF"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
    />
    <path
      d="M99.457 110.394h31.458M93.925 119.244H137.064"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M126.441 58.91h0l-.013.003c-.335.082-.735-.064-1.105-.412l-.688-1.085a1.164 1.164 0 01-.139-.59c.011-.176.077-.37.242-.59l2.49-3.032.034-.042.025-.049c.059-.115.182-.195.509-.356l.026-.013.025-.016 23.951-15.503 3.266 4.953-23.919 15.483-.8.394-3.904.855z"
      fill="#fff"
      stroke="#5C5CFF"
    />
    <path
      d="M127.648 52.756l.025-.012.025-.016 4.133-2.67h10.773l-11.631 7.472-.044.029-.037.036a.67.67 0 01-.263.158 2.762 2.762 0 01-.118.04 2.406 2.406 0 00-.321.12l-3.768 1c-.477.117-.903-.04-1.045-.32l-.021-.04-.027-.036-.44-.58-.411-.54c-.214-.451-.153-.895.07-1.116l.019-.018.017-.02 2.435-2.966c.167-.102.292-.226.378-.31l.014-.014c.117-.116.173-.166.237-.197z"
      fill="url(#paint1_linear)"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
    />
    <path
      d="M170.729 36.68c-1.468 1.013-2.789 2.17-3.816 3.618l-1.175 1.592-9.541-14.326 1.908-.434c1.762-.289 3.376-1.012 4.844-2.025l4.257-2.75c3.23-2.025 7.487-1.157 9.688 2.026 2.056 3.183 1.175 7.38-2.055 9.55l-4.11 2.75z"
      fill="#fff"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
    />
    <path
      d="M161.628 48.69c-.734.435-1.614.29-2.055-.433L147.83 30.604c-.147-.29-.293-.724-.147-1.013.147-.434.294-.724.588-.869l4.844-3.183c.734-.434 1.614-.29 2.055.434l11.743 17.653c.44.724.293 1.592-.441 2.026l-4.844 3.039z"
      fill="#fff"
    />
    <path
      d="M161.628 48.69c-.734.435-1.614.29-2.055-.433L147.83 30.604c-.147-.29-.293-.724-.147-1.013.147-.434.294-.724.588-.869l4.844-3.183c.734-.434 1.614-.29 2.055.434l11.743 17.653c.44.724.293 1.592-.441 2.026l-4.844 3.039z"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M170.729 36.68c-1.468 1.013-2.789 2.17-3.816 3.618l-1.175 1.592-9.541-14.326 1.908-.434c1.762-.289 3.376-1.012 4.844-2.025l4.257-2.75c3.23-2.025 7.487-1.157 9.688 2.026 2.056 3.183 1.175 7.38-2.055 9.55l-4.11 2.75zM156.638 28.288l-4.404 2.75M159.573 32.629l-4.403 2.75M162.363 36.825l-4.404 2.894M165.298 41.166l-4.404 2.894"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M128.317 72.118c0 2.146-1.625 3.96-3.671 3.96-1.928 0-3.671-1.687-3.671-3.96 0-.519.222-1.245.607-2.09.378-.828.886-1.713 1.401-2.53a41.197 41.197 0 011.663-2.428 40.602 40.602 0 011.662 2.427c.516.818 1.024 1.703 1.402 2.532.385.844.607 1.57.607 2.09z"
      fill="url(#paint2_linear)"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
    />
    <path
      d="M23.125 45.206c12.22 0 22.125-9.906 22.125-22.125 0-12.22-9.906-22.125-22.125-22.125C10.905.956 1 10.862 1 23.081c0 12.22 9.906 22.125 22.125 22.125z"
      fill="#fff"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
    />
    <path
      d="M18.255 35.744c-.418 0-.696-.279-.696-.696v-.557c0-.417.278-.696.696-.696 1.809-.139 2.226-.417 2.226-.556 0 0 .278-.418.278-2.227V14.871h-1.252c-2.087 0-2.922.417-3.34.696-.417.278-.834 1.113-1.252 3.061 0 .278-.417.557-.696.557h-.835c-.139 0-.417-.14-.556-.279-.14-.139-.14-.278-.14-.556l.14-5.566c0-.418.278-.696.696-.696h19.063c.418 0 .696.278.696.696l.14 5.705c0 .139 0 .417-.14.556-.14.14-.278.279-.556.279h-.835c-.279 0-.557-.279-.696-.557-.418-2.087-.974-2.783-1.253-3.2-.278-.279-1.113-.696-3.34-.696h-1.112V31.43c0 1.53.278 1.809.278 1.809.14.139.557.278 2.226.417.418 0 .696.279.696.696v.557c0 .417-.14.835-.557.835h-9.88z"
      fill="#5C5CFF"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="36.744"
        y1="49.576"
        x2="80.994"
        y2="49.576"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5290" />
        <stop offset="0" stopColor="#FF5290" />
        <stop offset="1" stopColor="#8960FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="123.846"
        y1="54.504"
        x2="144.307"
        y2="54.504"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5290" />
        <stop offset="0" stopColor="#FF5290" />
        <stop offset="1" stopColor="#8960FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="120.475"
        y1="70.409"
        x2="128.817"
        y2="70.409"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5290" />
        <stop offset="0" stopColor="#FF5290" />
        <stop offset="1" stopColor="#8960FF" />
      </linearGradient>
    </defs>
  </svg>
);

EmptyFlowsIllustration.propTypes = {
  className: PropTypes.string,
};

export default styled(EmptyFlowsIllustration)``;
