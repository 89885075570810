import styled from 'styled-components';
import { Button, Checkbox, H3, Text } from '@studio/legacy-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Image = styled.img`
  align-self: center;
  height: 160px;
  width: 160px;
`;

export const Header = styled(H3)`
  color: var(--oh-hi-dark);
  margin: 8px auto;
  text-align: center;
`;

export const Subheader = styled.div`
  text-align: center;

  ${Text} {
    display: inline;
    font-weight: var(--bold);
  }
`;

export const Action = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  ${Checkbox} {
    label {
      font-size: var(--x-small);
    }
  }

  ${Button} {
    &:hover {
      color: var(--white);
    }
  }
`;
