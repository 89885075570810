import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { callApi } from 'actions/account/published-rules';
import { selectPublishedFlowRules } from 'reducers/account/published-rules';
import { selectRuleAndConditions } from 'selectors/ruleAndConditions';
import { selectFlowStatus } from 'reducers/account/flowStatus';

import FlowPrioritiesTableRow from './FlowPrioritiesTableRow';

const Container = styled.table`
  display: grid;
  border: 1px solid var(--sharkbait-ooh-la-la);
  border-radius: 6px;
  padding: 0.5em 1em;
  max-width: 700px;
  height: 120px;
`;

const Body = styled.tbody`
  overflow-y: scroll;
`;

export const sortRules = triggerMap => (a, b) => {
  // Higher priority sorts before lower priority
  if (a.sort_priority < b.sort_priority) {
    return 1;
  }

  if (a.sort_priority > b.sort_priority) {
    return -1;
  }

  // Rules triggered by other rules are sorted lower

  if (triggerMap[a.id].includes(b.id) && !triggerMap[b.id].includes(a.id)) {
    return -1;
  }

  if (!triggerMap[a.id].includes(b.id) && triggerMap[b.id].includes(a.id)) {
    return 1;
  }

  // Rules that trigger content are sorted higher
  if (a.next_content_id && !b.next_content_id) {
    return -1;
  }

  if (!a.next_content_id && b.next_content_id) {
    return 1;
  }

  // Rules are sorted by show once then show every time then any others
  if (a.frequency === 'once' && b.frequency !== 'once') {
    return -1;
  }

  if (a.frequency !== 'once' && b.frequency === 'once') {
    return 1;
  }

  // Rules published most recently are sorted first
  if (new Date(a.published_at) < new Date(b.published_at)) {
    return 1;
  }

  if (new Date(a.published_at) > new Date(b.published_at)) {
    return -1;
  }

  return 0;
};

export const FlowPrioritiesTable = ({
  flowId,
  hasChanges,
  draftSortPriority,
  draftConditions,
  publishedRules = {},
  onLoad,
}) => {
  useEffect(() => {
    onLoad?.();
  }, [onLoad, hasChanges]);

  // we'll be using the draft priority & conditions if the flow is dirty
  // or unpublished, so remove it from the published response in that case
  const allRules = [
    ...Object.values(publishedRules).filter(
      ({ id }) => id !== flowId || !hasChanges
    ),
    ...(hasChanges
      ? [
          {
            id: flowId,
            conditions: draftConditions,
            sort_priority: draftSortPriority,
            published_at: Date.now(),
          },
        ]
      : []),
  ];

  const triggerMap = allRules.reduce((acc, currRule) => {
    acc[currRule.id] = allRules.filter(
      item => item.next_content_id === currRule.id
    );
    return acc;
  }, {});

  const sortedRules = allRules.sort(sortRules(triggerMap));

  return (
    <Container>
      <Body>
        {sortedRules.map(({ id, sort_priority, conditions }) => {
          const selected = id === flowId;
          return (
            <FlowPrioritiesTableRow
              key={id}
              flowId={id}
              sortPriority={sort_priority}
              conditions={conditions}
              selected={selected}
            />
          );
        })}
      </Body>
    </Container>
  );
};

FlowPrioritiesTable.propTypes = {
  flowId: PropTypes.string,
  hasChanges: PropTypes.bool,
  draftSortPriority: PropTypes.number,
  draftConditions: PropTypes.object,
  publishedRules: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
      conditions: PropTypes.object,
      published_at: PropTypes.string,
      sort_priority: PropTypes.number,
    })
  ),
  onLoad: PropTypes.func,
};

const mapStateToProps = (state, { flowId }) => {
  const draftRule = selectRuleAndConditions(state, flowId);
  const contentStatus = selectFlowStatus(state, flowId);
  return {
    hasChanges: !draftRule?.published || contentStatus?.publishedAndDirty,
    draftSortPriority: draftRule?.sortPriority,
    draftConditions: draftRule?.conditions,
    publishedRules: selectPublishedFlowRules(state),
  };
};

const mapDispatchToProps = {
  onLoad: callApi,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlowPrioritiesTable);
