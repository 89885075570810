import {
  call,
  getContext,
  put,
  takeEvery,
  select,
  all,
  takeLatest,
} from 'redux-saga/effects';

import toast from 'next/lib/toast';
import { CONTENT_PUBLISHED } from 'actions/publishing';
import { resolve, reject, insert, patterns, FETCH_SCHEDULES } from './actions';
import { selectSchedules, selectSchedule } from './reducer';

/**
 * Normalize schedules response as collection
 *
 * @param {Response} response - Raw schedules response
 * @return {Collection} Schedules collection
 */
export const transform = response =>
  response.reduce((acc, schedule) => {
    acc[schedule.id] = schedule;
    return acc;
  }, {});

const resolveDataFormat = response => {
  // as we use the same saga in both next and classic codebases
  // data can be in different formats
  // response.data.data duplicated due to how studio classic works
  // or just response.data for studio next
  return response?.data?.data ?? response?.data;
};

function* fetchSchedules({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getExperienceSchedules, id);
    const data = resolveDataFormat(response);

    yield put(resolve(transform(data?.schedules ?? [])));
  } catch (error) {
    yield put(reject(error));
  }
}

function* deleteExistingSchedules() {
  try {
    const api = yield getContext('api');
    const schedules = yield select(selectSchedules);

    yield all(
      Object.values(schedules).map(schedule => {
        return call(api.deleteExperienceSchedule, schedule.id);
      })
    );

    yield put(resolve({}));
  } catch (error) {
    yield put(reject(error));
  }
}

function* createSchedule({ payload }) {
  yield call(deleteExistingSchedules);

  try {
    const api = yield getContext('api');
    const response = yield call(api.createExperienceSchedule, payload);
    const data = resolveDataFormat(response);

    yield put(insert(data?.createSchedule));
  } catch (error) {
    yield put(reject(error));
  }
}

function* showToast() {
  yield call(toast.success, 'Changes saved');
}

function* showError() {
  yield call(toast.error, 'We had a problem saving the schedule changes');
}

function* handleExperiencePublished({ payload }) {
  const schedule = yield select(selectSchedule);

  if (schedule && schedule?.startDate && schedule?.endDate) {
    yield call(createSchedule, {
      payload: {
        enabled: true,
        contentId: payload.id,
        endDate: schedule?.endDate,
      },
    });

    return;
  }

  if (schedule && schedule?.startDate) {
    yield call(deleteExistingSchedules);
  }
}

export default function* saga() {
  yield takeEvery([FETCH_SCHEDULES], fetchSchedules);
  yield takeLatest(patterns.add, createSchedule);
  yield takeLatest(patterns.remove, deleteExistingSchedules);
  yield takeEvery(patterns.insert, showToast);
  yield takeEvery(patterns.reject, showError);
  yield takeEvery(CONTENT_PUBLISHED, handleExperiencePublished);
}
