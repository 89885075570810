import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ButtonLink } from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';
import {
  getConditionsSections,
  getEligibilityConditionsSections,
  sectionsTitle,
} from './utils';
import {
  CriteriaCount,
  Inelegible,
  CriteriaOverflow,
  CriteriaWrapper,
  CriteriaIcon,
  Criteria,
  CriteriaName,
  ModalFooter,
  CriteriaTitle,
} from './styled';

export function CriteriaModalManager({ conditions, name, activity }) {
  const sections = getEligibilityConditionsSections(
    getConditionsSections(conditions)
  );

  if (activity === 'pin') {
    delete sections.frequency;
  }

  const criterias = Object.values(sections);
  const totalCriterias = criterias?.length;
  const [visible, toggleShowCriteria] = useToggle(false);
  const notFulfilledCriterias = criterias?.filter(
    criteria => !criteria.eligible
  ).length;

  return (
    <>
      <ButtonLink
        aria-label={`See ${name} experience criteria details`}
        onClick={toggleShowCriteria}
      >
        Ineligible
      </ButtonLink>
      {visible && (
        <Modal
          visible={visible}
          key="dialog"
          onClose={toggleShowCriteria}
          size="s"
        >
          <CriteriaTitle title={name}>{name}</CriteriaTitle>
          <CriteriaCount>
            <Inelegible>Ineligible</Inelegible> ({notFulfilledCriterias}/
            {totalCriterias} Criteria failed)
          </CriteriaCount>
          <CriteriaOverflow>
            {Object.keys(sections)?.map(key => (
              <CriteriaWrapper key={key}>
                <CriteriaIcon
                  $eligible={sections[key].eligible}
                  icon={sections[key].eligible ? 'check' : 'times'}
                />
                <Criteria>
                  <CriteriaName>{sectionsTitle[key]}</CriteriaName>
                </Criteria>
              </CriteriaWrapper>
            ))}
          </CriteriaOverflow>
          <ModalFooter>
            <Button onClick={toggleShowCriteria}>Done</Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}

CriteriaModalManager.propTypes = {
  activity: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  conditions: PropTypes.object,
};

export default CriteriaModalManager;
