import { getContext, call, put, takeEvery } from 'redux-saga/effects';
import { getCacheKey } from 'next/lib/caching';
import { replacePagePatternFor } from 'next/entities/page';
import {
  queryFlowsMetricsAnalytics,
  queryExperienceMetricsAnalytics,
  queryExperienceAnalyticsChart,
} from 'next/client/queries';
import {
  append,
  reject,
  readAnalytics,
  READ_ANALYTICS,
  READ_GOALS_REACHED_FROM_EXPERIENCE_STARTED,
} from './actions';

export function* getAnalytics({ payload: { query, cacheKey } }) {
  try {
    const api = yield getContext('api');
    const { results } = yield call(api.getAnalytics, query);
    const key = cacheKey ?? getCacheKey(query);
    yield put(append({ [key]: results }));
  } catch (error) {
    yield put(reject(error));
  }
}

export function* getGoalsReachedFromExperienceStarted({
  payload: { query, cacheKey },
}) {
  try {
    const api = yield getContext('api');
    const results = yield call(api.getGoalsReachedFromExperienceStarted, query);
    const key = cacheKey ?? getCacheKey(query);
    yield put(append({ [key]: results }));
  } catch (error) {
    yield put(reject(error));
  }
}

export function* getFlowAnalytics() {
  const query = queryFlowsMetricsAnalytics();
  const readAction = readAnalytics(query);
  yield call(getAnalytics, readAction);
}

export function* getMobileFlowAnalytics() {
  const query = queryExperienceMetricsAnalytics();
  const readAction = readAnalytics(query);
  yield call(getAnalytics, readAction);
}

export function* getExperienceAnalyticsChart({
  payload: { startTime, endTime, experienceId, segmentId },
}) {
  const query = queryExperienceAnalyticsChart({
    startTime,
    endTime,
    experienceId,
    segmentId,
  });
  const readAction = readAnalytics(query);
  yield call(getAnalytics, readAction);
}

export default function* analyticsSagas() {
  // Internal actions
  yield takeEvery(READ_ANALYTICS, getAnalytics);
  yield takeEvery(
    READ_GOALS_REACHED_FROM_EXPERIENCE_STARTED,
    getGoalsReachedFromExperienceStarted
  );

  // Page actions
  yield takeEvery(replacePagePatternFor('/flows'), getFlowAnalytics);
  yield takeEvery(replacePagePatternFor('/flows'), getMobileFlowAnalytics);
}
