import { connect } from 'react-redux';
import { FrequencyPanel } from '@studio/settings-panels';
import { FLOW_FREQUENCY, selectFeature } from 'next/entities/features';
import { selectAccount, update as updateAccount } from 'next/entities/account';

const mapStateToProps = state => {
  const { throttleEnabled, throttleContentLimit, throttleContentTimespanMs } =
    selectAccount(state);

  return {
    hasFrequency: selectFeature(state, FLOW_FREQUENCY),
    throttleEnabled,
    throttleContentLimit,
    throttleContentTimespanMs,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateAccount: delta => dispatch(updateAccount(delta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FrequencyPanel);
