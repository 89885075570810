import React, { Fragment } from 'react';
import styled from 'styled-components';
import { H4, Flex, Td } from '@appcues/component-library';

import { ExternalLink } from '@studio/legacy-components';
import UpdatedTable from 'components/Common/UpdatedTable';
import CoolTip from 'components/Common/CoolTip';

import { sortByKey } from 'utils';
import PropertyRow from './PropertyRow';

const ToolTip = styled(CoolTip)`
  padding-left: 16px;
  color: ${props => props.theme['$gray-5']};
`;

const docsLink =
  'https://docs.appcues.com/article/47-sending-user-properties-to-appcues';

const headers = [
  {
    text: 'Display Name',
    tip: 'The name that displays in Appcues menus.',
  },
  {
    text: 'Property Name',
    tip: 'The name chosen by developers in your Appcues installation.',
  },
  {
    text: 'Last seen',
    tip: 'The last time Appcues received data for this property.',
  },
  {
    text: 'Show in Menus',
    tip: 'Hide or show this property in Appcues menus.',
  },
];

export default function PropertiesList({ properties, isAutoProperties }) {
  const renderRow = property => (
    <PropertyRow key={property.name} attribute={property} />
  );

  const renderHeader = (headerObj, index) => {
    const padding = index === 0 ? <PaddedTd style={{ width: 24 }} /> : null;

    return (
      <Fragment key={headerObj.text}>
        {padding}
        <PaddedTd>
          <Flex>
            <H4 type="secondary" truncate>
              {headerObj.text}
            </H4>
            <ToolTip tip={headerObj.tip} />
          </Flex>
        </PaddedTd>
      </Fragment>
    );
  };

  const propsArr = Object.values(properties);

  return (
    <>
      {isAutoProperties && (
        <IntroText className="intro-text">
          Auto-Properties are automatically collected by Appcues.
        </IntroText>
      )}
      {!isAutoProperties && (
        <IntroText className="intro-text">
          Custom Properties are added to Appcues by your team. Want to add a new
          property? <ExternalLink href={docsLink}>Learn more.</ExternalLink>
        </IntroText>
      )}
      <BorderTable
        headers={headers}
        rowData={propsArr.sort(sortByKey('name'))}
        renderRow={renderRow}
        renderHeader={renderHeader}
      />
    </>
  );
}

PropertiesList.defaultProps = {
  isAutoProperties: false,
  properties: {},
};

const BorderTable = styled(UpdatedTable)`
  border: 1px solid ${props => props.theme['$gray-1']};
  border-collapse: collapse;
  border-radius: 6px;
  margin-bottom: 40px;

  .property-row td:last-child {
    text-align: right;
  }
`;

const PaddedTd = styled(Td)`
  padding: 24px 16px 8px;
  font-weight: 400;

  &:first-child {
    padding-left: 32px;
  }
  &:last-child {
    padding-right: 32px;
  }
`;

const IntroText = styled.p`
  &.intro-text {
    margin-bottom: 24px;
  }
`;
