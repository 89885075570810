/* eslint-disable no-underscore-dangle */
import React from 'react';

import { faWavePulse } from '@fortawesome/pro-solid-svg-icons/faWavePulse';
import PropTypes from 'prop-types';
import {
  SubTitle,
  Button,
  Icon,
  Tooltip,
  Spinner,
  Text,
  Link,
} from '@appcues/sonar';
import { conditionalRelativeTim } from 'next/lib/date';
import NoRecentGroups from 'next/assets/images/no-recent-groups.svg';
import {
  GroupList,
  Group,
  DateWrapper,
  GroupSection,
  SpinnerWrapper,
  EmptyState,
  EmptyHelperText,
  Identifier,
} from './styled';

export default function RecentAccountList({
  groups = [],
  identifier,
  loading,
}) {
  return (
    <GroupSection>
      <SubTitle>Recent Accounts</SubTitle>
      {loading && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      {!loading && groups.length > 0 && (
        <>
          <GroupList>
            {groups?.map(group => (
              <Group key={group._groupId}>
                <Identifier href={`/audience/accounts/${group._groupId}`}>
                  {identifier && group[identifier]
                    ? group[identifier]
                    : group._groupId}
                </Identifier>

                <DateWrapper>
                  {conditionalRelativeTim(Number(group._lastSeenAt))}
                </DateWrapper>
                <Tooltip content="View account activity">
                  <Button
                    iconOnly
                    variant="tertiary"
                    forwardedAs={Link}
                    href={`/audience/accounts/${group._groupId}/usage`}
                  >
                    <Icon size="small" icon={faWavePulse} />
                  </Button>
                </Tooltip>
              </Group>
            ))}
          </GroupList>
          <Link href="/audience/accounts">See all accounts</Link>
        </>
      )}
      {!loading && groups.length === 0 && (
        <EmptyState>
          <img src={NoRecentGroups} alt="No accounts" />
          <Text size="medium" weight="semibold" colorToken="foreground-primary">
            No accounts seen yet
          </Text>
          <EmptyHelperText size="regular" align>
            Include{' '}
            <Link
              href="https://docs.appcues.com/dev-installing-appcues/group-properties-overview#what-are-group-properties-0"
              target="_blank"
            >
              group properties
            </Link>{' '}
            when you <Link to="/settings/installation">install</Link> to uncover
            retention traits, expansion opportunities, and drive revenue growth.
          </EmptyHelperText>
        </EmptyState>
      )}
    </GroupSection>
  );
}

RecentAccountList.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      _lastSeenAt: PropTypes.string,
    })
  ),
  identifier: PropTypes.string,
  loading: PropTypes.bool,
};
