import React from 'react';
import PropTypes from 'prop-types';
import { CPanel, H3, CButton } from '@appcues/component-library';
import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';

import { getThemeFor } from 'utils';

const StyledCButton = styled(CButton)`
  min-width: 0;

  .svg-inline--fa {
    color: ${props =>
      props.selected && `${getThemeFor('$purple')(props)} !important;`};
  }
  &:hover {
    .svg-inline--fa {
      color: ${getThemeFor('$purple-dark')} !important;
    }
  }
`;

const LineChartButton = styled(StyledCButton)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const ColumnChartButton = styled(StyledCButton)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
`;

const StyledCPanelHeader = styled(CPanel.Header)`
  padding-bottom: 0;
  padding-top: 0;
`;

const StyledIcon = styled(Icon)`
  margin-right: 0 !important;
  color: var(--icon);
`;

export const ChartWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 275px;
  justify-content: center;
  margin-top: -21px;
  position: relative;
  width: 100%;
  > div {
    width: 100%;
  }
`;

const ChartContainer = ({
  title = 'Overview',
  chartType = 'line',
  onSaveChart,
  onSelectChartType,
  children,
}) => {
  return (
    <CPanel marginBottom="1em" minWidth={948}>
      <StyledCPanelHeader>
        <H3 lineHeight={1}>{title}</H3>
        <CPanel.Header.Actions>
          {onSaveChart && (
            <CButton
              key="save-chart-button"
              type="positive"
              isDisabled={false}
              onClick={onSaveChart}
            >
              Save Chart
            </CButton>
          )}
          {onSelectChartType && (
            <>
              <LineChartButton
                selected={chartType === 'line'}
                onClick={() => onSelectChartType('line')}
              >
                <StyledIcon icon="chart-line" title="Display line chart" />
              </LineChartButton>
              <ColumnChartButton
                selected={chartType === 'column'}
                onClick={() => onSelectChartType('column')}
              >
                <StyledIcon icon="chart-bar" title="Display column chart" />
              </ColumnChartButton>
            </>
          )}
        </CPanel.Header.Actions>
      </StyledCPanelHeader>
      {children}
    </CPanel>
  );
};

ChartContainer.propTypes = {
  title: PropTypes.string,
  chartType: PropTypes.string,
  onSaveChart: PropTypes.func,
  onSelectChartType: PropTypes.func,
  children: PropTypes.node,
};

export default ChartContainer;
