import { local } from './storage';

const filterKey = type => `appcues:${type}:filter`;

export const loadFilters = type => {
  return local.get(filterKey(type), {
    app: {},
    flow_status: {},
    tags: {},
    segments: {},
    creator: {},
    domains: {},
  });
};

export const saveFilter = (type = 'flows', group, value, isEnabled) => {
  const savedFilter = loadFilters(type);
  if (savedFilter[group]) {
    savedFilter[group][value] = { enabled: isEnabled };
    local.set(filterKey(type), savedFilter);
  }
};

export const saveFilterGroup = (type = 'flows', group, isEnabled) => {
  const savedFilter = loadFilters(type);
  const filterGroup = savedFilter[group];
  if (filterGroup) {
    const nextFilterGroup = { ...filterGroup };
    Object.values(nextFilterGroup).map(groupValues => {
      const nextGroupValues = groupValues;
      if (nextGroupValues.enabled) {
        nextGroupValues.enabled = isEnabled;
      }

      return nextGroupValues;
    });
    savedFilter[group] = nextFilterGroup;
    local.set(filterKey(type), savedFilter);
  }
};
