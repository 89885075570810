import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Modal,
  Button,
  ButtonGroup,
  Overflow,
  Link,
  Heading,
  Description,
  NegativeNotice,
} from '@studio/legacy-components';

import { selectAccountSatisfactionSegmentId } from 'selectors/satisfaction';
import { deleteSegment } from 'actions/account/segments';
import {
  selectNumberOfMatchingChecklists,
  selectNumberOfMatchingFlows,
  selectNumberOfMatchingSegments,
  selectNumberOfMatchingPins,
  selectNumberOfMobileFlows,
  selectNumberOfMatchingBanners,
} from 'selectors/segments';
import { asPromised } from 'utils/as-promised';

const pluralize = (word, qty) => `${qty} ${word}${qty > 1 ? 's' : ''}`;

export const DeleteSegmentModal = ({
  visible,
  onClose,
  numberOfMatchingSegments,
  numberOfMatchingChecklists,
  numberOfMatchingPins,
  numberOfMatchingBanners,
  numberOfMatchingFlows = 0,
  numberOfMatchingMobileFlows = 0,
  isUsedInNps,
  segmentId,
  onDelete,
}) => {
  const [hasError, setHasError] = useState(false);
  const [inFlight, setInFlight] = useState(false);

  const handleSegmentDeletion = async () => {
    setInFlight(true);
    setHasError(false);
    onDelete(segmentId)
      .then(() => onClose())
      .catch(() => setHasError(true))
      .finally(() => setInFlight(false));
  };

  if (
    numberOfMatchingFlows ||
    numberOfMatchingMobileFlows ||
    numberOfMatchingSegments ||
    numberOfMatchingChecklists ||
    numberOfMatchingPins ||
    numberOfMatchingBanners ||
    isUsedInNps
  ) {
    const links = [];

    if (numberOfMatchingBanners > 0) {
      links.push({
        path: `/banners`,
        name: pluralize('banners', numberOfMatchingBanners),
      });
    }

    if (numberOfMatchingPins > 0) {
      links.push({
        path: `/pins`,
        name: pluralize('pin', numberOfMatchingPins),
      });
    }

    if (numberOfMatchingFlows > 0 || numberOfMatchingMobileFlows > 0) {
      links.push({
        path: `/flows`,
        name: pluralize(
          'flow',
          numberOfMatchingFlows + numberOfMatchingMobileFlows
        ),
      });
    }

    if (numberOfMatchingChecklists > 0) {
      links.push({
        path: '/checklists',
        name: pluralize('checklist', numberOfMatchingChecklists),
      });
    }

    if (numberOfMatchingSegments > 0) {
      links.push({
        path: '/segments',
        name: pluralize('segment', numberOfMatchingSegments),
      });
    }

    if (isUsedInNps) {
      links.push({
        path: '/nps',
        name: 'NPS',
      });
    }

    return (
      <Modal visible={visible} onClose={onClose} size="s">
        <Overflow>
          <Heading>Unable to delete segment</Heading>
          <Description>
            To delete, you will need to remove it from the targeting conditions
            for{' '}
            {links.map((link, i) => [
              i > 0 && ', ',
              <Link key={link.name} to={link.path}>
                {link.name}
              </Link>,
            ])}
          </Description>
          <ButtonGroup right>
            <Button onClick={onClose}>Got it</Button>
          </ButtonGroup>
        </Overflow>
      </Modal>
    );
  }

  return (
    <Modal visible={visible} onClose={onClose} size="s">
      <Overflow>
        <Heading>Delete this segment?</Heading>
        <Description>
          Are you sure you want to delete this segment? <br />
          This cannot be undone.
        </Description>
        {hasError && <NegativeNotice>Failed to delete segment</NegativeNotice>}
        <ButtonGroup right>
          <Button
            kind="negative"
            disabled={inFlight}
            onClick={handleSegmentDeletion}
          >
            Delete
          </Button>
          <Button kind="secondary" onClick={onClose}>
            Cancel
          </Button>
        </ButtonGroup>
      </Overflow>
    </Modal>
  );
};

DeleteSegmentModal.propTypes = {
  segmentId: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onLoad: PropTypes.func,
  onDelete: PropTypes.func,
  numberOfMatchingFlows: PropTypes.number,
  numberOfMatchingSegments: PropTypes.number,
  numberOfMatchingChecklists: PropTypes.number,
  isUsedInNps: PropTypes.bool,
};

function mapStateToProps(state, { segmentId }) {
  return {
    numberOfMatchingFlows: selectNumberOfMatchingFlows(state, segmentId),
    numberOfMatchingMobileFlows: selectNumberOfMobileFlows(state, segmentId),
    numberOfMatchingSegments: selectNumberOfMatchingSegments(state, segmentId),
    numberOfMatchingChecklists: selectNumberOfMatchingChecklists(
      state,
      segmentId
    ),
    isUsedInNps: selectAccountSatisfactionSegmentId(state) === segmentId,
    numberOfMatchingPins: selectNumberOfMatchingPins(state, segmentId),
    numberOfMatchingBanners: selectNumberOfMatchingBanners(state, segmentId),
  };
}

const mapDispatchToProps = dispatch => ({
  onDelete: id => asPromised(dispatch, deleteSegment(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSegmentModal);
