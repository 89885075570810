import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import { INITIALIZE } from 'next/root/actions';
import { reject, resolve } from './actions';

/**
 * TODO: Add toast notifications on success/failure
 */

/**
 * Normalize installed domains response as collection
 *
 * @param {Response} response - Raw accounts response
 * @return {Collection} Accounts collection
 */
const normalize = response =>
  response.reduce((acc, { domain: hostname }) => {
    if (hostname) {
      acc[hostname] = { hostname };
    }
    return acc;
  }, {});

function* fetchInstalled() {
  try {
    const api = yield getContext('api');
    const { results } = yield call(api.getInstalledDomains);
    yield put(resolve(normalize(results)));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  yield takeLatest(INITIALIZE, fetchInstalled);
}
