import React, { Component } from 'react';
import styled from 'styled-components';
import { CButton } from '@appcues/component-library';
import { IconWithinCButton } from '@studio/legacy-components';
import WizardHeader from 'components/Common/URLTargetingHelper/WizardHeader';
import TestStep from 'components/Common/URLTargetingHelper/TestStep';

const TargetingWrapper = styled.div`
  margin-top: 1rem;
`;

const TargetingMessage = styled.span`
  margin-left: 1rem;
  color: ${props => props.theme['$gray-30']};
`;

const HelperWrapper = styled.div`
  background-color: ${props => props.theme['$gray-1']};
  border-radius: 4px;
  border-left: ${props => `8px solid ${props.theme.$purple}`};
  margin: 1rem 0;
  padding: 0.5rem 2rem 1.5rem;
`;

class URLTargetingHelper extends Component {
  state = {
    testUrls: [],
  };

  componentDidMount() {
    const { previewUrl } = this.props;

    if (previewUrl) {
      this.setState({ testUrls: [previewUrl] });
    }
  }

  deleteUrl = deleteUrl => {
    this.setState(({ testUrls }) => {
      const newUrls = testUrls.filter(url => {
        return url !== deleteUrl;
      });

      return { testUrls: newUrls };
    });
  };

  handleSubmit = update => {
    this.setState(({ testUrls }) => ({
      testUrls: [...testUrls, encodeURI(update)],
    }));
  };

  render() {
    const { active, toggleHelper, conditions } = this.props;
    const { testUrls } = this.state;

    return (
      <TargetingWrapper>
        <CButton onClick={toggleHelper} type="primary">
          <IconWithinCButton icon="clipboard-check" />
          Test Page Targeting
        </CButton>
        <TargetingMessage>
          Make sure your content shows on the right pages
        </TargetingMessage>
        {active && (
          <HelperWrapper>
            <WizardHeader closeHelper={toggleHelper} />
            <TestStep
              conditions={conditions}
              deleteUrl={this.deleteUrl}
              handleSubmit={this.handleSubmit}
              testUrls={testUrls}
            />
          </HelperWrapper>
        )}
      </TargetingWrapper>
    );
  }
}

export default URLTargetingHelper;
