import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';

export const PlatformBadgeWrapper = styled.div`
  align-items: center;
  background: var(--status-inactive-bg);
  border-radius: 6px;
  color: var(--text-primary);
  display: flex;
  font-size: var(--small);
  font-weight: var(--bold);
  gap: 6px;
  height: 24px;
  padding: 4px 8px;
  min-width: fit-content;
  margin-right: 8px;

  ${Icon} {
    color: var(--sharkbait-ooh-la-la);

    &[data-icon='android'] {
      margin-top: 2px;
    }
  }
`;
