import React from 'react';
import {
  conditionNames,
  filterAudienceTargetingClauses,
  getFilteredClauseTree,
  getConditionsFriendlyDescription,
} from '@appcues/libcues';
import { Button } from '@appcues/component-library';
import SatisfactionSurveyCard from 'components/SatisfactionSurveys/SatisfactionSurveyCard';
import { millisecondsPerDay } from 'constants/account/conditions';
import {
  SATISFACTION_TARGETING_CATEGORY,
  SATISFACTION_TARGETING_OPTIONS,
} from 'constants/satisfaction/data';
import {
  detectConditionsTemplate,
  getAudienceSettingsSummary,
  getPageOptionFromClauses,
  getAudienceTargetingOption,
  getNewUserClause,
  getEmailFilteredClauses,
} from 'helpers/satisfaction';
import {
  filterUrlTargetingClauses,
  filterDomainTargetingClauses,
  getSegmentClause,
} from 'utils/conditions';
import * as clauseTransforms from 'transforms/clauses';

const { hasOwnProperty } = Object.prototype;

const getPageDescriptionFromClauses = clauses => {
  const urlTargetingClauses = getFilteredClauseTree(
    filterUrlTargetingClauses,
    clauses
  );

  const urlFilteredClauses = urlTargetingClauses.filter(urlFilteredClause => {
    return (
      hasOwnProperty.call(urlFilteredClause, 'conditionName') &&
      urlFilteredClause.conditionName === conditionNames.URL
    );
  });

  const pageOption = getPageOptionFromClauses(urlFilteredClauses);

  const domainClauses = getFilteredClauseTree(
    filterDomainTargetingClauses,
    clauses
  );

  const pageDescription = `${pageOption.description}${
    domainClauses.length > 0
      ? ' on specific domains'
      : ' on all installed domains'
  }`;
  return {
    description: pageDescription,
  };
};

const getFrequencyDescriptionFromClauses = userPropertyClauses => {
  const FREQUENCY_SETTINGS =
    SATISFACTION_TARGETING_OPTIONS[SATISFACTION_TARGETING_CATEGORY.FREQUENCY];

  const surveyFrequencyClause = userPropertyClauses.find(clause => {
    return (
      clause.property === FREQUENCY_SETTINGS.HAS_NOT_SEEN_NPS.clauseProperty &&
      clause.operator === FREQUENCY_SETTINGS.HAS_NOT_SEEN_NPS.operator
    );
  });

  const sessionPageViewClause = userPropertyClauses.find(clause => {
    return (
      clause.property === FREQUENCY_SETTINGS.SESSION_PAGE_VIEWS.clauseProperty
    );
  });

  const surveyDelayClause = userPropertyClauses.find(clause => {
    return (
      clause.property === FREQUENCY_SETTINGS.ASK_ME_LATER.clauseProperty &&
      clause.operator === FREQUENCY_SETTINGS.ASK_ME_LATER.operator &&
      clause.value < surveyFrequencyClause.value
    );
  });

  return {
    surveyFrequency: surveyFrequencyClause.value / millisecondsPerDay,
    pageViews: sessionPageViewClause.value,
    surveyDelay: surveyDelayClause.value / millisecondsPerDay,
  };
};

export default function AudienceSummary({
  clauses: initial,
  meta,
  segments,
  steps,
  updateSatisfaction,
  rule,
  checklists,
}) {
  const getAudienceDescriptionFromClauses = userPropertyClauses => {
    const newUserClause = getNewUserClause(userPropertyClauses, meta);
    const segmentClause = getSegmentClause(userPropertyClauses);
    const userOperator = (newUserClause && newUserClause.operator) || null;
    const userOption = getAudienceTargetingOption(userOperator, segmentClause);

    const flowFilteredClauses = userPropertyClauses.filter(clause => {
      return (
        hasOwnProperty.call(clause, 'conditionName') &&
        clause.conditionName === conditionNames.CONTENT
      );
    });

    const emailFilteredClauses = getEmailFilteredClauses(
      userPropertyClauses,
      meta
    );
    const newUserDays = newUserClause
      ? newUserClause.value / millisecondsPerDay
      : null;
    const userOptionDescription = getAudienceSettingsSummary(
      userOption,
      flowFilteredClauses.length,
      emailFilteredClauses.length,
      newUserClause,
      newUserDays
    );

    return {
      userOptionValue: userOption.value,
      description: userOptionDescription,
    };
  };

  const renderNPSConditionsSummary = clauses => {
    const pageDetails = getPageDescriptionFromClauses(clauses);
    const pageDescription = pageDetails.description;

    const userPropertyClauses = getFilteredClauseTree(
      filterAudienceTargetingClauses,
      clauses
    );

    const frequencyDetails =
      getFrequencyDescriptionFromClauses(userPropertyClauses);

    const audienceDetails =
      getAudienceDescriptionFromClauses(userPropertyClauses);

    const urlClausesWithParent = clauseTransforms.getUrlClauses(clauses);

    const samplingDetails = userPropertyClauses.find(clause => {
      return (
        clause.property ===
        SATISFACTION_TARGETING_OPTIONS[SATISFACTION_TARGETING_CATEGORY.SAMPLING]
          .USER_SESSIONS.clauseProperty
      );
    });

    return (
      <div>
        <p>
          <b>Where: </b>
          {pageDescription}
          <br />
        </p>
        <em>
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: getConditionsFriendlyDescription(
                null,
                urlClausesWithParent,
                null,
                null,
                null,
                true
              ),
            }}
          />
        </em>

        <p>
          <b>When: </b>
          {`Once every ${frequencyDetails.surveyFrequency} days after
            ${frequencyDetails.pageViews} page views, or after delaying for
            ${frequencyDetails.surveyDelay} days`}
        </p>
        <p>
          <b>Who: </b>
          {audienceDetails.description}
        </p>
        <p>
          <b>Sampling: </b>
          {samplingDetails.value}% of users that visit your site per day
        </p>
        {meta.throttleEnabled && rule.throttleExempt && (
          <p>
            <b>Frequency Limit: </b>
            Override
          </p>
        )}
      </div>
    );
  };

  const renderCustomerConditionsSummary = clauses => {
    return (
      <div className="conditions-summary">
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: getConditionsFriendlyDescription(
              null,
              clauses,
              steps,
              segments,
              checklists
            ),
          }}
        />
      </div>
    );
  };

  const summary = detectConditionsTemplate(initial)
    ? renderNPSConditionsSummary(initial)
    : renderCustomerConditionsSummary(initial);

  return (
    <SatisfactionSurveyCard
      disableHover
      className="satisfaction-form-card"
      header="Audience"
    >
      <div className="satisfaction-card-top">
        <hr />
        {summary}
        <Button
          onClick={() => {
            updateSatisfaction({ currentStep: 2, isTestMode: false });
          }}
          className="button"
        >
          Edit
        </Button>
      </div>
    </SatisfactionSurveyCard>
  );
}
