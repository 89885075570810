import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  H1,
  Page,
  PageActions,
  PageBody,
  PageHeader,
  PageTitle,
} from '@studio/legacy-components';
import { Shape } from '@studio/conditions';
import { readPins } from 'next/entities/pins';
import { readRules } from 'next/entities/rules';
import { readTags, Shape as TagShape } from 'next/entities/tags';
import { Shape as CreatorShape } from 'next/entities/users';
import { Shape as SegmentShape, readSegments } from 'next/entities/segments';
import { Shape as PinShape } from 'next/entities/experiences';
import { Shape as ThemeShape, readThemes } from 'next/entities/themes';
import { asRequest } from 'next/entities/requests';
import { replace as replacePage } from 'next/entities/page';
import {
  readPinAssociationsForDomains,
  readCreators,
} from 'next/lib/selectors';
import { Loading } from 'next/components/Listing';
import useTitle from 'next/hooks/use-title';
import ExperiencesMoreMenu from 'next/components/ExperiencesMoreMenu';
import { HelpBar } from 'next/components/HelpBar';
import CreatePinManager from './CreatePinManager';
import PinsList from './PinsList';

/**
 * TODO: Consider consolidating with flow listing page when moving pin page
 *       components to the `/pages` directory
 */

export const PinsPage = ({
  creators = {},
  domainAssociations = {},
  onLoad,
  pins = {},
  segments = {},
  tags = {},
  rules = {},
  themes = {},
}) => {
  useTitle('Pins | Appcues');
  useEffect(() => {
    onLoad?.();
  }, [onLoad]);

  const { loading: loadingDomainAssociations = true } = domainAssociations;
  const { loading: loadingPins = true } = pins;
  const { loading: loadingTags = true } = tags;
  const { loading: loadingSegments = true } = segments;
  const { loading: loadingCreators = true } = creators;
  const { loading: loadingRules = true } = rules;
  const { loading: loadingThemes = true } = themes;

  const loading =
    loadingCreators ||
    loadingDomainAssociations ||
    loadingPins ||
    loadingTags ||
    loadingSegments ||
    loadingRules ||
    loadingThemes;

  return (
    <Page>
      <PageHeader>
        <PageTitle>
          <H1>Pins</H1>
        </PageTitle>

        <PageActions>
          <ExperiencesMoreMenu />
          <CreatePinManager />
        </PageActions>
      </PageHeader>

      <PageBody>
        <HelpBar section="pins" />
        {loading && <Loading />}

        {!loading && (
          <PinsList
            creators={creators.data}
            domainAssociations={domainAssociations.data}
            pins={pins.data}
            segments={segments.data}
            tags={tags.data}
          />
        )}
        {/* TODO: Handle error state */}
      </PageBody>
    </Page>
  );
};

PinsPage.propTypes = {
  domainAssociations: asRequest(
    PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
  ),
  pins: asRequest(PropTypes.objectOf(PinShape)),
  tags: asRequest(PropTypes.objectOf(TagShape)),
  segments: asRequest(PropTypes.objectOf(SegmentShape)),
  creators: asRequest(PropTypes.objectOf(CreatorShape)),
  rules: asRequest(PropTypes.objectOf(Shape)),
  themes: asRequest(PropTypes.objectOf(ThemeShape)),
  onLoad: PropTypes.func,
};

const mapStateToProps = state => ({
  domainAssociations: readPinAssociationsForDomains(state),
  rules: readRules(state),
  pins: readPins(state),
  tags: readTags(state),
  segments: readSegments(state),
  creators: readCreators(state),
  themes: readThemes(state),
});

const mapDispatchToProps = (dispatch, { match: { path } }) => ({
  onLoad: () => dispatch(replacePage({ path })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PinsPage);
