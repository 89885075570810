import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { withPanelStyle } from '@appcues/component-library';
import {
  NextArrow,
  PreviousArrow,
} from 'components/Common/Carousel/CarouselArrows';
import CarouselItems from 'components/Common/Carousel/CarouselItems';
import CarouselItem from 'components/Common/Carousel/CarouselItem';

class Carousel extends Component {
  state = {
    currentX: 0,
    containerWidth: 0,
  };

  containerRef = createRef();

  componentDidMount() {
    if (this.containerRef) {
      this.setState({ containerWidth: this.containerRef.current.offsetWidth });
    }
  }

  getTotalItemsWidth() {
    const { items, itemWidth, itemPadding } = this.props;
    const numOfItems = items.length;
    return numOfItems * (itemWidth + itemPadding);
  }

  getItems = () => {
    const { items } = this.props;
    return items;
  };

  translateItemsBackwards = () => {
    const { currentX } = this.state;
    const { itemWidth } = this.props;
    const nextX = currentX + itemWidth;

    if (nextX > 0) {
      return;
    }

    this.setState({
      currentX: nextX,
    });
  };

  translateItemsForward = () => {
    if (!this.reachedEnd()) {
      const { currentX } = this.state;
      const { itemWidth } = this.props;
      const nextX = currentX - itemWidth;

      this.setState({
        currentX: nextX,
      });
    }
  };

  showNextArrow = () => {
    const { containerWidth } = this.state;
    return !this.reachedEnd() && this.getTotalItemsWidth() > containerWidth;
  };

  reachedEnd = () => {
    const { currentX } = this.state;
    const { itemWidth } = this.props;

    const nextX = currentX - 2 * itemWidth;
    return Math.abs(nextX) + itemWidth > this.getTotalItemsWidth();
  };

  isAtStartPosition = () => {
    const { currentX } = this.state;
    return currentX === 0;
  };

  render() {
    const {
      itemWidth,
      itemPadding,
      className,
      background,
      height,
      whitespace,
    } = this.props;
    const { currentX } = this.state;

    return (
      <Container
        className={className}
        ref={this.containerRef}
        background={background}
        height={height}
      >
        <StyledCarousel whitespace={whitespace}>
          {!this.isAtStartPosition() && (
            <div className="arrowContainer leftArrow">
              <PreviousArrow
                currentXPosition={currentX}
                onClick={this.translateItemsBackwards}
              />
            </div>
          )}
          <CarouselItems
            totalItemsWidth={this.getTotalItemsWidth()}
            translateX={currentX}
            transitionLengthInMillis={500}
          >
            {this.getItems().map((itemElement, index) => {
              return (
                <CarouselItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  itemWidth={itemWidth}
                  itemPadding={itemPadding}
                >
                  {itemElement}
                </CarouselItem>
              );
            })}
          </CarouselItems>
          <div className="arrowContainer rightArrow">
            {this.showNextArrow() ? (
              <NextArrow onClick={this.translateItemsForward} />
            ) : null}
          </div>
        </StyledCarousel>
      </Container>
    );
  }
}

const DEFAULT_ITEM_WIDTH = 200;
const DEFAULT_ITEM_PADDING = 16;

Carousel.defaultProps = {
  itemWidth: DEFAULT_ITEM_WIDTH,
  itemPadding: DEFAULT_ITEM_PADDING,
  height: '250px',
};

const StyledCarousel = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: ${props => props.theme['$blue-gray-2']};

  display: flex;
  align-items: center;

  flex-wrap: nowrap;
  white-space: ${({ whitespace }) => whitespace || 'nowrap'}
  overflow: hidden;

  padding: 25px 0px 25px 0px;

  .arrowContainer {
    position: absolute;
    display: flex;
    align-items: center;
    width: 72px;
    height: 100%;
  }

  .leftArrow {
    left: 0;
    z-index: 1;
  }

  .rightArrow {
    right: 0;
  }
`;

const Container = withPanelStyle(styled.div`
  position: relative;
  height: ${props => props.height};
  width: 100%;
  background-color: ${props => props.theme[props.background]};
`);

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(Carousel);
