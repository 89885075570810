import React from 'react';
import styled from 'styled-components';

import { CPanel, CTextInput, CTextArea, H3 } from '@appcues/component-library';
import { SegmentMetadata } from 'components/SegmentMetadata';

const StyledTextArea = styled(CTextArea)`
  max-width: 640px;
`;

export default function SegmentMeta({
  segmentId,
  name,
  description,
  setName,
  setDescription,
  meta,
}) {
  const hasMeta = meta && Object.keys(meta).length > 0;

  return (
    <CPanel>
      <H3 marginBottom={24}>Segment details</H3>
      <CTextInput.Group label="Name">
        <CTextInput
          value={name}
          placeholder="Give your segment a name"
          onChange={value => setName(value)}
          maxWidth={320}
        />
      </CTextInput.Group>
      <CTextInput.Group
        label="Description"
        paddingBottom={0}
        marginBottom={hasMeta ? 24 : 0}
        maxWidth={640}
      >
        <StyledTextArea
          value={description}
          placeholder="Describe what this segment is for"
          onChange={value => setDescription(value)}
          rows={4}
        />
      </CTextInput.Group>
      {hasMeta && (
        <SegmentMetadata
          segmentId={segmentId}
          fileName={meta.originalFileName}
          uploadDate={meta.uploadDate}
          usersProcessed={meta.usersProcessed}
        />
      )}
    </CPanel>
  );
}
