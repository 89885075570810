import React from 'react';
import { Link } from '@studio/legacy-components';

const INSTALL_LINK = '/settings/installation';

export const emptyStatePropsMap = {
  events: {
    header: 'No events yet',
    iconName: 'users',
    className: 'no-events-msg',
    body: <Link to={INSTALL_LINK}>Learn how to send events.</Link>,
  },
  autoProperties: {
    header: 'No auto-properties yet',
    iconName: 'chart-bar',
    className: 'no-autoproperties-msg',
    body: <Link to={INSTALL_LINK}>Learn how to send auto-properties.</Link>,
  },
  customProperties: {
    header: 'No custom properties yet',
    iconName: 'chart-bar',
    className: 'no-customproperties-msg',
    body: <Link to={INSTALL_LINK}>Learn how to send custom properties.</Link>,
  },
};
