import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { CDropdownButton, CButton } from '@appcues/component-library';
import { Icon, IconWithinCButton } from '@studio/legacy-components';

import { navigate } from 'actions/routing';
import { showModal } from 'actions/currentModal';
import { DELETE_THEME_MODAL } from 'constants/globals';
import { cloneAccountTheme, setDefaultTheme } from 'actions/account/themes';
import Modal from 'components/Common/UI/Modal/Modal';

import FlowsUsingTheme from './Modal/FlowsUsingTheme';

const ThemeOptions = ({ theme, themeId, border, padding, hideEditOption }) => {
  const [flowsUsingThemeModal, setFlowsUsingThemeModal] = useState(false);
  const dispatch = useDispatch();

  const setThemeAsDefault = useCallback(() => {
    dispatch(setDefaultTheme(themeId));
  });
  const handleCloneTheme = useCallback(() => {
    dispatch(cloneAccountTheme(themeId));
  }, [dispatch, themeId]);

  const navigateToTheme = useCallback(() => {
    dispatch(navigate(`themes/${themeId}/edit`));
  }, [dispatch, themeId]);

  const handleDeleteTheme = useCallback(() => {
    dispatch(showModal(DELETE_THEME_MODAL, { themeId }));
  }, [dispatch, themeId]);

  const handleModalClose = useCallback(event => {
    event.stopPropagation();
    setFlowsUsingThemeModal(false);
  }, []);
  const handleModalOpen = useCallback(() => {
    setFlowsUsingThemeModal(true);
  }, []);

  const themeDropdownOptions = useMemo(() => {
    const defaultOption = {
      label: 'Set as default',
      value: 'setDefault',
      icon: 'asterisk',
      onClick: setThemeAsDefault,
    };

    const flowsUsingThemeOption = {
      label: 'Flows using this theme',
      value: 'flows',
      icon: 'map',
      onClick: handleModalOpen,
    };

    const editOption = {
      label: 'Edit',
      value: 'edit',
      icon: 'edit',
      onClick: navigateToTheme,
    };

    const cloneOption = {
      label: 'Clone',
      value: 'clone',
      icon: 'clone',
      onClick: handleCloneTheme,
    };

    const deleteOption = {
      label: 'Delete',
      value: 'delete',
      icon: 'trash-alt',
      onClick: handleDeleteTheme,
    };

    const options = [
      ...(theme.isDefault ? [] : [defaultOption]),
      flowsUsingThemeOption,
      ...(hideEditOption ? [] : [editOption]),
      cloneOption,
      ...(theme.isDefault ? [] : [deleteOption]),
    ];
    return options;
  }, [theme.isDefault, hideEditOption]);

  const options = themeDropdownOptions.map(option => {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div onClick={option.onClick} key={option.value}>
        <IconWithinCButton icon={option.icon} gray />
        {option.label}
      </div>
    );
  });

  return (
    <>
      {flowsUsingThemeModal && (
        <Modal onClose={handleModalClose} title="Flows Using This Theme">
          <Modal.Content>
            <FlowsUsingTheme themeId={themeId} />
          </Modal.Content>
          <Modal.Footer>
            <CButton type="secondary" onClick={handleModalClose}>
              Done
            </CButton>
          </Modal.Footer>
        </Modal>
      )}
      <StyledDropdownButton options={options} border={border} padding={padding}>
        <IconOpenMenu />
      </StyledDropdownButton>
    </>
  );
};

const StyledDropdownButton = styled(CDropdownButton)`
  > i {
    margin: 0px;
  }
`;

const IconOpenMenu = styled(Icon).attrs({
  icon: 'ellipsis-h',
  title: 'Open Menu',
})`
  color: var(--icon);
`;

ThemeOptions.propTypes = {
  theme: PropTypes.shape({
    isDefault: PropTypes.bool,
  }).isRequired,
  themeId: PropTypes.string.isRequired,
  border: PropTypes.string,
  padding: PropTypes.string,
  hideEditOption: PropTypes.bool,
};

export default ThemeOptions;
