import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import {
  Page,
  PageBody,
  Link,
  TabsNav,
  TabsList,
  TabItem,
} from '@studio/legacy-components';
import Activities from './Activities';
import Properties from './Properties';
import Segments from './Segments';
import Eligibility from './Eligibility';
import Header from './Header';

export const Profile = ({
  match: {
    url,
    path,
    params: { userId: encodedUserId },
  },
}) => {
  const userId = decodeURIComponent(encodedUserId);
  // use a fixed query end_time to prevent flooding the analytics-api
  const [endTime] = useState(Date.now());
  return (
    <Page>
      <Header userId={userId} />
      <TabsNav sticky>
        <TabsList>
          <Route path={`${path}/properties`}>
            {({ match }) => (
              <TabItem $active={match}>
                <Link to={`${url}/properties`}>Properties</Link>
              </TabItem>
            )}
          </Route>
          <Route path={`${path}/activities`}>
            {({ match }) => (
              <TabItem $active={match}>
                <Link to={`${url}/activities`}>Activities</Link>
              </TabItem>
            )}
          </Route>
          <Route path={`${path}/segments`}>
            {({ match }) => (
              <TabItem $active={match}>
                <Link to={`${url}/segments`}>Segments</Link>
              </TabItem>
            )}
          </Route>
          <Route path={`${path}/eligibility`}>
            {({ match }) => (
              <TabItem $active={match}>
                <Link to={`${url}/eligibility`}>Eligibility</Link>
              </TabItem>
            )}
          </Route>
        </TabsList>
      </TabsNav>
      <PageBody>
        <Switch>
          <Route
            path={`${path}/properties`}
            render={() => <Properties userId={userId} />}
          />
          <Route
            path={`${path}/activities`}
            render={() => <Activities userId={userId} endTime={endTime} />}
          />
          <Route
            path={`${path}/segments`}
            render={() => <Segments userId={encodedUserId} />}
          />
          <Route
            path={`${path}/eligibility`}
            render={() => <Eligibility userId={encodedUserId} />}
          />
          <Redirect to={`${path}/properties`} />
        </Switch>
      </PageBody>
    </Page>
  );
};

Profile.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
    }),
  }),
};

export default Profile;
