import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { CButton } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';
import ThreeDotsDropdownButton from 'components/Common/ThreeDotsDropdownButton';
import {
  determineFlowCompletedOnly,
  getItemAction,
} from 'helpers/checklist-item-presets';
import { EDIT_CHECKLIST_ITEM_MODAL } from 'constants/globals';
import { getActionDescription } from 'utils/checklist-actions';

export const ChecklistItem = forwardRef(
  (
    {
      checklistId,
      checklistItem,
      checklistItems,
      className,
      isSequential,
      removeItemFromChecklist,
      reorderChecklistItems,
      showModal,
      steps,
    },
    ref
  ) => {
    const moveItemUp = () => {
      const currentIndex = checklistItem.index;
      // don't do anything if first item
      if (currentIndex === 0) {
        return;
      }
      // update preceding item index to current and vice versa
      const precedingIndex = checklistItem.index - 1;
      const precedingItemId = Object.keys(checklistItems).find(
        id => checklistItems[id].index === precedingIndex
      );
      reorderChecklistItems(checklistId, checklistItem.id, precedingItemId);
    };

    const moveItemDown = () => {
      const currentIndex = checklistItem.index;
      // don't do anything if last item
      if (currentIndex === Object.keys(checklistItems).length - 1) {
        return;
      }
      // update succeeding item index to current and vice versa
      const succeedingIndex = checklistItem.index + 1;
      const succeedingItemId = Object.keys(checklistItems).find(
        id => checklistItems[id].index === succeedingIndex
      );
      reorderChecklistItems(checklistId, checklistItem.id, succeedingItemId);
    };

    const editChecklistItem = () => {
      showModal(EDIT_CHECKLIST_ITEM_MODAL, {
        checklistId,
        checklistItemId: checklistItem.id,
        steps,
      });
    };

    const handleSelect = dropdownValue => {
      if (dropdownValue === 'delete') {
        removeItemFromChecklist(checklistId, checklistItem.id, checklistItems);
      }
    };

    const numberLabel = checklistItem.index + 1;
    const ballClass = isSequential ? 'sequential' : '';
    // get item action description
    const pageAction = getItemAction(checklistItem, 'redirect');
    const flowAction = getItemAction(checklistItem, 'show_flow');
    const actionDescriptionText = getActionDescription(
      pageAction,
      flowAction,
      steps
    );
    // item completion criteria description
    const flowId =
      flowAction && flowAction.attributes && flowAction.attributes.params
        ? flowAction.attributes.params.flowId
        : null;
    const currentFlow = flowId && steps[flowId];
    const flowCompleted = determineFlowCompletedOnly(
      checklistItem,
      currentFlow,
      steps
    );
    // item dropdown options (just delete for now...)
    const itemOptions = [{ label: 'Delete', value: 'delete' }];
    const first = numberLabel === 1;
    const last = numberLabel === Object.keys(checklistItems).length;

    return (
      <div ref={ref} className={className}>
        <div className={`blue-ball ${ballClass}`}>
          <span className="number-label">{numberLabel}</span>
        </div>
        <div className="card-container" title="Checklist Item">
          <div className="item-description-container">
            <header>
              {checklistItem.label || '[Edit item to enter a name]'}
            </header>
            <main>{actionDescriptionText}</main>
            {flowCompleted && (
              <main>When the first step of the flow is completed</main>
            )}
            {!flowCompleted && (
              <main>Mark completed when the user meets certain criteria</main>
            )}
          </div>
          <div className="item-controls-container">
            <CButton onClick={editChecklistItem} className="edit-item">
              Edit
            </CButton>

            <ThreeDotsDropdownButton
              dropdownOptions={itemOptions}
              handleOnSelect={handleSelect}
            />
          </div>
        </div>
        <div className="arrow-container">
          {!first && (
            <ArrowUpIcon
              role="button"
              tabIndex={0}
              aria-label="Move item up"
              icon="arrow-up"
              onClick={moveItemUp}
            />
          )}
          {!last && (
            <Icon
              role="button"
              tabIndex={-1}
              aria-label="Move item down"
              icon="arrow-down"
              onClick={moveItemDown}
            />
          )}
        </div>
      </div>
    );
  }
);

const ArrowUpIcon = styled(Icon)`
  margin-bottom: 4px;
`;

export default styled(ChecklistItem)`
  display: flex;
  flex-direction: row;
  position: relative;

  &:hover {
    .arrow-container {
      opacity: 1;
      transition: opacity 0.15s ease-in;
    }
  }

  .blue-ball {
    display: flex;
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    border: 2px solid #00b2e5;
    z-index: 1;
    margin-right: 20px;
    transition: all 0.15s ease-in;

    .number-label {
      display: none;
    }

    &.sequential {
      background-color: #00b2e5;

      .number-label {
        color: white;
        display: flex;
        align-self: center;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .card-container {
    display: flex;
    background-color: #f4f4f4;
    padding: 14px 22px;
    border-radius: 4px;
    margin-bottom: 20px;
    width: 480px;
    flex-shrink: 0;
    border: 2px solid white;

    :before {
      content: '';
      position: absolute;
      border-left: 2px solid #e3e3e3;
      width: 1px;
      height: 63%;
      top: 40px;
      left: 17.5px;
      z-index: 0;
    }

    :before:last-child {
      height: 50%;
    }

    .item-description-container {
      margin-right: 14px;
      overflow: hidden;

      header {
        font-size: 18px;
        font-weight: 600;
        color: ${props => props.theme['gray-70']};
        margin-bottom: 8px;
      }

      main {
        color: #848484;

        & + main {
          margin-top: 4px;
        }
      }
    }

    hr {
      display: none;
    }

    svg {
      cursor: pointer;
    }

    .item-controls-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: auto;
    }

    .item-header-container {
      display: flex;
      color: grey;

      .item-header {
        font-size: 14px;
        letter-spacing: 0.05em;
      }

      i {
        font-size: 14px;
        margin-left: 16px;
      }
    }

    .item-header-container > div:first-child {
      margin-right: auto;
    }

    .item-header-container > div:last-child {
      margin-left: auto;
    }
  }

  .arrow-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 13px;
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: 20px;
    opacity: 0;

    &:hover {
      opacity: 1;
      transition: opacity 0.15s ease-in;
    }

    svg {
      color: #bbbbbb;

      &:hover {
        color: #848484;
      }
    }
  }
`;
