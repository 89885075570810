import React, { Component } from 'react';
import { Button } from '@appcues/component-library';
import {
  addClause,
  deleteClause,
  conditionNames,
  getFilteredClauseTree,
  propertyOperators,
  getConditionsFriendlyDescription,
} from '@appcues/libcues';
import styled from 'styled-components';
import { Icon, IconButton, Select } from '@studio/legacy-components';
import FieldGroup from 'components/Common/Forms/FieldGroup';
import SatisfactionSurveyCard from 'components/SatisfactionSurveys/SatisfactionSurveyCard';
import Tag, { TagTypes } from 'components/Common/Tag';
import InputTag from 'components/Common/InputTag';
import * as clauseTransforms from 'transforms/clauses';
import {
  stripUrlTargetingClauses,
  filterDomainTargetingClauses,
  getEmptyClauseForConditionName,
  clauseTreesAreEqual,
  stripEmptyClauses,
} from 'utils/conditions';
import {
  SATISFACTION_TARGETING_CATEGORY,
  SATISFACTION_TARGETING_OPTIONS,
} from 'constants/satisfaction/data';
import {
  advancedTargetingOption,
  getPageOptionFromClauses,
  getPageOptionFromOperator,
} from 'helpers/satisfaction';
import SatisfactionAdvancedUrlTargeting from './SatisfactionAdvancedUrlTargeting';

const { hasOwnProperty } = Object.prototype;

const Wrapper = styled.div`
  ${Select} {
    max-width: 100%;
  }
`;

const Domain = styled.span`
  margin-right: 6px;
`;

const IncludedDomainsList = styled.div`
  margin: 4px 0px;
  padding: 6px;

  ${Tag} {
    margin-bottom: 5px;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: var(--regular);
  }

  ${Icon} {
    cursor: pointer;
    font-size: var(--x-small);
    color: var(--white);
  }
`;

class SatisfactionPageSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clauses: props.clauses,
      pageOption: null,
      accountDomainsList: [],
      domainClauses: [],
      domainOptions: [],
    };

    this.addOrDeleteUrlClauses = this.addOrDeleteUrlClauses.bind(this);
    this.updateUrlClauses = this.updateUrlClauses.bind(this);
    this.deleteDomain = this.deleteDomain.bind(this);
    this.updateAndSaveSurvey = this.updateAndSaveSurvey.bind(this);
    this.deleteUrlClause = this.deleteUrlClause.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { meta, clauses } = this.props;
    this.populateClauseState(clauses);
    this.populateDomainState(meta, clauses);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { clauses, targetingOptionView } = this.props;

    const willPageViewOpen =
      targetingOptionView &&
      targetingOptionView !== SATISFACTION_TARGETING_CATEGORY.PAGE &&
      nextProps.targetingOptionView === SATISFACTION_TARGETING_CATEGORY.PAGE;

    if (willPageViewOpen) {
      window.scrollTo(0, 250);
    }

    if (nextProps.areConditionsFromTemplate) {
      const initialDomainClauses = getFilteredClauseTree(
        filterDomainTargetingClauses,
        clauses
      );

      const firebaseDomainTargetingClauses = getFilteredClauseTree(
        filterDomainTargetingClauses,
        nextProps.clauses
      );

      const domainTargetingClausesChanged = !clauseTreesAreEqual(
        initialDomainClauses,
        firebaseDomainTargetingClauses
      );

      if (domainTargetingClausesChanged) {
        this.setState({
          // eslint-disable-next-line react/no-unused-state
          domainTargetingClauses: firebaseDomainTargetingClauses,
        });
      }
    }
  }

  getPageOption(urlClauses) {
    const { pageOption } = this.state;
    if (pageOption) {
      return pageOption;
    }

    return getPageOptionFromClauses(urlClauses);
  }

  // The following two functions should more or less disappear after we add
  // clauses to Redux state
  populateClauseState(clauses, callback) {
    const pageOptionForClauses = this.getPageOption(clauses);
    this.setState({ clauses, pageOption: pageOptionForClauses }, () => {
      if (callback) callback();
    });
  }

  populateDomainState(meta, clauses) {
    const domainClauses = getFilteredClauseTree(
      filterDomainTargetingClauses,
      clauses
    ).filter(clause => {
      return (
        hasOwnProperty.call(clause, 'conditionName') &&
        clause.conditionName === conditionNames.DOMAINS
      );
    });

    const accountDomainsList =
      meta && hasOwnProperty.call(meta, 'domains') ? meta.domains : [];

    const includedDomains = domainClauses
      ? domainClauses.map(clause => {
          return clause.value;
        })
      : [];

    const domainOptions = accountDomainsList
      .filter(domain => !includedDomains.includes(domain))
      .map(domain => {
        return {
          label: domain,
          value: domain,
        };
      });

    this.setState({
      accountDomainsList,
      domainClauses,
      domainOptions,
    });
  }

  updateOperator(operator) {
    const { clauses, pageOption } = this.state;

    const oldPageOption = pageOption;

    const pageOptionForOperator = getPageOptionFromOperator(operator);
    this.setState({ pageOption: pageOptionForOperator }, () => {
      let newClauses = [];
      if (operator === propertyOperators.REGEX) {
        // Handle "every page" targeting. This gets rid of
        // all URL targeting clauses and adds a regex
        // clause to match all URL values.
        const allPagesClause = {
          conditionName: conditionNames.URL,
          operator,
          value: '.*',
        };
        newClauses.push(allPagesClause);
        this.replaceUrlClauses(newClauses);
        return;
      }

      const urlClauses = clauseTransforms.getUrlClauses(clauses, false);
      const wasAllPages = oldPageOption.operator === propertyOperators.REGEX;
      const containsEmptyClauses =
        stripEmptyClauses(urlClauses).length !== urlClauses.length;

      if (oldPageOption.advancedTargeting && containsEmptyClauses) {
        this.replaceUrlClauses(
          stripEmptyClauses(urlClauses),
          pageOptionForOperator
        );
      }

      if (wasAllPages) {
        this.replaceUrlClauses([], pageOptionForOperator);
      } else {
        // Handle specific URL page targeting. If there
        // are existing URL targeting clauses, we must
        // switch the operator.
        newClauses = stripEmptyClauses(urlClauses)
          .map(clause => {
            return {
              ...clause,
              ...(clause && clause.value === '.*' ? { value: '' } : {}),
              operator: operator || clause.operator,
            };
          })
          .filter(Boolean); // This filters out nulls

        if (!pageOptionForOperator.advancedTargeting && !containsEmptyClauses) {
          // Switch clauses if necessary
          const existingParent = clauseTransforms.getParentUrlClause(clauses);

          if (existingParent) {
            existingParent.value = pageOptionForOperator.conditionType;
            newClauses.push(existingParent);
          }
        }
      }

      if (
        pageOptionForOperator.advancedTargeting &&
        !containsEmptyClauses &&
        newClauses.length === 0
      ) {
        // Make sure to always populate one clause for Advanced Targeting
        const emptyCondition = getEmptyClauseForConditionName(
          conditionNames.URL
        );

        newClauses = [emptyCondition];
      }

      if (newClauses && newClauses.length > 0) {
        this.updateUrlClauses(newClauses);
      }
    });
  }

  addOrDeleteUrlClauses(updatedClauseList) {
    const { clauses, pageOption } = this.state;

    const urlClausesWithoutParent = clauseTransforms.getUrlClauses(
      clauses,
      false
    );

    const deletedClause = urlClausesWithoutParent.find(
      clause => !updatedClauseList.includes(clause)
    );

    const addedClause = updatedClauseList.find(
      clause => !urlClausesWithoutParent.includes(clause)
    );

    if (deletedClause) {
      this.deleteUrlClause(deletedClause.id);
    } else if (addedClause) {
      if (!addedClause.operator) {
        addedClause.operator = pageOption.operator;
      }
      if (!addedClause.conditionName) {
        addedClause.conditionName = conditionNames.URL;
      }
      this.updateUrlClauses([addedClause]);
    }
  }

  deleteUrlClause(deletedClauseId) {
    const { clauses } = this.state;

    const newClauses = deleteClause(clauses, deletedClauseId);

    this.populateClauseState(newClauses, () => {
      this.updateAndSaveSurvey();
    });
  }

  replaceUrlClauses(replacementClauses, pageOptionForOperator) {
    const { clauses } = this.state;

    let newClauses = getFilteredClauseTree(stripUrlTargetingClauses, clauses);

    // Swap the operator if need be
    let existingParent = clauseTransforms.getParentUrlClause(clauses);
    if (existingParent) {
      newClauses = deleteClause(clauses, existingParent.id);
    }

    replacementClauses.forEach(replacementClause => {
      newClauses = addClause(newClauses, null, replacementClause);
    });

    existingParent = clauseTransforms.getParentUrlClause(newClauses);
    if (existingParent && pageOptionForOperator) {
      existingParent.value = pageOptionForOperator.conditionType;
    }

    this.populateClauseState(newClauses, () => {
      this.updateAndSaveSurvey();
    });
  }

  updateUrlClauses(updatedClauses, saveSurvey = true) {
    const { clauses, pageOption } = this.state;

    let parentUrlClause = clauseTransforms.getParentUrlClause(clauses);

    let newClauses = [...clauses];

    if (!updatedClauses || updatedClauses.length === 0) {
      return;
    }

    updatedClauses.forEach(updatedClause => {
      const existingClause = clauses.find(
        clause => clause.id === updatedClause.id
      );

      if (existingClause) {
        newClauses = clauseTransforms.updateClause(
          newClauses,
          existingClause.id,
          updatedClause
        );
      } else {
        newClauses = addClause(
          newClauses,
          parentUrlClause ? parentUrlClause.id : null,
          updatedClause
        );

        if (!parentUrlClause) {
          parentUrlClause = clauseTransforms.getParentUrlClause(newClauses);
          parentUrlClause.value = pageOption.conditionType;
        }
      }
    });

    this.populateClauseState(newClauses, () => {
      if (saveSurvey) {
        this.updateAndSaveSurvey();
      }
    });
  }

  updateAndSaveSurvey() {
    const { updateSatisfaction, saveSatisfaction } = this.props;
    const { clauses } = this.state;

    const urlClauses = clauseTransforms.getUrlClauses(clauses, false);

    // Valudate all the clauses to make sure they're not empty
    const clausesAreValid = urlClauses.every(clause => {
      return clause.operator && clause.value;
    });

    if (!clausesAreValid) {
      return;
    }

    updateSatisfaction({ clauses });
    saveSatisfaction(clauses);
  }

  addDomain(domain) {
    const { meta } = this.props;
    const { clauses } = this.state;

    const newDomainClause = {
      conditionName: conditionNames.DOMAINS,
      operator: propertyOperators.EQUALS,
      value: domain,
    };

    const updatedClauses = addClause(clauses, null, newDomainClause);

    this.setState({ clauses: updatedClauses }, () => {
      this.populateDomainState(meta, updatedClauses);
      this.updateAndSaveSurvey();
    });
  }

  deleteDomain(clauseId) {
    const { meta } = this.props;
    const { clauses } = this.state;

    const updatedClauses = deleteClause(clauses, clauseId);

    this.setState({ clauses: updatedClauses }, () => {
      this.populateDomainState(meta, updatedClauses);
      this.updateAndSaveSurvey();
    });
  }

  render() {
    const { className, targetingOptionView, setTargetingOptionView, rule } =
      this.props;

    const {
      clauses,
      accountDomainsList,
      domainClauses,
      domainOptions,
      pageOption,
    } = this.state;

    const urlClausesWithParent = clauseTransforms.getUrlClauses(clauses);
    const urlClausesWithoutParent = clauseTransforms.getUrlClauses(
      clauses,
      false
    );
    const pageUrls = urlClausesWithoutParent.map(clause => clause.value);
    const { pageTargetingEnabled } = pageOption;
    const advancedTargeting =
      pageOption && pageOption.value === advancedTargetingOption.value;

    const options =
      SATISFACTION_TARGETING_OPTIONS[SATISFACTION_TARGETING_CATEGORY.PAGE]
        .URL_OPTIONS;

    return (
      <Wrapper>
        <FieldGroup
          className={`${className} satisfaction-form-block page-settings`}
        >
          <SatisfactionSurveyCard
            disableHover
            className="satisfaction-form-card"
            header="Where will the survey appear:"
          >
            <div className="satisfaction-card-top">
              {targetingOptionView ===
              SATISFACTION_TARGETING_CATEGORY.PAGE ? null : (
                <div className="satisfaction-card-description">
                  <p>{`${pageOption.description}${
                    domainClauses.length > 0
                      ? ' on specific domains'
                      : ' on all installed domains'
                  }`}</p>
                  {pageTargetingEnabled && (
                    <p
                      className="pages-urls-description"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: getConditionsFriendlyDescription(
                          null,
                          urlClausesWithParent,
                          null,
                          null,
                          null,
                          true
                        ),
                      }}
                    />
                  )}
                </div>
              )}
              <Button
                onClick={() =>
                  setTargetingOptionView(SATISFACTION_TARGETING_CATEGORY.PAGE)
                }
                className="button-primary"
              >
                {targetingOptionView === SATISFACTION_TARGETING_CATEGORY.PAGE
                  ? 'Done'
                  : 'Edit'}
              </Button>
            </div>

            {targetingOptionView === SATISFACTION_TARGETING_CATEGORY.PAGE && (
              <div className="satisfaction-form-card-bottom">
                <hr />
                <p className="settings-label">
                  On what pages should the survey appear?
                </p>
                <Select
                  onChange={option => this.updateOperator(option.operator)}
                  options={options}
                  value={options.find(
                    ({ value }) => value === pageOption.value
                  )}
                />
                <div className="satisfaction-card-setting page-settings">
                  <p className="settings-label">
                    {pageOption.settingsLabelText}
                  </p>
                  {pageTargetingEnabled && !advancedTargeting && (
                    <div>
                      <InputTag
                        tagList={urlClausesWithoutParent}
                        displayProperty="value"
                        placeholder={
                          pageUrls && pageUrls.length > 0
                            ? '/admin'
                            : 'ex. /admin   Type and Press Enter to add more'
                        }
                        onUpdateInputTagList={this.addOrDeleteUrlClauses}
                      />
                    </div>
                  )}
                  {pageTargetingEnabled && advancedTargeting && (
                    <div className="url-targeting">
                      <SatisfactionAdvancedUrlTargeting
                        clauses={urlClausesWithParent}
                        rule={rule}
                        updateUrlClauses={this.updateUrlClauses}
                        deleteUrlClause={this.deleteUrlClause}
                      />
                    </div>
                  )}
                </div>
                <div className="satisfaction-card-setting domain-settings">
                  <p className="settings-label domain-settings">
                    Which domains should we include?
                  </p>
                  {domainClauses.length === 0 && (
                    <p>
                      <i>
                        {`\u2004\u2004All domains with the Appcues SDK installed`}
                      </i>
                    </p>
                  )}
                  {domainClauses.length > 0 && (
                    <IncludedDomainsList>
                      {domainClauses.map(clause => {
                        return (
                          <Tag key={clause.id} type={TagTypes.BLUE_1}>
                            <Domain>{clause.value}</Domain>

                            <IconButton
                              label="Delete domain"
                              onClick={() => {
                                this.deleteDomain(clause.id);
                              }}
                              icon="times"
                            />
                          </Tag>
                        );
                      })}
                    </IncludedDomainsList>
                  )}
                  {accountDomainsList.length > 0 && (
                    <div>
                      <p className="settings-label include-domains">
                        Choose a domain to include:
                      </p>
                      <Select
                        options={domainOptions}
                        onChange={option => {
                          this.addDomain(option.value);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </SatisfactionSurveyCard>
        </FieldGroup>
      </Wrapper>
    );
  }
}

export default SatisfactionPageSettings;
