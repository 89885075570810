import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { array, object, string } from 'yup';
import isEmpty from 'lodash.isempty';

import {
  Button,
  ButtonGroup,
  Heading,
  Modal,
  ModalFooter,
  NegativeNotice,
  Overflow,
  Form,
} from '@studio/legacy-components';
import { FormikShape } from 'next/lib/shapes';

import {
  BuildUrlField,
  NameField,
  ThemesField,
  TagsField,
} from 'next/components/CreateExperienceForms';

const VALIDATION_SCHEMA = object().shape({
  name: string().required('Name is required'),
  previewUrl: string()
    .required('Build URL is required')
    .url('Must be a valid URL'),
  theme: string().required('Theme is required'),
  tagIds: array().of(string()),
});

const createModalSchema = omitTheme => {
  if (omitTheme) {
    const { theme: omit, ...formFields } = VALIDATION_SCHEMA.fields;
    return object().shape(formFields);
  }

  return VALIDATION_SCHEMA;
};

export const CreateExperienceModal = ({
  errors = {},
  handleBlur: onBlur,
  handleChange: onChange,
  onClose,
  onSubmit,
  setFieldValue,
  setFieldTouched,
  touched = {},
  values,
  visible,
  disabled,
  hasError,
  title,
  omitTheme = false,
}) => {
  const isValid = !isEmpty(touched) && isEmpty(errors);

  const handleSubmit = event => {
    event.preventDefault();
    return onSubmit(values);
  };

  return (
    <Modal size="m" visible={visible} onClose={onClose}>
      <Overflow>
        <Heading>{title}</Heading>
        {hasError && (
          <NegativeNotice>
            We're sorry, something went wrong on our end. Try again.
          </NegativeNotice>
        )}
        <Form onSubmit={handleSubmit}>
          <NameField
            error={errors.name}
            onBlur={onBlur}
            onChange={onChange}
            touched={touched.name}
          />

          <BuildUrlField
            error={errors.previewUrl}
            onBlur={onBlur}
            onChange={onChange}
            touched={touched.previewUrl}
          />

          {!omitTheme && (
            <ThemesField
              value={values.theme}
              onChange={({ value: theme }) => {
                setFieldValue('theme', theme);
                setFieldTouched('theme');
              }}
            />
          )}

          <TagsField
            value={values.tagIds}
            onChange={tags => {
              setFieldValue('tagIds', tags);
              setFieldTouched('tagIds');
            }}
          />
          <ModalFooter>
            <ButtonGroup right>
              <Button
                kind="primary"
                type="submit"
                disabled={!isValid || disabled}
              >
                Create
              </Button>
              <Button kind="secondary" onClick={onClose}>
                Cancel
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </Overflow>
    </Modal>
  );
};

const formShape = PropTypes.shape({
  name: PropTypes.string,
  previewUrl: PropTypes.string,
  theme: PropTypes.string,
  tagIds: PropTypes.arrayOf(PropTypes.string),
});

CreateExperienceModal.propTypes = {
  ...FormikShape,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  visible: PropTypes.bool,
  values: formShape,
  hasError: PropTypes.bool,
  omitTheme: PropTypes.bool,
  title: PropTypes.string,
};

const enhanceForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ defaultTheme }) => {
    return {
      name: '',
      previewUrl: '',
      theme: defaultTheme ? defaultTheme.id : '',
      tagIds: [],
    };
  },
  validationSchema: ({ omitTheme }) => createModalSchema(omitTheme),
});

export default enhanceForm(CreateExperienceModal);
