// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import {
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  differenceInHours,
  differenceInMinutes,
} from 'date-fns';

/**
 * Public interface to date related operations
 *
 * NOTE: While these methods may use e.g. moment internally, inputs and outputs
 *       should be agnostic of any specific date library and should return Date
 *       objects when possible.
 */

// Suppress moment deprecation warnings
moment.suppressDeprecationWarnings = true;

/**
 * Format date to timeago or relative time display
 *
 * @param {(Date|number|string)} date - Date-like value
 * @param {boolean} omitSuffix - should omit suffix
 * @return {string} Formatted date
 */
export const fromNow = (date, omitSuffix = false) => {
  return moment(date).fromNow(omitSuffix);
};

/**
 * Get the number of full day periods between two dates.
 */
export const diffInDays = (dateLeft, dateRight) => {
  return differenceInDays(dateLeft, dateRight);
};

/**
 * Get the number of full months periods between two dates.
 */
export const diffInMonths = (dateLeft, dateRight) => {
  return differenceInMonths(dateLeft, dateRight);
};

/**
 * Get the number of full years periods between two dates.
 */
export const diffInYears = (dateLeft, dateRight) => {
  return differenceInYears(dateLeft, dateRight);
};

function formatRelative(
  yearsDifference,
  monthsDifference,
  daysDifference,
  hoursDifference,
  minutesDifference
) {
  if (yearsDifference > 0) {
    return `${yearsDifference} ${yearsDifference === 1 ? 'year' : 'years'} ago`;
  }

  if (monthsDifference > 0) {
    return `${monthsDifference} ${
      monthsDifference === 1 ? 'month' : 'months'
    } ago`;
  }

  if (daysDifference > 0) {
    return `${daysDifference} ${daysDifference === 1 ? 'day' : 'days'} ago`;
  }

  if (hoursDifference > 0) {
    return `${hoursDifference} ${hoursDifference === 1 ? 'hour' : 'hours'} ago`;
  }

  if (minutesDifference < 1) {
    return 'Just now';
  }
  return `${minutesDifference} ${
    minutesDifference === 1 ? 'minute' : 'minutes'
  } ago`;
}

export const diffRelative = (dateLeft, dateRight) => {
  const years = diffInYears(dateLeft, dateRight);
  const months = diffInMonths(dateLeft, dateRight);
  const days = diffInDays(dateLeft, dateRight);
  const hours = differenceInHours(dateLeft, dateRight);
  const minutes = differenceInMinutes(dateLeft, dateRight);

  return formatRelative(years, months, days, hours, minutes);
};

export const conditionalRelativeTim = date => {
  return differenceInMonths(Date.now(), date) < 1
    ? diffRelative(Date.now(), date)
    : moment(date).format('lll');
};

/**
 * Calendar time displays time relative to a given referenceDay (defaults to the start of today), but does so slightly differently than fromNow
 *
 * @param {(Date|number|string)} date - Date-like value
 */
export const calendar = date => {
  return moment(date).calendar();
};

/**
 * Format date
 *
 * @param {(Date|number|string)} date - Date-like value
 * @param {string} tokens - Formatting tokens
 * @return {string} Formatted date
 */
export const format = (date, tokens) => {
  return moment(date).format(tokens);
};

/**
 * Determine if date is after another
 *
 * @param {(Date|number|string)} date - Date-like value
 * @param {(Date|number|string)} compare - Date to determine if after
 * @return {boolean} Whether date is after comparison
 */
export const isAfter = (date, compare) => {
  return moment(date).isAfter(moment(compare));
};
/**
 * Determine if date is before another
 *
 * @param {(Date|number|string)} date - Date-like value
 * @param {(Date|number|string)} compare - Date to determine if before
 * @return {boolean} Whether date is before comparison
 */
export const isBefore = (date, compare) => {
  return moment(date).isBefore(moment(compare));
};

/**
 * Return the start of unit
 *
 * @param {(Date|number|string)} date - Date-like value
 * @param {string} unit - Date unit
 * @return {Date} Start of day for date
 */
export const startOf = (date, unit) => {
  return moment(date).startOf(unit).toDate();
};

/**
 * Return the end of unit
 *
 * @param {(Date|number|string)} date - Date-like value
 * @param {string} unit - Date unit
 * @return {Date} End of day for date
 */
export const endOf = (date, unit) => {
  return moment(date).endOf(unit).toDate();
};

/**
 * Add number of date units
 *
 * @param {(Date|number|string)} date - Date-like value
 * @param {number} value - Number of units to add
 * @param {string} unit - Date unit
 * @return {Date} Date with added units
 */
export const add = (date, value, unit) => {
  return moment(date).add(value, unit).toDate();
};

/**
 * Subtract number of date units
 *
 * @param {(Date|number|string)} date - Date-like value
 * @param {number} value - Number of units to subtract
 * @param {string} unit - Date unit
 * @return {Date} Date with added units
 */
export const subtract = (date, value, unit) => {
  return moment(date).subtract(value, unit).toDate();
};

/**
 * Get difference between two dates by unit
 *
 * @param {(Date|number|string)} start - Date-like value
 * @param {(Date|number|string)} end - Date-like value
 * @param {string} unit - Date unit
 * @return {Date} Date with added units
 */
export const diff = (start, end, unit) => {
  return moment(end).diff(moment(start), unit, true);
};

/**
 * Attempt to parse string into date
 *
 * @param {string} value - Date-like string
 * @return {Date|null} Date if value was parseable
 */
export const parse = value => {
  const date = moment(value);
  return date.isValid(date) ? date.toDate() : null;
};

/**
 * convert date to utc
 *
 * @param {(Date|number|string)} date - Date-like value
 */
export const utc = date => {
  return moment(date).utc();
};

/**
 * Determine if date is equal another
 *
 * @param {(Date|number|string)} date - Date-like value
 * @param {(Date|number|string)} compare - Date to determine if after
 * @return {boolean} Whether date is after comparison
 */
export const isEqual = (date, compare) => {
  return moment(date).isSame(moment(compare));
};
