import { getContext, call } from 'redux-saga/effects';
import { takeEveryPromised } from 'next/lib/effects';
import { patterns } from './actions';

function* fetchSuggestions({
  payload: {
    id: { type, attributeName, term },
  },
}) {
  try {
    const api = yield getContext('api');

    const {
      data: { suggestions },
    } = yield call(api.getSuggestions, type, attributeName, term);

    return suggestions;
  } catch {
    return [];
  }
}

export default function* saga() {
  yield takeEveryPromised(patterns.readOne, fetchSuggestions);
}
