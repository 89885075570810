import styled from 'styled-components';

import {
  Label,
  H2,
  H4,
  Button,
  Text,
  PageTitle,
  Panel,
  Notice,
  Icon,
} from '@studio/legacy-components';

export const BackArrow = styled(Icon)`
  color: var(--blurple);
  margin-right: 20px;

  &:hover {
    cursor: pointer;
  }
`;

export const CustomPageTitle = styled(PageTitle)`
  flex-direction: row;
`;

export const StyledHeading = styled(H2)`
  margin-bottom: 8px;
`;

export const StyledText = styled(Text)`
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : '10px')};
`;

export const CheckoutLayout = styled.section`
  display: grid;
  grid-template-columns: minmax(auto, 600px) 358px;
  grid-gap: 24px;
  justify-content: center;
  padding: 16px 83px 0px 61px;
`;

export const CheckoutLabel = styled(Label)`
  box-sizing: border-box;
  justify-content: start;
  max-width: 400px;
  min-width: 256px;
  font-weight: var(--bold);
`;

export const StyledPanel = styled(Panel)`
  padding: 23px;
  position: relative;
  overflow: inherit;

  ${CheckoutLabel} {
    margin-bottom: 16px;
  }

  ${CheckoutLabel},${CheckoutLabel}:last-of-type {
    margin-top: 0;
  }
`;

export const PaymentSectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TwoColumnWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  max-width: 400px;
  min-width: 256px;

  ${CheckoutLabel} {
    justify-content: start;
    min-width: initial;
  }
`;

export const DueTodayText = styled(H4)`
  font-weight: var(--extra-bold);
`;

export const IntervalInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${({ spaced }) => (spaced ? '14px' : '0px')};
`;

export const CheckoutRow = styled.div`
  border-bottom: ${({ borderless }) =>
    borderless ? 'none' : '1px solid rgba(0, 0, 0, 0.1)'};
  display: flex;
  min-height: 51px;
  padding-top: 12px;
  padding-bottom: 14px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${({ layout }) => (layout === 'vertical' ? 'column' : 'row')};

  &:first-child {
    padding-top: 22px;
  }

  ${DueTodayText} {
    margin-bottom: 0;
  }

  ${Notice} {
    padding: 7.5px 16px;
  }
`;

export const PayButton = styled(Button)`
  margin-top: 24px;
  width: 100%;
`;

export const PoweredByText = styled(Text)`
  font-size: var(--x-small);
`;

export const LockIcon = styled(Icon)`
  color: var(--dark-resting);
  margin-right: 7px;
`;

export const StripeIcon = styled(Icon)`
  color: var(--dirty-water);
`;

export const PoweredByStripeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 27px;
  gap: 5px;

  ${LockIcon} {
    width: 11.67px;
    height: 13.33px;
  }

  ${StripeIcon} {
    font-size: 33px;
  }
`;

export const LoadingSpinner = styled.img`
  animation: spinner 0.6s linear infinite;

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const HorizontalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ spaced }) => (spaced ? '6px' : '0px')};
  margin-bottom: ${({ spaced }) => (spaced ? '9px' : '0px')};
  min-height: ${({ spaced }) => (spaced ? '21px' : '0px')};

  ${Text} {
    color: var(--dark-resting);
  }
`;

export const TadaIcon = styled.div`
  margin-right: 3px;
`;

export const InformativeLabelText = styled.span`
  ${Icon} {
    color: var(--dark-resting);
  }
`;

export const NextInvoiceInfo = styled.div`
  > ${Text} {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

// Payment Details
export const BillingSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BillingDetailsImageWrapper = styled.div`
  width: 30px;
  text-align: center;
`;

export const BillingDetailsWrapper = styled.div`
  display: flex;
  gap: 19px;
  margin-top: 24px;
`;

export const CardTitle = styled(Text)`
  text-transform: capitalize;
`;

export const StyledIcon = styled(Icon)`
  color: var(--sharkbait-ooh-la-la);
  font-size: 2em;
  width: 40px;
`;

export const EditButton = styled(Button)`
  position: absolute;
  right: 24px;
  top: 24px;
`;
