import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text, Flex } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';

const StatusIcon = styled(Icon)`
  color: ${props => props.theme[props.color]};
  margin-left: 8px;
  font-size: var(--x-small);
`;

const DomainItem = ({ isInstalled, metaDomain, noCheckBox = false }) => {
  const displayedWithCheckBox = !noCheckBox;
  const altText = isInstalled
    ? 'icon indicating domain is installed'
    : 'icon indicating domain is not installed';
  const iconColor = isInstalled ? '$green' : '$gray-3';
  const textColor = isInstalled ? 'inherit' : '$gray-3';

  return (
    <Flex alignItems="center">
      {displayedWithCheckBox && (
        <Text color={textColor} isInstalled={isInstalled}>
          {metaDomain}
        </Text>
      )}
      <StatusIcon icon="circle" title={altText} color={iconColor} />
      {noCheckBox && (
        <Text marginLeft="0.5em" color={textColor} isInstalled={isInstalled}>
          {metaDomain}
        </Text>
      )}
    </Flex>
  );
};

DomainItem.propTypes = {
  isInstalled: PropTypes.bool.isRequired,
  metaDomain: PropTypes.string.isRequired,
  noCheckBox: PropTypes.bool,
};

export default DomainItem;
