import { put, call, getContext, takeEvery } from 'redux-saga/effects';
import { reportError } from 'helpers/error-reporting';
import { send, resolve, reject, patterns } from './actions';

export const transform = response =>
  response.reduce((acc, { experience }) => {
    acc[experience.id] = experience;
    return acc;
  }, {});

export function* fetchExperiences() {
  try {
    const api = yield getContext('api');
    yield put(send());
    const { data: experiences } = yield call(api.getAllExperiences);
    yield put(resolve(transform(experiences)));
  } catch (error) {
    yield call(reportError, error);
    yield put(reject(error));
  }
}

export default function* saga() {
  yield takeEvery(patterns.callApi, fetchExperiences);
}
