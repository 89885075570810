import { selectAccountChecklist } from 'entities/checklists';
import { selectAccountSatisfactionSurvey } from 'selectors/satisfaction';
import { selectFlow } from 'reducers/account/flows';

export const getCurrentContent = (state, contentId) => {
  let currentContent;
  if (selectAccountChecklist(state, contentId)) {
    currentContent = selectAccountChecklist(state, contentId);
  } else if (selectAccountSatisfactionSurvey(state, contentId)) {
    currentContent = selectAccountSatisfactionSurvey(state, contentId);
  } else if (selectFlow(state, contentId)) {
    currentContent = selectFlow(state, contentId);
  } else {
    currentContent = null;
  }

  return currentContent;
};
