import { getContext, call, put } from 'redux-saga/effects';
import { takeLeadingByProp } from 'effects/takeLeadingByProp';

import { replace, patterns } from 'actions/account/versionedFlows';
import { reportError } from 'helpers/error-reporting';

export function* getVersionedFlow({ payload: { flowId, versionId } }) {
  try {
    const { getFlowVersionById } = yield getContext('api');
    const {
      data: { flow },
    } = yield call(getFlowVersionById, flowId, versionId);
    yield put(replace({ ...flow, id: `${flowId}+${versionId}` }));
  } catch (error) {
    yield call(reportError, error);
  }
}

export default function* versionedFlows() {
  yield takeLeadingByProp(
    ({ flowId, versionId }) => `${flowId}+${versionId}`,
    patterns.callApi,
    getVersionedFlow
  );
}
