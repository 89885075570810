import { ConditionsShape } from '@studio/conditions';
import PropTypes from 'prop-types';

export default PropTypes.objectOf(
  PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    conditions: ConditionsShape,
  })
);
