import PropTypes from 'prop-types';
import pick from 'lodash.pick';

/**
 * Full shape of segment data model
 */
const Shape = {
  // FIXME: Inherit full shape from rule builder definition
  conditions: PropTypes.shape(PropTypes.object),
  createdAt: PropTypes.number,
  createdBy: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  meta: PropTypes.shape({
    userLifecycleStage: PropTypes.oneOf([
      'beginners',
      'champions',
      'explorers',
      'regulars',
    ]),
  }),
  name: PropTypes.string,
  updatedAt: PropTypes.number,
  updatedBy: PropTypes.string,
};

/**
 * Allowed fields for storage
 */
export const allowed = ['id', 'name'];

/**
 * Segment parsing function
 *
 * @param {Object<keyof Segment>} segment - Object with segment fields
 * @return {Object<keyof Segment>} Object with parsed segment fields
 */
export const parse = segment => pick(segment, allowed);

export default PropTypes.shape(parse(Shape));
