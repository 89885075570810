import { createLifecycleFor } from 'actions/account/collections';

const TYPE = 'flows';

export const actionTypes = {
  FLOW_ARCHIVED: 'FLOW_ARCHIVED',
  FLOW_CLONED: 'FLOW_CLONED',
  FLOW_UNARCHIVED: 'FLOW_UNARCHIVED',
  FLOW_DELETED: 'FLOW_DELETED',
};

const actions = createLifecycleFor(TYPE);

export const {
  send,
  resolve,
  reject,
  create,
  update,
  replace,
  flush,
  patterns,
} = actions;

/**
 * We need a way to trigger a refetch when we archive/delete/etc, but we don't want too
 * much of that logic being spread around. This action creator is for `sagas/views` so that
 * it doesn't know too much about what it's doing with Flows.
 */
export function flowActionFor(operation) {
  switch (operation) {
    case 'clone':
      return { type: actionTypes.FLOW_CLONED };
    case 'archive':
      return { type: actionTypes.FLOW_ARCHIVED };
    case 'unarchive':
      return { type: actionTypes.FLOW_UNARCHIVED };
    case 'delete':
      return { type: actionTypes.FLOW_DELETED };
    default:
      return null;
  }
}

export const PRODUCT_LED_FLOW_CREATED = 'PRODUCT_LED_FLOW_CREATED';
export const createProductLedFlow = ({
  userLifecycleStage,
  previewUrl,
  name,
}) => ({
  type: PRODUCT_LED_FLOW_CREATED,
  payload: {
    userLifecycleStage,
    previewUrl,
    name,
  },
});

export const TAG_ADDED_TO_FLOW = 'TAG_ADDED_TO_FLOW';
export const addTagToFlow = ({ flowId, tagId }) => ({
  type: TAG_ADDED_TO_FLOW,
  payload: { flowId, tagId },
});

export const TAG_REMOVED_FROM_FLOW = 'TAG_REMOVED_FROM_FLOW';
export const removeTagFromFlow = ({ flowId, tagId }) => ({
  type: TAG_REMOVED_FROM_FLOW,
  payload: { flowId, tagId },
});
