import styled from 'styled-components';

const HorizontalRule = styled.hr`
  border: none;
  background-color: ${props => props.theme['$gray-2']};
  height: 1px;
  margin: 1rem 0;
  width: ${props => props.width || 'inherit'};
`;

export default HorizontalRule;
