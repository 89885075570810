import styled from 'styled-components';
import {
  ButtonGroup,
  Checkbox,
  Description,
  ExternalLink,
  Heading,
  Icon,
  Input,
  Label,
  MenuList,
  NegativeNotice,
  Panel,
  Paragraph,
  Select,
} from '@studio/legacy-components';
import Loader from 'components/Common/Loader';

export const TableLoader = styled(Loader)`
  height: 250px;
`;

export const Wrapper = styled.section`
  ${Panel} {
    min-width: 960px;
    overflow-x: hidden;
    padding: 0;
  }
`;

export const ApiDescription = styled(Paragraph)`
  color: var(--heading-primary);
  margin-bottom: 16px;
`;

export const HeaderWrapper = styled.div`
  align-items: center;
  border-bottom: 1px solid var(--table-border);
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
`;

export const ControlWrapper = styled.div`
  margin-bottom: 16px;
`;

export const CreateForm = styled.form`
  ${Heading} {
    margin-bottom: 32px;
  }

  ${NegativeNotice} {
    margin-bottom: 32px;
  }

  ${Input} {
    width: 100%;
  }

  ${Select} {
    max-width: 100%;
  }

  ${ButtonGroup} {
    margin-top: 24px;
  }
`;

export const CopySecretWrapper = styled.div`
  ${Heading} {
    margin-bottom: 20px;
  }
`;

export const SecretsLabel = styled(Label)`
  color: var(--banner-text);
  font-weight: var(--normal);
  line-height: var(--regular);
`;

export const Message = styled.span`
  color: var(--banner-text);
  font-weight: var(--normal);
`;

export const TermsCheckbox = styled(Checkbox)`
  margin: 16px 0;
  align-items: flex-start;

  ${({ error }) =>
    error &&
    `
    ${Message} {
      color: var(--danger-dark);
    }
  `}
`;

export const StateDescription = styled(Description)`
  margin: 8px 0 20px;
  color: var(--banner-text);
  word-break: break-word;
`;

export const StateNegativeNotice = styled(NegativeNotice)`
  margin-bottom: 8px;
`;

export const Opener = styled(Icon)`
  color: var(--icon);
`;

export const KeyMenuOptions = styled(MenuList)`
  color: var(--text-secondary);

  ${Icon} {
    color: var(--icon);
  }
`;

export const KeyLimitWrapper = styled.div`
  text-align: left;
  white-space: normal;
  width: 200px;
`;

export const ContactLink = styled(ExternalLink)`
  color: var(--white);
  font-weight: var(--bold);
  text-decoration: underline;

  &:hover {
    color: var(--text-tertiary);
  }
`;
