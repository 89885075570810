import React, { useState } from 'react';

import SpecialCard from 'components/Common/SpecialCard';
import NpsQueryResults from 'components/Common/QueryResults/NpsQueryResults';

const NpsSpecialCard = ({ hasCreatedNPS, npsId }) => {
  const [endTime] = useState(Date.now());

  return (
    <NpsQueryResults npsId={npsId} endTime={endTime}>
      {results => {
        let npsScore;
        let isLoading;
        if (!results) {
          npsScore = 0;
          isLoading = true;
        } else {
          npsScore = Math.floor(results.npsScore);
          isLoading = false;
        }

        return (
          <SpecialCard
            name={hasCreatedNPS ? 'Current NPS Score' : 'NPS Survey'}
            value={hasCreatedNPS ? npsScore : 'Create one'}
            description="Net Promoter Score (NPS)"
            backgroundImage="$grapefruit"
            iconClassName="comment-alt"
            to="/nps"
            isLoading={hasCreatedNPS && isLoading}
          />
        );
      }}
    </NpsQueryResults>
  );
};

export default NpsSpecialCard;
