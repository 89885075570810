import React from 'react';
import styled from 'styled-components';
import { Button } from '@appcues/component-library';
import { Icon, ButtonLink } from '@studio/legacy-components';

const CtaBar = props => {
  const { errors, errorText, navigateHome, navigateNextStep, buttonText } =
    props;

  return (
    <CtaWrapper>
      <ButtonLink onClick={navigateHome}>
        <CtaIcon icon="arrow-left" />
        Back to Appcues
      </ButtonLink>
      <ButtonWrapper>
        {errors && <ErrorText>{errorText}</ErrorText>}
        <CtaButton onClick={navigateNextStep}>{buttonText}</CtaButton>
      </ButtonWrapper>
    </CtaWrapper>
  );
};

const CtaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 32px 0;
`;

const CtaIcon = styled(Icon)`
  margin-right: 0.5rem;
`;

// I abhor that I need importants here, but this will be necessary
// until the component library is updated for the redesign

const ButtonWrapper = styled.div`
  max-width: 450px;
  display: flex;
`;

const ErrorText = styled.p`
  color: ${props => props.theme['$red-1']};
  margin-bottom: 0 !important;
`;

const CtaButton = styled(Button)`
  background-color: ${props => props.theme['$red-1']} !important;
  color: white !important;
  padding: 0.75rem !important;
  border: 1px solid white !important;
  margin-left: 1rem;

  &:hover {
    background-color: white !important;
    color: ${props => props.theme['$red-1']} !important;
    border: 1px solid ${props => props.theme['$red-1']} !important;
  }
`;

export default CtaBar;
