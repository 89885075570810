import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Property, PropertySeparator, RowIcon } from './styled';

export default function Metric({ icon, label }) {
  if (!label) {
    return null;
  }

  const metrics = Array.isArray(label) ? label : [label];

  return (
    <>
      <Property title={metrics.join(' | ')}>
        {metrics.map((metric, index) => (
          <Fragment key={metric}>
            {index > 0 && <PropertySeparator />}
            {metric}
          </Fragment>
        ))}
      </Property>
      <RowIcon icon={icon} />
    </>
  );
}

Metric.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};
