import PropTypes from 'prop-types';
import pick from 'lodash.pick';

/**
 * Full shape of user/meta model
 */
const Shape = {
  avatarUrl: PropTypes.string,
  cannotPublish: PropTypes.string, // deprecated
  createdAt: PropTypes.number,
  crxVersion: PropTypes.string,
  email: PropTypes.string,
  fullname: PropTypes.string,
  id: PropTypes.string,
  isAdmin: PropTypes.bool,
  isInRebrandBeta: PropTypes.bool, // deprecated,
  isInvitedUser: PropTypes.bool,
  job: PropTypes.string,
  lastSeenAt: PropTypes.number,
  tel: PropTypes.string, // deprecated
};

/**
 * Allowed fields for storage
 */
const allowed = ['email', 'fullname', 'id', 'isAdmin'];

/**
 * Account parsing function
 *
 * @param {Object<keyof User>} user - Object with user fields
 * @return {Object<keyof User>} Object with parsed user fields
 */
export const parse = user => pick(user, allowed);

export default PropTypes.shape(Shape);
