import React from 'react';
import { Box, Flex, Text } from '@appcues/component-library';
import { ButtonLink, Icon } from '@studio/legacy-components';

const FlowListLayout = ({ title, children, linkOnClick, ...boxProps }) => (
  <Box flexGrow={1} minWidth={0} flexBasis={380} marginTop={34} {...boxProps}>
    <Flex justifyContent="space-between" alignItems="center" marginBottom={12}>
      <Text bold>{title}</Text>
      {linkOnClick && (
        <ButtonLink onClick={linkOnClick}>
          See all <Icon icon="arrow-right" color="inherit" />
        </ButtonLink>
      )}
    </Flex>
    {children}
  </Box>
);

export default FlowListLayout;
