import React from 'react';
import { Row, Column } from '@appcues/component-library';
import useTitle from 'next/hooks/use-title';
import ProfilePanel from './ProfilePanel';
import AccountSettingsPanel from './AccountSettingsPanel';
import FrequencyCappingPanel from './FrequencyCappingPanel';
import CustomerSupportPanel from './CustomerSupportPanel';

const AccountPage = () => {
  useTitle('Account | Settings | Appcues');
  return (
    <Row setChildrenEqualSize marginTop={0}>
      <Column>
        <ProfilePanel />
        <CustomerSupportPanel />
      </Column>
      <Column>
        <AccountSettingsPanel />
        <FrequencyCappingPanel />
      </Column>
    </Row>
  );
};

export default AccountPage;
