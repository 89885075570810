import React, { Component, createRef } from 'react';
import SliderPicker from 'react-color/lib/components/slider/Slider';
import styled from 'styled-components';
import Input from 'components/Common/Forms/Input';

class ColorPicker extends Component {
  state = {
    displaySlider: false,
  };

  sliderRef = createRef();

  UNSAFE_componentWillMount() {
    document.body.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleClick);
  }

  handleClick = e => {
    if (this.sliderRef.current && !this.sliderRef.current.contains(e.path[0])) {
      this.setState({ displaySlider: false });
    }
  };

  render() {
    const {
      className,
      color,
      defaultColor,
      onChangeSlider,
      onChangeInput,
      onFocus,
      onBlur,
    } = this.props;
    const { displaySlider } = this.state;

    return (
      <div
        className={`color-picker ${className} ${
          displaySlider ? 'show-slider' : ''
        }`}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          role="switch"
          tabIndex={0}
          aria-checked={displaySlider}
          onClick={() => {
            this.setState({
              displaySlider: !displaySlider,
            });
            if (onFocus) {
              onFocus();
            }
          }}
          className="color-element outer"
        >
          <div className="color-element inner" />
        </div>
        {displaySlider && (
          <SliderPicker
            styles={{ default: { wrap: {} } }}
            ref={this.sliderRef}
            color={color || defaultColor}
            onChangeComplete={value => onChangeSlider(value.hex)}
          />
        )}
        <Input
          type="text"
          value={color}
          placeholder={defaultColor}
          onChange={onChangeInput}
          onClick={() => {
            this.setState({ displaySlider: true });
            if (onFocus) {
              onFocus();
            }
          }}
          onBlur={onBlur}
        />
      </div>
    );
  }
}

export default styled(ColorPicker)`
  &.color-picker {
    position: relative;
    display: table;
    padding: 4px 10px 4px 6px;
    border: 1px solid #ddd;
    border-radius: 25px;
    background-color: #e3e3e347;

    &.show-slider {
      border-color: #aeaeae;

      .color-element {
        &.outer {
          border: 2px solid #aeaeae;
        }
      }
    }

    input {
      display: inline-block;
      vertical-align: middle;
      width: 90px;
      margin-left: 5px;
      border: none;
      background-color: transparent;
      text-transform: uppercase;
    }

    .color-element {
      border-radius: 50%;
      cursor: pointer;

      &.outer {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        width: 35px;
        height: 35px;
        background-color: transparent;
        border: 2px solid #ddd;
      }

      &.inner {
        width: 25px;
        height: 25px;
        border: 2px solid transparent;
        background-color: ${props => props.color || props.defaultColor};
      }
    }
    .slider-picker {
      position: absolute;
      top: -10px;
      left: 155px;
      width: 300px;
      background-color: white;
      border: 1px solid lightgrey;
      padding: 10px;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
      z-index: 1;
    }
  }
`;
