import { call, takeEvery, put, select } from 'redux-saga/effects';
import toast from 'next/lib/toast';
import {
  HANDLE_FLOW_ACTIONS,
  UPDATE_VIEW_AND_FLUSH_VIEW,
  UPDATE_VIEW_IN_STORE,
  FLUSH_VIEW_TO_FIREBASE,
} from 'constants/view/actionTypes';
import { UPDATED_FILTERS } from 'constants/events';
import { FLOW_ACTIONS_HANDLER } from 'constants/view';
import { selectUserId } from 'reducers/user';
import { selectAccountUsers } from 'reducers/account/users';

import { getFlowPublishingApiPromise } from 'helpers/publishing-api';
import { trackEvent } from 'actions/events';
import { flowActionFor } from 'actions/account/flows';
import { selectFlow, selectFlows } from 'reducers/account/flows';
import { buildDeepLinkToStepChild } from 'utils/steps';
import { openFlowInBuilder } from 'utils/url';
import * as persistence from 'helpers/persistence';
import { navigate } from 'actions/routing';
import { buildFlowActionPath } from 'utils';
import { selectChromeExtensionVersion } from 'reducers/chromeExtension';
import { canOpenInBuilder } from 'utils/chromeExtension';

function makeToast(actionType) {
  switch (actionType) {
    case 'clone':
      return toast.show('Cloning flow...');
    case 'archive':
      return toast.show('Archiving flow...');
    case 'unarchive':
      return toast.show('Restoring flow...');
    case 'delete':
      return toast.show('Deleting flow...');
    default:
      return null;
  }
}

export function* handleFlowActions(action) {
  const { stepActionType, stepActionHandler, data } = action.payload;
  const { content_type, content_id, showOpenInBuilderModal } = data;
  const dontShowOpenInBuilderModal =
    persistence.getItem('dontShowOpenInBuilderModal') === 'true';
  const chromeExtensionVersion = yield select(selectChromeExtensionVersion);
  const isLatestVersion = canOpenInBuilder(chromeExtensionVersion);

  switch (stepActionHandler) {
    case FLOW_ACTIONS_HANDLER.OPEN_IN_NEW_TAB: {
      if (isLatestVersion) {
        const stepGroup = yield select(selectFlow, content_id) || {};
        const allSteps = yield select(selectFlows);
        const url = buildDeepLinkToStepChild(stepGroup, null, allSteps);
        if (dontShowOpenInBuilderModal) {
          openFlowInBuilder(content_id, null, url);
        } else {
          showOpenInBuilderModal(url);
        }
      } else {
        const actionPath = buildFlowActionPath(
          content_type,
          content_id,
          stepActionType
        );

        yield put(navigate(actionPath, true));
      }

      break;
    }
    case FLOW_ACTIONS_HANDLER.NAVIGATE: {
      const actionPath = buildFlowActionPath(
        content_type,
        content_id,
        stepActionType
      );
      yield put(navigate(actionPath, false));

      break;
    }
    case FLOW_ACTIONS_HANDLER.CONTENT_API: {
      try {
        yield call(makeToast, stepActionType);
        yield getFlowPublishingApiPromise(stepActionType, {
          content_id,
        });
        yield put(flowActionFor(stepActionType));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }

      break;
    }
    default:
      break;
  }
}

function* updateViewAndFlushView(action) {
  const { changes, key } = action.payload;
  const userId = yield select(selectUserId);
  const users = yield select(selectAccountUsers);
  const userIds = Object.keys(users);

  yield put({
    type: UPDATE_VIEW_IN_STORE,
    payload: {
      changes,
      key,
    },
  });

  if (userIds.includes(userId)) {
    yield put({
      type: FLUSH_VIEW_TO_FIREBASE,
      payload: {
        userId,
      },
    });
  }
}

function* trackFilterUpdates(action) {
  const { key, changes } = action.payload;
  if (key) {
    if (key === 'tags') {
      const {
        filters: { tags },
      } = changes;
      yield put(
        trackEvent(UPDATED_FILTERS, {
          filterKey: key,
          numberOfTags: Object.keys(tags).length,
        })
      );
    } else {
      const filterValue =
        changes[key] || (changes.filters && changes.filters[key]);
      yield put(
        trackEvent(UPDATED_FILTERS, {
          filterKey: key,
          filterValue,
        })
      );
    }
  }
}

export default function* view() {
  yield takeEvery(HANDLE_FLOW_ACTIONS, handleFlowActions);
  yield takeEvery(UPDATE_VIEW_AND_FLUSH_VIEW, updateViewAndFlushView);
  yield takeEvery(UPDATE_VIEW_IN_STORE, trackFilterUpdates);
}
