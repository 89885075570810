import React, { useEffect, useMemo, useContext } from 'react';
import { connect } from 'react-redux';
import { Grid, List as FixedSizeList, WindowScroller } from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { SearchInput, SortDropdown } from '@studio/legacy-components';
import { hasMatchingSegment, Shape } from '@studio/conditions';
import ExperienceContext from 'next/components/ExperienceContext';
import { Filter, GroupedFiltersView } from 'next/components/Filter';
import { Shape as ExperienceShape } from 'next/entities/experiences';
import { selectRules } from 'next/entities/rules';
import { Shape as TagsShape } from 'next/entities/tags';
import { Shape as CreatorShape } from 'next/entities/users';
import { Shape as SegmentShape } from 'next/entities/segments';
import { loadFilters, saveFilter } from 'next/lib/filter';
import {
  Control,
  ControlSeparator,
  Controls,
  List,
  NoResults,
  ViewToggle,
  createUseControls,
} from 'next/components/Listing';

import ExperienceCard from './ExperienceCard';

const createTagsOptions = (tags, currentOptions = {}) =>
  tags.reduce((acc, tag) => {
    acc[tag.id] = {
      label: tag.name,
      enabled: currentOptions[tag.id] ? currentOptions[tag.id].enabled : false,
      filterFn: experience => experience.tagIds.includes(tag.id),
    };
    return acc;
  }, {});

const sortByName = collection =>
  [...collection.filter(ele => Boolean(ele.name))].sort((a, b) =>
    a.name.localeCompare(b.name, undefined, {
      numeric: true,
    })
  );

const createCreatorsOptions = (creators, currentOptions = {}) =>
  creators.reduce((acc, creator) => {
    acc[creator.id] = {
      label: creator.name,
      enabled: currentOptions[creator.id]
        ? currentOptions[creator.id].enabled
        : false,
      filterFn: experience => experience.createdBy === creator.id,
    };
    return acc;
  }, {});

const sortDomains = domains =>
  [...domains].sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));

const createDomainOptions = (domainAssociations, currentOptions = {}) => {
  const sortedDomains = sortDomains(Object.keys(domainAssociations));
  return sortedDomains.reduce((acc, domain) => {
    acc[domain] = {
      label: domain,
      enabled: currentOptions[domain] ? currentOptions[domain].enabled : false,
      filterFn: experience =>
        domainAssociations[domain]?.includes(experience.id),
    };
    return acc;
  }, {});
};

export function ExperienceList({
  creators = {},
  domainAssociations = {},
  experiences,
  rules = {},
  segments = {},
  tags,
  emptyState,
}) {
  const { type } = useContext(ExperienceContext);
  const savedFilter = loadFilters(type);
  const { useControls } = createUseControls(type);
  const capitalizedContext = useMemo(() => {
    return type.charAt(0).toUpperCase() + type.slice(1);
  }, [type]);

  const {
    processed,
    search,
    sort,
    view,
    filters,
    updateFilters,
    handleFilterChange,
    handleFilterClear,
  } = useControls(
    { data: experiences },
    {
      flow_status: {
        name: 'Status',
        options: {
          draft: {
            label: 'Draft',
            enabled: savedFilter.flow_status?.draft?.enabled ?? false,
            filterFn: item => !item.published && item.state === 'DRAFT',
          },
          live: {
            label: 'Live',
            enabled: savedFilter.flow_status?.live?.enabled ?? false,
            filterFn: item => item.published && item.state === 'DRAFT',
          },
          archived: {
            label: 'Include archived',
            enabled: savedFilter.flow_status?.archived?.enabled ?? false,
            separated: true,
            defaultHidden: true,
            filterFn: item => item.state === 'ARCHIVED',
          },
        },
      },
      tags: {
        name: 'Tag',
        options: createTagsOptions(
          sortByName(Object.values(tags)),
          savedFilter.tags
        ),
      },
      segments: {
        name: 'Segment',
        options: sortByName(Object.values(segments)).reduce((acc, segment) => {
          acc[segment.id] = {
            label: segment.name,
            enabled: savedFilter.segments?.[segment.id]?.enabled ?? false,
            filterFn: experience =>
              hasMatchingSegment(rules[experience.id]?.conditions, segment.id),
          };
          return acc;
        }, {}),
      },
      creator: {
        name: 'Creator',
        options: createCreatorsOptions(
          sortByName(Object.values(creators)),
          savedFilter.creator
        ),
      },
      domains: {
        name: 'Domain',
        options: createDomainOptions(domainAssociations, savedFilter.domains),
      },
    }
  );

  const onFilterChange = (group, option, isEnabled) => {
    saveFilter(type, group, option, isEnabled);
    handleFilterChange(group, option, isEnabled);
  };

  useEffect(() => {
    updateFilters(currentFilters => {
      const newTags = Object.values(tags);
      const newDomainAssociations = domainAssociations;
      return {
        ...currentFilters,
        domains: {
          ...currentFilters?.domains,
          options: {
            ...createDomainOptions(
              newDomainAssociations,
              currentFilters.domains.options
            ),
          },
        },
        tags: {
          ...currentFilters.tags,
          options: {
            ...createTagsOptions(
              sortByName(newTags),
              currentFilters.tags.options
            ),
          },
        },
      };
    });
  }, [domainAssociations, tags, updateFilters]);

  if (isEmpty(experiences) && emptyState) {
    return emptyState;
  }

  return (
    <>
      <Controls>
        <Control>
          <SearchInput
            onChange={search.onChange}
            placeholder={`Search by ${capitalizedContext} name`}
            value={search.value}
          />
        </Control>

        <Control>
          <Filter
            filters={filters}
            onChange={onFilterChange}
            onClear={handleFilterClear}
            view={GroupedFiltersView}
          />
        </Control>

        <ControlSeparator />

        <Control>
          <SortDropdown
            onChange={sort.onChange}
            options={sort.options}
            value={sort.value}
          />
        </Control>

        <Control>
          <ViewToggle onChange={view.onChange} value={view.value} />
        </Control>
      </Controls>

      {isEmpty(processed) && (
        <NoResults>No results found. Please try different filters.</NoResults>
      )}

      {!isEmpty(processed) && (
        <List $kind={view.value}>
          <WindowScroller>
            {({ height, isScrolling, onChildScroll, scrollTop }) => (
              <AutoSizer disableHeight>
                {({ width }) => {
                  const numColumns = width < 1000 ? 2 : 3;

                  if (view.value === 'list') {
                    return (
                      <FixedSizeList
                        autoHeight
                        height={height}
                        rowCount={processed.length}
                        rowHeight={136}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        rowRenderer={({ index, style }) => {
                          const experience = processed[index];
                          return (
                            <ExperienceCard
                              key={experience.id}
                              experience={experience}
                              view={view.value}
                              deferred={isScrolling}
                              type={type}
                              style={{
                                ...style,
                                bottom: (style.bottom || 0) + 16,
                              }}
                            />
                          );
                        }}
                        isScrolling={isScrolling}
                        onScroll={onChildScroll}
                        scrollTop={scrollTop}
                        width={width}
                        processed={processed}
                      />
                    );
                  }
                  return (
                    <Grid
                      autoHeight
                      // eslint-disable-next-line react/no-unstable-nested-components
                      cellRenderer={({ columnIndex, rowIndex, style }) => {
                        const index = rowIndex * numColumns + columnIndex;
                        if (index >= processed.length) {
                          return null;
                        }

                        const isFirstColumn = columnIndex === 0;
                        const experience = processed[index];
                        return (
                          <ExperienceCard
                            key={experience.id}
                            experience={experience}
                            view={view.value}
                            deferred={isScrolling}
                            type={type}
                            style={{
                              ...style,
                              left: isFirstColumn
                                ? style.left
                                : style.left + 16,
                              width: isFirstColumn
                                ? style.width
                                : style.width - 16,
                              top: style.top + 16,
                              height: style.height - 16,
                            }}
                          />
                        );
                      }}
                      columnWidth={width / numColumns}
                      columnCount={numColumns}
                      height={height}
                      isScrolling={isScrolling}
                      onScroll={onChildScroll}
                      rowCount={Math.ceil(processed.length / numColumns)}
                      rowHeight={296}
                      scrollTop={scrollTop}
                      width={width}
                    />
                  );
                }}
              </AutoSizer>
            )}
          </WindowScroller>
        </List>
      )}
    </>
  );
}

const mapStateToProps = state => ({
  rules: selectRules(state),
});

export default connect(mapStateToProps)(ExperienceList);

ExperienceList.propTypes = {
  domainAssociations: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  tags: PropTypes.objectOf(TagsShape),
  experiences: PropTypes.objectOf(ExperienceShape),
  segments: PropTypes.objectOf(SegmentShape),
  creators: PropTypes.objectOf(CreatorShape),
  rules: PropTypes.objectOf(Shape),
  emptyState: PropTypes.node,
};
