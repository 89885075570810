import React from 'react';
import styled from 'styled-components';

const GoalsList = props => {
  const {
    className,
    goals,
    setGoal,
    activateGoal,
    deactivateGoal,
    canActivateGoal,
  } = props;

  const onClickActivateDeactivate = goal => {
    if (canActivateGoal && goal.disabled) {
      activateGoal(goal);
    } else if (!goal.disabled) {
      deactivateGoal(goal);
    }
  };

  return (
    <div className={className}>
      <div className="headerRow">
        <h3 className="column goalColumn">Goal</h3>
        <h3 className="column enabledColumn">Tracking?</h3>
        <h3 className="column actionsColumn">Actions</h3>
      </div>
      {goals.map(goal => (
        <GoalRow
          key={goal.id}
          name={goal.name}
          disabled={goal.disabled}
          canActivateGoal={canActivateGoal}
          onClickUse={() => setGoal(goal.id)}
          onClickActivateDeactivate={() => onClickActivateDeactivate(goal)}
        />
      ))}
    </div>
  );
};

const GoalRow = ({
  disabled,
  name,
  canActivateGoal,
  onClickActivateDeactivate,
  onClickUse,
}) => (
  <div className={`goalRow ${disabled ? 'goalDisabled' : ''}`}>
    <div className="column goalColumn">{name}</div>
    <div className="column enabledColumn">{disabled ? 'No' : 'Yes'}</div>
    <div className="column actionsColumn">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
      <a
        role="switch"
        tabIndex={0}
        aria-checked={!disabled}
        className={!canActivateGoal && disabled && 'disabled'}
        onClick={onClickActivateDeactivate}
      >
        {disabled ? 'Activate' : 'Deactivate'}
      </a>
      {!disabled && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events
        <a role="button" tabIndex={-1} onClick={onClickUse}>
          Use this Goal
        </a>
      )}
    </div>
  </div>
);

export default styled(GoalsList)`
  display: flex;
  flex-direction: column;

  .headerRow,
  .goalRow {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid ${props => props.theme['$gray-3']};

    .column {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      padding: 0 5px;
    }

    .goalColumn {
      flex-grow: 1;
    }

    .enabledColumn,
    .actionsColumn {
      flex-basis: 210px;
      flex-shrink: 0;
    }

    .enabledColumn {
      flex-basis: 135px;
    }

    .actionsColumn {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      a {
        cursor: pointer;
        color: ${props => props.theme.$blue};
      }

      a:hover {
        color: ${props => props.theme['$blue-dark']};
      }

      a.disabled,
      a.disabled:hover {
        cursor: not-allowed;
        color: ${props => props.theme['$gray-30']};
      }
    }
  }

  .headerRow {
    .enabledColumn,
    .actionsColumn {
      color: ${props => props.theme['$gray-30']};
    }
  }

  .goalRow {
    padding: 12px 0;

    &.goalDisabled {
      color: ${props => props.theme['$gray-30']};
    }

    .column {
      padding-left: 8px;
    }
  }
`;
