import { createCollectionReducer } from 'next/lib/collections';
import { selectRequestStatus } from 'next/entities/requests';
import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

export const selectPins = state => state[TYPE];
export const selectPin = (state, id) => selectPins(state)[id];

export const readPins = state => ({
  ...selectRequestStatus(state, TYPE),
  data: selectPins(state),
});
export const readPin = (state, id) => ({
  ...selectRequestStatus(state, TYPE, id),
  data: selectPin(state, id),
});

export const selectPinStep = (state, stepId) => {
  return Object.values(selectPins(state) ?? {})
    .flatMap(({ steps }) => steps)
    .find(({ id }) => id === stepId);
};
