import {
  conditionNames,
  propertyConditionNames,
  propertyOperators,
} from '@appcues/libcues';
import moment from 'moment';
import {
  millisecondsPerDay,
  millisecondOperators,
  datePickerOperators,
} from 'constants/account/conditions';
import { hashArray } from 'utils/index';
import { STEP_TYPE_IDS } from 'constants/stepTypes';

const { hasOwnProperty } = Object.prototype;

export function clauseTreesAreEqual(clauses, newClauses) {
  return hashArray(newClauses) === hashArray(clauses);
}

export function filterPropertyClauses(clause) {
  return Object.values(propertyConditionNames).includes(clause.conditionName);
}

export function filterUrlTargetingClauses(clause) {
  return clause.conditionName === conditionNames.URL;
}

export function filterTriggerClauses(clause) {
  return clause.conditionName === conditionNames.TRIGGER;
}

export function filterTargetingHelperClauses(clause) {
  return (
    clause.conditionName === conditionNames.URL ||
    clause.conditionName === conditionNames.DOMAINS
  );
}

export function stripUrlTargetingClauses(clause) {
  return clause.conditionName !== conditionNames.URL;
}

export function filterDomainTargetingClauses(clause) {
  return clause.conditionName === conditionNames.DOMAINS;
}

export function filterABGroupClauses(clause) {
  return (
    clause.conditionName === propertyConditionNames.PROPERTIES &&
    clause.property === '_ABGroup'
  );
}

export function getFlowTargetingClauses(clauses) {
  return clauses.filter(clause => {
    return (
      hasOwnProperty.call(clause, 'conditionName') &&
      clause.conditionName === conditionNames.CONTENT
    );
  });
}

export function getSegmentClause(clauses) {
  return clauses.find(clause => {
    return (
      hasOwnProperty.call(clause, 'segment') &&
      clause.conditionName === conditionNames.SEGMENTS
    );
  });
}

export function stripEmptyClauses(clauses) {
  return clauses.filter(clause => clause.operator && clause.value);
}

export function getEmptyClauseForConditionName(conditionName) {
  const baseCondition = { conditionName };

  switch (conditionName) {
    case propertyConditionNames.EVENT:
      return { ...baseCondition, event: '' };
    case propertyConditionNames.CONTENT:
      return { ...baseCondition, content: '' };
    case propertyConditionNames.LANGUAGES:
      return { ...baseCondition, operator: propertyOperators.STARTS_WITH };
    case propertyConditionNames.SEGMENTS:
      return { ...baseCondition, segment: '' };
    case conditionNames.ATTRIBUTES:
      return { ...baseCondition, operator: propertyOperators.CONTAINS };
    case propertyConditionNames.CHECKLISTS:
      return { ...baseCondition, checklist: '', status: 'in_progress' };
    default:
      return {
        ...baseCondition,
        value: '',
      };
  }
}

export function prepareClauseUpdates(clause, newOperator) {
  const oldOperator = clause.operator;
  const clauseUpdates = {
    operator: newOperator,
    value: clause.value,
  };

  if (millisecondOperators.includes(clauseUpdates.operator)) {
    // if the incoming operator is in milliseconds...

    if (datePickerOperators.includes(oldOperator)) {
      // ...and the outgoing operator is a timestamp
      clauseUpdates.value = millisecondsPerDay;
    } else if (!millisecondOperators.includes(oldOperator)) {
      // ...and the outgoing operator is _not_ in milliseconds
      clauseUpdates.value =
        Number.parseInt(clauseUpdates.value, 10) * millisecondsPerDay;
    } else {
      // incoming operator and outgoing operator both milliseconds, no bother
    }
  } else if (millisecondOperators.includes(oldOperator)) {
    // if the outgoing operator is in milliseconds...

    if (datePickerOperators.includes(clauseUpdates.operator)) {
      // ...and the incoming operator is a timestamp
      clauseUpdates.value = moment().startOf('day').format('x');
    } else if (!millisecondOperators.includes(clauseUpdates.operator)) {
      // ...and the incoming operator is _not_ in milliseconds
      clauseUpdates.value =
        Number.parseInt(clauseUpdates.value, 10) / millisecondsPerDay;
    } else {
      // outgoing and incoming operators are both in milliseconds, no bother
    }
  } else if (datePickerOperators.includes(clauseUpdates.operator)) {
    // if the incoming operator is a datestamp...

    if (!datePickerOperators.includes(oldOperator)) {
      // ...and the outgoing operator is _not_ a datestamp
      clauseUpdates.value = moment().startOf('day').format('x');
    }
  } else if (datePickerOperators.includes(oldOperator)) {
    // if the outgoing operator is a datestamp...

    if (millisecondOperators.includes(clauseUpdates.operator)) {
      // ...and the incoming operator is in milliseconds
      clauseUpdates.value = millisecondsPerDay;
    } else if (!datePickerOperators.includes(clauseUpdates.operator)) {
      // ...and the incoming operator is _not_ a datestamp
      clauseUpdates.value = 1;
    } else {
      // outgoing and incoming operators are both datestamps
    }
  } else {
    // If we could possibly be going from a textarea to a textbox, let's make sure we don't carry any newlines or spaces
    clauseUpdates.value = `${clauseUpdates.value}`.trim();
  }

  return clauseUpdates;
}

/**
 * Modals & Slideouts must only be "seen" to be considered "completed"
 * by Dynamo/The API, while all other patterns require each step to complete.
 *
 * Thus, attempt to ensure that we don't use the wrong term.
 *
 * @param step
 * @returns {string}
 */

export function getCompletionEventLabel(step) {
  return step &&
    step.stepType &&
    [STEP_TYPE_IDS.MODAL, STEP_TYPE_IDS.SLIDEOUT].includes(step.stepType)
    ? 'seen'
    : 'completed';
}
