import React from 'react';
import styled from 'styled-components';

const ThreeDotsLoader = ({ className }) => {
  return (
    <div className={`${className}`}>
      <div className="dot-loader">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </div>
    </div>
  );
};

export default styled(ThreeDotsLoader)`
  .dot-loader {
    width: 100%;
    text-align: center;
  }

  .dot-loader > div {
    width: 10px;
    height: 10px;
    background-color: ${props => props.dotColor || '#fff'};

    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .dot-loader .bounce1 {
    animation-delay: -0.32s;
  }

  .dot-loader .bounce2 {
    animation-delay: -0.16s;
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;
