import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CDialog, CButton } from '@appcues/component-library';
import { ZAPIER_APP_LINK } from 'constants/externalAppcuesResources';
import ZapierTemplatesEmbed from './ZapierTemplatesEmbed';

const ZapierIntegrationDialog = styled(CDialog)`
  width: 700px;
  margin-top: 30px;

  #zapier-embed {
    margin: 20px 0;
  }
`;

export default function ZapierIntegrationModal({ actions }) {
  return (
    <ZapierIntegrationDialog
      title="Zapier integration"
      components={{ Footer: false }}
    >
      Automate key activities by connecting Appcues to 1,500+ apps, all without
      writing any code. Head to Zapier to get started, or choose a template
      below to jump right in.
      <ZapierTemplatesEmbed />
      <CDialog.Footer>
        <CButton onClick={actions.hideModal}>Cancel</CButton>
        <CButton
          is="a"
          type="primary"
          href={ZAPIER_APP_LINK}
          isExternal
          onClick={actions.hideModal}
        >
          Let&apos;s go!
        </CButton>
      </CDialog.Footer>
    </ZapierIntegrationDialog>
  );
}

ZapierIntegrationModal.propTypes = {
  actions: PropTypes.shape({
    hideModal: PropTypes.func.isRequired,
  }).isRequired,
};
