import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Flex, Box, CCard } from '@appcues/component-library';

import { H3, Icon, StatusLabel } from '@studio/legacy-components';
import { navigate } from 'actions/routing';
import ThemeOptions from 'components/Themes/ThemeEdit/ThemeOptions';
import ThemeImage from 'components/Themes/ThemeImage';
import {
  selectAreFlowsSynced,
  selectFlowsWithTheme,
} from 'reducers/account/flows';
import { modalPreviewContent } from 'constants/account/themes';

const Name = styled(H3)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const UsageIcon = styled.span`
  align-items: center;
  background-color: var(--disabled-x-light);
  border-radius: 12px;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-right: 8px;
  width: 24px;

  ${Icon} {
    color: var(--icon);
    font-size: var(--xx-small);
  }
`;

const Text = styled.span`
  color: var(--text-secondary);
`;

const Footer = styled.footer`
  align-items: center;
  display: flex;

  ${Text} {
    flex-grow: 1;
  }
`;

const ThemeCard = ({
  loaded,
  role = '',
  theme = {},
  themeId,
  themedFlows,
  onClick,
}) => (
  <div role={role}>
    <CCard onClick={onClick} padding={16} role={role}>
      <Flex alignItems="center" paddingBottom={8}>
        <Name title={theme.name}>{theme.name}</Name>
        <Box marginLeft="auto">
          <ThemeOptions theme={theme} themeId={themeId} border={0} />
        </Box>
      </Flex>
      <ThemeImage content={modalPreviewContent} theme={theme} />
      <Footer>
        <UsageIcon>
          <Icon icon="map" />
        </UsageIcon>
        {loaded && (
          <Text type="tertiary">Used in {themedFlows.length} flows</Text>
        )}
        {theme.isDefault && <StatusLabel active={false} label="Default" />}
      </Footer>
    </CCard>
  </div>
);

ThemeCard.propTypes = {
  theme: PropTypes.shape({
    isDefault: PropTypes.bool,
    name: PropTypes.string,
  }),
  themeId: PropTypes.string.isRequired,
  role: PropTypes.string,
  loaded: PropTypes.bool,
  themedFlows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  onClick: PropTypes.func,
};

const mapStateToProps = (state, { themeId }) => ({
  themedFlows: selectFlowsWithTheme(state, themeId),
  loaded: selectAreFlowsSynced(state),
});

const mapDispatchToProps = (dispatch, { themeId }) => ({
  onClick: () => dispatch(navigate(`themes/${themeId}/edit`)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemeCard);
