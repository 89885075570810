import {
  put,
  call,
  getContext,
  takeEvery,
  debounce,
  select,
} from 'redux-saga/effects';
import toast from 'next/lib/toast';
import { reportError } from 'helpers/error-reporting';
import { navigate } from 'actions/routing';
import {
  CONTENT_REVERTED,
  CONTENT_PUBLISHED,
  CONTENT_UNPUBLISHED,
  CONTENT_PUSHED,
} from 'actions/publishing';
import {
  send,
  resolve,
  reject,
  remove,
  update,
  insert,
  flush,
  replace,
  patterns,
  CHECKLIST_DELETED,
  ITEM_UPDATED,
  ITEM_REMOVED,
  ITEMS_SWAPPED,
  ITEM_ADDED,
  CHECKLIST_CLONED,
} from './actions';
import { selectAccountChecklist } from './reducer';

const CONTENT_ACTIONS = new Set([
  CONTENT_REVERTED,
  CONTENT_PUBLISHED,
  CONTENT_UNPUBLISHED,
  CONTENT_PUSHED,
]);

export const transform = response =>
  response.reduce((acc, checklist) => {
    acc[checklist.id] = checklist;
    return acc;
  }, {});

export function* fetchChecklists() {
  try {
    const api = yield getContext('api');
    yield put(send());
    const { data: checklists } = yield call(api.getAllChecklists);
    yield put(resolve(transform(checklists)));
  } catch (error) {
    yield call(reportError, error);
    yield put(reject(error));
  }
}

export function* fetchChecklist(action) {
  try {
    const {
      payload: { id },
    } = action;

    const api = yield getContext('api');
    const { data: checklist } = yield call(api.getChecklist, id);
    yield put(replace(checklist));
  } catch (error) {
    yield call(reportError, error);
    yield put(reject(error));
  }
}

export function* deleteChecklist({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    yield call(api.deleteChecklist, id);
    yield put(remove(id));
  } catch (error) {
    yield call(reportError, error);
    yield call(toast.error, 'Failed to delete checklist');
  }
}

export function* flushChecklist({ payload: { id, delta } }) {
  try {
    const api = yield getContext('api');
    yield call(api.updateChecklist, id, delta);
    yield put(flush(id));
  } catch (error) {
    yield call(reportError, error);
    yield call(toast.error, 'Failed to save checklist');
  }
}

export function* flushItems({ payload: { id } }) {
  try {
    const { items } = yield select(selectAccountChecklist, id);
    yield call(flushChecklist, update(id, { items }));
  } catch (error) {
    yield call(reportError, error);
  }
}

export function* createChecklist() {
  try {
    const api = yield getContext('api');
    const { data: checklist } = yield call(api.createChecklist);
    yield put(insert(checklist));
  } catch (error) {
    yield call(reportError, error);
    yield call(toast.error, 'Failed to create checklist');
  }
}

export function* cloneChecklist({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    const { data: checklist } = yield call(api.cloneChecklist, id);
    yield put(insert(checklist));
  } catch (error) {
    yield call(reportError, error);
    yield call(toast.error, 'Failed to clone checklist');
  }
}

export function* navigateToChecklist({ payload: { id } }) {
  yield put(navigate(`checklists/${id}/edit`));
}

export function* showUpdatedToast() {
  yield call(toast.success, 'Checklist updated');
}

export default function* saga() {
  yield takeEvery(patterns.callApi, fetchChecklists);
  yield takeEvery(CHECKLIST_DELETED, deleteChecklist);
  yield takeEvery(patterns.create, createChecklist);
  yield takeEvery(CHECKLIST_CLONED, cloneChecklist);
  yield debounce(500, [patterns.update], flushChecklist);
  yield debounce(
    500,
    [ITEM_UPDATED, ITEM_REMOVED, ITEMS_SWAPPED, ITEM_ADDED],
    flushItems
  );

  // publishing
  yield takeEvery(
    action =>
      CONTENT_ACTIONS.has(action.type) && action.payload.type === 'checklist',
    fetchChecklist
  );

  // monitoring
  yield takeEvery(patterns.insert, navigateToChecklist);
  yield takeEvery(patterns.flush, showUpdatedToast);
}
