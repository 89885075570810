import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import styled from 'styled-components';

import {
  CForm,
  P,
  ModalProvider,
  CModal,
  H2,
} from '@appcues/component-library';

import ErrorBanner from 'components/Insights/Common/ErrorBanner';

const StyledModalContent = styled.section`
  padding: 30px 48px;
`;

const ModalWrapper = styled.div`
  .modal-panel {
    height: auto !important;
  }

  .modal-panel button.close-button {
    top: 6px;
    right: 6px;
    background: transparent;
  }
`;

const StyledField = styled(CForm.Field)`
  max-width: 100%;
`;

const SaveChartModal = ({ onClose, onSubmit }) => {
  const [inFlight, setInFlight] = useState(false);
  const [errorMsgVisible, setErrorMsgVisible] = useState(false);

  const handleSubmit = (form, { setErrors: setYupError }) => {
    setInFlight(true);
    setErrorMsgVisible(false);

    onSubmit(form.name)
      .then(() => {
        onClose();
      })
      .catch(error => {
        switch (error.name) {
          case 'DuplicateChartNameError':
            setYupError({ name: 'Chart name already exists' });
            break;
          default: {
            setErrorMsgVisible(true);
            break;
          }
        }

        setInFlight(false);
      });
  };

  return (
    <ModalWrapper>
      <ModalProvider>
        <CModal title="" type="small" onClose={onClose}>
          <StyledModalContent>
            <H2 lineHeight={1}>Save Chart</H2>
            <P marginTop={12}>Name this chart to refer back to later.</P>
            {errorMsgVisible && <ErrorBanner />}
            <CForm
              onSubmit={handleSubmit}
              initialValues={{
                name: '',
              }}
              validationSchema={yup.object().shape({
                name: yup
                  .string()
                  .required('Name required')
                  .max(50, 'Maximum 50 characters'),
              })}
            >
              <StyledField
                label=""
                name="name"
                placeholder="Chart Name"
                helperText="Maximum 50 characters"
                disabled={inFlight}
                maxWidth="100%"
              />
              <CForm.SubmitButton
                type="primary"
                disabledUntilDirty={false}
                ariaLabel="Save"
                disabled={inFlight}
              >
                Save
              </CForm.SubmitButton>
            </CForm>
          </StyledModalContent>
        </CModal>
      </ModalProvider>
    </ModalWrapper>
  );
};

SaveChartModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

SaveChartModal.defaultProps = {
  onSubmit: () => false,
};

export default SaveChartModal;
