import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash.isempty';
import { conditionNames } from '@appcues/libcues';
import { Tooltip } from '@appcues/sonar';
import { CButton, CPage, Column } from '@appcues/component-library';
import { ContentStatus } from '@studio/legacy-components';
import styled from 'styled-components';
import {
  FlowStatusBanner,
  LaunchpadConfiguration,
} from '@studio/settings-panels';
import toast from 'next/lib/toast';
import ExperimentBanner from 'next/components/ExperimentBanner';
import ExperimentBadge from 'next/components/ExperimentBadge';
import { DocumentTitle } from 'next/hooks/use-title';
import { fetchSchedules, selectSchedule } from 'next/entities/schedules';
import SchedulingTooltipMessage from 'next/components/ExperienceScheduling/SchedulingTooltipMessage';
import { shouldRenderScheduleStatus } from 'next/components/ExperienceScheduling/validation';
import { isExperienceListByTypeEmpty } from 'next/entities/experiences';
import { LAUNCHPADS_V2 } from 'next/entities/features';

import SchedulingModalProvider from 'components/SchedulingModalProvider';
import ChildPage from 'components/Common/ChildPage';

import EventTriggering from 'components/StepSettings/EventTriggering';
import AudienceTargeting from 'components/Common/AudienceTargeting';
import ABTesting from 'components/StepSettings/ABTesting';
import GoalSetting from 'components/StepSettings/GoalSetting';
import RedirectActionSetting from 'components/StepSettings/RedirectActionSetting';
import ContentTriggering from 'components/StepSettings/ContentTriggering';
import FlowInformationPanel from 'components/StepSettings/FlowInformationPanel';
import Publishing from 'components/Common/Publishing';
import Panel from 'components/Common/Panel';
import Loader from 'components/Common/Loader';
import PageTargeting from 'components/Common/PageTargeting';
import Localization from 'components/StepSettings/Localization';
import { STEP_TYPE_IDS, STEP_TYPES } from 'constants/stepTypes';
import {
  CREATE_SEGMENT_MODAL,
  DEFINE_GOAL_MODAL,
  FLOW_PUBLISHING_MODAL,
  PERMALINK_MODAL,
} from 'constants/globals';
import {
  callApi as fetchRule,
  update as updateRule,
} from 'actions/account/rules';
import { navigate } from 'actions/routing';
import { update as updateFlow } from 'actions/account/flows';
import { startPolling, stopPolling } from 'actions/account/flowStatus';
import { selectFlowStatus } from 'reducers/account/flowStatus';

import { selectAccountRule } from 'reducers/account/rules';
import { selectFlow, selectAreFlowsSynced } from 'reducers/account/flows';
import {
  selectAccountConditionsSynced,
  selectAccountStepConditions,
} from 'reducers/account/conditions';
import {
  selectAccountSegments,
  selectAccountSegmentsSynced,
} from 'reducers/account/segments';
import {
  selectAccountMeta,
  selectAccountMetaSynced,
} from 'reducers/account/meta';
import {
  selectAccountGoals,
  selectAccountGoalsSynced,
} from 'reducers/account/goals';
import { selectUserRole } from 'reducers/account/users';
import { selectUserId } from 'reducers/user';
import { includesCrxParam } from 'utils/url';

import {
  selectAccountFeature,
  selectAccountFeaturesSynced,
} from 'reducers/account/features';
import {
  EVENT_ATTRIBUTE_TRIGGERING,
  EVENT_TRIGGERING,
  WIDGET_TARGETING,
  AB_TESTING,
} from 'constants/features';
import { selectIsInstalled } from 'reducers/account/installedDomains';
import { showModal } from 'actions/currentModal';
import {
  addClauseAndFlushRule,
  addGroupClauseAndFlushRule,
  deleteClauseAndFlushRule,
  deleteMatchingClausesAndFlushRule,
  dissolveGroupClauseAndFlushRule,
  replaceClauseAndFlushRule,
  setAudienceAllUsersAndFlushRule,
  setAudienceCustomSegmentAndFlushRule,
  setAudienceSegmentAndFlushRule,
  setDisplayAnyPageAndFlushRule,
  setDisplaySpecificPageAndFlushRule,
  updateClauseAndFlushRule,
} from 'actions/account/conditions';
import Prioritization from 'components/StepSettings/Prioritization';
import getContentAction from 'actions/publishing';
import { PUBLISH_ACTIONS } from 'constants/modalStatuses';
import { Headline } from './styled';

const { hasOwnProperty } = Object.prototype;

const BadgeContainer = styled.div`
  display: flex;
  gap: var(--spacing-small);
`;

export class StepSettings extends Component {
  componentDidMount() {
    const { actions, stepId } = this.props;
    this.componentDidUpdate({});
    actions.startPolling(stepId);
    actions.onLoad();
    actions.fetchSchedules();
  }

  componentDidUpdate(prevProps) {
    const { loaded, step, actions } = this.props;

    if (loaded && !prevProps.loaded && !step) {
      toast.show("You don't have access to this flow—redirecting to Overview");
      actions.stopPolling();
      window.setTimeout(() => actions.navigate('/'), 1000);
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.stopPolling();
  }

  handlePermalinkClick = () => {
    const { actions, rule, meta, isInstalled } = this.props;
    actions.showModal(PERMALINK_MODAL, {
      stepId: rule.id,
      isInstalled,
      accountId: meta.id,
    });
  };

  render() {
    const {
      goals,
      loaded,
      rule,
      step,
      conditions,
      meta,
      segments,
      actions,
      contentStatus,
      isInCRX,
      isPublished,
      hasWidgetTargeting,
      hasEventTriggering,
      hasEventAttributeTriggering,
      hasABTesting,
      hasLaunchpad,
      hasNoLaunchpadCreated,
      role,
      onAction,
      schedule,
    } = this.props;

    if (!loaded) {
      return <Loader />;
    }

    const stepProperties = STEP_TYPES[step.type];
    const lastUpdatedAtTimestamp = step.updatedAt;

    const isSatisfactionStepType =
      stepProperties.id === STEP_TYPE_IDS.SATISFACTION;
    const isChecklistStepType = stepProperties.id === STEP_TYPE_IDS.CHECKLIST;
    const { contentType } = stepProperties;

    const showEventTriggering =
      hasEventTriggering ||
      conditions.find(
        clause => clause.conditionName === conditionNames.TRIGGER
      );

    const hasLiveExperiment =
      step.state === 'DRAFT' &&
      step.experiment?.state &&
      ['LIVE', 'PAUSED'].includes(step.experiment.state.toUpperCase());

    const hasLiveOrNotStartedExperiment =
      step.state === 'DRAFT' &&
      step.experiment?.state &&
      ['NOT_STARTED', 'LIVE', 'PAUSED'].includes(
        step.experiment.state.toUpperCase()
      );

    const confirmDiscardChanges = () => {
      actions.showModal(FLOW_PUBLISHING_MODAL, {
        publishActionType: PUBLISH_ACTIONS.REVERT,
        stepId: step.id,
        onAction,
      });
    };

    const hasScheduleStatusToShow = shouldRenderScheduleStatus(
      schedule,
      step.state
    );

    const handleWidgetFilterChange = widgetFilter => {
      actions.setStepWidgetFilter(rule.id, widgetFilter);
    };

    return (
      <ChildPage
        components={{
          Title: () => (
            <>
              <Headline title={step.name}>{step.name}</Headline>
              <BadgeContainer>
                {!isEmpty(contentStatus) && (
                  <ContentStatus published={isPublished} state={step.state} />
                )}

                {hasScheduleStatusToShow && (
                  <Tooltip
                    content={<SchedulingTooltipMessage schedule={schedule} />}
                    delayDuration={0}
                  >
                    <ContentStatus state="SCHEDULED" published={false} />
                  </Tooltip>
                )}

                <ExperimentBadge
                  experimentId={step.experiment?.id}
                  experimentName={step.experiment?.name}
                  experimentState={step.experiment?.state}
                  flowState={step.state}
                />
              </BadgeContainer>
            </>
          ),
        }}
        breadcrumbContents={{
          label: isPublished ? `Flow details` : 'Flows',
          path: isPublished ? `/${contentType}/${step.id}/analytics` : '/flows',
        }}
        subHeaderActions={
          <>
            {isPublished && !isSatisfactionStepType && !isChecklistStepType && (
              <CButton onClick={this.handlePermalinkClick}>Permalink</CButton>
            )}
            <Publishing
              contentStatus={contentStatus}
              enableTestMode
              flowName={step.name}
              conditions={conditions}
              isPublished={isPublished}
              previewUrl={step.previewUrl}
              stepId={step.id}
              stepType={step.type}
              showTestOptions
              isInCRX={isInCRX}
              lastUpdatedAtTimestamp={lastUpdatedAtTimestamp}
              existingTestFlowId={rule.testFlowId}
              role={role}
              hasLiveExperiment={hasLiveExperiment}
            />
            <SchedulingModalProvider
              contentId={step.id}
              published={isPublished}
            />
          </>
        }
      >
        <DocumentTitle title={`${step.name} | Settings | Appcues`} />
        <CPage.Container>
          <FlowStatusBanner
            isPublished={isPublished}
            isPublishedAndDirty={contentStatus.publishedAndDirty}
            showBanner={!hasLiveExperiment}
          />
          <Column>
            <ExperimentBanner
              className="experiment-banner"
              experimentId={step.experiment?.id}
              experimentName={step.experiment?.name}
              published={isPublished}
              showBanner={hasLiveExperiment}
              hasChanges={contentStatus.publishedAndDirty}
              onConfirmDiscardChanges={confirmDiscardChanges}
            />
            <FlowInformationPanel
              shouldShowOpenInBuilderAction={
                !isInCRX && !isSatisfactionStepType && !isChecklistStepType
              }
              isLocalized={step.lockedForLocalization}
              id={step.id}
              name={step.name}
              previewUrl={step.previewUrl}
              publicName={step.publicName}
              state={step.state}
              onSubmit={actions.onSave}
            />
            <Panel
              name="Trigger"
              description="Define when this flow launches."
              showLine={false}
            >
              <EventTriggering
                stepId={rule.id}
                clauses={conditions}
                rule={rule}
                addClause={actions.addClauseAndFlushRule}
                updateClause={actions.updateClauseAndFlushRule}
                deleteClause={actions.deleteClauseAndFlushRule}
                deleteMatchingClauses={
                  actions.deleteMatchingClausesAndFlushRule
                }
                setStepFrequency={actions.setStepFrequency}
                hasEventTriggering={showEventTriggering}
                hasEventTriggeringWithAttributes={hasEventAttributeTriggering}
              />
            </Panel>
            <Panel
              name="Page targeting"
              description="Define where the flow should appear."
            >
              <PageTargeting
                addClause={actions.addClauseAndFlushRule}
                clauses={conditions}
                deleteClause={actions.deleteClauseAndFlushRule}
                deleteMatchingClauses={
                  actions.deleteMatchingClausesAndFlushRule
                }
                showManualTriggerOption
                meta={meta}
                previewUrl={step.previewUrl}
                rule={rule}
                setDisplayAnyPage={actions.setDisplayAnyPageAndFlushRule}
                setDisplaySpecificPage={
                  actions.setDisplaySpecificPageAndFlushRule
                }
                setStepFrequency={actions.setStepFrequency}
                stepId={rule.id}
                updateClause={actions.updateClauseAndFlushRule}
              />
            </Panel>
            <Panel
              name="Audience"
              description="Define who should see the flow."
            >
              <AudienceTargeting
                isInCRX={isInCRX}
                stepId={rule.id}
                clauses={conditions}
                segments={segments}
                role={role}
                addGroupClause={actions.addGroupClauseAndFlushRule}
                dissolveGroupClause={actions.dissolveGroupClauseAndFlushRule}
                addClause={actions.addClauseAndFlushRule}
                updateClause={actions.updateClauseAndFlushRule}
                replaceClause={actions.replaceClauseAndFlushRule}
                deleteClause={actions.deleteClauseAndFlushRule}
                setAudienceAllUsers={actions.setAudienceAllUsersAndFlushRule}
                setAudienceCustomSegment={
                  actions.setAudienceCustomSegmentAndFlushRule
                }
                setAudienceSegment={actions.setAudienceSegmentAndFlushRule}
                createSegmentFromRule={onCreateCallback => () =>
                  actions.showModal(CREATE_SEGMENT_MODAL, {
                    stepId: rule.id,
                    createSegmentFromRule: actions.createSegmentFromRule,
                    segments,
                    onCreateCallback,
                  })}
              />
              {hasABTesting && (
                <ABTesting
                  stepId={rule.id}
                  clauses={conditions}
                  addClause={actions.addClauseAndFlushRule}
                  updateClause={actions.updateClauseAndFlushRule}
                  deleteClause={actions.deleteClauseAndFlushRule}
                />
              )}
            </Panel>
            {(hasWidgetTargeting || hasLaunchpad) && (
              <LaunchpadConfiguration
                empty={hasNoLaunchpadCreated}
                widgetFilter={rule.widgetFilter}
                onChange={handleWidgetFilterChange}
              />
            )}
            <Prioritization flowId={rule.id} />
            <Localization flowId={rule.id} />
            <Panel
              name="Goal"
              description="Choose how to measure the success of this flow based on what users accomplished after seeing it."
            >
              <GoalSetting
                setGoal={actions.onChangeGoal}
                removeGoalFromRule={actions.onRemoveGoal}
                stepId={rule.id}
                goals={rule.goals}
                accountGoals={goals}
                disableSetNoGoal={hasLiveOrNotStartedExperiment}
                showDefineGoalModal={props => {
                  actions.showModal(DEFINE_GOAL_MODAL, {
                    ...props,
                    stepId: rule.id,
                  });
                }}
              />
            </Panel>
            <Panel
              name="Actions"
              description="Set action for after this flow has completed."
              showLine={false}
            >
              <h4>What should we do when this flow has completed?</h4>
              <RedirectActionSetting
                redirectUrl={rule.redirectUrl}
                onBlur={actions.setStepRedirectUrl}
                onClear={actions.clearStepRedirectUrl}
                labelText="Redirect to a different page"
                placeholderText="Redirect URL"
              />
              <ContentTriggering
                rule={rule}
                setNextStepId={actions.setNextStepId}
              />
              <div className="content">
                <span className="help-text">
                  Note: these actions won&apos;t be triggered during preview,
                  but they will work in the live flow.
                </span>
              </div>
            </Panel>
          </Column>
        </CPage.Container>
      </ChildPage>
    );
  }
}

function mapStateToProps(state, routeProps) {
  const { stepId } = routeProps.match.params;
  const isInCRX = includesCrxParam(routeProps);
  const contentStatus = selectFlowStatus(state, stepId);
  const conditions = selectAccountStepConditions(state, stepId);
  const hasLaunchpad = selectAccountFeature(state, LAUNCHPADS_V2);
  const hasNoLaunchpadCreated =
    hasLaunchpad && isExperienceListByTypeEmpty(state, 'launchpad');

  return {
    hasEventTriggering: selectAccountFeature(state, EVENT_TRIGGERING),
    hasEventAttributeTriggering: selectAccountFeature(
      state,
      EVENT_ATTRIBUTE_TRIGGERING
    ),
    hasWidgetTargeting: selectAccountFeature(state, WIDGET_TARGETING),
    hasABTesting: selectAccountFeature(state, AB_TESTING),
    hasLaunchpad,
    hasNoLaunchpadCreated,
    meta: selectAccountMeta(state),
    isInstalled: selectIsInstalled(state),
    rule: selectAccountRule(state, stepId),
    step: selectFlow(state, stepId),
    stepId,
    conditions,
    segments: selectAccountSegments(state),
    contentStatus,
    goals: selectAccountGoals(state),
    role: selectUserRole(state, selectUserId(state)),
    isInCRX,
    isPublished: hasOwnProperty.call(contentStatus, 'publishedAndDirty'),
    loaded:
      conditions.length > 0 &&
      selectAccountMetaSynced(state) &&
      selectAccountGoalsSynced(state) &&
      selectAreFlowsSynced(state) &&
      selectAccountConditionsSynced(state) &&
      selectAccountSegmentsSynced(state) &&
      selectAccountFeaturesSynced(state),
    schedule: selectSchedule(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { stepId } = ownProps.match.params;
  return {
    actions: bindActionCreators(
      {
        onLoad: () => fetchRule({ id: stepId }),
        fetchSchedules: () => fetchSchedules(stepId),
        showModal,
        addClauseAndFlushRule,
        updateClauseAndFlushRule,
        deleteClauseAndFlushRule,
        deleteMatchingClausesAndFlushRule,
        setDisplayAnyPageAndFlushRule,
        setDisplaySpecificPageAndFlushRule,
        addGroupClauseAndFlushRule,
        dissolveGroupClauseAndFlushRule,
        replaceClauseAndFlushRule,
        setAudienceAllUsersAndFlushRule,
        setAudienceCustomSegmentAndFlushRule,
        setAudienceSegmentAndFlushRule,
        onSave: updateFlow,
        setStepRedirectUrl: url => updateRule(stepId, { redirectUrl: url }),
        clearStepRedirectUrl: () =>
          updateRule(stepId, { redirectUrl: null, redirectNewTab: null }),
        setStepFrequency: (id, frequency) => updateRule(id, { frequency }),
        setNextStepId: (id, nextContentId) => updateRule(id, { nextContentId }),
        setStepWidgetFilter: (id, widgetFilter) =>
          updateRule(id, { widgetFilter }),
        onRemoveGoal: id => updateRule(id, { goals: [] }),
        onChangeGoal: (id, goalId) => updateRule(id, { goals: [goalId] }),
        onAction: (actionType, { content_id, content_type }) =>
          getContentAction(actionType, { id: content_id, type: content_type }),
        startPolling,
        stopPolling,
        navigate,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StepSettings);
