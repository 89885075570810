import { call, put, getContext, takeEvery } from 'redux-saga/effects';
import { RESET_FLOW_HISTORY_SUCCESS } from 'entities/user-qualified-content';
import { reportError } from 'helpers/error-reporting';
import { patterns as segmentPatterns, replace, remove } from './actions';

export function* getUserSegments({ payload: { userId } }) {
  try {
    const api = yield getContext('endUserApi');
    const { data } = yield call(api.getUserSegments, userId);
    yield put(replace({ id: userId, ...data }));
  } catch (error) {
    yield call(reportError, error);
  }
}

export function* cleanUserSegments({ payload: userId }) {
  yield put(remove(userId));
}

export default function* saga() {
  yield takeEvery([segmentPatterns.callApi], getUserSegments);
  yield takeEvery(RESET_FLOW_HISTORY_SUCCESS, cleanUserSegments);
}
