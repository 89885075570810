import { createSelector } from 'reselect';
import {
  formatDate,
  getOptGroup,
  getPropertyDisplayName,
  isUnixTimestamp,
} from 'utils';

import { selectProfileAttributes } from 'entities/profile-attributes';
import { selectProfileAttributeLabels } from 'entities/profile-attribute-labels';
import { isAutoProperty } from 'utils/events-properties';
import { selectAccountUserProfile } from 'reducers/account/userProfiles';

const ATTRIBUTE_DENYLIST = new Set(['_ABGroup', '_testContentId']);

export const selectAccountProfileAttributes = createSelector(
  [selectProfileAttributes, selectProfileAttributeLabels],
  (attributes, meta) =>
    attributes &&
    Object.entries(attributes || {}).reduce((acc, [attribute, values]) => {
      const {
        id,
        label = meta[attribute]?.label || getPropertyDisplayName(attribute),
        description = '',
        showInUI = true,
      } = Object.values(meta).find(({ name }) => name === attribute) || {};

      const lastSeenTs = Math.max(
        ...Object.values(values)
          .map(({ lastSeenTs: valueLastSeenTs }) => valueLastSeenTs)
          .filter(Boolean)
      );

      acc[attribute] = {
        source: id ? 'firebase' : 'api',
        description,
        name: attribute,
        id,
        label,
        lastSeenTs,
        showInUI,
        values: Object.keys(values),
      };

      return acc;
    }, {})
);

export const selectAccountProfileAttributeByName = (state, name) => {
  const profileAttributes = selectAccountProfileAttributes(state);
  return (
    profileAttributes &&
    Object.values(profileAttributes).find(
      ({ name: attrName }) => attrName === name
    )
  );
};

export const selectUserProfileAttributes = (state, userId) => {
  const attributes = selectProfileAttributeLabels(state) || {};
  const user = selectAccountUserProfile(state, userId);
  return user
    ? Object.entries(user).map(([attrName, attrValue]) => {
        const { isStarred = false, label } =
          Object.values(attributes).find(({ name }) => name === attrName) || {};

        const isUserId = /user\s*id/i.test(attrName);

        const displayValue =
          isUnixTimestamp(attrValue) && !isUserId
            ? formatDate(attrValue, 'MMM D, YYYY h:mm A')
            : null;

        return {
          isStarred,
          name: attrName,
          label: label || getPropertyDisplayName(attrName),
          displayValue,
          value: attrValue,
          type: getOptGroup(attrName),
        };
      })
    : null;
};

export const selectProfileCustomProperties = createSelector(
  [selectAccountProfileAttributes],
  attribute => {
    const customProperties = Object.values(attribute).filter(property => {
      return !isAutoProperty(property);
    });
    return customProperties;
  }
);

export const selectProfileAutoProperties = createSelector(
  [selectAccountProfileAttributes],
  attributes => {
    const autoProperties = Object.values(attributes).filter(attribute => {
      return isAutoProperty(attribute);
    });
    return autoProperties;
  }
);

export const selectProfileAttributeDropdownOptions = createSelector(
  [selectAccountProfileAttributes],
  attributes =>
    Object.values(
      Object.values(attributes)
        .filter(
          ({ name, showInUI }) =>
            name &&
            !ATTRIBUTE_DENYLIST.has(name) &&
            (showInUI === undefined || !!showInUI)
        )
        .map(({ label, name: value }) => ({
          label: label || getPropertyDisplayName(value),
          value,
          isAutoProp: value.match(/^_/),
          group: getOptGroup(value),
        }))
        .reduce(
          (acc, option) => {
            const { group } = option;
            if (acc[group]) {
              acc[group].options.push(option);
            }
            return acc;
          },
          {
            normal: {
              label: 'CUSTOM PROPERTIES',
              options: [],
              order: 1,
            },
            form: {
              label: 'FORM RESPONSES',
              options: [],
              order: 2,
            },
            device: {
              label: 'DEVICE PROPERTIES',
              options: [],
              order: 3,
            },
            satisfaction: {
              label: 'USER SATISFACTION',
              options: [],
              order: 4,
            },
            auto: {
              label: 'AUTO-PROPERTIES',
              options: [],
              order: 5,
            },
          }
        )
    )
);

export const selectAttributesColumnsByName = (state, name) => {
  const attributes = selectAccountProfileAttributes(state) || {};
  const attr = Object.values(attributes).find(({ name: attrName }) => {
    return attrName === name;
  });
  return {
    label: attr?.label || getPropertyDisplayName(name),
    name,
  };
};
