import React from 'react';
import { Switch, Route } from 'react-router-dom';

import EditStepInChrome from 'components/EditStepInChrome';

const EditInChrome = () => (
  <Switch>
    <Route
      path="/edit-in-chrome/stepGroup/:stepGroupId/stepChild/:stepChildId"
      component={EditStepInChrome}
    />
    <Route
      path="/edit-in-chrome/stepGroup/:stepGroupId"
      component={EditStepInChrome}
    />
  </Switch>
);

export default EditInChrome;
