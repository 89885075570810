import {
  call,
  getContext,
  put,
  select,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';
import { navigate } from 'next/lib/history';
import { PUSH_WORKFLOWS, selectFeature } from 'next/entities/features';
import { patterns as experiencePatterns } from 'next/entities/experiences';
import { replacePagePatternFor } from 'next/entities/page';

import { patterns, insert, reject, resolve, replace } from './actions';

/**
 * Normalize apps list response as collection
 *
 * @param {Response} response - Raw apps list response
 * @return {Collection} Apps collection
 */
const transform = response =>
  response.reduce((acc, app) => {
    acc[app.id] = app;
    return acc;
  }, {});

function* fetchApps() {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getApps);
    yield put(resolve(transform(response)));
  } catch (error) {
    yield put(reject(error));
  }
}

function* fetchApp({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    const app = yield call(api.getApp, id);
    yield put(replace(app));
  } catch (error) {
    yield put(reject(error));
  }
}

function* createApp({ payload: { id, name, platform, bundlePackageId } }) {
  try {
    const api = yield getContext('api');
    const app = yield call(api.createApp, {
      name,
      platform,
      bundlePackageId,
    });

    yield put(insert(app));

    const hasPushEnabled = yield select(selectFeature, PUSH_WORKFLOWS);
    if (hasPushEnabled) {
      yield call(navigate, `/apps/${app.id}`);
    }
  } catch (error) {
    yield put(reject(id, error));
  }
}

function* updateApp({ payload: { id, delta } }) {
  try {
    const api = yield getContext('api');

    const app = yield call(api.updateApp, id, delta);

    yield put(replace(app));
  } catch (error) {
    yield put(reject(id, error));
  }
}

export default function* saga() {
  // Internal actions
  yield takeEvery(patterns.read, fetchApps);
  yield takeEvery(patterns.readOne, fetchApp);
  yield takeLeading(patterns.create, createApp);
  yield takeLeading(patterns.update, updateApp);

  // External actions
  yield takeEvery(experiencePatterns.replace, fetchApps);

  // Page actions
  yield takeLeading(
    [
      replacePagePatternFor('/flows'),
      replacePagePatternFor('/mobile/flows/:experienceId/settings'),
    ],
    fetchApps
  );
}
