import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CDialog, CButton } from '@appcues/component-library';
import { Link, calculateDatesFor } from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';
import { asPromised } from 'utils/as-promised';

import { selectChart } from 'reducers/account/charts';
import { navigate } from 'actions/routing';
import { deleteChart } from 'actions/account/charts';

import Card from 'components/Common/Card';
import CustomChart from 'components/Insights/Explorer/Chart/CustomChart';
import ErrorBanner from 'components/Insights/Common/ErrorBanner';

const CustomChartWrapper = styled.div`
  min-height: 275px;
  display: initial;
`;

const StyledFooter = styled(CDialog.Footer)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledCDialog = styled(CDialog)`
  ${CDialog.Content} {
    overflow: hidden;
  }
`;

const CardLink = styled(Link)`
  --link-text-decoration: none;
  --link-font-weight: initial;
`;

export const ChartCard = ({
  id,
  name,
  segmentId,
  events = [],
  startTime,
  endTime,
  type,
  className,
  onView,
  onDelete,
}) => {
  const [confirmDeleteVisible, toggleConfirmDelete] = useToggle();
  const [deleteErrorVisible, setDeleteErrorVisible] = useState(false);

  const handleSelectDropdownOption = opt => {
    switch (opt) {
      case 'view':
        onView();
        break;
      case 'delete':
        setDeleteErrorVisible(false);
        toggleConfirmDelete();
        break;
      default:
    }
  };

  const handleDelete = () =>
    void onDelete()
      .then(toggleConfirmDelete)
      .catch(() => {
        setDeleteErrorVisible(true);
      });

  return (
    <>
      {confirmDeleteVisible && (
        <StyledCDialog title="Delete Chart" components={{ Footer: false }}>
          {deleteErrorVisible && <ErrorBanner />}
          <p>
            Deleting this chart will remove it but you can recreate it any time
            by selecting the same events.
          </p>
          <p>Are you sure you&apos;d like to continue?</p>
          <StyledFooter>
            <CButton onClick={toggleConfirmDelete}>Cancel</CButton>
            <CButton type="negative" onClick={handleDelete}>
              Delete
            </CButton>
          </StyledFooter>
        </StyledCDialog>
      )}
      <CardLink to={`/insights/charts/${id}`} title={`Navigate to ${name}`}>
        <Card
          header={name}
          className={className}
          padding={32}
          dropdownOptions={[
            {
              label: 'View Details',
              value: 'view',
              icon: 'chart-bar',
            },
            { label: 'Delete', value: 'delete', icon: 'trash-alt' },
          ]}
          onSelectDropdownOption={handleSelectDropdownOption}
        >
          <CustomChartWrapper>
            <CustomChart
              name={name}
              startTime={startTime}
              endTime={endTime}
              segmentId={segmentId}
              selectedEvents={events}
              type={type}
            />
          </CustomChartWrapper>
        </Card>
      </CardLink>
    </>
  );
};

ChartCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  segmentId: PropTypes.string,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  startTime: PropTypes.number,
  endTime: PropTypes.number,
  type: PropTypes.string,
  className: PropTypes.string,
  onView: PropTypes.func,
  onDelete: PropTypes.func,
};

const mapStateToProps = (state, { id }) => {
  const chart = selectChart(state, id);

  if (!chart) {
    return {};
  }

  const {
    end_time,
    events,
    mode,
    name,
    segment_id,
    start_time,
    trailing_days = '7',
    type,
  } = chart;

  const { start, end } =
    mode === 'FIXED'
      ? { start: new Date(start_time), end: new Date(end_time) }
      : calculateDatesFor(Number.parseInt(trailing_days, 10));

  return {
    id,
    name,
    segmentId: segment_id,
    type,
    events,
    startTime: start.getTime(),
    endTime: end.getTime(),
  };
};

const mapDispatchToProps = (dispatch, { id }) => ({
  onView: () => dispatch(navigate(`/insights/charts/${id}`)),
  onDelete: () => asPromised(dispatch, deleteChart({ id })),
});

const ConnectedChartCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChartCard);

ConnectedChartCard.propTypes = {
  id: PropTypes.string.isRequired,
  endTime: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default ConnectedChartCard;
