import { PUBLISH_ACTIONS } from 'constants/modalStatuses';

// eslint-disable-next-line no-restricted-imports
import authClient from 'helpers/auth-client';

import createApiClient from 'helpers/api-client';

const apiClient = createApiClient(authClient);

export const getFlowPublishingApiPromise = (actionType, data) => {
  const { content_id: flowId, to_version_id: versionId } = data;
  switch (actionType) {
    case PUBLISH_ACTIONS.REVERT:
      return apiClient.revertFlow(flowId, versionId);
    case PUBLISH_ACTIONS.PUBLISH:
      return apiClient.publishFlow(flowId);
    case PUBLISH_ACTIONS.PUSH:
      return apiClient.pushFlow(flowId);
    case PUBLISH_ACTIONS.UNPUBLISH:
      return apiClient.unpublishFlow(flowId);
    case PUBLISH_ACTIONS.CREATE:
      return apiClient.createFlow({
        name: data.name,
        previewUrl: data.preview_url,
        tagIds: data.tags,
        formatVersion: data.format_version,
      });
    case PUBLISH_ACTIONS.CREATE_TEST:
      return apiClient.createTestFlow(flowId);
    case PUBLISH_ACTIONS.CLONE:
      return apiClient.cloneFlow(flowId);
    case PUBLISH_ACTIONS.DELETE:
      return apiClient.deleteFlow(flowId);
    case PUBLISH_ACTIONS.ARCHIVE:
      return apiClient.archiveFlow(flowId);
    case PUBLISH_ACTIONS.UNARCHIVE:
      return apiClient.unarchiveFlow(flowId);
    default:
      throw new Error(`No publish action of ${actionType} available for Flows`);
  }
};

export const publishNPS = (
  actionType,
  { content_id: id, to_version_id: versionId }
) => {
  switch (actionType) {
    case PUBLISH_ACTIONS.PUBLISH:
      return apiClient.publishNps(id);
    case PUBLISH_ACTIONS.REVERT:
      return apiClient.revertNps(id, versionId);
    case PUBLISH_ACTIONS.UNPUBLISH:
      return apiClient.unpublishNps(id);
    default:
      throw new Error(`No publish action of ${actionType} available for NPS`);
  }
};

export const getVersionedFlow = (flowId, versionId) => {
  return apiClient.getFlowVersionById(flowId, versionId);
};

const getLatestVersion = versions => {
  return Math.max(
    ...versions.map(version => {
      return version.updatedAt;
    })
  );
};

export const getLastPublishedVersionIdForFlow = async flowId => {
  const response = await apiClient.getAllFlowVersions(flowId);
  return getLatestVersion(response.data);
};

export const publishChecklist = (actionType, { content_id: id }) => {
  switch (actionType) {
    case PUBLISH_ACTIONS.PUBLISH:
      return apiClient.publishChecklist(id);
    case PUBLISH_ACTIONS.REVERT:
      return apiClient.revertChecklist(id);
    case PUBLISH_ACTIONS.UNPUBLISH:
      return apiClient.unpublishChecklist(id);
    default:
      throw new Error(
        `No publish action of ${actionType} available for checklist`
      );
  }
};
