import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Caption } from '@appcues/component-library';
import { Text } from '@studio/legacy-components';

const HiddenInput = styled.input`
  opacity: 0;
  z-index: -1;
`;

const Content = styled.div`
  flex-direction: column;
`;

const Indicator = styled.div`
  position: relative;
  display: flex;
  margin-right: 8px;
  margin-top: 2px;
  min-width: 16px;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  background-color: ${props => props.theme['$gray-1']};
  transition: box-shadow 0.2s ease;
  box-shadow: inset 0 0 0 ${props => (props.checked ? '6px' : '2px')};
  color: ${props => props.theme[props.checked ? '$blue' : '$gray-5']};
`;

export const RadioWrapper = styled.label`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  font-weight: 600;
  color: ${props => props.theme['$gray-7']};
  cursor: pointer;
  max-width: 400px;
  padding: 10px 3px;

  &:first-of-type {
    margin-top: 6px;
  }

  &:hover {
    color: ${props => props.theme['$blue-dark']};
    background-color: ${props => props.theme['$gray-1']};
    border-radius: 6px;
    & ${Indicator} {
      box-shadow: inset 0 0 0 ${props => (props.checked ? '6px' : '2px')}
        ${props => props.theme['$blue-dark']};
    }
  }
  ${({ checked, disabled }) => {
    switch (true) {
      case !checked && !disabled:
        return `
          &:hover,
          &:focus-within {
            ${Content} {
              border-color: var(--radio-hover);
            }
          }
        `;

      case checked && !disabled:
        return `
          ${Content} {
            border-color: var(--radio-checked);
            &:hover,
            &:focus-within {
                border-color: var(--radio-checked-hover);
            }
          }
        `;

      case !checked && disabled:
      case checked && disabled:
      default:
        return `
          cursor: default;
          pointer-events: none;
          ${Indicator} {
              color: var(--radio-disabled);
          }
           ${Content} {
              ${Text} {
                color: var(--radio-disabled);
              }
          }

        `;
    }
  }}
`;

const Radio = ({ label, checked, children, disabled, caption, ...rest }) => {
  return (
    <RadioWrapper checked={checked} disabled={disabled}>
      <HiddenInput
        data-checked={checked}
        checked={checked}
        disabled={disabled}
        type="radio"
        {...rest}
      />
      <Indicator checked={checked} />
      <Content>
        <Text className="checkbox-label">{label}</Text>
        {children}
        {caption && <Caption className="checkbox-caption">{caption}</Caption>}
      </Content>
    </RadioWrapper>
  );
};

Radio.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  caption: PropTypes.string,
  children: PropTypes.any,
};

export default Radio;
