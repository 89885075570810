import React from 'react';
import styled from 'styled-components';
import { ButtonLink, Link } from '@studio/legacy-components';
import { useSelector } from 'react-redux';
import { history } from 'next/lib/history';
import { selectPaymentInfo } from 'reducers/newBilling';

const CardLinks = () => {
  const paymentInfo = useSelector(selectPaymentInfo);
  const linkText = paymentInfo?.paymentMethodId
    ? 'edit the credit card'
    : 'save a credit card';

  return (
    <div>
      <LinkWrapper>
        <p>
          Startup with a tight tech budget? We have options just for you.{' '}
          <Link href="mailto:sales@appcues.com?subject=Appcues startup pricing">
            Contact sales
          </Link>{' '}
          to learn more.
        </p>
      </LinkWrapper>
      <LinkWrapper>
        <p>
          <Link href="mailto:sales@appcues.com?subject=Appcues mobile purchase">
            Contact sales
          </Link>{' '}
          if you would like to purchase Appcues Mobile.
        </p>
      </LinkWrapper>
      <LinkWrapper>
        <p>
          You can{' '}
          <ButtonLink onClick={() => history.push('/subscription/billing')}>
            {linkText}
          </ButtonLink>{' '}
          to use at a later time with your subscription.
        </p>
      </LinkWrapper>
    </div>
  );
};

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  color: ${props => props.theme['$gray-30']};
`;

export default CardLinks;
