import React from 'react';
import styled from 'styled-components';
import { H4 } from '@appcues/component-library';

function FieldGroup({ children, className, title, caption }) {
  return (
    <div className={className}>
      <div className="field-group-header">
        {title && <H4>{title}</H4>}
        {caption && <p className="caption">{caption}</p>}
      </div>
      <div className="field-group-fields">{children}</div>
    </div>
  );
}

export default styled(FieldGroup)`
  margin-bottom: ${props => (props.disableMargin ? 0 : '2.5rem')};

  .field-group-header {
    margin-bottom: 0.75rem;

    h3 {
      color: ${props => props.theme['$gray-50']};
      font-size: ${props => props.theme['$font-size-base']};
      font-weight: normal;
      margin-bottom: 0.25rem;
    }
  }

  .caption {
    color: ${props => props.theme['$gray-7']};
    font-size: ${props => props.theme['$font-size-small']};
    margin-bottom: 0;
  }
`;
