import React from 'react';

import { Button } from '@appcues/component-library';
import SatisfactionSurveyCard from 'components/SatisfactionSurveys/SatisfactionSurveyCard';

export default function StyleSummary({
  backgroundColor,
  foregroundColor,
  updateSatisfaction,
}) {
  return (
    <SatisfactionSurveyCard
      disableHover
      className="satisfaction-form-card"
      header="Style"
    >
      <div className="satisfaction-card-top">
        <hr />
        <p>
          <b>Theme:</b>{' '}
          {backgroundColor !== '#FFFFFF' ? 'Dark NPS Form' : 'Light NPS Form'}
        </p>
        <p>
          <b>Primary Color:</b>
          <span className="satisfaction-preview-color" />
          {backgroundColor !== '#FFFFFF' ? backgroundColor : foregroundColor}
        </p>
        <Button
          onClick={() => {
            updateSatisfaction({ currentStep: 0, isTestMode: false });
          }}
          className="button"
        >
          Edit
        </Button>
      </div>
    </SatisfactionSurveyCard>
  );
}
