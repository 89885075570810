import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  ButtonGroup,
  Modal,
  Heading,
  ModalFooter,
  Overflow,
  Table,
  Tbody,
  Tr,
  Td,
} from '@studio/legacy-components';

import { Shape as ExperienceShape } from 'next/entities/experiences';

import ReadableConditions from 'next/components/ReadableConditions';
import StepsCarousel from 'next/components/StepsCarousel';
import ExperienceContext from 'next/components/ExperienceContext';
import { ScheduleType } from 'next/components/ExperienceScheduling/types';
import { formatScheduleDate } from 'next/components/ExperienceScheduling/dates';
import SchedulingStartAlert from 'next/components/ExperienceScheduling/SchedulingStartAlert';

import { TableContainer } from './styled';

export function ConfirmPushChangesModal({
  experience = {},
  onPublish,
  onClose,
  visible,
  schedule,
}) {
  const { id, steps = [] } = experience;
  const { sections = {}, type = 'pins' } = useContext(ExperienceContext);

  return (
    <Modal visible={visible} onClose={onClose} size="l">
      <Heading>Confirm Publish</Heading>
      <Overflow>
        <SchedulingStartAlert schedule={schedule} experienceType={type} />

        {steps.length > 0 && type !== 'launchpad' && (
          <StepsCarousel
            experienceId={id}
            steps={steps}
            showLabelIcon={type === 'pins'}
            type={type}
          />
        )}

        <TableContainer>
          <Table>
            <Tbody>
              <Tr>
                <Td>Page</Td>
                <Td>
                  {sections.url ? (
                    <ReadableConditions conditions={sections.url} />
                  ) : (
                    'Any page'
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td>Domains</Td>
                <Td>
                  {sections.domains ? (
                    <ReadableConditions conditions={sections.domains} />
                  ) : (
                    'Everywhere my Appcues script is installed'
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td>Audience</Td>
                <Td>
                  {sections.audience ? (
                    <ReadableConditions conditions={sections.audience} />
                  ) : (
                    'All users'
                  )}
                </Td>
              </Tr>
              {schedule?.startDate && (
                <Tr>
                  <Td>Publish date</Td>
                  <Td>{formatScheduleDate(schedule?.startDate)}</Td>
                </Tr>
              )}
              {schedule?.endDate && (
                <Tr>
                  <Td>Unpublish date</Td>
                  <Td>{formatScheduleDate(schedule?.endDate)}</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Overflow>
      <ModalFooter>
        <ButtonGroup>
          <Button kind="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onPublish}>Confirm</Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
}

ConfirmPushChangesModal.propTypes = {
  experience: ExperienceShape.isRequired,
  onPublish: PropTypes.func,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  schedule: ScheduleType,
};

export default ConfirmPushChangesModal;
