import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal, Tooltip } from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';
import { create } from 'entities/keys';
import { selectUser } from 'reducers/user';
import { selectAccountUsers } from 'reducers/account/users';
import { canManageAccountUsers } from 'helpers/account-management';
import { asPromised } from 'utils/as-promised';

import { BeaconLink } from 'components/Common/HelpScout';

import CreateKeyForm from './CreateKeyForm';
import CopySecret from './CopySecret';

import { KeyLimitWrapper } from './styled';

const LIMIT_SUBJECT = 'API key limit reached';
const LIMIT_MESSAGE = "I'd like to increase my API key limit.";

export const CreateManager = ({ hasCreateKey, disabled, onSubmit }) => {
  const [createdKey, setCreatedKey] = useState(null);
  const [inFlight, setInFlight] = useState(false);
  const [error, setError] = useState(false);
  const [hasAcceptedTerms, setAcceptedTerms] = useState(false);
  const [visible, toggle] = useToggle();

  useEffect(() => {
    if (visible) {
      setCreatedKey(null);
      setInFlight(false);
      setError(false);
      setAcceptedTerms(false);
    }
  }, [visible]);

  const handleCreate = async ({ name, role_id }) => {
    setInFlight(true);
    setError(false);

    try {
      const key = await onSubmit({ name, role_id });
      setCreatedKey(key);
    } catch {
      setError(true);
      setInFlight(false);
    }
  };

  const handleAcceptTerms = checked => {
    if (checked) {
      setError(false);
    }

    setAcceptedTerms(checked);
  };

  const handleClose = () => {
    if (!createdKey) {
      toggle();
      return;
    }

    if (!hasAcceptedTerms) {
      setError(true);
      return;
    }

    toggle();
  };

  return (
    <>
      {hasCreateKey && (
        <Tooltip
          disabled={!disabled}
          label={
            <KeyLimitWrapper>
              You&apos;ve reached your API key limit. Contact{' '}
              <BeaconLink
                subject={LIMIT_SUBJECT}
                message={LIMIT_MESSAGE}
                mailto="support@appcues.com"
              >
                support@appcues.com
              </BeaconLink>{' '}
              to increase your limit.
            </KeyLimitWrapper>
          }
          persist
          wrapped
        >
          <Button kind="primary" onClick={toggle} disabled={disabled}>
            Create new key
          </Button>
        </Tooltip>
      )}
      <Modal onClose={handleClose} size="m" visible={visible}>
        {createdKey ? (
          <CopySecret
            apiKey={createdKey}
            hasAcceptedTerms={hasAcceptedTerms}
            hasError={error}
            onAcceptTerms={handleAcceptTerms}
            onClose={handleClose}
          />
        ) : (
          <CreateKeyForm
            hasSubmitError={error}
            inFlight={inFlight}
            onClose={handleClose}
            onSubmit={handleCreate}
          />
        )}
      </Modal>
    </>
  );
};

CreateManager.propTypes = {
  hasCreateKey: PropTypes.bool,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
};

const mapStateToProps = state => {
  const user = selectUser(state);
  const accountUsers = selectAccountUsers(state);

  return {
    hasCreateKey: canManageAccountUsers({ user, accountUsers }),
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmit: key => asPromised(dispatch, create(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateManager);
