import * as satisfactionActionTypes from 'constants/satisfaction/actionTypes';
import { PREVIEW_TABS } from 'constants/satisfaction/data';
import { CLEAR_ACCOUNT_DATA } from 'constants/actionTypes';

const { hasOwnProperty } = Object.prototype;

const initialState = {
  synced: false,
  satisfactionExportFormat: 'json',
  collapsePreview: true,
  previewTab: PREVIEW_TABS.SURVEY,
  satisfaction: {},
};

export default function reducer(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
    case satisfactionActionTypes.TOGGLE_SATISFACTION_EXPORT_FORMAT:
      return {
        ...state,
        satisfactionExportFormat:
          state.satisfactionExportFormat === 'json' ? 'csv' : 'json',
      };

    case satisfactionActionTypes.REPLACE_ACCOUNT_SATISFACTION: {
      const { satisfaction } = payload;
      return {
        ...state,
        satisfaction,
        synced: true,
      };
    }

    case satisfactionActionTypes.UPDATE_SATISFACTION_IN_STORE: {
      const { satisfactionId: surveyId, userId, updatedAt, changes } = payload;
      const {
        name,
        questionOne,
        questionTwo,
        autoCollapseFeedbackForm,
        ...colorChanges
      } = changes;
      const satisfactionSurvey = state.satisfaction[surveyId];
      const satisfactionSurveySteps = Object.values(satisfactionSurvey.steps);
      const firstStep = satisfactionSurveySteps.find(step => step.index === 0);
      const secondStep = satisfactionSurveySteps.find(step => step.index === 1);
      return {
        ...state,
        satisfaction: {
          ...state.satisfaction,
          [surveyId]: {
            ...satisfactionSurvey,
            updatedBy: userId,
            updatedAt,
            ...(name ? { name } : {}),
            steps: {
              [firstStep.id]: {
                ...firstStep,
                ...(questionOne ? { questionText: questionOne } : {}),
                ...(colorChanges ? { ...colorChanges } : {}),
                ...(hasOwnProperty.call(changes, 'autoCollapseFeedbackForm')
                  ? { collapseBeforeNextStep: autoCollapseFeedbackForm }
                  : {}),
              },
              [secondStep.id]: {
                ...secondStep,
                ...(questionTwo ? { questionText: questionTwo } : {}),
                ...(colorChanges ? { ...colorChanges } : {}),
              },
            },
          },
        },
      };
    }

    case satisfactionActionTypes.TOGGLE_SURVEY_PREVIEW: {
      const { collapsePreview, previewTab } = payload;
      const newState = {
        ...state,
        collapsePreview,
      };

      if (previewTab) {
        newState.previewTab = previewTab;
      }
      return newState;
    }

    case CLEAR_ACCOUNT_DATA:
      return initialState;

    default:
      return state;
  }
}

export const selectAccountSatisfactionSynced = state =>
  state.account.satisfaction.synced;
