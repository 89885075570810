import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H3, P } from '@appcues/component-library';
import EmptyEventSourceImg from 'assets/images/empty-event-source.svg';

const EmptySourcesContainer = styled.div`
  display: grid;
  place-items: center;
`;

const EmptyEventSource = ({ type }) => (
  <EmptySourcesContainer>
    <img src={EmptyEventSourceImg} alt={`No ${type}`} />
    <H3 marginTop={18} marginBottom={8}>
      Looks like there are no {type}.
    </H3>
    <P>
      Check out other event sources in the drop down or adjust the timeframe.
    </P>
  </EmptySourcesContainer>
);

EmptyEventSource.propTypes = {
  type: PropTypes.string.isRequired,
};

export default EmptyEventSource;
