import React from 'react';
import PropTypes from 'prop-types';
import { CDialog, P } from '@appcues/component-library';

const DeleteLocaleDialog = ({ onSubmit, onClose }) => {
  return (
    <CDialog
      title="Are you sure you want to delete this language?"
      type="negative"
      onPrimaryAction={onSubmit}
      onSecondaryAction={onClose}
      primaryActionText="Yes, delete"
    >
      <P>This language has uploaded translations on unpublished flows</P>
      <P>Deleting this language will also remove those translations</P>
    </CDialog>
  );
};

DeleteLocaleDialog.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default DeleteLocaleDialog;
