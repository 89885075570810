import { createContext } from 'react';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createReduxEnhancer } from '@sentry/react';
import { promiseMiddleware } from 'next/lib/effects';
import { createApiClient } from 'next/lib/clients/api';
import { initialize } from './actions';
import reducer from './reducer';
import saga from './saga';

// Use the Redux devtools composer if the extension exists
const enhance = window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'Studio Next' })
  : compose;

/**
 * Configure Redux store
 *
 * @param {object} bootstrap - Bootstrapping payload
 * @param {Auth} bootstrap.auth - Auth information
 * @param {Account} bootstrap.account - Authenticated account
 * @param {Features} bootstrap.features - Account feature flags
 * @param {boolean} bootstrap.spoofing - Whether spoofing
 * @param {User} bootstrap.user - User meta
 * @return {Redux.Store} Configured Redux store
 */
export function configure({ auth, account, features, spoofing, user } = {}) {
  // Initial state hydrated from bootstrapping payload
  const state = { account, features, spoofing, user };

  // Create saga middleware with context
  const sagaMiddleware = createSagaMiddleware({
    context: {
      api: createApiClient(auth),
    },
  });

  // Wrap Redux dispatch with middlewares
  const middlewares = applyMiddleware(promiseMiddleware, sagaMiddleware);

  const sentryReduxEnhancer = createReduxEnhancer({});
  // Create middleware enhanced store
  const store = createStore(
    reducer,
    state,
    enhance(middlewares, sentryReduxEnhancer)
  );

  // Run the root saga
  sagaMiddleware.run(saga);

  // Dispatch initialize action to kick off any start up tasks
  store.dispatch(initialize(auth));

  // Return  configured Redux store
  return store;
}

const createStoreContext = () => {
  let context = null;

  return {
    set: store => {
      context = createContext(store);
    },
    get: () => {
      return context;
    },
  };
};

export const StoreContext = createStoreContext();
