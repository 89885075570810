import React from 'react';
import PropTypes from 'prop-types';

import { Label } from '@studio/legacy-components';
import { TileButton, TileGroup } from '@appcues/sonar';

import {
  MOBILE_DEFAULT_TYPE,
  MOBILE_EMBED_TYPE,
} from 'next/entities/experiences';
import { EmbedsIcon, ModalsTooltipsIcon } from './TypeOptionsIcons';

export const TypeOptions = ({ type, onClick }) => (
  <>
    <Label htmlFor="type">Flow type</Label>
    <TileGroup type="single" defaultValue={MOBILE_DEFAULT_TYPE}>
      <TileButton
        key="modals-tooltips"
        value={MOBILE_DEFAULT_TYPE}
        label="Modals & Tooltips"
        data-state={type === MOBILE_DEFAULT_TYPE ? 'on' : 'off'}
        onClick={() => {
          onClick(MOBILE_DEFAULT_TYPE);
        }}
      >
        <ModalsTooltipsIcon />
      </TileButton>

      <TileButton
        key="embeds"
        label="Embeds"
        value={MOBILE_EMBED_TYPE}
        data-state={type === MOBILE_EMBED_TYPE ? 'on' : 'off'}
        onClick={() => {
          onClick(MOBILE_EMBED_TYPE);
        }}
      >
        <EmbedsIcon />
      </TileButton>
    </TileGroup>
  </>
);

TypeOptions.propTypes = {
  type: PropTypes.oneOf([MOBILE_DEFAULT_TYPE, MOBILE_EMBED_TYPE]),
  onClick: PropTypes.func,
};

export default TypeOptions;
