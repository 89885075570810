import React from 'react';
import PropTypes from 'prop-types';
import FeatureGate from 'components/Common/FeatureGate/FeatureGate';
import { FLOW_PRIORITIZATION } from 'constants/features';
import Prioritization from './Prioritization';
import PrioritizationPrompt from './PrioritizationPrompt';

export default function PrioritizationGate({ flowId }) {
  return (
    <FeatureGate feature={FLOW_PRIORITIZATION}>
      {enabled =>
        enabled ? <Prioritization flowId={flowId} /> : <PrioritizationPrompt />
      }
    </FeatureGate>
  );
}

PrioritizationGate.propTypes = {
  flowId: PropTypes.string,
};
