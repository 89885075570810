/* global CRX_ID */
/* eslint-disable camelcase */
import {
  select,
  call,
  take,
  all,
  spawn,
  takeEvery,
  getContext,
} from 'redux-saga/effects';
import { LOGOUT, selectUser } from 'next/entities/user';
import {
  DISABLE_FULLSTORY,
  selectFeature,
  selectFeatures,
} from 'next/entities/features';
import { selectAccount } from 'next/entities/account';
import {
  selectIsInstalled,
  patterns as installedPatterns,
} from 'next/entities/installed';
import {
  selectTrialStatus,
  patterns as trialStatusPatterns,
} from 'next/entities/trial-status';
import {
  selectAccountUsers,
  selectAccountUser,
  patterns as accountUsersPatterns,
} from 'next/entities/account-users';
import { isSpoofing } from 'next/lib/auth';
import { ACCOUNT_SWITCHED } from 'next/entities/accounts';
import { SPOOF_STARTED, SPOOF_STOPPED } from 'next/entities/spoofing';
import toast from 'next/lib/toast';
import {
  getChromeExtensionVersion,
  doubleCheckHasChromeExtension,
} from 'utils/chromeExtension';
import { selectUserId } from 'reducers/user';

function* disableFullStory() {
  const isFullStoryDisabled = yield select(selectFeature, DISABLE_FULLSTORY);

  if (isFullStoryDisabled) {
    window.FS?.shutdown();
  }
}

export function* selectFullStoryDisabled() {
  const isFullStoryDisabled = yield select(selectFeature, DISABLE_FULLSTORY);

  return isFullStoryDisabled;
}

// these actions fire once per session as they describe the resolution
// of data relating to the USER, not the ACCOUNT
const WAIT_ACTIONS = [
  accountUsersPatterns.replace,
  installedPatterns.resolve,
  trialStatusPatterns.resolve,
];

export function* identifyAll() {
  const spoofing = yield call(isSpoofing);

  if (!spoofing) {
    const {
      buffer: account_buffer,
      createdAt: account_createdAt,
      flowsShown: account_flowsShown = 0,
      flowsShownLimit: account_flowsShownLimit = 50,
      id: account_id,
      isTrial: account_isTrial,
      isTrialExpired: account_isTrialExpired,
      lastSeenTimestamp: account_lastSeenTimestamp,
      metrics,
      name,
      npsScore: account_npsScore,
      stripeId: account_stripeId,
      stripePlanId: account_stripePlanId,
      stripeSubCreatedAt: account_stripeSubCreatedAt,
      groupProfileIdentifier,
      userProfileIdentifier,
    } = yield select(selectAccount) || {};

    const {
      appsCount,
      flowsCount: totalFlows = 0,
      mobileFlowsCount = 0,
      publishedFlowsCount: totalFlowsPublished = 0,
      segmentsCount: segments_count = 0,
    } = metrics ?? {};

    const users = yield select(selectAccountUsers) || {};
    const user = yield select(selectUser) || {};
    const currentAccountUser = yield select(selectAccountUser, user.id) || {};

    const {
      id,
      email,
      firstUsedComponents,
      fullname,
      isInvitedUser,
      lastSeenAt,
      createdAt,
      signature,
      job,
    } = user;

    const { role } = currentAccountUser || {};

    const features = yield select(selectFeatures) || {};
    const account_isInstalled = yield select(selectIsInstalled);
    const trialStatus = yield select(selectTrialStatus);

    const { HAS_BETA_FEATURES, TRIAL_BY_DAYS } = features;
    const { lengthDays, isExpired, expirationDate, isWebInstalled } =
      trialStatus || {};
    let hasCrxInstalled;
    let crxVersion;
    try {
      hasCrxInstalled = yield doubleCheckHasChromeExtension(CRX_ID);
      crxVersion = yield getChromeExtensionVersion(CRX_ID);
    } catch (error) {
      console.warn('Chrome or extension not detected');
      console.error(error);
    }

    // for privacy reasons, some users have been allowed to opt-out of
    // studio analytics providers.  exclusions take the form of feature
    // flags and are instrumented below...

    const isFullStoryDisabled = yield selectFullStoryDisabled();

    // use a reference to window.analytics instead of defining it as an external
    // the analytics.js snippet defines "queueing stubs" and uses a
    // createElement to load itself asynchronously.

    // on load, the stub invocations are replayed, but since webpack has wrapped
    // the snippet in a non-window context, analytics.js can't access the
    // queues, and all invocations (read: identifies) are lost.

    if (window.analytics && window.analytics.identify) {
      const commonTraits = {
        account_createdAt,
        ...Object.keys(features).reduce((acc, feature) => {
          acc[`account_feature_${feature}`] = features[feature];
          return acc;
        }, {}),
        account_flowsShown,
        account_flowsShownLimit,
        account_isInstalled,
        account_isTrial,
        account_isTrialExpired,
        account_lastSeenTimestamp,
        account_npsScore,
        account_stripeId,
        account_stripePlanId,
        account_stripeSubCreatedAt,
        company: name,
        isTrial: account_isTrial,
        isTrialExpired: account_isTrialExpired,
        stripeId: account_stripeId,
        stripePlanId: account_stripePlanId,
        users_count: Object.keys(users || {}).length,
        ...(TRIAL_BY_DAYS && {
          lengthDays_trialByDays: lengthDays,
          isExpired_trialByDays: isExpired,
          expirationDate_trialByDays: expirationDate,
          isWebInstalled_trialByDays: isWebInstalled,
        }),
      };

      const identifyTraits = {
        ...commonTraits,
        Account_created_at: account_createdAt,
        accountId: account_id,
        account_buffer,
        account_id,
        account_isBeta: HAS_BETA_FEATURES,
        appcuesId: account_id,
        createdAt,
        created_at: createdAt,
        crxVersion,
        crxVersionFromClient: crxVersion,
        email,
        embedCodeInstalled: account_isInstalled,
        firstUsedComponents,
        fullname,
        hasApps: appsCount > 0,
        hasCrxInstalled,
        id,
        isAdmin: role === 'ACCOUNT_ADMIN',
        isBeta: HAS_BETA_FEATURES,
        isInstalled: account_isInstalled,
        isInvitedUser,
        is_trial: account_isTrial,
        lastSeenTimestamp: lastSeenAt,
        mobileFlowsCount,
        npsScore: account_npsScore,
        only_user_in_account: Object.keys(users || {}) === 1,
        role,
        segments_count,
        totalFlows,
        totalFlowsPublished,
        userEmail: email,
        userId: id,
        userRole: role,
        job,
      };

      const groupTraits = {
        ...commonTraits,
        groupProfileIdentifier,
        userProfileIdentifier,
      };

      const options = {
        integrations: { FullStory: !isFullStoryDisabled },
      };

      window.AppcuesSettings = {
        ...window.AppcuesSettings,
        userIdSignature: signature,
      };

      // https://github.com/appcues/my.appcues.com/blob/171d8e239402198c83e4d4e4c205398983e1795b/app/models/account.coffee#L97-L98
      window.analytics.group(account_id, groupTraits, options);

      // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#managing-data-flow-with-the-integrations-object
      window.analytics.identify(id, identifyTraits, options);
    }

    if (window.ga && window.ga.set) {
      // https://github.com/appcues/my.appcues.com/blob/171d8e239402198c83e4d4e4c205398983e1795b/app/models/account.coffee#L100-L101
      window.ga.set('&uid', account_id);
    }
  }
}

function* identify(payload) {
  try {
    const spoofing = yield call(isSpoofing);
    const isFullStoryDisabled = yield select(selectFullStoryDisabled);
    const options = {
      integrations: { FullStory: !isFullStoryDisabled },
    };
    const userId = yield select(selectUserId);
    if (window.analytics && window.analytics.identify && !spoofing) {
      window.analytics.identify(userId, payload, options);
    }
  } catch (error) {
    toast.error(error);
  }
}

export function* identifyInvitation() {
  const user = yield select(selectUser) || {};
  const api = yield getContext('api');
  const invitations = yield call(api.getInvitations);
  const userInvitation = invitations.find(({ email }) => email === user.email);

  if (userInvitation) {
    yield call(identify, {
      user_invited: userInvitation.inserted_at,
      user_accepted: userInvitation.redeemed_at,
    });
  }
}

export function* identifyWelcomeExperience(arg) {
  const {
    payload: {
      delta: { usageIntent, userIntent, invitations },
    },
  } = arg;

  yield call(identify, {
    ...(usageIntent ? { usage_intent: usageIntent } : {}),
    ...(userIntent ? { user_intent: userIntent } : {}),
    ...(invitations ? { nums_of_invitations: invitations } : {}),
  });
}
function* reset() {
  yield call(window.Appcues?.reset);
}

export default function* identifier() {
  yield takeEvery(accountUsersPatterns.replace, identifyInvitation);
  yield takeEvery(accountUsersPatterns.update, identifyWelcomeExperience);
  yield takeEvery(
    [ACCOUNT_SWITCHED, SPOOF_STARTED, SPOOF_STOPPED, LOGOUT],
    reset
  );
  yield spawn(disableFullStory);
  yield all([...WAIT_ACTIONS.map(take)]);
  yield call(identifyAll);
}
