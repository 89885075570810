import { takeEvery, call, getContext, put } from 'redux-saga/effects';
import { reportError } from 'helpers/error-reporting';
import { getCacheKey } from 'utils/caching';
import { patterns as estimationPatterns, replace } from './actions';

export function* getConditionsEstimate({ payload: { conditions } }) {
  try {
    if (!conditions) {
      throw new Error('getConditionsEstimate called without conditions');
    }
    const estimationApi = yield getContext('estimation');
    const estimate = yield call(
      estimationApi.getConditionsEstimate,
      conditions
    );
    const key = getCacheKey(conditions);
    yield put(replace({ id: key, ...estimate }));
  } catch (error) {
    yield call(reportError, error);
    throw error;
  }
}

export default function* conditionsEstimate() {
  yield takeEvery(estimationPatterns.callApi, getConditionsEstimate);
}
