import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Heading, Modal } from '@studio/legacy-components';
import { asPromised } from 'utils/as-promised';
import { deleteKey } from 'entities/keys';
import { StateDescription, StateNegativeNotice } from './styled';

export const DeleteKey = ({ apiKey, name, visible, onDelete, onClose }) => {
  const [inFlight, setInFlight] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (visible) {
      setInFlight(false);
      setError(false);
    }
  }, [visible]);

  const handleDelete = async () => {
    setInFlight(true);
    setError(false);

    try {
      await onDelete(apiKey);
      onClose();
    } catch {
      setError(true);
      setInFlight(false);
    }
  };

  return (
    <Modal onClose={onClose} size="s" visible={visible}>
      <Heading>Delete API Key</Heading>
      {error && (
        <StateNegativeNotice>
          We&apos;re sorry, something went wrong on our end. Try again.
        </StateNegativeNotice>
      )}
      <StateDescription>
        This will permanently remove the &apos;{name}&apos; API key from your
        account. Any application using it will no longer have access.
      </StateDescription>
      <ButtonGroup right>
        <Button kind="negative" onClick={handleDelete} disabled={inFlight}>
          Delete
        </Button>
        <Button kind="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ButtonGroup>
    </Modal>
  );
};

DeleteKey.propTypes = {
  apiKey: PropTypes.string,
  name: PropTypes.string,
  visible: PropTypes.bool,
  onDelete: PropTypes.func,
  onClose: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  onDelete: id => asPromised(dispatch, deleteKey({ id })),
});

export default connect(null, mapDispatchToProps)(DeleteKey);
