import React from 'react';
import PropTypes from 'prop-types';
import { Shape as TagShape } from 'next/entities/tags';
import { Tag } from 'next/components/Tags';

const sortTags = tags =>
  tags.sort(({ name: a }, { name: b }) => {
    return a.localeCompare(b, 'en', {
      sensitivity: 'base',
      numeric: true,
    });
  });

export function TagsTeaser({ limit, tags }) {
  const sortedTags = sortTags(tags);
  if (sortedTags.length <= limit) {
    return sortedTags.map(({ id, name }) => (
      <Tag key={id} name={name} readOnly title={name} />
    ));
  }

  const rendered = sortedTags.slice(0, limit);
  const remaining = sortedTags.slice(limit).map(({ name }) => name);

  return (
    <>
      {rendered.map(({ id, name }) => (
        <Tag key={id} name={name} readOnly title={name} />
      ))}

      <Tag
        name={`+${remaining.length}`}
        readOnly
        title={remaining.join(', ')}
      />
    </>
  );
}

TagsTeaser.propTypes = {
  limit: PropTypes.number,
  tags: PropTypes.arrayOf(TagShape),
};

export default TagsTeaser;
