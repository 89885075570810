import PropTypes from 'prop-types';

const EventPropType = PropTypes.shape({
  contentName: PropTypes.string,
  name: PropTypes.string,
  stepId: PropTypes.oneOfType([PropTypes.string]),
  stepName: PropTypes.oneOfType([PropTypes.string]),
  stepType: PropTypes.oneOfType([PropTypes.string]),
  timestamp: PropTypes.string,
  url: PropTypes.oneOfType([PropTypes.string]),
});

const ProfilePropType = PropTypes.shape({
  userId: PropTypes.string,
});

const ResultPropType = PropTypes.shape({
  accountId: PropTypes.string,
  event: EventPropType,
  profile: ProfilePropType,
  userId: PropTypes.string,
});

export default PropTypes.arrayOf(ResultPropType);
