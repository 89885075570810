import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'components/Common/Chart';
import withQuery from 'components/Common/QueryResults/withQuery';
import { groupByMonth } from 'components/Common/QueryResults/processors';
import { getQueryConditionsForSelectedEvents } from 'components/Insights/utils';

const { hasOwnProperty } = Object.prototype;

const EventsChart = ({ frequency, series }) => (
  <Chart
    type="line"
    dimension={frequency}
    height={275}
    titleX="Date"
    series={series}
  />
);

EventsChart.propTypes = {
  frequency: PropTypes.oneOf(['day', 'month']),
  series: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string,
      overview_users: PropTypes.number,
      overview_events: PropTypes.number,
      overview_sessions: PropTypes.number,
      users: PropTypes.number,
      events: PropTypes.number,
    })
  ),
};

export const mapPropsToQuery = ({
  startTime,
  endTime,
  segmentId,
  eventName,
  id,
  stepId,
  source,
}) => {
  const conditions = [];
  let metrics = ['overview_users', 'overview_events', 'overview_sessions'];

  if (segmentId) {
    conditions.push(['user_segment_id', '==', segmentId]);
  }

  if (eventName && source) {
    metrics = ['users', 'events'];
    conditions.push(
      ...getQueryConditionsForSelectedEvents([
        { id, stepId, event: eventName, source },
      ])
    );
  }

  return {
    conditions,
    metrics,
    dimensions: ['day'],
    start_time: startTime,
    end_time: endTime,
    meta: {
      tags: {
        feature: 'Event analytics',
        page: 'Event explorer',
        component: 'EventsChart',
        description: 'Default event overview chart',
      },
    },
  };
};

export const mapResultsToSeries = (
  rawResults,
  defaultOpts,
  { frequency } = {}
) => {
  const nonNullResults = rawResults.filter(Boolean);
  const results =
    frequency === 'month' ? groupByMonth(nonNullResults) : nonNullResults;

  const metrics = hasOwnProperty.call(results[0] || {}, 'overview_users')
    ? ['overview_users', 'overview_events', 'overview_sessions']
    : ['users', 'events'];
  const [uniqueUsersMetric, totalEventsMetric, sessionsMetric] = metrics;

  const getSeries = (name, color, metricName) => ({
    ...defaultOpts,
    name,
    color,
    frequency,
    marker: {
      symbol: 'circle',
      fillColor: color,
    },
    fillOpacity: 0.4,
    data: results.map(({ day, month, [metricName]: data }) => ({
      x: month ? Date.parse(month) : Date.parse(day),
      y: data,
    })),
  });

  const series = [
    getSeries('Total Events', '#9D9DFF', totalEventsMetric),
    getSeries('Unique Users', '#6FDDDB', uniqueUsersMetric),
  ];

  if (metrics.includes(sessionsMetric)) {
    series.push(getSeries('Sessions', '#FFB039', sessionsMetric));
  }

  return series;
};

export default withQuery(mapPropsToQuery, mapResultsToSeries)(EventsChart);
