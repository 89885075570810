import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { H2 } from '@studio/legacy-components';
import { selectAccountChecklist } from 'entities/checklists';
import FieldGroup from 'components/Common/Forms/FieldGroup';
import Radio from 'components/Common/UI/Forms/Radio';

const StyledFieldGroup = styled(FieldGroup)`
  .field-group-header {
    margin-bottom: 0;
  }
`;

const openBehaviorOptions = {
  OPENED: 'opened',
  CLOSED: 'closed',
  OPEN_ONCE: 'open_once',
};

export const DisplayContent = ({
  checklistId,
  updateChecklist,
  openBehavior,
}) => {
  const handleRadioChange = value => {
    updateChecklist(checklistId, {
      openBehavior: value,
    });
  };

  return (
    <>
      <H2>Display</H2>
      <StyledFieldGroup title="How should the checklist appear by default?">
        <div>
          <Radio
            label="Open once, closed after the first time"
            onChange={() => handleRadioChange(openBehaviorOptions.OPEN_ONCE)}
            checked={openBehavior === openBehaviorOptions.OPEN_ONCE}
          />
          <Radio
            label="Closed"
            onChange={() => handleRadioChange(openBehaviorOptions.CLOSED)}
            checked={openBehavior === openBehaviorOptions.CLOSED}
          />

          <Radio
            label="Open"
            onChange={() => handleRadioChange(openBehaviorOptions.OPENED)}
            checked={openBehavior === openBehaviorOptions.OPENED}
          />
        </div>
      </StyledFieldGroup>
    </>
  );
};

DisplayContent.propTypes = {
  updateChecklist: PropTypes.func,
  openBehavior: PropTypes.string,
};

const mapStateToProps = (state, { checklistId }) => {
  const checklist = selectAccountChecklist(state, checklistId) ?? {};

  return {
    openBehavior: checklist.openBehavior || openBehaviorOptions.OPEN_ONCE,
  };
};

export default connect(mapStateToProps)(DisplayContent);
