import { call, delay, put, race, take, getContext } from 'redux-saga/effects';

import {
  POLLING_STARTED,
  POLLING_STOPPED,
  replace,
  stopPolling,
} from 'actions/account/flowStatus';

import {
  CONTENT_REVERTED,
  CONTENT_PUBLISHED,
  CONTENT_PUSHED,
  CONTENT_UNPUBLISHED,
} from 'actions/publishing';
import { patterns } from 'actions/account/rules';

import { reportError } from 'helpers/error-reporting';

export const POLLING_INTERVAL = 10000; // 10 seconds

function* pollForStatus(id) {
  try {
    const api = yield getContext('api');
    while (true) {
      const { data } = yield call(api.getFlowContentStatus, id);
      yield put(replace({ id, ...data }));
      /**
       * either wait until the next interval to poll, or immediately fetch again
       * if a rule-replace was dispatched
       */
      yield race([
        delay(POLLING_INTERVAL),
        take([
          patterns.flush,
          'FLUSH_SATISFACTION_TO_FIREBASE',
          CONTENT_REVERTED,
          CONTENT_PUBLISHED,
          CONTENT_PUSHED,
          CONTENT_UNPUBLISHED,
        ]),
      ]);
    }
  } catch (error) {
    reportError(error);
    yield put(stopPolling());
  }
}

export default function* flowStatus() {
  while (true) {
    const { payload: id } = yield take(POLLING_STARTED);
    yield race([call(pollForStatus, id), take(POLLING_STOPPED)]);
  }
}
