import React, { Component } from 'react';
import style from './style';

export default class ModalBase extends Component {
  constructor(props) {
    super(props);
    const effect = props.effect || 'fadeInDown';
    this.setSize(effect);
    this.state = {
      visible: props.visible,
      style: style[effect],
    };
  }

  UNSAFE_componentWillReceiveProps({ visible, effect = 'fadeInDown' }) {
    this.setState({ visible });
    this.setSize(effect);
    this.setStyles(effect);
  }

  setStyles(effect) {
    const { styles } = this.props;
    if (styles) {
      style[effect].panel = {
        ...style[effect].panel,
        ...styles,
      };
    }
  }

  setSize(effect) {
    const { width, height } = this.props;
    if (width) {
      if (width.charAt(width.length - 1) === '%') {
        // Use Percentage
        const w = width.slice(0, -1);
        style[effect].panel.width = `${w}%`;
      } else if (width.charAt(width.length - 1) === 'x') {
        // Use Pixels
        const w = width.slice(0, -2);
        style[effect].panel.width = `${w}px`;
      } else {
        // Defaults
        style[effect].panel.width = `${width}px`;
      }
    }
    if (height) {
      if (height.charAt(height.length - 1) === '%') {
        // Use Percentage
        const h = height.slice(0, -1);
        style[effect].panel.height = `${h}vh`;
      } else if (height.charAt(height.length - 1) === 'x') {
        // Use Pixels
        const h = height.slice(0, -2);
        style[effect].panel.height = `${h}px`;
      } else {
        // Defaults
        style[effect].panel.height = `${height}px`;
      }
    }
  }

  render() {
    const { ariaLabel, children, onClickAway } = this.props;
    const { visible, style: theme } = this.state;
    return (
      <div
        id="modalBase"
        style={visible ? theme.container : theme.containerHidden}
      >
        <div
          role="dialog"
          className="modal-panel"
          aria-label={ariaLabel}
          style={visible ? { ...theme.panel } : theme.panelHidden}
        >
          {children}
        </div>

        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          role="button"
          tabIndex={0}
          aria-label="Close"
          className="modal-mask"
          style={visible ? theme.mask : theme.maskHidden}
          onClick={onClickAway}
        />
      </div>
    );
  }
}
