import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Button, CDialog } from '@appcues/component-library';
import {
  getNestedConditionsFromFlattenedClauses,
  evaluatePageConditions,
} from '@appcues/libcues';
import { ExternalLink, Icon } from '@studio/legacy-components';
import BaseModal from 'components/ModalContainer/BaseModal';
import ClickToCopyText from 'components/Common/ClickToCopyText';

import { trackEvent } from 'actions/events';
import { hideModal } from 'actions/currentModal';
import { getFlowPublishingApiPromise } from 'helpers/publishing-api';

import { reportError } from 'helpers/error-reporting';
import { SUPPORT_EMAIL } from 'constants/externalAppcuesResources';
import {
  TEST_MODE_CLICK_OPEN_BTN,
  TEST_MODE_COPY_TEST_URL,
} from 'constants/events';
import { PUBLISH_ACTIONS } from 'constants/modalStatuses';

export class TestFlowModal extends Component {
  state = { uiState: 'creatingTestFlow', testFlowUrl: '' };

  async componentDidMount() {
    const {
      accountApiKey,
      accountId,
      contentType,
      existingTestFlowId,
      getTestFlowUrl,
      stepId,
      conditions,
      previewUrl,
    } = this.props;

    let testFlowId;

    if (!existingTestFlowId) {
      try {
        ({
          data: { test_content_id: testFlowId },
        } = await getFlowPublishingApiPromise(PUBLISH_ACTIONS.CREATE_TEST, {
          content_id: stepId,
        }));
      } catch (error) {
        reportError(error, { contentType, stepId, accountApiKey, accountId });
        this.setState({ uiState: 'error' });
        return;
      }
    } else {
      testFlowId = existingTestFlowId;
    }

    if (!testFlowId) {
      reportError('No valid test ID returned', {
        contentType,
        stepId,
        accountApiKey,
        accountId,
      });
    }

    const previewUrlMatchesConditions = evaluatePageConditions(
      getNestedConditionsFromFlattenedClauses(conditions),
      previewUrl
    );

    const testFlowUrl = getTestFlowUrl(accountId, stepId);

    if (previewUrlMatchesConditions) {
      this.setState({ uiState: 'testFlowCreated', testFlowUrl });
    } else {
      this.setState({ uiState: 'previewUrlNotMatched', testFlowUrl });
    }
  }

  advanceToTestFlowCreated = () => {
    this.setState({ uiState: 'testFlowCreated' });
  };

  handleUrlCopy = () => {
    const {
      accountId,
      existingTestFlowId,
      stepId,
      trackEvent: track,
    } = this.props;

    track(TEST_MODE_COPY_TEST_URL, {
      accountId,
      existingTestFlowId,
      stepId,
    });
  };

  handleTestBtnClick = () => {
    const {
      accountId,
      existingTestFlowId,
      stepId,
      trackEvent: track,
    } = this.props;

    track(TEST_MODE_CLICK_OPEN_BTN, {
      accountId,
      existingTestFlowId,
      stepId,
    });
  };

  render() {
    const { flowName, previewUrl, hideModal: hide } = this.props;
    const { testFlowUrl, uiState } = this.state;

    let flowText;
    let title;
    switch (uiState) {
      case 'creatingTestFlow': {
        flowText = flowName ? `"${flowName}"` : '';
        title = `Creating ${flowText} Test...`;
        return (
          <BaseModal
            large
            actions={{ hideModal: hide }}
            className="test-flow-modal--creating"
            modalClass="pending-modal"
          >
            <Title>{title}</Title>
            <ExpectationSet className="expectation-set">
              This might take a few seconds.
            </ExpectationSet>
          </BaseModal>
        );
      }

      case 'testFlowCreated': {
        flowText = flowName ? `"${flowName}"` : 'Flow';
        title = `${flowText} Test`;
        return (
          <ReadyModal
            actions={{ hideModal: hide }}
            className="test-flow-modal--ready"
            title={title}
            large
          >
            <Intro className="intro">
              Anyone with the link below can test the flow, as many times as
              they&apos;d like:
            </Intro>
            <ClickToCopy
              text={testFlowUrl}
              hintText="Copy link"
              onCopy={this.handleUrlCopy}
            />
            <ModalFooter>
              <ShareSuggestion>
                <strong>Working with teammates?</strong> Send them the test link
                above!
              </ShareSuggestion>
              <OpenTestButton
                className="button-warning test-flow-open-btn"
                href={testFlowUrl}
                onClick={() => {
                  this.handleTestBtnClick();
                }}
                target="_blank"
              >
                <Icon title="Link to external page" icon="external-link-alt" />{' '}
                Open Test in New Tab
              </OpenTestButton>
            </ModalFooter>
          </ReadyModal>
        );
      }

      case 'previewUrlNotMatched': {
        flowText = flowName ? `"${flowName}"` : '';
        title = "Build URL doesn't match page targeting";
        return (
          <BaseModal
            headerHR
            okText="Continue to Test"
            showCancelButton
            cancelButtonText="Edit Build URL"
            large
            onClickOK={this.advanceToTestFlowCreated}
            afterHandleOk={() => false}
            actions={{ hideModal: hide }}
            title={title}
          >
            <br />
            <p>
              <strong>Heads up!</strong> Your test will start on the build URL:
              &ldquo;{previewUrl}&rdquo;
            </p>
            <p style={{ marginTop: '10px' }}>
              This URL doesn&apos;t match the page targeting you set.
            </p>
            <p style={{ marginTop: '10px' }}>
              If you would like your flow to appear on the page where the test
              starts, edit the build URL to meet this flow&apos;s page targeting
              conditions.
            </p>
          </BaseModal>
        );
      }

      case 'error':
        return (
          <CDialog
            modalClass="pending-modal"
            title="Something went wrong"
            primaryActionText="Okay"
            onPrimaryAction={hide}
          >
            <TextWrapper>
              <Text>
                An error occurred while initializing this test. If this
                persists, please reach out to{' '}
                <ExternalLink href={`mailto:${SUPPORT_EMAIL}`}>
                  support@appcues.com
                </ExternalLink>
              </Text>
            </TextWrapper>
          </CDialog>
        );

      default:
        return null;
    }
  }
}

const mapDispatchToProps = {
  hideModal,
  trackEvent,
};

export default connect(null, mapDispatchToProps)(TestFlowModal);

TestFlowModal.defaultProps = {
  flowName: '',
};

const Title = styled.h2`
  text-align: center;
`;

const ExpectationSet = styled.p`
  font-style: italic;
  text-align: center;
`;

const Intro = styled.p`
  &.intro {
    margin-bottom: 24px;
  }
`;

const ReadyModal = styled(BaseModal)`
  .modal-dialog {
    padding: 0 0 18px 0;

    header {
      border-bottom: 1px solid ${props => props.theme['$gray-1']};
      margin-bottom: 24px;
      padding: 24px;

      /* can be removed when everyone is on rebrand */
      h2 {
        margin: 0;
        padding: 0;
      }
    }

    .base-modal-body {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
`;

const ClickToCopy = styled(ClickToCopyText)`
  margin-bottom: 24px;
`;

const ModalFooter = styled.footer`
  display: flex;
  font-size: 14px;
`;

const ShareSuggestion = styled.p`
  flex: 1;
`;

const OpenTestButton = styled(Button)`
  font-size: 16px;
  line-height: 16px;
  margin-left: 24px;
  max-height: 38px; /* not sure why but this button is otherwise too tall */
`;

const TextWrapper = styled.div`
  margin-top: 24px;
`;

const Text = styled.p`
  line-height: 26px;
`;
