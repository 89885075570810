import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  CPanel,
  CTextArea,
  CForm,
  CTextInput,
} from '@appcues/component-library';
import { updateAccountMeta } from 'actions/account/meta';
import {
  selectAccountMeta,
  selectAccountMetaSynced,
} from 'reducers/account/meta';
import { selectUserRole } from 'reducers/account/users';
import { selectUserId } from 'reducers/user';
import { ACCOUNT_PERMISSIONS } from 'constants/accountManagement/permissions';

const sanitizeWebApplicationDomain = webApplicationDomain =>
  webApplicationDomain?.trim().replace(/\/$/, '');

const sanitizeAvailableDomains = availableDomains =>
  availableDomains
    .replace(/\s/g, '') // Whitespace
    .replace(/,$/, '') // Trailing comma
    .split(',') // Convert to array
    .map(value => {
      let domain = value;

      // Remove protocol
      if (/^https?/.test(domain)) {
        const match = domain.match(/^https?:\/\/(.*?)(?=\/|\?|$)/, '$1');
        domain = match && match[1];
      }

      // Remove port
      return domain.split(':')[0];
    })

    .filter(
      (domain, index, domains) => !!domain && domains.indexOf(domain) === index // Remove empty and duplicates
    );

export function AccountSettingsPanel({
  isMetaSynced = false,
  meta = { name: '', siteUrl: '', domains: [], id: '' },
  onSubmit,
  role,
}) {
  const onSubmitForm = ({
    companyName,
    webApplicationDomain,
    availableDomains,
  }) => {
    const sanitizedDomains = sanitizeAvailableDomains(availableDomains);
    const sanitizedAppDomain =
      sanitizeWebApplicationDomain(webApplicationDomain);

    onSubmit({
      domains: sanitizedDomains,
      siteUrl: sanitizedAppDomain,
      name: companyName,
    });
  };

  if (!isMetaSynced) {
    return false;
  }

  const initialValues = {
    companyName: meta.name,
    webApplicationDomain: meta.siteUrl,
    availableDomains: meta.domains.join(','),
  };
  const accountId = meta.id;
  const canSaveAccountMeta = role !== ACCOUNT_PERMISSIONS.EDITOR;

  return (
    <CPanel title="Account settings">
      <CForm initialValues={initialValues} onSubmit={onSubmitForm}>
        <CForm.Field
          disabled={!canSaveAccountMeta}
          name="companyName"
          label="Company name"
          placeholder="Your company"
        />
        <CTextInput.Group label="Account ID">
          <CTextInput readOnly value={accountId} />
        </CTextInput.Group>
        <CForm.Field
          disabled={!canSaveAccountMeta}
          name="webApplicationDomain"
          label="Web application domain"
          placeholder="yourdomain.com"
        />
        <CForm.Field
          disabled={!canSaveAccountMeta}
          name="availableDomains"
          label="Available domains"
          helperText="A comma-separated list of domains for targeting."
        >
          <CTextArea placeholder="yourdomain.com" />
        </CForm.Field>
        {canSaveAccountMeta && (
          <CForm.SubmitButton type="positive">Save</CForm.SubmitButton>
        )}
      </CForm>
    </CPanel>
  );
}

AccountSettingsPanel.propTypes = {
  meta: PropTypes.shape({
    name: PropTypes.string,
    domains: PropTypes.string,
    id: PropTypes.string,
    siteUrl: PropTypes.string,
  }),
  isMetaSynced: PropTypes.bool,
  onSubmit: PropTypes.func,
};

const mapStateToProps = state => ({
  meta: selectAccountMeta(state),
  isMetaSynced: selectAccountMetaSynced(state),
  role: selectUserRole(state, selectUserId(state)),
});

const mapDispatchToProps = {
  onSubmit: updateAccountMeta,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettingsPanel);
