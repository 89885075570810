import React from 'react';
import { STEP_TYPE_IDS } from 'constants/stepTypes';
import HotspotIcon from 'components/Common/Icons/HotspotIcon';
import ModalIcon from 'components/Common/Icons/ModalIcon';
import RedirectIcon from 'components/Common/Icons/RedirectIcon';
import SlideoutIcon from 'components/Common/Icons/SlideoutIcon';
import TooltipIcon from 'components/Common/Icons/TooltipIcon';

const getIconForStepType = stepTypeId => {
  switch (stepTypeId) {
    case STEP_TYPE_IDS.MODAL:
      return <ModalIcon />;
    case STEP_TYPE_IDS.TOOLTIP_GROUP:
      return <TooltipIcon />;
    case STEP_TYPE_IDS.SLIDEOUT:
      return <SlideoutIcon />;
    case STEP_TYPE_IDS.HOTSPOT_GROUP:
      return <HotspotIcon />;
    case STEP_TYPE_IDS.REDIRECT:
      return <RedirectIcon />;
    default:
      return <ModalIcon />;
  }
};

const StepIcon = ({ stepType, className }) => {
  return <div className={className}>{getIconForStepType(stepType.id)}</div>;
};

export default StepIcon;
