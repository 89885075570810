import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { promisaga } from 'utils/as-promised';
import { resolve, reject, patterns } from './actions';

const resolveDataFormat = response => {
  return response?.data?.data ?? response?.data;
};

function* fetchEndUserProfiles({
  payload: { propertyNames, sortKey, direction, limit, offset },
}) {
  try {
    const api = yield getContext('api');
    const response = yield call(
      api.getUserProfiles,
      propertyNames,
      sortKey,
      direction,
      limit,
      offset
    );

    const data = resolveDataFormat(response);
    yield put(resolve({ profiles: data.endUserProfileList?.profiles || [] }));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  yield takeEvery(patterns.callApi, promisaga(fetchEndUserProfiles));
}
