import React from 'react';
// import styled from 'styled-components';
import { ExternalLink } from '@studio/legacy-components';
import GuideStep from 'components/InstallationGuide/GuideStep';
import GuideHeading from 'components/InstallationGuide/GuideHeading';
import GuideParagraph from 'components/InstallationGuide/GuideParagraph';
import CopyableCodeSnippet from 'components/Common/CopyableCodeSnippet';
import { jsIdentify, groupIdentify } from 'constants/installation/snippets';

const InstallProperties = props => {
  const { moveForward, moveBackward, stepMax, stepIndex } = props;

  return (
    <GuideStep
      stepIndex={stepIndex}
      stepMax={stepMax}
      title="Identify users and groups with properties"
      moveForward={moveForward}
      moveBackward={moveBackward}
    >
      <GuideParagraph>
        The next step is identifying users and accounts in Appcues and their
        profiles: attributes you can use to target content to specific segments.
      </GuideParagraph>
      <GuideHeading>How to identify users</GuideHeading>
      <GuideParagraph>
        The <code className="language-markup">Appcues.identify()</code> call is
        used on each page to identify the user and determine if they should see
        Appcues content. The user ID (a unique identifier) is required.
      </GuideParagraph>
      <CopyableCodeSnippet language="javascript">
        {jsIdentify}
      </CopyableCodeSnippet>
      <GuideParagraph>
        Appcues targeting requires identifying users for each page visit, for
        more details{' '}
        <ExternalLink href="https://docs.appcues.com/en_US/dev-installing-appcues/user-properties-overview">
          check our help doc
        </ExternalLink>
        .
      </GuideParagraph>
      <GuideHeading>How to identify accounts or groups</GuideHeading>
      <GuideParagraph>
        Group properties are attributes you want to associate with your app’s
        own customer accounts instead of individual users. A good rule of thumb
        is that if every user in one of your app's customer accounts would have
        the same property value, then that property might be a group property.
        They are the “adjectives” that describe your customer's accounts.
        Examples include things like Company name, industry, number of
        employees, etc.
      </GuideParagraph>
      <CopyableCodeSnippet language="javascript">
        {groupIdentify}
      </CopyableCodeSnippet>
      <GuideParagraph>
        For more details on group properties,{' '}
        <ExternalLink href="https://docs.appcues.com/en_US/dev-installing-appcues/group-properties-overview">
          check our help doc
        </ExternalLink>
        .
      </GuideParagraph>
    </GuideStep>
  );
};

export default InstallProperties;
