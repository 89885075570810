import React from 'react';
import { Switch, Route } from 'react-router-dom';
import GoalsIndex from 'components/GoalsIndex';
import GoalsCreate from 'components/GoalsCreate';
import GoalsShow from 'components/GoalsShow';

const Goals = () => (
  <Switch>
    <Route path="/goals/new" component={GoalsCreate} />
    <Route path="/goals/:goalId" component={GoalsShow} />
    <Route path="/goals" component={GoalsIndex} />
  </Switch>
);

export default Goals;
