import { useMemo } from 'react';

/**
 * Transforms `selectEntities` results into memoized dropdown options
 * @param  {Object.<string, Object>}} collection object with keys to be transformed into options
 * @param  {string} [labelField = 'name'] name of object field to be used for label
 * @returns {Array.<{ label: string, id: string }>}
 */
export default function useOptions(collection, labelField = 'name') {
  return useMemo(
    () =>
      Object.values(collection)
        .filter(item => item[labelField])
        .map(item => ({
          label: item[labelField],
          value: item.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [collection, labelField]
  );
}
