import moment from 'moment';
import { domain } from 'utils/opscues';
import HTTPError from 'errors/HTTPError';

/**
 * Check if date is today or in the future
 *
 * @param {number} datetime - Date/time value
 * @return {boolean} Whether date is today
 */
const isTodayOrAfter = datetime =>
  moment(datetime).isSameOrAfter(moment(), 'day');

export default function createRbfClient(auth) {
  const baseRbfUrl = domain({
    app: 'rbf',
    /* global APPCUES_ENV */
    env: APPCUES_ENV,
    facet: 'main',
  });

  return {
    requestCSVExport: ({
      apiKey: account_api_key,
      email,
      startDate,
      endDate,
      filter,
      useV2 = false,
    }) =>
      Promise.all([auth.getToken(), auth.getAccountId()]).then(
        ([jwt, account_id]) =>
          fetch(`https://${baseRbfUrl}/v2/csv`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${jwt}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              type: 'multi',
              format: 'csv',
              account_id,
              account_api_key,
              email,
              filter,
              ...(startDate && { start_date: startDate }),
              ...(!isTodayOrAfter(endDate) && { end_date: endDate }),
              ...(useV2 && { version: 2 }),
            }),
          }).then(response => {
            if (response.ok) {
              return response.json();
            }
            throw new HTTPError(response);
          })
      ),
  };
}
