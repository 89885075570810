import React from 'react';
import { addClause } from '@appcues/libcues';
import GroupClause from 'components/Common/Clauses/Group';

export default function SatisfactionAdvancedUrlTargeting({
  className,
  clauses,
  deleteUrlClause,
  rule,
  updateUrlClauses,
}) {
  const handleUpdateClause = (id, newClauseValues) => {
    const urlClauses = clauses || [];
    const changedClause = urlClauses.find(clause => clause.id === id);

    Object.keys(newClauseValues).forEach(key => {
      changedClause[key] = newClauseValues[key] || changedClause[key];
    });

    updateUrlClauses([changedClause], true);
  };

  const handleAddClause = (parentId, clause) => {
    const urlClauses = clauses || [];
    const updatedClauses = addClause(urlClauses, parentId, clause);
    updateUrlClauses(updatedClauses);
  };

  return (
    <div className={`${className} url-targeting`}>
      <GroupClause
        clauses={clauses}
        rule={rule}
        isRootNode
        addGroupClause={false}
        dissolveGroupClause={() => {}}
        addClause={handleAddClause}
        updateClause={handleUpdateClause}
        replaceClause={false}
        deleteClause={id => deleteUrlClause(id)}
      />
    </div>
  );
}
