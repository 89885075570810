const initialState = {
  synced: false,
  features: {},
};

/**
 * NOTE: Feature flags are hydrate during initialization so this reducer is just
 *       an identity function.
 */
export default function features(state = initialState) {
  return state;
}

export const selectAccountFeaturesSynced = state =>
  state.account.features.synced;

export const selectAccountFeatures = state => state.account?.features?.features;

export const selectAccountFeature = (state, feature) => {
  const accountFeatures = selectAccountFeatures(state);

  return Boolean(accountFeatures?.[feature]);
};
