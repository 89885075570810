import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Grid, Box, P, H2, Text } from '@appcues/component-library';
import { ExternalLink, Link } from '@studio/legacy-components';
import useTitle from 'next/hooks/use-title';
import { WEBHOOK_BASIC } from 'next/entities/features';
import manageWebhooksImg from 'assets/images/manage-webhooks.png';
import { readOne as fetchAccountEntitlement } from 'actions/entitlements';
import {
  selectIsEntitlementAllowed,
  selectIsEntitlementSynced,
} from 'reducers/entitlements';
import {
  selectIntegrations,
  selectAreIntegrationsSynced,
} from 'reducers/account/integrations';

import { selectAvailableIntegrationTypes } from 'selectors/integration-types';
import Loader from 'components/Common/Loader';
import { selectAccountFeature } from 'reducers/account/features';
import { PREMIUM_INTEGRATIONS_ENTITLEMENT } from 'constants/entitlements';
import { ENTITLEMENTS } from 'constants/features';
import {
  IntegrationImage,
  IntegrationCardWrapper,
} from 'components/IntegrationsPage/styled';

import IntegrationCard from './IntegrationCard';
import IntegrationBeacon from './IntegrationBeacon';
import { PremiumIntegrationSection } from './PremiumIntegrationSection';

export const IntegrationsPage = ({
  accountIntegrations = {},
  hasEntitlements,
  hasPremiumIntegrations,
  integrationTypes = [],
  isSynced = false,
  onLoad,
  hasWebhooks = false,
}) => {
  useTitle('Integrations | Settings | Appcues');
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  if (!isSynced) {
    return <Loader />;
  }

  // New integrations should just use Hubspot Two-Way.
  const premiumIntegrations = integrationTypes
    .filter(integration => integration.premium)
    .map(integration => {
      // Redefine the availability based on the entitlements, if the feature flag is enabled
      const isIntegrationAvailable = !hasEntitlements
        ? integration.available
        : integration.available && hasPremiumIntegrations;

      return {
        ...integration,
        isEnabled: !!accountIntegrations[integration.id]?.isEnabled,
        available: isIntegrationAvailable,
      };
    });

  const totalPremium = premiumIntegrations.length;
  const totalAvailable = premiumIntegrations.filter(
    integration => integration.available
  ).length;

  return (
    <>
      {hasWebhooks && (
        <>
          <H2 type="secondary">Webhooks</H2>
          <P marginTop={4} type="secondary">
            Create and manage your webhooks.{' '}
            <ExternalLink href="https://docs.appcues.com/en_US/webhooks-management">
              Learn more
            </ExternalLink>
          </P>
          <Grid minItemWidth={225} marginTop={20} marginBottom={40}>
            <Box is={Link} to="/webhooks/">
              <IntegrationCardWrapper
                padding="20px 36px"
                role="button"
                tabIndex={0}
                available
              >
                <IntegrationImage
                  alt="Manage Webhooks"
                  src={manageWebhooksImg}
                  available
                />
              </IntegrationCardWrapper>
            </Box>
          </Grid>
        </>
      )}
      <H2 type="secondary">Integrations</H2>
      <P marginTop={4} type="secondary">
        Track how users interact with your content in the tools you already use.{' '}
        <ExternalLink href="https://www.appcues.com/integrations">
          Learn more about integrations
        </ExternalLink>{' '}
        or{' '}
        <ExternalLink href="https://www.appcues.com/integrations/workflows">
          explore example workflows
        </ExternalLink>
        .
      </P>
      <Grid minItemWidth={225} marginTop={20} marginBottom={60}>
        {integrationTypes
          .filter(integration => !integration.premium)
          .map(integrationType => (
            <IntegrationCard
              key={integrationType.id}
              integration={integrationType}
              isEnabled={accountIntegrations[integrationType.id]?.isEnabled}
            />
          ))}
        <Box
          is="a"
          textDecoration="none"
          href="https://feedback.appcues.com/product-feedback?selectedCategory=integrations"
          target="_blank"
        >
          <IntegrationCardWrapper padding="20px 36px" available>
            <div>
              <Text type="secondary" is="div">
                <strong>Integration request?</strong>
              </Text>
              <Text type="secondary" is="div">
                Let us know
              </Text>
            </div>
          </IntegrationCardWrapper>
        </Box>
      </Grid>
      {totalPremium > 0 && (
        <>
          <H2 type="secondary">Premium Integrations</H2>
          <P marginTop={4} type="secondary">
            Integrations available to Growth and Enterprise customers only.{' '}
            {totalAvailable < totalPremium && (
              <IntegrationBeacon>Contact us to upgrade</IntegrationBeacon>
            )}
          </P>
          <Grid minItemWidth={225} marginTop={20} marginBottom={40}>
            <PremiumIntegrationSection
              premiumIntegrations={premiumIntegrations}
            />
          </Grid>
        </>
      )}
    </>
  );
};

IntegrationsPage.propTypes = {
  integrationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      available: PropTypes.bool,
      base64Image: PropTypes.string,
      description: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.string,
      imageUrl: PropTypes.string,
    })
  ),
  isSynced: PropTypes.bool,
  accountIntegrations: PropTypes.object,
  hasWebhooks: PropTypes.bool,
};

const mapStateToProps = state => ({
  accountIntegrations: selectIntegrations(state),
  integrationTypes: selectAvailableIntegrationTypes(state),
  hasPremiumIntegrations: selectIsEntitlementAllowed(
    state,
    PREMIUM_INTEGRATIONS_ENTITLEMENT
  ),
  hasEntitlements: selectAccountFeature(state, ENTITLEMENTS),
  isSynced:
    selectAreIntegrationsSynced(state) &&
    selectIsEntitlementSynced(state, PREMIUM_INTEGRATIONS_ENTITLEMENT),
  hasWebhooks: selectAccountFeature(state, WEBHOOK_BASIC),
});

const mapDispatchToProps = {
  onLoad: () => fetchAccountEntitlement(PREMIUM_INTEGRATIONS_ENTITLEMENT),
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsPage);
