import React from 'react';
import styled from 'styled-components';
import { CDialog } from '@appcues/component-library';
import { SEGMENT_BLOCKED_MODAL } from 'constants/globals';

const SegmentBlockedModal = ({ actions }) => {
  const hideModal = () => {
    actions.hideModal(SEGMENT_BLOCKED_MODAL);
  };
  return (
    <CDialog
      title="Segment blocked by browser extension"
      primaryActionText="Okay"
      onPrimaryAction={hideModal}
      size="large"
    >
      <Text>
        It looks like one of your ad blocking browser extensions is preventing
        us from connecting to Segment.
      </Text>
      <br />
      <Text>Disable the extension(s) and try again to get installed!</Text>
    </CDialog>
  );
};

export default SegmentBlockedModal;

const Text = styled.p`
  line-height: 24px;
`;
