/**
 * TODO: Add linting rule to disable accessing session objects directly
 */

/**
 * Safely execute callback or return fallback
 *
 * @param {function} callback - Throwable callback
 * @return {any} Return value of callback
 */
const safe = callback => {
  try {
    return callback();
  } catch {
    return null;
  }
};

/**
 * Storage implementation
 *
 * @typedef {object} StorageImpl
 * @property {function} getItem - Get value by key
 * @property {function} setItem - Set value on key
 * @property {function} removeItem - Remove value on key
 */

/**
 * Storage API
 *
 * @typedef {object} StorageAPI
 * @property {function} get - Get value by key
 * @property {function} set - Set value on key
 * @property {function} remove - Remove value on key
 */

/**
 * Create storage API for given storage implementation
 *
 * @param {StorageImpl} implementation - Storage implementation
 * @return {StorageAPI} Safe wrapped storage API
 */
export const create = implementation => {
  /**
   * Safely get and parse value from storage or return fallback
   *
   * @param {string} key - Storage key
   * @param {string} fallback - Fallback value
   * @return {string} Storage value or fallback
   */
  const get = (key, fallback) => {
    const data = safe(() => JSON.parse(implementation.getItem(key)));
    return data || fallback;
  };

  /**
   * Safely set and stringify value to storage
   *
   * @param {string} key - Storage key
   * @param {string} value - Value to set
   * @return {void}
   */
  const set = (key, value) => {
    safe(() => implementation.setItem(key, JSON.stringify(value)));
  };

  /**
   * Safely remove value from storage
   *
   * @param {string} key - Storage key
   * @return {void}
   */
  const remove = key => {
    safe(() => implementation.removeItem(key));
  };

  return { get, set, remove };
};

/**
 * Local storage API
 */
export const local = create(window.localStorage);

/**
 * Session storage API
 */
export const session = create(window.sessionStorage);
