import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@studio/legacy-components';
import Editing from './Editing';
import { CreateTag, Wrapper } from './styled';

export default function Create({
  editing,
  error,
  onCreate,
  onToggle,
  ...unhandledProps
}) {
  if (editing) {
    return (
      <Wrapper {...unhandledProps}>
        <Editing error={error} onClose={onToggle} onConfirm={onCreate} />
      </Wrapper>
    );
  }

  return (
    <Wrapper {...unhandledProps}>
      <CreateTag aria-label="Create" onClick={onToggle}>
        <Icon icon="plus" />
        Create new
      </CreateTag>
    </Wrapper>
  );
}

Create.propTypes = {
  editing: PropTypes.bool,
  error: PropTypes.bool,
  onCreate: PropTypes.func,
  onToggle: PropTypes.func,
};
