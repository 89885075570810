import { takeEvery, call, put, select, getContext } from 'redux-saga/effects';
import toast from 'next/lib/toast';
import {
  replaceUser as replaceUserAction,
  updateUserMeta,
  USER_SYNCED,
  USER_META_UPDATED,
  USER_USAGE_PROPERTY_UPDATED,
  PASSWORD_RESET,
} from 'actions/user';
import {
  selectUserEmail,
  selectUserId,
  selectUserUsageProperties,
} from 'reducers/user';
import { reportError } from 'helpers/error-reporting';

export function* syncUser({ payload: { userId } }) {
  const api = yield getContext('api');
  const { data: user } = yield call(api.getUser, userId);
  yield put(replaceUserAction(user));
}

export function* flushUserMeta({ payload: { changes = {} } }) {
  try {
    const userId = yield select(selectUserId);

    const api = yield getContext('api');
    yield call(api.updateUser, userId, changes);

    yield call(toast.success, 'Profile saved.');
  } catch (error) {
    yield call(toast.error, 'Profile changes failed to save');
    yield call(reportError, error);
  }
}

export function* flushUsageProperties() {
  try {
    const updatedUsageProperties = yield select(selectUserUsageProperties);

    yield call(
      flushUserMeta,
      updateUserMeta({ usageProperties: updatedUsageProperties })
    );
  } catch (error) {
    yield call(reportError, error);
  }
}

export function* resetPassword() {
  try {
    const api = yield getContext('firebaseAuth');
    const email = yield select(selectUserEmail);
    yield call(api.resetPassword, email);
    yield call(toast.success, 'Password reset email sent');
  } catch (error) {
    yield call(toast.error, 'Failed to send reset password email');
    yield call(reportError, error);
  }
}

export function* saga() {
  yield takeEvery(USER_SYNCED, syncUser);
  yield takeEvery(USER_META_UPDATED, flushUserMeta);
  yield takeEvery(USER_USAGE_PROPERTY_UPDATED, flushUsageProperties);
  yield takeEvery(PASSWORD_RESET, resetPassword);
}

export default saga;
