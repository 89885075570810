import React, { useState } from 'react';
import { Button, Tooltip, Link } from '@appcues/sonar';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import styled from 'styled-components';

import { isAfter } from 'next/lib/date';

import { ScheduleType } from 'next/components/ExperienceScheduling/types';
import { SchedulingModal } from 'next/components/ExperienceScheduling/SchedulingModal';
import { add, remove, selectSchedules } from 'entities/schedules';
import { selectIsInstalled } from 'reducers/account/installedDomains';

// We know this is not good
// but needed since we are using a sonar button with classic buttons
// to avoid alignment issues
const StyledButton = styled(Button)`
  max-height: 40px;
`;

// This container is only needed to force useForm to unmount correctly
// hence avoiding showing stale data.
// Other strategies we've tried:
// - forceUnregister configuration, which doesn't solve the problem
// - A key prop on the modal container, still the useForm was showing stale data
// - A useEffect cleaning the data, which caused more undesired side-effects than solving the issue
// - The container which was the solution that works the best and is the least intrusive
export const SchedulingModalProvider = ({
  schedule,
  installed,
  ...modalProps
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasSchedule = !isEmpty(schedule);

  const toggleModal = () => {
    setIsOpen(open => !open);
  };

  return (
    <>
      <Tooltip
        disabled={installed}
        size="regular"
        content={
          <>
            To schedule your experiences to publish, you'll have to install
            Appcues directly into your codebase. Don't worry...it's super easy.{' '}
            <Link
              activeColorToken="foreground-tooltip-link-active"
              colorToken="foreground-tooltip-link"
              href="/settings/installation"
              size="small"
            >
              Click here to learn how.{' '}
            </Link>
          </>
        }
      >
        <StyledButton
          disabled={!installed}
          variant="tertiary"
          onClick={toggleModal}
        >
          {hasSchedule && 'Edit'} Schedule
        </StyledButton>
      </Tooltip>
      {isOpen && (
        <SchedulingModal
          {...modalProps}
          schedule={schedule}
          toggleModal={toggleModal}
        />
      )}
    </>
  );
};

SchedulingModalProvider.propTypes = {
  contentId: PropTypes.string.isRequired,
  schedule: ScheduleType,
  onCreate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  installed: PropTypes.bool,
};

const mapStateToProps = state => {
  const [schedule] = Object.values(selectSchedules(state));
  const areDatesInTheFuture =
    isAfter(schedule?.startDate, new Date()) ||
    isAfter(schedule?.endDate, new Date());

  return {
    schedule: areDatesInTheFuture ? schedule : {},
    installed: selectIsInstalled(state),
  };
};

const mapDispatchToProps = {
  onCreate: add,
  onRemove: remove,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchedulingModalProvider);
