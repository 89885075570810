import { createCollectionActions } from 'next/lib/collections';

export const TYPE = 'user';

export const LOGOUT = 'LOGOUT';
export const logout = () => ({
  type: LOGOUT,
});

export const { patterns, reject, replace, update } =
  createCollectionActions(TYPE);
