import React from 'react';

import moment from 'moment';
import { Notice, NOTICE_TYPES } from '@appcues/component-library';

export const FrequencyCapBanner = props => {
  const { buffer, userProfile, navigateToAccount } = props;
  const { _lastContentShownAt: lastContentShownAt } = userProfile;

  const flowSeenDuringFreqCap =
    buffer && userProfile && Date.now() - lastContentShownAt < buffer;

  const formattedTime = moment(lastContentShownAt)
    .add(buffer, 'ms')
    .fromNow(true);

  if (flowSeenDuringFreqCap) {
    return (
      <Notice type={NOTICE_TYPES.warning}>
        Frequency capping is enabled on your account. This user will not see
        another flow for {formattedTime}. To change this go to your{' '}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,  jsx-a11y/click-events-have-key-events */}
        <a role="button" tabIndex={0} onClick={() => navigateToAccount()}>
          account settings
        </a>
        .
      </Notice>
    );
  }
  return null;
};
