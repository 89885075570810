import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import { CDialog, P } from '@appcues/component-library';

function ThirdCancellationModal({ actions, billing }) {
  const billingDetails = billing.details;
  const firstSubscription =
    billingDetails &&
    billingDetails.subscriptions &&
    billingDetails.subscriptions.data &&
    billingDetails.subscriptions.data[0];
  const plan = firstSubscription && firstSubscription.plan;

  const formattedDate =
    plan &&
    moment(firstSubscription.current_period_end * 1000).format('MMMM D, YYYY');

  return (
    <CDialog
      title="Your subscription is canceled"
      large
      primaryActionText="Okay"
      onPrimaryAction={() => actions.hideModal()}
    >
      <P>Thank you for your feedback.</P>
      <P>
        You will have access to your account until the end of your billing
        period: <strong>{formattedDate}</strong>.
      </P>
    </CDialog>
  );
}

const mapStateToProps = state => {
  return {
    billing: state.billing,
  };
};

export default connect(mapStateToProps)(ThirdCancellationModal);
