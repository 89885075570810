/**
 * Pluralizes the string based on the quantity
 *
 * @param {string} word
 * @param {number} qty
 * @returns string
 */
export const pluralize = (word, qty = 0) =>
  `${qty} ${word}${qty > 1 ? 's' : ''}`;

/**
 * Capitalizes the first letter of a string
 *
 * @param {string} string
 * @returns string
 */
export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
