import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown } from '@studio/legacy-components';
import { selectUser } from 'reducers/user';
import { selectAccountUsers } from 'reducers/account/users';
import { canManageAccountUsers } from 'helpers/account-management';
import { KeyMenuOptions, Opener } from './styled';

export const KeysMenu = ({
  hasAdminPermission = false,
  legacy = false,
  state,
  onOptionClick,
}) => {
  const options = [
    {
      label: 'View details',
      icon: 'chart-bar',
      onClick: () => {
        onOptionClick('view');
      },
    },
  ];

  if (!legacy && hasAdminPermission) {
    if (state === 'active') {
      options.push({
        label: 'Disable',
        icon: 'minus-circle',
        onClick: () => {
          onOptionClick('disable');
        },
      });
    }

    if (state === 'revoked') {
      options.push(
        {
          label: 'Enable',
          icon: 'check-circle',
          onClick: () => {
            onOptionClick('enable');
          },
        },
        {
          label: 'Delete',
          icon: 'trash',
          onClick: () => {
            onOptionClick('delete');
          },
        }
      );
    }
  }

  return (
    <Dropdown attachment={<KeyMenuOptions options={options} />}>
      <Opener icon="ellipsis-h" aria-label="Open menu" />
    </Dropdown>
  );
};

KeysMenu.propTypes = {
  legacy: PropTypes.bool,
  hasAdminPermission: PropTypes.bool,
  state: PropTypes.string,
  onOptionClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const user = selectUser(state);
  const accountUsers = selectAccountUsers(state);

  return {
    hasAdminPermission: canManageAccountUsers({ user, accountUsers }),
  };
};

export default connect(mapStateToProps)(KeysMenu);
