import { createCollectionReducer } from 'next/lib/collections';
import { ENTITLEMENTS } from 'next/entities/features';
import { USER_SEATS_ENTITLEMENT } from 'constants/entitlements';
import { selectAccountFeature } from 'reducers/account/features';
import { determineStatus } from 'utils/entitlements';

export const TYPE = 'entitlements';

export default createCollectionReducer(TYPE);

export const selectIsEntitlementAllowed = (state, name) =>
  state.entitlements[name]?.allowed;

export const selectUserSeatsEntitlement = state =>
  selectIsEntitlementAllowed(state, USER_SEATS_ENTITLEMENT);

export const selectIsEntitlementSynced = (state, name) => {
  const isFeatureEnabled = selectAccountFeature(state, ENTITLEMENTS);

  if (!isFeatureEnabled) return true;

  return selectIsEntitlementAllowed(state, name) !== undefined;
};

export const selectAccountEntitlements = state => state.entitlements;

export const selectAccountEntitlementsProcessed = (state, asObject = false) => {
  const entitlements = selectAccountEntitlements(state) ?? {};

  const entitlementsUsage = Object.values(entitlements).map(
    ({
      baseUnits = 0,
      id,
      grantedUnits,
      purchasedUnits,
      maxUnits,
      unitPrice,
      usedUnits,
    }) => {
      const availableUnits = baseUnits + purchasedUnits + grantedUnits;
      const percent =
        availableUnits > 0 ? (usedUnits / availableUnits) * 100 : 100;
      const currentStatus = determineStatus(percent);

      return {
        availableUnits,
        completePercent: percent,
        currentStatus,
        id,
        maxUnits,
        purchasedUnits,
        // For add-ons, we'll only display the warning in case of overuse
        showWarning: currentStatus === 'over',
        unitPrice,
        usedUnits,
      };
    }
  );

  if (asObject) {
    const entitlementsObject = entitlementsUsage.reduce(
      (acc, curr) => ({ ...acc, [curr.id]: curr }),
      {}
    );

    if (Object.keys(entitlementsObject).length === 0) return null;
    return entitlementsObject;
  }

  return entitlementsUsage;
};

export const selectAccountEntitlementsProcessedSynced = state => {
  const hasEntitlements = selectAccountFeature(state, ENTITLEMENTS);

  if (!hasEntitlements) return true;

  return !!selectAccountEntitlementsProcessed(state);
};
