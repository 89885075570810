import { createCollectionReducer } from 'next/lib/collections';
import { selectRequestStatus } from 'next/entities/requests';
import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

export const selectAccounts = state => state[TYPE];

export const readAccounts = state => ({
  ...selectRequestStatus(state, TYPE),
  data: selectAccounts(state),
});
