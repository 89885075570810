import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Caption, Select, Text } from '@studio/legacy-components';
import CoolTip from 'components/Common/CoolTip';
import { NORMALIZED_PLAN_LIMIT, PLAN_LIMITS } from 'constants/plans';
import {
  selectAccountStats,
  selectAccountStatsSynced,
} from 'reducers/account/stats';
import { numberWithCommas } from 'utils/textFormatting';

import {
  ControlsWrapper,
  MonthlyUsersCount,
  PlanSelectSection,
  PlanSelectHeader,
  SelectWrapper,
} from './styled';

export const selectOptions = PLAN_LIMITS.map(bracket => ({
  label: bracket.name,
  value: bracket.id,
}));

const Controls = ({ allTimeStats, onChangeLimit, planLimit }) => {
  const { monthly_website_users } = allTimeStats;
  const displayUserCount = monthly_website_users
    ? numberWithCommas(monthly_website_users)
    : 'None yet';

  const normalizedLimit =
    planLimit > NORMALIZED_PLAN_LIMIT ? 'high-volume' : `${planLimit}`;

  const selected = selectOptions.find(({ value }) => value === normalizedLimit);

  return (
    <ControlsWrapper>
      <PlanSelectSection>
        <PlanSelectHeader>
          <Text bold>How many monthly active users (MAUs) do you have?</Text>
          <CoolTip
            className="mau-hint"
            tip="# of unique users that log into your platform each month"
          />
        </PlanSelectHeader>
        <SelectWrapper>
          <Select
            value={selected}
            options={selectOptions}
            onChange={onChangeLimit}
            test-id="mau-selector"
          />
        </SelectWrapper>
        <MonthlyUsersCount overLimit={monthly_website_users > planLimit}>
          Current MAU Count: <Caption bold>{displayUserCount}</Caption>
        </MonthlyUsersCount>
      </PlanSelectSection>
    </ControlsWrapper>
  );
};

Controls.propTypes = {
  allTimeStats: PropTypes.shape({
    monthly_website_users: PropTypes.number,
  }),
  onChangeLimit: PropTypes.func,
  planLimit: PropTypes.number,
};

const mapStateToProps = state => ({
  synced: selectAccountStatsSynced(state),
  allTimeStats: selectAccountStats(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
