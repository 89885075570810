import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Select } from '@studio/legacy-components';
import { selectAccountGoals } from 'reducers/account/goals';

const SelectGoal = ({ value, options, onChange }) => {
  const dropdownOptions = [
    ...options,
    ...(value && !options.some(({ value: optValue }) => optValue === value)
      ? [{ label: value, value }]
      : []),
  ];

  const selected = dropdownOptions.find(option => option.value === value);

  return (
    <Select
      value={selected}
      placeholder="Select a Goal"
      options={dropdownOptions}
      isDisabled={dropdownOptions.length === 0}
      onChange={option => onChange(option.value)}
    />
  );
};

SelectGoal.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};

function mapStateToProps(state) {
  const goals = selectAccountGoals(state) || {};
  return {
    options: Object.keys(goals).map(id => ({
      label: goals[id].name || id,
      value: id,
    })),
  };
}

export default connect(mapStateToProps)(SelectGoal);
