import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'qs';
import { CPage, CSkeleton } from '@appcues/component-library';
import { Button, ContentStatus, Icon } from '@studio/legacy-components';
import useTitle from 'next/hooks/use-title';
import { selectFlow } from 'reducers/account/flows';
import { selectAccountUserFullName } from 'reducers/account/users';
import IndexPage from 'components/Common/IndexPage';

import { FLOW_EVENTS } from 'components/Insights/constants';
import { decodeEventNameUrl } from 'components/Insights/utils';
import { ExportManager } from 'components/Insights/Common/ExportManager';
import SingleMetricContent from './SingleMetricContent';
import {
  HeaderWrapper,
  HeaderTitle,
  SubHeaderColumn,
  StyledLink,
} from './styled';

export const FlowMetric = ({
  encodedEventName,
  id,
  flow,
  createdByUserName,
  updatedByUserName,
}) => {
  const eventName = decodeEventNameUrl('flow', encodedEventName);
  const { label = '' } =
    FLOW_EVENTS.find(item => item.type === eventName) || {};
  const title = `${flow?.name}: ${label}`;
  useTitle(`${title} | Events Explorer | Appcues`);

  return (
    <IndexPage
      title={
        !flow ? (
          <CSkeleton height={20} width={400} />
        ) : (
          <HeaderWrapper>
            <HeaderTitle>{title}</HeaderTitle>
            <ContentStatus published={flow.published} state={flow.state} />
          </HeaderWrapper>
        )
      }
      description={
        !flow ? (
          <CSkeleton height={18} width={400} />
        ) : (
          <HeaderWrapper>
            <SubHeaderColumn>
              Flow created {createdByUserName ? `by ${createdByUserName} ` : ''}
              {flow.createdAt && moment(flow.createdAt).format('ll')}
            </SubHeaderColumn>
            <SubHeaderColumn>
              {updatedByUserName || flow.updatedAt ? 'Updated ' : ''}
              {updatedByUserName ? `by ${updatedByUserName} ` : ''}
              {flow.updatedAt && moment(flow.updatedAt).fromNow()}
            </SubHeaderColumn>
          </HeaderWrapper>
        )
      }
      subHeaderActions={
        <>
          <ExportManager events={[{ source: 'flow', event: eventName, id }]} />
          <StyledLink to={`/flows/${id}/analytics`} target="_blank">
            <Button kind="secondary">
              <Icon icon="external-link-alt" />
              Flow details
            </Button>
          </StyledLink>
        </>
      }
    >
      <CPage.Container>
        <SingleMetricContent eventName={eventName} id={id} source="flow" />
      </CPage.Container>
    </IndexPage>
  );
};

FlowMetric.propTypes = {
  flow: PropTypes.shape({
    name: PropTypes.string,
    published: PropTypes.bool,
    state: PropTypes.string,
  }),
  encodedEventName: PropTypes.string,
  id: PropTypes.string,
  createdByUserName: PropTypes.string,
  updatedByUserName: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { encodedEventName },
    },
    location: { search },
  } = ownProps;

  const { id } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const flow = selectFlow(state, id);

  if (!flow) {
    return {
      id,
      encodedEventName,
    };
  }

  return {
    flow,
    id,
    encodedEventName,
    createdByUserName: selectAccountUserFullName(state, flow.createdBy),
    updatedByUserName: selectAccountUserFullName(state, flow.updatedBy),
  };
};

const ConnectedFlowMetric = connect(mapStateToProps)(FlowMetric);

ConnectedFlowMetric.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      encodedEventName: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default ConnectedFlowMetric;
