import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { promisaga } from 'utils/as-promised';
import { patterns, append, reject } from './actions';

const getResponseData = response => {
  return response?.data?.endUserProfileListForEvent;
};

function* fetchEventUserProfiles({ payload: { queryParams, cacheKey } }) {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getEventUserProfiles, queryParams);
    yield put(append({ [cacheKey]: getResponseData(response?.data) }));
  } catch (error) {
    yield put(reject(error));
  }
}
export default function* saga() {
  yield takeEvery(patterns.callApi, promisaga(fetchEventUserProfiles));
}
