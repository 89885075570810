import { NAVIGATE } from 'constants/actionTypes';

/**
 * Dispatches a navigate event to be processed by the `routing/navigate`
 * saga, which will use either Backbone or React to change the route.
 *
 * @param path
 * @returns {{type, payload: {path: *, openInNewTab: boolean}}}
 */

export function navigate(path, openInNewTab = false) {
  return {
    type: NAVIGATE,
    payload: { path, openInNewTab },
  };
}
