import React from 'react';
import { connect } from 'react-redux';
import { Shape as FeaturesShape, selectFeatures } from 'next/entities/features';
import useFeatureOverrides from './use-feature-overrides';
import { AlertBanner } from './styled';

export function FeaturesBanner({ features }) {
  const { overrides } = useFeatureOverrides(features);
  const hasOverrides = Object.keys({ ...overrides }).length > 0;
  if (!hasOverrides) return null;

  return (
    <AlertBanner>
      ⚠️ Heads up: You are currently locally overriding feature flags
    </AlertBanner>
  );
}

const mapStateToProps = state => ({
  features: selectFeatures(state),
});

FeaturesBanner.propTypes = {
  features: FeaturesShape,
};

export default connect(mapStateToProps)(FeaturesBanner);
