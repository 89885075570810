import React from 'react';
import styled from 'styled-components';

const CarouselItem = props => {
  const { children, className } = props;
  return <div className={className}>{children}</div>;
};

export default styled(CarouselItem)`
  display: flex;

  width: ${props => props.itemWidth + props.itemPadding}px;
  height: 100%;
  padding-left: ${props => props.itemPadding}px;

  display: flex;
  flex-direction: row;
  justify-content: center;
`;
