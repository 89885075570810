import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  addClause,
  addGroupClause,
  deleteClause,
  deleteMatchingClauses,
  getFlattenedClausesFromNestedConditions,
  getNestedConditionsFromFlattenedClauses,
  clauseKeys,
} from '@appcues/libcues';
import PageTargeting from 'components/Common/PageTargeting';
import AudienceTargeting from 'components/Common/AudienceTargeting';
import { selectAccountChecklist } from 'entities/checklists';
import { dissolveGroupClause, replaceClause } from 'transforms/clauses';
import {
  setAudienceAllUsers,
  setAudienceCustomSegment,
  setAudienceSegment,
  setDisplayAnyPage,
  setDisplaySpecificPage,
} from 'helpers/rules';

export function ChecklistTargeting({
  checklistId,
  className,
  clauses,
  meta,
  rule,
  updateChecklist,
}) {
  const updateConditions = updatedClauses => {
    const newConditions = getNestedConditionsFromFlattenedClauses(
      updatedClauses,
      null,
      clauseKeys
    );
    updateChecklist(checklistId, {
      rule: { conditions: newConditions },
    });
  };

  const handleAddClause = (stepId, parentId, clause) => {
    const updatedClauses = addClause(clauses, parentId, clause);
    updateConditions(updatedClauses);
  };

  const handleAddGroupClause = (stepId, parentId, boolean, childClauses) => {
    const updatedClauses = addGroupClause(
      clauses,
      parentId,
      boolean,
      childClauses
    );
    updateConditions(updatedClauses);
  };

  const handleDeleteClause = (stepId, clauseId) => {
    const updatedClauses = deleteClause(clauses, clauseId);
    updateConditions(updatedClauses);
  };

  const handleDeleteMatchingClauses = (stepId, transform) => {
    const updatedClauses = deleteMatchingClauses(clauses, transform);
    updateConditions(updatedClauses);
  };

  const handleDissolveGroupClause = (stepId, clauseId) => {
    const updatedClauses = dissolveGroupClause(clauses, clauseId);
    updateConditions(updatedClauses);
  };

  const handleReplaceClause = (stepId, clauseId, replacementClause) => {
    const updatedClauses = replaceClause(clauses, clauseId, replacementClause);
    updateConditions(updatedClauses);
  };

  const handleSetAudienceAllUsers = () => {
    const updatedClauses = setAudienceAllUsers(clauses);
    updateConditions(updatedClauses);
  };

  const handleSetAudienceSegment = segmentId => {
    const updatedClauses = setAudienceSegment(clauses, segmentId);
    updateConditions(updatedClauses);
  };

  const handleSetAudienceCustomSegment = (stepId, replacementClauses) => {
    const updatedClauses = setAudienceCustomSegment(
      clauses,
      replacementClauses
    );
    updateConditions(updatedClauses);
  };

  const handleSetDisplayAnyPage = () => {
    const updatedClauses = setDisplayAnyPage(clauses);
    updateConditions(updatedClauses);
  };

  const handleSetDisplaySpecificPage = (stepId, replacementClauses) => {
    const updatedClauses = setDisplaySpecificPage(clauses, replacementClauses);
    updateConditions(updatedClauses);
  };

  const handleUpdateRule = (stepId, clauseId, changes) => {
    const updatedClauses = clauses.map(clause => {
      if (clause.id === clauseId) {
        return { ...clause, ...changes };
      }
      return clause;
    });
    updateConditions(updatedClauses);
  };

  return (
    <div className={className}>
      <PageTargeting
        addClause={handleAddClause}
        clauses={clauses}
        deleteClause={handleDeleteClause}
        deleteMatchingClauses={handleDeleteMatchingClauses}
        frequencyTargeting={false}
        meta={meta}
        rule={rule}
        setDisplayAnyPage={handleSetDisplayAnyPage}
        setDisplaySpecificPage={handleSetDisplaySpecificPage}
        stepId={checklistId}
        updateClause={handleUpdateRule}
      />
      <br />
      <AudienceTargeting
        stepId={checklistId}
        clauses={clauses}
        addClause={handleAddClause}
        addGroupClause={handleAddGroupClause}
        createSegmentFromRule={() => {}}
        deleteClause={handleDeleteClause}
        dissolveGroupClause={handleDissolveGroupClause}
        replaceClause={handleReplaceClause}
        setAudienceAllUsers={handleSetAudienceAllUsers}
        setAudienceCustomSegment={handleSetAudienceCustomSegment}
        setAudienceSegment={handleSetAudienceSegment}
        updateClause={handleUpdateRule}
        hideSaveAsSegmentOption
      />
    </div>
  );
}

const mapStateToProps = (state, { checklistId }) => {
  const checklist = selectAccountChecklist(state, checklistId);
  const rule = checklist && checklist.rule;
  const clauses = getFlattenedClausesFromNestedConditions(
    checklist.rule.conditions || {}
  );

  return {
    clauses,
    rule,
  };
};

export default connect(mapStateToProps)(styled(ChecklistTargeting)`
  .legend {
    display: flex;
    flex-direction: row;
    padding: 12px 23px 0;
    label {
      font-size: 12px;
      margin-right: 25px;
      color: ${props => props.theme['$gray-30']};
      i {
        margin-right: 8px;
        &.live {
          color: ${props => props.theme.$green};
        }
        &.draft {
          color: ${props => props.theme['$gray-30']};
        }
      }
    }
  }

  .content {
    padding: 5px 23px 0;
    .help-text {
      color: ${props => props.theme['$gray-30']};
      font-style: italic;
      margin: 5px 0;
      a {
        color: ${props => props.theme['$gray-30']};
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          color: ${props => props.theme.$blue};
          text-decoration: underline;
        }
      }
    }
  }
`);
