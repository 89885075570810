import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Modal, Text } from '@studio/legacy-components';
import { Action, Header, Image, Subheader, Wrapper } from './styled';
import illustration from './chrome-extension.png';

const labels = {
  flows: 'Flows',
  pins: 'Pins',
  banner: 'Banner',
};

const OpenInBuilderModal = ({ onClose, onSave, type, visible }) => {
  const [checked, setChecked] = useState(false);

  return (
    <Modal onClose={onClose} size="m" visible={visible}>
      <Wrapper>
        <Image src={illustration} />

        <Header>Opening your page</Header>

        <Subheader>
          When the page loads, remember to{' '}
          <Text>click the Appcues Builder Chrome extension</Text> and your{' '}
          {labels[type]} will open.
        </Subheader>

        <Action>
          <Checkbox
            aria-label="Don't show again"
            defaultChecked={checked}
            onChange={setChecked}
          >
            Don't show again
          </Checkbox>
          <Button kind="primary" onClick={() => onSave(checked)}>
            Continue
          </Button>
        </Action>
      </Wrapper>
    </Modal>
  );
};

OpenInBuilderModal.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  type: PropTypes.oneOf(['flows', 'pins', 'mobile', 'banner', 'launchpad']),
  visible: PropTypes.bool,
};

export default OpenInBuilderModal;
