import React from 'react';
import styled from 'styled-components';

const CarouselItems = props => {
  const {
    children,
    totalItemsWidth,
    translateX,
    transitionLengthInMillis,
    className,
  } = props;

  const carouselItemsStyle = {
    width: `${totalItemsWidth}px`,
    transform: `translate3d(${translateX}px, 0px, 0px)`,
    transition: `all ${transitionLengthInMillis}ms ease-in-out`,
  };

  return (
    <div style={carouselItemsStyle} className={className}>
      {children}
    </div>
  );
};

export default styled(CarouselItems)`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
