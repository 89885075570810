import React from 'react';
import PropTypes from 'prop-types';

import { ErrorMsg, Label, Input } from '@studio/legacy-components';

export const NameField = ({
  error,
  onChange,
  onBlur,
  touched,
  label = 'Name',
  value,
}) => (
  <Label>
    {label}
    <Input
      error={Boolean(error && touched)}
      type="text"
      name="name"
      onChange={onChange}
      onBlur={onBlur}
      value={value}
    />
    {error && touched && <ErrorMsg>{error}</ErrorMsg>}
  </Label>
);

NameField.propTypes = {
  error: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  touched: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.node,
};

export default NameField;
