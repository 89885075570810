import { RANGE_OPTIONS } from '@studio/legacy-components';

export const PROMOTERS = 'promoters';
export const PASSIVES = 'passives';
export const DETRACTORS = 'detractors';

/**
 * Default max duration for range
 */
export const MAX_DURATION = 90;

/**
 * Date range options without all time and 7 day options
 */
export const FULL_NPS_RANGE_OPTIONS = RANGE_OPTIONS.filter(({ value }) => {
  return ![7].includes(value);
});

/**
 * Date range options for export with limited options
 */
export const LIMITED_NPS_RANGE_OPTIONS = [
  {
    label: 'Last 30 days',
    value: 30,
  },
  {
    label: 'Last 90 days',
    value: 90,
  },
  {
    label: 'Last 180 days',
    value: 180,
  },
];
