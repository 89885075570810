import {
  REQUEST_RESOLVED,
  ITEM_REPLACED,
  ITEM_UPDATED,
  ITEM_CREATED,
  ITEM_INSERTED,
  ITEM_REMOVED,
} from 'constants/account/collections';

export function createEntityReducer(entityType) {
  return (state = {}, { type, payload, meta }) => {
    if (meta && meta.type === entityType) {
      switch (type) {
        case ITEM_CREATED:
        case ITEM_REPLACED:
        case ITEM_INSERTED:
        case REQUEST_RESOLVED:
          return payload;
        case ITEM_UPDATED:
          return {
            ...state,
            ...payload,
          };
        default:
          return state;
      }
    }

    return state;
  };
}

export function createCollectionReducer(entityType) {
  return (state = {}, { type, payload, meta }) => {
    if (meta && meta.type === entityType) {
      switch (type) {
        case REQUEST_RESOLVED:
          if (meta.partial) {
            return {
              ...state,
              ...payload,
            };
          }
          return payload;
        case ITEM_UPDATED:
          return {
            ...state,
            [payload.id]: {
              ...state[payload.id],
              ...payload.delta,
            },
          };
        case ITEM_INSERTED:
        case ITEM_REPLACED:
          return {
            ...state,
            [payload.id]: payload,
          };
        case ITEM_REMOVED: {
          const { [payload.id]: omit, ...rest } = state;
          return rest;
        }

        default:
          return state;
      }
    }

    return state;
  };
}

/**
 * NOTE: This should probably be a separate module once we move collections into
 *       its own module rather than spread across action/reducers/etc.
 */

/**
 * Reducer composer
 *
 * @param {...Reducer} reducers - Reducers to compose
 * @return {Reducer} Composed reducer
 */
export function composeReducers(...reducers) {
  if (reducers.length < 2) {
    throw new Error('No need for composition');
  }

  return reducers.reduce((composed, reducer) => (state, action) => {
    const next = reducer(state, action);
    return composed(next, action);
  });
}
