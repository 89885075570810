import { createLifecycleFor } from 'actions/account/collections';

const TYPE = 'charts';

const actions = createLifecycleFor(TYPE);

export const CHART_CREATED = 'CHART_CREATED';
export const createChart = ({
  chartType,
  endTime,
  events,
  mode,
  name,
  segmentId,
  startTime,
  trailingDays,
}) => ({
  type: CHART_CREATED,
  payload: {
    chartType,
    endTime,
    events,
    mode,
    name,
    segmentId,
    startTime,
    trailingDays,
  },
});

export const CHART_DELETED = 'CHART_DELETED';
export const deleteChart = ({ id }) => ({
  type: CHART_DELETED,
  payload: { id },
});

export const {
  insert,
  replace,
  callApi: getChart,
  patterns,
  send,
  resolve,
  reject,
  update,
  flush,
  remove,
} = actions;
