/**
 * Converts checklist actions into a semi-readable english description.
 *
 * @param pageAction
 * @param flowAction
 * @param steps
 * @returns {string}
 */
export function getActionDescription(pageAction, flowAction, steps) {
  let pageActionText;
  let flowActionText;
  if (pageAction && pageAction.attributes && pageAction.attributes.params) {
    const { url } = pageAction.attributes.params;
    pageActionText = url && `go to page ${url}`;
  }
  if (flowAction && flowAction.attributes && flowAction.attributes.params) {
    const { flowId } = flowAction.attributes.params;
    const flowName = flowId && steps[flowId] && steps[flowId].name;
    flowActionText = flowName && `launch flow ${flowName}`;
  }

  if (pageActionText && flowActionText) {
    return `When clicked, ${pageActionText} and ${flowActionText}`;
  }
  if (pageActionText) {
    return `When clicked, ${pageActionText}`;
  }
  if (flowActionText) {
    return `When clicked, ${flowActionText}`;
  }
  return `Edit item to configure an action`;
}
