import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CPanel, Box, Text, P, H3 } from '@appcues/component-library';
import { ExternalLink } from '@studio/legacy-components';
import useTitle from 'next/hooks/use-title';
import LocalizationEmptyState from 'components/Settings/Localization/LocalizationEmptyState';
import AddLanguageForm from 'components/Settings/Localization/AddLanguageForm';
import LocalesTable from 'components/Settings/Localization/LocalesTable';
import Loader from 'components/Common/Loader';
import {
  selectLocales,
  selectAreLocalesSynced,
} from 'reducers/account/locales';

import languages from 'constants/languages';

/**
 * parse the locale conditions tree and return the browser code
 */
const getCode = ({
  and: [
    {
      properties: { value },
    },
  ],
}) => value;

export const Localization = ({ localesById, synced }) => {
  useTitle('Localization | Settings | Appcues');
  const locales = Object.values(localesById);
  const hasLocales = locales.length > 0;

  if (!synced) {
    return <Loader margin="16em" />;
  }

  // Removing any languages already saved as a locale from the dropdown menu
  const localeCodes = new Set(locales.map(loc => getCode(loc.conditions)));
  const filteredLanguages = hasLocales
    ? languages.filter(lang => !localeCodes.has(lang.code))
    : languages;

  return (
    <>
      <H3 fontWeight={600} marginTop={8} marginBottom={24}>
        Make your content multi-lingual
      </H3>
      <Box width={520} marginBottom={20} lineHeight="150%">
        <Text>
          Translating your flow? Tell us which languages you plan to support, as
          well as the criteria we should use when targeting those speakers.
        </Text>
        <P>
          <ExternalLink href="https://docs.appcues.com/article/584-localizing-content">
            How does localization work?
          </ExternalLink>
        </P>
      </Box>
      <CPanel alignItems={!hasLocales ? 'center' : 'normal'} marginBottom={300}>
        {hasLocales ? (
          <LocalesTable locales={locales} />
        ) : (
          <LocalizationEmptyState />
        )}
        <AddLanguageForm
          locales={locales}
          languageOptions={filteredLanguages}
        />
      </CPanel>
    </>
  );
};

function mapStateToProps(state) {
  return {
    localesById: selectLocales(state),
    synced: selectAreLocalesSynced(state),
  };
}

Localization.propTypes = {
  synced: PropTypes.bool,
  localesById: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      conditions: PropTypes.object,
    })
  ),
  onSave: PropTypes.func,
};

Localization.defaultProps = {
  localesById: {},
};

export default connect(mapStateToProps)(Localization);
