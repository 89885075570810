import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  Icon,
  Link,
  Modal,
  Text,
  Tooltip,
} from '@appcues/sonar';
import { faWandMagicSparkles } from '@fortawesome/pro-solid-svg-icons/faWandMagicSparkles';
import { faClockRotateLeft } from '@fortawesome/pro-solid-svg-icons/faClockRotateLeft';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons/faArrowUpRightFromSquare';
import { useToggle } from '@studio/legacy-components';
import { faOctagonExclamation } from '@fortawesome/pro-regular-svg-icons/faOctagonExclamation';
import { MOBILE_PUBLISHING, RESET } from 'next/entities/features';
import { selectAccountFeature } from 'reducers/account/features';
import { asPromised } from 'utils/as-promised';
import { resetFlowHistory } from 'entities/user-qualified-content';
import { ResetFlowModalContent, ResetFlowTriggerContainer } from './styled';

function ResetFlowModal({
  userId,
  hasResetFlowHistory,
  hasMobilePublishing,
  onReset,
}) {
  const [isModalOpen, toggleModal] = useToggle(false);
  return (
    <Modal.Root
      open={isModalOpen}
      trigger={
        <ResetFlowTriggerContainer>
          <Button
            variant="tertiary"
            onClick={toggleModal}
            disabled={!hasResetFlowHistory}
          >
            <Icon icon={faClockRotateLeft} />
            Reset Flow history
          </Button>
          {!hasResetFlowHistory && (
            <Tooltip
              content={
                <Text
                  size="small"
                  lineHeight="large"
                  colorToken="foreground-tooltip"
                >
                  <Link
                    size="small"
                    href="https://www.appcues.com/pricing"
                    icon={faArrowUpRightFromSquare}
                    inline
                    colorToken="foreground-tooltip-link"
                    activeColorToken="foreground-tooltip-link-active"
                  >
                    Upgrade
                  </Link>{' '}
                  to get access to this feature.
                </Text>
              }
            >
              <Badge icon={faWandMagicSparkles}>Upgrade</Badge>
            </Tooltip>
          )}
        </ResetFlowTriggerContainer>
      }
    >
      <Modal.Title>
        <Icon
          colorToken="color-pink-600"
          size="large"
          icon={faOctagonExclamation}
        />
        Reset Flow history
      </Modal.Title>
      <Modal.Close onClick={toggleModal}>
        <Icon size="large" icon={faXmark} />
      </Modal.Close>
      <ResetFlowModalContent>
        Reset this user's history of all {hasMobilePublishing ? 'Web' : ''}{' '}
        Flows they have seen. This action is helpful for retesting live Flows
        with a “Show once” frequency setting. Reporting will not be affected.
      </ResetFlowModalContent>
      {hasMobilePublishing && (
        <ResetFlowModalContent>
          Resetting Flow history does not apply to Mobile Flows.
        </ResetFlowModalContent>
      )}
      <ResetFlowModalContent>
        Are you sure you want to reset the Flow history for User ID {userId}?
      </ResetFlowModalContent>
      <Modal.Actions>
        <Button variant="tertiary" onClick={toggleModal}>
          Cancel
        </Button>
        <Button
          variant="destructive"
          onClick={() => {
            onReset(userId);
            toggleModal();
          }}
        >
          Reset
        </Button>
      </Modal.Actions>
    </Modal.Root>
  );
}

ResetFlowModal.propTypes = {
  userId: PropTypes.number,
  hasResetFlowHistory: PropTypes.bool,
  hasMobilePublishing: PropTypes.bool,
  onReset: PropTypes.func,
};

const mapStateToProps = (state, { userId }) => ({
  userId,
  hasResetFlowHistory: selectAccountFeature(state, RESET),
  hasMobilePublishing: selectAccountFeature(state, MOBILE_PUBLISHING),
});

const mapDispatchToProps = dispatch => ({
  onReset: id => asPromised(dispatch, resetFlowHistory(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetFlowModal);
