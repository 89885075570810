import React, { useState } from 'react';
import styled from 'styled-components';

function SatisfactionQualitativePreview({ className, questionTwo }) {
  const [feedback, setFeedback] = useState('');

  return (
    <div className={className}>
      <div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="back-to-nps" id="back-to-nps">
          Update Your Score
        </a>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="ask-me-later">
          <div className="close-icon">✕</div>
          <div className="ask-me-later-text">Close</div>
        </a>
      </div>
      <div className="qualitative-question" id="qualitative-question">
        <div className="question-text">{questionTwo}</div>
        <div>
          <textarea
            id="feedback-box"
            value={feedback}
            onChange={e => setFeedback(e.target.value)}
          />
        </div>
        <div className="buttons">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="submit-button">Submit</a>
        </div>
      </div>
    </div>
  );
}

export default styled(SatisfactionQualitativePreview)`
  .submit-button {
    background-color: ${props =>
      props.backgroundColor === '#FFFFFF' ? props.foregroundColor : '#FFFFFF'};
    color: ${props =>
      props.backgroundColor === '#FFFFFF' ? '#FFFFFF' : props.backgroundColor};

    &:hover {
      background-color: ${props =>
        props.backgroundColor === '#FFFFFF'
          ? props.foregroundColor
          : '#FFFFFF'};
    }
  }
`;
