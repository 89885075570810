import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { WarningNotice, Paragraph } from '@studio/legacy-components';

const TranslationErrorBanner = styled(WarningNotice)`
  margin-bottom: 8px;
`;

const UploadTranslationModalError = ({ errors, message }) => (
  <TranslationErrorBanner>
    <strong>Whoops! We had an issue uploading your file!</strong>
    {message && <Paragraph>{message}</Paragraph>}
    {errors && (
      <ErrorList>
        {Object.keys(errors).map(e => (
          <li key={e}>{errors[e]}</li>
        ))}
      </ErrorList>
    )}
  </TranslationErrorBanner>
);

const ErrorList = styled.ul`
  margin: 1em;
`;

UploadTranslationModalError.propTypes = {
  errors: PropTypes.object,
  message: PropTypes.string,
};

export default UploadTranslationModalError;
