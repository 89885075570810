import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import {
  Select,
  Input,
  Button,
  DateInput,
  Creatable,
} from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';
import AudienceBaseClause from 'components/Common/Clauses/AudienceBase';
import PropertyTextArea from 'components/Common/Clauses/PropertyTextArea';
import {
  SubClause,
  ClauseWrapper,
  AdditionalInfo,
  RelativeDate,
} from 'components/Common/Clauses/styled';
import {
  selectGroupAttributeOptions,
  selectGroupAttributeTypeaheadOptions,
} from 'entities/group-attributes';

import {
  OPERATORS,
  ABSOLUTE_TIME_OPERATORS,
  DEFAULT_OPERATORS,
  MULTI_VALUE_OPERATORS,
  NUMERIC_OPERATORS,
  RELATIVE_TIME_OPERATORS,
  EXISTENTIAL_OPERATORS,
  MILLISECONDS_PER_DAY,
} from './constants';

export const Groups = props => {
  const {
    clause,
    updateClause,
    groupPropertyOptions = [],
    groupPropertyTypeaheadOptions = [],
  } = props;

  const [showOptions, toggleShowOptions] = useToggle(true);

  const isDefaultOperator = DEFAULT_OPERATORS.has(clause.operator);
  const isRelativeTimeOperator = RELATIVE_TIME_OPERATORS.has(clause.operator);
  const isAbsoluteTimeOperator = ABSOLUTE_TIME_OPERATORS.has(clause.operator);
  const isNumericOperator = NUMERIC_OPERATORS.has(clause.operator);
  const isMultiValueOperator = MULTI_VALUE_OPERATORS.has(clause.operator);

  const handlePropertyChange = ({ value: property }) =>
    void updateClause(clause.id, { property });

  const handleOperatorChange = ({ value: operator }) => {
    if (MULTI_VALUE_OPERATORS.has(operator) && !isMultiValueOperator) {
      return updateClause(clause.id, { operator, value: '' });
    }

    if (NUMERIC_OPERATORS.has(operator) && !isNumericOperator) {
      return updateClause(clause.id, { operator, value: 1 });
    }

    if (RELATIVE_TIME_OPERATORS.has(operator) && !isRelativeTimeOperator) {
      return updateClause(clause.id, { operator, value: MILLISECONDS_PER_DAY });
    }

    if (ABSOLUTE_TIME_OPERATORS.has(operator) && !isAbsoluteTimeOperator) {
      return updateClause(clause.id, {
        operator,
        value: moment().startOf('day').valueOf(),
      });
    }

    if (EXISTENTIAL_OPERATORS.has(operator)) {
      return updateClause(clause.id, {
        operator,
        value: null,
      });
    }

    if (DEFAULT_OPERATORS.has(operator) && !isDefaultOperator) {
      return updateClause(clause.id, {
        operator,
        value: '',
      });
    }

    return updateClause(clause.id, {
      operator,
    });
  };

  const handleValueBlur = ({ target: { value } }) => {
    if (!value) {
      if (isRelativeTimeOperator) {
        updateClause(clause.id, { value: MILLISECONDS_PER_DAY });
      }

      if (isNumericOperator) {
        updateClause(clause.id, { value: 1 });
      }
    }
  };

  const handleValueChange = event => {
    if (isRelativeTimeOperator) {
      const {
        target: { valueAsNumber },
      } = event;
      return updateClause(clause.id, {
        value: Number.isNaN(valueAsNumber)
          ? ''
          : valueAsNumber * MILLISECONDS_PER_DAY,
      });
    }

    if (isAbsoluteTimeOperator) {
      return updateClause(clause.id, {
        value: moment(event).startOf('day').valueOf(),
      });
    }

    if (isNumericOperator) {
      const {
        target: { valueAsNumber },
      } = event;
      return updateClause(clause.id, {
        value: Number.isNaN(valueAsNumber) ? '' : valueAsNumber,
      });
    }

    if (isMultiValueOperator) {
      return updateClause(clause.id, { value: event });
    }

    return updateClause(clause.id, { value: event.value });
  };

  const options = [...groupPropertyOptions];

  if (
    clause.property &&
    !groupPropertyOptions.some(
      ({ value: property }) => property === clause.property
    )
  ) {
    options.push({
      label: clause.property,
      value: clause.property,
    });
  }

  const typeaheadOptions = [...groupPropertyTypeaheadOptions];

  if (
    clause.value &&
    !typeaheadOptions.some(({ value }) => value === clause.value)
  ) {
    typeaheadOptions.push({
      label: clause.value,
      value: clause.value,
    });
  }

  return (
    <ClauseWrapper>
      <AudienceBaseClause {...props}>
        <SubClause>
          <Select
            placeholder="Pick an option"
            value={options.find(
              ({ value: property }) => property === clause.property
            )}
            options={options}
            onChange={handlePropertyChange}
          />
          <Select
            placeholder="Select..."
            value={OPERATORS.find(
              ({ value: operator }) => operator === clause.operator
            )}
            options={OPERATORS}
            onChange={handleOperatorChange}
          />
          {isRelativeTimeOperator && (
            <RelativeDate>
              <Input
                type="number"
                aria-label="value"
                name="value"
                value={clause.value && clause.value / MILLISECONDS_PER_DAY}
                onChange={handleValueChange}
                onBlur={handleValueBlur}
              />
              {clause.value === MILLISECONDS_PER_DAY ? 'day' : 'days'}
              {['> ago', '< ago'].includes(clause.operator) && ' ago'}
            </RelativeDate>
          )}
          {isAbsoluteTimeOperator && (
            <DateInput
              future
              value={new Date(clause.value)}
              onChange={handleValueChange}
            />
          )}
          {isNumericOperator && (
            <Input
              type="number"
              aria-label="value"
              name="value"
              value={clause.value}
              onChange={handleValueChange}
              onBlur={handleValueBlur}
            />
          )}
          {isDefaultOperator && (
            <Creatable
              createOptionPosition="first"
              formatCreateLabel={label => label}
              value={typeaheadOptions.find(
                ({ value }) => value === clause.value
              )}
              placeholder={
                typeaheadOptions.length > 0 &&
                `e.g. ${typeaheadOptions[0].value}`
              }
              options={typeaheadOptions}
              onChange={handleValueChange}
            />
          )}
          {isMultiValueOperator && (
            <Button kind="secondary" onClick={toggleShowOptions}>
              {showOptions ? 'Hide' : 'Show'} {clause.value.split('\n').length}{' '}
              values
            </Button>
          )}
        </SubClause>
      </AudienceBaseClause>

      {showOptions && isMultiValueOperator && (
        <AdditionalInfo>
          <PropertyTextArea value={clause.value} onChange={handleValueChange} />
        </AdditionalInfo>
      )}
    </ClauseWrapper>
  );
};

Groups.propTypes = {
  clause: PropTypes.shape({
    conditionName: PropTypes.string,
    id: PropTypes.string,
    parentId: PropTypes.string,
    checklist: PropTypes.string,
    status: PropTypes.string,
  }),
  updateClause: PropTypes.func,
  groupPropertyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

const mapStateToProps = (state, { clause: { property } }) => ({
  groupPropertyOptions: selectGroupAttributeOptions(state),
  groupPropertyTypeaheadOptions: selectGroupAttributeTypeaheadOptions(
    state,
    property
  ),
});

export default connect(mapStateToProps)(Groups);
