import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import PropTypes from 'prop-types';

import {
  H1,
  H2,
  Page,
  PageActions,
  PageHeader,
  PageTitle,
} from '@studio/legacy-components';
import useTitle from 'next/hooks/use-title';
import { read as fetchAccountEntitlements } from 'actions/entitlements';
import {
  USER_SEATS_ENTITLEMENT,
  PREMIUM_INTEGRATIONS_ENTITLEMENT,
} from 'constants/entitlements';
import Loader from 'components/Common/Loader';
import Plans from 'components/SubscriptionPicker/Plans';
import useEntitlementsFromURL from 'hooks/useEntitlementsFromURL';
import usePlanDataFromURL from 'hooks/usePlanDataFromURL';
import {
  selectAccountMeta,
  selectAccountMetaSynced,
  selectHasSubscription,
} from 'reducers/account/meta';
import {
  selectAccountEntitlementsProcessed,
  selectIsEntitlementSynced,
} from 'reducers/entitlements';
import { selectCurrentPlanData } from 'selectors/billing';

import Addons from './Addons';
import CheckoutPreview from './CheckoutPreview';
import { BackArrow, CustomPageBody } from './styled';

export const UpgradeSubscription = ({
  accountMeta,
  accountCurrentPlan,
  entitlementsProcessed,
  hasSubscription,
  isSynced,
  onLoad,
}) => {
  useTitle('Subscription | Settings | Appcues');
  const location = useLocation();
  const history = useHistory();
  const [plan, setPlan] = useState(accountCurrentPlan);

  const {
    selectedEntitlements,
    selectedEntitlementsCount,
    setSelectedEntitlements,
  } = useEntitlementsFromURL(location);
  const { planData } = usePlanDataFromURL(location);

  useEffect(() => {
    onLoad?.();
  }, []);

  if (!accountMeta.stripeSubscriptionId && plan !== planData) {
    setPlan(planData);
  }

  if (!isSynced) return <Loader />;

  const handleBackAction = () =>
    history.push(`/settings/subscription${location.search}`);

  return (
    <Page>
      <PageHeader>
        <PageTitle>
          <BackArrow icon="chevron-left" onClick={handleBackAction} />
          <H1>Upgrade Plan</H1>
        </PageTitle>

        <PageActions>
          {plan && (
            <CheckoutPreview
              selectedEntitlements={selectedEntitlements}
              selectedEntitlementsCount={selectedEntitlementsCount}
              entitlementsProcessed={entitlementsProcessed}
              hasSubscription={hasSubscription}
              plan={plan}
            />
          )}
        </PageActions>
      </PageHeader>

      <CustomPageBody>
        {hasSubscription && (
          <>
            <H2>1. Confirm your base plan</H2>
            {plan && (
              <Plans
                planLimit={plan.planLimit}
                targetPlan={accountMeta.targetStripePlan}
                isViewMode
              />
            )}
          </>
        )}
        <Addons
          selectedEntitlements={selectedEntitlements}
          entitlementsProcessed={entitlementsProcessed}
          hasSubscription={hasSubscription}
          onChange={({ entitlement, prev, next }) => {
            const delta = next - prev;
            setSelectedEntitlements({
              ...selectedEntitlements,
              [entitlement]: (selectedEntitlements[entitlement] += delta),
            });
          }}
        />
      </CustomPageBody>
    </Page>
  );
};

UpgradeSubscription.propTypes = {
  accountMeta: PropTypes.shape({
    stripeSubscriptionId: PropTypes.string,
  }),
  entitlementsProcessed: PropTypes.object,
  existingPlanData: PropTypes.object,
  hasSubscription: PropTypes.bool,
  isSynced: PropTypes.bool,
  onLoad: PropTypes.func,
};

const mapStateToProps = state => ({
  accountMeta: selectAccountMeta(state),
  accountCurrentPlan: selectCurrentPlanData(state),
  entitlementsProcessed: selectAccountEntitlementsProcessed(state, true),
  hasSubscription: selectHasSubscription(state),
  isSynced:
    selectAccountMetaSynced(state) &&
    selectIsEntitlementSynced(USER_SEATS_ENTITLEMENT) &&
    selectIsEntitlementSynced(PREMIUM_INTEGRATIONS_ENTITLEMENT) &&
    !!selectAccountEntitlementsProcessed(state, true),
});

const mapDispatchToProps = {
  onLoad: fetchAccountEntitlements,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpgradeSubscription);
