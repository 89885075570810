export const SUPPORT_EMAIL = 'support@appcues.com';

export const STATUS_LINK = 'https://status.appcues.com/';

// Installation docs
export const SEGMENT_LINK =
  'https://segment.com/integrations/appcues/?utm_source=partners&utm_medium=setup&utm_campaign=www.appcues.com&ajs_event=Enable%20Flow%20Started&ajs_prop_integration=Appcues';
export const SEGMENT_INSTALL_DOC =
  'https://docs.appcues.com/article/26-install-appcues-segment';
export const INSTALLATION_TESTING_DOCS =
  'https://docs.appcues.com/article/34-debugging-your-appcues-installation';
export const INSTALL_OVERVIEW_URL =
  'https://docs.appcues.com/article/48-install-overview';
export const TECHNICAL_PROPS_DOC_URL =
  'https://docs.appcues.com/article/42-user-properties-technical';
export const PROPERTIES_DOC_URL =
  'https://docs.appcues.com/article/47-user-properties-overview';
export const EVENTS_DOC_URL =
  'https://docs.appcues.com/article/387-events-overview';
export const TECHNICAL_EVENTS_DOC_URL =
  'https://docs.appcues.com/article/156-sending-events-guide-technical';
export const ANONYMOUS_USERS_DOC =
  'https://docs.appcues.com/article/438-anonymous-users';
export const INSTALL_TEMPLATE_LINK =
  'https://docs.appcues.com/article/713-installation-plan';

export const GOALS_DOC_URL = 'http://docs.appcues.com/article/280-goals';

// zapier app link
export const ZAPIER_APP_LINK = 'https://zapier.com/apps/appcues/integrations';

// salesforce typeform link
export const SALESFORCE_TYPEFORM_LINK =
  'https://appcues.typeform.com/to/FMT4bL';
