import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { copyText } from 'utils/clipboard';

const CONFIRM_SHOW_DURATION = 2000;

const TextWrapper = styled.div`
  border: 1px solid ${props => props.theme['$gray-30']};
  border-radius: ${props => props.theme['$border-radius']};
  display: flex;
  margin-bottom: 10px;
  position: relative;
`;

// content-box is here to allow padding to still be applied when
// flexbox is stretching its height
const TextToCopy = styled.span`
  box-sizing: content-box;
  flex: 14;
  padding: 10px;
`;

const CopyWrapper = styled.span`
  display: flex;
  flex: 1;

  &:hover {
    .clicktocopy-hint {
      display: flex;
    }
  }
`;

const CopyButtonWrapper = styled.span`
  align-items: center;
  border-left: 1px solid ${props => props.theme['$gray-30']};
  display: flex;
  flex: 1
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
`;

const CopyButton = styled.a`
  color: ${props => props.theme.$blue};
`;

const HintWrapper = styled.span`
  align-items: center;
  background-color: #fff;
  border-radius: ${props => props.theme['$border-radius']};
  display: none;
  flex: 4;
  height: 100%;
  margin-left: 10px;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 40px;
`;

const CopyHint = styled.span`
  color: ${props => props.theme.$blue};
  text-align: right;
`;

const ConfirmationWrapper = styled.span`
  display: flex;
  height: 100%;
  padding: 3px 0;
  position: absolute;
  top: 0;
  right: 40px;
`;

const ConfirmationPill = styled.span`
  align-items: center;
  background-color: ${props => props.theme.$blue};
  border-radius: ${props => props.theme['$border-radius']};
  display: flex;
  padding: 8px 15px 5px 15px;
`;

const CopyConfirmation = styled.span`
  color: #fff;
  flex: 1;
`;

export default class ClickToCopyText extends Component {
  state = {
    copied: false,
  };

  componentDidUpdate() {
    const { copied } = this.state;
    if (copied) {
      setTimeout(() => {
        this.setState({ copied: false });
      }, CONFIRM_SHOW_DURATION);
    }
  }

  handleCopyClick = () => {
    const { onCopy } = this.props;

    copyText(this.textRef);
    this.setState({ copied: true });

    if (onCopy) {
      onCopy();
    }
  };

  setTextRef = el => {
    this.textRef = el;
  };

  render() {
    const { className, text, hintText } = this.props;
    const { copied } = this.state;

    return (
      <TextWrapper className={className}>
        <TextToCopy className="clicktocopy-text" title="Copy Text">
          <span ref={this.setTextRef}>{text}</span>
        </TextToCopy>
        <CopyWrapper>
          {!copied && (
            <HintWrapper className="clicktocopy-hint">
              <CopyHint>{hintText || 'Copy'}</CopyHint>
            </HintWrapper>
          )}
          {copied && (
            <ConfirmationWrapper>
              <ConfirmationPill>
                <CopyConfirmation className="clicktocopy-confirmation">
                  Copied to clipboard
                </CopyConfirmation>
              </ConfirmationPill>
            </ConfirmationWrapper>
          )}
          <CopyButtonWrapper
            onClick={this.handleCopyClick}
            className="clicktocopy-copy-btn"
          >
            <CopyButton className="fas fa-copy" />
          </CopyButtonWrapper>
        </CopyWrapper>
      </TextWrapper>
    );
  }
}

ClickToCopyText.propTypes = {
  onCopy: PropTypes.func,
};
