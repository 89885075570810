export const STEP_TYPE_IDS = {
  JOURNEY: 'journey',
  SATISFACTION: 'satisfaction-survey',
  MODAL: 'modal',
  HOTSPOT_GROUP: 'hotspot-group',
  TOOLTIP_GROUP: 'tooltip-group',
  SLIDEOUT: 'shorty',
  REDIRECT: 'redirect',
  COACHMARK_GROUP: 'coachmark-group',
  CHECKLIST: 'checklist',
  WAIT_FOR_PAGE: 'wait-for-page',
  UNKNOWN: 'unknown',
};

export const STEP_CATEGORY_IDS = {
  ACTION: 'action',
  CONTENT: 'content',
};

export const MODAL_PATTERNS = {
  MODAL: 'modal',
  LEFT: 'left',
  FULLSCREEN: 'fullscreen',
  SLIDEOUT: 'shorty',
};

export const SLIDEOUT_POSITIONS = {
  TOP_LEFT: 'topLeft',
  TOP: 'top',
  TOP_RIGHT: 'topRight',
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
  BOTTOM_LEFT: 'bottomLeft',
  BOTTOM: 'bottom',
  BOTTOM_RIGHT: 'bottomRight',
};

export const BEACON_STYLES = {
  HIDDEN: 'hidden',
};

export const FIREBASE_TYPES = {
  journeys: 'journey',
  satisfaction: 'satisfaction-surveys',
  flows: 'flow',
  'hotspot-groups': 'hotspot-group',
  'coachmark-groups': 'coachmark-group',
  actions: 'redirect',
  checklists: 'checklist',
};

export const STEP_TYPES = {
  [STEP_TYPE_IDS.JOURNEY]: {
    id: STEP_TYPE_IDS.JOURNEY,
    iconURL: '/assets/images/step-icons/modal.png',
    label: 'Journey',
    childNoun: 'Journey',
    childrenKey: 'steps',
    firebaseKey: 'journeys',
    firebaseType: FIREBASE_TYPES.journeys,
    category: STEP_CATEGORY_IDS.CONTENT,
    contentType: 'journeys',
    defaults: {},
    reducer: 'steps',
  },
  [STEP_TYPE_IDS.SATISFACTION]: {
    id: STEP_TYPE_IDS.SATISFACTION,
    label: 'Satisfaction Survey',
    childNoun: 'Satisfaction Survey',
    childrenKey: 'content',
    firebaseKey: 'satisfaction-surveys',
    firebaseType: FIREBASE_TYPES.satisfaction,
    category: STEP_CATEGORY_IDS.CONTENT,
    contentType: 'satisfaction-surveys',
    defaults: {},
    reducer: 'satisfaction',
  },
  [STEP_TYPE_IDS.CHECKLIST]: {
    id: STEP_TYPE_IDS.CHECKLIST,
    label: 'Checklist',
    childNoun: 'Checklist Item',
    childrenKey: 'items',
    firebaseKey: 'checklists',
    firebaseType: FIREBASE_TYPES.checklists,
    category: STEP_CATEGORY_IDS.CONTENT,
    contentType: 'checklists',
    defaults: {},
    reducer: 'checklists',
  },
  [STEP_TYPE_IDS.MODAL]: {
    id: STEP_TYPE_IDS.MODAL,
    iconURL: '/assets/images/step-icons/modal.png',
    label: 'Modal',
    statsLabel: 'Modals',
    statsChildLabel: 'Modal',
    childNoun: 'Step',
    childrenKey: 'steps',
    firebaseKey: 'flows',
    firebaseType: FIREBASE_TYPES.flows,
    category: STEP_CATEGORY_IDS.CONTENT,
    contentType: 'flows',
    defaults: {
      patternType: MODAL_PATTERNS.MODAL,
      sandbox: true, // Ideally this can go away some day.
      skippable: true,
      backdrop: true,
    },
    reducer: 'steps',
  },
  [STEP_TYPE_IDS.TOOLTIP_GROUP]: {
    id: STEP_TYPE_IDS.TOOLTIP_GROUP,
    iconURL: '/assets/images/step-icons/tooltip.png',
    label: 'Tooltip Tour',
    statsLabel: 'Tooltips',
    statsChildLabel: 'Tooltip',
    childNoun: 'Tooltip',
    childrenKey: 'hotspots',
    firebaseKey: 'hotspot-groups',
    firebaseType: FIREBASE_TYPES['hotspot-groups'],
    category: STEP_CATEGORY_IDS.CONTENT,
    contentType: 'tooltips',
    defaults: {
      skippable: true,
      sequential: true,
      beaconStyle: BEACON_STYLES.HIDDEN,
    },
    reducer: 'steps',
  },
  [STEP_TYPE_IDS.HOTSPOT_GROUP]: {
    id: STEP_TYPE_IDS.HOTSPOT_GROUP,
    iconURL: '/assets/images/step-icons/hotspot.png',
    label: 'Hotspots',
    statsLabel: 'Hotspots',
    statsChildLabel: 'Hotspot',
    childNoun: 'Hotspot',
    childrenKey: 'hotspots',
    firebaseKey: 'hotspot-groups',
    firebaseType: FIREBASE_TYPES['hotspot-groups'],
    category: STEP_CATEGORY_IDS.CONTENT,
    contentType: 'hotspots',
    defaults: {
      skippable: true,
      sequential: false,
    },
    reducer: 'steps',
  },
  [STEP_TYPE_IDS.SLIDEOUT]: {
    id: STEP_TYPE_IDS.SLIDEOUT,
    iconURL: '/assets/images/step-icons/slideout.png',
    label: 'Slideout',
    statsLabel: 'Slideouts',
    statsChildLabel: 'Slideout',
    childNoun: 'Step',
    childrenKey: 'steps',
    firebaseKey: 'flows',
    firebaseType: FIREBASE_TYPES.flows,
    category: STEP_CATEGORY_IDS.CONTENT,
    contentType: 'flows',
    defaults: {
      patternType: MODAL_PATTERNS.SLIDEOUT,
      sandbox: true,
      skippable: true,
      backdrop: false,
      position: SLIDEOUT_POSITIONS.BOTTOM_LEFT,
    },
    reducer: 'steps',
  },
  // Coachmarks aren't being created in the new app currently, so they don't
  // need as much defined. This is mainly to help stay compatible with old
  // data.
  [STEP_TYPE_IDS.COACHMARK_GROUP]: {
    id: STEP_TYPE_IDS.COACHMARK_GROUP,
    label: 'Coachmarks',
    childNoun: 'Coachmark',
    childrenKey: 'hotspots',
    firebaseKey: 'coachmark-groups',
    firebaseType: FIREBASE_TYPES['coachmark-groups'],
    category: STEP_CATEGORY_IDS.CONTENT,
    contentType: 'coachmarks',
  },
  [STEP_TYPE_IDS.REDIRECT]: {
    id: STEP_TYPE_IDS.REDIRECT,
    icon: 'share',
    label: 'Redirect Page',
    childNoun: 'Redirect',
    firebaseKey: 'actions',
    firebaseType: FIREBASE_TYPES.actions,
    category: STEP_CATEGORY_IDS.ACTION,
    defaults: {
      newTab: false,
    },
    reducer: 'steps',
  },
  [STEP_TYPE_IDS.WAIT_FOR_PAGE]: {
    id: STEP_TYPE_IDS.WAIT_FOR_PAGE,
    icon: 'share',
    label: 'Wait For Page',
    childNoun: 'Wait For Page',
    firebaseKey: 'actions',
    firebaseType: FIREBASE_TYPES.actions,
    category: STEP_CATEGORY_IDS.ACTION,
    defaults: {
      newTab: false,
    },
    reducer: 'steps',
  },
  [STEP_TYPE_IDS.UNKNOWN]: {
    id: STEP_TYPE_IDS.UNKNOWN,
    label: 'Unknown',
  },
};

export const STEP_REDUCER_FIREBASE_KEYS = Object.values(STEP_TYPES)
  .filter(it => it.reducer === 'steps')
  .reduce(
    (acc, { firebaseKey, firebaseType }) => ({
      ...acc,
      [firebaseKey]: firebaseType,
    }),
    {}
  );

export const STEP_TYPE_IDS_WITHOUT_CHILDREN = new Set([
  STEP_TYPE_IDS.REDIRECT,
  STEP_TYPE_IDS.WAIT_FOR_PAGE,
]);

export function isRedirectStep(step) {
  return (
    step &&
    step.stepType &&
    step.actionType &&
    step.stepType === STEP_CATEGORY_IDS.ACTION &&
    step.actionType === STEP_TYPE_IDS.REDIRECT
  );
}
