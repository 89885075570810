import styled from 'styled-components';
import {
  Button,
  Caption,
  ExternalLink,
  H2,
  H3,
  Link,
  Modal,
  Notice,
  Overflow,
  Panel,
  Progress,
  SuccessNotice,
  Text,
} from '@studio/legacy-components';

// Commons
export const HorizontalWrapper = styled.div`
  display: flex;
  align-items: baseline;

  ${H2} {
    margin-right: 10px;
  }
`;

export const StyledNotice = styled(SuccessNotice)`
  width: 100%;

  > div {
    display: flex;
  }

  strong:nth-child(1) {
    margin-left: 16px;
    margin-right: 4px;
  }

  strong:last-child {
    margin-left: 4px;
  }

  button {
    &:focus {
      outline: none;
    }
  }
`;

// Progress bar
export const AccountUsage = styled.div`
  ${Progress} {
    height: 8px;
    background: var(--myst);
    box-shadow: none;
    margin-top: 12px;
  }

  ${Progress} > div {
    ${({ kind }) => {
      switch (kind) {
        case 'warning':
          return 'background: var(--rubber-ducky);';
        case 'over':
          return 'background: var(--motorola-razr);';
        default:
          return 'background: var(--blues-cues);';
      }
    }}
  }
`;

export const WarningWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
  margin-top: 20px;

  ${Caption} {
    font-size: var(--regular);
    color: var(--oh-hi-dark);
    font-weight: var(--bold);
  }
`;

// Current Period
export const PlanFeatureList = styled.ul``;

// Subscription
export const EntitlementsWrapper = styled.div``;

export const SubscriptionCardWrapper = styled(Panel)`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 22px 24px;

  > ${H2} {
    margin-bottom: 22px;
  }

  ${PlanFeatureList} {
    margin-top: 30px;
  }

  ${AccountUsage}:not(:first-child) {
    margin-top: 24px;
  }

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 6px;
    border-radius: 6px 6px 0px 0px;

    ${({ kind }) => {
      switch (kind) {
        case 'warning':
          return 'background: var(--rubber-ducky);';
        case 'over':
          return 'background: var(--motorola-razr);';
        case 'borderless':
          return 'background: transparent;';
        default:
          return 'background: var(--daba-dee-daba-die);';
      }
    }}
`;

export const PlanHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

// Cost breakdown
export const CostLineItem = styled(HorizontalWrapper)`
  justify-content: space-between;
`;

export const HorizontalDivider = styled.hr`
  width: 100%;
  border: 1px solid var(--tint-black);
`;

export const SubtotalPriceWrapper = styled(Overflow)`
  align-items: flex-end;
  gap: 0px;
`;

// Your Plan
export const CostBreakdownLink = styled(Link)``;

export const PlanUsage = styled.div`
  display: flex;
  grid-gap: 24px;
  width: 100%;

  > ${SubscriptionCardWrapper}, > ${Panel} {
    width: calc(50% - 12px);
  }
`;

export const TaxText = styled(Text)`
  font-style: italic;
`;

export const NextBillingText = styled(Text)`
  color: var(--sharkbait-ooh-la-la);
`;

export const DoneButton = styled(Button)`
  width: 72px;
  align-self: flex-end;
`;

export const CostBreakdownModalWrapper = styled(Modal)`
  padding: 24px;

  ${H3} {
    margin-bottom: 8px;
  }

  ${CostLineItem},
  ${HorizontalDivider},
  ${NextBillingText} {
    margin-top: 15px;
  }

  ${DoneButton} {
    margin-top: 26px;
  }
`;

// Installation Issues
export const MauHigherReason = styled(Caption)`
  font-size: var(--regular);
  color: var(--blurple);
  font-weight: var(--bold);
  cursor: pointer;
`;

export const InstallationIssuesWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;

  ${Text} {
    margin-right: 5px;
  }
`;

export const InstallationIssuesNotice = styled(Notice)``;

export const InstallationIssuesBody = styled.div`
  > ${Text}:nth-child(1) {
    margin-bottom: 24px;
  }

  > ${Text}:nth-child(2) {
    margin-bottom: 12px;
  }

  ${InstallationIssuesNotice} {
    margin-top: 12px;
  }

  ${ExternalLink} {
    text-decoration: none;
    color: var(--blurple);
  }
`;

export const IssueLineItem = styled.div`
  display: flex;
  align-items: baseline;

  ${H2} {
    margin-right: 5px;
    margin-bottom: 0;
  }
`;

export const TrialMeterPanelWrapper = styled.div`
  display: flex;
  align-items: baseline;
  white-space: nowrap;
`;

export const TrialProgressBarWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  width: 400px;
  margin-left: 32px;
`;

export const Message = styled.span`
  margin-bottom: 16px;
  font-weight: 400;
`;

export const TermsOfServiceWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
