import {
  BILLING_PERIODS,
  BILLING_INTERVALS,
  NPS_PLAN_TYPES,
} from 'constants/plans';

const { hasOwnProperty } = Object.prototype;

export function isCustomer(accountMeta = {}) {
  return (
    hasOwnProperty.call(accountMeta, 'stripePlanId') &&
    accountMeta.stripePlanId.length > 0
  );
}

export function isNpsOnlyPlan(planId) {
  if (!planId) {
    return false;
  }

  const isNpsOnly = NPS_PLAN_TYPES.find(plan => {
    return planId.includes(plan.id);
  });

  return !!isNpsOnly;
}

export function normalizeBillingIntervalName(interval) {
  const normalizedBillingIntervalName = BILLING_PERIODS.find(
    billingInterval => {
      return (
        BILLING_INTERVALS[billingInterval].name === interval ||
        (BILLING_INTERVALS[billingInterval].aliases &&
          BILLING_INTERVALS[billingInterval].aliases.includes(interval))
      );
    }
  );

  return normalizedBillingIntervalName;
}
