import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Switch } from '@appcues/component-library';
import { H2 } from '@studio/legacy-components';
import { selectAccountChecklist } from 'entities/checklists';
import FieldGroup from 'components/Common/Forms/FieldGroup';
import CharacterCount from 'components/Common/Forms/CharacterCount';
import Input from 'components/Common/Forms/Input';
import Textarea from 'components/Common/Forms/Textarea';

const Container = styled.div`
  margin-bottom: 2.5rem;
  max-width: 65%;
  padding-right: 24px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderActionContainer = styled.div`
  display: flex;
  align-items: center;
  .toggle-dismiss-label {
    margin-right: 5px;
  }
`;

export function DismissContent({
  isDismissable,
  dismissText,
  dismissTextError,
  dismissPromptText,
  dismissCancelText,
  dismissCancelTextError,
  dismissConfirmText,
  dismissConfirmTextError,
  resetDismissState,
  setDismissState,
  updateChecklistTextToken,
  updateLimitedChecklistTextToken,
  updateChecklistCanDismissFlag,
}) {
  const updateChecklistDismissText = input => {
    updateLimitedChecklistTextToken('dismissText', 20, input);
  };

  const updateChecklistDismissPromptText = input => {
    updateChecklistTextToken('dismissPromptText', input);
  };

  const updateChecklistDismissCancelText = input => {
    updateLimitedChecklistTextToken('dismissCancelText', 10, input);
  };

  const updateChecklistDismissConfirmText = input => {
    updateLimitedChecklistTextToken('dismissConfirmText', 10, input);
  };

  return (
    <Container>
      <HeaderContainer>
        <H2>Dismiss Content</H2>

        <HeaderActionContainer className="toggle-dismiss-container">
          <span
            className={`toggle-dismiss-label ${isDismissable ? 'toggled' : ''}`}
          >
            Allow Users to Dismiss This Checklist
          </span>
          <Switch
            checked={isDismissable}
            onChange={updateChecklistCanDismissFlag}
            name="toggle-dismiss"
          />
        </HeaderActionContainer>
      </HeaderContainer>
      {isDismissable && (
        <>
          <FieldGroup title="Link Text" className="configuration-item">
            <Input
              type="text"
              className="checklist-text-short test-dismiss-text"
              value={dismissText}
              placeholder="No thanks!"
              invalid={dismissTextError}
              onChange={updateChecklistDismissText}
              onBlur={resetDismissState}
              onFocus={setDismissState}
            />
            <CharacterCount input={dismissText} maxLength={20} />
          </FieldGroup>
          <FieldGroup title="Prompt Text" className="configuration-item">
            <Textarea
              type="text"
              className="checklist-textarea-long test-dismiss-prompt"
              value={dismissPromptText}
              placeholder="Are you sure you want to dismiss this list?"
              onChange={updateChecklistDismissPromptText}
              onBlur={resetDismissState}
              onFocus={setDismissState}
            />
          </FieldGroup>
          <FieldGroup title="Cancel Button Text" className="configuration-item">
            <Input
              type="text"
              className="checklist-text-short test-dismiss-cancel"
              value={dismissCancelText}
              placeholder="Keep it"
              invalid={dismissCancelTextError}
              onChange={updateChecklistDismissCancelText}
              onBlur={resetDismissState}
              onFocus={setDismissState}
            />
            <CharacterCount input={dismissCancelText} maxLength={10} />
          </FieldGroup>
          <FieldGroup
            title="Confirm Button Text"
            className="configuration-item"
          >
            <Input
              type="text"
              className="checklist-text-short test-dismiss-confirm"
              value={dismissConfirmText}
              placeholder="Dismiss it"
              invalid={dismissConfirmTextError}
              onChange={updateChecklistDismissConfirmText}
              onBlur={resetDismissState}
              onFocus={setDismissState}
            />
            <CharacterCount input={dismissConfirmText} maxLength={10} />
          </FieldGroup>
        </>
      )}
    </Container>
  );
}

DismissContent.propTypes = {
  dismissCancelText: PropTypes.string,
  dismissCancelTextError: PropTypes.bool,
  dismissConfirmText: PropTypes.string,
  dismissConfirmTextError: PropTypes.string,
  dismissPromptText: PropTypes.string,
  dismissText: PropTypes.string,
  dismissTextError: PropTypes.bool,
  resetDismissState: PropTypes.func,
  setDismissState: PropTypes.func,
  updateChecklistTextToken: PropTypes.func,
  updateLimitedChecklistTextToken: PropTypes.func,
  isDismissable: PropTypes.bool,
};

const mapStateToProps = (state, { checklistId }) => {
  const checklist = selectAccountChecklist(state, checklistId);
  const dismissText = checklist.textTokens && checklist.textTokens.dismissText;
  const dismissCancelText =
    checklist.textTokens && checklist.textTokens.dismissCancelText;
  const dismissConfirmText =
    checklist.textTokens && checklist.textTokens.dismissConfirmText;
  const dismissPromptText =
    checklist.textTokens && checklist.textTokens.dismissPromptText;
  const isDismissable =
    'isDismissable' in checklist ? checklist.isDismissable : true;

  return {
    dismissText,
    dismissCancelText,
    dismissConfirmText,
    dismissPromptText,
    isDismissable,
  };
};

export default connect(mapStateToProps)(DismissContent);
