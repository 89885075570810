import styled from 'styled-components';
import { Table, Td, Th, Tr } from '@studio/legacy-components';

export const SegmentTable = styled(Table)`
  ${Tr}:nth-child(10) {
    ${Td} {
      border-bottom: none;
    }
  }

  ${Th}:nth-child(1), ${Th}:nth-child(3) , ${Th}:nth-child(4) {
    width: 20%;
  }
  ${Th}:nth-child(2) {
    width: 40%;
  }
`;

export const SegmentRow = styled(Tr)`
  ${Td} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 300px;
  }
`;
