import { connect } from 'react-redux';
import {
  selectScreenshot,
  readOneExperience,
  ExperienceScreenshotShape,
  getImageHashKeys,
} from 'next/entities/screenshots';

import { Screenshot } from './Screenshot';

const mapStateToProps = (
  state,
  { step, locale, size, version, state: experienceState }
) => {
  const imageHash = getImageHashKeys({
    step,
    locale,
    size,
    version,
    state: experienceState,
  });
  const { url } = selectScreenshot(state, { hash: imageHash }) ?? {};
  return { url, imageHash };
};

const mapDispatchToProps = {
  onLoad: readOneExperience,
};

const ConnectedScreenshot = connect(
  mapStateToProps,
  mapDispatchToProps
)(Screenshot);

ConnectedScreenshot.propTypes = {
  step: ExperienceScreenshotShape,
};

export default ConnectedScreenshot;
