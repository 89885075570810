import { createSelector } from 'reselect';
import { createCollectionReducer } from 'reducers/account/collections';
import { selectIsSynced } from 'reducers/account/requests';
import { selectAccountMeta } from 'reducers/account/meta';

const TYPE = 'keys';

export default createCollectionReducer(TYPE);

export const selectKeys = state => state.account.keys;
export const selectKeysAsList = createSelector(selectKeys, keys =>
  Object.values(keys || {})
);

export const selectKeysSynced = state => selectIsSynced(state, TYPE);

/**
 * Returns a list of combined legacy key at the top
 * followed by the new api keys sorted by newest
 */
export const selectAllApiKeys = createSelector(
  selectKeysAsList,
  selectAccountMeta,
  (keys, meta) => {
    const legacyKey = {
      inserted_at: meta.createdAt,
      key: meta.uuid,
      legacy: true,
      name: 'Legacy API Key',
      role_id: 'account-admin',
      state: 'active',
    };

    const sortedKeys = [...keys];
    sortedKeys.sort(
      (a, b) => new Date(b.inserted_at) - new Date(a.inserted_at)
    );

    return [legacyKey, ...sortedKeys];
  }
);

export const selectKey = (state, id) => selectKeys(state)[id];
