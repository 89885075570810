import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { takeEveryPromised } from 'next/lib/effects';
import { replacePagePatternFor } from 'next/entities/page';
import { patterns as pinPatterns } from 'next/entities/pins';
import { flush, insert, patterns, prune, reject, resolve } from './actions';
import { parse } from './shape';

/**
 * Normalize tags summary response as collection
 *
 * @param {Response} response - Raw tags summary response
 * @return {Collection} Tags collection
 */
const transform = response =>
  response.reduce((acc, tag) => {
    const { id } = tag;
    acc[id] = parse(tag);
    return acc;
  }, {});

function* fetchTags() {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getTags);
    yield put(resolve(transform(response || {})));
  } catch (error) {
    yield put(reject(error));
  }
}

function* createTag({ payload: data }) {
  try {
    const api = yield getContext('api');
    const tag = yield call(api.createTag, data);
    yield put(insert(tag));

    return tag;
  } catch (error) {
    yield put(reject(error));
    throw error;
  }
}

function* updateTag({ payload: { id, delta } }) {
  try {
    const api = yield getContext('api');
    yield call(api.updateTag, id, delta);
    yield put(flush(id));
  } catch (error) {
    yield put(reject(error));
    throw error;
  }
}

function* removeTag({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    yield call(api.deleteTag, id);
    yield put(prune(id));
  } catch (error) {
    yield put(reject(error));
    throw error;
  }
}

export default function* saga() {
  // Internal actions
  yield takeEvery(patterns.read, fetchTags);
  yield takeEveryPromised(patterns.create, createTag);
  yield takeEveryPromised(patterns.update, updateTag);
  yield takeEveryPromised(patterns.remove, removeTag);

  // External actions
  yield takeEvery(pinPatterns.read, fetchTags);

  // Page actions
  yield takeEvery(
    [
      replacePagePatternFor('/flows'),
      replacePagePatternFor('/pins'),
      replacePagePatternFor('/banners'),
    ],
    fetchTags
  );
}
