import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { selectAccountFeature } from 'reducers/account/features';

import Profile from './Profile';
import { Audiences } from './Audiences';

const Audience = ({ hasS5UserProfile }) => (
  <Switch>
    {/* 
        These are placeholder routes for the audience,
        the components for the routes are currently under development 
        and once merged should be set as component prop in the routes bellow
     */}
    {hasS5UserProfile ? (
      <Redirect from="/audience/users/:userId" to="/users-v2/:userId" />
    ) : (
      <Route path="/audience/users/:userId" component={Profile} />
    )}
    <Route path="/audience" component={Audiences} />
  </Switch>
);

Audience.propTypes = {
  hasS5UserProfile: PropTypes.bool,
};

const mapStateToProps = state => ({
  hasS5UserProfile: selectAccountFeature(state, 'S5_USER_PROFILE'),
});

export default connect(mapStateToProps)(Audience);
