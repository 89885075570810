import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconButton, Icon, Tooltip } from '@studio/legacy-components';
import { MAX_SELECTED_EVENTS } from 'components/Insights/constants';

const ButtonToggle = styled(IconButton)`
  --event-toggle-unchecked: var(--sea-monsters);
  --event-toggle-checked: var(--sharkbait-ooh-la-la);
  --event-toogle-transition: all 200ms ease-in-out;

  border-radius: 50%;
  color: var(--white);
  height: 16px;
  width: 16px;
  font-size: 12px;
  transition: var(--event-toogle-transition);
  background-color: var(--event-toggle-unchecked);

  ${Icon} {
    transition: var(--event-toogle-transition);
    transform: rotate(0);
  }

  &[aria-pressed='true'] {
    background-color: var(--event-toggle-checked);

    ${Icon} {
      transform: rotate(45deg);
    }
  }

  &[aria-pressed='false'] {
    background-color: var(--event-toggle-unchecked);

    ${Icon} {
      transform: rotate(0);
    }
  }

  &[disabled] {
    background-color: var(--disabled);
  }

  &:hover {
    color: var(--white);
  }

  &:focus {
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px var(--input-focus);
  }
`;

const EventToggle = ({ label, checked, disabled, onClick }) => {
  return (
    <Tooltip
      label={`You have selected the maximum of ${MAX_SELECTED_EVENTS} events. Deselect an event from the chart above to add another.`}
      size="m"
      disabled={checked || !disabled}
      wrapped
    >
      <ButtonToggle
        aria-pressed={checked}
        label={`Event toggle: ${label}`}
        icon="plus"
        disabled={disabled && !checked}
        onClick={onClick}
      />
    </Tooltip>
  );
};

EventToggle.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default EventToggle;
