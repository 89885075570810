import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getStepTypeMetadata } from 'utils/steps';
import {
  selectFlow,
  selectSortedFlowStepChildren,
} from 'reducers/account/flows';
import { selectVersionedStepChildren } from 'reducers/account/versionedFlows';
import { getVersionedFlow } from 'actions/account/versionedFlows';

import Slider from 'components/Common/UI/Slider';
import ImageServiceImage from 'components/Common/ImageServiceImage';
import OpenInBuilderButton from 'components/OpenInBuilder/OpenInBuilderButton';
import PreviewThumbnail from './PreviewThumbnail';

export const PreviewCarousel = ({
  stepChildren,
  onLoad,
  versionId,
  flowId,
}) => {
  useEffect(() => {
    onLoad();
  }, []);

  const childThumbnails = stepChildren.map((stepChild, index) => {
    const step = getStepTypeMetadata(stepChild);

    return (
      <Slider.Item data-testid="carousel-stepchild" key={step.stepId}>
        <OpenInBuilderButton
          stepGroupId={flowId}
          stepChildId={stepChild.id}
          render={handleClick => (
            <PreviewThumbnail stepIndex={index} onClick={handleClick}>
              <ImageServiceImage
                flowId={flowId}
                stepId={stepChild.stepId}
                contentType={step.firebaseType}
                stepChildId={stepChild.id}
                size="medium"
                versionId={versionId || 'latest'}
                forceRegenerate
              />
            </PreviewThumbnail>
          )}
        />
      </Slider.Item>
    );
  });

  return <Slider>{childThumbnails}</Slider>;
};

const mapStateToProps = (state, { flowId }) => {
  const { publishedAt } = selectFlow(state, flowId);
  const versionId = new Date(publishedAt).getTime();
  const stepChildren = versionId
    ? selectVersionedStepChildren(state, {
        flowId,
        versionId,
      })
    : selectSortedFlowStepChildren(state, flowId);
  return {
    versionId,
    stepChildren,
  };
};

const mapDispatchToProps = (dispatch, { flowId, versionId }) => ({
  onLoad: () =>
    versionId ? dispatch(getVersionedFlow({ flowId, versionId })) : null,
});

const ConnectedPreviewCarousel = connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewCarousel);

PreviewCarousel.propTypes = {
  onLoad: PropTypes.func,
  versionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flowId: PropTypes.string,
  stepChildren: PropTypes.arrayOf(
    PropTypes.shape({
      stepId: PropTypes.string,
      id: PropTypes.string,
      firebaseType: PropTypes.string,
    })
  ),
};

ConnectedPreviewCarousel.propTypes = {
  flowId: PropTypes.string,
};

export default ConnectedPreviewCarousel;
