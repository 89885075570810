import PropTypes from 'prop-types';

export const Entitlement = PropTypes.shape({
  availableUnits: PropTypes.number,
  completePercent: PropTypes.number,
  currentStatus: PropTypes.string,
  showWarning: PropTypes.bool,
  usedUnits: PropTypes.number,
  unitPrice: PropTypes.number,
});

/**
 * This shape is the frontend representation of a plan, which is different
 * from the backend. This will change once we grab all the plan's data from the
 * API, instead of hardcoding the prices and features at src/constants/plans.js
 */
export const Plan = PropTypes.shape({
  color: PropTypes.string,
  description: PropTypes.string,
  featuresCaption: PropTypes.string,
  features: PropTypes.object,
  gradient: PropTypes.string,
  interval: PropTypes.string,
  id: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pricePlanId: PropTypes.string,
  name: PropTypes.string,
  visible: PropTypes.bool,
});

const StripeLineItem = PropTypes.shape({
  id: PropTypes.string,
  quantity: PropTypes.number,
  unitPrice: PropTypes.number,
});

export const UpcomingInvoice = PropTypes.shape({
  date: PropTypes.number,
  next_payment_attempt: PropTypes.number,
  total: PropTypes.number,
  subtotal: PropTypes.number,
  items: PropTypes.arrayOf(StripeLineItem),
});
