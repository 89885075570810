import styled from 'styled-components';

const CarouselArrow = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  background-color: #fafafa;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
  border-radius: 50%;

  z-index: 10;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .arrow {
    width: 50%;
    height: 50%;
  }

  svg {
    width: 32px;
    height: 32px;
  }
`;

export default CarouselArrow;
