import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { CButton } from '@appcues/component-library';
import PropTypes from 'prop-types';
import { IconWithinCButton } from '@studio/legacy-components';
import { EXPORT_MODAL } from 'constants/globals';
import { showModal } from 'actions/currentModal';
import CoolTip from 'components/Common/CoolTip';
import OpenInBuilderButton from 'components/OpenInBuilder/OpenInBuilderButton';

export const FlowAnalyticsActions = ({
  flowId,
  onClickExport,
  isLocalized,
}) => {
  return (
    <>
      <CButton id="exportModalButton" onClick={onClickExport}>
        Export
      </CButton>
      <CButton is={Link} to={`/flows/${flowId}/settings`} title="Settings">
        Settings
      </CButton>
      <CoolTip
        tip="This flow has been localized, so content cannot be edited, only styling and positioning."
        position="left"
        disabled={!isLocalized}
      >
        <OpenInBuilderButton
          stepGroupId={flowId}
          render={handleClick => (
            <CButton type="primary" onClick={handleClick}>
              <IconWithinCButton icon="edit" />
              Open in Builder
            </CButton>
          )}
        />
      </CoolTip>
    </>
  );
};

FlowAnalyticsActions.propTypes = {
  flowId: PropTypes.string.isRequired,
  isLocalized: PropTypes.bool.isRequired,
  createdAt: PropTypes.string.isRequired,
  onClickExport: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { flowId, createdAt }) => ({
  onClickExport: () =>
    dispatch(
      showModal(EXPORT_MODAL, {
        exportType: 'flow_id',
        exportId: flowId,
        createdAt,
      })
    ),
});

export default connect(null, mapDispatchToProps)(FlowAnalyticsActions);
