import React from 'react';

const HotspotIcon = ({ className }) => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(3.500000, 3.000000)">
            <circle strokeWidth="2" cx="5.5" cy="5.5" r="5.5" />
            <path
              d="M5.5,8.25 C3.98121694,8.25 2.75,7.01878309 2.75,5.5 C2.75,3.98121691 3.98121694,2.75 5.5,2.75 C7.01878309,2.75 8.25,3.98121691 8.25,5.5 C8.25,7.01878309 7.01878309,8.25 5.5,8.25 Z M5.5,6.47988506 C6.0411756,6.47988506 6.47988506,6.0411756 6.47988506,5.5 C6.47988506,4.9588244 6.0411756,4.52011494 5.5,4.52011494 C4.9588244,4.52011494 4.52011494,4.9588244 4.52011494,5.5 C4.52011494,6.0411756 4.9588244,6.47988506 5.5,6.47988506 Z"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default HotspotIcon;
