import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@studio/legacy-components';
import useEscape from 'next/hooks/use-escape';
import { Action, Control, NameInput } from './styled';

export default function Editing({
  error,
  onClose,
  onConfirm,
  onDelete,
  value: initial = '',
}) {
  const [value, setValue] = useState(initial);

  const handleSubmit = event => {
    event.preventDefault();

    const normalized = value.trim();

    // Execute confirm handler only if the submitted value is not empty and has
    // changed. Otherwise, close the editing state. Note that control to toggle
    // the editing state is inverted on confirm to allow the caller to determine
    // whether it needs to close the actions or not.
    if (normalized && normalized !== initial) {
      onConfirm(event, normalized);
    } else {
      onClose();
    }
  };

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleClose = () => {
    setValue(initial);
    onClose();
  };

  useEscape(event => {
    event.stopPropagation();
    handleClose();
  }, true);

  return (
    <Control data-testid="form" onSubmit={handleSubmit}>
      <NameInput
        autoFocus
        error={error}
        onChange={handleChange}
        type="text"
        value={value}
        name="tagname"
        aria-label="Type Tag name"
      />

      <Action aria-label="Confirm" kind="confirm" tabIndex="0" type="submit">
        <Icon icon="check" />
      </Action>

      <Action
        aria-label="Close"
        kind="close"
        onClick={handleClose}
        tabIndex="0"
        type="button"
      >
        <Icon icon="times" />
      </Action>

      {onDelete && (
        <Action
          aria-label="Delete"
          kind="delete"
          onClick={onDelete}
          tabIndex="0"
          type="button"
        >
          <Icon icon="trash-alt" />
        </Action>
      )}
    </Control>
  );
}

Editing.propTypes = {
  error: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onDelete: PropTypes.func,
  value: PropTypes.string,
};
