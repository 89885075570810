import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { promisaga } from 'utils/as-promised';
import { resolve, reject, FETCH_SURVEYS } from './actions';

export const transform = (response, id) =>
  response.reduce(acc => {
    acc[id] = response;
    return acc;
  }, {});

const resolveDataFormat = response => {
  return response?.data?.data ?? response?.data;
};

function* fetchResponses({ payload: { id, start, end, propertyNames } }) {
  try {
    const api = yield getContext('api');
    const response = yield call(
      api.getSurveyResponses,
      id,
      start,
      end,
      propertyNames
    );
    const data = resolveDataFormat(response);

    yield put(resolve({ [id]: data.surveyAnswers }));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  yield takeEvery(FETCH_SURVEYS, promisaga(fetchResponses));
}
