import React, { Component } from 'react';
import styled from 'styled-components';
import ReactSimpleRange from 'react-simple-range';
import { Button } from '@appcues/component-library';
import {
  addClause,
  conditionNames,
  deleteClause,
  propertyOperators,
} from '@appcues/libcues';
import { ExternalLink, Icon, Select } from '@studio/legacy-components';
import FieldGroup from 'components/Common/Forms/FieldGroup';
import SatisfactionSurveyCard from 'components/SatisfactionSurveys/SatisfactionSurveyCard';
import InputTag from 'components/Common/InputTag';
import SelectProfileAttribute from 'components/Common/SelectProfileAttribute';
import SelectSegmentDropDown from 'components/Common/SelectSegmentDropDown';

import { getStepLabel } from 'utils';
import * as clauseTransforms from 'transforms/clauses';
import { millisecondsPerDay } from 'constants/account/conditions';
import {
  SATISFACTION_TARGETING_CATEGORY,
  SATISFACTION_TARGETING_OPTIONS,
} from 'constants/satisfaction/data';

import {
  getAudienceSettingsSummary,
  transformClausesForAudienceTargeting,
} from 'helpers/satisfaction';
import SelectStepGroup from 'components/Common/SelectStepGroup';

const Wrapper = styled.div`
  ${Select} {
    max-width: 100%;
  }
`;

class SatisfactionAudienceSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasAppcuesNPSFlows: props.targetedFlows.length,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { targetingOptionView } = this.props;
    const willUsersViewOpen =
      targetingOptionView !== SATISFACTION_TARGETING_CATEGORY.AUDIENCE &&
      nextProps.targetingOptionView ===
        SATISFACTION_TARGETING_CATEGORY.AUDIENCE;

    if (willUsersViewOpen) {
      window.scrollTo(0, 590);
    }
  }

  updateUserCreatedAtMetaSetting = userCreatedAtField => {
    const {
      actions,
      clauses,
      newUserClause,
      createdWithinDays,
      userEmailField,
      userPropertyClausesParentId,
      updateSatisfaction,
      saveSatisfaction,
    } = this.props;

    if (userCreatedAtField === userEmailField) return;

    let updatedClauses = [];

    updatedClauses = newUserClause
      ? clauseTransforms.replaceClause(clauses, newUserClause.id, {
          ...newUserClause,
          property: userCreatedAtField,
        })
      : addClause(clauses, userPropertyClausesParentId, {
          conditionName: conditionNames.PROPERTIES,
          operator: '*',
          property: userCreatedAtField,
          value: createdWithinDays || millisecondsPerDay * 30,
        });
    const newClauses = updatedClauses.length > 0 ? updatedClauses : clauses;
    actions.updateAccountMeta({ userCreatedAtField });
    updateSatisfaction({
      clauses: newClauses,
    });
    saveSatisfaction(newClauses);
  };

  updateCreatedWithinDays = days => {
    const { clauses, newUserClause, updateSatisfaction } = this.props;
    const updatedClauses = clauseTransforms.replaceClause(
      clauses,
      newUserClause.id,
      { ...newUserClause, value: days }
    );
    updateSatisfaction({ clauses: updatedClauses });
  };

  updateSegmentTargeting = segmentId => {
    const { clauses, segmentClause, updateSatisfaction, saveSatisfaction } =
      this.props;
    const updatedClauses = clauseTransforms.replaceClause(
      clauses,
      segmentClause.id,
      { ...segmentClause, segment: segmentId }
    );
    updateSatisfaction({ clauses: updatedClauses });
    saveSatisfaction(updatedClauses);
  };

  addFlowTargetingClause = stepId => {
    const {
      clauses,
      surveyFrequencyValue,
      userPropertyClausesParentId,
      updateSatisfaction,
      saveSatisfaction,
    } = this.props;

    const baseFlowClause = {
      conditionName: conditionNames.CONTENT,
      negative: true,
      operator: propertyOperators.LESS_THAN_X_DAYS_AGO,
      value: surveyFrequencyValue || millisecondsPerDay * 30,
    };
    const updatedClauses = addClause(clauses, userPropertyClausesParentId, {
      ...baseFlowClause,
      content: stepId,
    });
    updateSatisfaction({ clauses: updatedClauses });
    saveSatisfaction(updatedClauses);
  };

  removeFlowTargetingClause = flowId => {
    const {
      clauses,
      flowFilteredClauses,
      updateSatisfaction,
      saveSatisfaction,
    } = this.props;

    const flowClauseToDelete = flowFilteredClauses.find(clause => {
      return clause.content === flowId;
    });
    const updatedClauses = deleteClause(clauses, flowClauseToDelete.id);

    updateSatisfaction({ clauses: updatedClauses });
    saveSatisfaction(updatedClauses);
  };

  updateUserEmailMetaSetting = userEmailField => {
    const {
      actions,
      clauses,
      userCreatedAtField,
      emailFilteredClauses,
      updateSatisfaction,
      saveSatisfaction,
    } = this.props;
    if (userCreatedAtField === userEmailField) return;

    let updatedClauses = clauses;
    emailFilteredClauses.forEach(clause => {
      updatedClauses = clauseTransforms.updateClause(
        updatedClauses,
        clause.id,
        { property: userEmailField }
      );
    });

    actions.updateAccountMeta({ userEmailField });
    updateSatisfaction({ clauses: updatedClauses });
    saveSatisfaction(updatedClauses);
  };

  updateEmailList = emailList => {
    const {
      clauses,
      emailFilteredClauses,
      userPropertyClausesParentId,
      userEmailField,
      updateSatisfaction,
      saveSatisfaction,
    } = this.props;
    const newEmailClause = {
      conditionName: conditionNames.PROPERTIES,
      operator: propertyOperators.NOT_CONTAINS,
      property: userEmailField || 'email',
    };
    let updatedClauses = clauses;

    if (emailFilteredClauses.length > 0) {
      const newEmailClauses = emailList.map(email => {
        return {
          ...newEmailClause,
          value: email,
        };
      });
      let nonEmailClauses = clauses;
      emailFilteredClauses.forEach(clause => {
        nonEmailClauses = deleteClause(nonEmailClauses, clause.id);
      });

      updatedClauses = nonEmailClauses;
      newEmailClauses.forEach(clause => {
        updatedClauses = addClause(
          updatedClauses,
          userPropertyClausesParentId,
          clause
        );
      });
    } else {
      updatedClauses = addClause(clauses, userPropertyClausesParentId, {
        ...newEmailClause,
        value: emailList[0],
      });
    }
    updateSatisfaction({ clauses: updatedClauses });
    saveSatisfaction(updatedClauses);
  };

  updateAudienceTargetingType = option => {
    const {
      clauses,
      userOption,
      userCreatedAtField,
      createdWithinDays,
      newUserClause,
      segmentClause,
      userPropertyClausesParentId,
      updateSatisfaction,
      saveSatisfaction,
    } = this.props;

    if (option.value === userOption.value) {
      return;
    }

    const updatedClauses = transformClausesForAudienceTargeting(
      clauses,
      option,
      newUserClause,
      segmentClause,
      userPropertyClausesParentId,
      userCreatedAtField,
      createdWithinDays
    );

    updateSatisfaction({
      clauses: updatedClauses,
    });
    saveSatisfaction(updatedClauses);
  };

  render() {
    const {
      targetingOptionView,
      setTargetingOptionView,
      isInstalled,
      steps,
      userOption,
      newUserClause,
      segmentClause,
      userCreatedAtField,
      userEmailField,
      surveyFrequencyValue,
      targetedFlows,
      emailDomainBlackList,
      saveSatisfaction,
    } = this.props;
    const { hasAppcuesNPSFlows } = this.state;

    const includesNewUsers = userOption && userOption.value === 1;
    const includesSegments = userOption && segmentClause && !includesNewUsers;
    const newUserDays =
      includesNewUsers &&
      newUserClause &&
      newUserClause.value / millisecondsPerDay;

    const userOptionDescription = getAudienceSettingsSummary(
      userOption,
      targetedFlows.length,
      emailDomainBlackList.length,
      newUserClause,
      newUserDays
    );

    const options =
      SATISFACTION_TARGETING_OPTIONS[SATISFACTION_TARGETING_CATEGORY.AUDIENCE]
        .AUDIENCE_OPTIONS;

    return (
      <Wrapper>
        <FieldGroup className="satisfaction-form-block audience-settings">
          <SatisfactionSurveyCard
            disableHover
            className="satisfaction-form-card"
            header="Who should see the survey:"
          >
            <div className="satisfaction-card-top">
              {targetingOptionView ===
              SATISFACTION_TARGETING_CATEGORY.AUDIENCE ? null : (
                <div className="satisfaction-card-description">
                  <p>{userOptionDescription}</p>
                </div>
              )}
              <Button
                onClick={() =>
                  setTargetingOptionView(
                    SATISFACTION_TARGETING_CATEGORY.AUDIENCE
                  )
                }
                className="button-primary"
              >
                {targetingOptionView ===
                SATISFACTION_TARGETING_CATEGORY.AUDIENCE
                  ? 'Done'
                  : 'Edit'}
              </Button>
            </div>

            {targetingOptionView ===
              SATISFACTION_TARGETING_CATEGORY.AUDIENCE && (
              <div className="satisfaction-form-card-bottom">
                <hr />
                <p className="settings-label which-users">
                  Which users should be included?
                </p>
                <Select
                  onChange={this.updateAudienceTargetingType}
                  options={options}
                  value={options.find(
                    ({ value }) => value === userOption.value
                  )}
                />

                {!isInstalled && includesNewUsers && (
                  <div className="settings-label install-prompt">
                    <p>
                      This setting requires you to install our SDK and identify
                      your users&apos; activation property with a timestamp.
                      Head over to our&nbsp;
                      <ExternalLink href="/settings/installation">
                        installation guide
                      </ExternalLink>{' '}
                      to get quickly set up.
                    </p>
                  </div>
                )}

                {isInstalled && includesNewUsers && (
                  <div className="satisfaction-card-setting audience-user-days">
                    <p className="settings-label audience-user-days">{`Activated users have triggered the activation property (for example, account created, credit card added, etc.) more than ${newUserDays} days ago`}</p>
                    <ReactSimpleRange
                      label
                      min={0}
                      max={90}
                      sliderSize={10}
                      trackColor="#00B2E5"
                      thumbColor="#00B2E5"
                      value={newUserDays}
                      onChange={e =>
                        this.updateCreatedWithinDays(
                          e.value * millisecondsPerDay
                        )
                      }
                      onChangeComplete={() => saveSatisfaction()}
                    />
                  </div>
                )}
                {isInstalled && includesNewUsers && (
                  <div className="satisfaction-card-setting new-user-targeting">
                    <p className="settings-label new-user-targeting">
                      Which field has the user&apos;s activation date?
                    </p>
                    <SelectProfileAttribute
                      value={userCreatedAtField || ''}
                      isNPSAudienceSetting
                      onChange={field =>
                        this.updateUserCreatedAtMetaSetting(field.value)
                      }
                    />
                  </div>
                )}
                {includesSegments && (
                  <SelectSegmentDropDown
                    value={segmentClause.segment || null}
                    onChange={value => this.updateSegmentTargeting(value)}
                  />
                )}
                {steps && Object.keys(steps).length > 0 && (
                  <div className="satisfaction-card-setting">
                    <input
                      type="checkbox"
                      id="appcuesNPS"
                      checked={hasAppcuesNPSFlows}
                      onChange={() =>
                        this.setState({
                          hasAppcuesNPSFlows: !hasAppcuesNPSFlows,
                        })
                      }
                    />
                    <label
                      htmlFor="appcuesNPS"
                      className="appcuesNPS settings-label"
                    >
                      Have you collected NPS previously using Appcues flows?
                    </label>

                    {hasAppcuesNPSFlows ? (
                      <p className="satisfaction-flow-targeting-description">
                        {`Select those flows to exclude users who have seen them within the last ${
                          surveyFrequencyValue / millisecondsPerDay
                        } days`}
                      </p>
                    ) : null}
                    {targetedFlows.length > 0 ? (
                      <div className="targeted-flows-list">
                        <p>
                          {`Users who have seen these flows are excluded from this
                          survey for: ${
                            surveyFrequencyValue / millisecondsPerDay
                          } days`}
                        </p>
                        {targetedFlows.map((flow, i) => {
                          return (
                            <p key={flow.id}>
                              <span>{`${
                                flow.id ? getStepLabel(flow.id, steps) : flow
                              }`}</span>

                              <Icon
                                role="button"
                                tabIndex={0}
                                aria-label="Delete flow"
                                data-flow-index={i}
                                data-flow-id={flow.id || flow}
                                onClick={() =>
                                  this.removeFlowTargetingClause(
                                    flow.id || flow
                                  )
                                }
                                className="delete-flow"
                                icon="trash-alt"
                              />
                            </p>
                          );
                        })}
                      </div>
                    ) : null}
                    {hasAppcuesNPSFlows ? (
                      <SelectStepGroup onChange={this.addFlowTargetingClause} />
                    ) : null}
                  </div>
                )}
                {isInstalled && (
                  <div className="satisfaction-card-setting exclude-emails">
                    <p className="settings-label exclude-emails">
                      Which emails should we exclude? Emails containing the
                      following values will be excluded:
                    </p>
                    <InputTag
                      tagList={emailDomainBlackList}
                      onUpdateInputTagList={this.updateEmailList}
                    />
                  </div>
                )}
                {isInstalled && (
                  <div className="satisfaction-card-setting email-field">
                    <p className="settings-label email-field">
                      Which field has the user&apos;s email?
                    </p>
                    <SelectProfileAttribute
                      value={userEmailField}
                      isNPSAudienceSetting
                      onChange={field =>
                        this.updateUserEmailMetaSetting(field.value)
                      }
                    />
                  </div>
                )}
              </div>
            )}
          </SatisfactionSurveyCard>
        </FieldGroup>
      </Wrapper>
    );
  }
}

export default SatisfactionAudienceSettings;
