import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import useToggle from 'next/hooks/use-toggle';
import RecentGroupsList from 'next/components/RecentGroupList';
import { selectGroups, callApi as fetchGroups } from 'entities/groups';
import { asPromised } from 'utils/as-promised';

export function RecentGroupsProvider({ onLoad, groups, identifier }) {
  const [loading, toggleLoading] = useToggle();
  useEffect(() => {
    toggleLoading();
    (async () => {
      await onLoad();
      toggleLoading();
    })();
  }, [onLoad]);

  return (
    <RecentGroupsList
      groups={groups}
      identifier={identifier}
      loading={loading}
    />
  );
}

const mapStateToProps = state => ({
  groups: selectGroups(state),
});

const mapDispatchToProps = (dispatch, { identifier }) => ({
  onLoad: () =>
    asPromised(
      dispatch,
      fetchGroups({
        propertyNames: identifier
          ? ['_lastSeenAt', '_groupId', 'avatarUrl', identifier]
          : ['_lastSeenAt', '_groupId', 'avatarUrl'],
        sortKey: '_lastSeenAt',
        direction: 'DESC',
        limit: 5,
      })
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecentGroupsProvider);
