import styled from 'styled-components';
import { Tabs } from '@appcues/sonar';

export const TabsRoot = styled(Tabs.Root)`
  border-bottom: 1px solid var(--border-default);
  background-color: var(--color-neutral-0);
  padding-left: var(--spacing-x-x-x-large);
  padding-right: var(--spacing-x-x-x-large);
  position: sticky;
  top: 108px;
  z-index: 3000;
`;

export const TabsList = styled(Tabs.List)`
  border-bottom: none;
`;

export const TabsTrigger = styled(Tabs.Trigger)`
  + div {
    transition: none;
  }
`;
