import { useState, useEffect } from 'react';

import {
  CHROME_INSTALL_MODAL_ID,
  EVENTS_EXPLORER_CRX_INSTALL_MODAL_ID,
} from 'constants/chromeExtension/modals';
import { getChromeExtensionVersion } from 'utils/chromeExtension';

/**
 * Handles checking for the Builder in Studio and returns a `verify` function that can be used
 * to guard against attempting to launch the Builder when it is not possible to do so.
 *
 * @returns {{isReady: {boolean}, verify: (function(): Promise<string>)}}
 */

export const useBuilderStatus = () => {
  const [hasBuilder, setHasBuilder] = useState(null);

  const hasChrome = !!window.chrome;
  const isReady = hasBuilder !== null;

  useEffect(() => {
    if (!hasChrome) {
      setHasBuilder(false);
      return () => {};
    }

    /* global CRX_ID */
    getChromeExtensionVersion(CRX_ID)
      .then(() => {
        setHasBuilder(true);
      })
      .catch(() => {
        setHasBuilder(false);
      });

    return () => {};
  }, [hasChrome]);

  const showAppcuesFlow = flowId => {
    if (window.Appcues) {
      window.Appcues.show(flowId);
    }
  };

  const verify = () =>
    new Promise((resolve, reject) => {
      if (!hasChrome) {
        showAppcuesFlow(CHROME_INSTALL_MODAL_ID);
        reject(new Error('User is not using Chrome'));
        return;
      }

      if (!isReady) {
        reject(new Error('Check for Builder has not yet completed'));
        return;
      }

      if (!hasBuilder) {
        showAppcuesFlow(EVENTS_EXPLORER_CRX_INSTALL_MODAL_ID);
        reject(new Error('User does not have Builder installed'));
        return;
      }

      resolve('Builder successfully verified');
    });

  return {
    isReady,
    verify,
  };
};
