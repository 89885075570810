import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  Heading,
  Modal,
  ModalFooter,
  Text,
} from '@studio/legacy-components';
import { Bold } from './styled';

const pluralize = (word, count) => `${word}${count === 1 ? '' : 's'}`;

export default function DeleteConfirmationModal({
  associations = [],
  name,
  onClose,
  onDelete,
  visible,
}) {
  const count = associations.length;

  return (
    <Modal onClose={onClose} size="s" visible={visible}>
      <Heading>Delete this tag?</Heading>

      <Text>
        The tag <Bold>{name}</Bold> is currently being used in{' '}
        <Bold>
          {count} {pluralize('experience', count)}
        </Bold>
        . Are you sure you want to delete the tag <Bold>{name}</Bold>?
      </Text>

      <ModalFooter>
        <ButtonGroup right>
          <Button
            aria-label="Confirm delete"
            kind="negative"
            onClick={onDelete}
          >
            Delete
          </Button>
          <Button aria-label="Cancel delete" kind="secondary" onClick={onClose}>
            Cancel
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
}

DeleteConfirmationModal.propTypes = {
  associations: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  visible: PropTypes.bool,
};
