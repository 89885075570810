import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderTitle = styled.div`
  margin-right: 16px;
`;

export const SubHeaderColumn = styled.div`
  margin-right: 25px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
