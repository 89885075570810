import styled from 'styled-components';

import { Icon, Carousel as BaseCarousel } from '@studio/legacy-components';
import { Screenshot } from 'next/components/Screenshot';

export const PinStep = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px;

  ${Screenshot} {
    height: 100px;
    width: 150px;
  }
`;

export const PinLabel = styled.div`
  align-items: center;
  color: var(--pleather);
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;

  ${Icon} {
    color: var(--sharkbait-ooh-la-la);
  }
`;

export const Carousel = styled(BaseCarousel)`
  min-height: auto;
`;
