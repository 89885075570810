import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  Flex,
  Box,
  H1,
  CButton,
  CIconButton,
} from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';
import { trackEvent } from 'actions/events';
import { GRAPHICS_BY_LIFECYCLE } from 'constants/segments';
import {
  BeginnerDescription,
  ExplorerDescription,
  RegularsDescription,
  ChampionsDescription,
} from 'components/SegmentsEdit/SegmentInfoCard/SegmentDescriptions';

const returnPLGIcon = lifecycleStage => {
  return GRAPHICS_BY_LIFECYCLE[lifecycleStage].flywheel;
};

const lifecycleIllustration = lifecycleStage => {
  return GRAPHICS_BY_LIFECYCLE[lifecycleStage].illustration;
};

const Description = ({ lifecycleStage }) => {
  switch (lifecycleStage) {
    case 'explorers':
      return <ExplorerDescription />;
    case 'regulars':
      return <RegularsDescription />;
    case 'champions':
      return <ChampionsDescription />;
    case 'beginners':
    default:
      return <BeginnerDescription />;
  }
};

const externalLinkBaseUrl =
  'https://www.appcues.com/product-led-growth-flywheel#';

export function SegmentInfoCard({
  actions,
  className,
  dismiss,
  lifecycleStage,
  ...boxProps
}) {
  const normalizedLifeCycle = lifecycleStage.toLowerCase();

  const handleReadMoreClick = () => {
    const { trackEvent: track } = actions;
    track('Clicked lifecycle segment read more link', {
      segment: normalizedLifeCycle,
    });
    window.open(`${externalLinkBaseUrl}${normalizedLifeCycle}`);
  };

  return (
    <StyledCard
      {...boxProps}
      className={className}
      borderRadius={6}
      marginY={25}
    >
      <Flex flexDirection="column" paddingTop={0} padding={10} width="10%">
        <Box
          is="img"
          borderRadius="50%"
          width={46}
          height={46}
          alt="avatar"
          src={lifecycleIllustration(normalizedLifeCycle)}
        />
      </Flex>
      <Flex flexDirection="column" width="60%">
        <Header>
          <H1 light className="segment_info_card--title">
            {normalizedLifeCycle}
          </H1>
        </Header>
        <Box is="main" paddingBottom="24px">
          <Description lifecycleStage={normalizedLifeCycle} />{' '}
        </Box>
        <footer>
          <CButton
            id="flyWheelLink"
            type="primary"
            onClick={handleReadMoreClick}
          >
            <Icon onClick={() => {}} icon="book-open" />
            Read all about {normalizedLifeCycle}
          </CButton>
          <CButton id="dismissButton" marginLeft={15} onClick={dismiss}>
            Dismiss
          </CButton>
        </footer>
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        paddingLeft="5%"
        width="30%"
      >
        <Box
          is="img"
          borderRadius="50%"
          alt={`flywheel illustration ${normalizedLifeCycle}`}
          src={returnPLGIcon(normalizedLifeCycle)}
        />
      </Flex>
      <CIconButton
        id="dismissX"
        zIndex={100}
        position="absolute"
        top={12}
        right={12}
        transparent
        border="none"
        onClick={dismiss}
      >
        <Icon icon="times" />
      </CIconButton>
    </StyledCard>
  );
}

SegmentInfoCard.propTypes = {
  lifecycleStage: PropTypes.string.isRequired,
  className: PropTypes.string,
  dismiss: PropTypes.func.isRequired,
  actions: PropTypes.exact({
    trackEvent: PropTypes.func,
  }),
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        trackEvent,
      },
      dispatch
    ),
  };
};

export default connect(null, mapDispatchToProps)(SegmentInfoCard);

const StyledCard = styled.figure`
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  border-radius: 8px;
  padding: 24px;
  background: linear-gradient(90deg, #0b1a38 0%, #3b456a 100%);
  box-shadow: ${props => props.theme['$box-shadow-1']};
  color: white;
  overflow: hidden;
  /* clip-path: inset(100%); //clips circular image */
  &:after {
    position: absolute;
    top: 0;
    clip-path: ellipse(250px 320px at 92% 50%);
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

SegmentInfoCard.displayName = 'SegmentInfoCard';

const Header = styled.header`
  text-transform: capitalize;
`;
