import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@studio/legacy-components';
import { create } from 'next/entities/pins';
import CreateExperienceManager from 'next/components/CreateExperienceManager';

export const CreatePinManager = ({ onCreate }) => {
  const handlePinCreate = async values => {
    const pinExperience = await onCreate({
      name: values.name,
      previewUrl: values.previewUrl,
      theme: values.theme,
      tagIds: values.tagIds,
      template: 'persistent',
    });

    return pinExperience;
  };

  return (
    <CreateExperienceManager
      type="pins"
      title="Create a Pin Experience"
      onCreate={handlePinCreate}
      renderTrigger={onClick => <Button onClick={onClick}>Create a Pin</Button>}
    />
  );
};

CreatePinManager.propTypes = {
  onCreate: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  onCreate: values => create(values),
};

export default connect(null, mapDispatchToProps)(CreatePinManager);
