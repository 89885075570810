import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import isEmpty from 'lodash.isempty';
import {
  queryExperienceUsers,
  queryExperienceTotalInteraction,
  queryExperienceTotalDismissed,
} from 'next/client/queries';
import {
  selectExperienceUsersAnalytics,
  readAnalytics,
  selectExperienceTotalDismissed,
  selectExperienceTotalInteraction,
} from 'next/entities/analytics';
import {
  AnalyticsMetricCard,
  AnalyticsMetricGroup,
  AnalyticsMetricTooltip,
} from 'next/components/AnalyticsMetricCard';

const calculatePercentage = (numerator, denominator) => {
  const result = numerator / denominator;

  if (denominator === 0 || !Number.isFinite(result)) {
    return '0%';
  }

  return `${Math.floor(result * 100)}%`;
};

const getTotal = (metric, key) => {
  if (!metric) return '-';

  return metric
    .reduce((prev, { [key]: value }) => prev + value, 0)
    .toLocaleString();
};

const getUniqueUserMetric = query => {
  const [userQueryResult] = query || [];

  if (!userQueryResult) {
    return {
      metric: '-',
      description: null,
    };
  }

  const { experience_users: users, experiences_shown: shown } = userQueryResult;

  return {
    metric: users.toLocaleString(),
    description: `${shown.toLocaleString()} total ${pluralize(
      'time',
      shown
    )} shown`,
  };
};

const getTotalClickThroughs = query => {
  const clickThroughsResult = query || [];
  // double find here shouldn't be a problem since the array will always be size 2
  // the only thing we want with this find is to make sure we get the correct data
  // since we are  not sure the data will always be returned in the same order.
  const stepInteraction = clickThroughsResult.find(
    it => it.event === 'appcues:v2:step_interaction'
  );
  const stepSeen = clickThroughsResult.find(
    it => it.event === 'appcues:v2:step_seen'
  );

  if (isEmpty(stepInteraction) || isEmpty(stepSeen)) {
    return {
      metric: '0%',
      description: '0% total click-throughs',
    };
  }

  return {
    metric: calculatePercentage(stepInteraction.users, stepSeen.users),
    description: `${calculatePercentage(
      stepInteraction.events,
      stepSeen.events
    )} total click-throughs`,
  };
};

export function OverviewMetrics({
  onLoad,
  usersAnalytics,
  totalDismissedAnalytics,
  totalInteractionAnalytics,
  hasDismissEnabled,
}) {
  const usersMetrics = getUniqueUserMetric(usersAnalytics);
  const clickTroughsMetrics = getTotalClickThroughs(totalInteractionAnalytics);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <AnalyticsMetricGroup columns={3}>
      <AnalyticsMetricCard
        title="Unique users"
        metric={usersMetrics.metric}
        description={usersMetrics.description}
        additionalInfo={
          <AnalyticsMetricTooltip>
            This Banner was shown to this many unique users over the selected
            time period.
          </AnalyticsMetricTooltip>
        }
        loading={!usersAnalytics}
      />

      <AnalyticsMetricCard
        title="Unique user click-throughs"
        metric={clickTroughsMetrics.metric}
        description={clickTroughsMetrics.description}
        loading={!totalInteractionAnalytics}
      />

      {hasDismissEnabled ? (
        <AnalyticsMetricCard
          title="Unique users dismissed"
          metric={getTotal(totalDismissedAnalytics, 'users')}
          description={`${getTotal(
            totalDismissedAnalytics,
            'events'
          )} total dismissals`}
          loading={!totalDismissedAnalytics}
        />
      ) : (
        <AnalyticsMetricCard
          title="Unique users dismissed"
          metric="-"
          description="Banner is not dismissable"
          loading={!totalDismissedAnalytics}
        />
      )}
    </AnalyticsMetricGroup>
  );
}

OverviewMetrics.propTypes = {
  // is is used by the queries only not the UI,
  // hence had to ignore this lint rule above
  // eslint-disable-next-line react/no-unused-prop-types
  id: PropTypes.string,
  onLoad: PropTypes.func,
  hasDismissEnabled: PropTypes.bool,
  totalDismissedAnalytics: PropTypes.arrayOf(
    PropTypes.shape({
      events: PropTypes.number,
    })
  ),
  totalInteractionAnalytics: PropTypes.arrayOf(
    PropTypes.shape({
      events: PropTypes.number,
    })
  ),
  usersAnalytics: PropTypes.arrayOf(
    PropTypes.shape({
      experience_completed_users: PropTypes.number,
      users: PropTypes.number,
      experiences_completed: PropTypes.number,
      experiences_shown: PropTypes.number,
    })
  ),
};

const getQueryParams = ({ id, startTime, endTime, segmentId }) => ({
  experienceId: id,
  startTime,
  endTime,
  segmentId,
});

const mapStateToProps = (state, ownProps) => {
  const queryParams = getQueryParams(ownProps);

  return {
    usersAnalytics: selectExperienceUsersAnalytics(state, queryParams),
    totalInteractionAnalytics: selectExperienceTotalInteraction(
      state,
      queryParams
    ),
    totalDismissedAnalytics: selectExperienceTotalDismissed(state, queryParams),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const queryParams = getQueryParams(ownProps);
  const usersQuery = queryExperienceUsers(queryParams);
  const interactionQuery = queryExperienceTotalInteraction(queryParams);
  const totalDismissalQuery = queryExperienceTotalDismissed(queryParams);

  return {
    onLoad: () => {
      dispatch(readAnalytics(usersQuery));
      dispatch(readAnalytics(interactionQuery));
      dispatch(readAnalytics(totalDismissalQuery));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OverviewMetrics);
