import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';

const SomethingWentWrong = styled.div`
  text-align: center;
  padding-top: 10px;

  & div {
    font-size: 20px;
    font-weight: 600;
    color: ${props => props.theme['$gray-50']};
    margin-bottom: 20px;
  }

  & a {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme['$gray-3']};
  align-self: center;

  ${Icon} {
    color: ${props => props.theme['$gray-30']};
    align-self: center;
    margin-bottom: 4px;
  }
`;

const TryAgain = styled.a`
  color: ${props => props.theme.$purple};
`;

const ErrorState = ({
  className,
  message = 'An error occurred when trying to load this.',
  retry,
}) => (
  <div className={className}>
    <IconContainer>
      <Icon icon="exclamation-triangle" />
    </IconContainer>
    <SomethingWentWrong>
      <div>Something Went Wrong</div>
      <p>
        {`${message} `}
        {typeof retry === 'function' && (
          <TryAgain onClick={retry}>Try again?</TryAgain>
        )}
      </p>
    </SomethingWentWrong>
  </div>
);

ErrorState.propTypes = {
  retry: PropTypes.func,
  className: PropTypes.string,
  message: PropTypes.string,
};

export default styled(ErrorState)`
  display: flex;
  padding: 15px 0;
  flex-direction: column;
`;
