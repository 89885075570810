// NOTE: Please do not add component-specific actions here! Add them to constants/[component-type]/actionTypes.js
// Currently migrated action types:
//   * Satisfaction -> satisfaction/actionTypes.js

// clause actions

// reducer actions
export const UPDATE_STEP_IN_STORE = 'UPDATE_STEP_IN_STORE';
export const UPDATE_CHECKLIST_ITEM_IN_STORE = 'UPDATE_CHECKLIST_ITEM_IN_STORE';

// api related
export const HIT_PUBLISH_HOOK = 'HIT_PUBLISH_HOOK';

// rest related
export const INIT_WITH_ACCOUNT_ENDPOINT = 'INIT_WITH_ACCOUNT_ENDPOINT';

// firebase related
export const CHECK_LOGIN_STATUS = 'CHECK_LOGIN_STATUS';

export const AUTH_FIREBASE_WITH_CUSTOM_TOKEN =
  'AUTH_FIREBASE_WITH_CUSTOM_TOKEN';
export const SYNC_AFTER_LOGIN = 'SYNC_AFTER_LOGIN';

export const INIT_ACCOUNT_SPOOF = 'INIT_ACCOUNT_SPOOF';
export const CANCEL_ACCOUNT_SPOOF = 'CANCEL_ACCOUNT_SPOOF';
export const FIREBASE_READY = 'FIREBASE_READY';

export const SYNC_ACCOUNT_WITH_FIREBASE = 'SYNC_ACCOUNT_WITH_FIREBASE';
// @deprecated
export const CANCEL_ACCOUNT_SYNC = 'CANCEL_ACCOUNT_SYNC';
export const CANCEL_USER_SYNC = 'CANCEL_USER_SYNC';

// @deprecated
export const CLEAR_ACCOUNT_DATA = 'CLEAR_ACCOUNT_DATA';

export const FLUSH_USER_META_TO_FIREBASE = 'FLUSH_USER_META_TO_FIREBASE';
export const FLUSH_ACCOUNT_USER_TO_FIREBASE = 'FLUSH_ACCOUNT_USER_TO_FIREBASE';
export const FLUSH_STEP_TO_FIREBASE = 'FLUSH_STEP_TO_FIREBASE';
export const FLUSH_SATISFACTION_TO_FIREBASE = 'FLUSH_SATISFACTION_TO_FIREBASE';
export const FLUSH_CHECKLIST_TO_FIREBASE = 'FLUSH_CHECKLIST_TO_FIREBASE';

export const CREATE_SATISFACTION_IN_FIREBASE =
  'CREATE_SATISFACTION_IN_FIREBASE';
export const CREATE_SATISFACTION_STEP_IN_FIREBASE =
  'CREATE_SATISFACTION_STEP_IN_FIREBASE';
export const CREATE_RULE_IN_FIREBASE = 'CREATE_RULE_IN_FIREBASE';

// step related
export const ADD_NEW_STEP = 'ADD_NEW_STEP';

// firebase replacements
export const REPLACE_ACCOUNT_STEPS = 'REPLACE_ACCOUNT_STEPS';
export const REPLACE_ACCOUNT_STEP = 'REPLACE_ACCOUNT_STEP';
export const REPLACE_ACCOUNT_FLOW = 'REPLACE_ACCOUNT_FLOW';
export const REPLACE_ACCOUNT_PROFILE_ATTRIBUTES =
  'REPLACE_ACCOUNT_PROFILE_ATTRIBUTES';
export const REPLACE_ACCOUNT_PROFILE_ATTRIBUTE =
  'REPLACE_ACCOUNT_PROFILE_ATTRIBUTE';
export const DELETE_ACCOUNT_PROFILE_ATTRIBUTE_IN_STORE =
  'DELETE_ACCOUNT_PROFILE_ATTRIBUTE_IN_STORE';
export const REPLACE_ACCOUNT_USER_EVENTS = 'REPLACE_ACCOUNT_USER_EVENTS';
export const REPLACE_ACCOUNT_USER_EVENT = 'REPLACE_ACCOUNT_USER_EVENT';
export const DELETE_ACCOUNT_USER_EVENT_IN_STORE =
  'DELETE_ACCOUNT_USER_EVENT_IN_STORE';
export const REPLACE_ACCOUNT_USERS = 'REPLACE_ACCOUNT_USERS';
export const REPLACE_ACCOUNT_USER = 'REPLACE_ACCOUNT_USER';
export const REPLACE_ACCOUNT_USER_META = 'REPLACE_ACCOUNT_USER_META';
export const REPLACE_ACCOUNT_BOOKMARKS = 'REPLACE_ACCOUNT_BOOKMARKS';
export const REPLACE_ACCOUNT_CHECKLISTS = 'REPLACE_ACCOUNT_CHECKLISTS';
export const REPLACE_ACCOUNT_CHECKLIST = 'REPLACE_ACCOUNT_CHECKLIST';
export const REPLACE_ACCOUNT_CHECKLIST_STATS =
  'REPLACE_ACCOUNT_CHECKLIST_STATS';
export const REPLACE_ACCOUNT_INTEGRATIONS = 'REPLACE_ACCOUNT_INTEGRATIONS';
export const REPLACE_PREVIEWS = 'REPLACE_PREVIEWS';

// integrations
export const ACTIVATE_INTEGRATION_AND_FLUSH_INTEGRATION =
  'ACTIVATE_INTEGRATION_AND_FLUSH_INTEGRATION';
export const REMOVE_INTEGRATION_AND_FLUSH_INTEGRATION =
  'REMOVE_INTEGRATION_AND_FLUSH_INTEGRATION';
export const ACTIVATE_INTEGRATION_IN_STORE = 'ACTIVATE_INTEGRATION_IN_STORE';
export const REMOVE_INTEGRATION_IN_STORE = 'REMOVE_INTEGRATION_IN_STORE';
export const FLUSH_INTEGRATION_TO_FIREBASE = 'FLUSH_INTEGRATION_TO_FIREBASE';
export const ACTIVATE_INTEGRATION_IN_STORE_ROLLBACK =
  'ACTIVATE_INTEGRATION_IN_STORE_ROLLBACK';
export const TOGGLE_INTEGRATION_STATE = 'TOGGLE_INTEGRATION_STATE';
export const FETCH_INTEGRATION = 'FETCH_INTEGRATION';

// ui actions
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const SET_OVERLAY_NAV_MODE = 'SET_OVERLAY_NAV_MODE';
export const SHOW_OVERLAY_NAV = 'SHOW_OVERLAY_NAV';
export const HIDE_OVERLAY_NAV = 'HIDE_OVERLAY_NAV';

export const NAVIGATE = 'NAVIGATE';

// event related
export const TRACK_EVENT = 'TRACK_EVENT';

// stripe actions
export const REPLACE_BILLING_DETAILS = 'REPLACE_BILLING_DETAILS';
export const REPLACE_BILLING_HISTORY = 'REPLACE_BILLING_HISTORY';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_FETCHING = 'CANCEL_SUBSCRIPTION_FETCHING';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILED = 'CANCEL_SUBSCRIPTION_FAILED';
export const TRACK_CANCEL_SUBSCRIPTION = 'TRACK_CANCEL_SUBSCRIPTION';
export const PAYMENT_TAXES_FETCHED = 'PAYMENT_TAXES_FETCHED';
export const PAYMENT_INTENT_FETCHED = 'PAYMENT_INTENT_FETCHED';
export const PAYMENT_INFO_FETCHED = 'PAYMENT_INFO_FETCHED';
export const PAYMENT_INFO_UPDATED = 'PAYMENT_INFO_UPDATED';
export const SETUP_INTENT_CREATED = 'SETUP_INTENT_CREATED';
export const SUBSCRIPTION_CREATED = 'SUBSCRIPTION_CREATED';
export const STRIPE_CUSTOMER_FETCHED = 'STRIPE_CUSTOMER_FETCHED';
export const SUBSCRIPTION_FETCHED = 'SUBSCRIPTION_FETCHED';
export const SUBSCRIPTION_DELETED = 'SUBSCRIPTION_DELETED';
export const PAYMENT_FAILED = 'PAYMENT_FAILED';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const UPCOMING_INVOICE_FETCHED = 'UPCOMING_INVOICE_FETCHED';
export const INVOICE_PREVIEW_CREATED = 'INVOICE_PREVIEW_CREATED';

// metrics
export const ADD_PERFORMANCE_MARK = 'ADD_PERFORMANCE_MARK';
export const ADD_PERFORMANCE_MEASURE = 'ADD_PERFORMANCE_MEASURE';
export const TRACK_METRIC = 'TRACK_METRIC';

// user profiles
export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const SET_USER_PROFILE_FETCHING = 'SET_USER_PROFILE_FETCHING';
export const SET_USER_PROFILE_SUCCESS = 'SET_USER_PROFILE_SUCCESS';
export const SET_USER_PROFILE_ERRORED = 'SET_USER_PROFILE_ERRORED';
