import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Formik, Field } from 'formik';
import { object, string } from 'yup';
import { connect } from 'react-redux';

import {
  Button,
  ButtonGroup,
  ErrorMsg,
  Heading,
  Input,
  Label,
  Modal,
  Overflow,
  Select,
} from '@studio/legacy-components';
import { AppShape, selectApp } from 'next/entities/apps';
import {
  selectExperience,
  clone as cloneExperience,
} from 'next/entities/experiences';
import { selectAppOptions } from 'next/lib/selectors-options';
import { SelectOptionsShape } from 'next/lib/shapes';
import { AppWarningNotice, CloneModalFlowForm } from './styled';

const validationSchema = object().shape({
  name: string().required('Flow name is required'),
  app: object()
    .shape({
      label: string(),
      value: string(),
    })
    .required('App is required'),
});

export function CloneMobileFlowModal({
  name = '',
  isVisible = false,
  onToggleModal,
  onClone,
  appOptions = [],
  app = {},
}) {
  const sortedAppOptions = useMemo(
    () =>
      appOptions.sort((a, b) =>
        a.label.localeCompare(b.label, undefined, { numeric: true })
      ),
    [appOptions]
  );
  const initialAppOption = useMemo(() => {
    if (!app) return null;

    return sortedAppOptions.find(({ value }) => value === app.id);
  }, [app, sortedAppOptions]);

  const onSubmit = values => {
    onClone({
      app_id: values.app.value,
      name: values.name,
    });
    onToggleModal();
  };

  return (
    <Modal onClose={onToggleModal} visible={isVisible}>
      <Heading>Clone Flow</Heading>
      <Overflow>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            name: `Copy of ${name}`,
            app: initialAppOption,
          }}
          onSubmit={onSubmit}
          validateOnBlur
        >
          {({ values, errors, setFieldValue }) => {
            const isAppDifferent =
              values.app && values.app.value !== initialAppOption?.value;

            return (
              <CloneModalFlowForm>
                <Label htmlFor="name">
                  Flow name
                  <Field
                    id="name"
                    type="text"
                    name="name"
                    placeholder="Flow name"
                    as={Input}
                    error={Boolean(errors.name)}
                  />
                  <ErrorMessage name="name" component={ErrorMsg} />
                </Label>
                <Label htmlFor="app">
                  App
                  <Select
                    inputId="app"
                    name="app"
                    placeholder="Select app type"
                    error={Boolean(errors.app)}
                    options={sortedAppOptions}
                    onChange={option => {
                      setFieldValue('app', option);
                    }}
                    value={values.app}
                    styles={{
                      option: {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      },
                    }}
                    portal
                  />
                </Label>

                {isAppDifferent && (
                  <AppWarningNotice>
                    All targeting settings for this Flow will be reset to
                    default because the app has changed.
                  </AppWarningNotice>
                )}

                <ButtonGroup right>
                  <Button kind="primary" type="submit" disabled={!values.name}>
                    Create
                  </Button>
                  <Button kind="secondary" onClick={onToggleModal}>
                    Cancel
                  </Button>
                </ButtonGroup>
              </CloneModalFlowForm>
            );
          }}
        </Formik>
      </Overflow>
    </Modal>
  );
}

CloneMobileFlowModal.propTypes = {
  isVisible: PropTypes.bool,
  onToggleModal: PropTypes.func,
  appOptions: SelectOptionsShape,
  name: PropTypes.string,
  app: AppShape,
  onClone: PropTypes.func,
};

const mapStateToProps = (state, { id }) => {
  const experience = selectExperience(state, id);
  const app = selectApp(state, experience.appId);

  return {
    appOptions: selectAppOptions(state),
    name: experience.name,
    app,
  };
};

const mapDispatchToProps = (dispatch, { id }) => ({
  onClone: body => dispatch(cloneExperience(id, body)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CloneMobileFlowModal);
