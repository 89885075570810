import * as actionTypes from 'constants/actionTypes';

export function showModal(key, props) {
  return {
    type: actionTypes.SHOW_MODAL,
    payload: { key, props },
  };
}

export function hideModal() {
  return {
    type: actionTypes.HIDE_MODAL,
  };
}
