import styled from 'styled-components';
import { CardContent } from '@studio/legacy-components';
import { TileButton, Section, FormField, InlineMessage } from '@appcues/sonar';

export const Wrapper = styled.div``;

export const UserIntentCardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: var(--spacing-x-large);
  overflow: scroll;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-regular);
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: var(--spacing-regular);
  justify-content: space-between;
`;

export const UserIntentCard = styled(Section)`
  width: 100%;
  background-color: var(--color-neutral-0);
  border-radius: var(--border-radius-small, 6px);
  box-shadow: var(--elevation-400);
  max-width: 788px;
  min-width: 644px;
  padding: var(--spacing-x-x-large);
  display: flex;
  flex-direction: column;
  margin: var(--spacing-x-large);
  gap: var(--spacing-x-large);

  ${Section} {
    display: flex;
    flex-direction: column;
  }

  ${InlineMessage} {
    margin-top: var(--spacing-x-small);
    margin-bottom: var(--spacing-x-large);
  }

  ${CardContent} {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-x-large);
  }

  ${InlineMessage} {
    margin-bottom: 0;
  }
`;

export const UserIntentForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-x-large);
`;

export const IntentOptionsWrapper = styled.div`
  max-width: 500px;
  margin: auto;
`;

//  Only setting width doesn't override the properties needed
export const Intent = styled(TileButton)`
  min-width: 220px;
  max-width: 220px;
`;

export const ImageWrapper = styled.div`
  width: 160px;
`;

export const Image = styled.img`
  aspect-ratio: 1;
`;

export const SecondaryInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-x-large);
`;

export const Apps = styled.div`
  display: flex;
  gap: var(--spacing-regular);
  flex-direction: column;
`;

export const App = styled.div`
  display: flex;
  gap: var(--spacing-regular);
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const AppField = styled(FormField)`
  padding-bottom: 0;
  flex: 1;
`;

export const AppAction = styled.div``;
