export const FETCH_CHECKLIST_ANALYTICS = 'FETCH_CHECKLIST_ANALYTICS';
export const FETCH_CHECKLIST_ANALYTICS_ERROR =
  'FETCH_CHECKLIST_ANALYTICS_ERROR';
export const FETCH_CHECKLIST_ANALYTICS_SUCCESS =
  'FETCH_CHECKLIST_ANALYTICS_SUCCESS';
export const FETCH_FLOW_LIST_ANALYTICS = 'FETCH_FLOW_LIST_ANALYTICS';
export const FETCH_FLOW_LIST_ANALYTICS_ERROR =
  'FETCH_FLOW_LIST_ANALYTICS_ERROR';
export const FETCH_FLOW_LIST_ANALYTICS_SUCCESS =
  'FETCH_FLOW_LIST_ANALYTICS_SUCCESS';

export const FETCH_GOALS_REACHED_BY_WEEKLY_ACCOUNT_USERS =
  'FETCH_GOALS_REACHED_BY_WEEKLY_ACCOUNT_USERS';
export const SET_GOALS_REACHED_BY_WEEKLY_ACCOUNT_USERS_FETCHING =
  'SET_GOALS_REACHED_BY_WEEKLY_ACCOUNT_USERS_FETCHING';
export const SET_GOALS_REACHED_BY_WEEKLY_ACCOUNT_USERS_SUCCESS =
  'SET_GOALS_REACHED_BY_WEEKLY_ACCOUNT_USERS_SUCCESS';
export const SET_GOALS_REACHED_BY_WEEKLY_ACCOUNT_USERS_ERROR =
  'SET_GOALS_REACHED_BY_WEEKLY_ACCOUNT_USERS_ERROR';

export const FETCH_GOAL_REACHED_USER_SAMPLE = 'FETCH_GOAL_REACHED_USER_SAMPLE';
export const SET_GOAL_REACHED_USER_SAMPLE_FETCHING =
  'SET_GOAL_REACHED_USER_SAMPLE_FETCHING';
export const SET_GOAL_REACHED_USER_SAMPLE_SUCCESS =
  'SET_GOAL_REACHED_USER_SAMPLE_SUCCESS';
export const SET_GOAL_REACHED_USER_SAMPLE_ERROR =
  'SET_GOAL_REACHED_USER_SAMPLE_ERROR';

export const FETCH_GOALS_FIRST_REACHED_THIS_WEEK =
  'FETCH_GOALS_FIRST_REACHED_THIS_WEEK';
export const SET_GOALS_FIRST_REACHED_THIS_WEEK_FETCHING =
  'SET_GOALS_FIRST_REACHED_THIS_WEEK_FETCHING';
export const SET_GOALS_FIRST_REACHED_THIS_WEEK_SUCCESS =
  'SET_GOALS_FIRST_REACHED_THIS_WEEK_SUCCESS';
export const SET_GOALS_FIRST_REACHED_THIS_WEEK_ERROR =
  'SET_GOALS_FIRST_REACHED_THIS_WEEK_ERROR';

export const FETCH_GOALS_REACHED_FROM_FLOW_STARTED =
  'FETCH_GOALS_REACHED_FROM_FLOW_STARTED';
export const SET_GOALS_REACHED_FROM_FLOW_STARTED_FETCHING =
  'SET_GOALS_REACHED_FROM_FLOW_STARTED_FETCHING';
export const SET_GOALS_REACHED_FROM_FLOW_STARTED_SUCCESS =
  'SET_GOALS_REACHED_FROM_FLOW_STARTED_SUCCESS';
export const SET_GOALS_REACHED_FROM_FLOW_STARTED_ERROR =
  'SET_GOALS_REACHED_FROM_FLOW_STARTED_ERROR';

export const FETCH_FLOW_PERFORMANCE_REPORT_DATA =
  'FETCH_FLOW_PERFORMANCE_REPORT_DATA';
export const SET_FLOW_PERFORMANCE_REPORT_DATA_FETCHING =
  'SET_FLOW_PERFORMANCE_REPORT_DATA_FETCHING';
export const SET_FLOW_PERFORMANCE_REPORT_DATA_SUCCESS =
  'SET_FLOW_PERFORMANCE_REPORT_DATA_SUCCESS';
export const SET_FLOW_PERFORMANCE_REPORT_DATA_ERROR =
  'SET_FLOW_PERFORMANCE_REPORT_DATA_ERROR';

export const FETCH_FLOW_ERROR_REPORT_DATA = 'FETCH_FLOW_ERROR_REPORT_DATA';
export const SET_FLOW_ERROR_REPORT_DATA_FETCHING =
  'SET_FLOW_ERROR_REPORT_DATA_FETCHING';
export const SET_FLOW_ERROR_REPORT_DATA_SUCCESS =
  'SET_FLOW_ERROR_REPORT_DATA_SUCCESS';
export const SET_FLOW_ERROR_REPORT_DATA_ERROR =
  'SET_FLOW_ERROR_REPORT_DATA_ERROR';

export const FETCH_INSTALLED_DOMAINS = 'FETCH_INSTALLED_DOMAINS';
export const SET_INSTALLED_DOMAINS_FETCHING = 'SET_INSTALLED_DOMAINS_FETCHING';
export const SET_INSTALLED_DOMAINS_SUCCESS = 'SET_INSTALLED_DOMAINS_SUCCESS';
export const SET_INSTALLED_DOMAINS_ERRORED = 'SET_INSTALLED_DOMAINS_ERRORED';
