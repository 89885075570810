import { createSelector } from 'reselect';
import { createCollectionReducer } from 'next/lib/collections';
import { selectRequestStatus } from 'next/entities/requests';

import { TYPE } from './actions';
import { MOBILE_DEFAULT_TYPE, MOBILE_EMBED_TYPE } from './constants';

export default createCollectionReducer(TYPE);

export const selectExperiences = state => state[TYPE];
export const selectExperience = (state, id) => selectExperiences(state)[id];

export const readExperiences = state => ({
  ...selectRequestStatus(state, TYPE),
  data: selectExperiences(state),
});
export const readExperience = (state, id) => ({
  ...selectRequestStatus(state, TYPE, id),
  data: selectExperience(state, id),
});

export const selectExperiencesByType = createSelector(
  selectExperiences,
  (_state, type) => type,
  (experiences, type) =>
    experiences &&
    Object.values(experiences)
      .filter(experience => experience.type === type)
      .reduce((acc, experience) => {
        const { id } = experience;
        acc[id] = experience;
        return acc;
      }, {})
);

export const readMobileFlows = state => ({
  ...selectRequestStatus(state, TYPE),
  data: {
    ...selectExperiencesByType(state, MOBILE_DEFAULT_TYPE),
    ...selectExperiencesByType(state, MOBILE_EMBED_TYPE),
  },
});

export const readBannersExperiences = state => ({
  ...selectRequestStatus(state, TYPE),
  data: selectExperiencesByType(state, 'banner'),
});

export const isExperienceListByTypeEmpty = (state, type) =>
  Object.keys(selectExperiencesByType(state, type) ?? {}).length === 0;

export const readExperiencesByType = (state, type) => ({
  ...selectRequestStatus(state, TYPE),
  data: selectExperiencesByType(state, type),
});
