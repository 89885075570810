import * as actionTypes from 'constants/analytics/actionTypes';

export function fetchChecklistAnalytics(checklistId) {
  return {
    type: actionTypes.FETCH_CHECKLIST_ANALYTICS,
    payload: { checklistId },
  };
}

export function fetchFlowListAnalytics(accountId) {
  return {
    type: actionTypes.FETCH_FLOW_LIST_ANALYTICS,
    payload: { accountId },
  };
}

export function fetchGoalsReachedByWeeklyAccountUsers() {
  return {
    type: actionTypes.FETCH_GOALS_REACHED_BY_WEEKLY_ACCOUNT_USERS,
  };
}

export function setGoalsReachedByWeeklyAccountUsersFetching() {
  return {
    type: actionTypes.SET_GOALS_REACHED_BY_WEEKLY_ACCOUNT_USERS_FETCHING,
  };
}

export function setGoalsReachedByWeeklyAccountUsersSuccess(results) {
  return {
    type: actionTypes.SET_GOALS_REACHED_BY_WEEKLY_ACCOUNT_USERS_SUCCESS,
    payload: { results },
  };
}

export function setGoalsReachedByWeeklyAccountUsersError(error) {
  return {
    type: actionTypes.SET_GOALS_REACHED_BY_WEEKLY_ACCOUNT_USERS_ERROR,
    payload: { error },
  };
}

export function fetchGoalReachedUserSample(goalId) {
  return {
    type: actionTypes.FETCH_GOAL_REACHED_USER_SAMPLE,
    payload: { goalId },
  };
}

export function setGoalReachedUserSampleFetching(goalId) {
  return {
    type: actionTypes.SET_GOAL_REACHED_USER_SAMPLE_FETCHING,
    payload: { goalId },
  };
}

export function setGoalReachedUserSampleSuccess(goalId, results) {
  return {
    type: actionTypes.SET_GOAL_REACHED_USER_SAMPLE_SUCCESS,
    payload: { goalId, results },
  };
}

export function setGoalReachedUserSampleError(goalId, error) {
  return {
    type: actionTypes.SET_GOAL_REACHED_USER_SAMPLE_ERROR,
    payload: { goalId, error },
  };
}

export function fetchGoalsFirstReachedThisWeek() {
  return {
    type: actionTypes.FETCH_GOALS_FIRST_REACHED_THIS_WEEK,
  };
}

export function setGoalsFirstReachedThisWeekFetching() {
  return {
    type: actionTypes.SET_GOALS_FIRST_REACHED_THIS_WEEK_FETCHING,
  };
}

export function setGoalsFirstReachedThisWeekSuccess(results) {
  return {
    type: actionTypes.SET_GOALS_FIRST_REACHED_THIS_WEEK_SUCCESS,
    payload: { results },
  };
}

export function setGoalsFirstReachedThisWeekError(error) {
  return {
    type: actionTypes.SET_GOALS_FIRST_REACHED_THIS_WEEK_ERROR,
    payload: { error },
  };
}

export function fetchGoalsReachedFromFlowStarted(params) {
  return {
    type: actionTypes.FETCH_GOALS_REACHED_FROM_FLOW_STARTED,
    payload: params,
  };
}

export function setGoalsReachedFromFlowStartedFetching() {
  return {
    type: actionTypes.SET_GOALS_REACHED_FROM_FLOW_STARTED_FETCHING,
  };
}

export function setGoalsReachedFromFlowStartedSuccess(results) {
  return {
    type: actionTypes.SET_GOALS_REACHED_FROM_FLOW_STARTED_SUCCESS,
    payload: { results },
  };
}

export function setGoalsReachedFromFlowStartedError(error) {
  return {
    type: actionTypes.SET_GOALS_REACHED_FROM_FLOW_STARTED_ERROR,
    payload: { error },
  };
}

export const fetchFlowPerformanceReportData = () => ({
  type: actionTypes.FETCH_FLOW_PERFORMANCE_REPORT_DATA,
});

export const setFlowPerformanceReportDataFetching = () => ({
  type: actionTypes.SET_FLOW_PERFORMANCE_REPORT_DATA_FETCHING,
});

export const setFlowPerformanceReportDataSuccess = (flowsArray, results) => ({
  type: actionTypes.SET_FLOW_PERFORMANCE_REPORT_DATA_SUCCESS,
  payload: { flowsArray, results },
});

export const setFlowPerformanceReportDataError = (flowsArray, error) => ({
  type: actionTypes.SET_FLOW_PERFORMANCE_REPORT_DATA_ERROR,
  payload: { flowsArray, error },
});

export const fetchFlowErrorReportData = () => ({
  type: actionTypes.FETCH_FLOW_ERROR_REPORT_DATA,
});

export const setFlowErrorReportDataFetching = () => ({
  type: actionTypes.SET_FLOW_ERROR_REPORT_DATA_FETCHING,
});

export const setFlowErrorReportDataSuccess = results => ({
  type: actionTypes.SET_FLOW_ERROR_REPORT_DATA_SUCCESS,
  payload: { results },
});

export const setFlowErrorReportDataError = error => ({
  type: actionTypes.SET_FLOW_ERROR_REPORT_DATA_ERROR,
  payload: { error },
});

export const fetchInstalledDomains = () => ({
  type: actionTypes.FETCH_INSTALLED_DOMAINS,
});

export const setInstalledDomainsFetching = () => ({
  type: actionTypes.SET_INSTALLED_DOMAINS_FETCHING,
});

export const setInstalledDomainsSuccess = result => ({
  type: actionTypes.SET_INSTALLED_DOMAINS_SUCCESS,
  payload: { result },
});

export const setInstalledDomainsErrored = error => ({
  type: actionTypes.SET_INSTALLED_DOMAINS_ERRORED,
  payload: { error },
});
