import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import {
  WarningNotice,
  Panel,
  Input,
  H2,
  Label,
  Link,
  ExternalLink,
  Text,
  PanelBody,
} from '@studio/legacy-components';
import { selectAccountRule } from 'reducers/account/rules';
import { update } from 'actions/account/rules';
import { selectAccountMeta } from 'reducers/account/meta';
import ThrottleWarning from 'components/Common/ThrottleWarning';

import FlowPrioritiesTable from './FlowPrioritiesTable';

const LEARN_MORE_URL = 'https://docs.appcues.com/article/744-flow-priorities';

const Container = styled(PanelBody)`
  display: flex;
  flex-direction: column;
  gap: 0;

  > *:not(:last-child) {
    margin-bottom: 1em;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25em;
  ${H2} {
    display: inline;
  }
`;

const InputContainer = styled.div`
  ${Input} {
    -moz-appearance: textfield;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    width: 8em;
  }
`;

const PreviewLabelText = styled.span``;

export const Prioritization = ({
  flowId,
  value = 0,
  max = 100000,
  min = -100000,
  onChange,
  throttleEnabled,
}) => {
  const sortPriority = useRef();
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    if (sortPriority.current) {
      sortPriority.current.value = value;
    }
  }, [value]);

  const handleBlur = ({ target: { valueAsNumber } }) => {
    if (
      Number.isNaN(valueAsNumber) ||
      valueAsNumber > max ||
      valueAsNumber < min
    ) {
      setErrorText(`Flow priority must be between ${min} and ${max}.`);
      sortPriority.current.value = value;
    } else if (valueAsNumber % 1 > 0) {
      const newValue = Number(valueAsNumber.toFixed(2));
      sortPriority.current.value = newValue;
      onChange(newValue);
    } else {
      const newValue = Math.round(valueAsNumber);
      onChange(newValue);
      sortPriority.current.value = newValue;
    }
  };

  const handleChange = () => void setErrorText('');

  return (
    <Panel>
      <Container id="flow-priority">
        <Header>
          <H2>Flow priority</H2>
          <Text secondary>(optional)</Text>
        </Header>
        <Text secondary>
          If multiple flows qualify to be shown to a user, the one with the
          greatest weight will be shown first.{' '}
          <Link to="/priorities">View Flow priority</Link>
        </Text>
        <InputContainer>
          <Label>
            Flow weight
            <Input
              type="number"
              name="sortPriority"
              aria-label="Sort priority"
              max={max}
              min={min}
              ref={sortPriority}
              defaultValue={value}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Label>
        </InputContainer>
        {errorText && <WarningNotice>{errorText}</WarningNotice>}
        <Label>
          <PreviewLabelText>
            Priority preview when published.{' '}
            <ExternalLink href={LEARN_MORE_URL}>Learn more</ExternalLink>
          </PreviewLabelText>
          <FlowPrioritiesTable flowId={flowId} />
        </Label>
        {throttleEnabled && (
          <ThrottleWarning
            warnText="your users may not see this Flow right away."
            checkboxLabel="Override frequency limit on this Flow"
            ruleId={flowId}
          />
        )}
      </Container>
    </Panel>
  );
};

Prioritization.propTypes = {
  flowId: PropTypes.string,
  value: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  throttleEnabled: PropTypes.bool,
};

const mapStateToProps = (state, { flowId }) => {
  const { sortPriority = 0 } = selectAccountRule(state, flowId);
  const meta = selectAccountMeta(state);
  return {
    value: sortPriority,
    throttleEnabled: meta?.throttleEnabled,
  };
};

const mapDispatchToProps = (dispatch, { flowId }) => ({
  onChange: sortPriority => dispatch(update(flowId, { sortPriority })),
});

const ConnectedPrioritization = connect(
  mapStateToProps,
  mapDispatchToProps
)(Prioritization);

ConnectedPrioritization.propTypes = {
  flowId: PropTypes.string,
};

export default ConnectedPrioritization;
