import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Thead, Tbody, Tr } from '@studio/legacy-components';
import { selectNonArchivedMobile } from 'entities/experiences';
import { sortByKey } from 'utils';
import { selectNonArchivedFlows } from 'reducers/account/flows';
import QueryResults from 'components/Common/QueryResults';
import Pagination, {
  PaginationMessage,
} from 'components/Common/UI/Pagination/Pagination';

import TableOverlay from 'components/Insights/Explorer/Common/TableOverlay';

import {
  ClearEvents,
  ControlHeader,
  EventNameHeader,
  PaginationWrapper,
  ResultsWrapper,
  SelectHeader,
  StatsHeader,
  StatusHeader,
  TableWrapper,
} from 'components/Insights/Common/Styled';

import {
  AddEventTooltip,
  TitleTooltip,
} from 'components/Insights/Explorer/Common/SourceTooltips';

import {
  FLOW_EVENTS,
  FLOW_EVENTS_LABEL,
  MOBILE_EVENTS,
} from 'components/Insights/constants';
import { removeRecoveredErrors } from 'components/Insights/utils';
import useClientsideTable from 'hooks/useClientsideTable';
import FlowRow from './FlowRow';

/**
 * Given an object with keys representing flows and a result from
 * the Analytics API, returns a merged result-set
 *
 * @param flows
 * @param results
 * @returns {[{name: string, flow_id: string, published: boolean, events: number, flow_errors: number, flows_shown: number, flows_completed: number, users: number, most_recent_timestamp: number}]}
 */
export const mergeEventsAndResults = (flows, results) =>
  (flows || []).map(({ id, name, published, type: flowType }) => {
    const flowAnalytics = (results || []).filter(
      result => result.flow_id === id && !flowType?.startsWith('mobile')
    );
    const mobileAnalytics = (results || []).filter(
      result => result.experience_id === id && flowType?.startsWith('mobile')
    );

    const analytics = [...flowAnalytics, ...mobileAnalytics];

    const eventNames = flowType?.startsWith('mobile')
      ? MOBILE_EVENTS
      : FLOW_EVENTS;

    const metrics = eventNames.map(({ type, label }) => {
      const metricsObj = analytics.find(
        ({ name: eventType }) => eventType === type
      );
      return (
        {
          ...metricsObj,
          event: label,
          eventType: type,
        } || {
          event: label,
          eventType: type,
          events: 0,
          users: 0,
          most_recent_timestamp: 0,
        }
      );
    });

    const { users: totalUsers } = analytics.find(({ name: eventType }) =>
      flowType === 'mobile'
        ? eventType === 'appcues:v2:experience_started'
        : eventType === 'appcues:flow_started'
    ) || { users: 0 };

    const { most_recent_timestamp } = analytics.sort(
      sortByKey('most_recent_timestamp', 'desc')
    )[0] || {
      most_recent_timestamp: 0,
    };

    return {
      totalEvents: analytics.reduce((acc, { events }) => acc + events, 0),
      most_recent_timestamp,
      totalUsers,
      published,
      id,
      type: flowType,
      name,
      metrics,
    };
  });

export const FlowEvents = ({
  results,
  pageSize = 10,
  selectedEvents,
  enableSelect,
  searchTerm,
  onSelect,
  onClearEvents,
}) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const {
    isEmpty,
    currentIndex,
    orderByColumn,
    orderByDirection,
    results: pageFlows,
    totalResults,
    handleSort,
    handleCurrentIndex,
  } = useClientsideTable({
    data: results,
    searchTerm,
    pageSize,
    defaultOrderColumn: 'totalEvents',
    defaultSortDirection: 'desc',
  });

  const handleExpandRow = clickedId => {
    if (expandedRow === clickedId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(clickedId);
    }
  };

  return (
    <>
      <ResultsWrapper>
        <TableOverlay
          isEmpty={isEmpty}
          results={results}
          type={FLOW_EVENTS_LABEL}
        />
        <TableWrapper>
          <Table>
            <Thead>
              <Tr>
                {pageFlows?.length > 0 ? (
                  <SelectHeader>
                    {selectedEvents?.some(evt => evt.source === 'flow') ? (
                      <ClearEvents
                        onClick={() => {
                          onClearEvents('flow');
                        }}
                      />
                    ) : (
                      <AddEventTooltip />
                    )}
                  </SelectHeader>
                ) : (
                  <ControlHeader />
                )}
                <EventNameHeader
                  sortable
                  sorted={orderByColumn === 'name' ? orderByDirection : null}
                  onClick={() => {
                    handleSort('name');
                  }}
                >
                  Flow Name
                </EventNameHeader>
                <StatusHeader
                  sortable
                  sorted={
                    orderByColumn === 'published' ? orderByDirection : null
                  }
                  onClick={() => {
                    handleSort('published');
                  }}
                >
                  Status
                </StatusHeader>
                <StatsHeader
                  sortable
                  sorted={
                    orderByColumn === 'totalEvents' ? orderByDirection : null
                  }
                  onClick={() => {
                    handleSort('totalEvents');
                  }}
                >
                  <TitleTooltip
                    tip="The number of total event ocurrences."
                    title="Total Events"
                  />
                </StatsHeader>
                <StatsHeader
                  sortable
                  sorted={
                    orderByColumn === 'totalUsers' ? orderByDirection : null
                  }
                  onClick={() => {
                    handleSort('totalUsers');
                  }}
                >
                  <TitleTooltip
                    tip="The number of unique users who triggered the event."
                    title="Unique Users"
                  />
                </StatsHeader>
                <StatsHeader
                  sortable
                  sorted={
                    orderByColumn === 'most_recent_timestamp'
                      ? orderByDirection
                      : null
                  }
                  onClick={() => {
                    handleSort('most_recent_timestamp');
                  }}
                >
                  Last Triggered
                </StatsHeader>
              </Tr>
            </Thead>
            <Tbody>
              {(pageFlows || []).map(
                ({
                  name,
                  published,
                  totalUsers,
                  totalEvents,
                  metrics,
                  most_recent_timestamp,
                  id,
                  users,
                  type,
                }) => (
                  <FlowRow
                    isExpanded={expandedRow === id}
                    onClick={handleExpandRow}
                    selectedEvents={selectedEvents}
                    onSelect={onSelect}
                    enableSelect={enableSelect}
                    key={id}
                    users={users}
                    name={name}
                    published={published}
                    id={id}
                    totalUsers={totalUsers}
                    totalEvents={totalEvents}
                    type={type}
                    metrics={metrics}
                    most_recent_timestamp={most_recent_timestamp}
                  />
                )
              )}
            </Tbody>
          </Table>
        </TableWrapper>
      </ResultsWrapper>
      {pageFlows && pageFlows.length > 0 && (
        <PaginationWrapper>
          <Pagination
            pageCount={Math.ceil(totalResults / pageSize)}
            currentIndex={currentIndex}
            onChange={handleCurrentIndex}
          />
          <PaginationMessage
            currentIndex={currentIndex}
            pageSize={pageSize}
            total={totalResults}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

FlowEvents.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      flows_shown: PropTypes.number,
      flows_completed: PropTypes.number,
      flow_errors: PropTypes.number,
      most_recent_timestamp: PropTypes.number,
      flow_id: PropTypes.string,
      users: PropTypes.number,
    })
  ),
  pageSize: PropTypes.number,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  enableSelect: PropTypes.bool,
  onSelect: PropTypes.func,
  searchTerm: PropTypes.string,
  onClearEvents: PropTypes.func,
};

export const mapStateToProps = (state, { searchTerm, results }) => {
  const flows = selectNonArchivedFlows(state);
  const mobile = selectNonArchivedMobile(state);
  const filtered = [...flows, ...mobile].filter(({ name }) =>
    name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return {
    results: results && mergeEventsAndResults(filtered, results),
  };
};

const ConnectedFlowEvents = connect(mapStateToProps)(FlowEvents);

export const ConnectedFlowEventsWithAnalytics = ({
  startTime,
  endTime,
  searchTerm,
  segmentId,
  pageSize = 10,
  selectedEvents,
  enableSelect,
  onSelect,
  onClearEvents,
}) => (
  <QueryResults
    query={{
      conditions: [
        [
          'event',
          'in',
          [
            ...FLOW_EVENTS.map(({ type }) => type),
            ...MOBILE_EVENTS.map(({ type }) => type),
            'appcues:step_child_recovered',
          ],
        ],
        ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
      ],
      dimensions: ['name', 'flow_id', 'experience_id'],
      metrics: ['most_recent_timestamp', 'events', 'users'],
      start_time: startTime,
      end_time: endTime,
      meta: {
        tags: {
          feature: 'Event analytics',
          page: 'Event explorer',
          component: 'FlowEventsWithAnalytics',
          description: 'Flow event source',
        },
      },
    }}
  >
    {results => (
      <ConnectedFlowEvents
        results={results && removeRecoveredErrors(results)}
        pageSize={pageSize}
        searchTerm={searchTerm}
        startTime={startTime}
        endTime={endTime}
        selectedEvents={selectedEvents}
        enableSelect={enableSelect}
        onSelect={onSelect}
        onClearEvents={onClearEvents}
      />
    )}
  </QueryResults>
);

ConnectedFlowEventsWithAnalytics.propTypes = {
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  searchTerm: PropTypes.string,
  segmentId: PropTypes.string,
  pageSize: PropTypes.number,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  enableSelect: PropTypes.bool,
  onSelect: PropTypes.func,
  onClearEvents: PropTypes.func,
};

export default ConnectedFlowEventsWithAnalytics;
