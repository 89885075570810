import React, { useEffect, useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { useHistory, useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  H1,
  Page,
  PageActions,
  PageBody,
  PageHeader,
} from '@studio/legacy-components';
import useTitle from 'next/hooks/use-title';
import { read as fetchAccountEntitlements } from 'actions/entitlements';
import Loader from 'components/Common/Loader';
import { ENTITLEMENTS } from 'constants/features';
import usePlanData from 'hooks/usePlanDataFromURL';
import {
  selectAccountEntitlementsProcessed,
  selectAccountEntitlementsProcessedSynced,
} from 'reducers/entitlements';
import { selectAccountFeature } from 'reducers/account/features';
import { setupStripe } from 'utils/stripe';

import CheckoutForm from './CheckoutForm';

import { BackArrow, CustomPageTitle } from './styled';

export const getStripeConfig = () => {
  const options = {
    fonts: [
      {
        cssSrc:
          'https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,600;0,700;1,400;1,800&display=swap',
      },
    ],
    // This is the only way to customize the Stripe's Payment element
    appearance: {
      theme: 'none',

      rules: {
        '.Input': {
          border: 'solid 1px rgb(98, 114, 147)',
          fontFamily: 'Mulish',
          fontSize: '14px',
        },
        '.Input::placeholder': {
          color: 'transparent',
        },
        '.Label': {
          fontFamily: 'Mulish',
          fontSize: '14px',
          color: 'rgb(30, 30, 30)',
          fontWeight: 600,
        },
      },
    },
  };

  return options;
};

export const Checkout = ({
  entitlementsProcessed,
  hasEntitlements,
  isSynced,
  onLoad,
}) => {
  useTitle('Subscription | Settings | Appcues');
  const history = useHistory();
  const location = useLocation();
  const { planData } = usePlanData(location);

  const stripePromise = useMemo(() => setupStripe());

  const handleBackAction = () =>
    hasEntitlements
      ? history.push(`/subscription/upgrade${location.search}`)
      : history.push(`/settings/subscription${location.search}`);

  useEffect(() => {
    onLoad?.();
  }, []);

  if (!isSynced) return <Loader />;

  return (
    <Elements stripe={stripePromise} options={getStripeConfig()}>
      <Page>
        <PageHeader>
          <CustomPageTitle>
            <BackArrow icon="chevron-left" onClick={handleBackAction} />
            <H1>Upgrade Plan</H1>
          </CustomPageTitle>

          <PageActions />
        </PageHeader>
        <PageBody>
          {planData && (
            <CheckoutForm
              entitlementsProcessed={entitlementsProcessed}
              planData={planData}
            />
          )}
        </PageBody>
      </Page>
    </Elements>
  );
};

Checkout.propTypes = {
  entitlementsProcessed: PropTypes.object,
  hasEntitlements: PropTypes.bool,
  isSynced: PropTypes.bool,
  onLoad: PropTypes.func,
};

const mapStateToProps = state => ({
  entitlementsProcessed: selectAccountEntitlementsProcessed(state, true),
  hasEntitlements: selectAccountFeature(state, ENTITLEMENTS),
  isSynced: !!selectAccountEntitlementsProcessedSynced(state, true),
});

const mapDispatchToProps = {
  onLoad: fetchAccountEntitlements,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
