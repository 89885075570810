import * as actionTypes from 'constants/accountManagement/actionTypes';

export const removeAccountUserAndFlushUsers = ({ userToRemove }) => {
  return {
    type: actionTypes.REMOVE_ACCOUNT_USER_AND_FLUSH_USERS,
    payload: {
      userToRemove,
    },
  };
};
