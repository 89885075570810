import { createSelector } from 'reselect';
import { createCollectionReducer } from 'next/lib/collections';

import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

export const selectPublishedRules = state => state[TYPE];

export const selectPublishedFlowRules = createSelector(
  selectPublishedRules,
  rules => {
    return Object.entries(rules ?? {})
      .filter(([, { contentType }]) => contentType === 'journey')
      .reduce((acc, [id, rule]) => {
        acc[id] = rule;
        return acc;
      }, {});
  }
);

export const selectPublishedExperienceRules = createSelector(
  selectPublishedRules,
  rules => {
    return Object.entries(rules ?? {})
      .filter(([, { contentType }]) => contentType === 'experience')
      .reduce((acc, [id, rule]) => {
        acc[id] = rule;
        return acc;
      }, {});
  }
);
