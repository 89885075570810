import React, { useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { CPanel, Flex } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';

const ReportHeader = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px 48px 25px 24px;
  cursor: pointer;
  border-radius: '6px 6px 0 0';
`;

const GradientBorder = styled.div`
  background: linear-gradient(to right, #5c5cff, #ff92c6);
  height: 3px;
  width: 100%;
  border-radius: ${props => (props.open ? '0px' : '0 0 6px 6px')};
`;

const FlexContainer = styled(Flex)`
  [class*='CTable'] {
    flex-grow: 1;
  }

  margin: 0;
  padding: 0 24px 25px;
  justify-content: center;
`;

const StyledReport = styled(CPanel)`
  margin-bottom: 30px;
  padding: 0;

  h3 {
    margin: 0;
  }

  .minimized {
    display: none;
  }
`;

export default function Report({ children, title }) {
  const [open, setOpen] = useState(true);

  const handleCollapse = () => {
    setOpen(!open);
  };

  return (
    <StyledReport marginBottom={30}>
      <ReportHeader onClick={handleCollapse}>
        <h3>{title}</h3>
        <Flex>
          <Icon
            icon={open ? 'angle-down' : 'angle-up'}
            fontSize="18px"
            marginLeft="50px"
          />
        </Flex>
      </ReportHeader>
      <GradientBorder open={open} />
      <FlexContainer
        className={classNames(!open ? 'minimized' : null)}
        padding={0}
      >
        {children}
      </FlexContainer>
    </StyledReport>
  );
}

Report.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};
