import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import hash from 'object-hash';
import { connect } from 'react-redux';
import { NavLink, Switch, Route, Redirect, Prompt } from 'react-router-dom';
import { getGlobalCSSFromStyleObject } from '@appcues/theme-utilities';

import {
  CPanel,
  CButton,
  CTab,
  H1,
  Flex,
  Box,
  CPage,
  CTag,
} from '@appcues/component-library';

import tinycolor from 'tinycolor2';
import { Link } from '@studio/legacy-components';
import useTitle from 'next/hooks/use-title';
import Loader from 'components/Common/Loader';
import CreatedUpdatedHeader from 'components/Common/CreatedUpdatedHeader';
import ThemeEditTab from 'components/Themes/ThemeEdit/ThemeEditTab';
import Css from 'components/Themes/ThemeEdit/Css';
import ButtonWithTooltip from 'components/Common/ButtonWithTooltip';
import { ACCOUNT_PERMISSIONS } from 'constants/accountManagement/permissions';
import ThemeOptions from 'components/Themes/ThemeEdit/ThemeOptions';
import PreviewPanel from 'components/Themes/ThemeEdit/PreviewPanel';

import { selectAccountTheme } from 'reducers/account/themes';
import { selectUserRole } from 'reducers/account/users';
import { selectUserId } from 'reducers/user';
import { updateAccountThemeAndFlush } from 'actions/account/themes';
import {
  generalSections,
  patternsSections,
  buttonSections,
  DEFAULT_FONT_SIZE,
} from 'constants/account/themes';

import useBeforeUnload from 'hooks/useBeforeUnload';

const generateHoverHexFor = color => `#${tinycolor(color).lighten(7).toHex()}`;

const getFallbackAttrs = theme => {
  const { primaryColor, secondaryColor } = theme;

  return {
    buttonFontSize: DEFAULT_FONT_SIZE,
    hoverPrimaryColor: generateHoverHexFor(primaryColor),
    hoverSecondaryColor: generateHoverHexFor(secondaryColor),
  };
};

// This is a temporary hack to make old page headers look like new one
const TitleBar = styled(CPage.TitleBar)`
  position: sticky;
  top: 0;
  /* Need bigger zindex here because checklist preview */
  z-index: 1000;
`;

const CancelButton = styled(CButton)`
  --link-text-decoration: none;

  font-size: var(--medium-large);
  line-height: normal;
`;

export const ThemeEdit = ({ theme, disabled, onSave }) => {
  useTitle(`${theme?.name} | Theme | Appcues`);
  const [draftTheme, setDraftTheme] = useState(null);
  const [cssExpanded, setCssExpanded] = useState(false);
  const [editState, setEditState] = useState({
    original: '',
    isDirty: false,
  });

  const setDraftThemeAndGlobalCSS = setDraftThemeInnerFunction => {
    setDraftTheme(draft => {
      const updatedDraftTheme = setDraftThemeInnerFunction(draft);
      const updatedGlobal = getGlobalCSSFromStyleObject(updatedDraftTheme);

      // this is to make sure the user will get the right color data when
      // editing themes that didn't have these attributes before
      const fallbackAttrs = getFallbackAttrs(updatedDraftTheme);

      return {
        ...fallbackAttrs,
        ...updatedDraftTheme,
        globalCss: updatedGlobal,
      };
    });
  };

  useEffect(() => {
    if (theme && !draftTheme) {
      setDraftThemeAndGlobalCSS(() => theme);
    }
    if (theme) {
      // There is a fallback for themes without button hover color styles
      // when saving a draft theme. This will cause the theme to be dirty
      // since those properties does not exist on the original theme
      // In order to avoid this, we are setting the fallback in here
      // when checking for dirty theme state.
      const fallbackAttributes = getFallbackAttrs(theme);
      const initialTheme = {
        ...fallbackAttributes,
        ...theme,
      };

      const { globalCss: omit, ...cleanTheme } = draftTheme || initialTheme;
      const newHash = hash(cleanTheme);
      setEditState(({ original }) => ({
        original: original || newHash,
        isDirty: original && original !== newHash,
      }));
    }
  }, [theme, draftTheme]);

  /**
   * if the underlying theme changes while editing the draft, reset state
   * this can happen if the theme is cloned, and the component re-mounts
   * with the newly created theme
   */

  useEffect(() => {
    if (theme && draftTheme && theme.id !== draftTheme.id) {
      setDraftTheme(null);
    }
  }, [theme]);

  useBeforeUnload(editState.isDirty);

  const handleSaveTheme = () => {
    onSave(draftTheme);
    setEditState({
      original: '',
      isDirty: false,
    });
  };

  const handleExpandCss = buttonTextAction => {
    setCssExpanded(buttonTextAction === 'Expand');
  };

  const isNavigatingToThemesTab = location => {
    const themesTab =
      location.pathname.endsWith('/general') ||
      location.pathname.endsWith('/patterns') ||
      location.pathname.endsWith('/buttons') ||
      location.pathname.endsWith('/css');

    if (!themesTab && editState.isDirty) {
      return 'Unsaved changes will be lost. Are you sure you want to leave?';
    }

    return true;
  };

  if (!draftTheme) {
    return <Loader />;
  }

  return (
    <>
      <Prompt message={isNavigatingToThemesTab} />
      <CPage>
        <TitleBar>
          <CPage.TitleBar.BreadcrumbLink href="/themes" title="Themes">
            Themes
          </CPage.TitleBar.BreadcrumbLink>
          <CPage.TitleBar.Content>
            <H1>{theme.name}</H1>
            {theme.isDefault ? <CTag marginTop={12}>Default</CTag> : null}
          </CPage.TitleBar.Content>
          <CPage.TitleBar.Actions>
            <ThemeOptions
              theme={theme}
              themeId={theme.id}
              border="1px solid #d6dbe7"
              hideEditOption
            />
            <CancelButton is={Link} type="secondary" to="/themes">
              Cancel
            </CancelButton>
            <ButtonWithTooltip
              isDisabled={disabled || !editState.isDirty}
              buttonText="Save"
              tooltipText={
                disabled
                  ? 'Contact a team admin or publisher to save'
                  : 'Edit your theme to save changes'
              }
              onClick={handleSaveTheme}
              coolTipProps={{
                marginRight: '20px',
              }}
            />
          </CPage.TitleBar.Actions>
          <CreatedUpdatedHeader
            createdByUserId={theme.createdBy}
            createdAt={theme.createdAt}
            updatedByUserId={theme.updatedBy}
            updatedAt={theme.updatedAt}
          />
        </TitleBar>
        <CPage.Container>
          <CPanel paddingY={0} paddingRight={0}>
            <Flex flexDirection={cssExpanded ? 'column' : 'row'}>
              <Box
                width={cssExpanded ? '100%' : '50%'}
                paddingRight={cssExpanded && '24px'}
              >
                <CTab.Bar onChange={() => setCssExpanded(false)}>
                  <CTab
                    is={NavLink}
                    to={`/themes/${theme.id}/edit/general`}
                    id="general"
                    width={cssExpanded ? '16.66%' : '33.33%'}
                    textAlign="center"
                  >
                    General
                  </CTab>
                  <CTab
                    is={NavLink}
                    to={`/themes/${theme.id}/edit/buttons`}
                    id="buttons"
                    width={cssExpanded ? '16.66%' : '33.33%'}
                    textAlign="center"
                  >
                    Buttons
                  </CTab>
                  <CTab
                    is={NavLink}
                    to={`/themes/${theme.id}/edit/patterns`}
                    id="patterns"
                    width={cssExpanded ? '16.66%' : '33.33%'}
                    textAlign="center"
                  >
                    Patterns
                  </CTab>
                  <CTab
                    is={NavLink}
                    to={`/themes/${theme.id}/edit/css`}
                    id="css"
                    width={cssExpanded ? '16.66%' : '33.33%'}
                    textAlign="center"
                  >
                    CSS
                  </CTab>
                </CTab.Bar>
                <Switch>
                  <Route
                    path={`/themes/${theme.id}/edit/general`}
                    render={props => (
                      <ThemeEditTab
                        {...props}
                        setDraftTheme={setDraftThemeAndGlobalCSS}
                        draftTheme={draftTheme}
                        sections={generalSections}
                      />
                    )}
                  />
                  <Route
                    path={`/themes/${theme.id}/edit/buttons`}
                    render={props => (
                      <ThemeEditTab
                        {...props}
                        setDraftTheme={setDraftThemeAndGlobalCSS}
                        draftTheme={draftTheme}
                        sections={buttonSections}
                        hasSectionHeading={false}
                      />
                    )}
                  />
                  <Route
                    path={`/themes/${theme.id}/edit/patterns`}
                    render={props => (
                      <ThemeEditTab
                        {...props}
                        setDraftTheme={setDraftThemeAndGlobalCSS}
                        draftTheme={draftTheme}
                        sections={patternsSections}
                      />
                    )}
                  />
                  <Route
                    path={`/themes/${theme.id}/edit/css`}
                    render={props => (
                      <Css
                        {...props}
                        setDraftTheme={setDraftThemeAndGlobalCSS}
                        draftTheme={draftTheme}
                        onExpand={handleExpandCss}
                      />
                    )}
                  />
                  <Redirect to={`/themes/${theme.id}/edit/general`} />
                </Switch>
              </Box>
              <Box
                width={cssExpanded ? '100%' : '50%'}
                marginTop={cssExpanded ? '24px' : '0'}
              >
                <PreviewPanel theme={draftTheme} />
              </Box>
            </Flex>
          </CPanel>
        </CPage.Container>
      </CPage>
    </>
  );
};

ThemeEdit.propTypes = {
  theme: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    isDefault: PropTypes.bool,
    createdAt: PropTypes.number,
    createdBy: PropTypes.string,
    updatedAt: PropTypes.number,
    updatedBy: PropTypes.string,
    globalCss: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  onSave: PropTypes.func,
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { themeId },
    },
  }
) => ({
  theme: selectAccountTheme(state, themeId),
  disabled:
    selectUserRole(state, selectUserId(state)) === ACCOUNT_PERMISSIONS.EDITOR,
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { themeId },
    },
  }
) => ({
  onSave: changes => {
    dispatch(updateAccountThemeAndFlush(themeId, changes));
  },
});

const ConnectedThemeEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(ThemeEdit);

ConnectedThemeEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      themeId: PropTypes.string,
    }),
  }),
};

export default ConnectedThemeEdit;
