import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Link,
  SearchInput,
  H3,
  Panel,
  PanelHeader,
  PanelTitle,
  ExternalLink,
  PanelActions,
  Spinner,
} from '@studio/legacy-components';
import useTitle from 'next/hooks/use-title';
import { callApi as fetchAllUserSegments } from 'entities/user-segments';
import { selectUserSegmentsDetails } from 'selectors/user-segments';
import NoData from 'assets/images/no-data.svg';
import NoResults from 'assets/images/no-results.svg';
import {
  TableWrapper,
  LoaderWrapper,
  NoResultsMessage,
  NoResultsMessageEmphasis,
} from 'components/Audience/styled';
import { SegmentTable, SegmentRow } from './styled';

export const createSortFn =
  sortConfig =>
  (a = '', b = '') => {
    // all columns are sorted as strings
    const aValue = a[sortConfig.column] || '-';
    const bValue = b[sortConfig.column] || '-';

    if (sortConfig.column !== 'createdAt' && aValue !== bValue) {
      if (sortConfig.direction === 'asc') {
        return aValue.localeCompare(bValue, undefined, { numeric: true });
      }

      return bValue.localeCompare(aValue, undefined, {
        numeric: true,
      });
    }

    const aDate = new Date(a.createdAt);
    const bDate = new Date(b.createdAt);
    // and in case of a tie we sort by date
    if (sortConfig.direction === 'desc') {
      return bDate - aDate;
    }

    return aDate - bDate;
  };
export function Segments({ onLoad, userSegments }) {
  useTitle('Segments | Users | Appcues');
  const [query, setQuery] = useState('');

  // Avoiding to have 2 states that should be updated together
  // So we are not exposed to bugs realted to call order and avoid unnecessary re-renders
  const [sortConfig, setSortConfig] = useState({
    direction: 'asc',
    column: 'createdAt',
  });

  const isLoading = !userSegments;

  const filteredSegments = userSegments?.filter(
    // there are a few accounts for which deleted segments are published
    // a consequence of null-checking for `name` is to omit these
    ({ name }) => name && name.toUpperCase().includes(query.toUpperCase())
  );

  const computedSegments = filteredSegments?.sort(createSortFn(sortConfig));

  const hasNoData = userSegments?.length === 0;
  const hasNoResults =
    !hasNoData && query.length > 0 && computedSegments?.length === 0;

  const handleSortClick = column => () => {
    setSortConfig(currentConfig => {
      if (currentConfig.column === column) {
        return {
          ...currentConfig,
          direction: currentConfig.direction === 'asc' ? 'desc' : 'asc',
        };
      }

      return {
        column,
        direction: 'asc',
      };
    });
  };

  useEffect(() => {
    onLoad?.();
  }, [onLoad]);

  return (
    <Panel>
      <PanelHeader>
        <PanelTitle>
          <H3>Segments</H3>
          <span>
            Segments this user is a member of based on the Segment&apos;s
            settings and user&apos;s properties.{' '}
            <ExternalLink href="https://docs.appcues.com/article/769-user-profile#segments">
              Learn more
            </ExternalLink>
          </span>
        </PanelTitle>
        <PanelActions>
          <SearchInput
            placeholder="Search for Segment name"
            value={query}
            onChange={({ target: { value } }) => {
              setQuery(value);
            }}
          />
        </PanelActions>
      </PanelHeader>
      <TableWrapper>
        <SegmentTable sticky>
          <Thead>
            <Tr>
              <Th
                sortable
                sorted={sortConfig.column === 'name' && sortConfig.direction}
                onClick={handleSortClick('name')}
              >
                Segment name
              </Th>
              <Th
                sortable
                sorted={
                  sortConfig.column === 'description' && sortConfig.direction
                }
                onClick={handleSortClick('description')}
              >
                Description
              </Th>
              <Th
                sortable
                sorted={
                  sortConfig.column === 'userName' && sortConfig.direction
                }
                onClick={handleSortClick('userName')}
              >
                Created by
              </Th>
              <Th
                sortable
                sorted={
                  sortConfig.column === 'createdAt' && sortConfig.direction
                }
                onClick={handleSortClick('createdAt')}
              >
                Created date
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {computedSegments?.map(
              ({ name, id, description, createdAt, userName }) => {
                const date = new Date(createdAt);
                return (
                  <SegmentRow key={id}>
                    <Td title={name}>
                      <Link to={`/segments/${id}/view`} target="_blank">
                        {name || '-'}
                      </Link>
                    </Td>
                    <Td title={description}>{description || '-'}</Td>
                    <Td>{userName || '-'}</Td>
                    <Td>
                      {date.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </Td>
                  </SegmentRow>
                );
              }
            )}
          </Tbody>
        </SegmentTable>
        {isLoading && (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        )}
        {hasNoResults && (
          <NoResultsMessage>
            <img src={NoResults} alt="No data found" />
            <NoResultsMessageEmphasis>
              No results found
            </NoResultsMessageEmphasis>
            Try adjusting your filters for results
          </NoResultsMessage>
        )}
        {hasNoData && (
          <NoResultsMessage>
            <img src={NoData} alt="No data found" />
            We don’t have data for you yet
          </NoResultsMessage>
        )}
      </TableWrapper>
    </Panel>
  );
}

Segments.propTypes = {
  onLoad: PropTypes.func,
  userSegments: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.number,
      createdBy: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.string,
      meta: PropTypes.object,
      name: PropTypes.string,
      updatedAt: PropTypes.number,
      updatedBy: PropTypes.string,
    })
  ),
};

const mapStateToProps = (state, { userId }) => ({
  userSegments: selectUserSegmentsDetails(state, userId),
});

const mapDispatchToProps = (dispatch, { userId }) => ({
  onLoad: () => dispatch(fetchAllUserSegments({ userId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Segments);
