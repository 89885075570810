import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import QueryResults from 'components/Common/QueryResults';
import MetricCard from 'components/Common/Metric/MetricCard';

const METRICS = ['active_users', 'all_flow_users', 'all_flows_shown'];

// analytics event began to be stored in this year
const BEGINING_OF_TIME = '2016-01-01';

const OverviewMetrics = ({ period }) => {
  const isAllTime = !period;
  const deltaTimePeriod = isAllTime ? 'all_time' : period;

  const {
    currentStartTime,
    currentEndTime,
    previousStartTime,
    previousEndTime,
  } = useMemo(() => {
    const endTime = moment().valueOf();
    const startTime = isAllTime
      ? moment(BEGINING_OF_TIME).startOf('day').valueOf()
      : moment(endTime).subtract(period, 'days').valueOf();

    const prevEndTime = moment(startTime).subtract(1, 'ms').valueOf();
    // We do not need to compare for all time stats, so use the same date
    // to fetch the "previous" period for all time.
    const prevStartTime = isAllTime
      ? prevEndTime
      : moment(prevEndTime).subtract(period, 'days').valueOf();

    return {
      currentStartTime: startTime,
      currentEndTime: endTime,
      previousStartTime: prevStartTime,
      previousEndTime: prevEndTime,
    };
  }, [period]);

  const currentQuery = {
    metrics: METRICS,
    start_time: currentStartTime,
    end_time: currentEndTime,
    meta: {
      tags: {
        feature: 'Overview',
        page: 'Overview',
        component: 'OverviewMetrics',
        description: 'Current metrics',
      },
    },
  };

  const previousQuery = {
    metrics: METRICS,
    start_time: previousStartTime,
    end_time: previousEndTime,
    meta: {
      tags: {
        feature: 'Overview',
        page: 'Overview',
        component: 'OverviewMetrics',
        description: 'Previous metrics',
      },
    },
  };

  return (
    <QueryResults query={currentQuery}>
      {currentResults => {
        return (
          <QueryResults query={previousQuery}>
            {previousResults => {
              const loaded = !!(currentResults && previousResults);
              const defaultResults = [
                {
                  active_users: 0,
                  all_flow_users: 0,
                  all_flows_shown: 0,
                },
              ];

              const [{ active_users, all_flow_users, all_flows_shown }] =
                currentResults ?? defaultResults;
              const currentFlowFrequency =
                all_flow_users === 0 ? 0 : all_flows_shown / all_flow_users;

              let [
                {
                  active_users: prevUsers,
                  all_flow_users: prevFlowUsers,
                  all_flows_shown: prevFlowsShown,
                },
              ] = previousResults ?? defaultResults;
              let previousFlowFrequency =
                prevFlowUsers === 0 ? 0 : prevFlowsShown / prevFlowUsers;

              // In order the make <Delta > show the "All time" text
              // We are faking "results" for prev period.
              // This fake results are only used to by pass an inner check
              // Since in reality those are not even shown ¯\_(ツ)_/¯
              if (isAllTime) {
                prevUsers = 1;
                prevFlowUsers = 1;
                prevFlowsShown = 1;
                previousFlowFrequency = 1;
              }

              return (
                <>
                  <MetricCard
                    loaded={loaded}
                    name="Active Users"
                    metric={active_users}
                    deltaTimePeriod={deltaTimePeriod}
                    previousMetric={prevUsers}
                    caption="Users active in your app"
                  />
                  <MetricCard
                    loaded={loaded}
                    name="Users Reached"
                    metric={all_flow_users}
                    deltaTimePeriod={deltaTimePeriod}
                    previousMetric={prevFlowUsers}
                    caption="Users that saw a flow"
                  />
                  <MetricCard
                    loaded={loaded}
                    name="Flows Shown"
                    metric={all_flows_shown}
                    deltaTimePeriod={deltaTimePeriod}
                    previousMetric={prevFlowsShown}
                    caption="Live flows seen by users"
                  />
                  <MetricCard
                    loaded={loaded}
                    name="Flow Frequency"
                    metric={currentFlowFrequency}
                    deltaTimePeriod={deltaTimePeriod}
                    previousMetric={previousFlowFrequency}
                    caption="Flows seen per user"
                  />
                </>
              );
            }}
          </QueryResults>
        );
      }}
    </QueryResults>
  );
};

OverviewMetrics.propTypes = {
  period: PropTypes.number,
};

export default OverviewMetrics;
