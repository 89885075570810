import { call, getContext, put, takeEvery, select } from 'redux-saga/effects';
import { replacePagePatternFor } from 'next/entities/page';
import { takeEveryPromised } from 'next/lib/effects';

import { insert, patterns, reject, resolve } from './actions';
import { parse } from './shape';
import { selectSegments } from './reducer';
import { SegmentDuplicateNameError } from './errors';

/**
 * Normalize segments summary response as collection
 *
 * @param {Response} response - Raw segments summary response
 * @return {Collection} Segments collection
 */
const transform = response =>
  response.reduce((acc, segment) => {
    acc[segment.id] = parse(segment);
    return acc;
  }, {});

function* fetchSegments() {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getSegments);
    yield put(resolve(transform(response)));
  } catch (error) {
    yield put(reject(error));
  }
}

function* createSegment({ payload }) {
  try {
    const segments = yield select(selectSegments);

    if (
      segments &&
      Object.values(segments).some(
        ({ name }) => name.toLowerCase() === payload.name.toLowerCase()
      )
    ) {
      throw new SegmentDuplicateNameError(
        `A segment by the name "${payload.name}" already exists.`
      );
    }

    const api = yield getContext('api');
    const response = yield call(api.createSegment, payload);
    yield put(insert(response));

    return response;
  } catch (error) {
    yield put(reject(error));
    throw error;
  }
}

export default function* saga() {
  // Internal actions
  yield takeEvery([patterns.read, patterns.insert], fetchSegments);
  yield takeEveryPromised(patterns.create, createSegment);

  // Page actions
  yield takeEvery(
    [
      replacePagePatternFor('/flows'),
      replacePagePatternFor('/pins'),
      replacePagePatternFor('/banners'),
    ],
    fetchSegments
  );
}
