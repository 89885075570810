import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { baseTheme } from '@appcues/theme-utilities';
import Input from 'components/Common/Forms/Input';

const ThemeSlider = ({ label, draftTheme, setDraftTheme, unit, max }) => {
  const handleChange = useCallback(
    evt => {
      const labelName = label.name;
      const { value } = evt.target;
      let tempValue = value;
      // because we are initialising the slider value to be zero if not set
      // we have to remove the first zero when the user enters a value
      if (tempValue?.length > 1 && tempValue[0] === '0') {
        tempValue = value.replace('0', '');
      }
      // limit value of slider to the max set;
      // important in case the user enters the value directly into the input box.
      if (max && Number.parseInt(tempValue, 10) > max) {
        tempValue = max;
      }
      // the idea here is to set draftTheme[label.name] to zero if the user clears the input entirely
      // which makes sense because this is a slider with a minimum value of zero.
      // by adding the '0' if tempValue is empty, we avoid themeSliderValue falling back to
      // baseTheme[label.name] if draftTheme[label.name] is empty.
      setDraftTheme(prevDraftTheme => {
        return { ...prevDraftTheme, [labelName]: tempValue || '0' };
      });
    },
    [setDraftTheme, max]
  );

  const themeSliderValue = draftTheme[label.name] || baseTheme[label.name] || 0;

  return (
    <Container unit={unit}>
      <StyledSlider
        id="typeinp"
        type="range"
        min="0"
        max={max}
        value={themeSliderValue}
        onChange={handleChange}
        step="1"
      />
      <Input
        type="number"
        min="0"
        max={unit === 'pixel' ? 1000 : max}
        className="announcement-title"
        value={themeSliderValue}
        placeholder={baseTheme[label.name] || '-'}
        onChange={handleChange}
      />
      <span className="unit">{unit === 'pixel' ? 'px' : '%'}</span>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  ${Input} {
    width: 104px;
    height: 40px;
    margin-left: 10px;
    border-width: 1px;
    border-radius: 6px;
  }
  .unit {
    position: absolute;
    right: 10px;
    top: 10px;
    color: ${props => props.theme['$gray-5']};
  }
`;

const StyledSlider = styled.input`
  position: relative;
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  height: 2px;
  border-radius: 10px;
  background: ${props => props.theme['$blue-med']};
  outline: none;
  opacity: 0.7;
  margin-bottom: 4px;
  transition: opacity 0.2s;
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 15px;
    display: block;
    width: 15px;
    height: 15px;
    background: ${props => props.theme.$blue};
    cursor: pointer;
    border: 0;
  }
  ::-moz-range-thumb {
    border-radius: 15px;
    width: 15px;
    height: 15px;
    background: ${props => props.theme.$blue};
    cursor: pointer;
    border: 0;
  }
  ::-moz-range-progress {
    background-color: ${props => props.theme.$blue};
  }
  ::-moz-range-track {
    background-color: ${props => props.theme['$blue-light']};
  }
`;

ThemeSlider.propTypes = {
  label: PropTypes.object.isRequired,
  draftTheme: PropTypes.object.isRequired,
  setDraftTheme: PropTypes.func.isRequired,
  unit: PropTypes.string,
  max: PropTypes.number,
};

export default ThemeSlider;
