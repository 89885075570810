import styled from 'styled-components';
import {
  H3,
  H4,
  Icon,
  Modal,
  SquareButton,
  easing,
} from '@studio/legacy-components';

export const Trigger = styled(SquareButton)`
  --devtools-trigger-bg: var(--blurple);
  --devtools-trigger-hover-bg: var(--deep-work);

  background: var(--devtools-trigger-bg);
  bottom: 24px;
  box-shadow: var(--level-2);
  position: fixed;
  right: 24px;
  transition: ${easing('background')};
  z-index: 10003;

  :hover {
    background: var(--devtools-trigger-hover-bg);
  }
`;

export const Dialog = styled(Modal)`
  --devtools-bg: var(--oh-hi-dark);
  --devtools-title: var(--pink-power-ranger);
  --devtools-subtitle: var(--noreaster);
  --devtools-description: var(--cotton-candy);
  --devtools-text: var(--myst);
  --devtools-separator: var(--sharkbait-ooh-la-la);
  --devtools-warning: var(--cheeeese);

  background: var(--devtools-bg);

  * {
    font-family: monospace;
  }
`;

export const Title = styled(H3)`
  color: var(--devtools-title);
  text-transform: uppercase;
`;

export const Subtitle = styled(H4)`
  color: var(--devtools-subtitle);
`;

export const Tool = styled.div`
  border: 1px solid var(--white);
  color: var(--devtools-text);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 24px 24px;
  margin-top: 16px;
`;

export const Description = styled.p`
  color: var(--devtools-description);
  font-size: var(--small);
  margin: 16px 0;
  padding: 0 8px;
`;

export const Controls = styled.div`
  overflow-y: auto;
  padding: 8px 0;
`;

export const Actions = styled.footer`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  border-top: 1px solid var(--devtools-separator);
  padding-top: 16px;
`;

export const Warning = styled.div`
  color: var(--devtools-warning);
  font-size: var(--small);
  font-weight: var(--semi-bold);
  margin-right: 12px;
  text-transform: uppercase;
`;

/**
 * Features components
 */
export const Cell = styled.span`
  align-items: center;
  display: flex;
  padding: 8px;
`;

export const Name = styled(Cell)`
  font-weight: var(--semi-bold);
`;

export const Row = styled.div`
  align-items: center;
  background: transparent;
  cursor: default;
  display: flex;
  justify-content: space-between;
  transition: ${easing('background')};

  :hover {
    background: rgba(255, 255, 255, 0.05);
  }

  > ${Name} {
    border-left-width: 2px;
    border-left-style: solid;
    border-left-color: transparent;
    flex-grow: 1;
    transition: ${easing('border-left-color')};
  }

  :hover > ${Name} {
    border-left-color: var(--secondary);
  }

  ${({ disabled }) =>
    disabled &&
    `
      color: var(--disabled);

      :hover > ${Name} {
        border-left-color: var(--disabled);
      }
    `}
`;

export const Unregistered = styled.div`
  ${Name} {
    color: var(--sharkbait-ooh-la-la);
  }
`;

export const Collapse = styled.div`
  align-items: center;
  color: var(--dirty-water);
  cursor: pointer;
  display: flex;
  font-size: var(--x-small);
  margin: 8px 0;
  padding: 8px;

  ${Icon} {
    margin-right: 8px;
  }
`;

/**
 * @FIXME: Replace with design system banner once implemented
 */
export const TemporaryBanner = styled.div`
  align-items: center;
  background: white;
  box-shadow: var(--elevation-200);
  display: flex;
  height: 48px;
  justify-content: center;
`;

export const BannerGroup = styled.div`
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 99999;
`;

export const AlertBanner = styled(TemporaryBanner)`
  background: var(--color-pink-600);
  color: white;
  gap: var(--spacing-small);
`;

export const AlertLinkWrapper = styled.div``;
