import { createBrowserHistory, createHashHistory } from 'history';

/**
 * Regex pattern for matching preview index files
 */
const PREVIEW_PATTERN = /^\/index\.([\da-f]{40})\.html/;

/**
 * Determine whether the current URL is a preview URL
 *
 * @return {boolean} Whether previewing
 */
export const isPreviewing = () => {
  const { location: { pathname = '' } = {} } = window;
  return PREVIEW_PATTERN.test(pathname);
};

/**
 * Create history environment based on whether the URL points to the root index
 * file or a preview index file. If the root index is used, use the browser
 * history environment as all paths can resolve without the index.html.
 * Otherwise if the preview index is used, use a hash history environment to
 * preserve the preview index file during navigation.
 */
export const createHistory = () => {
  return isPreviewing() ? createHashHistory() : createBrowserHistory();
};

/**
 * Export shared history singleton
 */
export const history = createHistory();

/**
 * Perform non-SPA navigation respecting preview state. Because location actions
 * like `assign` do not take into consideration whether we are previewing e.g.
 * using the hash router, we need to manually check for that state and perform
 * the router equivalent action.
 *
 * @param {string} href - URL to navigate
 * @return {void}
 */
export const navigate = href => {
  switch (true) {
    // If no path is provided, reload the page
    case href == null:
      window.location.reload();
      break;

    // Else if path provided and preview link, update hash and reload
    case isPreviewing():
      window.location.hash = `#${href}`;
      window.location.reload();
      break;

    // Otherwise navigate to path
    default:
      window.location.assign(href);
      break;
  }
};
