import React from 'react';
import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';

const StatusIcon = props => {
  const { className, error, pending, qualified } = props;
  return (
    <span className={className}>
      <Icon
        icon={`${
          pending
            ? 'circle-notch'
            : error
            ? 'exclamation'
            : qualified
            ? 'check'
            : qualified === null
            ? 'far fa-circle'
            : 'times'
        }`}
      />
    </span>
  );
};

export default styled(StatusIcon)`
  width: 1.75em;
  [class*='fa'] {
    color: ${props => props.theme['$gray-20']};
  }
  .fa-circle-notch {
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
    animation: spin 0.5s linear infinite;
  }
  .fa-exclamation {
    color: ${props => props.theme['$yellow-xtradark']};
  }
  .fa-check {
    color: ${props => props.theme.$green};
  }
  .fa-times {
    color: ${props => props.theme['$red-dark']};
  }
`;
