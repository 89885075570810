import { createSelector } from 'reselect';
import { createEntityReducer } from 'reducers/account/collections';
import { TYPE } from './actions';

export default createEntityReducer(TYPE);

export const selectExperiences = state => state.account[TYPE];
export const selectExperience = (state, id) => selectExperiences(state)?.[id];

export const selectPins = createSelector(
  selectExperiences,
  experiences =>
    experiences &&
    Object.values(experiences)
      .filter(({ type }) => type === 'persistent')
      .reduce((acc, experience) => {
        const { id } = experience;
        acc[id] = experience;
        return acc;
      }, {})
);

export const selectExperiencesByType = createSelector(
  selectExperiences,
  (_state, type) => type,
  (experiences, type) =>
    experiences &&
    Object.values(experiences)
      .filter(experience => experience.type === type)
      .reduce((acc, experience) => {
        const { id } = experience;
        acc[id] = experience;
        return acc;
      }, {})
);

export const selectPin = (state, pinId) => selectPins(state)?.[pinId];

export const selectMobileFlows = createSelector(
  selectExperiences,
  experiences =>
    experiences &&
    Object.values(experiences)
      .filter(({ type }) => type === 'mobile' || type === 'mobile_embed')
      .reduce((acc, experience) => {
        const { id } = experience;
        acc[id] = experience;
        return acc;
      }, {})
);

export const selectMobile = (state, flowId) =>
  selectMobileFlows(state)?.[flowId];

export const selectNonArchivedPins = state => {
  const pinsById = selectPins(state) || {};
  return Object.values(pinsById).filter(pin => pin.state !== 'ARCHIVED');
};

export const selectNonArchivedMobile = state => {
  const mobileById = selectMobileFlows(state) || {};
  return Object.values(mobileById).filter(flow => flow.state !== 'ARCHIVED');
};

export const selectNonArchivedExperienceByType = (state, type) => {
  const experienceById = selectExperiencesByType(state, type) || {};
  return Object.values(experienceById).filter(pin => pin.state !== 'ARCHIVED');
};

export const selectBanners = state => selectExperiencesByType(state, 'banner');
