export const modalStatuses = {
  ERROR: 'error',
  PENDING: 'pending',
  PROMPT: 'prompt',
  SUCCESS: 'success',
};

export const PUBLISH_ACTIONS = {
  REVERT: 'revert',
  PUBLISH: 'publish',
  PUSH: 'push',
  UNPUBLISH: 'unpublish',
  CREATE: 'create',
  CREATE_TEST: 'create_test',
  DELETE: 'delete',
  CLONE: 'clone',
  ARCHIVE: 'archive',
  UNARCHIVE: 'unarchive',
};
