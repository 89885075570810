import React from 'react';
import PropTypes from 'prop-types';

import { PublishingBanner } from '@studio/settings-panels';
import PinsPublishingManager from './PinsPublishingManager';

export function PinBanner({ id }) {
  return (
    <PinsPublishingManager id={id}>{PublishingBanner}</PinsPublishingManager>
  );
}

PinBanner.propTypes = {
  id: PropTypes.string.isRequired,
};

export default PinBanner;
