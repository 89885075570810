import { createSelector } from 'reselect';
import { createEntityReducer } from 'next/lib/collections';
import { format, isAfter, isBefore } from 'next/lib/date';

import { TYPE } from './actions';

export default createEntityReducer(TYPE);

export const selectExperienceVersions = state => state[TYPE];

/**
 * Select versions for an experience by start time
 *
 * @param {State} state - Redux state
 * @param {string} startTime - Timestamp for beginning of range
 * @return {Version[]} List of versions for the experience
 */
export const selectExperienceVersionsByStart = createSelector(
  selectExperienceVersions,
  (state, meta) => meta,
  (versions = {}, { id, startTime, endTime }) => {
    const experienceVersions = versions[id];
    return experienceVersions
      ? Object.values(experienceVersions)
          .filter(({ version }) => {
            const date = format(Number(version), 'YYYY-MM-DD');
            return (
              date === startTime ||
              date === endTime ||
              (isAfter(date, startTime) && isBefore(date, endTime))
            );
          })
          .reduce((acc, version) => {
            const date = format(Number(version.version), 'YYYY-MM-DD');

            return acc[date]
              ? { ...acc, [date]: [...acc[date], version] }
              : { ...acc, [date]: [version] };
          }, {})
      : [];
  }
);
