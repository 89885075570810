import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { selectAccountUser } from 'reducers/account/users';
import AvatarImg from 'components/Common/AvatarImg';

export const Badge = ({ action, timestamp, id, name }) => {
  return (
    <>
      <AvatarImg userId={id} width={24} marginRight="1em" />
      {action}
      {name && ` by ${name}`}
      {timestamp && ` on ${moment(timestamp).format('LL')}`}
    </>
  );
};

Badge.propTypes = {
  action: PropTypes.string,
  timestamp: PropTypes.number,
  id: PropTypes.string,
  name: PropTypes.string,
};

const mapStateToProps = (state, { id }) => {
  const user = selectAccountUser(state, id);
  return {
    name: user && user.meta && user.meta.fullname,
  };
};

const ConnectedBadge = connect(mapStateToProps)(Badge);

ConnectedBadge.propTypes = {
  action: PropTypes.string,
  timestamp: PropTypes.number,
  id: PropTypes.string,
};

export default ConnectedBadge;
