import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CDialog } from '@appcues/component-library';

const GoalLimitModal = props => {
  const { title, activeGoalsCount, actions, className, action } = props;
  return (
    <CDialog
      title={title}
      className={className}
      primaryActionText="Okay"
      onPrimaryAction={actions.hideModal}
    >
      <TextWrapper>
        You already have <strong>{activeGoalsCount}</strong> active goals, which
        is the limit for your plan.
        <br />
        <br />
        To {action} a new goal, you can either deactivate one of your goals or
        upgrade your plan.
      </TextWrapper>
    </CDialog>
  );
};

GoalLimitModal.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  activeGoalsCount: PropTypes.number.isRequired,
  planName: PropTypes.string.isRequired,
  actions: PropTypes.shape({ hideModal: PropTypes.func.isRequired }).isRequired,
};

export default GoalLimitModal;

const TextWrapper = styled.div`
  font-size: 16px;
  line-height: 26px;
`;
