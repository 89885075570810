import { CREATE_RULE, FETCH_FLOW_RULE } from 'constants/account/rules';

import { createLifecycleFor } from 'actions/account/collections';

const TYPE = 'rules';

export const {
  callApi,
  send,
  append,
  resolve,
  reject,
  update,
  replace,
  flush,
  patterns,
} = createLifecycleFor(TYPE);

export function createRule(id, rule, conditions) {
  return {
    type: CREATE_RULE,
    payload: {
      id,
      rule,
      conditions,
    },
  };
}

export function fetchFlowRule(id) {
  return {
    type: FETCH_FLOW_RULE,
    payload: { id },
  };
}
