import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import classNames from 'classnames';

import { CTab } from '@appcues/component-library';
import FlowEligibility from 'components/Diagnostics/FlowEligibility';
import ChecklistEligibility from 'components/Diagnostics/ChecklistEligibility';
import Loader from 'components/Common/Loader';

import {
  selectFlows,
  selectAreFlowsSynced,
  selectStepGroups,
} from 'reducers/account/flows';
import {
  selectAccountSegments,
  selectAccountSegmentsSynced,
} from 'reducers/account/segments';
import {
  selectAccountRules,
  selectAccountRulesSynced,
} from 'reducers/account/rules';
import {
  selectAccountConditions,
  selectAccountConditionsSynced,
} from 'reducers/account/conditions';
import {
  selectAccountMeta,
  selectAccountMetaSynced,
} from 'reducers/account/meta';
import { selectAccountChecklists } from 'entities/checklists';
import { callApi as fetchRules } from 'actions/account/rules';
import { navigate } from 'actions/routing';
import { showModal } from 'actions/currentModal';
import { getQualifiedFlows } from 'helpers/api';
import {
  selectAccountFeature,
  selectAccountFeaturesSynced,
} from 'reducers/account/features';
import { FREQUENCY_BUFFER } from 'constants/features';

import { selectIsInstalled } from 'reducers/account/installedDomains';

const ContentEligibilityWrapper = styled.div`
  margin-top: 1rem;
`;

const VIEWS = {
  FLOWS: 'FLOWS',
  CHECKLISTS: 'CHECKLSITS',
};

export class ContentEligibility extends Component {
  state = {
    qualifyRequested: false,
    qualifyLoaded: false,
    qualifiedFlows: [],
    qualifiedChecklists: [],
    view: VIEWS.FLOWS,
  };

  UNSAFE_componentWillMount() {
    // eslint-disable-next-line new-cap
    this.UNSAFE_componentWillReceiveProps(this.props);
  }

  componentDidMount() {
    const { onLoad } = this.props;
    onLoad();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { qualifyRequested } = this.state;
    const { userId, url, loaded } = this.props;
    const { userId: nextUserId, url: nextUrl } = nextProps;

    const hasUserOrUrlChanged = userId !== nextUserId || url !== nextUrl;

    if (loaded && (hasUserOrUrlChanged || !qualifyRequested)) {
      this.qualifyUserAndUrl(nextUserId, nextUrl);
    }
  }

  changeView = view => this.setState({ view });

  async qualifyUserAndUrl(userId, url) {
    const { accountId, steps } = this.props;

    try {
      this.setState({
        qualifiedFlows: [],
        qualifyRequested: true,
        qualifyLoaded: false,
      });

      const qualifiedFlows = await getQualifiedFlows(accountId, userId, url);
      const qualifiedNonTestFlows = qualifiedFlows.contents.filter(flow => {
        const step = flow.id && steps[flow.id];
        return step && !step.testVersionOf;
      });

      this.setState({
        qualifyLoaded: true,
        qualifiedFlows: qualifiedNonTestFlows,
        qualifiedChecklists: qualifiedFlows.checklists,
      });
    } catch {
      // do something useful here someday
    }
  }

  render() {
    const { qualifyLoaded, qualifiedFlows, qualifiedChecklists, view } =
      this.state;
    const {
      accountId,
      className,
      userId,
      url,
      steps,
      rules,
      conditions,
      segments,
      meta,
      isInstalled,
      userProfile,

      checklists,
      hasFrequencyBuffer,
    } = this.props;

    // immediately eject without trying to calculate stuff here
    if (!qualifyLoaded) {
      return <Loader margin="75px" />;
    }

    const allContent = steps || {};

    const publishedContent = Object.values(allContent || {}).filter(step => {
      return step.published && !step.parentId;
    });

    const qualifiedContent = qualifiedFlows.map(
      flow =>
        publishedContent.find(step => step.id === flow.id) || {
          id: flow.id,
        }
    );

    const unqualifiedContent = publishedContent.filter(
      content =>
        content.id &&
        !qualifiedContent.some(it => it.id === content.id) &&
        !(steps[content.id] && steps[content.id].testVersionOf)
    );

    const publishedChecklists = Object.values(checklists || {}).filter(
      checklist => {
        return checklist.published;
      }
    );

    const sdkQualifiedChecklists = publishedChecklists.filter(checklist => {
      return qualifiedChecklists.some(qualChecklist => {
        const userOptOut =
          qualChecklist.state.status === 'dismissed' ||
          qualChecklist.state.status === 'skipped';
        return qualChecklist.checklist.id === checklist.id && !userOptOut;
      });
    });

    const statusChecklists = sdkQualifiedChecklists.map(checklist => {
      const apiList = qualifiedChecklists.find(
        qualList => qualList.checklist.id === checklist.id
      );

      const statusItems = Object.values(checklist.items || {}).map(item => {
        const apiItem = apiList.state.items.find(
          qualItem => qualItem.item.id === item.id
        );

        if (apiItem && apiItem.is_completed) {
          return { ...item, state: 'complete' };
        }

        return item;
      });

      return { ...checklist, items: statusItems };
    });

    const unqualifiedChecklists = publishedChecklists.filter(checklist => {
      return !sdkQualifiedChecklists.some(qualChecklist => {
        return qualChecklist.id === checklist.id;
      });
    });

    const statusUnqualifiedChecklists = unqualifiedChecklists.map(checklist => {
      const profileKey = `_checklist_status_${checklist.id}`;
      const checklistProfileStatus = userProfile && userProfile[profileKey];

      return { ...checklist, status: checklistProfileStatus };
    });

    const wrapperClasses = classNames(className, 'content-eligibility');

    return (
      <ContentEligibilityWrapper className={wrapperClasses}>
        <CTab.Bar value={view} onChange={this.changeView}>
          <CTab id={VIEWS.FLOWS}>Flows</CTab>
          <CTab id={VIEWS.CHECKLISTS}>Checklists</CTab>
        </CTab.Bar>
        {view === VIEWS.FLOWS && (
          <FlowEligibility
            accountId={accountId}
            allContent={allContent}
            conditions={conditions}
            meta={meta}
            isInstalled={isInstalled}
            navigate={navigate}
            qualifiedSteps={qualifiedContent}
            rules={rules}
            segments={segments}
            unqualifiedSteps={unqualifiedContent}
            url={url}
            userId={userId}
            userProfile={userProfile}
            hasFrequencyBuffer={hasFrequencyBuffer}
            checklists={checklists}
          />
        )}
        {view === VIEWS.CHECKLISTS && (
          <ChecklistEligibility
            accountId={accountId}
            allContent={allContent}
            conditions={conditions}
            meta={meta}
            isInstalled={isInstalled}
            navigate={navigate}
            qualifiedChecklists={statusChecklists}
            rules={rules}
            segments={segments}
            unqualifiedChecklists={statusUnqualifiedChecklists}
            url={url}
            userId={userId}
            userProfile={userProfile}
            hasFrequencyBuffer={hasFrequencyBuffer}
            checklists={checklists}
          />
        )}
      </ContentEligibilityWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    loaded: [
      selectAreFlowsSynced(state),
      selectAccountRulesSynced(state),
      selectAccountConditionsSynced(state),
      selectAccountSegmentsSynced(state),
      selectAccountMetaSynced(state),
      selectAccountFeaturesSynced(state),
    ].every(Boolean),
    steps: { ...selectFlows(state), ...selectStepGroups(state) },
    rules: selectAccountRules(state),
    conditions: selectAccountConditions(state),
    segments: selectAccountSegments(state),
    checklists: selectAccountChecklists(state),
    meta: selectAccountMeta(state),
    isInstalled: selectIsInstalled(state),
    hasFrequencyBuffer: selectAccountFeature(state, FREQUENCY_BUFFER),
  };
}

const mapDispatchToProps = {
  onLoad: fetchRules,
  showModal,
  navigate,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentEligibility);
