import { selectFlow } from 'reducers/account/flows';

const blockTypes = {
  linksBlock: 'links_block',
  flowsBlock: 'auto_flows',
  knowledgeSearchResults: 'search_results',
};

const interactionTypes = {
  linkClick: 'link_click',
  flowClick: 'flow_click',
  searchResultClick: 'search_result_click',
  searchQuery: 'search_query',
  opened: 'opened',
};

const interactionTypeDisplayNames = {
  [interactionTypes.linkClick]: 'Link Click',
  [interactionTypes.flowClick]: 'Flow Click',
  [interactionTypes.searchResultClick]: 'Knowledge Base Article Click',
  [interactionTypes.searchQuery]: 'Knowledge Base Search',
  [interactionTypes.opened]: 'Launchpad Opened',
};

const getInteractionTypeDisplayName = ({ interactionData, searchQuery }) => {
  if (searchQuery) {
    return interactionTypes.searchQuery;
  }

  switch (interactionData?.blockType) {
    case blockTypes.linksBlock: {
      if (interactionData?.flowId) {
        return interactionTypes.flowClick;
      }
      return interactionTypes.linkClick;
    }

    case blockTypes.flowsBlock:
      return interactionTypes.flowClick;

    case blockTypes.knowledgeSearchResults:
      return interactionTypes.searchResultClick;

    default: {
      return interactionTypes.opened;
    }
  }
};

const getInteractionElementDisplayName = (
  state,
  eventInteractionType,
  { interactionData, searchQuery }
) => {
  switch (eventInteractionType) {
    case interactionTypes.linkClick:
      return interactionData?.text;

    case interactionTypes.flowClick:
      return selectFlow(state, interactionData?.flowId)?.name;

    case interactionTypes.searchResultClick:
      return interactionData?.title;

    case interactionTypes.searchQuery:
      return searchQuery;

    default:
      return '';
  }
};

export const launchpadActivitiesDisplayName = (
  state,
  { interactionData, experienceName, searchQuery }
) => {
  const eventInteractionType = getInteractionTypeDisplayName({
    interactionData,
    searchQuery,
  });

  const interactionElement = getInteractionElementDisplayName(
    state,
    eventInteractionType,
    {
      interactionData,
      searchQuery,
    }
  );

  return [
    experienceName,
    interactionTypeDisplayNames[eventInteractionType],
    interactionElement,
  ]
    .filter(Boolean)
    .join(': '); // Result: ${experienceName}: ${interactionType}: ${interactionElement}
};
