import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  StatusLabel,
} from '@studio/legacy-components';
import { keyShape } from 'entities/keys';
import { sortByKey } from 'utils';

import KeysMenu from './KeysMenu';
import KeyDetail from './KeyDetail';
import DisableKey from './DisableKey';
import EnableKey from './EnableKey';
import DeleteKey from './DeleteKey';

// max-width: 1px serves as a hack to make the cell create the ellipsis
const NameColumn = styled(Td)`
  height: 42px;
  max-width: 1px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const KeyColumn = styled(Td)`
  width: 370px;
`;

const DateColumn = styled(Td)`
  width: 130px;
`;

const StatusColumn = styled(Td)`
  width: 120px;
`;

const MenuColumn = styled(Td)`
  text-align: right;
  width: 30px;
`;

// We want the panel border radius to be noticeable at the bottom
const ApiKeysTable = styled(Table)`
  background: transparent;

  ${Tbody} ${Tr}:last-child ${Td} {
    border-bottom: none;
  }
`;

const Header = styled(Th)`
  height: 48px;
`;

const KeyRow = ({ apiKey }) => {
  const [activeModal, setActiveModal] = useState(null);
  const { name, key, legacy, inserted_at, state, role_id, last_seen_at } =
    apiKey;
  const isActive = state === 'active';

  const handleOpenModal = modal => {
    setActiveModal(modal);
  };

  const handleCloseModal = () => {
    setActiveModal(null);
  };

  return (
    <>
      <Tr>
        <NameColumn title={name}>{name}</NameColumn>
        <KeyColumn className="fs-mask">{key}</KeyColumn>
        <DateColumn>
          {inserted_at ? moment(inserted_at).format('MMM D, YYYY') : ''}
        </DateColumn>
        <DateColumn>
          {legacy
            ? 'N/A'
            : last_seen_at
            ? moment(last_seen_at).format('MMM D, YYYY h:mm A')
            : '-'}
        </DateColumn>
        <StatusColumn>
          {isActive ? (
            <StatusLabel icon="check-circle" label="Enabled" active />
          ) : (
            <StatusLabel icon="minus-circle" label="Disabled" />
          )}
        </StatusColumn>
        <MenuColumn>
          <KeysMenu
            legacy={legacy}
            state={state}
            onOptionClick={handleOpenModal}
          />
        </MenuColumn>
      </Tr>

      <DisableKey
        apiKey={key}
        visible={activeModal === 'disable'}
        onClose={handleCloseModal}
      />
      <EnableKey
        apiKey={key}
        roleId={role_id}
        visible={activeModal === 'enable'}
        onClose={handleCloseModal}
      />

      <DeleteKey
        apiKey={key}
        name={name}
        visible={activeModal === 'delete'}
        onClose={handleCloseModal}
      />

      <KeyDetail
        apiKey={apiKey}
        visible={activeModal === 'view'}
        onClose={handleCloseModal}
      />
    </>
  );
};

KeyRow.propTypes = {
  apiKey: keyShape,
};

const KeysTable = ({ keys = [] }) => {
  const [sortBy, setSortBy] = useState('inserted_at');
  const [sortDirection, setSortDirection] = useState('desc');

  const handleSort = column => () => {
    if (column === sortBy) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
    }
  };

  const sortedKeys = useMemo(() => {
    // Legacy key always comes as the first element
    const [legacyKey, ...apiKeys] = keys;
    apiKeys.sort(sortByKey(sortBy, sortDirection));
    apiKeys.unshift(legacyKey);

    return apiKeys;
  }, [keys, sortBy, sortDirection]);

  return (
    <ApiKeysTable>
      <Thead>
        <Tr>
          <Header
            sortable
            sorted={sortBy === 'name' ? sortDirection : null}
            onClick={handleSort('name')}
          >
            Name
          </Header>
          <Header
            sortable
            sorted={sortBy === 'key' ? sortDirection : null}
            onClick={handleSort('key')}
          >
            Key
          </Header>
          <Header
            sortable
            sorted={sortBy === 'inserted_at' ? sortDirection : null}
            onClick={handleSort('inserted_at')}
          >
            Created
          </Header>
          <Header
            sortable
            sorted={sortBy === 'last_seen_at' ? sortDirection : null}
            onClick={handleSort('last_seen_at')}
          >
            Last Used
          </Header>
          <Header
            sortable
            sorted={sortBy === 'state' ? sortDirection : null}
            onClick={handleSort('state')}
          >
            Status
          </Header>
          <Header />
        </Tr>
      </Thead>
      <Tbody>
        {sortedKeys.map(apiKey => (
          <KeyRow key={apiKey.key} apiKey={apiKey} />
        ))}
      </Tbody>
    </ApiKeysTable>
  );
};

KeysTable.propTypes = {
  keys: PropTypes.arrayOf(keyShape),
};

export default KeysTable;
