import styled from 'styled-components';

import {
  Checkbox,
  CheckboxGroup,
  MenuSection,
  ButtonLink,
  H4,
  SquareButton,
  Icon,
  IconButton,
  ListRow,
  MenuCheckboxItem,
} from '@studio/legacy-components';

// We need this wrapper to absolute position the Badge
export const OpenerWrapper = styled.div`
  cursor: pointer;
  position: relative;
  width: fit-content;
`;

export const Badge = styled.span`
  align-items: center;
  border-radius: 50%;
  background-color: var(--primary);
  color: var(--white);
  display: flex;
  font-size: var(--xx-small);
  height: 16px;
  justify-content: center;
  position: absolute;
  right: -6px;
  top: -6px;
  width: 16px;
`;

export const Opener = styled(SquareButton)`
  &[aria-expanded='true'] {
    border: 1px solid var(--primary);
    background-color: var(--luna);

    ${Icon} {
      color: var(--primary);
    }
  }
`;

export const GroupedFilterWrapper = styled(MenuSection)`
  min-width: 170px;
  max-width: 268px;
`;

export const GroupListWrapper = styled.div``;

export const Options = styled.div`
  min-width: 230px;
  ${Checkbox} {
    width: 100%;
  }
`;

export const CheckboxListItem = styled(MenuCheckboxItem)`
  cursor: pointer;
  outline: none;
  :focus-visible {
    background-color: var(--checkbox-group-hover);
  }
`;

export const Separator = styled.hr`
  background: var(--ui-vertical-separator);
  border: none;
  height: 1px;
  margin: 8px -4px 8px -4px;
`;

export const OptionLabelText = styled.span`
  font-weight: var(--normal);
  display: inline-block;
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;

  ${Icon} {
    width: 16px;
    margin-right: 4px;
    color: var(--sharkbait-ooh-la-la);
  }
`;

export const OptionHeaderLabel = styled.div`
  align-items: center;
  display: flex;
  font-size: var(--x-small);
  gap: 8px;
  ${IconButton} {
    ${Icon} {
      height: 12px;
      width: 12px;
    }
  }
`;

export const OptionsHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid var(--myst);
  color: var(--sharkbait-ooh-la-la);
  display: flex;
  font-weight: var(--bold);
  gap: 40px;
  height: 32px;
  justify-content: space-between;
  margin: 0px -4px 8px -4px;
  padding: 0px 12px 8px 12px;
`;

export const ActiveFilterIcon = styled(Icon).attrs({
  icon: 'circle',
})`
  color: var(--blurple);
  height: 8px;
  position: absolute;
  right: 32px;
  top: 15px;
  width: 8px;
`;

export const OptionGroupName = styled(ListRow)`
  position: relative;
  &:hover {
    ${ActiveFilterIcon} {
      color: var(--blurple);
    }
  }
`;

export const OptionGroup = styled(CheckboxGroup)`
  max-height: 252px;
  ${props => props.$scroll && `overflow-y: auto;`}
  label {
    cursor: pointer;
  }
`;

export const SearchWrapper = styled.div`
  padding: 16px 24px;
  [type='search'] {
    min-width: auto;
  }
`;

export const GroupName = styled(H4)``;

export const FilterWrapper = styled.div`
  min-width: 250px;
  position: relative;
  ${CheckboxGroup} {
    border-bottom: 1px solid var(--ui-vertical-separator);
    padding: 20px 20px 8px;

    ${Checkbox} {
      margin-bottom: 16px;
      padding: 0;
      min-height: auto;

      &:hover {
        background-color: transparent;
      }
    }

    ${GroupName} {
      color: var(--heading-primary);
      font-weight: var(--bold);
      font-size: var(--regular);
      margin-bottom: 16px;
    }
  }

  ${MenuSection} {
    padding: 0;
  }
`;

export const ClearButton = styled(ButtonLink)`
  position: absolute;
  right: 24px;
  top: 22px;
`;
