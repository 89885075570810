import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  ChecklistsShape,
  ConditionsShape,
  ReadableConditions,
} from '@studio/conditions';
import { selectChecklists } from 'next/entities/checklists';
import { selectAccountProfileAttributes } from 'next/lib/selectors';
import { selectTargetingOptions } from 'next/entities/targeting';
import { serializeByKey } from 'next/lib/serialize';

export function ReadableConditionsProvider({
  conditions,
  checklists,
  properties,
  targetingOptions,
}) {
  const options = serializeByKey(targetingOptions, 'id');

  return (
    <ReadableConditions
      banners={options.banners}
      checklists={checklists}
      conditions={conditions}
      emails={options.emails}
      flows={options.flows}
      mobiles={options.mobile}
      pins={options.pins}
      properties={properties}
      segments={options.segments}
    />
  );
}

ReadableConditionsProvider.propTypes = {
  checklists: ChecklistsShape,
  conditions: ConditionsShape,
  properties: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  targetingOptions: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      id: PropTypes.string,
    })
  ),
};

const mapStateToProps = state => {
  return {
    checklists: selectChecklists(state),
    properties: selectAccountProfileAttributes(state),
    targetingOptions: selectTargetingOptions(state),
  };
};

export default connect(mapStateToProps)(ReadableConditionsProvider);
