import React from 'react';
import PropTypes from 'prop-types';
import { Property, TeaserWrapper } from './styled';

export function PropertyTeaser({ values }) {
  const [first, ...rest] = values ?? [];

  if (rest.length === 0) {
    return <Property title={first}>{first}</Property>;
  }

  return (
    <TeaserWrapper title={values.join(', ')}>
      <Property>{first}</Property>
      <Property>+{rest.length}</Property>
    </TeaserWrapper>
  );
}

PropertyTeaser.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
};

export default PropertyTeaser;
