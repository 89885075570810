export const CANCELED = 'CANCELED';
export const API_CALLED = 'API_CALLED';
export const REQUEST_SENT = 'REQUEST_SENT';
export const REQUEST_RESOLVED = 'REQUEST_RESOLVED';
export const REQUEST_REJECTED = 'REQUEST_REJECTED';

export const NEW_ITEM = '@@collection/NEW_ITEM';
export const ITEM_CREATED = '@@collection/ITEM_CREATED';
export const ITEM_INSERTED = '@@collection/ITEM_INSERTED';
export const ITEM_UPDATED = '@@collection/ITEM_UPDATED';
export const ITEM_REPLACED = '@@collection/ITEM_REPLACED';
export const ITEM_FLUSHED = '@@collection/ITEM_FLUSHED';
export const ITEM_REMOVED = '@@collection/ITEM_REMOVED';
