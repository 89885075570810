import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const WebIcon = ({ className }) => (
  <svg
    className={className}
    width="103"
    height="108"
    viewBox="0 0 103 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M51.5 101.472C66.6028 101.472 78.8461 100.447 78.8461 99.1833C78.8461 97.9194 66.6028 96.8948 51.5 96.8948C36.3971 96.8948 24.1538 97.9194 24.1538 99.1833C24.1538 100.447 36.3971 101.472 51.5 101.472Z"
      fill="#E3F2FF"
    />
    <rect
      x="12"
      y="29"
      width="79"
      height="56.4518"
      rx="6"
      fill="#F5FAFF"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 76.2975H91V79.4518C91 82.7655 88.3137 85.4518 85 85.4518H18C14.6863 85.4518 12 82.7655 12 79.4518V76.2975Z"
      fill="white"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="12" y="76.6789" width="79" height="1.52572" fill="#E0E0FF" />
    <path
      d="M12 35C12 31.6863 14.6863 29 18 29H59.8558L12 76.2975V35Z"
      fill="white"
    />
    <path
      d="M58.3367 94.6062H44.6636L45.8295 85.4518H57.2767L58.3367 94.6062Z"
      fill="white"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.6922 99.1833H36.3076C36.3076 96.6286 38.3694 94.6062 40.9738 94.6062H62.026C64.6304 94.6062 66.6922 96.7351 66.6922 99.1833Z"
      fill="white"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.1827 85.8333H56.9691L57.121 87.359H45.9927L46.1827 85.8333Z"
      fill="#E0E0FF"
    />
    <path
      d="M51.3745 81.9977C51.8923 81.9977 52.312 81.5755 52.312 81.0547C52.312 80.534 51.8923 80.1118 51.3745 80.1118C50.8567 80.1118 50.437 80.534 50.437 81.0547C50.437 81.5755 50.8567 81.9977 51.3745 81.9977Z"
      fill="#E3F2FF"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="12"
      y="29"
      width="79"
      height="56.4518"
      rx="6"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="27" y="52" width="49" height="6.75" rx="3.375" fill="#E0E0FF" />
    <rect
      x="27.5"
      y="52.5"
      width="48"
      height="5"
      rx="2.5"
      fill="white"
      stroke="#5C5CFF"
    />
    <path
      d="M30 52.5H65.0574V57.5H30C28.6193 57.5 27.5 56.3807 27.5 55C27.5 53.6193 28.6193 52.5 30 52.5Z"
      fill="url(#paint0_linear_1918_8268)"
      stroke="#5C5CFF"
    />
    <path
      d="M39.6887 22.1048C39.0581 22.1048 38.4274 22.4984 38.1572 23.0889C37.8869 23.6794 37.977 24.4667 38.3373 25.0571L49.1486 40.3111C49.509 40.8032 49.9594 41 50.5 41C51.0406 41 51.491 40.7048 51.8514 40.3111L62.6627 25.0571C63.023 24.4667 63.1131 23.7778 62.8428 23.0889C62.5726 22.4984 61.9419 22.1048 61.3113 22.1048L57.1669 22.1048V13.6413C57.1669 11.5746 55.6353 10 53.8335 10H47.1665C45.2746 10 43.8331 11.673 43.8331 13.6413V22.1048H39.6887V22.1048Z"
      fill="url(#paint1_linear_1918_8268)"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1918_8268"
        x1="27"
        y1="55"
        x2="65.5574"
        y2="55"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5290" />
        <stop offset="0.0001" stopColor="#FF4592" />
        <stop offset="1" stopColor="#8960FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1918_8268"
        x1="38"
        y1="25.5"
        x2="63"
        y2="25.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5C5CFF" />
        <stop offset="1" stopColor="#7D52FF" />
      </linearGradient>
    </defs>
  </svg>
);

WebIcon.propTypes = {
  className: PropTypes.string,
};

export default styled(WebIcon)``;
