import {
  SET_ACCOUNT_META_FETCHING,
  REPLACE_ACCOUNT_META,
  SET_ACCOUNT_META_ERRORED,
  UPDATE_ACCOUNT_META,
} from 'constants/account/meta';
import { CLEAR_ACCOUNT_DATA } from 'constants/actionTypes';

const initialState = {
  meta: {
    fetching: false,
    synced: false,
    errored: false,
  },
  data: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACCOUNT_META_FETCHING:
      return {
        ...state,
        meta: {
          fetching: true,
          synced: false,
          errored: false,
        },
      };
    case REPLACE_ACCOUNT_META:
      return {
        ...state,
        meta: {
          fetching: false,
          synced: true,
          errored: false,
        },
        data: action.payload,
      };
    case SET_ACCOUNT_META_ERRORED:
      return {
        ...state,
        meta: {
          fetching: false,
          synced: true,
          errored: true,
        },
      };
    case UPDATE_ACCOUNT_META:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.changes,
        },
      };
    case CLEAR_ACCOUNT_DATA:
      return initialState;

    default:
      return state;
  }
}

export const selectAccount = state => state.account;
export const selectAccountMeta = state => state.account.meta.data;
export const selectAccountId = state =>
  selectAccountMeta(state).id && `${selectAccountMeta(state).id}`;
export const selectAccountUuid = state => selectAccountMeta(state).uuid;
export const selectAccountMetaMeta = state => state.account.meta.meta;
export const selectAccountMetaSynced = state =>
  selectAccountMetaMeta(state).synced;
export const selectAccountFramework = state =>
  selectAccountMeta(state).selectedInstallFramework;
export const selectIsPlanCanceled = state =>
  selectAccountMeta(state).canceledAt;
export const selectIsSubscriptionEnded = state =>
  selectAccountMeta(state).stripeSubEndedAt;
export const selectHasSubscription = state =>
  !!selectAccountMeta(state).stripeSubscriptionId &&
  !selectIsPlanCanceled(state) &&
  !selectIsSubscriptionEnded(state);
