import styled from 'styled-components';
import { ErrorMsg } from '@studio/legacy-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export const RowItem = styled.div`
  position: relative;
`;

export const Error = styled(ErrorMsg)`
  position: absolute;
  left: 0;
  bottom: -16px;
`;
