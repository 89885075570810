import React from 'react';
import PropTypes from 'prop-types';

import {
  H3,
  RadioGroup,
  Radio,
  RadioControl,
  RadioLabel,
  PanelHeader,
  Panel,
  PanelSection,
  PanelSectionTitle,
  PanelTitle,
  PanelBody,
  HorizontalScroll,
} from '@studio/legacy-components';
import { ConditionsShape } from '@studio/conditions';
import { Conditions } from 'next/components/Conditions';

import { Targeting } from './styled';

const DEFAULT_SCREEN_CONDITIONS = {
  or: [
    {
      screen: { operator: '==' },
    },
  ],
};

export const Screen = ({ conditions, onChange }) => {
  const currentOption = conditions ? 'screen' : 'any';
  const handleOptionChange = nextTrigger => {
    switch (nextTrigger) {
      case 'screen':
        onChange(DEFAULT_SCREEN_CONDITIONS);
        break;
      case 'any':
        onChange();
        break;
      default:
        break;
    }
  };

  const handleScreenConditionsChange = nextScreenConditions =>
    void onChange(nextScreenConditions);

  return (
    <Panel>
      <PanelHeader>
        <PanelTitle>
          <H3>Screen targeting</H3>
        </PanelTitle>
      </PanelHeader>
      <PanelBody>
        <PanelSection>
          <PanelSectionTitle>
            What screen(s) should this content show on?
          </PanelSectionTitle>
          <RadioGroup
            name="screen"
            value={currentOption}
            onValueChange={handleOptionChange}
          >
            <RadioControl>
              <Radio id="any" value="any" />
              <RadioLabel htmlFor="any">Any screen</RadioLabel>
            </RadioControl>

            <RadioControl>
              <Radio id="specific-screen" value="screen" />
              <RadioLabel htmlFor="specific-screen">
                Specific screens
              </RadioLabel>
            </RadioControl>

            {currentOption === 'screen' && (
              <Targeting>
                <HorizontalScroll>
                  <Conditions
                    validClauseTypes={['screen']}
                    conditions={conditions}
                    onChange={handleScreenConditionsChange}
                  />
                </HorizontalScroll>
              </Targeting>
            )}
          </RadioGroup>
        </PanelSection>
      </PanelBody>
    </Panel>
  );
};

Screen.propTypes = {
  conditions: ConditionsShape,
  onChange: PropTypes.func,
};

export default Screen;
