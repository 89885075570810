import React from 'react';
import PropTypes from 'prop-types';
import { CPage } from '@appcues/component-library';
import useTitle from 'next/hooks/use-title';
import IndexPage from 'components/Common/IndexPage';
import { ExportManager } from 'components/Insights/Common/ExportManager';

import SingleMetricContent from './SingleMetricContent';

const ApplicationMetric = ({ match }) => {
  const {
    params: { encodedEventName: eventName },
  } = match;

  useTitle(`${eventName} | Events Explorer | Appcues`);
  return (
    <IndexPage
      title={eventName}
      description="Application event"
      subHeaderActions={
        <ExportManager events={[{ source: 'application', event: eventName }]} />
      }
    >
      <CPage.Container>
        <SingleMetricContent eventName={eventName} source="application" />
      </CPage.Container>
    </IndexPage>
  );
};

ApplicationMetric.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      encodedEventName: PropTypes.string,
    }),
  }),
};

export default ApplicationMetric;
