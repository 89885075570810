import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import styled from 'styled-components';
import { Stepper, CButton } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';
import { DocumentTitle } from 'next/hooks/use-title';
import GreyBackgroundPanel from 'components/Common/GreyBackgroundPanel';
import InstallContent from 'components/InstallationGuide/InstallContent';
import { selectAccountId, selectAccountMeta } from 'reducers/account/meta';
import { navigate } from 'actions/routing';
import { hideModal, showModal } from 'actions/currentModal';

export const STEPS = [
  { key: 'overview', value: 'Overview' },
  { key: 'framework', value: 'Framework' },
  { key: 'script', value: 'Script' },
  { key: 'properties', value: 'Properties' },
  { key: 'events', value: 'Events' },
  { key: 'summary', value: 'Summary' },
];

export class InstallationGuide extends Component {
  state = {
    selectedTab: 'overview',
  };

  componentDidMount() {
    const splitLocation = window.location.pathname.split('/');
    const initialSection =
      splitLocation && Array.isArray(splitLocation) && splitLocation[4];
    const isValidStep = STEPS.find(step => step.key === initialSection);

    if (isValidStep) {
      this.setState({ selectedTab: initialSection });
    }
  }

  selectStep = step => {
    const { actions } = this.props;

    actions.navigate(`/settings/installation/guide/${step}`);
    this.setState({ selectedTab: step });
    window.scrollTo(0, 0);
  };

  handleButtonClick = direction => {
    const { selectedTab } = this.state;

    const currentStepIndex = STEPS.findIndex(step => step.key === selectedTab);
    const nextStepIndex =
      direction === 'next' ? currentStepIndex + 1 : currentStepIndex - 1;

    const nextStep = STEPS[nextStepIndex];
    const nextStepKey = nextStep && nextStep.key;

    this.selectStep(nextStepKey);
    window.scrollTo(0, 0);
  };

  moveForward = () => this.handleButtonClick('next');

  moveBackward = () => this.handleButtonClick('back');

  renderHeaderSecondaryComponent = () => {
    return (
      <HeaderButton onClick={this.onClickShareWithDeveloper}>
        <Icon icon="share" title="share" /> Share with a Developer
      </HeaderButton>
    );
  };

  navigateToInstallDash = () => {
    const { actions } = this.props;

    actions.navigate('/settings/installation');
  };

  onClickShareWithDeveloper = () => {
    const { actions } = this.props;
    actions.navigate('/settings/team');
  };

  render() {
    const { accountId, selectedInstallFramework } = this.props;
    const { selectedTab } = this.state;

    const stepIndex = STEPS.findIndex(step => step.key === selectedTab);
    const stepMax = STEPS.length;

    return (
      <GreyBackgroundPanel
        backDestination="/settings/installation"
        backText="Settings"
        header="Install Appcues"
        renderHeaderSecondaryComponent={this.renderHeaderSecondaryComponent}
      >
        <DocumentTitle title="Installation | Settings | Appcues" />
        <GuideWrapper>
          <StepperWrapper>
            <Stepper
              steps={STEPS}
              selected={selectedTab}
              onSelect={this.selectStep}
            />
          </StepperWrapper>
          <ContentWrapper>
            <InstallContent
              accountId={accountId}
              moveBackward={this.moveBackward}
              moveForward={this.moveForward}
              navigateToInstallDash={this.navigateToInstallDash}
              selected={selectedTab}
              selectedInstallFramework={selectedInstallFramework}
              stepIndex={stepIndex}
              stepMax={stepMax}
            />
          </ContentWrapper>
        </GuideWrapper>
      </GreyBackgroundPanel>
    );
  }
}

const GuideWrapper = styled.div`
  position: relative;
`;

const StepperWrapper = styled.div`
  position: relative;
  top: -16px;
  z-index: 1;
  display: flex;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

const HeaderButton = styled(CButton)`
  height: 100%;
  ${Icon} {
    margin-right: 10px;
  }
`;

const mapStateToProps = state => {
  const accountId = selectAccountId(state);
  const meta = selectAccountMeta(state);
  const selectedInstallFramework = meta && meta.selectedInstallFramework;

  return {
    accountId,
    selectedInstallFramework,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        hideModal,
        showModal,
        navigate,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InstallationGuide);
