import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const EmptyBannersImage = ({ className }) => (
  <svg
    className={className}
    width="160"
    height="160"
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M160 80C160 124.183 124.183 160 80 160C35.8172 160 0 124.183 0 80C0 35.8172 35.8172 0 80 0C124.183 0 160 35.8172 160 80Z"
      fill="#F4FBFF"
    />
    <mask
      id="mask0_3293_10649"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="160"
      height="160"
    >
      <path
        d="M160 80C160 124.183 124.183 160 80 160C35.8172 160 0 124.183 0 80C0 35.8172 35.8172 0 80 0C124.183 0 160 35.8172 160 80Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_3293_10649)">
      <rect y="114" width="160" height="46" fill="#E9F7FF" />
      <line
        x1="10.5"
        y1="114.5"
        x2="149.5"
        y2="114.5"
        stroke="#5C5CFF"
        strokeLinecap="round"
      />
      <line
        x1="154.5"
        y1="114.5"
        x2="159.5"
        y2="114.5"
        stroke="#5C5CFF"
        strokeLinecap="round"
      />
      <line
        x1="0.5"
        y1="114.5"
        x2="5.5"
        y2="114.5"
        stroke="#5C5CFF"
        strokeLinecap="round"
      />
    </g>
    <path
      d="M80 143C99.8823 143 116 141.657 116 140C116 138.343 99.8823 137 80 137C60.1178 137 44 138.343 44 140C44 141.657 60.1178 143 80 143Z"
      fill="#2CB4FF"
      fillOpacity="0.2"
    />
    <rect
      x="28"
      y="48"
      width="104"
      height="74"
      rx="6"
      fill="#F4FBFF"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28 110H132V116C132 119.314 129.314 122 126 122H34C30.6863 122 28 119.314 28 116V110Z"
      fill="white"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="28" y="110.5" width="104" height="2" fill="#D7D7FF" />
    <path d="M28 54C28 50.6863 30.6863 48 34 48H91L28 110V54Z" fill="white" />
    <path
      d="M89 134H71L72.5349 122H87.6047L89 134Z"
      fill="white"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M100 140H60C60 136.651 62.7143 134 66.1429 134H93.8571C97.2857 134 100 136.791 100 140Z"
      fill="white"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73 122.5H87.1998L87.3998 124.5H72.7498L73 122.5Z"
      fill="#D7D7FF"
    />
    <path
      d="M79.8347 117.472C80.5163 117.472 81.0689 116.919 81.0689 116.236C81.0689 115.553 80.5163 115 79.8347 115C79.1531 115 78.6006 115.553 78.6006 116.236C78.6006 116.919 79.1531 117.472 79.8347 117.472Z"
      fill="#2CB4FF"
      fillOpacity="0.2"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="28"
      y="48"
      width="104"
      height="74"
      rx="6"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 55H148V55.1093L135 65.5L148 75.8906V76H10V75.7983L23.9473 65.5004L10 55.2025V55Z"
      fill="white"
    />
    <path
      d="M148 55H148.5V54.5H148V55ZM10 55V54.5H9.5V55H10ZM148 55.1093L148.312 55.4999L148.5 55.3498V55.1093H148ZM135 65.5L134.688 65.1094L134.199 65.5L134.688 65.8906L135 65.5ZM148 75.8906H148.5V75.6502L148.312 75.5001L148 75.8906ZM148 76V76.5H148.5V76H148ZM10 76H9.5V76.5H10V76ZM10 75.7983L9.70301 75.396L9.5 75.5459V75.7983H10ZM23.9473 65.5004L24.2443 65.9026L24.789 65.5004L24.2443 65.0982L23.9473 65.5004ZM10 55.2025H9.5V55.4549L9.70301 55.6048L10 55.2025ZM148 54.5H10V55.5H148V54.5ZM148.5 55.1093V55H147.5V55.1093H148.5ZM135.312 65.8906L148.312 55.4999L147.688 54.7188L134.688 65.1094L135.312 65.8906ZM148.312 75.5001L135.312 65.1094L134.688 65.8906L147.688 76.2812L148.312 75.5001ZM148.5 76V75.8906H147.5V76H148.5ZM10 76.5H148V75.5H10V76.5ZM9.5 75.7983V76H10.5V75.7983H9.5ZM23.6503 65.0982L9.70301 75.396L10.297 76.2005L24.2443 65.9026L23.6503 65.0982ZM9.70301 55.6048L23.6503 65.9026L24.2443 65.0982L10.297 54.8003L9.70301 55.6048ZM9.5 55V55.2025H10.5V55H9.5Z"
      fill="#5C5CFF"
    />
    <path
      d="M106.853 95.8992C106.478 97.2804 105.727 97.4059 105.102 96.0247L96.2229 74.6786C95.5976 73.2974 96.348 72.6695 97.7236 73.1718L118.983 82.087C120.359 82.7148 120.359 83.4682 118.858 83.8449L112.105 85.7284C110.73 86.1051 109.229 87.6118 108.854 88.9931L106.853 95.8992Z"
      fill="url(#paint0_linear_3293_10649)"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
    />
    <rect x="54" y="61" width="68" height="4" rx="2" fill="#D7D7FF" />
    <rect x="54" y="67" width="68" height="4" rx="2" fill="#D7D7FF" />
    <circle cx="42.5" cy="65.5" r="5.5" fill="#5C5CFF" />
    <path
      d="M42.3801 65.7202C42.3358 65.6611 42.3358 65.5724 42.3801 65.5207L44.2645 63.119C44.368 62.9934 44.5749 63.0673 44.5749 63.2298V67.7672C44.5749 67.8633 44.501 67.9372 44.405 67.9372H43.8951C43.8359 67.9372 43.7768 67.915 43.7473 67.8633L42.3801 65.7202ZM40.8431 67.9446C40.7027 67.9446 40.614 67.782 40.7027 67.6637L41.6042 66.5035C41.7077 66.3779 41.9146 66.4518 41.9146 66.6144V67.7746C41.9146 67.8707 41.8407 67.9446 41.7446 67.9446H40.8431Z"
      fill="white"
    />
    <path
      d="M15.8995 99.1796C15.9223 99.1108 15.963 99.0493 16.0173 99.0014C16.0717 98.9536 16.1378 98.921 16.2089 98.9071L21.6906 97.4087C21.779 97.3866 21.8574 97.3356 21.9134 97.2638C21.9695 97.192 21.9999 97.1035 22 97.0124C22.0006 96.9197 21.9706 96.8294 21.9146 96.7555C21.8587 96.6816 21.78 96.6283 21.6906 96.6037L16.2089 95.0929C16.1387 95.0716 16.075 95.0333 16.0232 94.9815C15.9714 94.9297 15.9331 94.8658 15.9119 94.7957L14.4022 89.3096C14.3801 89.2212 14.3292 89.1427 14.2574 89.0866C14.1857 89.0305 14.0973 89.0001 14.0062 89C13.9136 88.9994 13.8234 89.0295 13.7496 89.0854C13.6757 89.1414 13.6224 89.2202 13.5979 89.3096L12.1006 94.7957C12.0815 94.8649 12.0455 94.9284 11.9958 94.9802C11.9461 95.0321 11.8843 95.0708 11.816 95.0929L6.30947 96.5913C6.22006 96.6114 6.14035 96.6618 6.08394 96.7341C6.02752 96.8063 5.9979 96.8959 6.00012 96.9876C6.00016 97.0787 6.03062 97.1672 6.08667 97.239C6.14272 97.3108 6.22114 97.3618 6.30947 97.3839L11.816 98.9071C11.8861 98.9284 11.9499 98.9667 12.0017 99.0185C12.0535 99.0703 12.0917 99.1342 12.113 99.2043L13.5979 104.69C13.6242 104.779 13.678 104.856 13.7514 104.912C13.8249 104.968 13.9141 104.999 14.0062 105C14.0973 105 14.1857 104.969 14.2574 104.913C14.3292 104.857 14.3801 104.779 14.4022 104.69L15.8995 99.1796Z"
      fill="white"
      stroke="#5C5CFF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.4246 36.6347C82.4417 36.5831 82.4722 36.537 82.513 36.5011C82.5538 36.4652 82.6033 36.4408 82.6566 36.4303L86.768 35.3065C86.8342 35.2899 86.893 35.2517 86.9351 35.1978C86.9771 35.144 87 35.0776 87 35.0093C87.0004 34.9398 86.9779 34.872 86.936 34.8166C86.894 34.7612 86.835 34.7212 86.768 34.7028L82.6566 33.5697C82.6041 33.5537 82.5562 33.525 82.5174 33.4861C82.4785 33.4472 82.4499 33.3994 82.4339 33.3468L81.3017 29.2322C81.2851 29.1659 81.2469 29.107 81.1931 29.065C81.1393 29.0229 81.073 29 81.0047 29C80.9352 28.9996 80.8675 29.0221 80.8122 29.0641C80.7568 29.1061 80.7168 29.1652 80.6984 29.2322L79.5755 33.3468C79.5611 33.3987 79.5341 33.4463 79.4969 33.4852C79.4596 33.5241 79.4133 33.5531 79.362 33.5697L75.2321 34.6935C75.165 34.7085 75.1053 34.7464 75.063 34.8006C75.0206 34.8548 74.9984 34.922 75.0001 34.9907C75.0001 35.0591 75.023 35.1254 75.065 35.1793C75.107 35.2331 75.1659 35.2714 75.2321 35.2879L79.362 36.4303C79.4146 36.4463 79.4624 36.475 79.5013 36.5139C79.5401 36.5528 79.5688 36.6006 79.5847 36.6533L80.6984 40.7678C80.7182 40.8341 80.7585 40.8923 80.8136 40.9341C80.8686 40.9758 80.9356 40.9989 81.0047 41C81.073 41 81.1393 40.9771 81.1931 40.935C81.2469 40.893 81.2851 40.8341 81.3017 40.7678L82.4246 36.6347Z"
      fill="url(#paint1_linear_3293_10649)"
      stroke="#5C5CFF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M145 95C145 93.5455 143.364 92 142 92C143.455 92 145 90.3636 145 89C145 90.4545 146.545 92 148 92C146.545 92 145 93.5455 145 95Z"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3293_10649"
        x1="96"
        y1="85"
        x2="120"
        y2="85"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5290" />
        <stop offset="0.0001" stopColor="#FF5290" />
        <stop offset="1" stopColor="#8960FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3293_10649"
        x1="75"
        y1="35"
        x2="87"
        y2="35"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5C5CFF" />
        <stop offset="1" stopColor="#8960FF" />
      </linearGradient>
    </defs>
  </svg>
);

EmptyBannersImage.propTypes = {
  className: PropTypes.string,
};

export default styled(EmptyBannersImage)``;
