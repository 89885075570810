import { takeEvery, call, put, select } from 'redux-saga/effects';
import {
  addClause,
  addGroupClause,
  deleteClause,
  deleteMatchingClauses,
} from '@appcues/libcues';
import {
  setDisplayAnyPage,
  setDisplaySpecificPage,
  setAudienceAllUsers,
  setAudienceSegment,
  setAudienceCustomSegment,
} from 'helpers/rules';
import {
  updateClause,
  replaceClause,
  dissolveGroupClause,
} from 'transforms/clauses';
import { trackEvent } from 'actions/events';
import { selectAccountStepConditions } from 'reducers/account/conditions';
import { selectAccountSegments } from 'reducers/account/segments';
import {
  ADD_CLAUSE_AND_FLUSH_RULE,
  ADD_GROUP_CLAUSE_AND_FLUSH_RULE,
  DELETE_CLAUSE_AND_FLUSH_RULE,
  DELETE_MATCHING_CLAUSES_AND_FLUSH_RULE,
  DISSOLVE_GROUP_CLAUSE_AND_FLUSH_RULE,
  REPLACE_CLAUSE_AND_FLUSH_RULE,
  SET_AUDIENCE_ALL_USERS_AND_FLUSH_RULE,
  SET_AUDIENCE_CUSTOM_SEGMENT_AND_FLUSH_RULE,
  SET_AUDIENCE_SEGMENT_AND_FLUSH_RULE,
  SET_DISPLAY_ANY_PAGE_AND_FLUSH_RULE,
  SET_DISPLAY_SPECIFIC_PAGE_AND_FLUSH_RULE,
  UPDATE_CLAUSE_AND_FLUSH_RULE,
} from 'constants/account/conditions';
import { updateConditions } from 'actions/account/conditions';

/**
 * Helpers
 */

export function* transformStateAndFlush(id, transform, ...args) {
  const stepClauses = yield select(selectAccountStepConditions, id);

  const nextState = yield call(transform, stepClauses, ...args);

  yield put(updateConditions(id, nextState));
}

/**
 * Wrappers
 */

/**
 * Wrapper for addClause triggered by a User Action
 * @param action
 */

export function* addClauseAndFlushRule(action) {
  const { id, parentId, clause } = action.payload;
  yield call(transformStateAndFlush, id, addClause, parentId, clause);
}

/**
 * Wrapper for addGroupClause triggered by a User Action
 * (the saga is also triggered internally and shouldn't flush then)
 *
 * @param action
 */

export function* addGroupClauseAndFlushRule(action) {
  const { id, parentId, value, childClauses } = action.payload;
  yield call(
    transformStateAndFlush,
    id,
    addGroupClause,
    parentId,
    value,
    childClauses
  );
}

/**
 * Uses transforms to make updates to a specific clause by id
 * and flushes updated state
 *
 * @param action
 */

export function* updateClauseAndFlushRule(action) {
  const { id, clauseId, changes } = action.payload;
  yield call(transformStateAndFlush, id, updateClause, clauseId, changes);
}

/**
 * Wrapper for REPLACE_CLAUSE_IN_STORE just like updateClauseAndFlushRule above
 *
 * @param action
 */

export function* replaceClauseAndFlushRule(action) {
  const { id, clauseId, clause } = action.payload;
  yield call(transformStateAndFlush, id, replaceClause, clauseId, clause);
}

/**
 * Wrapper for DISSOLVE_GROUP_CLAUSE which flushes rule
 *
 * @param action
 */

export function* dissolveGroupClauseAndFlushRule(action) {
  const { id, clauseId } = action.payload;
  yield call(transformStateAndFlush, id, dissolveGroupClause, clauseId);
}

/**
 * Wrapper for deleting a clause
 *
 * @param action
 */

export function* deleteClauseAndFlushRule(action) {
  const { id, clauseId } = action.payload;
  yield call(transformStateAndFlush, id, deleteClause, clauseId);
}

/**
 * Wrapper for deleteMatchingClauses triggered by a User Action
 *
 * @param action
 */

export function* deleteMatchingClausesAndFlushRule(action) {
  const { id, filter } = action.payload;
  yield call(transformStateAndFlush, id, deleteMatchingClauses, filter);
}

/**
 * Helpers
 */

/**
 * Deletes all page targeting rules, then adds a new clause
 * to target { url: { operator: 'regex', value: '.*' }}
 *
 * @param action
 */

export function* setDisplayAnyPageAndFlushRule(action) {
  const { id } = action.payload;
  yield call(transformStateAndFlush, id, setDisplayAnyPage);
}

export function* setDisplaySpecificPageAndFlushRule(action) {
  const { id, replacementClauses } = action.payload;
  yield call(
    transformStateAndFlush,
    id,
    setDisplaySpecificPage,
    replacementClauses
  );
}

export function* setAudienceAllUsersAndFlushRule(action) {
  const { id } = action.payload;
  yield call(transformStateAndFlush, id, setAudienceAllUsers);
}

export function* setAudienceSegmentAndFlushRule(action) {
  const { id } = action.payload;
  let { segmentId } = action.payload;

  if (!segmentId) {
    const accountSegments = yield select(selectAccountSegments);
    const newestSegment = Object.values(accountSegments).find(segment => {
      return !Object.values(accountSegments).some(
        ({ createdAt }) => createdAt > segment.createdAt
      );
    });

    segmentId = newestSegment ? newestSegment.id : '';
  }

  yield call(transformStateAndFlush, id, setAudienceSegment, segmentId);

  if (segmentId !== '') {
    yield put(
      trackEvent('Used a Segment in a Rule', {
        content_id: id,
        segment_id: segmentId,
      })
    );
  }
}

export function* setAudienceCustomSegmentAndFlushRule(action) {
  const { id, replacementClauses } = action.payload;
  yield call(
    transformStateAndFlush,
    id,
    setAudienceCustomSegment,
    replacementClauses
  );
}

export function* conditions() {
  yield takeEvery(ADD_CLAUSE_AND_FLUSH_RULE, addClauseAndFlushRule);
  yield takeEvery(ADD_GROUP_CLAUSE_AND_FLUSH_RULE, addGroupClauseAndFlushRule);
  yield takeEvery(UPDATE_CLAUSE_AND_FLUSH_RULE, updateClauseAndFlushRule);
  yield takeEvery(REPLACE_CLAUSE_AND_FLUSH_RULE, replaceClauseAndFlushRule);
  yield takeEvery(
    DISSOLVE_GROUP_CLAUSE_AND_FLUSH_RULE,
    dissolveGroupClauseAndFlushRule
  );
  yield takeEvery(DELETE_CLAUSE_AND_FLUSH_RULE, deleteClauseAndFlushRule);
  yield takeEvery(
    DELETE_MATCHING_CLAUSES_AND_FLUSH_RULE,
    deleteMatchingClausesAndFlushRule
  );
  yield takeEvery(
    SET_AUDIENCE_ALL_USERS_AND_FLUSH_RULE,
    setAudienceAllUsersAndFlushRule
  );
  yield takeEvery(
    SET_AUDIENCE_SEGMENT_AND_FLUSH_RULE,
    setAudienceSegmentAndFlushRule
  );
  yield takeEvery(
    SET_AUDIENCE_CUSTOM_SEGMENT_AND_FLUSH_RULE,
    setAudienceCustomSegmentAndFlushRule
  );
  yield takeEvery(
    SET_DISPLAY_ANY_PAGE_AND_FLUSH_RULE,
    setDisplayAnyPageAndFlushRule
  );
  yield takeEvery(
    SET_DISPLAY_SPECIFIC_PAGE_AND_FLUSH_RULE,
    setDisplaySpecificPageAndFlushRule
  );
}

export default conditions;
