import { selectRequestStatus } from 'next/entities/requests';
import { TYPE } from 'next/entities/users';
import {
  USER_REPLACED,
  USER_META_UPDATED,
  USER_USAGE_PROPERTY_UPDATED,
} from 'actions/user';

const initialState = {
  synced: false,
  user: {},
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case USER_REPLACED:
      return {
        synced: true,
        user: action.payload,
      };

    case USER_META_UPDATED:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.changes,
        },
      };

    case USER_USAGE_PROPERTY_UPDATED:
      return {
        ...state,
        user: {
          ...state.user,
          usageProperties: {
            ...state.user.usageProperties,
            [action.payload.propertyName]: action.payload.propertyValue,
          },
        },
      };
    default:
      return state;
  }
}

export const selectUser = state => state.user.user;
export const selectUserId = state => selectUser(state)?.id;
export const selectUserEmail = state => selectUser(state)?.email;
export const selectUserSynced = state => state.user.synced;
export const selectUserUsageProperties = state =>
  selectUser(state)?.usageProperties ?? {};
export const selectUserIsSystemAdmin = state => !!selectUser(state).isAdmin;

export const readUser = state => ({
  ...selectRequestStatus(state, TYPE),
  data: selectUser(state),
});
