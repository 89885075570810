import React, { useMemo, useCallback } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Chart } from '@studio/charts';
import styled from 'styled-components';

import Tooltip from './Tooltip';
import Label from './Label';

const ChartWrapper = styled.div`
  .tooltip-container {
    z-index: 1;
  }
`;

export default function LegacyChart({
  type,
  series,
  dimension = 'day',
  title = false,
  padding = 30,
  margin = 30,
  titleX = 'Time',
  titleY = 'Count',
  height = 275,
  allowDecimals = false,
  tooltipHeaders = ['Name', 'Total'],
  tooltipFormatter,
}) {
  // See: https://api.highcharts.com/highcharts/tooltip.formatter
  // for more info on tooltip.formatter
  const formatter = useMemo(
    () =>
      tooltipFormatter ||
      function renderTooltip() {
        const { x, key, points } = this;
        if (points) {
          const format =
            dimension === 'month' ? 'MMMM YYYY' : 'dddd, MMM D, YYYY';

          return renderToStaticMarkup(
            <Tooltip
              label={moment(x).utc().format(format)}
              headers={tooltipHeaders}
              series={points.map(({ color, series: { name }, y: value }) => ({
                color,
                name,
                value,
              }))}
            />
          );
        }
        return renderToStaticMarkup(<Label>{key}</Label>);
      },
    [tooltipFormatter, tooltipHeaders]
  );

  const positioner = useCallback(function (
    labelWidth,
    labelHeight,
    { plotX, plotY }
  ) {
    const {
      plotLeft: chartPlotLeft,
      plotTop: chartPlotTop,
      plotWidth: chartPlotWidth,
      chartHeight,
      // eslint-disable-next-line react/no-this-in-sfc
    } = this.chart;

    let columnWidth = 0;
    let numColumns = 0;

    if (type === 'column') {
      ({
        0: {
          columnMetrics: { width: columnWidth },
        },
        length: numColumns,
        // eslint-disable-next-line react/no-this-in-sfc
      } = this.chart.series);
    }

    const tooltipLeftX = chartPlotLeft + plotX - labelWidth - columnWidth / 2;

    const tooltipRightX =
      chartPlotLeft + plotX + columnWidth * (numColumns - 1) + columnWidth / 2;

    let tooltipY = chartPlotTop + plotY - labelHeight / 2;

    if (tooltipY < 0) {
      tooltipY -= tooltipY;
    }

    if (tooltipY + labelHeight > chartHeight) {
      tooltipY -= chartPlotTop + chartHeight - (tooltipY + labelHeight);
    }

    return {
      x:
        tooltipRightX + labelWidth > chartPlotLeft + chartPlotWidth
          ? tooltipLeftX
          : tooltipRightX,
      y: tooltipY,
    };
  },
  []);

  const overrides = useMemo(
    () => ({
      chart: {
        type,
        height,
        spacingLeft: -5,
        style: {
          fontFamily: 'Mulish, Helvetica Neue, sans-serif',
          position: 'initial',
        },
      },
      credits: {
        enabled: false,
      },
      title,
      xAxis: {
        dateTimeLabelFormats: {
          day: '%b %e',
          week: '%b %e',
          month: '%b %e',
          year: '%b %e',
        },
        title: {
          text: titleX,
          padding,
          margin,
        },
        type: 'datetime',
        ...(dimension === 'month'
          ? { tickInterval: 30 * 24 * 3600 * 1000 }
          : {}),
      },
      yAxis: {
        allowDecimals,
        height: null,
        top: null,
        min: null,
        minRange: null,
        title: {
          text: titleY,
          padding,
          margin,
        },
      },
      legend: {
        align: 'left',
        verticalAlign: 'top',
        symbolHeight: 12,
        symbolWidth: 12,
        symbolRadius: 6,
        margin: padding,
      },
      plotOptions: {
        column: {
          animation: false,
        },
      },
      tooltip: {
        shared: true,
        padding: 10,
        formatter,
        positioner,
        useHTML: true,
        className: 'tooltip-container',
      },
    }),
    [
      type,
      series,
      dimension,
      title,
      padding,
      margin,
      titleX,
      titleY,
      height,
      allowDecimals,
      tooltipHeaders,
      formatter,
      positioner,
    ]
  );

  return (
    <ChartWrapper>
      <Chart options={overrides} series={series} />
    </ChartWrapper>
  );
}

LegacyChart.propTypes = {
  allowDecimals: PropTypes.bool,
  type: PropTypes.string,
  dimension: PropTypes.oneOf(['day', 'month']),
  series: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.string,
      fillOpacity: PropTypes.number,
      type: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })
      ),
    })
  ),
  title: PropTypes.string,
  padding: PropTypes.number,
  margin: PropTypes.number,
  titleX: PropTypes.string,
  titleY: PropTypes.string,
  height: PropTypes.number,
  tooltipHeaders: PropTypes.arrayOf(PropTypes.string),
  tooltipFormatter: PropTypes.func,
};
