import { createCollectionReducer } from 'reducers/account/collections';
import { TYPE } from './constants';

export default createCollectionReducer(TYPE);

export const selectProfileAttributeLabels = state => state.account[TYPE];
export const selectProfileAttributeLabelByName = (state, attrName) => {
  const attributeLabels = selectProfileAttributeLabels(state);

  if (attributeLabels) {
    return Object.values(selectProfileAttributeLabels(state)).find(
      ({ name }) => name === attrName
    );
  }

  return null;
};
