import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Text, Flex } from '@appcues/component-library';
import { Link, Td } from '@studio/legacy-components';

import ExpandableRow from 'components/Insights/Explorer/Common/ExpandableRow';
import ExpandedRow from 'components/Insights/Explorer/Common/ExpandedRow';

import {
  ExternalLinkIcon,
  SelectedIcon,
  LiveIcon,
  DraftIcon,
  ExpandedNameColumn,
} from 'components/Insights/Common/Styled';

export const ChecklistRow = ({
  id,
  name,
  published,
  metrics,
  totalUsers,
  most_recent_timestamp,
  selectedEvents,
  enableSelect,
  isExpanded,
  onClick,
  onSelect,
}) => {
  // Prevent event bubbling up to the clickable accordion div
  const handleLinkClick = useCallback(e => {
    e.stopPropagation();
    return e;
  }, []);

  const rowChecked = (selectedEvents || []).some(
    ({ id: rowId }) => rowId === id
  );

  return (
    <ExpandableRow
      id={id}
      isExpanded={isExpanded}
      onClick={onClick}
      expandable={metrics.map(
        ({ event, eventType, users, most_recent_timestamp: timestamp }) => {
          const checked = (selectedEvents || []).some(
            ({ id: selectedChecklistId, event: selectedEvent }) =>
              selectedChecklistId === id && selectedEvent === eventType
          );

          return (
            <ExpandedRow
              key={event}
              id={id}
              source="checklist"
              parentName={name}
              eventName={event}
              eventType={eventType}
              events={users}
              users={users}
              most_recent_timestamp={timestamp}
              enableSelect={enableSelect}
              checked={checked}
              onSelect={onSelect}
            />
          );
        }
      )}
    >
      <ExpandedNameColumn>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex maxWidth="80%">
            <Text is="div" truncate>
              {name}
            </Text>
            <div>
              {rowChecked && !isExpanded && <SelectedIcon title="checkmark" />}
            </div>
          </Flex>
          <Link
            to={`/checklists/${id}/analytics`}
            onClick={handleLinkClick}
            onKeyDown={handleLinkClick}
            target="_blank"
            rel="noopener noreferrer"
            title="Checklist Analytics Page"
          >
            <ExternalLinkIcon />
          </Link>
        </Flex>
      </ExpandedNameColumn>
      <Td>
        {published ? (
          <>
            <LiveIcon /> <Text marginLeft="5px">Live</Text>
          </>
        ) : (
          <>
            <DraftIcon /> <Text marginLeft="5px">Draft</Text>
          </>
        )}
      </Td>
      <Td>{totalUsers ? totalUsers.toLocaleString() : '-'}</Td>
      <Td>
        {/* Since totalEvents is not an accurate metric for checklists, we use totalUsers as a replacement metric instead */}
        {totalUsers ? totalUsers.toLocaleString() : '-'}
      </Td>
      <Td>
        {most_recent_timestamp
          ? moment(most_recent_timestamp).format('lll')
          : '-'}
      </Td>
    </ExpandableRow>
  );
};

ChecklistRow.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  onSelect: PropTypes.func,
  enableSelect: PropTypes.bool,
  name: PropTypes.string,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      metric: PropTypes.string,
      events: PropTypes.number,
      users: PropTypes.number,
      most_recent_timestamp: PropTypes.number,
    })
  ),
  totalEvents: PropTypes.number,
  totalUsers: PropTypes.number,
  most_recent_timestamp: PropTypes.number,
  id: PropTypes.string,
  published: PropTypes.bool,
};

export default ChecklistRow;
