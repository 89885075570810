import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import isEmpty from 'lodash.isempty';
import { connect } from 'react-redux';
import {
  Modal,
  Button,
  ButtonGroup,
  Label,
  Input,
  ErrorMsg,
  Heading,
} from '@studio/legacy-components';
import { exportSegment } from 'actions/account/segments';
import { selectUserEmail } from 'reducers/user';

export const ExportForm = styled(Form)`
  margin-top: 8px;

  ${ButtonGroup} {
    margin-top: 32px;
  }

  ${ErrorMsg} {
    position: absolute;
    bottom: -21px;
  }
`;

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email address.')
    .required('Email is required to proceed.'),
});

export const ExportModal = ({ userEmail, visible, onClose, onSubmit }) => {
  const handleSubmit = data => {
    onSubmit(data);
    onClose();
  };
  return (
    <Modal visible={visible} onClose={onClose}>
      <Heading>Export a CSV</Heading>
      <Formik
        initialValues={{ email: userEmail || '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, isSubmitting }) => (
          <ExportForm>
            <Label>
              Email Address - we&apos;ll send your CSV here
              <Field
                as={Input}
                type="text"
                name="email"
                placeholder="email@email.com"
                error={!!errors.email}
              />
              {errors.email && <ErrorMsg>{errors.email}</ErrorMsg>}
            </Label>

            <ButtonGroup right>
              <Button type="submit" disabled={!isEmpty(errors) || isSubmitting}>
                Send CSV
              </Button>
              <Button
                kind="secondary"
                disabled={isSubmitting}
                onClick={onClose}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </ExportForm>
        )}
      </Formik>
    </Modal>
  );
};
ExportModal.propTypes = {
  id: PropTypes.string,
  userEmail: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
const mapStateToProps = state => ({
  userEmail: selectUserEmail(state),
});
const mapDispatchToProps = (dispatch, { id }) => ({
  onSubmit: data => dispatch(exportSegment(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportModal);
