import { createCollectionActions } from 'next/lib/collections';

export const TYPE = 'pins';

export const {
  create,
  flush,
  insert,
  patterns,
  prune,
  read,
  readOne,
  drop,
  reject,
  remove,
  replace,
  reset,
  resolve,
  update,
} = createCollectionActions(TYPE);

export const PIN_PUBLISH_REQUESTED = '@pin/PUBLISH_REQUESTED';
export const requestPublishPin = id => ({
  type: PIN_PUBLISH_REQUESTED,
  payload: { id },
});

export const PIN_PUBLISHED = '@pin/PUBLISHED';
export const pinPublished = id => ({
  type: PIN_PUBLISHED,
  payload: { id },
});

export const PIN_UNPUBLISH_REQUESTED = '@pin/UNPUBLISH_REQUESTED';
export const requestUnpublishPin = id => ({
  type: PIN_UNPUBLISH_REQUESTED,
  payload: { id },
});

export const PIN_REVERT_REQUESTED = '@pin/PIN_REVERT_REQUESTED';
export const requestRevertPin = (id, version) => ({
  type: PIN_REVERT_REQUESTED,
  payload: { id, version },
});

export const PIN_REVERTED = '@pin/REVERTED';
export const pinReverted = id => ({
  type: PIN_REVERTED,
  payload: { id },
});

export const PIN_UNPUBLISHED = '@pin/UNPUBLISHED';
export const pinUnpublished = id => ({
  type: PIN_UNPUBLISHED,
  payload: { id },
});

export const PIN_CLONED = '@pin/CLONED';
export const clone = id => ({
  type: PIN_CLONED,
  payload: { id },
});

export const PIN_ARCHIVED = '@pin/ARCHIVED';
export const archive = id => ({
  type: PIN_ARCHIVED,
  payload: { id },
});

export const PIN_UNARCHIVED = '@pin/UNARCHIVED';
export const unarchive = id => ({
  type: PIN_UNARCHIVED,
  payload: { id },
});
