export const INITIALIZE = '@root/INITIALIZE';

/**
 * Application initialization
 *
 * @param {Auth} auth - Auth information
 * @return {Action} Initialized action creator
 */
export const initialize = auth => ({
  type: INITIALIZE,
  payload: auth,
});
