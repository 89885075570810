import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';
import DropdownButton from 'components/Common/DropdownButton';

const IconOpenMenu = styled(Icon).attrs({
  icon: 'ellipsis-h',
  title: 'Open Menu',
})`
  color: var(--icon);
`;

const ThreeDotsDropdownButton = ({
  handleOnSelect,
  dropdownOptions,
  ...dropdownButtonProps
}) => (
  <DropdownButton
    {...dropdownButtonProps}
    options={dropdownOptions}
    onSelect={handleOnSelect}
    useIconButton
    paddingY={0}
    transparent
  >
    <IconOpenMenu />
  </DropdownButton>
);

ThreeDotsDropdownButton.propTypes = {
  handleOnSelect: PropTypes.func,
  dropdownOptions: PropTypes.array,
};

export default ThreeDotsDropdownButton;
