import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { connect } from 'react-redux';

import {
  Panel,
  PanelHeader,
  PanelTitle,
  H3,
  PanelBody,
  Spinner,
  Text,
} from '@studio/legacy-components';
import { Chart } from '@studio/charts';

import { queryExperienceAnalyticsChart } from 'next/client/queries';
import { readAnalytics, selectAccountAnalytics } from 'next/entities/analytics';
import {
  Shape as VersionShape,
  selectExperienceVersionsByStart,
} from 'next/entities/experience-versions';
import { asRequest } from 'next/entities/requests';
import { Shape as PinShape } from 'next/entities/pins';
import { selectPublishedPin } from 'next/lib/selectors';

import { getCacheKey } from 'next/lib/caching';
import { format } from 'next/lib/date';

import { SpinnerWrapper } from './styled';

const COLORS = [
  '#9D9DFF',
  '#6FDDDB',
  '#FF97BC',
  '#FFBB61',
  '#7cb5ec',
  '#434348',
  '#90ed7d',
  '#f7a35c',
  '#8085e9',
  '#f15c80',
];

const PUBLISHED_SVG =
  "data:image/svg+xml,%3Csvg width='24' height='278' viewBox='0 0 24 278' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='23.9998' rx='11.9999' fill='%23627293'/%3E%3Cpath d='M17.989 6.0077L16.239 17.3581C16.198 17.6243 16.0374 17.8563 15.8023 17.9884C15.6699 18.0627 15.5215 18.1004 15.3736 18.1004C15.2591 18.1004 15.1461 18.0781 15.0375 18.0328L11.6852 16.6355L10.2945 18.7216C10.1878 18.9041 10.0101 18.9998 9.81871 18.9998C9.50425 18.9998 9.24996 18.7455 9.24996 18.4311V15.8011C9.24996 15.6066 9.31482 15.4175 9.43431 15.2641L15.375 7.62481L7.3441 14.8518L4.53836 13.6814C4.23068 13.5529 4.02217 13.2631 4.00167 12.9049C3.98116 12.5467 4.15121 12.2563 4.44081 12.0909L16.6908 5.11547C16.9831 4.94843 17.3438 4.9638 17.6205 5.15392C17.8972 5.34404 18.041 5.67575 17.989 6.0077Z' fill='white'/%3E%3Cpath d='M12 23.9998L12 277' stroke='%23627293' stroke-width='1' stroke-linecap='square'/%3E%3C/svg%3E";

export const ActivityChart = ({ pin, analytics, onLoad, versions = {} }) => {
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const pinLoading = !pin;

  const dates = [...new Set(analytics?.map(({ day }) => day))];

  const pinEventSeries = pin?.steps.map((step, index) => {
    const lastDigit2Str = String(index).slice(-1);
    const colorIndex = Number(lastDigit2Str);
    return {
      name: step.name,
      color: COLORS[colorIndex],
      fillOpacity: 0.4,
      marker: {
        symbol: 'circle',
        fillColor: COLORS[colorIndex],
      },
      type: 'line',
      data: dates.map(date => {
        const value = analytics?.find(
          ({ day, step_id: stepId }) => day === date && stepId === step.id
        );
        return {
          x: Date.parse(date),
          y: value?.users ?? 0,
        };
      }),
    };
  });

  const pinVersionSeries = [
    {
      clip: false,
      colorByPoint: false,
      data:
        Object.keys(versions).map(date => ({
          x: Date.parse(date),
          y: 0,
          title: ' ',
          name: `${versions[date].length} version${
            versions[date].length === 1 ? '' : 's'
          } published on ${format(date, "ddd, MMM D, 'YY")}`,
        })) || [],
      shape: `url(${PUBLISHED_SVG})`,
      showInLegend: false,
      type: 'flags',
      y: -142,
    },
  ];

  return (
    <Panel>
      <PanelHeader>
        <PanelTitle>
          <H3>Pin activity</H3>
          <Text>Tooltip shown events or button click events</Text>
        </PanelTitle>
      </PanelHeader>
      <PanelBody>
        {pinLoading || isEmpty(analytics) ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <Chart
            series={[...pinVersionSeries, ...pinEventSeries]}
            options={{ headers: ['Name', 'Unique users'] }}
            tooltipHeaders={['Name', 'Unique users']}
          />
        )}
      </PanelBody>
    </Panel>
  );
};

ActivityChart.propTypes = {
  query: PropTypes.shape({
    metrics: PropTypes.arrayOf(PropTypes.string),
    dimensions: PropTypes.arrayOf(PropTypes.string),
    start_time: PropTypes.number,
    end_time: PropTypes.number,
    conditions: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.array, PropTypes.string])
      )
    ),
  }),
  pin: PinShape,
  analytics: asRequest(PropTypes.arrayOf(PropTypes.object)),
  onLoad: PropTypes.func,
  versions: PropTypes.objectOf(PropTypes.arrayOf(VersionShape)),
};

const getQueryParams = ({ id, startTime, endTime, segmentId, goalId }) => ({
  experienceId: id,
  startTime,
  endTime,
  segmentId,
  goalId,
});

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const query = queryExperienceAnalyticsChart(getQueryParams(ownProps));
  const cacheKey = getCacheKey(query);

  return {
    pin: selectPublishedPin(state, id),
    analytics: selectAccountAnalytics(state, cacheKey),
    versions: selectExperienceVersionsByStart(state, {
      id,
      startTime: query.start_time,
      endTime: query.end_time,
    }),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const queryParams = getQueryParams(ownProps);
  const query = queryExperienceAnalyticsChart(queryParams);

  return {
    onLoad: () => dispatch(readAnalytics(query)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityChart);
