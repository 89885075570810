import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Page, PageBody, Spinner } from '@studio/legacy-components';
import {
  AudiencePanel,
  ExperienceDetails,
  UrlTargeting,
} from '@studio/settings-panels';
import {
  ConditionsPropsShape,
  SelectOptionsShape,
  Shape,
  getPinTargetingSections,
} from '@studio/conditions';
import {
  Shape as PinShape,
  update as updatePin,
  selectPin,
} from 'next/entities/pins';
import { update as updateRule, selectRule } from 'next/entities/rules';
import { readOne as readSuggestions } from 'next/entities/suggestions';

import PinHeader from 'next/components/PinHeader';
import { selectUser } from 'next/entities/user';
import { selectAccountUser } from 'next/entities/account-users';
import { replace as replacePage } from 'next/entities/page';
import { drop as dropContentStatus } from 'next/entities/content-status';
import { create as createSegment } from 'next/entities/segments';
import { selectSegmentTargetingOptions } from 'next/lib/selectors-options';
import { MOBILE_FLOWS, selectFeature } from 'next/entities/features';
import ExperienceContext from 'next/components/ExperienceContext';
import { selectAccount } from 'next/entities/account';
import { selectInstalled } from 'next/entities/installed';
import { selectConditionsOptions } from 'next/lib/selector-conditions-options';
import { Loading } from './styled';
import PinBanner from './PinBanner';
import SettingsHeaderActions from './SettingsHeaderActions';

export const SettingsPage = ({
  id,
  loaded,
  pin,
  rule,
  onLoad,
  onUnload,
  onPinChange,
  onRuleChange,
  domains,
  installedDomains,
  conditionsOptions,
  segmentsOptions = [],
  onSegmentCreate,
  hasMobileFlows,
  onAttributeChange,
}) => {
  const experienceData = useMemo(
    () => ({
      type: 'pins',
      sections: getPinTargetingSections(rule?.conditions),
      routePath: '/pins',
    }),
    [rule]
  );

  useEffect(() => {
    onLoad();
    return () => onUnload();
  }, [onLoad, onUnload]);

  if (!loaded) {
    return (
      <Loading>
        <Spinner aria-label="Loading" />
      </Loading>
    );
  }

  const handleSectionChange = section => newConditions =>
    void onRuleChange({
      conditions: {
        and: Object.values({
          ...experienceData.sections,
          [section]: newConditions,
        }).filter(branch => branch && (branch.and || branch.or).length > 0),
      },
    });

  const { sections } = experienceData;

  return (
    <ExperienceContext.Provider value={experienceData}>
      <Page>
        <PinHeader
          id={id}
          currentPage="settings"
          renderActions={({ onClickOpenInBuilder, published, updatedAt }) => {
            return (
              <SettingsHeaderActions
                id={id}
                onClickOpenInBuilder={onClickOpenInBuilder}
                published={published}
                updatedAt={updatedAt}
              />
            );
          }}
        />
        <PageBody>
          <PinBanner id={id} />
          <ExperienceDetails
            name={pin.name}
            previewUrl={pin.previewUrl}
            onChange={onPinChange}
            namePlaceholder="Pin Name"
            nameAriaLabel="Pin Name"
          />
          <UrlTargeting
            domains={domains}
            installedDomains={installedDomains}
            urlConditions={sections.url}
            domainConditions={sections.domains}
            onUrlConditionChange={handleSectionChange('url')}
            onDomainConditionChange={handleSectionChange('domains')}
            conditionsOptions={conditionsOptions}
          />
          <AudiencePanel
            conditions={sections.audience}
            conditionsOptions={conditionsOptions}
            experienceName="Pin Experience"
            hasMobileFlows={hasMobileFlows}
            onChange={handleSectionChange('audience')}
            onSegmentCreate={onSegmentCreate}
            segmentsOptions={segmentsOptions}
            onAttributeChange={onAttributeChange}
          />
        </PageBody>
      </Page>
    </ExperienceContext.Provider>
  );
};

SettingsPage.propTypes = {
  id: PropTypes.string,
  loaded: PropTypes.bool,
  pin: PinShape,
  rule: Shape,
  onLoad: PropTypes.func,
  onUnload: PropTypes.func,
  onPinChange: PropTypes.func,
  onRuleChange: PropTypes.func,
  domains: PropTypes.arrayOf(PropTypes.string),
  installedDomains: PropTypes.objectOf(
    PropTypes.shape({
      hostname: PropTypes.string,
    })
  ),
  conditionsOptions: ConditionsPropsShape,
  segmentsOptions: SelectOptionsShape,
  onSegmentCreate: PropTypes.func,
  hasMobileFlows: PropTypes.bool,
  onAttributeChange: PropTypes.func,
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { pinId },
    },
  }
) => {
  const pin = selectPin(state, pinId);
  const rule = selectRule(state, pinId);
  const { id: userId } = selectUser(state);
  const accountUser = selectAccountUser(state, userId);

  const { domains = [] } = selectAccount(state);

  return {
    segmentsOptions: selectSegmentTargetingOptions(state),
    hasMobileFlows: selectFeature(state, MOBILE_FLOWS),
    domains,
    conditionsOptions: selectConditionsOptions(state),
    installedDomains: selectInstalled(state),
    id: pinId,
    // @todo should we track loading state of targeting reducer?
    loaded: Boolean(pin && rule && accountUser),
    pin,
    rule,
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      path,
      params: { pinId: id },
    },
  }
) => ({
  onLoad: () => dispatch(replacePage({ path, id })),
  onUnload: () => dispatch(dropContentStatus(id)),
  onPinChange: delta => dispatch(updatePin(id, delta)),
  onRuleChange: delta => dispatch(updateRule(id, delta)),
  onSegmentCreate: data => dispatch(createSegment(data)),
  onAttributeChange: (type, attributeName, term) =>
    dispatch(readSuggestions({ type, attributeName, term })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
