import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { baseTheme } from '@appcues/theme-utilities';
import { Select } from '@studio/legacy-components';

const ThemeDropDown = ({ label, draftTheme, setDraftTheme }) => {
  const { name, options } = label;

  const handleChange = useCallback(
    ({ value }) => {
      setDraftTheme(prevDraftTheme => ({
        ...prevDraftTheme,
        [name]: value,
      }));
    },
    [name, setDraftTheme]
  );

  const selected = options.find(
    ({ value }) => value === (draftTheme[name] || baseTheme[name])
  );

  return <Select options={options} value={selected} onChange={handleChange} />;
};

ThemeDropDown.propTypes = {
  label: PropTypes.shape({
    category: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    type: PropTypes.string,
  }),
  draftTheme: PropTypes.shape({
    id: PropTypes.string,
    customFontUrl: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    typekitId: PropTypes.string,
    projectId: PropTypes.string,
  }),
  setDraftTheme: PropTypes.func,
};

export default ThemeDropDown;
