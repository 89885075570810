import styled from 'styled-components';
import {
  H3,
  Modal,
  Overflow,
  Skeleton,
  Text,
  easing,
} from '@studio/legacy-components';

export const AccountsModal = styled(Modal)`
  align-items: center;
  background: var(--noreaster);
  display: flex;
  flex-direction: column;
`;

export const AccountsList = styled(Overflow).attrs({
  'aria-live': 'polite',
})`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 48px 16px;
  width: 100%;
`;

export const Account = styled.div.attrs({ role: 'button' })`
  align-items: center;
  background: var(--white);
  border: 1px solid var(--myst);
  border-radius: 6px;
  box-shadow: none;
  display: flex;
  height: 64px;
  justify-content: space-between;
  max-width: 600px;
  padding: 12px 16px;
  transition: ${easing('border', 'box-shadow')};
  width: 100%;

  :hover {
    border-color: var(--primary);
  }

  & + & {
    margin-top: 16px;
  }

  ${({ $selected }) =>
    $selected &&
    `
      border-color: var(--primary);
      border-width: 2px;
      box-shadow: var(--level-2);
      transition: none;
  `}

  ${({ disabled }) =>
    disabled &&
    `
      ${H3}, ${Text} {
        color: var(--disabled);
      }
  `}
`;

export const AccountLoading = styled(Skeleton)`
  border: 1px solid var(--myst);
  border-radius: 6px;
  height: 64px;
  max-width: 600px;
  width: 100%;

  & + & {
    margin-top: 16px;
  }
`;
