import React, { Component } from 'react';
import { CButton, CDialog } from '@appcues/component-library';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toast from 'next/lib/toast';
import { trackEvent } from 'actions/events';
import { hideModal } from 'actions/currentModal';
import { selectAccountId, selectAccountUuid } from 'reducers/account/meta';
import {
  enableOrDisableInstance,
  getConfigurationCode,
  getOrCreateTraySolutionInstance,
} from 'helpers/webhook-api';
import {
  activateAndFlushIntegration,
  removeAndFlushIntegration,
} from 'actions/account/integrations';
import { selectAccountIntegration } from 'reducers/account/integrations';
import {
  CANCELED_SALESFORCE_CONFIGURATION_WIZARD,
  OPENED_SALESFORCE_CONFIGURATION_WIZARD,
} from 'constants/events';
import salesforceImg from 'assets/images/salesforce-integration.png';
import IntegrationImage from './IntegrationImage';

class SalesForceIntegrationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingInstance: true,
      integrationError: null,
      integrationResponse: null,
      configurationStatus: null,
      modifyExisting: false,
      configWindow: null,
    };

    this.launchConfigWizard = this.launchConfigWizard.bind(this);
    this.closeConfigWindow = this.closeConfigWindow.bind(this);
    this.toggleIntegrationState = this.toggleIntegrationState.bind(this);
    this.modifyIntegration = this.modifyIntegration.bind(this);
  }

  async componentDidMount() {
    const { actions, accountId, apiKey, account } = this.props;
    window.addEventListener('message', this.handlePopupEvents);
    try {
      /* global SALESFORCE_SOLUTION_ID */
      const integrationResponse = await getOrCreateTraySolutionInstance(
        SALESFORCE_SOLUTION_ID,
        'Salesforce',
        accountId,
        apiKey
      );
      if (
        !!integrationResponse.enabled !==
        !!account.integrations.integrations.salesforce
      ) {
        // While the Salesforce integration gets synced to Firebase, the source of truth remains the webhook API
        if (integrationResponse.enabled) {
          actions.activateAndFlushIntegration('salesforce');
        } else {
          actions.removeAndFlushIntegration('salesforce');
        }
      }
      this.setState(state => ({
        ...state,
        loadingInstance: false,
        integrationResponse,
      }));
    } catch (error) {
      this.setState(state => ({
        ...state,
        integrationError: error,
        loadingInstance: false,
      }));
    }
  }

  async componentDidUpdate() {
    const { accountId, actions, apiKey } = this.props;
    const { configurationStatus, modifyExisting, integrationResponse } =
      this.state;
    if (configurationStatus === 'FINISHED') {
      if (modifyExisting) {
        toast.success('Successfully updated Salesforce integration');
        actions.hideModal();
      } else {
        try {
          await enableOrDisableInstance(
            integrationResponse.instanceId,
            accountId,
            apiKey,
            { enabled: true }
          );

          this.setState(state => ({
            ...state,
            configurationStatus: null,
            integrationResponse: {
              ...state.integrationResponse,
              enabled: true,
            },
          }));
          actions.activateAndFlushIntegration('salesforce');
        } catch {
          toast.error(
            'We had a problem activating this integration. Please try again, and if the problem continues, contact us.'
          );
        } finally {
          actions.hideModal();
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handlePopupEvents);
  }

  handlePopupEvents = e => {
    const { actions } = this.props;
    if (e.data.type === 'tray.configPopup.error') {
      this.closeConfigWindow();
      this.setState(state => ({
        ...state,
        configurationStatus: 'ERROR',
        integrationError: e.data,
      }));
    }
    if (e.data.type === 'tray.configPopup.cancel') {
      this.closeConfigWindow();
      actions.trackEvent(CANCELED_SALESFORCE_CONFIGURATION_WIZARD, {
        integration: 'salesforce',
      });
      this.setState(state => ({ ...state, configurationStatus: 'CANCELLED' }));
      actions.hideModal();
    }
    if (e.data.type === 'tray.configPopup.finish') {
      this.closeConfigWindow();
      this.setState(state => ({ ...state, configurationStatus: 'FINISHED' }));
    }
  };

  closeConfigWindow() {
    const { configWindow } = this.state;
    if (configWindow) {
      configWindow.close();
      this.setState(state => ({ ...state, configWindow: null }));
    }
  }

  generatePopupUrl() {
    const { integrationResponse } = this.state;
    if (integrationResponse) {
      const { instanceId: id, code } = integrationResponse;
      // Enables us to hide steps of the configuration wizard, like setting an account ID or API key.
      /* global SALESFORCE_CONFIGURE_SHOW_STEPS */
      const extraParams = SALESFORCE_CONFIGURE_SHOW_STEPS
        ? `&show=${SALESFORCE_CONFIGURE_SHOW_STEPS}`
        : '';
      /* global TRAY_POPUP_CONFIG_DOMAIN */
      return `${TRAY_POPUP_CONFIG_DOMAIN}/external/solutions/appcues/configure/${id}?code=${code}${extraParams}`;
    }
    return null;
  }

  async launchConfigWizard(modifyExisting) {
    const { accountId, actions, apiKey } = this.props;
    const { integrationResponse } = this.state;
    if (!integrationResponse.code) {
      const code = await getConfigurationCode(accountId, apiKey);
      this.setState(state => ({
        ...state,
        integrationResponse: { ...state.integrationResponse, code },
      }));
    }
    const configWindow = window.open(
      this.generatePopupUrl(),
      '_blank',
      'width=500,height=500,scrollbars=no'
    );
    configWindow.document.title =
      'Appcues: Salesforce Integration Configuration';
    actions.trackEvent(OPENED_SALESFORCE_CONFIGURATION_WIZARD, {
      integration: 'salesforce',
    });
    this.setState(state => ({
      ...state,
      modifyExisting,
      integrationResponse: {
        ...state.integrationResponse,
        code: null,
      },
      configWindow,
      configurationStatus: 'IN_PROGRESS',
    }));
  }

  modifyIntegration() {
    this.launchConfigWizard(true);
  }

  async toggleIntegrationState() {
    const { accountId, actions, apiKey } = this.props;
    const { integrationResponse } = this.state;

    if (integrationResponse) {
      const { instanceId, enabled } = integrationResponse;
      const desiredState = !enabled;

      if (!desiredState) {
        try {
          await enableOrDisableInstance(instanceId, accountId, apiKey, {
            enabled: false,
          });
          this.setState(state => ({
            ...state,
            integrationResponse: {
              ...state.integrationResponse,
              enabled: false,
            },
          }));
          actions.removeAndFlushIntegration('salesforce');
        } catch {
          toast.error(
            'We had a problem removing this integration. Please try again, and if the problem continues, contact us.'
          );
        } finally {
          actions.hideModal();
        }
      } else {
        this.launchConfigWizard(false);
      }
    }
  }

  render() {
    const { actions, salesforceEnabledInFirebase } = this.props;
    const { integrationResponse, configurationStatus } = this.state;

    const configUrl = this.generatePopupUrl();
    const enabled =
      (integrationResponse && integrationResponse.enabled) ||
      salesforceEnabledInFirebase;
    const configInProgress = configurationStatus === 'IN_PROGRESS';
    return (
      <CDialog
        title="Salesforce integration"
        components={{
          Header: () => (
            <CDialog.Header>
              <IntegrationImage
                src={salesforceImg}
                alt="Appcues <> Salesforce Integration"
              />
            </CDialog.Header>
          ),
          Footer: false,
        }}
      >
        {configInProgress
          ? "To configure the Salesforce integration, please follow the steps in the popup. If you're blocking popups, try disabling popups for this site and try again."
          : 'The Salesforce integration is now available.'}
        <CDialog.Footer>
          <CButton onClick={actions.hideModal}>Cancel</CButton>
          <CButton
            onClick={this.modifyIntegration}
            isDisabled={
              !enabled || !integrationResponse || !configUrl || configInProgress
            }
          >
            Modify
          </CButton>
          <CButton
            isDisabled={!integrationResponse || !configUrl || configInProgress}
            type={enabled ? 'negative' : 'positive'}
            onClick={this.toggleIntegrationState}
          >
            {enabled ? 'Remove' : 'Activate'}
          </CButton>
        </CDialog.Footer>
      </CDialog>
    );
  }
}

SalesForceIntegrationModal.propTypes = {
  actions: PropTypes.shape({
    hideModal: PropTypes.func.isRequired,
  }).isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: {
    ...ownProps.actions,
    ...bindActionCreators(
      {
        hideModal,
        activateAndFlushIntegration,
        removeAndFlushIntegration,
        trackEvent,
      },
      dispatch
    ),
  },
});

const mapStateToProps = state => {
  return {
    accountId: selectAccountId(state),
    apiKey: selectAccountUuid(state),
    salesforceEnabledInFirebase: !!selectAccountIntegration(
      state,
      'salesforce'
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesForceIntegrationModal);
