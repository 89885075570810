import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  ContentStatus,
  H1,
  Page,
  PageActions,
  PageBody,
  PageHeader,
  PageTitle,
  Panel,
  PrintButton,
  Tooltip,
  useDateRange,
} from '@studio/legacy-components';
import BoundedDateRangeDropdown from 'next/components/BoundedDateRangeDropdown';
import useTitle from 'next/hooks/use-title';
import { HelpBar } from 'next/components/HelpBar';
import SelectSegmentDropDown from 'components/Common/SelectSegmentDropDown';
import Loader from 'components/Common/Loader';
import { CLICKED_BUTTON } from 'constants/events';
import { trackEvent } from 'actions/events';
import { createSatisfactionWithRule } from 'actions/satisfaction';
import { selectAccountSatisfactionSynced } from 'reducers/account/satisfaction';
import {
  selectAccountFirstSatisfactionId,
  selectAccountSatisfactionSurvey,
} from 'selectors/satisfaction';
import { selectDateRangeType } from 'selectors/timeframe-options';
import { FULL_NPS_RANGE_OPTIONS, MAX_DURATION } from 'components/nps/constants';
import SegmentWarningNotice from './SegmentWarningNotice';
import NpsBreakdown from './NpsBreakdown';
import ScoreOverTime from './ScoreOverTime';
import NpsResponses from './NpsResponses';
import EmptyState from './EmptyState';
import ExportManager from './ExportManager';
import { ControlsWrapper, ControlColumn, ChartWrapper } from './styled';

export const NpsDashboard = ({
  disabled,
  loading,
  published,
  npsId,
  onCreate,
}) => {
  useTitle('NPS Survey | Appcues');
  const [segmentId, setSegmentId] = useState(null);

  const [{ range, start, end }, setDateRange] = useDateRange(
    FULL_NPS_RANGE_OPTIONS,
    { range: 30 }
  );

  if (loading || !start || !end) {
    return <Loader />;
  }

  const isEmpty = !npsId;

  const startTime = start.getTime();
  const endTime = end.getTime();

  return (
    <Page>
      <PageHeader>
        <PageTitle>
          <H1>NPS Survey</H1>
          {!isEmpty && <ContentStatus published={published} />}
        </PageTitle>
        {!isEmpty && (
          <PageActions>
            <Button forwardedAs={Link} to={`/nps/${npsId}/edit`}>
              Edit survey
            </Button>
            <ExportManager range={range} start={start} end={end} />
            <PrintButton />
          </PageActions>
        )}
      </PageHeader>
      <PageBody>
        <HelpBar section="nps" />
        {isEmpty && <EmptyState onCreate={onCreate} />}

        {!isEmpty && (
          <>
            <ControlsWrapper>
              <ControlColumn>
                <Tooltip
                  disabled={!disabled}
                  label="Contact sales@appcues.com to view more than 30 days worth of data."
                  placement="bottom"
                  size="m"
                  wrapped
                >
                  <BoundedDateRangeDropdown
                    disabled={disabled}
                    duration={MAX_DURATION}
                    onApply={setDateRange}
                    options={FULL_NPS_RANGE_OPTIONS}
                    value={{ range, start, end }}
                  />
                </Tooltip>
              </ControlColumn>
              <ControlColumn>
                <SelectSegmentDropDown
                  value={segmentId}
                  onChange={setSegmentId}
                  isClearable
                />
              </ControlColumn>
            </ControlsWrapper>

            <SegmentWarningNotice
              segmentId={segmentId}
              selected
              start={startTime}
            />

            <Panel>
              <NpsBreakdown
                npsId={npsId}
                startTime={startTime}
                endTime={endTime}
                segmentId={segmentId}
              />
            </Panel>
            <ChartWrapper>
              <ScoreOverTime
                npsId={npsId}
                startTime={startTime}
                endTime={endTime}
                segmentId={segmentId}
              />
            </ChartWrapper>
            <NpsResponses
              npsId={npsId}
              startTime={startTime}
              endTime={endTime}
              segmentId={segmentId}
            />
          </>
        )}
      </PageBody>
    </Page>
  );
};

NpsDashboard.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  npsId: PropTypes.string,
  onCreate: PropTypes.func,
  published: PropTypes.bool,
};

const mapStateToProps = state => {
  const type = selectDateRangeType(state);
  const npsId = selectAccountFirstSatisfactionId(state);
  const loaded = selectAccountSatisfactionSynced(state);
  const npsSurvey = selectAccountSatisfactionSurvey(state, npsId);

  return {
    disabled: type === null,
    loading: !loaded,
    npsId,
    published: npsSurvey?.published,
  };
};

const mapDispatchToProps = dispatch => ({
  onCreate: () => {
    dispatch(
      trackEvent(CLICKED_BUTTON, {
        buttonLabel: 'Create Your NPS Survey',
      })
    );
    dispatch(createSatisfactionWithRule());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NpsDashboard);
