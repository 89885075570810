import React from 'react';
import styled from 'styled-components';
import { getFlattenedClausesFromNestedConditions } from '@appcues/libcues';
import { Notice, NOTICE_TYPES } from '@appcues/component-library';
import Panel from 'components/Common/Panel';
import QualifiedContentNotice from 'components/Diagnostics/QualifiedContentNotice';
import { FrequencyCapBanner } from 'components/Diagnostics/FrequencyCapBanner';

const ChecklistEligibility = props => {
  const {
    accountId,
    allContent,
    userId,
    url,
    segments,
    meta,
    isInstalled,
    userProfile,
    navigate,
    qualifiedChecklists,
    unqualifiedChecklists,
    hasFrequencyBuffer,
    checklists,
  } = props;

  return (
    <StyledChecklistEligibility>
      {userProfile && meta && hasFrequencyBuffer && (
        <FrequencyCapBanner
          buffer={meta.buffer}
          userProfile={userProfile}
          navigateToAccount={() => navigate('/account')}
        />
      )}

      {!isInstalled && (
        <Notice type={NOTICE_TYPES.warning}>
          Appcues is not yet installed on this account.
        </Notice>
      )}
      <Panel
        name="Eligible Checklists"
        description="Checklists the given user should be able to see."
      >
        {qualifiedChecklists.map(checklist => (
          <QualifiedContentNotice
            key={checklist.id}
            stepId={checklist.id}
            accountId={accountId}
            userId={userId}
            url={url}
            qualified
            steps={allContent}
            rule={{}}
            clauses={
              getFlattenedClausesFromNestedConditions(
                checklist.rule.conditions || {}
              ) || []
            }
            segments={segments}
            name={checklist.internalName}
            hideFrequency
            hideABGroup
            checklist={checklist}
            checklists={checklists}
          />
        ))}
      </Panel>
      <Panel
        name="Ineligible Checklists"
        description="Checklists that the given user is not eligible to see."
      >
        {unqualifiedChecklists.map(checklist => {
          return (
            <QualifiedContentNotice
              key={checklist.id}
              stepId={checklist.id}
              accountId={accountId}
              userId={userId}
              url={url}
              qualified={null}
              steps={allContent}
              rule={{}}
              clauses={
                getFlattenedClausesFromNestedConditions(
                  checklist.rule.conditions || {}
                ) || []
              }
              segments={segments}
              name={checklist.internalName}
              hideFrequency
              hideABGroup
              checklist={checklist}
              checklists={checklists}
            />
          );
        })}
      </Panel>
    </StyledChecklistEligibility>
  );
};

const StyledChecklistEligibility = styled.div`
  margin-top: 1rem;
`;

export default ChecklistEligibility;
