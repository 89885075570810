import { createLifecycleFor } from 'actions/account/collections';

const TYPE = 'keys';

const actions = createLifecycleFor(TYPE);

export const KEY_ACTIVATED = 'KEY_ACTIVATED';
export const activateKey = ({ key }) => ({
  type: KEY_ACTIVATED,
  payload: { key },
});

export const KEY_REVOKED = 'KEY_REVOKED';
export const revokeKey = ({ key }) => ({
  type: KEY_REVOKED,
  payload: { key },
});

export const KEY_DELETED = 'KEY_DELETED';
export const deleteKey = ({ id }) => ({
  type: KEY_DELETED,
  payload: { id },
});

export const {
  callApi: getKeys,
  patterns,
  reject,
  resolve,
  send,
  insert,
  create,
  replace,
  remove,
} = actions;
