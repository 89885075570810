import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import classNames from 'classnames';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Caption, M2, Box, CSkeleton } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';
import Card from 'components/Common/Card';
import CoolTip from 'components/Common/CoolTip';
import { navigate } from 'actions/routing';
import { showModal } from 'actions/currentModal';
import { cloneSegment } from 'actions/account/segments';
import { fetchConditionsEstimate } from 'actions/estimation';
import { trackEvent } from 'actions/events';
import { selectConditionsBySegmentId } from 'reducers/account/conditions';
import { conditionsAreEmpty, shouldUseEstimateData } from 'utils/segments';
import { numberWithAbbrevMillions } from 'utils/textFormatting';
import { getPercentageStrRoundedEnds } from 'utils/numbers';
import { DELETE_SEGMENT_MODAL } from 'constants/globals';
import { GRAPHICS_BY_LIFECYCLE } from 'constants/segments';
import { selectUserRole } from 'reducers/account/users';
import { selectUserId } from 'reducers/user';
import { ACCOUNT_PERMISSIONS } from 'constants/accountManagement/permissions';
import { selectAccountSegment } from 'reducers/account/segments';
import { asPromised } from 'utils/as-promised';
import {
  selectSegmentMembership,
  selectSegmentMembershipsSynced,
} from 'reducers/account/segmentMemberships';

const metricContentLoading = {
  metric: <CSkeleton width="20%" height="24px" marginBottom="10px" />,
  metricCaption: <CSkeleton width="50%" height="16px" />,
  metricClassName: 'segment_metric--loading',
};
const metricContentNoData = {
  metric: 'No data yet',
  metricCaption: 'waiting for users',
  metricClassName: 'segment_metric--no_members',
};
const metricContentNoConditions = {
  metric: 'Define',
  metricCaption: 'to start tracking',
  metricClassName: 'segment_metric--no_conditions',
};

const MetricNoMargins = styled(M2)`
  margin: 0;
`;

const UsageIcon = styled.span`
  background-color: ${props => props.theme['$gray-1']};
  border-radius: 12px;
  color: var(--icon);
  display: inline-block;
  font-size: var(--xx-small);
  line-height: 24px;
  height: 24px;
  margin-right: 8px;
  text-align: center;
  width: 24px;
`;

const getLifecycleGraphicUrl = (userLifecycleStage, whichGraphic) => {
  if (userLifecycleStage) {
    return GRAPHICS_BY_LIFECYCLE[userLifecycleStage][whichGraphic];
  }
  return '';
};

export class SegmentCard extends Component {
  state = {
    estimateLoaded: false,
    estimateErrored: false,
    estimate: null,
  };

  componentDidMount() {
    const { useEstimate, onLoad } = this.props;
    if (useEstimate) {
      onLoad()
        .then(estimate => this.setState({ estimateLoaded: true, estimate }))
        .catch(() => {
          this.setState({ estimateLoaded: true, estimateErrored: true });
        });
    }
  }

  // handles clicks when card is a placeholder, triggers
  // creation of segments via handler prop
  handleClick = () => {
    const {
      hasConditions,
      userLifecycleStage,
      handlePlaceholderClick,
      isPlaceholderLifecycleSegment,
      onTrackEvent,
    } = this.props;
    if (userLifecycleStage) {
      onTrackEvent('Clicked lifecycle segment card', {
        segmentIsSetUp: !(isPlaceholderLifecycleSegment || !hasConditions),
      });
    }
    if (isPlaceholderLifecycleSegment) {
      handlePlaceholderClick(userLifecycleStage);
    } else {
      const navTarget = this.getNavTarget();
      this.navigateTo(navTarget);
    }
  };

  getNavTarget = () => {
    const { hasConditions } = this.props;

    // if there are no conditions yet, take user to /edit
    if (!hasConditions) {
      return 'edit';
    }
    return 'view';
  };

  navigateTo = page => {
    const { segmentId, onNavigate } = this.props;
    onNavigate(`/segments/${segmentId}/${page}`);
  };

  onSelectDropdownOption = optionValue => {
    const { onClone, onDelete } = this.props;
    switch (optionValue) {
      case 'view':
        this.navigateTo('view');
        break;
      case 'edit':
        this.navigateTo('edit');
        break;
      case 'clone':
        onClone();
        break;
      case 'delete':
        onDelete();
        break;
      default:
        break;
    }
  };

  dropdownOptions(canEditSegments) {
    const { userLifecycleStage } = this.props;
    // no dropdown menu on lifecycle segments
    if (userLifecycleStage) {
      return [];
    }

    const cardActions = [
      {
        label: 'Details',
        value: 'view',
        icon: 'eye',
      },
    ];

    if (canEditSegments) {
      cardActions.push(
        {
          label: 'Edit',
          value: 'edit',
          icon: 'edit',
        },
        { label: 'Clone', value: 'clone', icon: 'copy' },
        { label: 'Delete', value: 'delete', icon: 'trash-alt' }
      );
    }
    return cardActions;
  }

  render() {
    const {
      className,
      segmentId,
      name,
      userLifecycleStage,
      hasConditions,
      numMatchingFlows,
      canEditSegments,
      useEstimate,
      userCountsLoaded,
      userCounts,
      updatedAt,
    } = this.props;

    const lifecycleIconUrl = getLifecycleGraphicUrl(userLifecycleStage, 'icon');
    const illustrationUrl = getLifecycleGraphicUrl(
      userLifecycleStage,
      'illustration'
    );

    const activeMembers =
      userCounts &&
      userCounts.count &&
      typeof Number.parseInt(userCounts.count, 10) === 'number'
        ? Number.parseInt(userCounts.count, 10)
        : null;
    const percentOfUsers =
      userCounts && typeof Number.parseInt(userCounts.basis, 10) === 'number'
        ? getPercentageStrRoundedEnds(userCounts.count / userCounts.basis)
            .percentLabel
        : null;
    const totalUsers =
      userCounts &&
      userCounts.basis &&
      typeof Number.parseInt(userCounts.basis, 10) === 'number'
        ? Number.parseInt(userCounts.basis, 10)
        : null;

    let metricContent = null;

    if (useEstimate) {
      const { estimateLoaded, estimateErrored, estimate } = this.state;
      if (!estimateLoaded) {
        metricContent = metricContentLoading;
      } else if (!hasConditions) {
        metricContent = metricContentNoConditions;
      } else if (estimateErrored) {
        metricContent = metricContentNoData;
      } else {
        const { pctQualifiedUsers } = estimate;
        const { percentLabel, wasRounded } =
          getPercentageStrRoundedEnds(pctQualifiedUsers);
        metricContent = {
          metric: `${wasRounded ? '' : '~'}${percentLabel}%`,
          metricCaption: 'of active users (estimated)',
          metricClassName: 'segment_metric--estimate',
        };
      }
    } else if (!userCountsLoaded) {
      metricContent = metricContentLoading;
    } else if (!hasConditions) {
      metricContent = metricContentNoConditions;
    } else if (
      typeof activeMembers === 'number' &&
      typeof percentOfUsers === 'string' &&
      typeof totalUsers === 'number'
    ) {
      metricContent = {
        metric: numberWithAbbrevMillions(activeMembers),
        metricCaption: `${percentOfUsers}% of total users.`,
        metricClassName: 'segment_metric--members',
      };
    } else {
      metricContent = metricContentNoData;
    }

    const { metric, metricCaption, metricClassName } = metricContent;

    return (
      <Card
        className={classNames(className, 'segmentCard')}
        header={name || segmentId}
        onClick={this.handleClick}
        dropdownOptions={this.dropdownOptions(canEditSegments)}
        onSelectDropdownOption={this.onSelectDropdownOption}
        backgroundImage={`url(${illustrationUrl})`}
        backgroundRepeat="no-repeat"
        backgroundPosition="top -15px right -10px"
        backgroundSize="80px 80px"
      >
        <>
          <MetricNoMargins
            type={!hasConditions || !userCountsLoaded ? 'tertiary' : ''}
            regular
            className={metricClassName}
          >
            {metric}
          </MetricNoMargins>
          <Caption
            type="tertiary"
            display="inline-block"
            width="100%"
            marginBottom={10}
          >
            <CoolTip
              tip={
                useEstimate
                  ? `This estimate will be replaced by an exact number ${moment(
                      updatedAt
                    )
                      .add(7, 'days')
                      .fromNow()}`
                  : `${numberWithAbbrevMillions(
                      totalUsers
                    )} users in the last 7 days.`
              }
              position="right"
            >
              {metricCaption}
            </CoolTip>
          </Caption>
        </>

        <Box is="footer" display="flex" alignItems="center" position="relative">
          <UsageIcon>
            <Icon icon="map" title="segment usage icon" />
          </UsageIcon>
          <Caption type="tertiary" className="segment_metric--num_flows">
            {`${numMatchingFlows} flow${numMatchingFlows === 1 ? '' : 's'}`}
          </Caption>
          {lifecycleIconUrl && (
            <Box
              className="segment--lifecycle_icon"
              is="img"
              src={lifecycleIconUrl}
              aria-hidden
              position="absolute"
              right="0"
              marginRight="5px"
            />
          )}
        </Box>
      </Card>
    );
  }
}

SegmentCard.propTypes = {
  className: PropTypes.string,
  segmentId: PropTypes.string,
  name: PropTypes.string,
  hasConditions: PropTypes.bool,
  userLifecycleStage: PropTypes.string,
  numMatchingFlows: PropTypes.number,
  isPlaceholderLifecycleSegment: PropTypes.bool,
  handlePlaceholderClick: PropTypes.func,
  canEditSegments: PropTypes.bool,
  useEstimate: PropTypes.bool,
  userCountsLoaded: PropTypes.bool,
  userCounts: PropTypes.shape({
    count: PropTypes.number,
    basis: PropTypes.number,
  }),
  updatedAt: PropTypes.number,
  onLoad: PropTypes.func,
  onClone: PropTypes.func,
  onDelete: PropTypes.func,
  onNavigate: PropTypes.func,
  onTrackEvent: PropTypes.func,
};

function mapStateToProps(state, { segmentId, isPlaceholderLifecycleSegment }) {
  const {
    name,
    updatedAt,
    conditions,
    meta = {},
  } = selectAccountSegment(state, segmentId) || {};
  const { userLifecycleStage } = meta;
  const hasConditions =
    !isPlaceholderLifecycleSegment && !conditionsAreEmpty({ conditions });
  const matchingFlows = isPlaceholderLifecycleSegment
    ? []
    : selectConditionsBySegmentId(state, { segmentId });
  const userRole = selectUserRole(state, selectUserId(state));
  const userCounts = selectSegmentMembership(state, {
    segmentId,
  });
  return {
    name,
    updatedAt,
    conditions,
    hasConditions,
    userLifecycleStage,
    numMatchingFlows: matchingFlows.length,
    canEditSegments: userRole !== ACCOUNT_PERMISSIONS.EDITOR,
    useEstimate: shouldUseEstimateData({ updatedAt }),
    userCountsLoaded: selectSegmentMembershipsSynced(state),
    userCounts,
  };
}

const mapDispatchToProps = (dispatch, { segmentId }) => ({
  onClone: () => dispatch(cloneSegment(segmentId)),
  onDelete: () =>
    dispatch(
      showModal(DELETE_SEGMENT_MODAL, {
        segmentType: 'segment',
        segmentId,
      })
    ),
  onNavigate: path => dispatch(navigate(path)),
  onTrackEvent: (name, opts) => trackEvent(name, opts),
  getConditionsEstimate: conditions =>
    asPromised(dispatch, fetchConditionsEstimate(conditions)),
});

const mergeProps = (
  { conditions, ...stateProps },
  { getConditionsEstimate, ...dispatchProps },
  ownProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onLoad: () => getConditionsEstimate(conditions),
});

const ConnectedSegmentCard = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(SegmentCard);

ConnectedSegmentCard.propTypes = {
  segmentId: PropTypes.string,
  className: PropTypes.string,
  isPlaceholderLifecycleSegment: PropTypes.bool,
  handlePlaceholderClick: PropTypes.func,
};

export default ConnectedSegmentCard;
