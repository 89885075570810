/**
 * HTTP exception
 *
 * Extends the native `Error` object but also includes the response as part of
 * the exception so that the error response, status code, etc. can be used to
 * handle the error appropriately
 *
 * Taken from https://github.com/sindresorhus/ky
 *
 * @param {Response} response - Fetch HTTP response
 * @param {string} [message] - Fallback error message
 * @return {Error} Error with HTTP response
 */
export default class HTTPError extends Error {
  constructor(response, message = 'Request failed') {
    super(response?.statusText || message);
    this.name = 'HTTPError';
    this.response = response;
    Error.captureStackTrace(this, this.constructor);
  }
}
