export const randomizeOptions = numberOfOptions => {
  const optionsArray = [...Array.from({ length: numberOfOptions }).keys()];

  for (let i = optionsArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = optionsArray[i];
    optionsArray[i] = optionsArray[j];
    optionsArray[j] = temp;
  }

  return optionsArray;
};

const ENTER_KEY_CODE = 13;
export const pressedEnter = e => {
  return e.keyCode === ENTER_KEY_CODE || e.which === ENTER_KEY_CODE;
};

export const focusCursorToEndOfInput = $scope => {
  $scope.focus();
  $scope.selectionStart = $scope.value.length;
  $scope.selectionEnd = $scope.value.length;
};
