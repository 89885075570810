import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const UpdatedTable = props => {
  const { headers, rowData, renderRow, className, renderHeader } = props;

  const headerComponents = headers.map((header, index) => {
    return renderHeader(header, index);
  });

  const rowComponents = rowData.map((row, index) => {
    return renderRow(row, index);
  });

  return (
    <Table className={className}>
      <thead>
        <tr>{headerComponents}</tr>
      </thead>
      <tbody>{rowComponents}</tbody>
    </Table>
  );
};

const defaultRenderHeader = header => {
  return (
    <TableHeader key={header.text} align={header.align}>
      {header.text}
    </TableHeader>
  );
};

UpdatedTable.propTypes = {
  headers: PropTypes.array,
  rowData: PropTypes.array,
  renderRow: PropTypes.func,
  className: PropTypes.string,
  renderHeader: PropTypes.func,
};

UpdatedTable.defaultProps = {
  renderHeader: defaultRenderHeader,
};

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr {
    border-top: 1px solid ${props => props.theme['$gray-1']};
  }
`;

const TableHeader = styled.th`
  color: ${props => props.theme['$gray-30']};
  padding: 1rem 0;
  text-align: ${props => props.align};
`;

export default UpdatedTable;
