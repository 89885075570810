import * as actionTypes from 'constants/account/themes';

export const replaceAccountTheme = (themeId, theme) => {
  return {
    type: actionTypes.REPLACE_ACCOUNT_THEME,
    payload: { themeId, theme },
  };
};

export const replaceAccountThemes = themes => {
  return {
    type: actionTypes.REPLACE_ACCOUNT_THEMES,
    payload: themes,
  };
};

export const updateAccountThemeAndFlush = (themeId, changes) => {
  return {
    type: actionTypes.UPDATE_ACCOUNT_THEME_AND_FLUSH,
    payload: { themeId, changes },
  };
};

export const createAccountTheme = () => {
  return {
    type: actionTypes.CREATE_ACCOUNT_THEME,
  };
};

export const cloneAccountTheme = originalThemeId => {
  return {
    type: actionTypes.CLONE_ACCOUNT_THEME,
    payload: { originalThemeId },
  };
};

export const deleteAccountTheme = themeId => {
  return {
    type: actionTypes.DELETE_ACCOUNT_THEME,
    payload: { themeId },
  };
};

export const setDefaultTheme = themeId => {
  return {
    type: actionTypes.SET_DEFAULT_THEME,
    payload: { themeId },
  };
};
