import { conditionNames } from '@appcues/libcues';
import {
  REPLACE_ACCOUNT_CONDITIONS,
  REPLACE_ACCOUNT_STEP_CONDITIONS,
  UPDATE_ACCOUNT_STEP_CONDITIONS,
} from 'constants/account/conditions';
import { CLEAR_ACCOUNT_DATA } from 'constants/actionTypes';
import { TYPE as RULES_TYPE, selectAccountRule } from 'reducers/account/rules';
import { selectIsSynced } from 'reducers/account/requests';

const initialState = {
  meta: {
    fetching: false,
    synced: false,
    errored: false,
  },
  data: {},
};

export default function conditions(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ACCOUNT_STEP_CONDITIONS:
    case REPLACE_ACCOUNT_STEP_CONDITIONS: {
      if (action.payload.conditions) {
        return {
          ...state,
          data: {
            ...state.data,
            [action.payload.id]: action.payload.conditions,
          },
        };
      }

      const { [action.payload.id]: omit, ...stateWithoutStepConditions } =
        state.data;

      return {
        ...state,
        data: stateWithoutStepConditions,
      };
    }

    case REPLACE_ACCOUNT_CONDITIONS:
      return {
        ...state,
        meta: {
          fetching: false,
          synced: true,
          errored: false,
        },
        data: action.payload,
      };

    case CLEAR_ACCOUNT_DATA:
      return initialState;

    default:
      return state;
  }
}

export const selectAccountConditions = state => state.account.conditions.data;
export const selectAccountStepConditions = (state, id) =>
  selectAccountConditions(state)[id] || [];
export const selectAccountConditionsSynced = state =>
  selectIsSynced(state, RULES_TYPE);
export const selectConditionsBySegmentId = (state, { segmentId }) => {
  const ruleConditions = selectAccountConditions(state) || {};

  return Object.keys(ruleConditions).filter(ruleId => {
    const clauses = ruleConditions[ruleId];
    const rule = selectAccountRule(state, ruleId);

    return clauses.find(
      clause =>
        clause.conditionName === conditionNames.SEGMENTS &&
        clause.segment === segmentId &&
        // only consider flows, discard nps ones
        rule &&
        rule.contentType === 'journey'
    );
  });
};
