export function componentRenderStartMarkName(componentName, key) {
  return `appcues_mark_start_render_${componentName}${key ? `_${key}` : ''}`;
}

export function componentRenderEndMarkName(componentName, key) {
  return `appcues_mark_end_render_${componentName}${key ? `_${key}` : ''}`;
}

export function componentRenderMeasureName(componentName) {
  return `appcues_measure_render_${componentName}`;
}

export function actionStartMarkName(label) {
  return `appcues_mark_start_action_${label}`;
}

export function actionEndMarkName(label) {
  return `appcues_mark_end_action_${label}`;
}

export function actionMeasureName(startLabel, endLabel) {
  return `appcues_measure_action_${startLabel}_${endLabel}`;
}
