import styled from 'styled-components';
import { H1 } from '@studio/legacy-components';

export const Name = styled(H1)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const NameWrapper = styled.div`
  display: inline-grid;
  max-width: 80%;
`;

export const BadgeContainer = styled.div`
  display: flex;
  gap: var(--spacing-small);
  margin-left: var(--spacing-regular);
`;
