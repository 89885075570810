import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CPanel, CRow, CCol, H3 } from '@appcues/component-library';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Select } from '@studio/legacy-components';
import { GOALS_EVENTS_EXPLORER } from 'next/entities/features';
import { selectAccountFeature } from 'reducers/account/features';
import {
  EVENT_SOURCE_OPTIONS,
  MAX_SELECTED_EVENTS,
  SELECTED_EVENT_SOURCE,
} from 'components/Insights/constants';
import EmptyState from 'components/Insights/Explorer/Common/EmptyState';
import EventSearchBar from 'components/Insights/Common/EventSearchBar';
import * as persistence from 'helpers/persistence';
import FlowEvents from './Flow';
import PinEvents from './Pin';
import Checklist from './Checklist';
import Builder from './Builder';
import Application from './Application';
import BannerEvents from './Banner';
import GoalEvents from './Goal';

const StyledCPanelHeader = styled(CPanel.Header)`
  min-height: 0;
`;

const searchPlaceholder = {
  application: 'Application Events',
  builder: 'Track Events (Builder)',
  banner: 'Banners',
  checklist: 'Checklists',
  flow: 'Flows',
  pin: 'Pins',
};

export const Sources = ({
  startTime,
  endTime,
  segmentId,
  selectedEvents,
  onSelectEvent,
  onClearEvents,
  hasGoalsExplorer,
  hasEvents = true,
}) => {
  const [eventSource, setEventSource] = useState(() => {
    const selectedEventSource = persistence.getItem(SELECTED_EVENT_SOURCE);
    if (!hasGoalsExplorer && selectedEventSource === 'goals') {
      return EVENT_SOURCE_OPTIONS[0].value;
    }
    return selectedEventSource || EVENT_SOURCE_OPTIONS[0].value;
  });

  const [searchTerm, setSearchTerm] = useState('');

  const eventSourceOptions = EVENT_SOURCE_OPTIONS.filter(source => {
    if (!hasGoalsExplorer && source.value === 'goals') {
      return false;
    }
    return source;
  });

  if (!hasEvents) {
    return <EmptyState />;
  }

  const sourceProps = {
    startTime,
    endTime,
    segmentId,
    searchTerm,
    selectedEvents,
    enableSelect: selectedEvents && selectedEvents.length < MAX_SELECTED_EVENTS,
    onSelect: onSelectEvent,
    onClearEvents,
  };

  const handleEventSource = ({ value }) => {
    setEventSource(value);
    persistence.setItem(SELECTED_EVENT_SOURCE, value);
  };
  return (
    <CPanel minWidth={948}>
      <StyledCPanelHeader>
        <H3 lineHeight={1}>Event Source</H3>
      </StyledCPanelHeader>
      <CRow between="xs" marginTop="-8px">
        <CCol xs={3}>
          <Select
            onChange={handleEventSource}
            options={eventSourceOptions}
            value={eventSourceOptions.find(
              ({ value }) => value === eventSource
            )}
          />
        </CCol>
        <CCol xs={3}>
          <EventSearchBar
            placeholder={`Search ${searchPlaceholder[eventSource] || ''}`}
            onChange={setSearchTerm}
          />
        </CCol>
      </CRow>
      {eventSource === 'application' && <Application {...sourceProps} />}
      {eventSource === 'builder' && <Builder {...sourceProps} />}
      {eventSource === 'checklist' && <Checklist {...sourceProps} />}
      {eventSource === 'flow' && <FlowEvents {...sourceProps} />}
      {eventSource === 'pin' && <PinEvents {...sourceProps} />}
      {eventSource === 'banner' && <BannerEvents {...sourceProps} />}
      {eventSource === 'goals' && <GoalEvents {...sourceProps} />}
    </CPanel>
  );
};

Sources.propTypes = {
  hasEvents: PropTypes.bool,
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  segmentId: PropTypes.string,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  onSelectEvent: PropTypes.func,
  onClearEvents: PropTypes.func,
};

const mapStateToProps = state => ({
  hasGoalsExplorer: selectAccountFeature(state, GOALS_EVENTS_EXPLORER),
});

export default connect(mapStateToProps)(Sources);
