import moment from 'moment';

import { createCollectionReducer } from 'reducers/account/collections';
import { selectAccountUser } from './users';

export const TYPE = 'flowVersions';
export default createCollectionReducer(TYPE);

export const selectFlowVersions = (state, { flowId, startTime }) => {
  let startArg;
  if (typeof startTime === 'string') {
    const [number, timeUnit] = startTime.split(' ');
    startArg = moment().subtract({ [timeUnit]: number });
  } else {
    startArg = startTime;
  }

  return (
    state.account.flowVersions &&
    state.account.flowVersions[flowId] &&
    state.account.flowVersions[flowId].versions.filter(({ version }) => {
      return moment(version).isAfter(startArg);
    })
  );
};

export const selectFlowHistory = (state, flowId) => {
  const flowVersions = state.account.flowVersions?.[flowId] || {};
  const versions = flowVersions.versions || [];
  const result = versions
    .sort((a, b) => a.version - b.version)
    .flatMap((version, index) => {
      const user = selectAccountUser(state, version.updatedBy)?.meta || {};
      const previousVersion = versions[index - 1];
      const nextVersion = versions[index + 1];

      if (
        (previousVersion &&
          previousVersion?.unpublishedAt !== version.updatedAt) ||
        (version.state === 'PUBLISHED' && index === 0)
      ) {
        return {
          updatedAt: version.updatedAt,
          user,
          status: 'Published',
        };
      }

      if (previousVersion?.unpublishedAt === version.updatedAt) {
        return [
          {
            updatedAt: version.updatedAt,
            user,
            status: 'Pushed changes',
          },
          ...((version.unpublishedAt &&
            version.unpublishedAt !== nextVersion?.version && [
              {
                updatedAt: version.unpublishedAt,
                user,
                status: 'Unpublished',
              },
            ]) ||
            []),
        ];
      }

      if (version.state === 'PUBLISHED') {
        return [
          {
            updatedAt: version.updatedAt,
            user,
            status: 'Published',
          },
        ];
      }

      return [
        {
          updatedAt: version.updatedAt,
          user,
          status: 'Published',
        },
        ...((version.unpublishedAt &&
          version.unpublishedAt !== nextVersion?.version && [
            {
              updatedAt: version.unpublishedAt,
              user,
              status: 'Unpublished',
            },
          ]) ||
          []),
      ];
    })
    .reverse();

  return result;
};

export const selectFlowVersionsByDimension = (
  state,
  { flowId, startTime, dimension }
) =>
  selectFlowVersions(state, { flowId, startTime }) &&
  selectFlowVersions(state, { flowId, startTime }).reduce((acc, version) => {
    const { version: versionId } = version;
    const date = moment(versionId).format(
      dimension === 'day' ? 'L' : 'YYYY-MM'
    );
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(version);
    return acc;
  }, {});
