import styled from 'styled-components';
import { Box } from '@appcues/component-library';
import { getThemeFor } from 'utils';

export const TableContainer = styled(Box)`
  display: block;
  position: relative;
  overflow-y: ${({ maxHeight }) => (maxHeight ? 'scroll' : 'visible')};
  background: linear-gradient(white 99%, rgba(200, 200, 200, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 99%) 0 100%,
    radial-gradient(
      farthest-corner at 50% 0,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
        farthest-corner at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 10px, 100% 10px, 100% 2px, 100% 2px;
  background-attachment: local, local, scroll, scroll;
  &.panel {
    margin-left: -24px;
    margin-right: -24px;
    ${({ marginTop }) => (!marginTop ? `margin-top:-25px` : '')};
    ${({ marginBottom }) => (!marginBottom ? `margin-bottom:-25px` : '')};
  }
`;

export const TableWrapper = styled.table`
  border-collapse: collapse;
  min-width: 100%;
`;

export const TableHead = styled.th`
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  min-width: ${({ minWidth }) => `${minWidth}px`};
`;

export const TableRow = styled.tr`
  display: table-row;
  border-bottom: 1px solid
    ${({ hasBorder }) =>
      hasBorder ? getThemeFor('$dark-tint-10') : 'transparent'};
  vertical-align: inherit;
`;

export const TableData = styled.td`
  border-collapse: collapse;
  vertical-align: middle;
  padding: 15px;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  min-width: ${({ minWidth }) => `${minWidth}px`};
  word-wrap: ${({ wordWrap }) => wordWrap};
  white-space: ${({ noWrap }) => noWrap || 'normal'};
  word-break: break-word;
  &.headerCell {
    display: flex;
    user-select: none;
    align-items: center;
    flex: 1;
    padding: 15px;
    height: 55px;
    justify-content: ${props =>
      props.alignment === 'right' ? 'flex-end' : 'space-between'};
    text-align: ${({ alignment }) =>
      alignment === 'right' ? 'right' : 'initial'};
    border-bottom: 1px solid ${getThemeFor('$dark-tint-10')};
    border-top: 1px solid ${getThemeFor('$dark-tint-10')};
  }
  &.firstCell {
    padding: 0 15px 0 15px;
    width: 60px;
    min-width: 60px;
  }
  &.canSort {
    transition: all 0.2s ease;
  }
  &.canSort:hover {
    cursor: pointer;
    transition: background-color 0.2s ease;
    background-color: ${getThemeFor('$gray-1')};
  }
  &.isSorted {
    position: relative;
    background-color: ${getThemeFor('$gray-2')};
    box-shadow: inset 0 -2px 0 0 ${getThemeFor('$smurfs-blood')};
  }
`;
