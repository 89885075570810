import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Row,
  Flex,
  CPanel,
  CSkeleton,
  Text,
  Caption,
  Column,
} from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';
import {
  selectAccountConditionsSynced,
  selectConditionsBySegmentId,
} from 'reducers/account/conditions';
import {
  selectAccountId,
  selectAccountMetaSynced,
} from 'reducers/account/meta';
import {
  selectFlows,
  selectAreFlowsSynced,
  selectFlow,
} from 'reducers/account/flows';
import {
  selectAccountRules,
  selectAccountRulesSynced,
} from 'reducers/account/rules';

import {
  selectAccountUsers,
  selectAccountUsersSynced,
} from 'reducers/account/users';
import { navigate } from 'actions/routing';
import { callApi as fetchRules } from 'actions/account/rules';
import { getUpdatedByUserFromStep } from 'helpers/view';
import AvatarImg from 'components/Common/AvatarImg';
import MetricFlowListItem from 'components/Common/MetricFlowListItem';

export class FlowsUsingSegment extends Component {
  componentDidMount() {
    const { onLoad } = this.props;
    onLoad();
  }

  updatedText(step) {
    const { accountUsers } = this.props;
    const updatedUser = getUpdatedByUserFromStep(step, accountUsers);
    return updatedUser && updatedUser.updatedDate;
  }

  render() {
    const { loaded, rulesUsingSegment } = this.props;
    if (rulesUsingSegment.length > 0 && loaded) {
      return (
        <Column gutter={16} alignItems="stretch">
          {rulesUsingSegment.map(flow => {
            return (
              <MetricFlowListItem flowId={flow.id} key={flow.id}>
                <Flex alignItems="center">
                  <AvatarImg
                    userId={flow.updatedBy}
                    width={16}
                    marginRight={4}
                  />
                  <Caption marginLeft={2} type="tertiary">
                    Updated {this.updatedText(flow)}
                  </Caption>
                </Flex>
              </MetricFlowListItem>
            );
          })}
        </Column>
      );
    }
    if (!loaded) {
      return (
        <CPanel>
          <CSkeleton width={250} marginBottom={16} />
          <CSkeleton width={500} marginBottom={16} />
          <CSkeleton width={125} marginBottom={16} />
        </CPanel>
      );
    }
    return (
      <Row>
        <Icon icon="map" />
        <Text marginLeft={16}>None yet</Text>
      </Row>
    );
  }
}

FlowsUsingSegment.propTypes = {
  segmentId: PropTypes.string.isRequired,
  segmentName: PropTypes.string,
  rulesUsingSegment: PropTypes.array,
  loaded: PropTypes.bool,
  steps: PropTypes.object,
  rules: PropTypes.object,
  accountUsers: PropTypes.object,
  onLoad: PropTypes.func,
  navigate: PropTypes.func,
};

FlowsUsingSegment.defaultProps = {
  rulesUsingSegment: [],
};

function mapStateToProps(state, { segmentId }) {
  return {
    accountId: selectAccountId(state),
    accountUsers: selectAccountUsers(state),
    rulesUsingSegment: selectConditionsBySegmentId(state, { segmentId }).reduce(
      (acc, flowId) => {
        const flow = selectFlow(state, flowId);
        if (flow) {
          acc.push(flow);
        }
        return acc;
      },
      []
    ),
    steps: selectFlows(state),
    rules: selectAccountRules(state),
    loaded:
      selectAccountConditionsSynced(state) &&
      selectAreFlowsSynced(state) &&
      selectAccountRulesSynced(state) &&
      selectAccountMetaSynced(state) &&
      selectAccountUsersSynced(state),
  };
}

const mapDispatchToProps = {
  onLoad: fetchRules,
  navigate,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlowsUsingSegment);
