/* eslint-disable no-underscore-dangle */
import React from 'react';

import { faWavePulse } from '@fortawesome/pro-solid-svg-icons/faWavePulse';
import PropTypes from 'prop-types';
import {
  SubTitle,
  Button,
  Icon,
  Link,
  Tooltip,
  Spinner,
  Text,
} from '@appcues/sonar';
import { conditionalRelativeTim } from 'next/lib/date';
import NoRecentUsers from 'next/assets/images/no-recent-users.svg';
import {
  UsersList,
  User,
  DateWrapper,
  UserSection,
  SpinnerWrapper,
  EmptyState,
  EmptyHelperText,
  Identifier,
} from './styled';

export default function EndUserProfiles({ users = [], identifier, loading }) {
  return (
    <UserSection>
      <SubTitle>Recent Users</SubTitle>
      {loading && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      {!loading && users.length > 0 && (
        <>
          <UsersList>
            {users?.map(user => (
              <User key={user.userId}>
                <Identifier href={`/users/${user.userId}`}>
                  {identifier && user[identifier]
                    ? user[identifier]
                    : user.userId}
                </Identifier>

                <DateWrapper>
                  {conditionalRelativeTim(Number(user._lastSeenAt))}
                </DateWrapper>
                <Tooltip content="View user activity">
                  <Button
                    iconOnly
                    variant="tertiary"
                    forwardedAs={Link}
                    href={`/audience/users/${user.userId}/activities`}
                  >
                    <Icon size="small" icon={faWavePulse} />
                  </Button>
                </Tooltip>
              </User>
            ))}
          </UsersList>
          <Link href="/audience/users">See all users</Link>
        </>
      )}
      {!loading && users.length === 0 && (
        <EmptyState>
          <img src={NoRecentUsers} alt="No recent users" />
          <Text size="medium" weight="semibold" colorToken="foreground-primary">
            No users seen yet
          </Text>
          <EmptyHelperText size="regular" align>
            Identify your product users when you{' '}
            <Link href="/settings/installation">install</Link> to understand key
            user behaviors that lead to activation, retention, and more.
          </EmptyHelperText>
        </EmptyState>
      )}
    </UserSection>
  );
}

EndUserProfiles.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      _lastSeenAt: PropTypes.string,
    })
  ),
  identifier: PropTypes.string,
  loading: PropTypes.bool,
};
