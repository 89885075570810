import styled from 'styled-components';
import { Table, Td } from '@studio/legacy-components';
import { PaginationMessage } from 'components/Common/UI/Pagination/Pagination';

export const TableWrapper = styled.div`
  --table-wrapper-min-height: 388px;
  --table-wrapper-max-height: 910px; // 26 rows of 35px height
  min-height: var(--table-wrapper-min-height);
  max-height: var(--table-wrapper-max-height);
  overflow-y: auto;
  position: relative;

  ${Table} {
    table-layout: fixed;
    display: block;

    ${Td} {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const PaginationInformation = styled(PaginationMessage)`
  right: 42px;
`;

export const Footer = styled.footer`
  display: flex;
  position: relative;
  height: 52px;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--tint-black);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(var(--table-wrapper-min-height) - 38px);
  gap: 24px;
  flex-direction: column;
`;

export const NoResultsMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(var(--table-wrapper-min-height) - 38px);
  flex-direction: column;
  gap: 16px;
  position: absolute;
`;

export const NoResultsMessageEmphasis = styled.strong`
  font-size: 20px;
  font-weight: var(--semi-bold);
`;
