import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import CarouselPreview from 'components/CarouselPreview';
import Checklists from 'components/Checklists';
import Diagnostics from 'components/Diagnostics';
import EditInChrome from 'components/EditInChrome';
import Flows from 'components/Flows';
import Goals from 'components/Goals';
import InstallationGuide from 'components/InstallationGuide';
import { getAccountRoutes } from 'components/App/account-routes';
import Overview from 'components/Overview';
import Priorities from 'components/Priorities';
import RedirectWithQueryParams from 'components/App/RedirectWithQueryParams';
import Reports from 'components/Reports';
import RouteNotFound from 'components/RouteNotFound';
import SatisfactionSurveys from 'components/SatisfactionSurveys';
import SegmentEnabled from 'components/SegmentEnabled';
import Segments from 'components/Segments';
import Settings from 'components/Settings';
import Subscription from 'components/Subscription';
import SubscriptionPicker from 'components/SubscriptionPicker';
import SubscriptionStory from 'components/SubscriptionStory';
import Themes from 'components/Themes';
import Insights from 'components/Insights';
import Audience from 'components/Audience';
import { SubscriptionCheckout } from 'components/SubscriptionCheckout';
import { SubscriptionUpgrade } from 'components/SubscriptionUpgrade';
import { APP_TYPES } from 'constants/apps';
import { addToErrReportTags } from 'helpers/error-reporting';
import Billing from 'components/SubscriptionBilling';

export const WebRoutes = () => {
  useEffect(() => {
    addToErrReportTags({ appType: APP_TYPES.WEB_APP });
  }, []);

  return (
    <Switch>
      <Route path="/subscription/public" component={Subscription} isPublic />

      <Route path="/carousel" component={CarouselPreview} />
      <Route path="/subscription/compare" component={SubscriptionPicker} />
      <Route path="/subscription/story" component={SubscriptionStory} />
      <Route path="/segments" component={Segments} />
      <Route path="/goals" component={Goals} />
      <Route
        path="/settings/installation/guide"
        component={InstallationGuide}
      />
      <Route path="/settings" component={Settings} />
      <Route
        path="/subscription/upgrade"
        component={SubscriptionUpgrade}
        exact
      />
      <Route path="/subscription/billing" component={Billing} exact />
      <Route
        path="/subscription/:type"
        component={SubscriptionCheckout}
        exact
      />
      <Route path="/themes" metric="render.theme.editor" component={Themes} />
      <Route path="/nps" component={SatisfactionSurveys} />
      <Route path="/diagnostics" component={Diagnostics} />
      <Route path="/checklists" component={Checklists} />

      <Route shouldHideNav path="/edit-in-chrome" component={EditInChrome} />

      <Route path="/flows" component={Flows} />

      {/* TEMPORARY REDIRECTS DURING MIGRATON */}

      <Redirect
        path="/journeys/:flowId/analytics"
        to="/flows/:flowId/analytics"
      />
      <Redirect
        path="/journeys/:flowId/settings"
        to="/flows/:flowId/settings"
      />
      <Redirect
        path="/journeys/:flowId/conditions"
        to="/flows/:flowId/settings"
      />

      {/* /TEMPORARY REDIRECTS DURING MIGRATON */}

      <RedirectWithQueryParams path="/reporting" to="/reports" />
      <Route path="/reports" component={Reports} />
      <Route path="/insights" component={Insights} />
      <Route path="/events" component={Insights} />
      <Route path="/priorities" component={Priorities} />

      <Route path="/segment-enabled" component={SegmentEnabled} />
      <Route path="/audience" component={Audience} />
      <Redirect path="/users/:userId" to="/audience/users/:userId" />
      <RedirectWithQueryParams path="/account" to="/settings/account" />
      <RedirectWithQueryParams path="/install" to="/settings/installation" />
      <RedirectWithQueryParams
        path="/integrations"
        to="/settings/integrations"
      />
      <RedirectWithQueryParams
        path="/events-attributes"
        to="/settings/events"
      />
      <RedirectWithQueryParams path="/team" to="/settings/team" />
      <RedirectWithQueryParams path="/billing" to="/settings/subscription" />
      <RedirectWithQueryParams
        path="/subscription"
        to="/settings/subscription"
      />
      <RedirectWithQueryParams path="/overview" to="/" />
      <Route exact path="/" component={Overview} />

      {getAccountRoutes()}

      <Route component={RouteNotFound} />
    </Switch>
  );
};

export default WebRoutes;
