import { call, getContext, select, takeEvery } from 'redux-saga/effects';

import { selectAccountUuid } from 'reducers/account/meta';
import { reportError } from 'helpers/error-reporting';
import { EVENTS_EXPORT_REQUESTED } from 'actions/exports';
import { promisaga } from 'utils/as-promised';

export function* exportEvents({
  payload: { events, email, startDate, endDate },
}) {
  try {
    const apiKey = yield select(selectAccountUuid);
    const api = yield getContext('api');

    const filter = events.map(({ source, event, id, stepId }) => ({
      name: event,
      ...(source === 'flow' && { flow_id: id }),
      ...(source === 'checklist' && { checklist_id: id }),
      ...(['pin', 'banner'].includes(source) && {
        experience_id: id,
        step_id: stepId,
      }),
      ...(source === 'mobile' && { experience_id: id }),
      ...(source === 'goals' && { goal_id: id }),
    }));

    yield call(api.exportAnalytics, {
      account_api_key: apiKey,
      format: 'csv',
      type: 'multi',
      email,
      filter,
      start_date: startDate,
      end_date: endDate,
      version: 2,
    });
  } catch (error) {
    yield call(reportError, error);
    throw error;
  }
}

export default function* exports() {
  yield takeEvery(EVENTS_EXPORT_REQUESTED, promisaga(exportEvents));
}
