import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { CPanel, CForm, Row, Column } from '@appcues/component-library';
import { Select } from '@studio/legacy-components';
import { JOB_DROPDOWN_OPTIONS } from 'constants/inviteModal';
import { selectUser } from 'reducers/user';
import { updateUserMeta } from 'actions/user';
import AvatarImg from 'components/Common/AvatarImg';
import PasswordResetManager from './PasswordResetManager';

export function ProfilePanel({
  onSubmit,
  userMeta = { fullname: '', job: '' },
}) {
  if (!userMeta) {
    return false;
  }

  const initialJob = JOB_DROPDOWN_OPTIONS.find(
    ({ value }) => value === (userMeta.job || userMeta.role)
  );

  /* global FIREBASE_PROJECT_ID */
  const canResetPassword = userMeta.provider === FIREBASE_PROJECT_ID;

  return (
    <CPanel alignItems="flex-start" title="Profile">
      <Row>
        <AvatarImg />
        <Column flexGrow={1}>
          <CForm
            onSubmit={onSubmit}
            initialValues={{
              fullname: userMeta.fullname,
              job: initialJob,
            }}
            validationSchema={yup.object().shape({
              fullname: yup.string().required('Required'),
            })}
            width="100%"
          >
            <CForm.Field name="fullname" label="Name" />
            <CForm.Field name="job" label="Job">
              <Select options={JOB_DROPDOWN_OPTIONS} />
            </CForm.Field>
            {canResetPassword && <PasswordResetManager />}
            <CForm.SubmitButton type="positive">Save</CForm.SubmitButton>
          </CForm>
        </Column>
      </Row>
    </CPanel>
  );
}

ProfilePanel.propTypes = {
  userMeta: PropTypes.shape({
    fullname: PropTypes.string,
    job: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
};

const mapStateToProps = state => ({
  userMeta: selectUser(state),
});

const mapDispatchToProps = {
  onSubmit: ({ fullname, job: { value: job } }) =>
    updateUserMeta({ fullname, job }),
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePanel);
