import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { object, string } from 'yup';
import isEmpty from 'lodash.isempty';

import {
  Button,
  ButtonGroup,
  Modal,
  Heading,
  Input,
  Label,
  ErrorMsg,
} from '@studio/legacy-components';

import { asRequest } from 'next/entities/requests';
import {
  AppShape,
  readApp,
  reset,
  selectApps,
  update,
} from 'next/entities/apps';
import { selectFeatures } from 'next/entities/features';
import { CreateAppForm, ModalNegativeNotice } from './styled';
import { enabledFeaturePlatformOptions } from './lib';

export const EditAppModal = ({
  app,
  appStatus = {},
  errors = {},
  onClose,
  onOpen,
  onSubmit,
  visible,
  resetForm,
  setValues,
  values = {},
}) => {
  const [inFlight, setInFlight] = useState(false);
  const { loading, error } = appStatus;

  useEffect(() => {
    if (visible) {
      onOpen?.(app.id);
      resetForm?.();
    }
  }, [visible, onOpen, resetForm, app.id]);

  useEffect(() => {
    if (visible && inFlight && loading === false && !error) {
      onClose();
      setInFlight(false);
    }
  }, [loading, error, inFlight, onClose, visible]);

  const disabled = loading || !isEmpty(errors);

  const handleSubmit = event => {
    event.preventDefault();
    setInFlight(true);
    onSubmit(app.id, {
      name: values.name,
    });
  };

  return (
    <Modal visible={visible} onClose={onClose}>
      <CreateAppForm onSubmit={handleSubmit}>
        <Heading>Edit name</Heading>
        {error && (
          <ModalNegativeNotice>
            We&apos;re sorry, something went wrong on our end. Try again.
          </ModalNegativeNotice>
        )}
        <Label htmlFor="name">
          Name
          <Input
            id="name"
            type="text"
            name="name"
            placeholder="e.g. my app"
            maxLength={50}
            error={!!errors.name}
            onChange={({ target: { value } }) =>
              setValues({ ...values, name: value })
            }
            value={values.name}
          />
          {errors.name && <ErrorMsg>{errors.name}</ErrorMsg>}
        </Label>
        <ButtonGroup right>
          <Button kind="primary" type="submit" disabled={disabled}>
            Save
          </Button>
          <Button kind="secondary" onClick={onClose}>
            Cancel
          </Button>
        </ButtonGroup>
      </CreateAppForm>
    </Modal>
  );
};

const formShape = type => ({
  name: type,
});

EditAppModal.propTypes = {
  app: AppShape,
  appStatus: asRequest(PropTypes.objectOf(AppShape)),
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  visible: PropTypes.bool,
  // formik HOC props
  errors: PropTypes.shape(formShape(PropTypes.string)),
  values: PropTypes.shape(formShape(PropTypes.string)),
  setValues: PropTypes.func,
  resetForm: PropTypes.func,
};

const enhanceForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ app = {} }) => ({
    name: app.name,
  }),
  validate: ({ name = '' }, { app, apps, platformOptions }) => {
    const errors = {};
    const exists = Object.values(apps)
      .filter(({ id, platform }) => platform === app.platform && id !== app.id)
      .find(
        ({ name: current }) =>
          current.toLowerCase() === name.toLowerCase().trim()
      );

    if (exists) {
      const { label } = platformOptions.find(
        item => item.value === app.platform
      );
      errors.name = `This name is already used by another ${label} app.`;
    }

    return errors;
  },
  validationSchema: object().shape({
    name: string().required('Name is required.'),
  }),
});

export const EditAppModalForm = enhanceForm(EditAppModal);

const mapStateToProps = (state, { app }) => ({
  appStatus: readApp(state, app.id),
  apps: selectApps(state),
  platformOptions: (() => {
    const features = selectFeatures(state);
    return enabledFeaturePlatformOptions(features);
  })(),
});

const mapDispatchToProps = {
  onSubmit: update,
  onOpen: reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAppModalForm);
