import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';

export const PropertyItem = styled.li`
  height: 45px;
  list-style: none;
  display: flex;
  align-items: center;
  background-color: var(--noreaster);
  border-radius: 6px;
  padding: 0 16px;
  justify-content: space-between;
`;

export const PropertiesList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
`;

export const PropertyName = styled.span`
  display: flex;
  gap: 24px;
  align-items: center;
  ${Icon} {
    font-size: 16px;
    color: var(--sharkbait-ooh-la-la);
  }
`;
