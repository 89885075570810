import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  CButton,
  CModal,
  Flex,
  ModalProvider,
} from '@appcues/component-library';
import { DocumentTitle } from 'next/hooks/use-title';
import {
  getAccounts,
  requestSwitchAccount,
  requestCreateAccount,
} from 'actions/accounts';
import Loader from 'components/Common/Loader';
import { selectAccountId } from 'reducers/account/meta';
import { partial } from 'utils';
import { selectAccounts, selectAccountsSynced } from 'reducers/accounts';
import { asPromised } from 'utils/as-promised';
import { selectAccountFeature } from 'reducers/account/features';
import { CREATE_NEW_ACCOUNTS } from 'constants/features';
import LegacyAccountSwitcher from './AccountSwitcher';

/**
 * alphabetize accounts by name then id
 *
 * @typedef {{id: string, name: string}} Account
 * @param {object.<string, Account>} accounts - accounts to sort
 * @returns {Account[]} sorted accounts
 */
const alphabetize = accounts =>
  Object.values(accounts).sort((a, b) => {
    if (!a.name || !b.name) {
      if (!a.name) {
        return 1;
      }
      if (!b.name) {
        return -1;
      }
      return a.id.localeCompare(b.id);
    }
    return a.name.localeCompare(b.name);
  });

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px;

  > button {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Accounts = ({
  currentAccount,
  accounts = {},
  history,
  loaded = false,
  onLoad,
  hasCreateNewAccounts,
  onSwitchAccount,
  onAddAccount,
}) => {
  const handleClose = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    if (onLoad) {
      onLoad();
    }
  }, [onLoad]);

  const [inFlight, setInFlight] = useState(false);

  const observePromise =
    handler =>
    (...args) => {
      setInFlight(true);
      handler(...args).finally(() => setInFlight(false));
    };

  if (!loaded) {
    return <Loader />;
  }

  return (
    <main className="apc-react-body">
      <DocumentTitle title="Accounts | Appcues" />
      <ModalProvider>
        <CModal
          backgroundColor="gray"
          onClose={handleClose}
          subtitle="Switch between your accounts!"
          title="Your accounts"
          type="full"
        >
          <CModal.Content>
            <Flex
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
              marginTop={60}
            >
              {alphabetize(accounts).map(({ id, name }) => (
                <LegacyAccountSwitcher
                  key={id}
                  disabled={inFlight}
                  name={name || `Account ${id}`}
                  onClick={partial(observePromise(onSwitchAccount), id)}
                  selected={id === currentAccount}
                />
              ))}

              {hasCreateNewAccounts && (
                <ButtonGroup>
                  <CButton
                    onClick={observePromise(onAddAccount)}
                    disabled={inFlight}
                  >
                    Create new account
                  </CButton>
                </ButtonGroup>
              )}
            </Flex>
          </CModal.Content>
        </CModal>
      </ModalProvider>
    </main>
  );
};

Accounts.propTypes = {
  currentAccount: PropTypes.string,
  accounts: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  loaded: PropTypes.bool,
  onLoad: PropTypes.func,
  hasCreateNewAccounts: PropTypes.bool,
  onSwitchAccount: PropTypes.func,
  onAddAccount: PropTypes.func,
};

const mapStateToProps = state => ({
  currentAccount: selectAccountId(state),
  accounts: selectAccounts(state),
  loaded: selectAccountsSynced(state),
  hasCreateNewAccounts: selectAccountFeature(state, CREATE_NEW_ACCOUNTS),
});

const mapDispatchToProps = dispatch => ({
  onLoad: () => dispatch(getAccounts()),
  onSwitchAccount: id => asPromised(dispatch, requestSwitchAccount(id)),
  onAddAccount: () => asPromised(dispatch, requestCreateAccount()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
