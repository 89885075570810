import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const ProgressContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  margin: 5px;
  height: ${({ height }) => `${height}px`};
  border-radius: 4px;
  background-color: ${({ theme }) => theme['$gray-2']};
  position: relative;
  text-align: center;
  overflow: hidden;
  box-shadow: inset 0 1px 1px rgba(100, 100, 100, 0.1);
  flex-wrap: nowrap;
`;

const Bar = styled.span`
  height: 100%;
  overflow: hidden;
  width: ${({ width }) => `${width}%`};
  position: relative;
  background: ${({ theme, color }) => theme[color]};
`;

const ProgressBar = ({ values = [], height = 20, maxWidth = '100%' }) => {
  return (
    <ProgressContainer height={height} role="figure" maxWidth={maxWidth}>
      {values.map(({ metric, color }) =>
        metric > 0 ? (
          <Bar
            title={`${metric}%`}
            key={`${metric}-${color}`}
            width={metric}
            color={color}
          />
        ) : null
      )}
    </ProgressContainer>
  );
};

ProgressBar.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({ metric: PropTypes.number, color: PropTypes.string })
  ).isRequired,
  height: PropTypes.number,
  maxWidth: PropTypes.any,
};

export default ProgressBar;
