import React from 'react';
import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';

const Container = styled.div`
  width: 75px;
  height: 35px;
  min-height: 20px;
  cursor: pointer;
  border: 2px solid #e3e3e3;
  border-radius: 4px;
  height: 100%;
  display: flex;
`;

const BorderlessInput = styled.input`
  width: 70%;
  text-align: center;
  padding: 5px;
  font-size: 14px;
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
  border-right: 2px solid #e3e3e3;
`;

const ArrowsContainer = styled.div`
  width: 30%;
  text-align: center;
`;

const UpArrow = styled.div`
  height: 50%;
  border-bottom: 2px solid #e3e3e3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  color: #999;
`;

const DownArrow = styled.div`
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  color: #999;
`;

function NumberPicker({ className, onChange, value }) {
  const incrementValue = () => {
    onChange(value + 1);
  };

  const decrementValue = () => {
    onChange(value - 1);
  };

  return (
    <Container className={className}>
      <BorderlessInput
        type="number"
        value={value}
        onChange={event => onChange(event.target.value)}
      />
      <ArrowsContainer>
        <UpArrow onClick={incrementValue}>
          <Icon icon="caret-up" />
        </UpArrow>
        <DownArrow onClick={decrementValue}>
          <Icon icon="caret-down" />
        </DownArrow>
      </ArrowsContainer>
    </Container>
  );
}

export default styled(NumberPicker)`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
