/* global APPCUES_ECS_IMAGE_SERVICE_URL, IMAGE_SERVICE_LAMBDA_URL */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import { Icon } from '@studio/legacy-components';
import qs from 'qs';
import { IMAGE_SERVICE_LAMBDA } from 'next/entities/features';
import Loader from 'components/Common/Loader';

import { selectAccountId, selectAccountUuid } from 'reducers/account/meta';
import { selectAccountFeature } from 'reducers/account/features';

// eslint-disable-next-line no-restricted-imports
import authClient from 'helpers/auth-client';

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 125px;
  overflow: hidden;
  background-color: #d6dbe7;
  color: var(--text-tertiary);
  width: calc(100% + 2rem);
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: 16px;
  margin-top: 8px;
  padding: 1rem;
`;

const Image = styled.img`
  height: 100%;
  width: auto;
`;

export const ThemeImage = ({ content, theme, visible }) => {
  const [themeImgReq, setThemeImgReq] = useState(false);
  const [themeImg, setThemeImg] = useState(null);
  const [themeImgErr, setThemeImgErr] = useState(null);

  const accountId = useSelector(selectAccountId);
  const apiKey = useSelector(selectAccountUuid);
  const isLambdaServiceEnabled = useSelector(state =>
    selectAccountFeature(state, IMAGE_SERVICE_LAMBDA)
  );

  useEffect(() => {
    async function loadImage(bearerToken) {
      const imageResponse = await fetch(
        `${APPCUES_ECS_IMAGE_SERVICE_URL}/generate`,
        {
          method: 'POST',
          headers: {
            'Content-Type': `application/json`,
            Authorization: `Bearer ${bearerToken}`,
          },
          body: JSON.stringify({
            apiKey,
            accountId,
            contentType: 'flow',
            size: 'small',
            content,
            style: theme,
          }),
        }
      );

      if (!imageResponse.ok) {
        throw new Error('Failed to load theme image');
      }

      return imageResponse.blob();
    }

    async function loadLambdaImage(bearerToken) {
      const queryParams = {
        accountId,
        themeId: theme.id,
      };
      const imageResponse = await fetch(
        `${IMAGE_SERVICE_LAMBDA_URL}/?${qs.stringify(queryParams)}`,
        {
          headers: {
            'Content-Type': `application/json`,
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );

      if (!imageResponse.ok) {
        throw new Error('Failed to load theme image');
      }

      return imageResponse.blob();
    }

    const getImage = async () => {
      try {
        const bearerToken = await authClient.getToken();
        const image = await (isLambdaServiceEnabled
          ? loadLambdaImage(bearerToken)
          : loadImage(bearerToken));

        setThemeImg(URL.createObjectURL(image));
        setThemeImgErr(null);
      } catch (error) {
        setThemeImgErr(error);
      }
    };

    if (visible && (!themeImgReq || themeImgErr)) {
      setThemeImgReq(true);

      getImage();
    }
  }, [theme, isLambdaServiceEnabled, visible]);

  return (
    <ImageContainer>
      {themeImg ? (
        <Image src={themeImg} alt="Theme image" />
      ) : themeImgErr ? (
        <Icon icon="camera-retro" title="Theme image failed to load" />
      ) : (
        <Loader width="1em" height="1em" />
      )}
    </ImageContainer>
  );
};

ThemeImage.propTypes = {
  content: PropTypes.object,
  theme: PropTypes.object,
  visible: PropTypes.bool,
};

const ThemeImageWithIntersection = props => {
  const [ref, inView] = useInView({ threshold: 0.25 });
  return (
    <div ref={ref}>
      <ThemeImage visible={inView} {...props} />
    </div>
  );
};

export default ThemeImageWithIntersection;
