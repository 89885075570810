import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Label, Select } from '@studio/legacy-components';
import { SelectOptionsShape } from 'next/lib/shapes';
import { selectTemplateOptions } from 'next/lib/selectors-options';

import { TemplateOption, TemplateSingleValue } from './TemplateOption';

export const TemplatesField = ({ onChange, value, options }) => (
  <Label>
    Use a template
    <Select
      kind="default"
      name="template"
      isClearable
      options={options}
      autoFocus={false}
      components={{
        Option: TemplateOption,
        SingleValue: TemplateSingleValue,
      }}
      placeholder="Select Template (Optional)"
      portal
      onChange={option => onChange(option)}
      value={options.find(option => option.value === value)}
    />
  </Label>
);

TemplatesField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  options: SelectOptionsShape,
};

const mapStateToProps = state => ({
  options: selectTemplateOptions(state),
});

export default connect(mapStateToProps)(TemplatesField);
