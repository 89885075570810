import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Menu, MenuContent, MenuTrigger } from '@studio/legacy-components';
import { FilterViewDefault } from './FilterViewDefault';
import { OpenerWrapper, Badge, Opener } from './styled';

import FiltersShape from './FiltersShape';

/**
 * NOTE: We must spread props to trigger component when using asChild:
 *       https://github.com/radix-ui/primitives/issues/725
 */
const OpenButton = forwardRef(
  ({ enabledFiltersCount, ...unhandledProps }, forwardedRef) => (
    <OpenerWrapper>
      {enabledFiltersCount > 0 && (
        <Badge
          aria-label={`Number of selected filters: ${enabledFiltersCount}`}
        >
          {enabledFiltersCount}
        </Badge>
      )}
      <Opener
        aria-label="Open filter menu"
        icon="sliders-h"
        ref={forwardedRef}
        kind="secondary"
        {...unhandledProps}
      />
    </OpenerWrapper>
  )
);

OpenButton.propTypes = {
  icon: PropTypes.string,
  small: PropTypes.bool,
  enabledFiltersCount: PropTypes.number,
};

const Filter = ({
  align = 'start',
  side = 'bottom',
  filters = {},
  onClear,
  onChange,
  view: View = FilterViewDefault,
}) => {
  const enabledFiltersCount = Object.values(filters)
    .flatMap(filter => Object.values(filter?.options))
    .reduce((acc, filter) => (filter?.enabled ? acc + 1 : acc), 0);
  return (
    <Menu>
      <MenuTrigger asChild>
        <OpenButton enabledFiltersCount={enabledFiltersCount} />
      </MenuTrigger>
      <MenuContent align={align} side={side} sideOffset={12}>
        <View
          enabledFiltersCount={enabledFiltersCount}
          filters={filters}
          onClear={onClear}
          onChange={onChange}
        />
      </MenuContent>
    </Menu>
  );
};

Filter.propTypes = {
  align: PropTypes.oneOf(['start', 'center', 'end']),
  side: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  filters: FiltersShape,
  onClear: PropTypes.func,
  onChange: PropTypes.func,
  view: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default styled(Filter)``;
