import { createLifecycleFor } from 'actions/account/collections';
import { ACCOUNTS } from 'constants/data-types';

export const {
  callApi: getAccounts,
  send,
  resolve,
  reject,
  patterns,
} = createLifecycleFor(ACCOUNTS);

export const ACCOUNT_CREATE_REQUESTED = 'ACCOUNT_CREATE_REQUESTED';

export const requestCreateAccount = () => ({
  type: ACCOUNT_CREATE_REQUESTED,
});

export const ACCOUNT_CREATED = 'ACCOUNT_CREATED';

export const accountCreated = id => ({
  type: ACCOUNT_CREATED,
  payload: { id },
});

export const ACCOUNT_SWITCH_REQUESTED = 'ACCOUNT_SWITCH_REQUESTED';

export const requestSwitchAccount = id => ({
  type: ACCOUNT_SWITCH_REQUESTED,
  payload: { id },
});

export const ACCOUNT_SWITCHED = 'ACCOUNT_SWITCHED';

export const accountSwitched = () => ({
  type: ACCOUNT_SWITCHED,
});
