const SPECIAL_CHARS_REGEXP = /[$()*+.?[\\\]^{|}-]/g;
/**
 * Prevents users from defining possibly invalid regular expressions by effectively
 * blocking the use of RegExp lexical tokens
 *
 * @param string
 * @returns {*}
 */

export function escapeRegExp(string = '') {
  return string.replace(SPECIAL_CHARS_REGEXP, '\\$&');
}
