import React from 'react';
import styled from 'styled-components';
import { H2, Text, CBanner } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';

const Checkmark = styled(Icon)`
  margin-right: 14px;
  color: rgb(1, 165, 106);
`;

const DashboardOverview = ({ header }) => (
  <>
    <H2 type="secondary">{header}</H2>
    <CBanner type="positive" marginY={12}>
      <Checkmark icon="check-circle" title="Success" />
      <Text>Appcues is installed</Text>
    </CBanner>
  </>
);

export default DashboardOverview;
