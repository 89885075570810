import * as actionTypes from 'constants/view/actionTypes';
import { USER_REPLACED } from 'actions/user';
import { FLOW_LAYOUT, SORT_TYPES } from 'constants/view';

const initialState = {
  userId: null,
  flows: {
    flowLayout: FLOW_LAYOUT.GRID,
    filters: {
      status: null,
      segment: null,
      createdBy: null,
      search: null,
      tags: [],
    },
    sort: SORT_TYPES.RECENT_UPDATED,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case USER_REPLACED:
      return {
        ...state,
        userId: action.payload.id,
      };

    case actionTypes.REPLACE_ACCOUNT_VIEWS: {
      const view = action.payload && action.payload[state.userId];
      if (view) {
        const updatedFilters =
          view.flows && view.flows.filters ? view.flows.filters : {};
        const { status, segment, createdBy, search } = updatedFilters;

        // Tags were previously stored as objects. If we see them, let's convert
        // them to arrays.
        let { tags } = updatedFilters;
        if (updatedFilters.tags && !Array.isArray(updatedFilters.tags)) {
          tags = Object.keys(updatedFilters.tags);
        }

        const updatedView = {
          flows: {
            flowLayout:
              (view.flows && view.flows.flowLayout) || FLOW_LAYOUT.GRID,
            filters: {
              status: status || null,
              segment: segment || null,
              createdBy: createdBy || null,
              search: search || null,
              tags: tags || updatedFilters.category || [],
            },
            sort: (view.flows && view.flows.sort) || SORT_TYPES.RECENT_UPDATED,
          },
          userId: view.userId || state.userId,
        };
        if (view.isNewFlowListOn !== undefined) {
          updatedView.isNewFlowListOn = view.isNewFlowListOn;
        }

        return {
          ...state,
          ...updatedView,
        };
      }

      return state;
    }

    case actionTypes.UPDATE_VIEW_IN_STORE: {
      const { changes } = action.payload;
      const { flowLayout, filters, sort } = changes || {};
      return {
        ...state,
        flows: {
          flowLayout: flowLayout || state.flows.flowLayout,
          filters: {
            ...state.flows.filters,
            ...filters,
          },
          sort: sort || state.flows.sort || null,
        },
      };
    }

    case actionTypes.SET_DEFAULT_VIEW:
      return initialState;

    default:
      return state;
  }
}
