import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Shape as ScreenshotShape,
  ExperienceScreenshotShape,
} from 'next/entities/screenshots';

import StepScreenshot from './StepScreenshot';
import ExperienceScreenshot from './ExperienceScreenshot';

const Switch = unhandledProps => {
  const { step } = unhandledProps;
  const { type } = step ?? {};

  const Component = type === 'flows' ? StepScreenshot : ExperienceScreenshot;

  return <Component {...unhandledProps} />;
};

Switch.propTypes = {
  step: PropTypes.oneOfType([ScreenshotShape, ExperienceScreenshotShape]),
};

export default styled(Switch)``;
