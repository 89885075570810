import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  getFlattenedClausesFromNestedConditions,
  getConditionsFriendlyDescription,
} from '@appcues/libcues';

const CriteriaClauses = styled.div`
  line-height: 1.5em;
  border-left: 4px solid ${props => props.theme['$gray-2']};

  .group {
    display: block;
    padding-left: 1.5em;
  }

  .condition {
    display: block;
  }

  .logic {
    display: block;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: 800;
    font-size: 0.675em;
    color: ${props => props.theme['$gray-5']};
    position: relative;
    top: 3px;
  }
  .logic.and {
    color: ${props => props.theme.$cutecumber};
  }
  .logic.or {
    color: ${props => props.theme['$yellow-dark']};
  }
`;

export default function HumanReadableCriteria({
  conditions = {},
  clauses: initial,
  steps,
  segments,
  checklists,
}) {
  const clauses =
    initial || getFlattenedClausesFromNestedConditions(conditions);

  const humanReadableClauses =
    clauses && segments && steps
      ? {
          __html: getConditionsFriendlyDescription(
            null,
            clauses,
            steps,
            segments,
            checklists
          ),
        }
      : { __html: '' };

  return <CriteriaClauses dangerouslySetInnerHTML={humanReadableClauses} />;
}

HumanReadableCriteria.propTypes = {
  conditions: PropTypes.object,
  steps: PropTypes.object.isRequired,
  segments: PropTypes.object.isRequired,
  checklists: PropTypes.object.isRequired,
};
