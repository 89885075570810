import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tr, Td, H4, Switch } from '@appcues/component-library';
import moment from 'moment';
import { Icon } from '@studio/legacy-components';
import Input from 'components/Common/Forms/Input';
import Textarea from 'components/Common/Forms/Textarea';
import { AUTO_PROFILE_ATTRIBUTES } from 'constants/profileAttributes';
import {
  getOptGroup,
  titleCase,
  setDataType,
  getPropertyDisplayName,
  formatDate,
} from 'utils';
import { isAutoProperty } from 'utils/events-properties';
import { upsert } from 'entities/profile-attribute-labels';

const MaxWidthColumn = styled.span`
  max-width: 300px;
  position: relative;
  display: block;
`;

const AutoProperty = styled.span`
  width: 244px;
  margin: 0 16px 0 0;
`;

const StyledAttributeRow = styled(Tr)`
  border-color: ${props => (props.isExpanded ? 'transparent' : null)};
`;

const ResizableTextArea = styled(Textarea)`
  resize: vertical;
  margin-right: 16px;
  width: 100%;
  min-height: 100px;
`;

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  li:not(:first-of-type) {
    padding-top: 4px;
  }

  li:not(:last-of-type) {
    padding-bottom: 8px;
    border-bottom: 1px solid ${props => props.theme['$gray-1']};
  }
`;

const AttributeTd = styled(Td)`
  vertical-align: center;
  padding: 16px;
  word-wrap: break-word;

  &:first-child {
    padding-left: 32px;
  }
  &:last-child {
    padding-right: 32px;
  }
`;

const PenultimateCol = styled(AttributeTd)`
  span {
    min-width: 200px;
  }
`;

const ExpandRowToggle = styled(AttributeTd)`
  cursor: pointer;
`;

const ToggleIcon = styled(Icon)`
  min-width: 15px;
`;

const SecondaryTd = styled(Td)`
  vertical-align: top;
  padding: 8px 16px 16px;

  &:first-of-type {
    padding-left: 8;
  }
`;

const ColPlaceholder = styled(SecondaryTd)``;

const FlexColumn = styled.div`
  padding: 8px 0;
`;

// FIXME: Unused method
// eslint-disable-next-line no-unused-vars
const setPropertyType = name => {
  const optGroup = getOptGroup(name);

  if (optGroup === 'normal') {
    return 'Custom Property';
  }
  return `Appcues ${titleCase(optGroup)} Property`;
};

export class PropertyRow extends Component {
  constructor(props) {
    super(props);
    const { attribute } = props;
    const values = attribute.values || [];

    this.state = {
      isExpanded: false,
      isAutoProperty: isAutoProperty(attribute),
      // FIXME: Undefined state property
      // propertyType: setPropertyType(attribute.name),
      dataType: setDataType(values),
    };

    this.toggleExpandedRow = this.toggleExpandedRow.bind(this);
  }

  handleDescriptionBlur = ({ target: { value: description } }) => {
    const {
      attribute: { name },
      onChange,
    } = this.props;

    onChange({ name, description });
  };

  handleLabelBlur = ({ target: { value: label } }) => {
    const {
      attribute: { name },
      onChange,
    } = this.props;

    onChange({ name, label });
  };

  handleChangeShowInUI = showInUI => {
    const {
      attribute: { name },
      onChange,
    } = this.props;
    onChange({ name, showInUI });
  };

  toggleExpandedRow() {
    const { isExpanded } = this.state;

    this.setState({
      isExpanded: !isExpanded,
    });
  }

  render() {
    const { attribute } = this.props;
    const { isExpanded, dataType, isAutoProperty: isAuto } = this.state;

    return [
      <StyledAttributeRow
        key={`${attribute.name}-first`}
        isExpanded={isExpanded}
        className="property-row"
      >
        <ExpandRowToggle onClick={this.toggleExpandedRow}>
          <ToggleIcon icon={`chevron-${isExpanded ? 'down' : 'right'}`} />
        </ExpandRowToggle>
        <AttributeTd>
          {isAuto ? (
            <AutoProperty>
              {getPropertyDisplayName(attribute.name)}
            </AutoProperty>
          ) : (
            <Input
              style={{
                width: 260,
              }}
              onBlur={this.handleLabelBlur}
              defaultValue={attribute.label}
              placeholder={getPropertyDisplayName(attribute.name)}
            />
          )}
        </AttributeTd>
        <AttributeTd
          style={{ cursor: 'pointer' }}
          onClick={this.toggleExpandedRow}
        >
          <MaxWidthColumn>{attribute.name}</MaxWidthColumn>
        </AttributeTd>
        <PenultimateCol>
          <span>{moment(new Date(attribute.lastSeenTs)).format('LL')}</span>
        </PenultimateCol>
        <AttributeTd>
          <Switch
            checked={attribute.showInUI}
            onChange={this.handleChangeShowInUI}
          />
        </AttributeTd>
      </StyledAttributeRow>,
      isExpanded && (
        <StyledAttributeRow
          key={`${attribute.name}-second`}
          className="property-row-secondary"
        >
          <ColPlaceholder />
          <SecondaryTd>
            <FlexColumn>
              <H4 type="secondary">Description</H4>
              {isAuto ? (
                <AutoProperty>
                  {AUTO_PROFILE_ATTRIBUTES[attribute.name]
                    ? AUTO_PROFILE_ATTRIBUTES[attribute.name].description
                    : 'No description available.'}
                </AutoProperty>
              ) : (
                <ResizableTextArea
                  id={`${attribute.name}-description`}
                  placeholder="Enter description"
                  defaultValue={attribute.description}
                  onBlur={this.handleDescriptionBlur}
                />
              )}
            </FlexColumn>
          </SecondaryTd>
          <SecondaryTd>
            <FlexColumn>
              <H4 type="secondary">Data Type</H4>
              <p>{dataType}</p>
            </FlexColumn>
          </SecondaryTd>
          <SecondaryTd colSpan="2">
            <FlexColumn>
              <H4 type="secondary">Example Values</H4>
              <Ul>
                {attribute.values &&
                  attribute.values.map((value, key) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={key}>
                      {dataType === 'Date' && !Number.isNaN(Number(value))
                        ? formatDate(value)
                        : String(value)}
                    </li>
                  ))}
                {(!attribute.values || attribute.values.length === 0) && (
                  <li>No Values Found</li>
                )}
              </Ul>
            </FlexColumn>
          </SecondaryTd>
        </StyledAttributeRow>
      ),
    ];
  }
}

PropertyRow.propTypes = {
  attribute: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    lastSeenTs: PropTypes.number,
    showInUI: PropTypes.bool,
    values: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func,
};

const mapDispatchToProps = {
  onChange: upsert,
};

export default connect(null, mapDispatchToProps)(PropertyRow);
