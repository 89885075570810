import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@studio/legacy-components';
import { Screenshot } from 'next/components/Screenshot';

import { StepShape } from 'next/entities/experiences';
import { ContentStateShape } from 'next/lib/shapes';
import { openExperience } from 'next/lib/builder';
import { PinLabel, PinStep, Carousel } from './styled';

const StepsCarousel = ({
  experienceId,
  state,
  version,
  steps = [],
  showLabelIcon = false,
  previewUrl,
  type = 'pins',
}) => {
  const handleOpenInBuilder = () => {
    if (!previewUrl) return;

    openExperience({
      experience: experienceId,
      url: previewUrl,
      pattern: type,
    });
  };
  const experienceSteps =
    type === 'mobile' ? steps.flatMap(({ children }) => children) : steps;

  return (
    <Carousel
      items={experienceSteps.map((step, stepIndex) => {
        const title =
          step.name ?? `${type === 'pins' ? 'Pin' : 'Step'} ${stepIndex + 1}`;

        return {
          title: `${title} - ${step.id}`,
          element: (
            <PinStep
              onClick={handleOpenInBuilder}
              role={previewUrl ? 'button' : undefined}
            >
              <Screenshot
                step={{
                  type,
                  id: experienceId,
                  stepId: step.id,
                }}
                state={state}
                version={version}
              />
              <PinLabel>
                {showLabelIcon && (
                  <Icon icon="thumbtack" data-testid="label-icon" />
                )}
                {title}
              </PinLabel>
            </PinStep>
          ),
        };
      })}
    />
  );
};

StepsCarousel.propTypes = {
  experienceId: PropTypes.string,
  state: ContentStateShape,
  version: PropTypes.number,
  steps: PropTypes.arrayOf(StepShape),
  showLabelIcon: PropTypes.bool,
  previewUrl: PropTypes.string,
  type: PropTypes.oneOf(['pins', 'mobile', 'banner']),
};

export default StepsCarousel;
