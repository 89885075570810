import {
  call,
  getContext,
  put,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';
import { takeEveryPromised, takeLeadingPromised } from 'next/lib/effects';
import { FLOW_TEMPLATE } from 'next/entities/templates';
import { replacePagePatternFor } from 'next/entities/page';
import toast from 'next/lib/toast';
import {
  TRANSLATIONS_DOWNLOADED,
  TRANSLATIONS_REMOVED,
} from 'next/entities/translations';
import {
  flush,
  patterns,
  prune,
  reject,
  resolve,
  replace,
  readOne,
  insert,
  FLOW_CLONED,
  FLOW_ARCHIVED,
  FLOW_UNARCHIVED,
  FLOW_PUBLISH_REQUESTED,
  flowPublished,
  FLOW_PUBLISHED,
  FLOW_UNPUBLISHED,
  FLOW_UNPUBLISH_REQUESTED,
  flowUnpublished,
  FLOW_REVERT_REQUESTED,
  flowReverted,
  FLOW_TEST_MODE,
  FLOW_REVERTED,
} from './actions';
import { parse } from './shape';

/**
 * Normalize flows summary response as collection
 *
 * @param {Response} response - Raw flows summary response
 * @return {Collection} Flows collection
 */
const transform = response =>
  response.reduce((acc, { flow }) => {
    acc[flow.id] = parse(flow);
    return acc;
  }, {});

/**
 * Create steps for flow with selected templates the current platform api
 * implementation we don't have a way to create flow with steps in one go
 * so we create steps group first then steps based on flow template.
 * @param {string} flowId - Flow ID
 * @param {string} template - Name of template
 * @returns Promise
 */
function* createTemplateSteps(flowId, template) {
  const api = yield getContext('api');
  const { steps: stepGroups } = FLOW_TEMPLATE[template];
  const groups = Object.values(stepGroups);

  return Promise.all(
    groups.map(
      async ({ steps, hotspots, stepGroupType, ...stepGroup }, index) => {
        const [firstStepChild, ...restStepChildren] = Object.values(
          steps || hotspots
        );

        const {
          stepGroups: [
            {
              stepGroup: { id: stepGroupId },
            },
          ],
        } = await api.createStepGroups(flowId, {
          stepGroups: [
            {
              stepGroup: {
                ...stepGroup,
                [steps ? 'steps' : 'hotspots']: [],
                parentId: flowId,
                index,
              },
              stepChild: firstStepChild,
              stepGroupTypePathParam: stepGroupType,
            },
          ],
          index,
        });
        /**
         * Step Children must be created sequentially lest the
         * customer-api will reject them, so we must
         * block between each call
         */

        restStepChildren.reduce(
          (prev, stepChild) =>
            /**
             * Step Children must be created sequentially lest the
             * flow-builder-api will reject them, so we must
             * block between each call
             */
            prev.then(() =>
              api.createStepChild({
                id: flowId,
                type: stepGroupType,
                groupId: stepGroupId,
                child: stepChild,
              })
            ),
          Promise.resolve()
        );
      }
    )
  );
}

function* fetchFlows() {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getFlows);
    const transformed = transform(response);
    yield put(resolve(transformed));
    return transformed;
  } catch (error) {
    yield put(reject(error));
    return null;
  }
}

function* fetchFlow({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    const { flow } = yield call(api.getFlow, id);
    yield put(replace(flow));
  } catch (error) {
    yield put(reject(error));
  }
}

function* createFlow({ payload }) {
  try {
    const api = yield getContext('api');
    const { id: omit, template, ...newFlow } = payload;
    const { flow } = yield call(api.createFlow, {
      ...newFlow,
      formatVersion: 2,
    });

    if (template) {
      yield call(createTemplateSteps, flow.id, template);
    }

    yield put(insert(flow));
    return flow;
  } catch (error) {
    yield put(reject(error));
    throw error;
  }
}

function* updateFlow({ payload: { id, delta } }) {
  try {
    const api = yield getContext('api');
    yield call(api.updateFlow, id, delta);

    yield put(flush(id));
  } catch (error) {
    yield put(reject(error));
  }
}

function* deleteFlow({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    yield call(api.deleteFlow, id);
    yield put(prune(id));
  } catch (error) {
    yield put(reject(error));
  }
}

function* cloneFlow({ payload: { id: baseId } }) {
  try {
    const api = yield getContext('api');
    const { new_content_id: id } = yield call(api.cloneFlow, baseId);

    yield put(readOne(id));
  } catch (error) {
    yield put(reject(error));
  }
}

function* archiveFlow({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    yield call(api.archiveFlow, id);
    yield put(flush(id));
  } catch (error) {
    yield put(reject(error));
  }
}

function* unarchiveFlow({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    yield call(api.unarchiveFlow, id);
    yield put(readOne(id));
  } catch (error) {
    yield put(reject(error));
  }
}

function* publishFlow({ payload: { id } }) {
  const api = yield getContext('api');
  yield call(api.publishFlow, id);
  yield put(flowPublished(id));
}

function* unpublishFlow({ payload: { id } }) {
  const api = yield getContext('api');
  yield call(api.unpublishFlow, id);
  yield put(flowUnpublished(id));
}

function* revertFlow({ payload: { id, version } }) {
  try {
    const api = yield getContext('api');
    yield call(api.revertFlow, id, version);
    yield put(flowReverted(id));
  } catch (error) {
    yield put(reject(error));
  }
}

function* showToast() {
  yield call(toast.success, 'Changes saved');
}

function* createFlowTest({ payload: { id } }) {
  try {
    const api = yield getContext('api');

    const { test_content_id: testFlowId } = yield call(api.createTestFlow, id);
    return testFlowId;
  } catch (error) {
    yield put(reject(error));
    throw error;
  }
}

export default function* saga() {
  // Internal actions
  yield takeEveryPromised(
    [patterns.read, patterns.flush, FLOW_PUBLISHED, FLOW_UNPUBLISHED],
    fetchFlows
  );
  yield takeEvery(patterns.readOne, fetchFlow);
  yield takeLeadingPromised(patterns.create, createFlow);
  yield takeLeading(patterns.remove, deleteFlow);
  yield takeLeading(patterns.update, updateFlow);
  yield takeLeading(FLOW_CLONED, cloneFlow);
  yield takeLeading(FLOW_ARCHIVED, archiveFlow);
  yield takeLeading(FLOW_UNARCHIVED, unarchiveFlow);
  yield takeEvery(patterns.flush, showToast);
  yield takeEveryPromised(FLOW_PUBLISH_REQUESTED, publishFlow);
  yield takeEveryPromised(FLOW_UNPUBLISH_REQUESTED, unpublishFlow);
  yield takeEveryPromised(FLOW_REVERT_REQUESTED, revertFlow);
  yield takeEvery(
    [TRANSLATIONS_DOWNLOADED, TRANSLATIONS_REMOVED, FLOW_REVERTED],
    fetchFlow
  );
  yield takeEveryPromised(FLOW_TEST_MODE, createFlowTest);

  // Page actions
  yield takeLeading(
    [replacePagePatternFor('/flows'), replacePagePatternFor('/web-install')],
    fetchFlows
  );

  yield takeLeading(
    [replacePagePatternFor('/flows/:flowId/settings')],
    fetchFlow
  );
}
