import React from 'react';
import { Notice, Button } from '@appcues/component-library';

const CHECKLIST_DOC_URL = 'https://docs.appcues.com/category/345-checklist';

export default function ChecklistDocLink({ className }) {
  const noticeActions = [
    <Button
      className="button-secondary"
      href={CHECKLIST_DOC_URL}
      target="_blank"
      key={1}
    >
      Read the docs
    </Button>,
  ];
  return (
    <Notice className={className} actions={noticeActions}>
      <b>Hello!</b> Welcome to Appcues Checklist. If you need help or ideas,
      check out the Checklist documentation.
    </Notice>
  );
}
