import React from 'react';
import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';
import {
  StepPreviewThumbnail,
  GenericStepPreview,
} from 'components/Common/StepThumbnail';
import Carousel from 'components/Common/Carousel';
import ImageServiceImage from 'components/Common/ImageServiceImage';
import {
  getSortedStepsById,
  getNumOfSteps,
  getStepTypeMetadata,
} from 'utils/steps';
import StepChildHover from 'components/Common/FlowCarousel/StepChildHover';

const buildVersionId = versionId =>
  versionId === 'all-time-unique' ? 'latest' : versionId;

const FlowCarousel = ({
  journey,
  allSteps,
  versionId,
  localeId,
  className,
  showHoverOptions,
  size,
  background,
  height,
}) => {
  const sortedStepGroups = getSortedStepsById(journey.steps);
  const items = Object.keys(sortedStepGroups).reduce(
    (allItems, stepGroupId) => {
      const step = allSteps[stepGroupId];
      const stepType = getStepTypeMetadata(step);
      const stepChildType = stepType.childrenKey;

      if (stepChildType) {
        const stepChildren = Object.values(
          getSortedStepsById(step[stepChildType])
        );
        const allChildren = stepChildren.map((stepChild, index) => {
          const stepChildVersionId = buildVersionId(versionId);
          return (
            <StepPreviewThumbnail
              key={stepChild.id}
              stepType={stepType}
              totalSteps={getNumOfSteps(step)}
              childIndex={index}
              onHoverComponent={
                showHoverOptions && (
                  <StepChildHover
                    stepGroupId={journey.id}
                    stepChildId={stepGroupId}
                  />
                )
              }
            >
              <ImageServiceImage
                localeId={localeId}
                flowId={journey.id}
                stepId={step.id}
                contentType={stepType.firebaseType}
                stepChildId={stepChild.id}
                size={size}
                versionId={stepChildVersionId}
                forceRegenerate={stepChildVersionId === 'latest'}
                alt="Flow Step Image"
              />
            </StepPreviewThumbnail>
          );
        });

        return [...allItems, ...allChildren];
      }

      return [
        ...allItems,
        <GenericStepPreview stepTypeId={stepType.id}>
          <div>
            <Icon className="apc-fa apc-fa-2x" icon={stepType.icon} />
            {step.params?.url ? (
              <PreviewUrl title={step.params.url}>{step.params.url}</PreviewUrl>
            ) : null}
          </div>
        </GenericStepPreview>,
      ];
    },
    []
  );

  return (
    <Carousel
      className={className}
      items={items}
      background={background}
      height={height}
    />
  );
};

const PreviewUrl = styled.div`
  padding: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9em;
  text-align: center;

  border-radius: 6px;
`;

FlowCarousel.defaultProps = {
  size: 'large',
  showHoverOptions: false,
  background: '$gray-0',
  height: '250px',
};

export default FlowCarousel;
