import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { NOTICE_TYPES } from '@appcues/component-library';
import { Button } from '@appcues/sonar';
import { selectHistoricalLimit } from 'selectors/timeframe-options';
import BaseModal from 'components/ModalContainer/BaseModal';
import ExportInputFields from 'components/ModalContainer/ExportModal/ExportInputFields';
import ExportModalStatusMessage from 'components/ModalContainer/ExportModal/ExportModalStatusMessage';
import ExportSuccessMessage from 'components/ModalContainer/ExportModal/ExportSuccessMessage';
import { exportModalMessages } from 'constants/modalMessages';
import { reportError } from 'helpers/error-reporting';
import { REQUESTED_CSV_EXPORT } from 'constants/events';
import { requestCsvExport } from 'helpers/csv-exports';
import { EXTRACTION_V2 } from 'constants/features';
import { selectAccountFeature } from 'reducers/account/features';

const ExportModalWrapper = styled.div`
  .modal-body {
    padding: 1.125rem 0 !important;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-regular);

  .button {
    display: inline-block;
  }

  .button-primary {
    margin-left: 16px;
  }
`;

export class ExportModal extends Component {
  /* eslint-disable react/destructuring-assignment */
  state = {
    email: this.props.email,
    formResponsesOnly: false,
    pending: false,
    statusText: null,
    status: NOTICE_TYPES.default,
    exportMode: 'basic',
  };
  /* eslint-enable react/destructuring-assignment */

  resetModal = () => {
    this.setState({
      status: NOTICE_TYPES.default,
      statusText: null,
    });
  };

  handleEmailChange = event => {
    this.setState({ email: event.target.value });
  };

  handleEnterPress = event => {
    if (event.key === 'Enter') {
      this.handleSubmit();
    }
  };

  handleExportModeChange = mode => {
    this.setState({ exportMode: mode });
  };

  handleCustomDateRangeSelect = (type, selectedOption) => {
    this.setState({ [type]: selectedOption });
  };

  handleDateChange = ({ start, end }) => {
    this.setState({
      startDate: moment(start),
      endDate: moment(end),
    });
  };

  handleSubmit = () => {
    const {
      accountId,
      actions: { trackEvent },
      exportType,
      exportId,
    } = this.props;
    const { email, exportMode } = this.state;

    if (this.validInput(email)) {
      trackEvent(REQUESTED_CSV_EXPORT, {
        accountId,
        to_email: email,
        [exportType]: exportId,
        type: exportMode,
        contentType: 'flow',
      });

      this.setState(
        {
          pending: true,
          status: NOTICE_TYPES.primary,
          statusText: 'Asking for your csv!',
        },
        this.callExtractionApi
      );
    } else {
      this.setState({
        statusText: 'Make sure you type in an email!',
        status: NOTICE_TYPES.warning,
      });
    }
  };

  validInput = input => {
    return input !== '';
  };

  /**
   * @todo the date normalization here could probably live in the
   * csv-exports helper
   */
  callExtractionApi() {
    const { accountId, exportId, exportType, uuid, useV2Extraction } =
      this.props;
    const { exportMode, email, endDate, startDate } = this.state;
    const csvType = exportType === 'checklist_id' ? 'checklist' : 'flow';

    const requestOpts = {
      accountApiKey: uuid,
      accountId,
      startDate: startDate.valueOf(),
      endDate: endDate.valueOf(),
      email,
      filter: {
        [exportType]: exportId,
      },
      basic: exportMode === 'basic',
      formResponsesOnly: exportMode === 'survey',
      useV2: useV2Extraction,
    };

    requestCsvExport(csvType, requestOpts).then(result => {
      if (result.error) {
        reportError(result.error);

        this.setState({
          pending: false,
          statusText:
            'There was a problem submitting your request, please try again later.',
          status: NOTICE_TYPES.warning,
        });
      } else {
        this.setState({
          pending: false,
          statusText: 'Awesome! Your CSV is on the way!',
          status: NOTICE_TYPES.success,
        });
      }
    });
  }

  render() {
    const {
      actions: { hideModal },
      createdAt,
      includeFormResponsesOption,
      limit,
      exportType,
    } = this.props;
    const {
      exportMode,
      email,
      formResponsesOnly,
      pending,
      status,
      statusText,
    } = this.state;

    const modalTitle =
      status === NOTICE_TYPES.success
        ? 'Analytics on the way!'
        : 'Export a CSV';

    return (
      <ExportModalWrapper>
        <BaseModal
          modalClass="export-modal"
          shouldHideButtonStyle
          hideOKButton
          title={modalTitle}
          {...this.props}
        >
          {status === NOTICE_TYPES.success ? (
            <ExportSuccessMessage
              email={email}
              resetModal={this.resetModal}
              hideModal={hideModal}
            />
          ) : (
            <div>
              <ExportInputFields
                createdAt={createdAt}
                exportMode={exportMode}
                exportType={
                  exportType === 'checklist_id' ? 'checklist' : 'flow'
                }
                email={email}
                formResponsesOnly={formResponsesOnly}
                handleEmailChange={this.handleEmailChange}
                handleEnterPress={this.handleEnterPress}
                handleExportModeChange={this.handleExportModeChange}
                includeFormResponsesOption={includeFormResponsesOption}
                limit={limit}
                onDateChange={this.handleDateChange}
              />
              <ExportModalStatusMessage status={status} statusText={statusText}>
                <span>{statusText}</span>
              </ExportModalStatusMessage>
              <ButtonWrapper>
                <Button variant="secondary" onClick={hideModal}>
                  Cancel
                </Button>
                <Button disabled={pending} onClick={this.handleSubmit}>
                  {exportModalMessages.buttonText}
                </Button>
              </ButtonWrapper>
            </div>
          )}
        </BaseModal>
      </ExportModalWrapper>
    );
  }
}

ExportModal.defaultProps = {
  includeFormResponsesOption: true,
};

const mapStateToProps = state => ({
  limit: selectHistoricalLimit(state),
  useV2Extraction: selectAccountFeature(state, EXTRACTION_V2),
});

export default connect(mapStateToProps)(ExportModal);
