export const RUBY = 'ruby';
export const HTML = 'html';
export const PYTHON = 'python';
export const PHP = 'PHP';

export const ANGULAR = 'angular';
export const REACT = 'react';
export const EMBER = 'ember';
export const VUE = 'vue';

export const SYNTAX_HIGHLIGHTING_CLASS = {
  [RUBY]: 'javascript',
  [HTML]: 'javascript',
  [PYTHON]: 'javascript',
  [PHP]: 'javascript',
  [ANGULAR]: 'javascript',
  [REACT]: 'javascript',
  [EMBER]: 'javascript',
  [VUE]: 'javascript',
};

export const frameworkIconClassNames = {
  angular: ['fab', 'angular'],
  react: ['fab', 'react'],
  python: ['fab', 'python'],
  ruby: 'gem',
  vue: ['fab', 'vuejs'],
  ember: ['fab', 'ember'],
  PHP: ['fab', 'php'],
  HTML: 'code',
};

const angularPageChange = `
  // For Angular 2 and above
  // You'll need to subscribe to the router's NavigationEnd event
  // Do this in the component where your app is bootstrapped

  import { Component } from '@angular/core';
  import { Router, NavigationEnd } from '@angular/router';

  @Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
  })
  export class AppComponent {
    title = 'app';

    constructor(router: Router) {
      router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          window.Appcues.page();
        }
      });
    }
  }
`;

const reactPageChange = `

  /*
   * React Router v3 and below
   */

    <Router onUpdate={()=>window.Appcues.page()}/>

  /*
   * React Router v4 and above
   */

    // In the \`componentDidUpdate\` of the component wrapping your entire app
    // usually called <App />

    function componentDidUpdate(prevProps) {
      // NOTE: in order to have access to this information, you will need
      // to wrap this component in the \`withRouter\` HOC

      const { location: { pathname } } = this.props;
      const previousLocation = prevProps.location.pathname;

      if (pathname !== previousLocation) {
        window.Appcues.page();
      }
    }

    // NOTE: There are several different methods of listening to this route change in
    // V4, but this one covers all edge cases not covered by other methods
    // and is the one endorsed in the React Router v4 migration guide
    // https://github.com/ReactTraining/react-router/issues/4278#issuecomment-299692502
`;

const emberPageChange = `
  // You'll need to call \`Appcues.page()\` once the URL has updated
  // This is done by watching the \`didTransition\` event when initializing
  // the router in your app's \`router.js\` file

  import EmberRouter from '@ember/routing/router';

  const Router = EmberRouter.extend({
    init(){
      this._super(...arguments);
      this.on('didTransition', () => {
        window.Appcues.page();
      })
    },
    location: config.locationType,
    rootURL: config.rootURL
  });
`;

const vuePageChange = `
  // You'll need to call \`Appcues.page()\` once the URL has updated
  // This is done by watching the route object for changes when initializing Vue

  new Vue({
    el: "#app",
    router,
    template: "<App/>",
    components: { App },
    watch: {
      "$route": () => {
        window.Appcues.page()
      }
    }
  })
`;

export const PAGE_CHANGE_SNIPPETS = {
  [ANGULAR]: angularPageChange,
  [REACT]: reactPageChange,
  [EMBER]: emberPageChange,
  [VUE]: vuePageChange,
};

const rubyIdentify = `
  window.Appcues.identify("<%= current_user.id %>", {

    // recommended (but optional) properties

    created_at: "<%= current_user.created_at %>", // Unix timestamp of user signup date
    purchased_at: "<%= current_account.purchased_at %>", // Unix timestamp of account purchase date
    plan_tier: "<%= current_user.plan_tier %>", // Current user’s plan tier
    role: "<%= current_user.role %>", // Current user’s role or permissions
    account_id: "<%= current_account.id %>", // Current user's account ID
    first_name: "<%= current_user.first_name %>",   // Current user's first name

    // additional suggestions

    company_name: "<%= current_user.company_name %>", // Current user’s company
    email: "<%= current_user.email %>", // Current user's email
    location: "<%= current_user.location %>", // a zipcode, state, or country enables location-based targeting
    version: "<%= current_user.version %>", // users on different versions may need to see different content
    language: "<%= current_user.language %>", // for multi-language applications
    renewal_date: "<%= current_user.renewal_date %>", // to remind users to renew
  });
`;

export const jsIdentify = `
  window.Appcues.identify(
    "<<< USER_ID >>>", // unique, required
    {

      // recommended (optional) properties

      createdAt: 1566932390, // Unix timestamp of user signup date
      purchasedAt: 1566932395, // Unix timestamp of account purchase date (leave null if empty)
      planTier: "Standard", // Current user’s plan tier
      role: "Admin", // Current user’s role or permissions
      accountId: "1234", // Current user's account ID
      firstName: "John", // current user's first name

      // additional suggestions

      companyName: "Acme Corp", // Current user’s company name
      email: "john.doe@example.com", // Current user's email
      location: "90210", // a zipcode, state, or country enables location-based targeting
      version: "2.0", // users on different versions may need to see different content
      language: "spanish", // for multi-language applications
      renewalDate: 1577880288 // to remind users to renew
    }
  );
`;

const pythonIdentify = `
  window.Appcues.identify("{{ current_user_id }}", {

    // recommended (but optional) properties

    created_at: "{{ current_user_created_at }}", // Unix timestamp of user signup date
    purchased_at: "{{ current_account_purchased_at }}", // Unix timestamp of account purchase date
    plan_tier: "{{ current_user_plan_tier }}", // Current user’s plan tier
    role: "{{ current_user_role }}", // Current user’s role or permissions
    account_id: "{{ current_account_id}}", // Current user's account ID
    first_name: "{{ current_user_first_name }}",   // Current user's first name

    // additional suggestions

    company_name: "{{ current_user_company_name }}", // Current user’s company
    email: "{{ current_user_email }}", // Current user's email
    location: "{{ current_user_location }}", // a zipcode, state, or country enables location-based targeting
    version: "{{ current_user_version }}", // users on different versions may need to see different content
    language: "{{ current_user_language }}", // for multi-language applications
    renewal_date: "{{ current_user_renewal_date }}" // to remind users to renew
  });
`;

const phpIdentify = `
  // This snippet uses the Blade templating engine included with Laravel

  window.Appcues.identify("<?= $current_user_id ?>", {

    // recommended (but optional) properties

    created_at: "<?= $current_user_created_at ?>",    // Unix timestamp of user signup date
    purchased_at: "<?= current_account_purchased_at ?>", // Unix timestamp of account purchase date
    plan_tier: "<?= $current_user_plan_tier ?>", // Current user’s plan tier
    role: "<?= $current_user_role ?>", // Current user’s role or permissions
    account_id: "<?= $current_account_id ?>", // Current user's account ID
    first_name: "<?= $current_user_first_name ?>",   // Current user's first name

    // additional suggestions

    company_name: "<?= $current_user_company_name ?>", // Current user’s company
    email: "<?= $current_user_email ?>", // Current user's email
    location: "<?= $current_user_location ?>", // a zipcode, state, or country enables location-based targeting
    version: "<?= $current_user_version ?>", // users on different versions may need to see different content
    language: "<?= $current_user_language ?>", // for multi-language applications
    renewal_date: "<?= $current_user_renewal_date ?>", // to remind users to renew
  });
`;

export const groupIdentify = `
window.Appcues.group(
  "<<< GROUP_ID >>>", // unique, required
  {
    // example properties
    createdAt: 1566932390, // Unix timestamp of account signup date
    purchasedAt: 1566932395, // Unix timestamp of account purchase date (leave null if empty)
    planTier: "Standard", // Account's plan tier
    companyName: "CoolCo", // Account's name
    MRR: 599, // Account's monthly recurring revenue
    renewalDate: "06202035", // US timestamp of upcoming account renewal date
    accountmanager: "Jerry" // Account manager
  });
`;

export const IDENTIFY_SNIPPETS = {
  [RUBY]: rubyIdentify,
  [HTML]: jsIdentify,
  [PYTHON]: pythonIdentify,
  [PHP]: phpIdentify,
  [ANGULAR]: jsIdentify,
  [REACT]: jsIdentify,
  [EMBER]: jsIdentify,
  [VUE]: jsIdentify,
};
