import styled from 'styled-components';
import {
  PageTitle,
  H2,
  PageDescription,
  PageHeader,
} from '@studio/legacy-components';

export const IdentifiersListSection = styled.div`
  display: flex;
`;

export const IdentifierListItem = styled.div`
  display: flex;
  margin-right: 15px;
`;

export const IdentifierName = styled.span`
  font-size: var(--regular);
  line-height: 150%;
`;

export const IdentifierValue = styled(IdentifierName)`
  font-weight: var(--normal);
  margin-left: 5px;
`;

export const IdentifierText = styled.span``;

export const ProfileTitle = styled(PageTitle)`
  width: 100%;

  ${H2} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`;

export const ResetFlowModalContent = styled.p``;

export const ResetFlowTriggerContainer = styled.div`
  text-wrap: nowrap;
`;

export const AudiencePageHeader = styled(PageHeader)`
  gap: var(--spacing-x-x-x-large);
`;

export const HeaderDescription = styled(PageDescription)`
  max-width: 75%;
`;
