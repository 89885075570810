import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Text, Button, Stack } from '@appcues/sonar';
import { faFileCsv } from '@fortawesome/pro-solid-svg-icons/faFileCsv';
import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload';
import { connect } from 'react-redux';
import useToggle from 'next/hooks/use-toggle';
import toast from 'next/lib/toast';
import { formatDate } from 'utils';
import { preSignSegmentCsv } from 'actions/account/segments';
import { asPromised } from 'utils/as-promised';
import { SegmentMetadataBox, FileInfo } from './styled';

/* Download CSV with anchor element in memory */
function downloadCSV(url, fileName) {
  const link = document.createElement('a');

  link.style.display = 'none';
  link.href = url;
  link.download = fileName;

  document.body.append(link);
  link.click();
  link.remove();
}

export const SegmentMetadata = ({
  fileName,
  uploadDate,
  usersProcessed,
  onRequestCsv,
}) => {
  const [isDownloading, toggleDownloading] = useToggle();

  /* Prevent UI from rendering if the segment doesn't have a file */
  if (!fileName) return null;

  const parsedUploadDate = new Date(uploadDate);

  const onDownloadCsv = async () => {
    try {
      toggleDownloading();
      const { url } = await onRequestCsv();
      downloadCSV(url, fileName);
    } catch {
      toast.error('Something went wrong.');
    } finally {
      toggleDownloading();
    }
  };

  return (
    <Stack spacing="small">
      <Text weight="bold" colorToken="foreground-primary">
        This segment was originally created with conditions generated upon file
        upload:
      </Text>
      <SegmentMetadataBox>
        <Icon
          icon={faFileCsv}
          size="large"
          colorToken="foreground-input-placeholder"
        />
        <FileInfo>
          <Text weight="bold">{fileName}</Text>
          <Text>
            {formatDate(parsedUploadDate.getTime(), 'MM/DD/YY hh:mma')}
          </Text>
        </FileInfo>
        <Text>{usersProcessed} rows</Text>
        <Button
          variant="tertiary"
          onClick={onDownloadCsv}
          disabled={isDownloading}
        >
          <Icon
            icon={faDownload}
            size="regular"
            colorToken="foreground-button-tertiary-default"
          />
        </Button>
      </SegmentMetadataBox>
    </Stack>
  );
};

SegmentMetadata.propTypes = {
  segmentId: PropTypes.string,
  fileName: PropTypes.string,
  uploadDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  usersProcessed: PropTypes.number,
  onRequestCsv: PropTypes.func,
};

const mapDispatchToProps = (dispatch, { segmentId }) => ({
  onRequestCsv: () => asPromised(dispatch, preSignSegmentCsv(segmentId)),
});

export default connect(null, mapDispatchToProps)(SegmentMetadata);
