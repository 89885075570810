import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Thead, Tbody, Tr } from '@studio/legacy-components';

import { sortByKey } from 'utils';

import { selectValidChecklists } from 'entities/checklists';

import QueryResults from 'components/Common/QueryResults';
import Pagination, {
  PaginationMessage,
} from 'components/Common/UI/Pagination/Pagination';

import TableOverlay from 'components/Insights/Explorer/Common/TableOverlay';
import {
  ClearEvents,
  ControlHeader,
  EventNameHeader,
  PaginationWrapper,
  ResultsWrapper,
  SelectHeader,
  StatsHeader,
  StatusHeader,
  TableWrapper,
} from 'components/Insights/Common/Styled';
import {
  AddEventTooltip,
  TitleTooltip,
} from 'components/Insights/Explorer/Common/SourceTooltips';

import useClientsideTable from 'hooks/useClientsideTable';

import {
  CHECKLIST_EVENTS,
  CHECKLIST_EVENTS_LABEL,
} from 'components/Insights/constants';
import ChecklistRow from './ChecklistRow';

/**
 * Given an array of checklist objects and a result from
 * the Analytics API, returns a merged result-set
 *
 * @param checklists
 * @param results
 * @returns {[{internalName: string, id: string, published: boolean, most_recent_timestamp: number, totalUsers: number, totalEvents: number, metrics: array }]}
 */
export const mergeChecklistsAndResults = (checklists, results) =>
  (checklists || []).map(({ id, internalName, published }) => {
    const analytics = (results || []).filter(
      result => result.checklist_id === id
    );

    // generate metrics object per checklist event "type"
    const metrics = CHECKLIST_EVENTS.map(
      ({ type, label }) =>
        ({
          ...analytics.find(({ event }) => event === type),
          event: label,
          eventType: type,
        } || {
          event: label,
          eventType: type,
          events: 0,
          users: 0,
          most_recent_timestamp: 0,
        })
    );

    const { users: totalUsers } = analytics.find(
      ({ event }) => event === 'appcues:checklist_shown'
    ) || { users: 0 };

    const { most_recent_timestamp } = analytics.sort(
      sortByKey('most_recent_timestamp', 'desc')
    )[0] || {
      most_recent_timestamp: 0,
    };

    return {
      totalEvents: analytics.reduce((acc, { events }) => acc + events, 0),
      most_recent_timestamp,
      totalUsers,
      published,
      id,
      internalName,
      metrics,
    };
  });

export const ChecklistEvents = ({
  results,
  pageSize = 10,
  selectedEvents,
  enableSelect,
  searchTerm,
  onSelect,
  onClearEvents,
}) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const {
    isEmpty,
    currentIndex,
    orderByColumn,
    orderByDirection,
    results: pageChecklists,
    totalResults,
    handleSort,
    handleCurrentIndex,
  } = useClientsideTable({
    data: results,
    searchTerm,
    pageSize,
    defaultOrderColumn: 'totalEvents',
    defaultSortDirection: 'desc',
  });

  const handleExpandRow = clickedId => {
    if (expandedRow === clickedId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(clickedId);
    }
  };

  return (
    <>
      <ResultsWrapper>
        <TableOverlay
          isEmpty={isEmpty}
          results={results}
          type={CHECKLIST_EVENTS_LABEL}
        />
        <TableWrapper>
          <Table>
            <Thead>
              <Tr>
                {pageChecklists?.length > 0 ? (
                  <SelectHeader>
                    {selectedEvents?.some(evt => evt.source === 'checklist') ? (
                      <ClearEvents
                        onClick={() => {
                          onClearEvents('checklist');
                        }}
                      />
                    ) : (
                      <AddEventTooltip />
                    )}
                  </SelectHeader>
                ) : (
                  <ControlHeader />
                )}
                <EventNameHeader
                  sortable
                  sorted={
                    orderByColumn === 'internalName' ? orderByDirection : null
                  }
                  onClick={() => {
                    handleSort('internalName');
                  }}
                >
                  Checklist Name
                </EventNameHeader>
                <StatusHeader
                  sortable
                  sorted={
                    orderByColumn === 'published' ? orderByDirection : null
                  }
                  onClick={() => {
                    handleSort('published');
                  }}
                >
                  Status
                </StatusHeader>
                <StatsHeader
                  sortable
                  sorted={
                    orderByColumn === 'totalEvents' ? orderByDirection : null
                  }
                  onClick={() => {
                    handleSort('totalEvents');
                  }}
                >
                  <TitleTooltip
                    tip="The total number of times a checklist was started."
                    title="Total Events"
                  />
                </StatsHeader>
                <StatsHeader
                  sortable
                  sorted={
                    orderByColumn === 'totalUsers' ? orderByDirection : null
                  }
                  onClick={() => {
                    handleSort('totalUsers');
                  }}
                >
                  <TitleTooltip
                    tip="The number of unique users who triggered the event."
                    title="Unique Users"
                  />
                </StatsHeader>
                <StatsHeader
                  sortable
                  sorted={
                    orderByColumn === 'most_recent_timestamp'
                      ? orderByDirection
                      : null
                  }
                  onClick={() => {
                    handleSort('most_recent_timestamp');
                  }}
                >
                  Last Triggered
                </StatsHeader>
              </Tr>
            </Thead>
            <Tbody>
              {(pageChecklists || []).map(
                ({
                  internalName,
                  published,
                  totalUsers,
                  id,
                  most_recent_timestamp,
                  metrics,
                }) => (
                  <ChecklistRow
                    onSelect={onSelect}
                    onClick={handleExpandRow}
                    selectedEvents={selectedEvents}
                    enableSelect={enableSelect}
                    key={id}
                    totalUsers={totalUsers}
                    name={internalName}
                    published={published}
                    id={id}
                    metrics={metrics}
                    isExpanded={expandedRow === id}
                    most_recent_timestamp={most_recent_timestamp}
                  />
                )
              )}
            </Tbody>
          </Table>
        </TableWrapper>
      </ResultsWrapper>
      {pageChecklists && pageChecklists.length > 0 && (
        <PaginationWrapper>
          <Pagination
            pageCount={Math.ceil(totalResults / pageSize)}
            currentIndex={currentIndex}
            onChange={handleCurrentIndex}
          />
          <PaginationMessage
            currentIndex={currentIndex}
            pageSize={pageSize}
            total={totalResults}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

ChecklistEvents.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      most_recent_timestamp: PropTypes.number,
      id: PropTypes.string,
      totalUsers: PropTypes.number,
      totalEvents: PropTypes.number,
    })
  ),
  pageSize: PropTypes.number,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  enableSelect: PropTypes.bool,
  onSelect: PropTypes.func,
  searchTerm: PropTypes.string,
  onClearEvents: PropTypes.func,
};

export const mapStateToProps = (state, { searchTerm, results }) => {
  const checklists = selectValidChecklists(state);

  const filtered = checklists.filter(({ internalName }) =>
    internalName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return {
    results: results && mergeChecklistsAndResults(filtered, results),
  };
};

export const ConnectedChecklistEvents =
  connect(mapStateToProps)(ChecklistEvents);

export const ConnectedChecklistEventsWithAnalytics = ({
  startTime,
  endTime,
  searchTerm,
  segmentId,
  pageSize = 10,
  selectedEvents,
  enableSelect,
  onSelect,
  onClearEvents,
}) => (
  <QueryResults
    query={{
      conditions: [
        ['event', 'in', CHECKLIST_EVENTS.map(({ type }) => type)],
        ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
      ],
      dimensions: ['event', 'checklist_id'],
      metrics: ['most_recent_timestamp', 'events', 'users'],
      start_time: startTime,
      end_time: endTime,
      meta: {
        tags: {
          feature: 'Event analytics',
          page: 'Event explorer',
          component: 'ConnectedChecklistEventsWithAnalytics',
          description: 'Checklist event source',
        },
      },
    }}
  >
    {results => (
      <ConnectedChecklistEvents
        results={results}
        pageSize={pageSize}
        searchTerm={searchTerm}
        startTime={startTime}
        selectedEvents={selectedEvents}
        enableSelect={enableSelect}
        onSelect={onSelect}
        onClearEvents={onClearEvents}
      />
    )}
  </QueryResults>
);

ConnectedChecklistEventsWithAnalytics.propTypes = {
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  searchTerm: PropTypes.string,
  segmentId: PropTypes.string,
  pageSize: PropTypes.number,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  enableSelect: PropTypes.bool,
  onSelect: PropTypes.func,
  onClearEvents: PropTypes.func,
};

export default ConnectedChecklistEventsWithAnalytics;
