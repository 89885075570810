import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Page, PageBody } from '@studio/legacy-components';
import {
  ExperienceDetails,
  WebTriggering,
  UrlTargeting,
  AudiencePanel,
  LaunchpadConfiguration,
  GoalPanel,
  ActionsPanel,
  LocalizationPanel,
  PrioritizationPanel,
  PublishingBanner,
  ABTestingPanel,
} from '@studio/settings-panels';
import {
  ConditionsPropsShape,
  SelectOptionsShape,
  Shape,
} from '@studio/conditions';
import { readOne as readSuggestions } from 'next/entities/suggestions';
import {
  AB_TESTING,
  EVENT_ATTRIBUTE_TRIGGERING,
  EVENT_TRIGGERING,
  FLOW_PRIORITIZATION,
  LAUNCHPADS_V2,
  MOBILE_FLOWS,
  FLOW_LOCALIZATION,
  selectFeature,
} from 'next/entities/features';
import { GoalShape } from 'next/entities/goals';
import {
  Shape as FlowShape,
  flowTestMode,
  selectFlow,
  update as updateFlow,
} from 'next/entities/flows';
import { selectRule, update as updateRule } from 'next/entities/rules';
import { selectUser } from 'next/entities/user';
import { selectAccountUser, ROLES } from 'next/entities/account-users';
import { replace as replacePage } from 'next/entities/page';
import { drop as dropContentStatus } from 'next/entities/content-status';
import { create as createSegment } from 'next/entities/segments';
import {
  selectEventTargetingOptions,
  selectSegmentTargetingOptions,
  selectGoalOptions,
  selectFlowsOptions,
  selectLaunchpadTargetingOptions,
} from 'next/lib/selectors-options';
import { selectPublishedFlowRules } from 'next/entities/published-rules';
import useTitle from 'next/hooks/use-title';
import { selectAccount } from 'next/entities/account';
import { LocalesShape, selectLocales } from 'next/entities/locales';
import {
  requestTranslations,
  removeTranslations,
  selectTranslations,
  uploadTranslation,
  deleteTranslation,
} from 'next/entities/translations';
import { selectInstalled } from 'next/entities/installed';
import FlowStepsCarousel from 'next/components/FlowStepsCarousel';
import { selectConditionsOptions } from 'next/lib/selector-conditions-options';
import SettingsHeaderActions from './SettingsHeaderActions';
import { getFlowTargetingSections } from './get-flow-targeting-sections';
import { Loading } from './styled';
import FlowHeader from './FlowHeader';
import FlowFrequency from './FlowFrequency';
import FlowPublishingManager from './FlowPublishingManager';

export const SettingsPage = ({
  loaded,
  flow,
  rule,
  onLoad,
  onUnload,
  onFlowChange,
  onRuleChange,
  onAttributeChange,
  domains,
  installedDomains,
  conditionsOptions,
  eventOptions,
  hasEventTriggering,
  hasAttributeTriggering,
  segmentsOptions,
  onSegmentCreate,
  hasMobileFlows,
  hasLaunchpad,
  hasNoLaunchpadCreated,
  canPublish,
  accountId,
  goalOptions,
  goal,
  goalConversionWindowDays,
  flowOptions,
  locales,
  onTranslationsRequest,
  lockedForLocalization,
  onRemoveTranslations,
  translations,
  uploadTranslationFile,
  onTestFlowCreated,
  deleteTranslationFile,
  email,
  fullName,
  publishedFlowRules,
  flows,
  hasFlowPrioritization,
  hasAbTesting,
  hasLocalization,
}) => {
  useTitle(`${flow?.name} | Settings | Appcues`);

  useEffect(() => {
    onLoad();
    return () => onUnload();
  }, [onLoad, onUnload]);

  if (!loaded) {
    return <Loading />;
  }

  const hasLiveOrNotStartedFlow =
    flow.state === 'DRAFT' &&
    flow.experiment?.state &&
    ['NOT_STARTED', 'LIVE', 'PAUSED'].includes(
      flow.experiment.state.toUpperCase()
    );

  const sections = getFlowTargetingSections(rule.conditions);

  const handleSectionChange =
    section =>
    (newConditions, ruleChanges = {}) =>
      void onRuleChange({
        ...ruleChanges,
        conditions: {
          and: Object.values({
            ...sections,
            [section]: newConditions,
          }).filter(branch => branch && (branch.and || branch.or).length > 0),
        },
      });

  const handleWidgetFilterChange = widgetFilter => {
    onRuleChange({ widgetFilter });
  };

  const handlePriorityChange = sortPriority => {
    onRuleChange({ sortPriority });
  };

  return (
    <Page>
      <FlowHeader
        id={flow.id}
        currentPage="settings"
        renderActions={({ updatedAt, state: experienceState }) => {
          return (
            <SettingsHeaderActions
              accountId={accountId}
              id={flow.id}
              published={flow.published}
              updatedAt={updatedAt}
              state={experienceState}
              flow={flow}
              canPublish={canPublish}
              onTestFlowCreated={onTestFlowCreated}
              sections={sections}
              urlConditions={sections.url}
              domainConditions={sections.domains}
            />
          );
        }}
      />

      <PageBody>
        <FlowPublishingManager id={flow.id}>
          {PublishingBanner}
        </FlowPublishingManager>
        <ExperienceDetails
          name={flow.name}
          previewUrl={flow.previewUrl}
          onChange={onFlowChange}
          publicName={flow.publicName}
          namePlaceholder="Flow Name"
          nameAriaLabel="Flow Name"
          showPublicName={hasLaunchpad}
        />
        <WebTriggering
          conditions={sections.trigger}
          frequency={rule.frequency}
          eventOptions={eventOptions}
          hasEventTriggering={hasEventTriggering}
          hasAttributeTriggering={hasAttributeTriggering}
          onChange={handleSectionChange('trigger')}
        />
        <UrlTargeting
          domains={domains}
          installedDomains={installedDomains}
          urlConditions={sections.url}
          domainConditions={sections.domains}
          onUrlConditionChange={handleSectionChange('url')}
          onDomainConditionChange={handleSectionChange('domains')}
          conditionsOptions={conditionsOptions}
        />
        <AudiencePanel
          conditions={sections.audience}
          conditionsOptions={conditionsOptions}
          experienceName="Flow"
          hasMobileFlows={hasMobileFlows}
          onChange={handleSectionChange('audience')}
          onSegmentCreate={onSegmentCreate}
          segmentsOptions={segmentsOptions}
          onAttributeChange={onAttributeChange}
        />
        {hasAbTesting && (
          <ABTestingPanel
            onUpdateClause={handleSectionChange('ab')}
            conditions={sections.ab}
          />
        )}
        <LaunchpadConfiguration
          empty={hasNoLaunchpadCreated}
          widgetFilter={rule.widgetFilter}
          onChange={handleWidgetFilterChange}
        />
        <PrioritizationPanel
          email={email}
          fullName={fullName}
          flows={flows}
          onChange={handlePriorityChange}
          publishedRules={publishedFlowRules}
          rule={rule}
          showUpgradePanel={!hasFlowPrioritization}
        />
        <FlowFrequency id={flow.id} />
        <LocalizationPanel
          locales={locales}
          published={flow.published}
          onTranslationsRequest={onTranslationsRequest}
          lockedForLocalization={lockedForLocalization}
          onRemoveTranslations={onRemoveTranslations}
          translations={translations}
          onTranslationUpload={uploadTranslationFile}
          onDeleteTranslationFile={deleteTranslationFile}
          renderPreviewCarousel={({ localeId }) => (
            <FlowStepsCarousel flow={flow} localeId={localeId} force />
          )}
          showUpgradePanel={!hasLocalization}
        />

        <GoalPanel
          onChange={values => {
            onRuleChange({
              goals: [values.goal],
              goalConversionWindowDays: values.goalConversionWindowDays,
            });
          }}
          goal={goal}
          conversionWindowDays={goalConversionWindowDays}
          disableSetNoGoal={hasLiveOrNotStartedFlow}
          options={goalOptions}
        />
        <ActionsPanel onSave={onRuleChange} rule={rule} options={flowOptions} />
      </PageBody>
    </Page>
  );
};

SettingsPage.propTypes = {
  loaded: PropTypes.bool,
  flow: FlowShape,
  rule: Shape,
  onLoad: PropTypes.func,
  onUnload: PropTypes.func,
  onFlowChange: PropTypes.func,
  onRuleChange: PropTypes.func,
  domains: PropTypes.arrayOf(PropTypes.string),
  installedDomains: PropTypes.objectOf(
    PropTypes.shape({
      hostname: PropTypes.string,
    })
  ),
  conditionsOptions: ConditionsPropsShape,
  eventOptions: SelectOptionsShape,
  hasEventTriggering: PropTypes.bool,
  hasAttributeTriggering: PropTypes.bool,
  segmentsOptions: SelectOptionsShape,
  onSegmentCreate: PropTypes.func,
  hasMobileFlows: PropTypes.bool,
  hasLaunchpad: PropTypes.bool,
  hasNoLaunchpadCreated: PropTypes.bool,
  canPublish: PropTypes.bool,
  accountId: PropTypes.string,
  goalOptions: PropTypes.arrayOf(GoalShape),
  goal: PropTypes.string,
  goalConversionWindowDays: PropTypes.number,
  flowOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  locales: PropTypes.arrayOf(LocalesShape),
  onTranslationsRequest: PropTypes.func,
  lockedForLocalization: PropTypes.bool,
  onRemoveTranslations: PropTypes.func,
  onAttributeChange: PropTypes.func,
  translations: PropTypes.arrayOf(
    PropTypes.shape({
      locale_id: PropTypes.string,
      updated_at: PropTypes.number,
    })
  ),
  uploadTranslationFile: PropTypes.func,
  onTestFlowCreated: PropTypes.func,
  deleteTranslationFile: PropTypes.func,
  email: PropTypes.string,
  fullName: PropTypes.string,
  publishedFlowRules: PropTypes.objectOf(Shape),
  flows: PropTypes.objectOf(FlowShape),
  hasFlowPrioritization: PropTypes.bool,
  hasAbTesting: PropTypes.bool,
  hasLocalization: PropTypes.bool,
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { flowId },
    },
  }
) => {
  const flow = selectFlow(state, flowId);
  const rule = selectRule(state, flowId);
  const { id: userId, email, fullname: fullName } = selectUser(state);
  const accountUser = selectAccountUser(state, userId);
  const { domains = [], id: accountId } = selectAccount(state);

  const canPublish = accountUser?.roleId !== ROLES.EDITOR;
  const locales = Object.values(selectLocales(state) || {}).sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const flowOptions = selectFlowsOptions(state);
  const launchpadOptions = selectLaunchpadTargetingOptions(state);

  const hasLaunchpad = selectFeature(state, LAUNCHPADS_V2);
  const hasNoLaunchpadCreated = hasLaunchpad && launchpadOptions.length === 0;

  const flows = flowOptions?.reduce((acc, { label, value }) => {
    acc[value] = { name: label };
    return acc;
  }, {});

  return {
    segmentsOptions: selectSegmentTargetingOptions(state),
    hasMobileFlows: selectFeature(state, MOBILE_FLOWS),
    conditionsOptions: selectConditionsOptions(state),
    installedDomains: selectInstalled(state),
    domains,
    eventOptions: selectEventTargetingOptions(state),
    hasEventTriggering: selectFeature(state, EVENT_TRIGGERING),
    hasAttributeTriggering: selectFeature(state, EVENT_ATTRIBUTE_TRIGGERING),
    goalOptions: selectGoalOptions(state) || [],
    goal: rule?.goals?.[0],
    goalConversionWindowDays: rule?.goalConversionWindowDays,
    hasLaunchpad,
    hasNoLaunchpadCreated,
    id: flowId,
    loaded: Boolean(flow && rule && accountUser),
    flow,
    rule,
    canPublish,
    accountId,
    flowOptions,
    locales,
    lockedForLocalization: flow?.lockedForLocalization,
    translations: selectTranslations(state) || {},
    email,
    fullName,
    publishedFlowRules: selectPublishedFlowRules(state),
    flows,
    hasFlowPrioritization: selectFeature(state, FLOW_PRIORITIZATION),
    hasAbTesting: selectFeature(state, AB_TESTING),
    hasLocalization: selectFeature(state, FLOW_LOCALIZATION),
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      path,
      params: { flowId: id },
    },
  }
) => ({
  onLoad: () => dispatch(replacePage({ path, id })),
  onUnload: () => dispatch(dropContentStatus(id)),
  onFlowChange: delta => dispatch(updateFlow(id, delta)),
  onRuleChange: delta => dispatch(updateRule(id, delta)),
  onSegmentCreate: data => dispatch(createSegment(data)),
  onTranslationsRequest: format =>
    dispatch(requestTranslations({ flowId: id, format })),
  onRemoveTranslations: () => dispatch(removeTranslations(id)),
  onAttributeChange: (type, attributeName, term) =>
    dispatch(readSuggestions({ type, attributeName, term })),
  uploadTranslationFile: payload =>
    dispatch(uploadTranslation({ ...payload, flowId: id })),
  onTestFlowCreated: () => dispatch(flowTestMode(id)),
  deleteTranslationFile: translationId =>
    dispatch(deleteTranslation({ flowId: id, translationId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
