import { createSelector } from 'reselect';
import { createCollectionReducer } from 'reducers/account/collections';
import { selectIsSynced } from 'reducers/account/requests';

export const TYPE = 'published-rules';

export default createCollectionReducer(TYPE);

export const selectPublishedRules = state => state.account.publishedRules;
export const selectArePublishedRulesSynced = state =>
  selectIsSynced(state, TYPE);

export const selectPublishedFlowRules = createSelector(
  selectPublishedRules,
  rules =>
    Object.entries(rules ?? {})
      .filter(([, { content_type }]) => content_type === 'journey')
      .reduce((acc, [id, rule]) => {
        acc[id] = rule;
        return acc;
      }, {})
);
