/**
 * Gets the Hubpsot token value from the Hubspot cookie
 * @returns {*}
 */
export const getHubspotCookieValue = () => {
  const hubspotUserToken =
    document.cookie.match(/hutk=(.*?)(;|$)/gi) ||
    document.cookie.match(/hubspotutk=(.*?)(;|$)/gi);

  if (hubspotUserToken) {
    return hubspotUserToken[0].split('=')[1].replace(';', '');
  }
  return null;
};

/**
 * Generates the Hubspot API V3 formatted payload
 * @param {*} contactInfo
 * @returns {*}
 */
export const generateHubspotPayload = contactInfo => {
  if (!contactInfo) return null;

  const { hutk, ...fieldsObj } = contactInfo;

  const fields = Object.entries(fieldsObj).map(([name, value]) => ({
    objectTypeId: '0-1',
    name,
    value,
  }));

  return {
    submittedAt: Date.now(),
    fields,
    context: {
      hutk, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
      pageUri: 'https://studio.appcues.com/signup_info', // hardcoded to match the existing accounts service implementation
      pageName: 'Appcues',
    },
  };
};

/**
 * Posts the passed in `hubspotFormData` to the form with the passed in `formId`
 * @param {*} formId
 * @param {*} hubspotFormData
 * @returns {*}
 */
export const postToHubspot = async (formId, hubspotFormData) => {
  /* global HUBSPOT_V3_FORMS_URL, HUBSPOT_ACCOUNT_ID */
  const response = await fetch(
    `${HUBSPOT_V3_FORMS_URL}/${HUBSPOT_ACCOUNT_ID}/${formId}`,
    {
      body: JSON.stringify(hubspotFormData),
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
    }
  );

  if (response.ok) {
    const data = await response.json();
    return { data };
  }

  return {};
};
