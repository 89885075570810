import { call, put, takeEvery, getContext } from 'redux-saga/effects';
import toast from 'next/lib/toast';
import {
  replaceAccountMeta,
  setAccountMetaFetching,
  setAccountMetaErrored,
  updateSuccessAccountMeta,
} from 'actions/account/meta';
import { promisaga } from 'utils/as-promised';
import {
  FETCH_ACCOUNT_META,
  UPDATE_ACCOUNT_META,
} from 'constants/account/meta';
import { SUBSCRIPTION_FETCHED } from 'constants/actionTypes';
import { reportError } from 'helpers/error-reporting';

export function* fetchAccountMeta() {
  try {
    yield put(setAccountMetaFetching());
    const api = yield getContext('apiV2');
    const { data } = yield call(api.getAccount);
    yield put(replaceAccountMeta(data));
  } catch (error) {
    yield put(setAccountMetaErrored(error));
    yield call(reportError, error);
  }
}

export function* flushAccountMeta({
  payload: { changes = {} },
  meta: { notify } = { notify: true },
}) {
  try {
    // optimistic changes have already been made in redux
    const api = yield getContext('apiV2');
    yield call(api.updateAccount, changes);

    // account meta changes trigger a re-bundle
    yield put(updateSuccessAccountMeta());

    if (notify) {
      yield call(toast.success, 'Account updated');
    }
  } catch (error) {
    yield call(reportError, error);
    yield call(toast.error, 'Failed to update account');
    throw error;
  }
}

export default function* meta() {
  yield takeEvery(
    [FETCH_ACCOUNT_META, SUBSCRIPTION_FETCHED],
    promisaga(fetchAccountMeta)
  );
  yield takeEvery(UPDATE_ACCOUNT_META, promisaga(flushAccountMeta));
}
