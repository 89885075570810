import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import useDateRangeQuery from 'next/hooks/use-date-range-query';
import useQueryString from 'next/hooks/use-query-string';
import BoundedDateRangeDropdown from 'next/components/BoundedDateRangeDropdown';
import TimestampRefresh from 'next/components/TimestampRefresh';
import { selectDateRangeOptions } from 'selectors/timeframe-options';
import { clear } from 'actions/analytics-v2';
import { selectAccountMetaSynced } from 'reducers/account/meta';
import { selectAccountFeaturesSynced } from 'reducers/account/features';
import Loader from 'components/Common/Loader';
import SelectSegmentDropDown from 'components/Common/SelectSegmentDropDown';
import EventsByUserTable from 'components/Insights/Charts/View/EventsByUserTable';
import {
  ChartContainer,
  ChartWrapper,
  EventsChart,
} from 'components/Insights/Explorer/Chart';
import EventTotals from './EventTotals';

const ControlsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;
`;

const ControlColumn = styled.div`
  margin-right: 1rem;
`;

const StretchedControlColumn = styled.div`
  margin-left: auto;
`;

const Block = styled.div`
  margin-bottom: 1rem;
`;

export const SingleMetricContent = ({
  eventName,
  id,
  stepId,
  onRefresh,
  options,
  source,
}) => {
  const [updated, setUpdated] = useState(Date.now());

  const [{ range, start, end }, setDateRange] = useDateRangeQuery(options, {
    range: 7,
  });

  const [segmentId, setSegmentId] = useQueryString('si', null);

  const startTime = start.getTime();
  const endTime = end.getTime();

  const selectedEvents = [{ event: eventName, id, stepId, source }];

  const handleRefresh = () => {
    setUpdated(Date.now());
    onRefresh();
  };

  return (
    <>
      <ControlsWrapper>
        <ControlColumn>
          <BoundedDateRangeDropdown
            value={{ range, start, end }}
            options={options}
            onApply={setDateRange}
          />
        </ControlColumn>
        <ControlColumn>
          <SelectSegmentDropDown
            value={segmentId}
            onChange={setSegmentId}
            isClearable
          />
        </ControlColumn>
        <StretchedControlColumn>
          <TimestampRefresh endTime={updated} onClick={handleRefresh} />
        </StretchedControlColumn>
      </ControlsWrapper>
      <Block>
        <ChartContainer>
          <ChartWrapper>
            <EventsChart
              startTime={startTime}
              endTime={endTime}
              segmentId={segmentId}
              eventName={eventName}
              source={source}
              id={id}
              stepId={stepId}
            />
          </ChartWrapper>
        </ChartContainer>
      </Block>
      <Block>
        <EventTotals
          startTime={startTime}
          endTime={endTime}
          segmentId={segmentId}
          id={id}
          stepId={stepId}
          eventName={eventName}
          source={source}
        />
      </Block>
      <Block>
        <EventsByUserTable
          source={source}
          startTime={startTime}
          endTime={endTime}
          events={selectedEvents}
          segmentId={segmentId}
          pageSize={10}
        />
      </Block>
    </>
  );
};

SingleMetricContent.propTypes = {
  eventName: PropTypes.string,
  id: PropTypes.string,
  stepId: PropTypes.string,
  onRefresh: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  source: PropTypes.string,
};

export const SyncedSingleMetricContent = ({
  eventName,
  id,
  stepId,
  onRefresh,
  options = [],
  source,
  synced,
}) => {
  if (!synced) {
    return <Loader />;
  }

  return (
    <SingleMetricContent
      eventName={eventName}
      id={id}
      stepId={stepId}
      onRefresh={onRefresh}
      options={options}
      source={source}
    />
  );
};

SyncedSingleMetricContent.propTypes = {
  ...SingleMetricContent.propTypes,
  synced: PropTypes.bool,
};

const mapStateToProps = state => ({
  options: selectDateRangeOptions(state),
  synced: selectAccountMetaSynced(state) && selectAccountFeaturesSynced(state),
});

const mapDispatchToProps = {
  onRefresh: clear,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyncedSingleMetricContent);
