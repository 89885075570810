import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CButton } from '@appcues/component-library';
import useToggle from 'next/hooks/use-toggle';
import { selectFlowTranslationByLocale } from 'reducers/account/translations';
import CoolTip from 'components/Common/CoolTip';
import FlowPreviewModal from './FlowPreviewModal';

export const PreviewManager = ({ flowId, localeId, language, disabled }) => {
  const [previewModalOpen, togglePreviewModal] = useToggle();

  return (
    <>
      {previewModalOpen && (
        <FlowPreviewModal
          language={language}
          flowId={flowId}
          localeId={localeId}
          onClose={togglePreviewModal}
        />
      )}
      <CoolTip
        tip={`Upload a translation for ${language} to preview flow`}
        disabled={!disabled}
      >
        <CButton
          type="secondary"
          border="none"
          onClick={togglePreviewModal}
          disabled={disabled}
        >
          View
        </CButton>
      </CoolTip>
    </>
  );
};

PreviewManager.propTypes = {
  flowId: PropTypes.string,
  localeId: PropTypes.string,
  language: PropTypes.string,
  disabled: PropTypes.bool,
};

const mapStateToProps = (state, { localeId }) => ({
  disabled: !selectFlowTranslationByLocale(state, localeId),
});

export default connect(mapStateToProps)(PreviewManager);
