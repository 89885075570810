import React, { Fragment } from 'react';
import styled from 'styled-components';
import { H4, Flex, Td } from '@appcues/component-library';
import { ExternalLink } from '@studio/legacy-components';
import CoolTip from 'components/Common/CoolTip';
import UpdatedTable from 'components/Common/UpdatedTable';
import EventRow from 'components/Settings/PropertiesAndEvents/EventsList/EventRow';
import { sortByKey } from 'utils';

const docsLink = 'https://docs.appcues.com/article/156-event-targeting';

const headers = [
  {
    text: 'Event',
    tip: 'The event name chosen by developers in your Appcues installation.',
  },
  {
    text: 'Type',
    tip: 'The source of this event.',
  },
  {
    text: 'Description',
    tip: 'Help your team understand this event.',
  },
  {
    text: 'Last seen',
    tip: 'The last time Appcues received data for this event.',
  },
  {
    text: 'Show in Menus',
    tip: 'Hide or show this event in Appcues menus.',
  },
];

const ToolTip = styled(CoolTip)`
  padding-left: 16px;
  color: ${props => props.theme['$gray-5']};
`;

const PaddedTd = styled(Td)`
  font-weight: 400;
  padding: 24px 16px 16px;
  text-transform: uppercase;

  &:first-child {
    padding-left: 32px;
  }
  &:last-child {
    padding-right: 32px;
  }
`;

const BorderTable = styled(UpdatedTable)`
  width: 100%;
  border: 1px solid ${props => props.theme['$gray-1']};
  border-collapse: collapse;
  border-radius: 6px;
  margin: 1rem 0 40px;

  td:last-child {
    text-align: right;
  }
`;

const IntroText = styled.p`
  &.intro-text {
    margin-bottom: 25px;
  }
`;

export default function EventsList({ userEvents }) {
  const renderRow = userEvent => (
    <EventRow key={userEvent.name} userEvent={userEvent} />
  );

  const renderHeader = headerObj => (
    <Fragment key={headerObj.text}>
      <PaddedTd>
        <Flex>
          <H4 type="secondary" truncate>
            {headerObj.text}
          </H4>
          <ToolTip tip={headerObj.tip} />
        </Flex>
      </PaddedTd>
    </Fragment>
  );

  const eventsArr = Object.values(userEvents);

  return (
    <div className="events-list">
      <IntroText className="intro-text">
        Events are added to Appcues by your team. Want to add a new event?{' '}
        <ExternalLink href={docsLink}>Learn more.</ExternalLink>
      </IntroText>
      <BorderTable
        headers={headers}
        rowData={eventsArr.sort(sortByKey('name'))}
        renderRow={renderRow}
        renderHeader={renderHeader}
      />
    </div>
  );
}

EventsList.defaultProps = {
  userEvents: {},
};
