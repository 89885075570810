import * as actionTypes from 'constants/actionTypes';

export const addPerformanceMark = metricName => {
  return {
    type: actionTypes.ADD_PERFORMANCE_MARK,
    payload: {
      metricName,
    },
  };
};

export const addPerformanceMeasure = metricName => {
  return {
    type: actionTypes.ADD_PERFORMANCE_MEASURE,
    payload: {
      metricName,
    },
  };
};

export const trackMetric = (metricName, duration) => {
  return {
    type: actionTypes.TRACK_METRIC,
    payload: {
      metricName,
      duration,
    },
  };
};
