import moment from 'moment';
import HTTPError from 'errors/HTTPError';

/* global ANALYTICS_API_HOST */
const queryUrl = `${ANALYTICS_API_HOST}/v2/query`;
export default function createAnalyticsV2Client(auth) {
  const request = async (body = {}) => {
    const jwt = await auth.getToken();
    const accountId = await auth.getAccountId();
    // NOTE: currently the analytics api requires account_id to be
    // present in both the query params and the request body
    const urlWithAccountParam = `${queryUrl}?account_id=${accountId}`;
    const timeZoneOffset = moment().utcOffset();
    const bodyWithAccountIdAndTimeZoneOffset = {
      ...body,
      account_id: accountId,
      time_zone: timeZoneOffset,
    };
    const apiBody = JSON.stringify(bodyWithAccountIdAndTimeZoneOffset);

    const response = await fetch(urlWithAccountParam, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: apiBody,
    });
    if (!response.ok) {
      throw new HTTPError(response);
    }

    return response.json();
  };

  return {
    getAnalytics: ({ query }) => request(query),
  };
}
