import PropTypes from 'prop-types';
import styled from 'styled-components';
import AppcuesLogoWithText from 'next/assets/images/appcues-logo-with-text.svg';

const Logo = styled.img.attrs({
  src: AppcuesLogoWithText,
})`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 189px;
`;

Logo.propTypes = {
  className: PropTypes.string,
};

export default Logo;
