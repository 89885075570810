import React, { Component } from 'react';
import { CDialog, Text } from '@appcues/component-library';
import styled from 'styled-components';
import {
  Description,
  ExternalLink,
  Heading,
  Icon,
} from '@studio/legacy-components';
import Loader from 'components/Common/Loader';
import Table from 'components/Common/UI/Table';
import { getOptGroup, getPropertyDisplayName } from 'utils/index';
import { capitalizeFirstLetter } from 'utils/textFormatting';
import { SUPPORT_EMAIL } from 'constants/externalAppcuesResources';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  ${ExternalLink} {
    margin-right: 40px;
    &:hover {
      color: var(--see-more-link-color);
    }
  }
`;

const CloseModalIcon = styled(Icon)`
  position: absolute;
  right: 10px;
`;

export class UserProfileModal extends Component {
  ModalHeader = () => {
    const { userId } = this.props;
    return (
      <Header>
        <CloseModalIcon
          icon="times"
          onClick={this.hideModal}
          cursor="pointer"
        />
        <Heading>User profile</Heading>
        <Description>{userId || ''}</Description>
      </Header>
    );
  };

  hideModal = () => {
    const { actions } = this.props;
    actions.hideModal();
  };

  render() {
    const { userProfile } = this.props;

    const properties = Object.keys(userProfile || {}).filter(
      p => !['_goal', '_segment', '_checklist'].some(e => p.includes(e))
    );

    const errorOccurs =
      userProfile && (properties.length === 0 || userProfile.error);
    const unidentifiedUser =
      userProfile && properties.length === 1 && properties[0] === '_ABGroup';
    const loaded = userProfile && properties.length > 0;

    return (
      <CDialog
        title="User profile"
        width={900}
        components={{ Header: this.ModalHeader, Footer: false }}
      >
        {errorOccurs && (
          <Text>
            There was an error loading profile for this user. If the problem
            persists contact{' '}
            <ExternalLink href={`mailto:${SUPPORT_EMAIL}`}>
              {SUPPORT_EMAIL}
            </ExternalLink>
          </Text>
        )}
        {unidentifiedUser && (
          <Text>
            We haven&apos;t seen this user yet! The eligibility below is based
            on a user with no properties.
          </Text>
        )}
        {!errorOccurs && !unidentifiedUser && loaded && (
          <Table
            defaultSortColumn="property"
            marginTop={12}
            data={properties.map(property => ({
              property: getPropertyDisplayName(property),
              value:
                typeof userProfile[property] === 'object'
                  ? JSON.stringify(userProfile[property]).replace(/,/g, ', ')
                  : userProfile[property],
              kind: capitalizeFirstLetter(getOptGroup(property)),
            }))}
            columns={[
              {
                Header: 'Property name',
                accessor: 'property',
                sortable: true,
                minWidth: 100,
                maxWidth: 120,
                Cell: ({ value }) => {
                  return <Text>{value}</Text>;
                },
              },
              {
                Header: 'Value',
                accessor: 'value',
                sortable: true,
                minWidth: 120,
                maxWidth: 240,
                Cell: ({ value }) => {
                  return (
                    <Text is="div" truncate>
                      {String(value) || '-'}
                    </Text>
                  );
                },
              },
              {
                Header: 'Kind',
                accessor: 'kind',
                sortable: true,
                minWidth: 40,
                maxWidth: 64,
                Cell: ({ value }) => {
                  return <Text>{value}</Text>;
                },
              },
            ]}
          />
        )}
        {!loaded && !errorOccurs && !unidentifiedUser && <Loader />}
      </CDialog>
    );
  }
}

export default UserProfileModal;
