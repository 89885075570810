import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Heading, Modal } from '@studio/legacy-components';
import { asPromised } from 'utils/as-promised';
import { activateKey } from 'entities/keys';
import { StateDescription, StateNegativeNotice } from './styled';
import { PERMISSIONS_OPTIONS } from './constants';

export const EnableKey = ({ apiKey, roleId, visible, onEnable, onClose }) => {
  const [inFlight, setInFlight] = useState(false);
  const [error, setError] = useState(false);

  const { label: roleName = '' } =
    PERMISSIONS_OPTIONS.find(item => item.value === roleId) || {};

  useEffect(() => {
    if (visible) {
      setInFlight(false);
      setError(false);
    }
  }, [visible]);

  const handleEnable = async () => {
    setInFlight(true);
    setError(false);

    try {
      await onEnable(apiKey);
      onClose();
    } catch {
      setError(true);
      setInFlight(false);
    }
  };

  return (
    <Modal onClose={onClose} size="s" visible={visible}>
      <Heading>Enable API Key</Heading>
      {error && (
        <StateNegativeNotice>
          We&apos;re sorry, something went wrong on our end. Try again.
        </StateNegativeNotice>
      )}
      <StateDescription>
        Enabling will allow any application using this key to have {roleName}{' '}
        access to this Appcues account. You won&apos;t be able to view the
        secret.
      </StateDescription>
      <ButtonGroup right>
        <Button kind="positive" onClick={handleEnable} disabled={inFlight}>
          Enable
        </Button>
        <Button kind="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ButtonGroup>
    </Modal>
  );
};

EnableKey.propTypes = {
  apiKey: PropTypes.string,
  roleId: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onEnable: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  onEnable: key => asPromised(dispatch, activateKey({ key })),
});

export default connect(null, mapDispatchToProps)(EnableKey);
