import { createCollectionActions } from 'next/lib/collections';

export const TYPE = 'schedules';

export const {
  patterns,
  read,
  readOne,
  reject,
  resolve,
  update,
  flush,
  replace,
  insert,
  add,
  remove,
  prune,
} = createCollectionActions(TYPE);

export const FETCH_SCHEDULES = 'FETCH_SCHEDULES';

export const fetchSchedules = id => ({
  type: FETCH_SCHEDULES,
  payload: { id },
});
