import PropTypes from 'prop-types';

export default PropTypes.shape({
  createdAt: PropTypes.number,
  createdBy: PropTypes.number,
  description: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  showInUI: PropTypes.bool,
  updatedAt: PropTypes.number,
  updatedBy: PropTypes.number,
});
