import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getMatchingChecklists, getMatchingSegments } from 'utils/segments';
import { callApi as fetchRules } from 'actions/account/rules';
import { selectConditionsBySegmentId } from 'reducers/account/conditions';
import { selectFlows } from 'reducers/account/flows';
import { selectAccountChecklists } from 'entities/checklists';
import { selectAccountSegments } from 'reducers/account/segments';
import { selectAccountSatisfactionSegmentId } from 'selectors/satisfaction';
import {
  selectMatchingPins,
  selectMatchingMobileFlows,
} from 'selectors/segments';

export const SegmentUsageBreakdown = ({
  segmentId,
  steps,
  matchingRules,
  segments,
  checklists,
  isUsedInNps,
  onLoad,
  matchingPins,
  matchingMobileFlows,
}) => {
  useEffect(() => void onLoad(), []);

  const matchingSegments = getMatchingSegments(segmentId, segments);
  const matchingChecklists = getMatchingChecklists(segmentId, checklists);

  return (
    <div>
      {(matchingRules.length > 0 || matchingMobileFlows.length > 0) && (
        <>
          <h2>Flows</h2>
          <ul>
            {matchingRules.map(ruleId => (
              <li key={ruleId}>
                {steps[ruleId] ? steps[ruleId].name : ruleId}
              </li>
            ))}
            {matchingMobileFlows.map(mobileFlow => (
              <li key={mobileFlow.id}>{mobileFlow.name ?? mobileFlow.id}</li>
            ))}
          </ul>
        </>
      )}

      {matchingSegments.length > 0 && (
        <>
          <h2>Segments</h2>
          <ul>
            {matchingSegments.map(id => (
              <li key={id}>{segments[id] ? segments[id].name : id}</li>
            ))}
          </ul>
        </>
      )}

      {matchingChecklists.length > 0 && (
        <>
          <h2>Checklists</h2>
          <ul>
            {matchingChecklists.map(checklistId => (
              <li key={checklistId}>
                {checklists[checklistId]
                  ? checklists[checklistId].internalName
                  : checklistId}
              </li>
            ))}
          </ul>
        </>
      )}

      {matchingPins.length > 0 && (
        <>
          <h2>Pins</h2>
          <ul>
            {matchingPins.map(pin => (
              <li key={pin.id}>{pin.name ?? pin.id}</li>
            ))}
          </ul>
        </>
      )}

      {isUsedInNps && <h2>NPS</h2>}
    </div>
  );
};

function mapStateToProps(state, { segmentId }) {
  return {
    steps: selectFlows(state),
    matchingRules: selectConditionsBySegmentId(state, { segmentId }),
    segments: selectAccountSegments(state),
    checklists: selectAccountChecklists(state),
    isUsedInNps: selectAccountSatisfactionSegmentId(state) === segmentId,
    matchingPins: selectMatchingPins(state, segmentId),
    matchingMobileFlows: selectMatchingMobileFlows(state, segmentId),
  };
}

const mapDispatchToProps = {
  onLoad: fetchRules,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SegmentUsageBreakdown);
