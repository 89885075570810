import {
  ACCOUNT_PERMISSIONS,
  ADMINISTRATOR_OPTION,
  EDITOR_OPTION,
  PUBLISHER_OPTION,
  ACCOUNT_PERMISSION_VALUE_TO_LABEL,
} from 'constants/accountManagement/permissions';

export function getUserRole(accountUser) {
  if (!accountUser) {
    return null;
  }
  if (
    accountUser.role &&
    [
      ACCOUNT_PERMISSIONS.EDITOR,
      ACCOUNT_PERMISSIONS.ADMINISTRATOR,
      ACCOUNT_PERMISSIONS.PUBLISHER,
    ].includes(accountUser.role)
  ) {
    return accountUser.role;
  }
  if (accountUser.isAccountAdmin) {
    return ADMINISTRATOR_OPTION.value;
  }
  if (
    accountUser.cannotPublish ||
    (accountUser.meta && accountUser.meta.cannotPublish)
  ) {
    return EDITOR_OPTION.value;
  }
  return PUBLISHER_OPTION.value;
}

export function getAccountAdminUserIds(accountUsers) {
  if (!accountUsers) {
    return [];
  }

  const userIds = Object.keys(accountUsers).reduce(
    (accountAdminUserIds, userId) => {
      const accountUser = accountUsers[userId];

      if (getUserRole(accountUser) === ADMINISTRATOR_OPTION.value) {
        return [...accountAdminUserIds, userId];
      }

      return accountAdminUserIds;
    },
    []
  );

  return new Set(userIds);
}

/*
 * @param accountUsers: a map of users from the account state
 */
export function isAccountAdmin({ user, accountUsers }) {
  if (!user || !accountUsers) {
    return false;
  }

  const accountAdmins = getAccountAdminUserIds(accountUsers);
  return accountAdmins.has(user.id);
}

export function getUserPermissionLabel({ user, accountUsers }) {
  if (isAccountAdmin({ user, accountUsers })) {
    return ADMINISTRATOR_OPTION.label;
  }
  if (user.role === 'EDITOR') {
    return EDITOR_OPTION.label;
  }
  return PUBLISHER_OPTION.label;
}

export function getPermissionValueForUser({ user, accountUsers }) {
  if (isAccountAdmin({ user, accountUsers })) {
    return ACCOUNT_PERMISSIONS.ADMINISTRATOR;
  }
  if (user.role === 'EDITOR') {
    return ACCOUNT_PERMISSIONS.EDITOR;
  }
  return ACCOUNT_PERMISSIONS.PUBLISHER;
}

export function getPermissionLabelForUser({ user, accountUsers }) {
  return ACCOUNT_PERMISSION_VALUE_TO_LABEL[
    getPermissionValueForUser({ user, accountUsers })
  ];
}

export function buildUserPermissionProperties(permissionOption) {
  switch (permissionOption) {
    case ADMINISTRATOR_OPTION.value:
      return {
        role: ADMINISTRATOR_OPTION.value,
      };
    case EDITOR_OPTION.value:
      return {
        role: EDITOR_OPTION.value,
      };
    default:
      return {
        role: PUBLISHER_OPTION.value,
      };
  }
}

export function isSuperUser(user) {
  return user && user.isAdmin;
}

export function canManageAccountUsers({ user, accountUsers }) {
  return isSuperUser(user) || isAccountAdmin({ user, accountUsers });
}
