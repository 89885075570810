import React from 'react';
import styled from 'styled-components';
import ExampleURL from 'components/Common/URLTargetingHelper/ExampleURL';
import AddUrlStep from 'components/Common/URLTargetingHelper/AddUrlStep';

const DescriptionText = styled.span`
  display: inline-block;
  margin: 0.5rem 0;
`;

const TestUrlsWrapper = styled.div`
  margin-top: 1rem;
`;

const TestStep = props => {
  const { conditions, deleteUrl, handleSubmit, testUrls } = props;

  const displayTestUrls = testUrls.map(url => {
    const handleDelete = () => {
      deleteUrl(url);
    };

    return (
      <ExampleURL
        conditions={conditions}
        key={url}
        url={url}
        handleDelete={handleDelete}
      />
    );
  });

  const headerMessage =
    'Enter a page URL below to test whether or not your content will show up on it:';

  return (
    <div>
      <DescriptionText>{headerMessage}</DescriptionText>
      <AddUrlStep handleSubmit={handleSubmit} testUrls={testUrls} />
      <TestUrlsWrapper>{displayTestUrls}</TestUrlsWrapper>
    </div>
  );
};

export default TestStep;
