import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectAccountImage } from 'reducers/account/images';
import { fetchStepChildImage } from 'actions/account/images';
import Loader from 'components/Common/Loader';

export const ImageServiceImage = ({
  className,
  onLoad,
  url,
  forceRegenerate,
  alt = '',
}) => {
  useEffect(() => {
    if (!url || forceRegenerate) {
      onLoad();
    }
  }, []);

  return url ? (
    <img src={url} alt={alt} className={`${className} image-service-image`} />
  ) : (
    <Loader margin="1px" />
  );
};

const mapStateToProps = (
  state,
  { stepChildId, versionId, size, localeId }
) => ({
  url: selectAccountImage(state, { stepChildId, versionId, size, localeId })
    .url,
});

const mapDispatchToProps = (
  dispatch,
  {
    forceRegenerate,
    flowId,
    stepId: stepGroupId,
    stepChildId,
    contentType,
    versionId,
    size,
    localeId,
  }
) => ({
  onLoad: () =>
    dispatch(
      fetchStepChildImage({
        forceRegenerate,
        flowId,
        stepGroupId,
        stepChildId,
        contentType,
        versionId,
        size,
        localeId,
      })
    ),
});

ImageServiceImage.propTypes = {
  flowId: PropTypes.string,
  stepId: PropTypes.string,
  localeId: PropTypes.string,
  stepChildId: PropTypes.string,
  versionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.string,
  contentType: PropTypes.string,
  forceRegenerate: PropTypes.bool,
};

ImageServiceImage.defaultProps = {
  forceRegenerate: false,
  localeId: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageServiceImage);
