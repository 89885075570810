import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Description, H3, Heading, Text } from '@studio/legacy-components';
import { history } from 'next/lib/history';
import { asRequest } from 'next/entities/requests';
import { selectAccount } from 'next/entities/account';
import {
  switchAccount,
  readAccounts,
  Shape as AccountShape,
} from 'next/entities/accounts';
import useTitle from 'next/hooks/use-title';
import { AccountsModal, AccountLoading, AccountsList, Account } from './styled';

/**
 * TODO: Remove fullscreen modal and use a fullscreen page instead
 */

/**
 * Alphabetize accounts with ID fallbacks
 *
 * @param {object<Account>} accounts - Accounts for user
 * @return {Account[]} Sorted accounts list
 */
const alphabetize = (accounts = {}) =>
  Object.values(accounts).sort((first, second) => {
    switch (true) {
      // Compare names if both exist
      case Boolean(first.name && second.name):
        return first.name.localeCompare(second.name);

      // Else prefer the account with a name
      case Boolean(first.name && !second.name):
        return -1;
      case Boolean(!first.name && second.name):
        return 1;

      // Otherwise compare account IDs
      default:
        return first.id.localeCompare(second.id);
    }
  });

export function Accounts({
  accounts = {},
  onClick,
  selected: initial,
  size = 'xl',
}) {
  useTitle('Accounts | Appcues');
  // Determines if an account switch has been requested
  const [pending, setPending] = useState(false);

  // Immediately set newly selected account for instant UI feedback
  const [selected, setSelected] = useState(initial);

  // Update selected if provided prop changes
  useEffect(() => {
    setSelected(initial);
  }, [initial]);

  // Sort accounts by name and ID
  const sorted = useMemo(() => {
    return alphabetize(accounts.data);
  }, [accounts.data]);

  // Go back in history on close
  const handleClose = pending ? null : () => history.goBack();

  // Create click handler for given account ID. Also only handle click if
  // different account has been selected and if there is no pending requests
  const handleClickFor = id => () => {
    if (!pending) {
      setPending(true);
      setSelected(id);
      onClick(id);
    }
  };

  return (
    <AccountsModal onClose={handleClose} size={size} visible>
      <Heading>Your accounts</Heading>

      <Description>Switch between your accounts</Description>

      <AccountsList aria-label="Accounts" aria-busy={accounts.loading}>
        {accounts.loading && (
          // Show three account skeletons while accounts are being fetched
          <>
            <AccountLoading />
            <AccountLoading />
            <AccountLoading />
          </>
        )}

        {!accounts.loading &&
          sorted.map(({ id, name, $active = id === selected }) => (
            <Account
              key={id}
              disabled={pending}
              onClick={handleClickFor(id)}
              $selected={$active}
            >
              <H3>{name || `Account ${id}`}</H3>
              {$active && <Text>Currently in use</Text>}
            </Account>
          ))}
      </AccountsList>
    </AccountsModal>
  );
}

Accounts.propTypes = {
  accounts: asRequest(PropTypes.objectOf(AccountShape)),
  onClick: PropTypes.func,
  selected: PropTypes.string,
  size: PropTypes.oneOf(['xl', 'full']),
};

const mapStateToProps = state => ({
  accounts: readAccounts(state),
  selected: selectAccount(state).id,
});

const mapDispatchToProps = {
  onClick: switchAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
