import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Button } from '@appcues/component-library';
import BaseModal from 'components/ModalContainer/BaseModal';
import ClickToCopyText from 'components/Common/ClickToCopyText';
import { buildPermalink, buildShareablePreviewLink } from 'utils/index';

const Modal = styled(BaseModal)`
  .install-button {
    margin: 0.5rem 0 1rem 0;
    display: inline-block;
  }

  .well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: whitesmoke;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  }

  .text-center {
    text-align: center;
  }

  .subheader {
    margin-bottom: 10px;
  }
`;

const LegacyCodeFormat = styled.code`
  font-family: 'source-code-pro', Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
`;

export default function PermalinkModal(props) {
  const { currentContent, isInstalled, stepId } = props;

  const permalink = buildPermalink(currentContent.previewUrl, stepId);
  const shareablePreviewLink = buildShareablePreviewLink(
    currentContent.type,
    stepId
  );

  return (
    <Modal
      modalClass="permalink-modal"
      title="Permalink Options"
      large
      okText="Close"
      shouldHideButtonStyle
      {...props}
    >
      {isInstalled ? (
        <div>
          <p className="subheader">
            <strong>Shareable permalink</strong>
          </p>
          <p>
            You can trigger this flow to appear by appending{' '}
            <LegacyCodeFormat>{`?appcue=${stepId}`}</LegacyCodeFormat> to the
            end of any URL in your app. Give it a try:
          </p>
          <ClickToCopyText text={permalink} hintText="Copy link" />
          <p>
            You can use this in emails, help docs, or anywhere your users can
            open URL links.
          </p>

          <br />
          <p className="subheader">
            <strong>Embeddable Button</strong>
          </p>
          <p>
            Add a button that your users can click to bring up this flow.
            Here&#39;s an example:
          </p>
          <div className="well text-center">
            <Button className="button-primary">Show hints &#x27a4;</Button>
          </div>
          <p>Copy the code below to use it:</p>
          <ClickToCopyText
            text={`<a class="btn btn-primary btn-sm" href="javascript:Appcues.show('${stepId}')">Show hints &#x27a4;</a>`}
            hintText="Copy code"
          />

          <p>
            This flow&#39;s ID is <code>{`${stepId}`}</code>
          </p>
        </div>
      ) : (
        <div>
          <p className="subheader">
            <strong>Install the Appcues SDK</strong>
          </p>
          <p>
            This content has been staged for publishing until you&apos;ve
            installed the Appcues SDK. Once the SDK is installed, your users can
            instantly see your published content.
          </p>
          <Button
            className={classNames('button-primary', 'install-button')}
            href="/settings/installation"
          >
            Install the Appcues SDK
          </Button>
          <br />
          <p className="subheader">
            <strong>Share Screenshots</strong>
          </p>
          <p>
            In the meantime, use this link to share your work with any member of
            your team.
          </p>
          <ClickToCopyText text={shareablePreviewLink} hintText="Copy link" />
        </div>
      )}
    </Modal>
  );
}
