/**
 * throw http error
 *
 * taken from https://github.com/sindresorhus/ky
 *
 * @todo our apis don't put anything interesting in
 *       statusText so we shouldn't pull the err msg from it.
 *       We should update consumers of this error to pass
 *       an error message explicitly, from the JSON body
 *       of their api response
 */
export default class HTTPError extends Error {
  constructor(response, message = '') {
    super(message || response.statusText);
    this.name = 'HTTPError';
    this.response = response;
  }
}
