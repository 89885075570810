/**
 * Helper function to determine if requestable entity is renderable. An entity
 * is considered if there is no error and either loading or has a certain number
 * of items.
 *
 * @param {RequestStatus} entity - Requestable entity
 * @param {boolean} entity.error - Request error state
 * @param {boolean} entity.loading - Request loading state
 * @param {any[]} entity.data - Entity data
 * @param {number} minimum - Minimum number of items
 * @return {boolean} Whether entity can be rendered
 */
export const renderable = (entity, count = 1) =>
  !entity?.error && (entity?.loading || entity?.data.length >= count);
