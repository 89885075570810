import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { selectAccountFeature } from 'reducers/account/features';
import * as FEATURES from 'constants/features';

export const FeatureGate = ({ enabled, children }) => {
  return children(enabled);
};

FeatureGate.propTypes = {
  enabled: PropTypes.bool,
  children: PropTypes.any,
};

const mapStateToProps = (state, { feature }) => ({
  enabled: selectAccountFeature(state, feature),
});

const ConnectedFeatureGate = connect(mapStateToProps)(FeatureGate);

ConnectedFeatureGate.propTypes = {
  feature: PropTypes.oneOf(Object.values(FEATURES)) || null,
  children: PropTypes.any,
};

export default ConnectedFeatureGate;
