// Helper function for making requests to the webhook API.
const makeWebhookApiRequest = async (method, path, jsonBody = null) => {
  const baseHeaders = {
    Accept: 'application/json',
    /* global TEMP_INTEGRATION_MANAGEMENT_KEY */
    Authorization: `Bearer ${TEMP_INTEGRATION_MANAGEMENT_KEY}`,
  };
  const headers =
    (jsonBody && { ...baseHeaders, 'Content-Type': 'application/json' }) ||
    baseHeaders;
  const body = jsonBody && JSON.stringify(jsonBody);
  /* global WEBHOOK_API_URL */
  const response = await fetch(`${WEBHOOK_API_URL}${path}`, {
    method,
    body,
    headers,
    mode: 'cors',
  });

  if (response.status !== 200) {
    throw new Error(`Webhook API request with status code ${response.status}`);
  } else {
    return response.json();
  }
};

// Retrieve solution instances; this is the simplest way to query whether an account already
// has e.g. a Salesforce integration (enabled or disabled).
function getSolutionInstances(accountId, accountApiKey) {
  return makeWebhookApiRequest(
    'GET',
    `/v1/accounts/${accountId}/tray/instances?account_api_key=${accountApiKey}`
  );
}

/**
 * Get a one-time configuration code, which is required to launch Tray's configuration wizard.
 * @param {string} accountId that the code will belong to
 * @returns {Promise<{code: string}>}
 */
function getConfigurationCode(accountId, accountApiKey) {
  return makeWebhookApiRequest(
    'GET',
    `/v1/accounts/${accountId}/tray/code?account_api_key=${accountApiKey}`
  );
}

// Create a solution instance. An account API key is required so Tray can do dynamic data mapping.
function createSolutionInstance(
  desiredSolutionId,
  instanceName,
  accountId,
  accountApiKey
) {
  return makeWebhookApiRequest(
    'POST',
    `/v1/accounts/${accountId}/tray/instances`,
    {
      solution_id: desiredSolutionId,
      instance_name: instanceName,
      account_api_key: accountApiKey,
    }
  );
}

/**
 * Change the state of an already created instance, to e.g. enable or disable the integration
 * @param {string} instanceId the ID of the solution instance, as returned by e.g. getOrCreateSolutionInstance
 * @param {string} accountId the account ID that owns the instance
 * @param {{enabled: boolean}} params whether to enable or disable the instance
 */
function enableOrDisableInstance(instanceId, accountId, accountApiKey, params) {
  return makeWebhookApiRequest(
    'PUT',
    `/v1/accounts/${accountId}/tray/instances/${instanceId}`,
    { ...params, account_api_key: accountApiKey }
  );
}

export default function createIntegrationsClient() {
  return {
    getSolutionInstances,
    getConfigurationCode,
    createSolutionInstance,
    enableInstance: (instanceId, accountId, accountApiKey) =>
      enableOrDisableInstance(instanceId, accountId, accountApiKey, {
        enabled: true,
      }),
    disableInstance: (instanceId, accountId, accountApiKey) =>
      enableOrDisableInstance(instanceId, accountId, accountApiKey, {
        enabled: false,
      }),
  };
}
