import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Creatable } from '@studio/legacy-components';
import { selectAccountProfileAttributeByName } from 'selectors/profile-attributes';

export const AttributeMultiSelect = ({
  onChange,
  profileAttributes,
  property,
  value,
}) => {
  const options = profileAttributes
    ? profileAttributes.values.map(attr => ({ value: attr, label: attr }))
    : [];

  return (
    <Creatable
      formatCreateLabel={label => label}
      isMulti
      onChange={onChange}
      options={options}
      placeholder={property}
      value={value}
    />
  );
};

AttributeMultiSelect.propTypes = {
  property: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  profileAttributes: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.string),
  }),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

const mapStateToProps = (state, { property }) => ({
  profileAttributes: selectAccountProfileAttributeByName(state, property),
});

export default connect(mapStateToProps)(AttributeMultiSelect);
