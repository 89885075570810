import React, { useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { CDialog } from '@appcues/component-library';
import { Button, ExternalLink } from '@studio/legacy-components';
import { INSTALL_SEGMENT_MODAL } from 'constants/globals';
import { hideModal } from 'actions/currentModal';
import { SEGMENT_INSTALL_DOC } from 'constants/externalAppcuesResources';

function Footer({ closeWithCallback }) {
  return (
    <CDialog.Footer>
      <Button kind="secondary" onClick={closeWithCallback}>
        Cancel
      </Button>

      <Button
        forwardedAs={ExternalLink}
        kind="primary"
        href={SEGMENT_INSTALL_DOC}
        onClick={closeWithCallback}
      >
        Get Started
      </Button>
    </CDialog.Footer>
  );
}

function InstallSegmentModal({ actions }) {
  const hide = useCallback(() => {
    actions.hideModal(INSTALL_SEGMENT_MODAL);
  }, [actions]);

  return (
    <CDialog
      title="Install with Segment"
      size="large"
      components={{
        Footer: ({ closeWithCallback }) => {
          return <Footer closeWithCallback={closeWithCallback(hide)} />;
        },
      }}
    >
      <p>
        Segment is a third party service that routes data from your app to many
        different services, and gives you central control.
      </p>
      <p>If you already have Segment, you can install Appcues instantly!</p>
    </CDialog>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        hideModal,
      },
      dispatch
    ),
  };
}

export default connect(null, mapDispatchToProps)(InstallSegmentModal);
