import React from 'react';
import {
  CPanel,
  CButton,
  H2,
  H4,
  Caption,
  Text,
  Flex,
  Box,
} from '@appcues/component-library';
import { history } from 'next/lib/history';

const userUpdateCard = () => {
  history.push('/subscription/billing');
};

const BillingDetails = props => {
  const { billingDetails } = props;

  const firstCard = billingDetails.sources.data[0];
  const contactEmail = billingDetails.email;

  return (
    <CPanel>
      <CPanel.Header>
        <H4 type="secondary">Billing Details</H4>
        <CPanel.Header.Actions>
          <CButton onClick={userUpdateCard}>Edit credit card</CButton>
        </CPanel.Header.Actions>
      </CPanel.Header>
      {firstCard && (
        <div>
          {firstCard.ach_credit_transfer ? (
            <H2>
              account ending in{' '}
              {firstCard.ach_credit_transfer.account_number.slice(-4)}
            </H2>
          ) : (
            <Flex alignItems="baseline" marginBottom={12}>
              <H2 type="secondary">Card ending in {firstCard.last4}</H2>{' '}
              <Caption type="tertiary">
                &nbsp; exp. {firstCard.exp_month}/{firstCard.exp_year}
              </Caption>
            </Flex>
          )}
        </div>
      )}
      <Box whiteSpace="nowrap">
        Billing contact: <Text bold>{contactEmail}</Text>
      </Box>
    </CPanel>
  );
};

export default BillingDetails;
