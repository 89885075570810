import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  filterAudienceTargetingClauses,
  getFilteredClauseTree,
  getFlattenedClausesFromNestedConditions,
  propertyOperators,
} from '@appcues/libcues';

import { Button } from '@appcues/component-library';
import styled from 'styled-components';
import Panel from 'components/Common/Panel';
import {
  clauseTreesAreEqual,
  getSegmentClause,
  getFlowTargetingClauses,
} from 'utils/conditions';
import {
  SATISFACTION_TARGETING_CATEGORY,
  SATISFACTION_TARGETING_OPTIONS,
  SATISFACTION_BASE_CONDITIONS,
  SATISFACTION_CREATION_STEP_TITLES,
} from 'constants/satisfaction/data';
import { VIEWED_APP_PAGE_STEP } from 'constants/events';

import {
  detectConditionsTemplate,
  getFrequencySettingsFromClauses,
  getNewUserClause,
  getAudienceTargetingOption,
  getFlowIdsFromClauses,
  getEmailFilteredClauses,
  getEmailDomainBlackList,
} from 'helpers/satisfaction';
import { navigate } from 'actions/routing';
import SatisfactionPageSettings from 'components/SatisfactionSurveys/SatisfactionSurveysEdit/Audience/PageSettings';
import SatisfactionFrequencySettings from 'components/SatisfactionSurveys/SatisfactionSurveysEdit/Audience/FrequencySettings';
import SatisfactionAudienceSettings from 'components/SatisfactionSurveys/SatisfactionSurveysEdit/Audience/AudienceSettings';
import SatisfactionSampleSettings from 'components/SatisfactionSurveys/SatisfactionSurveysEdit/Audience/SampleSettings';
import { selectAccountMeta } from 'reducers/account/meta';
import ThrottleWarning from 'components/Common/ThrottleWarning';

export const customConditionsWarning =
  'We detected custom audience settings for your NPS Survey. Edit your settings, or switch to the standard NPS survey targeting. (Note: You will lose your custom settings when you switch to the standard audience setting.)';

const NPSThrottleWarning = styled(ThrottleWarning)`
  margin-bottom: 32px;
  margin-left: 12%;
`;

export class SatisfactionAudienceEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      targetingOptionView: null,
      userPropertyClauses: getFilteredClauseTree(
        filterAudienceTargetingClauses,
        props.clauses
      ),
      emailDomainBlackList: [],
      baseNPSConditionsFound: true,
    };
  }

  UNSAFE_componentWillMount() {
    const { meta, clauses, showError } = this.props;
    const { userPropertyClauses } = this.state;

    const areConditionsFromTemplate = detectConditionsTemplate(clauses);
    if (!areConditionsFromTemplate) {
      this.setState({ baseNPSConditionsFound: false });
      showError(customConditionsWarning);
      return;
    }

    // Frequency Settings
    const frequencyClauses =
      getFrequencySettingsFromClauses(userPropertyClauses);

    // Audience Settings
    const audienceSettings = this.getAudienceSettingsFromClauses(
      userPropertyClauses,
      meta
    );

    // Sampling Setting
    const sessionRandomizerClause = userPropertyClauses.find(clause => {
      return (
        clause.property ===
        SATISFACTION_TARGETING_OPTIONS[SATISFACTION_TARGETING_CATEGORY.SAMPLING]
          .USER_SESSIONS.clauseProperty
      );
    });

    this.setState({
      ...frequencyClauses,
      ...audienceSettings,
      sessionRandomizerClause,
    });
  }

  componentDidMount() {
    const { trackEvent } = this.props;

    trackEvent(VIEWED_APP_PAGE_STEP, {
      step: SATISFACTION_CREATION_STEP_TITLES.AUDIENCE,
    });

    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { showError, meta: currentMeta } = this.props;
    const { clauses, meta } = nextProps;
    const {
      userPropertyClauses: localUserPropertyClauses,
      baseNPSConditionsFound,
    } = this.state;

    const areConditionsFromTemplate = detectConditionsTemplate(clauses);
    if (!areConditionsFromTemplate && baseNPSConditionsFound) {
      this.setState({ baseNPSConditionsFound: false });
      showError(customConditionsWarning);
      return;
    }

    if (areConditionsFromTemplate) {
      const updates = {};
      if (!baseNPSConditionsFound) {
        updates.baseNPSConditionsFound = true;
      }

      const firebaseUserPropertyClauses = getFilteredClauseTree(
        filterAudienceTargetingClauses,
        clauses
      );

      const userPropertyTargetingChanged = !clauseTreesAreEqual(
        localUserPropertyClauses,
        firebaseUserPropertyClauses
      );

      if (userPropertyTargetingChanged) {
        updates.userPropertyClauses = firebaseUserPropertyClauses;

        // Frequency Settings
        const frequencyClauses = getFrequencySettingsFromClauses(
          firebaseUserPropertyClauses
        );
        updates.surveyFrequencyClause = frequencyClauses.surveyFrequencyClause;
        updates.sessionPageViewClause = frequencyClauses.sessionPageViewClause;
        updates.surveyDelayClause = frequencyClauses.surveyDelayClause;
        updates.surveyDelayExcludeClause =
          frequencyClauses.surveyDelayExcludeClause;

        // Audience Settings
        const audienceSettings = this.getAudienceSettingsFromClauses(
          firebaseUserPropertyClauses,
          meta
        );

        updates.userCreatedAtField = audienceSettings.userCreatedAtField;
        updates.newUserClause = audienceSettings.newUserClause;
        updates.segmentClause = audienceSettings.segmentClause;
        updates.userOption = audienceSettings.userOption;
        updates.flowFilteredClauses = audienceSettings.flowFilteredClauses;
        updates.targetedFlows = audienceSettings.targetedFlows;
        updates.userEmailField = audienceSettings.userEmailField;
        updates.emailFilteredClauses = audienceSettings.emailFilteredClauses;
        updates.emailDomainBlackList = audienceSettings.emailDomainBlackList;

        // Sampling Setting
        const sessionRandomizerClause = firebaseUserPropertyClauses.find(
          clause => {
            return (
              clause.property ===
              SATISFACTION_TARGETING_OPTIONS[
                SATISFACTION_TARGETING_CATEGORY.SAMPLING
              ].USER_SESSIONS.clauseProperty
            );
          }
        );
        updates.sessionRandomizerClause = sessionRandomizerClause;
      }

      if (
        !userPropertyTargetingChanged &&
        currentMeta.userCreatedAtField !== meta.userCreatedAtField
      ) {
        updates.userCreatedAtField = meta.userCreatedAtField;
      }
      if (
        !userPropertyTargetingChanged &&
        currentMeta.userEmailField !== meta.userEmailField
      ) {
        updates.userEmailField = meta.userEmailField;
      }

      this.setState({ ...updates });
    }
  }

  setTargetingOptionView = option => {
    const { targetingOptionView } = this.state;

    this.setState({
      targetingOptionView:
        targetingOptionView && targetingOptionView === option ? null : option,
    });
  };

  getAudienceSettingsFromClauses(userPropertyClauses, meta = {}) {
    const { steps, showError, isInstalled } = this.props;
    const { emailDomainBlackList: localEmailDomainBlackList } = this.state;

    const newUserClause = getNewUserClause(userPropertyClauses, meta);
    const segmentClause = getSegmentClause(userPropertyClauses);

    const userOperator = (newUserClause && newUserClause.operator) || null;
    const userOption = getAudienceTargetingOption(userOperator, segmentClause);

    if (newUserClause && !meta.userCreatedAtField) {
      if (!isInstalled) {
        showError(
          `Heads up! Your audience settings require you to install the Appcues SDK into your site. Check out our installation guide at https://studio.appcues.com/settings/installation to get set up.`
        );
      } else if (
        userOperator === propertyOperators.GREATER_THAN_X_DAYS_AGO &&
        isInstalled
      ) {
        showError(
          "Heads up! You included activated users in your audience settings, but haven't chosen the user property to define their activation point. Make sure to select the property for your user's activation timestamp."
        );
      }
    }

    // Flow targeting
    const flowFilteredClauses = getFlowTargetingClauses(userPropertyClauses);
    const flowIds = getFlowIdsFromClauses(flowFilteredClauses);
    const targetedFlows = flowIds.map(flowId => {
      return steps[flowId] || flowId;
    });

    // Email excudes tareting
    const emailFilteredClauses = getEmailFilteredClauses(
      userPropertyClauses,
      meta
    );
    const emailDomainBlackList = getEmailDomainBlackList(emailFilteredClauses);

    return {
      userCreatedAtField: meta.userCreatedAtField,
      newUserClause,
      segmentClause,
      userOption,
      flowFilteredClauses,
      targetedFlows,
      userEmailField: meta.userEmailField,
      emailFilteredClauses,
      emailDomainBlackList: emailDomainBlackList || localEmailDomainBlackList,
    };
  }

  revertConditionsToDefault() {
    const { saveSatisfaction } = this.props;
    const newClauses = getFlattenedClausesFromNestedConditions(
      SATISFACTION_BASE_CONDITIONS
    );
    saveSatisfaction(newClauses);
    window.scrollTo(0, 0);
  }

  render() {
    const {
      className,
      actions,
      meta,
      isInstalled,
      rule,
      steps,
      clauses,
      satisfactionId,
      updateSatisfaction,
      saveSatisfaction,
      navigate: navigateTo,
      throttleEnabled,
    } = this.props;

    const {
      targetingOptionView,
      userPropertyClauses,
      surveyFrequencyClause,
      sessionPageViewClause,
      surveyDelayClause,
      surveyDelayExcludeClause,
      userOption,
      newUserClause,
      segmentClause,
      userCreatedAtField,
      userEmailField,
      flowFilteredClauses,
      targetedFlows,
      emailFilteredClauses,
      emailDomainBlackList,
      sessionRandomizerClause,
      baseNPSConditionsFound,
    } = this.state;

    const audienceSettingsDesc =
      "Based on our research, we've set some default audience targeting for you, but you know your app and users best. When choosing additional targeting, be cognizant of how users interact with your product so that your NPS doesn't interrupt their primary tasks.";

    return (
      <Panel
        name="Segment Your NPS Audience"
        description={baseNPSConditionsFound ? audienceSettingsDesc : ''}
        className={`${className} satisfaction-form page-container`}
      >
        {baseNPSConditionsFound && (
          <div>
            <SatisfactionPageSettings
              targetingOptionView={targetingOptionView}
              setTargetingOptionView={view => this.setTargetingOptionView(view)}
              rule={rule}
              clauses={clauses}
              meta={meta}
              updateSatisfaction={updateSatisfaction}
              saveSatisfaction={newClauses => saveSatisfaction(newClauses)}
              areConditionsFromTemplate={detectConditionsTemplate(clauses)}
            />

            <SatisfactionFrequencySettings
              actions={actions}
              clauses={clauses}
              targetingOptionView={targetingOptionView}
              setTargetingOptionView={view => this.setTargetingOptionView(view)}
              updateSatisfaction={updateSatisfaction}
              flowFilteredClauses={flowFilteredClauses}
              surveyFrequencyClause={surveyFrequencyClause}
              sessionPageViewClause={sessionPageViewClause}
              surveyDelayClause={surveyDelayClause}
              surveyDelayExcludeClause={surveyDelayExcludeClause}
              saveSatisfaction={newClauses => saveSatisfaction(newClauses)}
            />

            <SatisfactionAudienceSettings
              actions={actions}
              targetingOptionView={targetingOptionView}
              setTargetingOptionView={view => this.setTargetingOptionView(view)}
              meta={meta}
              isInstalled={isInstalled}
              steps={steps}
              clauses={clauses}
              userPropertyClauses={userPropertyClauses}
              newUserClause={newUserClause}
              segmentClause={segmentClause}
              userOption={userOption}
              userCreatedAtField={userCreatedAtField}
              userEmailField={userEmailField}
              surveyFrequencyValue={
                surveyFrequencyClause && surveyFrequencyClause.value
              }
              flowFilteredClauses={flowFilteredClauses}
              targetedFlows={targetedFlows}
              userPropertyClausesParentId={sessionPageViewClause.parentId}
              emailFilteredClauses={emailFilteredClauses}
              emailDomainBlackList={emailDomainBlackList}
              updateSatisfaction={updateSatisfaction}
              saveSatisfaction={newClauses => saveSatisfaction(newClauses)}
            />

            <SatisfactionSampleSettings
              targetingOptionView={targetingOptionView}
              setTargetingOptionView={view => this.setTargetingOptionView(view)}
              clauses={clauses}
              sessionRandomizerClause={sessionRandomizerClause}
              updateSatisfaction={updateSatisfaction}
              saveSatisfaction={newClauses => saveSatisfaction(newClauses)}
            />

            {throttleEnabled && (
              <NPSThrottleWarning
                warnText="your users may not see this NPS survey right away."
                checkboxLabel="Override frequency limit on this NPS survey"
                ruleId={satisfactionId}
              />
            )}
          </div>
        )}
        {!baseNPSConditionsFound && (
          <div className="nps-advanced-audience-buttons">
            <Button
              onClick={() => navigateTo(`/nps/${satisfactionId}/settings`)}
            >
              Edit Custom Settings
            </Button>
            <Button onClick={() => this.revertConditionsToDefault()}>
              Use Standard NPS Targeting
            </Button>
          </div>
        )}
      </Panel>
    );
  }
}

SatisfactionAudienceEdit.propTypes = {
  className: PropTypes.string,
  satisfactionId: PropTypes.string,
  actions: PropTypes.object,
  meta: PropTypes.object,
  isInstalled: PropTypes.bool,
  steps: PropTypes.object,
  clauses: PropTypes.array,
  showError: PropTypes.func,
  updateSatisfaction: PropTypes.func,
  saveSatisfaction: PropTypes.func,
  navigate: PropTypes.func,
  throttleEnabled: PropTypes.bool,
};

const mapStateToProps = state => {
  const meta = selectAccountMeta(state);

  return {
    throttleEnabled: meta?.throttleEnabled,
  };
};

export default connect(mapStateToProps, { navigate })(
  styled(SatisfactionAudienceEdit)`
    &.panel {
      width: 55%;
      justify-content: center;
      flex-direction: column;

      hr {
        height: 0;
        border: none;
        border-bottom: 2px solid ${props => props.theme['$gray-1']};
        margin: 1.25rem 0;
      }

      .satisfaction-card-description {
        display: flex;
        flex-direction: column;
      }

      .settings-label {
        font-weight: 600;

        &.install-prompt {
          margin-top: 15px;
          padding: 10px;
          background-color: #fdf1df;

          p {
            margin-bottom: 0px;
            color: #e18f0e;
            font-weight: 400;
          }
        }
      }

      .satisfaction-card-setting {
        margin: 25px 0px;

        &:last-child {
          margin-bottom: 0px;
        }

        .appcuesNPS {
          color: #848484;
          margin-left: 5px;
        }

        &.audience-user-days > div > div,
        &.sampling-range > div > div {
          border-radius: 4px;

          > :first-child {
            border-radius: 4px;
          }
        }

        .satisfaction-flow-targeting-description {
          margin: 11px 0;
        }

        .targeted-flows-list {
          margin: 15px 0;

          p:first-child {
            text-decoration: underline;
            margin-bottom: 5px;
          }

          p {
            margin-bottom: 0px;
          }

          i {
            cursor: pointer;

            &.delete-flow {
              margin-left: 10px;
            }
          }
        }
      }

      .nps-advanced-audience-buttons {
        text-align: center;

        .button {
          margin-left: 10px;
        }
      }
    }
  `
);
