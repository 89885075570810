import React from 'react';
import styled from 'styled-components';

import { ExternalLink } from '@studio/legacy-components';
import CenteredPage from 'components/Common/CenteredPage';
import UFOIllustration from 'assets/images/ufo-illustration.svg';

const Illustration = styled.img`
  width: 300px;
  margin-bottom: 20px;
`;

const RouteNotFound = () => {
  return (
    <CenteredPage>
      <Illustration src={UFOIllustration} />
      <h1>404</h1>
      <p>
        The page you are looking for seems to have disappeared... 👽 The
        navigation to the left might help get you back on track.
      </p>
      <p>
        Please shoot us a note if we made a mistake{' '}
        <ExternalLink href="mailto:folks@appcues.com">
          folks@appcues.com
        </ExternalLink>
      </p>
    </CenteredPage>
  );
};

export default RouteNotFound;
