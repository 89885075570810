import React, { useCallback, useEffect, useMemo, useState } from 'react';
import countryList from 'react-select-country-list';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import pick from 'lodash.pick';

import { ErrorMsg, Input, Select } from '@studio/legacy-components';
import toast from 'next/lib/toast';
import { asPromised } from 'utils/as-promised';
import { BILLING_FIELDS, haveValuesChanged } from 'utils/billing';
import { fetchPaymentTaxes } from 'actions/newBilling';

import {
  CheckoutLabel,
  StyledHeading,
  StyledPanel,
  TwoColumnWrapper,
} from './styled';

const allValuesExist = values => {
  return BILLING_FIELDS.reduce((prev, curr) => prev && !!values[curr], true);
};

export const BillingAddressForm = ({
  errors,
  formValues,
  onCalculateTaxes,
  onCompleteForm,
  planPrice,
}) => {
  const [submittedValues, setSubmittedValues] = useState({});
  const options = useMemo(() => countryList().getData(), []);

  // Make sure we reset the form state once we load the component
  useEffect(() => {
    if (!submittedValues || !allValuesExist(formValues)) {
      onCompleteForm({
        billing: false,
      });
    } else if (allValuesExist(formValues)) {
      onCompleteForm({
        billing: true,
      });
    }
  }, [submittedValues, formValues]);

  const triggerTaxesCalculation = useCallback(async () => {
    const { country, postalCode, line1, city } = formValues;

    setSubmittedValues(pick(formValues, BILLING_FIELDS));

    onCompleteForm({
      billing: true,
    });

    try {
      await onCalculateTaxes({
        billingAddress: {
          country: country.value,
          city,
          line1,
          postalCode,
        },
        amount: planPrice,
      });
    } catch (error) {
      toast.error(error.message);
    }
  });

  useEffect(() => {
    if (
      allValuesExist(formValues) &&
      haveValuesChanged(formValues, submittedValues)
    ) {
      triggerTaxesCalculation();
    }
  }, [allValuesExist, formValues, submittedValues, triggerTaxesCalculation]);

  return (
    <StyledPanel>
      <StyledHeading>Billing Address</StyledHeading>
      <CheckoutLabel noMargin>
        Country
        <Field name="country">
          {({ field: { name, value }, form: { setFieldValue } }) => (
            <Select
              autoComplete="off"
              value={value || { label: '', value: '' }}
              error={!!errors.country}
              options={options}
              placeholder=""
              onChange={country => setFieldValue(name, country)}
            />
          )}
        </Field>
        {errors.country && <ErrorMsg>{errors.country}</ErrorMsg>}
      </CheckoutLabel>
      <CheckoutLabel>
        Billing address
        <Field
          as={Input}
          type="text"
          name="line1"
          placeholder=""
          error={!!errors.line1}
        />
        {errors.line1 && <ErrorMsg>{errors.line1}</ErrorMsg>}
      </CheckoutLabel>
      <TwoColumnWrapper>
        <CheckoutLabel>
          Postal code
          <Field
            as={Input}
            type="text"
            name="postalCode"
            placeholder=""
            error={!!errors.postalCode}
          />
          {errors.postalCode && <ErrorMsg>{errors.postalCode}</ErrorMsg>}
        </CheckoutLabel>
        <CheckoutLabel>
          City
          <Field
            as={Input}
            type="text"
            name="city"
            placeholder=""
            error={!!errors.city}
          />
          {errors.city && <ErrorMsg>{errors.city}</ErrorMsg>}
        </CheckoutLabel>
      </TwoColumnWrapper>
    </StyledPanel>
  );
};

BillingAddressForm.propTypes = {
  errors: PropTypes.object,
  formValues: PropTypes.object,
  onCalculateTaxes: PropTypes.func,
  onCompleteForm: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  onCalculateTaxes: payload => asPromised(dispatch, fetchPaymentTaxes(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingAddressForm);
