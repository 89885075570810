import { call, all, getContext, put, takeEvery } from 'redux-saga/effects';
import { patterns as pinPatterns } from 'next/entities/pins';
import { patterns as experiencePatterns } from 'next/entities/experiences';
import { replacePagePatternFor } from 'next/entities/page';
import { patterns, reject, replace, readOne, resolve } from './actions';
import { parse } from './shape';

/**
 * Normalize users summary response as collection
 *
 * @param {Response} response - Raw account-users summary response
 * @return {Collection} AccountUser collection
 */
const transform = response =>
  response.reduce((acc, { user }) => {
    acc[user.id] = parse(user);
    return acc;
  }, {});

function* fetchUsers() {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getAccountUsers);
    yield put(resolve(transform(response)));
  } catch (error) {
    yield put(reject(error));
  }
}

function* fetchUser({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    const { user } = yield call(api.getAccountUser, id);
    yield put(replace({ ...user, id }));
  } catch (error) {
    yield put(reject(error));
  }
}

function* fetchExperienceUsers({ payload: { createdBy, updatedBy } }) {
  yield all([
    call(fetchUser, readOne(createdBy)),
    call(fetchUser, readOne(updatedBy)),
  ]);
}

export default function* saga() {
  yield takeEvery(patterns.read, fetchUsers);

  // External actions
  yield takeEvery(
    [pinPatterns.replace, experiencePatterns.replace],
    fetchExperienceUsers
  );

  // Page actions
  yield takeEvery(
    [
      replacePagePatternFor('/flows'),
      replacePagePatternFor('/flows/:flowId/settings'),
      replacePagePatternFor('/pins'),
      replacePagePatternFor('/banners'),
    ],
    fetchUsers
  );
}
