import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { format, isBefore, startOf } from 'next/lib/date';
import { Property, DateTimeWrapper } from './styled';

const DATE_FORMAT = 'M/DD/YY';
const TIME_FORMAT = 'h:mm a';

export function Datetime({ prefix, value }) {
  const start = useMemo(() => {
    return startOf(Date.now(), 'day');
  }, []);

  const tokens = useMemo(() => {
    return isBefore(value, start) ? DATE_FORMAT : TIME_FORMAT;
  }, [start, value]);

  return (
    <DateTimeWrapper>
      <Property>
        {prefix} {format(value, tokens)}
      </Property>
    </DateTimeWrapper>
  );
}

Datetime.propTypes = {
  prefix: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Datetime;
