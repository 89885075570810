import { normalizeDates } from '@studio/legacy-components';
import {
  add,
  endOf,
  isAfter,
  isBefore,
  startOf,
  subtract,
} from 'next/lib/date';

/**
 * Range error types
 */
export const RANGE_LIMIT_EXCEEDED = 'RANGE_LIMIT_EXCEEDED';
export const HISTORICAL_LIMIT_EXCEEDED = 'HISTORICAL_LIMIT_EXCEEDED';

/**
 * Determine earliest date allowed by historical bound
 *
 * @param {number} limit - Max historical days limit
 * @return {Date} Earliest date allowed
 */
const getHistoricalBound = limit => {
  return subtract(startOf(Date.now(), 'day'), limit - 1, 'days');
};

/**
 * Determine range bounds based on start, end, and max allowed duration
 *
 * @param {DateRange} dates - Range and start/end dates
 * @param {number} duration - Max range limit
 * @return {object<Date>} Lower and upper bound of allowed dates
 */
const getRangeBounds = ({ start, end }, duration) => ({
  lower: end && subtract(startOf(end, 'day'), duration - 1, 'days'),
  upper: start && add(endOf(start, 'day'), duration - 1, 'days'),
});

/**
 * Generate restricted days handlers. Specifically, restricts days that:
 *
 * 1. Are in the future
 * 2. Are before the allowed historical limit
 * 3. Would cause the start/end to be after/before end/start
 * 4. Would cause the range to exceed the allowed range limit
 *
 * @param {DateRange} dates - Range and start/end dates
 * @param {object} options - Bounding options
 * @param {number} options.limit - Max historical days limit
 * @param {number} options.duration - Max range duration limit
 * @return {object<function>} Restricted days handler
 */
export const restrict = (
  { range, ...dates } = {},
  { limit, duration } = {}
) => {
  // If range provided and not custom, ignore restrictions
  if (range && range !== 'custom') {
    return { start: () => false, end: () => false };
  }

  // Coerce into dates
  // NOTE: This step may be unnecessary
  const { start, end } = normalizeDates(dates);

  // Earliest start date possible based on allowed historical limit
  const earliest = getHistoricalBound(limit);

  // Derive lower and upper bounds based on max allowed range duration
  const { lower, upper } = getRangeBounds({ start, end }, duration);

  // Common preconditions for both start and end
  const base = normalized =>
    // Prevent dates after today
    isAfter(normalized, endOf(Date.now(), 'day')) ||
    // Preview dates before historical bound
    Boolean(limit && isBefore(normalized, earliest));

  return {
    start: date => {
      // Normalize start to start of the day
      // NOTE: This step may be unnecessary
      const { start: normalized } = normalizeDates({ start: date });

      return (
        base(normalized) ||
        // If end date exists and valid, prevent start date after end date
        Boolean(end && !base(end) && isAfter(normalized, end)) ||
        // If end date and duration exists and end date is valid, prevent start
        // date before allowed max days
        Boolean(end && duration && !base(end) && isBefore(normalized, lower))
      );
    },

    end: date => {
      // Normalize end to end of the day
      // NOTE: This step may be unnecessary
      const { end: normalized } = normalizeDates({ end: date });

      return (
        base(normalized) ||
        // If start date exists and valid, prevent end date before start date
        Boolean(start && !base(start) && isBefore(normalized, start)) ||
        // If start date and duration exists and start date is valid, prevent
        // end date after allowed max days
        Boolean(start && duration && !base(start) && isAfter(normalized, upper))
      );
    },
  };
};

/**
 * Validate that date range is valid. Specifically, verify that:
 *
 * 1. Neither date is in the future
 * 2. Neither date is before the allowed historical limit
 * 3. Neither start/end is after/before end/start
 * 4. The duration of the range does not exceed the allowed range limit
 *
 * @param {DateRange} dates - Start and end dates
 * @param {object} options - Bounding options
 * @param {number} options.limit - Max historical days limit
 * @param {number} options.duration - Max range duration limit
 * @return {string|boolean|null} Validation error or null
 */
export const validate = (
  { range, ...dates } = {},
  { limit, duration } = {}
) => {
  // If range provided and not custom, ignore validation
  if (range && range !== 'custom') {
    return null;
  }

  // Coerce into dates
  // NOTE: This step may be unnecessary
  const { start, end } = normalizeDates(dates);

  // Check if either date is in the future
  const today = endOf(Date.now(), 'day');
  if ((start && isAfter(start, today)) || (end && isAfter(end, today))) {
    return true;
  }

  // Check if range exceeds allowed historical days if restriction exists
  const earliest = getHistoricalBound(limit);
  if (limit != null) {
    // If start exists and before historical cutoff
    if (start && isBefore(start, earliest)) {
      return HISTORICAL_LIMIT_EXCEEDED;
    }

    // If end exists and before historical cutoff
    if (end && isBefore(end, earliest)) {
      return HISTORICAL_LIMIT_EXCEEDED;
    }
  }

  // Short circuit bound check for sparse date ranges
  if (!start || !end) {
    return null;
  }

  // Check if start after end or end before start
  if (isAfter(start, end) || isBefore(end, start)) {
    return true;
  }

  // Check if range is greater than max allowed range if restriction exists
  const { lower, upper } = getRangeBounds({ start, end }, duration);
  if (duration != null) {
    if (isBefore(start, lower)) {
      return RANGE_LIMIT_EXCEEDED;
    }

    if (isAfter(end, upper)) {
      return RANGE_LIMIT_EXCEEDED;
    }
  }

  return null;
};
