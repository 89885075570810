import React from 'react';
import {
  AutoSizer,
  List as VirtualList,
  WindowScroller,
} from 'react-virtualized';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { H3 } from '@appcues/component-library';
import { selectSortedFilteredCharts } from 'reducers/account/charts';
import ChartCard from 'components/Insights/Charts/ChartCard';

import 'react-virtualized/styles.css';

const ChartsContainer = styled.div`
  margin: 14px 0;
  width: 100%;

  > div:first-child {
    /* to fix the glitch of react-virtualized with the height with AutoSizer */
    height: auto !important;
  }

  /* to preserve the shadows in the cards */
  .ReactVirtualized__Grid {
    outline: none;
    overflow: visible !important;
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }
`;

export const ChartList = ({ charts = [], endTime }) => {
  if (charts.length === 0) {
    return (
      <H3 marginTop={14} marginLeft={8}>
        No charts match your filters.
      </H3>
    );
  }

  const renderRow = ({ index, key, style }) => {
    const chart = charts[index];
    const rowStyle = { ...style, paddingBottom: '30px' };

    return (
      <div key={key} style={rowStyle}>
        <ChartCard id={chart.id} endTime={endTime} />
      </div>
    );
  };

  return (
    <ChartsContainer>
      <WindowScroller>
        {({ height, isScrolling, onChildScroll, scrollTop }) => (
          <AutoSizer>
            {({ width }) => (
              <VirtualList
                autoHeight
                height={height}
                overscanRowCount={1}
                rowCount={charts.length}
                rowHeight={407}
                rowRenderer={renderRow}
                isScrolling={isScrolling}
                onScroll={onChildScroll}
                scrollTop={scrollTop}
                width={width}
              />
            )}
          </AutoSizer>
        )}
      </WindowScroller>
    </ChartsContainer>
  );
};

ChartList.propTypes = {
  charts: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string,
      created_by: PropTypes.string,
      events: PropTypes.arrayOf(
        PropTypes.shape({
          source: PropTypes.string,
          event: PropTypes.string,
        })
      ),
      id: PropTypes.string,
      name: PropTypes.string,
      segment_id: PropTypes.string,
      trailing_days: PropTypes.number,
      updated_at: PropTypes.string,
      updated_by: PropTypes.string,
    })
  ),
  endTime: PropTypes.number.isRequired,
  searchTerm: PropTypes.string,
  sortOption: PropTypes.string,
};

const mapStateToProps = (state, { searchTerm, sortOption }) => {
  return {
    charts: selectSortedFilteredCharts(state, { searchTerm, sortOption }),
  };
};

export default connect(mapStateToProps)(ChartList);
