import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Button, H2, Link, Overflow, Text } from '@studio/legacy-components';
import { read as fetchAccountEntitlements } from 'actions/entitlements';
import {
  PlanFeatureList,
  PlanHeader,
  SubscriptionCardWrapper,
} from 'components/Subscription/styled';
import {
  FeatureItem,
  FeatureCheck,
} from 'components/SubscriptionPicker/styled';
import CostBreakdownModalManager from 'components/Subscription/CostBreakdownModalManager';
import { ENTITLEMENTS } from 'constants/features';
import { selectAccountFeature } from 'reducers/account/features';
import { selectIsPlanCanceled } from 'reducers/account/meta';
import { buildQueryParams } from 'utils/billing';
import { numberWithCommas } from 'utils/textFormatting';

export const YourPlan = ({
  hasEntitlements,
  isPlanCanceled,
  onLoad,
  planData,
  mausProcessed,
}) => {
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <SubscriptionCardWrapper kind="compliant">
      <PlanHeader>
        <Overflow>
          <H2 type="secondary">{`Appcues ${planData.name} Plan`}</H2>
          {hasEntitlements && (
            <CostBreakdownModalManager
              plan={{
                description: `${planData.name} (${numberWithCommas(
                  mausProcessed.availableUnits
                )} MAUs)`,
                price: planData.price,
                interval: planData.planInterval,
              }}
            />
          )}
        </Overflow>
        {hasEntitlements && !isPlanCanceled && (
          <Button
            kind="secondary"
            forwardedAs={Link}
            to={`/subscription/upgrade?${buildQueryParams(planData)}`}
          >
            Upgrade plan
          </Button>
        )}
      </PlanHeader>

      <PlanFeatureList>
        <Text bold>Includes</Text>
        {Object.keys(planData.features).map(feature => (
          <FeatureItem key={feature}>
            <FeatureCheck selected color={planData.color} icon="check-circle" />
            <Text>{feature}</Text>
          </FeatureItem>
        ))}
      </PlanFeatureList>
    </SubscriptionCardWrapper>
  );
};

const mapStateToProps = state => {
  return {
    hasEntitlements: selectAccountFeature(state, ENTITLEMENTS),
    isPlanCanceled: selectIsPlanCanceled(state),
  };
};

const mapDispatchToProps = {
  onLoad: fetchAccountEntitlements,
};

export default connect(mapStateToProps, mapDispatchToProps)(YourPlan);
