import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon, Link, ListRow } from '@studio/legacy-components';
import { archive, clone, remove, unarchive } from 'next/entities/experiences';
import { selectFeature, LAUNCHPADS_V2_ANALYTICS } from 'next/entities/features';
import { ActionsMenu } from 'next/components/Listing';
import ExperienceContext from 'next/components/ExperienceContext';

export function Actions({
  archived,
  onArchive,
  onClone,
  onDelete,
  onOpenBuilder,
  onOpenEditTags,
  onUnarchive,
  experienceId,
  hasLaunchpadAnalyticsEnabled,
}) {
  const { routePath } = useContext(ExperienceContext);

  const showAnalyticsOption =
    routePath !== '/launchpads' || hasLaunchpadAnalyticsEnabled;

  if (archived) {
    return (
      <ActionsMenu>
        <ListRow prefix={<Icon icon="upload" />} onClick={onUnarchive}>
          Restore
        </ListRow>
        <ListRow prefix={<Icon icon="trash-alt" />} onClick={onDelete}>
          Delete
        </ListRow>
      </ActionsMenu>
    );
  }

  return (
    <ActionsMenu>
      <ListRow prefix={<Icon icon="edit" />} onClick={onOpenBuilder}>
        Open in Builder
      </ListRow>
      {showAnalyticsOption && (
        <ListRow
          forwardedAs={Link}
          prefix={<Icon icon="chart-bar" />}
          to={`${routePath}/${experienceId}/analytics`}
        >
          Analytics
        </ListRow>
      )}
      <ListRow
        forwardedAs={Link}
        prefix={<Icon icon="cog" />}
        to={`${routePath}/${experienceId}/settings`}
      >
        Settings
      </ListRow>
      <ListRow prefix={<Icon icon="tag" />} onClick={onOpenEditTags}>
        Edit tags
      </ListRow>
      <ListRow prefix={<Icon icon="copy" />} onClick={onClone}>
        Clone
      </ListRow>
      <ListRow prefix={<Icon icon="archive" />} onClick={onArchive}>
        Archive
      </ListRow>
    </ActionsMenu>
  );
}

Actions.propTypes = {
  archived: PropTypes.bool,
  onArchive: PropTypes.func,
  onClone: PropTypes.func,
  onDelete: PropTypes.func,
  onOpenBuilder: PropTypes.func,
  onUnarchive: PropTypes.func,
  experienceId: PropTypes.string,
  onOpenEditTags: PropTypes.func,
  hasLaunchpadAnalyticsEnabled: PropTypes.bool,
};

const mapDispatchToProps = (dispatch, { experienceId }) => ({
  onArchive: () => dispatch(archive(experienceId)),
  onClone: () => dispatch(clone(experienceId)),
  onDelete: () => dispatch(remove(experienceId)),
  onUnarchive: () => dispatch(unarchive(experienceId)),
});

const mapStateToProps = state => ({
  hasLaunchpadAnalyticsEnabled: selectFeature(state, LAUNCHPADS_V2_ANALYTICS),
});

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
