import { createEntityReducer } from 'next/lib/collections';
import { selectRequestStatus } from 'next/entities/requests';
import { TYPE } from './actions';

export default createEntityReducer(TYPE);

export const selectAccount = state => state[TYPE];

export const readAccount = state => ({
  ...selectRequestStatus(state, TYPE),
  data: selectAccount(state),
});

export const selectPublishedRules = state => state.account.publishedRules;
