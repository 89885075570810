import { getLeafNodes } from '@studio/conditions';

const BRANCH_KEYS = new Set(['and', 'or', 'not']);

const NON_AUDIENCE_TYPES = ['trigger', 'url', 'domains', 'frequency'];

export const evaluateConditions = conditions => {
  if (conditions.or) {
    return conditions.or.some(evaluateConditions);
  }
  if (conditions.and) {
    return conditions.and.every(evaluateConditions);
  }
  if (conditions.not) {
    return !conditions.not.every(evaluateConditions);
  }
  const { _qualified: qualified } = conditions;
  return qualified;
};

export const filterConditions = (
  conditions,
  conditionsToFilter = ['url', 'domains', 'app', 'version', 'screen', 'trigger']
) => {
  const key = Object.keys(conditions).find(k => BRANCH_KEYS.has(k));
  if (BRANCH_KEYS.has(key)) {
    const condition = {
      [key]: conditions[key]
        .filter(
          currCondition =>
            !conditionsToFilter.some(currKey => currCondition[currKey])
        )
        .map(currCondition =>
          filterConditions(currCondition, conditionsToFilter)
        )
        .filter(({ and, or, not }) => {
          const branch = and || or || not;
          return !branch || branch?.length > 0;
        }),
    };

    return condition;
  }
  return conditions;
};

export const sectionsTitle = {
  audience: 'Audiences',
  ab: 'AB Testing',
  frequency: 'Frequency',
};

export const getConditionsSections = conditions => {
  const { and: branches } = conditions;

  return {
    audience: branches.find(branch =>
      getLeafNodes(branch).every(
        clause =>
          NON_AUDIENCE_TYPES.every(type => !clause[type]) &&
          clause.properties?.property !== '_ABGroup'
      )
    ),
    ab: branches.find(condition =>
      getLeafNodes(condition.and || condition.or).find(
        ({ properties }) => properties && properties.property === '_ABGroup'
      )
    ),
    frequency: branches.find(condition =>
      getLeafNodes(condition.and || condition.or).find(
        ({ frequency }) => frequency
      )
    ),
  };
};

export const getEligibilityConditionsSections = sections => {
  return Object.keys(sections)
    .filter(key => !!sections[key])
    .reduce((acc, key) => {
      acc[key] = sections[key];
      acc[key].eligible = evaluateConditions(sections[key]);
      return acc;
    }, {});
};
