import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import hash from 'object-hash';
import isEqual from 'lodash.isequal';
import {
  addClause,
  addGroupClause,
  deleteClause,
  getNestedConditionsFromFlattenedClauses,
  getFlattenedClausesFromNestedConditions,
  clauseKeys,
} from '@appcues/libcues';
import {
  Column,
  CPage,
  CPanel,
  CButton,
  CIconButton,
  CBanner,
  H3,
  Text,
  CDialog,
} from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';
import { DocumentTitle } from 'next/hooks/use-title';
import ChildPage from 'components/Common/ChildPage';
import GroupClause from 'components/Common/Clauses/Group';
import { showModal } from 'actions/currentModal';
import { navigate } from 'actions/routing';
import { updateUserUsageProperty } from 'actions/user';
import { trackEvent } from 'actions/events';
import { callApi as fetchRules } from 'actions/account/rules';
import { create, update } from 'actions/account/segments';
import { selectUserUsageProperties } from 'reducers/user';
import { selectConditionsBySegmentId } from 'reducers/account/conditions';
import { selectAccountSatisfactionSegmentId } from 'selectors/satisfaction';
import { selectNumberOfMatchingPins } from 'selectors/segments';
import {
  selectAccountSegments,
  selectAccountSegmentsSynced,
} from 'reducers/account/segments';
import { selectAccountMeta } from 'reducers/account/meta';
import { UPDATE_SEGMENT_MODAL } from 'constants/globals';
import {
  dissolveGroupClause,
  replaceClause,
  updateClause,
} from 'transforms/clauses';
import { setSegmentCustomSegment } from 'helpers/segments';
import {
  getMatchingChecklists,
  getMatchingSegments,
  isLifecycleSegment as getIsLifecycleSegment,
} from 'utils/segments';
import { selectAccountChecklists } from 'entities/checklists';
import SegmentMeta from './SegmentMeta';
import SegmentInfoCard from './SegmentInfoCard';

export class SegmentsEdit extends Component {
  state = {
    error: '',
    showNavigationModal: false,
    showSegmentInfoCard: true,
    name: '',
    description: '',
    clauses: [],
    meta: null,
  };

  UNSAFE_componentWillMount() {
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentDidMount() {
    const { onLoad } = this.props;
    onLoad();
    // eslint-disable-next-line new-cap
    this.UNSAFE_componentWillReceiveProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { segmentId: routeSegmentId, segments } = nextProps;
    const { segmentId } = this.state;

    if (segmentId !== routeSegmentId) {
      this.initSegmentState(routeSegmentId, segments);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  resetSegmentState = () => {
    const { segmentId } = this.props;
    this.initSegmentState(segmentId);
  };

  matchesPLGSegments = segment => {
    if (segment && segment.meta && segment.meta.userLifecycleStage) {
      return ['beginners', 'explorers', 'regulars', 'champions'].includes(
        segment.meta.userLifecycleStage
      );
    }
    return false;
  };

  // eslint-disable-next-line consistent-return
  onUnload = e => {
    if (this.hasSegmentChanged()) {
      const confirmationMessage =
        'It looks like your Appcues segment has unsaved changes' +
        'If you leave before saving, your changes will be lost.';

      (e || window.event).returnValue = confirmationMessage; // Gecko + IE
      return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
    }
  };

  onClickSave = () => {
    const { name, description, clauses } = this.state;
    const {
      segmentId,
      isSegmentReferenced,
      segments,
      showModal: show,
      onCreate,
      onSave,
    } = this.props;

    if (!name) {
      this.setState({
        error: 'Please enter a name for your segment',
      });
    } else if (
      Object.values(segments || {}).some(
        it => it.id !== segmentId && it.name === name
      )
    ) {
      this.setState({
        error: `A segment by the name "${name}" already exists.`,
      });
    } else if (clauses.length === 0) {
      this.setState({
        error:
          'This segment has no conditions defined. Please add one or more conditions to define segment membership.',
      });
    } else {
      this.setState({ error: '' });

      const changes = {
        name,
        description,
        conditions: getNestedConditionsFromFlattenedClauses(
          clauses,
          null,
          clauseKeys
        ),
      };

      this.trackChanges(changes);

      if (isSegmentReferenced) {
        show(UPDATE_SEGMENT_MODAL, {
          segmentId,
          segmentType: 'segment',
          changes,
        });
      } else if (segmentId === 'new') {
        onCreate(changes);
      } else {
        onSave(segmentId, changes);
      }
    }
  };

  hasSegmentChanged = () => {
    const { segmentId, segments } = this.props;
    const { name, description, clauses } = this.state;
    const segment = (segments || {})[segmentId] || {};
    const conditions = segment.conditions || {};

    const nestedChanges = getNestedConditionsFromFlattenedClauses(
      clauses,
      null,
      clauseKeys
    );
    return (
      segmentId === 'new' ||
      (segment &&
        (name !== segment.name ||
          hash(nestedChanges) !== hash(conditions) ||
          description !== segment.description))
    );
  };

  setName = name => {
    this.setState({ name });
  };

  setDescription = description => {
    this.setState({ description });
  };

  onClickCancel = () => {
    const { navigate: navigateTo } = this.props;
    if (this.hasSegmentChanged()) {
      this.setState({ showNavigationModal: true });
    } else {
      navigateTo(`/segments`);
    }
  };

  onClickShowSegmentInfo = () => {
    const { onChangeShowInfoCard } = this.props;
    this.setState({ showSegmentInfoCard: true });
    onChangeShowInfoCard(true);
  };

  onClickHideSegmentInfo = () => {
    const { onChangeShowInfoCard } = this.props;
    this.setState({ showSegmentInfoCard: false });
    onChangeShowInfoCard(false);
  };

  onDismissModal = () => {
    this.setState({ showNavigationModal: false });
  };

  onAcceptModal = () => {
    const { navigate: navigateTo } = this.props;
    navigateTo(`/segments`);
  };

  /**
   * Constructs a new `state` based on the provided segmentId from which
   * changes to the actual segment are isolated.
   *
   * @param segmentId
   * @param segments
   */

  initSegmentState(segmentId, maybeSegments) {
    const { segments: initial } = this.props;
    const segments = maybeSegments || initial;

    const segment = (segments || {})[segmentId];

    if (segmentId === 'new') {
      this.setState({
        segmentId,
        name: '',
        description: '',
        clauses: setSegmentCustomSegment([]),
        error: '',
      });
    } else if (segment) {
      const { name, description, conditions, meta } = segment;
      const clauses =
        conditions && Object.keys(conditions).length > 0
          ? getFlattenedClausesFromNestedConditions(conditions)
          : setSegmentCustomSegment([]);
      this.setState({
        segmentId,
        name,
        description,
        clauses,
        error: '',
        meta,
      });
    }
  }

  trackChanges(updatedProps) {
    const {
      segmentId,
      segments,
      accountCreatedAt,
      trackEvent: track,
    } = this.props;
    const segment = (segments || {})[segmentId];
    const isLifecycleSegment = getIsLifecycleSegment(segment);

    if (!segment || updatedProps.name.trim() !== segment.name?.trim()) {
      track('Updated segment name', {
        isLifecycleSegment,
        newName: updatedProps.name,
        accountCreatedAt,
      });
    }
    if (
      !segment ||
      updatedProps.description.trim() !== segment.description?.trim()
    ) {
      track('Updated segment description', {
        isLifecycleSegment,
        newDescription: updatedProps.description,
        accountCreatedAt,
      });
    }
    if (!segment || !isEqual(updatedProps.conditions, segment.conditions)) {
      track('Updated segment conditions', {
        isLifecycleSegment,
        newConditions: updatedProps.conditions,
        accountCreatedAt,
      });
    }
  }

  breadcrumbContents() {
    const { segmentId, name } = this.state;
    const shouldRouteToIndex = segmentId === 'new';
    if (!shouldRouteToIndex) {
      return {
        label: name,
        path: `/segments/${segmentId}/view`,
      };
    }
    return {
      label: 'Segments',
      path: '/segments',
    };
  }

  render() {
    const { className, segmentId, segments, usageProperties, loaded } =
      this.props;
    const {
      name,
      description,
      clauses,
      error,
      showNavigationModal,
      showSegmentInfoCard,
      meta,
    } = this.state;
    const segment = (segments || {})[segmentId];
    const hasSegmentChanged = this.hasSegmentChanged();
    const showFromUsageProperties =
      typeof usageProperties.showSegmentInfoCard === 'undefined'
        ? true
        : usageProperties.showSegmentInfoCard;
    const segmentIsPLG = this.matchesPLGSegments(segment);

    const pageTitle = segment ? 'Edit segment' : 'Create segment';

    return (
      <ChildPage
        className={className}
        title={pageTitle}
        breadcrumbContents={this.breadcrumbContents()}
        isLoading={!loaded}
        subHeaderActions={
          <>
            {segmentIsPLG && (
              <CIconButton onClick={this.onClickShowSegmentInfo}>
                <Icon icon="info" />
              </CIconButton>
            )}
            <CButton type="secondary" onClick={this.onClickCancel}>
              Cancel
            </CButton>
            <CButton
              type="primary"
              isDisabled={!hasSegmentChanged}
              onClick={this.onClickSave}
            >
              {hasSegmentChanged ? 'Save' : 'Saved'}
            </CButton>
          </>
        }
      >
        <DocumentTitle
          title={`${
            segment ? `${name} | Segment` : 'Create Segment'
          } | Appcues`}
        />
        <CPage.Container>
          <Column>
            {error && <CBanner type="negative" text={error} />}
            {showNavigationModal && (
              <CDialog
                title="Discard changes?"
                type="negative"
                primaryActionText="Discard changes"
                onPrimaryAction={this.onAcceptModal}
                onSecondaryAction={this.onDismissModal}
              >
                Are you sure you want to discard the changes you have made to
                this segment? This cannot be undone.
              </CDialog>
            )}
            {segmentIsPLG && showSegmentInfoCard && showFromUsageProperties ? (
              <SegmentInfoCard
                lifecycleStage={segment.meta.userLifecycleStage}
                dismiss={this.onClickHideSegmentInfo}
              />
            ) : (
              ''
            )}
            <SegmentMeta
              segmentId={segmentId}
              name={name}
              description={description}
              setName={this.setName}
              setDescription={this.setDescription}
              meta={meta}
            />
            <CPanel marginBottom={300}>
              <H3 marginBottom={4}>Conditions</H3>
              <Text type="secondary" marginBottom={32}>
                Define what it means for a user to be in this segment.
              </Text>
              <GroupClause
                clauses={clauses}
                isRootNode
                segmentBlacklist={[segmentId]}
                addClause={(parentId, clause) =>
                  this.setState({
                    clauses: addClause(clauses, parentId, clause),
                  })
                }
                addGroupClause={(parentId, booleanOperator, childClauses) =>
                  this.setState({
                    clauses: addGroupClause(
                      clauses,
                      parentId,
                      booleanOperator,
                      childClauses
                    ),
                  })
                }
                updateClause={(clauseId, changes) =>
                  this.setState({
                    clauses: updateClause(clauses, clauseId, changes),
                  })
                }
                replaceClause={(clauseId, replacementClause) =>
                  this.setState({
                    clauses: replaceClause(
                      clauses,
                      clauseId,
                      replacementClause
                    ),
                  })
                }
                dissolveGroupClause={clauseId =>
                  this.setState({
                    clauses: dissolveGroupClause(clauses, clauseId),
                  })
                }
                deleteClause={clauseId =>
                  this.setState({
                    clauses: deleteClause(clauses, clauseId),
                  })
                }
              />
            </CPanel>
          </Column>
        </CPage.Container>
      </ChildPage>
    );
  }
}

function mapStateToProps(state, routeProps) {
  const { segmentId } = routeProps.match.params;
  return {
    segmentId,
    segments: selectAccountSegments(state),
    isSegmentReferenced:
      selectConditionsBySegmentId(state, { segmentId }).length > 0 ||
      getMatchingSegments(segmentId, selectAccountSegments(state)).length > 0 ||
      getMatchingChecklists(segmentId, selectAccountChecklists(state)).length >
        0 ||
      selectAccountSatisfactionSegmentId(state) === segmentId ||
      selectNumberOfMatchingPins(state, segmentId) > 0,
    usageProperties: selectUserUsageProperties(state),
    loaded: selectAccountSegmentsSynced(state),
    accountCreatedAt: selectAccountMeta(state).createdAt,
  };
}

const mapDispatchToProps = {
  onLoad: fetchRules,
  showModal,
  onCreate: create,
  onSave: update,
  navigate,
  trackEvent,
  onChangeShowInfoCard: value =>
    updateUserUsageProperty('showSegmentInfoCard', value),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styled(SegmentsEdit)`
  input.segment-name {
    width: 320px;
  }
  label textarea {
    max-width: 640px;
  }
  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .buttons {
      display: flex;
      align-items: center;
    }
    h1 {
      margin-top: 0;
      color: ${props => props.theme['$gray-70']};
      font-size: 36px;
      font-weight: 600;
    }
  }
  hr {
    height: 0;
    border: none;
    border-bottom: 2px solid ${props => props.theme['$gray-1']};
    margin: 1.25rem 0 2rem;
  }
`);
