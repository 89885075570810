import React from 'react';
import PropTypes from 'prop-types';
import { Popover, Button, Icon } from '@appcues/sonar';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import OpenInBuilderButton from 'components/OpenInBuilder/OpenInBuilderButton';

const CustomFieldInfoButton = ({ flowId, stepChildId }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Popover.Root
      open={isOpen}
      onOpenChange={setIsOpen}
      trigger={
        <Button iconOnly variant="tertiary">
          <Icon icon={faInfoCircle} />
        </Button>
      }
    >
      <Popover.Title>Custom field name</Popover.Title>
      <Popover.Description>
        This is an automatically generated field label. You can set a custom one
        on the field settings in the builder.
      </Popover.Description>
      <Popover.Action>
        <OpenInBuilderButton
          stepGroupId={flowId}
          stepChildId={stepChildId}
          render={handleClick => (
            <Button
              variant="secondary"
              onClick={() => {
                handleClick();
                setIsOpen(false);
              }}
            >
              Open in builder
            </Button>
          )}
        />
      </Popover.Action>
      <Popover.Close>
        <Popover.IconClose />
      </Popover.Close>
    </Popover.Root>
  );
};

CustomFieldInfoButton.propTypes = {
  flowId: PropTypes.string.isRequired,
  stepChildId: PropTypes.string,
};

export default CustomFieldInfoButton;
