import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { getAudienceConditions } from '@studio/conditions';
import {
  readDomainsForExperience,
  readSegmentsForExperience,
  readTagsForFlow,
} from 'next/lib/selectors';
import { openFlow } from 'next/lib/builder';
import { Shape as FlowShape, update } from 'next/entities/flows';
import {
  MOBILE_DEFAULT_TYPE,
  MOBILE_EMBED_TYPE,
  update as updateMobile,
} from 'next/entities/experiences';
import { selectFlowAnalyticsById } from 'next/entities/analytics';
import { Experience, Metric } from 'next/components/Listing';
import useToggle from 'next/hooks/use-toggle';
import { OpenInBuilderManager } from 'next/components/OpenInBuilder';
import { selectRule } from 'next/entities/rules';
import { EditTagsModal } from 'next/components/Tags';
import { selectApp } from 'next/entities/apps';
import {
  canOpenInBuilder,
  getChromeExtensionVersion,
} from 'utils/chromeExtension';
import Actions from './Actions';
import CloneMobileFlowModal from './CloneMobileFlowModal';
import { FlowCardWrapper } from './styled';

export function FlowCard({
  domains,
  flow,
  segments,
  tags,
  view,
  app,
  analytics,
  onChange,
  style,
  isScrolling,
}) {
  const { id, previewUrl, state, type } = flow;
  const [openBuilder, toggleOpenBuilder] = useToggle();
  const [editingTags, toggleEditingTags] = useToggle();
  const [cloningMobileFlow, toggleCloneModal] = useToggle();

  const handleOpenInBuilder = useCallback(async () => {
    try {
      /* global CRX_ID */
      const chromeExtensionVersion = await getChromeExtensionVersion(CRX_ID);
      const isLatestVersion = canOpenInBuilder(chromeExtensionVersion);

      if (isLatestVersion) {
        openFlow({ flow: id, url: previewUrl });
      } else {
        window.open(`/edit-in-chrome/stepGroup/${id}`, '_blank');
      }
    } catch {
      window.open(`/edit-in-chrome/stepGroup/${id}`, '_blank');
    }
  }, [id, previewUrl]);

  // TODO: Consider abstracting as separate component e.g. FlowMetrics
  const metric = useMemo(() => {
    if (!analytics) {
      return null;
    }
    const users = analytics.flow_users || analytics.experience_users || 0;
    const completed =
      analytics.flow_completed_users ||
      analytics.experience_completed_users ||
      0;

    const label = [
      `${users} Unique user${users > 1 ? 's' : ''}`,
      `${Number.parseInt(completed, 10)} Completed`,
    ];

    return <Metric icon="chart-bar" label={label} />;
  }, [analytics]);

  const isMobile = type === MOBILE_DEFAULT_TYPE || type === MOBILE_EMBED_TYPE;
  const flowType = isMobile ? 'mobile' : 'flows';

  const handleTagsUpdate = tagIds => {
    onChange(id, { tagIds });
  };

  return (
    <FlowCardWrapper style={style}>
      <Experience
        actions={
          <Actions
            archived={state === 'ARCHIVED'}
            id={id}
            type={flowType}
            experimentState={flow.experiment?.state}
            onOpenBuilder={toggleOpenBuilder}
            onOpenEditTags={toggleEditingTags}
            onToggleCloneModal={toggleCloneModal}
          />
        }
        experience={flow}
        domains={domains}
        metric={metric}
        segments={segments}
        tags={tags}
        type={flowType}
        view={view}
        app={app}
        style={style}
        isScrolling={isScrolling}
      />
      {flowType !== 'mobile' && (
        <OpenInBuilderManager
          onClose={toggleOpenBuilder}
          type={flowType}
          onNavigate={handleOpenInBuilder}
          visible={openBuilder}
        />
      )}
      <EditTagsModal
        tags={tags?.data}
        onClose={toggleEditingTags}
        visible={editingTags}
        label={flow.name}
        onChange={handleTagsUpdate}
      />
      {isMobile && (
        <CloneMobileFlowModal
          id={id}
          isVisible={cloningMobileFlow}
          onToggleModal={toggleCloneModal}
        />
      )}
    </FlowCardWrapper>
  );
}

FlowCard.propTypes = {
  ...Experience.propTypes,

  flow: FlowShape,
};

const mapDispatchToProps = (dispatch, { flow }) => {
  const onUpdate = ['mobile', 'mobile_embed'].includes(flow?.type)
    ? updateMobile
    : update;

  return {
    onChange: (id, delta) => dispatch(onUpdate(id, delta)),
  };
};

const mapStateToProps = (state, { flow }) => {
  const rule = selectRule(state, flow.id) || {};
  const audienceConditions = getAudienceConditions(rule.conditions);
  const showSegmentsBadge =
    audienceConditions.some(({ segments }) => Boolean(segments)) ||
    audienceConditions.length === 0;

  return {
    domains: readDomainsForExperience(state, flow.id),
    ...(showSegmentsBadge && {
      segments: readSegmentsForExperience(state, flow.id),
    }),
    tags: readTagsForFlow(state, flow.id, flow.type),
    analytics: selectFlowAnalyticsById(state, flow.id),
    app: selectApp(state, flow.appId),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlowCard);
