import React from 'react';
import { PropTypes } from 'prop-types';
import { Alert } from '@appcues/sonar';
import { isAfter } from 'next/lib/date';
import { ScheduleType } from './types';
import { formatScheduleDate } from './dates';

export const SchedulingStartAlert = ({ schedule, experienceType }) => {
  const shouldRenderScheduleAlert =
    schedule && isAfter(schedule.startDate, new Date());

  if (!shouldRenderScheduleAlert) return null;

  return (
    <Alert.Root variant="warning">
      <Alert.Message>
        <Alert.Title>
          This {experienceType} is scheduled to publish on{' '}
          {formatScheduleDate(schedule.startDate)}
        </Alert.Title>
        <Alert.Description>
          Publishing now will override the schedule and the {experienceType}{' '}
          will publish immediately.
        </Alert.Description>
      </Alert.Message>
    </Alert.Root>
  );
};

SchedulingStartAlert.propTypes = {
  schedule: ScheduleType,
  experienceType: PropTypes.string,
};

export default SchedulingStartAlert;
