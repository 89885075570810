import styled from 'styled-components';
import { Paragraph } from '@studio/legacy-components';
import Reports from 'next/assets/images/reports.svg';

export const NotPublishedWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  height: 100%;
`;

export const NotPublishedImage = styled.img.attrs({
  src: Reports,
})``;

export const NotPublishedDescription = styled(Paragraph)`
  width: 490px;
  text-align: center;
`;
