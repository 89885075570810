import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { StoreContext } from 'next/root/store';

/**
 * NOTE: This component acts as wrapper that provides access to the Next Store
 *       to its children. This is meant to be used in old studio where new code
 *       might be introduced that needs access to the next store. i.e.
 *       Navigation, Installation & Apps. This component should only be used in
 *       edge cases
 */
export default function NextStoreProvider({ children }) {
  const store = useContext(StoreContext.get());

  return <Provider store={store}>{children}</Provider>;
}

NextStoreProvider.propTypes = {
  children: PropTypes.node,
};
