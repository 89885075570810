import {
  REQUEST_READ,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
  REQUEST_RESET,
  ITEM_CREATED,
  ITEM_UPDATED,
  ITEM_REMOVED,
  ITEM_INSERTED,
  ITEM_REPLACED,
  ITEM_FLUSHED,
  ITEM_PRUNED,
} from 'next/lib/collections';

/**
 * NOTE: Also for `ITEM_UPDATED`, we currently treat that action as
 *       optimistic meaning the UI may already reflect the changing, but we
 *       may be showing a pending state regardless. At some point we may
 *       need to fully lean into optimistic or non-optimistic UI for
 *       consistency, but for now we'll just allow the UI update and the
 *       pending state.
 */

const TYPE = 'requests';

/**
 * Temporary placeholder ID used for items without an ID
 */
export const NEW_ITEM_ID = '@requests/NEW_ITEM_ID';

/**
 * Request status states
 */
export const PENDING = { loading: true, error: null };
export const COMPLETED = { loading: false, error: null };
export const UNINITIALIZED = null;

/**
 * Status updater for entity
 *
 * @param {State} state - Current state
 * @param {Action} action - Dispatched action
 * @param {Status} status - Next status
 * @return {State} Next state
 */
const entity = (state, { meta }, status) => ({
  ...state,
  [meta.type]: status,
});

/**
 * Status updater for collection item. Assumes the ID of the item will be a
 * field on the action payload
 *
 * @param {State} state - Current state
 * @param {Action} action - Dispatched action
 * @param {Status} status - Next status
 * @return {State} Next state
 */
const item = (state, { payload, meta }, status) => ({
  ...state,
  [meta.type]: {
    ...state[meta.type],
    items: {
      ...state[meta.type]?.items,
      [payload.id]: status,
    },
  },
});

/**
 * Augment actions to handle items without IDs e.g. when creating by providing a
 * temporary ID that can be used for selection
 *
 * @param {Action} action - Dispatched action
 * @return {Action} Action potentially augmented with a new item ID
 */
const augment = action => {
  const { payload, ...rest } = action;
  const { id } = payload ?? {};

  // If an ID exists, return early with provided action
  if (id != null) {
    return action;
  }

  // Otherwise add the temporary new item ID
  const augmented = { ...payload, id: NEW_ITEM_ID };
  return { payload: augmented, ...rest };
};

/**
 * Clear up any temporary IDs used that may have been used in state
 *
 * @param {State} state - Current state
 * @return {State} State cleared of new item IDs
 */
const clear = (state, action) => {
  const { meta } = action;
  const { items } = state?.[meta.type] ?? {};
  const { [NEW_ITEM_ID]: omit, ...rest } = items ?? {};

  return {
    ...state,
    [meta.type]: {
      ...state[meta.type],
      items: rest,
    },
  };
};

export default function reducer(state = {}, action = {}) {
  const { type, payload, meta } = action;

  switch (type) {
    case REQUEST_RESET:
      return meta.collection
        ? item(state, action, UNINITIALIZED)
        : entity(state, action, UNINITIALIZED);

    case REQUEST_READ:
      return entity(state, action, PENDING);

    case ITEM_CREATED:
      return meta.collection
        ? item(state, augment(action), PENDING)
        : entity(state, action, PENDING);

    case ITEM_UPDATED:
    case ITEM_REMOVED:
      return meta.collection
        ? item(state, action, PENDING)
        : entity(state, action, PENDING);

    case REQUEST_RESOLVED:
      return entity(state, action, COMPLETED);

    case ITEM_INSERTED:
      return meta.collection
        ? item(clear(state, action), action, COMPLETED)
        : entity(state, action, COMPLETED);

    case ITEM_REPLACED:
    case ITEM_FLUSHED:
    case ITEM_PRUNED:
      return meta.collection
        ? item(state, action, COMPLETED)
        : entity(state, action, COMPLETED);

    case REQUEST_REJECTED: {
      const ERRORED = { ...COMPLETED, error: payload.error };
      return meta.collection
        ? item(state, augment(action), ERRORED)
        : entity(state, action, ERRORED);
    }

    default:
      return state;
  }
}

export const selectRequestStatus = (state, type, id) => {
  const root = state[TYPE]?.[type];
  const status = id != null ? root?.items?.[id] : root;
  return status || UNINITIALIZED;
};
