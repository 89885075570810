import React from 'react';
import { connect } from 'react-redux';

import { Column } from '@appcues/component-library';
import { isTrialAccount } from '@studio/navigation';

import { selectTrialStatus } from 'entities/trial-status';
import SubscriptionPicker from 'components/SubscriptionPicker';
import { selectSubscriptionEmail } from 'selectors/billing';
import TrialMeterByDaysPanel from 'components/Subscription/TrialMeterByDaysPanel';
import { selectAccountMeta } from 'reducers/account/meta';

export function FreeTrial({ subscriptionEmail, trialStatus, account }) {
  const { expirationDate, isExpired, isWebInstalled, lengthDays } = trialStatus;
  const isTrial = isTrialAccount(account, trialStatus);
  if (!subscriptionEmail) return null;

  return (
    <Column alignItems="stretch">
      <TrialMeterByDaysPanel
        isTrial={isTrial}
        createdAt={trialStatus.createdAt}
        expirationDate={expirationDate}
        isExpired={isExpired}
        isWebInstalled={isWebInstalled}
        lengthDays={lengthDays}
      />
      <SubscriptionPicker subscriptionEmail={subscriptionEmail} />
    </Column>
  );
}

const mapStateToProps = state => {
  return {
    subscriptionEmail: selectSubscriptionEmail(state),
    trialStatus: selectTrialStatus(state),
    account: selectAccountMeta(state),
  };
};

export default connect(mapStateToProps)(FreeTrial);
