import React from 'react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import {
  Caption,
  H2,
  H3,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Text,
} from '@studio/legacy-components';
import { ENTITLEMENTS_CAPTIONS } from 'constants/entitlements';
import usePlanPricing from 'hooks/usePlanPricing';
import {
  buildQueryParams,
  formatCurrency,
  getIntervalShortVersion,
} from 'utils/billing';

import {
  Count,
  CheckoutButton,
  LineItem,
  PlanSummary,
  PlustaxText,
  PlusIcon,
  PopoverWrapper,
  SelectedPlan,
  Subtotal,
  TriggerWrapper,
} from './styled';

/**
 * The entitlementsProcessed object contains information coming from the API, that allows
 * us to determine things like:
 * - what are the maximum entitlements allowed for this plan
 * - how many entitlements has the current account purchased
 *
 * The selectedEntitlements is an object that contains the entitlements that the user has selected.
 * This will be used when the user decides to go to the checkout page and come back to the upgrade
 * page. We will preload the entitlements that they had previously selected.
 */
const CheckoutPreview = ({
  selectedEntitlements,
  selectedEntitlementsCount,
  entitlementsProcessed,
  hasSubscription,
  plan,
}) => {
  const planPricing = usePlanPricing({
    entitlementsProcessed,
    hasSubscription,
    plan,
    selectedEntitlements,
  });

  return (
    <>
      <PlanSummary>
        <SelectedPlan>
          <Text>Selected plan</Text>
          <H3>Essentials</H3>
          {selectedEntitlementsCount > 0 && (
            <PopoverWrapper>
              <Popover>
                <PopoverTrigger asChild>
                  <TriggerWrapper>
                    <PlusIcon icon="plus" name="plus" />
                    <Count>
                      {selectedEntitlementsCount}{' '}
                      {pluralize('add-ons', selectedEntitlementsCount)}
                    </Count>
                  </TriggerWrapper>
                </PopoverTrigger>
                <PopoverContent size="medium">
                  <H2>Add-ons</H2>
                  {Object.entries(selectedEntitlements).map(
                    ([addon, value]) => {
                      if (!value) return null;
                      return (
                        <LineItem key={`addon-${addon}`}>
                          +{value} Additional{' '}
                          {pluralize(ENTITLEMENTS_CAPTIONS[addon], value)} (
                          <Caption bold>
                            ${entitlementsProcessed[addon].unitPrice}/mo
                          </Caption>
                          )
                        </LineItem>
                      );
                    }
                  )}
                </PopoverContent>
              </Popover>
            </PopoverWrapper>
          )}
        </SelectedPlan>
        <Subtotal>
          <Text>Subtotal</Text>
          <H3>
            USD {formatCurrency(planPricing, false)}/
            {getIntervalShortVersion(plan.planInterval)}
          </H3>
          <PlustaxText>Plus tax</PlustaxText>
        </Subtotal>
      </PlanSummary>
      <CheckoutButton
        disabled={planPricing === 0}
        forwardedAs={Link}
        to={`/subscription/${plan.id}?${buildQueryParams(
          plan,
          selectedEntitlements
        )}`}
      >
        Check out
      </CheckoutButton>
    </>
  );
};

CheckoutPreview.propTypes = {
  selectedEntitlements: PropTypes.object,
  selectedEntitlementsCount: PropTypes.number,
  entitlementsProcessed: PropTypes.object,
  hasSubscription: PropTypes.bool,
  plan: PropTypes.object,
};

export default CheckoutPreview;
