import { select, call, put, getContext, takeLeading } from 'redux-saga/effects';
import toast from 'next/lib/toast';
import * as actionTypes from 'constants/actionTypes';
import { selectAccountMeta } from 'reducers/account/meta';
import { selectAccountUser, selectAccountUsers } from 'reducers/account/users';

import {
  TRANSFER_ACCEPTED,
  USER_INVITED,
  USER_ROLE_UPDATED,
  MAX_ALLOWED_USERS,
} from 'constants/account/users';
import {
  replaceAccountUserMeta,
  replaceAccountUsers,
  resolveUserRoleUpdate,
  failUserRoleUpdate,
} from 'actions/account/users';
import UserInAccountError from 'errors/UserInAccountError';
import MaxUsersError from 'errors/MaxUsersError';
import { promisaga } from 'utils/as-promised';
import { reportError } from 'helpers/error-reporting';
import { scrapeAcceptInviteToAccountId } from 'utils/url';
import { selectUser, selectUserId } from 'reducers/user';
import { USER_META_UPDATED } from 'actions/user';

/**
 * Convert the new account/users response format
 * to the older format used by this app
 */

const transform = accountUsers =>
  accountUsers
    .filter(({ user: { hidden } }) => !hidden)
    .reduce((acc, { accountUser, user: meta }) => {
      acc[accountUser.id] = { ...accountUser, meta };
      return acc;
    }, {});

export function* fetchAccountUsers() {
  const api = yield getContext('api');
  const { data: accountUsers } = yield call(api.getAccountUsers);
  yield put(replaceAccountUsers(transform(accountUsers)));
}

export function* inviteUser({
  payload: { email, name, role, job, singleSignOn, passwordlessInvitation },
}) {
  try {
    if (!email) {
      throw new ReferenceError('No email specified for user to invite.');
    }

    const accountUsers = yield select(selectAccountUsers);
    const usersAsArr = Object.values(accountUsers || {});
    if (
      usersAsArr
        .map(({ meta }) => meta)
        .some(({ email: userEmail } = {}) => userEmail === email)
    ) {
      throw new UserInAccountError();
    }

    const accountMeta = yield select(selectAccountMeta);
    if (accountMeta.isTrial && usersAsArr.length >= MAX_ALLOWED_USERS) {
      throw new MaxUsersError();
    }

    if (singleSignOn || passwordlessInvitation) {
      const api = yield getContext('api');

      yield call(api.inviteTeammate, {
        email,
        role,
        job,
      });
    } else {
      const accounts = yield getContext('accounts');
      const isExistingUser = yield call(accounts.exists, { email });
      const { email: inviter, id: inviterUid } = yield select(selectUser);

      yield isExistingUser
        ? call(accounts.addUser, {
            email,
            role,
            inviterUid,
          })
        : call(accounts.inviteUser, { email, name, role, inviter });
    }
  } catch (error) {
    if (error.name === 'MaxUsersError' || error.name === 'UserInAccountError') {
      throw error;
    }
    yield call(reportError, error);
    throw error;
  }
}

export function* acceptTransfer({ payload: { accountId } }) {
  try {
    const userId = yield select(selectUserId);
    const accounts = yield getContext('accounts');
    yield call(accounts.acceptTransfer, { accountId, userId });
    yield call(scrapeAcceptInviteToAccountId);
    const auth = yield getContext('auth');
    yield call(auth.logout);
  } catch (error) {
    yield call(reportError, error);
    throw error;
  }
}

export function* updateUserRole({ payload: { userId: id, role } }) {
  try {
    const customerApi = yield getContext('api');

    const { data: updatedRawAcctUser } = yield call(
      customerApi.updateAccountUser,
      id,
      {
        role,
      }
    );

    // accountUsers in the store are actually the firebase /account/users/<id>
    // record enriched with the firebase db user object; the api call only gives
    // us the raw firebase account user so we grab and update the existing one
    const user = yield select(selectAccountUser, id);

    const updatedAcctUser = {
      ...user,
      ...updatedRawAcctUser,
    };

    yield put({
      type: actionTypes.REPLACE_ACCOUNT_USER,
      payload: updatedAcctUser,
    });
    yield put(resolveUserRoleUpdate());
    yield call(toast.success, 'Role updated');
  } catch (error) {
    yield call(reportError, error);
    yield put(failUserRoleUpdate());
    yield call(toast.error, 'Role update failed. Please try again.');
    throw error;
  }
}

/**
 * Compensate for the missing realtime update from firebase
 * by repeating any updates to the user onto the matching
 * account/user.
 */

function* replicateUserMeta() {
  const user = yield select(selectUser);
  yield put(replaceAccountUserMeta(user.id, user));
}

export default function* users() {
  yield takeLeading(USER_INVITED, promisaga(inviteUser));
  yield takeLeading(TRANSFER_ACCEPTED, promisaga(acceptTransfer));
  yield takeLeading(USER_ROLE_UPDATED, promisaga(updateUserRole));

  // external actions
  yield takeLeading(USER_META_UPDATED, replicateUserMeta);
}
