import React, { useState } from 'react';
import styled from 'styled-components';
import {
  getFilteredClauseTree,
  getNestedConditionsFromFlattenedClauses,
} from '@appcues/libcues';
import DomainTargeting from 'components/Common/DomainTargeting';
import FrequencyTargeting from 'components/Common/FrequencyTargeting';
import URLTargeting from 'components/Common/URLTargeting';
import URLTargetingHelper from 'components/Common/URLTargetingHelper';
import { filterTargetingHelperClauses } from 'utils/conditions';

const PageTargetingContainer = styled.div``;

export default function PageTargeting({
  addClause,
  clauses,
  deleteClause,
  deleteMatchingClauses,
  frequencyTargeting,
  meta,
  previewUrl,
  rule,
  setDisplayAnyPage,
  setDisplaySpecificPage,
  setStepFrequency,
  showManualTriggerOption,
  stepId,
  updateClause,
}) {
  const [helperOpen, setHelperOpen] = useState(false);

  const toggleHelper = () => {
    setHelperOpen(!helperOpen);
  };

  return (
    <PageTargetingContainer>
      <URLTargeting
        addClause={addClause}
        clauses={clauses}
        deleteClause={deleteClause}
        helperOpen={helperOpen}
        rule={rule}
        setDisplayAnyPage={setDisplayAnyPage}
        setDisplaySpecificPage={setDisplaySpecificPage}
        stepId={stepId}
        toggleHelper={toggleHelper}
        updateClause={updateClause}
      />
      <DomainTargeting
        addClause={addClause}
        deleteClause={deleteClause}
        deleteMatchingClauses={deleteMatchingClauses}
        conditions={clauses}
        meta={meta}
        rule={rule}
        stepId={stepId}
        updateClause={updateClause}
      />
      {frequencyTargeting && (
        <FrequencyTargeting
          setStepFrequency={setStepFrequency}
          stepId={rule.id}
          stepFrequency={rule.frequency}
          showManualTriggerOption={showManualTriggerOption || true}
        />
      )}
      <URLTargetingHelper
        active={helperOpen}
        conditions={getNestedConditionsFromFlattenedClauses(
          getFilteredClauseTree(filterTargetingHelperClauses, clauses)
        )}
        toggleHelper={toggleHelper}
        helperOpen={helperOpen}
        previewUrl={previewUrl}
      />
    </PageTargetingContainer>
  );
}

PageTargeting.defaultProps = {
  frequencyTargeting: false,
};
