import React from 'react';
import { ListRow, MenuSection, MoreMenu } from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';
import { TagsModal } from 'next/components/Tags';

export default function ExperiencesMoreMenu() {
  const [visible, toggle] = useToggle();

  return (
    <>
      <MoreMenu icon="ellipsis-h">
        <MenuSection>
          <ListRow onClick={toggle}>Manage tags</ListRow>
        </MenuSection>
      </MoreMenu>

      <TagsModal onClose={toggle} visible={visible} />
    </>
  );
}
