/* global MOBILE_BUILDER_URL */

import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonGroup,
  Button,
  Link,
  Icon,
  MoreMenu,
  MenuSection,
  ListRow,
} from '@studio/legacy-components';
import ExportModalManager from 'next/components/ExportModalManager';

export default function AnalyticsHeaderActions({
  id,
  accountId,
  showExport,
  createdAt,
}) {
  const settingsPath = `/mobile/flows/${id}/settings`;
  const builderPath = `${MOBILE_BUILDER_URL}/flows/${id}?account=${accountId}`;

  return (
    <ButtonGroup>
      {showExport ? (
        <>
          <ExportModalManager createdAt={createdAt} id={id} type="mobile" />

          <MoreMenu>
            <MenuSection>
              <ListRow forwardedAs={Link} to={settingsPath}>
                Settings
              </ListRow>
              <ListRow forwardedAs={Link} href={builderPath} target="_blank">
                Open in Builder
              </ListRow>
            </MenuSection>
          </MoreMenu>
        </>
      ) : (
        <>
          <Button kind="secondary" forwardedAs={Link} to={settingsPath}>
            Settings
          </Button>
          <Button forwardedAs={Link} href={builderPath} target="_blank">
            <Icon icon="edit" />
            Open in Builder
          </Button>
        </>
      )}
    </ButtonGroup>
  );
}

AnalyticsHeaderActions.propTypes = {
  accountId: PropTypes.string,
  id: PropTypes.string,
  showExport: PropTypes.bool,
  createdAt: PropTypes.number,
};
