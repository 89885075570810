import { useEffect, useState } from 'react';
import { loadScripts, track } from './lib';

/**
 * The purpose of this hook is to load 3rd party ad platforms in order
 * to track conversions for people who register.
 */
const useConversion = () => {
  const [scriptsLoaded, setScriptsLoaded] = useState(false);

  useEffect(() => {
    async function load() {
      if (await loadScripts()) setScriptsLoaded(true);
    }
    load();
  }, []);

  return { track, scriptsLoaded };
};

export default useConversion;
