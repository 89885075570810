import { takeEvery, call, getContext } from 'redux-saga/effects';
import { FETCH_CONDITIONS_ESTIMATE } from 'actions/estimation';
import { reportError } from 'helpers/error-reporting';
import { promisaga } from 'utils/as-promised';

export function* fetchConditionsEstimate(action) {
  const {
    payload: { conditions },
  } = action;

  try {
    if (!conditions) {
      throw new Error('fetchConditionsEstimate called without conditions');
    }

    const estimation = yield getContext('estimation');
    return yield call(estimation.getConditionsEstimate, conditions);
  } catch (error) {
    yield call(reportError, error);
    throw error;
  }
}

export default function* conditionsEstimate() {
  yield takeEvery(
    FETCH_CONDITIONS_ESTIMATE,
    promisaga(fetchConditionsEstimate)
  );
}
