export const CREATE_SATISFACTION_WITH_RULE = 'CREATE_SATISFACTION_WITH_RULE';
export const UPDATE_SATISFACTION_AND_FLUSH_SATISFACTION =
  'UPDATE_SATISFACTION_AND_FLUSH_SATISFACTION';
export const REPLACE_ACCOUNT_SATISFACTION = 'REPLACE_ACCOUNT_SATISFACTION';
export const UPDATE_SATISFACTION_IN_STORE = 'UPDATE_SATISFACTION_IN_STORE';
export const SET_AUTO_COLLAPSE_FEEDBACK_SETTING =
  'SET_AUTO_COLLAPSE_FEEDBACK_SETTING';

export const TOGGLE_SATISFACTION_EXPORT_FORMAT =
  'TOGGLE_SATISFACTION_EXPORT_FORMAT';

export const TOGGLE_SURVEY_PREVIEW = 'TOGGLE_SURVEY_PREVIEW';
export const SATISFACTION_CREATED = 'SATISFACTION_CREATED';
