import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import LoadingIcon from 'assets/images/checkout-loading.svg';
import { Plan as PlanShape } from 'components/Subscription/shape';
import { selectPaymentTaxes, selectLoadingTaxes } from 'reducers/newBilling';
import { selectHasSubscription } from 'reducers/account/meta';

import NewSubscriptionCheckout from './NewSubscriptionCheckout';
import ExistingSubscriptionCheckout from './ExistingSubscriptionCheckout';

import {
  PayButton,
  PoweredByStripeWrapper,
  PoweredByText,
  LoadingSpinner,
  LockIcon,
  StripeIcon,
  StyledPanel,
} from './styled';

export const CheckoutSummary = ({
  completedForms,
  entitlementsProcessed,
  editingBillingDetails,
  isSubmitting,
  hasSubscription,
  hasPaymentMethodId,
  formValues,
  planData,
  planPrice,
  selectedEntitlements,
  selectedEntitlementsCount,
  subscriptionTaxes,
  taxesLoading,
}) => {
  const isButtonDisabled = useMemo(() => {
    if (isSubmitting || taxesLoading) return true;

    if (
      editingBillingDetails &&
      (!completedForms.billing || !completedForms.paymentMethod)
    ) {
      return true;
    }

    if (hasSubscription && !Number.isFinite(subscriptionTaxes)) {
      return true;
    }

    if (
      !hasSubscription &&
      !hasPaymentMethodId &&
      !editingBillingDetails &&
      (!completedForms.billing || !completedForms.paymentMethod)
    ) {
      return true;
    }

    return false;
  }, [
    completedForms,
    editingBillingDetails,
    hasSubscription,
    isSubmitting,
    subscriptionTaxes,
    taxesLoading,
    hasPaymentMethodId,
  ]);

  return (
    <StyledPanel>
      {hasSubscription ? (
        <ExistingSubscriptionCheckout
          completedForms={completedForms}
          editingBillingDetails={editingBillingDetails}
          entitlementsProcessed={entitlementsProcessed}
          formValues={formValues}
          planData={planData}
          selectedEntitlements={selectedEntitlements}
          selectedEntitlementsCount={selectedEntitlementsCount}
        />
      ) : (
        <NewSubscriptionCheckout
          completedForms={completedForms}
          formValues={formValues}
          planData={planData}
          planPrice={planPrice}
          subscriptionTaxes={subscriptionTaxes}
          selectedEntitlementsCount={selectedEntitlementsCount}
          taxesLoading={taxesLoading}
        />
      )}
      <PayButton disabled={isButtonDisabled} type="submit">
        {isSubmitting ? (
          <LoadingSpinner src={LoadingIcon} alt="stripe icon" />
        ) : (
          'Buy now'
        )}
      </PayButton>

      <PoweredByStripeWrapper>
        <LockIcon icon="lock" />
        <PoweredByText>Secure payments powered by</PoweredByText>
        <StripeIcon icon={['fab', 'stripe']} className="fa-lg" />
      </PoweredByStripeWrapper>
    </StyledPanel>
  );
};

CheckoutSummary.propTypes = {
  completedForms: PropTypes.object,
  entitlementsProcessed: PropTypes.object,
  editingBillingDetails: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  hasSubscription: PropTypes.bool,
  hasPaymentMethodId: PropTypes.bool,
  formValues: PropTypes.object,
  planData: PlanShape,
  planPrice: PropTypes.number,
  selectedEntitlements: PropTypes.object,
  selectedEntitlementsCount: PropTypes.number,
  subscriptionTaxes: PropTypes.number,
  taxesLoading: PropTypes.bool,
};

const mapStateToProps = state => ({
  hasSubscription: selectHasSubscription(state),
  subscriptionTaxes: selectPaymentTaxes(state),
  taxesLoading: selectLoadingTaxes(state),
});

export default connect(mapStateToProps)(CheckoutSummary);
