import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '@studio/legacy-components';
import { openFlow } from 'next/lib/builder';
import { selectAccount } from 'next/entities/account';
import {
  MOBILE_DEFAULT_TYPE,
  create as createExperience,
} from 'next/entities/experiences';
import { create as createFlow } from 'next/entities/flows';
import { MOBILE_FLOWS, selectFeature } from 'next/entities/features';
import { selectEnabledApps, WEB_APP } from 'next/entities/apps';

import { OpenInBuilderManager } from 'next/components/OpenInBuilder';

import CreateExperienceModal from './CreateExperienceModal';

const STATES = {
  IDLE: 'IDLE',
  CREATE: 'CREATE',
  IN_FLIGHT: 'IN_FLIGHT',
  OPEN_IN_BUILDER: 'OPEN_IN_BUILDER',
  ERROR: 'ERROR',
};

export const CreateExperienceManager = ({
  accountId,
  hasMobile,
  onCreateFlow,
  onCreateExperience,
}) => {
  const [modalState, setModalState] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [id, setId] = useState(null);

  const handleSubmit = async values => {
    try {
      setModalState(STATES.IN_FLIGHT);
      if (values.appId === WEB_APP) {
        const { previewUrl: createdPreviewUrl, id: createdId } =
          await onCreateFlow({
            name: values.name,
            previewUrl: values.previewUrl,
            template: values.template,
            tagIds: values.tagIds,
          });

        setPreviewUrl(createdPreviewUrl);
        setId(createdId);
        setModalState(STATES.OPEN_IN_BUILDER);
      } else {
        const { id: experienceId } = await onCreateExperience({
          appId: values.appId,
          name: values.name,
          type: values.type || MOBILE_DEFAULT_TYPE,
          tagIds: values.tagIds,
          template: 'mobile',
        });

        /* global MOBILE_BUILDER_URL */
        window.open(
          `${MOBILE_BUILDER_URL}/flows/${experienceId}?account=${accountId}`
        );

        setModalState(STATES.IDLE);
      }
    } catch {
      setModalState(STATES.ERROR);
    }
  };

  const handleClose = () => void setModalState(null);

  return (
    <>
      <Button onClick={() => setModalState(STATES.create)}>
        Create a Flow
      </Button>
      <CreateExperienceModal
        onClose={handleClose}
        onSubmit={handleSubmit}
        visible={[STATES.create, STATES.ERROR].includes(modalState)}
        disabled={modalState === STATES.IN_FLIGHT}
        hasError={modalState === STATES.ERROR}
        hasMobile={hasMobile}
      />
      <OpenInBuilderManager
        onClose={() => setModalState(null)}
        onNavigate={() => void openFlow({ flow: id, url: previewUrl })}
        type="flows"
        visible={modalState === STATES.OPEN_IN_BUILDER}
      />
    </>
  );
};

CreateExperienceManager.propTypes = {
  accountId: PropTypes.string,
  hasMobile: PropTypes.bool,
  onCreateFlow: PropTypes.func,
  onCreateExperience: PropTypes.func,
};

const mapStateToProps = state => ({
  accountId: selectAccount(state).id,
  hasMobile:
    selectFeature(state, MOBILE_FLOWS) &&
    Object.keys(selectEnabledApps(state) ?? {}).length > 0,
});

const mapDispatchToProps = {
  onCreateFlow: values => createFlow(values),
  onCreateExperience: values => createExperience(values),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateExperienceManager);
