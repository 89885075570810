import React from 'react';
import { connect } from 'react-redux';

import { CPanel } from '@appcues/component-library';

import { DocumentTitle } from 'next/hooks/use-title';
import EmptyState from 'components/Common/EmptyState';
import EventsList from 'components/Settings/PropertiesAndEvents/EventsList';
import { emptyStatePropsMap } from 'components/Settings/PropertiesAndEvents';

import { selectAccountUserEvents } from 'selectors/event-names';

export const Events = ({ userEvents }) => {
  return (
    <>
      {Object.keys(userEvents).length > 0 ? (
        <CPanel>
          <EventsList userEvents={userEvents} />
        </CPanel>
      ) : (
        <EmptyState {...emptyStatePropsMap.events} />
      )}
      <DocumentTitle title="Events | Settings | Appcues" />
    </>
  );
};

function mapStateToProps(state) {
  return {
    userEvents: selectAccountUserEvents(state),
  };
}

export default connect(mapStateToProps)(Events);
