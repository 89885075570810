import styled from 'styled-components';
import { Button, Select, Input, DateInput } from '@studio/legacy-components';
import SelectProfileAttribute from 'components/Common/SelectProfileAttribute';
import AutosuggestProfileAttribute from 'components/Common/AutosuggestProfileAttribute';

export const ClauseWrapper = styled.div`
  width: 100%;
`;

export const AdditionalInfo = styled.div`
  width: 100%;
  margin-top: 4px;
`;

export const RelativeDate = styled.label`
  align-items: center;
  display: flex;
  white-space: nowrap;
  gap: 8px;
`;

export const SubClause = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  gap: 8px;

  ${Select}:nth-child(1), ${Select}:nth-child(2), ${SelectProfileAttribute} {
    min-width: unset;
    max-width: unset;
    width: 10em;
    flex-grow: 1;
  }

  ${Select}:nth-child(3), ${Input}, ${DateInput}, ${AutosuggestProfileAttribute}, ${RelativeDate} {
    min-width: unset;
    max-width: unset;
    width: 10em;
    flex-grow: 2;
  }

  ${Button} {
    align-self: stretch;
  }
`;
