import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { evaluatePageConditions } from '@appcues/libcues';
import { Icon } from '@studio/legacy-components';
import { isInstalledAtDomain } from 'utils';
import { selectInstalledDomains } from 'reducers/account/installedDomains';

const URLWrapper = styled.div`
  align-items: center;
  display: flex;
  margin: 0.5rem 0;
  overflow: hidden;
  width: 100%;
`;

const DeleteButton = styled(Icon)`
  cursor: pointer;
  color: ${props => props.theme['$gray-50']};
`;

const URLText = styled.span`
  color: ${props => props.theme['$gray-80']};
  margin-right: 0.75rem;
  overflow: hidden;
  padding: 0.25rem 0;
  text-overflow: ellipsis;
`;

const StatusPill = styled.div`
  background-color: ${props => props.theme[props.backgroundColor]};
  border-radius: 25px;
  flex-shrink: 0;
  margin-right: 0.75rem;
  padding: 5px 13px;
  min-width: 180px;
  max-width: 300px;
  white-space: nowrap;
`;

const StatusIcon = styled(Icon)`
  color: white;
  font-size: 13px;
  margin-right: 0.5rem;
  margin-top: 1px;
`;

const StatusText = styled.span`
  color: white;
`;

export const ExampleURL = ({
  conditions,
  handleDelete,
  installedDomains,
  url,
}) => {
  const match = evaluatePageConditions(conditions, url);
  const installedDomain = isInstalledAtDomain(url, installedDomains);

  const checkInstallationColor = installedDomain ? '$green' : '$yellow';
  const checkInstallationText = installedDomain
    ? 'Flow will show'
    : 'Flow will show when installed';

  const matchColor = match ? checkInstallationColor : '$red-dark';
  const matchIcon = match ? 'check' : 'times';
  const matchText = match ? checkInstallationText : "Flow won't show";

  return (
    <URLWrapper>
      <StatusPill backgroundColor={matchColor}>
        <StatusIcon icon={`${matchIcon}-circle`} />
        <StatusText>{matchText}</StatusText>
      </StatusPill>
      <URLText>{url}</URLText>
      <DeleteButton onClick={handleDelete} icon="trash-alt" />
    </URLWrapper>
  );
};

ExampleURL.propTypes = {
  conditions: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired,
  installedDomains: PropTypes.arrayOf(PropTypes.string).isRequired,
  url: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  installedDomains: selectInstalledDomains(state) || [],
});

export default connect(mapStateToProps)(ExampleURL);
