import React from 'react';
import PropTypes from 'prop-types';
import DisableEnableAppModal from './DisableEnableAppModal';
import { APP_DISABLED } from './lib';

const EnableModal = ({ appId, onClose, visible }) => {
  return (
    <DisableEnableAppModal
      appId={appId}
      onClose={onClose}
      state={APP_DISABLED}
      visible={visible}
    />
  );
};

EnableModal.propTypes = {
  appId: PropTypes.string,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

export default EnableModal;
