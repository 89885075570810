import { call, getContext, put } from 'redux-saga/effects';
import { reject, resolve } from './actions';

export function* fetchTrialStatus() {
  try {
    const api = yield getContext('api');
    const { data } = yield call(api.getTrialStatus);
    yield put(resolve(data));
  } catch (error) {
    yield put(reject(error));
  }
}
