import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import isEmpty from 'lodash.isempty';
import {
  Button,
  ButtonGroup,
  DateRangePicker,
  ErrorMsg,
  Heading,
  Input,
  Label,
  Modal,
  getEndOfToday,
  useDateRange,
} from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';
import { requestEventsExport } from 'actions/exports';
import { selectUserEmail } from 'reducers/user';
import { selectDateRangeOptions } from 'selectors/timeframe-options';
import { asPromised } from 'utils/as-promised';
import ErrorBanner from 'components/Insights/Common/ErrorBanner';

const Wrapper = styled.div`
  padding: 16px 0;

  ${DateRangePicker} {
    margin-bottom: 16px;
  }
`;

const SegmentWarningText = styled.span`
  font-style: italic;
`;

export const ExportManager = ({
  userEmail,
  events,
  startDate,
  endDate,
  range: rangeType = 7,
  segmentId,
  options,
  onSubmit,
}) => {
  const [dialogOpen, toggleDialogOpen] = useToggle();
  const [errorVisible, setErrorVisible] = useState(false);

  const [dates] = useDateRange(options, {
    range: rangeType,
    start: startDate && new Date(startDate),
    end: endDate && new Date(endDate),
  });

  const handleToggleDialogOpen = e => {
    e.preventDefault();
    toggleDialogOpen();
  };

  const handleSubmit = async ({ email, start, end }, { setSubmitting }) => {
    try {
      await onSubmit({
        events,
        endDate: end.valueOf(),
        startDate: start.valueOf(),
        email,
      });
      setErrorVisible(false);
      toggleDialogOpen();
    } catch {
      setErrorVisible(true);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Modal visible={dialogOpen} onClose={toggleDialogOpen}>
        <Heading>Export a CSV</Heading>

        <Formik
          initialValues={{ email: userEmail || '', ...dates }}
          validationSchema={yup.object().shape({
            email: yup
              .string()
              .email('Please enter a valid email address.')
              .required('Email is required to proceed.'),
            end: yup.date().max(getEndOfToday()),
            start: yup.date().max(getEndOfToday()),
          })}
          onSubmit={handleSubmit}
        >
          {({ values, errors, isSubmitting, setValues }) => (
            <Form>
              <Wrapper>
                {errorVisible && <ErrorBanner />}

                <DateRangePicker
                  endProps={{
                    inputProps: { error: Boolean(values.end && errors.end) },
                  }}
                  onChange={({ range, start = null, end = null }) => {
                    setValues({ ...values, range, start, end });
                  }}
                  options={options}
                  portal
                  stacked
                  startProps={{
                    inputProps: {
                      error: Boolean(values.start && errors.start),
                    },
                  }}
                  value={{
                    range: values.range,
                    start: values.start,
                    end: values.end,
                  }}
                />

                <Label>
                  Email Address - we&apos;ll send your CSV here
                  <Field
                    as={Input}
                    type="text"
                    name="email"
                    placeholder="email@email.com"
                    error={!!errors.email}
                  />
                  {errors.email && <ErrorMsg>{errors.email}</ErrorMsg>}
                </Label>
                {segmentId && (
                  <SegmentWarningText>
                    Note: Segment filters will not be applied.
                  </SegmentWarningText>
                )}
              </Wrapper>
              <ButtonGroup right>
                <Button
                  type="submit"
                  disabled={!isEmpty(errors) || isSubmitting}
                >
                  Send CSV
                </Button>
                <Button
                  kind="secondary"
                  disabled={isSubmitting}
                  onClick={handleToggleDialogOpen}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </Form>
          )}
        </Formik>
      </Modal>
      <Button kind="secondary" disabled={!events} onClick={toggleDialogOpen}>
        Export
      </Button>
    </>
  );
};

ExportManager.propTypes = {
  endDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  events: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  onSubmit: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  range: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  segmentId: PropTypes.string,
  startDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  userEmail: PropTypes.string,
};

const mapStateToProps = state => ({
  userEmail: selectUserEmail(state),
  options: selectDateRangeOptions(state),
});

const mapDispatchToProps = dispatch => ({
  onSubmit: args => asPromised(dispatch, requestEventsExport(args)),
});

const ConnectedExportManager = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportManager);

ConnectedExportManager.propTypes = {
  endDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  events: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  range: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  segmentId: PropTypes.string,
  startDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ConnectedExportManager;
