import {
  conditionNames,
  groupOperatorValues,
  propertyOperators,
} from '@appcues/libcues';
import { millisecondsPerDay } from 'constants/account/conditions';

export const SAMPLE_METRICS = {
  buckets: {
    promoters: 23,
    neutrals: 11,
    detractors: 9,
  },
  npsScore: 23,
  shownCount: 600,
  responseCount: 100,
  qualitativeCount: 23,
  responsesByScore: [
    { score: 0, result: 2 },
    { score: 1, result: 2 },
    { score: 2, result: 3 },
    { score: 3, result: 2 },
    { score: 4, result: 1 },
    { score: 5, result: 3 },
    { score: 6, result: 6 },
    { score: 7, result: 5 },
    { score: 8, result: 9 },
    { score: 9, result: 14 },
    { score: 10, result: 8 },
  ],
};

export const PREVIEW_TABS = {
  SURVEY: 'SURVEY',
  FEEDBACK: 'FEEDBACK',
};

export const BUCKETS = {
  PROMOTERS: 'promoters',
  NEUTRALS: 'neutrals',
  DETRACTORS: 'detractors',
};

export const BUCKET_COLORS = {
  [BUCKETS.PROMOTERS]: {
    color: '#05DB99',
  },
  [BUCKETS.NEUTRALS]: {
    color: '#9CA7BA',
  },
  [BUCKETS.DETRACTORS]: {
    color: '#FF7145',
  },
};

export const defaultQuestionOne =
  'On a scale of 0-10, how likely are you to recommend this product to a friend or colleague?';

export const defaultQuestionTwo = 'Why did you choose that score?';

export const STEP_ONE_BASE = {
  backgroundColor: '#152C69',
  foregroundColor: '#FFFFFF',
  index: 0,
  nextButtonHidden: true,
  prevButtonHidden: true,
  questionText: defaultQuestionOne,
  stepNumber: 1,
  stepType: 'quantitative-question',
};

export const STEP_TWO_BASE = {
  backgroundColor: '#152C69',
  foregroundColor: '#FFFFFF',
  index: 1,
  nextButtonHidden: false,
  nextText: 'Submit',
  prevButtonHidden: false,
  prevText: 'Cancel',
  questionText: defaultQuestionTwo,
  stepNumber: 2,
  stepType: 'qualitative-question',
};

export const SATISFACTION_BASE = {
  legacyStepType: 'satisfaction-survey',
  name: 'NPS Survey',
  patternType: 'nps-survey',
  position: 'bottom',
  published: false,
  skippable: true,
  state: 'DRAFT',
  stepType: 'satisfaction-survey',
  steps: {},
};

export const SATISFACTION_CREATION_STEP_TITLES = {
  STYLE: 'Style',
  CONTENT: 'Content',
  AUDIENCE: 'Audience',
  SUMMARY: 'Summary',
};

export const SATISFACTION_CREATION_STEPS = [
  SATISFACTION_CREATION_STEP_TITLES.STYLE,
  SATISFACTION_CREATION_STEP_TITLES.CONTENT,
  SATISFACTION_CREATION_STEP_TITLES.AUDIENCE,
  SATISFACTION_CREATION_STEP_TITLES.SUMMARY,
];

export const SATISFACTION_TARGETING_CATEGORY = {
  PAGE: 'PAGE',
  FREQUENCY: 'FREQUENCY',
  AUDIENCE: 'AUDIENCE',
  SAMPLING: 'SAMPLING',
};

export const SATISFACTION_TARGETING_PROPERTIES = {
  NPS_LAST_COLLECTED: '_appcuesSatisfaction_NPSLastCollectedAt',
  SESSION_PAGE_VIEWS: '_sessionPageviews',
  ASK_ME_LATER: '_appcuesSatisfaction_AskMeLaterSelectedAt',
  SESSION_RANDOMIZER: '_sessionRandomizer',
};

export const SATISFACTION_REQUIRED_BASE_PROPERTIES = [
  SATISFACTION_TARGETING_PROPERTIES.SESSION_PAGE_VIEWS,
  SATISFACTION_TARGETING_PROPERTIES.SESSION_RANDOMIZER,
  SATISFACTION_TARGETING_PROPERTIES.NPS_LAST_COLLECTED,
  SATISFACTION_TARGETING_PROPERTIES.ASK_ME_LATER,
];

export const SATISFACTION_TARGETING_OPTIONS = {
  [SATISFACTION_TARGETING_CATEGORY.PAGE]: {
    URL_OPTIONS: [
      {
        label: 'All pages',
        description: 'All pages',
        settingsLabelText: null,
        value: 0,
        operator: propertyOperators.REGEX,
        conditionType: groupOperatorValues.AND,
        clauseType: null,
        pageTargetingEnabled: false,
      },
      {
        label: 'All pages, except certain ones',
        description:
          'All pages, except the pages that end with the following urls ',
        settingsLabelText:
          "What pages should we exclude? Pages that don't end with the URLs: ",
        value: 1,
        operator: propertyOperators.NOT_ENDS_WITH,
        conditionType: groupOperatorValues.AND,
        clauseType: conditionNames.URL,
        pageTargetingEnabled: true,
      },
      {
        label: 'Only certain pages',
        description: 'Only pages that end with the following urls ',
        settingsLabelText:
          'What pages should we include? Pages that end with: ',
        value: 2,
        operator: propertyOperators.ENDS_WITH,
        conditionType: groupOperatorValues.OR,
        clauseType: conditionNames.URL,
        pageTargetingEnabled: true,
      },
      {
        label: 'Advanced page targeting',
        description: 'Only pages that meet certain criteria ',
        settingsLabelText:
          'What pages should we include? Pages that meet the following criteria: ',
        value: 3,
        operator: null,
        conditionType: groupOperatorValues.OR,
        clauseType: conditionNames.URL,
        pageTargetingEnabled: true,
        advancedTargeting: true,
      },
    ],
    get ADVANCED_TARGETING_OPTION() {
      return this.URL_OPTIONS.find(option => option.advancedTargeting);
    },
  },
  [SATISFACTION_TARGETING_CATEGORY.FREQUENCY]: {
    HAS_NOT_SEEN_NPS: {
      operator: propertyOperators.GREATER_THAN_X_DAYS_AGO,
      conditionType: 'not',
      clauseType: conditionNames.PROPERTIES,
      clauseKey: 'property',
      clauseProperty: SATISFACTION_TARGETING_PROPERTIES.NPS_LAST_COLLECTED,
    },
    SESSION_PAGE_VIEWS: {
      operator: propertyOperators.GREATER_THAN,
      conditionType: groupOperatorValues.AND,
      clauseType: conditionNames.PROPERTIES,
      clauseKey: 'property',
      clauseProperty: SATISFACTION_TARGETING_PROPERTIES.SESSION_PAGE_VIEWS,
    },
    ASK_ME_LATER: {
      operator: propertyOperators.GREATER_THAN_X_DAYS_AGO,
      conditionType: 'not',
      clauseType: conditionNames.PROPERTIES,
      clauseKey: 'property',
      clauseProperty: SATISFACTION_TARGETING_PROPERTIES.ASK_ME_LATER,
    },
  },
  [SATISFACTION_TARGETING_CATEGORY.AUDIENCE]: {
    AUDIENCE_OPTIONS: [
      {
        label: 'All users',
        description: 'All users',
        value: 0,
        operator: null,
        conditionType: null,
        clauseType: null,
      },
      {
        label: 'Only activated users',
        description:
          'Only users who have an activation property (account created, credit card added, etc.) older than ',
        value: 1,
        operator: propertyOperators.GREATER_THAN_X_DAYS_AGO,
        conditionType: groupOperatorValues.AND,
        clauseType: conditionNames.PROPERTIES,
        clauseKey: 'property',
      },
      {
        label: 'A segment of users',
        description: 'Users within a specific segment  ',
        value: 2,
        conditionType: groupOperatorValues.AND,
        clauseType: conditionNames.SEGMENTS,
        clauseKey: 'segment',
      },
    ],
  },
  [SATISFACTION_TARGETING_CATEGORY.SAMPLING]: {
    USER_SESSIONS: {
      operator: '<=',
      conditionType: groupOperatorValues.AND,
      clauseType: conditionNames.PROPERTIES,
      clauseKey: 'property',
      clauseProperty: SATISFACTION_TARGETING_PROPERTIES.SESSION_RANDOMIZER,
    },
  },
};

export const defaultNewUserProperty = 'created_at';
export const defaultSurveyDelayTimePeriod = millisecondsPerDay * 60;
export const surveyDelayMaxThreshold = millisecondsPerDay * 14;

export const SATISFACTION_BASE_CONDITIONS = {
  and: [
    {
      and: [
        {
          and: [
            {
              properties: {
                operator: propertyOperators.GREATER_THAN,
                property: SATISFACTION_TARGETING_PROPERTIES.SESSION_PAGE_VIEWS,
                value: 5,
              },
            },
            {
              properties: {
                operator: '==',
                property: '_deviceType',
                value: 'desktop',
              },
            },
            {
              or: [
                {
                  properties: {
                    operator: propertyOperators.GREATER_THAN_X_DAYS_AGO,
                    property:
                      SATISFACTION_TARGETING_PROPERTIES.NPS_LAST_COLLECTED,
                    value: defaultSurveyDelayTimePeriod,
                  },
                },
                {
                  properties: {
                    operator: propertyOperators.NOT_EXISTS,
                    property:
                      SATISFACTION_TARGETING_PROPERTIES.NPS_LAST_COLLECTED,
                    value: '',
                  },
                },
              ],
            },
            {
              or: [
                {
                  and: [
                    {
                      properties: {
                        operator: '<=',
                        property:
                          SATISFACTION_TARGETING_PROPERTIES.SESSION_RANDOMIZER,
                        value: 5,
                      },
                    },
                    {
                      or: [
                        {
                          properties: {
                            operator: propertyOperators.NOT_EXISTS,
                            property:
                              SATISFACTION_TARGETING_PROPERTIES.ASK_ME_LATER,
                            value: '',
                          },
                        },
                        {
                          properties: {
                            operator: propertyOperators.GREATER_THAN_X_DAYS_AGO,
                            property:
                              SATISFACTION_TARGETING_PROPERTIES.ASK_ME_LATER,
                            value: defaultSurveyDelayTimePeriod,
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  and: [
                    {
                      properties: {
                        operator: propertyOperators.EXISTS,
                        property:
                          SATISFACTION_TARGETING_PROPERTIES.ASK_ME_LATER,
                        value: '',
                      },
                    },
                    {
                      properties: {
                        operator: propertyOperators.GREATER_THAN_X_DAYS_AGO,
                        property:
                          SATISFACTION_TARGETING_PROPERTIES.ASK_ME_LATER,
                        value: millisecondsPerDay * 7,
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const quantitativeOptionNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const DEFAULT_DARK_COLOR_HEX = '#100E44';
export const DEFAULT_LIGHT_COLOR_HEX = '#FFFFFF';
export const LIGHT_THEME = 'LIGHT';
export const DARK_THEME = 'DARK';
