import { takeEvery, put, select, getContext, call } from 'redux-saga/effects';
import toast from 'next/lib/toast';
import * as actionTypes from 'constants/actionTypes';
import * as accountManagementActionTypes from 'constants/accountManagement/actionTypes';
import { selectAccountUsers } from 'reducers/account/users';
import { selectUser } from 'reducers/user';
import {
  getAccountAdminUserIds,
  canManageAccountUsers,
} from 'helpers/account-management';
import { reportError } from 'helpers/error-reporting';
import { removeUser } from 'actions/account/users';

export function* removeAccountUserAndFlushUsers(action) {
  const { userToRemove } = action.payload;

  const customerApi = yield getContext('api');

  const accountUsers = yield select(selectAccountUsers);
  const loggedInUser = yield select(selectUser);
  const adminUserIds = getAccountAdminUserIds(accountUsers);

  if (!canManageAccountUsers({ user: loggedInUser, accountUsers })) {
    // eslint-disable-next-line no-console
    console.error(
      `(loggedInuser=${loggedInUser.id}, remove=${userToRemove.id}): is not account admin`
    );

    throw new Error('Cannot remove user if you are not an admin.');
  }

  if (adminUserIds.has(userToRemove.id) && adminUserIds.size < 2) {
    // eslint-disable-next-line no-console
    console.error(
      `(loggedInuser=${loggedInUser.id}, remove=${userToRemove.id}): cannot remove last admin`
    );
    throw new Error('Cannot remove the last admin from the team.');
  }

  try {
    yield call(customerApi.removeUser, userToRemove.id);

    yield put(removeUser({ id: userToRemove.id }));

    yield call(toast.success, 'User removed successfully');

    yield put({
      type: actionTypes.TRACK_EVENT,
      payload: {
        eventName: 'Removed User From Team',
        properties: {
          userId: userToRemove.id,
          email: userToRemove.email,
        },
      },
    });
  } catch (error) {
    yield call(reportError, error);
    yield call(toast.error, 'Failed to remove user from account');
  }
}

export default function* accountManagement() {
  yield takeEvery(
    accountManagementActionTypes.REMOVE_ACCOUNT_USER_AND_FLUSH_USERS,
    removeAccountUserAndFlushUsers
  );
}
