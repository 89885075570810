import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { CPanel, Text, CIconButton } from '@appcues/component-library';
import { ExternalLink, Icon } from '@studio/legacy-components';
import OpenInBuilderButton from 'components/OpenInBuilder/OpenInBuilderButton';
import QueryResults from 'components/Common/QueryResults';
import Table from 'components/Common/UI/Table';
import Loader from 'components/Common/Loader';
import ClickToCopy from 'components/Common/ClickToCopy/ClickToCopy';

import UserSampleTableUserCell from 'components/Common/UserSampleTable/UserSampleTableUserCell';

import StepChildIndex from './StepChildIndex';

const ISSUES_OVERVIEW_DOC_URL =
  'https://docs.appcues.com/article/694-issues-reporting';

const StyledUserSampleTableUserCell = styled(UserSampleTableUserCell)`
  display: inline;
`;

const StyledCPanel = styled(CPanel)`
  h2 {
    color: var(--foreground-primary);
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-small);
  }
`;

export const IssuesTable = ({ flowId, results }) => {
  if (!results) {
    return (
      <CPanel>
        <Loader margin="1em" />
      </CPanel>
    );
  }

  return (
    <StyledCPanel title="Issues reporting">
      <p>
        The most recent issues encountered by your users. Issues occur when
        Appcues cannot display a step due to a missing or incorrect selector.{' '}
        <ExternalLink href={ISSUES_OVERVIEW_DOC_URL}>Learn more</ExternalLink>
      </p>
      <Table
        panel
        isScrollable
        defaultSortColumn="timestamp"
        containerStyles={{ marginTop: 20, maxHeight: 400 }}
        columns={[
          {
            Header: 'Step',
            accessor: 'step_child_id',
            maxWidth: 80,
            Cell: function StepChildLinks({ value: stepChildId }) {
              return (
                <StepChildIndex flowId={flowId} stepChildId={stepChildId} />
              );
            },
          },
          {
            Header: 'User ID',
            accessor: 'user_id',
            maxWidth: 200,
            sortable: true,
            Cell: function UserDisplay({ value: user_id }) {
              // note: the truncate prop doesn't work right
              // as it requires the cell to have a fixed (max-)width
              return (
                <Text truncate>
                  <StyledUserSampleTableUserCell userId={user_id} />
                </Text>
              );
            },
          },
          {
            Header: 'URL',
            accessor: 'step_child_error_url',
            sortable: true,
            maxWidth: 300,
            Cell: function UrlDisplay({ value: errorURL }) {
              return (
                <ClickToCopy truncate content={errorURL}>
                  {errorURL}
                </ClickToCopy>
              );
            },
          },

          {
            Header: 'Issue',
            accessor: 'step_child_error_message',
            sortable: true,
            maxWidth: 300,
            Cell: function MessageDisplay({ value: errorMessage }) {
              return (
                <ClickToCopy truncate content={errorMessage}>
                  {errorMessage}
                </ClickToCopy>
              );
            },
          },
          {
            Header: '',
            maxWidth: 100,
            accessor: 'clickToOpenColumn',
            Cell: function ClickToOpen({
              row: {
                original: { stepChildId },
              },
            }) {
              return (
                <OpenInBuilderButton
                  stepGroupId={flowId}
                  stepChildId={stepChildId}
                  render={handleClick => (
                    <CIconButton
                      title="Open Step in Flow Builder"
                      border="none"
                      onClick={handleClick}
                    >
                      <Icon icon="external-link-alt" />
                    </CIconButton>
                  )}
                />
              );
            },
          },
          {
            Header: 'Last seen',
            accessor: 'timestamp',
            sortable: true,
            maxWidth: 300,
            Cell: ({ value }) => moment(value).format('lll'),
          },
        ]}
        data={results}
      />
    </StyledCPanel>
  );
};

IssuesTable.propTypes = {
  flowId: PropTypes.string,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      step_child_id: PropTypes.string,
      user_id: PropTypes.string,
      step_child_error_url: PropTypes.string,
      step_child_error_message: PropTypes.string,
      timestamp: PropTypes.string,
    })
  ),
};

const IssuesTableWithAnalytics = ({
  flowId,
  startTime,
  endTime,
  segmentId,
}) => (
  <QueryResults
    query={{
      columns: [
        'timestamp',
        'user_id',
        'step_child_id',
        'step_child_error_message',
        'step_child_error_url',
      ],
      conditions: [
        ['flow_id', '==', flowId],
        ['name', '==', 'appcues:step_child_error'],
        ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
      ],
      order_by: [['timestamp', 'desc']],
      start_time: startTime,
      end_time: endTime,
      limit: 25,
      meta: {
        tags: {
          feature: 'Flow analytics',
          page: 'Flow analytics',
          component: 'IssuesTable',
          description: 'Flow issues table',
        },
      },
    }}
  >
    {results => <IssuesTable results={results} flowId={flowId} />}
  </QueryResults>
);

IssuesTableWithAnalytics.propTypes = {
  flowId: PropTypes.string,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  segmentId: PropTypes.string,
};

export default IssuesTableWithAnalytics;
