/* global AUTH_APP_URL */

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import LogoutView from 'components/_base/LogoutView';
import Accounts from 'components/Accounts';

const ExternalRedirect = ({ from, to }) => (
  <Route
    path={from}
    component={() => {
      window.location.href = to;
      return null;
    }}
  />
);

export const getAccountRoutes = () => [
  <Route key="accounts" path="/accounts" component={Accounts} />,
  <Route key="logout" path="/logout" component={LogoutView} />,
  <Redirect key="login" from="/login" to="/" />,
  <ExternalRedirect
    key="register"
    from="/register"
    to={`${AUTH_APP_URL}/register`}
  />,
];
