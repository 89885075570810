import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Thead, Tbody, Tr } from '@studio/legacy-components';

import QueryResults from 'components/Common/QueryResults';
import Pagination, {
  PaginationMessage,
} from 'components/Common/UI/Pagination/Pagination';

import TableOverlay from 'components/Insights/Explorer/Common/TableOverlay';

import {
  ClearEvents,
  ControlHeader,
  EventNameHeader,
  PaginationWrapper,
  ResultsWrapper,
  SelectHeader,
  StatsHeader,
  StatusHeader,
  TableWrapper,
} from 'components/Insights/Common/Styled';

import {
  AddEventTooltip,
  TitleTooltip,
} from 'components/Insights/Explorer/Common/SourceTooltips';

import { GOAL_EVENTS_LABEL } from 'components/Insights/constants';
import useClientsideTable from 'hooks/useClientsideTable';
import { selectAccountGoals } from 'reducers/account/goals';
import GoalRow from './GoalRow';

/**
 * Given list of experience with analytics result, this will construct experience with individual with goal event for table
 * @param  experiences
 * @param  results
 * @returns
 */
export const mergeEventsAndResults = (goals, results) =>
  (goals || []).map(({ id, name, disabled }) => {
    const analytics = (results || []).filter(result => result.goal_id === id);

    const { most_recent_timestamp, users, events } = analytics.find(
      ({ name: eventName }) => eventName === 'appcues:goal_reached'
    ) || {
      events: 0,
      users: 0,
      most_recent_timestamp: 0,
    };

    return {
      totalEvents: analytics
        .filter(({ name: eventName }) => eventName === 'appcues:goal_reached')
        .reduce((acc, curr) => acc + curr.events, 0),
      most_recent_timestamp,
      totalUsers: 0,
      users,
      id,
      name,
      events,
      disabled,
    };
  });

export const GoalEvents = ({
  results,
  pageSize = 10,
  selectedEvents,
  enableSelect,
  searchTerm,
  onSelect,
  onClearEvents,
}) => {
  const {
    isEmpty,
    currentIndex,
    orderByColumn,
    orderByDirection,
    results: pageGoals,
    totalResults,
    handleSort,
    handleCurrentIndex,
  } = useClientsideTable({
    data: results,
    searchTerm,
    pageSize,
    defaultOrderColumn: 'totalEvents',
    defaultSortDirection: 'desc',
  });

  return (
    <>
      <ResultsWrapper>
        <TableOverlay
          isEmpty={isEmpty}
          results={results}
          type={GOAL_EVENTS_LABEL}
        />
        <TableWrapper>
          <Table>
            <Thead>
              <Tr>
                {pageGoals?.length > 0 ? (
                  <SelectHeader>
                    {selectedEvents?.some(evt => evt.source === 'goals') ? (
                      <ClearEvents
                        onClick={() => {
                          onClearEvents('goals');
                        }}
                      />
                    ) : (
                      <AddEventTooltip />
                    )}
                  </SelectHeader>
                ) : (
                  <ControlHeader />
                )}
                <EventNameHeader
                  sortable
                  sorted={orderByColumn === 'name' ? orderByDirection : null}
                  onClick={() => {
                    handleSort('name');
                  }}
                >
                  Goal Name
                </EventNameHeader>
                <StatusHeader
                  sortable
                  sorted={
                    orderByColumn === 'disabled' ? orderByDirection : null
                  }
                  onClick={() => {
                    handleSort('disabled');
                  }}
                >
                  Status
                </StatusHeader>
                <StatsHeader
                  sortable
                  sorted={orderByColumn === 'events' ? orderByDirection : null}
                  onClick={() => {
                    handleSort('events');
                  }}
                >
                  <TitleTooltip
                    tip="The number of times this goal was achieved."
                    title="Total Events"
                  />
                </StatsHeader>
                <StatsHeader
                  sortable
                  sorted={orderByColumn === 'users' ? orderByDirection : null}
                  onClick={() => {
                    handleSort('users');
                  }}
                >
                  <TitleTooltip
                    tip="The number of unique users who achieved this goal."
                    title="Unique Users"
                  />
                </StatsHeader>
                <StatsHeader
                  sortable
                  sorted={
                    orderByColumn === 'most_recent_timestamp'
                      ? orderByDirection
                      : null
                  }
                  onClick={() => {
                    handleSort('most_recent_timestamp');
                  }}
                >
                  Last Achieved
                </StatsHeader>
              </Tr>
            </Thead>
            <Tbody>
              {(pageGoals || []).map(
                ({
                  name,
                  disabled,
                  totalUsers,
                  totalEvents,
                  events,
                  most_recent_timestamp,
                  id,
                  users,
                  goals,
                }) => {
                  const checked = (selectedEvents || []).some(
                    ({ id: selectedId }) => selectedId === id
                  );
                  return (
                    <GoalRow
                      selectedEvents={selectedEvents}
                      onSelect={onSelect}
                      events={events}
                      enableSelect={enableSelect}
                      key={id}
                      users={users}
                      name={name}
                      active={!disabled}
                      id={id}
                      totalUsers={totalUsers}
                      totalEvents={totalEvents}
                      goals={goals}
                      checked={checked}
                      disabled={!enableSelect}
                      most_recent_timestamp={most_recent_timestamp}
                    />
                  );
                }
              )}
            </Tbody>
          </Table>
        </TableWrapper>
      </ResultsWrapper>
      {pageGoals && pageGoals.length > 0 && (
        <PaginationWrapper>
          <Pagination
            pageCount={Math.ceil(totalResults / pageSize)}
            currentIndex={currentIndex}
            onChange={handleCurrentIndex}
          />
          <PaginationMessage
            currentIndex={currentIndex}
            pageSize={pageSize}
            total={totalResults}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

GoalEvents.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      most_recent_timestamp: PropTypes.string,
      experience_id: PropTypes.string,
      step_id: PropTypes.string,
      users: PropTypes.number,
      events: PropTypes.number,
    })
  ),
  pageSize: PropTypes.number,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  enableSelect: PropTypes.bool,
  onSelect: PropTypes.func,
  searchTerm: PropTypes.string,
  onClearEvents: PropTypes.func,
};

export const mapStateToProps = (state, { searchTerm, results }) => {
  const goals = selectAccountGoals(state);
  const filtered = Object.values(goals).filter(({ name }) =>
    name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return {
    results: results && mergeEventsAndResults(filtered, results),
  };
};

const ConnectedGoalEvents = connect(mapStateToProps)(GoalEvents);

export const ConnectedGoalEventsWithAnalytics = ({
  startTime,
  endTime,
  searchTerm,
  segmentId,
  pageSize = 10,
  selectedEvents,
  enableSelect,
  onSelect,
  onClearEvents,
}) => (
  <QueryResults
    query={{
      conditions: [
        ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
      ],
      dimensions: ['goal_id', 'name'],
      metrics: ['most_recent_timestamp', 'events', 'users'],
      start_time: startTime,
      end_time: endTime,
      meta: {
        tags: {
          feature: 'Event analytics',
          page: 'Event explorer',
          component: 'GoalEventsWithAnalytics',
          description: 'Goal event source',
        },
      },
    }}
  >
    {results => (
      <ConnectedGoalEvents
        results={results}
        pageSize={pageSize}
        searchTerm={searchTerm}
        startTime={startTime}
        endTime={endTime}
        selectedEvents={selectedEvents}
        enableSelect={enableSelect}
        onSelect={onSelect}
        onClearEvents={onClearEvents}
      />
    )}
  </QueryResults>
);

ConnectedGoalEventsWithAnalytics.propTypes = {
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  searchTerm: PropTypes.string,
  segmentId: PropTypes.string,
  pageSize: PropTypes.number,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  enableSelect: PropTypes.bool,
  onSelect: PropTypes.func,
  onClearEvents: PropTypes.func,
};

export default ConnectedGoalEventsWithAnalytics;
