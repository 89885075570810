import React from 'react';
import styled from 'styled-components';

import { ExternalLink } from '@studio/legacy-components';
import CenteredPage from 'components/Common/CenteredPage';
import HorizontalRule from 'components/Common/HorizontalRule';
import { STATUS_LINK, SUPPORT_EMAIL } from 'constants/externalAppcuesResources';

const AppcuesErrorPage = () => {
  return (
    <CenteredPage>
      <SadEmoji>😔</SadEmoji>
      <ApologyHeader>
        We’re sorry, something went wrong on our end.
      </ApologyHeader>
      <ApologyText className="apology-text">
        You can try again by refreshing the page. If the problem persists,
        contact us:{' '}
        <ExternalLink href={`mailto:${SUPPORT_EMAIL}`}>
          {SUPPORT_EMAIL}
        </ExternalLink>
      </ApologyText>
      <HorizontalRule width="240px" />
      <StatusLink href={STATUS_LINK}>Appcues Status</StatusLink>
    </CenteredPage>
  );
};

const SadEmoji = styled.p`
  font-size: 48px;
`;

const ApologyHeader = styled.h2`
  margin: 0 0 11px;
`;

const ApologyText = styled.p`
  color: ${props => props.theme['$gray-8']}
  font-size: 16px;
  line-height: 26px;

  &.apology-text {
    margin-bottom: 0px;
  }
`;

const StatusLink = styled(ExternalLink)`
  font-size: 12px;
`;

export default AppcuesErrorPage;
