import React from 'react';
import PropTypes from 'prop-types';
import { CDialog } from '@appcues/component-library';

export default function DeleteChecklistModal({
  actions,
  deleteChecklist,
  checklistId,
}) {
  const handleClickDelete = () => {
    deleteChecklist(checklistId);
    actions.hideModal();
  };

  return (
    <CDialog
      title="Delete Checklist"
      type="negative"
      primaryActionText="Delete Checklist"
      onPrimaryAction={handleClickDelete}
      onSecondaryAction={actions.hideModal}
    >
      <p>
        Deleting a Checklist will permanently remove it from your account and
        prevent you from viewing any results associated with it.
      </p>

      <p>Are you sure you&apos;d like to continue?</p>
    </CDialog>
  );
}

DeleteChecklistModal.propTypes = {
  checklistId: PropTypes.string.isRequired,
  deleteChecklist: PropTypes.func.isRequired,
  actions: PropTypes.shape({ hideModal: PropTypes.func.isRequired }).isRequired,
};
