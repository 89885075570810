import React from 'react';
import styled from 'styled-components';
import {
  STEP_TYPE_IDS_WITHOUT_CHILDREN,
  STEP_TYPES,
} from 'constants/stepTypes';
import { StepIcon } from 'components/Common/Icons';

const StepPreviewFooter = ({ stepTypeId, childIndex, className }) => {
  const stepType = STEP_TYPES[stepTypeId];
  return (
    <div className={className}>
      <StepIcon stepType={stepType} />
      {stepType.childNoun}
      {!STEP_TYPE_IDS_WITHOUT_CHILDREN.has(stepTypeId) ? (
        <div>&nbsp;{childIndex + 1}</div>
      ) : null}
    </div>
  );
};

export default styled(StepPreviewFooter)`
  font-size: 0.9em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  svg {
    height: 20px;
    width: 20px;
    margin-right: 8px;

    path {
      fill: rgba(113, 109, 161, 0.54);
    }

    circle {
      stroke: rgba(113, 109, 161, 0.54);
    }
  }
`;
