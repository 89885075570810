import {
  USER_INVITED,
  TRANSFER_ACCEPTED,
  USER_REMOVED,
  USER_ROLE_UPDATED,
  USER_ROLE_UPDATE_FAILED,
  USER_ROLE_UPDATE_SUCCEEDED,
} from 'constants/account/users';

import {
  REPLACE_ACCOUNT_USERS,
  REPLACE_ACCOUNT_USER_META,
} from 'constants/actionTypes';

export const inviteUser = ({
  email,
  name,
  role,
  job,
  singleSignOn,
  passwordlessInvitation,
}) => ({
  type: USER_INVITED,
  payload: { email, name, role, job, singleSignOn, passwordlessInvitation },
});

export const acceptTransfer = ({ accountId }) => ({
  type: TRANSFER_ACCEPTED,
  payload: { accountId },
});

export const removeUser = ({ id }) => ({
  type: USER_REMOVED,
  payload: { id },
});

export const updateUserRole = ({ userId, role }) => ({
  type: USER_ROLE_UPDATED,
  payload: {
    userId,
    role,
  },
});

export const resolveUserRoleUpdate = () => ({
  type: USER_ROLE_UPDATE_SUCCEEDED,
});

export const failUserRoleUpdate = () => ({
  type: USER_ROLE_UPDATE_FAILED,
});

export const replaceAccountUsers = accountUsers => ({
  type: REPLACE_ACCOUNT_USERS,
  payload: accountUsers,
});

export const replaceAccountUserMeta = (id, meta) => ({
  type: REPLACE_ACCOUNT_USER_META,
  payload: { id, meta },
});
