import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';
import ExportModal from './ExportModal';

export default function ExportModalManager({ id }) {
  const [visible, toggleVisible] = useToggle(false);

  return (
    <>
      <Button kind="secondary" onClick={toggleVisible}>
        Export
      </Button>
      <ExportModal visible={visible} onClose={toggleVisible} id={id} />
    </>
  );
}

ExportModalManager.propTypes = {
  id: PropTypes.string,
};
