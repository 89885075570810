import React from 'react';
import PropTypes from 'prop-types';
import {
  CCard,
  CSkeleton,
  Text,
  Caption,
  M2,
} from '@appcues/component-library';
import Delta from 'components/Common/Delta/Delta';
import { numberWithCommas } from 'utils/textFormatting';

const MetricCard = ({
  loaded = true,
  name,
  metric,
  metricType,
  previousMetric,
  delta,
  deltaTimePeriod,
  caption,
  children,
}) => (
  <CCard padding={16} width="100%">
    {loaded ? (
      <>
        {name && (
          <Text type="tertiary" marginBottom={5}>
            {name}
          </Text>
        )}
        {(metric || metric === 0) && (
          <M2>
            {metric === undefined
              ? '...'
              : typeof metric === 'string' || Number.isNaN(metric)
              ? metric
              : numberWithCommas(Math.round(metric))}
            {metricType === 'percentage' && '%'}
          </M2>
        )}
        {metric !== 0 && (delta || previousMetric) && (
          <Delta
            delta={delta}
            value={metric}
            previousValue={previousMetric}
            timePeriod={deltaTimePeriod}
          />
        )}
        {children}
        {caption && (
          <Caption bold type="tertiary" marginTop={10}>
            {caption}
          </Caption>
        )}
      </>
    ) : (
      <>
        <CSkeleton width="30%" height={20} marginBottom={10} />
        <CSkeleton width="60%" height={30} />
        <CSkeleton width="30%" height={15} marginTop={10} />
      </>
    )}
  </CCard>
);

MetricCard.propTypes = {
  loaded: PropTypes.bool,
  name: PropTypes.string,
  caption: PropTypes.string,
  metric: PropTypes.number,
  metricType: PropTypes.string,
  previousMetric: PropTypes.number,
  delta: PropTypes.number,
  deltaTimePeriod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.object,
};

export default MetricCard;
