import { createCollectionReducer } from 'next/lib/collections';
import { selectRequestStatus } from 'next/entities/requests';
import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

export const selectTags = state => state[TYPE];
export const selectTag = (state, id) => selectTags(state)[id];

export const readTags = state => ({
  ...selectRequestStatus(state, TYPE),
  data: selectTags(state),
});
export const readTag = (state, id) => ({
  ...selectRequestStatus(state, TYPE, id),
  data: selectTag(state, id),
});
