import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Badge } from '@appcues/sonar';

import { faCircleDot } from '@fortawesome/pro-regular-svg-icons';
import { Td, Tr, Link } from '@studio/legacy-components';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import EventToggle from 'components/Insights/Explorer/Common/EventToggle';
import { NameColumn, SelectColumn } from 'components/Insights/Common/Styled';
import { encodeEventNameUrl } from 'components/Insights/utils';

const eventType = 'appcues:goal_reached';

export const GOAL_SINGLE_METRIC_URL = `/insights/explorer/goals/${encodeEventNameUrl(
  eventType,
  'goals'
)}`;

export const GoalRow = ({
  name,
  active,
  users,
  totalEvents,
  most_recent_timestamp,
  onSelect,
  checked = false,
  disabled = false,
  id,
}) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  if (!searchParams.has('id')) {
    searchParams.append('id', id);
  }

  return (
    <Tr>
      <SelectColumn>
        <EventToggle
          label={`Select goal event: ${name}`}
          checked={checked}
          disabled={disabled}
          onClick={() => {
            onSelect({
              source: 'goals',
              event: eventType,
              id,
            });
          }}
        />
      </SelectColumn>
      <NameColumn>
        <Link
          to={{
            pathname: GOAL_SINGLE_METRIC_URL,
            search: searchParams.toString(),
          }}
          title={`${name} detail page`}
        >
          {name || '-'}
        </Link>
      </NameColumn>
      <Td>
        <Badge
          variant={active ? 'active' : 'default'}
          icon={active ? faCircleDot : faCircle}
        >
          {active ? 'Active' : 'Inactive'}
        </Badge>
      </Td>
      <Td>{totalEvents ? totalEvents.toLocaleString() : '-'}</Td>
      <Td>{users}</Td>
      <Td>
        {most_recent_timestamp
          ? moment(most_recent_timestamp).format('lll')
          : '-'}
      </Td>
    </Tr>
  );
};

GoalRow.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  onSelect: PropTypes.func,
  enableSelect: PropTypes.bool,
  name: PropTypes.string,
  totalUsers: PropTypes.number,
  totalEvents: PropTypes.number,
  most_recent_timestamp: PropTypes.string,
  goals: PropTypes.arrayOf(
    PropTypes.shape({
      stepId: PropTypes.string,
      stepName: PropTypes.string,
      type: PropTypes.string,
      eventType: PropTypes.string,
      users: PropTypes.number,
      most_recent_timestamp: PropTypes.string,
    })
  ),

  id: PropTypes.string,
  active: PropTypes.bool,
};

export default GoalRow;
