import React from 'react';
import PropTypes from 'prop-types';
import { ConditionsShape } from '@studio/conditions';

import { PublishingBanner } from '@studio/settings-panels';
import ExperiencePublishingManager from 'next/components/ExperiencePublishingManager';

export function ExperienceBanner({ id, sections, type }) {
  return (
    <ExperiencePublishingManager type={type} sections={sections} id={id}>
      {PublishingBanner}
    </ExperiencePublishingManager>
  );
}

ExperienceBanner.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  sections: PropTypes.objectOf(ConditionsShape),
};

export default ExperienceBanner;
