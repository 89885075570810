import React, { useEffect, useState } from 'react';
import { CCheckbox } from '@appcues/component-library';
import SelectFlow from 'components/Common/SelectFlow';

export default function ContentTriggering({ rule, setNextStepId }) {
  const [showNextStep, setShowNextStep] = useState(Boolean(rule.nextContentId));

  useEffect(() => {
    setShowNextStep(Boolean(rule.nextContentId));
  }, [rule.nextContentId]);

  return (
    <>
      <CCheckbox
        onClick={() => {
          if (showNextStep) {
            setNextStepId(rule.id, null);
            setShowNextStep(false);
          } else {
            setShowNextStep(true);
          }
        }}
        label="Trigger Appcues content"
        checked={showNextStep}
      />
      {showNextStep && (
        <div className="content">
          <SelectFlow
            value={rule.nextContentId}
            stepFilter={step => !step.parentId}
            onChange={value => {
              setNextStepId(rule.id, value);
            }}
          />
        </div>
      )}
    </>
  );
}
