import { createGlobalStyle } from 'styled-components';
import 'styles/global.css';
import '@appcues/sonar-tokens/dist/variables.css';
import '@typeform/embed/build/css/popup.css';

const GlobalStyle = createGlobalStyle`
  button,
  [role='button'] {
    cursor: pointer;
  }

  [disabled] {
    cursor: not-allowed;
  }



  /* this is the chart overlay backdrop */
  g.highcharts-tooltip {
    display:none;
  }

  /* Appcues Launchpad */
  .appcues-widget-dropdown.appcues-widget-fixed.appcues-widget-top-right {
    background: var(--dropdown-bg);
    border-radius: 6px;
    box-shadow: var(--dropdown-menu-shadow);
    margin: 0 0 0 65px;
    padding: 10px;
    position: fixed;

    /**
     * NOTE: Center the menu with the widget icon by nudging:
     *   - Half the height of the arrow
     *   - Height of the icon that was not accounted in positioning
     *   - Percent from top the arrow is positioned
     */
    transform: translateY(calc((10px / 2) + 30px - 60%));

    &:after, &:before {
      left: -10px;
      top: 60%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-right-color: var(--dropdown-bg);
      border-width: 10px;
    }

  }

  .appcues-widget-dropdown .appcues-widget-header {
    border-bottom: 1px solid var(--dropdown-option-bg);
    color: var(--dropdown-text);
    padding: 4px 8px;
    margin-bottom: 8px;
  }

  .appcues-widget-header-text {
    font-weight: var(--medium);
    font-size: var(--large);
    line-height: 1.5em;
    letter-spacing: 0;
  }

  .appcues-widget-container {
    li.appcues-nothing-new {
      display: none;
    }

    a[data-itemid] time {
      display: none;
    }
  }

  .appcues-widget-list {
    border-bottom: none;

    li {
      border-bottom: none;

      &.appcues-unread {
        color: var(--dropdown-text);
        font-weight: normal
      }

      a {
        border-radius: 6px;
        padding: 8px 12px;

        &:hover {
          background: var(--dropdown-option-bg);
        }
      }
    }
  }

  .appcues-widget-icon {
    color: rgb(54, 71, 96);
    margin-left: 12px;
    opacity: 1; /* override widget fade-in */

    &:hover {
      color: var(--nav-hover);

      .svg-inline--fa path {
        color: var(--nav-hover);
      }
    }

    &.appcues-icon-visible::before {
      content: 'Resources';
      font-size: 14px;
      left: 25px;
      position: absolute;
      top: 8px;
      width: 100px;
    }

    &.appcues-icon-visible::after {
      display: none;
    }

    .svg-inline--fa {
      width: 0.5em
    }
  }

  /* FIXME: This will be removed once we get rid of component library */
  .svg-inline--fa.fa-caret-down {
    top: 8px;
    right: 8px;
  }

  @media print {
    [data-no-print] {
      display: none;
    }

    body {
      -webkit-print-color-adjust: exact;
    }

    /* hide appcues checklists */
    appcues-checklists {
      display: none;
    }
  }
`;

export default GlobalStyle;
