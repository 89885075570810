import { connect } from 'react-redux';
import { SpoofBanner } from '@studio/navigation';

import { selectAccount } from 'next/entities/account';
import { selectSpoofing, stop } from 'next/entities/spoofing';

const mapStateToProps = state => ({
  accountId: selectAccount(state).id,
  spoofing: selectSpoofing(state),
});

const mapDispatchToProps = {
  onDespoof: stop,
};

export default connect(mapStateToProps, mapDispatchToProps)(SpoofBanner);
