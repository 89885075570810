import styled from 'styled-components';

const InstallCard = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${props => (props.isInstalled ? 'row' : 'column')};
  justify-content: ${props => (props.isInstalled ? 'flex-end' : 'center')};
  width: ${props => (props.isInstalled ? '100%' : '48%')};
  height: ${props => (props.isInstalled ? '72px' : '100%')};
  background-color: ${props => props.theme['$gray-0']};
  box-shadow: 0px 2px 2px rgba(26, 43, 73, 0.08);
  border-radius: 6px;
  font-size: 16px;
  padding: ${props => (props.isInstalled ? '0' : '28px')};
  margin-top: ${props => (props.isInstalled ? '0' : '24px')};
`;

export default InstallCard;
