import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { CPanel, Text } from '@appcues/component-library';

const Panel = ({ className, name, description, children, ...boxProps }) => (
  <CPanel {...boxProps} title={name} className={classNames('panel', className)}>
    {description && <Panel.Description>{description}</Panel.Description>}
    {children}
  </CPanel>
);

Panel.Description = props => (
  <Text is="p" marginTop={-20} color="$gray-5" {...props} />
);

Panel.Description.displayName = 'Panel.Description';

export default styled(Panel)`
  // TODO: remove once CCheckbox and CRadio are used;
  .option {
    display: flex;
    align-items: center;
    padding: 5px 0;
    label {
      cursor: pointer;
      input[type='radio'],
      input[type='checkbox'] {
        position: inherit;
        margin: 0 10px 0 0;
      }
      &.domain {
        flex-direction: row;
        min-width: 100px;
      }
    }
  }
  .content {
    padding: 5px 23px 0;
    .help-text {
      color: #7e89a9;
    }
  }
`;
