/* global MOBILE_BUILDER_URL */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  ExternalLink,
  H3,
  Icon,
  Panel,
  PanelHeader,
  PanelTitle,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@studio/legacy-components';
import { Shape as ExperienceShape } from 'next/entities/experiences';
import { format } from 'next/lib/date';
import NoData from 'next/assets/images/no-data.svg';
import {
  readAnalytics,
  selectExperienceStepBreakdownAnalytics,
} from 'next/entities/analytics';
import { queryExperienceStepBreakdown } from 'next/client/queries';
import { selectPublishedExperience } from 'next/lib/selectors';
import StepsCarousel from 'next/components/StepsCarousel';

import {
  Description,
  LoaderWrapper,
  TableWrapper,
  NoResultsMessage,
  StepIndex,
  StepLabel,
  StepFunnel,
  StepCompletion,
  TooltipContent,
  ImageStepsContainer,
} from './StepBreakdown.styled';

const LEARN_MORE_HREF =
  'https://docs.appcues.com/article/693-step-by-step-overview';

export function StepBreakdown({ id, experience, analytics, onLoad }) {
  useEffect(() => {
    onLoad?.();
  }, [onLoad]);

  const isLoading = !experience || !analytics;
  const hasResults = !isLoading && experience?.steps.length > 0;

  const analyticsById = (analytics ?? []).reduce((acc, cur) => {
    const { step_id: stepId } = cur;
    acc[stepId] = cur;
    return acc;
  }, {});

  const stepChildren = (experience?.steps ?? []).flatMap(
    ({ children }) => children
  );

  const analyticsByStep = stepChildren.map(
    ({ id: stepId, type, createdAt }, globalIndex) => {
      const {
        step_shown_users: shown = 0,
        step_completed_users: completed = 0,
      } = analyticsById[stepId] ?? {};

      const percentCompleted =
        shown > 0 ? Math.round(100 * Math.min(1, completed / shown)) : 0;

      const [{ id: firstStepId }] = stepChildren;
      const { step_shown_users: totalShown = 0 } =
        analyticsById[firstStepId] ?? {};

      const globalCompletionRate =
        totalShown > 0
          ? Math.round(100 * Math.min(1, completed / totalShown))
          : 0;

      return {
        stepId,
        type,
        createdAt,
        shown,
        completed,
        percentCompleted,
        globalIndex,
        globalCompletionRate,
      };
    }
  );

  return (
    <Panel>
      <PanelHeader>
        <PanelTitle>
          <H3>Step breakdown</H3>
          <Description>
            <Text>This table shows data for unique users</Text>
            <Tooltip
              size="m"
              wrapped
              persist
              label={
                <TooltipContent>
                  Flow steps that were added, reordered or removed may contain
                  data discrepancies.{' '}
                  <ExternalLink href={LEARN_MORE_HREF}>Learn more</ExternalLink>
                </TooltipContent>
              }
            >
              <Icon icon="info-circle" />
            </Tooltip>
          </Description>
        </PanelTitle>
      </PanelHeader>

      <ImageStepsContainer>
        <StepsCarousel
          type="mobile"
          experienceId={id}
          steps={experience?.steps}
          state={experience?.state}
        />
      </ImageStepsContainer>

      <TableWrapper>
        {isLoading && (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        )}
        {!isLoading && !hasResults && (
          <NoResultsMessage>
            <img src={NoData} alt="No data found" />
            No results, please try different filters.
          </NoResultsMessage>
        )}
        {!isLoading && (
          <Table>
            <Thead>
              <Tr>
                <Th>Step</Th>
                <Th>Created</Th>
                <Th>Shown</Th>
                <Th>Completed</Th>
                <Th>Completion rate</Th>
                <Th>Completion funnel</Th>
              </Tr>
            </Thead>
            <Tbody>
              {analyticsByStep.map(
                ({
                  stepId,
                  type,
                  createdAt,
                  shown,
                  completed,
                  percentCompleted,
                  globalIndex,
                  globalCompletionRate,
                }) => (
                  <Tr key={stepId}>
                    <Td>
                      <StepLabel>
                        <StepIndex>{globalIndex + 1}</StepIndex>
                        {type}
                        <ExternalLink
                          title="Open step in Builder"
                          href={`${MOBILE_BUILDER_URL}/flows/${id}/steps/${stepId}`}
                        />
                      </StepLabel>
                    </Td>
                    <Td>{format(createdAt, 'LL')}</Td>
                    <Td>{shown}</Td>
                    <Td>{completed}</Td>
                    <Td>
                      {percentCompleted > 0 ? `${percentCompleted}%` : '-'}
                    </Td>
                    <Td>
                      <StepLabel>
                        <StepFunnel>
                          <StepCompletion $rate={globalCompletionRate} />
                        </StepFunnel>
                        {globalCompletionRate}%
                      </StepLabel>
                    </Td>
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        )}
      </TableWrapper>
    </Panel>
  );
}

StepBreakdown.propTypes = {
  id: PropTypes.string,
  experience: ExperienceShape,
  analytics: PropTypes.arrayOf(
    PropTypes.shape({
      step_shown_users: PropTypes.number,
      step_completed_users: PropTypes.number,
      step_id: PropTypes.string,
    })
  ),
  onLoad: PropTypes.func,
};

const getQueryParams = ({ id, segmentId, startTime, endTime }) => ({
  experienceId: id,
  startTime,
  endTime,
  segmentId,
});

const mapStateToProps = (state, ownProps) => ({
  experience: selectPublishedExperience(state, ownProps.id),
  analytics: selectExperienceStepBreakdownAnalytics(
    state,
    getQueryParams(ownProps)
  ),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoad: () =>
    dispatch(
      readAnalytics(queryExperienceStepBreakdown(getQueryParams(ownProps)))
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepBreakdown);
