import React from 'react';
import styled, { css } from 'styled-components';
import { Icon, IconButton, Td, Th } from '@studio/legacy-components';
import { PaginationContainer } from 'components/Common/UI/Pagination/Pagination';

export const ResultsWrapper = styled.div`
  position: relative;
  min-height: 400px;
`;

export const CheckboxWrapper = styled.div`
  margin: 0 -5px 0 0;
  label {
    margin: 0 !important;
    span {
      font-weight: normal;
    }
  }
`;

export const Footer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 2em;
  padding-top: 30px;
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

export const ExternalLinkIcon = () => <StyledIcon icon="external-link-alt" />;

export const LiveIcon = styled(Icon).attrs(() => ({
  icon: 'check-circle',
  size: 's',
}))`
  color: var(--status-active);
`;

export const DraftIcon = styled(Icon).attrs(() => ({
  icon: 'minus-circle',
  size: 's',
}))`
  color: var(--status-inactive);
`;

export const SelectedIcon = styled(Icon).attrs(() => ({
  icon: 'check',
}))`
  margin-left: 5px;
  color: var(--link);
`;

export const InfoIcon = styled(Icon).attrs(() => ({ icon: 'info-circle' }))`
  color: var(--text-secondary);
  margin-right: 10px;
`;

export const ControlHeader = styled(Th)`
  width: 40px;
  height: 40px;
  padding: 0;
`;

const baseSelectStyle = css`
  background-color: var(--table-header-hover);
  height: 40px;
  padding: 0;
  text-align: center;
  width: 40px;

  ${IconButton} {
    margin: 0 auto;
  }
`;

export const SelectHeader = styled(Th)`
  ${baseSelectStyle}

  /* To center the tooltip. Targeting Tooltip inside does not work */
  > * {
    display: block;
  }
`;

export const SelectColumn = styled(Td)`
  ${baseSelectStyle}
`;

export const NameColumn = styled(Td)`
  white-space: normal;
  word-break: break-word;
`;

export const TableWrapper = styled.div`
  background-color: var(--white);
  border-top: 1px solid var(--table-border);
  margin: 24px -24px 0;
  overflow-y: visible;
  position: relative;
`;

export const ClearEvents = styled(IconButton).attrs({
  icon: 'minus',
  label: 'Clear all selected events',
})``;

export const PaginationWrapper = styled(PaginationContainer)`
  margin: 48px 0 0;
`;

export const ExpandedNameColumn = styled(Td)`
  width: 378px;
  max-width: 378px;
`;

export const EventNameHeader = styled(Th)`
  width: 378px;
`;

export const StatsHeader = styled(Th)`
  width: 175px;
`;

export const StatusHeader = styled(Th)`
  width: 142px;
`;

export const SelectedEventsTransition = styled.div`
  max-height: 0;
  opacity: 0;
  transition-property: opacity, max-height;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;

  &.fade-appear,
  &.fade-enter,
  &.fade-exit,
  &.fade-exit-active,
  &.fade-exit-done {
    max-height: 0;
    opacity: 0;
  }

  &.fade-appear-active,
  &.fade-appear-done,
  &.fade-enter-active,
  &.fade-enter-done {
    max-height: 9999px;
    opacity: 1;
  }
`;
