import React from 'react';
import moment from 'moment';
import { Modal, Icon, Badge } from '@appcues/sonar';
import { faClockRotateLeft, faXmark } from '@fortawesome/pro-solid-svg-icons';
import AvatarImg from 'components/Common/AvatarImg';
import { VersionList, VersionListItem, Button, User, Date } from './styled';

const BADGE_COLORS = {
  Published: 'active',
  'Pushed changes': 'info',
  Unpublished: 'default',
};

export default function SummaryModal({ history }) {
  return (
    <Modal.Root
      size="regular"
      trigger={
        <Button title="See flow history" iconOnly variant="tertiary">
          <Icon icon={faClockRotateLeft} />
        </Button>
      }
    >
      <Modal.Title>Flow History</Modal.Title>
      <Modal.Close>
        <Icon size="large" icon={faXmark} />
      </Modal.Close>
      <VersionList>
        {history.map(({ updatedAt, user, status }) => (
          <VersionListItem key={updatedAt}>
            <Badge variant={BADGE_COLORS[status]}>{status}</Badge>
            <User>
              <AvatarImg userId={user.id} width={24} /> {user.fullname}
            </User>
            {/* Use momento to format as exemple: Feb 2, 2024 08:32AM */}
            <Date>{moment(updatedAt).format('MMM D, YYYY hh:mmA')}</Date>
          </VersionListItem>
        ))}
      </VersionList>
    </Modal.Root>
  );
}
