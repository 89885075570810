import {
  addClause,
  conditionNames,
  deleteMatchingClauses,
  filterAudienceTargetingClauses,
  propertyOperators,
} from '@appcues/libcues';

export function setSegmentCustomSegment(clauses, defaultProperty = '') {
  return addClause(
    deleteMatchingClauses(clauses, filterAudienceTargetingClauses),
    null,
    {
      conditionName: conditionNames.PROPERTIES,
      property: defaultProperty,
      operator: propertyOperators.CONTAINS,
      value: '',
    },
    false
  );
}
