import Recase from 'recase';
import {
  FETCH_CHECKLIST_ANALYTICS,
  FETCH_CHECKLIST_ANALYTICS_ERROR,
  FETCH_CHECKLIST_ANALYTICS_SUCCESS,
  FETCH_FLOW_LIST_ANALYTICS,
  FETCH_FLOW_LIST_ANALYTICS_ERROR,
  FETCH_FLOW_LIST_ANALYTICS_SUCCESS,
  SET_GOALS_REACHED_BY_WEEKLY_ACCOUNT_USERS_SUCCESS,
  SET_GOALS_REACHED_BY_WEEKLY_ACCOUNT_USERS_FETCHING,
  SET_GOALS_REACHED_BY_WEEKLY_ACCOUNT_USERS_ERROR,
  SET_GOAL_REACHED_USER_SAMPLE_SUCCESS,
  SET_GOAL_REACHED_USER_SAMPLE_FETCHING,
  SET_GOAL_REACHED_USER_SAMPLE_ERROR,
  SET_GOALS_FIRST_REACHED_THIS_WEEK_FETCHING,
  SET_GOALS_FIRST_REACHED_THIS_WEEK_ERROR,
  SET_GOALS_FIRST_REACHED_THIS_WEEK_SUCCESS,
  SET_FLOW_PERFORMANCE_REPORT_DATA_FETCHING,
  SET_FLOW_PERFORMANCE_REPORT_DATA_SUCCESS,
  SET_FLOW_PERFORMANCE_REPORT_DATA_ERROR,
  SET_FLOW_ERROR_REPORT_DATA_FETCHING,
  SET_FLOW_ERROR_REPORT_DATA_SUCCESS,
  SET_FLOW_ERROR_REPORT_DATA_ERROR,
  SET_GOALS_REACHED_FROM_FLOW_STARTED_FETCHING,
  SET_GOALS_REACHED_FROM_FLOW_STARTED_ERROR,
  SET_GOALS_REACHED_FROM_FLOW_STARTED_SUCCESS,
} from 'constants/analytics/actionTypes';
import { selectAccountId } from 'reducers/account/meta';

const recase = Recase.create({});

const transformChecklistAnalyticsResults = results => {
  return results.reduce((acc, row) => {
    if (row.item_id === 'checklist') {
      return {
        ...acc,
        ...recase.camelCopy(row),
      };
    }
    return {
      ...acc,
      items: {
        ...acc.items,
        [row.item_id]: recase.camelCopy(row),
      },
    };
  }, {});
};

const transformFlowListAnalyticsResults = results => {
  return results.reduce((acc, row) => {
    const flowId = row.flow_id;
    return {
      ...acc,
      [flowId]: {
        'all-time-unique': {
          completed: row.flow_completed,
          reached: row.flow_started,
          seen: row.flow_started,
        },
      },
    };
  }, {});
};

const getAnalyticsReducerKey = action => {
  const { type, payload } = action;
  if (/GOALS_REACHED_BY_WEEKLY_ACCOUNT_USERS/.test(type)) {
    return 'goals_reached_by_weekly_account_users';
  }
  if (/GOAL_REACHED_USER_SAMPLE/.test(type)) {
    return `goal_reached_user_sample+${payload.goalId}`;
  }
  if (/GOALS_FIRST_REACHED_THIS_WEEK/.test(type)) {
    return 'goals_first_reached_this_week';
  }
  if (/GOALS_REACHED_FROM_FLOW_STARTED/.test(type)) {
    return 'goal_reached_from_flow_started';
  }
  if (/SEGMENTS_ENTERED_BY_WEEKLY_ACCOUNT_USERS/.test(type)) {
    return 'segments_entered_by_weekly_account_users';
  }
  if (/SEGMENT_ENTERED_USER_SAMPLE/.test(type)) {
    return `segment_entered_user_sample+${payload.segmentId}`;
  }
  if (/FLOW_PERFORMANCE_REPORT_DATA/.test(type)) {
    return 'flow_performance_report_data';
  }
  if (/FLOW_ERROR_REPORT_DATA/.test(type)) {
    return 'flow_error_report_data';
  }
  if (/FLOW_EVENT_ROLLUPS_BY_DAY/.test(type)) {
    return `flow_event_rollups_by_day+${payload.flowId}`;
  }
  if (/FLOW_EVENT_USER_SAMPLE/.test(type)) {
    return `flow_event_user_sample+${payload.flowId}+${payload.eventName}`;
  }
  return payload.flowId || payload.checklistId || payload.accountId;
};

/*
 * The observant reader will note that checklist analytics and flow analytics
 * are handled exactly the same. This is because they will each have unique
 * firebase IDs, so they can be safely stored in the same home without
 * collisions.
 */
export default function analytics(state = {}, action) {
  switch (action.type) {
    case FETCH_CHECKLIST_ANALYTICS:
    case FETCH_FLOW_LIST_ANALYTICS:
    case SET_GOALS_REACHED_BY_WEEKLY_ACCOUNT_USERS_FETCHING:
    case SET_GOAL_REACHED_USER_SAMPLE_FETCHING:
    case SET_GOALS_FIRST_REACHED_THIS_WEEK_FETCHING:
    case SET_GOALS_REACHED_FROM_FLOW_STARTED_FETCHING:
    case SET_FLOW_PERFORMANCE_REPORT_DATA_FETCHING:
    case SET_FLOW_ERROR_REPORT_DATA_FETCHING: {
      const key = getAnalyticsReducerKey(action);

      return {
        ...state,
        [key]: {
          meta: {
            fetching: true,
            synced: false,
            errored: false,
          },
        },
      };
    }
    case FETCH_CHECKLIST_ANALYTICS_ERROR:
    case FETCH_FLOW_LIST_ANALYTICS_ERROR:
    case SET_GOALS_REACHED_BY_WEEKLY_ACCOUNT_USERS_ERROR:
    case SET_GOAL_REACHED_USER_SAMPLE_ERROR:
    case SET_GOALS_FIRST_REACHED_THIS_WEEK_ERROR:
    case SET_GOALS_REACHED_FROM_FLOW_STARTED_ERROR:
    case SET_FLOW_PERFORMANCE_REPORT_DATA_ERROR:
    case SET_FLOW_ERROR_REPORT_DATA_ERROR: {
      const key = getAnalyticsReducerKey(action);
      return {
        ...state,
        [key]: {
          meta: {
            fetching: false,
            synced: true,
            errored: true,
          },
        },
      };
    }
    case FETCH_CHECKLIST_ANALYTICS_SUCCESS: {
      const { payload } = action;
      const key = payload.checklistId;
      return {
        ...state,
        [key]: {
          meta: {
            fetching: false,
            synced: true,
            errored: false,
          },
          data: transformChecklistAnalyticsResults(payload.results),
        },
      };
    }
    case FETCH_FLOW_LIST_ANALYTICS_SUCCESS: {
      const { payload } = action;
      const key = payload.accountId;
      return {
        ...state,
        [key]: {
          meta: {
            fetching: false,
            synced: true,
            errored: false,
          },
          data: transformFlowListAnalyticsResults(payload.results),
        },
      };
    }
    case SET_GOALS_REACHED_BY_WEEKLY_ACCOUNT_USERS_SUCCESS: {
      const {
        payload: { results },
      } = action;
      const key = getAnalyticsReducerKey(action);
      return {
        ...state,
        [key]: {
          meta: {
            fetching: false,
            synced: true,
            errored: false,
          },
          data: results,
        },
      };
    }
    case SET_GOAL_REACHED_USER_SAMPLE_SUCCESS: {
      const {
        payload: { results },
      } = action;
      const key = getAnalyticsReducerKey(action);
      return {
        ...state,
        [key]: {
          meta: {
            fetching: false,
            synced: true,
            errored: false,
          },
          data: results,
        },
      };
    }
    case SET_GOALS_FIRST_REACHED_THIS_WEEK_SUCCESS: {
      const {
        payload: { results },
      } = action;
      const key = getAnalyticsReducerKey(action);
      return {
        ...state,
        [key]: {
          meta: {
            fetching: false,
            synced: true,
            errored: false,
          },
          data: results,
        },
      };
    }
    case SET_GOALS_REACHED_FROM_FLOW_STARTED_SUCCESS: {
      const {
        payload: { results },
      } = action;
      const key = getAnalyticsReducerKey(action);
      return {
        ...state,
        [key]: {
          meta: {
            fetching: false,
            synced: true,
            errored: false,
          },
          data: results,
        },
      };
    }
    case SET_FLOW_PERFORMANCE_REPORT_DATA_SUCCESS: {
      const {
        payload: { results },
      } = action;
      const key = getAnalyticsReducerKey(action);
      return {
        ...state,
        [key]: {
          meta: {
            fetching: false,
            synced: true,
            errored: false,
          },
          data: results,
        },
      };
    }
    case SET_FLOW_ERROR_REPORT_DATA_SUCCESS: {
      const {
        payload: { results },
      } = action;
      const key = getAnalyticsReducerKey(action);
      return {
        ...state,
        [key]: {
          meta: {
            fetching: false,
            synced: true,
            errored: false,
          },
          data: results,
        },
      };
    }
    default:
      return state;
  }
}

export const emptyAnalyticsState = () => ({
  meta: {
    fetching: false,
    synced: false,
    errored: false,
  },
});

export const selectAccountAnalytics = state => {
  return state.account.analytics || {};
};

export const selectContentAnalytics = (state, contentId) => {
  return selectAccountAnalytics(state)[contentId] || emptyAnalyticsState();
};

function getCompoundGoalAnalyticsId(flowId, goalId) {
  // Create a compound id for the goal analytics for a certain flow, so that it
  // the same place in the redux state can be used for these analytics

  // a `+` separator is used because I'm pretty sure firebase IDs do not include
  // plus signs
  return `${flowId}+${goalId}`;
}

export const selectGoalAnalyticsForFlow = (state, flowId, goalId) => {
  const compoundId = getCompoundGoalAnalyticsId(flowId, goalId);
  return selectContentAnalytics(state, compoundId);
};

export const selectFlowListAnalytics = state => {
  const accountId = selectAccountId(state);
  return selectAccountAnalytics(state)[accountId] || emptyAnalyticsState();
};

export const selectGoalsReachedByWeeklyAccountUsers = state => {
  return selectContentAnalytics(state, 'goals_reached_by_weekly_account_users');
};

export const selectGoalReachedUserSample = (state, goalId) => {
  return selectContentAnalytics(state, `goal_reached_user_sample+${goalId}`);
};

export const selectGoalsFirstReachedThisWeek = state => {
  return selectContentAnalytics(state, 'goals_first_reached_this_week');
};

export const selectGoalsReachedFromFlowStarted = state => {
  return selectContentAnalytics(state, 'goal_reached_from_flow_started');
};

export const selectFlowPerformanceReportData = state => {
  return selectContentAnalytics(state, 'flow_performance_report_data');
};

export const selectFlowErrorReportData = state => {
  return selectContentAnalytics(state, 'flow_error_report_data');
};
