import React from 'react';
import * as yup from 'yup';
import { CForm, CButton, P } from '@appcues/component-library';
import { ExternalLink } from '@studio/legacy-components';
import Modal from 'components/Common/UI/Modal/Modal';

const CUSTOM_FONT_DOCS = 'https://docs.appcues.com/article/53-custom-fonts';

const AddFontModal = ({ onSubmit, onClose }) => (
  <Modal
    title="Add font"
    components={{ Footer: false }}
    type="medium"
    onClose={onClose}
  >
    <CForm
      onSubmit={values => {
        onSubmit(values);
        onClose();
      }}
      validationSchema={yup.object().shape({
        url: yup.string().url(),
      })}
    >
      <Modal.Content>
        <P marginBottom={20}>
          Configure a custom font to use in your theme.
          <br />
          <ExternalLink href={CUSTOM_FONT_DOCS}>Learn more</ExternalLink>
        </P>
        <CForm.Row marginTop={16}>
          <CForm.Field
            label="Font URL"
            name="url"
            placeholder="https://fonts.googleapis.com/css?family=Source..."
          />
        </CForm.Row>
      </Modal.Content>
      <Modal.Footer>
        <CButton onClick={onClose}>Cancel</CButton>
        <CForm.SubmitButton type="positive">Save</CForm.SubmitButton>
      </Modal.Footer>
    </CForm>
  </Modal>
);

export default AddFontModal;
