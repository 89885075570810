import { createCollectionActions } from 'next/lib/collections';

export const TYPE = 'analytics';

export const { patterns, append, reject } = createCollectionActions(TYPE);

export const ANALYTICS_CACHE_CLEARED = '@analytics/ANALYTICS_CACHE_CLEARED';
export const clear = () => ({
  type: ANALYTICS_CACHE_CLEARED,
});

export const READ_ANALYTICS = '@analytics/READ_ANALYTICS';

export const readAnalytics = query => ({
  type: READ_ANALYTICS,
  payload: { query },
});

export const READ_GOALS_REACHED_FROM_EXPERIENCE_STARTED =
  '@analytics/READ_GOALS_REACHED_FROM_EXPERIENCE_STATED';

export const readGoalsReachedFromExperienceStarted = query => ({
  type: READ_GOALS_REACHED_FROM_EXPERIENCE_STARTED,
  payload: { query },
});
