import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Row,
  Flex,
  CPanel,
  CSkeleton,
  Text,
  Caption,
} from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';
import {
  selectAccountId,
  selectAccountMetaSynced,
} from 'reducers/account/meta';
import {
  selectAreFlowsSynced,
  selectFlowsWithTheme,
} from 'reducers/account/flows';
import {
  selectAccountUsers,
  selectAccountUsersSynced,
} from 'reducers/account/users';
import { navigate } from 'actions/routing';
import { getUpdatedByUserFromStep } from 'helpers/view';
import AvatarImg from 'components/Common/AvatarImg';
import MetricFlowListItem from 'components/Common/MetricFlowListItem';

const FlowListItem = styled.div`
  margin-bottom: 16px;
`;

export function FlowsUsingTheme({ accountUsers, loaded, themedFlows = [] }) {
  const getUpdatedText = step => {
    const updatedUser = getUpdatedByUserFromStep(step, accountUsers);
    return updatedUser && updatedUser.updatedDate;
  };

  if (!loaded) {
    return (
      <CPanel>
        <CSkeleton width={250} marginBottom={16} />
        <CSkeleton width={500} marginBottom={16} />
        <CSkeleton width={125} marginBottom={16} />
      </CPanel>
    );
  }

  if (themedFlows.length > 0 && loaded) {
    return themedFlows.map(flow => {
      return (
        <FlowListItem key={flow.id}>
          <MetricFlowListItem flowId={flow.id}>
            <Flex alignItems="center">
              <AvatarImg userId={flow.updatedBy} width={16} marginRight={4} />
              <Caption marginLeft={2} type="tertiary">
                Updated {getUpdatedText(flow)}
              </Caption>
            </Flex>
          </MetricFlowListItem>
        </FlowListItem>
      );
    });
  }

  return (
    <Row>
      <Icon icon="map" />
      <Text marginLeft={16}>None yet</Text>
    </Row>
  );
}

FlowsUsingTheme.propTypes = {
  themeId: PropTypes.string.isRequired,
  loaded: PropTypes.bool,
  themedFlows: PropTypes.array,
  accountUsers: PropTypes.object,
  onSelectFlow: PropTypes.func.isRequired,
};

function mapStateToProps(state, { themeId }) {
  return {
    accountId: selectAccountId(state),
    accountUsers: selectAccountUsers(state),
    themedFlows: selectFlowsWithTheme(state, themeId),
    loaded:
      selectAreFlowsSynced(state) &&
      selectAccountMetaSynced(state) &&
      selectAccountUsersSynced(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        navigate,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FlowsUsingTheme);
