import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import GroupList from './GroupList';
import { GroupedFilterWrapper } from './styled';

import FiltersShape from './FiltersShape';
import OptionsList from './OptionsList';

function GroupedFiltersView({ filters, onChange, onClear }) {
  const groups = Object.entries(filters);
  const [selectedGroup, setSelectedGroup] = useState();

  return (
    <GroupedFilterWrapper>
      {selectedGroup ? (
        <OptionsList
          groups={groups}
          selectedGroup={selectedGroup}
          onGroupChange={setSelectedGroup}
          filters={filters}
          onOptionChange={onChange}
          onClear={onClear}
        />
      ) : (
        <GroupList groups={groups} onGroupChange={setSelectedGroup} />
      )}
    </GroupedFilterWrapper>
  );
}

GroupedFiltersView.propTypes = {
  filters: FiltersShape,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
};

GroupedFiltersView.displayName = 'GroupedFiltersView';

export default styled(GroupedFiltersView)``;
