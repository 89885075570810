import React, { Component } from 'react';
import Recase from 'recase';

const { hasOwnProperty } = Object.prototype;

const sortByindex = (a, b) => (a?.index < b?.index ? -1 : 1);

const prepareInjectedContent = data => {
  const steps = Object.values(data.steps) || {};
  const rawInjectedContent = {
    ...data,
    nextContentId: null,
    redirectUrl: null,
    redirectNewTab: null,
    steps: Object.keys(data.steps || {}).map(stepId => {
      if (stepId.includes('"')) {
        return stepId.replace(/"/g, '');
      }
      return stepId;
    }),
    attributes: {
      pattern_type: 'nps-survey',
      position: 'bottom',
      skippable: true,
      steps: [...steps].sort(sortByindex).map(step => {
        return {
          ...step,
          ...(hasOwnProperty.call(step, 'collapseBeforeNextStep')
            ? { collapseBeforeNextStep: step.collapseBeforeNextStep }
            : { collapseBeforeNextStep: true }),
          [step.id]: {
            ...step,
            index: step.index,
          },
        };
      }),
    },
  };

  // We don't want to snake case the step IDs. Add them as exceptions.
  const recase = Recase.create({
    exceptions: Object.keys(data.steps).reduce((memo, stepId) => {
      return { ...memo, [stepId]: stepId };
    }, {}),
  });
  return recase.snakeCopy(rawInjectedContent);
};

class SatisfactionSurveyLiveTest extends Component {
  constructor(props) {
    super(props);

    this.observeIframeChildren = this.observeIframeChildren.bind(this);
  }

  componentDidMount() {
    const { satisfaction } = this.props;
    const scriptContainer = this.$sdkScriptContainer;
    const headEl = scriptContainer.contentDocument.head;

    const sdkScriptEl = scriptContainer.contentDocument.createElement('script');
    sdkScriptEl.type = 'text/javascript';
    /* global APPCUES_INJECTABLE_SDK_URL */
    sdkScriptEl.src = APPCUES_INJECTABLE_SDK_URL;
    sdkScriptEl.addEventListener('load', () => {
      const injectContent = prepareInjectedContent(satisfaction);
      scriptContainer.contentWindow.Appcues.injectContent(injectContent);
    });
    headEl.append(sdkScriptEl);

    const observer = new MutationObserver(this.observeIframeChildren);
    observer.observe(scriptContainer.contentDocument.body, {
      attributes: true,
      subtree: true,
    });
  }

  observeIframeChildren(mutationsList) {
    mutationsList.forEach(mutation => {
      if (
        mutation.type === 'attributes' &&
        mutation.target.tagName === 'IFRAME'
      ) {
        const appcuesNPSEl =
          mutation.target.contentDocument.querySelector('.appcues-nps');
        const feedbackTabEl =
          mutation.target.contentDocument.querySelector('.feedback-tab');

        // Use the approximate height of the container elements
        // as the fallback value.
        const appcuesNPSElHeight = appcuesNPSEl
          ? appcuesNPSEl.clientHeight
          : 270;
        const feedbackTabElHeight = feedbackTabEl
          ? feedbackTabEl.clientHeight
          : 40;

        const targetHeight = feedbackTabEl.classList.contains('collapsed')
          ? feedbackTabElHeight
          : appcuesNPSElHeight;
        this.$sdkScriptContainer.style.height = `${targetHeight + 5}px`;
      }
    });
  }

  render() {
    return (
      <div>
        <iframe
          className="sdk-script-container"
          ref={el => {
            this.$sdkScriptContainer = el;
          }}
          title="SDK script container"
        />
        <div className="hsbeacon-cover" />
      </div>
    );
  }
}

export default SatisfactionSurveyLiveTest;
