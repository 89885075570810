import { connect } from 'react-redux';
import { FrequencyPanel } from '@studio/settings-panels';
import { FLOW_FREQUENCY, selectFeature } from 'next/entities/features';
import { selectRule, update as updateRule } from 'next/entities/rules';
import { selectAccount, update as updateAccount } from 'next/entities/account';

const mapStateToProps = (state, { id: flowId }) => {
  const { throttleEnabled, throttleContentLimit, throttleContentTimespanMs } =
    selectAccount(state);
  const rule = selectRule(state, flowId);

  return {
    hasFrequency: selectFeature(state, FLOW_FREQUENCY),
    throttleEnabled,
    throttleContentLimit,
    throttleContentTimespanMs,
    throttleExempt: rule.throttleExempt,
  };
};

const mapDispatchToProps = (dispatch, { id }) => ({
  onRuleChange: delta => dispatch(updateRule(id, delta)),
  onUpdateAccount: delta => dispatch(updateAccount(delta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FrequencyPanel);
