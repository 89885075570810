import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  conditionNames,
  propertyOperators,
  getFilteredClauseTree,
} from '@appcues/libcues';
import {
  CCheckbox,
  Notice,
  NOTICE_TYPES,
  Caption,
  H4,
  Box,
} from '@appcues/component-library';
import { Link } from '@studio/legacy-components';
import { filterDomainTargetingClauses } from 'utils/conditions';
import { selectInstalledDomains } from 'reducers/account/installedDomains';

import Radio from 'components/Common/UI/Forms/Radio';
import DomainItem from 'components/Common/DomainItem';
import DomainLegend from 'components/Common/DomainLegend';

const DomainWrapper = styled.div`
  break-inside: avoid;

  &.col2 {
    column-count: 2;
    column-gap: 20px;
  }

  label.domain {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 100px;

    span.domain-name {
      &.draft {
        color: #bbbbbb;
      }
    }

    i {
      font-size: 55%;
      margin-left: 10px;
      &.live {
        color: #06db82;
      }
      &.draft {
        color: #bbbbbb;
      }
    }
  }
`;

const DomainCaption = styled(Caption)`
  padding: 0 23px;
`;

const OptionWrapper = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  label {
    cursor: pointer;
    input[type='radio'],
    input[type='checkbox'] {
      position: inherit;
      margin: 0 10px 0 0;
    }
    &.domain {
      flex-direction: row;
      min-width: 100px;
    }
  }
`;

class DomainTargeting extends Component {
  state = {
    forceShowDomains: '',
    error: false,
  };

  componentDidMount() {
    const { forceShowDomains } = this.state;
    if (forceShowDomains === '') {
      // set initial forceShowDomains based on the first domainClauses and installedDomains length we see in this session

      const domainClauses = this.getDomainClausesFromConditions(this.props);

      this.setState({
        forceShowDomains: domainClauses.length > 0,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const domainClauses = this.getDomainClausesFromConditions(nextProps);

    this.setState({
      forceShowDomains: domainClauses.length > 0,
    });
  }

  handleEverywhereClick = () => {
    const { deleteMatchingClauses, stepId } = this.props;

    deleteMatchingClauses(stepId, filterDomainTargetingClauses);
    this.setState({ forceShowDomains: false, error: false });
  };

  getDomainClausesFromConditions = ({ conditions }) => {
    const domainClauses = getFilteredClauseTree(
      filterDomainTargetingClauses,
      conditions || []
    );

    return domainClauses.filter(
      clause => clause.conditionName === conditionNames.DOMAINS && clause.value
    );
  };

  getAllDomains = () => {
    const { meta } = this.props;
    const metaDomains = meta.domains ?? [];
    const domainsFromClauses = this.getDomainClausesFromConditions(
      this.props
    ).map(clause => clause.value);

    // NOTE: Coercing Set to array via spreading currently not supported due to
    //       our babel configurations using core-js for IE support.
    // eslint-disable-next-line unicorn/prefer-spread
    return Array.from(new Set([...domainsFromClauses, ...metaDomains]));
  };

  getSortedDomainList = () => {
    const { installedDomains } = this.props;

    const allDomains = this.getAllDomains();

    const sortedByInstalled = allDomains
      .map(metaDomain => {
        return {
          metaDomain,
          isInstalled: installedDomains.includes(metaDomain),
        };
      })
      .sort((a, b) => {
        return a.metaDomain.localeCompare(b.metaDomain);
      })
      .sort((a, b) => {
        if (a.isInstalled === b.isInstalled) {
          return a.metaDomain.localeCompare(b.metaDomain);
        }
        if (a.isInstalled) {
          return -1;
        }
        return 1;
      });

    return sortedByInstalled;
  };

  handleDomainsClick = () => {
    const { addClause, stepId } = this.props;

    const domainClauses = this.getDomainClausesFromConditions(this.props);

    const sortedByInstalled = this.getSortedDomainList();
    const parentId =
      domainClauses.length > 0 ? domainClauses[0].parentId : null;

    if (
      domainClauses.length === 0 &&
      sortedByInstalled &&
      sortedByInstalled[0]
    ) {
      addClause(stepId, parentId, {
        conditionName: conditionNames.DOMAINS,
        operator: propertyOperators.EQUALS,
        value: sortedByInstalled[0].metaDomain,
      });
    }

    this.setState({ forceShowDomains: true });
  };

  handleDomainBoxClick = (
    domainObj,
    included,
    onlyOneDomain,
    domainClause,
    parentId
  ) => {
    const { addClause, deleteClause, stepId } = this.props;
    const { metaDomain } = domainObj;

    if (included && !onlyOneDomain) {
      this.setState({ error: false });
      deleteClause(stepId, domainClause.id, domainClause);
    } else if (included && onlyOneDomain) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false });
      addClause(stepId, parentId, {
        conditionName: conditionNames.DOMAINS,
        operator: propertyOperators.EQUALS,
        value: metaDomain,
      });
    }
  };

  render() {
    const { error, forceShowDomains } = this.state;

    const domainClauses = this.getDomainClausesFromConditions(this.props);

    const allDomains = this.getAllDomains();

    const showDomains = forceShowDomains;
    const onlyOneDomain = domainClauses.length === 1;
    return (
      <Box marginY={25}>
        <H4>On which environments?</H4>
        <Radio
          label="Everywhere my Appcues embed script is installed"
          onClick={this.handleEverywhereClick}
          checked={!showDomains}
        />
        <Radio
          label="Only the domains I choose"
          onClick={this.handleDomainsClick}
          checked={showDomains}
        />
        {error && (
          <Notice type={NOTICE_TYPES.warning}>
            Please leave at least one domain selected or select the option
            above.
          </Notice>
        )}
        {(() => {
          if (showDomains) {
            // if the user has already specified a set of domains in their conditions, latch-on
            const parentId =
              domainClauses.length > 0 ? domainClauses[0].parentId : null;
            const listLength = allDomains.length;
            const columnClass = listLength > 5 ? 'content col2' : `content`;

            const sortedByInstalled = this.getSortedDomainList();

            return (
              <>
                <DomainCaption>
                  Don&apos;t see the domain you&apos;re looking for?{' '}
                  <Link to="/settings/account">Update domain list</Link>
                </DomainCaption>
                <DomainWrapper className={columnClass}>
                  {sortedByInstalled.map((domainObj, i) => {
                    const { isInstalled, metaDomain } = domainObj;
                    const domainClause = domainClauses.find(
                      domain => domain.value === metaDomain
                    );
                    const included = !!domainClause;

                    return (
                      metaDomain && (
                        <OptionWrapper>
                          <Option
                            key={`${metaDomain}-${i}`} // eslint-disable-line react/no-array-index-key
                          >
                            <CCheckbox
                              checked={included}
                              onChange={() =>
                                this.handleDomainBoxClick(
                                  domainObj,
                                  included,
                                  onlyOneDomain,
                                  domainClause,
                                  parentId
                                )
                              }
                            />
                            <DomainItem
                              metaDomain={metaDomain}
                              isInstalled={isInstalled}
                            />
                          </Option>
                        </OptionWrapper>
                      )
                    );
                  })}
                </DomainWrapper>
              </>
            );
          }
          return null;
        })()}
        {showDomains && <DomainLegend />}
      </Box>
    );
  }
}

function mapStateToProps(state) {
  return {
    installedDomains: selectInstalledDomains(state),
  };
}

export default connect(mapStateToProps)(DomainTargeting);
