/**
 * Converts an "encoded" property name (e.g. camel or snake case)
 * and returns a friendly-looking version of it.
 *
 * @param prop
 */
export const getPropertyDisplayName = prop => {
  return (
    prop
      // Remove custom prefixes.
      .replace(/^_appcuesForm_/g, 'Form Response: ')
      .replace(/^_appcuesSatisfaction_/g, '')
      // Replace initial _ with no space.
      .replace(/^_/, '')
      // Replace - _ . + with spaces.
      .replace(/[+._-]/g, ' ')
      // Replace "abcD" with "abc D", "abcDEF" with "abc DEF".
      .replace(/([a-z]+)([A-Z]+)/g, (m, g1, g2) => {
        return `${g1} ${g2}`;
      })
      // Replace "Abcd" with " Abcd", discarding a starting space if it's there.
      .replace(/(\s?)([A-Z])([a-z]+)/g, (m, g1, g2, g3) => {
        return ` ${g2}${g3}`;
      })
      // Remove leading and trailing whitespace.
      .trim()
      // Lower case the whole thing to make it sentence case
      .toLowerCase()
      // Take common all-caps acronyms and capitalize them.
      .replace(/\b(id|url|os|ip|eid|fb|crm|uuid|nps)\b/gi, (m, g1) => {
        return `${g1.toUpperCase()}`;
      })
      // Upper case the first character of each space separated word.
      .replace(/(\w)(\w*)/g, (m, g1, g2) => {
        return `${g1.toUpperCase()}${g2}`;
      })
      .trim()
  );
};

/**
 * Determines a user property's grouping based on its name
 *
 * @param propName
 * @returns {*}
 */

export const getOptGroup = propName => {
  if (!propName) {
    return null;
  }
  if (/^(_deviceType|_operatingSystem|_browser)/.test(propName)) {
    return 'device';
  }
  if (propName.startsWith('_appcuesForm_')) {
    return 'form';
  }
  if (propName.startsWith('_appcuesSatisfaction_')) {
    return 'satisfaction';
  }
  if (propName.startsWith('_')) {
    return 'auto';
  }
  return 'normal';
};
