import styled from 'styled-components';

const IndexIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 50%;
  font-weight: bold;
  background-color: ${props => props.theme['$gray-2']};
  margin-right: 1em;
`;

export default IndexIndicator;
