import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  IconButton,
  SearchInput,
  ButtonLink,
} from '@studio/legacy-components';

import {
  CheckboxListItem,
  Options,
  OptionsHeader,
  OptionHeaderLabel,
  SearchWrapper,
  OptionGroup,
  OptionLabelText,
  Separator,
} from './styled';
import FiltersShape from './FiltersShape';
import { getGroupHasSelectedOption } from './utils';

export default function OptionsList({
  onGroupChange,
  selectedGroup,
  filters,
  onClear,
  groups,
  onOptionChange,
}) {
  const [search, setSearch] = useState(
    groups.reduce((acc, [key]) => {
      acc[key] = '';
      return acc;
    }, {})
  );

  const shouldAllowSearch =
    Object.values(filters[selectedGroup.groupKey].options).length >= 6;

  return (
    <Options>
      <OptionsHeader>
        <OptionHeaderLabel>
          <IconButton
            aria-label="return"
            icon="chevron-left"
            onClick={() => onGroupChange()}
          />
          {selectedGroup.name.toUpperCase()}
        </OptionHeaderLabel>
        {getGroupHasSelectedOption(filters[selectedGroup.groupKey].options) && (
          <ButtonLink
            aria-label="clear filters"
            onClick={() => onClear(selectedGroup.groupKey)}
          >
            Clear
          </ButtonLink>
        )}
      </OptionsHeader>
      {shouldAllowSearch && (
        <SearchWrapper>
          <SearchInput
            value={search[selectedGroup.groupKey]}
            onChange={({ target: { value } }) =>
              setSearch(searchMap => {
                const updatedMap = { ...searchMap };
                updatedMap[selectedGroup.groupKey] = value;
                return updatedMap;
              })
            }
            placeholder={`Search by ${selectedGroup.name}`}
          />
        </SearchWrapper>
      )}

      <OptionGroup $scroll={shouldAllowSearch}>
        {Object.entries(filters[selectedGroup.groupKey].options)
          .filter(([, { label }]) =>
            label
              .toLowerCase()
              .includes(search[selectedGroup.groupKey].toLowerCase())
          )
          .map(
            ([
              optionKey,
              { label, enabled, separated, checkboxProps, icon },
            ]) => (
              <Fragment key={optionKey}>
                {separated && <Separator />}
                <CheckboxListItem
                  textValue=""
                  onClick={e => {
                    onOptionChange(selectedGroup.groupKey, optionKey, !enabled);
                    e.preventDefault();
                  }}
                >
                  <Checkbox
                    aria-label={`filter by ${label}`}
                    title={label}
                    checked={enabled}
                    // eslint-disable-next-line @appcues/jsx-props-no-spreading
                    {...checkboxProps}
                  >
                    <OptionLabelText>
                      {icon} {label}
                    </OptionLabelText>
                  </Checkbox>
                </CheckboxListItem>
              </Fragment>
            )
          )}
      </OptionGroup>
    </Options>
  );
}

OptionsList.propTypes = {
  onGroupChange: PropTypes.func,
  selectedGroup: PropTypes.shape({
    groupKey: PropTypes.string,
    name: PropTypes.string,
  }),
  filters: FiltersShape,
  onClear: PropTypes.func,
  groups: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))
  ),
  onOptionChange: PropTypes.func,
};
