import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectSortedFlowStepChildren } from 'reducers/account/flows';
import IndexIndicator from './IndexIndicator';

export const StepChildIndex = ({ stepIndex }) => {
  return <IndexIndicator title="Step Number">{stepIndex + 1}</IndexIndicator>;
};

const mapStateToProps = (state, { flowId, stepChildId }) => {
  const stepChildren = selectSortedFlowStepChildren(state, flowId);

  const stepIndex = stepChildren.findIndex(
    stepChild => stepChild.id === stepChildId
  );

  return {
    stepIndex,
  };
};

const ConnectedStepChildIndex = connect(mapStateToProps)(StepChildIndex);

StepChildIndex.propTypes = {
  stepIndex: PropTypes.number,
};

ConnectedStepChildIndex.propTypes = {
  flowId: PropTypes.string,
  stepChildId: PropTypes.string,
};

export default ConnectedStepChildIndex;
