import qs from 'qs';
import HTTPError from 'errors/HTTPError';

const createBillingClient = ({ getToken }) => {
  /* global BILLING_API_URL */
  const baseUrl = BILLING_API_URL;

  /**
   * accounts api request
   * @params {string} path - endpoint path
   * @params {object} query - query params
   * @params {object} body - request body
   * @returns {Promise<object>} response object
   * @throws {HTTPError}
   */

  const request = async ({ path, query = {}, body, method }) => {
    const url = `${baseUrl}/${path}?${qs.stringify(query)}`;
    const httpMethod = method || (body ? 'POST' : 'GET');
    const jwt = await getToken();
    const response = await fetch(url, {
      method: httpMethod,
      headers: {
        'Content-Type': 'application/json',
        ...(jwt && { Authorization: `Bearer ${jwt}` }),
      },
      ...(body && { body: JSON.stringify(body) }),
    });

    if (!response.ok && !response.success) {
      throw new HTTPError((await response.json()) || response);
    }

    return response.json();
  };

  return {
    fetchPaymentTaxes: async ({ amount, line1, city, country, postalCode }) =>
      request({
        path: 'payments/taxes',
        query: { amount, line1, city, country, postalCode },
      }),

    findOrCreateStripeCustomer: async ({ email, accountId }) =>
      request({
        path: 'customers/stripe',
        body: {
          email,
          accountId: `${accountId}`,
        },
        method: 'PUT',
      }),
    findSubscription: async ({ stripeId, id }) =>
      request({
        path: `v2/subscriptions`,
        query: {
          ...(id && { id }),
          ...(stripeId && { stripeId }),
        },
      }),
    deleteSubscription: async ({ id }) =>
      request({
        path: `v2/subscriptions/${id}`,
        method: 'DELETE',
      }),
    fetchUpcomingInvoice: async ({ stripeId }) =>
      request({
        path: `customers/${stripeId}/invoices/upcoming`,
      }),
    createInvoicePreview: async ({ stripeId, billingAddress, addOns }) =>
      request({
        path: `customers/${stripeId}/invoices/preview`,
        method: 'POST',
        body: {
          billingAddress,
          addOns,
        },
      }),
    fetchCustomerInvoices: async ({ stripeId }) =>
      request({
        path: `customers/${stripeId}/invoices`,
      }),
    fetchCustomerData: async ({ stripeId }) =>
      request({
        path: `customers/${stripeId}`,
      }),
    createSetupIntent: async ({ stripeId, paymentMethodId }) =>
      request({
        path: 'payments/setup_intent',
        body: {
          stripeId,
          paymentMethodId,
        },
      }),
    updateCreditCard: async ({ stripeId, billingAddress, paymentMethodId }) => {
      return request({
        path: `customers/${stripeId}/payment_info`,
        body: {
          billingAddress,
          paymentMethodId,
        },
        method: 'PATCH',
      });
    },
    fetchPaymentInfo: async ({ stripeId }) =>
      request({
        path: `customers/${stripeId}/payment_info`,
      }),
  };
};

export default createBillingClient;
