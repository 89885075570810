import { createSelector } from 'reselect';

import { selectSpoofing } from 'next/entities/spoofing';

import {
  BILLING_INTERVALS,
  DEFAULT_PLAN_LIMIT,
  PLAN_TYPES,
  PLAN_TO_TIER_MAPPINGS,
} from 'constants/plans';
import { isNpsOnlyPlan } from 'helpers/plans';
import { selectAccountEntitlementsProcessed } from 'reducers/entitlements';
import { selectAccountMeta } from 'reducers/account/meta';
import { selectAccountUsers } from 'reducers/account/users';
import { selectUserEmail } from 'reducers/user';
import { calculatePlanPriceFromBasicInfo } from 'utils/billing';
import { getSettingsFromPlanId } from 'utils/stripe';

const getPlanLimit = (accountMeta, limit, currentPlanId) => {
  // In rare cases a customer who is on an enterprise/unlimited plan may have an mau value of 1.
  // We don't want to actually show that for their MAU limit so skip any of those instances.
  if (Number(accountMeta.mau) > 1) {
    return accountMeta.mau;
  }

  if (isNpsOnlyPlan(currentPlanId)) {
    return DEFAULT_PLAN_LIMIT;
  }

  return limit;
};

const getPlanType = (accountMeta, currentPlanId) => {
  if (accountMeta.tier) {
    // Some values for tier inside accountMeta may have an underscore with a bunch of junk after it.
    // We want to exclude the underscore and all the subsequent junk. Hence the split function.
    return accountMeta.tier.split('_')[0];
  }

  return PLAN_TO_TIER_MAPPINGS[currentPlanId] ?? 'enterprise';
};

export const selectCurrentPlanData = createSelector(
  selectAccountMeta,
  selectAccountEntitlementsProcessed,
  (accountMeta, entitlements) => {
    const { stripePlanId } = accountMeta;
    // Ideally, the interval in the store and the one in the stripeId shouldn't missmatch
    const { interval: metaInterval } = accountMeta;
    let { mau: planLimit } = accountMeta;

    if (!stripePlanId) return {};

    const { interval: planInterval, limit } =
      getSettingsFromPlanId(stripePlanId);
    let { type: planType } = getSettingsFromPlanId(stripePlanId);

    if (planType !== BILLING_INTERVALS.custom.name) {
      planLimit = getPlanLimit(accountMeta, limit, stripePlanId);
      planType = getPlanType(accountMeta, stripePlanId);
    }

    const planIdentifier = PLAN_TO_TIER_MAPPINGS[planType] || planType;

    const plan = PLAN_TYPES.find(
      type => type.id === planIdentifier.toLowerCase()
    );

    const features = Object.keys(plan.features).filter(
      feature => plan.features[feature]
    );

    const entitlementsPrice = entitlements.reduce(
      (acc, curr) => acc + (curr.unitPrice ?? 0),
      0
    );

    const { price } = calculatePlanPriceFromBasicInfo({
      planType,
      planInterval: metaInterval || planInterval,
      planLimit,
    });

    const subtotal = price + entitlementsPrice;

    return {
      features,
      planInterval,
      planLimit,
      planType,
      price,
      subtotal,
      ...plan,
    };
  }
);

/*
  The logic to determine which email we should use to load a Stripe customer email is not straightforward.
  We need to consider the following scenarios:
  1. When somebody from Appcues is spoofing an account, we need to make sure that we are using the customer's email,
  not the email from the person who's spoofing. Otherwise, the subscription will be attached to the wrong person.
  2. When an account has multiple admins, if one of them has already set up a subscription, we need to reuse the
  Stripe's customer ID. Otherwise, we'd be setting multiple subscriptions for a single account, which is wrong
  3. Finally, the happy path, which is an admin that is accessing for the first time and wants to set up the
  subscription with their email.
*/
export const selectSubscriptionEmail = createSelector(
  selectUserEmail,
  selectAccountMeta,
  selectAccountUsers,
  selectSpoofing,
  (email, { stripeEmail }, accountUsers, spoofing) => {
    // Stripe email holds the email associated with an active subscription
    if (stripeEmail) return stripeEmail;

    // If spoofing, we can grab any user that is an admin in this account
    if (spoofing) {
      const [firstUser] = Object.values(accountUsers);
      return firstUser?.meta?.email;
    }

    return email;
  }
);
