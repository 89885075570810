import React from 'react';
import PropTypes from 'prop-types';

import {
  AnalyticsMetricGroup,
  AnalyticsMetricCard,
  AnalyticsMetricTooltip,
} from 'next/components/AnalyticsMetricCard';

import { numberWithCommas } from 'utils/textFormatting';
import QueryResults from 'components/Common/QueryResults';
import GoalMetricCard from './GoalMetricCard';

export const Scoreboard = ({ flowId, results }) => {
  const [
    {
      flow_users = 0,
      flows_shown = 0,
      flow_completed_users = 0,
      flows_completed = 0,
      flow_error_users = 0,
      flow_errors = 0,
    },
  ] = results && results.length > 0 ? results : [{}];

  const completionMetric = flow_users
    ? `${Number.parseInt(100 * (flow_completed_users / flow_users), 10)}%`
    : '-';
  const descriptionText = flows_shown
    ? `unique | ${Math.floor(100 * (flows_completed / flows_shown))}% total`
    : null;

  return (
    <AnalyticsMetricGroup>
      <AnalyticsMetricCard
        loading={!results}
        title="Users"
        metric={flow_users}
        description={`unique | ${numberWithCommas(flows_shown)} total`}
      />
      <AnalyticsMetricCard
        loading={!results}
        title="Completion"
        metric={completionMetric}
        description={descriptionText}
      />

      <AnalyticsMetricCard
        loading={!results}
        title="Issues"
        metric={flow_error_users}
        additionalInfo={
          <AnalyticsMetricTooltip>
            When Appcues cannot display a step, due to a missing or incorrect
            selector.
          </AnalyticsMetricTooltip>
        }
        description={`unique | ${numberWithCommas(flow_errors)} total`}
      />

      <GoalMetricCard flowId={flowId} />
    </AnalyticsMetricGroup>
  );
};

Scoreboard.propTypes = {
  flowId: PropTypes.string,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  results: PropTypes.arrayOf(
    PropTypes.shape({
      flow_users: PropTypes.number,
      flows_shown: PropTypes.number,
      flow_completed_users: PropTypes.number,
      flows_completed: PropTypes.number,
      flow_error_users: PropTypes.number,
      flow_errors: PropTypes.number,
    })
  ),
};

const ScoreboardWithAnalytics = ({ flowId, startTime, endTime, segmentId }) => (
  <QueryResults
    query={{
      metrics: [
        'flow_users',
        'flows_shown',
        'flow_completed_users',
        'flows_completed',
        'flow_error_users',
        'flow_errors',
      ],
      conditions: [
        ['flow_id', '==', flowId],
        ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
      ],
      start_time: startTime,
      end_time: endTime,
      meta: {
        tags: {
          feature: 'Flow analytics',
          page: 'Flow analytics',
          component: 'Scoreboard',
          description: 'Metric scoreboard for individual flow',
        },
      },
    }}
  >
    {results => (
      <Scoreboard
        results={results}
        flowId={flowId}
        startTime={startTime}
        endTime={endTime}
      />
    )}
  </QueryResults>
);

ScoreboardWithAnalytics.propTypes = {
  flowId: PropTypes.string,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  segmentId: PropTypes.string,
};

export default ScoreboardWithAnalytics;
