import React from 'react';
import InstallOverview from 'components/InstallationGuide/InstallOverview';
import InstallFramework from 'components/InstallationGuide/InstallFramework';
import InstallScript from 'components/InstallationGuide/InstallScript';
import InstallProperties from 'components/InstallationGuide/InstallProperties';
import InstallEvents from 'components/InstallationGuide/InstallEvents';
import InstallSummary from 'components/InstallationGuide/InstallSummary';

const InstallContent = props => {
  const {
    accountId,
    moveForward,
    moveBackward,
    navigateToInstallDash,
    selected,
    selectedInstallFramework,
    stepMax,
    stepIndex,
  } = props;

  switch (selected) {
    case 'overview':
      return (
        <InstallOverview
          accountId={accountId}
          moveBackward={moveBackward}
          moveForward={moveForward}
          stepIndex={stepIndex}
          stepMax={stepMax}
        />
      );
    case 'framework':
      return (
        <InstallFramework
          moveBackward={moveBackward}
          moveForward={moveForward}
          selectedInstallFramework={selectedInstallFramework}
          stepIndex={stepIndex}
          stepMax={stepMax}
        />
      );
    case 'script':
      return (
        <InstallScript
          accountId={accountId}
          moveBackward={moveBackward}
          moveForward={moveForward}
          stepIndex={stepIndex}
          stepMax={stepMax}
          selectedInstallFramework={selectedInstallFramework}
        />
      );
    case 'properties':
      return (
        <InstallProperties
          moveBackward={moveBackward}
          moveForward={moveForward}
          selectedInstallFramework={selectedInstallFramework}
          stepIndex={stepIndex}
          stepMax={stepMax}
        />
      );
    case 'events':
      return (
        <InstallEvents
          moveBackward={moveBackward}
          moveForward={moveForward}
          selectedInstallFramework={selectedInstallFramework}
          stepIndex={stepIndex}
          stepMax={stepMax}
        />
      );
    case 'summary':
      return (
        <InstallSummary
          moveBackward={moveBackward}
          navigateToInstallDash={navigateToInstallDash}
          stepIndex={stepIndex}
          stepMax={stepMax}
        />
      );
    default:
      return (
        <InstallOverview
          moveBackward={moveBackward}
          moveForward={moveForward}
          stepIndex={stepIndex}
          stepMax={stepMax}
        />
      );
  }
};

export default InstallContent;
