import React from 'react';

import {
  Caption,
  ExternalLink,
  H2,
  Text,
  Progress,
} from '@studio/legacy-components';
import { SUPPORT_EMAIL } from 'constants/externalAppcuesResources';
import {
  AccountUsage,
  HorizontalWrapper,
  WarningWrapper,
} from 'components/Subscription/styled';

const ISSUE_BY_STATE = {
  warning: {
    description:
      'If you exceed the limit, we will reach out to you to upgrade your plan.',
    headline: 'Approaching limit',
  },
  over: {
    description: `You will need to upgrade your account for the next billing cycle.  We'll reach out to you with the next steps. `,
    headline: 'You have exceeded the limit',
  },
};

const EntitlementProgress = ({
  caption,
  completePercent,
  currentStatus = 'over',
  showWarning,
  title,
}) => (
  <AccountUsage kind={currentStatus}>
    <HorizontalWrapper>
      <H2 type="secondary">{title}</H2>{' '}
      <Caption type="tertiary">{caption}</Caption>
    </HorizontalWrapper>

    <Progress value={completePercent} />

    {showWarning && (
      <WarningWrapper>
        <Text>
          <Caption bold>
            🎉 {ISSUE_BY_STATE[currentStatus].headline} - your business must be
            growing! &nbsp;
          </Caption>
          {ISSUE_BY_STATE[currentStatus].description} Questions? Email us:
          <ExternalLink href={`mailto:${SUPPORT_EMAIL}`}>
            {` ${SUPPORT_EMAIL}`}
          </ExternalLink>
        </Text>
      </WarningWrapper>
    )}
  </AccountUsage>
);

export default EntitlementProgress;
