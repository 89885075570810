import { getLeafNodes } from '@studio/conditions';

const NON_AUDIENCE_TYPES = ['trigger', 'url', 'domains'];

/**
 * Split a conditions tree into branches that correspond to the
 * flow targeting sections
 *
 * @param {Conditions} conditions
 * @returns {{trigger: {Conditions}, audience: {Conditions}}}
 */

export const getFlowTargetingSections = ({ and: branches = [] } = {}) => ({
  trigger: branches.find(branch =>
    getLeafNodes(branch).some(({ trigger }) => Boolean(trigger))
  ),
  url: branches.find(branch =>
    getLeafNodes(branch).some(({ url }) => Boolean(url))
  ),
  domains: branches.find(branch =>
    getLeafNodes(branch).some(({ domains }) => Boolean(domains))
  ),
  audience: branches.find(branch =>
    getLeafNodes(branch).every(
      clause =>
        NON_AUDIENCE_TYPES.every(type => !clause[type]) &&
        clause.properties?.property !== '_ABGroup'
    )
  ),
  ab: branches.find(branch =>
    getLeafNodes(branch).every(
      clause => clause.properties?.property === '_ABGroup'
    )
  ),
});
