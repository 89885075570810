import {
  takeEvery,
  put,
  select,
  call,
  getContext,
  take,
  all,
} from 'redux-saga/effects';
import {
  filterAudienceTargetingClauses,
  getFilteredClauseTree,
  getNestedConditionsFromFlattenedClauses,
  clauseKeys,
} from '@appcues/libcues';
import toast from 'next/lib/toast';
import { setAudienceSegmentAndFlushRule } from 'actions/account/conditions';
import { selectAccountStepConditions } from 'reducers/account/conditions';
import { selectAccountSegment } from 'reducers/account/segments';
import {
  SEGMENT_CREATED_FROM_RULE,
  SEGMENT_CLONED,
  SEGMENT_DELETED,
  SEGMENT_EXPORTED,
  SEGMENT_USER_IDS_UPLOADED,
  SEGMENT_PRE_SIGN_CSV,
  send,
  remove,
  replace,
  resolve,
  reject,
  insert,
  create,
  patterns,
} from 'actions/account/segments';
import { reportError } from 'helpers/error-reporting';
import { selectFlow } from 'reducers/account/flows';
import { promisaga } from 'utils/as-promised';

export function* fetchSegments() {
  try {
    const api = yield getContext('api');
    yield put(send());
    const { data: allSegments } = yield call(api.getAllSegments);
    yield put(
      resolve(
        allSegments.reduce((acc, segment) => {
          const { id } = segment;
          acc[id] = segment;
          return acc;
        }, {})
      )
    );
  } catch (error) {
    yield put(reject(error));
    yield call(reportError, error);
  }
}

export function* createSegment(action) {
  try {
    const {
      payload: {
        name = 'New Segment',
        description = '',
        conditions,
        clonedFrom,
      },
    } = action;

    const api = yield getContext('api');
    const { data: segment } = yield call(api.createSegment, {
      name,
      description,
      conditions,
      ...(clonedFrom ? { clonedFrom } : {}),
    });

    yield put(insert(segment));
    yield call(toast.success, 'Segment created successfully');
  } catch (error) {
    yield call(toast.error, 'Failed to create segment');
    yield call(reportError, error);
  }
}

export function* updateSegment(action) {
  try {
    const { id, delta } = action.payload;

    const api = yield getContext('api');
    const { data: updatedSegment } = yield call(api.updateSegment, id, delta);

    yield put(replace(updatedSegment));
    yield call(toast.success, 'Segment updated successfully');
  } catch (error) {
    yield call(toast.error, 'Failed to save changes to segment');
    yield call(reportError, error);
  }
}

export function* deleteSegment(action) {
  try {
    const { id } = action.payload;

    const api = yield getContext('api');
    yield call(api.deleteSegment, id);
    yield put(remove(id));
    yield call(toast.success, 'Segment deleted successfully');
  } catch (error) {
    yield call(reportError, error);
    yield call(toast.error, 'Failed to delete segment');
  }
}
export function* exportSegment(action) {
  try {
    const { id, data } = action.payload;

    const api = yield getContext('endUserApi');
    yield call(api.exportSegment, id, data);
    yield call(toast.success, 'Segment exported successfully');
  } catch (error) {
    yield call(reportError, error);
    yield call(toast.error, 'Failed to export segment');
  }
}

export function* cloneSegment(action) {
  try {
    const { id } = action.payload;

    const { name, description, conditions } = yield select(
      selectAccountSegment,
      id
    );

    const newSegmentName = `Clone of ${name}`;

    yield call(
      createSegment,
      create({
        name: newSegmentName,
        description,
        conditions,
        clonedFrom: id,
      })
    );
  } catch (error) {
    yield call(reportError, error);
  }
}

export function* createSegmentFromRule(action) {
  try {
    const { name, ruleId } = action.payload;

    // we'll base the new segment on the audience branch of
    // the provided step's rule conditions

    const stepClauses = yield select(selectAccountStepConditions, ruleId);
    const audienceClauses = yield call(
      getFilteredClauseTree,
      filterAudienceTargetingClauses,
      stepClauses
    );

    const conditions = yield call(
      getNestedConditionsFromFlattenedClauses,
      audienceClauses,
      null,
      clauseKeys
    );

    const { name: flowName } = yield select(selectFlow, ruleId);

    const [
      {
        payload: { id },
      },
    ] = yield all([
      take(patterns.insert),
      call(
        createSegment,
        create({
          name,
          description: `Created from targeting on ${
            flowName || `Flow ${ruleId}`
          }`,
          conditions,
        })
      ),
    ]);

    yield put(setAudienceSegmentAndFlushRule(ruleId, id));
  } catch (error) {
    yield call(toast.error, 'Failed to create segment from rule');
    yield call(reportError, error);
  }
}

export function* uploadSegmentUserIds(action) {
  try {
    const { data } = action.payload;
    const api = yield getContext('api');
    const {
      data: { segment },
    } = yield call(api.uploadSegmentUserIds, data);

    /* Update segment properties to be camelCase */
    segment.createdBy = segment.created_by;
    segment.updatedAt = new Date(segment.updated_at).getTime();

    yield put(insert(segment));
    return segment;
  } catch (error) {
    yield call(reportError, error);
    throw error;
  }
}

export function* preSignSegmentCsv(action) {
  try {
    const { segmentId } = action.payload;
    const api = yield getContext('api');
    const { data } = yield call(api.preSignSegmentCsv, segmentId);

    return { url: data.url };
  } catch (error) {
    yield call(reportError, error);
    throw error;
  }
}

export function* segments() {
  yield takeEvery(patterns.create, createSegment);
  yield takeEvery(patterns.update, updateSegment);
  yield takeEvery(SEGMENT_DELETED, promisaga(deleteSegment));
  yield takeEvery(SEGMENT_CLONED, cloneSegment);
  yield takeEvery(SEGMENT_EXPORTED, exportSegment);
  yield takeEvery(SEGMENT_CREATED_FROM_RULE, createSegmentFromRule);
  yield takeEvery(SEGMENT_USER_IDS_UPLOADED, promisaga(uploadSegmentUserIds));
  yield takeEvery(SEGMENT_PRE_SIGN_CSV, promisaga(preSignSegmentCsv));
}

export default segments;
