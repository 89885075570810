import React from 'react';
import { connect } from 'react-redux';
import NextStoreProvider from './NextStoreProvider';

/**
 * Drop-in replacement for Redux `connect` to force providing of the next store
 * on connected components. Useful for components that only ever need to read
 * from the next store but may be used in older codepaths.
 */
export const provide =
  (...args) =>
  Component => {
    const Connected = connect(...args)(Component);

    const Provided = unhandledProps => (
      <NextStoreProvider>
        <Connected {...unhandledProps} />
      </NextStoreProvider>
    );

    return Provided;
  };
