import analyticsV2 from 'sagas/analytics-v2';
import charts from 'sagas/account/charts';
import conditions from 'sagas/account/conditions';
import customEvents from 'sagas/account/customEvents';
import flows from 'sagas/account/flows';
import flowStatus from 'sagas/account/flowStatus';
import flowVersions from 'sagas/account/flowVersions';
import goals from 'sagas/account/goals';
import images from 'sagas/account/images';
import integrations from 'sagas/account/integrations';
import locales from 'sagas/account/locales';
import meta from 'sagas/account/meta';
import publishedRules from 'sagas/account/published-rules';
import publishing from 'sagas/account/publishing';
import rules from 'sagas/account/rules';
import satisfaction from 'sagas/account/satisfaction';
import segmentMemberships from 'sagas/account/segmentMemberships';
import themes from 'sagas/account/themes';
import translations from 'sagas/account/translations';
import users from 'sagas/account/users';
import versionedFlows from 'sagas/account/versionedFlows';

export default [
  analyticsV2,
  charts,
  conditions,
  customEvents,
  flows,
  flowStatus,
  flowVersions,
  goals,
  images,
  integrations,
  locales,
  meta,
  publishedRules,
  publishing,
  rules,
  satisfaction,
  segmentMemberships,
  themes,
  translations,
  users,
  versionedFlows,
];
