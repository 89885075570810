import {
  createRequestSelector,
  selectIsSynced,
} from 'reducers/account/requests';
import { createCollectionReducer } from 'reducers/account/collections';

export const TYPE = 'rules';

export default createCollectionReducer(TYPE);

export const selectAccountRules = state => state.account.rules;
export const selectAccountRule = (state, stepId) =>
  selectAccountRules(state)[stepId] || {};

export const selectAccountRulesSynced = state => selectIsSynced(state, TYPE);

export const selectShouldFetchRules = createRequestSelector(TYPE);

export const selectGoalForRule = (state, stepId) => {
  const { goals } = selectAccountRule(state, stepId);
  if (goals) {
    const [goalId] = goals;
    return goalId;
  }
  // eslint-disable-next-line unicorn/no-useless-undefined
  return undefined;
};

export const selectRulesByGoal = (state, { goalId }) =>
  Object.values(selectAccountRules(state) || {}).filter(({ goals = [] }) =>
    (goals || []).includes(goalId)
  );

export const selectRulesWithGoal = state => {
  const rules = selectAccountRules(state);
  return Object.values(rules).filter(rule => rule?.goals?.length > 0);
};

export const selectAreRulesSynced = state => selectIsSynced(state, TYPE);
