import {
  call,
  getContext,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { setAccount } from 'next/lib/crx';
import { INITIALIZE } from 'next/root/actions';
import toast from 'next/lib/toast';

import { flush, patterns, reject } from './actions';

function* initialize({ payload: auth }) {
  try {
    const { accountId, jwt } = auth;
    yield call(setAccount, { accountId, jwt });
  } catch {
    // TODO: Handle error
  }
}

function* updateAccount({ payload }) {
  try {
    const api = yield getContext('api');
    yield call(api.updateAccount, payload);

    yield put(flush());
  } catch (error) {
    yield put(reject(error));
  }
}

function* showToast() {
  yield call(toast.success, 'Changes saved');
}

export default function* saga() {
  yield takeLatest(INITIALIZE, initialize);

  // Internal actions
  yield takeEvery(patterns.update, updateAccount);
  yield takeEvery(patterns.flush, showToast);
}
