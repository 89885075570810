import { call, put, getContext, takeLeading } from 'redux-saga/effects';
import { send, resolve, reject, patterns } from 'actions/account/customEvents';
import { reportError } from 'helpers/error-reporting';

export function* fetchAllCustomEvents() {
  try {
    yield put(send());
    const api = yield getContext('api');
    const { data: customEvents = [] } = yield call(api.getAllCustomEvents);
    yield put(
      resolve(
        customEvents.reduce((acc, customEvent) => {
          const { id } = customEvent;
          acc[id] = customEvent;
          return acc;
        }, {})
      )
    );
  } catch (error) {
    yield call(reportError, error);
    yield put(reject(error));
  }
}

export default function* saga() {
  yield takeLeading(patterns.callApi, fetchAllCustomEvents);
}
