import styled from 'styled-components';
import { Icon, Label, Link } from '@studio/legacy-components';

/**
 * TemplateOption components
 */
export const OptionWrapper = styled.div`
  display: flex;
`;

export const OptionImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 72px;
  padding: 4px;
  background: var(--white-walker);
  border-radius: 4px;
`;

export const Image = styled.img`
  width: ${({ size }) => `${size.width}px`};
  height: ${({ size }) => `${size.height}px`};
`;

export const OptionLabelWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 12px;
`;

export const OptionLabel = styled.div`
  font-weight: var(--bold);
  margin-bottom: 4px;
`;

export const SubLabel = styled.div`
  font-size: var(--regular);
`;

/**
 * ThemeField components
 */

export const ThemeLabelWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  ${Label} {
    padding: 0;
    flex-grow: 1;
  }

  ${Link} {
    display: flex;
    align-items: center;
  }

  ${Icon} {
    margin-left: 8px;
    width: 12px;
  }
`;
