import React from 'react';
import styled from 'styled-components';
import { ButtonLink } from '@studio/legacy-components';

const LearnMore = styled(ButtonLink)`
  text-align: left;
`;

const LiveContentCard = props => {
  const {
    description,
    emptyDescription,
    header,
    linkSlug,
    navigate,
    stat,
    type,
  } = props;

  const handleLearnMoreClick = () => {
    navigate(`/${linkSlug}`);
  };

  return (
    <ContentCardWrapper>
      {stat === 0 ? (
        <>
          <StatHeader>No {type} yet</StatHeader>
          <EmptyDescription>{emptyDescription}</EmptyDescription>
          <LearnMore onClick={handleLearnMoreClick}>Learn more</LearnMore>
        </>
      ) : (
        <>
          <StatHeader>{header}</StatHeader>
          <StatNumber>{stat}</StatNumber>
          <span>{description}</span>
        </>
      )}
    </ContentCardWrapper>
  );
};

const ContentCardWrapper = styled.div`
  flex: 0 0 31%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  padding: 1rem;
  border: 1px solid ${props => props.theme['$gray-3']};
  color: ${props => props.theme['$gray-30']};
`;

const StatHeader = styled.h3`
  text-transform: uppercase;
  margin: 0 !important;
`;

const EmptyDescription = styled.span`
  color: black;
`;

const StatNumber = styled.span`
  font-size: 3rem;
  font-weight: 200;
  line-height: 2.6rem;
  margin: 1rem 1rem 1rem 0;
  color: black;
`;

export default LiveContentCard;
