import React, { useMemo } from 'react';
import { getRecommendedFrequency } from '@studio/legacy-components';
import Loader from 'components/Common/Loader';
import QueryResults from './index';

/**
 * Options to define a hightcharts' series
 * https://api.highcharts.com/highcharts/series
 */
const DEFAULT_SERIES_OPTS = {
  color: '#9D9DFF',
  fillOpacity: 0.4,
  marker: {
    symbol: 'circle',
    fillColor: '#9D9DFF',
  },
  type: 'line',
  pointPadding: 0,
  borderWidth: 0,
};

/**
 * @typedef {function} mapPropsToQuery
 * @param {Object} props - The wrapped component props
 * @return query to be dispatched to the analytics api
 */

/**
 * @typedef {function} mapResultsToSeries
 * @param {Array} results - The results fetched for the given query
 * @param {Object} defaultOpts - Our default options for hightchart's series
 * @param {Object} ownProps - The wapper component props
 * @return series to be used in a hightcharts' Chart
 */

/**
 * @param {mapPropsToQuery} mapPropsToQuery - a function that creates the query to be dispatched
 * @param {mapResultsToSeries} mapResultsToSeries - a function that resolves the fetched results into a highcharts series
 */
const withQuery = (mapPropsToQuery, mapResultsToSeries) => {
  return WrappedComponent => props => {
    const query = mapPropsToQuery(props);

    const { start_time, end_time } = query;

    const frequency = useMemo(() => {
      return getRecommendedFrequency({
        start: new Date(start_time),
        end: new Date(end_time),
      });
    }, [start_time, end_time]);

    return (
      <QueryResults query={query}>
        {results => {
          if (!results) {
            return <Loader />;
          }

          const series = mapResultsToSeries(results, DEFAULT_SERIES_OPTS, {
            frequency,
            ...props,
          });

          return (
            <WrappedComponent
              {...props}
              frequency={frequency}
              series={series}
            />
          );
        }}
      </QueryResults>
    );
  };
};

export default withQuery;
