import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { H2 } from '@studio/legacy-components';
import { selectAccountChecklist } from 'entities/checklists';
import FieldGroup from 'components/Common/Forms/FieldGroup';
import Input from 'components/Common/Forms/Input';
import Textarea from 'components/Common/Forms/Textarea';

export function ListContent({
  descriptionText,
  headerText,
  updateChecklistTextToken,
}) {
  const updateChecklistDescriptionText = input => {
    updateChecklistTextToken('descriptionText', input);
  };

  const updateChecklistHeaderText = input => {
    updateChecklistTextToken('headerText', input);
  };

  return (
    <>
      <H2>List Content</H2>
      <FieldGroup title="Main Heading" className="configuration-item">
        <Input
          type="text"
          className="checklist-text-short"
          value={headerText}
          placeholder="Welcome!"
          onChange={updateChecklistHeaderText}
        />
      </FieldGroup>
      <FieldGroup title="Description Text" className="configuration-item">
        <Textarea
          type="text"
          className="checklist-textarea-long"
          value={descriptionText}
          onChange={updateChecklistDescriptionText}
        />
      </FieldGroup>
    </>
  );
}

ListContent.propTypes = {
  descriptionText: PropTypes.string,
  headerText: PropTypes.string,
  updateChecklistTextToken: PropTypes.func,
};

const mapStateToProps = (state, { checklistId }) => {
  const checklist = selectAccountChecklist(state, checklistId);
  const headerText = checklist.textTokens && checklist.textTokens.headerText;
  const descriptionText =
    checklist.textTokens && checklist.textTokens.descriptionText;

  return {
    descriptionText,
    headerText,
  };
};

export default connect(mapStateToProps)(ListContent);
