import React from 'react';

import IntegrationCard from './IntegrationCard';

export const PremiumIntegrationCard = ({
  integration,
  isEnabled,
  isLoadingPrismatic,
}) => {
  return (
    <IntegrationCard
      key={integration.id}
      integration={integration}
      isEnabled={isEnabled}
      isLoadingPrismatic={isLoadingPrismatic}
    />
  );
};
