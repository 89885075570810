import React from 'react';
import { CBanner } from '@appcues/component-library';

const ErrorBanner = () => (
  <CBanner
    type="negative"
    text="We're sorry, something went wrong on our end. Try again."
    marginBottom={20}
  />
);

export default ErrorBanner;
