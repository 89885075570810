import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';
import { CColorPickerDropdown } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';
import { DEFAULT_COMPONENT_WIDTH } from 'constants/account/themes';

const ThemeColorPicker = ({
  label,
  draftTheme,
  setDraftTheme,
  width,
  onBeforeSetDraft,
}) => {
  const handleChange = useCallback(
    value => {
      const { name } = label;
      const hexColor = value.hex;
      const styles = onBeforeSetDraft
        ? onBeforeSetDraft({ borderColor: hexColor, label, draftTheme })
        : {};
      setDraftTheme(prevDraftTheme => {
        return {
          ...prevDraftTheme,
          [name]: hexColor,
          ...styles,
        };
      });
    },
    [setDraftTheme]
  );

  return (
    <CColorPickerDropdown
      title="Color Picker Dropdown"
      colorState={draftTheme[label.name]}
      onChange={handleChange}
      width={width ?? DEFAULT_COMPONENT_WIDTH}
    >
      {label.type === 'textcolorpicker' && (
        <StyledFontIcon icon="font" currentColor={draftTheme[label.name]} />
      )}
    </CColorPickerDropdown>
  );
};

const StyledFontIcon = styled(Icon)`
  stroke: gray;
  stroke-width: ${props =>
    tinycolor(props.currentColor).isLight() ? '15px' : 0};
`;

ThemeColorPicker.propTypes = {
  label: PropTypes.object.isRequired,
  draftTheme: PropTypes.object.isRequired,
  setDraftTheme: PropTypes.func.isRequired,
  width: PropTypes.number,
  onBeforeSetDraft: PropTypes.func,
};

export default ThemeColorPicker;
