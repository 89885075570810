import React, { useMemo, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Heading, Select } from '@studio/legacy-components';
import { Shape as TagShape, selectTags, create } from 'next/entities/tags';
import useOptions from 'next/hooks/use-options';
import useToggle from 'next/hooks/use-toggle';
import { Content, Footer, Tags, EditTagsModalWrapper } from './styled';
import ExperienceTag from './ExperienceTag';
import Create from './Create';

const sortTags = tags =>
  tags.sort(({ name: a }, { name: b }) => {
    return a.localeCompare(b, 'en', {
      sensitivity: 'base',
      numeric: true,
    });
  });

export function EditTagsModal({
  onClose,
  visible,
  label,
  tags,
  accountTags,
  onChange,
  onCreate,
}) {
  const [experienceTags, setExperienceTags] = useState(tags);
  const [editing, toggleEditing] = useToggle(false);
  const allOptions = useOptions(accountTags);
  const options = useMemo(
    () =>
      allOptions.filter(
        ({ value }) => !experienceTags.some(({ id }) => id === value)
      ),
    [allOptions, experienceTags]
  );
  useDeepCompareEffect(() => {
    setExperienceTags(tags);
  }, [tags]);

  const handleClose = () => {
    setExperienceTags(tags);
    onClose();
  };

  const handleTagSelection = ({ value, label: optionLabel }) => {
    setExperienceTags(currentTags => [
      ...currentTags,
      { id: value, name: optionLabel },
    ]);
  };

  const handleTagRemove = tagId => {
    setExperienceTags(currentTags =>
      currentTags.filter(({ id }) => id !== tagId)
    );
  };

  const handleTagCreate = async ({
    target: {
      elements: {
        tagname: { value },
      },
    },
  }) => {
    toggleEditing();
    const trimmedTag = value.trim();
    // if the tag is already in the experience tag's then don't do anything
    if (experienceTags.some(({ name }) => name === trimmedTag)) return;

    const tagInAccount = options.find(
      ({ label: optionLabel }) => optionLabel === trimmedTag
    );

    if (tagInAccount) {
      setExperienceTags(currentTags => [
        ...currentTags,
        { id: tagInAccount.value, name: tagInAccount.label },
      ]);
      return;
    }

    const result = await onCreate({ name: value });

    handleTagSelection({ value: result.id, label: result.name });
  };

  const handleSave = () => {
    onChange(experienceTags.map(({ id }) => id));
    handleClose();
  };

  const sortedTags = sortTags(experienceTags);

  return (
    <EditTagsModalWrapper onClose={handleClose} size="m" visible={visible}>
      <Heading>Tags for {label}</Heading>
      <Select value={null} options={options} onChange={handleTagSelection} />
      <Content>
        <Tags>
          <Create
            editing={editing}
            onCreate={handleTagCreate}
            onToggle={toggleEditing}
          />
          {sortedTags?.map(({ id, name }) => (
            <ExperienceTag
              key={id}
              id={id}
              name={name}
              onRemove={() => handleTagRemove(id)}
            />
          ))}
        </Tags>
      </Content>
      <Footer>
        <Button kind="primary" onClick={handleSave}>
          Save
        </Button>
      </Footer>
    </EditTagsModalWrapper>
  );
}

EditTagsModal.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  label: PropTypes.string,
  tags: PropTypes.arrayOf(TagShape),
  accountTags: PropTypes.objectOf(TagShape),
  onChange: PropTypes.func,
  onCreate: PropTypes.func,
};

const mapDispatchToProps = {
  onCreate: create,
};

const mapStateToProps = state => ({
  accountTags: selectTags(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTagsModal);
