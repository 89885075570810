import { object, string } from 'yup';

export const EditModeFormSchema = object().shape({
  city: string().required('City is required'),
  country: object().required('Please select a country from the list'),
  line1: string().required('Billing address is required'),
  name: string().required('Name on card is required'),
  postalCode: string()
    .required('Postal code is required')
    .test('Empty', 'Postal code is required', val => !!val)
    .when('country', {
      is: val => val?.value === 'US',
      // eslint-disable-next-line unicorn/no-thenable
      then: string().test(
        'is-numeric',
        'Please enter a valid postal code',
        val => /^\d{5}(-\d{4})?$/.test(val)
      ),
      otherwise: string()
        .test('is-alphanumeric', 'Please enter a valid postal code', val =>
          /^[\d\sA-Za-z]+$/.test(val)
        )
        .max('11', 'Postal code must be less than 12 characters'),
    }),
});

export const ViewModeFormSchema = object().shape({
  city: string(),
  country: object(),
  line1: string(),
  postalCode: string(),
  name: string(),
});
