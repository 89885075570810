import { PUBLISH_ACTIONS } from 'constants/modalStatuses';
import { STEP_TYPE_IDS } from 'constants/stepTypes';

export const getConfirmActionMessageForType = (action, contentType) => {
  const contentTypeNoun = {
    [STEP_TYPE_IDS.ANNOUNCEMENT]: 'announcement',
    [STEP_TYPE_IDS.CHECKLIST]: 'checklist',
    [STEP_TYPE_IDS.SATISFACTION]: 'satisfaction survey',
  };
  switch (action) {
    case PUBLISH_ACTIONS.REVERT:
      return 'Are you sure you want to discard unpublished changes? These changes will be lost.';
    case PUBLISH_ACTIONS.PUBLISH:
      return `Are you sure you want to publish this ${
        contentTypeNoun[contentType] || 'flow'
      }?`;
    case PUBLISH_ACTIONS.PUSH:
      return 'Are you sure you want to publish these changes?';
    case PUBLISH_ACTIONS.UNPUBLISH:
      return `Are you sure you want to unpublish this ${
        contentTypeNoun[contentType] || 'flow'
      }?`;
    default:
      return 'Are you sure you want to make these changes?';
  }
};

export const progressiveVerbs = {
  [PUBLISH_ACTIONS.REVERT]: 'Discarding changes for',
  [PUBLISH_ACTIONS.PUBLISH]: 'Publishing',
  [PUBLISH_ACTIONS.PUSH]: 'Pushing changes for',
  [PUBLISH_ACTIONS.UNPUBLISH]: 'Unpublishing',
};

export const exportModalMessages = {
  basic: 'Basic flow events and default user properties. No custom properties.',
  full: 'All flow events and user data, including custom properties.',
  survey: 'Includes only user survey responses.',
  responses:
    'One row per user, with their user ID, email (if provided), and form responses.',
  buttonText: 'Send CSV',
  timeNotificationHeader: 'Just a heads up: ',
  timeNotificationBody:
    'We recently made changes to how our CSV exports work. It might take up to 10-15 minutes before it arrives in your inbox.',
};

export const exportChecklistModalMessages = {
  basic:
    'All checklist events and default user properties. No custom properties.',
  full: 'All checklist events and user data, including custom properties.',
  responses:
    'One row per user, with their user ID, email (if provided), and form responses.',
  buttonText: 'Send CSV',
  timeNotificationHeader: 'Just a heads up: ',
  timeNotificationBody:
    'We recently made changes to how our CSV exports work. It might take up to 10-15 minutes before it arrives in your inbox.',
};
