import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StepContainer = ({ className, children }) => (
  <div className={className}>{children}</div>
);

// A single child is required.
StepContainer.propTypes = {
  children: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default styled(StepContainer)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  position: relative;

  align-items: center;
  justify-content: center;

  > * {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
  }
`;
