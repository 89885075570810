import React, { Component } from 'react';

import styled from 'styled-components';
import Panel from 'components/Common/Panel';
import StyleSummary from 'components/SatisfactionSurveys/SatisfactionSurveysEdit/Summary/StyleSummary';
import ContentSummary from 'components/SatisfactionSurveys/SatisfactionSurveysEdit/Summary/ContentSummary';
import AudienceSummary from 'components/SatisfactionSurveys/SatisfactionSurveysEdit/Summary/AudienceSummary';

import { SATISFACTION_CREATION_STEP_TITLES } from 'constants/satisfaction/data';
import { VIEWED_APP_PAGE_STEP } from 'constants/events';

class SatisfactionSummaryStep extends Component {
  componentDidMount() {
    const { trackEvent } = this.props;
    trackEvent(VIEWED_APP_PAGE_STEP, {
      step: SATISFACTION_CREATION_STEP_TITLES.SUMMARY,
    });

    window.scrollTo(0, 0);
  }

  render() {
    const {
      className,
      meta,
      steps,
      segments,
      backgroundColor,
      foregroundColor,
      questionOne,
      questionTwo,
      clauses,
      updateSatisfaction,
      rule,
      checklists,
    } = this.props;

    return (
      <Panel
        name="Preview & Publish Your NPS Survey"
        description="Review your settings and click Publish when you're ready to go live."
        className={`${className} satisfaction-form page-container`}
      >
        <div className="satisfaction-preview-rollup">
          <StyleSummary
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}
            updateSatisfaction={updateSatisfaction}
          />
          <ContentSummary
            questionOne={questionOne}
            questionTwo={questionTwo}
            updateSatisfaction={updateSatisfaction}
          />
          <AudienceSummary
            steps={steps}
            segments={segments}
            meta={meta}
            rule={rule}
            clauses={clauses}
            updateSatisfaction={updateSatisfaction}
            checklists={checklists}
          />
        </div>
      </Panel>
    );
  }
}

export default styled(SatisfactionSummaryStep)`
  & {
    justify-content: center;
    flex-direction: column;

    .satisfaction-preview-rollup {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: -1.5rem;
    }

    .satisfaction-form-card {
      display: inline-block;
      justify-content: center;
      margin: 16px 1.5rem 16px 0;
      cursor: default;

      flex: 1;
      width: auto;

      .satisfaction-card-top {
        p:first-child {
          margin-top: 10px;
        }

        hr {
          margin: 1rem -1.25rem;
          border-bottom: 2px solid ${props => props.theme['$gray-3']};
        }

        .button {
          position: absolute;
          top: 15px;
          right: 15px;
          background-color: transparent;
        }
      }

      .metadata-item::before {
        content: none;
      }

      .satisfaction-preview-color {
        display: inline-block;
        height: 12px;
        width: 12px;
        margin: 0 8px;
        background-color: ${props =>
          props.backgroundColor !== '#FFFFFF'
            ? props.backgroundColor
            : props.foregroundColor};
      }

      .conditions-summary {
        max-height: 160px;
        overflow-y: auto;
      }
    }
  }
`;
