import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { H2 } from '@studio/legacy-components';
import ChecklistTargeting from 'components/ChecklistsEdit/ChecklistTargeting';
import ChecklistPreview from 'components/ChecklistsEdit/ChecklistPreview';
import ExtraColorCustomization from 'components/ChecklistsEdit/ExtraColorCustomization';
import Panel from 'components/Common/Panel';

import { selectAccountMeta } from 'reducers/account/meta';
import { update } from 'entities/checklists';
import { navigate } from 'actions/routing';

import OverallData from 'components/ChecklistsEdit/OverallData';
import BeaconFields from 'components/ChecklistsEdit/BeaconFields';
import ListContent from 'components/ChecklistsEdit/ListContent';
import DismissContent from 'components/ChecklistsEdit/DismissContent';
import ChecklistItemsPanel from 'components/ChecklistsEdit/ChecklistItemsPanel';
import CongratsContent from 'components/ChecklistsEdit/CongratsContent';
import DisplayContent from 'components/ChecklistsEdit/DisplayContent';
import { selectAccountFeature } from 'reducers/account/features';
import { EXTRA_CHECKLIST_CUSTOMIZATION } from 'constants/features';

export class ChecklistConfiguration extends Component {
  state = {
    beaconTextError: false,
    completionEdit: false,
    dismissEdit: false,
    completionDismissTextError: false,
    dismissTextError: false,
    dismissCancelTextError: false,
    dismissConfirmTextError: false,
  };

  setCompletionState = () => {
    this.setState({ completionEdit: true });
  };

  resetCompletionState = () => {
    this.setState({ completionEdit: false });
  };

  setDismissState = () => {
    this.setState({ dismissEdit: true });
  };

  resetDismissState = () => {
    this.setState({ dismissEdit: false });
  };

  resetTextError = property => {
    const errorProperty = `${property}Error`;

    this.setState({ [errorProperty]: false });
  };

  resetBeaconTextError = () => this.resetTextError('beaconText');

  // styles
  updateChecklistStyles = (property, { target: { value } }) => {
    const { checklist, onChange } = this.props;
    onChange(checklist.id, {
      styles: {
        ...checklist.styles,
        [property]: value,
      },
    });
  };

  updateChecklistStylesDropdown = (property, item) => {
    const { checklist, onChange } = this.props;
    onChange(checklist.id, {
      styles: {
        ...checklist.styles,
        [property]: item.value,
      },
    });
  };

  updateChecklistStylesSlider = (property, hex) => {
    const { checklist, onChange } = this.props;
    onChange(checklist.id, {
      styles: {
        ...checklist.styles,
        [property]: hex,
      },
    });
  };

  updateChecklistFontDropdown = item => {
    const { checklist, onChange } = this.props;
    onChange(checklist.id, {
      styles: {
        ...checklist.styles,
        font: item.value,
        fontUrl: item.importUrl || null,
      },
    });
  };

  // textTokens
  updateChecklistTextToken = (property, { target: { value } }) => {
    const { checklist, onChange } = this.props;

    onChange(checklist.id, {
      textTokens: {
        ...checklist.textTokens,
        [property]: value,
      },
    });
  };

  updateChecklistCanDismissFlag = value => {
    const { checklist, onChange } = this.props;
    onChange(checklist.id, {
      isDismissable: value,
    });
  };

  updateLimitedChecklistTextToken = (
    property,
    limit,
    { target: { value } }
  ) => {
    const { checklist, onChange } = this.props;

    const errorProperty = `${property}Error`;
    const { [errorProperty]: currentError } = this.state;

    if (value.length > limit) {
      this.setState({ [errorProperty]: true }, () =>
        setTimeout(() => this.resetTextError(property), 1000)
      );
      return;
    }
    if (value.length < limit && currentError) {
      this.resetTextError(property);
    }

    onChange(checklist.id, {
      textTokens: {
        ...checklist.textTokens,
        [property]: value,
      },
    });
  };

  render() {
    const {
      checklist,
      className,
      meta,
      hasExtraChecklistCustomization,
      onChange,
      onNavigate,
    } = this.props;

    const {
      beaconTextError,
      completionDismissTextError,
      dismissTextError,
      dismissCancelTextError,
      dismissConfirmTextError,
      completionEdit,
      dismissEdit,
    } = this.state;

    return (
      <Panel className={className} name="Configuration">
        <div className="config-wrapper">
          <div className="fields-wrapper">
            <OverallData
              checklistId={checklist.id}
              updateChecklist={onChange}
              updateChecklistFontDropdown={this.updateChecklistFontDropdown}
              updateChecklistStyles={this.updateChecklistStyles}
              updateChecklistStylesSlider={this.updateChecklistStylesSlider}
            />
            {
              // this is a special customization (for IBM) not meant for general use
              hasExtraChecklistCustomization && (
                <ExtraColorCustomization
                  checklistId={checklist.id}
                  updateChecklistStyles={this.updateChecklistStyles}
                  updateChecklistStylesSlider={this.updateChecklistStylesSlider}
                />
              )
            }
            <BeaconFields
              checklistId={checklist.id}
              beaconTextError={beaconTextError}
              resetBeaconTextError={this.resetBeaconTextError}
              updateChecklistStyles={this.updateChecklistStyles}
              updateChecklistStylesSlider={this.updateChecklistStylesSlider}
              updateChecklistStylesDropdown={this.updateChecklistStylesDropdown}
              updateLimitedChecklistTextToken={
                this.updateLimitedChecklistTextToken
              }
            />
            <ListContent
              checklistId={checklist.id}
              updateChecklistTextToken={this.updateChecklistTextToken}
            />
            <DismissContent
              checklistId={checklist.id}
              dismissTextError={dismissTextError}
              dismissCancelTextError={dismissCancelTextError}
              dismissConfirmTextError={dismissConfirmTextError}
              resetDismissState={this.resetDismissState}
              setDismissState={this.setDismissState}
              updateChecklistTextToken={this.updateChecklistTextToken}
              updateLimitedChecklistTextToken={
                this.updateLimitedChecklistTextToken
              }
              updateChecklistCanDismissFlag={this.updateChecklistCanDismissFlag}
            />
            <ChecklistItemsPanel checklistId={checklist.id} />
            <CongratsContent
              checklistId={checklist.id}
              completionDismissTextError={completionDismissTextError}
              meta={meta}
              resetCompletionState={this.resetCompletionState}
              setCompletionState={this.setCompletionState}
              updateChecklistStylesDropdown={this.updateChecklistStylesDropdown}
              updateChecklistStyles={this.updateChecklistStyles}
              updateChecklistStylesSlider={this.updateChecklistStylesSlider}
              updateChecklistTextToken={this.updateChecklistTextToken}
              updateLimitedChecklistTextToken={
                this.updateLimitedChecklistTextToken
              }
            />

            <DisplayContent
              checklistId={checklist.id}
              updateChecklist={onChange}
            />
          </div>
          <ChecklistPreview
            checklist={checklist}
            completionEdit={completionEdit}
            dismissEdit={dismissEdit}
            navigate={onNavigate}
          />
        </div>
        <H2>Targeting</H2>
        <div className="targeting-wrapper">
          <div className="targeting-container">
            <ChecklistTargeting
              checklistId={checklist.id}
              meta={meta}
              updateChecklist={onChange}
            />
          </div>
        </div>
      </Panel>
    );
  }
}

const mapStateToProps = state => {
  return {
    meta: selectAccountMeta(state),
    hasExtraChecklistCustomization: selectAccountFeature(
      state,
      EXTRA_CHECKLIST_CUSTOMIZATION
    ),
  };
};

const mapDispatchToProps = {
  onChange: update,
  onNavigate: navigate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styled(ChecklistConfiguration)`
  .config-wrapper {
    display: flex;
  }

  .fields-wrapper {
    flex: 1 1 66%;
  }

  .preview-wrapper {
  }

  .config-wrapper .fields-wrapper .items-panel {
    max-width: 65%;

    .panel-heading {
      width: 94%;
    }
  }

  .hover-tab {
    position: absolute;
    z-index: 1;
    height: 47px;
    width: 138px;
    background-color: ${props => props.theme['$gray-50']};
    color: ${props => props.theme['$gray-70']};
    right: 337px;
    bottom: 50%;
    text-align: center;
    font-size: 14px;
    border-radius: 6px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding: 6px 20px;
    cursor: pointer;

    transition: all 0.1s ease-out;

    transform: rotate(-90deg);

    &:hover {
      color: white;
      background-color: ${props => props.theme.$purple};
      transform: rotate(-90deg) translateY(-6px);
    }
  }

  .preview-header {
    font-size: 20px;
    color: #cccccc;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.05em;
    -webkit-font-smoothing: antialiased;
  }

  .slider-picker {
    position: absolute;
    top: -10px;
    left: 155px;
    width: 300px;
    background-color: white;
    border: 1px solid lightgrey;
    padding: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  .field-group-fields {
    display: flex;
    align-items: center;
  }

  .targeting-wrapper {
    display: flex;
  }

  .targeting-container {
    flex: 1 1 60%;
  }

  .helper-container {
    flex: 1 1 40%;
  }

  .add-item-button {
    display: inline-block;
  }
`);
