import { createSelector } from 'reselect';

import { ENTITLEMENTS } from 'constants/features';
import { isAccountAdmin, isSuperUser } from 'helpers/account-management';
import { selectAccountFeatures } from 'reducers/account/features';
import { selectUserSeatsEntitlement } from 'reducers/entitlements';
import { selectAccountUsers } from 'reducers/account/users';
import { selectUser } from 'reducers/user';
import { selectAccountStats } from 'reducers/account/stats';
import { selectCurrentPlanData } from 'selectors/billing';
import { determineStatus } from 'utils/entitlements';

export const selectIsAllowedToInviteUsers = createSelector(
  selectUser,
  selectAccountUsers,
  selectUserSeatsEntitlement,
  selectAccountFeatures,
  (user, accountUsers, hasUserEntitlement, featureFlags) => {
    const isRoleAuthorized =
      isSuperUser(user) || isAccountAdmin({ user, accountUsers });

    if (!featureFlags[ENTITLEMENTS]) {
      return isRoleAuthorized;
    }

    return isRoleAuthorized && hasUserEntitlement;
  }
);

export const selectMausProcessed = createSelector(
  selectAccountStats,
  selectCurrentPlanData,
  (allTimeStats, planData = {}) => {
    const monthlyActiveUsers = allTimeStats?.monthly_website_users;
    const planLimit = planData?.planLimit;

    const percent =
      monthlyActiveUsers && planLimit
        ? (monthlyActiveUsers / planLimit) * 100
        : 0;

    const currentStatus = determineStatus(percent);

    return {
      id: 'maus',
      usedUnits: monthlyActiveUsers ?? 0,
      availableUnits: planLimit,
      completePercent: percent,
      currentStatus,
      showWarning: planLimit && currentStatus !== 'compliant',
    };
  }
);

export const selectMobileMaus = createSelector(
  selectAccountStats,
  allTimeStats => allTimeStats.monthly_mobile_active_users || 0
);
export const selectCombinedMaus = createSelector(
  selectAccountStats,
  allTimeStats => allTimeStats.monthly_combined_users || 0
);
