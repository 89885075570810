/* global ADWORDS_ID,ADWORDS_EVENT_ID,FACEBOOK_ID,CAPTERRA_ID,CAPTERRA_KEY,LINKEDIN_ID,
LINKEDIN_CONVERSION_ID */
/* eslint-disable func-names */
/* eslint-disable no-multi-assign */
/* eslint-disable prefer-spread */
/* eslint-disable no-unused-expressions */

/**
 * Ported over from auth.appcues.com
 * */

const loadScriptAsync = src => {
  return new Promise(resolve => {
    const script = document.createElement('script');
    script.async = true;
    script.src = src;
    script.addEventListener('load', () => {
      resolve();
    });
    document.body.append(script);
  });
};
const loadAdwords = async () => {
  await loadScriptAsync(
    `https://www.googletagmanager.com/gtag/js?id=${ADWORDS_ID}`
  );

  window.dataLayer = window.dataLayer || [];
};
const trackAdwords = async () => {
  if (!window.dataLayer) return;
  function gtag() {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', ADWORDS_ID);
  gtag('event', 'conversion', {
    send_to: ADWORDS_EVENT_ID,
  });
};

const loadFacebook = async () => {
  if (window.fbq) {
    return;
  }

  const n = (window.fbq = function (...parameters) {
    n.callMethod ? n.callMethod.apply(n, parameters) : n.queue.push(parameters);
  });
  // eslint-disable-next-line no-underscore-dangle
  if (!window._fbq) window._fbq = n;
  n.push = n;
  n.loaded = true;
  n.version = '2.0';
  n.queue = [];
  await loadScriptAsync('https://connect.facebook.net/en_US/fbevents.js');
};

const trackFacebook = async () => {
  if (!window.fbq) {
    return;
  }
  window.fbq('init', FACEBOOK_ID);
  window.fbq('track', 'PageView');
};

const loadAndTrackCapterra = async () => {
  await loadScriptAsync(
    `https://ct.capterra.com/capterra_tracker.js?vid=${CAPTERRA_ID}&vkey=${CAPTERRA_KEY}`
  );
};

const loadAndTrackLinkedin = () => {
  return new Promise(resolve => {
    const img = document.createElement('img');
    img.height = 1;
    img.width = 1;
    img.style = 'display:none;';
    img.addEventListener('load', () => {
      resolve();
    });
    img.src = `https://dc.ads.linkedin.com/collect/?pid=${LINKEDIN_ID}&conversionId=${LINKEDIN_CONVERSION_ID}&fmt=gif`;
    document.body.append(img);
  });
};

const trackGoogleAnalytics = async () => {
  if (window.ga) {
    window.ga('send', 'pageview');
  }
};

export const track = async () => {
  try {
    await Promise.all([
      loadAndTrackCapterra(),
      loadAndTrackLinkedin(),
      trackAdwords(),
      trackFacebook(),
      trackGoogleAnalytics(),
    ]);
    return true;
  } catch {
    return false;
  }
};

export const loadScripts = async () => {
  try {
    await Promise.all([loadAdwords(), loadFacebook()]);
    return true;
  } catch {
    return false;
  }
};
