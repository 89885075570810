/**
 * Versioned experiences have a multi-column primary key
 * (experienceId + version).  To support this in Collections API,
 * we use this function to generate a compound key encapsulating
 * both fields
 *
 * @param id
 * @param version
 * @returns {string}
 */

export const getCacheKey = (id, version) => `${id}:${version}`;
