import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Box, Caption } from '@appcues/component-library';
import styled from 'styled-components';
import { IconWithinCButton } from '@studio/legacy-components';
import CoolTip from 'components/Common/CoolTip';
import { numberWithCommas } from 'utils/textFormatting';

const DELTA_STATES = {
  positive: {
    color: '$green-dark',
    icon: 'caret-up',
  },
  negative: {
    color: '$red',
    icon: 'caret-down',
  },
  neutral: {
    color: '$gray-5',
    icon: 'minus',
  },
};

const StyledMetricDelta = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme['$gray-5']};
  .svg-inline--fa {
    color: ${props => props.theme[props.color]};
  }
`;

const getComputedDelta = (delta, value, previousValue) => {
  return delta || Math.round((100 * (value - previousValue)) / previousValue);
};

export const Delta = ({
  value,
  delta,
  previousValue,
  timePeriod,
  showToolTip,
}) => {
  let deltaIcon;

  const computedDelta =
    !delta && Number(value) !== 0
      ? getComputedDelta(delta, value, previousValue)
      : 0;

  if (computedDelta > 0) {
    deltaIcon = DELTA_STATES.positive;
  } else if (computedDelta < 0) {
    deltaIcon = DELTA_STATES.negative;
  } else if (computedDelta === 0) {
    deltaIcon = DELTA_STATES.neutral;
  }

  return (
    <StyledMetricDelta color={deltaIcon && deltaIcon.color}>
      {timePeriod !== 'all_time' ? (
        <CoolTip
          tip={`From ${numberWithCommas(previousValue)} users`}
          width="auto"
          whiteSpace="nowrap"
          position="right"
          disabled={showToolTip}
        >
          <Flex cursor="pointer" alignItems="center">
            {deltaIcon && <IconWithinCButton icon={deltaIcon.icon} />}
            {!!computedDelta && (
              <Box fontWeight={600} lineHeight="26px" fontSize={16}>
                <Caption aria-label={computedDelta}>
                  {Math.abs(computedDelta)}%
                </Caption>
              </Box>
            )}
          </Flex>
        </CoolTip>
      ) : (
        <Caption>All time</Caption>
      )}
    </StyledMetricDelta>
  );
};

Delta.propTypes = {
  value: PropTypes.number,
  delta: PropTypes.string,
  previousValue: PropTypes.number,
  timePeriod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showToolTip: PropTypes.bool,
};

export default Delta;
