import React from 'react';
import PropTypes from 'prop-types';

import { Caption, H2, Text } from '@studio/legacy-components';
import UnitsCalculator from './UnitsCalculator';
import { AddonWrapper, Description, Header, TitleWrapper } from './styled';

const Addon = ({
  currentUsage,
  description,
  initialValue,
  minValue,
  maxValue,
  onChange,
  price,
  title,
}) => (
  <AddonWrapper>
    <Header>
      <TitleWrapper>
        <H2>{title}</H2>
        <Caption bold>{price}</Caption>
      </TitleWrapper>
      <UnitsCalculator
        initialValue={initialValue}
        onChange={onChange}
        minValue={minValue}
        maxValue={maxValue}
      />
    </Header>
    <Description>
      <Text bold>{currentUsage}</Text>
      {description && <Text>{description}</Text>}
    </Description>
  </AddonWrapper>
);

Addon.propTypes = {
  currentUsage: PropTypes.string,
  description: PropTypes.string,
  initialValue: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  onChange: PropTypes.func,
  price: PropTypes.string,
  title: PropTypes.string,
};

export default Addon;
