import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { propertyConditionNames } from '@appcues/libcues';
import { Select } from '@studio/legacy-components';
import {
  CHECKLIST_TARGETING,
  CHECKLIST_TARGETING_BETA,
  GROUP_PROPERTY_TARGETING,
} from 'next/entities/features';
import { UpgradeBadge } from 'next/components/UpgradeTag';
import BaseClause from 'components/Common/Clauses/Base';
import { getEmptyClauseForConditionName } from 'utils/conditions';
import { selectFlows } from 'reducers/account/flows';
import { selectAccountSegments } from 'reducers/account/segments';
import { selectAccountUserEvents } from 'selectors/event-names';
import { selectAccountChecklists } from 'entities/checklists';
import { selectAccountFeature } from 'reducers/account/features';
import { selectGroupAttributes } from 'entities/group-attributes';

const Property = styled.div`
  ${Select} {
    margin-right: 10px;
    max-width: unset;
    min-width: unset;
    width: 11em;

    ${UpgradeBadge} {
      margin-top: 6px;
    }
  }
`;

function AudienceBaseClause(props) {
  const {
    children,
    clause,
    hasEvents,
    hasSegments,
    hasChecklists,
    hasSteps,
    hasGroupProperties,
    hasChecklistTargeting,
    hasChecklistTargetingBeta,
    hasGroupPropertyTargeting,
    replaceClause,
    skipSegments,
  } = props;

  const conditionNameOptions = [
    {
      value: propertyConditionNames.PROPERTIES,
      label: hasGroupPropertyTargeting ? 'User property' : 'Property',
    },
  ];

  if (hasGroupPropertyTargeting) {
    conditionNameOptions.push({
      value: propertyConditionNames.GROUPS,
      label: 'Group property',
      isDisabled: !hasGroupProperties,
    });
  }

  conditionNameOptions.push(
    {
      value: propertyConditionNames.LANGUAGES,
      label: 'Language',
    },
    {
      value: propertyConditionNames.CONTENT,
      label: 'Flow',
      disabled: !hasSteps,
    }
  );

  const isChecklistClause =
    clause.conditionName === propertyConditionNames.CHECKLISTS;

  if (hasChecklistTargetingBeta || isChecklistClause) {
    conditionNameOptions.push({
      value: propertyConditionNames.CHECKLISTS,
      label: (
        <>
          Checklist
          {!hasChecklistTargeting && !isChecklistClause && (
            <UpgradeBadge label="Upgrade" icon="magic" />
          )}
        </>
      ),
      isDisabled: !(hasChecklistTargeting && hasChecklists),
    });
  }

  conditionNameOptions.push({
    value: propertyConditionNames.EVENT,
    label: 'Event',
    disabled: !hasEvents,
  });

  if (!skipSegments) {
    conditionNameOptions.push({
      value: propertyConditionNames.SEGMENTS,
      label: 'Segment',
      disabled: !hasSegments,
    });
  }

  const selected = conditionNameOptions.find(
    option => option.value === clause.conditionName
  );

  return (
    <BaseClause {...props}>
      <Property>
        <Select
          onChange={({ value }) =>
            replaceClause(clause.id, getEmptyClauseForConditionName(value))
          }
          operator
          options={conditionNameOptions}
          value={selected}
        />
      </Property>
      {children}
    </BaseClause>
  );
}

AudienceBaseClause.propTypes = {
  children: PropTypes.node,
  clause: PropTypes.shape({
    conditionName: PropTypes.string,
    id: PropTypes.string,
  }),
  hasEvents: PropTypes.bool,
  hasSegments: PropTypes.bool,
  hasSteps: PropTypes.bool,
  hasChecklists: PropTypes.bool,
  hasGroupProperties: PropTypes.bool,
  replaceClause: PropTypes.func,
  skipSegments: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    hasSteps: Object.keys(selectFlows(state)).length > 0,
    hasEvents: Object.keys(selectAccountUserEvents(state)).length > 0,
    hasSegments: Object.keys(selectAccountSegments(state)).length > 0,
    hasChecklists: Object.keys(selectAccountChecklists(state)).length > 0,
    hasGroupProperties:
      Object.keys(selectGroupAttributes(state) || {}).length > 0,
    hasChecklistTargeting: selectAccountFeature(state, CHECKLIST_TARGETING),
    hasChecklistTargetingBeta: selectAccountFeature(
      state,
      CHECKLIST_TARGETING_BETA
    ),
    hasGroupPropertyTargeting: selectAccountFeature(
      state,
      GROUP_PROPERTY_TARGETING
    ),
  };
}

export default connect(mapStateToProps)(AudienceBaseClause);
