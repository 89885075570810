import { createLifecycleFor } from 'actions/account/collections';

export const TYPE = 'userQualifiedContent';

export const { callApi, replace, patterns, remove } = createLifecycleFor(TYPE);

export const RESET_FLOW_HISTORY = 'RESET_FLOW_HISTORY';

export function resetFlowHistory(userId) {
  return {
    type: RESET_FLOW_HISTORY,
    payload: userId,
  };
}

export const RESET_FLOW_HISTORY_SUCCESS = 'RESET_FLOW_HISTORY_SUCCESS';

export function resetFlowHistorySuccess(userId) {
  return {
    type: RESET_FLOW_HISTORY_SUCCESS,
    payload: userId,
  };
}
