import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Notice, NOTICE_TYPES } from '@appcues/component-library';
import BaseModal from 'components/ModalContainer/BaseModal';
import Input from 'components/Common/Forms/Input';
import { createSegmentFromRule } from 'actions/account/segments';
import { selectAccountSegments } from 'reducers/account/segments';

const NameInput = styled(Input)`
  margin-bottom: 10px;

  &::placeholder {
    color: #bbbbbb;
    font-style: italic;
  }
`;

function CreateSegmentModal(props) {
  const { onCreateCallback, onSave, segments, stepId } = props;

  const [name, setName] = useState('');
  const [error, setError] = useState(null);

  const handleOK = () => {
    return new Promise((resolve, reject) => {
      if (name) {
        const matchingSegment = Object.values(segments || {}).find(segment => {
          return `${segment.name}`.toLowerCase() === name.toLowerCase();
        });

        if (matchingSegment) {
          // should warn the user that a segment already exists
          const message = `A segment by the name "${name}" already exists.`;
          setError(message);
          reject(new Error(message));
        } else {
          onSave(name, stepId);

          if (onCreateCallback) {
            onCreateCallback();
          }

          resolve();
        }
      } else {
        const message = 'Please enter a name for your new segment';
        setError(message);
        reject(new Error(message));
      }
    });
  };

  const handleChange = ({ target: { value } }) => {
    setName(value);
  };

  return (
    <BaseModal
      title="Create Segment"
      okText="Create Segment"
      modalClass="redirect-modal"
      onClickOK={handleOK}
      {...props}
    >
      {error && <Notice type={NOTICE_TYPES.warning}>{error}</Notice>}

      <p>Please enter a name for your new segment</p>

      <NameInput
        type="text"
        placeholder="Activated Users"
        value={name}
        onChange={handleChange}
      />
    </BaseModal>
  );
}

CreateSegmentModal.propTypes = {
  stepId: PropTypes.string,
  segments: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  onSave: PropTypes.func,
  onCreateCallback: PropTypes.func,
};

const mapStateToProps = state => ({
  segments: selectAccountSegments(state),
});

const mapDispatchToProps = {
  onSave: createSegmentFromRule,
};

const ConnectedCreateSegmentModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSegmentModal);

ConnectedCreateSegmentModal.propTypes = {
  stepId: PropTypes.string,
  onCreateCallback: PropTypes.func,
};

export default ConnectedCreateSegmentModal;
