import * as actionTypes from 'constants/chromeExtension/actionTypes';

const initialState = {
  hasChrome: false,
  hasCheckedForExtension: false,
  hasExtensionInstalled: false,
  /* global CRX_ID */
  extensionId: CRX_ID,
  version: null,
};

export default function chromeExtension(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CHROME_EXTENSION_STATUS_UPDATED: {
      return {
        ...state,
        hasCheckedForExtension: true,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}

export const selectHasCheckedForChromeExtension = state =>
  state.chromeExtension.hasCheckedForExtension;
export const selectChromeExtensionId = state =>
  state.chromeExtension.extensionId;
export const selectChromeExtensionVersion = state =>
  state.chromeExtension.version;
export const selectHasChromeExtensionInstalled = state =>
  state.chromeExtension.hasExtensionInstalled;
export const selectHasChrome = state => state.chromeExtension.hasChrome;
