import styled from 'styled-components';
import { ButtonLink } from '@studio/legacy-components';

export const TimestampContainer = styled.span`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const UpdateButton = styled(ButtonLink)`
  margin-left: 8px;

  &[disabled] {
    color: var(--dirty-water);
    pointer-events: none;
  }
`;

export const ButtonWrapper = styled.div``;
