import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  H1,
  Page,
  PageActions,
  PageBody,
  PageHeader,
  PageTitle,
} from '@studio/legacy-components';
import { Button } from '@appcues/sonar';
import ExperienceList from 'next/components/ExperienceList';
import { readTags, Shape as TagShape } from 'next/entities/tags';
import { Shape as CreatorShape } from 'next/entities/users';
import { Shape as SegmentShape, readSegments } from 'next/entities/segments';
import {
  readExperienceAssociationsForDomains,
  readCreators,
} from 'next/lib/selectors';
import {
  Shape as ExperienceShape,
  readExperiencesByType,
} from 'next/entities/experiences';
import { asRequest } from 'next/entities/requests';
import { replace as replacePage } from 'next/entities/page';
import { Loading } from 'next/components/Listing';
import { HelpBar } from 'next/components/HelpBar';
import useTitle from 'next/hooks/use-title';
import ExperienceContext from 'next/components/ExperienceContext';
import Empty from './Empty';

const LaunchpadListingPage = ({
  creators = {},
  domainAssociations = {},
  experiences = {},
  onLoad,
  segments = {},
  tags = {},
}) => {
  useTitle('Launchpads | Appcues');
  useEffect(() => {
    onLoad?.();
  }, [onLoad]);

  const { loading: loadingExperiences = true } = experiences;
  const loading = loadingExperiences;

  const experienceData = useMemo(
    () => ({
      type: 'launchpad',
      routePath: '/launchpads',
    }),
    []
  );

  const StyledButton = styled(Button)`
    text-decoration: none;
  `;

  return (
    <ExperienceContext.Provider value={experienceData}>
      <Page>
        <PageHeader>
          <PageTitle>
            <H1>Launchpads</H1>
          </PageTitle>

          <PageActions>
            <StyledButton forwardedAs={Link} to="/launchpads/builder">
              Create Launchpad
            </StyledButton>
          </PageActions>
        </PageHeader>

        <PageBody>
          <HelpBar section="launchpads" />
          {loading && <Loading />}

          {!loading && (
            <ExperienceList
              creators={creators.data}
              domainAssociations={domainAssociations.data}
              experiences={experiences.data}
              segments={segments.data}
              tags={tags.data}
              emptyState={<Empty />}
            />
          )}

          {/* TODO: Handle error state */}
        </PageBody>
      </Page>
    </ExperienceContext.Provider>
  );
};

LaunchpadListingPage.propTypes = {
  domainAssociations: asRequest(
    PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
  ),
  experiences: asRequest(PropTypes.objectOf(ExperienceShape)),
  tags: asRequest(PropTypes.objectOf(TagShape)),
  segments: asRequest(PropTypes.objectOf(SegmentShape)),
  creators: asRequest(PropTypes.objectOf(CreatorShape)),
  onLoad: PropTypes.func,
};

const mapStateToProps = state => ({
  creators: readCreators(state),
  domainAssociations: readExperienceAssociationsForDomains(state, 'launchpad'),
  experiences: readExperiencesByType(state, 'launchpad'),
  segments: readSegments(state),
  tags: readTags(state),
});

const mapDispatchToProps = (dispatch, { match: { path } }) => ({
  onLoad: () => dispatch(replacePage({ path })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LaunchpadListingPage);
