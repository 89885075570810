import decode from 'jwt-decode';

// eslint-disable-next-line no-restricted-imports
import auth from 'helpers/auth-client';

/**
 * Logout user
 *
 * @return {Promise<void>}
 */
export const logout = () => {
  return auth.logout();
};

/**
 * Spoof into another account
 *
 * @param {string} id - Account ID to spoof
 * @return {Promise<void>}
 */
export const spoof = id => {
  return auth.spoof(id);
};

/**
 * Despoof from current account
 *
 * @return {Promise<void>}
 */
export const despoof = () => {
  return auth.despoof();
};

/**
 * Whether user is currently spoofing
 *
 * @return {Promise<boolean>}
 */
export const isSpoofing = () => {
  return auth.isSpoofing();
};

/**
 * Switch account
 *
 * @param {string} id - Account ID
 * @return {Promise<void>}
 */
export const switchAccount = id => {
  return auth.switchAccount(id);
};

/**
 * Determine if this is the initial login for the user by checking the JWT
 * claim for first login.
 *
 * @param {string} jwt - JSON web Token
 * @return {boolean} Whether this is the first login ever
 */
export const initial = jwt => {
  const { claims } = decode(jwt);
  return Boolean(claims?.first_login);
};
