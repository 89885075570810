import styled from 'styled-components';
import {
  Button,
  ButtonGroup,
  Card,
  Definition,
  DescriptiveList,
  H3,
  Heading,
  Icon,
  Link,
  NegativeNotice,
  Term,
} from '@studio/legacy-components';

/**
 * Apps Installation
 */
export const AppsLayout = styled.div`
  display: grid;
  gap: 24px;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fill, minmax(430px, 1fr));

  ${Card} {
    margin-top: 0;
  }
`;

/**
 * Web Card
 */
export const WebCardWrapper = styled(Card)`
  display: flex;
  align-items: center;

  ${H3} {
    margin-bottom: 8px;
  }

  ${Link} {
    color: var(--primary);

    &:hover {
      color: var(--primary-dark);
    }
  }
`;

export const WebBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const UpperContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  justify-content: space-between;
  align-items: center;

  padding-top: 4px;
`;

/**
 * Segment Modal
 */
export const ButtonLink = styled(Button)`
  &:hover {
    color: var(--white);
  }
`;

/**
 * App Card
 */
export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  ${H3} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 12px;
  }
`;

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
`;

export const InfoColumn = styled.div`
  padding: 6px 0 0;
  min-width: 0;

  ${DescriptiveList} {
    ${Term},
    ${Term}:not(:last-of-type) {
      float: none;
      margin-bottom: 6px;
    }

    ${Definition}:not(:last-of-type) {
      margin-bottom: 18px;
    }

    ${Definition}:last-of-type {
      color: var(--text-primary);
    }
  }

  ${Icon} {
    margin-right: 8px;
  }
`;

export const ActionsColumn = styled.div`
  display: flex;
  align-items: flex-end;

  ${Button} {
    white-space: nowrap;

    &:hover {
      color: var(--white);
    }
  }
`;

export const PlatformIcon = styled(Icon)`
  &[data-icon='android'] {
    margin-top: 2px;
  }
`;

/**
 * App Form
 */
export const CreateAppForm = styled.form`
  ${Heading} {
    margin-bottom: 16px;
  }

  ${ButtonGroup} {
    margin-top: 24px;
  }

  ${NegativeNotice} {
    margin-bottom: 16px;
  }
`;

export const ModalNegativeNotice = styled(NegativeNotice)`
  margin-bottom: 12px;
  margin-top: 6px;
`;
