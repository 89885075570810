import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Thead,
  Th,
  Tbody,
  Tr,
  PanelTitle,
  PanelHeader,
  Panel,
  H3,
  SearchInput,
  PanelActions,
  Description,
  Tooltip,
} from '@studio/legacy-components';
import { Loading } from 'next/components/Listing';
import useQueryString from 'next/hooks/use-query-string';
import { fromNow } from 'next/lib/date';
import NoData from 'assets/images/no-data.svg';
import NoResults from 'assets/images/no-results.svg';
import { callApi as fetchAllSegmentMemberships } from 'actions/account/segmentMemberships';
import {
  selectSegmentsAsTableRows,
  selectMaxSegmentBasis,
} from 'selectors/segments';
import { callApi as fetchRules } from 'actions/account/rules';
import { selectAccountSegmentsSynced } from 'reducers/account/segments';
import { selectSegmentMembershipsComputedAt } from 'reducers/account/segmentMemberships';
import { callApi as readExperiences } from 'entities/experiences';

import {
  LoaderWrapper,
  NoResultsMessage,
  NoResultsMessageEmphasis,
} from 'components/Audience/styled';
import {
  TableWrapper,
  SegmentsTable,
  TooltipWrapper,
  TooltipIcon,
  TooltipContentWrapper,
} from './styled';

import SegmentRowItem from './SegmentRowItem';
import { DeleteSegmentModal } from './DeleteSegmentModal';
import ExportModal from './ExportModal';

export function List({
  onLoad,
  weeklyUsers,
  segments,
  lastComputedAt,
  isLoading,
}) {
  const [modalConfig, setModalConfig] = useState({
    currentModal: null,
    visible: false,
    segmentId: null,
  });

  const dropdownRef = useRef();

  const handleTableScroll = useCallback(() => {
    dropdownRef.current?.reposition();
  }, []);

  const [sortConfig, setSortConfig] = useState({
    order: 'desc',
    column: 'updatedAt',
  });

  const [searchQuery, setSearchQuery] = useQueryString('search', '');

  const sortFn = useCallback(
    (a = '', b = '') => {
      const { order, column } = sortConfig;
      const columnA = a[column];
      const columnB = b[column];
      const columnAValue =
        typeof columnA === 'string' ? columnA : JSON.stringify(columnA) || '-';
      const columnBValue =
        typeof columnB === 'string' ? columnB : JSON.stringify(columnB) || '-';

      if (columnAValue !== columnBValue) {
        if (order === 'asc') {
          return columnAValue.localeCompare(columnBValue, undefined, {
            numeric: true,
          });
        }
        return columnBValue.localeCompare(columnAValue, undefined, {
          numeric: true,
        });
      }

      return String(a.updatedAt).localeCompare(String(b.updatedAt), undefined, {
        numeric: true,
      });
    },
    [sortConfig]
  );

  const sortedSegments = useMemo(() => {
    return [...(segments || [])]
      .sort(sortFn)
      .filter(({ name }) =>
        name.toUpperCase().includes(searchQuery.trim().toUpperCase())
      );
  }, [searchQuery, segments, sortFn]);

  const hasNoData = !isLoading && segments?.length === 0;
  const hasNoResults = !hasNoData && sortedSegments?.length === 0;

  useEffect(() => {
    onLoad();
  }, []);

  const handleColumnClick = column => {
    setSortConfig(current => {
      if (column === sortConfig.column) {
        return {
          ...current,
          order: current.order === 'asc' ? 'desc' : 'asc',
        };
      }
      return {
        order: 'asc',
        column,
      };
    });
  };

  const handleDeleteClick = segmentId =>
    setModalConfig({
      visible: true,
      segmentId,
    });
  const handleModalClose = () =>
    setModalConfig(current => ({
      ...current,
      visible: false,
    }));

  const handleExport = segmentId => {
    setModalConfig({
      currentModal: 'export',
      visible: true,
      segmentId,
    });
  };

  let totalSegments = '-';
  let totalUsers = '-';

  if (segments && segments.length > 0) {
    totalSegments = segments.length.toLocaleString();
    totalUsers = weeklyUsers?.toLocaleString() || 0;
  }

  return (
    <>
      <DeleteSegmentModal
        segmentId={modalConfig.segmentId}
        visible={modalConfig.currentModal !== 'export' && modalConfig.visible}
        onClose={handleModalClose}
      />
      <ExportModal
        id={modalConfig.segmentId}
        visible={modalConfig.currentModal === 'export' && modalConfig.visible}
        onClose={handleModalClose}
      />
      <Panel>
        <PanelHeader>
          {!isLoading && (
            <>
              <PanelTitle>
                <H3>{totalSegments} total Segments</H3>
                <Description>
                  {`${totalUsers} total
                  ${totalUsers === 1 ? 'user' : 'users'} in the last 7 days.`}
                  {lastComputedAt &&
                    lastComputedAt !== Number.POSITIVE_INFINITY &&
                    ` Calculated ${fromNow(lastComputedAt)}.`}
                </Description>
              </PanelTitle>
              <PanelActions>
                <SearchInput
                  placeholder="Search by Segment name"
                  value={searchQuery}
                  onChange={({ target: { value } }) => {
                    setSearchQuery(value);
                  }}
                />
              </PanelActions>
            </>
          )}
        </PanelHeader>
        <TableWrapper onScroll={handleTableScroll}>
          {isLoading && (
            <LoaderWrapper>
              <Loading />
            </LoaderWrapper>
          )}
          {hasNoData && (
            <NoResultsMessage>
              <img src={NoData} alt="No data yet" />
              We don’t have data for you yet
            </NoResultsMessage>
          )}
          {hasNoResults && (
            <NoResultsMessage>
              <img src={NoResults} alt="No data found" />
              <NoResultsMessageEmphasis>
                No results found
              </NoResultsMessageEmphasis>
              Try adjusting your filters for results
            </NoResultsMessage>
          )}
          {sortedSegments && sortedSegments.length > 0 && (
            <SegmentsTable sticky>
              <Thead>
                <Tr>
                  <Th
                    sortable
                    sorted={
                      (sortConfig.column === 'name' && sortConfig.order) || null
                    }
                    onClick={() => handleColumnClick('name')}
                  >
                    Name
                  </Th>
                  <Th
                    sortable
                    sorted={
                      (sortConfig.column === 'creator' && sortConfig.order) ||
                      null
                    }
                    onClick={() => handleColumnClick('creator')}
                  >
                    Created by
                  </Th>

                  <Th
                    sortable
                    sorted={
                      (sortConfig.column === 'numberOfExperiences' &&
                        sortConfig.order) ||
                      null
                    }
                    onClick={() => handleColumnClick('numberOfExperiences')}
                  >
                    Total Experiences
                  </Th>

                  <Th
                    sortable
                    sorted={
                      (sortConfig.column === 'count' && sortConfig.order) ||
                      null
                    }
                    onClick={() => handleColumnClick('count')}
                  >
                    <TooltipWrapper>
                      {' '}
                      <Tooltip
                        delay={0}
                        placement="top"
                        timeout={200}
                        persist
                        label={
                          <TooltipContentWrapper>
                            Based on {totalUsers} users seen on your app in the
                            last 7 days. Segments edited within the last 7 days
                            are denoted by a &quot;~&quot; as they have been
                            estimated based on a sample of users.
                          </TooltipContentWrapper>
                        }
                        wrapped
                      >
                        <TooltipIcon />
                      </Tooltip>
                      Users
                    </TooltipWrapper>
                  </Th>
                  <Th
                    sortable
                    sorted={
                      (sortConfig.column === 'percentOfUsers' &&
                        sortConfig.order) ||
                      null
                    }
                    onClick={() => handleColumnClick('percentOfUsers')}
                  >
                    <TooltipWrapper>
                      <Tooltip
                        delay={0}
                        placement="top"
                        timeout={200}
                        persist
                        label={
                          <TooltipContentWrapper>
                            Every time a user visits your app, we check if they
                            meet this Segment&apos;s criteria. If they do, we
                            add them to this count. Segments edited within the
                            last 7 days are denoted by a &quot;~&quot; as they
                            have been estimated based on a sample of users.
                          </TooltipContentWrapper>
                        }
                        wrapped
                      >
                        <TooltipIcon />
                      </Tooltip>
                      % of total users
                    </TooltipWrapper>
                  </Th>
                  <Th
                    sortable
                    sorted={
                      (sortConfig.column === 'updatedAt' && sortConfig.order) ||
                      null
                    }
                    onClick={() => handleColumnClick('updatedAt')}
                  >
                    Last edited
                  </Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {sortedSegments.map(segment => (
                  <SegmentRowItem
                    key={segment.id}
                    {...segment}
                    onDeleteClick={handleDeleteClick}
                    onExport={handleExport}
                    dropdownRef={dropdownRef}
                  />
                ))}
              </Tbody>
            </SegmentsTable>
          )}
        </TableWrapper>
      </Panel>
    </>
  );
}

List.propTypes = {
  onLoad: PropTypes.func,
  lastComputedAt: PropTypes.number,
  weeklyUsers: PropTypes.number,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      creator: PropTypes.string,
      userLifecycleStage: PropTypes.string,
      numberOfExperiences: PropTypes.number,
      updatedAt: PropTypes.number,
      count: PropTypes.number,
      basis: PropTypes.number,
    })
  ),
};

const mapStateToProps = state => {
  return {
    weeklyUsers: selectMaxSegmentBasis(state),
    segments: selectSegmentsAsTableRows(state),
    isLoading: !selectAccountSegmentsSynced(state),
    lastComputedAt: selectSegmentMembershipsComputedAt(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onLoad: () => {
    dispatch(readExperiences());
    dispatch(fetchAllSegmentMemberships());
    dispatch(fetchRules());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
