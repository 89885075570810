import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CButton } from '@appcues/component-library';
import { Creatable, Select } from '@studio/legacy-components';
import FieldGroup from 'components/Common/Forms/FieldGroup';
import Modal from 'components/Common/UI/Modal/Modal';
import AutosuggestProfileAttribute from 'components/Common/AutosuggestProfileAttribute';

const StyledFieldGroup = styled(FieldGroup)`
  margin-bottom: 20px;

  ${Creatable}, ${Select} {
    max-width: 100%;
  }
`;

export default function DiagnosticsUserModal({
  url: initialUrl,
  userId: initialUserId,
  onSubmit,
  onClose,
}) {
  const [url, setUrl] = useState(initialUrl);
  const [userId, setUserId] = useState(initialUserId);

  const handleCalculateClick = () => {
    onSubmit({ userId, url });
  };

  const handleSetURL = val => {
    setUrl(val);
  };

  const handleSetUserID = val => {
    setUserId(val);
  };

  return (
    <Modal type="medium" title="User & Page" onClose={onClose}>
      <Modal.Content padding={15}>
        <StyledFieldGroup title="User ID" disableMargin>
          <AutosuggestProfileAttribute
            onChange={handleSetUserID}
            portal
            property="userId"
            value={userId}
          />
        </StyledFieldGroup>

        <StyledFieldGroup title="Domain & Page" disableMargin>
          <AutosuggestProfileAttribute
            onChange={handleSetURL}
            portal
            property="_lastPageUrl"
            value={url}
          />
        </StyledFieldGroup>
      </Modal.Content>
      <Modal.Footer>
        <CButton onClick={onClose}>Cancel</CButton>
        <CButton
          type="primary"
          isDisabled={!(userId.length > 0 && url.length > 0)}
          onClick={handleCalculateClick}
        >
          Recalculate Eligibility
        </CButton>
      </Modal.Footer>
    </Modal>
  );
}

DiagnosticsUserModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  url: PropTypes.string,
  userId: PropTypes.string,
};
