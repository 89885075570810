import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import { ExternalLink, WarningNotice } from '@studio/legacy-components';
import { selectAccountSegment } from 'reducers/account/segments';
import { WarningWrapper } from './styled';

const HELP_ARTICLE = 'https://docs.appcues.com/article/260-segments-overview';

/**
 * Build warning message based on the cutoff date, property of the segment, and
 * whether the message refers to a selected segment or the segment directly.
 *
 * @param {object} params
 * @param {string} params.date - Formatted date of affected change
 * @param {('created'|'updated')} params.property - Property of segment
 * @param {boolean} params.selected - Whether to refer to segment indirectly
 * @return {string} Warning message
 */
const buildMessage = ({ date, property, selected }) => {
  const subject = selected ? 'The selected segment' : 'This segment';
  return `${subject} was recently ${property}. Data before ${date} will not reflect those updates.`;
};

export function SegmentWarningNotice({ segment = {}, selected, start }) {
  const { createdAt = 0, updatedAt = 0 } = segment;

  const [dismissed, setDismissed] = useState();

  // Reset the dismiss state on re-rerender if any parameters change
  useEffect(() => {
    setDismissed(false);
  }, [createdAt, updatedAt, start]);

  // Since we replay back 7 days from a segment's last updated/created date,
  // offset the updated/created days appropriately
  const { created, updated } = useMemo(
    () => ({
      created: moment(createdAt).subtract(7, 'days'),
      updated: moment(updatedAt).subtract(7, 'days'),
    }),
    [createdAt, updatedAt]
  );

  // Check if the date requested includes points where segment changes have occurred
  const recent = useMemo(() => {
    const cutoff = moment(start);
    return created.isSameOrAfter(cutoff) || updated.isSameOrAfter(cutoff);
  }, [created, updated, start]);

  // Only show message if there is a segment, hasn't been dismissed, and is not
  // using a recently updated/created segment
  if (isEmpty(segment) || dismissed || !recent) {
    return null;
  }

  // Programmatically build the warning message
  const date = updated.format('MMM D, YYYY');
  const property = createdAt === updatedAt ? 'created' : 'updated';
  const message = buildMessage({ date, property, selected });

  return (
    <WarningWrapper>
      <WarningNotice onDismiss={() => setDismissed(true)}>
        {message} <ExternalLink href={HELP_ARTICLE}>Learn more</ExternalLink>
      </WarningNotice>
    </WarningWrapper>
  );
}

SegmentWarningNotice.propTypes = {
  // Unconnected props
  segmentId: PropTypes.string,
  selected: PropTypes.bool,

  // Connected props
  segment: PropTypes.shape({
    createdAt: PropTypes.number,
    updatedAt: PropTypes.number,
  }),
  start: PropTypes.number,
};

const mapStateToProps = (state, { segmentId }) => ({
  segment: selectAccountSegment(state, segmentId),
});

export default connect(mapStateToProps)(SegmentWarningNotice);
