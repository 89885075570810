import React from 'react';
import PropTypes from 'prop-types';
import { BeaconLink } from 'components/Common/HelpScout';

const MESSAGE = "I'd like to enable a premium integration.";

const IntegrationBeacon = ({ children, integration }) => {
  const subject = `Interested in ${
    integration ? `${integration} integration` : 'premium integrations'
  }`;

  return (
    <BeaconLink subject={subject} message={MESSAGE}>
      {children}
    </BeaconLink>
  );
};

IntegrationBeacon.propTypes = {
  children: PropTypes.node,
  integration: PropTypes.string,
};

export default IntegrationBeacon;
