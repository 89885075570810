import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Popover } from '@appcues/sonar';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { ExternalLink, Link } from '@studio/legacy-components';

function AnalyticsMetricGoalPopover({ flowId }) {
  return (
    <Popover.Root
      trigger={
        <Button iconOnly variant="tertiary">
          <Icon icon={faInfoCircle} />
        </Button>
      }
    >
      <Popover.Title>Goal</Popover.Title>
      <Popover.Description>
        <>
          Understand how this Flow is helping you achieve an{' '}
          <ExternalLink href="https://docs.appcues.com/en_US/analytics-studio/goals-overview">
            Appcues goal
          </ExternalLink>{' '}
          during the selected time period. Go to the Flow's{' '}
          <Link href={`/flows/${flowId}/settings`}>settings page</Link> to set a
          goal for this Flow.
        </>
      </Popover.Description>
      <Popover.Close>
        <Popover.IconClose />
      </Popover.Close>
    </Popover.Root>
  );
}

AnalyticsMetricGoalPopover.propTypes = {
  flowId: PropTypes.string.isRequired,
};

export default AnalyticsMetricGoalPopover;
