import React from 'react';
import { Box, Flex, Column, H2, Text } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';

const EmptyState = ({
  iconName,
  header,
  body,
  children,
  imageSrc,
  customVisualNode,
  className,
}) => (
  <Flex justifyContent="center" flexWrap="wrap" className={className}>
    <Column maxWidth={450}>
      <H2 marginTop={72}>{header}</H2>
      {body && <Text lineHeight="26px">{body}</Text>}
      {children}
    </Column>

    {iconName && (
      <Flex
        flexGrow={1}
        alignItems="center"
        justifyContent="center"
        fontSize={200}
        opacity={0.1}
      >
        <Icon icon={iconName} />
      </Flex>
    )}
    {imageSrc && (
      <Box minWidth={260} flexShrink={1} flexBasis={260} flexGrow={1}>
        <img alt="Empty state" src={imageSrc} />
      </Box>
    )}
    {customVisualNode && (
      <Flex flexGrow={1} alignItems="center" justifyContent="flex-end">
        {customVisualNode}
      </Flex>
    )}
  </Flex>
);

export default EmptyState;
