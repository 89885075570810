import { useMemo } from 'react';

import { MONTHLY_INTERVAL } from 'constants/plans';

const usePlanPricing = ({
  entitlementsProcessed,
  hasSubscription,
  plan,
  selectedEntitlements = {},
}) => {
  const planPricing = useMemo(() => {
    if (entitlementsProcessed) {
      /**
       * For all the entitlements we have selected, look at the unit price that lives at
       * entitlementsProcessed and aggregate the sum.
       */
      const entitlementsTotalPrice = Object.keys(selectedEntitlements).reduce(
        (acc, curr) =>
          acc +
          entitlementsProcessed[curr].unitPrice * selectedEntitlements[curr],
        0
      );

      const multiplier = plan.planInterval === MONTHLY_INTERVAL ? 1 : 12;
      // Set 0 for existing subscriptions because we do not support plan upgrades YET
      const planPrice = hasSubscription ? 0 : plan.price;
      const total = entitlementsTotalPrice * multiplier + planPrice;

      return total;
    }

    return plan.price;
  }, [selectedEntitlements, hasSubscription]);

  return planPricing;
};

export default usePlanPricing;
