import { loadStripe } from '@stripe/stripe-js';
import {
  PRICES,
  NPS_PRICES,
  BILLING_INTERVALS,
  PLAN_TO_TIER_MAPPINGS,
} from 'constants/plans';
import { isNpsOnlyPlan, normalizeBillingIntervalName } from 'helpers/plans';
import AppcuesIcon from 'assets/images/appcues-icon.png';
// eslint-disable-next-line no-restricted-imports
import authClient from 'helpers/auth-client';

/* global STRIPE_PUBLIC_KEY */
const stripePublicKey = `${STRIPE_PUBLIC_KEY}`;

async function request(path, payload) {
  const token = await authClient.getToken();

  /* global BILLING_API_URL */
  return fetch(`${BILLING_API_URL}${path}`, {
    method: 'post',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
}

function configureCheckout(tokenCallback) {
  return window.StripeCheckout.configure({
    key: stripePublicKey,
    image: AppcuesIcon,
    token: tokenCallback,
    allowRememberMe: false,
  });
}

function createChargeHandler(data, onFail, onSuccess) {
  const { email, stripeId, planId, accountId, userId } = data;

  return configureCheckout(async token => {
    const payload = {
      ...token,
      stripeId,
      planId,
      email,
      metadata: { accountId, userId },
    };

    // Subscribe to a plan.
    // The Stripe webhook handles adding the plan info to the account in Firebase.
    try {
      const response = await request('/subscriptions', payload);

      if (response.ok) {
        // Only do this stuff if there's a success payload returned from Stripe
        onSuccess();
        if (window.Appcues) {
          window.Appcues.show('-Kmgp8KgJDUG6umrrWxg');
        }
      } else {
        throw await response.json();
      }
    } catch {
      // Hardcoded error message for now, change once we have our new billing provider
      onFail('Your payment has failed.');
    }
  });
}

export function purchasePlan(plan, actor = {}, onFail, onSuccess) {
  const payload = {
    planId: plan.id,
    ...actor,
  };
  const handler = createChargeHandler(payload, onFail, onSuccess);

  handler.open({
    name: 'Appcues',
    description: `Sign up for Appcues ${plan.name}.`,
    billingAddress: true,
    panelLabel: 'Sign up',
    email: actor.email,
  });
}

export function createNpsPlanId(type, interval) {
  const billingInterval = BILLING_INTERVALS[interval];

  let intervalName = interval;
  if (billingInterval && billingInterval.npsAlias) {
    intervalName = billingInterval.npsAlias;
  }

  return [type, intervalName].join('-');
}

export function createPlanId(amount, type, interval) {
  if (type.includes('nps')) {
    return createNpsPlanId(type, interval);
  }

  return [amount, type, interval].join('-');
}

function getSettingsFromNpsPlanId(planId) {
  const planIdParts = planId.split('-');

  const type = `${planIdParts[0]}-${planIdParts[1]}`;

  let limit = planIdParts[2];
  limit = limit.replace('k', '000');

  let interval = planIdParts[3];
  interval = normalizeBillingIntervalName(interval);

  const priceForInterval = NPS_PRICES[interval];

  const amount = priceForInterval[limit][type];

  return { type, limit, interval, amount };
}

export function getSettingsFromPlanId(planId) {
  let limit;
  // eslint-disable-next-line prefer-const
  let [amount, type, interval] = planId.split('-');
  if (isNpsOnlyPlan(planId)) {
    return getSettingsFromNpsPlanId(planId);
  }

  if (typeof amount === 'number' && interval) {
    amount /= BILLING_INTERVALS[interval]
      ? BILLING_INTERVALS[interval].multiplier
      : 1;
  } else {
    interval = 'monthly';
  }

  const priceForInterval = PRICES[interval];
  // Look up the limit based on the plan type and price.
  if (priceForInterval) {
    limit = Object.keys(priceForInterval).find(key => {
      return priceForInterval[key][PLAN_TO_TIER_MAPPINGS[planId]] === amount;
    });
  }

  if (limit === null) {
    return {
      amount: 'custom',
      type: 'custom',
      interval: 'custom',
      limit: 'custom',
    };
  }
  return { amount, type, interval, limit };
}

export const getFilteredOptionsForTargetPlan = (defaultOptions, targetPlan) => {
  const targetPlanSettings = getSettingsFromPlanId(targetPlan.id);
  const targetLimit = targetPlanSettings.limit || 0;

  const greaterThanTargetLimit = option => {
    return (
      Number.parseInt(option.value, 10) >= Number.parseInt(targetLimit, 10) ||
      option.value === 'high-volume'
    );
  };

  return defaultOptions.filter(option => greaterThanTargetLimit(option));
};

export const setupStripe = () => {
  return loadStripe(`${stripePublicKey}`);
};

export const getPaymentMethod = async (
  card,
  formValues,
  stripe,
  user,
  billingAddress
) => {
  const { error, paymentMethod } = await stripe.createPaymentMethod({
    card,
    billing_details: {
      name: formValues.name,
      email: user.email,
      address: {
        city: billingAddress.city,
        country: billingAddress.country,
        line1: billingAddress.line1,
        postal_code: billingAddress.postalCode,
      },
    },
    type: 'card',
  });

  if (error) {
    throw error;
  }

  return paymentMethod;
};
