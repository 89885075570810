import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CDialog } from '@appcues/component-library';
import { PREMIUM_INTEGRATIONS_ENTITLEMENT } from 'constants/entitlements';
import { readOne as fetchAccountEntitlement } from 'actions/entitlements';
import { activateAndFlushIntegration } from 'actions/account/integrations';

export function ConfirmActivateIntegrationModal({ actions, integration }) {
  const handleClickActivateIntegration = () => {
    actions.activateAndFlushIntegration(integration.id);
    actions.hideModal();
    actions.onActivate();
  };

  return (
    <CDialog
      type="positive"
      title={`Activate ${integration.name} integration?`}
      onPrimaryAction={handleClickActivateIntegration}
      primaryActionText="Activate"
      onSecondaryAction={actions.hideModal}
    >
      Once activated, we&apos;ll start sending data to {integration.name}{' '}
      immediately.
    </CDialog>
  );
}

ConfirmActivateIntegrationModal.propTypes = {
  actions: PropTypes.shape({
    hideModal: PropTypes.func.isRequired,
  }).isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: {
    ...ownProps.actions,
    ...bindActionCreators(
      {
        activateAndFlushIntegration,
      },
      dispatch
    ),
    onActivate: () =>
      dispatch(fetchAccountEntitlement(PREMIUM_INTEGRATIONS_ENTITLEMENT)),
  },
});

export default connect(
  null,
  mapDispatchToProps
)(ConfirmActivateIntegrationModal);
