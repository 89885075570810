import React, { Component } from 'react';
import { Button } from '@appcues/component-library';
import FieldGroup from 'components/Common/Forms/FieldGroup';
import SatisfactionSurveyCard from 'components/SatisfactionSurveys/SatisfactionSurveyCard';
import InputNumber from 'components/Common/InputNumber';
import * as clauseTransforms from 'transforms/clauses';
import { millisecondsPerDay } from 'constants/account/conditions';
import { SATISFACTION_TARGETING_CATEGORY } from 'constants/satisfaction/data';

const DEFAULT_SAMPLING_FREQUENCY = 60;

class SatisfactionFrequencySettings extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { targetingOptionView } = this.props;
    const willFrequencyViewOpen =
      targetingOptionView !== SATISFACTION_TARGETING_CATEGORY.FREQUENCY &&
      nextProps.targetingOptionView ===
        SATISFACTION_TARGETING_CATEGORY.FREQUENCY;

    if (willFrequencyViewOpen) {
      window.scrollTo(0, 430);
    }
  }

  updateFrequencyClause = (value, clause) => {
    const {
      clauses,
      surveyFrequencyClause,
      surveyDelayClause,
      surveyDelayExcludeClause,
      flowFilteredClauses,
      updateSatisfaction,
      saveSatisfaction,
    } = this.props;

    let updatedClauses = clauseTransforms.updateClause(clauses, clause.id, {
      value,
    });

    // If we are changing the frequency, we need to
    // double check the Ask Me Later clauses. We also need to update
    // any flow targeting clauses to match the survey cadence.
    if (clause.property === surveyFrequencyClause.property) {
      // Ensure that the Ask Me Later clause is always
      // less than the frequency clause, and if not, change
      // Ask Me Later to one day less than frequency clause.
      let updatedSurveyDelayDays;
      if (value <= surveyDelayClause.value) {
        updatedSurveyDelayDays = value - millisecondsPerDay;
        updatedClauses = clauseTransforms.updateClause(
          updatedClauses,
          surveyDelayClause.id,
          {
            value: updatedSurveyDelayDays,
          }
        );
      }

      // Ensure that the Ask Me Later exclude clause is always
      // bigger than the primary Ask Me Later clause, and
      // if not, change the exclude clause to one day higher.
      updatedClauses = clauseTransforms.updateClause(
        updatedClauses,
        surveyDelayExcludeClause.id,
        {
          value:
            value > surveyDelayClause.value
              ? value
              : (updatedSurveyDelayDays &&
                  updatedSurveyDelayDays + millisecondsPerDay) ||
                surveyDelayClause.value + millisecondsPerDay,
        }
      );

      if (flowFilteredClauses.length > 0) {
        flowFilteredClauses.forEach(flowClause => {
          updatedClauses = clauseTransforms.updateClause(
            updatedClauses,
            flowClause.id,
            { value }
          );
        });
      }
    }

    updateSatisfaction({ clauses: updatedClauses });
    saveSatisfaction(updatedClauses);
  };

  render() {
    const {
      targetingOptionView,
      setTargetingOptionView,
      surveyFrequencyClause,
      sessionPageViewClause,
      surveyDelayClause,
    } = this.props;

    return (
      <FieldGroup className="satisfaction-form-block frequency-settings">
        <SatisfactionSurveyCard
          disableHover
          className="satisfaction-form-card"
          header="When should the survey appear:"
        >
          <div className="satisfaction-card-top">
            {targetingOptionView ===
            SATISFACTION_TARGETING_CATEGORY.FREQUENCY ? null : (
              <p>{`Show once every ${
                surveyFrequencyClause
                  ? surveyFrequencyClause.value / millisecondsPerDay
                  : DEFAULT_SAMPLING_FREQUENCY
              } days after ${
                sessionPageViewClause ? sessionPageViewClause.value : 4
              } pageviews, or after delaying for ${
                Number.parseInt(
                  surveyDelayClause && surveyDelayClause.value,
                  10
                ) / millisecondsPerDay
              } days from clicking "Ask me later"`}</p>
            )}
            <Button
              onClick={() =>
                setTargetingOptionView(
                  SATISFACTION_TARGETING_CATEGORY.FREQUENCY
                )
              }
              className="button-primary"
            >
              {targetingOptionView === SATISFACTION_TARGETING_CATEGORY.FREQUENCY
                ? 'Done'
                : 'Edit'}
            </Button>
          </div>
          {targetingOptionView ===
            SATISFACTION_TARGETING_CATEGORY.FREQUENCY && (
            <div className="satisfaction-form-card-bottom">
              <hr />
              <div className="satisfaction-card-setting survey-frequency">
                <p className="settings-label survey-frequency">
                  After a user answers this survey, how many days later should
                  they be able to see it again?
                </p>
                <InputNumber
                  minValue={15}
                  value={
                    Number.parseInt(surveyFrequencyClause.value, 10) /
                    millisecondsPerDay
                  }
                  onChange={days =>
                    this.updateFrequencyClause(
                      days * millisecondsPerDay,
                      surveyFrequencyClause
                    )
                  }
                />
              </div>

              <div className="satisfaction-card-setting session-pageviews">
                <p className="settings-label session-pageviews">
                  How many pages should a user visit in a session before they
                  see the survey?
                </p>
                <InputNumber
                  minValue={0}
                  value={Number.parseInt(sessionPageViewClause.value, 10)}
                  onChange={views =>
                    this.updateFrequencyClause(views, sessionPageViewClause)
                  }
                />
              </div>
              <div className="satisfaction-card-setting ask-me-later-setting">
                <p className="settings-label ask-me-later-text">
                  When a user clicks &ldquo;Ask Me Later&rdquo;, for how many
                  days should the survey be suppressed?
                </p>
                <InputNumber
                  minValue={1}
                  maxValue={
                    Number.parseInt(surveyFrequencyClause.value, 10) /
                      millisecondsPerDay -
                    1
                  }
                  value={
                    Number.parseInt(surveyDelayClause.value, 10) /
                    millisecondsPerDay
                  }
                  onChange={days =>
                    this.updateFrequencyClause(
                      days * millisecondsPerDay,
                      surveyDelayClause
                    )
                  }
                />
              </div>
            </div>
          )}
        </SatisfactionSurveyCard>
      </FieldGroup>
    );
  }
}

export default SatisfactionFrequencySettings;
