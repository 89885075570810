import React from 'react';
import styled from 'styled-components';
import { Form } from 'formik';
import {
  ButtonGroup,
  Label,
  Select,
  WarningNotice,
  Spinner,
  PageHeader,
} from '@studio/legacy-components';
import { Tabs } from '@appcues/sonar';

/**
 * CloneMobileFLow components
 */
export const CloneModalFlowForm = styled(Form)`
  margin-top: 8px;

  ${Label}:not(:first-child) {
    margin-top: 32px;
  }

  ${Select} {
    max-width: 100%;
  }

  ${ButtonGroup} {
    margin-top: 32px;
  }
`;

export const AppWarningNotice = styled(WarningNotice)`
  margin-top: 32px;

  > div {
    line-height: 21px;
  }
`;

export const FlowCardWrapper = styled.div``;

export const LoadingTabSpinner = styled.div.attrs({ children: <Spinner /> })`
  padding-top: var(--spacing-x-x-x-large);
`;

export const TabSection = styled.div`
  padding: var(--spacing-x-large);
  flex: 1;
`;

export const TabsListHeader = styled(Tabs.List)`
  position: sticky;
  background-color: var(--white);
  z-index: 10;
  top: 100px;
  padding-left: var(--spacing-x-large);

  @media (min-width: 1366px) {
    padding-left: var(--spacing-x-x-x-large);
  }
`;

export const TabsTriggerHeader = styled(Tabs.Trigger)``;

export const PageHeaderWithTabs = styled(PageHeader)`
  padding: var(--spacing-large) var(--spacing-x-large) var(--spacing-regular)
    var(--spacing-x-large);

  @media (min-width: 1366px) {
    padding: var(--spacing-large) var(--spacing-x-x-x-large)
      var(--spacing-regular) var(--spacing-x-x-x-large);
  }
`;
export const FullPageTabRoot = styled(Tabs.Root)`
  height: calc(100% - 100px);
  flex-direction: column;
`;

export const FullPageTabContent = styled(Tabs.Content)`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: ${({ vAlign }) => vAlign};
`;
