import React from 'react';
import { ExternalLink } from '@studio/legacy-components';
import GuideStep from 'components/InstallationGuide/GuideStep';
import GuideHeading from 'components/InstallationGuide/GuideHeading';
import GuideParagraph from 'components/InstallationGuide/GuideParagraph';
import CopyableCodeSnippet from 'components/Common/CopyableCodeSnippet';
import {
  PAGE_CHANGE_SNIPPETS,
  HTML,
  ANGULAR,
  REACT,
  EMBER,
  VUE,
} from 'constants/installation/snippets';
import { OrderedList, ListItem } from './InstallScript.styled';

const isSinglePageFramework = framework =>
  framework === ANGULAR ||
  framework === REACT ||
  framework === EMBER ||
  framework === VUE;

const InstallScript = props => {
  const {
    accountId,
    moveForward,
    moveBackward,
    stepMax,
    stepIndex,
    selectedInstallFramework,
  } = props;

  const isSinglePage = isSinglePageFramework(selectedInstallFramework);

  const pageChangeSnippet =
    PAGE_CHANGE_SNIPPETS[selectedInstallFramework || HTML];

  return (
    <GuideStep
      stepIndex={stepIndex}
      stepMax={stepMax}
      title="Add the Appcues script (SDK) and identify pages"
      moveForward={moveForward}
      moveBackward={moveBackward}
    >
      <GuideParagraph>
        The script allows Appcues to show content to your end users. Page
        identification notifies Appcues of page changes so we can show content
        on the correct pages.
      </GuideParagraph>
      <GuideHeading>Install the SDK</GuideHeading>
      <GuideParagraph>
        Copy the code below and paste it as the first item in your section on
        every page you want to use Appcues.
      </GuideParagraph>
      <CopyableCodeSnippet>
        {`<script type="text/javascript">
  window.AppcuesSettings = { enableURLDetection: true };
</script>
<script src="//fast.appcues.com/${accountId}.js"></script>`}
      </CopyableCodeSnippet>
      <GuideParagraph>
        The file name should be your Appcues account ID:{' '}
        <code className="language-markup">{accountId}</code>.
      </GuideParagraph>
      <GuideHeading>Identify Pages</GuideHeading>
      <GuideParagraph>
        There are two ways to notify Appcues when users navigate through pages,
        so Appcues can determine the correct content to show. Single-page
        Applications (SPAs) only trigger a full page load when they are first
        loaded. Otherwise, navigating across pages in your app doesn't trigger a
        page load.
      </GuideParagraph>
      <OrderedList>
        <ListItem>
          <GuideParagraph>
            Appcues automatically detects page changes (preferred and most
            common).
            <br />
            This is already enabled if you followed the directions above to set{' '}
            <code className="language-markup">enableURLDetection</code> to true.
            <br />
            If you would like to disable automatic url detection, set the{' '}
            <code className="language-markup">enableURLDetection</code> setting
            to false.
          </GuideParagraph>
        </ListItem>
        <ListItem>
          <GuideParagraph>
            Appcues can be manually notified of page changes via the{' '}
            <code className="language-markup">Appcues.page()</code> call.
            Detailed instructions are in our{' '}
            <ExternalLink href="https://docs.appcues.com/en_US/dev-installing-appcues/single-page-application-spa-guide-developer">
              help docs
            </ExternalLink>
            .
          </GuideParagraph>
        </ListItem>
      </OrderedList>
      {isSinglePage && (
        <CopyableCodeSnippet>{pageChangeSnippet}</CopyableCodeSnippet>
      )}
    </GuideStep>
  );
};

export default InstallScript;
