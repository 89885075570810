import React from 'react';

import { CButton, Text, CPage } from '@appcues/component-library';
import { ExternalLink } from '@studio/legacy-components';
import { HelpBar } from 'next/components/HelpBar';
import IndexPage from 'components/Common/IndexPage';
import EmptyState from 'components/Common/EmptyState';

const ChecklistEmptyState = ({ isLoading, createChecklist }) => (
  <IndexPage title="Checklists">
    <CPage.Container>
      <HelpBar section="checklists" />
      <EmptyState header="Create a Checklist" iconName="check-circle">
        <Text>
          Help your users get started with the most important tasks, encourage
          them along the way, and reward them at the end.
        </Text>
        <Text>
          To learn more, check out the{' '}
          <ExternalLink href="https://docs.appcues.com/category/345-checklist">
            documentation
          </ExternalLink>
          .
        </Text>
        <CButton
          isDisabled={isLoading}
          type="positive"
          onClick={createChecklist}
        >
          Create Checklist
        </CButton>
      </EmptyState>
    </CPage.Container>
  </IndexPage>
);

export default ChecklistEmptyState;
