import { useState, useEffect, useMemo } from 'react';

import { SUPPORTED_ENTITLEMENTS } from 'constants/entitlements';

/**
 * We use query params between the routes to keep the state. Once the user goes to another route
 * and comes back, this hook allows us to initialize the entitlements with the values in the query
 * URL.
 */
const useEntitlementsFromURL = location => {
  const [selectedEntitlements, setSelectedEntitlements] = useState({});

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const entitlements = {};

    SUPPORTED_ENTITLEMENTS.forEach(entitlement => {
      if (params.get(entitlement)) {
        entitlements[entitlement] = Number.parseInt(
          params.get(entitlement),
          10
        );
      } else {
        entitlements[entitlement] = 0;
      }
    });

    setSelectedEntitlements({ ...entitlements });
  }, [location]);

  const selectedEntitlementsCount = useMemo(
    () =>
      Object.values(selectedEntitlements).reduce((acc, curr) => acc + curr, 0),
    [selectedEntitlements]
  );

  return {
    selectedEntitlements,
    selectedEntitlementsCount,
    setSelectedEntitlements,
  };
};

export default useEntitlementsFromURL;
