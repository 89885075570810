import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  SubTitle,
  Text,
  Button,
  Icon,
  Row,
  Input,
  Label,
  ButtonGroup,
  FormField,
  InlineMessage,
  toast,
} from '@appcues/sonar';
import { Select } from '@studio/legacy-components';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { string } from 'yup';
import { history } from 'next/lib/history';
import {
  inviteTeamMember,
  selectCanInviteTeamMembers,
  update,
} from 'next/entities/account-users';
import { selectUser } from 'next/entities/user';

import {
  ADMINISTRATOR_OPTION,
  EDITOR_OPTION,
  PUBLISHER_OPTION,
} from 'constants/accountManagement/permissions';

import {
  Wrapper,
  UserIntentCard,
  Header,
  Actions,
  UserIntentCardWrapper,
} from './styled';

const Field = styled(FormField)`
  padding-bottom: var(--spacing-x-large);
  flex: 1;
`;

const options = [
  {
    ...ADMINISTRATOR_OPTION,
    description: ADMINISTRATOR_OPTION.permissionDescription,
  },
  {
    ...PUBLISHER_OPTION,
    description: PUBLISHER_OPTION.permissionDescription,
  },
  {
    ...EDITOR_OPTION,
    description: EDITOR_OPTION.permissionDescription,
  },
];

export function InviteTeamMembers({
  user,
  canInvite,
  onInvite,
  trackingInvitation,
}) {
  const [emailErrors, setEmailErrors] = useState({});
  const [sendingInvitations, setSendingInvitations] = useState(false);
  const [invitationFields, setInvitationFields] = useState([
    { id: 0, email: '', permission: options[0] },
  ]);

  const addInvitationField = () => {
    if (invitationFields.length >= 10) return;

    setInvitationFields(inputs => [
      ...inputs,
      { id: crypto.randomUUID(), email: '', permission: options[0] },
    ]);
  };

  const doValidation = index => {
    try {
      string().email().validateSync(invitationFields[index]?.email);
      setEmailErrors(s => ({ ...s, [index]: false }));
    } catch {
      setEmailErrors(s => ({ ...s, [index]: true }));
    }
  };

  const handleChange = (index, key, value) => {
    setInvitationFields(inputs => {
      const newInputs = [...inputs];
      newInputs[index][key] = value;
      return newInputs;
    });
  };

  const handleInvite = async () => {
    const hasError = Object.values(emailErrors).some(Boolean);

    if (hasError) return;

    setSendingInvitations(true);

    const members = invitationFields.filter(item => item.email);

    try {
      if (members.length > 0) {
        await Promise.all(
          members.map(async ({ email, permission }) =>
            onInvite({ email, role: permission.value })
          )
        );
        toast.success('Invitations sent successfully.');
      }

      trackingInvitation(user.id, { invitations: members.length });
      history.push('/');
    } catch (error) {
      toast.danger(error?.message ?? 'Something went wrong. Please try again.');
    }

    setSendingInvitations(false);
  };

  return (
    <Wrapper>
      <UserIntentCardWrapper>
        <UserIntentCard>
          <Header>
            <SubTitle>I want to invite:</SubTitle>
            <Text>
              We suggest inviting teammates from Product, Marketing, UX/Design,
              Engineering, and Customer Success.
            </Text>
          </Header>

          <Wrapper>
            {invitationFields.map((item, index) => (
              <Row key={item.id} spacing="large" xAlign="start" yAlign="center">
                <Field>
                  <Label>Email</Label>
                  <Input
                    disabled={!canInvite}
                    aria-label={`Email ${index + 1}`}
                    onBlur={() => doValidation(index)}
                    onChange={({ target: { value } }) => {
                      handleChange(index, 'email', value);
                    }}
                  />
                  {emailErrors[index] ? (
                    <InlineMessage variant="error">
                      Please enter a valid email address.
                    </InlineMessage>
                  ) : null}
                </Field>
                <Field>
                  <Label>Permissions</Label>
                  <Select
                    options={options}
                    isDisabled={!canInvite}
                    isSearchable={false}
                    menuPlacement="auto"
                    value={item.permission}
                    onChange={permission =>
                      handleChange(index, 'permission', permission)
                    }
                    portal
                  />
                </Field>
              </Row>
            ))}

            <Row wrap>
              <Button
                variant="tertiary"
                disabled={!canInvite}
                onClick={addInvitationField}
              >
                <Icon icon={faPlus} />
                Add another
              </Button>
            </Row>
          </Wrapper>

          <Actions>
            <ButtonGroup>
              <Button
                variant="tertiary"
                onClick={() => {
                  trackingInvitation(user.id, { invitations: 0 });
                  history.push('/');
                }}
              >
                Skip for now
              </Button>
              <Button loading={sendingInvitations} onClick={handleInvite}>
                Invite and start building
                <Icon icon="arrow-right" />
              </Button>
            </ButtonGroup>
            <Button
              variant="secondary"
              onClick={() => history.push('/welcome')}
            >
              <Icon icon="arrow-left" />
              Back
            </Button>
          </Actions>
        </UserIntentCard>
      </UserIntentCardWrapper>
    </Wrapper>
  );
}

InviteTeamMembers.propTypes = {
  user: PropTypes.shape({ id: PropTypes.string }),
  onInvite: PropTypes.func,
  trackingInvitation: PropTypes.func,
  canInvite: PropTypes.bool,
};

const mapDispatchToProps = {
  onInvite: inviteTeamMember,
  trackingInvitation: update,
};

const mapStateToProps = state => ({
  user: selectUser(state),
  canInvite: selectCanInviteTeamMembers(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteTeamMembers);
