import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AppsInstallation } from 'next/components/Apps';
import { NextStoreProvider } from 'next/components/Hacks';
import { selectIsInstalled } from 'reducers/account/installedDomains';

// For now, this component will server as a brige between
// old studio data and next installation
// This feeds the new InstallationPage with information about
// installed domains by connecting to old store
const NextInstallation = ({ installed }) => {
  return (
    <NextStoreProvider>
      <AppsInstallation installed={installed} />
    </NextStoreProvider>
  );
};

NextInstallation.propTypes = {
  installed: PropTypes.bool,
};

const mapStateToProps = state => ({
  installed: selectIsInstalled(state),
});

export default connect(mapStateToProps)(NextInstallation);
