import styled from 'styled-components';
import {
  DateRangePicker,
  Label,
  Overflow,
  Select,
} from '@studio/legacy-components';

export const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 20px;
`;

export const ControlColumn = styled.div.attrs(() => ({
  'data-no-print': true,
}))`
  margin-right: 16px;
`;

export const ChartWrapper = styled.div`
  margin: 20px 0;
`;

export const ExportForm = styled.form`
  ${DateRangePicker} {
    margin-bottom: 16px;
  }

  ${Label} {
    flex-grow: 1;
  }

  ${Overflow} {
    margin-bottom: 24px;
  }

  ${Select} {
    max-width: 100%;
  }
`;

export const WarningWrapper = styled.div`
  margin-bottom: 20px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
