import {
  LIGHT_THEME,
  DARK_THEME,
  DEFAULT_DARK_COLOR_HEX,
  DEFAULT_LIGHT_COLOR_HEX,
} from 'constants/satisfaction/data';

function isTheme(theme, themeType) {
  return theme && theme === themeType;
}

export function isDarkTheme(theme) {
  return isTheme(theme, DARK_THEME);
}

export function isLightTheme(theme) {
  return isTheme(theme, LIGHT_THEME);
}

export function getThemeFromBackgroundColor(backgroundColor) {
  switch (backgroundColor) {
    case DEFAULT_LIGHT_COLOR_HEX:
      return LIGHT_THEME;
    default:
      return DARK_THEME;
  }
}

export function getPrimaryColor(backgroundColor, foregroundColor) {
  const theme = getThemeFromBackgroundColor(backgroundColor);
  if (isLightTheme(theme)) {
    return foregroundColor;
  }

  return backgroundColor;
}

export function buildSurveyBackgroundColor(backgroundColor, foregroundColor) {
  if (!backgroundColor) {
    return DEFAULT_DARK_COLOR_HEX;
  }
  const theme = getThemeFromBackgroundColor(backgroundColor);

  if (isLightTheme(theme)) {
    return foregroundColor;
  }

  return backgroundColor;
}
