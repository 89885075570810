import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon } from '@studio/legacy-components';
import { selectApp, MOBILE_PLATFORM_ICONS } from 'next/entities/apps';

import { PlatformBadgeWrapper } from './styled';

export function PlatformBadge({ platform, name, loading }) {
  if (loading) return null;

  return (
    <PlatformBadgeWrapper>
      <Icon icon={MOBILE_PLATFORM_ICONS[platform]} />
      {name}
    </PlatformBadgeWrapper>
  );
}

PlatformBadge.propTypes = {
  platform: PropTypes.oneOf(Object.keys(MOBILE_PLATFORM_ICONS)),
  name: PropTypes.string,
  loading: PropTypes.bool,
};

const mapStateToProps = (state, { appId }) => {
  const app = appId ? selectApp(state, appId) : null;

  return {
    platform: app?.platform,
    name: app?.name,
    loading: !app,
  };
};

export default connect(mapStateToProps)(PlatformBadge);
