// Actions for account data that comes from firebase
export const FETCH_ACCOUNT_META = 'FETCH_ACCOUNT_META';
export const SET_ACCOUNT_META_FETCHING = 'SET_ACCOUNT_META_FETCHING';
export const SET_ACCOUNT_META_ERRORED = 'SET_ACCOUNT_META_ERRORED';
export const UPDATE_ACCOUNT_META = 'UPDATE_ACCOUNT_META';
export const UPDATE_ACCOUNT_META_SUCCESS = 'UPDATE_ACCOUNT_META_SUCCESS';
export const REPLACE_ACCOUNT_META = 'REPLACE_ACCOUNT_META';
// Actions for account data that comes from customer API
export const UPDATE_ACCOUNT_INFO = 'UPDATE_ACCOUNT_INFO';
export const UPDATE_SSO_SETTING = 'UPDATE_SSO_SETTING';
