import React from 'react';
import styled from 'styled-components';
import { ExternalLink } from '@studio/legacy-components';
import { SUPPORT_EMAIL } from 'constants/externalAppcuesResources';

const ChecklistPreviewError = () => {
  return (
    <ChecklistPreviewWrapper>
      <ChecklistErrorWrapper>
        <ErrorBox>
          <ErrorEmoji>🤔</ErrorEmoji>
          <ErrorHeader>Whoops...</ErrorHeader>
          <ChecklistErrorMessage>
            We ran into an error showing your checklist. Contact{' '}
            <ExternalLink href={`mailto:${SUPPORT_EMAIL}`}>
              support
            </ExternalLink>{' '}
            if this problem persists.
          </ChecklistErrorMessage>
        </ErrorBox>
      </ChecklistErrorWrapper>
    </ChecklistPreviewWrapper>
  );
};

const ChecklistPreviewWrapper = styled.div`
  height: 100%;
  background-color: ${props => props.theme['$gray-50']};
  border-radius: 4px;

  .sdk-script-container {
    border: none;
    width: 100%;
    height: 100%;
    min-width: 400px;
  }
`;

const ChecklistErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 400px;
`;

const ErrorBox = styled.div`
  border-radius: 3px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  width: 75%;
  background-color: white;
  padding: 2rem;
`;

const ErrorHeader = styled.h2`
  margin: 1rem auto !important;
`;

const ErrorEmoji = styled.p`
  font-size: 3rem;
`;

const ChecklistErrorMessage = styled.p``;
export default ChecklistPreviewError;
