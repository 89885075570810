export const USER_SYNCED = 'USER_SYNCED';
export const USER_REPLACED = 'USER_REPLACED';
export const USER_META_UPDATED = 'USER_META_UPDATED';
export const USER_USAGE_PROPERTY_UPDATED = 'USER_USAGE_PROPERTY_UPDATED';

export function syncUser(userId) {
  return {
    type: USER_SYNCED,
    payload: { userId },
  };
}

export function replaceUser(user) {
  return {
    type: USER_REPLACED,
    payload: user,
  };
}

export function updateUserMeta(changes) {
  return {
    type: USER_META_UPDATED,
    payload: { changes },
  };
}

export function updateUserUsageProperty(propertyName, propertyValue) {
  return {
    type: USER_USAGE_PROPERTY_UPDATED,
    payload: { propertyName, propertyValue },
  };
}

export const PASSWORD_RESET = 'PASSWORD_RESET';
export function resetPassword() {
  return {
    type: PASSWORD_RESET,
  };
}
