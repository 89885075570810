"use strict";'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var react = require('react');

var e={size:"regular"},t=react.createContext(e),x=t.Provider,i=()=>react.useContext(t)||e;

exports.a = x;
exports.b = i;
