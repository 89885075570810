import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import { INITIALIZE } from 'next/root/actions';

import { reject, resolve } from './actions';

function* fetchTrialStatus() {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getTrialStatus);
    yield put(resolve(response));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  yield takeLatest(INITIALIZE, fetchTrialStatus);
}
