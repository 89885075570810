import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Text, Flex } from '@appcues/component-library';

import { Link, Td } from '@studio/legacy-components';
import { selectExperience } from 'entities/experiences';

import ExpandableRow from 'components/Insights/Explorer/Common/ExpandableRow';
import BannerExpandedRow from 'components/Insights/Explorer/Common/BannerExpandedRow';
import {
  ExternalLinkIcon,
  SelectedIcon,
  LiveIcon,
  DraftIcon,
  ExpandedNameColumn,
} from 'components/Insights/Common/Styled';

export const sortEvents = events =>
  [...events].sort((a, b) => {
    if (a.eventType === 'appcues:v2:step_seen') return -1;
    if (a.eventType === 'appcues:v2:experience_dismissed') return 1;
    if (b.eventType === 'appcues:v2:experience_dismissed') return -1;

    return 0;
  });

export const BannerRow = ({
  id,
  name,
  published,
  banners,
  totalEvents,
  totalUsers,
  most_recent_timestamp,
  selectedEvents,
  enableSelect,
  isExpanded,
  onSelect,
  onClick,
  isDismissible,
}) => {
  // Prevent event bubbling up to the clickable accordion div
  const handleLinkClick = useCallback(e => {
    e.stopPropagation();
    return e;
  }, []);

  const rowChecked = (selectedEvents || []).some(
    ({ id: rowId }) => rowId === id
  );

  return (
    <ExpandableRow
      id={id}
      isExpanded={isExpanded}
      onClick={onClick}
      expandable={sortEvents(banners).map(
        ({
          stepId,
          eventType,
          events,
          users,
          most_recent_timestamp: timestamp,
        }) => {
          if (!isDismissible && eventType === 'appcues:v2:experience_dismissed')
            return null;

          const checked = (selectedEvents || []).some(
            ({
              id: selectedBannerId,
              event: selectedEvent,
              stepId: selectedStepId,
            }) =>
              selectedBannerId === id &&
              selectedEvent === eventType &&
              selectedStepId === stepId
          );

          return (
            <BannerExpandedRow
              key={stepId}
              id={id}
              source="banner"
              stepId={stepId}
              eventType={eventType}
              events={events}
              users={users}
              most_recent_timestamp={timestamp}
              parentName={name}
              enableSelect={enableSelect}
              checked={checked}
              onSelect={onSelect}
            />
          );
        }
      )}
    >
      <ExpandedNameColumn>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex maxWidth="80%">
            <Text is="div" truncate>
              {name}
            </Text>
            <div>
              {rowChecked && !isExpanded && (
                <SelectedIcon overpin="visible" title="checkmark" />
              )}
            </div>
          </Flex>

          <Link
            to={`/banners/${id}/analytics`}
            onClick={handleLinkClick}
            onKeyDown={handleLinkClick}
            target="_blank"
            rel="noopener noreferrer"
            title="Banner Analytics Page"
          >
            <ExternalLinkIcon />
          </Link>
        </Flex>
      </ExpandedNameColumn>
      <Td>
        {published ? (
          <>
            <LiveIcon /> <Text marginLeft="5px">Live</Text>
          </>
        ) : (
          <>
            <DraftIcon /> <Text marginLeft="5px">Draft</Text>
          </>
        )}
      </Td>
      <Td>{totalEvents ? totalEvents.toLocaleString() : '-'}</Td>
      <Td> {totalUsers ? totalUsers.toLocaleString() : '-'} </Td>
      <Td>
        {most_recent_timestamp
          ? moment(most_recent_timestamp).format('lll')
          : '-'}
      </Td>
    </ExpandableRow>
  );
};

BannerRow.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  onSelect: PropTypes.func,
  enableSelect: PropTypes.bool,
  name: PropTypes.string,
  totalUsers: PropTypes.number,
  totalEvents: PropTypes.number,
  most_recent_timestamp: PropTypes.string,
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      stepId: PropTypes.string,
      eventType: PropTypes.string,
      eventName: PropTypes.string,
      users: PropTypes.number,
      most_recent_timestamp: PropTypes.string,
    })
  ),

  id: PropTypes.string,
  published: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const experience = selectExperience(state, id) || {};
  const isDismissible = experience?.traits?.some(
    ({ type }) => type === '@appcues/dismissible'
  );

  return { isDismissible };
};

export default connect(mapStateToProps)(BannerRow);
