import React, { Component } from 'react';
import styled from 'styled-components';
import { CButton } from '@appcues/component-library';
import toast from 'next/lib/toast';
import { copyText } from 'utils/clipboard';
import { reportError } from 'helpers/error-reporting';

class CopyableCodeSnippet extends Component {
  copyCodeSnippet = () => {
    const { children } = this.props;

    if (this.codeElement) {
      copyText(this.codeElement);
      toast.success('Copied to clipboard');
    } else {
      toast.show('Please try again.');
      reportError('Snippet failed to copy', children);
    }
  };

  setCodeRef = element => {
    this.codeElement = element;
  };

  render() {
    const { children, language } = this.props;

    return (
      <SnippetWrapper>
        <code
          ref={this.setCodeRef}
          className={`language-${language || 'javascript'}`}
        >
          {children}
        </code>
        <CButton
          className="copy-button"
          type="info"
          onClick={this.copyCodeSnippet}
        >
          Copy
        </CButton>
      </SnippetWrapper>
    );
  }
}

const SnippetWrapper = styled.pre`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .copy-button {
    align-self: flex-start;

    opacity: 0;
    transition: opacity 0.1s linear;
  }

  &:hover {
    .copy-button {
      opacity: 1;
    }
  }
`;

export default CopyableCodeSnippet;
