// safely get percentage decimal for any
// numerator and denominator
export const getPercentage = (a, b) => (Number(a) && Number(b) ? a / b : 0);

// for use by goals & segments display, to fudge extreme percentages rather
// than rounding to zero/100
export const getPercentageStrRoundedEnds = decimal => {
  const decimalAsPercent = Math.floor(decimal * 100);

  if (decimalAsPercent > 95) {
    return {
      percentLabel: '> 95',
      wasRounded: true,
    };
  }
  if (decimalAsPercent < 5) {
    return {
      percentLabel: '< 5',
      wasRounded: true,
    };
  }

  return {
    percentLabel: decimalAsPercent.toString(),
    wasRounded: false,
  };
};
