import { createLifecycleFor } from 'actions/account/collections';

const TYPE = 'analyticsV2';

export const ANALYTICS_CACHE_CLEARED = 'ANALYTICS_CACHE_CLEARED';
export const clear = () => ({
  type: ANALYTICS_CACHE_CLEARED,
});

export const {
  callApi: getAnalytics,
  patterns,
  replace,
  send,
  append,
  reject,
  remove,
} = createLifecycleFor(TYPE);
