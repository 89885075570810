import { connect } from 'react-redux';
import SurveyResponses from 'next/components/SurveyResponses/SurveyResponses';
import { fetchSurveys, selectResponse } from 'entities/surveys';
import { asPromised } from 'utils/as-promised';
import { selectAccountMeta } from 'reducers/account/meta';

const mapStateToProps = (state, { flowId: id }) => ({
  surveys: selectResponse(state, id),
  currentIdentifier:
    selectAccountMeta(state)?.userProfileIdentifier || 'userId',
});
const mapDispatchToProps = (dispatch, { flowId: id, start, end }) => ({
  onLoad: (identifier = '') =>
    asPromised(dispatch, fetchSurveys(id, start, end, [identifier])),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyResponses);
