import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';

import { Input, ErrorMsg, Tooltip, Icon } from '@studio/legacy-components';

import CardNumber from 'components/SubscriptionPicker/CardNumber';
import CardExpiry from 'components/SubscriptionPicker/CardExpiry';
import CardCVC from 'components/SubscriptionPicker/CardCvc';
import { STRIPE_ERROR_MAP } from 'utils/billing';

import {
  CheckoutLabel,
  InformativeLabelText,
  StyledHeading,
  StyledPanel,
  TwoColumnWrapper,
} from './styled';

const ERRORS_INITIAL_STATE = {
  cardNumber: { isValid: false, message: '' },
  cardExpiry: { isValid: false, message: '' },
  cardCVC: { isValid: false, message: '' },
  name: { isValid: true, message: '' },
};

export const PaymentMethodForm = ({ dirty, formValues, onCompleteForm }) => {
  const [errors, setErrors] = useState(ERRORS_INITIAL_STATE);

  useEffect(() => {
    if (
      dirty &&
      errors.cardNumber.isValid &&
      errors.cardExpiry.isValid &&
      errors.cardCVC.isValid &&
      !isEmpty(formValues.name) &&
      errors.name.isValid
    ) {
      onCompleteForm({
        paymentMethod: true,
      });
    } else {
      onCompleteForm({
        paymentMethod: false,
      });
    }
  }, [dirty, errors]);

  return (
    <StyledPanel>
      <StyledHeading>Payment Method</StyledHeading>
      <CheckoutLabel>
        Card Number
        <CardNumber
          name="cardNumber"
          onChange={e => {
            setErrors(prevErrors => ({
              ...prevErrors,
              cardNumber: {
                isValid: e.complete && !e.error,
                message: STRIPE_ERROR_MAP[e.error?.code] ?? e.error?.message,
              },
            }));
          }}
        />
        {!errors.cardNumber.isValid && (
          <ErrorMsg>{errors.cardNumber.message}</ErrorMsg>
        )}
      </CheckoutLabel>

      <TwoColumnWrapper>
        <CheckoutLabel className="checkoutLabel">
          Expiration date
          <CardExpiry
            name="cardExpiry"
            onChange={e => {
              setErrors(prevErrors => ({
                ...prevErrors,
                cardExpiry: {
                  isValid: e.complete && !e.error,
                  message: STRIPE_ERROR_MAP[e.error?.code] ?? e.error?.message,
                },
              }));
            }}
          />
          {!errors.cardExpiry.isValid && (
            <ErrorMsg>{errors.cardExpiry.message}</ErrorMsg>
          )}
        </CheckoutLabel>

        <CheckoutLabel className="checkoutLabel">
          <InformativeLabelText>
            Security code{' '}
            <Tooltip
              size="m"
              wrapped
              label="The 3 or 4 digit security code on your credit card."
            >
              <Icon icon="info-circle" />
            </Tooltip>
          </InformativeLabelText>
          <CardCVC
            name="cardCVC"
            onChange={e => {
              setErrors(prevErrors => ({
                ...prevErrors,
                cardCVC: {
                  isValid: e.complete && !e.error,
                  message: STRIPE_ERROR_MAP[e.error?.code] ?? e.error?.message,
                },
              }));
            }}
          />
          {!errors.cardCVC.isValid && (
            <ErrorMsg>{errors.cardCVC.message}</ErrorMsg>
          )}
        </CheckoutLabel>
      </TwoColumnWrapper>

      <CheckoutLabel>
        Name on card
        <Field name="name">
          {({ field: { name, value }, form: { setFieldValue } }) => (
            <Input
              type="text"
              placeholder=""
              value={value}
              error={!errors.name.isValid}
              onChange={({ target: { value: inputValue } }) => {
                setFieldValue(name, inputValue);
                setErrors(prevErrors => ({
                  ...prevErrors,
                  name: {
                    isValid: !isEmpty(inputValue),
                    message: 'Name is Required',
                  },
                }));
              }}
            />
          )}
        </Field>
        {!errors.name.isValid && <ErrorMsg>{errors.name.message}</ErrorMsg>}
      </CheckoutLabel>
    </StyledPanel>
  );
};

PaymentMethodForm.propTypes = {
  dirty: PropTypes.bool,
  formValues: PropTypes.object,
  onCompleteForm: PropTypes.func,
};

export default PaymentMethodForm;
