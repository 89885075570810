import React from 'react';
import styled from 'styled-components';
import SelectFlow from 'components/Common/SelectFlow';
import ItemActionRedirect from 'components/ChecklistsEdit/ItemActionRedirect';
import InvalidFlowWarning from './InvalidFlowWarning';

const ActionRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 1rem 0 1rem 35px;
`;

const ActionLabel = styled.label`
  display: flex;
  align-items: center;
  flex: 0 1 20%;
  font-size: 16px;
`;

const SelectFlowWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  div.notice {
    margin: 0 0 0 1rem;
    padding: 0.6rem;
  }
`;

const FlowActionSection = props => {
  const {
    flowId,
    pageActionChecked,
    pageUrl,
    selectItemAction,
    updateChecklistItemPage,
    updateChecklistItemFlow,
    updateChecklistItemNewTab,
    redirectNewTab,
  } = props;

  return (
    <div>
      <ActionRow>
        <ActionLabel>
          <input
            className="checkbox"
            type="checkbox"
            checked={pageActionChecked}
            onChange={selectItemAction('redirect', true)}
          />
          Go to a page
        </ActionLabel>
        <ItemActionRedirect
          pageUrl={pageUrl}
          onUpdateChecklistItemPage={updateChecklistItemPage}
          onUpdateChecklistItemNewTab={updateChecklistItemNewTab}
          redirectNewTab={redirectNewTab}
        />
      </ActionRow>
      <ActionRow>
        <ActionLabel>Then, launch</ActionLabel>
        <SelectFlowWrapper>
          <SelectFlow
            value={flowId}
            className="checklist-item-input"
            onChange={updateChecklistItemFlow}
            showWarning
            shouldFilterArchived
          />
          <InvalidFlowWarning flowId={flowId} />
        </SelectFlowWrapper>
      </ActionRow>
    </div>
  );
};

export default FlowActionSection;
