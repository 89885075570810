import React from 'react';
import { Notice } from '@appcues/component-library';

const WarningBanner = ({ minItems, maxItems }) => {
  // # of checklist items < 5
  if (minItems) {
    return null;
  }
  // If # of checklist items 5 < n < 10
  if (!minItems && !maxItems) {
    return (
      <Notice type="warning">
        <b>Important tip: </b>
        we recommend a maximum of around 5 or 6 checklist items to provide the
        best experience for your users
      </Notice>
    );
  }
  return (
    <Notice type="error">
      <b>Hold up: </b>
      you&apos;ve reached the maximum of 10 checklist items
    </Notice>
  );
};

export default WarningBanner;
