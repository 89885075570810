import {
  put,
  all,
  call,
  getContext,
  takeEvery,
  select,
} from 'redux-saga/effects';
import toast from 'next/lib/toast';
import {
  send,
  resolve,
  reject,
  replace,
  flush,
  update,
  patterns,
  actionTypes,
  PRODUCT_LED_FLOW_CREATED,
  TAG_ADDED_TO_FLOW,
  TAG_REMOVED_FROM_FLOW,
} from 'actions/account/flows';
import { append as appendRules } from 'actions/account/rules';
import { replaceRule } from 'sagas/account/rule-conditions';
import { createFromTemplate } from 'sagas/transitory/flows';
import {
  CONTENT_REVERTED,
  CONTENT_PUBLISHED,
  CONTENT_UNPUBLISHED,
  CONTENT_PUSHED,
} from 'actions/publishing';
import {
  TRANSLATIONS_DOWNLOADED,
  TRANSLATIONS_REMOVED,
} from 'actions/account/translations';
import { selectLifecycleSegment } from 'reducers/account/segments';
import { PRODUCT_LED_FLOWS } from 'constants/productLedFlows';

import { promisaga } from 'utils/as-promised';
import { reportError } from 'helpers/error-reporting';
import { selectFlow } from 'reducers/account/flows';
import { selectAccountRulesSynced } from 'reducers/account/rules';

const CONTENT_ACTIONS = new Set([
  CONTENT_REVERTED,
  CONTENT_PUBLISHED,
  CONTENT_UNPUBLISHED,
  CONTENT_PUSHED,
]);

export function* fetchFlow(action) {
  try {
    const { id } = action.payload;
    const api = yield getContext('api');
    const {
      data: { flow, rule },
    } = yield call(api.getFlow, id);

    yield all([put(replace(flow)), call(replaceRule, rule)]);
  } catch (error) {
    yield call(reportError, error);
  }
}

export function* fetchFlows() {
  try {
    const api = yield getContext('api');
    yield put(send());
    const { data } = yield call(api.getAllFlows);

    const { flows, rules } = data
      .filter(({ flow: { id } }) => id)
      .reduce(
        (acc, { flow, rule }) => {
          acc.flows[flow.id] = flow;
          acc.rules[flow.id] = rule;
          return acc;
        },
        { flows: {}, rules: {} }
      );

    yield put(resolve(flows));

    // summary flows don't have conditions, so we don't need to
    // replaceAccountStepConditions or getFlattenedClausesFromNestedConditions!
    const accountRulesSynced = yield select(selectAccountRulesSynced);
    if (!accountRulesSynced) {
      yield put(appendRules(rules));
    }
  } catch (error) {
    yield put(reject(error));
    yield call(reportError, error);
  }
}

export function* flushFlow(action) {
  try {
    const { id, delta } = action.payload;
    const api = yield getContext('api');

    yield call(api.updateFlow, id, delta);
    yield put(flush(id));
    yield call(toast.success, 'Changes saved');
  } catch (error) {
    reportError(error);
  }
}

export function* cloneFlow(action) {
  const {
    payload: { step },
  } = action;
  yield put(replace(step));
}

/**
 * Finds the template stepGroups for the specified userLifecycleState
 * as well as the id of that stage's corresponding user segment, and
 * creates a new flow targeted to that segment and made up of those
 * steps.
 *
 * @param userLifecycleStage
 * @param previewUrl
 * @param name
 * @returns {Generator<any, *, ?>}
 */

export function* createProductLedFlow({
  payload: { userLifecycleStage, previewUrl, name },
}) {
  try {
    const { steps: stepGroups } = PRODUCT_LED_FLOWS.find(
      ({ userLifecycleStage: stage }) => stage === userLifecycleStage
    );

    const matchingLifecycleSegment = yield select(
      selectLifecycleSegment,
      userLifecycleStage
    );

    const {
      data: { flow, rule },
    } = yield call(createFromTemplate, {
      payload: {
        name,
        previewUrl,
        stepGroups,
        segmentId: matchingLifecycleSegment
          ? matchingLifecycleSegment.id
          : undefined,
      },
    });

    yield all([put(replace(flow)), call(replaceRule, rule)]);

    return flow.id;
  } catch (error) {
    yield call(reportError, error);
    throw error;
  }
}

export function* addTagToFlow({ payload: { flowId, tagId } }) {
  try {
    const { tagIds = [] } = yield select(selectFlow, flowId);
    if (!tagIds.includes(tagId)) {
      yield put(update(flowId, { tagIds: [...tagIds, tagId] }));
    }
  } catch (error) {
    reportError(error);
    yield call(toast.error, 'Failed to add tag to flow');
  }
}

export function* removeTagFromFlow({ payload: { flowId, tagId } }) {
  try {
    const { tagIds = [] } = yield select(selectFlow, flowId);
    if (tagIds.includes(tagId)) {
      yield put(update(flowId, { tagIds: tagIds.filter(id => id !== tagId) }));
    }
  } catch (error) {
    reportError(error);
    yield call(toast.error, 'Failed to remove tag from flow');
  }
}

export default function* flowsSaga() {
  yield takeEvery(patterns.update, flushFlow);
  yield takeEvery([Object.keys(actionTypes)], fetchFlows);
  yield takeEvery(
    action =>
      CONTENT_ACTIONS.has(action.type) && action.payload.type === 'journey',
    fetchFlow
  );
  yield takeEvery([TRANSLATIONS_DOWNLOADED, TRANSLATIONS_REMOVED], fetchFlow);
  yield takeEvery(PRODUCT_LED_FLOW_CREATED, promisaga(createProductLedFlow));
  yield takeEvery(TAG_ADDED_TO_FLOW, addTagToFlow);
  yield takeEvery(TAG_REMOVED_FROM_FLOW, removeTagFromFlow);
}
