import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H1, Flex, CPage } from '@appcues/component-library';
import Loader from 'components/Common/Loader';
import CreatedUpdatedHeader from 'components/Common/CreatedUpdatedHeader';
import { SummaryWrapper } from './styled';
import SummaryModal from './SummaryModal';

const Title = ({ title }) => <H1 title={title}>{title}</H1>;

// This is a temporary hack to make old page headers look like new one
const TitleBar = styled(CPage.TitleBar)`
  position: sticky;
  top: 0;
  // Need bigger zindex here because checklist preview
  z-index: 1000;
`;

const ChildPage = React.memo(props => {
  const {
    title,
    isLoading,
    subHeaderActions,
    breadcrumbContents,
    children,
    components,
    createdAt,
    createdByUserId,
    updatedAt,
    updatedByUserId,
    history,
  } = props;

  const TitleComponent = components.Title || Title;

  return (
    <>
      <TitleBar>
        <CPage.TitleBar.BreadcrumbLink href={breadcrumbContents.path}>
          {breadcrumbContents.label}
        </CPage.TitleBar.BreadcrumbLink>
        <Flex alignItems="center" width="100%">
          <TitleComponent title={title} />
          <CPage.TitleBar.Actions>{subHeaderActions}</CPage.TitleBar.Actions>
        </Flex>
        <SummaryWrapper>
          <CreatedUpdatedHeader
            createdAt={createdAt}
            updatedAt={updatedAt}
            createdByUserId={createdByUserId}
            updatedByUserId={updatedByUserId}
          />
          {history && history.length > 0 && <SummaryModal history={history} />}
        </SummaryWrapper>
      </TitleBar>
      {isLoading && <Loader />}
      {!isLoading && children}
    </>
  );
});

ChildPage.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  subHeaderActions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  breadcrumbContents: PropTypes.object,
  isLoading: PropTypes.bool,
  components: PropTypes.objectOf(PropTypes.elementType),
};

ChildPage.defaultProps = {
  components: {},
};

ChildPage.displayName = 'ChildPage';

export default ChildPage;
