/* global MOBILE_BUILDER_URL */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon, Link, ExternalLink, ListRow } from '@studio/legacy-components';
import { selectAccount } from 'next/entities/account';
import {
  archive as archiveFlow,
  clone as cloneFlow,
  remove as removeFlow,
  unarchive as unarchiveFlow,
} from 'next/entities/flows';
import {
  archive as archiveExperience,
  unarchive as unarchiveExperience,
  remove as removeExperience,
} from 'next/entities/experiences';
import { ActionsMenu } from 'next/components/Listing';

export function Actions({
  accountId,
  archived,
  experimentState,
  id,
  onArchive,
  onClone,
  onDelete,
  onOpenBuilder,
  onOpenEditTags,
  onToggleCloneModal,
  onUnarchive,
  type,
}) {
  const isMobile = type === 'mobile';
  const flowType = isMobile ? 'mobile/flows' : 'flows';
  const builderUrl = `${MOBILE_BUILDER_URL}/flows/${id}?account=${accountId}`;
  const hasActiveExperiment =
    Boolean(experimentState) &&
    ['NOT_STARTED', 'LIVE', 'PAUSED'].includes(experimentState.toUpperCase());

  if (archived) {
    return (
      <ActionsMenu>
        <ListRow prefix={<Icon icon="upload" />} onClick={onUnarchive}>
          Restore
        </ListRow>
        <ListRow prefix={<Icon icon="trash-alt" />} onClick={onDelete}>
          Delete
        </ListRow>
      </ActionsMenu>
    );
  }

  return (
    <ActionsMenu>
      {isMobile ? (
        <ListRow
          prefix={<Icon icon="edit" />}
          forwardedAs={ExternalLink}
          href={builderUrl}
          icon={false}
        >
          Open in Builder
        </ListRow>
      ) : (
        <ListRow prefix={<Icon icon="edit" />} onClick={onOpenBuilder}>
          Open in Builder
        </ListRow>
      )}
      <ListRow
        forwardedAs={Link}
        prefix={<Icon icon="chart-bar" />}
        to={`/${flowType}/${id}/analytics`}
      >
        Analytics
      </ListRow>
      <ListRow
        forwardedAs={Link}
        prefix={<Icon icon="cog" />}
        to={`/${flowType}/${id}/settings`}
      >
        Settings
      </ListRow>
      <ListRow prefix={<Icon icon="tag" />} onClick={onOpenEditTags}>
        Edit tags
      </ListRow>
      <ListRow
        prefix={<Icon icon="copy" />}
        onClick={isMobile ? onToggleCloneModal : onClone}
      >
        Clone
      </ListRow>
      {!hasActiveExperiment && (
        <ListRow prefix={<Icon icon="archive" />} onClick={onArchive}>
          Archive
        </ListRow>
      )}
    </ActionsMenu>
  );
}

Actions.propTypes = {
  accountId: PropTypes.string,
  archived: PropTypes.bool,
  experimentState: PropTypes.string,
  id: PropTypes.string,
  onArchive: PropTypes.func,
  onClone: PropTypes.func,
  onDelete: PropTypes.func,
  onOpenBuilder: PropTypes.func,
  onOpenEditTags: PropTypes.func,
  onToggleCloneModal: PropTypes.func,
  onUnarchive: PropTypes.func,
  type: PropTypes.oneOf(['mobile', 'flows']),
};

const mapStateToProps = state => ({
  accountId: selectAccount(state).id,
});

const mapDispatchToProps = (dispatch, { id, type }) => {
  return type === 'mobile'
    ? {
        onArchive: () => dispatch(archiveExperience(id)),
        onDelete: () => {
          dispatch(removeExperience(id));
        },
        onUnarchive: () => dispatch(unarchiveExperience(id)),
      }
    : {
        onArchive: () => dispatch(archiveFlow(id)),
        onClone: () => dispatch(cloneFlow(id)),
        onDelete: () => dispatch(removeFlow(id)),
        onUnarchive: () => dispatch(unarchiveFlow(id)),
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
