import {
  fork,
  takeEvery,
  put,
  call,
  getContext,
  takeLeading,
  take,
  select,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { history } from 'next/lib/history';
import { routes } from 'next/pages/Routes';
import { DISABLE_FULLSTORY } from 'next/entities/features';
import { NAVIGATE, CLEAR_ACCOUNT_DATA } from 'constants/actionTypes';
import { navigate as navWithProps } from 'actions/routing';
import { patterns as chartPatterns } from 'actions/account/charts';
import { patterns as segmentPatterns } from 'actions/account/segments';
import { SATISFACTION_CREATED } from 'constants/satisfaction/actionTypes';
import { patterns as goalPatterns } from 'actions/account/goals';
import { selectAccountFeature } from 'reducers/account/features';
import { syncAccountWithFirebase } from 'actions/bootstrap';

const nextRoutes = routes.map(route => route.path.replace(/:.+\//, '.+/'));

export function navigate(action) {
  const { path, openInNewTab } = action.payload;
  if (openInNewTab) {
    window.open(path, '_blank');
  } else {
    history.push(path);
  }
}

function* trackPageChange() {
  const auth = yield getContext('auth');
  const isSpoofing = yield call(auth.isSpoofing);

  const isFullStoryDisabled = yield select(
    selectAccountFeature,
    DISABLE_FULLSTORY
  );

  // If Backbone is defined, managing page() calls
  // will be taken care of by Backbone, so only call
  // analytics.page() when it isnt defined.
  if (!isSpoofing && !!window.analytics && !window.Backbone) {
    // We need to pass a category for this page view. This is used in the
    // index.html file to send the Viewed App Page event which we use in our
    // analytics.
    window.analytics.page('App', undefined, undefined, {
      integrations: { FullStory: !isFullStoryDisabled },
    });
  }
}

export function createPageChangeChannel() {
  return eventChannel(emit =>
    history.listen(nextRoute => {
      emit(nextRoute);
    })
  );
}

function* shouldSyncStore(nextRoute, prevRoute) {
  const isComingFromNext = nextRoutes.some(route => {
    const reg = new RegExp(route);
    return reg.test(prevRoute.pathname);
  });

  const auth = yield getContext('auth');
  const accountId = yield call(auth.getAccountId);
  if (isComingFromNext) {
    yield put(
      syncAccountWithFirebase({
        accountId,
      })
    );
  }
}

function* onPageChanges() {
  const channel = yield call(createPageChangeChannel);
  let prevRoute = {};
  while (true) {
    const nextRoute = yield take(channel);
    yield call(shouldSyncStore, nextRoute, prevRoute);
    yield call(trackPageChange);
    prevRoute = nextRoute;
  }
}

function navigateToDashboard() {
  history.push('/');
}

function navigateToChartPage({ payload }) {
  const { id } = payload;
  history.push(`/insights/charts/${id}`);
}

function navigateToNPSEdit({ payload }) {
  const { id } = payload;
  history.push(`/nps/${id}/edit`);
}

export function* navigateToSegmentPage() {
  const {
    payload: { id },
  } = yield take([segmentPatterns.insert, segmentPatterns.replace]);
  yield put(
    navWithProps({
      pathname: `/segments/${id}/view`,
      state: {
        segmentAlert: true,
      },
    })
  );
}

export function* navigateToGoalPage() {
  yield take(goalPatterns.insert);
  history.push(`/goals`);
}

export default function* routing() {
  yield fork(onPageChanges);
  yield fork(trackPageChange);
  yield takeEvery(NAVIGATE, navigate);
  yield takeEvery(CLEAR_ACCOUNT_DATA, navigateToDashboard);
  yield takeLeading(chartPatterns.insert, navigateToChartPage);
  yield takeLeading(
    [segmentPatterns.create, segmentPatterns.update],
    navigateToSegmentPage
  );
  yield takeLeading(SATISFACTION_CREATED, navigateToNPSEdit);
  yield takeLeading(goalPatterns.create, navigateToGoalPage);
}
