import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ContentStatus, MoreMenu } from '@studio/legacy-components';
import { asRequest } from 'next/entities/requests';
import { Shape as FlowShape } from 'next/entities/flows';
import { Shape as PinShape } from 'next/entities/experiences';
import { Shape as SegmentShape } from 'next/entities/segments';
import { Shape as TagShape } from 'next/entities/tags';
import { AppShape, MOBILE_PLATFORM_ICONS } from 'next/entities/apps';
import ExperimentBadge from 'next/components/ExperimentBadge';
import { renderable } from './lib';
import Datetime from './Datetime';
import PropertyTeaser from './PropertyTeaser';
import TagsTeaser from './TagsTeaser';
import {
  ActionButton,
  Actions,
  Name,
  Properties,
  Property,
  PropertyLoading,
  PropertySeparator,
  RowColumn,
  RowIcon,
  RowWrapper,
  Tags,
  PlatformIcon,
  PlatformLabel,
} from './styled';

/**
 * NOTE: Currently we only have two types of experiences so we can hard code the
 *       two analytics variations, but if we decide to add more experiences, we
 *       should consider exposing a prop to allow for generic rendering.
 */

export function Row({
  actions,
  domains,
  experience: {
    createdAt,
    experiment,
    id,
    name,
    published,
    publishedAt,
    state,
    updatedAt,
  },
  metric,
  segments,
  tags,
  to,
  type,
  app,
  settingsLink,
}) {
  const linkTo = type === 'mobile' ? 'mobile/flows' : type;
  const settingsPath = settingsLink ?? `/${linkTo}/${id}/settings`;

  return (
    <RowWrapper>
      <RowColumn>
        <Name as={Link} title={name} to={to}>
          {name}
        </Name>

        <Properties secondary>
          <Datetime
            prefix={
              !updatedAt || createdAt === updatedAt ? 'Created ' : 'Updated '
            }
            value={updatedAt || createdAt}
          />

          {published && publishedAt && (
            <>
              <PropertySeparator />
              <Datetime prefix="Published" value={publishedAt} />
            </>
          )}

          {app && (
            <>
              <PropertySeparator />
              <PlatformIcon icon={MOBILE_PLATFORM_ICONS[app.platform]} />
              <PlatformLabel title={app.name}>{app.name}</PlatformLabel>
            </>
          )}

          {type !== 'mobile' && renderable(domains, 0) && (
            <>
              <PropertySeparator />

              {domains.loading && (
                <PropertyLoading aria-label="Loading domains" />
              )}

              <PlatformIcon icon="desktop" />

              {!domains.loading &&
                (domains.data.length > 0 ? (
                  <PropertyTeaser limit={3} values={domains.data} />
                ) : (
                  <Property>All domains</Property>
                ))}
            </>
          )}
        </Properties>

        <Tags>
          {renderable(tags) && (
            <>
              {tags.loading && (
                <PropertyLoading aria-label="Loading tags" tag />
              )}

              {/* TODO: Add click handlers to tag management */}
              {!tags.loading && <TagsTeaser limit={3} tags={tags.data} />}
            </>
          )}
        </Tags>
      </RowColumn>

      <RowColumn>
        <Actions>
          <ContentStatus published={published} state={state} />
          <ExperimentBadge
            experimentId={experiment?.id}
            experimentName={experiment?.name}
            experimentState={experiment?.state}
            flowState={state}
          />
          {published && (
            <ActionButton
              aria-label="Settings"
              forwardedAs={Link}
              icon="cog"
              to={settingsPath}
            />
          )}

          {actions && <MoreMenu small>{actions}</MoreMenu>}
        </Actions>

        {/* TODO: Add real analytics and loader */}
        <Properties>{metric}</Properties>

        {renderable(segments, 0) && (
          <Properties>
            {segments.loading && (
              <PropertyLoading aria-label="Loading segments" />
            )}

            {!segments.loading &&
              (segments.data.length > 0 ? (
                <PropertyTeaser
                  values={segments.data.map(segment => segment.name)}
                />
              ) : (
                <Property>All users</Property>
              ))}

            <RowIcon icon="users" />
          </Properties>
        )}
      </RowColumn>
    </RowWrapper>
  );
}

Row.propTypes = {
  actions: PropTypes.node,
  app: AppShape,
  domains: asRequest(PropTypes.arrayOf(PropTypes.string)),
  experience: PropTypes.oneOfType([FlowShape, PinShape]),
  metric: PropTypes.node,
  segments: asRequest(PropTypes.arrayOf(SegmentShape)),
  tags: asRequest(PropTypes.arrayOf(TagShape)),
  to: PropTypes.string,
  type: PropTypes.oneOf(['flows', 'pins', 'mobile', 'banner', 'launchpad']),
  settingsLink: PropTypes.string,
};

export default Row;
