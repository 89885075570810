import { useDateRange } from '@studio/legacy-components';
import { parse, format } from 'next/lib/date';
import useQueryString from './use-query-string';

// Query string date format e.g. 01-07-2021
export const QUERY_FORMAT = 'MM-DD-YYYY';

/**
 * Hook for managing date ranges with query string support
 *
 * @param {Option[]} options - Valid range options
 * @param {DateRange} initial - Iniital date range values
 * @param {function} calculate - Range calculator override
 * @return {[DateRange, function]} Calculated date range values and state setter
 */
const useDateRangeQuery = (options = [], initial = {}, calculate) => {
  // Range query string hook
  const [range, setRange] = useQueryString('range', initial.range, {
    validate: value => options.find(option => option.value === value),
    parse: value => Number.parseInt(value, 10) || value,
  });

  // Whether the current range type is custom
  const custom = range === 'custom';

  // Shared query string hooks for start and end
  const hooks = {
    validate: value => Boolean(parse(value)),
    serialize: value => (custom ? format(value, QUERY_FORMAT) : null),
    parse: value => parse(value),
  };

  // Start and end query string hooks
  const [start, setStart] = useQueryString('start', initial.start, hooks);
  const [end, setEnd] = useQueryString('end', initial.end, hooks);

  const [dates, setDateRange] = useDateRange(
    options,
    { range, start, end },
    calculate
  );

  // Normalize and calculate date range based on range type
  // const dates = !custom ? calculateDatesFor(range) : { range, start, end };

  // State updater
  const setState = next => {
    setDateRange(next);
    setRange(next.range);
    setStart(next.start);
    setEnd(next.end);
  };

  return [dates, setState];
};

export default useDateRangeQuery;
