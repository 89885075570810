import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Separator } from '@radix-ui/react-separator';
import {
  RadioGroup,
  RadioGroupItem,
  RadioGroupIndicator,
} from '@radix-ui/react-radio-group';
import { Icon } from '@studio/legacy-components';

const option = css`
  align-items: center;
  background: none;
  border: none;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;

  > ${Icon} {
    color: inherit;
    font-size: var(--medium-large);
  }
`;

const Group = styled(RadioGroup)`
  align-items: center;
  display: flex;
`;

const Item = styled(RadioGroupItem)`
  ${option}

  color: var(--icon);

  /* Hide the immediate icon so only the selected icon renders */
  &[data-state='checked'] > ${Icon} {
    display: none;
  }
`;

const Indicator = styled(RadioGroupIndicator)`
  ${option}

  color: var(--blurple);
`;

const Divider = styled(Separator)`
  background-color: var(--dirty-water);
  height: 24px;
  margin: 0 8px;
  width: 1px;
`;

export default function ViewToggle({ onChange, value }) {
  return (
    <Group
      aria-label="View toggle"
      orientation="horizontal"
      onValueChange={onChange}
      value={value}
    >
      <Item aria-label="List view" value="list">
        <Icon icon="th-list" />

        <Indicator>
          <Icon icon="th-list" />
        </Indicator>
      </Item>

      <Divider decorative orientation="vertical" />

      <Item aria-label="Grid view" value="grid">
        <Icon icon="th" />

        <Indicator>
          <Icon icon="th" />
        </Indicator>
      </Item>
    </Group>
  );
}

ViewToggle.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOf(['grid', 'list']),
};
