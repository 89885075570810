import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Select } from '@studio/legacy-components';

const TWEMOJI_SVG_URL = 'https://twemoji.maxcdn.com/2/svg';
const EMOJI_CODES = ['1f389', '1f44d', '1f38a', '1f44f', '1f4af'];

const Wrapper = styled.div`
  ${Select} {
    max-width: 72px;
    min-width: 72px;
  }
`;

const EmojiWrapper = styled.img`
  height: 28px;
  padding-top: 4px;
  width: auto;
`;

export default function EmojiSelect({
  defaultImage,
  disabled,
  onChange,
  onFocus,
  successColor,
  value,
}) {
  const options = useMemo(() => {
    return [
      // option for no icon
      {
        label: <EmojiWrapper />,
        value: 'none',
      },
      // default emoji
      {
        label: defaultImage({
          width: '28px',
          height: '28px',
          color: successColor,
        }),
        value: 'default',
      },
      ...EMOJI_CODES.map(code => ({
        label: <EmojiWrapper src={`${TWEMOJI_SVG_URL}/${code}.svg`} />,
        value: `${code}`,
      })),
    ];
  }, [defaultImage, successColor]);

  const selected = options.find(option => option.value === value);

  return (
    <Wrapper>
      <Select
        disabled={disabled}
        onChange={onChange}
        onFocus={onFocus}
        options={options}
        placeholder="Choose an emoji..."
        value={selected}
      />
    </Wrapper>
  );
}

EmojiSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  defaultImage: PropTypes.func,
  successColor: PropTypes.string,
};
