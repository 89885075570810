import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';

/**
 * add/change/remove params in an immutable way
 *
 * @param {object} params - parsed query params
 * @param {string} key - key to add to URL
 * @param {string|object} value - value to set in the URL
 */
const updateParams = (params, key, value) => {
  if (value === null) {
    const { [key]: omit, ...rest } = params;
    return rest;
  }
  return { ...params, [key]: value };
};

/**
 * queryString to object
 */
const getParams = str =>
  qs.parse(str, {
    ignoreQueryPrefix: true,
  });

/**
 * object to queryString
 */
const setParams = obj =>
  qs.stringify(obj, {
    arrayFormat: 'indices',
    encodeValuesOnly: true,
  });

/**
 * Updates query string for specific key only
 *
 * @params {string} key - specific key of param we want to update
 * @params {any} defaultValue - value to use in STATE when value from URL is empty or invalid
 * @params {object}
 *   @option {function} validate - validation callback; called when page first loads to validate inputs from URL.
 *   @option {function} serialize - transform state into URL representation
 *   @option {function} parse - transform URL representation back into real data
 *
 * @return {Array<any, function>} - {any} value in state; {function} setValue to set state
 */
export default function useQueryString(key, defaultValue, opts = {}) {
  const { validate = () => true, parse, serialize } = opts;

  const history = useHistory();
  const { [key]: rawValue } = getParams(history.location.search);

  const initialValue =
    typeof parse === 'function' ? parse(rawValue || defaultValue) : rawValue;

  const isValid = !!(initialValue && validate(initialValue));

  const [value, setValue] = useState(isValid ? initialValue : defaultValue);

  useEffect(() => {
    const params = getParams(history.location.search);
    const current = params[key] || null;
    const next = typeof serialize === 'function' ? serialize(value) : value;

    if (current !== next) {
      const result = updateParams(params, key, next);
      history.replace(`?${setParams(result)}`);
    }
  }, [history, key, serialize, value]);

  return [value, setValue];
}
