import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { faBugSlash } from '@fortawesome/pro-solid-svg-icons/faBugSlash';
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit';
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons/faFileAlt';
import {
  MoreMenu,
  MenuSection,
  ListRow,
  ExternalLink,
} from '@studio/legacy-components';
import { Icon as SonarIcon } from '@appcues/sonar';
import { navigate } from 'next/lib/history';
import { AppShape } from 'next/entities/apps';
import { APP_ENABLED, getPlatformData } from './lib';

const Icon = styled(SonarIcon)`
  width: 24px;
`;

const AppMenu = ({ app, hasPushEnabled, onOptionClick }) => {
  const { id, platform, state } = app;
  const { sdkUrl } = getPlatformData(platform);

  const handleEdit = () => onOptionClick('edit');
  const handleDisable = () => onOptionClick('disable');
  const handleEnable = () => onOptionClick('enable');
  const handleDebugger = () => onOptionClick('debugger');

  return (
    <MoreMenu>
      <MenuSection>
        {hasPushEnabled ? (
          <ListRow
            prefix={<Icon icon="cog" />}
            onClick={() => navigate(`/apps/${id}`)}
          >
            App settings
          </ListRow>
        ) : (
          <ListRow prefix={<Icon icon={faEdit} />} onClick={handleEdit}>
            Edit name
          </ListRow>
        )}

        <ListRow prefix={<Icon icon={faFileAlt} />}>
          <ExternalLink href={sdkUrl}>Go to developer docs</ExternalLink>
        </ListRow>

        <ListRow prefix={<Icon icon={faBugSlash} />} onClick={handleDebugger}>
          Launch debugger
        </ListRow>

        {state === APP_ENABLED ? (
          <ListRow
            onClick={handleDisable}
            prefix={<Icon icon="minus-circle" />}
          >
            Disable
          </ListRow>
        ) : (
          <ListRow onClick={handleEnable} prefix={<Icon icon="check-circle" />}>
            Enable
          </ListRow>
        )}
      </MenuSection>
    </MoreMenu>
  );
};

AppMenu.propTypes = {
  app: AppShape,
  hasPushEnabled: PropTypes.bool,
  onOptionClick: PropTypes.func,
};

export default AppMenu;
