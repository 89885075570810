import { takeEvery, call, put, getContext } from 'redux-saga/effects';
import { replacePagePatternFor } from 'next/entities/page';
import { resolve, reject, patterns } from './actions';

export function* fetchEventNameLabels() {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getEventNameLabels);

    yield put(resolve(response));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  // Internal actions
  yield takeEvery(patterns.read, fetchEventNameLabels);

  // Page actions
  yield takeEvery(
    [
      replacePagePatternFor('/pins/:pinId/settings'),
      replacePagePatternFor('/banners/:experienceId/settings'),
      replacePagePatternFor('/mobile/flows/:experienceId/settings'),
      replacePagePatternFor('/flows/:flowId/settings'),
      replacePagePatternFor('/launchpads/:experienceId/settings'),
    ],
    fetchEventNameLabels
  );
}
