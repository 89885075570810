import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CButton } from '@appcues/component-library';

import { IconWithinCButton } from '@studio/legacy-components';
import CoolTip from 'components/Common/CoolTip';
import DownloadManagerModal from 'components/StepSettings/Localization/DownloadManagerModal';
import { selectFlow } from 'reducers/account/flows';
import { requestTranslations } from 'actions/account/translations';

export const DownloadManager = ({ flowId, disabled, onSubmit }) => {
  const [visible, setVisible] = useState(false);

  const handleClose = () => {
    setVisible(false);
  };

  const handleOpen = () => {
    setVisible(true);
  };

  const handleSubmit = ({ format }) => {
    onSubmit({ flowId, format });
    setVisible(false);
  };
  return (
    <>
      {visible && (
        <DownloadManagerModal
          onClose={handleClose}
          flowId={flowId}
          onSubmit={handleSubmit}
        />
      )}
      <CoolTip
        tip="Unpublish your flow to download content."
        disabled={!disabled}
      >
        <CButton
          disabled={disabled}
          onClick={handleOpen}
          className="download-button"
        >
          <IconWithinCButton icon="download" gray />
          Download content
        </CButton>
      </CoolTip>
    </>
  );
};

DownloadManager.propTypes = {
  flowId: PropTypes.string,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
};

DownloadManager.defaultProps = {
  disabled: false,
};

const mapStateToProps = (state, { flowId }) => ({
  disabled: selectFlow(state, flowId).published,
});
const mapDispatchToProps = {
  onSubmit: requestTranslations,
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadManager);
