import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  ChecklistsShape,
  ConditionsShape,
  ExperienceShape,
  SegmentsShape,
  ReadableConditions,
} from '@studio/conditions';
import { Shape as PinShape } from 'next/entities/experiences';
import { selectFlows } from 'reducers/account/flows';
import { selectAccountSegments } from 'reducers/account/segments';
import {
  selectBanners,
  selectExperiences,
  selectMobileFlows,
  selectPins,
} from 'entities/experiences';
import { selectAccountChecklists } from 'entities/checklists';
import { selectAccountProfileAttributes } from 'selectors/profile-attributes';

export function ReadableConditionsProvider({
  conditions,
  checklists,
  flows,
  segments,
  pins,
  experiences,
  properties,
  mobiles,
  banners,
}) {
  return (
    <ReadableConditions
      conditions={conditions}
      checklists={checklists}
      flows={flows}
      segments={segments}
      pins={pins}
      experiences={experiences}
      properties={properties}
      mobiles={mobiles}
      banners={banners}
    />
  );
}

ReadableConditionsProvider.propTypes = {
  conditions: ConditionsShape,
  checklists: ChecklistsShape,
  flows: PropTypes.objectOf(PropTypes.shape({ name: PropTypes.string })),
  segments: SegmentsShape,
  pins: PropTypes.objectOf(PinShape),
  experiences: PropTypes.objectOf(ExperienceShape),
  banners: PropTypes.objectOf(ExperienceShape),
  mobiles: PropTypes.objectOf(ExperienceShape),
  properties: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      id: PropTypes.string,
    })
  ),
};

const mapStateToProps = state => {
  return {
    checklists: selectAccountChecklists(state),
    flows: selectFlows(state),
    segments: selectAccountSegments(state),
    pins: selectPins(state),
    experiences: selectExperiences(state),
    mobiles: selectMobileFlows(state),
    properties: selectAccountProfileAttributes(state),
    banners: selectBanners(state),
  };
};

export default connect(mapStateToProps)(ReadableConditionsProvider);
