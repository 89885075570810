import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'qs';
import { CPage, CSkeleton } from '@appcues/component-library';
import { Button, ContentStatus, Icon } from '@studio/legacy-components';
import { format, fromNow } from 'next/lib/date';
import { Shape as ExperienceShape } from 'next/entities/experiences';
import useTitle from 'next/hooks/use-title';
import { selectExperience } from 'entities/experiences';
import { selectAccountUserFullName } from 'reducers/account/users';
import IndexPage from 'components/Common/IndexPage';

import { MOBILE_EVENTS } from 'components/Insights/constants';
import { decodeEventNameUrl } from 'components/Insights/utils';
import { ExportManager } from 'components/Insights/Common/ExportManager';
import SingleMetricContent from './SingleMetricContent';
import {
  HeaderWrapper,
  HeaderTitle,
  SubHeaderColumn,
  StyledLink,
} from './styled';

export const MobileMetric = ({
  encodedEventName,
  id,
  experience,
  createdByUserName,
  updatedByUserName,
}) => {
  const eventName = decodeEventNameUrl('mobile', encodedEventName);
  const { label = '' } =
    MOBILE_EVENTS.find(item => item.type === eventName) || {};
  const title = `${experience?.name}: ${label}`;
  useTitle(`${title} | Events Explorer | Appcues`);

  return (
    <IndexPage
      title={
        !experience ? (
          <CSkeleton height={20} width={400} />
        ) : (
          <HeaderWrapper>
            <HeaderTitle>{title}</HeaderTitle>
            <ContentStatus
              published={experience.published}
              state={experience.state}
            />
          </HeaderWrapper>
        )
      }
      description={
        !experience ? (
          <CSkeleton height={18} width={400} />
        ) : (
          <HeaderWrapper>
            <SubHeaderColumn>
              Flow created {createdByUserName ? `by ${createdByUserName} ` : ''}
              {experience.createdAt && format(experience.createdAt, 'll')}
            </SubHeaderColumn>
            <SubHeaderColumn>
              {updatedByUserName || experience?.updatedAt ? 'Updated ' : ''}
              {updatedByUserName ? `by ${updatedByUserName} ` : ''}
              {experience?.updatedAt && fromNow(experience.updatedAt)}
            </SubHeaderColumn>
          </HeaderWrapper>
        )
      }
      subHeaderActions={
        <>
          <ExportManager
            events={[{ source: 'mobile', event: eventName, id }]}
          />
          <StyledLink to={`/mobile/flows/${id}/analytics`} target="_blank">
            <Button kind="secondary">
              <Icon icon="external-link-alt" />
              Flow details
            </Button>
          </StyledLink>
        </>
      }
    >
      <CPage.Container>
        <SingleMetricContent eventName={eventName} id={id} source="mobile" />
      </CPage.Container>
    </IndexPage>
  );
};

MobileMetric.propTypes = {
  experience: PropTypes.shape(ExperienceShape),
  encodedEventName: PropTypes.string,
  id: PropTypes.string,
  createdByUserName: PropTypes.string,
  updatedByUserName: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { encodedEventName },
    },
    location: { search },
  } = ownProps;

  const { id } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const experience = selectExperience(state, id);

  return {
    experience,
    id,
    encodedEventName,
    createdByUserName: selectAccountUserFullName(state, experience?.createdBy),
    updatedByUserName: selectAccountUserFullName(state, experience?.updatedBy),
  };
};

const ConnectedMobileMetric = connect(mapStateToProps)(MobileMetric);

ConnectedMobileMetric.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      encodedEventName: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default ConnectedMobileMetric;
