import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Panel, H3, Paragraph } from '@studio/legacy-components';

import EmptyNPSImage from 'assets/images/empty-nps.svg';

const Wrapper = styled(Panel)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 60px;
`;

const LeftColumn = styled.div`
  ${H3} {
    margin-bottom: 24px;
  }

  ${Paragraph} {
    color: var(--banner-text);
    margin-bottom: 0;
  }

  ${Button} {
    margin-top: 40px;
  }
`;

const Image = styled.img`
  margin-right: 48px;
`;

const EmptyState = ({ onCreate }) => {
  const [isSaving, setIsSaving] = useState(false);

  const handleCreate = () => {
    setIsSaving(true);
    onCreate();
  };

  return (
    <Wrapper>
      <LeftColumn>
        <H3>NPS analytics</H3>
        <Paragraph>
          Create your NPS Survey to collect feedback from your users. <br />
        </Paragraph>
        <Paragraph>Track your NPS score over time and more.</Paragraph>
        <Button disabled={isSaving} kind="positive" onClick={handleCreate}>
          Create survey
        </Button>
      </LeftColumn>
      <Image src={EmptyNPSImage} alt="No NPS configured" />
    </Wrapper>
  );
};

EmptyState.propTypes = {
  onCreate: PropTypes.func,
};

export default EmptyState;
