import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import ThreeDotsLoader from 'components/Common/ThreeDotsLoader';

export function ImageUploader({
  accountId,
  children,
  disableClick,
  dropZoneOverrideStyle,
  onUpload,
  preventDropOnDocument,
  protocol,
  userId,
}) {
  const [isUploading, setIsUploading] = useState(false);

  const uploadImageFile = file => {
    const imgName = file.name;

    const data = new FormData();
    data.append('file', file);
    /* global CLOUDINARY_API_KEY */
    data.append('api_key', CLOUDINARY_API_KEY);
    data.append('timestamp', Math.floor(Date.now() / 1000));
    data.append('tags', [`account-${accountId}`, `user-${userId}`].join(','));
    data.append('folder', accountId);

    // Transforming GIFs can have unintended consequences for Cloudinary,
    // like causing the GIF to become too large if it's scaled up, or dropping
    // frames if a transformation doesn't support the full resolution.
    /* global CLOUDINARY_UPLOAD_PRESET */
    const uploadPreset =
      file.type === 'image/gif' ? 'gif_preset' : CLOUDINARY_UPLOAD_PRESET;
    data.append('upload_preset', uploadPreset);

    /* global CLOUDINARY_UPLOAD_URL */
    fetch(CLOUDINARY_UPLOAD_URL, {
      method: 'POST',
      body: data,
    })
      .then(response => {
        return response.json();
      })
      .then(imageDetails => {
        setIsUploading(false);

        const urlWithoutProtocol = imageDetails.url.replace(
          /^https?:\/\//i,
          '//'
        );

        if (typeof onUpload === 'function') {
          onUpload(
            `${protocol ? `${protocol}:` : ''}${urlWithoutProtocol}`,
            imgName
          );
        }
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
        setIsUploading(false);
        if (onUpload) {
          onUpload('', 'Error uploading. Please try again.');
        }
      });
  };

  const handleDrop = files => {
    setIsUploading(true);
    uploadImageFile(files[0]);
  };

  const style = {
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    ...dropZoneOverrideStyle,
  };

  return isUploading ? (
    <ThreeDotsLoader dotColor="#848484" />
  ) : (
    <Dropzone
      multiple={false}
      accept="image/*"
      style={style}
      onDrop={handleDrop}
      preventDropOnDocument={preventDropOnDocument}
      disableClick={disableClick}
    >
      {children}
    </Dropzone>
  );
}

ImageUploader.propTypes = {
  protocol: PropTypes.oneOf(['http', 'https']),
  onUpload: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
};

export default ImageUploader;
