import React from 'react';
import PropTypes from 'prop-types';
import useToggle from 'next/hooks/use-toggle';
import { Shape as FeaturesShape } from 'next/entities/features';
import Features from './features';
import { Dialog, Title, Trigger } from './styled';

export function DevTools({ features = {}, open: initial }) {
  const [open, toggle] = useToggle(initial);

  return (
    <>
      <Trigger icon="flask" onClick={toggle} />

      <Dialog onClose={toggle} size="xl" visible={open}>
        <Title>Studio DevTools</Title>

        <Features features={features} />
      </Dialog>
    </>
  );
}

DevTools.propTypes = {
  features: FeaturesShape,
  open: PropTypes.bool,
};

export default DevTools;
