import PropTypes from 'prop-types';
import pick from 'lodash.pick';

const Shape = {
  accountId: PropTypes.string,
  id: PropTypes.string,
  isDefault: PropTypes.bool,
  name: PropTypes.string,
};

const allowed = ['id', 'isDefault', 'name'];

/**
 * Theme parsing function
 *
 * @param {Object<keyof Theme>} theme - Object with theme fields
 * @return {Object<keyof Theme>} Object with parsed theme fields
 */
export const parse = theme => pick(theme, allowed);

export default PropTypes.shape(parse(Shape));
