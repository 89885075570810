import { createSelector } from 'reselect';
import { selectDateRangeOptions } from 'selectors/timeframe-options';
import { createCollectionReducer } from 'reducers/account/collections';
import { selectIsSynced } from 'reducers/account/requests';
import { sortAscending, sortDescending } from 'utils';

const TYPE = 'charts';

export default createCollectionReducer(TYPE);

const selectNativeCharts = state => state.account.charts;
export const selectCharts = createSelector(
  selectNativeCharts,
  selectDateRangeOptions,
  (charts, timeframeOptions) => {
    if (!charts) {
      return {};
    }

    const maxAllowedTrailingDays = '30';
    const availableOptions = timeframeOptions.reduce((acc, option) => {
      acc[option.value] = option.value;
      return acc;
    }, {});

    return Object.keys(charts).reduce((acc, id) => {
      const chart = charts[id];
      let trailingDays = `${chart.trailing_days}`;

      if (!availableOptions[trailingDays]) {
        trailingDays = maxAllowedTrailingDays;
      }

      acc[id] = {
        ...chart,
        trailing_days: trailingDays,
      };
      return acc;
    }, {});
  }
);

export const selectChart = (state, id) => selectCharts(state)[id];

export const selectChartsAsList = createSelector(selectCharts, charts =>
  Object.values(charts || {})
);

export const CHART_SORT_TYPES = {
  NAME_AZ: 'name-asc',
  NAME_ZA: 'name-desc',
  DATE_ADDED_NEWEST: 'created-desc',
  DATE_ADDED_OLDEST: 'created-asc',
};

export const CHART_SORT_OPTIONS = [
  {
    label: 'Name (A-Z)',
    value: CHART_SORT_TYPES.NAME_AZ,
  },
  {
    label: 'Name (Z-A)',
    value: CHART_SORT_TYPES.NAME_ZA,
  },
  {
    label: 'Date Added (newest)',
    value: CHART_SORT_TYPES.DATE_ADDED_NEWEST,
  },
  {
    label: 'Date Added (oldest)',
    value: CHART_SORT_TYPES.DATE_ADDED_OLDEST,
  },
];

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

const chartSortingConfig = {
  [CHART_SORT_TYPES.NAME_AZ]: charts => {
    return charts.sort((a, b) => collator.compare(a.name, b.name));
  },
  [CHART_SORT_TYPES.NAME_ZA]: charts => {
    return charts.sort((a, b) => collator.compare(b.name, a.name));
  },
  [CHART_SORT_TYPES.DATE_ADDED_NEWEST]: charts => {
    return charts.sort((a, b) => sortDescending(a.created_at, b.created_at));
  },
  [CHART_SORT_TYPES.DATE_ADDED_OLDEST]: charts => {
    return charts.sort((a, b) => sortAscending(a.created_at, b.created_at));
  },
};

/**
 * Returns the given chart array filtered by its name based on the given criteria
 * @param {Array<Chart>} charts - charts to be filtered
 * @param {String} searchTerm - search criteria
 */
const getFilteredCharts = (charts, searchTerm = '') => {
  if (!charts || !Array.isArray(charts)) {
    return [];
  }

  if (!searchTerm) {
    return charts;
  }

  const query = searchTerm.toLowerCase();
  return charts.filter(chart => chart.name.toLowerCase().includes(query));
};

/**
 * Returns the given chart array sorted by the given sort option
 * @param {Array<Chart>} charts - charts to be sorted
 * @param {String} sortValue - the sort value. Must be one of SORT_TYPES
 */
const getSortedCharts = (charts, sortValue) => {
  if (!charts || !Array.isArray(charts)) {
    return [];
  }

  const sortCharts = chartSortingConfig[sortValue];

  if (!sortCharts) {
    return charts;
  }

  return sortCharts(charts);
};

export const selectSortedFilteredCharts = createSelector(
  selectChartsAsList,
  (_, props) => props.searchTerm,
  (_, props) => props.sortOption,
  (charts, searchTerm, sortOption) => {
    let result = [];
    result = getFilteredCharts(charts, searchTerm);
    result = getSortedCharts(result, sortOption);

    return result;
  }
);

export const selectChartsSynced = state => selectIsSynced(state, TYPE);
