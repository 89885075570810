import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from '@studio/legacy-components';
import { encodeEventNameUrl } from 'components/Insights/utils';
import { mapStateToProps, getEventLabel } from './SelectedEventLabel';

const SelectedEventLink = ({ event, id, source, name, stepId, stepName }) => {
  const singleMetricUrl = `/insights/explorer/${source}/${encodeEventNameUrl(
    event,
    source
  )}${id ? `?id=${id}` : ''}${stepId ? `&stepId=${stepId}` : ''}`;
  const eventLabel = getEventLabel(event, source);
  const title = `${
    name ? `${name} ${eventLabel.toLowerCase()}` : eventLabel
  } detail page`;

  const labelStepName = source !== 'banner' && stepName ? `${stepName} : ` : '';

  return (
    <Link to={singleMetricUrl} title={title}>
      {id ? `${name || id}: ` : ''} {labelStepName}
      {eventLabel}
    </Link>
  );
};

SelectedEventLink.propTypes = {
  event: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  stepId: PropTypes.string,
  stepName: PropTypes.string,
  source: PropTypes.string,
};

export default connect(mapStateToProps)(SelectedEventLink);
