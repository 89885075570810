import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTable, useSortBy } from 'react-table';

import { TableContainer, TableWrapper, TableData } from './Styled';
import TableHeaders from './TableHeaders';

export const Table = ({
  columns,
  data,
  defaultSortColumn,
  defaultSortDirection = 'desc',
  panel = false,
  containerStyles = {
    marginTop: 20,
  },
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        disableMultiSort: true,
        initialState: {
          sortBy: [
            { id: defaultSortColumn, desc: defaultSortDirection === 'desc' },
          ],
        },
      },
      useSortBy
    );

  return (
    <TableContainer
      {...containerStyles}
      className={classnames({
        panel,
      })}
    >
      <TableWrapper {...getTableProps()}>
        <TableHeaders headerGroups={headerGroups} />
        <tbody data-testid="table-body" {...getTableBodyProps()}>
          {rows.map((row, mainIndex) => {
            prepareRow(row);
            return (
              <tr
                data-testid="table-row"
                // eslint-disable-next-line react/no-array-index-key
                key={mainIndex}
                {...row.getRowProps()}
              >
                {row.cells.map((cell, index) => (
                  <TableData
                    wordWrap={cell.column.wordWrap}
                    maxWidth={cell.column.maxWidth}
                    minWidth={cell.column.minWidth}
                    {...cell.getCellProps()}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${index}-${mainIndex}`}
                  >
                    {cell.render('Cell')}
                  </TableData>
                ))}
              </tr>
            );
          })}
        </tbody>
      </TableWrapper>
    </TableContainer>
  );
};

Table.propTypes = {
  panel: PropTypes.bool,
  containerStyles: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.any,
      accessor: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
      minWidth: PropTypes.number,
      maxWidth: PropTypes.number,
      alignment: PropTypes.oneOf(['left', 'right', 'center']),
      Cell: PropTypes.func.isRequired,
      wordWrap: PropTypes.oneOf(['initial', 'inherit', 'break-word', 'normal']),
    })
  ).isRequired,
  defaultSortColumn: PropTypes.string,
  defaultSortDirection: PropTypes.oneOf(['asc', 'desc']),
};

export default Table;
