import { call, put, getContext, takeEvery } from 'redux-saga/effects';
import toast from 'next/lib/toast';
import { reportError } from 'helpers/error-reporting';
import { patterns, resolve, insert } from './actions';

export function* getAllProfileAttributeLabels() {
  try {
    const api = yield getContext('api');
    const { data = [] } = yield call(api.getAllProfileAttributeLabels);
    yield put(
      resolve(
        data.reduce((acc, meta) => {
          acc[meta.id] = meta;
          return acc;
        }, {})
      )
    );
  } catch (error) {
    yield call(reportError, error);
  }
}

export function* createProfileAttributeLabel(action) {
  try {
    const {
      payload: { name, ...delta },
    } = action;
    const api = yield getContext('api');
    const { data: attributeLabel } = yield call(
      api.updateProfileAttributeLabel,
      name,
      delta
    );
    yield put(insert(attributeLabel));
    yield call(toast.success, 'Profile attribute updated successfully');
  } catch (error) {
    yield call(reportError, error);
  }
}

export default function* saga() {
  yield takeEvery(patterns.create, createProfileAttributeLabel);
}
