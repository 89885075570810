import styled from 'styled-components';
import { Input, Select } from '@studio/legacy-components';

export const InformativeText = styled.p`
  margin-top: 14px;
  word-break: break-word;

  > strong {
    font-weight: var(--bold);
  }
`;

export const FieldsWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;

  ${Input} {
    width: 100%;
  }

  ${Select} {
    min-width: initial;
    max-width: initial;
    flex: 1;
  }
`;

export const InputWrapper = styled.div`
  flex: 1;
  position: relative;
`;
