import React, { useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, Link, MenuList, Td } from '@studio/legacy-components';
import { GRAPHICS_BY_LIFECYCLE } from 'constants/segments';
import { selectUserRole } from 'reducers/account/users';
import { selectUserId } from 'reducers/user';
import { ACCOUNT_PERMISSIONS } from 'constants/accountManagement/permissions';
import { cloneSegment } from 'actions/account/segments';
import { callApi as getConditionsEstimate } from 'entities/conditions-estimates';
import { SEGMENT_EXPORT } from 'constants/features';
import { selectAccountFeature } from 'reducers/account/features';

import {
  Dots,
  LifeCycleIcon,
  SegmentsRow,
  EstimateLoadingSkeleton,
} from './styled';

function FormattedTd({ estimate, value }) {
  if (value === null && estimate)
    return (
      <Td>
        <EstimateLoadingSkeleton />
      </Td>
    );

  if (value) {
    const label = estimate ? `~${value}` : value;
    return <Td title={label}>{label}</Td>;
  }

  return <Td>-</Td>;
}

export const SegmentRowItem = ({
  id,
  name,
  updatedAt,
  count,
  percentOfUsers,
  numberOfExperiences,
  creator,
  userLifecycleStage,
  canEdit,
  onClone,
  onDeleteClick,
  onExport,
  useEstimate,
  conditions,
  onLoad,
  hasSegmentExport,
  dropdownRef,
}) => {
  useEffect(() => {
    if (useEstimate) {
      onLoad({ conditions });
    }
  }, [useEstimate, conditions, onLoad]);

  const createdBy = creator || '-';

  const lastUpdated =
    moment().diff(updatedAt, 'months', true) > 1
      ? moment(updatedAt).format('lll')
      : moment(updatedAt).fromNow();

  const isUserLifecycleStage = Boolean(userLifecycleStage);
  const handleClone = () => onClone(id);
  const handleDeleteClick = () => onDeleteClick(id);
  const handleExport = () => onExport(id);

  const dropdownOptions = [
    {
      as: Link,
      to: `/segments/${id}/view`,
      label: 'View',
      icon: 'users',
    },
  ];

  if (canEdit) {
    dropdownOptions.push({
      as: Link,
      to: `/segments/${id}/edit`,
      label: 'Edit',
      icon: 'edit',
    });
  }
  if (hasSegmentExport) {
    dropdownOptions.push({
      label: 'Export',
      icon: 'file-export',
      onClick: handleExport,
    });
  }

  if (canEdit && !isUserLifecycleStage) {
    dropdownOptions.push(
      {
        label: 'Clone',
        icon: 'copy',
        onClick: handleClone,
      },
      {
        label: 'Delete',
        icon: 'trash-alt',
        onClick: handleDeleteClick,
      }
    );
  }

  return (
    <SegmentsRow key={id}>
      <Td title={name}>
        <Link to={`/segments/${id}/view`}>{name || '-'}</Link>
        {userLifecycleStage && (
          <LifeCycleIcon src={GRAPHICS_BY_LIFECYCLE[userLifecycleStage].icon} />
        )}
      </Td>
      <Td title={createdBy}>{createdBy}</Td>
      <Td title={numberOfExperiences}>{numberOfExperiences}</Td>
      <FormattedTd estimate={useEstimate} value={count} />
      <FormattedTd estimate={useEstimate} value={percentOfUsers} />
      <Td title={moment(updatedAt).format('lll')}>{lastUpdated}</Td>
      <Td>
        <Dropdown
          ref={dropdownRef}
          attachment={<MenuList options={dropdownOptions} />}
        >
          <Dots icon="ellipsis-h" />
        </Dropdown>
      </Td>
    </SegmentsRow>
  );
};

SegmentRowItem.propTypes = {
  canEdit: PropTypes.bool,
  useEstimate: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  creator: PropTypes.string,
  userLifecycleStage: PropTypes.string,
  numberOfExperiences: PropTypes.number,
  updatedAt: PropTypes.number,
  count: PropTypes.number,
  percentOfUsers: PropTypes.number,
  onClone: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  conditions: PropTypes.object,
  hasSegmentExport: PropTypes.bool,
  hasNextSegmentsEdit: PropTypes.bool,
};

const mapStateToProps = state => {
  const userRole = selectUserRole(state, selectUserId(state));
  return {
    canEdit: userRole !== ACCOUNT_PERMISSIONS.EDITOR,
    hasSegmentExport: selectAccountFeature(state, SEGMENT_EXPORT),
  };
};

const mapDispatchToProps = {
  onClone: cloneSegment,
  onLoad: getConditionsEstimate,
};

export default connect(mapStateToProps, mapDispatchToProps)(SegmentRowItem);
