import { transparentize } from './mixins';

const colorGroups = [
  {
    '$purple-light': '#EEEEFF',
    '$purple-med': '#D7D7FF',
    $purple: '#5C5CFF',
    '$purple-dark': '#4343C5',
  },
  {
    '$blue-light': '#E9F7FF',
    '$blue-med': '#8AD5FF',
    $blue: '#2CB4FF',
    '$blue-dark': '#0D7EF9',
    '$blue-xtradark': '#0963C6',
  },
  {
    '$green-light': '#E0FBF2',
    '$green-med': '#B2F4DF',
    $green: '#05DB99',
    '$green-dark': '#01A56A',
    '$green-xtradark': '#198A51',
  },
  {
    '$cutecumber-light': '#E8FBFA',
    '$cutecumber-med': '#B0F2F4',
    $cutecumber: '#20E0D6',
    '$cutecumber-dark': '#1EB5C4',
  },
  {
    '$pink-power-ranger-light': '#FFEDF3',
    '$pink-power-ranger-med': '#FFB0D0',
    '$pink-power-ranger': '#FF5290',
    '$pink-power-ranger-dark': '#D6216B',
  },
  {
    '$yellow-light': '#FFF5CD',
    '$yellow-med': '#FFE680',
    $yellow: '#FFCD02',
    '$yellow-dark': '#FFAA39',
    '$yellow-xtradark': '#DB7B16',
  },
  {
    '$red-light': '#FFF2F3',
    '$red-med': '#FFDFE2',
    $red: '#FF3B4E',
    '$red-dark': '#D30014',
  },
  {
    '$blurple-haze': 'linear-gradient(to right, #5C5CFF, #8960FF)',
    '$daba-dee-daba-die': 'linear-gradient(to right, #4980FF, #2EB1FF)',
    $merfolk: 'linear-gradient(to right, #0BB6D9, #10D5CB)',
    $grapefruit: 'linear-gradient(to right, #FF5290, #FF8389)',
    '$mood-lighting': 'linear-gradient(to right, #5C5CFF, #8AD5FF)',
    $galaxy: 'linear-gradient(to right, #5C5CFF, #FF92C6)',
    $regulus: 'linear-gradient(to right, #0B1A38, #3B456A)',
    $mithril: 'linear-gradient(to right, #F4F7FA, #FFFFFF)',
    tropico: 'linear-gradient(to right, #FF5290, #8960FF)',
    '$gradient-green': 'linear-gradient(90deg, #108484 0%, #00c2c2 100%)',
  },
  {
    '$smurfs-blood': '#0B1A38',
  },
  {
    '$gray-0': '#FFFFFF',
    '$gray-1': '#F5F7FA',
    '$gray-2': '#E7ECF3',
    '$gray-3': '#D6DBE7',
    '$gray-4': '#C0C6D9',
    '$gray-5': '#7E89A9',
    '$gray-6': '#475872',
    '$gray-7': '#394455',
    '$gray-8': '#242A35',
  },
  {
    '$light-tint-100': '#ffffff',
    '$light-tint-70': 'rgba(255,255,255,0.70)',
    '$light-tint-38': 'rgba(255,255,255,0.38)', // deprecate
    '$light-tint-30': 'rgba(255,255,255,0.30)',
    '$light-tint-10': 'rgba(255,255,255,0.10)',
  },
  {
    '$dark-tint-100': 'rgba(0,0,0,1.0)',
    '$dark-tint-70': 'rgba(0,0,0,0.70)',
    '$dark-tint-38': 'rgba(0,0,0,0.38)',
    '$dark-tint-30': 'rgba(0,0,0,0.30)',
    '$dark-tint-10': 'rgba(0,0,0,0.10)',
  },
  {
    // deprecated naming below
    __deprecated_tokens_below: true,
    '$light-orange': '#FDEAE6',
    '$orange-1': '#FF7145',
    '$orange-2': '#DA3E0D',
    '$red-1': '#FF495B',
  },
  {
    // deprecated naming below
    __deprecated_tokens_below: true,
    '$brand-danger': '#FF495B',
    '$brand-danger-dark': '#D30014',
    '$brand-danger-light': '#FFECEE',
  },
  {
    // deprecated naming below
    __deprecated_tokens_below: true,
    '$yellow-1': '#FFCD02',
    '$dark-yellow': '#df8915',
    '$light-yellow': '#FFEED3',
    '$brand-warning': '#FFCD02',
    '$brand-warning-dark': '#df8915',
    '$brand-warning-light': '#FFEED3',
  },
  {
    // deprecated naming below
    __deprecated_tokens_below: true,
    '$brand-primary': '#5C5CFF',
    '$brand-primary-dark': '#3923B7',
    '$brand-primary-light': '#e9e9ff',
  },
  {
    // deprecated naming below
    __deprecated_tokens_below: true,
    '$brand-info': '#2CB4FF',
    '$brand-info-dark': '#0D7EF9',
    '$brand-info-light': '#AFE2FF',
  },
  {
    // deprecated naming below
    __deprecated_tokens_below: true,
    '$blue-gray-0': '#081731',
    '$blue-gray-1': '#1A2B49',
    '$blue-gray-2': '#364760',
    '$blue-gray-3': '#9CA7BA',
  },
  {
    // deprecated naming below
    __deprecated_tokens_below: true,
    '$light-blue-1': '#56C6FF',
    '$light-blue-2': '#A6E1FF',
    '$blue-1': '#2CB4FF',
    '$blue-2': '#0D7EF9',
    '$dark-blue': '#0D7EF9',
    '$dark-purple': '#100E44',
  },
  {
    // deprecated naming below
    __deprecated_tokens_below: true,
    '$green-1': '#05DB99',
    '$dark-green': '#09BB71',
    '$light-green-1': '#E6FBF4',
    '$brand-success': '#05DB99',
    '$brand-success-dark': '#09BA82',
    '$brand-success-light': '#def7ff',
  },
  {
    // deprecated naming below
    __deprecated_tokens_below: true,
    '$gray-5-2': '#9CA7BA',
    '$gray-7-2': '#1A2B49',
    '$gray-10': '#E1E7F0',
    '$gray-20': '#C8D2E0',
    '$gray-30': '#9CA7BA',
    '$gray-50': '#9CA7BA',
    '$gray-70': '#364760',
    '$gray-90': '#1A2B49',
  },
];

const colors = colorGroups.reduce(
  (agg, colorGroup) => ({ ...agg, ...colorGroup }),
  {}
);

const flexboxgrid = {
  gridSize: 12, // columns
  gutterWidth: 1, // rem
  outerMargin: 2, // rem
  mediaQuery: 'only screen',
  container: {
    sm: 46, // rem
    md: 61, // rem
    lg: 76, // rem
  },
  breakpoints: {
    xs: 0, // em
    sm: 48, // em
    md: 64, // em
    lg: 75, // em
  },
};

export default {
  name: 'default',
  flexboxgrid,
  ...colors,
  '$box-shadow-0': 'none',
  '$box-shadow-1': `${transparentize(0.9, colors['$gray-6'])} 0 2px 6px`,
  '$box-shadow-2': `${transparentize(0.84, colors['$gray-6'])} 0 4px 16px`,
  '$box-shadow-3': `${transparentize(0.8, colors['$gray-6'])} 0 12px 34px`,
  '$box-shadow-4': `${transparentize(0.65, colors['$gray-6'])} 0 12px 34px`,
  '$box-shadow-5': `inset 0px 0px 2px rgba(71, 88, 114, 0.4);`,

  '$border-radius': '6px',

  // Fonts //////////////////////////////////////////////////////////////////////

  '$font-size-xxxxl': '46px',
  '$font-size-xxxl': '38px',
  '$font-size-xxl': '26px',
  '$font-size-xl': '20px',
  '$font-size-l': '16px',
  '$font-size-m': '14px',
  '$font-size-s': '13px',
  '$font-size-xs': '10px',
  '$font-size-xxs': '10px',

  '$font-weight-xxl': '800',
  '$font-weight-xl': '700',
  '$font-weight-l': '600',
  '$font-weight-m': '400',
  '$font-weight-s': '300',
  '$font-weight-xs': '200',

  '$font-size-base': '14px',
  '$font-size-large': '18px',
  '$font-size-small': '13px',

  // Animation Timings ///////////////////////////////////////////////////////
  '$fade-duration-ms': 300,

  '$brand-ease-out-fastest': '0.15s ease-out',
  '$brand-ease-out-fast': '0.3s ease-out',
  '$brand-ease-out-slow': '0.7s ease-out',
  '$brand-ease-in-fastest': '0.15s ease-in',
  '$brand-ease-in-fast': '0.3s ease-in',
  '$brand-ease-in-slow': '0.7s ease-in',
};
