// Getters and setters for client-side storage of
// key-value pair string data.

function ifLocalStorageAPIExists(callback, errorMessage = '', defaultValue) {
  if (!window.localStorage) {
    // eslint-disable-next-line no-console
    console.error('window.localStorage is not defined.', errorMessage);
    return defaultValue;
  }
  if (callback) {
    try {
      return callback();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error, errorMessage);
    }
  }
  return defaultValue;
}

export const getItem = key =>
  ifLocalStorageAPIExists(
    () => window.localStorage.getItem(key),
    `Attempt to access "${key}" failed.`,
    null
  );

export const setItem = (key, value) =>
  ifLocalStorageAPIExists(
    () => window.localStorage.setItem(key, value),
    `Attempt to set "${key}" failed.`
  );

export const removeItem = key =>
  ifLocalStorageAPIExists(
    () => window.localStorage.removeItem(key),
    `Attempt to remove "${key}" failed.`
  );
