import qs from 'qs';

export default function createAnalyticsClient(auth) {
  const fetchAnalytics = async ({ params, body }) => {
    const jwt = await auth.getToken();
    const accountId = Number.parseInt(await auth.getAccountId(), 10);
    const paramsWithAccountId = { ...params, account_id: accountId };
    const queryString = qs.stringify(paramsWithAccountId);
    /* global ANALYTICS_API_HOST */
    const queryUrl = `${ANALYTICS_API_HOST}/v1/query`;
    const url = encodeURI(`${queryUrl}?${queryString}`);
    const apiBody = JSON.stringify(body);

    const response = await fetch(url, {
      method: body ? 'POST' : 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: apiBody,
    });

    if (response.status !== 200) {
      throw new Error(`failed to fetch from analytics API ${url}`);
    }

    return response.json();
  };

  return {
    /**
     * @return {Promise(User)} user
     */

    getChecklistStats: checklistId => {
      const params = {
        type: 'checklist_stats',
        checklist_id: checklistId,
      };
      return fetchAnalytics({ params });
    },

    getFlowListStats: () => {
      const params = { type: 'dashboard_flow_stats' };
      return fetchAnalytics({ params });
    },

    getGoalsReachedByWeeklyAccountUsers: () => {
      const params = {
        type: 'goals_reached_by_weekly_account_users',
      };
      return fetchAnalytics({ params });
    },

    getGoalReachedUserSample: goalId => {
      const params = {
        type: 'goal_reached_user_sample',
        goal_id: goalId,
      };
      return fetchAnalytics({ params });
    },

    getGoalsReachedFromFlowStarted: ({
      accountId,
      goalId,
      flowId,
      conversionWindow,
    }) => {
      const params = {
        type: 'goal_reached_from_flow_started',
        account_id: accountId,
        goal_id: goalId,
        flow_id: flowId,
        conversion_window: conversionWindow,
      };
      return fetchAnalytics({ params });
    },

    getFlowPerformanceReportData: flowGoals => {
      const params = { type: 'flow_goal_stats' };
      return fetchAnalytics({ params, body: flowGoals });
    },

    getFlowErrorReportData: () => {
      const params = { type: 'account_error_stats' };
      return fetchAnalytics({ params });
    },
  };
}
