import React from 'react';
import Card from 'components/Common/Card';

const SatisfactionSurveyCard = props => (
  <Card
    position="relative"
    backgroundColor="$gray-1"
    boxShadow="none"
    {...props}
  />
);

export default SatisfactionSurveyCard;
