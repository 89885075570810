import React from 'react';

export const ModalsTooltipsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="73" height="49" fill="none">
    <path
      fill="#FAFAFF"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      d="M.782 4.287c0-1.54 1.333-2.849 2.902-2.849h31.92c1.569 0 2.902 1.309 2.902 2.849v22.016c0 1.54-1.333 2.849-2.902 2.849H25.41v3.156c0 .693-.393 1.232-1.02 1.54a1.73 1.73 0 0 1-1.804-.385l-4.392-4.311H3.683c-1.568 0-2.901-1.309-2.901-2.849V4.287Z"
    />
    <path stroke="#5C5CFF" strokeMiterlimit="10" d="M7.45 10.29h15.92" />
    <path
      stroke="#5C5CFF"
      strokeLinecap="round"
      strokeMiterlimit="10"
      d="M7.637 16.142h22.285M7.637 21.067h22.285"
    />
    <path
      fill="url(#a)"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      d="M29.493 12.42H8.066c-.257 0-.429-.164-.429-.41V9.548c0-.246.172-.41.429-.41h21.427c.257 0 .429.164.429.41v2.462c0 .246-.172.41-.429.41Z"
    />
    <rect
      width="38.578"
      height="33.123"
      x="33.364"
      y="15.001"
      fill="#fff"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      rx="2"
    />
    <path
      fill="#D7D7FF"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      d="M33.372 17.438a2 2 0 0 1 2-2h34.577a2 2 0 0 1 2 2v8.667H33.372v-8.667Z"
    />
    <path
      stroke="#5C5CFF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M43.012 34.485h19.59M39.575 38.977h26.863"
    />
    <defs>
      <linearGradient
        id="a"
        x1="7.637"
        x2="29.922"
        y1="10.779"
        y2="10.779"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5290" />
        <stop offset="0" stopColor="#FF5290" />
        <stop offset="1" stopColor="#8960FF" />
      </linearGradient>
    </defs>
  </svg>
);

export const EmbedsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="58" height="56" fill="none">
    <path
      fill="#D7D7FF"
      d="M.635 0h56v1.693a4 4 0 0 1-4 4h-48a4 4 0 0 1-4-4V0Z"
    />
    <rect
      width="56"
      height="38.108"
      x=".635"
      y="8.946"
      fill="#fff"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      rx="4"
    />
    <path
      fill="#D7D7FF"
      stroke="#5C5CFF"
      d="M34.17 17.468v.002L27.89 39.673v.002a2.073 2.073 0 0 1-2.56 1.433h-.003a2.094 2.094 0 0 1-1.421-2.577v-.002l6.279-22.203a2.07 2.07 0 0 1 2.562-1.433l1.422 2.575Zm0 0a2.09 2.09 0 0 0-1.422-2.575l1.422 2.575ZM13.446 29.471l5.495 5.551c.81.817 2.12.817 2.928 0a2.094 2.094 0 0 0 0-2.943l-4.035-4.08 4.035-4.078a2.094 2.094 0 0 0 0-2.944 2.055 2.055 0 0 0-2.928 0l-5.495 5.55a2.094 2.094 0 0 0 0 2.944Zm31.183-2.943-5.493-5.55a2.055 2.055 0 0 0-2.928 0 2.094 2.094 0 0 0 0 2.943L40.24 28l-4.032 4.079a2.094 2.094 0 0 0 0 2.943c.808.817 2.119.817 2.928 0l5.493-5.55a2.094 2.094 0 0 0 0-2.944Z"
    />
    <path
      fill="#D7D7FF"
      d="M.635 54.307a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4V56h-56v-1.693Z"
    />
  </svg>
);
