export const CANCELED = '@collection/CANCELED';

export const REQUEST_READ = '@collection/REQUEST_READ';
export const REQUEST_READ_ONE = '@collection/REQUEST_READ_ONE';
export const REQUEST_RESOLVED = '@collection/REQUEST_RESOLVED';
export const REQUEST_REJECTED = '@collection/REQUEST_REJECTED';
export const REQUEST_RESET = '@collection/REQUEST_RESET';

export const NEW_ITEM = '@collection/NEW_ITEM';
export const ITEM_CREATED = '@collection/ITEM_CREATED';
export const ITEM_UPDATED = '@collection/ITEM_UPDATED';
export const ITEM_DROPPED = '@collection/ITEM_DROPPED';
export const ITEM_REMOVED = '@collection/ITEM_REMOVED';

export const ITEM_INSERTED = '@collection/ITEM_INSERTED';
export const ITEM_REPLACED = '@collection/ITEM_REPLACED';
export const ITEM_FLUSHED = '@collection/ITEM_FLUSHED';
export const ITEM_PRUNED = '@collection/ITEM_PRUNED';
