import styled from 'styled-components';
import {
  H2,
  Button,
  Overflow,
  Panel,
  Icon,
  ExternalLink,
  ExpandableText,
  Td,
  Th,
  Link,
} from '@studio/legacy-components';
import ComingSoonImg from 'next/assets/images/coming-soon.svg';

export const ComingSoonImage = styled.img.attrs({
  src: ComingSoonImg,
})`
  width: 122px;
`;

export const ComingSoonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px;
  gap: 16px;
  ${H2} {
    font-weight: var(--semi-bold);
    font-size: var(--large);
  }
  ${Button} {
    :hover {
      color: var(--white);
    }
  }
`;

/* PinStats */
export const PinStatsPanel = styled(Panel)`
  padding-bottom: 0;
`;

export const PinStepsContainer = styled(Overflow)`
  border-top: 1px solid var(--myst);
  margin-top: 8px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;
  gap: 24px;
  flex-direction: column;
`;

export const NoResultsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;
  flex-direction: column;
  gap: 16px;
`;

export const LoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
`;

export const SubTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${Icon} {
    color: var(--status-inactive);
    cursor: pointer;
  }
`;

export const Help = styled.div`
  ${ExternalLink} {
    display: block;
    color: var(--white);
  }
`;
export const IssuePanel = styled(Panel)`
  padding-bottom: 0;
`;
export const TableWrapper = styled.div`
  border-top: 1px solid var(--tint-black);
  overflow-x: hidden;

  ${Td} {
    width: 20%;
    max-width: 100px;
    vertical-align: top;
    white-space: normal;
    word-break: break-word;
  }
  ${Th} {
    width: 20%;
    max-width: 100px;
  }
  ${Link} {
    white-space: break-spaces;
    word-break: break-all;
  }
`;
export const EmptyValue = styled.div`
  text-align: center;
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const OpenBuilder = styled(Icon)`
  color: var(--primary);
  cursor: pointer;
`;

export const CopyWrapper = styled.div`
  display: flex;
  ${ExpandableText} {
    width: 100%;
    word-break: break-all;
  }
`;

export const CopyButton = styled.div`
  padding-top: 4px;
`;

export const Image = styled.img``;
