import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  PublishingManager,
  PublishingManagerPropTypes,
} from '@studio/settings-panels';
import { selectUser } from 'next/entities/user';
import { ROLES, selectAccountUser } from 'next/entities/account-users';
import { selectContentStatus } from 'next/entities/content-status';
import {
  requestPublishFlow,
  requestUnpublishFlow,
  selectFlow,
  requestRevertFlow,
} from 'next/entities/flows';
import ConfirmPushChangesModal from './ConfirmPushChangesModal';

function FlowPublishingManager({ id, children, ...unhandledProps }) {
  return (
    <PublishingManager
      {...unhandledProps}
      renderConfirmPushChangesModal={modalProps => (
        // eslint-disable-next-line @appcues/jsx-props-no-spreading
        <ConfirmPushChangesModal id={id} {...modalProps} />
      )}
    >
      {children}
    </PublishingManager>
  );
}

FlowPublishingManager.propTypes = {
  ...PublishingManagerPropTypes,
  id: PropTypes.string,
};

const mapStateToProps = (state, { id }) => {
  const {
    published,
    updatedAt,
    publishedAt,
    name,
    experiment,
    state: flowState,
  } = selectFlow(state, id);
  const { id: userId } = selectUser(state);
  const { roleId } = selectAccountUser(state, userId);
  const contentStatus = selectContentStatus(state, id);

  return {
    name,
    published,
    experiment,
    hasChanges: contentStatus?.publishedAndDirty,
    updatedAt,
    publishedAt,
    canPublish: roleId !== ROLES.EDITOR,
    flowState,
  };
};

const mapDispatchToProps = (dispatch, { id }) => ({
  onPublish: () => dispatch(requestPublishFlow(id)),
  onUnpublish: () => dispatch(requestUnpublishFlow(id)),
  onDiscardChanges: version => dispatch(requestRevertFlow(id, version)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlowPublishingManager);
