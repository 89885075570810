import React, { useState } from 'react';
import {
  addClause,
  addGroupClause,
  deleteClause,
  getNestedConditionsFromFlattenedClauses,
  clauseKeys,
} from '@appcues/libcues';
import GroupClause from 'components/Common/Clauses/Group';
import RadioButton from 'components/Common/Forms/RadioButton';
import * as clauseTransforms from 'transforms/clauses';
import { getItemClauses } from 'helpers/checklist-item-presets';
import { setDefaultPropertyClause, setItemClickedClause } from 'helpers/rules';

export function ItemCompletion({
  autoupdateFlowCompleted,
  checklistId,
  checklistItem,
  className,
  clauses: initial,
  currentFlow,
  flowCompleted,
  flowId,
  updateChecklistItem,
}) {
  const isClauseSetToItemClicked = clause => {
    return (
      clause[1]?.property === `_checklist_item_${checklistItem.id}_completed` &&
      clause[1]?.value === 'true'
    );
  };

  const [oldClauses, setOldClauses] = useState(null);
  const [itemClickedChecked, setItemClickedChecked] = useState(
    () => isClauseSetToItemClicked(initial) || false
  );

  const updateChecklistItemClauses = transformationFunction => {
    const clauses = getItemClauses(checklistItem);
    updateChecklistItem(checklistId, checklistItem.id, {
      conditions: getNestedConditionsFromFlattenedClauses(
        transformationFunction(clauses),
        null,
        clauseKeys
      ),
    });
  };

  const updateChecklistItemAddClause = (parentId, clause) => {
    updateChecklistItemClauses(clauses => addClause(clauses, parentId, clause));
  };

  const updateChecklistItemAddGroupClause = (
    parentId,
    booleanOperator,
    childClauses
  ) => {
    updateChecklistItemClauses(clauses =>
      addGroupClause(clauses, parentId, booleanOperator, childClauses)
    );
  };

  const updateChecklistItemDeleteClause = clauseId => {
    updateChecklistItemClauses(clauses => deleteClause(clauses, clauseId));
  };

  const updateChecklistItemDissolveGroupClause = clauseId => {
    updateChecklistItemClauses(clauses =>
      clauseTransforms.dissolveGroupClause(clauses, clauseId)
    );
  };

  const updateChecklistItemReplaceClause = (clauseId, replacementClause) => {
    updateChecklistItemClauses(clauses =>
      clauseTransforms.replaceClause(clauses, clauseId, replacementClause)
    );
  };

  const updateChecklistItemUpdateClause = (clauseId, changes) => {
    updateChecklistItemClauses(clauses =>
      clauseTransforms.updateClause(clauses, clauseId, changes)
    );
  };

  const setSelectedFlowCompleted = () => {
    setItemClickedChecked(false);
    if (!isClauseSetToItemClicked(initial)) {
      setOldClauses(initial);
    }
    autoupdateFlowCompleted({ flowId });
  };

  const setClausesFromState = () => {
    setOldClauses(null);
    updateChecklistItem(checklistId, checklistItem.id, {
      conditions: getNestedConditionsFromFlattenedClauses(
        oldClauses,
        null,
        clauseKeys
      ),
    });
  };

  const setPropertyClause = () => {
    setItemClickedChecked(false);
    if (oldClauses) {
      setClausesFromState();
    } else {
      updateChecklistItemClauses(clauses => setDefaultPropertyClause(clauses));
    }
  };

  const handleitemClicked = () => {
    setItemClickedChecked(true);
    setOldClauses(initial);
    updateChecklistItem(checklistId, checklistItem.id, {
      conditions: getNestedConditionsFromFlattenedClauses(
        setItemClickedClause(initial, checklistItem.id),
        null,
        clauseKeys
      ),
    });
  };

  const numberOfSteps =
    currentFlow &&
    currentFlow.steps &&
    Object.values(currentFlow.steps).length > 0;
  const flowLaunchAdjective = numberOfSteps === 1 ? 'completed' : 'started';

  return (
    <div className={`${className} indent-items`}>
      {flowId && (
        <RadioButton
          checked={flowCompleted && !itemClickedChecked}
          label={`When the launched flow is ${flowLaunchAdjective}`}
          onChange={setSelectedFlowCompleted}
          name={checklistItem.id}
        />
      )}
      <RadioButton
        checked={!flowCompleted && !itemClickedChecked}
        label="When the user matches..."
        onChange={setPropertyClause}
        name={checklistItem.id}
      />
      {!flowCompleted && !itemClickedChecked && (
        <GroupClause
          clauses={initial}
          isRootNode
          addClause={updateChecklistItemAddClause}
          addGroupClause={updateChecklistItemAddGroupClause}
          updateClause={updateChecklistItemUpdateClause}
          replaceClause={updateChecklistItemReplaceClause}
          dissolveGroupClause={updateChecklistItemDissolveGroupClause}
          deleteClause={updateChecklistItemDeleteClause}
        />
      )}
      <RadioButton
        checked={itemClickedChecked}
        label="When the item is clicked"
        onChange={handleitemClicked}
        name={checklistItem.id}
      />
    </div>
  );
}

export default ItemCompletion;
