import React from 'react';
import PropTypes from 'prop-types';
import { calendar } from 'next/lib/date';
import DebouncedButton from 'next/components/DebouncedButton';
import { TimestampContainer } from './styled';

const TimestampRefresh = ({ endTime, onClick, waitMs = 1000 * 60 }) => {
  const dateString = calendar(endTime).toLowerCase();
  return (
    <TimestampContainer>
      Last updated {dateString}
      <DebouncedButton onClick={onClick} waitMs={waitMs} />
    </TimestampContainer>
  );
};

TimestampRefresh.propTypes = {
  endTime: PropTypes.number,
  onClick: PropTypes.func,
  waitMs: PropTypes.number,
};

export default TimestampRefresh;
