/* global Appcues */
import React from 'react';
import {
  diff,
  H2,
  H3,
  Panel,
  PanelBody,
  PanelHeader,
  PanelTitle,
  TrialProgressBar,
  format,
  Link,
  ButtonLink,
} from '@studio/legacy-components';
import pluralize from 'pluralize';
import {
  TrialMeterPanelWrapper,
  TrialProgressBarWrapper,
  Message,
} from './styled';

const getCTAMessage = (
  isWebInstalled,
  remainingDays,
  expirationDate,
  lengthDays
) => {
  if (isWebInstalled) {
    return remainingDays < 1 ? (
      <>
        Choose a plan and upgrade below or{' '}
        <ButtonLink
          onClick={() => {
            Appcues.show('-LerJ1l3alV9we_zskMj');
          }}
        >
          contact Sales
        </ButtonLink>{' '}
        to learn more.
      </>
    ) : (
      ''
    );
  }

  return remainingDays > 0 ? (
    <>
      Choose a plan and upgrade below or{' '}
      <Link to="/settings/installation">install the Appcues SDK</Link> to extend
      your trial beyond {format(expirationDate, 'MM/DD/YYYY')}.
    </>
  ) : (
    <>
      Choose a plan and upgrade below or{' '}
      <Link to="/settings/installation">install the Appcues SDK</Link> to extend
      your trial by {lengthDays} days.
    </>
  );
};

export default function TrialMeterByDaysPanel({
  createdAt,
  isTrial,
  expirationDate,
  isWebInstalled,
  lengthDays,
}) {
  if (!isTrial) {
    return null;
  }

  const trialDays = Math.ceil(
    diff(new Date(createdAt), new Date(expirationDate), 'days')
  );
  const remainingDays = Math.ceil(
    diff(new Date(), new Date(expirationDate), 'days')
  );
  const message = getCTAMessage(
    isWebInstalled,
    remainingDays,
    expirationDate,
    lengthDays
  );

  const usedDays = trialDays - remainingDays;

  const progress = usedDays / trialDays;
  const percentage = Math.round(progress * 100, 2);

  const progressBarStatus =
    remainingDays > 7 ? 'regular' : remainingDays > 3 ? 'warn' : 'alert';

  return (
    <Panel>
      <PanelHeader>
        <PanelTitle>
          <H2>Appcues free trial</H2>
        </PanelTitle>
      </PanelHeader>
      <PanelBody>
        <TrialMeterPanelWrapper>
          <H3>{`${remainingDays > 0 ? remainingDays : 0} ${pluralize(
            'day',
            remainingDays
          )} left`}</H3>
          <TrialProgressBarWrapper>
            <TrialProgressBar
              value={percentage}
              $status={progressBarStatus}
              $height="large"
            />
          </TrialProgressBarWrapper>
        </TrialMeterPanelWrapper>
        {message && <Message>{message}</Message>}
      </PanelBody>
    </Panel>
  );
}
