import React from 'react';
import { Link, ExternalLink } from '@studio/legacy-components';
import GuideStep from 'components/InstallationGuide/GuideStep';
import GuideHeading from 'components/InstallationGuide/GuideHeading';
import GuideParagraph from 'components/InstallationGuide/GuideParagraph';
import CopyableCodeSnippet from 'components/Common/CopyableCodeSnippet';

const InstallEvents = props => {
  const { moveForward, moveBackward, stepMax, stepIndex } = props;

  return (
    <GuideStep
      stepIndex={stepIndex}
      stepMax={stepMax}
      title="How to track events"
      moveForward={moveForward}
      moveBackward={moveBackward}
    >
      <GuideParagraph>
        Events can be sent as part of the Appcues installation using the
        Appcues.track() call or using a partner integration. Events can also be
        setup using Click-to-Track events, the Appcues no-code option for
        tracking events.
      </GuideParagraph>
      <GuideHeading>Using Appcues.track()</GuideHeading>
      <GuideParagraph>
        Use the <code className="language-markup">Appcues.track()</code> call to{' '}
        <ExternalLink href="https://docs.appcues.com/en_US/dev-installing-appcues/events-overview">
          track actions users take in your application
        </ExternalLink>
        .
      </GuideParagraph>
      <CopyableCodeSnippet>
        {`
          // e.g. Clicked the purchase button
          Appcues.track("Clicked purchase button");

          // e.g. Submitted a help ticket
          Appcues.track("Submitted help ticket", {
            url: "/support",
            article: "installation"
          });
        `}
      </CopyableCodeSnippet>
      <GuideHeading>Click-to-Track</GuideHeading>
      <GuideParagraph>
        You can also track interactions with no code. Install the{' '}
        <ExternalLink href="https://chrome.google.com/webstore/detail/appcues-builder/hjdcpbokggnjjmcmbmhkbkmibdekodmk">
          Appcues Chrome Extension
        </ExternalLink>{' '}
        and click elements to track hover, input, and click actions in your
        product. Get more details and best practices for tracking events in our
        docs:{' '}
        <ExternalLink href="https://docs.appcues.com/user-experiences-building/click-to-track-events">
          https://docs.appcues.com/user-experiences-building/click-to-track-events
        </ExternalLink>
        .
      </GuideParagraph>
      <GuideHeading>Analytics integrations</GuideHeading>
      <GuideParagraph>
        <code className="language-markup">Appcues.track()</code> events can be
        sent to your other tools like:{' '}
        <ExternalLink href="https://docs.appcues.com/integration-documents/heap-two-way-integration">
          Heap
        </ExternalLink>
        ,{' '}
        <ExternalLink href="https://docs.appcues.com/integration-documents/mixpanel-two-way-integration">
          Mixpanel
        </ExternalLink>
        ,{' '}
        <ExternalLink href="https://docs.appcues.com/integration-documents/amplitude-two-way-integration">
          Amplitude
        </ExternalLink>
        ,{' '}
        <ExternalLink href="https://docs.appcues.com/integration-documents/hotjar-integration">
          Hotjar
        </ExternalLink>
        ,{' '}
        <ExternalLink href="https://docs.appcues.com/integration-documents/fullstory-integration">
          FullStory
        </ExternalLink>
        , and more! Manage and discover more integrations here:{' '}
        <Link to="/integrations">https://studio.appcues.com/integrations</Link>.
      </GuideParagraph>
    </GuideStep>
  );
};

export default InstallEvents;
