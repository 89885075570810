import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, Heading, Modal, Overflow } from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';
import { selectUser } from 'next/entities/user';

import { selectHistoricalLimit } from 'next/lib/selectors';
import { exportAnalytics } from 'next/entities/experiences';
import ExportCsvForm from './ExportCsvForm';

function ExportModalManager({ email, limit, createdAt, onSubmit, id, type }) {
  const [visible, toggle] = useToggle();

  const handleSubmit = async ({ dates, mode, ...values }) => {
    onSubmit({
      filter: {
        experience_id: id,
      },
      format: 'csv',
      start_date: new Date(dates.start).getTime(),
      end_date: new Date(dates.end).getTime(),
      options: {
        ...(mode === 'basic' && { basic: true }),
      },
      type,
      ...values,
      ...(mode === 'survey' && { form_responses: true }),
    });
    toggle();
  };

  return (
    <>
      <Button kind="secondary" onClick={toggle}>
        Export
      </Button>

      <Modal visible={visible} onClose={toggle} key="dialog" size="xl">
        <Heading>Export a CSV</Heading>

        <Overflow>
          <ExportCsvForm
            onToggleModal={toggle}
            onSubmit={handleSubmit}
            email={email}
            limit={limit}
            createdAt={createdAt}
          />
        </Overflow>
      </Modal>
    </>
  );
}

ExportModalManager.propTypes = {
  email: PropTypes.string,
  limit: PropTypes.number,
  createdAt: PropTypes.number,
  onSubmit: PropTypes.func,
  id: PropTypes.string,
  type: PropTypes.string,
};

const mapStateToProps = state => {
  const user = selectUser(state);

  return {
    email: user.email,
    limit: selectHistoricalLimit(state),
  };
};

const mapDispatchToProps = {
  onSubmit: exportAnalytics,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportModalManager);
