/* global JOB_API_URL */

import moment from 'moment';

// eslint-disable-next-line no-restricted-imports
import authClient from 'helpers/auth-client';

export const CSV_EXPORT_TYPE = {
  FLOW: 'flow',
  CHECKLIST: 'checklist',
  NPS: 'nps',
};

export const CSV_EXPORT_CONTENT_ID_FILTER = {
  FLOW: 'flow_id',
  CHECKLIST: 'checklist_id',
  NPS: 'flow_id',
};

const defaults = {
  accountApiKey: '',
  accountId: '',
  startDate: '',
  endDate: '',
  email: '',
  filter: '',
  basic: true,
  formResponsesOnly: false,
};

/**
 * Check if date is today or in the future
 *
 * @param {number} datetime - Date/time value
 * @return {boolean} Whether date is today
 */
const isTodayOrAfter = datetime =>
  moment(datetime).isSameOrAfter(moment(), 'day');

export async function requestCsvExport(csvType, options = defaults) {
  const {
    accountApiKey,
    accountId,
    basic,
    email,
    endDate,
    filter,
    formResponsesOnly,
    startDate,
    useV2,
  } = options;

  const token = await authClient.getToken();

  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  const body = {
    account_id: accountId,
    account_api_key: accountApiKey,
    filter,
    email,
    format: 'csv',
    options: {},
    type: csvType,
    ...(startDate && { start_date: startDate }),
    ...(!isTodayOrAfter(endDate) && { end_date: endDate }),
    ...(useV2 && { version: 2 }),
  };

  // only adding these if present because "false" doesn't
  // seem to be interpreted as false by the api
  if (basic) {
    body.options.basic = 'true';
  }
  if (formResponsesOnly) {
    body.form_responses = 'true';
  }

  try {
    const response = await fetch(`${JOB_API_URL}/api/batch/v3/csv`, {
      body: JSON.stringify(body),
      headers,
      method: 'POST',
    });

    if (!response.ok) {
      throw new Error('Request for CSV export failed');
    }

    return await response.json();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      `(accountId=${accountId}, csvType=${csvType}, filter=${JSON.stringify(
        filter
      )}): error requesting CSV export`,
      error
    );
    return {
      error,
    };
  }
}

export function requestNpsCsvExport({
  accountApiKey,
  accountId,
  startDate,
  endDate,
  email,
  useV2,
}) {
  return requestCsvExport(CSV_EXPORT_TYPE.NPS, {
    accountId,
    accountApiKey,
    startDate,
    endDate,
    email,
    filter: {
      flow_type: 'satisfaction-survey',
    },
    useV2,
  });
}
