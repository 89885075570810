import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'qs';

import { CPage, CSkeleton } from '@appcues/component-library';
import { Button, ContentStatus, Icon } from '@studio/legacy-components';
import { format, fromNow } from 'next/lib/date';

import useTitle from 'next/hooks/use-title';
import IndexPage from 'components/Common/IndexPage';
import { ExportManager } from 'components/Insights/Common/ExportManager';
import { decodeEventNameUrl } from 'components/Insights/utils';
import { BANNER_EVENTS } from 'components/Insights/constants';
import { selectAccountUserFullName } from 'reducers/account/users';
import { selectExperience } from 'entities/experiences';

import SingleMetricContent from './SingleMetricContent';
import {
  HeaderTitle,
  HeaderWrapper,
  SubHeaderColumn,
  StyledLink,
} from './styled';

export const BannerMetric = ({
  encodedEventName,
  id,
  stepId,
  banner,
  createdByUserName,
  updatedByUserName,
}) => {
  const eventName = decodeEventNameUrl('banner', encodedEventName);
  const { label: eventLabel = 'Unknown Event' } =
    BANNER_EVENTS.find(({ type }) => type === eventName) ?? {};

  const {
    name: experienceName,
    published,
    state,
    createdAt,
    updatedAt,
  } = banner ?? {};

  const title = banner ? `${experienceName}, ${eventLabel}` : '';
  useTitle(`${title} | Events Explorer | Appcues`);

  return (
    <IndexPage
      title={
        banner ? (
          <HeaderWrapper>
            <HeaderTitle>{title}</HeaderTitle>
            <ContentStatus published={published} state={state} />
          </HeaderWrapper>
        ) : (
          <CSkeleton height={20} width={400} />
        )
      }
      description={
        banner ? (
          <HeaderWrapper>
            <SubHeaderColumn>
              Banner created{' '}
              {createdByUserName ? `by ${createdByUserName} ` : ''}
              {createdAt && format(createdAt, 'll')}
            </SubHeaderColumn>
            <SubHeaderColumn>
              {updatedByUserName || updatedAt ? 'Updated ' : ''}
              {updatedByUserName ? `by ${updatedByUserName} ` : ''}
              {updatedAt && fromNow(updatedAt)}
            </SubHeaderColumn>
          </HeaderWrapper>
        ) : (
          <CSkeleton height={18} width={400} />
        )
      }
      subHeaderActions={
        <>
          <ExportManager
            events={[{ source: 'banner', event: eventName, id, stepId }]}
          />
          <StyledLink to={`/banners/${id}/settings`} target="_blank">
            <Button kind="secondary">
              <Icon icon="external-link-alt" />
              Banner settings
            </Button>
          </StyledLink>
        </>
      }
    >
      <CPage.Container>
        <SingleMetricContent
          eventName={eventName}
          source="banner"
          id={id}
          stepId={stepId}
        />
      </CPage.Container>
    </IndexPage>
  );
};

BannerMetric.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      encodedEventName: PropTypes.string,
    }),
  }),
};

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { encodedEventName },
    },
    location: { search },
  } = ownProps;

  const { id, stepId } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const banner = selectExperience(state, id);

  if (!banner) {
    return {
      id,
      stepId,
      encodedEventName,
    };
  }

  return {
    banner,
    id,
    stepId,
    encodedEventName,
    createdByUserName: selectAccountUserFullName(state, banner.createdBy),
    updatedByUserName: selectAccountUserFullName(state, banner.updatedBy),
  };
};

const ConnectedPinMetric = connect(mapStateToProps)(BannerMetric);

export default ConnectedPinMetric;
