import {
  CANCELED,
  API_CALLED,
  REQUEST_SENT,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
  NEW_ITEM,
  ITEM_CREATED,
  ITEM_INSERTED,
  ITEM_UPDATED,
  ITEM_REPLACED,
  ITEM_FLUSHED,
  ITEM_REMOVED,
} from 'constants/account/collections';

const createPatternCreator =
  type =>
  (actionType, meta = {}) =>
  action =>
    action.type === actionType &&
    action.meta &&
    action.meta.type === type &&
    Object.keys(meta).every(key => meta[key] === action.meta[key]);

/**
 * returns action creators tied to a specific data type
 *
 * @param type - string - requested data type, e.g. 'flows', 'modals', etc
 */
export function createLifecycleFor(type) {
  const createPatternFor = createPatternCreator(type);

  return {
    /**
     * action describing that some action is being cancelled
     */
    cancel: () => ({
      type: CANCELED,
      meta: { type },
    }),

    /**
     * action to kick off a fetch saga
     */
    callApi: payload => ({
      type: API_CALLED,
      payload,
      meta: { type },
    }),
    /**
     * action describing that the request to the server was sent (set isFetching)
     */
    send: () => ({
      type: REQUEST_SENT,
      meta: { type },
    }),
    /**
     * received data from the server
     */
    resolve: data => ({
      type: REQUEST_RESOLVED,
      payload: data,
      meta: { type, partial: false },
    }),
    append: data => ({
      type: REQUEST_RESOLVED,
      payload: data,
      meta: { type, partial: true },
    }),
    /**
     * received an error from the server
     */
    reject: err => ({
      type: REQUEST_REJECTED,
      payload: err,
      error: true,
      meta: { type },
    }),
    /**
     * client-only;
     * use this to trigger the record creation process
     * (works like `new` in AD)
     */
    add: itemType => ({
      type: NEW_ITEM,
      payload: itemType,
      meta: { type },
    }),
    /**
     * add a new item into the store; functionally the same as replace, but more descriptive
     *
     * @param {any} item
     */
    create: item => ({
      type: ITEM_CREATED,
      payload: item,
      meta: { type },
    }),
    /**
     * functionally the same as `replace`, but used to describe
     * the FIRST time a new canonical record is added to a COLLECTION
     * usually the result of side-effects triggered by `create`
     *
     * @param {any} item
     * @param {string} parent - (optional) id of the parent entity, if any
     */
    insert: (item, parent) => ({
      type: ITEM_INSERTED,
      payload: item,
      meta: { type, parent },
    }),
    /**
     * receive updates from the UI and insert into the store
     *
     * @NOTE - this function is variadic to support both collection and single entity updates
     *          e.g. you may call `update(id, delta)` for entries in a collection (flows, rules, etc)
     *          OR you may call `update(delta)` for blob data that does not have an id (account meta, etc)
     *
     * @param {string} id - if present, the id of the record to change
     * @param {object} delta - the keys:values to update in the record
     */
    update: (...args) => {
      if (args.length === 1) {
        const [payload] = args;
        return {
          type: ITEM_UPDATED,
          payload,
          meta: { type },
        };
      }
      const [id, delta] = args;
      return {
        type: ITEM_UPDATED,
        payload: {
          id,
          delta,
        },
        meta: { type },
      };
    },
    /**
     * update a single item received from the server, not as part of a collection fetch
     *
     * @param {any} item - the item to be replaced in the store
     */
    replace: item => ({
      type: ITEM_REPLACED,
      payload: item,
      meta: { type },
    }),
    /**
     * report that an item was saved to the server
     *
     * @param {string} id - the id of the entity (rule, flow, etc)
     */
    flush: id => ({
      type: ITEM_FLUSHED,
      payload: { id },
      meta: { type },
    }),
    /**
     * report that an item was deleted on the server
     *
     * @param {string} id - the id of the entity
     */
    remove: id => ({
      type: ITEM_REMOVED,
      payload: { id },
      meta: { type },
    }),
    /**
     * a set of filter functions for use with all flavors of `take`
     * @param {Action} - action to test against pattern
     */
    patterns: {
      cancel: createPatternFor(CANCELED),
      callApi: createPatternFor(API_CALLED),
      send: createPatternFor(REQUEST_SENT),
      resolve: createPatternFor(REQUEST_RESOLVED, { partial: false }),
      append: createPatternFor(REQUEST_RESOLVED, { partial: true }),
      reject: createPatternFor(REQUEST_REJECTED),
      add: createPatternFor(NEW_ITEM),
      create: createPatternFor(ITEM_CREATED),
      insert: createPatternFor(ITEM_INSERTED),
      update: createPatternFor(ITEM_UPDATED),
      replace: createPatternFor(ITEM_REPLACED),
      flush: createPatternFor(ITEM_FLUSHED),
      remove: createPatternFor(ITEM_REMOVED),
    },
  };
}
