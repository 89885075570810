import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { baseTheme } from '@appcues/theme-utilities';
import Input from 'components/Common/Forms/Input';

const Wrapper = styled.div`
  position: relative;
  ${Input} {
    width: 104px;
    height: 40px;
    margin-left: 10px;
    border-width: 1px;
    border-radius: 6px;
  }
`;

const Unit = styled.span`
  color: ${props => props.theme['$gray-5']};
  position: absolute;
  right: 10px;
  top: 10px;
`;

const UnitInput = ({ draftTheme, label, max, min, setDraftTheme, unit }) => {
  const [inputVal, setInputVal] = useState(
    Number.parseInt(draftTheme[label.name] || baseTheme[label.name], 10)
  );

  const handleChange = evt => {
    const { value } = evt.target;
    const { name } = label;
    let tempValue = value;
    if (value > max) {
      tempValue = max;
    }

    if (value < min) {
      tempValue = min;
    }
    setInputVal(tempValue);
    setDraftTheme(prevDraftTheme => {
      return {
        ...prevDraftTheme,
        [name]: `${tempValue}${unit}`,
      };
    });
  };

  return (
    <Wrapper>
      <Input
        type="number"
        min={min}
        max={max}
        value={inputVal}
        placeholder={baseTheme[label.name] || '-'}
        onChange={handleChange}
      />
      <Unit>{unit}</Unit>
    </Wrapper>
  );
};

UnitInput.defaultProps = {
  max: 1000,
  min: 0,
};

UnitInput.propTypes = {
  draftTheme: PropTypes.object,
  label: PropTypes.object,
  max: PropTypes.number,
  min: PropTypes.number,
  setDraftTheme: PropTypes.func.isRequired,
  unit: PropTypes.string.isRequired,
};

export default UnitInput;
