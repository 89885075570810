import {
  put,
  getContext,
  takeEvery,
  call,
  fork,
  take,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { SWMessageChannel } from '@studio/entitlements';
import {
  EXPERIENCE_PUBLISHED,
  EXPERIENCE_UNPUBLISHED,
} from 'next/entities/experiences';
import { INITIALIZE } from 'next/root/actions';
import { resolve, reject } from './actions';

export const normalize = results => {
  return results.reduce((acc, next) => {
    acc[next.name] = next;
    return acc;
  }, {});
};

function* fetchEntitlements() {
  try {
    const api = yield getContext('api');
    const results = yield call(api.getEntitlements);
    yield put(resolve(normalize(results)));
  } catch (error) {
    yield put(reject(error));
  }
}

function* watchServiceWorkerMessages() {
  const channel = new SWMessageChannel();
  const chan = eventChannel(emit => {
    channel.listenForMessage(data => emit({ data }));

    return () => {};
  });

  while (true) {
    yield take(chan);
    yield call(fetchEntitlements);
  }
}

export default function* saga() {
  yield fork(watchServiceWorkerMessages);
  yield takeEvery(INITIALIZE, fetchEntitlements);
  yield takeEvery(EXPERIENCE_PUBLISHED, fetchEntitlements);
  yield takeEvery(EXPERIENCE_UNPUBLISHED, fetchEntitlements);
}
