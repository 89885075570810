import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { SubTitle, Text, Button, TileGroup, Icon } from '@appcues/sonar';
import { history } from 'next/lib/history';
import LeverageData from 'next/assets/images/leverage-data.png';
import AnnounceNewFeatures from 'next/assets/images/announce-new-features.svg';
import IncreaseProductAdoption from 'next/assets/images/increase-product-adoption.svg';
import ProvideInAppSupport from 'next/assets/images/provide-in-app-support.png';
import SignOut from 'next/components/SignOut';
import { selectUser, Shape as UserShape } from 'next/entities/user';
import {
  update,
  selectAccountUser,
  readOne,
  selectCanInviteTeamMembers,
} from 'next/entities/account-users';
import {
  Wrapper,
  UserIntentCard,
  Header,
  Actions,
  UserIntentForm,
  Image,
  UserIntentCardWrapper,
  Intent,
  IntentOptionsWrapper,
} from './styled';

export const INTENT_OPTIONS = [
  {
    label: 'Explore Appcues features and capabilities',
    value: 'explore-appcues-features-and-capabilities',
    image: LeverageData,
  },
  {
    label: 'See how Appcues supports my use case',
    value: 'see-how-appcues-supports-my-use-case',
    image: IncreaseProductAdoption,
  },
  {
    label: 'Build something to show my team',
    value: 'build-something-to-show-my-team',
    image: AnnounceNewFeatures,
  },
  {
    label: 'Install the SDK on my product',
    value: 'install-the-sdk-on-my-product',
    image: ProvideInAppSupport,
  },
];

export function UserIntent({ onSubmit, canInvite = false, user, onLoad }) {
  const [userIntent, setUserIntent] = useState([]);

  useEffect(() => {
    onLoad?.(user.id);
  }, [onLoad, user.id]);

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(user.id, { userIntent });
    history.push(canInvite ? '/invite-team-members' : '/');
  };

  const handleChange = newUserIntent => {
    setUserIntent(newUserIntent);
  };

  return (
    <Wrapper>
      <UserIntentCardWrapper>
        <UserIntentCard>
          <Header>
            <SubTitle>I'm here to:</SubTitle>
            <Text>Why did you sign up for Appcues today?</Text>
          </Header>
          <UserIntentForm onSubmit={handleSubmit}>
            <IntentOptionsWrapper>
              <TileGroup type="single" onValueChange={handleChange}>
                {INTENT_OPTIONS.map(({ label, value, image }) => (
                  <Intent key={value} label={label} value={value}>
                    <Image src={image} />
                  </Intent>
                ))}
              </TileGroup>
            </IntentOptionsWrapper>
            <Actions>
              <Button disabled={userIntent.length === 0}>
                {canInvite ? (
                  <>
                    Next
                    <Icon icon="arrow-right" />
                  </>
                ) : (
                  'Get Started'
                )}
              </Button>
              <Button
                type="button"
                onClick={() => {
                  history.push('/usage');
                }}
                variant="secondary"
              >
                <Icon icon="arrow-left" />
                Back
              </Button>
            </Actions>
          </UserIntentForm>
        </UserIntentCard>
      </UserIntentCardWrapper>
      <SignOut />
    </Wrapper>
  );
}

UserIntent.propTypes = {
  onSubmit: PropTypes.func,
  user: UserShape,
  onLoad: PropTypes.func,
  canInvite: PropTypes.bool,
};

const mapDispatchToProps = {
  onSubmit: update,
  onLoad: readOne,
};

const mapStateToProps = state => {
  const user = selectUser(state);
  return {
    user,
    accountUser: selectAccountUser(state, user?.id),
    canInvite: selectCanInviteTeamMembers(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserIntent);
