import * as actionTypes from 'constants/actionTypes';

export const trackEvent = (eventName, properties, options = {}) => {
  return {
    type: actionTypes.TRACK_EVENT,
    payload: {
      eventName,
      properties,
      options,
    },
  };
};
