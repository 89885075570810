import PropTypes from 'prop-types';
import pick from 'lodash.pick';

/**
 * Full shape of account data model
 *
 * NOTE: The account meta data model is quite large, but also not consistent so
 *       the below are only the fields used within studio.
 */
const Shape = {
  name: PropTypes.string,
  createdAt: PropTypes.number,
  flowsShownLimit: PropTypes.number,
  id: PropTypes.string,
  isTrial: PropTypes.bool,
  isTrialExpired: PropTypes.bool,
  tier: PropTypes.string,
  trialLengthDays: PropTypes.number,
};

/**
 * Allowed fields for storage
 */
const allowed = Object.keys(Shape);

/**
 * Account parsing function
 *
 * @param {Object<keyof Account>} account - Object with account fields
 * @return {Object<keyof Account>} Object with parsed account fields
 */
export const parse = account => pick(account, allowed);

export default PropTypes.shape(parse(Shape));
