import React from 'react';
import { H4 } from '@appcues/component-library';

const GuideHeading = ({ children }) => (
  <H4 type="secondary" marginTop={40} marginBottom={15}>
    {children}
  </H4>
);

export default GuideHeading;
