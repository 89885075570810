import {
  REPLACE_ACCOUNT_CONDITIONS,
  REPLACE_ACCOUNT_STEP_CONDITIONS,
  UPDATE_ACCOUNT_STEP_CONDITIONS,
  ADD_CLAUSE_AND_FLUSH_RULE,
  ADD_GROUP_CLAUSE_AND_FLUSH_RULE,
  UPDATE_CLAUSE_AND_FLUSH_RULE,
  REPLACE_CLAUSE_AND_FLUSH_RULE,
  DISSOLVE_GROUP_CLAUSE_AND_FLUSH_RULE,
  DELETE_CLAUSE_AND_FLUSH_RULE,
  DELETE_MATCHING_CLAUSES_AND_FLUSH_RULE,
  SET_DISPLAY_ANY_PAGE_AND_FLUSH_RULE,
  SET_DISPLAY_SPECIFIC_PAGE_AND_FLUSH_RULE,
  SET_AUDIENCE_ALL_USERS_AND_FLUSH_RULE,
  SET_AUDIENCE_SEGMENT_AND_FLUSH_RULE,
  SET_AUDIENCE_CUSTOM_SEGMENT_AND_FLUSH_RULE,
} from 'constants/account/conditions';

export function addClauseAndFlushRule(id, parentId, clause) {
  return {
    type: ADD_CLAUSE_AND_FLUSH_RULE,
    payload: {
      id,
      parentId,
      clause,
    },
  };
}

export function addGroupClauseAndFlushRule(id, parentId, value, childClauses) {
  return {
    type: ADD_GROUP_CLAUSE_AND_FLUSH_RULE,
    payload: {
      id,
      parentId,
      value,
      childClauses,
    },
  };
}

export function updateClauseAndFlushRule(id, clauseId, changes) {
  return {
    type: UPDATE_CLAUSE_AND_FLUSH_RULE,
    payload: {
      id,
      clauseId,
      changes,
    },
  };
}

export function replaceClauseAndFlushRule(id, clauseId, clause) {
  return {
    type: REPLACE_CLAUSE_AND_FLUSH_RULE,
    payload: {
      id,
      clauseId,
      clause,
    },
  };
}

export function dissolveGroupClauseAndFlushRule(id, clauseId) {
  return {
    type: DISSOLVE_GROUP_CLAUSE_AND_FLUSH_RULE,
    payload: {
      id,
      clauseId,
    },
  };
}

export function deleteClauseAndFlushRule(id, clauseId) {
  return {
    type: DELETE_CLAUSE_AND_FLUSH_RULE,
    payload: {
      id,
      clauseId,
    },
  };
}

export function deleteMatchingClausesAndFlushRule(id, filter) {
  return {
    type: DELETE_MATCHING_CLAUSES_AND_FLUSH_RULE,
    payload: {
      id,
      filter,
    },
  };
}

export function setDisplayAnyPageAndFlushRule(id) {
  return {
    type: SET_DISPLAY_ANY_PAGE_AND_FLUSH_RULE,
    payload: {
      id,
    },
  };
}

export function setDisplaySpecificPageAndFlushRule(id, replacementClauses) {
  return {
    type: SET_DISPLAY_SPECIFIC_PAGE_AND_FLUSH_RULE,
    payload: {
      id,
      replacementClauses,
    },
  };
}

export function setAudienceAllUsersAndFlushRule(id) {
  return {
    type: SET_AUDIENCE_ALL_USERS_AND_FLUSH_RULE,
    payload: {
      id,
    },
  };
}

export function setAudienceSegmentAndFlushRule(id, segmentId) {
  return {
    type: SET_AUDIENCE_SEGMENT_AND_FLUSH_RULE,
    payload: {
      id,
      segmentId,
    },
  };
}

export function setAudienceCustomSegmentAndFlushRule(id, replacementClauses) {
  return {
    type: SET_AUDIENCE_CUSTOM_SEGMENT_AND_FLUSH_RULE,
    payload: {
      id,
      replacementClauses,
    },
  };
}

export function updateConditions(id, conditions) {
  return {
    type: UPDATE_ACCOUNT_STEP_CONDITIONS,
    payload: { id, conditions },
  };
}

export function replaceAccountStepConditions(id, conditions) {
  return {
    type: REPLACE_ACCOUNT_STEP_CONDITIONS,
    payload: { id, conditions },
  };
}

export function replaceAccountConditions(conditions) {
  return {
    type: REPLACE_ACCOUNT_CONDITIONS,
    payload: conditions,
  };
}
