import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  ErrorMsg,
  H3,
  Label,
  Panel,
  PanelBody,
  PanelHeader,
  PanelTitle,
} from '@studio/legacy-components';
import {
  update as updateExperience,
  selectExperience,
} from 'next/entities/experiences';

import { DetailsForm, HalfSizeInput } from './styled';

export const emptyNameErrorMsg =
  'A Flow name is required. This change will not be saved.';

export function Details({ onSave, name }) {
  const [draftFlowName, setDraftFlowName] = useState(name);

  const handleNameSubmit = value => {
    if (!value || value === name) return;

    onSave({ name: value });
  };

  const isNameEmpty = !draftFlowName;

  useEffect(() => {
    setDraftFlowName(name);
  }, [name]);

  return (
    <Panel>
      <PanelHeader>
        <PanelTitle>
          <H3>Details</H3>
        </PanelTitle>
      </PanelHeader>
      <PanelBody>
        <DetailsForm>
          <Label htmlFor="name">
            Flow name
            <HalfSizeInput
              id="name"
              type="text"
              name="Flow name"
              placeholder="Flow name"
              value={draftFlowName}
              error={isNameEmpty}
              onChange={({ target: { value } }) => setDraftFlowName(value)}
              onBlur={({ target: { value } }) => handleNameSubmit(value)}
            />
            {isNameEmpty && <ErrorMsg>{emptyNameErrorMsg}</ErrorMsg>}
          </Label>
        </DetailsForm>
      </PanelBody>
    </Panel>
  );
}

Details.propTypes = {
  onSave: PropTypes.func,
  name: PropTypes.string,
};

const mapDispatchToProps = (dispatch, { id }) => ({
  onSave: delta => dispatch(updateExperience(id, delta)),
});

const mapStateToProps = (state, { id }) => {
  const experience = selectExperience(state, id);

  return {
    name: experience.name,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
