import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Text } from '@appcues/component-library';
import { Icon, Tooltip } from '@studio/legacy-components';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons-legacy/faCircleNotch';

import toast from 'next/lib/toast';
import { showModal } from 'actions/currentModal';
import { selectAccountMeta } from 'reducers/account/meta';
import { selectAccountStats } from 'reducers/account/stats';
import {
  CONFIRM_REMOVE_INTEGRATION_MODAL,
  CONFIRM_ACTIVATE_INTEGRATION_MODAL,
  INSTALL_TO_INTEGRATE_MODAL,
  ZAPIER_INTEGRATION_MODAL,
} from 'constants/globals';
import { trackEvent } from 'actions/events';
import betaFlag from 'assets/images/beta-flag.svg';
import {
  selectInstalledDomainsLoading,
  selectIsInstalled,
} from 'reducers/account/installedDomains';

import IntegrationBeacon from 'components/IntegrationsPage/IntegrationBeacon';
import {
  BetaFlag,
  CardAction,
  CardFooter,
  IntegrationCardWrapper,
  IntegrationImage,
} from 'components/IntegrationsPage/styled';

import prismaticClient, {
  HUBSPOT_INTEGRATION_ID,
  HUBSPOT_PRISMATIC_INTEGRATION,
  MARKETO_INTEGRATION_ID,
  MARKETO_PRISMATIC_INTEGRATION,
  SALESFORCE_INTEGRATION_ID,
  SALESFORCE_PRISMATIC_INTEGRATION,
  SLACK_INTEGRATION_ID,
  SLACK_PRISMATIC_INTEGRATION,
  ZENDESK_SUPPORT_INTEGRATION_ID,
  ZENDESK_SUPPORT_PRISMATIC_INTEGRATION,
} from 'clients/prismatic-api';

/**
 * There's an important key to understand how this component is rendered
 * If the integration is available, it means that it is available to the current plan
 * If the integration is enabled, it means that the user has already activated the integration
 */
function IntegrationCard({
  actions,
  integration = { id: '', name: '' },
  isEnabled = false,
  isInstalled,
  isLoadingDomains,
  isLoadingPrismatic,
}) {
  const { available, beta, hideCardAction, id, isTwoWay, name } = integration;

  const onClickIntegrationCard = () => {
    if (!available) {
      return;
    }

    if (isLoadingDomains) {
      return;
    }

    if (!isInstalled) {
      actions.showModal(INSTALL_TO_INTEGRATE_MODAL);
    } else if (name === 'Zapier') {
      actions.showModal(ZAPIER_INTEGRATION_MODAL, { integration });
    } else
      switch (id) {
        case SALESFORCE_INTEGRATION_ID: {
          prismaticClient
            .configureInstance(SALESFORCE_PRISMATIC_INTEGRATION)
            .catch(() => {
              toast.error(
                'An error occurred while configuring Salesforce. Please try again.'
              );
            });
          break;
        }
        case HUBSPOT_INTEGRATION_ID: {
          prismaticClient
            .configureInstance(HUBSPOT_PRISMATIC_INTEGRATION)
            .catch(() => {
              toast.error(
                'An error occurred while configuring Hubspot. Please try again.'
              );
            });
          break;
        }
        case ZENDESK_SUPPORT_INTEGRATION_ID: {
          prismaticClient
            .configureInstance(ZENDESK_SUPPORT_PRISMATIC_INTEGRATION)
            .catch(() => {
              toast.error(
                'An error occurred while configuring Zendesk Support. Please try again.'
              );
            });
          break;
        }
        case SLACK_INTEGRATION_ID: {
          prismaticClient
            .configureInstance(SLACK_PRISMATIC_INTEGRATION)
            .catch(() => {
              toast.error(
                'An error occurred while configuring Slack. Please try again.'
              );
            });
          break;
        }
        case MARKETO_INTEGRATION_ID: {
          prismaticClient
            .configureInstance(MARKETO_PRISMATIC_INTEGRATION)
            .catch(() => {
              toast.error(
                'An error occurred while configuring Marketo. Please try again.'
              );
            });
          break;
        }
        default:
          if (!isEnabled) {
            actions.showModal(CONFIRM_ACTIVATE_INTEGRATION_MODAL, {
              integration,
            });
          } else {
            actions.showModal(CONFIRM_REMOVE_INTEGRATION_MODAL, {
              integration,
            });
          }
      }
  };

  // This ID is going to be used for CTT
  const cardId = `card-${id}-${available ? 'enabled' : 'disabled'}`;

  // eslint-disable-next-line import/no-dynamic-require, global-require
  const { default: image } = require(`assets/integrations/${id}.png`);

  return (
    <Tooltip
      disabled={available}
      label={
        <>
          <IntegrationBeacon integration={name}>Upgrade</IntegrationBeacon> to
          get access to this integration.
        </>
      }
      persist
      size="m"
    >
      <IntegrationCardWrapper
        id={cardId}
        available={!isLoadingDomains && available}
        isEnabled={isEnabled}
        onClick={onClickIntegrationCard}
        role="button"
        tabIndex={0}
      >
        {!hideCardAction && (
          <CardAction>
            {isLoadingDomains || isLoadingPrismatic ? (
              <Icon icon={faCircleNotch} className="fa-spin" />
            ) : (
              <Icon icon={isEnabled ? 'times' : 'plus'} />
            )}
          </CardAction>
        )}
        {beta && <BetaFlag alt="Beta feature" src={betaFlag} />}
        <IntegrationImage
          available={available}
          src={image}
          alt={integration.name}
        />
        {isTwoWay && (
          <CardFooter available={available}>
            <Icon icon="exchange-alt" /> <Text>Two-way</Text>
          </CardFooter>
        )}
      </IntegrationCardWrapper>
    </Tooltip>
  );
}

IntegrationCard.propTypes = {
  integration: PropTypes.shape({
    available: PropTypes.bool,
    beta: PropTypes.bool,
    hideCardAction: PropTypes.bool,
    id: PropTypes.string.isRequired,
    isTwoWay: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }),
  isEnabled: PropTypes.bool,
};

const mapStateToProps = state => {
  const meta = selectAccountMeta(state);
  const accountStats = selectAccountStats(state);
  const isInstalled = selectIsInstalled(state);
  const mau =
    accountStats && accountStats.monthly_active_users
      ? accountStats.monthly_active_users
      : 0;

  return {
    meta,
    mau,
    isInstalled,
    isLoadingDomains: selectInstalledDomainsLoading(state),
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      showModal,
      trackEvent,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationCard);
