import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';

export const BackArrow = styled(Icon)`
  color: var(--blurple);
  margin-right: 20px;

  &:hover {
    cursor: pointer;
  }
`;
