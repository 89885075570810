import { takeEvery, select, call } from 'redux-saga/effects';
import { DISABLE_FULLSTORY } from 'next/entities/features';
import * as actionTypes from 'constants/actionTypes';
import { selectAccountId } from 'reducers/account/meta';
import { selectUserId } from 'reducers/user';
import { getCurrentPath } from 'utils/url';
import { reportError } from 'helpers/error-reporting';

import { trackEvent as trackEventAction } from 'actions/events';
import { patterns as segmentPatterns } from 'actions/account/segments';
import { patterns as rulePatterns } from 'actions/account/rules';
import { patterns as goalPatterns } from 'actions/account/goals';
import { selectAccountFeature } from 'reducers/account/features';

export function* trackEvent(action) {
  const { eventName, properties, options = {} } = action.payload;

  const accountId = yield select(selectAccountId);
  const userId = yield select(selectUserId);

  const isFullStoryDisabled = yield select(
    selectAccountFeature,
    DISABLE_FULLSTORY
  );

  const propertiesWithPath = {
    path: getCurrentPath(),
    ...properties,
  };

  if (window.analytics && userId) {
    window.analytics.track(
      eventName,
      { ...propertiesWithPath, module: 'webapp' },
      { ...options, integrations: { FullStory: !isFullStoryDisabled } }
    );
  }

  if (window.amplitude && accountId) {
    // account/customer level stats are sent directly to amplitude to get around
    // the need for their enterprise package.  HOWEVER: the amplitude installation
    // in my.appcues.com hot-wires `analytics.track` to `amplitude.logEvent` so
    // there is no need to make multiple calls there.
    //
    // we'll probably switch back to a standard installation when studio
    // takes over, so leaving the separate call here.
    // window.amplitude.getInstance('customer').logEvent(eventName, properties);
  }
}

function* trackSegmentCreatedEvent(action) {
  try {
    const {
      payload: { name, id: segment_id },
    } = action;
    yield call(
      trackEvent,
      trackEventAction('Created a Segment', { name, segment_id })
    );
  } catch (error) {
    yield call(reportError, error);
  }
}

function* trackSegmentUpdatedEvent(action) {
  try {
    const {
      payload: { name, id: segment_id },
    } = action;
    yield call(
      trackEvent,
      trackEventAction('Updated a Segment', { name, segment_id })
    );
  } catch (error) {
    yield call(reportError, error);
  }
}

function* trackSegmentDeletedEvent(action) {
  try {
    const {
      payload: { id: segment_id },
    } = action;
    yield call(
      trackEvent,
      trackEventAction('Deleted a Segment', { segment_id })
    );
  } catch (error) {
    yield call(reportError, error);
  }
}

function* trackSetGoalOnRule(action) {
  try {
    const {
      payload: { id: content_id, delta },
    } = action;

    if (delta.goals) {
      const {
        goals: [goal_id],
      } = delta;

      if (goal_id) {
        yield call(
          trackEvent,
          trackEventAction('Set a goal on a rule', {
            content_id,
            goal_id,
          })
        );
      }
    }
  } catch (error) {
    yield call(reportError, error);
  }
}

function* trackGoalCreatedEvent(action) {
  try {
    const {
      payload: { name, id: goal_id },
    } = action;
    yield call(
      trackEvent,
      trackEventAction('Created a goal', { name, goal_id })
    );
  } catch (error) {
    yield call(reportError, error);
  }
}

function* trackGoalUpdatedEvent(action) {
  try {
    const {
      payload: { id: goal_id, disabled },
    } = action;
    yield call(
      trackEvent,
      trackEventAction(disabled ? 'Disabled a goal' : 'Enabled a goal', {
        goal_id,
      })
    );
  } catch (error) {
    yield call(reportError, error);
  }
}

function* trackGoalDeletedEvent(action) {
  try {
    const {
      payload: { id: goal_id },
    } = action;
    yield call(trackEvent, trackEventAction('Deleted a Goal', { goal_id }));
  } catch (error) {
    yield call(reportError, error);
  }
}

function* events() {
  yield takeEvery(actionTypes.TRACK_EVENT, trackEvent);
  yield takeEvery(segmentPatterns.insert, trackSegmentCreatedEvent);
  yield takeEvery(segmentPatterns.replace, trackSegmentUpdatedEvent);
  yield takeEvery(segmentPatterns.remove, trackSegmentDeletedEvent);
  yield takeEvery(rulePatterns.update, trackSetGoalOnRule);
  yield takeEvery(goalPatterns.insert, trackGoalCreatedEvent);
  yield takeEvery(goalPatterns.replace, trackGoalUpdatedEvent);
  yield takeEvery(goalPatterns.remove, trackGoalDeletedEvent);
}

export default events;
