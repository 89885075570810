import React from 'react';
import PropTypes from 'prop-types';
import DisableEnableAppModal from './DisableEnableAppModal';
import { APP_ENABLED } from './lib';

const DisableModal = ({ appId, onClose, visible }) => {
  return (
    <DisableEnableAppModal
      appId={appId}
      onClose={onClose}
      state={APP_ENABLED}
      visible={visible}
    />
  );
};

DisableModal.propTypes = {
  appId: PropTypes.string,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

export default DisableModal;
