import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { string, object } from 'yup';
import { CForm, CButton, CPanel, H2, Row } from '@appcues/component-library';
import { connect } from 'react-redux';
import { LAUNCHPADS_V2 } from 'next/entities/features';
import Panel from 'components/Common/Panel';
import CoolTip from 'components/Common/CoolTip';
import OpenInBuilderButton from 'components/OpenInBuilder/OpenInBuilderButton';
import { selectAccountFeature } from 'reducers/account/features';

class FlowInformationPanel extends Component {
  formRef = React.createRef();

  componentDidUpdate(prevProps) {
    const { name, previewUrl } = this.props;
    const didRulePreviewUrlUpdate = previewUrl !== prevProps.previewUrl;
    const didStepNameUpdate = name !== prevProps.name;
    if (
      (this.formRef.current && didRulePreviewUrlUpdate) ||
      didStepNameUpdate
    ) {
      this.formRef.current.resetForm();
    }
  }

  handleSubmit = values => {
    const { id, name, previewUrl, onSubmit } = this.props;
    const updates = {
      publicName: values.publicName,
    };

    if (previewUrl !== values.previewUrl) {
      updates.previewUrl = values.previewUrl;
    }
    if (name !== values.name) {
      updates.name = values.name;
    }
    onSubmit(id, updates);
  };

  render() {
    const {
      id,
      name,
      previewUrl,
      publicName,
      state,
      shouldShowOpenInBuilderAction,
      isLocalized,
      hasLaunchpads,
    } = this.props;
    const disabled = state === 'ARCHIVED';

    return (
      <CForm
        initialValues={{ name, previewUrl, publicName }}
        validationSchema={object().shape({
          name: string().required('A flow name is required'),
          previewUrl: string()
            .urlWithLocalhost()
            .required('A build URL is required'),
          publicName: string().nullable(),
        })}
        ref={this.formRef}
        onSubmit={this.handleSubmit}
        width="100%"
      >
        <Panel>
          <CPanel.Header>
            <H2>Flow information</H2>
            <CPanel.Header.Actions>
              <CForm.SubmitButton type="positive">Save</CForm.SubmitButton>
              {shouldShowOpenInBuilderAction && (
                <CoolTip
                  tip="This flow has been localized, so content cannot be edited, only styling and positioning."
                  position="left"
                  disabled={!isLocalized}
                >
                  <OpenInBuilderButton
                    stepGroupId={id}
                    render={handleClick => (
                      <CButton type="primary" onClick={handleClick}>
                        Open in Builder
                      </CButton>
                    )}
                  />
                </CoolTip>
              )}
            </CPanel.Header.Actions>
          </CPanel.Header>
          <Row flexWrap="wrap" gutterY={0}>
            <CForm.Field
              name="name"
              label="Flow name"
              disabled={disabled}
              placeholder="My flow name"
            />

            {hasLaunchpads && (
              <CForm.Field
                name="publicName"
                label="Display name"
                disabled={disabled}
                placeholder="Flow name visible to your customers"
              />
            )}

            <CForm.Field
              name="previewUrl"
              label="Build URL"
              disabled={disabled}
              placeholder="https://www.example.com"
              helperText="Used only for editing and testing flows"
            />
          </Row>
        </Panel>
      </CForm>
    );
  }
}

FlowInformationPanel.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  previewUrl: PropTypes.string,
  publicName: PropTypes.string,
  state: PropTypes.oneOf(['DRAFT', 'PUBLISHED', 'ARCHIVED']),
  shouldShowOpenInBuilderAction: PropTypes.bool,
  isLocalized: PropTypes.bool,
  hasLaunchpads: PropTypes.bool,
};

FlowInformationPanel.defaultProps = {
  shouldShowOpenInBuilderAction: false,
};

const mapStateToProps = state => {
  return {
    hasLaunchpads: selectAccountFeature(state, LAUNCHPADS_V2),
  };
};

export default connect(mapStateToProps)(FlowInformationPanel);
