import PropTypes from 'prop-types';
import { DEVTOOLS_REGISTRY } from './constants';

export default PropTypes.shape(
  Object.fromEntries(
    Object.keys(DEVTOOLS_REGISTRY).map(feature => {
      return [feature, PropTypes.bool];
    })
  )
);
