export const EVENT_SOURCES = ['application', 'builder', 'checklist', 'flow'];

export const APPLICATION_EVENTS_LABEL = 'Application Events';
export const BUILDER_EVENTS_LABEL = 'Track Events (Builder)';
export const GOALS_EVENTS_LABEL = 'Goal Events';
export const CHECKLIST_EVENTS_LABEL = 'Checklist Events';
export const FLOW_EVENTS_LABEL = 'Flow Events';
export const PIN_EVENTS_LABEL = 'Pin Events';
export const GOAL_EVENTS_LABEL = 'Goal Events';
export const BANNER_EVENTS_LABEL = 'Banner Events';

export const PIN_TOOLTIP_TYPE = 'tooltip';
export const PIN_BUTTON_TYPE = 'button';

export const SELECTED_EVENT_SOURCE = 'appcues.selected.event.source';

export const EVENT_SOURCE_LABELS_SINGULAR = {
  application: 'Application Event',
  builder: 'Track Event (Builder)',
  checklist: 'Checklist Event',
  flow: 'Flow Event',
};

export const EVENT_SOURCE_OPTIONS = [
  {
    value: 'flow',
    label: FLOW_EVENTS_LABEL,
    tooltip: 'Flow started and completed events, and Flow issues',
  },
  {
    value: 'checklist',
    label: CHECKLIST_EVENTS_LABEL,
    tooltip: 'Checklist shown and completed events',
  },
  {
    value: 'pin',
    label: PIN_EVENTS_LABEL,
    tooltip: 'Tooltip opens and button clicks',
  },
  {
    value: 'banner',
    label: BANNER_EVENTS_LABEL,
    tooltip: 'Banner views, button/link click, dismissals',
  },
  {
    value: 'application',
    label: APPLICATION_EVENTS_LABEL,
    tooltip:
      'Events from the Appcues SDK, Appcues Server-side API, and Segment',
  },
  {
    value: 'builder',
    label: BUILDER_EVENTS_LABEL,
    tooltip: 'Events tracked with the Appcues Builder',
  },
  {
    value: 'goals',
    label: GOALS_EVENTS_LABEL,
    tooltip: 'Goal Events',
  },
];

export const FLOW_EVENTS = [
  { type: 'appcues:flow_started', label: 'Flow Started', abbrev: 'fs' },
  { type: 'appcues:flow_completed', label: 'Flow Completed', abbrev: 'fc' },
  { type: 'appcues:step_child_error', label: 'Flow Issues', abbrev: 'fi' },
];
export const MOBILE_EVENTS = [
  {
    type: 'appcues:v2:experience_started',
    label: 'Flow Started',
    abbrev: 'es',
  },
  {
    type: 'appcues:v2:experience_completed',
    label: 'Flow Completed',
    abbrev: 'ec',
  },
  {
    type: 'appcues:v2:step_error',
    label: 'Flow Issues',
    abbrev: 'ei',
  },
];
export const PIN_EVENTS = [
  { type: 'appcues:v2:step_seen', label: 'Pin Seen', abbrev: 'ss' },
  {
    type: 'appcues:v2:step_interaction',
    label: 'Pin Interacted',
    abbrev: 'si',
  },
];
export const GOAL_EVENTS = [
  { type: 'appcues:goal_reached', label: 'Goal Achieved', abbrev: 'gr' },
  {
    type: 'appcues:goal_missed',
    label: 'Goals Missed',
    abbrev: 'gm',
  },
];

export const CHECKLIST_EVENTS = [
  { type: 'appcues:checklist_shown', label: 'Checklist Shown', abbrev: 'cs' },
  {
    type: 'appcues:checklist_completed',
    label: 'Checklist Completed',
    abbrev: 'cc',
  },
  {
    type: 'appcues:checklist_skipped',
    label: 'Checklist Skipped',
    abbrev: 'csk',
  },
];

export const BANNER_EVENTS = [
  {
    type: 'appcues:v2:step_seen',
    label: 'Unique Users',
    abbrev: 'ss',
    cellLabel: 'times shown',
  },
  {
    type: 'appcues:v2:step_interaction',
    label: 'Total Click-Throughs',
    abbrev: 'si',
    cellLabel: 'times clicked',
  },
  {
    type: 'appcues:v2:experience_dismissed',
    label: 'Total Dismissals',
    abbrev: 'ed',
    cellLabel: 'times dismissed',
  },
];

export const BANNER_EVENTS_TYPES = BANNER_EVENTS.map(({ type }) => type);

export const MAX_SELECTED_EVENTS = 10;
