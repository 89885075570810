import React from 'react';
import { Text, Link } from '@appcues/sonar';
import { TermsOfServiceWrapper } from './styled';

export default function TermsOfService() {
  return (
    <TermsOfServiceWrapper>
      <Text colorToken="color-neutral-400">
        For additional information on payments, account management, or
        cancellation, please reference our{' '}
        <Link weight="bold" href="https://www.appcues.com/terms">
          Terms of Service.
        </Link>
      </Text>
    </TermsOfServiceWrapper>
  );
}
