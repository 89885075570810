import React, { useMemo } from 'react';
import { CButton } from '@appcues/component-library';
import CoolTip from 'components/Common/CoolTip';

export const ButtonWithTooltip = ({
  isDisabled,
  buttonText,
  tooltipText,
  buttonProps,
  coolTipProps,
  onClick,
}) => {
  const BasePublishButton = useMemo(
    () => (
      <CButton
        isDisabled={isDisabled}
        type="positive"
        onClick={onClick}
        {...buttonProps}
      >
        {buttonText}
      </CButton>
    ),
    [isDisabled, onClick, buttonText]
  );

  return (
    <div>
      {isDisabled ? (
        <CoolTip tip={tooltipText} position="bottom" {...coolTipProps}>
          {BasePublishButton}
        </CoolTip>
      ) : (
        BasePublishButton
      )}
    </div>
  );
};

ButtonWithTooltip.defaultProps = {
  isDisabled: false,
  buttonText: 'Save',
  tooltipText: '',
  buttonProps: {},
  coolTipProps: {},
};

export default ButtonWithTooltip;
