import React, { Component } from 'react';
import hash from 'object-hash';
import styled from 'styled-components';
import classNames from 'classnames';
import {
  getNestedConditionsFromFlattenedClauses,
  conditionNames,
  groupOperators,
  groupOperatorValues,
  propertyConditionNames,
  clauseKeys,
} from '@appcues/libcues';

import { CButton } from '@appcues/component-library';
import { IconWithinCButton, Select } from '@studio/legacy-components';
import { getVerboseNestedCondition } from 'transforms/conditions';
import { convertRuleToNestedConditions } from 'transforms/migrations';
import { getEmptyClauseForConditionName } from 'utils/conditions';
import { titleCase } from 'utils';

import PropertyClause from 'components/Common/Clauses/Property';
import GroupsClause from 'components/Common/Clauses/Groups';
import LanguageClause from 'components/Common/Clauses/Language';
import SegmentClause from 'components/Common/Clauses/Segment';
import ContentClause from 'components/Common/Clauses/Content';
import EventClause from 'components/Common/Clauses/Event';
import AttributeClause from 'components/Common/Clauses/Attribute';
import ChecklistsClause from 'components/Common/Clauses/Checklists';
import URLClause from 'components/Common/Clauses/URL';
import ButtonWithTooltip from 'components/Common/ButtonWithTooltip';
import { ACCOUNT_PERMISSIONS } from 'constants/accountManagement/permissions';

const SET_TYPE_OPTIONS = [
  { value: groupOperatorValues.AND, label: 'All' },
  { value: groupOperatorValues.OR, label: 'Any' },
];

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &:not(.lite):before {
    content: '';
    position: absolute;
    border-left: 2px dotted #e3e3e3;
    width: 1px;
    height: calc(100% - 50px);
    left: 37.5px;
  }

  .condition-set-row {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    .condition-set-operator {
      width: 70px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      & + .condition-set-value {
        margin-left: 10px;
      }
    }
    .condition-set-value {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-grow: 1;
      > * {
        margin-right: 10px;
      }
      > .condition-set.full {
        margin-right: 0;
      }
    }
    &.contains-condition-set {
      align-items: flex-start;
    }
  }

  // Case where there's only one condition and it's first.
  .condition-set-orphan .condition-set-operator {
    display: none;
    & + .condition-set-value {
      margin-left: 0;
    }
  }
  // Case where there's more than one condition, and we want to hide the first operator, but not the selector.
  .condition-set-select
    + .condition-set-row
    .condition-set-operator
    .button.operator {
    visibility: hidden;
  }

  .segment-matches {
    color: #848484;
    .button {
      margin-left: 10px;
    }
  }
`;

const Operator = styled.div`
  ${Select} {
    max-width: 70px;
    min-width: 70px;
  }
`;

class GroupClause extends Component {
  render() {
    const {
      hideSaveAsSegmentOption,
      clauses,
      rule,
      isRootNode,
      groupType,
      disabled,
      addGroupClause,
      segments,
      applySegment,
      createSegment,
      dissolveGroupClause,
      addClause,
      updateClause,
      replaceClause,
      deleteClause,
      skipSegments,
      segmentBlacklist,
      role,
    } = this.props;
    let { clause } = this.props;

    clause = clause || getVerboseNestedCondition(clauses);

    const clauseType =
      clause.operator === groupOperators.BOOLEAN ? clause.value : undefined;
    const childClauses = clause.children || [];

    const showGroupOperatorSelect = childClauses.length > 1 || !isRootNode;
    const fullOrLiteClass = showGroupOperatorSelect ? 'full' : 'lite';

    const cannotCreateSegment = role === ACCOUNT_PERMISSIONS.EDITOR;

    return (
      <Wrapper className={classNames('condition-set', fullOrLiteClass)}>
        {showGroupOperatorSelect && (
          <div className="condition-set-row condition-set-select">
            <div className="condition-set-operator">
              <Operator>
                <Select
                  options={SET_TYPE_OPTIONS}
                  onChange={({ value }) => {
                    updateClause(clause.id, {
                      operator: groupOperators.BOOLEAN,
                      value,
                    });
                  }}
                  value={SET_TYPE_OPTIONS.find(
                    ({ value }) => value === clauseType
                  )}
                />
              </Operator>
            </div>

            <div className="condition-set-value">
              of the following conditions match
            </div>
          </div>
        )}

        {(() => {
          if (childClauses.length > 0) {
            return childClauses.map((child, i) => {
              const siblingConditions = childClauses.filter(it => {
                return it.id !== child.id;
              });

              // special css class needed if the child is a set (may be possible with pure css, not sure)
              const conditionSet = !child.operator
                ? 'contains-condition-set'
                : '';

              return (
                <div
                  className={`condition-set-row ${conditionSet} ${
                    isRootNode && siblingConditions.length === 0
                      ? ' condition-set-orphan'
                      : ''
                  }`}
                  key={child.id}
                >
                  <div className="condition-set-operator">
                    {i > 0 && (
                      <StyledOperatorBox
                        className={`clause-type-${clauseType}`}
                      >
                        {clauseType}
                      </StyledOperatorBox>
                    )}
                  </div>
                  <div className="condition-set-value">
                    {(() => {
                      const childAttributes = {
                        clause: child,
                        rule,
                        disabled,
                        updateClause,
                        replaceClause,
                        deleteClause,
                        skipSegments,
                        segmentBlacklist,
                        key: child.id,
                      };

                      if (child.operator === groupOperators.BOOLEAN) {
                        return (
                          <GroupClause
                            {...childAttributes}
                            addGroupClause={addGroupClause}
                            dissolveGroupClause={dissolveGroupClause}
                            addClause={addClause}
                          />
                        );
                      }

                      const childClauseAttributes = {
                        ...childAttributes,
                        canDeleteClause:
                          (siblingConditions.length > 0 || !isRootNode) &&
                          !disabled,
                      };

                      switch (child.conditionName) {
                        case propertyConditionNames.PROPERTIES:
                          return <PropertyClause {...childClauseAttributes} />;

                        case propertyConditionNames.GROUPS:
                          return <GroupsClause {...childClauseAttributes} />;

                        case propertyConditionNames.LANGUAGES:
                          return <LanguageClause {...childClauseAttributes} />;

                        case propertyConditionNames.CONTENT:
                          return <ContentClause {...childClauseAttributes} />;

                        case propertyConditionNames.EVENT:
                          return <EventClause {...childClauseAttributes} />;

                        case conditionNames.URL:
                          return <URLClause {...childClauseAttributes} />;

                        case conditionNames.SEGMENTS:
                          return <SegmentClause {...childClauseAttributes} />;

                        case conditionNames.ATTRIBUTES:
                          return <AttributeClause {...childClauseAttributes} />;

                        case conditionNames.CHECKLISTS:
                          return (
                            <ChecklistsClause {...childClauseAttributes} />
                          );

                        default:
                          return null;
                      }
                    })()}
                  </div>
                </div>
              );
            });
          }
          return null;
        })()}

        <div className="condition-set-row">
          <div className="condition-set-value">
            <CButton
              isDisabled={disabled}
              type="positive"
              onClick={() => {
                addClause(
                  clause.id,
                  getEmptyClauseForConditionName(
                    childClauses[childClauses.length - 1].conditionName ||
                      propertyConditionNames.PROPERTIES
                  )
                );
              }}
            >
              Add another
            </CButton>

            {(() => {
              if (addGroupClause) {
                return (
                  <CButton
                    isDisabled={disabled}
                    onClick={() => {
                      addGroupClause(
                        clause.id,
                        clause.value === groupOperatorValues.AND
                          ? groupOperatorValues.OR
                          : groupOperatorValues.AND,
                        [
                          getEmptyClauseForConditionName(
                            propertyConditionNames.PROPERTIES
                          ),
                        ]
                      );
                    }}
                  >
                    <IconWithinCButton icon="expand" gray />
                    Add logic group
                  </CButton>
                );
              }
              return null;
            })()}

            {(() => {
              if (!isRootNode) {
                return (
                  <CButton
                    isDisabled={disabled}
                    onClick={() => {
                      dissolveGroupClause(clause.id);
                    }}
                  >
                    Ungroup
                  </CButton>
                );
              }
              return null;
            })()}

            {(() => {
              if (isRootNode && applySegment) {
                const nestedUserPropertyClause =
                  getNestedConditionsFromFlattenedClauses(
                    clauses,
                    null,
                    clauseKeys
                  );
                const userPropertyClauseHash = hash(nestedUserPropertyClause);

                const matchingSegment = Object.values(segments || {}).find(
                  segment => {
                    const conditions =
                      segment.conditions ||
                      convertRuleToNestedConditions(segment).and[0];
                    return (
                      conditions && hash(conditions) === userPropertyClauseHash
                    );
                  }
                );

                if (matchingSegment) {
                  return (
                    <div className="segment-matches">
                      Heads up! This targeting matches the &ldquo;
                      {matchingSegment.name}&rdquo; {groupType} but isn&apos;t
                      linked
                      <CButton onClick={() => applySegment(matchingSegment.id)}>
                        Use It!
                      </CButton>
                    </div>
                  );
                }
                if (!hideSaveAsSegmentOption) {
                  return (
                    <ButtonWithTooltip
                      isDisabled={cannotCreateSegment}
                      onClick={createSegment}
                      buttonText={`Save as ${titleCase(groupType)}`}
                      tooltipText="Contact a team admin or publisher to create new segments"
                      coolTipProps={{ marginRight: '36px' }}
                    />
                  );
                }
              }
              return null;
            })()}
          </div>
        </div>
      </Wrapper>
    );
  }
}

const StyledOperatorBox = styled.div`
  z-index: 0;
  min-width: 48px;
  padding: 2px 8px 3px;
  text-align: center;

  font-size: 1rem;
  line-height: 1.625rem;

  border-radius: 40px;
  color: ${props => props.theme['$gray-7']};
  background-color: #defaf9;

  &.clause-type-or {
    background-color: ${props => props.theme['$yellow-light']};
  }
`;

export default GroupClause;
