/* global APPCUES_TEST_MODE_LAMBDA_URL */

import { reportError } from 'helpers/error-reporting';

// eslint-disable-next-line no-restricted-imports
import authClient from 'helpers/auth-client';

/* global APPCUES_CONTENT_API_URL */

export const getContentApiHeaders = async () => {
  const currentCustomToken = await authClient.getToken();
  return {
    Authorization: `Bearer ${currentCustomToken}`,
    'Content-Type': 'application/json',
  };
};

/**
 * Hits content api to create the default lifecycle segments in the
 * user's account
 * @param  {Obj} data - account_id
 *                    - account_api_key
 * @return {Promise<Obj>}      Resolves with {data {segments: [ ]}}
 */
export const createLifecycleSegments = async data => {
  const requestHeaders = await getContentApiHeaders();
  const response = await fetch(
    `${APPCUES_CONTENT_API_URL}/default-content/product-led-segments/`,
    {
      method: 'post',
      headers: requestHeaders,
      body: JSON.stringify(data),
    }
  );

  const responseData = await response.json();
  if (response.status === 200) {
    if (
      typeof responseData !== 'object' ||
      typeof responseData.data !== 'object' ||
      typeof responseData.data.segments !== 'object' ||
      typeof responseData.data.segments.length !== 'number'
    ) {
      throw new TypeError(
        'Invalid response data returned by content api product-led-segments endpoint'
      );
    }
    return responseData;
  }
  throw new Error(responseData.message);
};

export const getTestFlowUrl = (accountId, draftFlowId) => {
  if (!accountId || !draftFlowId) {
    reportError('Invalid accountId or draftFlowId passed to getTestFlowUrl');
    return '';
  }

  return `${APPCUES_TEST_MODE_LAMBDA_URL}?accountId=${accountId}&draftFlowId=${draftFlowId}`;
};

export default {
  createLifecycleSegments,
  getTestFlowUrl,
};
