import {
  addClause,
  deleteClause,
  getFilteredClauseTree,
  getMasterClause,
  groupOperators,
  propertyOperators,
} from '@appcues/libcues';

import { filterUrlTargetingClauses } from 'utils/conditions';

/**
 * Given a set of changes and a clauseId, applies changes to the
 * specified clause and returns updated state
 *
 * @param clauses
 * @param clauseId
 * @param changes
 * @returns {*}
 */

export function updateClause(clauses, clauseId, changes) {
  const clause = (clauses || []).find(({ id }) => id === clauseId);

  if (clause) {
    const clauseIndex = clauses.indexOf(clause);

    return [
      ...clauses.slice(0, clauseIndex),
      { ...clause, ...changes },
      ...clauses.slice(clauseIndex + 1),
    ];
  }

  return clauses;
}

/**
 * Given a clause and a clauseId, replaces the existing clause
 * with that id and assumes its parentId.
 *
 * Differs from `updateClause` in that the new clause isn't
 * merged into the existing (like `changes`), it replaces it.
 *
 * @param clauses
 * @param clauseId
 * @param replacementClause
 * @returns {*}
 */

export function replaceClause(clauses, clauseId, replacementClause) {
  const existingClause = (clauses || []).find(it => it.id === clauseId);

  if (existingClause) {
    const existingClauseIndex = clauses.indexOf(existingClause);

    return [
      ...clauses.slice(0, existingClauseIndex),
      {
        id: clauseId,
        parentId: existingClause.parentId,
        ...replacementClause,
      },
      ...clauses.slice(existingClauseIndex + 1),
    ];
  }

  return clauses;
}

export function replaceClauseBranch(
  maybeClauses,
  replacementClauses,
  maybeMasterClause,
  parentId
) {
  let clauses = maybeClauses;
  const masterClause = maybeMasterClause || getMasterClause(replacementClauses);

  if (masterClause.operator === groupOperators.BOOLEAN) {
    clauses = addClause(clauses, parentId, masterClause);

    /**
     * Assuming here that the masterClause has been added to the conditions reducer in the
     * (n)th position.  Grab it now to determine what `id` it was assigned.
     */

    const groupClauseId = clauses[clauses.length - 1].id;
    const childClauses = replacementClauses.filter(
      clause => clause.parentId === masterClause.id
    );

    childClauses.forEach(childClause => {
      clauses = replaceClauseBranch(
        clauses,
        replacementClauses,
        childClause,
        groupClauseId
      );
    });
  } else {
    clauses = addClause(clauses, parentId, masterClause);
  }

  return clauses;
}

/**
 * Given the clauseId for a group node, deletes it and forces its parent to
 * adopt all of its orphaned children.
 *
 * @param clauses
 * @param clauseId
 */

export function dissolveGroupClause(maybeClauses, clauseId) {
  let clauses = maybeClauses || [];
  const groupClause = clauses.find(it => it.id === clauseId);

  if (groupClause && groupClause.operator === groupOperators.BOOLEAN) {
    const parentId = groupClause.parentId || null;

    const orphanedClauses = clauses.filter(it => it.parentId === clauseId);

    orphanedClauses.forEach(({ id }) => {
      clauses = updateClause(clauses, id, { parentId });
    });

    clauses = deleteClause(clauses, clauseId);
  }

  return clauses;
}

/**
 * Returns the first operator from a clause tree.
 *
 * Useful for subtrees where you know the clauses should all have the same operator
 *
 * @param clauses
 */

export function getFirstClauseOperator(clauses) {
  const firstClause = clauses[0];
  const urlOperator =
    (firstClause && firstClause.operator) || propertyOperators.REGEX;

  return urlOperator;
}

/**
 * Finds the branch of a clause tree that corresponds to URL targeting.
 *
 * @param clauseTree
 * @param includeRoot Whether or not to include the root clause of the tree. Defaults to true.
 */

export function getUrlClauses(clauseTree, includeRoot = true) {
  const urlClauses = getFilteredClauseTree(
    filterUrlTargetingClauses,
    clauseTree || []
  );

  if (!includeRoot) {
    return urlClauses.filter(
      clause => clause.operator !== groupOperators.BOOLEAN
    );
  }

  return urlClauses;
}

/**
 * Returns the root clause of the URL branch of a clause tree, or null if it does not exist.
 *
 * @param clauseTree
 */

export function getParentUrlClause(clauseTree) {
  const urlClauses = getUrlClauses(clauseTree, true);

  const parentClause = urlClauses.find(
    clause => clause.operator === groupOperators.BOOLEAN
  );

  // If there's no parentClause, return null so that one can be created later
  if (!parentClause) {
    return null;
  }

  return parentClause;
}

/**
 * Returns true if all of the operators of the clauses in a clause tree match.
 * Be sure to strip out the root clause, since that will always have a different operator.
 *
 * @param clauseTree
 */

export function allOperatorsMatch(clauses) {
  const firstOperator = getFirstClauseOperator(clauses);
  return clauses.every(clause => {
    return clause.operator === firstOperator;
  });
}
