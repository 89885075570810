/* global CUSTOMER_API_URL PRISMATIC_URL */
import prismaticEmbeddedSDK, { TermOperator } from '@prismatic-io/embedded';

// eslint-disable-next-line no-restricted-imports
import authClient from 'helpers/auth-client';
import createFetchClient from './client-factory';

// These values need to match the name of the integration in the Prismatic Marketplace
export const HUBSPOT_PRISMATIC_INTEGRATION = 'HubSpot Integration';
export const SALESFORCE_PRISMATIC_INTEGRATION = 'Salesforce Integration';
export const SLACK_PRISMATIC_INTEGRATION = 'Slack Integration';
export const MARKETO_PRISMATIC_INTEGRATION = 'Marketo Integration';
export const ZENDESK_SUPPORT_PRISMATIC_INTEGRATION =
  'Zendesk Support Integration';

// These values need to match the id of the integraion in integration-types.json
export const HUBSPOT_INTEGRATION_ID = 'hubspot-two-way-integration';
export const SALESFORCE_INTEGRATION_ID = 'salesforce';
export const SLACK_INTEGRATION_ID = 'slack-integration';
export const MARKETO_INTEGRATION_ID = 'marketo';
export const ZENDESK_SUPPORT_INTEGRATION_ID =
  'zendesk-support-two-way-integration';

const APPCUES_ID_TO_PRISMATIC_NAME = {
  [HUBSPOT_INTEGRATION_ID]: HUBSPOT_PRISMATIC_INTEGRATION,
  [SALESFORCE_INTEGRATION_ID]: SALESFORCE_PRISMATIC_INTEGRATION,
  [SLACK_INTEGRATION_ID]: SLACK_PRISMATIC_INTEGRATION,
  [MARKETO_INTEGRATION_ID]: MARKETO_PRISMATIC_INTEGRATION,
  [ZENDESK_SUPPORT_INTEGRATION_ID]: ZENDESK_SUPPORT_PRISMATIC_INTEGRATION,
};

const getPrismaticQueryforAccount = accountId => {
  return ` {
  instances(enabled: true customer_ExternalId: "${accountId}") {
    totalCount
    nodes {
      configState
      name
      enabled
      integration {
        name
      }
    }
  }
}`;
};

export const isPrismaticEnabled = (appcuesIntegrationId, data) => {
  const integrationName = APPCUES_ID_TO_PRISMATIC_NAME[appcuesIntegrationId];
  if (!integrationName) return false;
  if (!data) return false;
  const integration = data.instances.nodes.find(
    node => node.integration.name === integrationName
  );
  return integration?.enabled === true;
};

function createPrismaticClient() {
  const prismaticPath = '/integrations/prismatic/auth';
  const baseUrl = `${CUSTOMER_API_URL}/v1`;
  const doAuthFetch = createFetchClient(authClient, baseUrl);
  prismaticEmbeddedSDK.init({
    prismaticUrl: PRISMATIC_URL,
  });
  const integrationsPromiseCache = {};
  const client = {
    configureInstance: integrationName => {
      return doAuthFetch(prismaticPath)
        .then(({ data: { auth_token } }) => auth_token)
        .then(token => prismaticEmbeddedSDK.authenticate({ token }))
        .then(() => {
          prismaticEmbeddedSDK.configureInstance({
            integrationName,
            skipRedirectOnRemove: true,
            usePopover: true,
            theme: 'LIGHT',
            filters: {
              marketplace: {
                filterQuery: [TermOperator.equal, 'name', integrationName],
              },
            },
            screenConfiguration: {
              marketplace: { configuration: 'disallow-details' },
              instance: {
                hideBackToMarketplace: true,
                hideTabs: ['Test', 'Executions', 'Monitors', 'Logs'],
              },
              configurationWizard: {
                hideSidebar: true,
                isInModal: true,
                triggerDetailsConfiguration: 'hidden',
              },
            },
          });
        });
    },
    getIntegrations: accountId => {
      if (integrationsPromiseCache[accountId])
        return integrationsPromiseCache[accountId];

      integrationsPromiseCache[accountId] = doAuthFetch(prismaticPath)
        .then(({ data: { auth_token } }) => auth_token)
        .then(token => prismaticEmbeddedSDK.authenticate({ token }))
        .then(() => {
          const query = getPrismaticQueryforAccount(accountId);
          return prismaticEmbeddedSDK.graphqlRequest({
            query,
          });
        })
        .catch(error => {
          throw error;
        })
        .finally(() => {
          delete integrationsPromiseCache[accountId];
        });
      return integrationsPromiseCache[accountId];
    },
  };

  return client;
}

const prismaticClient = createPrismaticClient();
export default prismaticClient;
