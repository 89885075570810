import { isTrialAccount } from '@studio/navigation';
import { createCollectionReducer } from 'next/lib/collections';
import { selectAccount } from 'next/entities/account';
import { selectUser } from 'next/entities/user';
import { selectTrialStatus } from 'next/entities/trial-status';
import { ADMINISTRATOR_OPTION } from 'constants/accountManagement/permissions';
import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

export const selectAccountUsers = state => state[TYPE];
export const selectAccountUser = (state, id) => selectAccountUsers(state)?.[id];

export const selectCanInviteTeamMembers = state => {
  const user = selectUser(state);
  const trialStatus = selectTrialStatus(state);
  const account = selectAccount(state);
  const accountUser = selectAccountUser(state, user?.id);

  if (isTrialAccount(account, trialStatus)) {
    return accountUser?.role === ADMINISTRATOR_OPTION.value;
  }

  const users = selectAccountUsers(state);
  return users.length === 1;
};
