import { useMemo, useState } from 'react';
import { local } from 'next/lib/storage';
import useFilter from 'next/hooks/use-filter';
import { escapeRegExp } from 'next/lib/escape';
/**
 * Internal hook for creating control state managers
 *
 * @param {Control} Control - Control interface
 * @return {[string, function]} Control hook
 */
const useControl = Control => {
  const { key, options, default: fallback } = Control;

  // Get valid initial value of state from local storage or use default fallback
  const initial = useMemo(() => {
    const maybe = local.get(key);

    if (options) {
      const exists = options.some(option => option.value === maybe);
      return exists ? maybe : fallback;
    }

    return maybe ?? fallback;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [control, setControl] = useState(initial);

  // Set value to local state and to local storage
  const persist = value => {
    local.set(key, value);
    setControl(value);
  };

  return [control, persist];
};

/**
 * Use Controls hook creator
 *
 * @param {string} type - Name of experience type
 * @return {Controls} Controls for listing page
 */
export function createUseControls(type) {
  /**
   * Experience search handler
   */
  const Search = {
    key: `appcues:${type}:search`,
    default: '',
    query: value => {
      return ({ name }) => new RegExp(escapeRegExp(value), 'i').test(name);
    },
  };

  /**
   * Experience sorting options and comparators
   */
  const Sort = {
    key: `appcues:${type}:sort`,
    default: 'updated',
    options: [
      { label: 'Name (A-Z)', value: 'atoz' },
      { label: 'Name (Z-A)', value: 'ztoa' },
      { label: 'Recently Published', value: 'published' },
      { label: 'Recently Updated', value: 'updated' },
      { label: 'Recently Created', value: 'created' },
    ],
    comparators: {
      atoz: ({ name: a }, { name: b }) => {
        return a.localeCompare(b, 'en', { sensitivity: 'base', numeric: true });
      },
      ztoa: ({ name: a }, { name: b }) => {
        return b.localeCompare(a, 'en', { sensitivity: 'base', numeric: true });
      },
      published: ({ publishedAt: a }, { publishedAt: b }) => {
        return new Date(b) - new Date(a);
      },
      updated: ({ updatedAt: a }, { updatedAt: b }) => {
        return new Date(b) - new Date(a);
      },
      created: ({ createdAt: a }, { createdAt: b }) => {
        return new Date(b) - new Date(a);
      },
    },
  };

  /**
   * Experience listing view options
   */
  const View = {
    key: `appcues:${type}:view`,
    default: 'list',
    options: [
      { label: 'List', value: 'list' },
      { label: 'Grid', value: 'grid' },
    ],
  };

  /**
   * Controls hooks for experience listing
   */
  const useControls = (experiences = {}, filterConfig = {}) => {
    const [search, setSearch] = useControl(Search);
    const [sort, setSort] = useControl(Sort);
    const [view, setView] = useControl(View);
    const {
      filters,
      filterFunction,
      handleFilterChange,
      handleFilterClear,
      updateFilters,
    } = useFilter(filterConfig);

    const processed = useMemo(
      () =>
        Object.values(experiences.data)
          .filter(filterFunction)
          .filter(Search.query(search))
          .sort(Sort.comparators[sort]),
      [experiences.data, search, sort, filterFunction]
    );

    return {
      processed,
      filters,
      handleFilterChange,
      handleFilterClear,
      updateFilters,
      search: {
        onChange: ({ target: { value } }) => {
          setSearch(value);
        },
        value: search,
      },

      sort: {
        onChange: ({ value }) => {
          setSort(value);
        },
        options: Sort.options,
        value: sort,
      },

      view: {
        onChange: value => {
          setView(value);
        },
        value: view,
      },
    };
  };

  return { useControls, Search, Sort, View };
}
