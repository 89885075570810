/* global NPS_CSS_URL */

import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';
import { PREVIEW_TABS } from 'constants/satisfaction/data';
import SatisfactionQuantitativePreview from './QuantitativeView';
import SatisfactionQualitativePreview from './QualitativeView';

function SatisfactionSurveyEditPreview({
  backgroundColor,
  cancelTestMode,
  className,
  collapsePreview,
  foregroundColor,
  isTestMode,
  previewTab,
  questionOne,
  questionTwo,
  shouldHidePreview,
  toggleSurveyPreview,
}) {
  const setSurveyTab = tab => {
    if (isTestMode && tab === PREVIEW_TABS.SURVEY) {
      cancelTestMode();
    }
    toggleSurveyPreview(shouldHidePreview, tab);
  };

  return (
    <div className={className}>
      {!isTestMode && (
        <div
          className={classNames(
            'nps-survey-tabs',
            collapsePreview ? 'tabs-collapsed' : 'tabs-opened'
          )}
        >
          <div className="tabs-container">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              role="radio"
              tabIndex={0}
              aria-checked={previewTab === PREVIEW_TABS.SURVEY}
              onClick={() => setSurveyTab(PREVIEW_TABS.SURVEY)}
              className={classNames(
                'nps-survey-tab',
                previewTab === PREVIEW_TABS.SURVEY && 'selected'
              )}
            >
              SURVEY PREVIEW
            </div>

            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              role="radio"
              tabIndex={-1}
              aria-checked={previewTab === PREVIEW_TABS.FEEDBACK}
              onClick={() => setSurveyTab(PREVIEW_TABS.FEEDBACK)}
              className={classNames(
                'nps-survey-tab',
                previewTab === PREVIEW_TABS.FEEDBACK && 'selected'
              )}
            >
              FEEDBACK PREVIEW
            </div>

            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              role="button"
              tabIndex={-1}
              className={classNames(
                'nps-survey-tab',
                'nps-survey-tab-collapse',
                !collapsePreview && 'active'
              )}
              onClick={() => toggleSurveyPreview(!collapsePreview)}
            >
              {!collapsePreview ? (
                <Icon icon="angle-double-down" aria-hidden="true" />
              ) : (
                <Icon icon="angle-double-up" aria-hidden="true" />
              )}
            </div>
          </div>
        </div>
      )}
      <div
        className={classNames(
          'nps-survey-preview',
          collapsePreview && !isTestMode ? 'preview-hidden' : '',
          !isTestMode && !collapsePreview ? 'sample-preview' : ''
        )}
      >
        <appcues-container
          data-pattern-type="nps-survey"
          data-position="bottom"
        >
          <div>
            <link href={NPS_CSS_URL} type="text/css" rel="stylesheet" />
            <div
              className="active"
              data-pattern-type="nps-survey"
              data-position="bottom"
            >
              <survey-container>
                <div className="active">
                  <div>
                    <section>
                      <div
                        className={classNames(
                          'appcues-nps',
                          collapsePreview && 'preview-hidden',
                          isTestMode && 'nps-test'
                        )}
                      >
                        <div
                          className={`nps-modal ${
                            previewTab === PREVIEW_TABS.FEEDBACK
                              ? 'qualitative'
                              : ''
                          }`}
                          id="nps-modal"
                        >
                          <div>
                            {previewTab === PREVIEW_TABS.SURVEY && (
                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                              <a className="ask-me-later">
                                <div className="close-icon">✕</div>
                                <div className="ask-me-later-text">
                                  Ask Me Later
                                </div>
                              </a>
                            )}
                            {previewTab === PREVIEW_TABS.SURVEY && (
                              <SatisfactionQuantitativePreview
                                currentTab={previewTab}
                                questionOne={questionOne}
                                backgroundColor={backgroundColor}
                              />
                            )}
                            {previewTab === PREVIEW_TABS.FEEDBACK && (
                              <SatisfactionQualitativePreview
                                currentTab={previewTab}
                                questionTwo={questionTwo}
                                backgroundColor={backgroundColor}
                                foregroundColor={foregroundColor}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </survey-container>
            </div>
          </div>
        </appcues-container>
      </div>
    </div>
  );
}

export default styled(SatisfactionSurveyEditPreview)`
  position: fixed;
  bottom: 210px;
  left: 0;
  right: 0;

  width: 100%;
  z-index: 1055;

  .nps-survey-tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 13px;

    .tabs-container {
      display: flex;
      box-shadow: 0px 0px 10px 2px rgba(55, 74, 100, 0.25);
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    &.tabs-opened {
      position: absolute;
      bottom: 10px;
    }
    &.tabs-collapsed {
      position: fixed;
      bottom: 0px;
    }

    .nps-survey-tab {
      background-color: ${props => props.theme['$gray-30']};
      color: white;
      padding: 6px 10px;
      z-index: 10;
      font-weight: 700;
      letter-spacing: 0.04em;
      transition: background-color 0.1s ease-out;

      &:first-child {
        border-top-left-radius: 6px;
      }

      &:last-child {
        border-top-right-radius: 6px;
      }

      &:hover {
        cursor: pointer;
        background-color: ${props => props.theme['$gray-70']};
      }
      &.selected,
      &.selected:hover {
        background-color: ${props => props.theme.$purple};
        color: white;
      }

      &.nps-survey-tab-collapse.active {
        background-color: ${props => props.theme['$gray-20']};
        &:hover {
          background-color: ${props => props.theme['$gray-30']};
        }
      }
    }
  }

  .appcues-container {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
  }

  .nps-survey-preview {
    height: 220px;
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0px;
    ${props =>
      props.isTestMode
        ? ''
        : 'box-shadow: 0px 0px 10px 2px rgba(55, 74, 100, 0.25);'} &.sample-preview {
      border-top: 1px solid ${props => props.theme['$gray-1']};
      background: repeating-linear-gradient(
        120deg,
        #ffff,
        #ffff 25px,
        ${props => props.theme['$gray-1']} 10px,
        ${props => props.theme['$gray-1']} 50px
      );
    }

    .active {
      position: absolute;
      bottom: 0px;
      width: 0px;
    }
  }

  .appcues-nps {
    max-width: 1350px;
    margin-right: auto;
    margin-left: auto;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background-color: ${props => props.backgroundColor || '#FFFFFF'};
    color: ${props => props.foregroundColor || '#000000'};

    .ask-me-later,
    .back-to-nps {
      color: inherit;
      -webkit-font-smoothing: auto;

      .close-icon,
      .ask-me-later-text {
        -webkit-font-smoothing: auto;
      }
    }

    #nps-modal,
    .nps-modal {
      background-color: ${props => props.backgroundColor || '#FFFFFF'};
      color: ${props => props.foregroundColor || '#000000'};

      .question-text,
      .not-likely-hint,
      .very-likely-hint {
        -webkit-font-smoothing: auto;
      }

      &.qualitative {
        ${props =>
          props.isTestMode ? '' : 'max-height: 190px;'} margin-bottom: 10px;

        textarea {
          -webkit-font-smoothing: auto;
          ${props => (props.isTestMode ? '' : 'max-height: 55px;')};
          color: ${props =>
            props.backgroundColor === '#FFFFFF'
              ? props.foregroundColor
              : '#FFFFFF'};
        }

        .buttons {
          margin-bottom: 20px;
        }
      }
    }
  }

  .preview-hidden {
    transition: transform 100ms;
    transform: translateY(250px);
  }

  .nps-test {
    animation: animationFrames ease 0.6s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode: forwards; /*when the spec is finished*/
    -webkit-animation: animationFrames ease 0.6s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
    -moz-animation: animationFrames ease 0.6s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode: forwards; /*FF 5+*/
    -o-animation: animationFrames ease 0.6s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode: forwards; /*Not implemented yet*/
    -ms-animation: animationFrames ease 0.6s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode: forwards; /*IE 10+*/
  }

  @keyframes animationFrames {
    0% {
      opacity: 0;
      transform: translate(0px, 250px);
    }
    100% {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }

  @-moz-keyframes animationFrames {
    0% {
      opacity: 0;
      -moz-transform: translate(0px, 250px);
    }
    100% {
      opacity: 1;
      -moz-transform: translate(0px, 0px);
    }
  }

  @-webkit-keyframes animationFrames {
    0% {
      opacity: 0;
      -webkit-transform: translate(0px, 250px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(0px, 0px);
    }
  }

  @-o-keyframes animationFrames {
    0% {
      opacity: 0;
      -o-transform: translate(0px, 250px);
    }
    100% {
      opacity: 1;
      -o-transform: translate(0px, 0px);
    }
  }

  @-ms-keyframes animationFrames {
    0% {
      opacity: 0;
      -ms-transform: translate(0px, 250px);
    }
    100% {
      opacity: 1;
      -ms-transform: translate(0px, 0px);
    }
  }
`;
