import moment from 'moment';
import {
  takeEvery,
  put,
  select,
  call,
  getContext,
  takeLatest,
} from 'redux-saga/effects';
import toast from 'next/lib/toast';
import { promisaga } from 'utils/as-promised';
import * as actionTypes from 'constants/actionTypes';
import { trackEvent } from 'actions/events';
import { selectBillingDetails } from 'reducers/billing';
import { selectAccountMeta } from 'reducers/account/meta';
import { selectUser } from 'reducers/user';
import { selectBillingData } from 'reducers/newBilling';
import { getSlackChurnString } from 'utils/billing';
import { reportError } from 'helpers/error-reporting';
import { replaceAccountMeta, updateAccountMeta } from 'actions/account/meta';
import { REPLACE_ACCOUNT_META } from 'constants/account/meta';
import { resolve, reject } from 'actions/newBilling';

function* removeCancelOnResubscribe() {
  const accountMeta = yield select(selectAccountMeta);

  if (accountMeta.canceledAt) {
    yield put(updateAccountMeta({ canceledAt: null }));
  }
}

function* trackCancelSubscription(action) {
  const { fields, timestamp } = action.payload;

  const billingDetails = yield select(selectBillingDetails);
  const firstSubscription =
    billingDetails &&
    billingDetails.subscriptions &&
    billingDetails.subscriptions.data &&
    billingDetails.subscriptions.data[0];
  const plan = firstSubscription && firstSubscription.plan;
  const planName = plan && plan.name;

  const subscriptionStart = firstSubscription && firstSubscription.created;
  const formattedSubscriptionStart =
    subscriptionStart && moment(subscriptionStart * 1000).format('MM/DD/YY');

  const formattedCancelDate =
    plan &&
    moment(firstSubscription.current_period_end * 1000).format('MM/DD/YY');

  const billingEmail = billingDetails && billingDetails.email;

  const accountMeta = yield select(selectAccountMeta);
  const accountId = accountMeta && accountMeta.id;
  const companyName = accountMeta && accountMeta.name;

  const accountCreated = accountMeta && accountMeta.createdAt;
  const formattedAccountCreated =
    accountCreated && moment(accountCreated).format('MM/DD/YY');

  const user = yield select(selectUser);
  const userEmail = user && user.email;

  const formattedRequestTimestamp = moment(timestamp).format('MM/DD/YY');

  const cancellationInfo = {
    expectedChurnDate: formattedCancelDate,
    userEmail,
    accountId,
    requestDate: formattedRequestTimestamp,
    companyName,
    billingEmail,
    planName,
    subscriptionStart: formattedSubscriptionStart,
    accountCreated: formattedAccountCreated,
  };

  const segmentPayload = { ...cancellationInfo, ...fields };

  try {
    yield put(trackEvent('Subscription Canceled', { ...segmentPayload }));

    const slackMessage = getSlackChurnString(cancellationInfo, fields);
    /* global SUB_SLACK_HOOK_URL */
    const webhookResponse = yield fetch(SUB_SLACK_HOOK_URL, {
      method: 'POST',
      body: JSON.stringify({ text: slackMessage }),
    });

    if (!webhookResponse.ok) {
      reportError('Slack hook failed', segmentPayload);
    }

    yield put(updateAccountMeta({ canceledAt: timestamp }));
  } catch {
    reportError('Subscription Automation Failed', segmentPayload);
  }
}

function* fetchBillingDetails(action) {
  const { stripeId } = action.payload;

  const isStripeCustomer = stripeId && stripeId.startsWith('cus_');

  if (!isStripeCustomer) return;

  try {
    const api = yield getContext('billing');
    const { data: apiResponse } = yield call(api.fetchCustomerData, {
      stripeId,
    });

    yield put({
      type: actionTypes.PAYMENT_INFO_FETCHED,
      payload: { stripeId },
    });

    yield put({
      type: actionTypes.REPLACE_BILLING_DETAILS,
      payload: apiResponse,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw new Error(error);
  }
}

function* fetchBillingHistory(action) {
  const { stripeId } = action.payload;

  const isStripeCustomer = stripeId && stripeId.startsWith('cus_');

  if (!isStripeCustomer) return;

  try {
    const api = yield getContext('billing');
    const { data: apiResponse } = yield call(api.fetchCustomerInvoices, {
      stripeId,
    });

    yield put({
      type: actionTypes.REPLACE_BILLING_HISTORY,
      payload: apiResponse,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

function* findOrCreateStripeCustomer({ payload }) {
  try {
    const api = yield getContext('billing');
    const { data: apiResponse } = yield call(
      api.findOrCreateStripeCustomer,
      payload
    );
    const billingData = yield select(selectBillingData);
    yield put(
      resolve({
        ...billingData,
        stripeId: apiResponse.id,
      })
    );

    const accountMeta = yield select(selectAccountMeta);
    yield put(replaceAccountMeta({ ...accountMeta, stripeId: apiResponse.id }));
  } catch (error) {
    yield put(reject(error));
    yield call(reportError, error);
  }
}

function* calculateTaxes({ payload }) {
  try {
    const billingData = yield select(selectBillingData);

    yield put(
      resolve({
        ...billingData,
        completedForms: {
          ...billingData.completedForms,
          [payload.formType]: true,
        },
        loading: true,
      })
    );

    const api = yield getContext('billing');
    const { data: apiResponse } = yield call(api.fetchPaymentTaxes, {
      ...payload.billingAddress,
      amount: payload.amount,
    });

    yield put(
      resolve({
        ...billingData,
        taxes: Number(apiResponse.total),
        loading: false,
        billingAddress: payload.billingAddress,
      })
    );
  } catch (error) {
    const billingData = yield select(selectBillingData);
    yield put(
      resolve({
        ...billingData,
        taxes: 0,
        loading: false,
      })
    );
    yield put(reject(error));
    yield call(reportError, error);
  }
}

function* createSubscription({ payload }) {
  try {
    const billingData = yield select(selectBillingData);
    const api = yield getContext('api');
    const {
      data: {
        billingResponse: { data },
      },
    } = yield call(api.createOrUpdateSubscription, payload);
    yield put(
      resolve({
        ...billingData,
        subscription: {
          ...data,
        },
      })
    );

    return data;
  } catch (error) {
    yield call(toast.error, error.response?.message || 'Something went wrong');
    yield call(reportError, error);
    throw error;
  }
}

function* findSubscription({ payload }) {
  try {
    const api = yield getContext('billing');
    const billingData = yield select(selectBillingData);
    const { data: apiResponse } = yield call(api.findSubscription, payload);
    yield put(
      resolve({
        ...billingData,
        subscription: apiResponse,
      })
    );
  } catch (error) {
    yield put(reject(error));
    yield call(reportError, error);
  }
}

function* deleteSubscription() {
  try {
    const api = yield getContext('billing');
    const billingData = yield select(selectBillingData);

    yield call(api.deleteSubscription, {
      id: billingData.subscription.id,
    });
    yield put(
      resolve({
        ...billingData,
      })
    );
  } catch (error) {
    yield put(reject(error));
    yield call(reportError, error);
  }
}

function* fetchUpcomingInvoice() {
  try {
    const accountMeta = yield select(selectAccountMeta);

    const api = yield getContext('billing');
    const { data: upcomingInvoice } = yield call(api.fetchUpcomingInvoice, {
      stripeId: accountMeta.stripeId,
    });

    const billingData = yield select(selectBillingData);
    yield put(
      resolve({
        ...billingData,
        upcomingInvoice,
      })
    );
  } catch (error) {
    yield put(reject(error));
    yield call(reportError, error);
  }
}

function* createInvoicePreview({ payload: { billingAddress, addOns } }) {
  try {
    const accountMeta = yield select(selectAccountMeta);

    const api = yield getContext('billing');
    const { data: invoicePreview } = yield call(api.createInvoicePreview, {
      stripeId: accountMeta.stripeId,
      billingAddress,
      addOns,
    });

    const billingData = yield select(selectBillingData);
    yield put(
      resolve({
        ...billingData,
        invoicePreview,
        taxes: invoicePreview.taxes,
      })
    );

    return invoicePreview;
  } catch (error) {
    yield put(reject(error));
    yield call(reportError, error);
    return null;
  }
}

function* createSetupIntent({ payload }) {
  try {
    const api = yield getContext('billing');
    const {
      data: { clientSecret, status, nextAction },
    } = yield call(api.createSetupIntent, payload);
    const billingData = yield select(selectBillingData);

    yield put(
      resolve({
        ...billingData,
        clientSecret,
        status,
        nextAction,
      })
    );

    return {
      clientSecret,
      status,
      nextAction,
    };
  } catch (error) {
    yield put(reject(error));
    yield call(reportError, error);
    throw error;
  }
}

function* fetchPaymentInfo(action) {
  const { stripeId } = action.payload;

  const isStripeCustomer = stripeId && stripeId.startsWith('cus_');

  if (!isStripeCustomer) return;

  try {
    const api = yield getContext('billing');
    const { data } = yield call(api.fetchPaymentInfo, {
      stripeId,
    });

    const billingData = yield select(selectBillingData);

    yield put(
      resolve({
        ...billingData,
        paymentInfo: data,
      })
    );
  } catch (error) {
    yield put(reject(error));
    yield call(reportError, error);
    throw new Error(error);
  }
}

function* updateCreditCard(action) {
  const { stripeId, billingAddress, paymentMethodId } = action.payload;
  try {
    const api = yield getContext('billing');
    const billingData = yield select(selectBillingData);

    yield call(api.updateCreditCard, {
      stripeId,
      billingAddress,
      paymentMethodId,
    });
    yield put(
      resolve({
        ...billingData,
      })
    );
  } catch (error) {
    yield put(reject(error));
    yield call(reportError, error);
  }
}

function* saga() {
  yield takeEvery(actionTypes.PAYMENT_SUCCESS, removeCancelOnResubscribe);
  yield takeEvery(REPLACE_ACCOUNT_META, fetchBillingDetails);
  yield takeEvery(REPLACE_ACCOUNT_META, fetchBillingHistory);

  yield takeEvery(
    actionTypes.TRACK_CANCEL_SUBSCRIPTION,
    trackCancelSubscription
  );
  yield takeLatest(
    actionTypes.STRIPE_CUSTOMER_FETCHED,
    findOrCreateStripeCustomer
  );
  yield takeLatest(
    actionTypes.PAYMENT_TAXES_FETCHED,
    promisaga(calculateTaxes)
  );
  yield takeLatest(
    actionTypes.SUBSCRIPTION_CREATED,
    promisaga(createSubscription)
  );
  yield takeLatest(
    actionTypes.SUBSCRIPTION_FETCHED,
    promisaga(findSubscription)
  );
  yield takeLatest(actionTypes.SUBSCRIPTION_DELETED, deleteSubscription);
  yield takeLatest(actionTypes.UPCOMING_INVOICE_FETCHED, fetchUpcomingInvoice);
  yield takeLatest(
    actionTypes.INVOICE_PREVIEW_CREATED,
    promisaga(createInvoicePreview)
  );

  yield takeLatest(
    actionTypes.PAYMENT_INFO_FETCHED,
    promisaga(fetchPaymentInfo)
  );
  yield takeLatest(
    actionTypes.SETUP_INTENT_CREATED,
    promisaga(createSetupIntent)
  );
  yield takeLatest(
    actionTypes.PAYMENT_INFO_UPDATED,
    promisaga(updateCreditCard)
  );
}

export default saga;
