import { createLifecycleFor } from 'actions/account/collections';

const TYPE = 'installedDomains';

const actions = createLifecycleFor(TYPE);

export const {
  callApi: fetchInstalledDomains,
  send,
  resolve,
  reject,
  patterns,
} = actions;
