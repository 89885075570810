import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { Box, CPage, CPanel, H2 } from '@appcues/component-library';
import useTitle from 'next/hooks/use-title';
import ChildPage from 'components/Common/ChildPage';
import Loader from 'components/Common/Loader';
import StatSection from 'components/SubscriptionStory/StatSection';
import AllTimeStats from 'components/SubscriptionStory/AllTimeStats';
import MostViewedFlows from 'components/SubscriptionStory/MostViewedFlows';
import ContentLive from 'components/SubscriptionStory/ContentLive';
import CtaBar from 'components/SubscriptionStory/CtaBar';
import { navigate } from 'actions/routing';
import {
  selectAccountStats,
  selectAccountStatsSynced,
} from 'reducers/account/stats';
import {
  selectFlows,
  selectStepGroups,
  selectAreFlowsSynced,
} from 'reducers/account/flows';
import {
  selectAccountChecklists,
  selectAccountChecklistsSynced,
} from 'entities/checklists';
import { selectUserSynced } from 'reducers/user';
import { selectAccountMetaSynced } from 'reducers/account/meta';
import { selectAccountSatisfactionSynced } from 'reducers/account/satisfaction';

export const SubscriptionStory = ({
  allTimeStats,
  checklists,
  flowStats,
  flows,
  navigateTo,
  navigateToFeedback,
  navigateToHome,
  satisfactionSurveys,
  steps,
  synced,
}) => {
  useTitle('Subscription | Settings | Appcues');
  if (!synced) {
    return <Loader />;
  }

  return (
    <ChildPage
      title="Before you go..."
      breadcrumbContents={{
        label: 'Back to Appcues',
        path: '/',
      }}
    >
      <CPage.Container>
        <CPanel>
          <H2 marginBottom={40} marginTop={16}>
            Take a moment to celebrate your Appcues story so far!
            <Box is="span" marginLeft={8}>
              <span role="img" aria-label="tada">
                🎉
              </span>
            </Box>
          </H2>

          <StatSection header="Since you joined Appcues">
            <AllTimeStats
              allTimeFlowsShown={allTimeStats.all_time_flows_shown}
              allTimeUsersReached={allTimeStats.all_time_users_reached}
            />
          </StatSection>

          {flowStats && !isEmpty(flowStats) && (
            <StatSection header="All time most viewed flows">
              <MostViewedFlows
                flows={flows}
                steps={steps}
                flowStats={flowStats}
              />
            </StatSection>
          )}

          <StatSection header="Content live on your site today">
            <ContentLive
              checklists={checklists}
              navigate={navigateTo}
              satisfactionSurveys={satisfactionSurveys}
              steps={steps}
            />
            <CtaBar
              buttonText="I still want to cancel"
              navigateNextStep={navigateToFeedback}
              navigateHome={navigateToHome}
            />
          </StatSection>
        </CPanel>
      </CPage.Container>
    </ChildPage>
  );
};

SubscriptionStory.propTypes = {
  allTimeStats: PropTypes.shape({
    all_time_flows_shown: PropTypes.number,
    all_time_users_reached: PropTypes.number,
  }),
  checklists: PropTypes.object,
  flowStats: PropTypes.object,
  flows: PropTypes.object,
  navigateTo: PropTypes.func,
  navigateToFeedback: PropTypes.func,
  navigateToHome: PropTypes.func,
  satisfactionSurveys: PropTypes.object,
  steps: PropTypes.object,
  synced: PropTypes.bool,
};

const mapStateToProps = state => {
  const synced =
    selectUserSynced(state) &&
    selectAccountMetaSynced(state) &&
    selectAccountStatsSynced(state) &&
    selectAccountChecklistsSynced(state) &&
    selectAccountSatisfactionSynced(state) &&
    selectAreFlowsSynced(state);

  return {
    allTimeStats: selectAccountStats(state),
    billing: state.billing,
    checklists: selectAccountChecklists(state),
    flows: selectFlows(state),
    steps: selectStepGroups(state),
    satisfactionSurveys: state.account.satisfaction.satisfaction,
    flowStats: state.account.stats.stats,
    synced,
  };
};

const mapDispatchToProps = {
  navigateTo: navigate,
  navigateToHome: () => navigate('/'),
  navigateToFeedback: () => navigate('/subscription/feedback'),
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionStory);
