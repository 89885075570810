import * as actionTypes from 'constants/actionTypes';
import { addTimingMarkInstructionsToAction } from 'utils/timing';

export const checkLoginStatus = token => {
  return {
    type: actionTypes.CHECK_LOGIN_STATUS,
    payload: {
      token,
    },
  };
};

export const syncAfterLogin = userId => {
  return {
    type: actionTypes.SYNC_AFTER_LOGIN,
    payload: {
      userId,
    },
  };
};

export const syncAccountWithFirebase = accountId => {
  return addTimingMarkInstructionsToAction({
    type: actionTypes.SYNC_ACCOUNT_WITH_FIREBASE,
    payload: {
      accountId,
    },
  });
};
