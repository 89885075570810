import React from 'react';
import styled from 'styled-components';
import { CPanel } from '@appcues/component-library';
import EmptyState from 'components/Common/EmptyState';
import ErrorState from 'components/Common/ErrorState';
import Loader from 'components/Common/Loader';
import OverviewSection from 'components/ChecklistAnalytics/OverviewSection';
import StepAnalysisSection from 'components/ChecklistAnalytics/StepAnalysisSection';

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-regular);
`;

export default function StatsSection(props) {
  const {
    checklistAnalytics,
    analyticsLoaded,
    analyticsError,
    checklist,
    actions,
    requestAnalytics,
  } = props;

  if (analyticsError) {
    return <ErrorState retry={requestAnalytics} />;
  }

  if (!analyticsLoaded) {
    return <Loader />;
  }

  if (
    analyticsLoaded &&
    checklistAnalytics.data &&
    Object.keys(checklistAnalytics.data).length === 0
  ) {
    return (
      <EmptyState
        iconName="chart-bar"
        header="No activity yet!"
        body="Once some users see your checklist, this page will show you how it's doing. Data may be delayed by up to one hour."
      />
    );
  }

  return (
    <Section>
      <OverviewSection checklistAnalytics={checklistAnalytics} />
      <CPanel>
        <StepAnalysisSection
          checklistAnalytics={checklistAnalytics}
          checklist={checklist}
          navigate={actions.navigate}
        />
      </CPanel>
    </Section>
  );
}
