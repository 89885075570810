import React from 'react';

import { H2 } from '@studio/legacy-components';
import Addon from 'next/components/Subscription/Addon';

import {
  ENTITLEMENTS_CAPTIONS,
  ENTITLEMENTS_DESCRIPTIONS,
  SUPPORTED_ENTITLEMENTS,
} from 'constants/entitlements';

const Addons = ({
  entitlementsProcessed,
  hasSubscription,
  onChange,
  selectedEntitlements,
}) => (
  <>
    <H2>
      {hasSubscription ? '2.' : ''} Select add-ons{' '}
      {hasSubscription ? '' : '(optional)'}
    </H2>
    {SUPPORTED_ENTITLEMENTS.map(entitlement => {
      const entitlementData = entitlementsProcessed[entitlement];

      if (!entitlementData) return null;

      return (
        <Addon
          caption={ENTITLEMENTS_CAPTIONS[entitlement]}
          currentUsage={`${entitlementData.availableUnits} of ${entitlementData.maxUnits} ${ENTITLEMENTS_CAPTIONS[entitlement]} currently in use`}
          description={ENTITLEMENTS_DESCRIPTIONS[entitlement]}
          initialValue={selectedEntitlements[entitlement]}
          key={entitlement}
          maxValue={entitlementData.maxUnits - entitlementData.availableUnits}
          onChange={({ prev, next }) => {
            onChange?.({ entitlement, prev, next });
          }}
          price={`$${entitlementData.unitPrice} each/mo`}
          title={`Additional ${ENTITLEMENTS_CAPTIONS[entitlement]}`}
        />
      );
    })}
  </>
);

export default Addons;
