import { put, all } from 'redux-saga/effects';
import { getFlattenedClausesFromNestedConditions } from '@appcues/libcues';

import { replace } from 'actions/account/rules';
import { replaceAccountStepConditions } from 'actions/account/conditions';

export function* replaceRule({ conditions, ...rule }) {
  yield all([
    put(replace(rule)),
    put(
      replaceAccountStepConditions(
        rule.id,
        getFlattenedClausesFromNestedConditions(conditions)
      )
    ),
  ]);
}
