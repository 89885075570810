import React, { Component } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { Icon } from '@studio/legacy-components';
import StatusIcon from 'components/Diagnostics/StatusIcon';

export class QualifiedSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      hasRequested: false,
      requestPending: true,
      qualified: null,
      error: false,
    };
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { hidden, qualifier } = nextProps;
    const { hasRequested } = this.state;

    if (!hidden && !hasRequested) {
      try {
        const qualified = await qualifier();
        this.setState({ hasRequested: true, requestPending: false, qualified });
      } catch (error) {
        this.setState({ requestPending: false, qualified: false, error });
      }
    }
  }

  render() {
    const { className, name, description } = this.props;
    const { showDetails, requestPending, qualified, error } = this.state;
    return (
      <div className={cx(className, 'section')}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          role="button"
          tabIndex={0}
          aria-label="Toggle details"
          className="section-label"
          onClick={() => this.setState({ showDetails: !showDetails })}
        >
          <StatusIcon
            error={error}
            pending={requestPending}
            qualified={qualified}
          />
          {name}
          <Icon key="caret" icon={`caret-${showDetails ? 'up' : 'down'}`} />
        </div>
        <div
          className={cx('section-details', showDetails ? 'visible' : 'hidden')}
        >
          {description}
        </div>
      </div>
    );
  }
}

export default styled(QualifiedSection)`
  cursor: pointer;
  [class*='fa-caret'] {
    margin-left: 0.5em;
  }
  .section-label {
    display: flex;
    align-items: center;
  }
  .section-details {
    display: none;
    color: ${props => props.theme['$gray-50']};
    padding: 0.5em 0 0 1.75em;
    width: 100%;
    ul {
      margin: 0.5em 0;
      padding: 0 1.25em;
      li {
        padding-right: 1em;
      }
    }
    &.visible {
      display: inline-block;
    }
  }
`;
