export function getChromeExtensionVersion(chromeExtensionId) {
  return new Promise((resolve, reject) => {
    if (!window || !window.chrome) {
      reject(new Error('Chrome not found on the window'));
    }

    window.chrome.runtime?.sendMessage(
      chromeExtensionId,
      { action: 'get-version' },
      {},
      reply => {
        if (reply && reply.version) {
          resolve(reply.version);
        } else {
          reject(
            new Error(`No Chrome extension found with id ${chromeExtensionId}`)
          );
        }
      }
    );

    setTimeout(() => {
      reject(
        new Error(
          'Reached 3 second timeout with no response from Chrome extension'
        )
      );
    }, 3000);
  });
}

export async function doubleCheckHasChromeExtension(
  chromeExtensionId,
  hasChromeExtensionInstalled
) {
  if (hasChromeExtensionInstalled) {
    return true;
  }

  try {
    const chromeExtensionVersion = await getChromeExtensionVersion(
      chromeExtensionId
    );

    return !!chromeExtensionVersion;
  } catch {
    return false;
  }
}

export function canOpenInBuilder(version) {
  if (!version) return false;

  const [major, minor, patch] = version
    .split('.')
    .map(x => Number.parseInt(x, 10));

  return (
    major > 5 ||
    (major === 5 && minor > 0) ||
    (major && minor === 0 && patch >= 35)
  );
}
