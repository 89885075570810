import {
  call,
  getContext,
  put,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';

import { replacePagePatternFor } from 'next/entities/page';

import { patterns, reject, resolve } from './actions';

/**
 * Normalize apps list response as collection
 *
 * @param {Response} response - Raw apps list response
 * @return {Collection} Apps collection
 */
const transform = response =>
  response.reduce((acc, app) => {
    acc[app.id] = app;
    return acc;
  }, {});

function* fetchGoals() {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getGoals);
    yield put(resolve(transform(response)));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  // Internal actions
  yield takeEvery(patterns.read, fetchGoals);

  // Page actions
  yield takeLeading(
    [
      replacePagePatternFor('/flows/:flowId/settings'),
      replacePagePatternFor('/mobile/flows/:experienceId/settings'),
      replacePagePatternFor('/mobile/flows/:experienceId/analytics'),
    ],
    fetchGoals
  );
}
