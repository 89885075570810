import { createCollectionReducer } from 'reducers/account/collections';
import { selectIsSynced } from 'reducers/account/requests';

const TYPE = 'locales';

export default createCollectionReducer(TYPE);

export const selectLocales = state => state.account.locales;
export const selectLocale = (state, id) => selectLocales(state)[id];
export const selectAreLocalesSynced = state => selectIsSynced(state, TYPE);
