import styled, { css } from 'styled-components';
import {
  Highlight,
  AndCondition,
  OrCondition,
  SubGroup,
} from '@studio/conditions';

const GroupCSS = css`
  text-transform: uppercase;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 800;
  font-size: 0.675em;
  color: ${props => props.theme['$gray-5']};
  position: relative;
  top: 3px;
`;

export const ConditionsWrapper = styled.div`
  line-height: 1.5em;
  border-left: 4px solid ${props => props.theme['$gray-2']};
  padding-left: 20px;
  &:first-letter {
    text-transform: capitalize;
  }
  ${Highlight} {
    font-weight: 800;
  }

  ${AndCondition} {
    ${GroupCSS}
    color: var(--color-green-600);
  }

  ${OrCondition} {
    ${GroupCSS}
    color: var(--color-yellow-600);
  }

  ${SubGroup} > ${SubGroup}, ${SubGroup} > ${OrCondition}, ${SubGroup} > ${AndCondition} {
    margin-left: 16px;
    display: block;
  }
`;
