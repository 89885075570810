import React from 'react';
import { Button } from '@appcues/component-library';
import styled from 'styled-components';
import { ExternalLink } from '@studio/legacy-components';
import BaseModal from 'components/ModalContainer/BaseModal';
import {
  getHubspotFormsUrl,
  prepareHubspotContextData,
  buildHbsptQueryString,
} from 'helpers/hubspot';
import upsellHeader from 'assets/images/flows_upsell_header.png';
import { SUPPORT_EMAIL } from 'constants/externalAppcuesResources';

const flowDashboardExampleBackground =
  'https://res.cloudinary.com/appcues-dev/image/upload/v1524332427/Flows_Blurred_BG_tqm7yx.png';

function NPSFlowUpsellModal(props) {
  const { className, onClickGoToNPS, onClickStartTrialCallback, user } = props;

  const startProdAdoptionTrial = async () => {
    if (user && !user.email) {
      // Skip the hubspot form submission.
      if (onClickStartTrialCallback) {
        onClickStartTrialCallback();
      }
    } else {
      const hsContext = prepareHubspotContextData();
      const data = {
        email: user.email,
        hsContext: JSON.stringify(hsContext),
      };
      const queryParams = buildHbsptQueryString(data);
      const URL = getHubspotFormsUrl(queryParams);

      try {
        await fetch(URL, {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });
        if (onClickStartTrialCallback) {
          onClickStartTrialCallback();
        }
      } catch {
        if (onClickStartTrialCallback) {
          onClickStartTrialCallback();
        }
      }
    }
  };

  return (
    <div className={className}>
      <div className="survey-background" />
      <BaseModal
        overrideDocumentBodyDefault
        hideOKButton
        large
        hideBackground
        modalClass="pending-modal"
        {...props}
      >
        <div className="satisfaction-icon-container">
          <img alt="Upsell" src={upsellHeader} />
        </div>
        <div className="satisfaction-content-container">
          <h2 className="satisfaction-intro-header">
            Appcues for Product Adoption
          </h2>
          <p className="satisfaction-intro-subtext">
            <b>
              Get started building great onboarding, feature adoption, and help
              user experiences - without a developer.
            </b>
          </p>
          <p className="satisfaction-intro-subtext paragraph-spacing-tight">
            Appcues for Product Adoption has everything you need to create,
            coordinate, and measure adoption for both new users and new
            features. Get started with a free trial in just a few minutes.
          </p>
          <div className="satisfaction-button-row">
            <Button
              onClick={startProdAdoptionTrial}
              className="button-success nps-modal-button"
            >
              Start Your Trial
            </Button>
            <Button
              onClick={() => onClickGoToNPS()}
              className="nps-modal-button"
            >
              Skip and Go to NPS &#8594;
            </Button>
          </div>
          <p className="satisfaction-intro-subtext paragraph-spacing-tight subtext">
            <ExternalLink href="https://www.appcues.com/product">
              Read more about it
            </ExternalLink>{' '}
            or{' '}
            <ExternalLink href={`mailto:${SUPPORT_EMAIL}`}>
              contact us for more info
            </ExternalLink>
            .
          </p>
        </div>
      </BaseModal>
    </div>
  );
}

export default styled(NPSFlowUpsellModal)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  text-align: center;
  padding-top: 55px;
  z-index: 10;

  .satisfaction-icon-container {
    img {
      width: 100%;
      margin: 0px;
    }
  }

  .survey-background {
    height: 100%;
    width: 100%;
    background: url(${flowDashboardExampleBackground});
  }

  .modal-dialog.large {
    width: 750px;
    pointer-events: all;
    padding: 0px 0px;

    .base-modal-body {
      padding: 0px 0px;

      .satisfaction-content-container {
        padding: 20px 30px 30px 30px;
      }
    }
  }

  .satisfaction-intro-header {
    margin-bottom: 11px;
    font-weight: 300;
    font-size: 36px;
    color: ${props => props.theme['$gray-50']};
  }

  .satisfaction-intro-subtext {
    font-size: 18px;
    margin: 20px 40px 20px 50px;
    text-align: left;
    color: #717171;
  }

  .paragraph-spacing-tight {
    margin-top: 0px;
  }

  .subtext {
    margin-top: 20px;
    margin-bottom: 0px;
    font-style: italic;
    text-align: center;
  }

  .nps-modal-button {
    margin: 15px auto;
    padding: 10px 15px;

    &:nth-child(2) {
      margin-left: 5px;
    }
  }
`;
