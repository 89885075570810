import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { string, array, object } from 'yup';
import { CForm, CButton, P, CRow } from '@appcues/component-library';
import {
  Creatable,
  Input,
  Button,
  ButtonGroup,
} from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';
import SelectProfileAttribute from 'components/Common/SelectProfileAttribute';
import { asPromised } from 'utils/as-promised';
import { saveLocale } from 'actions/account/locales';

import { Error, Row, RowItem } from './styled';
import AttributeMultiSelect from './AttributeMultiSelect';

export function AddLanguageForm({ languageOptions, onSave, locales }) {
  const [isDuplicate, setFormDuplicateError] = useState(false);
  const [isVisible, toggleForm] = useToggle();

  const handleSubmit = ({ name, property, values }) => {
    onSave({
      name,
      property,
      value: values.map(item => item.value).join('\n'),
    }).then(() => {
      toggleForm();
    });
  };

  const checkForDuplicates = (name, val, setFieldValue) => {
    const dupe = locales.some(
      element => val.toLowerCase() === element.name.toLowerCase()
    );
    setFormDuplicateError(dupe);
    return setFieldValue(name, val);
  };

  const options = useMemo(
    () =>
      languageOptions.map(lang => {
        return {
          value: lang.code,
          label: lang.desc,
        };
      }),
    [languageOptions]
  );

  return (
    <CRow fullWidth center="xs" marginTop="45px">
      {isVisible ? (
        <CForm
          onSubmit={handleSubmit}
          initialValues={{
            name: '',
            property: '_lastBrowserLanguage',
            values: [],
          }}
          validationSchema={object().shape({
            name: string().min(1).required(),
            property: string().required(),
            values: array().min(1).required(),
          })}
        >
          {({ values, setFieldValue, isValid }) => (
            <CForm.Element justifyContent="space-between" flexDirection="row">
              <Row>
                <RowItem>
                  <Input
                    name="name"
                    onChange={({ target: { value } }) => {
                      checkForDuplicates('name', value, setFieldValue);
                    }}
                    value={values.name}
                    placeholder="Language name"
                  />
                  {isDuplicate && (
                    <Error className="testid-error-message">
                      Language already exists!
                    </Error>
                  )}
                </RowItem>
                <RowItem>
                  <SelectProfileAttribute
                    marginBottom={10}
                    value={values.property}
                    onChange={val => setFieldValue('property', val.value)}
                  />
                </RowItem>
                <RowItem xs sm={2} lg={1}>
                  <P textAlign="center" marginTop={5} marginBottom={5}>
                    is one of
                  </P>
                </RowItem>
                <RowItem>
                  {values.property === '_lastBrowserLanguage' ? (
                    <Creatable
                      formatCreateLabel={label => label}
                      isClearable={false}
                      isMulti
                      onChange={val => setFieldValue('values', val)}
                      options={options}
                      placeholder="Browser language"
                      value={values.values}
                    />
                  ) : (
                    <AttributeMultiSelect
                      property={values.property}
                      onChange={val => setFieldValue('values', val)}
                      value={values.values}
                    />
                  )}
                </RowItem>
                <ButtonGroup>
                  <Button kind="secondary" onClick={toggleForm} marginRight={8}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={!isValid || isDuplicate || !values.name}
                  >
                    Save
                  </Button>
                </ButtonGroup>
              </Row>
            </CForm.Element>
          )}
        </CForm>
      ) : (
        <CButton onClick={toggleForm} width={140}>
          Add language
        </CButton>
      )}
    </CRow>
  );
}

AddLanguageForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  languageOptions: PropTypes.arrayOf(
    PropTypes.shape({ desc: PropTypes.string, code: PropTypes.string })
  ).isRequired,
};

const mapDispatchToProps = dispatch => ({
  onSave: values => asPromised(dispatch, saveLocale(values)),
});

export default connect(null, mapDispatchToProps)(AddLanguageForm);
