import { selectIsSynced } from 'reducers/account/requests';
import { createCollectionReducer } from 'reducers/account/collections';
import { ACCOUNTS } from 'constants/data-types';

export default createCollectionReducer(ACCOUNTS);

export const selectAccounts = state => state.accounts;
export const selectNumAccounts = state =>
  Object.keys(selectAccounts(state) || {}).length;
export const selectAccountsSynced = state => selectIsSynced(state, ACCOUNTS);
