import React from 'react';
import PropTypes from 'prop-types';
import { OptionGroupName, ActiveFilterIcon, GroupListWrapper } from './styled';
import { getGroupHasSelectedOption } from './utils';

export default function GroupList({ groups, onGroupChange }) {
  return (
    <GroupListWrapper>
      {groups.map(([groupKey, { name, options }]) => (
        <OptionGroupName
          key={groupKey}
          onClick={e => {
            e.preventDefault();
            onGroupChange({ groupKey, name, options });
          }}
          caret
        >
          {name}

          {getGroupHasSelectedOption(options) && (
            <ActiveFilterIcon aria-label="actives filter" />
          )}
        </OptionGroupName>
      ))}
    </GroupListWrapper>
  );
}

GroupList.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))
  ),
  onGroupChange: PropTypes.func,
};
