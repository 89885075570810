import React from 'react';
import PropTypes from 'prop-types';

import { Notice, Text } from '@studio/legacy-components';
import { YEARLY_INTERVAL } from 'constants/plans';
import { formatCurrency } from 'utils/billing';

import { HorizontalWrapper, TadaIcon } from './styled';

const PlanSavings = ({
  isSelfService,
  planData,
  useNotice = true,
  spaced = true,
}) => {
  if (
    planData.planInterval !== YEARLY_INTERVAL ||
    planData.planLimit === 'high-volume' ||
    !isSelfService
  )
    return <HorizontalWrapper spaced={spaced} />;

  return useNotice ? (
    <Notice>
      <HorizontalWrapper spaced={spaced}>
        <TadaIcon>🎉</TadaIcon>
        You're saving {formatCurrency(planData.savings, false)} with an annual
        plan!
      </HorizontalWrapper>
    </Notice>
  ) : (
    <HorizontalWrapper spaced={spaced}>
      <TadaIcon>🎉</TadaIcon>
      <Text>Save {formatCurrency(planData.savings, false)}/year</Text>
    </HorizontalWrapper>
  );
};

PlanSavings.propTypes = {
  planData: PropTypes.shape({
    planInterval: PropTypes.string,
    planLimit: PropTypes.number,
    savings: PropTypes.number,
    spaced: PropTypes.bool,
    useNotice: PropTypes.bool,
  }),
};

export default PlanSavings;
