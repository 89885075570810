/**
 * Use a DOM link to force-download a blob of data.
 *
 * @param blob
 * @param filename
 * @param type
 */

export const downloadFile = (blob, filename, type) => {
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.append(a);

  a.setAttribute('href', window.URL.createObjectURL(blob));
  a.setAttribute('download', `${filename}.${type}`);

  a.click();

  // Delay revoking the object URL to allow the download to complete in Chrome
  setTimeout(() => {
    URL.revokeObjectURL(a.href);
  }, 10);

  a.remove();
};
