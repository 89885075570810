import { useCallback } from 'react';
import { local } from 'next/lib/storage';
import { DEVTOOLS_REGISTRY } from 'next/entities/features';
import { FEATURES_KEY } from './keys';

export default (features = {}) => {
  // Partition the features based on whether they are registered above and can
  // be edited or if they were included from LaunchDarkly but are not registered
  // for override.
  const { editable, uneditable, types } = Object.entries({
    ...DEVTOOLS_REGISTRY,
    ...features,
  }).reduce(
    (acc, [feature, value]) => {
      if (Object.prototype.hasOwnProperty.call(DEVTOOLS_REGISTRY, feature)) {
        acc.editable[feature] = value;
      } else {
        acc.uneditable[feature] = value;
      }
      return acc;
    },
    { editable: {}, uneditable: {} }
  );

  // Handler to remove all feature flags from local storage
  const reset = useCallback(() => {
    local.remove(FEATURES_KEY);
    window.location.reload();
  }, []);

  // Handler to save feature flag overrides to local storage
  const save = useCallback(patch => {
    local.set(FEATURES_KEY, patch);
    window.location.reload();
  }, []);

  return {
    overrides: local.get(FEATURES_KEY, {}),
    editable,
    uneditable,
    types,
    reset,
    save,
  };
};
