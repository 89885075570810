import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  H3,
  PanelHeader,
  PanelTitle,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@studio/legacy-components';
import { format } from 'next/lib/date';
import StepsCarousel from 'next/components/StepsCarousel';
import { PIN_BUTTON_TYPE, StepShape } from 'next/entities/experiences';
import {
  readAnalytics,
  selectExperienceStepAnalytics,
} from 'next/entities/analytics';
import { queryExperienceStepAnalytics } from 'next/client/queries';
import { selectPublishedPin } from 'next/lib/selectors';
import { selectPin } from 'next/entities/pins';
import { ContentStateShape } from 'next/lib/shapes';

import NoDataImg from 'next/assets/images/no-data.svg';
import {
  PinStatsPanel,
  PinStepsContainer,
  LoaderWrapper,
  NoResultsWrapper,
} from './styled';

const ANALYTICS_EVENTS = {
  'appcues:v2:step_seen': 'events',
  'appcues:v2:icon_seen': 'impressions',
  'appcues:v2:button_pin_seen': 'impressions',
  'appcues:v2:step_interaction': 'events',
  'appcues:v2:step_error': 'issues',
  'appcues:v2:step_recovered': 'recovered',
};

const getEventValue = (type, events) => {
  const eventLabel = type === PIN_BUTTON_TYPE ? 'click' : 'open';

  if (!events) return '-';

  return `${events.toLocaleString()} ${eventLabel}${events !== 1 ? 's' : ''}`;
};

const getImpressionValue = events => {
  const eventLabel = 'view';

  if (!events) return '-';

  return `${events.toLocaleString()} ${eventLabel}${events !== 1 ? 's' : ''}`;
};

export const PinStats = ({
  id,
  state,
  version,
  steps,
  stepsAnalytics,
  onLoad,
  previewUrl,
}) => {
  /* Returns analytics indexed by step_id with all associated events */
  const analyticsByStep = useMemo(() => {
    if (!stepsAnalytics) return {};

    return stepsAnalytics.reduce((acc, analyticsRow) => {
      if (!analyticsRow.step_id) return acc;

      if (!acc[analyticsRow.step_id]) {
        /* Create empty analytics data for the current step */
        acc[analyticsRow.step_id] = {
          events: 0,
          impressions: 0,
          unique: 0,
          issues: 0,
          recovered: 0,
        };
      }

      const event = ANALYTICS_EVENTS[analyticsRow.name];
      const currentStep = acc[analyticsRow.step_id];

      /* The Unique column is deduped from the same events event  */
      if (event === 'events') {
        currentStep.events = analyticsRow.events;
        currentStep.unique = analyticsRow.users;

        return acc;
      }

      currentStep[event] = analyticsRow.events;

      return acc;
    }, {});
  }, [stepsAnalytics]);

  const isLoading = !steps || !stepsAnalytics;
  const hasNoResults = !isLoading && stepsAnalytics?.length === 0;

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <PinStatsPanel>
      <PanelHeader>
        <PanelTitle>
          <H3>Individual Pin stats</H3>
        </PanelTitle>
      </PanelHeader>

      <PinStepsContainer>
        <StepsCarousel
          experienceId={id}
          state={state}
          version={version}
          steps={steps}
          previewUrl={previewUrl}
        />
      </PinStepsContainer>

      <Table>
        <Thead>
          <Tr>
            <Th>Pin name</Th>
            <Th>Impressions</Th>
            <Th>Events</Th>
            <Th>Unique</Th>
            <Th>Issues</Th>
            <Th>Created</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading && (
            <Tr>
              <Td colSpan={6}>
                <LoaderWrapper>
                  <Spinner />
                </LoaderWrapper>
              </Td>
            </Tr>
          )}
          {hasNoResults && (
            <Tr>
              <Td colSpan={6}>
                <NoResultsWrapper>
                  <img src={NoDataImg} alt="No data found" />
                  No results, please try different filters.
                </NoResultsWrapper>
              </Td>
            </Tr>
          )}
          {!isLoading &&
            !hasNoResults &&
            steps.map((step, stepIndex) => {
              const analytics = analyticsByStep[step.id] || {};

              return (
                <Tr key={step.id}>
                  <Td>{step.name || `Pin ${stepIndex + 1}}`}</Td>
                  <Td>{getImpressionValue(analytics.impressions)}</Td>
                  <Td>{getEventValue(step.type, analytics.events)}</Td>
                  <Td>{getEventValue(step.type, analytics.unique)}</Td>
                  <Td>
                    {analytics.issues
                      ? (
                          analytics.issues - analytics.recovered
                        ).toLocaleString()
                      : '-'}
                  </Td>
                  <Td>{format(step.createdAt, 'MMM D, YYYY hh:mm A')}</Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </PinStatsPanel>
  );
};

PinStats.propTypes = {
  id: PropTypes.string,
  steps: PropTypes.arrayOf(StepShape),
  stepsAnalytics: PropTypes.arrayOf(
    PropTypes.shape({
      events: PropTypes.number,
      users: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  onLoad: PropTypes.func,
  previewUrl: PropTypes.string,
  state: ContentStateShape,
  version: PropTypes.number,
};

const getQueryParams = ({ id, startTime, endTime, segmentId, goalId }) => ({
  experienceId: id,
  startTime,
  endTime,
  segmentId,
  goalId,
});

const mapStateToProps = (state, ownProps) => {
  const queryParams = getQueryParams(ownProps);

  const pin = selectPin(state, ownProps.id);
  const publishedPin = selectPublishedPin(state, ownProps.id);

  // initially, state is either DRAFT or ARCHIVED
  let pinState = pin?.state;

  if (pin?.published) {
    // if pin is published, use the published image
    pinState = 'PUBLISHED';
  } else if (pin?.publishedAt) {
    // if pin was previously published, use that version
    pinState = 'VERSIONED';
  }

  return {
    stepsAnalytics: selectExperienceStepAnalytics(state, queryParams),
    state: pinState,
    version: pin?.publishedAt,
    steps: publishedPin?.steps,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const queryParams = getQueryParams(ownProps);
  const query = queryExperienceStepAnalytics(queryParams);

  return {
    onLoad: () => dispatch(readAnalytics(query)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PinStats);
