import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Notice, NOTICE_TYPES } from '@appcues/component-library';
import { selectFlow } from 'reducers/account/flows';

const InvalidFlowWarning = ({ message = null }) =>
  message && <Notice type={NOTICE_TYPES.warning}>{message}</Notice>;

InvalidFlowWarning.propTypes = {
  message: PropTypes.string,
};

const mapStateToProps = (state, { flowId }) => {
  if (flowId) {
    const flow = selectFlow(state, flowId);
    if (flow) {
      const { published, steps = {} } = flow;
      if (!published) {
        return { message: 'The flow you have selected is unpublished!' };
      }
      if (Object.keys(steps).length === 0) {
        return { message: 'The flow you have selected has no steps!' };
      }
      return { message: null };
    }
    return { message: 'The flow you have selected no longer exists!' };
  }
  return { message: null };
};

const ConnectedInvalidFlowWarning =
  connect(mapStateToProps)(InvalidFlowWarning);

ConnectedInvalidFlowWarning.propTypes = {
  flowId: PropTypes.string,
};

export default ConnectedInvalidFlowWarning;
