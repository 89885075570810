import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import { H2 } from '@studio/legacy-components';
import { Stack, Text, Title } from '@appcues/sonar';

import {
  SUBSCRIPTION_COMBINED_MAUS,
  SUBSCRIPTION_MOBILE_MAUS,
} from 'next/entities/features';
import { read as fetchAccountEntitlements } from 'actions/entitlements';
import { ENTITLEMENTS } from 'constants/features';
import { ENTITLEMENTS_CAPTIONS } from 'constants/entitlements';
import {
  EntitlementsWrapper,
  SubscriptionCardWrapper,
} from 'components/Subscription/styled';
import { selectAccountFeature } from 'reducers/account/features';
import { Entitlement as EntitlementShape } from 'components/Subscription/shape';
import { numberWithCommas } from 'utils/textFormatting';
import { selectCombinedMaus, selectMobileMaus } from 'selectors/entitlements';

import InstallationIssues from './InstallationIssues';
import EntitlementProgress from './EntitlementProgress';
import { CounterWithDescription } from './styled';

export function CurrentPeriod({
  onLoad,
  anonymousWebsiteMaus,
  anonymousMobileMaus,
  entitlementsProcessed,
  hasEntitlements,
  mausProcessed,
  monthlyActiveUsers,
  hasSubscriptionMobileMAUs,
  mobileMAUs,
  hasSubscriptionCombinedMAUs,
  combinedMAUs,
}) {
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const entitlements = hasEntitlements
    ? [mausProcessed, ...entitlementsProcessed]
    : [mausProcessed];

  const overallAnonymousPercent = Math.round(
    ((anonymousWebsiteMaus + anonymousMobileMaus) / monthlyActiveUsers) * 100,
    2
  );

  return (
    <SubscriptionCardWrapper kind="borderless">
      <H2 type="secondary">Your usage</H2>

      <EntitlementsWrapper>
        {entitlements.map(
          ({
            availableUnits,
            completePercent,
            currentStatus,
            id,
            showWarning,
            usedUnits,
          }) =>
            id === 'maus' ? (
              <Stack key={id} spacing="regular">
                <EntitlementProgress
                  title={`${numberWithCommas(usedUnits)} of ${numberWithCommas(
                    availableUnits
                  )}`}
                  caption={ENTITLEMENTS_CAPTIONS[id]}
                  completePercent={completePercent}
                  currentStatus={currentStatus}
                  showWarning={showWarning}
                />
                {overallAnonymousPercent > 0 && (
                  <InstallationIssues
                    overallAnonymousPercent={overallAnonymousPercent}
                    overallIdentifiedPercent={100 - overallAnonymousPercent}
                  />
                )}
                {hasSubscriptionMobileMAUs && (
                  <CounterWithDescription>
                    <Title>{numberWithCommas(mobileMAUs)}</Title>
                    <Text size="small">monthly mobile active users (MAUs)</Text>
                  </CounterWithDescription>
                )}
                {hasSubscriptionCombinedMAUs && (
                  <CounterWithDescription>
                    <Title>{numberWithCommas(combinedMAUs)}</Title>
                    <Text size="small">
                      monthly combined active users (MAUs)
                    </Text>
                  </CounterWithDescription>
                )}
              </Stack>
            ) : (
              <EntitlementProgress
                key={id}
                title={`${numberWithCommas(usedUnits)} of ${numberWithCommas(
                  availableUnits
                )}`}
                caption={pluralize(ENTITLEMENTS_CAPTIONS[id])}
                completePercent={completePercent}
                currentStatus={currentStatus}
                showWarning={showWarning}
              />
            )
        )}
      </EntitlementsWrapper>
    </SubscriptionCardWrapper>
  );
}

CurrentPeriod.propTypes = {
  anonymousWebsiteMaus: PropTypes.number,
  anonymousMobileMaus: PropTypes.number,
  entitlementsProcessed: PropTypes.arrayOf(EntitlementShape),
  hasEntitlements: PropTypes.bool,
  onLoad: PropTypes.func,
  mausProcessed: EntitlementShape,
  monthlyActiveUsers: PropTypes.number,
  hasSubscriptionMobileMAUs: PropTypes.bool,
  mobileMAUs: PropTypes.number,
  hasSubscriptionCombinedMAUs: PropTypes.bool,
  combinedMAUs: PropTypes.number,
};

const mapStateToProps = state => {
  return {
    hasEntitlements: selectAccountFeature(state, ENTITLEMENTS),
    hasSubscriptionMobileMAUs: selectAccountFeature(
      state,
      SUBSCRIPTION_MOBILE_MAUS
    ),
    mobileMAUs: selectMobileMaus(state),
    hasSubscriptionCombinedMAUs: selectAccountFeature(
      state,
      SUBSCRIPTION_COMBINED_MAUS
    ),
    combinedMAUs: selectCombinedMaus(state),
  };
};

const mapDispatchToProps = {
  onLoad: fetchAccountEntitlements,
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentPeriod);
