export const ACCOUNT_USER_STATUSES = {
  INVITED: 'invited', // existing user invited, has not yet accepted
  PENDING: 'pending', // new user invited, never logged in, not in other account
  ENABLED: 'enabled', // default/standard active user status
};

export const ADMINISTRATOR_OPTION = {
  label: 'Admin',
  value: 'ACCOUNT_ADMIN',
  permissionDescription: 'Create, edit, publish, and manage account',
};

export const PUBLISHER_OPTION = {
  label: 'Publisher',
  value: 'PUBLISHER',
  permissionDescription: 'Create, edit, and publish',
};

export const EDITOR_OPTION = {
  label: 'Editor',
  value: 'EDITOR',
  permissionDescription: "Create and edit (can't publish)",
};

export const ACCOUNT_PERMISSION_OPTIONS = [
  ADMINISTRATOR_OPTION,
  PUBLISHER_OPTION,
  EDITOR_OPTION,
];

// removing Editor option from Dropdown if the user does not have the hasEditorPermissionsRole gate
export const LIMITED_ACCOUNT_PERMISSION_OPTIONS = [
  ADMINISTRATOR_OPTION,
  PUBLISHER_OPTION,
];

export const ACCOUNT_PERMISSIONS = {
  ADMINISTRATOR: ADMINISTRATOR_OPTION.value,
  PUBLISHER: PUBLISHER_OPTION.value,
  EDITOR: EDITOR_OPTION.value,
};

export const ACCOUNT_PERMISSION_VALUE_TO_LABEL = {
  [ADMINISTRATOR_OPTION.value]: ADMINISTRATOR_OPTION.label,
  [PUBLISHER_OPTION.value]: PUBLISHER_OPTION.label,
  [EDITOR_OPTION.value]: EDITOR_OPTION.label,
};

export const ACCOUNT_PERMISSION_DROPDOWN = [
  {
    label: `${ADMINISTRATOR_OPTION.label}: ${ADMINISTRATOR_OPTION.permissionDescription}`,
    value: 'ACCOUNT_ADMIN',
  },
  {
    label: `${PUBLISHER_OPTION.label}: ${PUBLISHER_OPTION.permissionDescription}`,
    value: 'PUBLISHER',
  },
  {
    label: `${EDITOR_OPTION.label}: ${EDITOR_OPTION.permissionDescription}`,
    value: 'EDITOR',
  },
];

// removing Editor option from Dropdown if the user does not have the hasEditorPermissionsRole gate
export const LIMITED_ACCOUNT_PERMISSION_DROPDOWN = [
  {
    label: `${ADMINISTRATOR_OPTION.label}: ${ADMINISTRATOR_OPTION.permissionDescription}`,
    value: 'ACCOUNT_ADMIN',
  },
  {
    label: `${PUBLISHER_OPTION.label}: ${PUBLISHER_OPTION.permissionDescription}`,
    value: 'PUBLISHER',
  },
];
