import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  getLeafNodes,
  getPinTargetingSections,
  getSegments,
} from '@studio/conditions';
import {
  readDomainsForExperience,
  readSegmentsForExperience,
  readTagsForPins,
} from 'next/lib/selectors';
import { selectRule } from 'next/entities/rules';
import { openExperience } from 'next/lib/builder';
import { Shape as PinShape } from 'next/entities/experiences';
import { update } from 'next/entities/pins';
import useToggle from 'next/hooks/use-toggle';
import { Experience, Metric } from 'next/components/Listing';
import { OpenInBuilderManager } from 'next/components/OpenInBuilder';
import { EditTagsModal } from 'next/components/Tags';
import { pluralize } from 'next/lib/string-manipulations';
import Actions from './Actions';
import { PinCardWrapper } from './styled';

export function PinCard({
  domains,
  pin,
  segments,
  tags,
  view,
  updatePin,
  style,
  deferred,
}) {
  const { id, previewUrl, state, steps } = pin;

  const [editingTags, toggleEditingTags] = useToggle();
  const [openBuilder, toggleOpenBuilder] = useToggle();

  const handleOpenInBuilder = useCallback(() => {
    openExperience({ experience: id, url: previewUrl });
  }, [id, previewUrl]);

  // TODO: Consider abstracting as separate component e.g. PinMetrics
  const metric = useMemo(() => {
    const label = pluralize('pin', steps?.length || 0);
    return <Metric icon="thumbtack" label={label} />;
  }, [steps]);

  const handleTagsUpdate = tagIds => {
    updatePin(id, { tagIds });
  };

  return (
    <PinCardWrapper style={style}>
      <Experience
        actions={
          <Actions
            archived={state === 'ARCHIVED'}
            pinId={id}
            onOpenBuilder={toggleOpenBuilder}
            onOpenEditTags={toggleEditingTags}
          />
        }
        experience={pin}
        domains={domains}
        metric={metric}
        segments={segments}
        tags={tags}
        type="pins"
        view={view}
        deferred={deferred}
      />

      <OpenInBuilderManager
        onClose={toggleOpenBuilder}
        onNavigate={handleOpenInBuilder}
        type="pins"
        visible={openBuilder}
      />
      <EditTagsModal
        tags={tags?.data}
        onClose={toggleEditingTags}
        visible={editingTags}
        label={pin.name}
        onChange={handleTagsUpdate}
      />
    </PinCardWrapper>
  );
}

PinCard.propTypes = {
  ...Experience.propTypes,
  pin: PinShape,
};

const mapDispatchToProps = dispatch => ({
  updatePin: (id, delta) => dispatch(update(id, delta)),
});

const mapStateToProps = (state, { pin }) => {
  const rule = selectRule(state, pin.id) ?? {};
  const sections = getPinTargetingSections(rule.conditions);
  const audiences = getLeafNodes(sections.audience);
  const hasSegments = !sections.audience || getSegments(audiences).length > 0;

  return {
    domains: readDomainsForExperience(state, pin.id),
    tags: readTagsForPins(state, pin.id),
    ...(hasSegments && {
      segments: readSegmentsForExperience(state, pin.id),
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PinCard);
