/* global CUSTOMER_API_URL */
import createFetchClient from './client-factory';

export default function createCustomerAPIV2Client(auth) {
  const baseUrl = `${CUSTOMER_API_URL}/v2`;
  const fetchCustomerAPIV2 = createFetchClient(auth, baseUrl);

  return {
    getAccount: () => fetchCustomerAPIV2(),
    updateAccount: delta =>
      fetchCustomerAPIV2('', {
        method: 'PATCH',
        body: JSON.stringify(delta),
      }),
  };
}
