import React from 'react';
import PropTypes from 'prop-types';

import { ErrorMsg, Label, Input, Caption } from '@studio/legacy-components';

export const BuildUrlField = ({
  error = '',
  onBlur,
  onChange,
  touched = false,
  value,
}) => (
  <Label>
    Build URL
    <Caption> Enter the URL you want to add an experience to </Caption>
    <Input
      error={Boolean(error && touched)}
      type="text"
      name="previewUrl"
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    />
    {error && touched && <ErrorMsg>{error}</ErrorMsg>}
  </Label>
);

BuildUrlField.propTypes = {
  error: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  touched: PropTypes.bool,
  value: PropTypes.string,
};

export default BuildUrlField;
