import { useEffect, useRef } from 'react';

/**
 * Triggers a browser specific prompt on unload
 * @params {Bool} enabled - whether or not the callback should be called on unload
 */
function useBeforeUnload(enabled) {
  // We only want a listener here to prevent the default navigation behavior
  // in modern browsers this is usually only triggered after the page is interacted with
  const cb = useRef(e => {
    e.preventDefault();
    e.returnValue = '';
  });

  useEffect(() => {
    const onUnload = cb.current;
    window.addEventListener('beforeunload', enabled ? onUnload : null);
    return () => window.removeEventListener('beforeunload', onUnload);
  }, [enabled, cb]);
}

export default useBeforeUnload;
