export const EVENTS_EXPORT_REQUESTED = 'EVENTS_EXPORT_REQUESTED';
export const requestEventsExport = ({ events, email, startDate, endDate }) => ({
  type: EVENTS_EXPORT_REQUESTED,
  payload: {
    events,
    email,
    startDate,
    endDate,
  },
});
