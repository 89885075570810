import React from 'react';
import styled from 'styled-components';
import {
  Panel,
  H2,
  ExternalLink,
  Text,
  PanelBody,
} from '@studio/legacy-components';
import { UpgradeTag } from 'next/components/UpgradeTag';
import { BeaconLink } from 'components/Common/HelpScout';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LocalizationPrompt = () => {
  return (
    <Panel>
      <PanelBody>
        <TitleWrapper>
          <H2>Localization</H2>
          <UpgradeTag
            tooltipContent={
              <>
                <BeaconLink
                  aria-label="Upgrade to get access to language support and reach all your audiences."
                  message="I'd like to enable Localization."
                  subject="Interested in Localization"
                >
                  Upgrade
                </BeaconLink>{' '}
                to get access to language support and reach all your audiences.
              </>
            }
          />
        </TitleWrapper>
        <Text secondary>
          Add languages to support, then download the flow content and upload
          translations.{' '}
          <ExternalLink href="https://docs.appcues.com/article/584-localizing-content">
            Learn More
          </ExternalLink>
        </Text>
      </PanelBody>
    </Panel>
  );
};

export default LocalizationPrompt;
