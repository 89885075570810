import React from 'react';

const SlideoutIcon = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon-Slideout">
          <g id="Group" transform="translate(0.000000, 0.438081)">
            <mask id="mask-2" fill="white">
              <g>
                <path d="M0.0775862069,6.39011947 L0.0775862069,1.39650151 C0.0775862069,0.625235023 0.702330779,0 1.47459918,0 L16.602987,0 C17.374536,0 18,0.625315111 18,1.39650151 L18,14.5862571 C18,15.3575236 17.3752555,15.9827586 16.602987,15.9827586 L13.9465476,15.9827586 C13.930735,16.0150866 13.9144029,16.0471149 13.8975624,16.0788335 C13.402283,16.0788335 11.7487632,16.0378811 9.74743673,15.9827586 L1.47459918,15.9827586 C1.1669049,15.9827586 0.882444259,15.8833072 0.651622395,15.7148137 C0.260121499,15.7027842 0.0360337785,15.695833 0.0360337785,15.695833 L0.0360337785,6.41069166 C0.049830222,6.40374214 0.0636813102,6.39688464 0.0775861783,6.39011993 L0.0775862069,6.39011947 Z M0.0775862069,6.39011947 L0.0775862069,14.5862571 C0.0775862069,15.0498938 0.303654276,15.4608067 0.651622395,15.7148137 C2.21590695,15.7628788 6.45292482,15.8920181 9.74743673,15.9827586 L13.9465476,15.9827586 C14.1573801,15.551723 14.2758621,15.0674157 14.2758621,14.5537348 L14.2758621,13.5775862 L15.6724138,13.5775862 L15.6724138,2.40517241 L2.48275862,2.40517241 L2.48275862,6.05172414 L1.55249195,6.05172414 C1.02457469,6.05172414 0.523434612,6.17321515 0.0775861783,6.39011993 L0.0775862069,6.39011947 Z" />
              </g>
            </mask>
            <g>
              <path d="M0.0775862069,6.39011947 L0.0775862069,1.39650151 C0.0775862069,0.625235023 0.702330779,0 1.47459918,0 L16.602987,0 C17.374536,0 18,0.625315111 18,1.39650151 L18,14.5862571 C18,15.3575236 17.3752555,15.9827586 16.602987,15.9827586 L13.9465476,15.9827586 C13.930735,16.0150866 13.9144029,16.0471149 13.8975624,16.0788335 C13.402283,16.0788335 11.7487632,16.0378811 9.74743673,15.9827586 L1.47459918,15.9827586 C1.1669049,15.9827586 0.882444259,15.8833072 0.651622395,15.7148137 C0.260121499,15.7027842 0.0360337785,15.695833 0.0360337785,15.695833 L0.0360337785,6.41069166 C0.049830222,6.40374214 0.0636813102,6.39688464 0.0775861783,6.39011993 L0.0775862069,6.39011947 Z M0.0775862069,6.39011947 L0.0775862069,14.5862571 C0.0775862069,15.0498938 0.303654276,15.4608067 0.651622395,15.7148137 C2.21590695,15.7628788 6.45292482,15.8920181 9.74743673,15.9827586 L13.9465476,15.9827586 C14.1573801,15.551723 14.2758621,15.0674157 14.2758621,14.5537348 L14.2758621,13.5775862 L15.6724138,13.5775862 L15.6724138,2.40517241 L2.48275862,2.40517241 L2.48275862,6.05172414 L1.55249195,6.05172414 C1.02457469,6.05172414 0.523434612,6.17321515 0.0775861783,6.39011993 L0.0775862069,6.39011947 Z" />
            </g>
            <path
              d="M0.155172414,1.47350229 C0.155172414,0.702559126 0.77981594,0.0775862069 1.5517076,0.0775862069 L16.6034648,0.0775862069 C17.3747499,0.0775862069 18,0.702921303 18,1.47350229 L18,14.5092564 C18,15.2801995 17.3753565,15.9051724 16.6034648,15.9051724 L13.9901655,15.9051724 L1.5517076,15.9051724 C0.780422516,15.9051724 0.155172414,15.2798373 0.155172414,14.5092564 L0.155172414,6.37166276 L0.155172414,1.47350229 Z"
              fillRule="nonzero"
              mask="url(#mask-2)"
            />
            <path
              d="M2.56034483,13.578217 L9.93103448,13.578217 L9.93103448,10.3183347 L2.56034483,10.3183347 L2.56034483,13.578217 Z M0.155172414,9.34281691 C0.155172414,8.59643853 0.780196547,7.99137931 1.55249195,7.99137931 L10.9388874,7.99137931 C11.7106056,7.99137931 12.3362069,8.59595447 12.3362069,9.34281691 L12.3362069,14.5537348 C12.3362069,15.3001132 11.7111827,15.9051724 10.9388874,15.9051724 L1.55249195,15.9051724 C0.780773679,15.9051724 0.155172414,15.3005973 0.155172414,14.5537348 L0.155172414,9.34281691 Z"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SlideoutIcon;
