import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

export const TagTypes = {
  GREEN_1: 'green-1',
  GRAY_30: 'gray-30',
  GRAY_5: 'gray-5',
  GRAY_10: 'gray-10',
  GRAY_50: 'gray-50',
  BLUE_1: 'blue-1',
  PENDING: 'yellow-light',
  SUCCESS: 'brand-success',
  FAIL: 'brand-danger',
};

const Tag = props => {
  const { children, className, title, type, onClick } = props;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      title={title}
      onClick={onClick}
      className={classNames(className, type)}
    >
      {children}
    </div>
  );
};

Tag.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  type: PropTypes.oneOf(Object.values(TagTypes)).isRequired,
  onClick: PropTypes.func,
};

export default styled(Tag)`
  flex-direction: row;
  align-items: center;

  padding: 0.25rem 0.5rem;
  text-align: center;
  font-weight: 700;
  letter-spacing: 0.08em;
  text-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  font-size: 12px;
  line-height: 1;
  color: ${props =>
    props.color ? props.theme[props.color] : props.theme['$gray-0']};
  vertical-align: baseline;
  border-radius: 0.25em;
  white-space: nowrap;
  min-width: 60px;

  &.green-1 {
    background: ${props => props.theme.$green};
  }
  &.gray-30 {
    background: ${props => props.theme['$gray-30']};
  }
  &.gray-5 {
    background: ${props => props.theme['$gray-5']};
  }
  &.gray-10 {
    color: black;
    background: ${props => props.theme['$gray-10']};
  }
  &.gray-50 {
    background: ${props => props.theme['$gray-50']};
  }
  &.blue-1 {
    background: ${props => props.theme.$blue};
  }
  &.yellow-light {
    background: ${props => props.theme['$yellow-light']};
  }
  &.brand-success {
    background: ${props => props.theme.$green};
  }
  &.brand-danger {
    background: ${props => props.theme.$red};
  }
`;
