import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ErrorMessage, Field, Formik } from 'formik';
import { object, string, number, date, lazy } from 'yup';

import {
  Button,
  Radio,
  RadioGroup,
  RadioLabel,
  DateRangePicker,
  Label,
  Input,
  ButtonGroup,
  Text,
  ErrorMsg,
  Form,
  useDateRange,
  FULL_RANGE_OPTIONS,
} from '@studio/legacy-components';
import { endOf, startOf, subtract } from 'next/lib/date';

import { MOBILE_SURVEY_EXPORT, selectFeature } from 'next/entities/features';
import { selectPage } from 'next/entities/page';
import { FieldWrapper, RadioControlWithDescription, RadioText } from './styled';

export const INVALID_EMAIL_MSG = 'Please enter a valid email address.';
export const REQUIRED_EMAIL_MSG = 'Email is required to proceed.';

const VALIDATION_SCHEMA = object().shape({
  mode: string().required('Export option is required to proceed.'),
  email: string().email(INVALID_EMAIL_MSG).required(REQUIRED_EMAIL_MSG),
  dates: object().shape({
    range: lazy(value => (Number.isNaN(Number(value)) ? string() : number())),
    start: date().nullable(),
    end: date().nullable(),
  }),
});

// All time option for date range picker
const ALL_TIME = 'all-time';

const EXPORT_RANGE_OPTIONS = [
  { label: 'All time', value: ALL_TIME },
  ...FULL_RANGE_OPTIONS,
];

export const ExportCsvForm = ({
  onToggleModal,
  onSubmit,
  email,
  limit,
  createdAt,
  hasSurveyExport,
}) => {
  // Create dates for created and limit
  const { created, bound } = useMemo(
    () => ({
      created: startOf(createdAt, 'day'),
      bound: subtract(new Date(), limit - 1, 'days'),
    }),
    [createdAt, limit]
  );

  // Date input props to apply earliest date limit
  const startEndProps = {
    dayPickerProps: {
      disabledDays: [{ before: bound }],
    },
    placement: 'top',
  };

  // Force earliest start date to be created at date
  const calculate = range =>
    range === ALL_TIME
      ? { range: ALL_TIME, start: created, end: endOf(Date.now(), 'day') }
      : null;

  const [dates] = useDateRange(
    EXPORT_RANGE_OPTIONS,
    { range: ALL_TIME },
    calculate
  );

  return (
    <Formik
      validationSchema={VALIDATION_SCHEMA}
      initialValues={{
        mode: 'basic',
        dates,
        email,
      }}
      onSubmit={onSubmit}
      validateOnBlur
      validateOnMount
    >
      {({
        values,
        setFieldValue,
        errors,
        touched,
        handleSubmit,
        setFieldTouched,
        isValid,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <RadioGroup
              name="mode"
              value="none"
              onValueChange={value => {
                setFieldValue('mode', value);
                setFieldTouched('mode');
              }}
            >
              <Label>Export options</Label>
              <RadioControlWithDescription>
                <Radio
                  id="basic"
                  value="basic"
                  checked={values.mode === 'basic'}
                />
                <RadioLabel htmlFor="basic">
                  <RadioText>
                    Basic — basic events and default user properties, no custom
                    properties.
                  </RadioText>
                </RadioLabel>
              </RadioControlWithDescription>
              <RadioControlWithDescription>
                <Radio
                  id="full"
                  value="full"
                  checked={values.mode === 'full'}
                />
                <RadioLabel htmlFor="full">
                  <RadioText>
                    Full — all events and user data, including custom
                    properties.
                  </RadioText>
                </RadioLabel>
              </RadioControlWithDescription>
              {hasSurveyExport && (
                <RadioControlWithDescription>
                  <Radio
                    id="survey"
                    value="survey"
                    checked={values.mode === 'survey'}
                  />
                  <RadioLabel htmlFor="survey">
                    <RadioText>
                      Survey — includes only user survey responses properties.
                    </RadioText>
                  </RadioLabel>
                </RadioControlWithDescription>
              )}
            </RadioGroup>

            <Field
              name="dates"
              as={DateRangePicker}
              calculate={calculate}
              onChange={({ range, start, end }) => {
                setFieldValue('dates', { range, start, end });
                setFieldTouched('dates');
              }}
              options={EXPORT_RANGE_OPTIONS}
              endProps={startEndProps}
              startProps={startEndProps}
              portal
              stacked
            />

            <FieldWrapper>
              <Label htmlFor="email">
                Email address &#8212; we&#8217;ll send your CSV here
                <Field
                  name="email"
                  as={Input}
                  type="text"
                  placeholder="email@email.com"
                  aria-label="Email we'll send your CSV"
                  error={Boolean(touched.email && errors.email)}
                />
                <ErrorMessage name="email" component={ErrorMsg} />
              </Label>
            </FieldWrapper>

            <FieldWrapper>
              <Text>
                Depending on the size of the CSV file, it may take several
                minutes to arrive in your inbox.
              </Text>
            </FieldWrapper>

            <ButtonGroup right>
              <Button kind="primary" type="submit" disabled={!isValid}>
                Send CSV
              </Button>
              <Button kind="secondary" onClick={onToggleModal}>
                Cancel
              </Button>
            </ButtonGroup>
          </Form>
        );
      }}
    </Formik>
  );
};

ExportCsvForm.propTypes = {
  onToggleModal: PropTypes.func,
  onSubmit: PropTypes.func,
  email: PropTypes.string,
  limit: PropTypes.number,
  createdAt: PropTypes.number,
  hasSurveyExport: PropTypes.bool,
};

const mapStateToProps = state => {
  const page = selectPage(state);
  const isPin = page?.path.includes('/pins/');
  const hasMobileSurveyExport = selectFeature(state, MOBILE_SURVEY_EXPORT);

  return {
    hasSurveyExport: hasMobileSurveyExport && !isPin,
  };
};

export default connect(mapStateToProps)(ExportCsvForm);
