import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Select } from '@studio/legacy-components';
import { selectEventTargetingOptions } from 'selectors/event-names';
import { checkIsValueDefinedAndValid } from 'utils';

function SelectEvent({ value, options, onChange }) {
  const missing = !options.some(
    ({ value: optionValue }) => optionValue === value
  );

  if (checkIsValueDefinedAndValid(value) && missing) {
    options.push({ label: value, value });
  }

  const selected = options.find(option => option.value === value);

  return <Select onChange={onChange} options={options} value={selected} />;
}

SelectEvent.propTypes = {
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  value: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    options: selectEventTargetingOptions(state),
  };
}

export default connect(mapStateToProps)(SelectEvent);
