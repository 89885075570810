import React from 'react';
import {
  Link,
  ListRow,
  MenuSection,
  MoreMenu,
} from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';
import { TagsModal } from 'next/components/Tags';

export default function FlowsMoreMenu() {
  const [visible, toggle] = useToggle();

  return (
    <>
      <MoreMenu>
        <MenuSection>
          <ListRow forwardedAs={Link} to="/priorities">
            Prioritize Flows
          </ListRow>

          <ListRow onClick={toggle}>Manage tags</ListRow>
        </MenuSection>
      </MoreMenu>

      <TagsModal onClose={toggle} visible={visible} />
    </>
  );
}
