import React from 'react';
import styled from 'styled-components';
import {
  getNestedConditionsFromFlattenedClauses,
  clauseKeys,
} from '@appcues/libcues';
import RadioButton from 'components/Common/Forms/RadioButton';
import FlowActionSection from 'components/ChecklistsEdit/FlowActionSection';
import {
  determineDefaultPropertyClauseOnly,
  getItemAction,
  getItemClauses,
} from 'helpers/checklist-item-presets';
import { setDefaultPropertyClause } from 'helpers/rules';
import ItemActionRedirect from 'components/ChecklistsEdit/ItemActionRedirect';

function ItemAction({
  autoupdateFlowCompleted,
  checklistId,
  checklistItem,
  className,
  flowActionChecked,
  flowCompleted,
  flowId,
  pageActionChecked,
  pageUrl,
  updateChecklistItem,
}) {
  const redirectNewTab =
    getItemAction(checklistItem, 'redirect')?.attributes.params?.new_tab ||
    false;

  const determineUpdatedActions = (type, update) => {
    const { actions } = checklistItem;
    let updatedActions;
    if (!actions || actions.length === 0) {
      updatedActions = [update];
    } else {
      const actionExists = !!getItemAction(checklistItem, type);
      updatedActions = actionExists
        ? actions.map(action => {
            if (action.attributes && action.attributes.actionType === type) {
              return update;
            }
            return action;
          })
        : [...actions, update];
    }
    // redirect action has to precede flow action
    updatedActions.sort(action =>
      action.attributes && action.attributes.actionType === 'redirect' ? -1 : 1
    );

    return updatedActions;
  };

  const toggleItemAction = type => {
    const toggledActions = [{ attributes: { actionType: type } }];

    if (type !== 'show_flow' && flowCompleted) {
      const clauses = getItemClauses(checklistItem);

      updateChecklistItem(checklistId, checklistItem.id, {
        actions: toggledActions,
        conditions: getNestedConditionsFromFlattenedClauses(
          setDefaultPropertyClause(clauses),
          null,
          clauseKeys
        ),
      });
      return;
    }

    updateChecklistItem(checklistId, checklistItem.id, {
      actions: toggledActions,
    });
  };

  const removeRedirectAction = () => {
    const { actions: currentActions } = checklistItem;
    const updatedActions = currentActions.filter(
      action => action.attributes && action.attributes.actionType !== 'redirect'
    );

    updateChecklistItem(checklistId, checklistItem.id, {
      actions: updatedActions,
    });
  };

  const updateChecklistItemActions = (type, update) => {
    const updatedActions = determineUpdatedActions(type, update);
    const flowNotSet = !flowId;
    const settingFlowInAction =
      update.attributes.params && update.attributes.params.flowId;

    const defaultProperty =
      determineDefaultPropertyClauseOnly(checklistItem) &&
      flowNotSet &&
      settingFlowInAction;

    if ((flowCompleted || defaultProperty) && type === 'show_flow') {
      autoupdateFlowCompleted(update.attributes.params, updatedActions);
    }

    updateChecklistItem(checklistId, checklistItem.id, {
      actions: updatedActions,
    });
  };

  const updateChecklistItemPage = ({ target: { value } }) => {
    updateChecklistItemActions('redirect', {
      attributes: {
        actionType: 'redirect',
        params: {
          url: value,
          new_tab: redirectNewTab,
        },
      },
    });
  };

  const updateChecklistItemNewTab = () => {
    updateChecklistItemActions('redirect', {
      attributes: {
        actionType: 'redirect',
        params: {
          url: pageUrl,
          new_tab: !redirectNewTab,
        },
      },
    });
  };

  const updateChecklistItemFlow = value => {
    updateChecklistItemActions('show_flow', {
      attributes: {
        actionType: 'show_flow',
        params: { flowId: value },
      },
    });
  };

  const selectItemAction = (type, nested) => () => {
    const otherChecked =
      (type === 'redirect' && flowActionChecked) ||
      (type === 'show_flow' && pageActionChecked);

    // `nested` refers to whether the selectItemAction is being fired from within another item action
    if (otherChecked && !nested) {
      toggleItemAction(type);
    } else if (nested && pageActionChecked) {
      removeRedirectAction();
    } else {
      updateChecklistItemActions(type, {
        attributes: { actionType: type },
      });
    }
  };

  const selectRedirectAction = selectItemAction('redirect', false);

  const selectFlowAction = selectItemAction('show_flow', false);

  const onlyPageAction = pageActionChecked && !flowActionChecked;

  return (
    <div className={className}>
      <div className="indent-items">
        <div className="checkbox-with-dropdown-container">
          <RadioButton
            name="go"
            checked={onlyPageAction}
            onChange={selectRedirectAction}
            label="Go to a page"
          />
          {onlyPageAction && (
            <ItemActionRedirect
              pageUrl={pageUrl}
              onUpdateChecklistItemPage={updateChecklistItemPage}
              onUpdateChecklistItemNewTab={updateChecklistItemNewTab}
              redirectNewTab={redirectNewTab}
            />
          )}
        </div>
        <div className="select-flow">
          <RadioButton
            label="Launch a Flow"
            checked={flowActionChecked}
            onChange={selectFlowAction}
          />
          {flowActionChecked && (
            <FlowActionSection
              flowId={flowId}
              pageActionChecked={pageActionChecked}
              pageUrl={pageUrl}
              selectItemAction={selectItemAction}
              updateChecklistItemPage={updateChecklistItemPage}
              updateChecklistItemFlow={updateChecklistItemFlow}
              redirectNewTab={redirectNewTab}
              updateChecklistItemNewTab={updateChecklistItemNewTab}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default styled(ItemAction)`
  .select-flow {
    width: 100%;
  }

  .checkbox {
    margin-right: 10px;
  }

  .regular-text {
    font-size: 16px;
    color: ${props => props.theme['$gray-70']};
    display: flex;
    flex-shrink: 0;
  }

  .indent-items {
    .checkbox-with-dropdown-container {
      display: flex;
      align-items: flex-start;
      min-height: 38px;

      label:not(.switch) {
        width: 275px;
      }

      .item {
        .field-group-header {
          margin-right: 0.75rem;
          width: 100px;
        }
      }
      .checkbox-and-label {
        width: 275px;
      }
    }

    .checkbox-with-dropdown-container:first-of-type {
      padding-bottom: 10px;
    }

    .flow-options-container {
      margin-left: 34px;

      .go-to-input-container {
        display: flex;
      }
    }
  }
`;
