import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Heading, Modal } from '@studio/legacy-components';
import { asPromised } from 'utils/as-promised';
import { revokeKey } from 'entities/keys';
import { StateDescription, StateNegativeNotice } from './styled';

export const DisableKey = ({ apiKey, visible, onDisable, onClose }) => {
  const [inFlight, setInFlight] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (visible) {
      setInFlight(false);
      setError(false);
    }
  }, [visible]);

  const handleDisable = async () => {
    setInFlight(true);
    setError(false);

    try {
      await onDisable(apiKey);
      onClose();
    } catch {
      setError(true);
      setInFlight(false);
    }
  };

  return (
    <Modal onClose={onClose} size="s" visible={visible}>
      <Heading>Disable API Key</Heading>
      {error && (
        <StateNegativeNotice>
          We&apos;re sorry, something went wrong on our end. Try again.
        </StateNegativeNotice>
      )}
      <StateDescription>
        Disabling will prevent applications using this key from having access.
      </StateDescription>
      <ButtonGroup right>
        <Button kind="negative" onClick={handleDisable} disabled={inFlight}>
          Disable
        </Button>
        <Button kind="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ButtonGroup>
    </Modal>
  );
};

DisableKey.propTypes = {
  apiKey: PropTypes.string,
  state: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDisable: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  onDisable: key => asPromised(dispatch, revokeKey({ key })),
});

export default connect(null, mapDispatchToProps)(DisableKey);
