import styled from 'styled-components';
import { Icon, Overflow, Table, Td } from '@studio/legacy-components';

export const Description = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  margin-top: -8px;

  ${Icon} {
    color: var(--dark-resting);
  }
`;

export const StepIndex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--noreaster);
  font-weight: bold;
`;

export const StepLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const StepFunnel = styled.div`
  border-radius: 4px;
  min-width: 149px;
  height: 16px;
  background-color: var(--myst);
`;

export const StepCompletion = styled.div`
  border-radius: 4px;
  background-image: linear-gradient(var(--merfolk));
  width: ${props => props.$rate}%;
  height: 100%;
`;

export const TooltipContent = styled.div`
  * {
    color: var(--white) !important;
  }
`;

export const TableWrapper = styled.div`
  min-height: 388px;
  overflow-y: auto;
  position: relative;
  border-top: 1px solid var(--tint-black);

  ${Table} ${Td} {
    overflow: hidden;
    text-overflow: ellipsis;

    &:first-of-type {
      text-transform: capitalize;
    }

    &:nth-of-type(3) {
      font-weight: var(--bold);
    }
  }
`;

/*
 @todo the following components were copied from next/components/RecentUsers
   without modification. should they be made common molecules?
 */

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 388px;
  gap: 24px;
  flex-direction: column;
`;

export const NoResultsMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 388px;
  flex-direction: column;
  gap: 16px;
  position: absolute;
`;

export const ImageStepsContainer = styled(Overflow)`
  border-top: 1px solid var(--myst);
  margin-top: 8px;
`;
