import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Icon, Input } from '@studio/legacy-components';
import { CalculatorWrapper, Operator } from './styled';

const Calculator = ({
  initialValue,
  minValue = 0,
  maxValue = Number.MAX_SAFE_INTEGER,
  onChange,
}) => {
  const [currentValue, setCurrentValue] = useState(minValue);

  if (currentValue !== initialValue && Number.isInteger(initialValue)) {
    setCurrentValue(initialValue);
  }

  return (
    <CalculatorWrapper>
      <Operator
        disabled={currentValue === minValue}
        onClick={() => {
          setCurrentValue(Math.max(minValue, currentValue - 1));
          onChange?.({
            prev: currentValue,
            next: currentValue - 1,
          });
        }}
      >
        <Icon icon="minus" name="minus" />
      </Operator>
      <Input
        type="number"
        name="units"
        aria-label="units"
        disabled
        value={currentValue}
      />
      <Operator
        disabled={currentValue === maxValue}
        onClick={() => {
          setCurrentValue(Math.min(maxValue, currentValue + 1));
          onChange?.({
            prev: currentValue,
            next: currentValue + 1,
          });
        }}
      >
        <Icon icon="plus" name="plus" />
      </Operator>
    </CalculatorWrapper>
  );
};

Calculator.propTypes = {
  initialValue: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  onChange: PropTypes.func,
};

export default Calculator;
