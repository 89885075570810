import { select, call, put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from 'constants/chromeExtension/actionTypes';
import { USER_REPLACED } from 'actions/user';

import {
  selectHasCheckedForChromeExtension,
  selectChromeExtensionId,
} from 'reducers/chromeExtension';
import { buildDeepLinkToStepChild } from 'utils/steps';
import { selectFlow, selectFlows } from 'reducers/account/flows';
import { hasChrome } from 'helpers/chromeExtension';
import { getChromeExtensionVersion } from 'utils/chromeExtension';
import { promisaga } from 'utils/as-promised';
import { updateChromeExtensionStatus } from 'actions/chromeExtension';

export function* checkForChromeExtension(forceCheck = false) {
  if (!hasChrome()) {
    yield put(
      updateChromeExtensionStatus({
        hasChrome: false,
        hasExtensionInstalled: false,
      })
    );
  }
  const hasCheckedForChromeExtension = yield select(
    selectHasCheckedForChromeExtension
  );

  if (!forceCheck && hasCheckedForChromeExtension) {
    return;
  }

  const chromeExtensionId = yield select(selectChromeExtensionId);

  try {
    const chromeExtensionVersion = yield call(
      getChromeExtensionVersion,
      chromeExtensionId
    );

    yield put(
      updateChromeExtensionStatus({
        hasChrome: true,
        hasExtensionInstalled: true,
        version: chromeExtensionVersion,
      })
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    yield put(
      updateChromeExtensionStatus({
        hasChrome: true,
        hasExtensionInstalled: false,
      })
    );
  }
}

export function* sendBuildMessageToChromeExtension(
  url,
  contentKey = null,
  options = {}
) {
  const chromeExtensionId = yield select(selectChromeExtensionId);

  const injectionData = {
    url,
    contentKey,
    options,
  };

  window.chrome.runtime.sendMessage(chromeExtensionId, {
    action: 'add-builder',
    value: JSON.stringify(injectionData),
  });
}

export function* startBuilderInChromeExtension(action) {
  const { url } = action.payload;
  yield call(sendBuildMessageToChromeExtension, url);
}

export function* editStepInChromeExtension(action) {
  const { stepGroupId, stepChildId } = action.payload;
  const stepGroup = yield select(selectFlow, stepGroupId);
  const allSteps = yield select(selectFlows);

  const url = buildDeepLinkToStepChild(stepGroup, stepChildId, allSteps);

  yield call(sendBuildMessageToChromeExtension, url, stepGroupId, {
    stepKey: stepChildId,
  });
}

export default function* chromeExtension() {
  yield takeEvery(
    actionTypes.CHECK_FOR_CHROME_EXTENSION,
    checkForChromeExtension
  );
  yield takeEvery(
    actionTypes.EDIT_STEP_IN_CHROME_EXTENSION,
    promisaga(editStepInChromeExtension)
  );
  yield takeEvery(
    actionTypes.START_BUILDER_IN_CHROME_EXTENSION,
    startBuilderInChromeExtension
  );
  yield takeEvery(USER_REPLACED, checkForChromeExtension, true);
}
