import * as satisfactionActionTypes from 'constants/satisfaction/actionTypes';

export const toggleSatisfactionExportFormat = () => {
  return {
    type: satisfactionActionTypes.TOGGLE_SATISFACTION_EXPORT_FORMAT,
  };
};

export function createSatisfactionWithRule(rule, conditions) {
  return {
    type: satisfactionActionTypes.CREATE_SATISFACTION_WITH_RULE,
    payload: {
      rule,
      conditions,
    },
  };
}

export function updateAndFlushSatisfaction(satisfactionId, changes) {
  return {
    type: satisfactionActionTypes.UPDATE_SATISFACTION_AND_FLUSH_SATISFACTION,
    payload: {
      satisfactionId,
      changes,
    },
  };
}

export const replaceAccountSatisfaction = satisfaction => {
  return {
    type: satisfactionActionTypes.REPLACE_ACCOUNT_SATISFACTION,
    payload: { satisfaction },
  };
};

export const toggleSurveyPreview = (collapsePreview, previewTab) => ({
  type: satisfactionActionTypes.TOGGLE_SURVEY_PREVIEW,
  payload: { collapsePreview, previewTab },
});

export const setAutoCollapseFeedbackSetting = (
  satisfactionId,
  autoCollapseFeedbackForm
) => ({
  type: satisfactionActionTypes.SET_AUTO_COLLAPSE_FEEDBACK_SETTING,
  payload: {
    satisfactionId,
    autoCollapseFeedbackForm,
  },
});

export const safisfactionCreated = id => ({
  type: satisfactionActionTypes.SATISFACTION_CREATED,
  payload: {
    id,
  },
});
