export const CHECK_FOR_CHROME_EXTENSION = 'CHECK_FOR_CHROME_EXTENSION';
export const EDIT_STEP_IN_CHROME_EXTENSION = 'EDIT_STEP_IN_CHROME_EXTENSION';
export const START_BUILDER_IN_CHROME_EXTENSION =
  'START_BUILDER_IN_CHROME_EXTENSION';
export const SET_IS_SPOOFING_ACCOUNT_IN_CHROME_EXTENSION =
  'SET_IS_SPOOFING_ACCOUNT_IN_CHROME_EXTENSION';
export const LOGOUT_USER_FROM_CHROME_EXTENSION =
  'LOGOUT_USER_FROM_CHROME_EXTENSION';
export const CHROME_EXTENSION_STATUS_UPDATED =
  'CHROME_EXTENSION_STATUS_UPDATED';
