import { createCollectionReducer } from 'reducers/account/collections';
import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

export const selectEventUserProfileResults = (state, cacheKey) =>
  state[TYPE]?.[cacheKey]?.results;

export const selectTotalResults = (state, cacheKey) => {
  const pageInfo = state[TYPE]?.[cacheKey]?.pageInfo;
  return pageInfo?.total;
};
