import { call, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';

export function promiseMiddleware() {
  return next =>
    ({ meta = {}, ...action }) => {
      return new Promise((resolve, reject) => {
        next({ ...action, meta: { ...meta, resolve, reject } });
      });
    };
}

function promisaga(saga) {
  return function* generator(action) {
    const { meta = {} } = action;
    try {
      const value = yield call(saga, action);
      yield meta.resolve?.(value);
    } catch (error) {
      yield meta.reject?.(error);
    }
  };
}

function promised(take) {
  return (pattern, saga, ...args) => take(pattern, promisaga(saga), ...args);
}

export const takeEveryPromised = promised(takeEvery);
export const takeLatestPromised = promised(takeLatest);
export const takeLeadingPromised = promised(takeLeading);
