/* global IMAGE_SERVICE_LAMBDA_URL */

import qs from 'qs';

export default auth => {
  const baseImageServiceUrl = IMAGE_SERVICE_LAMBDA_URL;
  /**
   * Prepares a request to the image service for a given endpoint method
   *
   * @param flowId
   * @param stepGroupId
   * @param stepChildId
   * @param localeId
   */

  const fetchFromImageService = ({
    flowId,
    stepGroupId,
    stepChildId,
    localeId,
    force,
  }) =>
    Promise.all([auth.getToken(), auth.getAccountId()])
      .then(([token, accountId]) => {
        const queryParameters = {
          accountId,
          flowId,
          flowStepId: stepGroupId,
          flowStepChildId: stepChildId,
          localeId,
          force,
        };

        return fetch(
          `${baseImageServiceUrl}/?${qs.stringify(queryParameters)}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      })
      .then(response => {
        if (response.ok) {
          return response;
        }
        throw new Error(response.statusText);
      });

  return {
    /**
     * Requests an image directly from the image-service, bypassing
     * the CDN entirely
     *
     * @param flowId
     * @param stepGroupId
     * @param stepChildId
     * @param localeId
     * @returns {Promise<string>}
     */

    get: ({ flowId, stepGroupId, stepChildId, localeId }) =>
      fetchFromImageService({
        flowId,
        stepGroupId,
        stepChildId,
        localeId,
      }).then(response => response.blob()),
  };
};
