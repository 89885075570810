import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  Description,
  Heading,
  Input,
  Label,
  Modal,
  Text,
} from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';
import { Disclaimer, Form, Note } from './styled';

const initialValues = {
  testUrl: '',
  testUsername: '',
  testPassword: '',
};

export const CredentialsManager = ({ exists, onSubmit }) => {
  const [open, toggle] = useToggle();
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    setValues(initialValues);
  }, [open]);

  const handleSubmit = event => {
    event.preventDefault();
    onSubmit(values);
    toggle();
  };

  const title = exists ? 'Update demo login' : 'Add demo login';

  return (
    <>
      <Button kind="secondary" onClick={toggle}>
        {title}
      </Button>

      <Modal onClose={toggle} size="l" visible={open}>
        <Heading>{title}</Heading>

        <Description>
          <Text>
            <Note>Security note:</Note> Please do not provide us with login
            credentials that could jeopardize the security of your organization.
            Appcues claims no responsibility over the security of your product
            or company, so we ask that you adhere to the security guidelines of
            your organization before providing this information.
          </Text>

          {exists && (
            <Disclaimer>
              You already have credentials on file. Any updates will overwrite
              those credentials.
            </Disclaimer>
          )}
        </Description>

        <Form autocomplete="off" onSubmit={handleSubmit}>
          <Label htmlFor="url">
            Test environment URL
            <Input
              id="url"
              name="url"
              placeholder="Enter application URL"
              onChange={({ target: { value } }) => {
                setValues(current => ({ ...current, testUrl: value }));
              }}
              type="text"
              value={values.testUrl}
            />
          </Label>

          <Label htmlFor="username">
            Test username
            <Input
              id="username"
              name="username"
              placeholder="Enter username"
              onChange={({ target: { value } }) => {
                setValues(current => ({ ...current, testUsername: value }));
              }}
              type="text"
              value={values.testUsername}
            />
          </Label>

          <Label htmlFor="password">
            Test password
            <Input
              id="password"
              name="password"
              placeholder="Enter password"
              onChange={({ target: { value } }) => {
                setValues(current => ({ ...current, testPassword: value }));
              }}
              type="password"
              value={values.testPassword}
            />
          </Label>

          <ButtonGroup right>
            <Button kind="primary" type="submit">
              {exists ? 'Update' : 'Save'}
            </Button>
            <Button kind="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ButtonGroup>
        </Form>
      </Modal>
    </>
  );
};

CredentialsManager.propTypes = {
  exists: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default CredentialsManager;
