import PropTypes from 'prop-types';
import pick from 'lodash.pick';
import { ContentStateShape } from 'next/lib/shapes';
import { ExperimentShape } from 'next/entities/experiment';
import {
  PIN_TOOLTIP_TYPE,
  PIN_BUTTON_TYPE,
  GROUP_TYPE,
  BANNER_TYPE,
  LAUNCHPAD_TYPE,
} from './constants';

/* Shape of the experience steps */
export const StepShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf([
    PIN_TOOLTIP_TYPE,
    PIN_BUTTON_TYPE,
    GROUP_TYPE,
    BANNER_TYPE,
    LAUNCHPAD_TYPE,
  ]),
});

StepShape.children = PropTypes.arrayOf(StepShape);

/**
 * Full shape of experience data model
 */
const Shape = {
  appId: PropTypes.string,
  archivedAt: PropTypes.number,
  archivedBy: PropTypes.string,
  createdAt: PropTypes.number,
  createdBy: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  previewUrl: PropTypes.string,
  published: PropTypes.bool,
  publishedAt: PropTypes.number,
  publishedBy: PropTypes.string,
  state: ContentStateShape,
  steps: PropTypes.arrayOf(StepShape),
  tagIds: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.objectOf(
    PropTypes.shape({
      themeId: PropTypes.string,
      accountId: PropTypes.string,
    })
  ),
  traits: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.oneOf([
    'journey',
    'mobile',
    'persistent',
    'banner',
    'launchpad',
  ]),
  unpublishedAt: PropTypes.string,
  unpublishedBy: PropTypes.string,
  updatedAt: PropTypes.number,
  updatedBy: PropTypes.string,
  experiment: ExperimentShape,
};

/**
 * Allowed fields for storage
 */
const allowed = [
  'appId',
  'createdAt',
  'createdBy',
  'id',
  'name',
  'previewUrl',
  'published',
  'publishedAt',
  'state',
  'steps',
  'type',
  'tagIds',
  'updatedAt',
  'updatedBy',
  'experiment',
];

/**
 * Experience parsing function
 *
 * @param {Object<keyof Experience>} experience - Object with experience fields
 * @return {Object<keyof Experience>} Object with parsed experience fields
 */
export const parse = experience => pick(experience, allowed);

export default PropTypes.shape(parse(Shape));
