import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CButton } from '@appcues/component-library';
import FlowCarousel from 'components/Common/FlowCarousel';
import { selectFlow, selectStepGroups } from 'reducers/account/flows';

import Modal from 'components/Common/UI/Modal/Modal';

export const FlowPreviewModal = ({
  onClose,
  stepGroups,
  flow,
  language,
  localeId,
}) => (
  <Modal
    onClose={onClose}
    type="full"
    label="Previewing"
    title={language}
    subtitle={flow.name}
  >
    <Modal.Content>
      <Container>
        <FlowCarousel
          localeId={localeId}
          journey={flow}
          allSteps={stepGroups}
          versionId="latest"
          className="flowCarousel"
          size="original"
          showHoverOptions={false}
          background="$gray-2"
          height="416px"
        />
      </Container>
    </Modal.Content>
    <Footer>
      <CButton type="secondary" onClick={onClose}>
        Done
      </CButton>
    </Footer>
  </Modal>
);

FlowPreviewModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  stepGroups: PropTypes.object,
};

function mapStateToProps(state, { flowId }) {
  return {
    stepGroups: selectStepGroups(state),
    flow: selectFlow(state, flowId),
  };
}

const Container = styled.div`
  width: 95%;
  margin: 0 auto;
`;

const Footer = styled(Modal.Footer)`
  position: fixed;
  bottom: 10px;
`;

export default connect(mapStateToProps)(FlowPreviewModal);
