import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { CButton } from '@appcues/component-library';

import { selectFlow } from 'reducers/account/flows';
import CoolTip from 'components/Common/CoolTip';
import { removeTranslations } from 'actions/account/translations';

const DelocalizeManager = ({ flowId, visible, disabled, onClick }) => {
  const handleClick = useCallback(() => void onClick(flowId));

  if (!visible) {
    return null;
  }

  const tooltip = disabled
    ? 'You need to unpublish this Flow to remove translations. Translations must be removed before you can make changes to the Flow in Builder.'
    : 'Translations have already been uploaded for this Flow. You will not be able to make changes to the Flow in Builder until you remove these translations. You will then need to upload new translation files.';

  return (
    <CoolTip tip={tooltip}>
      <CButton type="negative" disabled={disabled} onClick={handleClick}>
        Remove translations
      </CButton>
    </CoolTip>
  );
};

const mapStateToProps = (state, { flowId }) => {
  const flow = selectFlow(state, flowId);
  const { lockedForLocalization, published } = flow;

  return {
    visible: lockedForLocalization,
    disabled: published,
  };
};

const mapDispatchToProps = {
  onClick: removeTranslations,
};

export default connect(mapStateToProps, mapDispatchToProps)(DelocalizeManager);
