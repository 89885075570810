import React from 'react';
import { conditionNames, propertyOperators } from '@appcues/libcues';
import { CCheckbox } from '@appcues/component-library';
import styled from 'styled-components';
import { Select } from '@studio/legacy-components';
import { filterABGroupClauses } from 'utils/conditions';

const linkForABTesting = 'https://docs.appcues.com/article/9-a-b-groups';

const ABTitle = styled.div`
  h4 {
    margin-bottom: 8px;
  }

  p,
  a {
    color: ${props => props.theme['$gray-30']};
  }
`;

export default function ABTesting({
  stepId,
  clauses,
  addClause,
  updateClause,
  deleteClause,
}) {
  const abTestingClause = clauses.find(filterABGroupClauses);

  return (
    <div>
      <ABTitle>
        <h4>A/B Comparison</h4>
        <p>
          Target the A and B groups auto-created by Appcues.
          <p>
            Note: this feature is no longer actively supported and has been
            replaced with the Audience Randomizer auto-property.{' '}
            <a
              href={linkForABTesting}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </a>
            .
          </p>
        </p>
      </ABTitle>
      <CCheckbox
        label="Split my users into two groups"
        onChange={() => {
          if (abTestingClause) {
            deleteClause(stepId, abTestingClause.id);
          } else {
            addClause(stepId, null, {
              conditionName: conditionNames.PROPERTIES,
              property: '_ABGroup',
              operator: propertyOperators.EQUALS,
              value: '1',
            });
          }
        }}
        checked={!!abTestingClause}
      />
      {(() => {
        if (abTestingClause) {
          const abGroupOptions = [
            { value: '1', label: 'Show this flow to group A only' },
            { value: '2', label: 'Show this flow to group B only' },
          ];

          return (
            <div className="content">
              <Select
                value={abGroupOptions.find(
                  ({ value }) => value === `${abTestingClause.value}`
                )}
                options={abGroupOptions}
                onChange={({ value }) => {
                  updateClause(stepId, abTestingClause.id, {
                    value,
                  });
                }}
              />
            </div>
          );
        }
        return null;
      })()}
    </div>
  );
}
