import React from 'react';

const ModalIcon = () => {
  return (
    <svg viewBox="0 0 18 18" width="18" height="18">
      <path d="M0,1.94041847 C0,1.16236839 0.630075205,0.531634183 1.40867897,0.531634183 L16.591321,0.531634183 C17.3693129,0.531634183 18,1.16255967 18,1.94041847 L18,15.6358933 C18,16.4139434 17.3699248,17.0446777 16.591321,17.0446777 L1.40867897,17.0446777 C0.63068706,17.0446777 0,16.4137521 0,15.6358933 L0,1.94041847 Z M2.34782609,14.6968516 L15.6521739,14.6968516 L15.6521739,2.87946027 L2.34782609,2.87946027 L2.34782609,14.6968516 Z M3.85327557,4.27255697 L14.0801291,4.27255697 L14.0801291,9.72259187 L3.85327557,9.72259187 L3.85327557,4.27255697 Z M8.17448126,10.7678041 L14.0801291,10.7678041 L14.0801291,13.3808345 L8.17448126,13.3808345 L8.17448126,10.7678041 Z" />
    </svg>
  );
};

export default ModalIcon;
