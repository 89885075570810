import { getContext, takeLatest, call, put, select } from 'redux-saga/effects';
import { navigate } from 'next/lib/history';
import toast from 'next/lib/toast';
import {
  ACCOUNT_CREATE_REQUESTED,
  ACCOUNT_CREATED,
  ACCOUNT_SWITCH_REQUESTED,
  accountCreated,
  accountSwitched,
  patterns,
  reject,
  resolve,
  send,
} from 'actions/accounts';
import { USER_REPLACED } from 'actions/user';
import { reportError } from 'helpers/error-reporting';
import { promisaga } from 'utils/as-promised';
import { selectUserId } from 'reducers/user';

export function* fetchAccounts() {
  try {
    yield put(send());
    const userId = yield select(selectUserId);
    const api = yield getContext('api');
    const accounts = yield call(api.getAccounts, userId);
    yield put(resolve(accounts));
  } catch (error) {
    yield put(reject(error));
    yield call(reportError, error);
  }
}

export function* createAccount() {
  try {
    const accounts = yield getContext('accounts');
    const id = yield call(accounts.create);
    yield put(accountCreated(id));
  } catch (error) {
    yield call(toast.error, 'Failed to create new acocunt');
    yield call(reportError, error);
  }
}

export function* switchAccount({ payload: { id } }) {
  try {
    const auth = yield getContext('auth');
    const accounts = yield getContext('accounts');

    yield call(auth.switchAccount, id);
    yield call(accounts.approveAdd);
    yield put(accountSwitched());

    // NOTE: Rather than switching in the same session, perform a hard refresh
    //       to simplify the process for the future and to maintain the
    //       correct features flags in context
    yield call(navigate, '/');
  } catch (error) {
    yield call(toast.error, `Failed to switch to account ${id}`);
    yield call(reportError, error);
  }
}

export default function* saga() {
  yield takeLatest(
    [patterns.callApi, USER_REPLACED, ACCOUNT_CREATED],
    fetchAccounts
  );
  yield takeLatest(ACCOUNT_CREATE_REQUESTED, promisaga(createAccount));
  yield takeLatest(
    [ACCOUNT_SWITCH_REQUESTED, ACCOUNT_CREATED],
    promisaga(switchAccount)
  );
}
