import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CButton, CPanel, H3 } from '@appcues/component-library';

import Events from 'assets/images/events.svg';

const EmptyStateWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 30px 90px 30px 30px;
  align-items: center;
  max-width: 428px;
  > * {
    margin: 1em 0;
  }
`;

const EmptyState = () => (
  <CPanel alignItems="center">
    <EmptyStateWrapper>
      <img src={Events} alt="No events in Account" />
      <H3>No Events Yet</H3>
      <p>
        All event activity from your Appcues content, Flow Builder, and
        installed SDK events will be shown here. Get started by creating a flow,
        checklist, tracking event or send events from your site through
        installation.
      </p>
      <p>
        <CButton type="positive" is={Link} to="./settings">
          Learn more
        </CButton>
      </p>
    </EmptyStateWrapper>
  </CPanel>
);

export default EmptyState;
