import { useEffect } from 'react';
import { history } from 'next/lib/history';
import { listener as FlowListener } from 'next/pages/flows';
import { listener as PinListener } from 'next/pages/pins';

/**
 * Global route listeners
 */
const listeners = [FlowListener, PinListener];

/**
 * Hook to run listeners on route changes
 */
export default function useListeners() {
  useEffect(() => {
    const unlisten = history.listen(location => {
      const { pathname } = location;

      listeners.forEach(listener => {
        listener(pathname);
      });
    });

    return () => {
      unlisten();
    };
  }, []);
}
