import styled from 'styled-components';

import { withPanelStyle } from '@appcues/component-library';

export const CardFooter = styled.div`
  position: absolute;
  bottom: 0;
  padding: 15px;
  left: 0;

  ${({ available }) => !available && `opacity: 0.5;`}
`;

export const CardAction = styled.div`
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 8px;
  top: 8px;
  transition: opacity 0.2s ease;
`;

export const BetaFlag = styled.img`
  left: 10px;
  max-width: 40px;
  opacity: 0.8;
  pointer-events: none;
  position: absolute;
  top: 10px;
  transition: opacity 0.2s ease;
`;

export const IntegrationImage = styled.img`
  max-height: 100%;
  max-width: 148px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 5px;

  ${({ available }) => !available && `opacity: 0.5;`};
`;

export const IntegrationCardWrapper = withPanelStyle(styled.div`
  position: relative;
  height: 120px;
  padding: ${props => props.padding || '20px 40px'};
  border: 2px solid transparent;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  color: ${props => props.theme['$green-dark']};

  &:is(:hover, :focus) {
    border-color: ${props => props.theme.$green};
    box-shadow: ${props => props.theme['$box-shadow-2']};

    & ${CardAction}, ${BetaFlag} {
      opacity: 1;
      pointer-events: all;
    }
  }

  ${({ available, isEnabled, theme }) => {
    switch (true) {
      case !available:
        return `filter: grayscale(100%);`;
      case isEnabled:
        return `
          border-color: ${theme.$green};
          box-shadow: ${theme['$box-shadow-2']};
          color: ${theme['$gray-5']};

          &:hover {
            border-color: ${theme.$red};
          }
        `;
      default:
        return ``;
    }
  }}
`);
