import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Link, Td, Tr, IconButton } from '@studio/legacy-components';
import SelectedEventLink from 'components/Insights/Explorer/Chart/SelectedEventLink';
import { ExternalLinkIcon } from 'components/Insights/Common/Styled';
import EventToggle from 'components/Insights/Explorer/Common/EventToggle';

export const ANALYTICS_PAGE_TITLE = {
  flow: 'Flow Analytics Page',
  checklist: 'Checklist Analytics Page',
  pin: 'Pin Analytics Page',
};

const EventNameColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ExternalLinkWrapper = styled.div`
  margin-left: 16px;
  padding-left: 16px;
  border-left: 1px solid var(--text-secondary);
`;

const RemoveColumn = styled(Td)`
  width: 40px;
  max-width: 40px;
  padding: 0;

  ${IconButton} {
    margin: 0 auto;
  }
`;

const Wrapper = styled(Tr)`
  --selected-event-transition: all 200ms ease-in-out;

  opacity: 0;
  transition: var(--selected-event-transition);

  ${Td}, ${IconButton} {
    transition: var(--selected-event-transition);
  }

  &.slide-appear,
  &.slide-enter,
  &.slide-exit,
  &.slide-exit-active,
  &.slide-exit-done {
    opacity: 0;

    ${Td} {
      padding: 0 24px;
      line-height: 0;
      overflow: hidden;
    }

    ${RemoveColumn} {
      padding: 0;
    }

    ${IconButton} {
      max-height: 0;
    }
  }

  &.slide-appear-active,
  &.slide-appear-done,
  &.slide-enter-active,
  &.slide-enter-done {
    opacity: 1;

    ${Td} {
      padding: 8px 24px;
      line-height: inherit;
      overflow: auto;
    }

    ${RemoveColumn} {
      padding: 0;
    }

    ${IconButton} {
      max-height: 9999px;
    }
  }
`;

const SelectedEventRow = ({
  name,
  source,
  id,
  stepId,
  events,
  users,
  totalUsers,
  onSelect = () => null,
  shouldHideCheckbox,
}) => {
  const percentUsers = users && totalUsers && 100 * (users / totalUsers);

  const analyticsTitle = ANALYTICS_PAGE_TITLE[source];
  const urls = {
    flow: `/flows/${id}/analytics`,
    checklist: `/checklists/${id}/analytics`,
    pin: `/pins/${id}/analytics`,
    mobile: `/mobile/flows/${id}/analytics`,
    banner: `/banners/${id}/analytics`,
    goals: `/goals/${id}`,
  };

  const analyticsUrl = urls[source];

  return (
    <Wrapper>
      {!shouldHideCheckbox && (
        <RemoveColumn>
          <EventToggle
            label="Remove selected event"
            checked
            onClick={onSelect}
          />
        </RemoveColumn>
      )}
      <Td title={source || false}>
        <EventNameColumn>
          <SelectedEventLink
            event={name}
            id={id}
            source={source}
            stepId={stepId}
          />
          {['flow', 'checklist', 'pin', 'mobile', 'banner', 'goals'].includes(
            source
          ) && (
            <ExternalLinkWrapper>
              <Link
                to={analyticsUrl}
                target="_blank"
                title={analyticsTitle}
                rel="noopener noreferrer"
              >
                <ExternalLinkIcon />
              </Link>
            </ExternalLinkWrapper>
          )}
        </EventNameColumn>
      </Td>
      <Td>{events ? events.toLocaleString() : '-'}</Td>
      <Td>{users ? users.toLocaleString() : '-'}</Td>
      <Td>
        {percentUsers
          ? `${
              percentUsers < 1
                ? '< 1'
                : percentUsers > 100
                ? '100'
                : Math.round(percentUsers)
            }%`
          : '-'}
      </Td>
    </Wrapper>
  );
};

SelectedEventRow.propTypes = {
  name: PropTypes.string,
  source: PropTypes.string,
  id: PropTypes.string,
  stepId: PropTypes.string,
  events: PropTypes.number,
  users: PropTypes.number,
  totalUsers: PropTypes.string,
  onSelect: PropTypes.func,
  shouldHideCheckbox: PropTypes.bool,
};

export default SelectedEventRow;
