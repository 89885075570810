import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ExternalLink, H3, Panel } from '@studio/legacy-components';
import useTitle from 'next/hooks/use-title';
import {
  getKeys,
  selectAllApiKeys,
  selectKeysSynced,
  keyShape,
} from 'entities/keys';

import { Wrapper, ApiDescription, HeaderWrapper, TableLoader } from './styled';
import KeysTable from './KeysTable';
import CreateManager from './CreateManager';

const MAX_KEYS_ALLOWED = 100;

export const ApiKeys = ({ keys = [], isLoading, onLoad }) => {
  useTitle('API Keys | Settings | Appcues');
  useEffect(() => {
    onLoad();
  }, []);

  // The max allowed keys + 1 legacy key
  const hasMaxKeys = keys.length - 1 >= MAX_KEYS_ALLOWED;

  return (
    <Wrapper>
      <ApiDescription>
        Use API Keys when working with our Public API.{' '}
        <ExternalLink href="https://docs.appcues.com/article/745-appcues-public-api">
          Learn more
        </ExternalLink>
      </ApiDescription>
      <Panel>
        <HeaderWrapper>
          <H3>API Keys</H3>
          <CreateManager disabled={hasMaxKeys} />
        </HeaderWrapper>
        {isLoading ? <TableLoader /> : <KeysTable keys={keys} />}
      </Panel>
    </Wrapper>
  );
};

ApiKeys.propTypes = {
  keys: PropTypes.arrayOf(keyShape),
  isLoading: PropTypes.bool,
  onLoad: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    keys: selectAllApiKeys(state),
    isLoading: !selectKeysSynced(state),
  };
};

const mapDispatchToProps = {
  onLoad: getKeys,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeys);
