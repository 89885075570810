/* eslint-disable no-restricted-imports */
import { combineReducers } from 'redux';
import { CLEAR_ACCOUNT_DATA } from 'constants/actionTypes';

import analytics from 'reducers/analytics';
import analyticsV2 from 'reducers/account/analytics-v2';
import charts from 'reducers/account/charts';
import checklists from 'entities/checklists/reducer';
import checklistStatus from 'entities/checklist-status/reducer';
import conditions from 'reducers/account/conditions';
import customEvents from 'reducers/account/customEvents';
import experiences from 'entities/experiences/reducer';
import features from 'reducers/account/features';
import flows from 'reducers/account/flows';
import flowStatus from 'reducers/account/flowStatus';
import flowVersions from 'reducers/account/flowVersions';
import gates from 'entities/gates/reducer';
import goals from 'reducers/account/goals';
import groupAttributes from 'entities/group-attributes/reducer';
import images from 'reducers/account/images';
import installedDomains from 'reducers/account/installedDomains';
import integrations from 'reducers/account/integrations';
import keys from 'entities/keys/reducer';
import userSegments from 'entities/user-segments/reducer';
import locales from 'reducers/account/locales';
import meta from 'reducers/account/meta';
import profileAttributes from 'entities/profile-attributes/reducer';
import profileAttributeLabels from 'entities/profile-attribute-labels/reducer';
import eventNames from 'entities/event-names/reducer';
import eventLabels from 'entities/event-labels/reducer';
import publishedRules from 'reducers/account/published-rules';
import requests from 'reducers/account/requests';
import rules from 'reducers/account/rules';
import satisfaction from 'reducers/account/satisfaction';
import segments from 'reducers/account/segments';
import segmentMemberships from 'reducers/account/segmentMemberships';
import stats from 'reducers/account/stats';
import themes from 'reducers/account/themes';
import translations from 'reducers/account/translations';
import userQualifiedContent from 'entities/user-qualified-content/reducer';
import userProfiles from 'reducers/account/userProfiles';
import users from 'reducers/account/users';
import versionedFlows from 'reducers/account/versionedFlows';
import conditionsEstimates from 'entities/conditions-estimates/reducer';
import trialStatus from 'entities/trial-status/reducer';

const combined = combineReducers({
  analytics,
  analyticsV2,
  charts,
  checklists,
  checklistStatus,
  conditions,
  customEvents,
  experiences,
  eventNames,
  eventLabels,
  features,
  flows,
  flowStatus,
  flowVersions,
  gates,
  goals,
  groupAttributes,
  images,
  installedDomains,
  integrations,
  keys,
  locales,
  meta,
  profileAttributes,
  profileAttributeLabels,
  publishedRules,
  requests,
  rules,
  satisfaction,
  segments,
  segmentMemberships,
  stats,
  themes,
  translations,
  userProfiles,
  users,
  versionedFlows,
  userSegments,
  userQualifiedContent,
  conditionsEstimates,
  trialStatus,
});

export default function account(state, action) {
  const shouldClear = action.type === CLEAR_ACCOUNT_DATA;
  return combined(shouldClear ? undefined : state, action);
}
