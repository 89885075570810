import React from 'react';
import styled from 'styled-components';

import StepPreviewFooter from 'components/Common/StepThumbnail/StepPreviewFooter';
import StepPreviewContainer from 'components/Common/StepThumbnail/StepPreviewContainer';
import StepPreviewHeader from 'components/Common/StepThumbnail/StepPreviewHeader';
import StepContainer from 'components/Common/StepThumbnail/StepContainer';

const GenericStepPreview = ({ stepTypeId, children, className }) => {
  return (
    <StepPreviewContainer className={className}>
      <StepPreviewHeader stepTypeId={stepTypeId} childIndex={0} />
      <StyledStepContainer>{children}</StyledStepContainer>
      <StepPreviewFooter stepTypeId={stepTypeId} childIndex={0} />
    </StepPreviewContainer>
  );
};

const StyledStepContainer = styled(StepContainer)`
  background-color: #ffffff;
  border-radius: 4px;
  margin: 1px 12px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);

  i {
    color: rgba(113, 109, 161, 0.54);
    padding-top: 25px;
  }
`;

export default GenericStepPreview;
