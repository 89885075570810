import styled from 'styled-components';
import { ButtonLink } from '@studio/legacy-components';

export const UpdateButton = styled(ButtonLink)`
  margin-left: 8px;

  &[disabled] {
    color: var(--dirty-water);
    pointer-events: none;
  }
`;
