import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { replacePagePatternFor } from 'next/entities/page';
import {
  PIN_PUBLISHED,
  PIN_REVERTED,
  PIN_UNPUBLISHED,
  patterns as pinPatterns,
} from 'next/entities/pins';
import toast from 'next/lib/toast';
import { FLOW_REVERTED, patterns as flowPatterns } from 'next/entities/flows';
import { patterns as experiencePatterns } from 'next/entities/experiences';

import { patterns, reject, resolve, replace, flush } from './actions';

/**
 * Normalize rules summary response as collection
 *
 * @param {Response} response - Raw rules summary response
 * @return {Collection} Rules collection
 */
const transform = response =>
  response.reduce((acc, rule) => {
    acc[rule.id] = rule;
    return acc;
  }, {});

function* fetchRules() {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getRules);
    yield put(resolve(transform(response)));
  } catch (error) {
    yield put(reject(error));
  }
}

function* fetchRule({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    const rule = yield call(api.getRule, id);
    yield put(replace(rule));
  } catch (error) {
    yield put(reject(error));
  }
}

function* updateRule({ payload: { id, delta } }) {
  try {
    const api = yield getContext('api');
    yield call(api.updateRule, id, delta);
    yield put(flush(id));
  } catch (error) {
    yield put(reject(error));
  }
}

function* showToast() {
  yield call(toast.success, 'Changes saved');
}

export default function* saga() {
  // Internal actions
  yield takeEvery(patterns.read, fetchRules);
  yield takeEvery(patterns.readOne, fetchRule);
  yield takeEvery(patterns.update, updateRule);
  yield takeEvery(patterns.flush, showToast);

  // External actions
  yield takeEvery(
    [
      PIN_PUBLISHED,
      PIN_UNPUBLISHED,
      PIN_REVERTED,
      FLOW_REVERTED,
      flowPatterns.insert,
      pinPatterns.insert,
      experiencePatterns.insert,
    ],
    fetchRule
  );

  // Page actions
  yield takeEvery(
    [
      replacePagePatternFor('/flows'),
      replacePagePatternFor('/pins'),
      // @todo why do these pages need to read the rules?
      replacePagePatternFor('/mobile/flows/:experienceId/settings'),
      replacePagePatternFor('/mobile/flows/:experienceId/analytics'),
      replacePagePatternFor('/banners'),
    ],
    fetchRules
  );
  yield takeEvery(
    [
      replacePagePatternFor('/pins/:pinId/settings'),
      replacePagePatternFor('/banners/:experienceId/settings'),
      replacePagePatternFor('/launchpads/:experienceId/settings'),
      replacePagePatternFor('/mobile/flows/:experienceId/settings'),
      replacePagePatternFor('/flows/:flowId/settings'),
    ],
    fetchRule
  );
}
