import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { addClause, addGroupClause, deleteClause } from '@appcues/libcues';

import { CPanel, H3 } from '@appcues/component-library';
import GroupClause from 'components/Common/Clauses/Group';
import Input from 'components/Common/Forms/Input';
import * as clauseTransforms from 'transforms/clauses';

const CreateGoalPanel = props => {
  const { disabled, name, clauses, onNameChange, onClausesChange, readOnly } =
    props;

  return (
    <StyledCPanel>
      {!readOnly && (
        <StyledBlock>
          <H3>Name</H3>
          <Input
            type="text"
            placeholder="Enter a name for your goal..."
            value={name}
            onChange={onNameChange}
          />
        </StyledBlock>
      )}
      <H3>Definition</H3>
      <p>
        The user meets the goal when the following criteria is true for them:
      </p>
      <GroupClause
        disabled={disabled}
        readOnly={readOnly}
        clauses={clauses}
        isRootNode
        skipSegments
        addClause={(parentId, clause) =>
          onClausesChange(addClause(clauses, parentId, clause))
        }
        addGroupClause={(parentId, booleanOperator, childClauses) =>
          onClausesChange(
            addGroupClause(clauses, parentId, booleanOperator, childClauses)
          )
        }
        updateClause={(clauseId, changes) =>
          onClausesChange(
            clauseTransforms.updateClause(clauses, clauseId, changes)
          )
        }
        replaceClause={(clauseId, replacementClause) =>
          onClausesChange(
            clauseTransforms.replaceClause(clauses, clauseId, replacementClause)
          )
        }
        dissolveGroupClause={clauseId =>
          onClausesChange(
            clauseTransforms.dissolveGroupClause(clauses, clauseId)
          )
        }
        deleteClause={clauseId =>
          onClausesChange(deleteClause(clauses, clauseId))
        }
        shouldLimitHeight
      />
    </StyledCPanel>
  );
};

const StyledBlock = styled.div`
  margin-bottom: 1rem;
`;

CreateGoalPanel.propTypes = {
  name: PropTypes.string.isRequired,
  clauses: PropTypes.array.isRequired,
  allGoals: PropTypes.object.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onClausesChange: PropTypes.func.isRequired,
  stepId: PropTypes.string,
  disabled: PropTypes.bool,
};

CreateGoalPanel.defaultProps = {
  disabled: false,
};

const StyledCPanel = styled(CPanel)`
  input[type='text'] {
    width: 50%;
  }

  p {
    font-size: ${props => props.theme['$font-size-base']};
    color: ${props => props.theme['$gray-50']};
  }

  .condition-set {
    margin: 10px 0 0 0;
  }

  hr {
    height: 0;
    border: none;
    border-bottom: 2px solid ${props => props.theme['$gray-1']};
    margin: 2rem 0;
    width: 100%;
  }
`;

export default CreateGoalPanel;
