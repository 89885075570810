import React from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import CreateFlowModal from 'components/ModalContainer/CreateFlowModal';
import CreateProductLedFlowModal from 'components/ModalContainer/CreateProductLedFlowModal';
import CreateSegmentModal from 'components/ModalContainer/CreateSegmentModal';
import DefineGoalModal from 'components/ModalContainer/DefineGoalModal';
import UpdateSegmentModal from 'components/ModalContainer/UpdateSegmentModal';
import DeleteSegmentModal from 'components/ModalContainer/DeleteSegmentModal';
import FlowDependencyModal from 'components/ModalContainer/FlowDependencyModal';
import PublishingModal from 'components/ModalContainer/PublishingModal';
import PermalinkModal from 'components/ModalContainer/PermalinkModal';
import GoalLimitModal from 'components/ModalContainer/GoalLimitModal';
import DeleteGoalModal from 'components/ModalContainer/DeleteGoalModal';
import DeleteChecklistModal from 'components/ModalContainer/DeleteChecklistModal';
import EditChecklistItemModal from 'components/ModalContainer/EditChecklistItemModal';
import UserProfileModal from 'components/ModalContainer/UserProfileModal';
import NPSTrialExpirationModal from 'components/ModalContainer/NPSTrialExpirationModal';
import NPSFlowUpsellModal from 'components/ModalContainer/NPSFlowUpsellModal';
import ExportModal from 'components/ModalContainer/ExportModal';
import FlowPublishingModal from 'components/ModalContainer/FlowPublishingModal';
import ExportNPSResultsToCSVModal from 'components/ModalContainer/ExportNPSResultsToCSVModal';
import TestFlowModal from 'components/ModalContainer/TestFlowModal';
import InstallSegmentModal from 'components/ModalContainer/InstallSegmentModal';
import ConfirmRemoveIntegrationModal from 'components/ModalContainer/ConfirmRemoveIntegrationModal';
import ConfirmActivateIntegrationModal from 'components/ModalContainer/ConfirmActivateIntegrationModal';
import FirstCancellationModal from 'components/ModalContainer/SubscriptionCancellation/FirstCancellationModal';
import SecondCancellationModal from 'components/ModalContainer/SubscriptionCancellation/SecondCancellationModal';
import ThirdCancellationModal from 'components/ModalContainer/SubscriptionCancellation/ThirdCancellationModal';
import InstallToIntegrateModal from 'components/ModalContainer/InstallToIntegrateModal';
import SegmentBlockedModal from 'components/ModalContainer/SegmentBlockedModal';
import DeleteThemeModal from 'components/ModalContainer/DeleteThemeModal';
import ZapierIntegrationModal from 'components/ModalContainer/ZapierIntegrationModal';
import SalesforceBetaIntegrationModal from 'components/ModalContainer/IntegrationConfigurationModal/SalesforceBetaIntegrationModal';
import IntegrationModal from 'components/ModalContainer/IntegrationConfigurationModal/IntegrationModal';

import {
  CREATE_FLOW_MODAL,
  CREATE_PRODUCT_LED_FLOW_MODAL,
  CREATE_SEGMENT_MODAL,
  UPDATE_SEGMENT_MODAL,
  DELETE_SEGMENT_MODAL,
  DEFINE_GOAL_MODAL,
  PUBLISHING_MODAL,
  GOAL_LIMIT_MODAL,
  DELETE_GOAL_MODAL,
  USER_PROFILE_MODAL,
  NPS_TRIAL_EXPIRATION_MODAL,
  NPS_FLOW_UPSELL_MODAL,
  EXPORT_MODAL,
  FLOW_PUBLISHING_MODAL,
  DELETE_CHECKLIST_MODAL,
  EDIT_CHECKLIST_ITEM_MODAL,
  EXPORT_NPS_SURVEY_CSV_MODAL,
  TEST_FLOW_MODAL,
  PERMALINK_MODAL,
  INSTALL_SEGMENT_MODAL,
  CONFIRM_REMOVE_INTEGRATION_MODAL,
  CONFIRM_ACTIVATE_INTEGRATION_MODAL,
  FIRST_CANCELLATION_MODAL,
  SECOND_CANCELLATION_MODAL,
  THIRD_CANCELLATION_MODAL,
  INSTALL_TO_INTEGRATE_MODAL,
  SEGMENT_BLOCKED_MODAL,
  ZAPIER_INTEGRATION_MODAL,
  DELETE_THEME_MODAL,
  SALESFORCE_BETA_INTEGRATION_MODAL,
  HUBSPOT_TWO_WAY_MODAL,
  ZENDESK_SUPPORT_TWO_WAY_MODAL,
  SLACK_TWO_WAY_MODAL,
  TRAY_INTEGRATION_MODAL,
  FLOW_DEPENDENCY_MODAL,
} from 'constants/globals';
import { getCurrentContent } from 'helpers/modal-content';

import * as currentModalActions from 'actions/currentModal';
import * as accountManagementActions from 'actions/accountManagement';
import { trackEvent } from 'actions/events';

import { getTestFlowUrl } from 'helpers/content-api';
import { buildShareablePreviewLink } from 'utils/index';

import { selectAccountId, selectAccountUuid } from 'reducers/account/meta';
import { selectStepGroups } from 'reducers/account/flows';
import { selectUser } from 'reducers/user';

const buildModal = (name, props) => {
  const { actions, user, currentModal } = props;
  const uuid = selectAccountUuid(props);
  const accountId = selectAccountId(props);
  const userMeta = selectUser(props);
  const stepId =
    currentModal && currentModal.props && currentModal.props.stepId;
  const currentContent = stepId && getCurrentContent(props, stepId);
  const previewUrl = (currentContent && currentContent.previewUrl) || '';
  const checklistId =
    currentModal && currentModal.props && currentModal.props.checklistId;
  const currentChecklist = checklistId && getCurrentContent(props, checklistId);
  const stepGroups = selectStepGroups(props);

  switch (name) {
    case CREATE_FLOW_MODAL:
      return <CreateFlowModal {...props} {...props.currentModal.props} />;

    case CREATE_PRODUCT_LED_FLOW_MODAL:
      return (
        <CreateProductLedFlowModal {...props} {...props.currentModal.props} />
      );

    case CREATE_SEGMENT_MODAL:
      return <CreateSegmentModal {...props} {...props.currentModal.props} />;

    case DEFINE_GOAL_MODAL:
      return <DefineGoalModal {...props} {...props.currentModal.props} />;

    case UPDATE_SEGMENT_MODAL:
      return <UpdateSegmentModal {...props} {...props.currentModal.props} />;

    case DELETE_SEGMENT_MODAL:
      return <DeleteSegmentModal {...props} {...props.currentModal.props} />;

    case PUBLISHING_MODAL:
      return (
        <PublishingModal
          accountId={accountId}
          accountApiKey={uuid}
          actions={actions}
          currentContent={currentContent}
          userId={user.user.id}
          {...props.currentModal.props}
        />
      );

    case PERMALINK_MODAL:
      return (
        <PermalinkModal
          actions={actions}
          buildShareablePreviewLink={buildShareablePreviewLink}
          currentContent={currentContent}
          previewUrl={previewUrl}
          {...props.currentModal.props}
        />
      );

    case GOAL_LIMIT_MODAL:
      return <GoalLimitModal actions={actions} {...props.currentModal.props} />;

    case FLOW_DEPENDENCY_MODAL:
      return (
        <FlowDependencyModal actions={actions} {...props.currentModal.props} />
      );

    case DELETE_GOAL_MODAL:
      return (
        <DeleteGoalModal actions={actions} {...props.currentModal.props} />
      );

    case DELETE_CHECKLIST_MODAL:
      return <DeleteChecklistModal {...props} {...props.currentModal.props} />;

    case EDIT_CHECKLIST_ITEM_MODAL:
      return (
        <EditChecklistItemModal
          currentChecklist={currentChecklist}
          stepGroups={stepGroups}
          {...props}
          {...props.currentModal.props}
        />
      );

    case USER_PROFILE_MODAL:
      return (
        <UserProfileModal actions={actions} {...props.currentModal.props} />
      );

    case NPS_TRIAL_EXPIRATION_MODAL:
      return (
        <NPSTrialExpirationModal
          actions={actions}
          {...props.currentModal.props}
        />
      );
    case NPS_FLOW_UPSELL_MODAL:
      return (
        <NPSFlowUpsellModal actions={actions} {...props.currentModal.props} />
      );
    case EXPORT_MODAL:
      return (
        <ExportModal
          accountId={accountId}
          actions={actions}
          email={userMeta.email}
          large
          uuid={uuid}
          {...props.currentModal.props}
        />
      );

    case FLOW_PUBLISHING_MODAL:
      return (
        <FlowPublishingModal
          accountId={accountId}
          accountApiKey={uuid}
          actions={actions}
          currentContent={currentContent}
          userId={user.user.id}
          {...props.currentModal.props}
        />
      );

    case EXPORT_NPS_SURVEY_CSV_MODAL:
      return (
        <ExportNPSResultsToCSVModal
          accountId={accountId}
          accountApiKey={uuid}
          email={userMeta.email}
          {...props}
          {...props.currentModal.props}
        />
      );

    case FIRST_CANCELLATION_MODAL:
      return (
        <FirstCancellationModal
          {...props}
          {...props.currentModal.props}
          actions={actions}
        />
      );

    case SECOND_CANCELLATION_MODAL:
      return (
        <SecondCancellationModal
          {...props}
          {...props.currentModal.props}
          actions={actions}
        />
      );

    case THIRD_CANCELLATION_MODAL:
      return (
        <ThirdCancellationModal
          {...props}
          {...props.currentModal.props}
          actions={actions}
        />
      );

    case INSTALL_SEGMENT_MODAL:
      return (
        <InstallSegmentModal
          {...props}
          {...props.currentModal.props}
          actions={actions}
          accountId={accountId}
        />
      );

    case TEST_FLOW_MODAL:
      return (
        <TestFlowModal
          {...props}
          {...props.currentModal.props}
          accountApiKey={uuid}
          accountId={accountId}
          actions={actions}
          getTestFlowUrl={getTestFlowUrl}
        />
      );

    case SEGMENT_BLOCKED_MODAL:
      return (
        <SegmentBlockedModal
          {...props}
          {...props.currentModal.props}
          actions={actions}
        />
      );

    case CONFIRM_REMOVE_INTEGRATION_MODAL:
      return (
        <ConfirmRemoveIntegrationModal
          {...props}
          {...props.currentModal.props}
          actions={actions}
        />
      );

    case CONFIRM_ACTIVATE_INTEGRATION_MODAL:
      return (
        <ConfirmActivateIntegrationModal
          {...props}
          {...props.currentModal.props}
          actions={actions}
        />
      );

    case INSTALL_TO_INTEGRATE_MODAL:
      return (
        <InstallToIntegrateModal
          {...props}
          {...props.currentModal.props}
          actions={actions}
        />
      );

    case DELETE_THEME_MODAL:
      return (
        <DeleteThemeModal {...props.currentModal.props} actions={actions} />
      );

    case ZAPIER_INTEGRATION_MODAL:
      return (
        <ZapierIntegrationModal
          {...props}
          {...props.currentModal.props}
          actions={actions}
        />
      );

    case SALESFORCE_BETA_INTEGRATION_MODAL:
      return (
        <SalesforceBetaIntegrationModal
          {...props}
          {...props.currentModal.props}
          actions={actions}
        />
      );

    case HUBSPOT_TWO_WAY_MODAL:
      return (
        <IntegrationModal
          {...props}
          {...props.currentModal.props}
          actions={actions}
        />
      );

    case ZENDESK_SUPPORT_TWO_WAY_MODAL:
      return (
        <IntegrationModal
          {...props}
          {...props.currentModal.props}
          actions={actions}
        />
      );

    case SLACK_TWO_WAY_MODAL:
    case TRAY_INTEGRATION_MODAL:
      return (
        <IntegrationModal
          {...props}
          {...props.currentModal.props}
          actions={actions}
        />
      );

    default:
      return null;
  }
};

const ModalContainer = props => {
  const { currentModal } = props;
  return (
    <div className="apc-modal-container">
      {buildModal((currentModal || {}).key, props)}
    </div>
  );
};

export default connect(
  state => state,
  dispatch => {
    return {
      actions: bindActionCreators(
        {
          trackEvent,
          ...currentModalActions,
          ...accountManagementActions,
        },
        dispatch
      ),
    };
  }
)(ModalContainer);
