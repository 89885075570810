/* global APPCUES_ENV */

import { domain } from 'utils/opscues';

export default auth => {
  const baseImageServiceUrl = domain({
    app: 'image-service',
    env: APPCUES_ENV,
    facet: 'main',
  });

  const baseImageServiceCacheUrl = `screenshots${
    APPCUES_ENV !== 'prod' ? `-${APPCUES_ENV}` : ''
  }.cdn.appcues.com`;

  /**
   * Prepares a request to the image service for a given endpoint method
   *
   * @param method
   * @param flowId
   * @param stepGroupId
   * @param stepChildId
   * @param versionId
   * @param contentType
   * @param size
   * @param localeId
   * @returns {Promise<string>}
   */

  const fetchFromImageService = ({
    method,
    flowId,
    stepGroupId,
    stepChildId,
    versionId,
    contentType,
    size,
    localeId,
  }) =>
    Promise.all([auth.getToken(), auth.getAccountId()])
      .then(([token, accountId]) => {
        const baseRoute = `accounts/${accountId}/versions/${versionId}/flows/${flowId}/steps/${stepGroupId}/children/${stepChildId}`;
        const baseQuery = `contentType=${contentType}&size=${size}${
          localeId ? `&locale=${localeId}` : ''
        }`;

        return fetch(
          `https://${baseImageServiceUrl}/step-image/${baseRoute}/${method}?${baseQuery}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      })
      .then(response => {
        if (response.ok) {
          return response;
        }
        throw new Error(response.statusText);
      });

  return {
    /**
     * Purge a stepChild image from CDN & image-service cache, to be used
     * when forceRegnerate is requested upstream
     *
     * @param flowId
     * @param stepGroupId
     * @param stepChildId
     * @param versionId
     * @param contentType
     * @param size
     * @param localeId
     * @returns {Promise<string>}
     */

    purge: ({
      flowId,
      stepGroupId,
      stepChildId,
      versionId,
      contentType,
      size,
      localeId,
    }) =>
      fetchFromImageService({
        method: 'purge',
        flowId,
        stepGroupId,
        stepChildId,
        versionId,
        contentType,
        size,
        localeId,
      }).then(response => response.json()),

    /**
     * Requests a pre-signed CDN path for a given stepChild image
     *
     * @param flowId
     * @param stepGroupId
     * @param stepChildId
     * @param versionId
     * @param contentType
     * @param size
     * @param localeId
     * @returns {Promise<string>}
     */

    presign: ({
      flowId,
      stepGroupId,
      stepChildId,
      versionId,
      contentType,
      size,
      localeId,
    }) =>
      fetchFromImageService({
        method: 'presign',
        flowId,
        stepGroupId,
        stepChildId,
        versionId,
        contentType,
        size,
        localeId,
      }).then(response => response.text()),

    /**
     * Requests an image directly from the image-service, bypassing
     * the CDN entirely
     *
     * @param flowId
     * @param stepGroupId
     * @param stepChildId
     * @param versionId
     * @param contentType
     * @param size
     * @param localeId
     * @returns {Promise<string>}
     */

    get: ({
      flowId,
      stepGroupId,
      stepChildId,
      versionId,
      contentType,
      size,
      localeId,
    }) =>
      fetchFromImageService({
        method: '',
        flowId,
        stepGroupId,
        stepChildId,
        versionId,
        contentType,
        size,
        localeId,
      }).then(response => response.blob()),

    /**
     * Requests an image from the screenshots CDN for a given
     * pre-signed path
     *
     * @param path
     * @returns {*}
     */

    getPresigned: ({ path }) =>
      auth
        .getToken()
        .then(token =>
          fetch(`https://${baseImageServiceCacheUrl}${path}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        )
        .then(response => {
          if (response.ok) {
            return response.blob();
          }
          throw new Error(response.statusText);
        }),
  };
};
