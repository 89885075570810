import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Button, CPanel, H2, Switch } from '@appcues/component-library';
import FlipMove from 'react-flip-move';
import ChecklistItem from 'components/ChecklistsEdit/ChecklistItem';
import WarningBanner from 'components/ChecklistsEdit/WarningBanner';
import { selectFlows } from 'reducers/account/flows';
import {
  selectAccountChecklist,
  update,
  updateItem,
  removeItem,
  swapItems,
  addItem,
} from 'entities/checklists';
import { showModal } from 'actions/currentModal';

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

const ToggleSequentialContainer = styled.div`
  display: flex;
  align-items: center;
  .toggle-sequential-label {
    margin-right: 5px;
  }
`;

export function ChecklistItemsPanel({
  checklist,
  steps,
  onChange,
  onItemChange,
  onItemAdded,
  onItemRemoved,
  onItemReordered,
  onShowModal,
}) {
  const toggleSequential = () => {
    onChange(checklist.id, {
      isSequential: !checklist.isSequential,
    });
  };

  const handleAddItemToChecklist = () => {
    onItemAdded(checklist.id);
  };

  const lessThanFive =
    !checklist.items || Object.keys(checklist.items).length < 5;
  const moreThanTen =
    checklist && checklist.items && Object.keys(checklist.items).length >= 10;

  const isSequential = !!checklist.isSequential;
  const toggledClass = isSequential ? 'toggled' : '';
  const hasMultipleItems =
    checklist && checklist.items && Object.keys(checklist.items).length > 1;

  return (
    <Wrapper>
      <CPanel className="items-panel" showLine={false}>
        <CPanel.Header>
          <H2>Items</H2>
          <CPanel.Header.Actions>
            <ToggleSequentialContainer className="toggle-sequential-container">
              <span className={`toggle-sequential-label ${toggledClass}`}>
                Must Complete in Order
              </span>
              <Switch
                checked={isSequential}
                onChange={toggleSequential}
                name="toggle-sequential"
                disabled={!hasMultipleItems}
              />
            </ToggleSequentialContainer>
          </CPanel.Header.Actions>
        </CPanel.Header>

        <FlipMove appearAnimation="fade" easing="linear">
          {Object.values(checklist.items || {})
            .sort((item1, item2) => item1.index - item2.index)
            .map(checklistItem => {
              return (
                <ChecklistItem
                  key={`${checklistItem.id}-${checklistItem.index}`}
                  checklistId={checklist.id}
                  checklistItem={checklistItem}
                  checklistItems={checklist.items}
                  removeItemFromChecklist={onItemRemoved}
                  updateChecklistItem={onItemChange}
                  steps={steps}
                  reorderChecklistItems={onItemReordered}
                  showModal={onShowModal}
                  isSequential={isSequential}
                />
              );
            })}
        </FlipMove>
        <Button
          className="button-success add-item-button"
          onClick={handleAddItemToChecklist}
          disabled={moreThanTen}
        >
          Add Checklist Item
        </Button>
      </CPanel>
      <WarningBanner minItems={lessThanFive} maxItems={moreThanTen} />
    </Wrapper>
  );
}

const mapStateToProps = (state, { checklistId }) => {
  return {
    checklist: selectAccountChecklist(state, checklistId),
    steps: selectFlows(state),
  };
};

const mapDispatchToProps = {
  onChange: update,
  onItemChange: updateItem,
  onItemAdded: addItem,
  onItemRemoved: removeItem,
  onItemReordered: swapItems,
  onShowModal: showModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChecklistItemsPanel);
