import styled from 'styled-components';
import { Card, Label, NegativeNotice, Text } from '@studio/legacy-components';
import { Button } from '@appcues/sonar';
import Logo from './Logo';

export const Wrapper = styled.div``;

export const SignupHeader = styled.h3`
  text-align: center;
  font-size: 20px;
  font-weight: 600;
`;

export const CardNegativeNotice = styled(NegativeNotice)``;

export const CompleteSignupButton = styled(Button)``;

export const SignupCard = styled(Card)`
  margin: 0 auto;
  margin-top: 32px;
  margin-bottom: 40px;
  padding: 32px 16px;
  width: 448px;
  box-shadow: 0px 12px 34px rgba(71, 88, 114, 0.2);

  ${Logo} {
    margin: 0px auto 48px;
  }

  ${CardNegativeNotice} {
    margin-bottom: 12px;
    margin-top: 6px;
  }

  ${SignupHeader} {
    margin-bottom: 48px;
  }

  ${CompleteSignupButton} {
    margin-top: 32px;
  }
`;
export const LinkSection = styled.aside`
  text-align: center;
`;

export const SignupContainer = styled.div`
  padding-top: 10%;

  ${LinkSection} {
    padding-bottom: 11px;
  }
`;

export const FooterText = styled(Text)`
  color: var(--dark-resting);
`;

export const SignupLabel = styled(Label)`
  font-weight: 700;
`;

export const SignupForm = styled.form``;
