import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const AppIcon = ({ className }) => (
  <svg
    className={className}
    width="103"
    height="108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50.5 105.394c15.284 0 27.675-1.033 27.675-2.306 0-1.274-12.39-2.307-27.675-2.307s-27.675 1.033-27.675 2.307c0 1.273 12.39 2.306 27.675 2.306Z"
      fill="#2CB4FF"
      fillOpacity=".2"
    />
    <path
      d="M72.197 86.893c.024 3.272-2.551 5.894-5.822 5.917l-29.321.142a5.835 5.835 0 0 1-5.917-5.824l-.32-65.954c-.024-3.271 2.55-5.894 5.82-5.917l29.322-.142a5.835 5.835 0 0 1 5.917 5.824l.321 65.954Z"
      fill="#fff"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m30.935 26.91.267 54.303 40.982-.191-.267-54.303-40.982.19Z"
      fill="#F4FBFF"
    />
    <path d="m31.092 75.211 39.222-48.478-39.425.223.203 48.255Z" fill="#fff" />
    <path
      d="m30.935 26.91.267 54.303 40.982-.191-.267-54.303-40.982.19ZM57.273 20.979l1.459-.063M43.493 21.158l9.52-.056"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.725 86.5a1.17 1.17 0 0 1-.458 1.596 1.17 1.17 0 0 1-1.597-.457 1.17 1.17 0 0 1 .458-1.597 1.17 1.17 0 0 1 1.597.457Z"
      fill="#2CB4FF"
      fillOpacity=".2"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="36.662"
      y="40.818"
      width="29.212"
      height="29.212"
      rx="2"
      fill="#fff"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
    />
    <path
      d="M36.663 42.818a2 2 0 0 1 2-2h25.212a2 2 0 0 1 2 2v7.408H36.663v-7.408Z"
      fill="url(#a)"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
    />
    <path
      d="M43.966 57.615H58.8M41.357 61.578h20.342"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="a"
        x1="36.663"
        y1="45.522"
        x2="65.875"
        y2="45.522"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5290" />
        <stop offset="0" stopColor="#FF5290" />
        <stop offset="1" stopColor="#8960FF" />
      </linearGradient>
    </defs>
  </svg>
);

AppIcon.propTypes = {
  className: PropTypes.string,
};

export default styled(AppIcon)``;
