import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';
import Tag, { TagTypes } from 'components/Common/Tag';
import Input from 'components/Common/Forms/Input';
import { pressedEnter } from 'utils/forms';

function InputTag({
  className,
  displayProperty,
  onUpdateInputTagList,
  placeholder,
  tagList = [],
}) {
  const [inputValue, setInputValue] = useState('');

  const handleOnChangeInput = url => {
    setInputValue(url);
  };

  const handleBlurInput = () => {
    if (inputValue.trim()) {
      const updatedObject = displayProperty
        ? { [displayProperty]: inputValue }
        : inputValue;

      const updatedUrlList = [...tagList, updatedObject];
      onUpdateInputTagList(updatedUrlList);
      setInputValue('');
    }
  };

  const handleKeyPressInput = e => {
    if (pressedEnter(e)) {
      handleBlurInput();
    }
  };

  const handleDeleteTag = e => {
    const tagIndex = Number.parseInt(e.currentTarget.dataset.tagIndex, 10);
    const updatedTagList = tagList.filter((tag, i) =>
      i !== tagIndex ? tag : false
    );
    onUpdateInputTagList(updatedTagList);
  };

  return (
    <div className={`input-tag-container ${className}`}>
      {tagList.length > 0
        ? tagList.map((tag, i) => {
            const displayTag = displayProperty ? tag[displayProperty] : tag;
            return (
              <Tag
                key={displayTag}
                className="input-tag"
                type={TagTypes.BLUE_1}
              >
                <span>{displayTag}</span>

                <Icon
                  role="button"
                  tabIndex={0}
                  aria-label="Delete tag"
                  data-tag-index={i}
                  onClick={handleDeleteTag}
                  icon="times"
                />
              </Tag>
            );
          })
        : null}
      <Input
        type="text"
        value={inputValue}
        placeholder={placeholder || 'Press Enter to add your text'}
        onChange={e => handleOnChangeInput(e.target.value)}
        onBlur={handleBlurInput}
        onKeyPress={handleKeyPressInput}
      />
    </div>
  );
}

export default styled(InputTag)`
  &.input-tag-container {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    align-items: center;
    padding: 0px;
    border: 2px solid #e3e3e3;
    border-radius: 4px;
    background-color: white;
    min-height: 20px;
    font-size: 1em;

    input[type='text'] {
      flex-grow: 1;
      border: transparent;

      :focus {
        border: transparent;
        outline: none;
      }
    }
    .input-tag {
      margin: 2px;
      border-radius: 10px;
      font-size: 1em;

      span {
        margin-right: 4px;
        background-color: transparent;
      }
      i {
        cursor: pointer;
      }
    }
  }
`;
