import { call, getContext, select, takeEvery } from 'redux-saga/effects';
import toast from 'next/lib/toast';
import { selectAccountUuid } from 'reducers/account/meta';
import { reportError } from 'helpers/error-reporting';
import { NPS_EXPORT_REQUESTED } from './actions';

function* requestCsvExport({ payload: { email, startDate, endDate, useV2 } }) {
  try {
    // NOTE: Ideally we would be able to obtain this value from an api or
    //       context and not selected from state
    const accountApiKey = yield select(selectAccountUuid);

    const auth = yield getContext('auth');
    const accountId = yield call(auth.getAccountId);

    const csv = yield getContext('csv');
    yield call(csv.requestNpsCsvExport, {
      accountId,
      accountApiKey,
      email,
      ...(endDate && { endDate }),
      ...(startDate && { startDate }),
      useV2,
    });
    yield call(toast.success, 'Request sent!');
  } catch (error) {
    yield call(toast.error, 'Request failed!');
    yield call(reportError, error);
  }
}

export default function* saga() {
  yield takeEvery(NPS_EXPORT_REQUESTED, requestCsvExport);
}
