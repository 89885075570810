import React from 'react';

import styled from 'styled-components';
import { CPage, H1 } from '@appcues/component-library';
import useTitle from 'next/hooks/use-title';
import betaFlag from 'assets/images/beta-flag.svg';

import Report from 'components/Reports/Report';
import FlowPerformanceReport from 'components/Reports/FlowPerformanceReport';
import FlowIssueReport from 'components/Reports/FlowIssueReport';

const Reports = () => {
  useTitle('Reports | Appcues');
  return (
    <CPage>
      <TitleBar>
        <H1>Reports</H1>
        <img alt="beta flag" src={betaFlag} />
      </TitleBar>
      <CPage.Container>
        <Report title="Flow Performance">
          <FlowPerformanceReport />
        </Report>
        <Report title="Flow Issues">
          <FlowIssueReport />
        </Report>
      </CPage.Container>
    </CPage>
  );
};

const TitleBar = styled(CPage.TitleBar)`
  display: flex;
  align-items: center;

  img {
    width: 35px;
  }
`;

export default Reports;
