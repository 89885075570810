import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon, Link, ListRow } from '@studio/legacy-components';
import { archive, clone, remove, unarchive } from 'next/entities/pins';
import { ActionsMenu } from 'next/components/Listing';

export function Actions({
  archived,
  onArchive,
  onClone,
  onDelete,
  onOpenBuilder,
  onOpenEditTags,
  onUnarchive,
  pinId,
}) {
  if (archived) {
    return (
      <ActionsMenu>
        <ListRow prefix={<Icon icon="upload" />} onClick={onUnarchive}>
          Restore
        </ListRow>
        <ListRow prefix={<Icon icon="trash-alt" />} onClick={onDelete}>
          Delete
        </ListRow>
      </ActionsMenu>
    );
  }

  return (
    <ActionsMenu>
      <ListRow prefix={<Icon icon="edit" />} onClick={onOpenBuilder}>
        Open in Builder
      </ListRow>
      <ListRow
        forwardedAs={Link}
        prefix={<Icon icon="chart-bar" />}
        to={`/pins/${pinId}/analytics`}
      >
        Analytics
      </ListRow>
      <ListRow
        forwardedAs={Link}
        prefix={<Icon icon="cog" />}
        to={`/pins/${pinId}/settings`}
      >
        Settings
      </ListRow>
      <ListRow prefix={<Icon icon="tag" />} onClick={onOpenEditTags}>
        Edit tags
      </ListRow>
      <ListRow prefix={<Icon icon="copy" />} onClick={onClone}>
        Clone
      </ListRow>
      <ListRow prefix={<Icon icon="archive" />} onClick={onArchive}>
        Archive
      </ListRow>
    </ActionsMenu>
  );
}

Actions.propTypes = {
  archived: PropTypes.bool,
  onArchive: PropTypes.func,
  onClone: PropTypes.func,
  onDelete: PropTypes.func,
  onOpenBuilder: PropTypes.func,
  onUnarchive: PropTypes.func,
  pinId: PropTypes.string,
  onOpenEditTags: PropTypes.func,
};

const mapDispatchToProps = (dispatch, { pinId }) => ({
  onArchive: () => dispatch(archive(pinId)),
  onClone: () => dispatch(clone(pinId)),
  onDelete: () => dispatch(remove(pinId)),
  onUnarchive: () => dispatch(unarchive(pinId)),
});

export default connect(null, mapDispatchToProps)(Actions);
