import {
  all,
  debounce,
  getContext,
  put,
  select,
  call,
  takeEvery,
} from 'redux-saga/effects';
import { replaceAccountSatisfaction } from 'actions/satisfaction';
import { replaceRule } from 'sagas/account/rule-conditions';

import { selectAccountSatisfactionSurvey } from 'selectors/satisfaction';
import { FLUSH_SATISFACTION_TO_FIREBASE } from 'constants/actionTypes';
import {
  CONTENT_REVERTED,
  CONTENT_PUBLISHED,
  CONTENT_UNPUBLISHED,
  CONTENT_PUSHED,
} from 'actions/publishing';

import { reportError } from 'helpers/error-reporting';

const CONTENT_ACTIONS = new Set([
  CONTENT_REVERTED,
  CONTENT_PUBLISHED,
  CONTENT_UNPUBLISHED,
  CONTENT_PUSHED,
]);

export function* fetchAllNPS() {
  try {
    const api = yield getContext('api');
    const { data } = yield call(api.getAllNps);

    const { surveys, rules } = data.reduce(
      (acc, { nps, rule }) => {
        acc.surveys[nps.id] = nps;
        acc.rules[nps.id] = rule;
        return acc;
      },
      { surveys: {}, rules: {} }
    );

    yield put(replaceAccountSatisfaction(surveys));
    yield all(Object.values(rules || {}).map(rule => call(replaceRule, rule)));
  } catch (error) {
    yield call(reportError, error);
  }
}

export function* fetchNPS({ payload }) {
  try {
    const api = yield getContext('api');
    const {
      data: { nps, rule },
    } = yield call(api.getNps, payload.id);
    yield all([
      put(replaceAccountSatisfaction({ [nps.id]: nps })),
      call(replaceRule, rule),
    ]);
  } catch (error) {
    yield call(reportError, error);
  }
}

export function* flushNPS(action) {
  try {
    const { satisfactionId } = action.payload;

    const nps = yield select(selectAccountSatisfactionSurvey, satisfactionId);
    const api = yield getContext('api');
    if (nps) {
      yield call(api.updateNps, satisfactionId, nps);
    }
  } catch (error) {
    reportError(error);
  }
}

export default function* satisfaction() {
  yield debounce(500, FLUSH_SATISFACTION_TO_FIREBASE, flushNPS);
  yield takeEvery(
    action =>
      CONTENT_ACTIONS.has(action.type) &&
      action.payload.type === 'satisfaction-survey',
    fetchNPS
  );
}
