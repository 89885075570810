import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';

const RedirectWithQueryParams = ({ to, location }) => (
  <Redirect
    to={{
      ...location,
      pathname: to,
    }}
  />
);

export default withRouter(RedirectWithQueryParams);
