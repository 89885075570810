import React, { Component } from 'react';

import { Button, CColorPickerDropdown } from '@appcues/component-library';
import styled from 'styled-components';
import FieldGroup from 'components/Common/Forms/FieldGroup';
import Panel from 'components/Common/Panel';

import {
  SATISFACTION_CREATION_STEP_TITLES,
  DEFAULT_LIGHT_COLOR_HEX,
  LIGHT_THEME,
  DARK_THEME,
} from 'constants/satisfaction/data';
import { VIEWED_APP_PAGE_STEP } from 'constants/events';
import {
  isDarkTheme,
  isLightTheme,
  getPrimaryColor,
  buildSurveyBackgroundColor,
  getThemeFromBackgroundColor,
} from 'helpers/satisfaction/themes';

const buildSatisfactionColors = (theme, color) => {
  const hasSelectedLightTheme = isLightTheme(theme);

  return {
    foregroundColor: hasSelectedLightTheme ? color : DEFAULT_LIGHT_COLOR_HEX,
    backgroundColor: hasSelectedLightTheme ? DEFAULT_LIGHT_COLOR_HEX : color,
  };
};

class SatisfactionStyleEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTheme: getThemeFromBackgroundColor(props.backgroundColor),
      backgroundColor: props.backgroundColor,
      foregroundColor: props.foregroundColor,
      highlightedTheme: null,
    };
  }

  componentDidMount() {
    const { trackEvent } = this.props;
    trackEvent(VIEWED_APP_PAGE_STEP, {
      step: SATISFACTION_CREATION_STEP_TITLES.STYLE,
    });

    window.scrollTo(0, 0);
  }

  setPrimaryColor(colorHex) {
    const { satisfactionId, updateAndFlushSatisfaction } = this.props;
    const { selectedTheme } = this.state;

    const satisfactionColors = buildSatisfactionColors(selectedTheme, colorHex);

    this.setState({
      ...satisfactionColors,
    });

    updateAndFlushSatisfaction(satisfactionId, satisfactionColors);
  }

  removeHighlightedTheme = () => {
    this.setState({ highlightedTheme: null });
  };

  highlightTheme(theme) {
    return () => {
      this.setState({
        highlightedTheme: theme,
      });
    };
  }

  selectTheme(theme) {
    return () => {
      const {
        satisfactionId,
        backgroundColor,
        foregroundColor,
        updateAndFlushSatisfaction,
        toggleSurveyPreview,
      } = this.props;

      const primaryColor = getPrimaryColor(backgroundColor, foregroundColor);
      const satisfactionColors = buildSatisfactionColors(theme, primaryColor);

      this.setState({
        ...satisfactionColors,
        selectedTheme: theme,
      });

      updateAndFlushSatisfaction(satisfactionId, satisfactionColors);
      toggleSurveyPreview(false);
    };
  }

  render() {
    const { className } = this.props;
    const {
      selectedTheme,
      highlightedTheme,
      backgroundColor,
      foregroundColor,
    } = this.state;
    const primaryColor = getPrimaryColor(backgroundColor, foregroundColor);

    return (
      <Panel
        name="Choose Your NPS Color Theme"
        description="Our testing shows that higher visual contrast between the NPS survey and your app will lead to higher a response rate."
        className={`${className} satisfaction-form page-container`}
      >
        <FieldGroup
          className="satisfaction-style-section"
          title="Change your primary theme color →"
        >
          <CColorPickerDropdown
            colorState={primaryColor}
            onChange={color => this.setPrimaryColor(color.hex)}
            marginLeft={10}
            width={150}
          >
            {primaryColor}
          </CColorPickerDropdown>
        </FieldGroup>

        <FieldGroup className="satisfaction-style-section">
          <div
            onMouseEnter={this.highlightTheme(DARK_THEME)}
            onMouseLeave={this.removeHighlightedTheme}
            className="satisfaction-style-theme-option"
          >
            <div
              className={`satisfaction-app ${
                isDarkTheme(selectedTheme) ? 'selected-theme' : ''
              }`}
            >
              <div className="satisfaction-app-background dark">
                <div className="satisfaction-app-form dark">
                  <button
                    type="button"
                    aria-label="Dark theme"
                    className="satisfaction-app-button dark"
                  />
                </div>
              </div>
              {isDarkTheme(highlightedTheme) && (
                <Button
                  className="button-success"
                  onClick={this.selectTheme(DARK_THEME)}
                >
                  Select Dark Theme
                </Button>
              )}
            </div>
            <p className="themeLabel">Dark Theme</p>
            <p>(Best for Light Apps)</p>
          </div>

          <div
            onMouseEnter={this.highlightTheme(LIGHT_THEME)}
            onMouseLeave={this.removeHighlightedTheme}
            className="satisfaction-style-theme-option"
          >
            <div
              className={`satisfaction-app ${
                isLightTheme(selectedTheme) ? 'selected-theme' : ''
              }`}
            >
              <div className="satisfaction-app-background light">
                <div className="satisfaction-app-form light">
                  <button
                    type="button"
                    aria-label="Light theme"
                    className="satisfaction-app-button light"
                  />
                </div>
              </div>
              {isLightTheme(highlightedTheme) && (
                <Button
                  className="button-success"
                  onClick={this.selectTheme(LIGHT_THEME)}
                >
                  Select Light Theme
                </Button>
              )}
            </div>
            <p className="themeLabel">Light Theme</p>
            <p>(Best for Dark Apps)</p>
          </div>
        </FieldGroup>
      </Panel>
    );
  }
}

export default styled(SatisfactionStyleEdit)`
  &.panel {
    width: 55%;
    justify-content: center;
    flex-direction: column;

    .satisfaction-style-section {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0px;

      .field-group-header h3 {
        margin-top: 1rem;
      }
    }

    .slider-picker {
      position: absolute;
      top: 225px;
      width: 300px;
      background-color: white;
      border: 1px solid lightgrey;
      padding: 10px;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    }

    .field-group-fields {
      display: flex;

      .satisfaction-background-color {
        display: table;
        padding: 8px 10px;
        border-radius: 25px;
        background-color: #e1e7f050; /* Todo: choose variable */
        margin-left: 1em;

        &.show-color-picker {
          border-color: ${props => props.theme['$gray-20']};
        }

        .satisfaction-color-element {
          border-radius: 50%;
          cursor: pointer;

          &.outer {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            vertical-align: middle;
            width: 35px;
            height: 35px;
            background-color: transparent;
            border: 3px solid
              ${props =>
                buildSurveyBackgroundColor(
                  props.backgroundColor,
                  props.foregroundColor
                )};
          }

          .inner {
            width: 25px;
            height: 25px;
            border: 3px solid transparent;
            background-color: ${props =>
              buildSurveyBackgroundColor(
                props.backgroundColor,
                props.foregroundColor
              )};
          }
        }

        input {
          display: inline-block;
          vertical-align: middle;
          width: 80px;
          margin-left: 5px;
          border: none;
          background-color: transparent;
        }
      }

      .themeLabel {
        margin-top: 8px;
        font-weight: bold;
      }

      .satisfaction-style-theme-option {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 15px;

        .satisfaction-app {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 300px;
          height: 220px;
          border: 4px solid transparent;
          border-radius: 6px;

          &.selected-theme {
            border: 4px solid ${props => props.theme.$purple};
          }

          .button-success {
            position: absolute;
            box-shadow: 0 0 10px 2px white;
          }

          .satisfaction-app-background {
            display: flex;
            width: 80%;
            height: 80%;
            justify-content: flex-end;
            flex-direction: column;
            align-items: center;
            border: 1px solid ${props => props.theme['$gray-3']};

            &.dark {
              background-color: #e1e7f050; /* Todo: choose variable */
            }

            &.light {
              background-color: ${props => props.theme['$gray-50']};
            }

            .satisfaction-app-form {
              width: 70%;
              height: 20%;
              border-top-left-radius: 2px;
              border-top-right-radius: 2px;
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;

              &.dark {
                background-color: ${props =>
                  buildSurveyBackgroundColor(
                    props.backgroundColor,
                    props.foregroundColor
                  )};
              }

              &.light {
                background-color: #ffffff;
              }

              .satisfaction-app-button {
                width: 25px;
                height: 10px;
                margin: 5px 10px;
                border: 1px solid transparent;

                &.dark {
                  background-color: #ffffff;
                }
                &.light {
                  background-color: ${props =>
                    buildSurveyBackgroundColor(
                      props.backgroundColor,
                      props.foregroundColor
                    )};
                }
              }
            }
          }
        }
      }
    }
  }
`;
