import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { CPage } from '@appcues/component-library';
import useTitle from 'next/hooks/use-title';
import Loader from 'components/Common/Loader';
import ChildPage from 'components/Common/ChildPage';
import FlowStatusBanner from 'components/Common/FlowStatusBanner';
import Publishing from 'components/Common/Publishing';
import ChecklistDocLink from 'components/ChecklistsEdit/ChecklistDocLink';
import ChecklistConfiguration from 'components/ChecklistsEdit/ChecklistConfiguration';
import {
  selectChecklistStatus,
  callApi as poll,
  drop as cancel,
} from 'entities/checklist-status';
import { selectUserRole } from 'reducers/account/users';
import { selectUserId } from 'reducers/user';
import {
  selectAccountChecklist,
  selectAccountChecklistsSynced,
} from 'entities/checklists';
import { STEP_TYPE_IDS } from 'constants/stepTypes';
import { publishChecklist } from 'helpers/publishing-api';

export function ChecklistsEdit({
  match: {
    params: { checklistId },
  },
  checklist,
  className,
  contentStatus,
  loaded,
  role,
  onLoad,
  onUnload,
}) {
  useTitle('Create Checklist | Appcues');
  useEffect(() => {
    onLoad(checklistId);
    return onUnload;
  }, [checklistId]);

  if (!loaded || !checklist) {
    return <Loader />;
  }

  return (
    <ChildPage
      title={checklist.internalName}
      className={className}
      breadcrumbContents={{
        label: 'Checklists',
        path: '/checklists',
        child: {
          label: `${checklist.internalName}`,
          path: `/checklists/${checklist.id}/analytics`,
          child: {
            label: 'Edit',
          },
        },
      }}
      isLoading={!loaded}
      subHeaderActions={
        <Publishing
          contentStatus={contentStatus}
          isPublished={checklist.published}
          stepId={checklist.id}
          stepType={STEP_TYPE_IDS.CHECKLIST}
          lastUpdatedAtTimestamp={checklist.updatedAt}
          role={role}
          publishToApi={publishChecklist}
          checklist
        />
      }
    >
      <CPage.Container>
        <ChecklistDocLink />
        <FlowStatusBanner
          isPublished={checklist.published}
          isPublishedAndDirty={contentStatus.publishedAndDirty}
        />
        <ChecklistConfiguration checklist={checklist} />
      </CPage.Container>
    </ChildPage>
  );
}

function mapStateToProps(
  state,
  {
    match: {
      params: { checklistId },
    },
  }
) {
  return {
    loaded: selectAccountChecklistsSynced(state),
    checklist: selectAccountChecklist(state, checklistId),
    contentStatus: selectChecklistStatus(state) ?? {},
    role: selectUserRole(state, selectUserId(state)),
  };
}

const mapDispatchToProps = {
  onLoad: poll,
  onUnload: cancel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styled(ChecklistsEdit)`
  .checklist-text-short {
    width: 200px;
  }
  .checklist-text-long {
    width: 400px;
  }
  .checklist-textarea-long {
    width: 400px;
    height: 50px;
    margin-bottom: 0.45rem;
    margin-top: 0.75rem;
  }
  .configuration-item {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    .field-group-header {
      margin-right: 0.75rem;
      width: 200px;
    }
  }
  .items-panel {
    max-width: 75%;
  }
  .item-header-container {
    margin-bottom: 25px;
  }
  .toggle-sequential-container,
  .toggle-dismiss-container {
    display: flex;
    align-items: center;

    .toggle-sequential-label,
    .toggle-dismiss-label {
      margin-right: 8px;
      color: ${props => props.theme['$gray-30']};

      &.toggled {
        color: ${props => props.theme['$gray-70']};
      }
    }
  }
`);
