import React from 'react';
import styled from 'styled-components';
import LiveContentCard from 'components/SubscriptionStory/LiveContentCard';
import { FLOW_STATE } from 'constants/view';

const getPublishedContent = content => {
  return content.filter(
    contentItem => contentItem.state === FLOW_STATE.PUBLISHED
  );
};

const ContentLive = props => {
  const { checklists, navigate, satisfactionSurveys, steps } = props;

  const liveChecklists = getPublishedContent(Object.values(checklists || {}));

  const liveSatisfaction = getPublishedContent(
    Object.values(satisfactionSurveys || {})
  );

  const liveFlows = getPublishedContent(Object.values(steps || {}));

  return (
    <LiveWrapper>
      <LiveContentCard
        header="Flows"
        stat={liveFlows.length}
        description="Flows live on your site"
        type="flows"
        emptyDescription="Add an experience layer to your site."
        linkSlug="steps"
        navigate={navigate}
      />
      <LiveContentCard
        header="Checklists"
        stat={liveChecklists.length}
        description="Checklists live on your site"
        type="checklists"
        emptyDescription="Engage new users in discovering your site."
        linkSlug="checklists"
        navigate={navigate}
      />
      <LiveContentCard
        header="NPS Surveys"
        stat={liveSatisfaction.length}
        description="NPS surveys live on your site"
        type="nps surveys"
        emptyDescription="Capture feeback + measure sentiment."
        linkSlug="nps"
        navigate={navigate}
      />
    </LiveWrapper>
  );
};

const LiveWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default ContentLive;
