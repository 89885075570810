import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Select } from '@studio/legacy-components';
import { updateAccountThemeAndFlush } from 'actions/account/themes';
import ThemeFontDropdownOption from './ThemeFontDropdownOption';
import AddFontModal from './AddFontModal';
import EditTypekitIdModal from './EditTypekitIdModal';

const getFontNameFromGoogleUrl = url =>
  url.split('family=').pop().split(':').shift().split('+').join(' ');

// this should be an array of strings, but we currently get bad data
// sometimes -- single strings that are valid font urls, or single empty
// strings
const getCustomFontUrlAsArr = customFontUrl => {
  return customFontUrl
    ? [customFontUrl].flat().filter(fontUrl => !!fontUrl)
    : [];
};

export const ThemeFontDropdown = ({
  value = '',
  name,
  defaultOptions = [],
  customFontUrl = [],
  typekitId,
  setDraftTheme,
  onSave,
}) => {
  const [currentModal, setCurrentModal] = useState(null);

  const handleAddFont = () => setCurrentModal('addGoogleFont');
  const handleEditTypekitId = () => setCurrentModal('editTypekitId');
  const handleCloseModal = () => setCurrentModal(null);

  const adobeFontOptions = {
    label: 'Adobe fonts',
    options: [
      typekitId
        ? { label: typekitId, value: typekitId, type: 'adobe' }
        : { label: 'Add Adobe Font', type: 'addAdobeFont', disabled: true },
    ],
  };

  const googleFontUrls = getCustomFontUrlAsArr(customFontUrl);
  const customGoogleFontOptions = googleFontUrls.map(url => ({
    label: getFontNameFromGoogleUrl(url),
    value: getFontNameFromGoogleUrl(url),
    type: 'google',
    url,
  }));
  const googleFontOptions = {
    label: 'Google fonts',
    options: [
      ...customGoogleFontOptions,
      // dummy option that will be rendered as an "Add font" button
      { type: 'addGoogleFont', disabled: true },
    ],
  };

  const options = [
    adobeFontOptions,
    googleFontOptions,
    { label: 'Default fonts', options: defaultOptions },
  ];

  const onAddGoogleFont = ({ url }) =>
    setDraftTheme(draft => {
      const { customFontUrl: currentCustomFontUrl } = draft;
      const currentCustomFontUrlAsArr =
        getCustomFontUrlAsArr(currentCustomFontUrl);
      const newCustomFontUrl = new Set(currentCustomFontUrlAsArr);
      newCustomFontUrl.add(url);
      return {
        ...draft,
        // NOTE: Coercing Set to array via spreading currently not
        //       supported due to our babel configurations using core-js for
        //       IE support.
        // eslint-disable-next-line unicorn/prefer-spread
        customFontUrl: Array.from(newCustomFontUrl),
        [name]: getFontNameFromGoogleUrl(url),
      };
    });

  const onChange = option =>
    setDraftTheme(draft => ({
      ...draft,
      [name]: option.value,
    }));

  const onChangeTypekitId = ({ value: newTypekitId }) =>
    setDraftTheme(draft => ({
      ...draft,
      typekitId: newTypekitId,
      projectId: newTypekitId,
      [name]: newTypekitId,
    }));

  const onDeleteCustomFont = url =>
    setDraftTheme(draft => {
      const { customFontUrl: currentCustomFontUrl } = draft;
      const currentCustomFontUrlAsArr =
        getCustomFontUrlAsArr(currentCustomFontUrl);
      return {
        ...draft,
        customFontUrl: currentCustomFontUrlAsArr.filter(
          fontUrl => fontUrl !== url
        ),
      };
    });

  // this value should be an array but is sometimes a string, patch those bad
  // values when we find them (at least until we know the cause)
  useEffect(() => {
    if (!Array.isArray(customFontUrl)) {
      const newCustomFontUrl =
        customFontUrl && typeof customFontUrl === 'string'
          ? [customFontUrl]
          : [];
      onSave({ customFontUrl: newCustomFontUrl });
    }
  }, []);

  const selected = options
    .flatMap(group => group.options)
    .find(option => option.value === value);

  return (
    <>
      {currentModal === 'addGoogleFont' && (
        <AddFontModal onSubmit={onAddGoogleFont} onClose={handleCloseModal} />
      )}
      {currentModal === 'editTypekitId' && (
        <EditTypekitIdModal
          value={typekitId}
          onSubmit={onChangeTypekitId}
          onClose={handleCloseModal}
        />
      )}
      <Select
        components={{
          // NOTE: This dropdown uses some strange custom options that launch
          // modals to add new Typekit/Google fonts...
          Option: ThemeFontDropdownOption,
        }}
        defaultValue={defaultOptions[0]}
        onAddFont={handleAddFont}
        onChange={onChange}
        onDelete={onDeleteCustomFont}
        onEditTypekitId={handleEditTypekitId}
        options={options}
        placeholder="Select a font"
        style={{
          // Update menu styles to expand and tether to bottom-right
          menu: provided => ({
            ...provided,
            minWidth: '100%',
            right: '0',
            width: 'auto',
          }),
        }}
        value={selected}
      />
    </>
  );
};

ThemeFontDropdown.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  defaultOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  customFontUrl: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  typekitId: PropTypes.string,
  setDraftTheme: PropTypes.func,
  onSave: PropTypes.func,
};

const mapDispatchToProps = (dispatch, { themeId }) => ({
  onSave: changes => {
    dispatch(updateAccountThemeAndFlush(themeId, changes));
  },
});

export default connect(null, mapDispatchToProps)(ThemeFontDropdown);
