import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Page,
  PageBody,
  FULL_RANGE_OPTIONS,
  Select,
} from '@studio/legacy-components';
import ExperienceHeader, {
  AnalyticsHeaderActions,
} from 'next/components/ExperienceHeader';
import { clear } from 'next/entities/analytics';
import { replace as replacePage } from 'next/entities/page';
import ExperienceContext from 'next/components/ExperienceContext';
import { selectExperience } from 'next/entities/experiences';
import BoundedDateRangeDropdown from 'next/components/BoundedDateRangeDropdown';
import { Control, Controls, ControlSeparator } from 'next/components/Listing';
import TimestampRefresh from 'next/components/TimestampRefresh';
import { RecentUsersNewProvider } from 'next/components/RecentUsers';
import NotPublished from 'next/components/NotPublished/NotPublished';
import useDateRangeQuery from 'next/hooks/use-date-range-query';
import { selectExperienceHistoricalLimit } from 'next/lib/selectors';
import { selectSegmentTargetingOptions } from 'next/lib/selectors-options';

import ActivityChart from './ActivityChart';
import OverviewMetrics from './OverviewMetrics';

export const AnalyticsPage = ({
  id,
  createdAt,
  onLoad,
  published,
  limit,
  segmentsOptions = [],
  onRefresh,
  hasDismissEnabled,
}) => {
  const [segmentId, setSegmentId] = useState(null);
  const [updated, setUpdated] = useState(Date.now());
  const [{ range, start, end }, setDateRange] = useDateRangeQuery(
    FULL_RANGE_OPTIONS,
    {
      range: 7,
    }
  );

  const selected = segmentsOptions.find(option => option.value === segmentId);

  const handleRefresh = () => {
    onRefresh();
    setUpdated(Date.now());
  };

  // This value is used by the ExperienceContext.
  // We're using a context to avoid the prop drilling across multiple levels of components.
  // This context is also important to pass other data to components far below in the tree.
  const experienceData = useMemo(
    () => ({
      type: 'banner',
      routePath: '/banners',
    }),
    []
  );

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <ExperienceContext.Provider value={experienceData}>
      <Page>
        <ExperienceHeader
          currentPage="analytics"
          id={id}
          renderActions={({ onClickOpenInBuilder }) => (
            <AnalyticsHeaderActions
              type="banners"
              id={id}
              onClickOpenInBuilder={onClickOpenInBuilder}
              createdAt={createdAt}
            />
          )}
        />
        <PageBody>
          {published ? (
            <>
              <Controls>
                <Control>
                  <BoundedDateRangeDropdown
                    limit={limit}
                    onApply={setDateRange}
                    options={FULL_RANGE_OPTIONS}
                    value={{ range, start, end }}
                  />
                </Control>
                <Control>
                  <Select
                    icon="users"
                    onChange={option => setSegmentId(option?.value ?? null)}
                    options={segmentsOptions}
                    placeholder="Select a segment..."
                    value={selected}
                    isClearable
                  />
                </Control>
                <ControlSeparator />
                <Control>
                  <TimestampRefresh endTime={updated} onClick={handleRefresh} />
                </Control>
              </Controls>
              <ActivityChart
                startTime={start.getTime()}
                endTime={end.getTime()}
                updated={updated}
                segmentId={segmentId}
                id={id}
              />
              <OverviewMetrics
                id={id}
                endTime={end.getTime()}
                startTime={start.getTime()}
                segmentId={segmentId}
                hasDismissEnabled={hasDismissEnabled}
              />
              <RecentUsersNewProvider
                id={id}
                endTime={end.toISOString()}
                startTime={start.toISOString()}
                limit={10}
                type="Banner"
              />
            </>
          ) : (
            <NotPublished
              title="Track Banner performance"
              description="View key performance metrics about this Banner experience."
              action={{
                link: `/banners/${id}/settings`,
                label: 'Publish Banner',
              }}
            />
          )}
        </PageBody>
      </Page>
    </ExperienceContext.Provider>
  );
};

AnalyticsPage.propTypes = {
  id: PropTypes.string,
  onLoad: PropTypes.func,
  onRefresh: PropTypes.func,
  createdAt: PropTypes.number,
  match: PropTypes.shape({
    params: PropTypes.shape({
      experienceId: PropTypes.string,
    }),
  }),
  published: PropTypes.bool,
  hasDismissEnabled: PropTypes.bool,
  limit: PropTypes.number,
  segmentsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      path,
      params: { experienceId: id },
    },
  }
) => ({
  onLoad: () => dispatch(replacePage({ path, id })),
  onRefresh: () => dispatch(clear()),
});

const mapStateToProps = (
  state,
  {
    match: {
      params: { experienceId: id },
    },
  }
) => {
  const experience = selectExperience(state, id) || {};
  const hasDismissEnabled = Boolean(
    experience?.traits?.some(({ type }) => type === '@appcues/dismissible')
  );

  return {
    id,
    createdAt: experience.createdAt || 0,
    published: Boolean(experience.publishedAt),
    limit: selectExperienceHistoricalLimit(state),
    segmentsOptions: selectSegmentTargetingOptions(state),
    hasDismissEnabled,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsPage);
