import { createSelector } from 'reselect';
import { createCollectionReducer } from 'next/lib/collections';
import { selectRequestStatus } from 'next/entities/requests';

import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

export const selectRules = state => state[TYPE];
export const selectRule = (state, id) => selectRules(state)?.[id];

export const readRules = state => ({
  ...selectRequestStatus(state, TYPE),
  data: selectRules(state),
});
export const readRule = (state, id) => ({
  ...selectRequestStatus(state, TYPE, id),
  data: selectRule(state, id),
});

export const selectRuleGoal = createSelector(selectRule, rule => {
  const [goalId] = rule?.goals || [];
  return goalId;
});
