import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  CPage,
  CButton,
  CBanner,
  Grid,
  Box,
  Flex,
  P,
  H2,
  Caption,
} from '@appcues/component-library';
import { IconWithinCButton, Link } from '@studio/legacy-components';
import { DocumentTitle } from 'next/hooks/use-title';
import { GOALS_EVENTS_EXPLORER } from 'next/entities/features';
import EmptyState from 'components/Common/EmptyState';
import IndexPage from 'components/Common/IndexPage';
import Loader from 'components/Common/Loader';
import GoalCard from 'components/GoalsIndex/GoalCard';
import CoolTip from 'components/Common/CoolTip';

import { showModal } from 'actions/currentModal';
import { selectUserRole } from 'reducers/account/users';
import { selectUserId } from 'reducers/user';
import { update, deleteGoal } from 'actions/account/goals';
import { navigate } from 'actions/routing';
import {
  selectAccountGoals,
  selectAccountGoalsSynced,
  selectGoalsWithFlowConnected,
} from 'reducers/account/goals';

import {
  SUPPORT_EMAIL,
  GOALS_DOC_URL,
} from 'constants/externalAppcuesResources';
import { PLAN_TO_TIER_MAPPINGS, PLAN_TYPES } from 'constants/plans';
import {
  GOAL_LIMIT_MODAL,
  DELETE_GOAL_MODAL,
  FLOW_DEPENDENCY_MODAL,
} from 'constants/globals';
import { ACCOUNT_PERMISSIONS } from 'constants/accountManagement/permissions';

import * as persistence from 'helpers/persistence';
import { computeGoalsLimit } from 'helpers/goals';
import {
  selectAccountMeta,
  selectAccountMetaSynced,
} from 'reducers/account/meta';
import { selectGoalsFirstReachedThisWeek } from 'reducers/analytics';
import { fetchGoalsFirstReachedThisWeek } from 'actions/analytics';
import { selectAccountFeature } from 'reducers/account/features';
import { SELECTED_EVENT_SOURCE } from 'components/Insights/constants';

function sortByLastActiveDate(ascending) {
  const order = ascending ? -1 : 1;
  return (goalA, goalB) => {
    try {
      if (
        goalA.enabledTs[goalA.enabledTs.length - 1] <=
        goalB.enabledTs[goalB.enabledTs.length - 1]
      ) {
        return order;
      }
      return -1 * order;
    } catch {
      return 0;
    }
  };
}

function filterToActiveGoals(goals) {
  return Object.keys(goals || {})
    .map(goalId => goals[goalId])
    .filter(goal => !goal.disabled)
    .sort(sortByLastActiveDate(true));
}

function filterToInactiveGoals(goals) {
  return Object.keys(goals || {})
    .map(goalId => goals[goalId])
    .filter(goal => goal.disabled)
    .sort(sortByLastActiveDate(true));
}

export class GoalsIndex extends Component {
  constructor(props) {
    super(props);
    const { loaded, accountMetadata, allGoals } = props;
    if (loaded) {
      const maxNumberOfActiveGoals = computeGoalsLimit(accountMetadata);
      const numberOfActiveGoals = filterToActiveGoals(allGoals).length;
      this.state = {
        maxNumberOfActiveGoals,
        numberOfActiveGoals,
        isAtGoalLimit: numberOfActiveGoals >= maxNumberOfActiveGoals,
      };
    } else {
      this.state = {};
    }
  }

  componentDidMount() {
    const { onLoad } = this.props;
    onLoad();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { allGoals, loaded, accountMetadata } = nextProps;

    if (loaded) {
      const { numberOfActiveGoals, maxNumberOfActiveGoals, planTitle } =
        this.state;
      const nextMaxNumberOfActiveGoals = computeGoalsLimit(accountMetadata);
      const nextNumberOfActiveGoals = filterToActiveGoals(allGoals).length;

      if (!maxNumberOfActiveGoals) {
        this.setState({ maxNumberOfActiveGoals: nextMaxNumberOfActiveGoals });
      }

      if (numberOfActiveGoals !== nextNumberOfActiveGoals) {
        this.setState({
          numberOfActiveGoals: nextNumberOfActiveGoals,
          isAtGoalLimit: nextNumberOfActiveGoals >= nextMaxNumberOfActiveGoals,
        });
      }

      if (!planTitle) {
        const tier = PLAN_TO_TIER_MAPPINGS[accountMetadata.stripePlanId];
        const plan = PLAN_TYPES.reduce((memo, planType) => {
          if (planType.id === tier) {
            return planType;
          }
          return memo;
        }, null);
        if (
          (accountMetadata.planLimitOverrides &&
            typeof accountMetadata.planLimitOverrides.maxGoals === 'number') ||
          !plan
        ) {
          this.setState({ planTitle: 'Custom Plan' });
        } else if (tier === 'trial') {
          this.setState({ planTitle: 'Trial' });
        } else {
          this.setState({ planTitle: `${plan.name} Plan` });
        }
      }
    }
  }

  GoalButton = ({ cannotCreateGoal, onClickCreate }) => {
    const baseGoalButton = (
      <CButton
        isDisabled={cannotCreateGoal}
        key="goals-create"
        type="positive"
        onClick={onClickCreate}
      >
        Create a goal
      </CButton>
    );

    if (cannotCreateGoal) {
      return (
        <CoolTip
          tip="Contact a team admin or publisher to create goals."
          position="bottom"
          marginRight={18}
        >
          {baseGoalButton}
        </CoolTip>
      );
    }
    return baseGoalButton;
  };

  onClickCreate = () => {
    const { onShowModal, onNavigate } = this.props;
    const { isAtGoalLimit, numberOfActiveGoals } = this.state;

    if (isAtGoalLimit) {
      onShowModal(GOAL_LIMIT_MODAL, {
        title: 'Active goal limit reached',
        activeGoalsCount: numberOfActiveGoals,
        action: 'create',
      });
    } else {
      onNavigate('/goals/new');
    }
  };

  onClickActivate = goal => {
    const { onShowModal, onChangeGoal } = this.props;
    const { isAtGoalLimit, numberOfActiveGoals } = this.state;

    if (isAtGoalLimit) {
      onShowModal(GOAL_LIMIT_MODAL, {
        title: 'Active goal limit reached',
        activeGoalsCount: numberOfActiveGoals,
        action: 'activate',
      });
    } else {
      onChangeGoal(goal.id, { disabled: false });
    }
  };

  onClickDeactivate = goal => {
    const { onShowModal, onChangeGoal, connectedFlows } = this.props;
    const hasFlowConnected = Boolean(connectedFlows[goal.id]);

    if (hasFlowConnected) {
      onShowModal(FLOW_DEPENDENCY_MODAL, {
        title: 'Unable to Deactivate Goal',
        description:
          'To deactivate this goal, remove it from the settings in the following areas:',
        flows: connectedFlows[goal.id],
      });
    } else {
      onChangeGoal(goal.id, { disabled: true });
    }
  };

  onClickDelete = goalId => {
    const { onShowModal, onDeleteGoal, allGoals, connectedFlows } = this.props;
    const hasFlowConnected = Boolean(connectedFlows[goalId]);
    const goalName = allGoals[goalId].name;

    if (hasFlowConnected) {
      onShowModal(FLOW_DEPENDENCY_MODAL, {
        title: 'Unable to Delete Goal',
        description:
          'To delete this goal, remove it from the settings in the following areas:',
        flows: connectedFlows[goalId],
      });
    } else {
      onShowModal(DELETE_GOAL_MODAL, {
        goalId,
        goalName,
        deleteGoal: onDeleteGoal,
      });
    }
  };

  render() {
    const {
      className,
      loaded,
      allGoals,
      goalsFirstReachedThisWeek,
      role,
      hasGoalsExplorer,
    } = this.props;
    const { isAtGoalLimit, maxNumberOfActiveGoals, planTitle } = this.state;
    const goalsExist = allGoals && Object.keys(allGoals).length > 0;
    const cannotCreateGoal = role === ACCOUNT_PERMISSIONS.EDITOR;

    if (!loaded) {
      return <Loader />;
    }

    const upgradeMailToURL = `mailto:${SUPPORT_EMAIL}?subject=I%27d%20like%20to%20upgrade%20for%20more%20goals&body=Hey%20Appcues%2C%0A%0AI%27m%20currently%20limited%20to%20${maxNumberOfActiveGoals}%20flows%20on%20my%20current%20plan.%20Please%20upgrade%20me!%0A%0AThanks!`;

    return (
      <IndexPage
        className={className}
        title="Goals"
        subHeaderActions={[
          <CButton key="help" is="a" isExternal href={GOALS_DOC_URL}>
            <IconWithinCButton icon="question-circle" gray />
            Help
          </CButton>,
          <this.GoalButton
            key="goal"
            cannotCreateGoal={cannotCreateGoal}
            onClickCreate={this.onClickCreate}
          />,
        ]}
      >
        <DocumentTitle title="Goals | Appcues" />
        <CPage.Container>
          {goalsExist && (
            <>
              {isAtGoalLimit && (
                <CBanner marginBottom={32}>
                  <CBanner.Content>
                    <strong>Note: </strong>
                    you&apos;ve reached the <strong>{planTitle}</strong> limit
                    of <strong>{maxNumberOfActiveGoals}</strong> active goals.
                    Upgrade your plan to track more at a time.
                  </CBanner.Content>
                  <CBanner.Actions>
                    <CButton
                      is="a"
                      type="info"
                      href={upgradeMailToURL}
                      target="_blank"
                    >
                      Upgrade
                    </CButton>
                  </CBanner.Actions>
                </CBanner>
              )}
              <Box>
                <H2 type="secondary">Active Goals</H2>
                {hasGoalsExplorer ? (
                  <P color="$gray-5" paddingY={10}>
                    These goals are actively being measured. Analyze your{' '}
                    <Link
                      href="/insights/explorer"
                      onClick={() => {
                        persistence.setItem(SELECTED_EVENT_SOURCE, 'goals');
                      }}
                    >
                      goal completion reporting
                    </Link>{' '}
                    for more insights.
                  </P>
                ) : (
                  <P color="$gray-5" paddingY={10}>
                    These goals are active and being measured. You can choose
                    any active goals when editing a flow to measure its effect.
                  </P>
                )}
                <Grid>
                  {filterToActiveGoals(allGoals).map(goal => (
                    <GoalCard
                      key={goal}
                      goal={goal}
                      goalsFirstReachedThisWeek={goalsFirstReachedThisWeek}
                      onDeactivate={this.onClickDeactivate}
                      onDelete={this.onClickDelete}
                      hasGoalsExplorer={hasGoalsExplorer}
                    />
                  ))}
                </Grid>
              </Box>
              <Box marginTop={48}>
                <H2 type="secondary">Inactive Goals</H2>
                <P color="$gray-5" paddingY={10}>
                  These goals aren&apos;t being measured. You can reactivate
                  them at any time.
                </P>
                <Grid>
                  {filterToInactiveGoals(allGoals).map(goal => (
                    <GoalCard
                      key={goal}
                      goal={goal}
                      onActivate={this.onClickActivate}
                      onDelete={this.onClickDelete}
                      hasGoalsExplorer={hasGoalsExplorer}
                    />
                  ))}
                </Grid>
              </Box>
            </>
          )}

          {!goalsExist && (
            <EmptyState
              customVisualNode={<GoalBarGraphs />}
              header="Use goals to measure the impact of your flows."
              body="Directly measure how your content influences user behaviors - including activation moments, feature adoption, upgrades, and more."
            >
              <CButton
                type="positive"
                marginTop={48}
                onClick={this.onClickCreate}
              >
                Create your first goal!
              </CButton>
            </EmptyState>
          )}
        </CPage.Container>
      </IndexPage>
    );
  }
}

function mapStateToProps(state) {
  return {
    loaded: selectAccountGoalsSynced(state) && selectAccountMetaSynced(state),
    allGoals: selectAccountGoals(state),
    accountMetadata: selectAccountMeta(state),
    goalsFirstReachedThisWeek: selectGoalsFirstReachedThisWeek(state),
    role: selectUserRole(state, selectUserId(state)),
    hasGoalsExplorer: selectAccountFeature(state, GOALS_EVENTS_EXPLORER),
    connectedFlows: selectGoalsWithFlowConnected(state),
  };
}

const mapDispatchToProps = {
  onShowModal: showModal,
  onNavigate: navigate,
  onLoad: fetchGoalsFirstReachedThisWeek,
  onChangeGoal: update,
  onDeleteGoal: deleteGoal,
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalsIndex);

const BarBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0px 12px 34px rgba(71, 88, 114, 0.35);
  width: 154px;
  height: ${props => props.height};
  overflow: hidden;
`;

const ImageContainer = styled.div`
  width: 55%;
  display: flex;
  align-self: flex-end;
  justify-content: space-between;
  align-items: center;
  height: 158px;
`;

const GoalBarGraphs = () => (
  <ImageContainer>
    <BarBox height={158}>
      <Box backgroundImage="$grapefruit" height={94} />
      <Flex alignItems="baseline" justifyContent="center" paddingY={12}>
        <H2 type="secondary">50%</H2>
        <Caption type="tertiary">&nbsp; of users</Caption>
      </Flex>
    </BarBox>
    <BarBox height={112}>
      <Box backgroundImage="$merfolk" height={48} />
      <Flex alignItems="baseline" justifyContent="center" paddingY={12}>
        <H2 type="secondary">25%</H2>
        <Caption type="tertiary">&nbsp; of users</Caption>
      </Flex>
    </BarBox>
  </ImageContainer>
);
