import React from 'react';
import PropTypes from 'prop-types';
import { object, number } from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import {
  Button,
  ErrorMsg,
  Heading,
  Input,
  Modal,
  ModalFooter,
  Overflow,
  Select,
  Switch,
} from '@studio/legacy-components';

import useToggle from 'next/hooks/use-toggle';
import { FieldsWrapper, InformativeText, InputWrapper } from './styled';

const THROTTLE_LIMIT_ERROR_MSG = 'Enter values between 1 - 100,000';

const VALIDATION_SCHEMA = object().shape({
  throttleContentLimit: number()
    .required(THROTTLE_LIMIT_ERROR_MSG)
    .positive()
    .integer(THROTTLE_LIMIT_ERROR_MSG)
    .max(100000, THROTTLE_LIMIT_ERROR_MSG),
});

export const timeframeOptions = [
  { label: 'Minute', value: 60000 },
  { label: 'Hour', value: 3600000 },
  { label: 'Day', value: 86400000 },
  { label: 'Week', value: 604800000 },
];

const formFields = {
  throttleEnabled: 'throttleEnabled',
  throttleContentLimit: 'throttleContentLimit',
  throttleContentTimespanMs: 'throttleContentTimespanMs',
};

export function getCurrentTimeframe(throttleContentTimespanMs) {
  const currentTimeframe = timeframeOptions
    .find(({ value }) => value === throttleContentTimespanMs)
    ?.label?.toLowerCase();

  return currentTimeframe || '-';
}

function FlowFrequencyModalManager({
  onSave,
  throttleEnabled = false,
  throttleContentLimit = 0,
  throttleContentTimespanMs = 3600000,
  trigger,
}) {
  const [visible, toggle] = useToggle(false);

  const onSubmit = values => {
    toggle();
    onSave(values);
  };

  return (
    <>
      {React.isValidElement(trigger) ? (
        React.cloneElement(trigger, {
          onClick: toggle,
        })
      ) : (
        <Button kind="secondary" onClick={toggle}>
          Edit frequency limit
        </Button>
      )}

      <Modal visible={visible} onClose={toggle} key="dialog" size="m">
        <Heading>Frequency limit</Heading>
        <Overflow>
          <p>Limit how often users can see a Flow or NPS survey.</p>
        </Overflow>
        <Formik
          initialValues={{
            throttleContentLimit,
            throttleContentTimespanMs,
            throttleEnabled,
          }}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={onSubmit}
          validateOnChange
        >
          {({ values, isValid, errors, setFieldValue, dirty, touched }) => {
            const isThrottleEnabled = values[formFields.throttleEnabled];

            const currentTimeframe = getCurrentTimeframe(
              values.throttleContentTimespanMs
            );

            return (
              <Form>
                <Field
                  name={formFields.throttleEnabled}
                  as={Switch}
                  checked={values[formFields.throttleEnabled]}
                  textOn="On"
                  textOff="Off"
                  onChange={enableFrequency => {
                    setFieldValue(formFields.throttleEnabled, enableFrequency);
                  }}
                />
                <FieldsWrapper>
                  <InputWrapper>
                    <Field
                      name={formFields.throttleContentLimit}
                      as={Input}
                      aria-label="experiences limit"
                      type="number"
                      placeholder="e.g 10"
                      min="1"
                      disabled={!isThrottleEnabled}
                      error={
                        isThrottleEnabled &&
                        Boolean(errors[formFields.throttleContentLimit]) &&
                        touched[formFields.throttleContentLimit]
                      }
                    />
                    {isThrottleEnabled && (
                      <ErrorMessage
                        name={formFields.throttleContentLimit}
                        component={ErrorMsg}
                      />
                    )}
                  </InputWrapper>
                  <Field
                    name={formFields.throttleContentTimespanMs}
                    as={Select}
                    id={formFields.throttleContentTimespanMs}
                    data-testid="units"
                    aria-label="units of time"
                    isDisabled={!isThrottleEnabled}
                    options={timeframeOptions}
                    onChange={option =>
                      setFieldValue(
                        formFields.throttleContentTimespanMs,
                        option.value
                      )
                    }
                    value={timeframeOptions.find(
                      option =>
                        option.value ===
                        values[formFields.throttleContentTimespanMs]
                    )}
                  />
                </FieldsWrapper>
                {isThrottleEnabled &&
                  Boolean(values.throttleContentTimespanMs) && (
                    <InformativeText>
                      Flows and NPS will not be shown to a user if they have
                      seen more than{' '}
                      <strong>
                        {values.throttleContentLimit || '-'} per{' '}
                        {currentTimeframe}
                      </strong>
                      . After the {currentTimeframe}, they will show again.
                    </InformativeText>
                  )}
                <ModalFooter>
                  <Button type="submit" disabled={!isValid || !dirty}>
                    Save
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
}

FlowFrequencyModalManager.propTypes = {
  onSave: PropTypes.func,
  throttleEnabled: PropTypes.bool,
  throttleContentLimit: PropTypes.number,
  throttleContentTimespanMs: PropTypes.number,
  trigger: PropTypes.element,
};

export default FlowFrequencyModalManager;
