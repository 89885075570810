import {
  debounce,
  takeEvery,
  put,
  select,
  call,
  getContext,
  all,
  fork,
  actionChannel,
  take,
} from 'redux-saga/effects';
import {
  getFlattenedClausesFromNestedConditions,
  getNestedConditionsFromFlattenedClauses,
  clauseKeys,
} from '@appcues/libcues';
import toast from 'next/lib/toast';
import { selectUserId } from 'reducers/user';
import { reportError } from 'helpers/error-reporting';

import { replaceRule } from 'sagas/account/rule-conditions';
import { CREATE_RULE, FETCH_FLOW_RULE } from 'constants/account/rules';
import { blacklist } from 'utils';
import { selectAccountRule } from 'reducers/account/rules';
import { selectAccountStepConditions } from 'reducers/account/conditions';
import { replaceAccountConditions } from 'actions/account/conditions';
import { UPDATE_ACCOUNT_STEP_CONDITIONS } from 'constants/account/conditions';
import {
  send,
  resolve,
  reject,
  update,
  flush,
  patterns,
} from 'actions/account/rules';
import {
  actionTypes as flowActionTypes,
  patterns as flowPatterns,
} from 'actions/account/flows';
import { patterns as goalPatterns } from 'actions/account/goals';
import { SYNC_ACCOUNT_WITH_FIREBASE } from 'constants/actionTypes';
import { promisaga } from 'utils/as-promised';

const fetchRulePattern = action =>
  patterns.callApi(action) && action.payload && action.payload.id;
const fetchAllRulesPattern = action =>
  patterns.callApi(action) && !action.payload;

export function* fetchRule(action) {
  try {
    const { id } = action.payload;
    const api = yield getContext('api');
    const { data: rule } = yield call(api.getRule, id);
    yield call(replaceRule, rule);
  } catch (error) {
    yield call(reportError, error);
  }
}

export function* fetchRules() {
  try {
    yield put(send());

    const api = yield getContext('api');

    const { data } = yield call(api.getAllRules);

    const result = data.reduce(
      (acc, item) => {
        const { conditions, ...rule } = item;
        acc.conditions[item.id] =
          getFlattenedClausesFromNestedConditions(conditions);
        acc.rules[item.id] = rule;
        return acc;
      },
      { rules: {}, conditions: {} }
    );
    yield all([
      put(resolve(result.rules)),
      /**
       * I have intentionally done the bad thing here so that I will have to
       * come back to fix it - @evandavis
       */
      put(replaceAccountConditions(result.conditions)),
    ]);
  } catch (error) {
    yield put(reject(error));
  }
}

export function* flushRule({ payload }) {
  try {
    const id = payload.stepId || payload.id;

    const ruleToFlush = yield select(selectAccountRule, id);
    const conditionsToFlush = yield select(selectAccountStepConditions, id);
    const api = yield getContext('api');

    const stepRule = {
      ...blacklist(ruleToFlush, [
        'published',
        'state',
        'updatedAt',
        'updatedBy',
      ]),
      // ...ruleToFlush,
      ...(conditionsToFlush.length > 0 && {
        conditions: getNestedConditionsFromFlattenedClauses(
          conditionsToFlush,
          null,
          clauseKeys
        ),
      }),
    };

    if (!stepRule.migratedAt) {
      stepRule.migratedAt = Date.now();
    }

    if (stepRule.conditions && stepRule.conditions.or) {
      stepRule.conditions = { and: stepRule.conditions.or };
    }

    yield call(api.updateRule, id, stepRule);
    yield put(flush(id));
    yield call(toast.success, 'Changes saved');
  } catch (error) {
    yield all([
      call(toast.error, 'Failed to save rule'),
      call(reportError, error),
    ]);
  }
}
// don't touch
export function* createRule(action) {
  try {
    const { id, rule: newRule, conditions: newConditions } = action.payload;
    const api = yield getContext('api');
    const now = Date.now();

    const userId = yield select(selectUserId);

    const { data } = yield call(api.updateRule, id, {
      ...newRule,
      id,
      createdBy: userId,
      createdAt: now,
      migratedAt: now,
      updatedAt: now,
      updatedBy: userId,
      conditions: getNestedConditionsFromFlattenedClauses(
        newConditions,
        null,
        clauseKeys
      ),
    });
    yield call(replaceRule, data);
  } catch (error) {
    yield all([
      call(toast.error, 'Failed to create rule'),
      call(reportError, error),
    ]);
  }
}

export function* setGoalOnRule(action) {
  const { stepId } = action.payload;

  if (stepId) {
    const {
      payload: { id: goalId },
    } = yield take(goalPatterns.insert);

    yield put(update(stepId, { goals: [goalId] }));
  }
}

export function* syncRules() {
  // buffer all rule requests until summary data loads
  const ruleRequestsChannel = yield actionChannel([
    fetchRulePattern,
    fetchAllRulesPattern,
  ]);

  yield take(flowPatterns.resolve);

  // once flow summaries have resolved, clear the buffer and continue
  // as you normally would

  while (true) {
    const ruleAction = yield take(ruleRequestsChannel);
    if (fetchRulePattern(ruleAction)) {
      yield call(fetchRule, ruleAction);
    } else if (fetchAllRulesPattern(ruleAction)) {
      break;
    }
  }

  yield fork(fetchRules);

  // if we made it past the takeUntil, trigger fetchRules each time SYNC_ACCOUNT_WITH_FIREBASE
  // is dispatched in case the spoofing user is on a page that relies on rules
  yield takeEvery(
    [SYNC_ACCOUNT_WITH_FIREBASE, fetchAllRulesPattern],
    fetchRules
  );
}

export default function* rules() {
  yield debounce(
    500,
    [patterns.update, UPDATE_ACCOUNT_STEP_CONDITIONS],
    flushRule
  );
  yield takeEvery(CREATE_RULE, createRule);
  yield takeEvery(goalPatterns.create, setGoalOnRule);
  yield takeEvery([Object.keys(flowActionTypes)], fetchRules);
  yield takeEvery(FETCH_FLOW_RULE, promisaga(fetchRule));
  yield fork(syncRules);
}
