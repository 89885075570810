import { conditionNames, propertyOperators } from '@appcues/libcues';
import {
  defaultOperators,
  millisecondsPerDay,
} from 'constants/account/conditions';

export function convertRuleToNestedConditions(rule) {
  let masterClause = [];

  if (rule.where || rule.whereString) {
    masterClause = [
      ...masterClause,
      {
        or: [
          (() => {
            switch (true) {
              case rule.isRegex:
                return {
                  [conditionNames.URL]: {
                    operator: defaultOperators.REGEX,
                    value: rule.where,
                  },
                };

              case rule.whereOperator !== undefined:
                switch (rule.whereOperator) {
                  case '^':
                    return {
                      [conditionNames.URL]: {
                        operator: defaultOperators.STARTS_WITH,
                        value: rule.whereString,
                      },
                    };
                  case '$':
                    return {
                      [conditionNames.URL]: {
                        operator: defaultOperators.ENDS_WITH,
                        value: rule.whereString,
                      },
                    };
                  case '*':
                    return {
                      [conditionNames.URL]: {
                        operator: defaultOperators.CONTAINS,
                        value: rule.whereString,
                      },
                    };
                  default:
                    break;
                }
                break;

              default:
                break;
            }
            return {
              [conditionNames.URL]: {
                operator: defaultOperators.EXACT,
                value: rule.where,
              },
            };
          })(),
        ],
      },
    ];
  }

  if (rule.domains && rule.domains !== '') {
    masterClause = [
      ...masterClause,
      {
        or: rule.domains.split(',').map(value => {
          return {
            [conditionNames.DOMAINS]: {
              operator: defaultOperators.EXACT,
              value,
            },
          };
        }),
      },
    ];
  }

  const nonExcludedProperties = Object.keys(rule.properties || {}).filter(
    it => it !== '_ABGroup'
  );

  const hasLanguages = rule.languages && rule.languages !== '';
  const hasProperties = nonExcludedProperties.length > 0;
  const hasContents = rule.contents;
  const hasEvents = rule.events;

  if (hasLanguages || hasProperties || hasContents || hasEvents) {
    let userConditions = [];

    if (hasLanguages) {
      userConditions = [
        ...userConditions,
        {
          or: rule.languages.split(',').map(value => {
            return {
              [conditionNames.LANGUAGES]: {
                operator: propertyOperators.STARTS_WITH,
                value,
              },
            };
          }),
        },
      ];
    }

    if (hasContents) {
      const stepId = Object.keys(rule.contents)[0];
      const stepClause = rule.contents[stepId];

      userConditions = stepClause.negative
        ? [
            ...userConditions,
            {
              not: [
                {
                  [conditionNames.CONTENT]: {
                    content: stepId,
                    times: stepClause.times,
                    since: '0',
                  },
                },
              ],
            },
          ]
        : [
            ...userConditions,
            {
              [conditionNames.CONTENT]: {
                content: stepId,
                times: stepClause.times,
                since: '0',
              },
            },
          ];
    }

    if (hasEvents) {
      const eventName = Object.keys(rule.events)[0];
      const eventClause = rule.events[eventName];

      userConditions = eventClause.negative
        ? [
            ...userConditions,
            {
              not: [
                {
                  [conditionNames.EVENT]: {
                    event: eventName,
                    times: eventClause.times,
                    since: '0',
                  },
                },
              ],
            },
          ]
        : [
            ...userConditions,
            {
              [conditionNames.EVENT]: {
                event: eventName,
                times: eventClause.times,
                since: '0',
              },
            },
          ];
    }

    if (hasProperties) {
      userConditions = [
        ...userConditions,
        ...nonExcludedProperties.map(property => {
          const recipe = rule.properties[property];

          if (
            [propertyOperators.IN, propertyOperators.NOT_IN].includes(
              recipe.operator
            ) ||
            (!(recipe instanceof Object && 'value' in recipe) &&
              recipe.valuesList &&
              recipe.valuesList !== '')
          ) {
            // these properties work against a list of values
            const valuesList =
              'valuesList' in recipe ? recipe.valuesList.split(/\n/g) : [];

            if (valuesList.length <= 10) {
              switch (recipe.operator) {
                case propertyOperators.IN:
                  return {
                    or: valuesList.map(value => {
                      return {
                        [conditionNames.PROPERTIES]: {
                          property,
                          operator: propertyOperators.EQUALS,
                          value,
                        },
                      };
                    }),
                  };
                case propertyOperators.NOT_IN:
                  return {
                    and: valuesList.map(value => {
                      return {
                        [conditionNames.PROPERTIES]: {
                          property,
                          operator: propertyOperators.NOT_EQUALS,
                          value,
                        },
                      };
                    }),
                  };
                default:
                  break;
              }
            }
            return {
              [conditionNames.PROPERTIES]: {
                property,
                operator: recipe.operator,
                value: valuesList.join('\n'),
              },
            };
          }

          // this property works against a single value
          const { value } = recipe;

          if (
            recipe instanceof Object &&
            ('value' in recipe || 'valuesList' in recipe)
          ) {
            // handles 'modern' style rules... { properties: { numFlows: { operator: "==", value: 2 } }

            if (
              [propertyOperators.EXISTS, propertyOperators.NOT_EXISTS].includes(
                recipe.operator
              )
            ) {
              return {
                [conditionNames.PROPERTIES]: {
                  property,
                  operator: recipe.operator,
                },
              };
            }
            if (
              [
                propertyOperators.LESS_THAN_X_DAYS_AGO,
                propertyOperators.GREATER_THAN_X_DAYS_AGO,
                propertyOperators.WITHIN_X_DAYS_AGO,
              ].includes(recipe.operator)
            ) {
              return {
                [conditionNames.PROPERTIES]: {
                  property,
                  operator: recipe.operator,
                  value: recipe.value * millisecondsPerDay,
                },
              };
            }
            return {
              [conditionNames.PROPERTIES]: {
                property,
                operator: recipe.operator,
                value,
              },
            };
          }
          // Handles 'original' style rules... { properties: { numFlows: 2 } }

          return {
            [conditionNames.PROPERTIES]: {
              property,
              operator: propertyOperators.EQUALS,
              value: recipe,
            },
          };
        }),
      ];
    }

    masterClause = [...masterClause, { and: userConditions }];
  }

  // eslint-disable-next-line no-underscore-dangle
  if (rule.properties && rule.properties._ABGroup) {
    masterClause = [
      ...masterClause,
      {
        and: [
          {
            [conditionNames.PROPERTIES]: {
              property: '_ABGroup',
              operator: defaultOperators.EXACT,
              // eslint-disable-next-line no-underscore-dangle
              value: rule.properties._ABGroup.value,
            },
          },
        ],
      },
    ];
  }

  return {
    and: masterClause,
  };
}

/**
 * Returns a conditions object from a rule.  If `rule.conditions` already exists,
 * it will be returned.  Else, the rule will be migrated.
 *
 * @param rule
 * @returns {*}
 */

export function getNestedConditionsFromFirebaseRule(rule) {
  if (rule.conditions || rule.migratedAt) {
    return rule.conditions || {};
  }

  return convertRuleToNestedConditions(rule);
}

/**
 * Returns a conditions branch to replace on-board rule
 * frequency (once | every_time | never)
 *
 * @param stepId
 * @param frequency
 * @returns {{and: [{frequency: { ... }]}}
 */

export function getFrequencyConditions(stepId, frequency) {
  return {
    and: [
      (() => {
        switch (frequency) {
          case 'once':
            return {
              [conditionNames.FREQUENCY]: { flowId: stepId, frequency: 'once' },
            };
          case 'every_time':
            return {
              [conditionNames.FREQUENCY]: {
                flowId: stepId,
                frequency: 'every_time',
              },
            };
          case 'never':
          default:
            return {
              [conditionNames.FREQUENCY]: {
                flowId: stepId,
                frequency: 'never',
              },
            };
        }
      })(),
    ],
  };
}
