import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getNestedConditionsFromFlattenedClauses,
  clauseKeys,
} from '@appcues/libcues';

import { CPage, CButton, CBanner } from '@appcues/component-library';
import { DocumentTitle } from 'next/hooks/use-title';
import ChildPage from 'components/Common/ChildPage';
import CreateGoalPanel from 'components/Common/CreateGoalPanel';

import { setGoalCustomSegment } from 'helpers/goals';
import { selectAccountGoals } from 'reducers/account/goals';
import { create } from 'actions/account/goals';

export class GoalsCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      error: '',
      clauses: setGoalCustomSegment([]),
      isSaving: false,
    };
  }

  onClickSave = () => {
    // TODO: nearly identical behavior between this function and handleOK
    // in DefineGoalModal component. Should abstract, maybe through
    // composition?
    const { allGoals, onSave } = this.props;
    const { name, clauses } = this.state;

    if (name) {
      const matchingGoal = Object.values(allGoals || {}).find(goal => {
        return `${goal.name}`.toLowerCase() === name.toLowerCase();
      });

      if (matchingGoal) {
        this.setState({
          error: `A goal with the name "${name}" already exists.`,
        });
      } else {
        this.setState({ isSaving: true, error: '' });
        const nestedConditions = getNestedConditionsFromFlattenedClauses(
          clauses,
          null,
          clauseKeys
        );
        onSave(name, nestedConditions);
      }
    } else {
      this.setState({
        error: `Please enter a name for your new goal.`,
      });
    }
  };

  handleTitleChange = ({ target: { value } }) => {
    this.setState({ name: value });
  };

  handleClausesChange = clauses => {
    this.setState({ clauses });
  };

  render() {
    const { name, clauses, isSaving, error } = this.state;
    const { allGoals } = this.props;

    return (
      <ChildPage
        title="Define a New Goal"
        breadcrumbContents={{
          label: 'Goals',
          path: '/goals',
          child: {
            label: 'Create',
          },
        }}
        subHeaderActions={
          <CButton
            type="positive"
            onClick={this.onClickSave}
            isDisabled={isSaving}
          >
            {isSaving ? 'Saving...' : 'Create goal'}
          </CButton>
        }
      >
        <DocumentTitle title="Create Goal | Appcues" />
        <CPage.Container>
          {error && <CBanner type="warning" text={error} />}

          <CreateGoalPanel
            name={name}
            clauses={clauses}
            allGoals={allGoals}
            onNameChange={this.handleTitleChange}
            onClausesChange={this.handleClausesChange}
          />
          <CBanner
            text="Link your goal to a flow to start measuring it!"
            marginTop={24}
            paddingY={20}
          />
        </CPage.Container>
      </ChildPage>
    );
  }
}

const mapStateToProps = state => ({
  allGoals: selectAccountGoals(state),
});

const mapDispatchToProps = {
  onSave: (name, conditions) => create({ name, conditions }),
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalsCreate);
