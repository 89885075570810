import React from 'react';
import styled from 'styled-components';

const EmptyLaunchpadImage = () => (
  <svg
    width="160"
    height="160"
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M160 80C160 124.185 124.155 160 79.9313 160C35.7082 160 0 124.048 0 80C0 35.952 35.8455 0 80.0687 0C124.292 0 160 35.8148 160 80Z"
      fill="#F4FBFF"
    />
    <path
      d="M117.997 24.9269V22"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M124.438 27.562L126.487 25.5131"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M127.073 33.9995H130"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M124.438 40.1474L126.487 42.4889"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M117.997 42.7801V45.9997"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M111.855 40.1474L109.513 42.4889"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M109.22 33.9995H106"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M111.855 27.562L109.513 25.5131"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="40" y="32" width="80" height="96" rx="4" fill="white" />
    <path d="M41 33H119V35H41V33Z" fill="#D7D7FF" />
    <rect x="40.5" y="32.5" width="79" height="95" rx="3.5" stroke="#5C5CFF" />
    <path
      d="M86.8253 76.6919L76.8186 76.6919C76.8186 76.6919 74.317 83.0196 74.317 89.2002C74.317 93.9092 78.5845 103.769 80.6447 108.183C81.0862 109.214 82.4106 109.066 82.9992 108.183C85.0594 103.769 89.327 93.9092 89.327 89.2002C89.4741 82.8725 86.8253 76.6919 86.8253 76.6919Z"
      fill="#E9F7FF"
    />
    <path
      d="M86.8253 76.6919L76.8186 76.6919C76.8186 76.6919 74.317 83.0196 74.317 89.2002C74.317 93.9092 78.5845 103.769 80.6447 108.183C81.0862 109.214 82.4106 109.066 82.9992 108.183C85.0594 103.769 89.327 93.9092 89.327 89.2002C89.4741 82.8725 86.8253 76.6919 86.8253 76.6919Z"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M83.1461 88.595C83.1461 88.595 86.2364 96.983 82.999 103.164C82.4103 104.047 81.2331 104.047 80.6445 103.164C77.407 96.983 80.4973 88.595 80.4973 88.595L83.1461 88.595Z"
      fill="url(#paint0_linear_3306_80683)"
    />
    <path
      d="M83.1461 88.595C83.1461 88.595 86.2364 96.983 82.999 103.164C82.4103 104.047 81.2331 104.047 80.6445 103.164C77.407 96.983 80.4973 88.595 80.4973 88.595H83.1461Z"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.4804 52.441C73.3635 52.441 68.165 64.0298 68.165 67.4973V71.5123C68.165 73.4285 74.0931 74.9798 81.4804 74.9798C88.8676 74.9798 94.7957 73.4285 94.7957 71.5123V67.4973C94.7957 64.0298 89.5972 52.441 81.4804 52.441Z"
      fill="white"
    />
    <path
      d="M81.4804 52.441C73.3635 52.441 68.165 64.0298 68.165 67.4973V71.5123C68.165 73.4285 74.0931 74.9798 81.4804 74.9798C88.8676 74.9798 94.7957 73.4285 94.7957 71.5123V67.4973C94.7957 64.0298 89.5972 52.441 81.4804 52.441Z"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.709 80.0717C75.2835 80.0717 71.1682 89.0261 71.1682 91.7054V94.8077C71.1682 96.2883 75.861 97.4869 81.709 97.4869C87.557 97.4869 92.2498 96.2883 92.2498 94.8077V91.7054C92.2498 89.0261 88.1346 80.0717 81.709 80.0717Z"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.709 80.0717C75.2835 80.0717 71.1682 89.0261 71.1682 91.7054V94.8077C71.1682 96.2883 75.861 97.4869 81.709 97.4869C87.557 97.4869 92.2498 96.2883 92.2498 94.8077V91.7054C92.2498 89.0261 88.1346 80.0717 81.709 80.0717Z"
      fill="#D6DAFF"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.4804 78.4473C74.0931 78.4473 68.165 76.896 68.165 74.9798V88.8498C68.165 90.766 74.0931 92.3173 81.4804 92.3173C88.8676 92.3173 94.7957 90.766 94.7957 88.8498V74.9798C94.7957 76.896 88.8676 78.4473 81.4804 78.4473Z"
      fill="white"
    />
    <path
      d="M63.605 84.196C63.605 83.101 63.9698 82.006 64.7906 81.1848L68.2562 77.261V88.8498H65.3378C64.4258 88.8498 63.605 88.0285 63.605 87.116V84.196Z"
      fill="#D6DAFF"
    />
    <path
      d="M63.605 84.196C63.605 83.101 63.9698 82.006 64.7906 81.1848L68.2562 77.261V88.8498H65.3378C64.4258 88.8498 63.605 88.0285 63.605 87.116V84.196Z"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M99.4471 84.196C99.4471 83.101 99.0823 82.006 98.2615 81.1848L94.7959 77.261V88.8498H97.7143C98.6263 88.8498 99.4471 88.0285 99.4471 87.116V84.196Z"
      fill="#D6DAFF"
    />
    <path
      d="M99.4471 84.196C99.4471 83.101 99.0823 82.006 98.2615 81.1848L94.7959 77.261V88.8498H97.7143C98.6263 88.8498 99.4471 88.0285 99.4471 87.116V84.196Z"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.4804 78.4473C74.0931 78.4473 68.165 76.896 68.165 74.9798V88.8498C68.165 90.766 74.0931 92.3173 81.4804 92.3173C88.8676 92.3173 94.7957 90.766 94.7957 88.8498V74.9798C94.7957 76.896 88.8676 78.4473 81.4804 78.4473Z"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
    />
    <path
      d="M107.633 93.7525C107.633 92.2925 105.992 90.7413 104.624 90.7413C106.083 90.7413 107.633 89.19 107.633 87.73C107.633 89.19 109.275 90.7413 110.643 90.7413C109.275 90.7413 107.633 92.2925 107.633 93.7525Z"
      fill="#D158BD"
    />
    <path
      d="M107.633 93.7525C107.633 92.2925 105.992 90.7413 104.624 90.7413C106.083 90.7413 107.633 89.19 107.633 87.73C107.633 89.19 109.275 90.7413 110.643 90.7413C109.275 90.7413 107.633 92.2925 107.633 93.7525Z"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.0652 57.4828C64.0652 55.8163 62.1903 54.0456 60.6279 54.0456C62.2945 54.0456 64.0652 52.1708 64.0652 50.6084C64.0652 52.2749 65.8358 54.0456 67.5024 54.0456C65.8358 54.0456 64.0652 55.8163 64.0652 57.4828Z"
      fill="url(#paint1_linear_3306_80683)"
    />
    <path
      d="M64.0652 57.4828C64.0652 55.8163 62.1903 54.0456 60.6279 54.0456C62.2945 54.0456 64.0652 52.1708 64.0652 50.6084C64.0652 52.2749 65.8358 54.0456 67.5024 54.0456C65.8358 54.0456 64.0652 55.8163 64.0652 57.4828Z"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M104.884 63.5041C104.884 62.0441 103.242 60.4929 101.874 60.4929C103.334 60.4929 104.884 58.8504 104.884 57.4816C104.884 58.9416 106.434 60.4929 107.894 60.4929C106.434 60.4929 104.884 62.1354 104.884 63.5041Z"
      fill="white"
    />
    <path
      d="M104.884 63.5041C104.884 62.0441 103.242 60.4929 101.874 60.4929C103.334 60.4929 104.884 58.8504 104.884 57.4816C104.884 58.9416 106.434 60.4929 107.894 60.4929C106.434 60.4929 104.884 62.1354 104.884 63.5041Z"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.6663 62.5707C82.6663 63.6657 82.1192 64.5782 81.4808 64.5782C80.8424 64.5782 80.2952 63.6657 80.2952 62.5707C80.2952 61.4757 80.8424 60.5632 81.4808 60.5632C82.1192 60.5632 82.6663 61.3845 82.6663 62.5707Z"
      fill="#D6DAFF"
    />
    <path
      d="M52.18 75.4208C52.18 73.9608 50.6296 72.4095 49.1704 72.4095C50.6296 72.4095 52.18 70.8583 52.18 69.3983C52.18 70.8583 53.8217 72.4095 55.1897 72.4095C53.8217 72.4095 52.18 74.052 52.18 75.4208Z"
      fill="white"
    />
    <path
      d="M52.18 75.4208C52.18 73.9608 50.6296 72.4095 49.1704 72.4095C50.6296 72.4095 52.18 70.8583 52.18 69.3983C52.18 70.8583 53.8217 72.4095 55.1897 72.4095C53.8217 72.4095 52.18 74.052 52.18 75.4208Z"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.3729 62.8442C76.1904 63.9392 75.4608 64.7605 74.8224 64.578C74.184 64.4867 73.9104 63.483 74.0928 62.388C74.2752 61.293 75.0048 60.4717 75.6432 60.6542C76.1904 60.7455 76.5553 61.7492 76.3729 62.8442Z"
      fill="#D6DAFF"
    />
    <path
      d="M82.6663 62.5707C82.6663 63.6657 82.1192 64.5782 81.4808 64.5782C80.8424 64.5782 80.2952 63.6657 80.2952 62.5707C80.2952 61.4757 80.8424 60.5632 81.4808 60.5632C82.1192 60.5632 82.6663 61.3845 82.6663 62.5707Z"
      fill="#D6DAFF"
    />
    <path
      d="M82.6663 62.5707C82.6663 63.6657 82.1192 64.5782 81.4808 64.5782C80.8424 64.5782 80.2952 63.6657 80.2952 62.5707C80.2952 61.4757 80.8424 60.5632 81.4808 60.5632C82.1192 60.5632 82.6663 61.3845 82.6663 62.5707Z"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.3729 62.8442C76.1904 63.9392 75.4608 64.7605 74.8224 64.578C74.184 64.4867 73.9104 63.483 74.0928 62.388C74.2752 61.293 75.0048 60.4717 75.6432 60.6542C76.1904 60.7455 76.5553 61.7492 76.3729 62.8442Z"
      fill="#D6DAFF"
    />
    <path
      d="M76.3729 62.8442C76.1904 63.9392 75.4608 64.7605 74.8224 64.578C74.184 64.4867 73.9104 63.483 74.0928 62.388C74.2752 61.293 75.0048 60.4717 75.6432 60.6542C76.1904 60.7455 76.5553 61.7492 76.3729 62.8442Z"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M86.6788 62.8442C86.8612 63.9392 87.5908 64.7605 88.2292 64.578C88.8676 64.4867 89.1412 63.483 88.9588 62.388C88.7764 61.293 88.0468 60.4717 87.4084 60.6542C86.77 60.7455 86.4964 61.7492 86.6788 62.8442Z"
      fill="#D6DAFF"
    />
    <path
      d="M86.6788 62.8442C86.8612 63.9392 87.5908 64.7605 88.2292 64.578C88.8676 64.4867 89.1412 63.483 88.9588 62.388C88.7764 61.293 88.0468 60.4717 87.4084 60.6542C86.77 60.7455 86.4964 61.7492 86.6788 62.8442Z"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M68.2563 71.5123C68.2563 73.4285 74.1844 74.9798 81.5717 74.9798C88.959 74.9798 94.887 73.4285 94.887 71.5123V74.9798C94.887 76.896 88.959 78.4473 81.5717 78.4473C74.1844 78.4473 68.2563 76.896 68.2563 74.9798V71.5123Z"
      fill="#D6DAFF"
    />
    <path
      d="M68.2563 71.5123C68.2563 73.4285 74.1844 74.9798 81.5717 74.9798C88.9589 74.9798 94.887 73.4285 94.887 71.5123V74.9798C94.887 76.896 88.9589 78.4473 81.5717 78.4473C74.1844 78.4473 68.2563 76.896 68.2563 74.9798V71.5123Z"
      stroke="#5C5CFF"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79 142C98.8823 142 115 140.657 115 139C115 137.343 98.8823 136 79 136C59.1178 136 43 137.343 43 139C43 140.657 59.1178 142 79 142Z"
      fill="#2CB4FF"
      fillOpacity="0.2"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3306_80683"
        x1="77.5428"
        y1="100.107"
        x2="86.1006"
        y2="91.5495"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5C5CFF" />
        <stop offset="1" stopColor="#8960FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3306_80683"
        x1="60.6279"
        y1="54.0456"
        x2="67.5024"
        y2="54.0456"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5290" />
        <stop offset="0.0001" stopColor="#FF5290" />
        <stop offset="1" stopColor="#8960FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default styled(EmptyLaunchpadImage)``;
