import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  state: PropTypes.oneOf([
    'archived',
    'ended',
    'live',
    'not_started',
    'paused',
  ]),
});
