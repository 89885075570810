import React from 'react';
import styled from 'styled-components';
import { EmptyState } from '@studio/legacy-components';
import EmptyBannersImage from './EmptyBannersImage';
import CreateBannerManager from './CreateBannerManager';

const Wrapper = styled.div`
  max-width: 620px;
  margin: 48px auto;
`;

const Empty = () => (
  <Wrapper>
    <EmptyState
      action={<CreateBannerManager />}
      image={<EmptyBannersImage />}
      headline="Build a Banner"
    >
      Use Banners to create in-line announcements, updates or promotions that
      get noticed.
    </EmptyState>
  </Wrapper>
);

export default Empty;
