import { local } from 'next/lib/storage';
import { enabled } from './enabled';
import { FEATURES_KEY } from './keys';

/**
 * Enhance bootstrapping payload with flag overrides
 *
 * @param {object} data - Bootstrapping payload
 * @param {Features} data.features - Account feature flags
 * @param {boolean} data.spoofing - Whether spoofing
 * @param {User} data.user - User meta
 * @return {Redux.Store} Configured Redux store
 */
export const enhance = data => {
  const { features, spoofing, user } = data;

  // If DevTools are disabled, do not enhance and passthrough payload
  if (!enabled(user, spoofing)) {
    return data;
  }

  // Check local storage for any feature flag overrides
  const overrides = local.get(FEATURES_KEY, {});

  // Merge overrides into bootstrapping payload
  return { ...data, features: { ...features, ...overrides } };
};
