import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  ButtonGroup,
  Description,
  Heading,
  Modal,
} from '@studio/legacy-components';
import { AppShape, readApp, reset, update } from 'next/entities/apps';
import { asRequest } from 'next/entities/requests';
import { ModalNegativeNotice } from './styled';
import { APP_ENABLED, APP_DISABLED } from './lib';

const DisableEnableAppModal = ({
  appId,
  appStatus = {},
  onAction,
  onClose,
  onOpen,
  state,
  visible,
}) => {
  const [inFlight, setInFlight] = useState(false);
  const { loading, error } = appStatus;

  useEffect(() => {
    if (visible) {
      onOpen?.(appId);
    }
  }, [visible, onOpen, appId]);

  useEffect(() => {
    if (visible && inFlight && loading === false && !error) {
      onClose();
      setInFlight(false);
    }
  }, [loading, error, inFlight, onClose, visible]);

  const handleAction = actionState => {
    setInFlight(true);
    onAction(appId, { state: actionState });
  };

  const handleDisableClick = () => {
    handleAction(APP_DISABLED);
  };
  const hanldeEnableClick = () => {
    handleAction(APP_ENABLED);
  };

  const isAppEnabled = state === APP_ENABLED;

  return (
    <Modal onClose={onClose} visible={visible}>
      <Heading>{isAppEnabled ? 'Disable' : 'Enable'} app</Heading>
      {error && (
        <ModalNegativeNotice>
          We&apos;re sorry, something went wrong on our end. Try again.
        </ModalNegativeNotice>
      )}
      <Description>
        {isAppEnabled
          ? "Disabling this app will hide any associated Flows and stop processing any app events and data. You will still be able to explore this app's historical events."
          : "Enabling will restore this app's Flows, and resume processing any new app events and data."}
      </Description>
      <ButtonGroup right>
        <Button
          disabeld={loading}
          kind={isAppEnabled ? 'negative' : 'positive'}
          onClick={isAppEnabled ? handleDisableClick : hanldeEnableClick}
        >
          {isAppEnabled ? 'Disable' : 'Enable'}
        </Button>
        <Button kind="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ButtonGroup>
    </Modal>
  );
};

DisableEnableAppModal.propTypes = {
  appId: PropTypes.string,
  appStatus: asRequest(PropTypes.objectOf(AppShape)),
  onAction: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  state: PropTypes.string,
  visible: PropTypes.bool,
};

const mapStateToProps = (state, { appId }) => ({
  appStatus: readApp(state, appId),
});

const mapDispatchToProps = {
  onAction: update,
  onOpen: reset,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisableEnableAppModal);
