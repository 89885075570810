import { call, put, getContext } from 'redux-saga/effects';
import { reportError } from 'helpers/error-reporting';
import { resolve } from './actions';

export function* getAllEventNames() {
  try {
    const api = yield getContext('endUserApi');
    const { data } = yield call(api.getEventNames);
    yield put(resolve(data));
  } catch (error) {
    yield call(reportError, error);
  }
}
