import {
  STEP_CATEGORY_IDS,
  STEP_TYPE_IDS,
  STEP_TYPES,
  isRedirectStep,
} from 'constants/stepTypes';

import { reportError } from 'helpers/error-reporting';

/**
 * Given a stepId and a complete set of flows & steps, returns
 * all stepChildren for all steps of that flow.
 *
 * @param stepId
 * @param steps
 * @returns {*}
 */

export const getStepChildren = (stepId, steps) => {
  const childSteps = Object.values(steps).filter(step => {
    return (
      step.parentId === stepId &&
      STEP_TYPES[step.type].category === STEP_CATEGORY_IDS.CONTENT
    );
  });

  return childSteps.reduce((prev, stepChild) => {
    return [
      ...prev,
      ...Object.values(stepChild[STEP_TYPES[stepChild.type].childrenKey]),
    ];
  }, []);
};

// Ported and updated from crx-ui
// The preview URL comes from a redirect or the journey object,
// whichever comes first traversing backwards.
export const findPreviewUrlSourceStep = ({
  stepGroup,
  stepChildId,
  allSteps,
}) => {
  const journeySteps = stepGroup.steps || {};
  const journeyStepValues = Object.values(journeySteps);
  let currentStepIndex = journeySteps[stepChildId]
    ? journeySteps[stepChildId].index
    : -1;

  while (currentStepIndex > -1) {
    const stepInJourney = journeyStepValues.find(
      // FIXME: Consider using a different loop pattern to prevent referencing
      //        the loop condition/variable in a predicate
      // eslint-disable-next-line no-loop-func
      step => step.index === currentStepIndex
    );
    const step = allSteps[stepInJourney.id];

    if (isRedirectStep(step)) {
      return step;
    }
    currentStepIndex -= 1;
  }
  return stepGroup;
};

export const findPreviewUrlFromPreviousSteps = ({
  stepGroup,
  stepChildId,
  allSteps,
}) => {
  const source = findPreviewUrlSourceStep({ stepGroup, stepChildId, allSteps });
  if (source.stepType && source.stepType === STEP_CATEGORY_IDS.ACTION) {
    return source.previewUrl || source.params.url;
  }
  return source.previewUrl;
};

const isStepGroupUrl = (stepChildUrl, stepGroup) => {
  return stepChildUrl === stepGroup.previewUrl;
};

const REMOVE_TRAILING_SLASH_ERROR =
  'You must pass in a string to remove a trailing slash';

const removeTrailingSlash = value => {
  if (!value) {
    reportError(REMOVE_TRAILING_SLASH_ERROR, { value });
  }

  if (typeof value !== 'string' && !(value instanceof String)) {
    reportError(REMOVE_TRAILING_SLASH_ERROR, { value });
  }

  if (value[0] !== '/') {
    return value;
  }

  return value.slice(1);
};

export const buildDeepLinkToStepChild = (stepGroup, stepChildId, allSteps) => {
  const stepChildUrlPath = findPreviewUrlFromPreviousSteps({
    stepGroup,
    stepChildId,
    allSteps,
  });

  if (isStepGroupUrl(stepChildUrlPath, stepGroup)) {
    return stepChildUrlPath;
  }

  const stepGroupUrl = new URL(stepGroup.previewUrl);
  const stepGroupUrlWithoutSlash = removeTrailingSlash(stepChildUrlPath);

  return `${stepGroupUrl.origin}/${stepGroupUrlWithoutSlash}`;
};

export const getFirstStep = stepGroup => {
  if (!stepGroup || !stepGroup.steps) {
    return null;
  }

  return Object.values(stepGroup.steps).find(step => step.index === 0);
};

export const getFirstStepInFlow = (flow, steps) => {
  if (flow.type === STEP_TYPE_IDS.JOURNEY) {
    const firstStep = getFirstStep(flow);
    return firstStep && steps[firstStep.id];
  }
  return flow;
};

export const getFirstStepId = stepGroup => {
  const firstStep = getFirstStep(stepGroup);
  if (!firstStep) {
    reportError('No first step found.');
    return null;
  }

  return firstStep.id;
};
export const getSortedSteps = steps => {
  return steps.sort(
    (stepChildA, stepChildB) => stepChildA.index - stepChildB.index
  );
};

export const getSortedStepsById = steps => {
  const stepsList = Object.values(steps || {});
  return getSortedSteps(stepsList).reduce((acc, step) => {
    acc[step.id] = step;
    return acc;
  }, {});
};

export function isJourney(step) {
  return step && step.type && step.type === STEP_TYPE_IDS.JOURNEY;
}

export function getJourneySteps(steps) {
  return Object.values(steps).filter(step => !step.parentId);
}

export function getJourneyStepsMap(steps) {
  return getJourneySteps(steps).reduce((stepsMap, step) => {
    return {
      ...stepsMap,
      [step.id]: step,
    };
  }, {});
}

export function getStepTypeMetadata(step) {
  if (!step || (!step.type && !step.stepType)) {
    return {};
  }

  if (step.type === 'action' || step.stepType === 'action') {
    return STEP_TYPES[step.actionType];
  }

  return STEP_TYPES[step.type || step.stepType];
}

export function getNumOfSteps(topLevelStep) {
  const stepChildType = (getStepTypeMetadata(topLevelStep) || {}).childrenKey;
  const children = Object.keys(topLevelStep[stepChildType] || {});
  return children.length;
}

export function isPublished(step) {
  return !!step && step.published;
}

export function hasNeverBeenPublished(step) {
  return !isPublished(step) && !step.unpublishedAt;
}

export function getVersionIdFromStep(step) {
  if (!step) {
    return null;
  }
  return isPublished(step) ? step.updatedAt : null;
}
