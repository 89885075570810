import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { CPanel, CButton, H4, P } from '@appcues/component-library';
import { bindActionCreators } from 'redux';

import { showModal } from 'actions/currentModal';
import { FIRST_CANCELLATION_MODAL } from 'constants/globals';

function ManageSubscription({ actions, billingDetails }) {
  const handleCancellation = () => {
    actions.showModal(FIRST_CANCELLATION_MODAL);
  };

  const firstSubscription =
    billingDetails &&
    billingDetails.subscriptions &&
    billingDetails.subscriptions.data &&
    billingDetails.subscriptions.data[0];
  const plan = firstSubscription && firstSubscription.plan;
  const formattedDate =
    plan &&
    moment(firstSubscription.current_period_end * 1000).format('MMMM D, YYYY');

  const formattedDateDisplay = formattedDate ? `: ${formattedDate}` : '';

  // This sadly joins the list of things waiting for autocancellation
  // accountCanceled at can be grabbed from props
  // const cancelDate =
  // accountCanceledAt && moment(accountCanceledAt * 1000).format("MMM D, YYYY");
  // const cancelDateDisplay = cancelDate ? ` on ${cancelDate}` : "";

  return (
    <CPanel alignItems="flex-start">
      <CPanel.Header>
        <H4 type="secondary">Cancellation</H4>
        <CPanel.Header.Actions>
          <CButton type="negative" onClick={handleCancellation}>
            Cancel subscription
          </CButton>
        </CPanel.Header.Actions>
      </CPanel.Header>
      <P width="55%">
        If you cancel your subscription, you will have access to your account
        until the end of your billing period{formattedDateDisplay}.
      </P>
    </CPanel>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        showModal,
      },
      dispatch
    ),
  };
};

export default connect(null, mapDispatchToProps)(ManageSubscription);
