import { isAfter, parse, isBefore, format } from 'next/lib/date';

const hasErrors = fieldErrors =>
  Object.values(fieldErrors).find(error => typeof error === 'string') ?? true;

export const getValidateStartDate = (getValues, published) => startDate => {
  const { startTime, startOption } = getValues();

  if (startOption === 'publish' || published) return true;

  const startDateValue = parse(
    `${format(startDate, 'MM-DD-YYYY')} ${startTime}`
  );

  if (!startDate || !startTime) {
    return 'Date time is required';
  }

  return isBefore(startDateValue, new Date())
    ? 'Start date time should be after current date time'
    : true;
};

export const getValidateEndDate = getValues => endDate => {
  const { startTime, startDate, endTime, endOption } = getValues();
  if (endOption === 'unpublish') return true;

  const fieldErrors = {
    bothDatesAreValid: true,
    endDateIsValid: true,
    dateIsRequired: true,
  };

  const startDateValue = parse(
    `${format(startDate, 'MM-DD-YYYY')} ${startTime}`
  );
  const endDateValue = parse(`${format(endDate, 'MM-DD-YYYY')} ${endTime}`);

  if (!endDate || !endTime) {
    fieldErrors.dateIsRequired = 'Date time is required';
  }

  if (startDate && endDate) {
    fieldErrors.bothDatesAreValid = isBefore(endDateValue, startDateValue)
      ? 'End date time should be after start date time'
      : true;
  }

  if (endDate) {
    fieldErrors.endDateIsValid = isBefore(endDateValue, new Date())
      ? 'End date time should be after current date time'
      : true;
  }

  return hasErrors(fieldErrors);
};

export const shouldRenderScheduleStatus = (schedule, state) =>
  schedule &&
  state === 'DRAFT' &&
  (isAfter(schedule.startDate, new Date()) ||
    isAfter(schedule.endDate, new Date()));
