import {
  call,
  put,
  takeEvery,
  takeLeading,
  getContext,
} from 'redux-saga/effects';
import toast from 'next/lib/toast';
import {
  send,
  resolve,
  reject,
  insert,
  remove,
  patterns,
  LOCALE_SAVED,
  LOCALE_DELETED,
} from 'actions/account/locales';
import { promisaga } from 'utils/as-promised';
import { reportError } from 'helpers/error-reporting';

export function* fetchLocales() {
  try {
    yield put(send());
    const api = yield getContext('api');
    const { data } = yield call(api.getAllLocales);
    const result = data.reduce((acc, locale) => {
      acc[locale.id] = locale;
      return acc;
    }, {});
    yield put(resolve(result));
  } catch (error) {
    yield put(reject(error));
    yield call(reportError, error);
  }
}

export function* createLocale({ payload }) {
  try {
    const api = yield getContext('api');
    const { data } = yield call(api.createLocale, payload);
    yield put(insert(data));
  } catch (error) {
    yield call(reportError, error);
  }
}

export function* deleteLocale({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    yield call(api.deleteLocale, id);
    yield put(remove(id));
  } catch (error) {
    if (error.response.status === 409) {
      throw error;
    }
    yield call(reportError, error);
    yield call(toast.error, 'Failed to delete locale');
  }
}

export default function* saga() {
  yield takeEvery(patterns.callApi, fetchLocales);
  yield takeLeading(LOCALE_SAVED, promisaga(createLocale));
  yield takeEvery(LOCALE_DELETED, promisaga(deleteLocale));
}
