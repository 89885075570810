import React from 'react';
import styled, { css } from 'styled-components';
import {
  Caption,
  Icon,
  Input,
  Modal,
  ModalFooter,
  SearchInput,
  Select,
  Spinner,
  SuccessNotice,
  easing,
} from '@studio/legacy-components';

const centered = css`
  align-items: center;
  display: inline-flex;
  justify-content: center;
`;

const ellipsis = css`
  overflow: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const BaseButtonTag = styled.button`
  ${centered}

  background: var(--noreaster);
  border: none;
  border-bottom-right-radius: var(--tag-border-radius);
  border-top-right-radius: var(--tag-border-radius);
  height: var(--tag-height);
  padding: 8px 12px 8px 8px;
  transition: ${easing('background')};
`;

export const Bold = styled.span`
  font-weight: var(--bold);
`;

/**
 * Tag
 */
export const Wrapper = styled.span`
  --tag-height: 24px;
  --tag-border-radius: calc(var(--tag-height) / 2);

  display: inline-flex;
  height: var(--tag-height);
`;

export const TruncatedName = styled.span`
  ${ellipsis}
`;

export const Name = styled(TruncatedName)`
  align-items: center;
  background: var(--noreaster);
  border-radius: var(--tag-border-radius);
  color: var(--pleather);
  cursor: default;
  display: flex;
  font-size: var(--x-small);
  height: var(--tag-height);
  line-height: 1.2;
  max-width: 160px;
  padding: 0 12px;
`;

export const CreateTag = styled(Name).attrs({
  as: 'button',
})`
  background: var(--luna);
  border: none;
  color: var(--blurple);
  cursor: pointer;
  font-weight: var(--bold);
  transition: ${easing('background')};

  :hover {
    background: var(--lil-guest-bathroom-soaps);
  }

  ${Icon} {
    font-size: var(--xx-small);
    margin-right: 4px;
  }
`;

export const Editable = styled.div`
  ${centered}

  ${Name} {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding-right: 8px;
  }
`;

export const Edit = styled(BaseButtonTag)`
  border-left: 1px solid var(--myst);

  :hover {
    background: var(--myst);
  }

  ${Icon} {
    color: var(--sharkbait-ooh-la-la);
    font-size: var(--xx-small);
  }
`;

/**
 * Editing
 */
export const Action = styled.button`
  ${centered}

  background: red;
  border: none;
  border-radius: 50%;
  font-size: var(--xx-small);
  height: 20px;
  width: 20px;

  & + & {
    margin-left: 4px;
  }

  ${({ kind }) => {
    switch (kind) {
      case 'confirm':
        return `
          background: var(--lisa-frank);
          color: var(--sea-monster);
        `;
      case 'close':
        return `
          background: var(--myst);
          color: var(--dark-resting);
        `;
      case 'delete':
        return `
          background: var(--bubble-tape);
          color: var(--motorola-razr);
        `;
      default:
        return '';
    }
  }}
`;

export const Control = styled.form`
  ${centered}
`;

export const NameInput = styled(Input)`
  font-size: var(--x-small);
  height: var(--tag-height);
  margin-right: 8px;
  padding: 8px 12px;
`;

/**
 * Modal
 */
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 160px;

  > ${Caption} {
    font-size: var(--regular);
    max-width: 100%;
  }
`;

export const Loading = styled.div.attrs(() => ({
  children: <Spinner />,
}))`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

export const Search = styled(SearchInput)`
  width: 100%;

  ${Input} {
    min-width: 100%;
  }
`;

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  max-height: 200px;
  overflow-y: auto;
  padding: 0 4px;
`;

export const Footer = styled(ModalFooter)`
  align-items: center;
`;

/**
 * Validation
 */

export const MsgWrap = styled.span`
  overflow: clip;
  white-space: pre-wrap;

  ${TruncatedName} {
    display: inline-block;
    max-width: calc(100% - 10px);
    vertical-align: bottom;
  }
`;

export const SuccessNoticeWithIcon = styled(SuccessNotice)`
  > div {
    align-items: center;
    display: flex;
    max-width: 100%; // prevent overflow
  }

  ${Icon} {
    color: var(--bulbasaur);
    margin-right: 12px;
  }
`;

/**
 * Experience Tag
 */

export const Remove = styled(BaseButtonTag)`
  ${Icon} {
    color: var(--sharkbait-ooh-la-la);
    font-size: var(--regular);
  }
`;

export const EditTagsModalWrapper = styled(Modal)`
  ${Select} {
    max-width: 100%;
    margin-bottom: 12px;
  }
`;
