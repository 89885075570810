import styled from 'styled-components';
import { Button } from '@appcues/sonar';

export const SignOutButtonWrapper = styled.div`
  position: fixed;
  bottom: var(--spacing-large);
  right: var(--spacing-large);

  ${({ isDevToolsEnabled }) =>
    isDevToolsEnabled &&
    `right: var(--spacing-x-x-x-large);
      padding-right: var(--spacing-regular);`};
`;

export const SignOutButton = styled(Button).attrs({
  variant: 'destructive',
})``;

export const Emoji = styled.span``;
