export function sortResponses(sortConfig) {
  return (a, b) => {
    const valueA = `${a[sortConfig?.column]}`;
    const valueB = `${b[sortConfig?.column]}`;

    if (sortConfig?.column === 'timestamp') {
      if (sortConfig?.direction === 'ASC') {
        return new Date(valueA).getTime() - new Date(valueB).getTime();
      }
      return new Date(valueB).getTime() - new Date(valueA).getTime();
    }

    if (sortConfig?.direction === 'ASC') {
      return valueA.localeCompare(valueB, undefined, {
        numeric: true,
      });
    }
    return valueB.localeCompare(valueA, undefined, {
      numeric: true,
    });
  };
}
