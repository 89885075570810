import { createSelector } from 'reselect';
import {
  LIMITED_RANGE_OPTIONS,
  FULL_RANGE_OPTIONS,
} from '@studio/legacy-components';
import { selectAccountMeta } from 'reducers/account/meta';
import { selectAccountFeature } from 'reducers/account/features';
import { NPS_RANGE_SM, NPS_RANGE_MED, NPS_RANGE_LG } from 'constants/features';

/**
 * FIXME: The following selectors also exist in `next/lib/selector-options` but
 *        reference the next Redux store. Once these selectors are only used
 *        components connected to the `next` store, we can safely remove this
 *        file.
 */

/**
 * Select range options type based on account gates
 *
 * @return {string|null} Range type or null
 */
export const selectDateRangeType = createSelector(
  state => selectAccountFeature(state, NPS_RANGE_SM),
  state => selectAccountFeature(state, NPS_RANGE_MED),
  state => selectAccountFeature(state, NPS_RANGE_LG),
  (small, medium, large) => {
    switch (true) {
      case large:
        return 'large';
      case medium:
        return 'medium';
      case small:
        return 'small';
      default:
        return null;
    }
  }
);

/**
 * Select how many days back a customer can view event data historically
 *
 * @return {number} Number of days from today
 */
export const selectHistoricalLimit = createSelector(
  selectDateRangeType,
  type => {
    switch (type) {
      case 'large':
        return 30 * 36;
      case 'medium':
        return 30 * 24;
      case 'small':
        return 30 * 9;
      default:
        return 30;
    }
  }
);

/**
 * Select how many days back a customer can view flow data historically
 *
 * @return {number} Number of days from today
 */
export const selectFlowHistoricalLimit = createSelector(
  selectDateRangeType,
  selectHistoricalLimit,
  (type, limit) => {
    switch (type) {
      case 'large':
      case 'medium':
        return limit;

      case 'small':
      default:
        return 30 * 12;
    }
  }
);

/**
 * Select the valid date range options
 *
 * @return {Option[]} Valid date range options
 */
export const selectDateRangeOptions = createSelector(
  selectAccountMeta,
  selectDateRangeType,
  ({ isTrial }, type) =>
    isTrial || type === null ? LIMITED_RANGE_OPTIONS : FULL_RANGE_OPTIONS
);
