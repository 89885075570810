import React from 'react';
import moment from 'moment';
import { CBanner } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';

const CanceledNotice = props => {
  const { billingDetails, canceledAt } = props;

  const firstSubscription =
    billingDetails &&
    billingDetails.subscriptions &&
    billingDetails.subscriptions.data &&
    billingDetails.subscriptions.data[0];

  const plan = firstSubscription && firstSubscription.plan;
  const formattedDate =
    plan &&
    moment(firstSubscription.current_period_end * 1000).format('MMMM D, YYYY');

  const formattedDateDisplay = formattedDate
    ? `${formattedDate}`
    : 'the end of your billing period';

  const formattedCanceledAt = moment(canceledAt).format('MMMM D, YYYY');

  return (
    <CBanner type="negative">
      <CBanner.Content>
        <Icon icon="exclamation-circle" color="$red" paddingRight={12} /> {` `}
        Your subscription was cancelled <strong>{formattedCanceledAt}</strong>.
        You will have full access to your account until{' '}
        <strong>{formattedDateDisplay}</strong>.
      </CBanner.Content>
    </CBanner>
  );
};

export default CanceledNotice;
