import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Badge, Text, Tooltip } from '@appcues/sonar';
import { faWarning } from '@fortawesome/pro-regular-svg-icons/faWarning';
import { Link } from '@studio/legacy-components';

const Content = styled(Text).attrs({
  size: 'small',
  lineHeight: 'large',
  colorToken: 'foreground-tooltip',
})`
  ${Link} {
    color: var(--foreground-link);

    &:active {
      color: var(--foreground-link-active);
    }
  }
`;

function AnalyticsMetricTooltip({ children }) {
  return (
    <Tooltip placement="top" content={<Content>{children}</Content>}>
      <Badge variant="attention" icon={faWarning} />
    </Tooltip>
  );
}

AnalyticsMetricTooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AnalyticsMetricTooltip;
