import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Link, Td, Tr } from '@studio/legacy-components';
import EventToggle from 'components/Insights/Explorer/Common/EventToggle';
import { SelectColumn, NameColumn } from 'components/Insights/Common/Styled';

const ApplicationEventRow = ({
  name,
  events,
  users,
  timestamp,
  disabled = false,
  checked = false,
  onSelect = () => null,
}) => {
  const { search } = useLocation();

  return (
    <Tr>
      <SelectColumn>
        <EventToggle
          label={`Select application event: ${name}`}
          checked={checked}
          disabled={disabled}
          onClick={onSelect}
        />
      </SelectColumn>
      <NameColumn>
        {name ? (
          <Link
            to={{
              pathname: `/insights/explorer/application/${encodeURI(name)}`,
              search,
            }}
            title={`${name} detail page`}
          >
            {name}
          </Link>
        ) : (
          '-'
        )}
      </NameColumn>
      <Td>{events ? events.toLocaleString() : '-'}</Td>
      <Td>{users ? users.toLocaleString() : '-'}</Td>
      <Td>{timestamp ? moment(timestamp).format('lll') : '-'}</Td>
    </Tr>
  );
};

ApplicationEventRow.propTypes = {
  name: PropTypes.string,
  events: PropTypes.number,
  users: PropTypes.number,
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default ApplicationEventRow;
