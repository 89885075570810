import {
  FETCH_USER_PROFILE,
  SET_USER_PROFILE_FETCHING,
  SET_USER_PROFILE_SUCCESS,
  SET_USER_PROFILE_ERRORED,
} from 'constants/actionTypes';

export const setUserProfileFetching = userId => ({
  type: SET_USER_PROFILE_FETCHING,
  payload: { userId },
});

export const setUserProfileSuccess = (userId, result) => ({
  type: SET_USER_PROFILE_SUCCESS,
  payload: {
    userId,
    result,
  },
});

export const setUserProfileErrored = (userId, error) => ({
  type: SET_USER_PROFILE_ERRORED,
  payload: { userId, error },
});

export const fetchUserProfile = userId => ({
  type: FETCH_USER_PROFILE,
  payload: { userId },
});
