import { propertyOperators } from '@appcues/libcues';
import { createLifecycleFor } from 'actions/account/collections';

const TYPE = 'locales';

const actions = createLifecycleFor(TYPE);

export const LOCALE_SAVED = 'LOCALE_SAVED';
export const saveLocale = ({ name, property, value }) => {
  const locale = {
    name,
    conditions: {
      and: [
        {
          properties: {
            property,
            operator: propertyOperators.IN,
            value,
          },
        },
      ],
    },
  };

  return {
    type: LOCALE_SAVED,
    payload: locale,
  };
};

export const LOCALE_DELETED = 'LOCALE_DELETED';
export const deleteLocale = id => {
  return {
    type: LOCALE_DELETED,
    payload: { id },
  };
};

export const {
  send,
  resolve,
  reject,
  update,
  replace,
  create,
  flush,
  remove,
  insert,
  patterns,
} = actions;
