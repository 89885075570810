import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  readOneStepChild,
  selectScreenshot,
  getImageHashKeys,
  Shape as ScreenshotShape,
} from 'next/entities/screenshots';

import { Screenshot } from './Screenshot';

const mapStateToProps = (state, { step, locale, size, version }) => {
  const imageHash = getImageHashKeys({ step, locale, size, version });
  const { url } = selectScreenshot(state, { hash: imageHash }) ?? {};
  return { url, imageHash };
};

const mapDispatchToProps = {
  onLoad: readOneStepChild,
};

const ConnectedScreenshot = connect(
  mapStateToProps,
  mapDispatchToProps
)(Screenshot);

ConnectedScreenshot.propTypes = {
  step: ScreenshotShape,
  force: PropTypes.bool,
  locale: PropTypes.string,
  size: PropTypes.string,
  version: PropTypes.number,
};

export default ConnectedScreenshot;
