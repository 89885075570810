import React from 'react';
import PropTypes from 'prop-types';

import {
  ButtonGroup,
  Button,
  Link,
  Icon,
  MoreMenu,
  ListRow,
  MenuSection,
} from '@studio/legacy-components';
import ExportModalManager from 'next/components/ExportModalManager';

export default function AnalyticsHeaderActions({
  id,
  onClickOpenInBuilder,
  showExport,
  createdAt,
  type = 'pins',
}) {
  const settingsPath = `/${type}/${id}/settings`;

  return (
    <ButtonGroup>
      {showExport ? (
        <>
          <ExportModalManager createdAt={createdAt} id={id} type={type} />

          <MoreMenu>
            <MenuSection>
              <ListRow forwardedAs={Link} to={settingsPath}>
                Settings
              </ListRow>
              <ListRow onClick={onClickOpenInBuilder}>Open in Builder</ListRow>
            </MenuSection>
          </MoreMenu>
        </>
      ) : (
        <>
          <Button kind="secondary" forwardedAs={Link} to={settingsPath}>
            Settings
          </Button>
          <Button onClick={onClickOpenInBuilder}>
            <Icon icon="edit" />
            Open in Builder
          </Button>
        </>
      )}
    </ButtonGroup>
  );
}

AnalyticsHeaderActions.propTypes = {
  id: PropTypes.string,
  onClickOpenInBuilder: PropTypes.func,
  showExport: PropTypes.bool,
  createdAt: PropTypes.number,
  type: PropTypes.oneOf(['pins', 'banner']),
};
