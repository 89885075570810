import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import qs from 'qs';
import classNames from 'classnames';

import { CPage, Button } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';
import { DocumentTitle } from 'next/hooks/use-title';
import ContentEligibility from 'components/Diagnostics/ContentEligibility';
import IndexPage from 'components/Common/IndexPage';
import Loader from 'components/Common/Loader';
import {
  selectAccountMeta,
  selectAccountMetaSynced,
} from 'reducers/account/meta';
import { showModal } from 'actions/currentModal';
import { getUserProfile } from 'helpers/api';
import { USER_PROFILE_MODAL } from 'constants/globals';
import { USER_PROFILE } from 'constants/features';
import { reportError } from 'helpers/error-reporting';
import { selectAccountFeature } from 'reducers/account/features';
import DiagnosticsUserModal from './DiagnosticsUserModal';

export class Diagnostics extends Component {
  constructor(props) {
    super(props);

    const { context } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    const { userId, url } = JSON.parse(window.atob(context || '') || '{}');

    this.state = {
      userId: userId || '',
      url: url || '',
      profileRequested: false,
      showDiagnosticModal: false,
    };
  }

  componentDidMount() {
    this.componentDidUpdate(this.props, this.state);
  }

  componentDidUpdate() {
    const { accountMeta } = this.props;
    const { id, uuid } = accountMeta;
    const { userId, profileRequested } = this.state;

    if (id && uuid && userId && !profileRequested) {
      this.getUserProfile(id, userId, uuid);
    }
  }

  handleDiagnosticsModalOpen = () => {
    this.setState({ showDiagnosticModal: true });
  };

  handleSubmit = ({ userId, url }) => {
    this.setState({
      userId,
      url,
      profileRequested: false,
      showDiagnosticModal: false,
    });
  };

  handleDiagnosticsModalClose = () => {
    this.setState({ showDiagnosticModal: false });
  };

  async getUserProfile(accountId, userId, accountUUID) {
    try {
      const userProfile = await getUserProfile(accountId, userId, accountUUID);
      this.setState({ userProfile, profileRequested: true });
    } catch (error) {
      reportError(error, { accountId, userId });
      this.setState({ userProfile: { error: true }, profileRequested: true });
    }
  }

  showUserProfileModal = () => {
    const { accountMeta, actions } = this.props;
    const { userProfile } = this.state;
    actions.showModal(USER_PROFILE_MODAL, {
      accountId: accountMeta.id,
      userProfile,
    });
  };

  render() {
    const { userId, url, userProfile, showDiagnosticModal } = this.state;
    const { className, loaded, accountMeta, hasUserProfile } = this.props;

    if (!loaded) {
      return <Loader />;
    }

    const hasUserBeenSet = userId && url;

    const viewProfileButtonProps = hasUserProfile
      ? {
          href: `/users/${encodeURIComponent(userId)}`,
        }
      : { onClick: this.showUserProfileModal };

    return (
      <IndexPage
        title="Diagnostics"
        description="View eligibility and targeting diagnostics for all live content for any user."
      >
        <DocumentTitle title="Diagnostics | Appcues" />
        {showDiagnosticModal && (
          <DiagnosticsUserModal
            onClose={this.handleDiagnosticsModalClose}
            onSubmit={this.handleSubmit}
            userId={userId}
            url={url}
          />
        )}

        <CPage.Container className={classNames(className, 'diagnostics')}>
          <div className="user-info">
            <div className="user-info-banner">
              <div className="user">
                <Icon icon="user" aria-hidden="true" />
                {hasUserBeenSet ? (
                  <div className="user-info-banner--inner">
                    <span className="label">User&nbsp;</span>
                    <span className="user-info-banner--userid">
                      {userId}
                      <Button
                        className="button-secondary-alt user-info--show-profile-btn"
                        {...viewProfileButtonProps}
                      >
                        View Profile
                      </Button>
                    </span>
                  </div>
                ) : (
                  <i className="user-info--prompt">
                    Select a Page and User to continue
                  </i>
                )}
              </div>
              <Button
                className="button-secondary-alt user-info--new-user-page-btn"
                onClick={this.handleDiagnosticsModalOpen}
              >
                Set User & Page
              </Button>
            </div>
            {hasUserBeenSet && (
              <div className="user-info--domain-page">
                <span className="label">Domain & Page</span>&nbsp;
                <span className="user-info--url">{url}</span>
              </div>
            )}
          </div>
          {hasUserBeenSet && (
            <ContentEligibility
              accountId={accountMeta.id}
              userId={userId}
              url={url}
              userProfile={userProfile}
            />
          )}
        </CPage.Container>
      </IndexPage>
    );
  }
}

function mapStateToProps(state) {
  return {
    loaded: selectAccountMetaSynced(state),
    accountMeta: selectAccountMeta(state),
    hasUserProfile: selectAccountFeature(state, USER_PROFILE),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ showModal }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styled(Diagnostics)`
  .user-info {
    .label {
      color: ${props => props.theme['$gray-50']};
    }
    .value {
      color: ${props => props.theme['$gray-50']};
      margin-left: 1em;
      font-weight: 600;
    }
    .user-info-row:not(:last-child) {
      padding-bottom: 0.5em;
    }
    .user-info-banner {
      background-color: ${props => props.theme.$purple};
      border-radius: 4px;
      padding: 1em;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1em;
      color: white;
      .user {
        display: flex;
        flex-direction: row;
        align-items: center;
        .button {
          margin-left: 1em;
        }
        .label,
        .value {
          color: white;
        }
      }
      .fa-user {
        font-size: 2rem;
        margin: 0 1rem 0 0.25rem;
      }
    }
  }
`);
