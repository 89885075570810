import React from 'react';
import PropTypes from 'prop-types';
import { SubTitle } from '@appcues/sonar';
import QueryResults from 'components/Common/QueryResults';
import UserSampleTable from 'components/Common/UserSampleTable';

export const RecentUsersTable = ({ results }) => {
  const userSample = {
    meta: {
      fetching: false,
      synced: !!results,
      errored: false,
    },
    data: results,
  };

  return (
    <UserSampleTable
      title={<SubTitle>10 Most Recent Users</SubTitle>}
      userSample={userSample}
      timestampHeader="Flow Seen"
    />
  );
};

RecentUsersTable.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      user_id: PropTypes.string,
      timestamp: PropTypes.string,
    })
  ),
};

const RecentUsersTableWithAnalytics = ({
  flowId,
  startTime,
  endTime,
  segmentId,
}) => (
  <QueryResults
    query={{
      conditions: [
        ['flow_id', '==', flowId],
        ['name', '==', 'appcues:flow_started'],
        ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
      ],
      dimensions: ['user_id', 'timestamp'],
      order_by: [['timestamp', 'desc']],
      start_time: startTime,
      end_time: endTime,
      limit: 10,
      meta: {
        tags: {
          feature: 'Flow analytics',
          page: 'Flow analytics',
          component: 'RecentUsersTable',
          description: 'Flow users table',
        },
      },
    }}
  >
    {results => <RecentUsersTable results={results} />}
  </QueryResults>
);

RecentUsersTableWithAnalytics.propTypes = {
  flowId: PropTypes.string,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  segmentId: PropTypes.string,
};

export default RecentUsersTableWithAnalytics;
