import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Modal, Text } from '@appcues/sonar';
import { faOctagonExclamation } from '@fortawesome/pro-regular-svg-icons/faOctagonExclamation';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { Link } from '@studio/legacy-components';

const FlowDependencyModal = ({ actions, title, description, flows }) => {
  return (
    <Modal.Root open modal={false} onOpenChange={actions.hideModal}>
      <Modal.Title>
        <Icon
          colorToken="color-pink-600"
          size="large"
          icon={faOctagonExclamation}
        />
        {title}
      </Modal.Title>

      <Text>{description}</Text>

      <Text weight="bold">Flows</Text>

      {flows.map(flow => (
        <Modal.DialogClose asChild>
          <Link key={flow.id} to={`/flows/${flow.id}/settings`}>
            {flow.name}
          </Link>
        </Modal.DialogClose>
      ))}

      <Modal.Actions>
        <Modal.DialogClose asChild>
          <Button>Got it</Button>
        </Modal.DialogClose>
      </Modal.Actions>

      <Modal.Close aria-label="Close">
        <Icon size="large" icon={faXmark} />
      </Modal.Close>
    </Modal.Root>
  );
};

FlowDependencyModal.propTypes = {
  actions: PropTypes.shape({
    hideModal: PropTypes.func.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default FlowDependencyModal;
