import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { components } from 'react-select';
import { Icon } from '@studio/legacy-components';

const Wrapper = styled.span`
  align-items: center;
  display: flex;

  ${Icon} {
    color: var(--text-secondary);
    font-size: var(--x-small);
    margin-right: 4px;
  }

  ${({ suffixed }) =>
    suffixed &&
    `
      justify-content: space-between;

      ${Icon} {
        margin-left: 4px

        :hover {
          color: var(--text-tertiary);
        }
      }
    `}
`;

const ThemeFontDropdownOption = props => {
  const {
    data: { type, url },
    innerProps,
    isSelected,
    selectProps: { onAddFont, onEditTypekitId, onDelete },
  } = props;

  switch (type) {
    case 'google': {
      const { children, ...rest } = props;
      return (
        <components.Option {...rest}>
          <Wrapper suffixed>
            {children}

            {!isSelected && (
              <Icon
                icon="trash-alt"
                aria-label="Delete this font"
                onClick={e => {
                  e.stopPropagation();
                  onDelete(url);
                }}
              />
            )}
          </Wrapper>
        </components.Option>
      );
    }

    case 'adobe': {
      const { children, ...rest } = props;
      return (
        <components.Option {...rest}>
          <Wrapper suffixed>
            {children}

            <Icon
              icon="edit"
              onClick={e => {
                e.stopPropagation();
                onEditTypekitId();
              }}
            />
          </Wrapper>
        </components.Option>
      );
    }

    case 'addGoogleFont':
      return (
        <components.Option
          {...{
            ...props,
            innerProps: {
              ...innerProps,
              onClick: onAddFont,
            },
          }}
        >
          <Wrapper>
            <Icon icon="plus" />
            Add Google Font
          </Wrapper>
        </components.Option>
      );

    case 'addAdobeFont':
      return (
        <components.Option
          {...{
            ...props,
            innerProps: {
              ...innerProps,
              onClick: e => {
                e.stopPropagation();
                onEditTypekitId();
              },
            },
          }}
        >
          <Wrapper>
            <Icon icon="plus" />
            Add Adobe Font
          </Wrapper>
        </components.Option>
      );

    default:
      return <components.Option {...props} />;
  }
};

ThemeFontDropdownOption.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.oneOf(['google', 'adobe', 'addGoogleFont', 'addAdobeFont']),
    createFont: PropTypes.bool,
    url: PropTypes.string,
  }),
  children: PropTypes.node,
  innerProps: PropTypes.object,
  isSelected: PropTypes.bool,
  selectProps: PropTypes.shape({
    onAddFont: PropTypes.func,
    onEditTypekitId: PropTypes.func,
    onDelete: PropTypes.func,
  }),
};

export default ThemeFontDropdownOption;
