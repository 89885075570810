import { call, put, takeEvery, getContext } from 'redux-saga/effects';
import {
  send,
  resolve,
  reject,
  patterns,
} from 'actions/account/installedDomains';

import { reportError } from 'helpers/error-reporting';

export function* fetchInstalledDomains() {
  try {
    yield put(send());
    const listserv = yield getContext('listserv');
    const { results } = yield call(listserv.getInstalledDomains);
    const domains = results.map(({ domain }) => domain);
    yield put(resolve(domains));
  } catch (error) {
    yield put(reject(error));
    yield call(reportError, error);
  }
}

export default function* saga() {
  yield takeEvery(patterns.callApi, fetchInstalledDomains);
}
