import { createCollectionReducer } from 'reducers/account/collections';

import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

export const selectResponses = state => state[TYPE];
export const selectResponse = (state, id) => state[TYPE]?.[id];
export const selectHasResponses = state =>
  Object.keys(state[TYPE] ?? []).length > 0;
