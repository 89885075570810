import React from 'react';
import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';

const DomainLegend = () => {
  return (
    <LegendWrapper>
      <label>
        <InstalledIcon icon="circle" title="installed" />
        <span>Installed and ready</span>
      </label>
      <label>
        <NotInstalledIcon icon="circle" title="draft" />
        <span>Install not detected</span>
      </label>
    </LegendWrapper>
  );
};

const LegendWrapper = styled.div`
  color: ${props => props.theme['$gray-5']};
  font-size: 12px;
  padding: 12px 23px 0;

  label {
    margin-right: 25px;
  }

  i {
    margin-right: 8px;
  }
`;

const InstalledIcon = styled(Icon)`
  color: ${props => props.theme.$green};
  margin-right: 8px;
`;

const NotInstalledIcon = styled(Icon)`
  color: ${props => props.theme['$gray-5']};
  margin-right: 8px;
`;

export default DomainLegend;
