import styled from 'styled-components';
import { Button as SonarButton } from '@appcues/sonar';
import AvatarImg from 'components/Common/AvatarImg';

export const SummaryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const VersionList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
`;

export const VersionListItem = styled.li`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 8px;
`;

export const Avatar = styled(AvatarImg)`
  margin: 0;
`;

export const Button = styled(SonarButton)`
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const User = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Date = styled.div``;
