import { combineReducers } from 'redux';

/**
 * NOTE: These imports DO NOT go through the index.js for each entity
 *
 *       THIS IS INTENTIONAL!
 *
 *       The reducers ARE NOT a public API unlike actions and selectors which
 *       ARE appropriate to import in components.
 */
/* eslint-disable no-restricted-imports */

import account from 'next/entities/account/reducer';
import accounts from 'next/entities/accounts/reducer';
import accountUsers from 'next/entities/account-users/reducer';
import analytics from 'next/entities/analytics/reducer';
import apps from 'next/entities/apps/reducer';
import checklists from 'next/entities/checklists/reducer';
import contentStatus from 'next/entities/content-status/reducer';
import eventNameLabels from 'next/entities/event-name-labels/reducer';
import experiences from 'next/entities/experiences/reducer';
import experienceVersions from 'next/entities/experience-versions/reducer';
import features from 'next/entities/features/reducer';
import flows from 'next/entities/flows/reducer';
import goals from 'next/entities/goals/reducer';
import installed from 'next/entities/installed/reducer';
import locales from 'next/entities/locales/reducer';
import page from 'next/entities/page/reducer';
import pins from 'next/entities/pins/reducer';
import profileAttributeLabels from 'next/entities/profile-attribute-labels/reducer';
import eventUserProfiles from 'next/entities/event-user-profiles/reducer';
import requests from 'next/entities/requests/reducer';
import rules from 'next/entities/rules/reducer';
import publishedRules from 'next/entities/published-rules/reducer';
import schedules from 'next/entities/schedules/reducer';
import screenshots from 'next/entities/screenshots/reducer';
import segments from 'next/entities/segments/reducer';
import spoofing from 'next/entities/spoofing/reducer';
import surveys from 'next/entities/surveys/reducer';
import tags from 'next/entities/tags/reducer';
import targeting from 'next/entities/targeting/reducer';
import themes from 'next/entities/themes/reducer';
import translations from 'next/entities/translations/reducer';
import trialStatus from 'next/entities/trial-status/reducer';
import user from 'next/entities/user/reducer';
import users from 'next/entities/users/reducer';
import versionedExperiences from 'next/entities/versioned-experiences/reducer';
import entitlements from 'next/entities/entitlements/reducer';
/* eslint-enable no-restricted-imports */

export default combineReducers({
  account,
  accounts,
  accountUsers,
  analytics,
  apps,
  checklists,
  contentStatus,
  eventNameLabels,
  experiences,
  experienceVersions,
  features,
  flows,
  goals,
  installed,
  page,
  pins,
  profileAttributeLabels,
  screenshots,
  eventUserProfiles,
  requests,
  rules,
  publishedRules,
  segments,
  spoofing,
  surveys,
  tags,
  targeting,
  themes,
  trialStatus,
  user,
  users,
  versionedExperiences,
  schedules,
  locales,
  translations,
  entitlements,
});
