import React from 'react';
import PropTypes from 'prop-types';
import { Link, Alert, Button } from '@appcues/sonar';
import styled from 'styled-components';

// The Column container in studio-classic uses 'align-items: flex-start', which
// prevents the Alert from taking up the full width unless this is specified.
const AlertRoot = styled(Alert.Root)`
  width: 100%;
`;

const ExperimentBanner = ({
  experimentId,
  experimentName,
  showBanner,
  published,
  hasChanges,
  onConfirmDiscardChanges,
}) => {
  if (!showBanner) return null;

  if (published && hasChanges) {
    return (
      <AlertRoot variant="warning">
        <Alert.Message spacing="small">
          <Alert.Title weight="bold">
            This Flow has unpublished changes
          </Alert.Title>
          <Alert.Description>
            Publishing changes will impact the accuracy of your data for an
            experiment:{' '}
            <Link href={`/experiments/${experimentId}/settings`}>
              {experimentName}
            </Link>
            . To publish these changes or unpublish the Flow, end the
            experiment.
          </Alert.Description>
        </Alert.Message>
        <Alert.Action>
          <Button
            variant="secondary"
            onClick={onConfirmDiscardChanges}
            aria-label="Discard changes"
          >
            Discard changes
          </Button>
        </Alert.Action>
      </AlertRoot>
    );
  }

  if (published) {
    return (
      <AlertRoot variant="warning">
        <Alert.Message spacing="small">
          <Alert.Title weight="bold">
            This Flow connects to an experiment:{' '}
            <Link href={`/experiments/${experimentId}/settings`}>
              {experimentName}
            </Link>
          </Alert.Title>
          <Alert.Description>
            To unpublish or make changes to this Flow, end the experiment.
            Editing this Flow will impact the accuracy of your experiment data.
          </Alert.Description>
        </Alert.Message>
      </AlertRoot>
    );
  }

  return (
    <AlertRoot variant="warning">
      <Alert.Message spacing="small">
        <Alert.Title weight="bold">
          This Flow connects to an experiment:{' '}
          <Link href={`/experiments/${experimentId}/settings`}>
            {experimentName}
          </Link>
        </Alert.Title>
        <Alert.Description>
          Once this Flow is live, do not make changes or unpublish it until you
          end the experiment. Making changes to a Flow while the experiment is
          active will impact the accuracy of your experiment data.
        </Alert.Description>
      </Alert.Message>
    </AlertRoot>
  );
};

ExperimentBanner.propTypes = {
  experimentId: PropTypes.string,
  experimentName: PropTypes.string,
  showBanner: PropTypes.bool,
  published: PropTypes.bool,
  hasChanges: PropTypes.bool,
  onConfirmDiscardChanges: PropTypes.func,
};

export default ExperimentBanner;
