export const CONTENT_REVERTED = 'CONTENT_REVERTED';
export const CONTENT_PUBLISHED = 'CONTENT_PUBLISHED';
export const CONTENT_PUSHED = 'CONTENT_PUSHED';
export const CONTENT_UNPUBLISHED = 'CONTENT_UNPUBLISHED';

export const revert = ({ id, type }) => ({
  type: CONTENT_REVERTED,
  payload: {
    id,
    type,
  },
});

export const publish = ({ id, type }) => ({
  type: CONTENT_PUBLISHED,
  payload: {
    id,
    type,
  },
});

export const push = ({ id, type }) => ({
  type: CONTENT_PUSHED,
  payload: {
    id,
    type,
  },
});

export const unpublish = ({ id, type }) => ({
  type: CONTENT_UNPUBLISHED,
  payload: {
    id,
    type,
  },
});

export default function getContentAction(actionType, data) {
  switch (actionType) {
    case 'revert':
      return revert(data);
    case 'publish':
      return publish(data);
    case 'push':
      return push(data);
    case 'unpublish':
      return unpublish(data);
    default:
      return null;
  }
}
