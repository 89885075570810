import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAnalytics } from 'actions/analytics-v2';
import { selectAccountAnalytics } from 'reducers/account/analytics-v2';
import { getCacheKey } from 'utils/caching';

const QueryShape = PropTypes.shape({
  metrics: PropTypes.arrayOf(PropTypes.string),
  dimensions: PropTypes.arrayOf(PropTypes.string),
  conditions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
    ])
  ),
  columns: PropTypes.arrayOf(PropTypes.string),
  start_time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  end_time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  limit: PropTypes.number,
});

/**
 * QueryResults retrieves the result of executing its query prop
 * and passes it to its children using the renderProp pattern
 */
export const QueryResults = ({ query, results, onChange, children }) => {
  const cacheKey = query && getCacheKey(query);
  useEffect(() => {
    if (query && cacheKey && !results) {
      onChange(query);
    }
  }, [cacheKey, results]);
  return children(results);
};

QueryResults.propTypes = {
  children: PropTypes.func,
  onChange: PropTypes.func,
  query: QueryShape,
  results: PropTypes.array,
};

const mapStateToProps = (state, { query }) => {
  return {
    results: selectAccountAnalytics(state, query),
  };
};

const mapDispatchToProps = {
  onChange: query => getAnalytics({ query }),
};

const ConnectedQueryResults = connect(
  mapStateToProps,
  mapDispatchToProps
)(QueryResults);

ConnectedQueryResults.propTypes = {
  query: QueryShape,
  children: PropTypes.func,
};

export default ConnectedQueryResults;
