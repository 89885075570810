import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import FlowAnalytics from 'components/FlowAnalytics';
import StepSettings from 'components/StepSettings';
import EditStepInChrome from 'components/EditStepInChrome';

const Flows = () => {
  return (
    <>
      <Route path="/flows/:stepId/analytics" component={FlowAnalytics} />

      <Route path="/flows/:stepId/settings" component={StepSettings} />

      <Route
        exact
        path="/flows/:stepId"
        render={({ match }) => (
          <Redirect to={`/flows/${match.params.stepId}/analytics`} />
        )}
      />

      <Route
        path="/edit-in-chrome/stepGroup/:stepGroupId/stepChild/:stepChildId"
        component={EditStepInChrome}
      />
      <Route
        path="/:contentType/:stepId/edit"
        render={({ match }) => (
          <Redirect to={`/edit-in-chrome/stepGroup/${match.params.stepId}`} />
        )}
      />
      <Route
        path="/:contentType/:stepId/design"
        render={({ match }) => (
          <Redirect to={`/edit-in-chrome/stepGroup/${match.params.stepId}`} />
        )}
      />
    </>
  );
};

export default Flows;
