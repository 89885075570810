import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Link } from '@appcues/sonar';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink';
import OpenInBuilderButton from 'components/OpenInBuilder/OpenInBuilderButton';
import { AlertAction, ButtonLink } from './styled';

const AutoGeneratedIdAlert = ({ flowId, stepChildId }) => {
  return (
    <Alert.Root variant="info">
      <Alert.Message>
        <Alert.Title>
          Some Fields Are Missing Custom Reporting Labels
        </Alert.Title>
        <Alert.Description>
          Easily identify fields by setting custom reporting labels in the
          Appcues Builder.
        </Alert.Description>
      </Alert.Message>
      <AlertAction>
        <OpenInBuilderButton
          stepGroupId={flowId}
          stepChildId={stepChildId}
          render={handleClick => (
            <Button variant="secondary" onClick={handleClick}>
              Open in builder
            </Button>
          )}
        />
        <ButtonLink
          variant="tertiary"
          forwardedAs={Link}
          href="https://docs.appcues.com/en_US/user-experiences-building/forms-surveys"
          icon={faExternalLink}
          target="_blank"
          inline
        >
          Learn more
        </ButtonLink>
      </AlertAction>
    </Alert.Root>
  );
};

AutoGeneratedIdAlert.propTypes = {
  flowId: PropTypes.string.isRequired,
  stepChildId: PropTypes.string,
};

export default AutoGeneratedIdAlert;
