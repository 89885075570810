import PropTypes from 'prop-types';

const FiltersShape = PropTypes.objectOf(
  PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.objectOf(
      PropTypes.shape({
        label: PropTypes.node,
        value: PropTypes.string,
        enabled: PropTypes.bool,
      })
    ),
  })
);

export default FiltersShape;
