import {
  createCollectionReducer,
  composeReducers,
} from 'reducers/account/collections';
import { ANALYTICS_CACHE_CLEARED } from 'actions/analytics-v2';
import { getCacheKey } from 'utils/caching';

const TYPE = 'analyticsV2';

const reducer = (state, action) => {
  switch (action.type) {
    case ANALYTICS_CACHE_CLEARED:
      return {};
    default:
      return state;
  }
};

export default composeReducers(reducer, createCollectionReducer(TYPE));

export const selectAccountAnalytics = (state, query = {}) => {
  const cacheKey = getCacheKey(query);
  const analytics = state.account[TYPE][cacheKey] || {};
  const { results } = analytics;
  return results;
};
