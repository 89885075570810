import React from 'react';
import Navigation from 'next/components/Navigation';
import { NextStoreProvider } from 'next/components/Hacks';

const Navbar = () => {
  return (
    <NextStoreProvider>
      <Navigation />
    </NextStoreProvider>
  );
};

export default Navbar;
