import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CPanel, CTextInput, CForm, Switch } from '@appcues/component-library';
import {
  selectAccountMeta,
  selectAccountMetaSynced,
} from 'reducers/account/meta';
import { updateAccountMeta } from 'actions/account/meta';
import {
  selectAccountFeature,
  selectAccountFeaturesSynced,
} from 'reducers/account/features';
import { FREQUENCY_BUFFER } from 'constants/features';

export function FrequencyCappingPanel({
  accountMetaAndFeaturesSynced = false,
  frequencyCapBuffer = '',
  hasFrequencyBuffer = false,
  onSubmit,
}) {
  const handleSubmitForm = ({
    isFrequencyCappingOn,
    frequencyCapBuffer: buffer,
  }) => {
    const frequencyCapBufferMs =
      Number.parseInt(buffer || 0, 10) * 60 * 60 * 1000;

    onSubmit({
      buffer: isFrequencyCappingOn ? frequencyCapBufferMs : '',
    });
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handleFrequencyCappingToggle = setFieldValue => value =>
    setFieldValue('isFrequencyCappingOn', value, true);

  const shouldShowFrequencyCap = hasFrequencyBuffer || frequencyCapBuffer;

  if (!accountMetaAndFeaturesSynced || !shouldShowFrequencyCap) {
    return false;
  }

  const frequencyCapBufferHours =
    Number.parseInt(frequencyCapBuffer || 0, 10) / 1000 / 60 / 60;

  return (
    <CPanel title="Frequency capping">
      <p>Limit how often customers can see a message from Appcues.</p>
      <CForm
        initialValues={{
          frequencyCapBuffer: frequencyCapBufferHours,
          isFrequencyCappingOn: !!frequencyCapBuffer,
        }}
        onSubmit={handleSubmitForm}
      >
        {({ values, setFieldValue }) => (
          <CForm.Element>
            <CForm.Group>
              <CForm.Row>
                <span>Off</span>
                <Switch
                  name="isFrequencyCappingOn"
                  checked={values.isFrequencyCappingOn}
                  onChange={handleFrequencyCappingToggle(setFieldValue)}
                />
                <span>On</span>
              </CForm.Row>
            </CForm.Group>
            <CForm.Field
              name="frequencyCapBuffer"
              label="Minimum number of hours between showing flows"
            >
              <CTextInput
                type="number"
                placeholder="0"
                disabled={!values.isFrequencyCappingOn}
              />
            </CForm.Field>
            <CForm.SubmitButton type="positive">Save</CForm.SubmitButton>
          </CForm.Element>
        )}
      </CForm>
    </CPanel>
  );
}
FrequencyCappingPanel.propTypes = {
  frequencyCapBuffer: PropTypes.string,
  hasFrequencyBuffer: PropTypes.bool,
  accountMetaAndFeaturesSynced: PropTypes.bool,
  onSubmit: PropTypes.func,
};

const mapStateToProps = state => ({
  frequencyCapBuffer: selectAccountMeta(state).buffer,
  hasFrequencyBuffer: selectAccountFeature(state, FREQUENCY_BUFFER),
  accountMetaAndFeaturesSynced:
    selectAccountMetaSynced(state) && selectAccountFeaturesSynced(state),
});

const mapDispatchToProps = {
  onSubmit: updateAccountMeta,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FrequencyCappingPanel);
