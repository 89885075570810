import React, { useEffect, useState, useCallback } from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';

import { CButton, CDialog, Text } from '@appcues/component-library';
import { connect } from 'react-redux';

import { asPromised } from 'utils/as-promised';
import {
  toggleIntegrationState,
  getIntegrationData,
} from 'actions/account/integrations';
import { trackEvent } from 'actions/events';
import { hideModal } from 'actions/currentModal';

import { selectAccountIntegration } from 'reducers/account/integrations';

import { INTEGRATION_EVENTS } from 'constants/events';
import { INTEGRATION_MESSAGING } from './constants';

import IntegrationImage from './IntegrationImage';

export const IntegrationModal = ({
  onEvent,
  onHideModal,
  integration,
  onToggleIntegrationState,
  getIntegration,
  enabledInFirebase,
}) => {
  const [configStatus, setConfigStatus] = useState(null);
  const [configWindow, setConfigWindow] = useState(null);
  const [configUrl, setConfigUrl] = useState(null);
  const [instanceMeta, setInstanceMeta] = useState({
    code: null,
    instanceId: null,
  });
  const [integrationResponse, setIntegrationResponse] = useState({});

  const handlePopupEvents = useCallback(
    e => {
      const closeConfigWizard = () => {
        if (configWindow) {
          configWindow.close();
          setConfigWindow(null);
        }
      };
      if (e.data.type === 'tray.configPopup.error') {
        closeConfigWizard();
        setConfigStatus('ERROR');
      }
      if (e.data.type === 'tray.configPopup.cancel') {
        closeConfigWizard();
        onEvent(
          INTEGRATION_EVENTS[integration.id].CANCELLED_CONFIGURATION_WIZARD,
          {
            integration: integration.name,
          }
        );
        onHideModal();
        setConfigStatus('CANCELLED');
      }
      if (e.data.type === 'tray.configPopup.finish') {
        setConfigStatus('FINISHED');
        onToggleIntegrationState(integration.id, instanceMeta.instanceId, {
          enabled: true,
        }).then(() => {
          onHideModal();
        });
        closeConfigWizard();
      }
    },
    [configWindow, integration, onToggleIntegrationState, instanceMeta]
  );

  useEffect(() => {
    window.addEventListener('message', handlePopupEvents);
    return () => {
      window.removeEventListener('message', handlePopupEvents);
    };
  }, [handlePopupEvents]);

  useEffect(() => {
    /* global TRAY_POPUP_CONFIG_DOMAIN SALESFORCE_CONFIGURE_SHOW_STEPS */
    getIntegration(integration.id).then(response => {
      setInstanceMeta(response);
      const show =
        integration.id === 'salesforce' ? SALESFORCE_CONFIGURE_SHOW_STEPS : [];
      const params = {
        show,
        code: response.code,
      };
      const url = `${TRAY_POPUP_CONFIG_DOMAIN}/external/solutions/appcues/configure/${
        response.instanceId
      }?${qs.stringify(params)}`;

      setIntegrationResponse(response);
      setConfigUrl(url);
    });
  }, []);

  const launchConfigWizard = () => {
    if (configUrl) {
      const trayConfigWindow = window.open(
        configUrl,
        '_blank',
        'width=600,height=600,scrollbars=yes'
      );
      trayConfigWindow.document.title = `Appcues: ${integration.name} Configuration`;

      onEvent(INTEGRATION_EVENTS[integration.id].OPENED_CONFIGURATION_WIZARD, {
        integration: integration.name,
      });

      setConfigWindow(trayConfigWindow);
    }
  };

  const handleModifyClick = () => {
    launchConfigWizard();
  };

  const handleToggleIntegrationState = () => {
    if (integrationResponse && integrationResponse.enabled) {
      onToggleIntegrationState(integration.id, instanceMeta.instanceId, {
        enabled: false,
      }).then(() => {
        onHideModal();
      });
    } else {
      launchConfigWizard();
    }
  };

  const configInProgress = configStatus === 'IN_PROGRESS';
  const integrationEnabled =
    (integrationResponse && integrationResponse.enabled) || enabledInFirebase;

  const integrationIsLoading = Object.keys(integrationResponse).length === 0;
  const bodyText = configInProgress
    ? INTEGRATION_MESSAGING[integration.id].integrationInProgress
    : INTEGRATION_MESSAGING[integration.id].integrationAvailable;

  return (
    <CDialog
      title={integration.name}
      components={{
        Header: function Header() {
          return (
            <CDialog.Header>
              <IntegrationImage
                alt={INTEGRATION_MESSAGING[integration.id].alt}
                src={INTEGRATION_MESSAGING[integration.id].src}
              />
            </CDialog.Header>
          );
        },
        Footer: false,
      }}
    >
      <Text marginLeft={10}>{bodyText}</Text>
      <CDialog.Footer>
        <CButton onClick={onHideModal}>Cancel</CButton>
        {(integrationIsLoading || integrationEnabled) && (
          <CButton
            onClick={handleModifyClick}
            isDisabled={
              !integrationEnabled || integrationIsLoading || configInProgress
            }
          >
            Modify
          </CButton>
        )}
        <CButton
          isDisabled={integrationIsLoading || configInProgress}
          type={integrationEnabled ? 'negative' : 'positive'}
          onClick={handleToggleIntegrationState}
        >
          {integrationEnabled ? 'Remove' : 'Activate'}
        </CButton>
      </CDialog.Footer>
    </CDialog>
  );
};

const mapStateToProps = (state, { integration }) => ({
  enabledInFirebase: !!selectAccountIntegration(state, integration.id),
});

const mapDispatchToProps = dispatch => ({
  onHideModal: () => dispatch(hideModal()),
  onEvent: (eventName, properties, options) =>
    dispatch(trackEvent(eventName, properties, options)),
  getIntegration: integrationId =>
    asPromised(dispatch, getIntegrationData(integrationId)),
  onToggleIntegrationState: (integrationId, instanceId, isEnabled) =>
    asPromised(
      dispatch,
      toggleIntegrationState(integrationId, instanceId, isEnabled)
    ),
});

IntegrationModal.propTypes = {
  onEvent: PropTypes.func,
  onHideModal: PropTypes.func,
  integration: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
  onToggleIntegrationState: PropTypes.func,
  getIntegration: PropTypes.func,
  enabledInFirebase: PropTypes.bool,
};

const ConnectedIntegrationModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegrationModal);

export default ConnectedIntegrationModal;
