import {
  FETCH_ACCOUNT_META,
  SET_ACCOUNT_META_FETCHING,
  SET_ACCOUNT_META_ERRORED,
  UPDATE_ACCOUNT_META,
  REPLACE_ACCOUNT_META,
  UPDATE_ACCOUNT_META_SUCCESS,
} from 'constants/account/meta';

export const fetchAccountMeta = accountId => ({
  type: FETCH_ACCOUNT_META,
  payload: {
    accountId,
  },
});

export const setAccountMetaFetching = () => ({
  type: SET_ACCOUNT_META_FETCHING,
});

export const replaceAccountMeta = data => ({
  type: REPLACE_ACCOUNT_META,
  payload: data,
});

export const setAccountMetaErrored = error => ({
  type: SET_ACCOUNT_META_ERRORED,
  payload: { error },
});

export const updateAccountMeta = (changes, notify = true) => ({
  type: UPDATE_ACCOUNT_META,
  payload: { changes },
  meta: { notify },
});

export const updateSuccessAccountMeta = () => ({
  type: UPDATE_ACCOUNT_META_SUCCESS,
});
