import { takeEvery, call, put, getContext } from 'redux-saga/effects';
import { resolve, reject, patterns } from './actions';

export const transform = response =>
  response.reduce((acc, checklist) => {
    acc[checklist.id] = checklist;
    return acc;
  }, {});

export function* fetchChecklists() {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getChecklists);
    yield put(resolve(transform(response)));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  // Internal actions
  yield takeEvery(patterns.read, fetchChecklists);
}
