export const JOB_DROPDOWN_OPTIONS = [
  { label: 'Product', value: 'Product' },
  { label: 'Growth', value: 'Growth' },
  { label: 'Marketing', value: 'Marketing' },
  { label: 'Engineering', value: 'Engineering' },
  { label: 'UX/Design', value: 'UX/Design' },
  { label: 'Customer Success/Support', value: 'Customer Success/Support' },
  { label: 'Executive', value: 'Executive' },
  { label: 'Other', value: 'Other' },
];
