import React from 'react';
import { CButton, CDialog } from '@appcues/component-library';

import { SALESFORCE_TYPEFORM_LINK } from 'constants/externalAppcuesResources';
import IntegrationImage from './IntegrationImage';

const SalesforceInformationModal = ({ actions }) => {
  return (
    <CDialog
      title="Salesforce integration"
      components={{
        Header: function Header() {
          return (
            <CDialog.Header>
              <IntegrationImage integrationId="salesforce" />
            </CDialog.Header>
          );
        },
        Footer: false,
      }}
    >
      <p>
        Our Appcues {'<>'} Salesforce Integration is here! We hope you’re as
        excited as we are.
      </p>

      <p>
        This integration is both real-time and bi-directional. We heard your
        feedback and built it to empower your customer-facing teams with more
        product data, and your product team with more CRM data.
      </p>

      <p>
        To get started, you&apos;ll need to upgrade your plan. Complete this
        short form, and we’ll reach out with next steps.
      </p>
      <CDialog.Footer>
        <CButton onClick={actions.hideModal}>Cancel</CButton>
        <CButton
          is="a"
          type="primary"
          href={SALESFORCE_TYPEFORM_LINK}
          isExternal
          onClick={actions.hideModal}
        >
          Get started
        </CButton>
      </CDialog.Footer>
    </CDialog>
  );
};

export default SalesforceInformationModal;
