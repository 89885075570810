import React from 'react';
import { connect } from 'react-redux';

import { Text } from '@studio/legacy-components';
import Loader from 'components/Common/Loader';
import {
  selectBillingDetails,
  selectBillingDetailsWithCardSynced,
} from 'reducers/billing';
import { selectUser } from 'reducers/user';

import {
  BillingDetailsWrapper,
  BillingDetailsImageWrapper,
  BillingSection,
  CardTitle,
  EditButton,
  StyledHeading,
  StyledPanel,
  StyledIcon,
} from './styled';

const getIconByCardBrand = brand => {
  switch (brand) {
    case 'master':
      return <StyledIcon icon={['fab', 'cc-mastercard']} />;
    case 'visa':
      return <StyledIcon icon={['fab', 'cc-visa']} />;
    default:
      return <StyledIcon icon={['fab', 'cc-stripe']} />;
  }
};

export const BillingDetails = ({ billingDetails, isSynced, onEdit, user }) => {
  if (!isSynced) return <Loader />;

  const [cardInfo] = billingDetails.sources.data;
  const billingDetailsMetadata = billingDetails.metadata;

  return (
    <StyledPanel>
      <EditButton kind="secondary" onClick={onEdit}>
        Edit
      </EditButton>
      <StyledHeading>Billing Details</StyledHeading>
      <BillingDetailsWrapper>
        <BillingDetailsImageWrapper>
          <StyledIcon icon={['fa', 'map-marker-alt']} />
        </BillingDetailsImageWrapper>
        <BillingSection>
          <Text bold>Billing address</Text>
          <Text>{billingDetailsMetadata.Address_Line1}</Text>
          <Text>
            {billingDetailsMetadata.Address_City},{' '}
            {billingDetailsMetadata.Address_State}{' '}
            {billingDetailsMetadata.Address_PostalCode}
          </Text>
          <Text>{billingDetailsMetadata.Address_Country}</Text>
        </BillingSection>
      </BillingDetailsWrapper>
      <BillingDetailsWrapper>
        <BillingDetailsImageWrapper>
          {getIconByCardBrand(cardInfo.brand)}
        </BillingDetailsImageWrapper>
        <BillingSection>
          <CardTitle bold>
            {cardInfo.brand} **** {cardInfo.last4}{' '}
          </CardTitle>
          <Text>
            Exp: {cardInfo.exp_month}/{cardInfo.exp_year}
          </Text>
          <Text>{user.fullname}</Text>
        </BillingSection>
      </BillingDetailsWrapper>
    </StyledPanel>
  );
};

const mapStateToProps = state => ({
  billingDetails: selectBillingDetails(state),
  isSynced: selectBillingDetailsWithCardSynced(state),
  user: selectUser(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BillingDetails);
