import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Banner } from '@studio/legacy-components';
import { isPreviewing } from 'next/lib/history';

const Wrapper = styled(Banner)`
  font-size: var(--medium-large);
`;

export function PreviewBanner({ previewing = isPreviewing() }) {
  if (!previewing) {
    return null;
  }

  return <Wrapper>Currently viewing preview build!</Wrapper>;
}

PreviewBanner.propTypes = {
  previewing: PropTypes.bool,
};

export default PreviewBanner;
