import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { filter, routes, Routes } from './Routes';

/**
 * XXX: FOR REWRITE MIGRATION ONLY!
 */

/**
 * Empty component
 */
const Ignore = () => null;

/**
 * Passthrough routes for legacy studio. If a route is already handled above for
 * studio next, a route will be added that will render nothing for the legacy
 * router to use. Otherwise both routers will attempt to render it's matches,
 * but because the legacy is rendered after, it will override any matches
 * registered above.
 */
export default function Passthrough({
  children,
  features,
  routes: registry = routes,
}) {
  const passthroughs = filter(registry, features);

  return (
    <Switch>
      {/* Passthrough routes from next studio */}
      {passthroughs.map(({ path, exact }) => (
        <Route key={path} path={path} exact={exact} component={Ignore} />
      ))}

      {/* Forwarded original routes */}
      <Route>{children}</Route>
    </Switch>
  );
}

Passthrough.propTypes = {
  children: PropTypes.node,
  ...Routes.propTypes,
};
