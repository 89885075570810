import React, { Component } from 'react';
import ellipsis from 'ellipsis.js';
import { connect } from 'react-redux';
import { getConditionsFriendlyDescription } from '@appcues/libcues';
import { selectStepGroups } from 'reducers/account/flows';
import { selectAccountSegments } from 'reducers/account/segments';
import { selectAccountChecklists } from 'entities/checklists';

class FriendlyDescription extends Component {
  componentDidMount() {
    const { lines } = this.props;

    this.ellipsis = ellipsis({
      ellipsis: '…',
      responsive: false,
      class: false,
      lines,
      break_word: true,
    });

    if (this.ellipsis && this.truncateContainer) {
      this.ellipsis.add(this.truncateContainer);
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { clauses, omitTailPropertyNames, steps, segments, checklists } =
      this.props;
    const friendlyDescription = getConditionsFriendlyDescription(
      null,
      clauses,
      steps,
      segments,
      checklists,
      omitTailPropertyNames
    );
    return (
      <div
        title={friendlyDescription.replace(/<\/?[^>]+(>|$)/g, '')}
        ref={node => {
          this.truncateContainer = node;
        }}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: friendlyDescription,
        }}
      />
    );
  }
}

export default connect(state => ({
  steps: selectStepGroups(state),
  segments: selectAccountSegments(state),
  checklists: selectAccountChecklists(state),
}))(FriendlyDescription);
