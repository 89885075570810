import styled from 'styled-components';
import {
  Table,
  Tr,
  Td,
  Th,
  Icon,
  Thead,
  Tbody,
} from '@studio/legacy-components';

export const ActivitiesTable = styled(Table)`
  display: block;
  ${Thead}, ${Tbody} {
    display: block;
  }
  ${Tr} {
    display: block;
    width: 100%;
  }
  ${Td}, ${Th} {
    display: inline-block;
    &:nth-child(3) {
      width: 40%;
    }
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(4) {
      width: 20%;
    }
  }
`;

export const ActivitiesRow = styled(Tr)`
  display: inline-block;
  ${Td} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    // Line height is necessary to make each column height match the column with a Link
    line-height: 1.5;
  }
`;

export const ActionIcon = styled(Icon)`
  font-size: var(--small);
  color: ${props => `var(--activity-${props.$action}-color)`};
`;

export const Action = styled.span``;

export const ActionLabel = styled.div`
  display: flex;
  align-items: center;

  > *:not(:first-child) {
    margin-left: 8px;
  }
`;
