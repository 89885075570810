import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ContentStatus } from '@studio/legacy-components';
import { DocumentTitle } from 'next/hooks/use-title';
import AudienceTargeting from 'components/Common/AudienceTargeting';
import Publishing from 'components/Common/Publishing';
import FlowStatusBanner from 'components/Common/FlowStatusBanner';
import Panel from 'components/Common/Panel';
import ContentEditable from 'components/Common/ContentEditable';
import Loader from 'components/Common/Loader';
import PageTargeting from 'components/Common/PageTargeting';
import { CREATE_SEGMENT_MODAL } from 'constants/globals';
import * as conditionsActions from 'actions/account/conditions';
import { update as updateRule } from 'actions/account/rules';
import * as currentModalActions from 'actions/currentModal';
import * as segmentsActions from 'actions/account/segments';
import { updateAndFlushSatisfaction } from 'actions/satisfaction';
import apiActions from 'actions/api';
import {
  selectAccountRule,
  selectAccountRulesSynced,
} from 'reducers/account/rules';
import { selectAccountSatisfactionSurvey } from 'selectors/satisfaction';
import {
  selectAccountConditionsSynced,
  selectAccountStepConditions,
} from 'reducers/account/conditions';
import {
  selectAccountSegments,
  selectAccountSegmentsSynced,
} from 'reducers/account/segments';
import {
  selectAccountMeta,
  selectAccountMetaSynced,
} from 'reducers/account/meta';
import { includesCrxParam } from 'utils/url';
import { publishNPS } from 'helpers/publishing-api';

import { selectIsInstalled } from 'reducers/account/installedDomains';
import { selectFlowStatus } from 'reducers/account/flowStatus';

function SatisfactionCustomAudienceEdit({
  actions,
  className,
  conditions,
  contentStatus,
  isInCRX,
  isInstalled,
  loaded,
  meta,
  rule,
  segments,
  step,
}) {
  if (!loaded) {
    return <Loader />;
  }

  const handleNPSSurveyNameChange = ({ target: { textContent: name } }) => {
    actions.updateAndFlushSatisfaction(step.id, { name });
  };

  const isPublished = rule.published;
  const lastUpdatedAtTimestamp = step.updatedAt;

  return (
    <div className={`${className} settings`}>
      <DocumentTitle title="NPS Survey Settings | Appcues" />
      <header className="page-container">
        <FlowStatusBanner
          isPublished={isPublished}
          isPublishedAndDirty={contentStatus.publishedAndDirty}
        />
        <div className="settings-header">
          <div className="title-container">
            <ContentEditable
              html={step.name}
              onBlur={handleNPSSurveyNameChange}
              placeholder="Enter a title..."
            />
            <ContentStatus published={step.published} />
          </div>
          <Publishing
            contentStatus={contentStatus}
            isInstalled={isInstalled}
            isPublished={isPublished}
            publishToApi={publishNPS}
            stepId={step.id}
            stepType={step.type}
            showTestOptions
            lastUpdatedAtTimestamp={lastUpdatedAtTimestamp}
          />
        </div>
      </header>
      <hr />
      <main className="page-container">
        <Panel
          name="Display"
          description="Define where the flow should appear."
        >
          <PageTargeting
            addClause={actions.addClauseAndFlushRule}
            clauses={conditions}
            deleteClause={actions.deleteClauseAndFlushRule}
            deleteMatchingClauses={actions.deleteMatchingClausesAndFlushRule}
            frequencyTargeting
            showManualTriggerOption
            meta={meta}
            previewUrl={rule.previewUrl}
            rule={rule}
            setDisplayAnyPage={actions.setDisplayAnyPageAndFlushRule}
            setDisplaySpecificPage={actions.setDisplaySpecificPageAndFlushRule}
            setStepFrequency={actions.setStepFrequency}
            stepId={rule.id}
            updateClause={actions.updateClauseAndFlushRule}
          />
        </Panel>

        <Panel name="Audience" description="Define who should see the flow.">
          <AudienceTargeting
            isInCRX={isInCRX}
            stepId={rule.id}
            clauses={conditions}
            segments={segments}
            addGroupClause={actions.addGroupClauseAndFlushRule}
            dissolveGroupClause={actions.dissolveGroupClauseAndFlushRule}
            addClause={actions.addClauseAndFlushRule}
            updateClause={actions.updateClauseAndFlushRule}
            replaceClause={actions.replaceClauseAndFlushRule}
            deleteClause={actions.deleteClauseAndFlushRule}
            setAudienceAllUsers={actions.setAudienceAllUsersAndFlushRule}
            setAudienceCustomSegment={
              actions.setAudienceCustomSegmentAndFlushRule
            }
            setAudienceSegment={actions.setAudienceSegmentAndFlushRule}
            createSegmentFromRule={onCreateCallback => () =>
              actions.showModal(CREATE_SEGMENT_MODAL, {
                stepId: rule.id,
                createSegmentFromRule: actions.createSegmentFromRule,
                segments,
                onCreateCallback,
              })}
          />
        </Panel>
      </main>
    </div>
  );
}

function mapStateToProps(state, routeProps) {
  const isInCRX = includesCrxParam(routeProps);
  const stepId =
    routeProps.match.params.stepId || routeProps.match.params.ruleId;
  const step = selectAccountSatisfactionSurvey(state, stepId);
  return {
    isInCRX,
    meta: selectAccountMeta(state),
    rule: selectAccountRule(state, stepId),
    step,
    conditions: selectAccountStepConditions(state, stepId),
    segments: selectAccountSegments(state),
    contentStatus: selectFlowStatus(state, stepId),
    isInstalled: selectIsInstalled(state),
    loaded:
      selectAccountMetaSynced(state) &&
      selectAccountRulesSynced(state) &&
      selectAccountConditionsSynced(state) &&
      selectAccountSegmentsSynced(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...conditionsActions,
        ...segmentsActions,
        ...apiActions,
        ...currentModalActions,
        updateRule,
        updateAndFlushSatisfaction,
        setStepFrequency: (id, frequency) => updateRule(id, { frequency }),
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styled(SatisfactionCustomAudienceEdit)`
  hr {
    height: 0;
    border: none;
    border-bottom: 2px solid ${props => props.theme['$gray-1']};
    margin: 1.25rem 0 2rem;
  }

  .panel-body {
    .option {
      .content {
        width: 100%;
      }
    }

    .legend {
      display: flex;
      flex-direction: row;
      padding: 12px 23px 0;
      label {
        font-size: 12px;
        margin-right: 25px;
        color: ${props => props.theme['$gray-30']};
        i {
          margin-right: 8px;
          &.live {
            color: ${props => props.theme.$green};
          }
          &.draft {
            color: ${props => props.theme['$gray-30']};
          }
        }
      }
    }

    .content {
      padding: 5px 23px 0;
      .help-text {
        color: ${props => props.theme['$gray-30']};
        font-style: italic;
        margin: 5px 0;
        a {
          color: ${props => props.theme['$gray-30']};
          text-decoration: underline;
          cursor: pointer;
          &:hover {
            color: ${props => props.theme.$blue};
            text-decoration: underline;
          }
        }
      }
    }
  }

  .settings-header {
    display: flex;
    flex-direction: row;

    .title-container {
      display: flex;
      flex-direction: row;
      margin-bottom: 1.5em;
      align-items: center;

      .flow-status-tag {
        align-self: flex-start;
        margin: 16px 50px 0 10px;
      }

      fieldset {
        margin: 0;
        padding: 0;
        border: none;
        &:disabled {
          input {
            color: ${props => props.theme['$gray-50']};
            &::placeholder {
              color: ${props => props.theme['$gray-30']};
              font-style: italic;
            }
          }
        }
      }
    }
  }
`);
