import * as actionTypes from 'constants/view/actionTypes';

export function replaceAccountView(userId, view) {
  return {
    type: actionTypes.REPLACE_ACCOUNT_VIEW,
    payload: { userId, view },
  };
}

export function replaceAccountViews(views) {
  return {
    type: actionTypes.REPLACE_ACCOUNT_VIEWS,
    payload: views,
  };
}

export function setFlowLayoutType(type) {
  return {
    type: actionTypes.UPDATE_VIEW_AND_FLUSH_VIEW,
    payload: {
      key: 'flowLayout',
      changes: {
        flowLayout: type,
      },
    },
  };
}

export function setFlowStatusFilter(status) {
  return {
    type: actionTypes.UPDATE_VIEW_AND_FLUSH_VIEW,
    payload: {
      key: 'status',
      changes: { filters: { status } },
    },
  };
}

export function setFlowSegmentFilter(segment) {
  return {
    type: actionTypes.UPDATE_VIEW_AND_FLUSH_VIEW,
    payload: {
      key: 'segment',
      changes: { filters: { segment } },
    },
  };
}

export function setFlowCreatedByFilter(user) {
  return {
    type: actionTypes.UPDATE_VIEW_AND_FLUSH_VIEW,
    payload: { key: 'createdBy', changes: { filters: { createdBy: user } } },
  };
}

export function setFlowSearchFilter(searchString) {
  return {
    type: actionTypes.UPDATE_VIEW_AND_FLUSH_VIEW,
    payload: { changes: { filters: { search: searchString } } },
  };
}

export function setFlowTagsFilter(tagsFilter) {
  return {
    type: actionTypes.UPDATE_VIEW_AND_FLUSH_VIEW,
    payload: { key: 'tags', changes: { filters: { tags: tagsFilter } } },
  };
}

export function setFlowSortOption(sortOption) {
  return {
    type: actionTypes.UPDATE_VIEW_AND_FLUSH_VIEW,
    payload: {
      key: 'sort',
      changes: {
        sort: sortOption,
      },
    },
  };
}

export function handleFlowActions(stepActionType, stepActionHandler, data) {
  return {
    type: actionTypes.HANDLE_FLOW_ACTIONS,
    payload: { stepActionType, stepActionHandler, data },
  };
}
