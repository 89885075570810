import React from 'react';

import { ONBOARD_MANAGER } from 'constants/plans';

import {
  GradientBanner,
  FeatureItem,
  FeatureCheck,
  PlanName,
  PricePreCaption,
  PricePostCaption,
  StyledFeaturesCaption,
  PlanPrice,
  PlanDescription,
  FeatureList,
  HorizontalWrapper,
} from './styled';

const getPlanFeatures = plan => ({
  ...plan.features,
  ...(plan.id !== 'essentials' && {
    [ONBOARD_MANAGER]: plan.pricePlanId === 'standard',
  }),
});

const PlanBasicInfo = ({ plan, price, planLimit, selfService, isViewMode }) => {
  const isHighVolumePlan = planLimit === 'high-volume';

  const planFeatures = getPlanFeatures(plan);

  const isPaidAnnually = !plan.pricePostCaption && !isHighVolumePlan;

  const pricePostCaption = isPaidAnnually
    ? 'paid annually'
    : plan.pricePostCaption;

  const hasSpecialPrice = !plan.price && isHighVolumePlan;
  const specialPrice = hasSpecialPrice ? price : plan.price;

  return (
    <>
      <GradientBanner gradient={plan.gradient} />
      <PlanName color={plan.color}>{plan.name}</PlanName>
      {plan.pricePreCaption && (
        <PricePreCaption>
          <span>{plan.pricePreCaption}</span>
        </PricePreCaption>
      )}

      <PlanPrice>{specialPrice || `$${price}/mo`}</PlanPrice>

      {pricePostCaption && (
        <PricePostCaption>
          <span>{pricePostCaption}</span>
        </PricePostCaption>
      )}

      <HorizontalWrapper />

      {!isViewMode && (
        <PlanDescription selfService={selfService}>
          <span>{plan.description}</span>
        </PlanDescription>
      )}

      {plan.featuresCaption && (
        <StyledFeaturesCaption isViewMode={isViewMode}>
          {plan.featuresCaption}
        </StyledFeaturesCaption>
      )}

      <FeatureList>
        {Object.keys(planFeatures).map(feature => {
          return (
            <FeatureItem key={feature}>
              <FeatureCheck selected color={plan.color} icon="check-circle" />
              <div>{feature}</div>
            </FeatureItem>
          );
        })}
      </FeatureList>
    </>
  );
};

export default PlanBasicInfo;
