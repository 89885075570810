import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

function ProgressBar({
  className,
  completedSteps = [],
  currentStep,
  onClick,
  progressSteps,
  shouldMarkStepsCompleted,
}) {
  const progressBarSteps =
    progressSteps &&
    progressSteps.map((step, i) => {
      return {
        label: step,
        stepLabel: i + 1,
        completed: completedSteps[i] || false,
        completedLabel:
          completedSteps[i] && shouldMarkStepsCompleted ? '\u2713' : null,
      };
    });

  return (
    <div className={`${className} progress-bar`}>
      {progressBarSteps &&
        progressBarSteps.map((step, i) => {
          return (
            <div key={step.stepLabel} className="progress-step-wrapper">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <div
                role="button"
                tabIndex={-1}
                aria-label="Progress step"
                onClick={() => onClick(i)}
                className="progress-step"
              >
                <div
                  className={classNames(
                    currentStep === i ||
                      (currentStep > i && currentStep < progressSteps.length)
                      ? 'current-step-icon'
                      : '',
                    'progress-step-icon',
                    step.completed && shouldMarkStepsCompleted
                      ? 'step-icon-completed'
                      : ''
                  )}
                >
                  {step.completed && shouldMarkStepsCompleted
                    ? step.completedLabel
                    : step.stepLabel}
                </div>
                <span
                  className={classNames(
                    currentStep === i ? 'current-step-label' : '',
                    currentStep > i && currentStep < progressSteps.length
                      ? 'step-label-completed'
                      : ''
                  )}
                >
                  {step.label}
                </span>
              </div>
              {i + 1 !== progressSteps.length && (
                <div
                  className={classNames(
                    'progress-step-divider',
                    currentStep > i && currentStep < progressSteps.length
                      ? 'current-step-divider'
                      : ''
                  )}
                >
                  &bull;
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
}

export default styled(ProgressBar)`
  min-width: ${props => props.minWidth || '0'};
  border: none;

  &.progress-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: transparent;
    color: ${props => props.theme['$gray-70']};
    box-shadow: none;

    .progress-step-wrapper {
      display: flex;
      align-items: center;

      .progress-step {
        height: 50px;
        cursor: pointer;
        margin-right: 1rem;
        display: flex;
        align-items: center;

        .progress-step-icon {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 28px;
          height: 28px;

          border-radius: 50%;
          background-color: ${props => props.theme['$gray-20']};
          color: white;
          font-weight: 700;
          margin-right: 1rem;

          &.current-step-icon {
            background-color: ${props => props.theme.$green};
          }
          &.step-icon-completed {
            padding: 2px 6px;
          }
        }
      }

      &:last-child {
        .progress-step {
          margin-right: 0;
        }
      }

      .current-step-label,
      .current-step-divider,
      .step-label-completed {
        color: ${props => props.theme.$green};
      }

      .progress-step-divider {
        color: ${props => props.theme['$gray-3']};
        margin-right: 1rem;
      }
    }
  }
`;
