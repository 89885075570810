import styled from 'styled-components';
import { StatusLabel, ExternalLink } from '@studio/legacy-components';

export const LabelWrapper = styled.div`
  width: 200px;
  white-space: initial;
  font-weight: var(--normal);

  ${ExternalLink} {
    color: inherit;
    :hover {
      color: inherit;
    }
    text-decoration: underline;
  }
`;

export const UpgradeBadge = styled(StatusLabel)`
  font-weight: var(--normal);
  word-break: keep-all;
  user-select: none;
`;
