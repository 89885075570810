/* global SALESFORCE_SOLUTION_ID HUBSPOT_SOLUTION_ID ZENDESK_SUPPORT_SOLUTION_ID SLACK_SOLUTION_ID MARKETO_SOLUTION_ID */
import {
  takeEvery,
  select,
  call,
  put,
  fork,
  getContext,
} from 'redux-saga/effects';
import toast from 'next/lib/toast';
import { selectAccountId, selectAccountUuid } from 'reducers/account/meta';
import { selectIntegrations } from 'reducers/account/integrations';
import { throttleBy } from 'utils/sagas';
import { trackEvent } from 'actions/events';
import { navigate } from 'actions/routing';
import { reportError } from 'helpers/error-reporting';
import { selectUserId } from 'reducers/user';
import { rebundleAccount } from 'helpers/bundler';
import { replaceTemplatedString } from 'utils';
import {
  activateAndFlushIntegration,
  activateIntegrationInStore,
  activateIntegrationInStoreRollback,
  flushIntegrationToFirebase,
  removeAndFlushIntegration,
  removeIntegrationInStore,
  replaceAccountIntegrations,
} from 'actions/account/integrations';
import { promisaga } from 'utils/as-promised';

import {
  FLUSH_INTEGRATION_TO_FIREBASE,
  ACTIVATE_INTEGRATION_AND_FLUSH_INTEGRATION,
  REMOVE_INTEGRATION_AND_FLUSH_INTEGRATION,
  FETCH_INTEGRATION,
  TOGGLE_INTEGRATION_STATE,
} from 'constants/actionTypes';
import INTEGRATION_TYPES from 'constants/integration-types';

const transform = response =>
  response.reduce((acc, integration) => {
    const { type } = integration;
    acc[type] = integration;
    return acc;
  }, {});

export function* fetchIntegrations() {
  try {
    const api = yield getContext('api');
    const { data: response } = yield call(api.getIntegrations);
    yield put(replaceAccountIntegrations(transform(response)));
  } catch (error) {
    yield call(reportError, error);
  }
}

export function* flush(action) {
  const { integrationId } = action.payload;

  const integrations = yield select(selectIntegrations);

  const api = yield getContext('api');

  const integration = integrations[integrationId];

  yield integration
    ? call(api.setIntegration, integrationId, integration)
    : call(api.deleteIntegration, integrationId);
}

export function* activateIntegrationAndFlushIntegration(action) {
  const { integrationId } = action.payload;
  const accountId = yield select(selectAccountId);
  const userId = yield select(selectUserId);
  const integrationData = { integrationId, accountId, userId };

  const selectedIntegrationType = INTEGRATION_TYPES[integrationId];

  if (selectedIntegrationType.activationUrlTemplate) {
    const templateProps = {
      ...selectedIntegrationType,
      APC_accountId: accountId,
    };
    const activationUrl = replaceTemplatedString(
      selectedIntegrationType.activationUrlTemplate,
      templateProps
    );
    yield put(navigate(activationUrl, true));
    return;
  }

  try {
    yield put(activateIntegrationInStore(integrationData));

    yield call(flush, flushIntegrationToFirebase(integrationId));

    yield call(rebundleAccount, accountId);

    yield put(
      trackEvent('Activated integration', {
        integration: integrationId,
      })
    );
    yield call(toast.success, 'Activated integration.');
  } catch (error) {
    yield call(reportError, error);
    yield call(toast.error, 'Unable to activate integration.');

    yield put(removeIntegrationInStore(integrationId));
    yield call(flush, flushIntegrationToFirebase(integrationId));
  }
}

export function* removeIntegrationAndFlushIntegration(action) {
  const { integrationId } = action.payload;
  const accountId = yield select(selectAccountId);
  const integrations = yield select(selectIntegrations);
  const rollbackData = integrations[integrationId];

  try {
    yield put(removeIntegrationInStore(integrationId));
    yield call(flush, flushIntegrationToFirebase(integrationId));

    // As a last step, we notify the bundler. If this fails, we will revert the
    // integration in firebase as well.
    yield call(rebundleAccount, accountId);

    yield call(toast.success, 'Removed integration.');
  } catch (error) {
    yield call(reportError, error);
    yield call(toast.error, 'Unable to remove integration.');

    yield put(
      activateIntegrationInStoreRollback({
        [integrationId]: rollbackData,
      })
    );

    yield call(flush, flushIntegrationToFirebase(integrationId));
  }
}

export function* fetchIntegrationResponse(action) {
  const { integrationId } = action.payload;

  const integrations = yield getContext('integrations');

  const accountUuid = yield select(selectAccountUuid);
  const accountId = yield select(selectAccountId);

  const solutionIds = {
    'zendesk-support-two-way-integration': ZENDESK_SUPPORT_SOLUTION_ID,
    'hubspot-two-way-integration': HUBSPOT_SOLUTION_ID,
    'slack-integration': SLACK_SOLUTION_ID,
    salesforce: SALESFORCE_SOLUTION_ID,
    marketo: MARKETO_SOLUTION_ID,
  };

  const solutionId = solutionIds[integrationId];

  try {
    const solutionInstances = yield call(
      integrations.getSolutionInstances,
      accountId,
      accountUuid
    );

    const target = solutionInstances.find(
      ({ tray_solution_id }) => tray_solution_id === solutionId
    );

    if (target) {
      const { code } = yield call(
        integrations.getConfigurationCode,
        accountId,
        accountUuid
      );
      return { instanceId: target.id, code, enabled: target.enabled };
    }
  } catch (error) {
    yield call(reportError, error);
    yield call(
      toast.error,
      'We had a problem retrieving this integration. Please try again, and if the problem continues, contact us.'
    );
  }

  try {
    const instance = yield call(
      integrations.createSolutionInstance,
      solutionId,
      integrationId,
      accountId,
      accountUuid
    );

    return {
      instanceId: instance.instance_id,
      code: instance.code,
      enabled: false,
    };
  } catch (error) {
    yield call(reportError, error);
    yield call(
      toast.error,
      'We ran into an issue creating the integration. Please try again, and if the problem continues, contact us.'
    );

    throw error;
  }
}

export function* toggleIntegration(action) {
  const { integrationId, instanceId, enabled } = action.payload;
  const accountUuid = yield select(selectAccountUuid);
  const accountId = yield select(selectAccountId);

  const integrations = yield getContext('integrations');

  try {
    if (enabled.enabled) {
      yield call(
        integrations.enableInstance,
        instanceId,
        accountId,
        accountUuid
      );
      yield put(activateAndFlushIntegration(integrationId));
    } else {
      yield call(
        integrations.disableInstance,
        instanceId,
        accountId,
        accountUuid
      );
      yield put(removeAndFlushIntegration(integrationId));
    }
  } catch (error) {
    yield call(reportError, error);
    yield call(
      toast.error,
      'We had a problem activating this integration. Please try again, and if the problem continues, contact us.'
    );
  }
}

export default function* integrationsSaga() {
  yield fork(
    throttleBy,
    'integrationId',
    500,
    FLUSH_INTEGRATION_TO_FIREBASE,
    flush
  );
  yield takeEvery(FETCH_INTEGRATION, promisaga(fetchIntegrationResponse));
  yield takeEvery(TOGGLE_INTEGRATION_STATE, promisaga(toggleIntegration));
  yield takeEvery(
    ACTIVATE_INTEGRATION_AND_FLUSH_INTEGRATION,
    activateIntegrationAndFlushIntegration
  );
  yield takeEvery(
    REMOVE_INTEGRATION_AND_FLUSH_INTEGRATION,
    removeIntegrationAndFlushIntegration
  );
}
