import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ContentStatus, MoreMenu } from '@studio/legacy-components';
import { asRequest } from 'next/entities/requests';
import { Shape as FlowShape } from 'next/entities/flows';
import { Shape as PinShape } from 'next/entities/experiences';
import { Shape as TagShape } from 'next/entities/tags';
import ExperimentBadge from 'next/components/ExperimentBadge';
import { renderable } from './lib';
import Datetime from './Datetime';
import TagsTeaser from './TagsTeaser';
import {
  ActionButton,
  Actions,
  CellRow,
  CellWrapper,
  Name,
  Properties,
  PropertyLoading,
  PropertySeparator,
  Tags,
} from './styled';

export function Cell({
  actions,
  experience: {
    createdAt,
    experiment,
    id,
    name,
    published,
    publishedAt,
    state,
    updatedAt,
  },
  tags,
  to,
  type,
  settingsLink,
}) {
  const linkTo = type === 'mobile' ? 'mobile/flows' : type;
  const settingsPath = settingsLink ?? `/${linkTo}/${id}/settings`;

  return (
    <CellWrapper>
      <CellRow>
        <Name as={Link} title={name} to={to}>
          {name}
        </Name>

        <Actions>
          {published && (
            <ActionButton
              aria-label="Settings"
              forwardedAs={Link}
              icon="cog"
              to={settingsPath}
            />
          )}

          {actions && <MoreMenu small>{actions}</MoreMenu>}
        </Actions>
      </CellRow>

      <CellRow>
        <Properties secondary>
          <Datetime
            prefix={
              !updatedAt || createdAt === updatedAt ? 'Created ' : 'Updated '
            }
            value={updatedAt || createdAt}
          />

          {published && publishedAt && (
            <>
              <PropertySeparator />
              <Datetime prefix="Published" value={publishedAt} />
            </>
          )}
        </Properties>
      </CellRow>

      <CellRow>
        <ContentStatus published={published} state={state} />
        <ExperimentBadge
          experimentId={experiment?.id}
          experimentName={experiment?.name}
          experimentState={experiment?.state}
          flowState={state}
        />
        <Tags>
          {renderable(tags) && (
            <>
              {tags.loading && (
                <PropertyLoading aria-label="Loading tags" tag />
              )}
              {/* TODO: Add click handlers to tag management */}
              {!tags.loading && <TagsTeaser limit={1} tags={tags.data} />}
            </>
          )}
        </Tags>
      </CellRow>
    </CellWrapper>
  );
}

Cell.propTypes = {
  actions: PropTypes.node,
  experience: PropTypes.oneOfType([FlowShape, PinShape]),
  tags: asRequest(PropTypes.arrayOf(TagShape)),
  to: PropTypes.string,
  type: PropTypes.oneOf(['flows', 'pins', 'mobile', 'banner']),
  settingsLink: PropTypes.string,
};

export default Cell;
