import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';
import Input from 'components/Common/Forms/Input';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  width: 100%;

  ${Input} {
    height: 38px;
    width: 100%;
  }
`;

const DeleteClause = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;

  ${Icon} {
    color: var(--disabled-dark);
    cursor: pointer;
    font-size: 1em;

    &:hover {
      color: var(--danger);
    }
  }
`;

export default function Clause({
  children,
  clause,
  canDeleteClause,
  deleteClause,
  className = '',
}) {
  return (
    <Wrapper className={className}>
      {children}

      {canDeleteClause && (
        <DeleteClause>
          <Icon
            aria-label="Delete clause"
            icon="trash-alt"
            onClick={() => deleteClause(clause.id)}
            role="button"
          />
        </DeleteClause>
      )}
    </Wrapper>
  );
}

Clause.propTypes = {
  canDeleteClause: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  clause: PropTypes.shape({
    id: PropTypes.string,
  }),
  deleteClause: PropTypes.func,
};
