import { createCollectionReducer } from 'reducers/account/collections';
import { selectIsSynced } from 'reducers/account/requests';
import { TRANSLATIONS_REMOVED } from 'actions/account/translations';

const TYPE = 'translations';

const reducer = createCollectionReducer(TYPE);

export default function (state, action) {
  switch (action.type) {
    case TRANSLATIONS_REMOVED:
      return {};
    default:
      return reducer(state, action);
  }
}

export const selectTranslations = state => state.account.translations;
export const selectFlowTranslationByLocale = (state, localeId) =>
  Object.values(selectTranslations(state) || {}).find(
    t => t.locale_id === localeId
  );
export const selectAreTranslationsSynced = state => selectIsSynced(state, TYPE);
