import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  conditionNames,
  filterAudienceTargetingClauses,
  getFilteredClauseTree,
  propertyConditionNames,
} from '@appcues/libcues';
import {
  CButton,
  CSkeleton,
  Notice,
  NOTICE_TYPES,
} from '@appcues/component-library';

import { Link } from '@studio/legacy-components';
import Radio from 'components/Common/UI/Forms/Radio';
import SelectSegmentDropDown from 'components/Common/SelectSegmentDropDown';
import GroupClause from 'components/Common/Clauses/Group';
import { getEmptyClauseForConditionName } from 'utils/conditions';
import { navigate } from 'actions/routing';

const Wrapper = styled.div`
  span.saved-rule {
    font-style: italic;
  }

  .segment-row {
    display: flex;
    margin-left: -10px;
    & > * {
      margin-left: 10px;
    }
  }

  .create-segments-link:hover {
    text-decoration: none;
  }
`;

export class AudienceTargeting extends Component {
  state = {};

  UNSAFE_componentWillMount() {
    // eslint-disable-next-line new-cap
    this.UNSAFE_componentWillReceiveProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { clauses } = nextProps;
    const { lastRadioClicked } = this.state;

    const userPropertyClauses = getFilteredClauseTree(
      filterAudienceTargetingClauses,
      clauses
    );

    let option = 'any';

    if (userPropertyClauses.length > 2) {
      option = 'properties';
    } else if (userPropertyClauses.length === 2) {
      option =
        userPropertyClauses.some(
          it => it.conditionName === conditionNames.SEGMENTS && !it.negative
        ) && lastRadioClicked !== 'properties'
          ? 'segment'
          : 'properties';
    }

    this.setState({ userPropertyClauses, initialRadioOption: option });
  }

  onSelectSegment = segmentClauseId => value => {
    const { updateClause, stepId } = this.props;

    updateClause(stepId, segmentClauseId, {
      segment: value,
    });
  };

  onClickAddCriteria = () => {
    const { addClause, stepId, clauses } = this.props;

    this.setState({ lastRadioClicked: 'properties' });
    addClause(
      stepId,
      clauses.find(it => it.conditionName === conditionNames.SEGMENTS).parentId,
      getEmptyClauseForConditionName(propertyConditionNames.PROPERTIES)
    );
  };

  render() {
    // @todo get actions and bind dispatch here... don't make your mommy do it

    const {
      hideSaveAsSegmentOption,
      stepId,
      segments,
      addGroupClause,
      dissolveGroupClause,
      addClause,
      updateClause,
      replaceClause,
      deleteClause,
      setAudienceAllUsers,
      setAudienceCustomSegment,
      setAudienceSegment,
      createSegmentFromRule,
      role,
      hideDescriptionText,
    } = this.props;

    const { initialRadioOption, userPropertyClauses, deletedClauses } =
      this.state;

    const radioOption = initialRadioOption;

    return (
      <Wrapper className="audience-targeting">
        {!hideDescriptionText && <h4>Who should see this content?</h4>}
        <Radio
          label="All users"
          checked={radioOption === 'any'}
          onClick={() => {
            if (radioOption === 'properties') {
              this.setState({
                deletedClauses: userPropertyClauses,
              });
            }
            this.setState({
              lastRadioClicked: 'any',
            });
            setAudienceAllUsers(stepId);
          }}
        />

        {setAudienceSegment && (
          <>
            <Radio
              label="Users in a Segment"
              onClick={() => {
                if (radioOption === 'properties') {
                  this.setState({
                    deletedClauses: userPropertyClauses,
                  });
                }
                this.setState({
                  lastRadioClicked: 'segment',
                });
                setAudienceSegment(stepId);
              }}
              checked={radioOption === 'segment'}
            />
            {radioOption === 'segment' && (
              <div className="content">
                {(() => {
                  const segmentClause = userPropertyClauses.find(
                    it => it.conditionName === conditionNames.SEGMENTS
                  );
                  if (
                    (segmentClause && segmentClause.segment) ||
                    Object.keys(segments || {}).length > 0
                  ) {
                    return (
                      <div className="segment-row">
                        {(segmentClause && (
                          <SelectSegmentDropDown
                            value={segmentClause ? segmentClause.segment : null}
                            onChange={this.onSelectSegment(segmentClause.id)}
                          />
                        )) || <CSkeleton width={320} height={38} />}
                        <CButton onClick={this.onClickAddCriteria}>
                          Add Criteria
                        </CButton>
                      </div>
                    );
                  }
                  // If we're in the CRX, we want to link to the segments page
                  // in a new tab, rather than navigating within this tab.
                  return (
                    <Notice type={NOTICE_TYPES.default}>
                      You don&apos;t have any saved segments.&nbsp;
                    </Notice>
                  );
                })()}
                <div className="help-text">
                  You can view and manage the conditions for this segment on the{' '}
                  <Link to="/segments">Segments page</Link>.
                </div>
              </div>
            )}
          </>
        )}
        <Radio
          label="Specific users"
          onClick={() => {
            if (radioOption !== 'properties') {
              setAudienceCustomSegment(stepId, deletedClauses);
            }
            this.setState({
              lastRadioClicked: 'properties',
            });
          }}
          checked={radioOption === 'properties'}
        />
        {radioOption === 'properties' && (
          <div className="content">
            <GroupClause
              role={role}
              hideSaveAsSegmentOption={hideSaveAsSegmentOption}
              clauses={userPropertyClauses}
              segments={segments}
              isRootNode
              groupType="segment"
              addGroupClause={(parentId, booleanOperator, clauses) =>
                addGroupClause(stepId, parentId, booleanOperator, clauses)
              }
              dissolveGroupClause={clauseId =>
                dissolveGroupClause(stepId, clauseId)
              }
              addClause={(parentId, clause) =>
                addClause(stepId, parentId, clause)
              }
              updateClause={(clauseId, changes) =>
                updateClause(stepId, clauseId, changes)
              }
              replaceClause={(clauseId, clause) =>
                replaceClause(stepId, clauseId, clause)
              }
              deleteClause={clauseId => deleteClause(stepId, clauseId)}
              applySegment={
                setAudienceSegment
                  ? segmentId => setAudienceSegment(stepId, segmentId)
                  : null
              }
              createSegment={createSegmentFromRule(() =>
                this.setState({ lastRadioClicked: 'segment' })
              )}
            />
          </div>
        )}
      </Wrapper>
    );
  }
}

export default connect(null, { navigate })(AudienceTargeting);
