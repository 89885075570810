import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CPanel } from '@appcues/component-library';
import moment from 'moment';
import { ExternalLink, Icon } from '@studio/legacy-components';

import QueryResults from 'components/Common/QueryResults';
import Table from 'components/Common/UI/Table';
import Loader from 'components/Common/Loader';
import { selectFlow } from 'reducers/account/flows';
import CoolTip from 'components/Common/CoolTip';
import { selectVersionedStepChildren } from 'reducers/account/versionedFlows';
import { getVersionedFlow } from 'actions/account/versionedFlows';

import IndexIndicator from './IndexIndicator';
import StepByStepProgressBar from './StepByStepProgressBar';

const STEP_BY_STEP_OVERVIEW_DOC_URL =
  'https://docs.appcues.com/article/693-step-by-step-overview';
const STEP_CHILD_ISSUE_TOOLTIP =
  'When Appcues cannot display a step, due to a missing or incorrect selector.';

const StepChildDescriptorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  text-transform: capitalize;
`;

const PrefixIcon = styled(Icon)`
  margin-left: 4px;
`;

const StyledCPanel = styled(CPanel)`
  h2 {
    color: var(--foreground-primary);
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-height-small);
  }
`;

const StepDescriptorText = ({ stepType }) => {
  if (stepType === 'shorty') {
    return 'Slideout';
  }
  if (stepType === 'hotspot-group') {
    return 'Hotspot';
  }
  if (stepType === 'tooltip-group') {
    return 'Tooltip';
  }
  return stepType;
};

export const StepByStepTable = ({ stepChildren, results, onLoad }) => {
  useEffect(() => void onLoad(), []);

  if (!results) {
    return (
      <CPanel>
        <Loader margin="1em" />
      </CPanel>
    );
  }

  const tableData = stepChildren.map(({ id, stepType, createdAt }, index) => {
    const {
      step_child_id = id,
      step_child_shown_users = 0,
      step_child_error_users = 0,
      step_child_completed_users = 0,
    } = results.find(({ step_child_id: stepChildId }) => stepChildId === id) ||
    {};
    return {
      index,
      stepType,
      createdAt,
      step_child_id,
      step_child_shown_users,
      step_child_error_users,
      step_child_completed_users,
    };
  });

  return (
    <StyledCPanel title="Step breakdown">
      <p>
        Data counted for unique users. Note that if steps were added, reordered,
        or removed, there may be discrepancies in the data.{' '}
        <ExternalLink href={STEP_BY_STEP_OVERVIEW_DOC_URL}>
          Learn more
        </ExternalLink>
      </p>
      <Table
        containerStyles={{ marginTop: 20 }}
        panel
        defaultSortColumn="index"
        defaultSortDirection="asc"
        data={tableData}
        columns={[
          {
            Header: 'Step',
            accessor: 'index',
            Cell: function StepChildDescriptor({
              row: {
                original: { index, stepType },
              },
            }) {
              return (
                <StepChildDescriptorWrapper>
                  <IndexIndicator>{index + 1}</IndexIndicator>
                  <StepDescriptorText stepType={stepType} />
                </StepChildDescriptorWrapper>
              );
            },
          },
          {
            Header: 'Created',
            maxWidth: 100,
            accessor: 'createdAt',
            Cell: function ValueDescriptor({
              row: {
                original: { createdAt },
              },
            }) {
              return moment(createdAt).format('MMM DD, YYYY');
            },
          },
          {
            Header: 'Shown',
            maxWidth: 100,
            accessor: 'step_child_shown_users',
            Cell: function StepChildShown({
              row: {
                original: { step_child_shown_users },
              },
            }) {
              return <b>{step_child_shown_users}</b>;
            },
          },
          {
            Header: (
              <CoolTip tip={STEP_CHILD_ISSUE_TOOLTIP}>
                Issues <PrefixIcon icon="info-circle" />
              </CoolTip>
            ),
            maxWidth: 100,
            accessor: 'step_child_error_users',
            Cell: ({
              row: {
                original: { step_child_error_users },
              },
            }) => step_child_error_users,
          },
          {
            Header: 'Completed',
            maxWidth: 100,
            accessor: 'step_child_completed_users',
            Cell: ({
              row: {
                original: { step_child_completed_users },
              },
            }) => step_child_completed_users,
          },
          {
            Header: 'Completion Rate',
            maxWidth: 100,
            accessor: '',
            Cell: ({
              row: {
                original: {
                  step_child_shown_users,
                  step_child_completed_users,
                },
              },
            }) =>
              step_child_shown_users > 0
                ? `${Math.floor(
                    100 * (step_child_completed_users / step_child_shown_users)
                  )}%`
                : '-',
          },
          {
            Header: 'Completion Funnel',
            accessor: '',
            minWidth: '30%',
            Cell: function Funnel({
              row: {
                original: {
                  step_child_shown_users,
                  step_child_completed_users,
                  step_child_error_users,
                  index,
                },
              },
            }) {
              return (
                <StepByStepProgressBar
                  originallyShown={
                    tableData.length > 0
                      ? tableData[0].step_child_shown_users
                      : 0
                  }
                  index={index}
                  shown={step_child_shown_users}
                  completed={step_child_completed_users}
                  issues={step_child_error_users}
                />
              );
            },
          },
        ]}
      />
    </StyledCPanel>
  );
};

StepByStepTable.propTypes = {
  query: PropTypes.shape({
    metrics: PropTypes.arrayOf(PropTypes.string),
    conditions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    dimensions: PropTypes.arrayOf(PropTypes.string),
    startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  stepChildren: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      stepType: PropTypes.string,
      createdAt: PropTypes.number,
    })
  ),
  results: PropTypes.arrayOf(
    PropTypes.shape({
      step_child_shown_users: PropTypes.number,
      step_child_completed_users: PropTypes.number,
      step_child_error_users: PropTypes.number,
      step_child_id: PropTypes.string,
    })
  ),
  onLoad: PropTypes.func,
};

const StepByStepTableWithAnalytics = ({
  flowId,
  startTime,
  endTime,
  segmentId,
  ...props
}) => (
  <QueryResults
    query={{
      metrics: [
        'step_child_shown_users',
        'step_child_completed_users',
        'step_child_error_users',
      ],
      conditions: [
        ['flow_id', '==', flowId],
        ['step_child_id', 'is not null'],
        ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
      ],
      dimensions: ['step_child_id'],
      start_time: startTime,
      end_time: endTime,
      meta: {
        tags: {
          feature: 'Flow analytics',
          page: 'Flow analytics',
          component: 'StepByStepTable',
          description: 'Step child performance table',
        },
      },
    }}
  >
    {results => <StepByStepTable results={results} {...props} />}
  </QueryResults>
);

StepByStepTableWithAnalytics.propTypes = {
  flowId: PropTypes.string,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = (state, { flowId }) => {
  const { publishedAt } = selectFlow(state, flowId);
  const versionId = new Date(publishedAt).getTime();
  const versionedStepChildren = selectVersionedStepChildren(state, {
    flowId,
    versionId,
  });

  return {
    versionId,
    stepChildren: versionedStepChildren,
  };
};

const mapDispatchToProps = {
  getVersionedFlow,
};

const mergeProps = (
  { versionId, stepChildren },
  dispatchProps,
  { flowId, ...ownProps }
) => ({
  ...ownProps,
  flowId,
  stepChildren,
  onLoad: () =>
    void dispatchProps.getVersionedFlow({
      flowId,
      versionId,
    }),
});

const ConnectedStepByStepTableWithAnalytics = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(StepByStepTableWithAnalytics);

ConnectedStepByStepTableWithAnalytics.propTypes = {
  flowId: PropTypes.string,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  segmentId: PropTypes.string,
};

export default ConnectedStepByStepTableWithAnalytics;
