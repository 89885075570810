import {
  REQUEST_SENT,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from 'constants/account/collections';

const initialState = {};

const initialRequestStatus = {
  fetching: false,
  errored: false,
  synced: false,
};

function requestStatus(state = initialRequestStatus, action) {
  switch (action.type) {
    case REQUEST_SENT:
      return {
        ...state,
        fetching: true,
        synced: false,
        errored: false,
      };
    case REQUEST_RESOLVED:
      return {
        errored: false,
        fetching: false,
        synced: true,
      };
    case REQUEST_REJECTED:
      return {
        ...state,
        fetching: false,
        errored: true,
      };
    default:
      return state;
  }
}

export default function requests(state = initialState, action) {
  const { type: entityType } = action.meta || {};

  if (!entityType) {
    return state;
  }

  return {
    ...state,
    [entityType]: requestStatus(state[entityType], action),
  };
}

function selectRequests(state, type) {
  return state.account.requests[type] || {};
}

export function selectIsFetching(state, type) {
  return !!selectRequests(state, type).fetching;
}

export function selectIsSynced(state, type) {
  return !!selectRequests(state, type).synced;
}

export function selectIsErrored(state, type) {
  return !!selectRequests(state, type).errored;
}

export function createRequestSelector(type) {
  return state => {
    const { synced, errored, fetching } = selectRequests(state, type);
    return !!(errored || !(synced || fetching));
  };
}
