import React from 'react';
import slideOne from 'assets/images/slide-1.png';
import slideTwo from 'assets/images/slide-2.png';
import Carousel from 'components/Common/Carousel';

export default function CarouselPreview() {
  const items = [
    <img key="Slide 0" alt="Slide 0" src={slideOne} />,
    <img key="Slide 1" alt="Slide 1" src={slideTwo} />,
    <img key="Slide 2" alt="Slide 2" src={slideOne} />,
    <img key="Slide 3" alt="Slide 3" src={slideTwo} />,
    <img key="Slide 4" alt="Slide 4" src={slideOne} />,
    <img key="Slide 5" alt="Slide 5" src={slideTwo} />,
    <img key="Slide 6" alt="Slide 6" src={slideOne} />,
  ];
  return (
    <div style={{ height: '250px' }}>
      <Carousel items={items} />
    </div>
  );
}
