import React from 'react';
import styled from 'styled-components';

import { CDropdownButton, Text } from '@appcues/component-library';
import { ExternalLink, Icon } from '@studio/legacy-components';
import { iconAllUrl } from 'constants/segments';

const LEARN_LIFECYCLE_URL = 'https://appcues.com/product-led-growth-flywheel';
const LIFECYCLE_TOUR_FLOW_ID = '-Lio4es2yK4lIe_HHsjc';
const FEEDBACK_TOUR_FLOW_ID = '-Lio6a1yg0vGU3O2H8fz';

const Trigger = styled(CDropdownButton)`
  line-height: 24px;

  button {
    padding-left: 12px;
  }

  .menu {
    padding-top: 17px;
    padding-bottom: 18px;

    li {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
`;

const Image = styled.img`
  margin-right: 10px;
`;

const Option = styled(Text)`
  align-items: center;
  display: flex;
  gap: 8px;

  ${ExternalLink} {
    --link-color: inherit;
    --link-font-weight: var(--normal);
    --link-hover-color: inherit;
    --link-text-decoration: none;
    --link-visited-color: inherit;

    align-items: inherit;
    display: inherit;
    gap: inherit;
    justify-content: center;
  }

  ${Image} {
    margin-right: 0;
  }
`;

const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;

  ${({ $color }) => $color && `color: ${$color};`}
`;

const handleTourClick = () => {
  window.Appcues.show(LIFECYCLE_TOUR_FLOW_ID);
};

const handleFeedbackClick = () => {
  window.Appcues.show(FEEDBACK_TOUR_FLOW_ID);
};

const options = [
  <Option key="tour" onClick={handleTourClick}>
    <IconWrapper $color="var(--blurple)">
      <Icon icon="question-circle" title="help icon" />
    </IconWrapper>
    Take a tour
  </Option>,

  <Option key="learn">
    <ExternalLink href={LEARN_LIFECYCLE_URL} icon={false}>
      <Image src={iconAllUrl} alt="user lifecycle icon" />
      Learn about the lifecycle
    </ExternalLink>
  </Option>,

  <Option key="feedback" onClick={handleFeedbackClick}>
    <IconWrapper $color="var(--orange-line)">
      <Icon icon="comment-alt" title="comment icon" />
    </IconWrapper>
    Send us feedback
  </Option>,
];

export default function LearnMoreButton() {
  return (
    <Trigger float="right" options={options} paddingRight="16px">
      <Image src={iconAllUrl} alt="user lifecycle icon" />
      Learn More
    </Trigger>
  );
}
