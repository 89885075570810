import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BaseHeader, { HeaderPropTypes } from 'next/components/Header';
import { selectUser } from 'next/entities/users';
import { selectSchedule } from 'next/entities/schedules';
import { ScheduleType } from 'next/components/ExperienceScheduling/types';
import { selectFlow } from 'next/entities/flows';

export function FlowHeader({
  appId,
  id,
  name,
  state,
  currentPage,
  published,
  createdAt,
  createdByName,
  updatedAt,
  updatedByName,
  renderActions,
  experiment,
  schedule,
}) {
  return (
    <BaseHeader
      id={id}
      name={name}
      state={state}
      schedule={schedule}
      published={published}
      appId={appId}
      experiment={experiment}
      returnUrl={
        currentPage === 'settings' && published
          ? `/flows/${id}/analytics`
          : `/flows`
      }
      title={currentPage === 'settings' && published ? 'Analytics' : 'Flows'}
      createdAt={createdAt}
      createdByName={createdByName}
      updatedAt={updatedAt}
      updatedByName={updatedByName}
      renderActions={() =>
        renderActions?.({
          id,
          updatedAt,
          published,
          state,
        })
      }
    />
  );
}

FlowHeader.propTypes = {
  ...HeaderPropTypes,
  id: PropTypes.string,
  appId: PropTypes.string,
  schedule: ScheduleType,
};

const mapStateToProps = (state, { id }) => {
  const {
    appId,
    published,
    state: flowState,
    updatedAt,
    updatedBy,
    createdAt,
    createdBy,
    name,
    previewUrl,
    experiment,
  } = selectFlow(state, id) || {};
  const createdByUser = selectUser(state, createdBy);
  const updatedByUser = selectUser(state, updatedBy);

  return {
    appId,
    id,
    name,
    state: flowState,
    published,
    createdAt,
    createdByName: createdByUser?.fullname ?? createdBy,
    updatedAt,
    updatedByName: updatedByUser?.fullname ?? updatedBy,
    previewUrl,
    experiment,
    schedule: selectSchedule(state),
  };
};

export default connect(mapStateToProps)(FlowHeader);
