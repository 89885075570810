import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import { takeLeadingPromised } from 'next/lib/effects';
import { logout } from 'next/lib/auth';
import { postLogout } from 'next/lib/crx';
import { patterns, reject, replace, LOGOUT } from './actions';

function* logoutUser() {
  try {
    yield call(logout);
    yield call(postLogout);
  } catch {
    // TODO: Handle error
  }
}

function* updateUser({ payload: { id, delta } }) {
  try {
    const api = yield getContext('api');
    const user = yield call(api.updateUser, id, delta);

    yield put(replace(user));

    return user;
  } catch (error) {
    yield put(reject(id, error));
    throw error;
  }
}

export default function* saga() {
  yield takeLeadingPromised(patterns.update, updateUser);
  yield takeLatest(LOGOUT, logoutUser);
}
