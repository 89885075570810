import React from 'react';
import styled from 'styled-components';
import {
  STEP_TYPE_IDS_WITHOUT_CHILDREN,
  STEP_TYPES,
} from 'constants/stepTypes';

const StepPreviewHeader = ({
  childIndex,
  stepTypeId,
  totalSteps,
  className,
}) => {
  const step = STEP_TYPES[stepTypeId];
  return (
    <div className={className}>
      {childIndex === 0 && (
        <FirstStepHeader
          step={step}
          stepTypeId={stepTypeId}
          totalSteps={totalSteps}
        />
      )}
    </div>
  );
};

const FirstStepHeader = ({ step, stepTypeId, totalSteps }) => {
  return (
    <div className="stepGroupTitle">
      {step.childNoun}
      {!STEP_TYPE_IDS_WITHOUT_CHILDREN.has(stepTypeId) && (
        <span>
          &nbsp;Group
          <span className="totalSteps">&nbsp;({totalSteps})</span>
        </span>
      )}
    </div>
  );
};

export default styled(StepPreviewHeader)`
  min-height: 20px;
  margin-bottom: 10px;

  display: flex;
  justify-content: center;

  .stepGroupTitle {
    font-weight: bold;
  }

  .totalSteps {
    font-size: 0.9em;
  }
`;
