import React from 'react';
import styled from 'styled-components';
import { EmptyState } from '@studio/legacy-components';
import EmptyFlowsIllustration from './EmptyFlowsIllustration';
import CreateExperience from './CreateExperience';

const Wrapper = styled.div`
  max-width: 620px;
  margin: 48px auto;
`;

const Empty = () => (
  <Wrapper>
    <EmptyState
      action={<CreateExperience />}
      headline="Build a Flow"
      image={<EmptyFlowsIllustration />}
    >
      Flows are flexible experiences that can help guide your customers through
      onboarding, announce new features, and more.
    </EmptyState>
  </Wrapper>
);

export default Empty;
