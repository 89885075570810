import React, { useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { NOTICE_TYPES, CBanner } from '@appcues/component-library';
import { Select, Icon } from '@studio/legacy-components';
import SelectGoal from 'components/Common/SelectGoal';

const ExistingGoal = styled.div`
  align-items: center;
  display: flex;

  ${Select} {
    width: calc(100% / 3 - 10px);
    margin-left: calc(100% / 3 + 5px);
    margin-top: 5px;
  }
`;

const disableButton = boolean => (boolean ? 'disabled' : null);

function GoalSetting({
  accountGoals,
  goals,
  showDefineGoalModal,
  className,
  setGoal,
  stepId,
  removeGoalFromRule,
  disableSetNoGoal,
}) {
  const [shouldWarnNoAccountGoals, setShouldWarnNoAccountGoals] =
    useState(false);

  const currentGoalId = goals ? goals[0] : null;
  const accountHasGoals = Object.keys(accountGoals).length > 0;
  const isNoGoalOptionActive = !goals || goals.length === 0;
  const isExistingGoalOptionActive = goals && goals.length > 0;

  const noGoalClass = isNoGoalOptionActive ? 'active' : null;
  const existingGoalClass = isExistingGoalOptionActive ? 'active' : null;

  return (
    <div className={classNames('goal-setting', className)}>
      {isNoGoalOptionActive && (
        <CBanner
          marginBottom={25}
          text="Note: We recommend you define a goal so you can track more than just views and completions."
        />
      )}
      {shouldWarnNoAccountGoals && !accountHasGoals && (
        <CBanner
          type={NOTICE_TYPES.warning}
          marginBottom={25}
          text="Oops: Your account does not have any existing goals yet. Try
            defining a goal."
        />
      )}
      {currentGoalId &&
        accountGoals[currentGoalId] &&
        accountGoals[currentGoalId].disabled && (
          <CBanner type={NOTICE_TYPES.warning} marginBottom={25}>
            <CBanner.Content>
              <strong>Goal Inactive: </strong>
              The selected goal is not currently being tracked by Appcues.
            </CBanner.Content>
          </CBanner>
        )}
      <div className="goal-options">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          role="radio"
          tabIndex={0}
          aria-checked={isNoGoalOptionActive}
          className={classNames(
            'goal-option',
            noGoalClass,
            disableButton(disableSetNoGoal)
          )}
          onClick={() => removeGoalFromRule(stepId)}
        >
          <div className="goal-option-container">
            <Icon icon="eye" />
            <div className="option-content">
              <strong>No Goal</strong>
              <p>Just track views and completion</p>
            </div>
          </div>
        </div>

        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          role="radio"
          tabIndex={-1}
          aria-checked={isExistingGoalOptionActive}
          className={classNames(
            'goal-option',
            existingGoalClass,
            disableButton(!accountHasGoals)
          )}
          onClick={() => {
            if (accountHasGoals) {
              setGoal(stepId, null);
            } else {
              setShouldWarnNoAccountGoals(true);
            }
          }}
        >
          <div className="goal-option-container">
            <Icon icon="bullseye" />
            <div className="option-content">
              <strong>Choose An Existing Goal</strong>
              <p>Pick a goal you&#8217;ve already created</p>
            </div>
          </div>
        </div>

        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          role="button"
          tabIndex={-1}
          className="goal-option"
          onClick={showDefineGoalModal}
        >
          <div className="goal-option-container">
            <Icon icon="plus" />
            <div className="option-content">
              <strong>Define a New Goal</strong>
              <p>Measure what users do afterward</p>
            </div>
          </div>
        </div>
      </div>
      <ExistingGoal>
        {isExistingGoalOptionActive && (
          <SelectGoal
            value={currentGoalId || ''}
            onChange={value => setGoal(stepId, value)}
          />
        )}
      </ExistingGoal>
    </div>
  );
}

export default styled(GoalSetting)`
  .goal-options {
    display: flex;
    flex-direction: row;
    width: 100%;

    div.goal-option {
      display: flex;
      align-self: center;
      flex-direction: column;
      align-items: center;
      color: ${props => props.theme['$gray-30']};
      border: 2px solid ${props => props.theme['$gray-3']};
      border-radius: 4px;
      width: calc(100% / 3);
      padding: 16px 5px;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 16px;
      }
      &:hover:not(.active):not(:active):not(.disabled) {
        color: ${props => props.theme.$blue};
        border: 2px solid ${props => props.theme.$blue};
        strong {
          color: ${props => props.theme.$blue};
        }
      }
      &.active,
      :active {
        color: white;
        background: ${props => props.theme.$blue};
        border: 2px solid ${props => props.theme.$blue};
        .option-content > strong {
          color: white !important;
        }
      }
      .goal-option-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        svg {
          font-size: 36px;
          margin-right: 18px;
          margin-left: 8px;
        }
        .option-content {
          display: flex;
          flex-direction: column;
          strong {
            color: ${props => props.theme['$gray-50']};
          }
          p {
            margin: 0.25em 0 0;
            font-size: ${props => props.theme['$font-size-small']};
          }
        }
      }
      &.disabled {
        pointer-events: none;
      }
    }
  }
`;
