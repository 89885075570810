import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Select } from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';
import AudienceBaseClause from 'components/Common/Clauses/AudienceBase';
import SelectEvent from 'components/Common/SelectEvent';
import UserHistoryDateRange from 'components/Common/UserHistoryDateRange';
import { SubClause } from 'components/Common/Clauses/styled';

const OPTIONS = [
  { value: false, label: 'has occurred' },
  { value: true, label: 'has not occurred' },
];

const Wrapper = styled.div`
  width: 100%;
`;

export default function EventClause(props) {
  const { clause, updateClause } = props;
  const [showAdditionalInfo, toggle] = useToggle();

  const show =
    showAdditionalInfo == null
      ? Number.parseInt(clause.times, 10) > 1 ||
        Number.parseInt(clause.value, 10) > 0
      : showAdditionalInfo;

  const value = Object.prototype.hasOwnProperty.call(clause, 'negative')
    ? clause.negative
    : false;

  const selected = OPTIONS.find(operator => operator.value === value);

  return (
    <Wrapper>
      <AudienceBaseClause {...props}>
        <SubClause>
          <SelectEvent
            value={clause.event}
            onChange={opt => updateClause(clause.id, { event: opt.value })}
          />

          <Select
            onChange={option =>
              updateClause(clause.id, { negative: option.value })
            }
            options={OPTIONS}
            value={selected}
          />

          <Button kind="secondary" onClick={toggle}>
            {show ? `Hide Options` : `Show Options`}
          </Button>
        </SubClause>
      </AudienceBaseClause>

      {show && (
        <UserHistoryDateRange clause={clause} updateClause={updateClause} />
      )}
    </Wrapper>
  );
}

EventClause.propTypes = {
  clause: PropTypes.shape({
    event: PropTypes.string,
    id: PropTypes.string,
    negative: PropTypes.bool,
    times: PropTypes.number,
    value: PropTypes.string,
  }),
  updateClause: PropTypes.func,
};
