import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { CPanel, H4, Text, Box, Flex } from '@appcues/component-library';
import { ExternalLink, Icon } from '@studio/legacy-components';

const InvoiceRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${props => props.theme['$gray-3']};
  padding: 0.5rem 0;
  align-items: baseline;
`;

const InvoiceAmount = styled.span`
  color: ${props => props.theme['$gray-7']};
  font-variant-numeric: tabular-nums;
  padding-right: 40px;
  text-align: right;
`;

const InvoiceLink = styled(ExternalLink)`
  width: 15%;

  ${Icon} {
    color: var(--icon);
    width: auto;

    :hover {
      color: var(--icon-hover);
    }
  }
`;

export default function BillingHistory({ billingHistory: { data: invoices } }) {
  const invoiceRows = invoices
    .filter(
      invoice =>
        !!invoice &&
        (invoice.status !== 'draft' || invoice.next_payment_attempt) &&
        invoice.total !== 0
    )
    .map(invoice => {
      const { plan } =
        invoice.lines.data.find(line => line.type === 'subscription') || {};

      const paidText = invoice.paid
        ? 'Successful'
        : invoice.next_payment_attempt || invoice.status === 'open'
        ? 'Pending'
        : 'Failed';
      const paidColor =
        paidText === 'Successful'
          ? '$green'
          : paidText === 'Pending'
          ? '$gray'
          : '$red-dark';

      const getReferenceDate = () => {
        /**
         * The date property was removed from Stripe's API
         *
         * finalized_at: The time that the invoice draft was finalized.
         * paid_at: The time that the invoice was paid.
         *
         * When the invoice's status is open, it means that the payment is
         * pending and therefore, we don't have a value in the `paid_at` attribute.
         * In such case, we should use the draft date as a reference until we have
         * more information about the payment.
         *
         * Possible statuses:
         * https://stripe.com/docs/invoicing/overview#invoice-statuses
         */
        const invoiceDateFormat = 'MMM D, YYYY';

        if (invoice.status_transitions.paid_at) {
          return moment(invoice.status_transitions.paid_at * 1000).format(
            invoiceDateFormat
          );
        }

        if (invoice.status_transitions.finalized_at) {
          return moment(invoice.status_transitions.finalized_at * 1000).format(
            invoiceDateFormat
          );
        }

        return '';
      };

      const getPlanName = () => {
        const { interval, metadata } = plan;
        const { mau, tier, type } = metadata;
        const maus = Number(mau).toLocaleString();
        return `Appcues ${type} ${tier} (${interval}ly) - ${maus} MAUs`;
      };

      return (
        <InvoiceRow key={invoice.id}>
          <Text width="15%">{getReferenceDate()}</Text>
          <Text flexGrow={1} textTransform="capitalize">
            {plan ? getPlanName() : 'Balance Adjustment'}
          </Text>
          <InvoiceAmount width="15%">
            {`${invoice.total < 0 ? '-' : ''}$${(
              Math.abs(invoice.total) / 100
            ).toFixed(2)}`}
          </InvoiceAmount>
          <Text color={paidColor} width="15%" fontWeight={700}>
            {paidText}
          </Text>
          <InvoiceLink href={invoice.hosted_invoice_url}>
            {invoice.status !== 'draft' && (
              <Icon icon="eye" title="Click to download invoice" />
            )}
          </InvoiceLink>
        </InvoiceRow>
      );
    });

  return invoiceRows.length > 0 ? (
    <CPanel>
      <CPanel.Header>
        <H4 type="secondary">Billing History</H4>
      </CPanel.Header>
      <Box marginTop={16}>
        <Flex
          justifyContent="space-between"
          paddingBottom="0.25rem"
          alignItems="baseline"
          color="$gray-5"
        >
          <Text width="15%">Date</Text>
          <Text flexGrow={1}>Description</Text>
          <InvoiceAmount width="15%">Amount</InvoiceAmount>
          <Text width="15%">Status</Text>
          <Text width="15%">Invoice</Text>
        </Flex>
        {invoiceRows}
      </Box>
    </CPanel>
  ) : null;
}

BillingHistory.propTypes = {
  billingHistory: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.number,
        hosted_invoice_url: PropTypes.string,
        id: PropTypes.string,
        lines: PropTypes.shape({
          data: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
            })
          ),
        }),
        next_payment_attempt: PropTypes.bool,
        paid: PropTypes.bool,
        status: PropTypes.oneOf([
          'draft',
          'open',
          'paid',
          'uncollectible',
          'void',
        ]),
        total: PropTypes.number,
      })
    ),
  }),
};
