import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import PropTypes from 'prop-types';
import Chart from 'components/Common/Chart';
import withQuery from 'components/Common/QueryResults/withQuery';
import NpsTooltip from './NpsTooltip';

function tooltipFormatter() {
  const { x, y } = this;
  return renderToStaticMarkup(<NpsTooltip day={x} value={y} />);
}

export const NpsChart = ({ series }) => {
  return (
    <Chart
      type="line"
      height={275}
      titleX="Day"
      titleY="NPS"
      series={series}
      tooltipFormatter={tooltipFormatter}
    />
  );
};

NpsChart.propTypes = {
  series: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
      }),
    })
  ).isRequired,
};

export const mapPropsToQuery = ({ startTime, endTime, segmentId, npsId }) => {
  const conditions = [['flow_id', '==', npsId]];

  if (segmentId) {
    conditions.push(['user_segment_id', '==', segmentId]);
  }

  return {
    conditions,
    dimensions: ['day'],
    metrics: ['nps_computed_score'],
    start_time: startTime,
    end_time: endTime,
    meta: {
      tags: {
        feature: 'NPS Score over time',
        page: 'NPS dashboard',
        component: 'NpsChartWithAnalytics',
        description: 'The NPS score over 30 trailing days',
      },
    },
  };
};

export const mapResultsToSeries = (results, opts) => [
  {
    ...opts,
    name: 'NPS score',
    showInLegend: false,
    data: results.map(({ day, nps_computed_score }) => ({
      x: Date.parse(day),
      y: Math.floor(nps_computed_score),
    })),
  },
];

export default withQuery(mapPropsToQuery, mapResultsToSeries)(NpsChart);
