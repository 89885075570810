import React from 'react';
import { Switch, Route } from 'react-router-dom';
import NpsDashboard from 'components/nps/dashboard/NpsDashboard';
import SatisfactionSurveysEdit from './SatisfactionSurveysEdit';
import SatisfactionCustomAudienceEdit from './SatisfactionCustomAudienceEdit';

export default function SatisfactionSurveys() {
  return (
    <Switch>
      <Route
        path="/nps/:satisfactionId/edit"
        component={SatisfactionSurveysEdit}
      />
      <Route
        path="/nps/:ruleId/settings"
        component={SatisfactionCustomAudienceEdit}
      />
      <Route path="/nps" component={NpsDashboard} />
    </Switch>
  );
}
