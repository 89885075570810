import * as actionTypes from 'constants/actionTypes';

const initialState = {
  details: null,
  fetching: false,
  history: null,
  subscriptionCanceled: null,
  paymentFailedMessage: null,
};

const billing = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REPLACE_BILLING_HISTORY:
      return {
        ...state,
        history: action.payload,
      };
    case actionTypes.REPLACE_BILLING_DETAILS:
      return {
        ...state,
        details: action.payload,
      };
    case actionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionCanceled: true,
        fetching: false,
      };
    case actionTypes.CANCEL_SUBSCRIPTION_FAILED:
      return {
        ...state,
        subscriptionCanceled: false,
        fetching: false,
      };
    case actionTypes.CANCEL_SUBSCRIPTION_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.PAYMENT_FAILED:
      return {
        ...state,
        paymentFailedMessage: action.payload.message,
      };
    case actionTypes.PAYMENT_SUCCESS:
      return {
        ...state,
        paymentFailedMessage: null,
      };
    default:
      return state;
  }
};

export default billing;

export const selectBilling = state => state.billing;
export const selectBillingDetails = state => selectBilling(state).details;
export const selectBillingDetailsWithCardSynced = state =>
  !!selectBilling(state).details &&
  !!selectBilling(state).details.sources?.data?.length > 0;
export const selectBillingHistory = state => selectBilling(state).history;
