import React from 'react';
import styled from 'styled-components';

import { CPanel } from '@appcues/component-library';
import { Button, Icon, Link } from '@studio/legacy-components';

const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 56px;
  height: 345px;
`;

const GlobeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  background-color: ${props => props.theme['$gray-1']};
`;

const EmptyState = () => {
  return (
    <CPanel>
      <EmptyStateWrapper>
        <GlobeContainer>
          <Icon icon="globe" fontSize={24} />
        </GlobeContainer>
        <h2>Localization</h2>
        <span>
          If you plan to translate your content for multiple languages, add the
          ones you wish to support.
        </span>
        <Button forwardedAs={Link} to="/settings/localization" type="primary">
          Add language
        </Button>
      </EmptyStateWrapper>
    </CPanel>
  );
};

export default EmptyState;
