import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Text,
  H2,
  H4,
  CSkeleton,
  CPanel,
  Row,
} from '@appcues/component-library';
import PercentOfUsersDisplay from 'components/Common/PercentOfUsersDisplay';
import { fetchConditionsEstimate } from 'actions/estimation';
import { getPercentageStrRoundedEnds } from 'utils/numbers';
import { asPromised } from 'utils/as-promised';

export class ConditionsEstimatePanel extends Component {
  state = {
    loaded: false,
    estimate: null,
  };

  componentDidMount() {
    const { onLoad } = this.props;
    onLoad().then(estimate => this.setState({ loaded: true, estimate }));
  }

  render() {
    const { loaded, estimate } = this.state;

    const pctQualifiedUsers = estimate ? estimate.pctQualifiedUsers : 0;
    const { percentLabel, wasRounded } =
      getPercentageStrRoundedEnds(pctQualifiedUsers);
    const metricLabel = pctQualifiedUsers
      ? `${wasRounded ? '' : '~'}${percentLabel}%`
      : '0';

    const weeklyWebsiteUsers = estimate ? estimate.weeklyWebsiteUsers : 0;

    return (
      <CPanel>
        <Row>
          <H4 type="tertiary">Estimated User Count</H4>
        </Row>
        <H2 marginTop={6}>
          {estimate ? metricLabel : <CSkeleton width="2em" height="20px" />}
        </H2>
        <Text type="tertiary" marginTop={5}>
          <PercentOfUsersDisplay
            userPercentage={pctQualifiedUsers}
            totalUsers={weeklyWebsiteUsers}
            loaded={loaded}
          />
        </Text>
      </CPanel>
    );
  }
}

ConditionsEstimatePanel.propTypes = {
  onLoad: PropTypes.func,
};

const mapDispatchToProps = (dispatch, { conditions }) => ({
  onLoad: () => asPromised(dispatch, fetchConditionsEstimate(conditions)),
});

export default connect(null, mapDispatchToProps)(ConditionsEstimatePanel);
