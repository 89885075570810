import React from 'react';
import PropTypes from 'prop-types';

import { Text, H4, CPanel } from '@appcues/component-library';
import { SegmentMetadata } from 'components/SegmentMetadata';

export default function DescriptionPanel({ segmentId, description, meta }) {
  const hasMeta = meta && Object.keys(meta).length > 0;
  const hasDescription = Boolean(description && description !== '');

  if (hasDescription || hasMeta) {
    return (
      <CPanel>
        <H4 marginBottom={16} type="tertiary">
          Description
        </H4>
        {hasDescription && (
          <Text marginBottom={hasMeta ? 24 : 0}>{description}</Text>
        )}
        {hasMeta && (
          <SegmentMetadata
            segmentId={segmentId}
            fileName={meta.originalFileName}
            uploadDate={meta.uploadDate}
            usersProcessed={meta.usersProcessed}
          />
        )}
      </CPanel>
    );
  }
  return null;
}

DescriptionPanel.propTypes = {
  segmentId: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.shape({
    originalFileName: PropTypes.string,
    uploadDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    usersProcessed: PropTypes.number,
  }),
};
