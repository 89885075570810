import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ImageServiceImage from 'components/Common/ImageServiceImage';
import { determineFlowCompletionStepId } from 'helpers/checklist-item-presets';
import { numberWithCommas } from 'utils/textFormatting';
import { STEP_TYPES } from 'constants/stepTypes';
import { getSortedSteps } from 'utils/steps';
import { determineTopFlows } from 'utils/stats';

const MostViewedFlows = props => {
  const { flows, steps, flowStats } = props;

  const topThreeFlows = determineTopFlows(flowStats, flows, 3);

  const flowStatsRows = topThreeFlows.map(flow => {
    const currentFlowStats = flowStats[flow.id];
    const firstStepId = determineFlowCompletionStepId(flow, steps);
    const firstStep = steps[firstStepId];

    if (!firstStep) {
      return null;
    }

    const stepChildType = (
      STEP_TYPES[firstStep.type || firstStep.stepType] || {}
    ).childrenKey;
    const stepChildren = Object.values(firstStep[stepChildType] || {});
    const sortedStepChildren = getSortedSteps(stepChildren);
    const flowNameDisplay = flow.name === '' ? 'Untitled Flow' : flow.name;

    return (
      <FlowRow key={flow.id}>
        <FlowImageWrapper>
          <ImageServiceImage
            flowId={flow.id}
            stepId={firstStepId}
            contentType={firstStep.type}
            stepChildId={sortedStepChildren[0] && sortedStepChildren[0].id}
            size="small"
          />
        </FlowImageWrapper>
        <span>{flowNameDisplay}</span>
        {currentFlowStats['all-time-unique'] &&
          currentFlowStats['all-time-unique'].reached && (
            <FlowViews>
              <FlowViewsNumber>
                {numberWithCommas(currentFlowStats['all-time-unique'].reached)}
              </FlowViewsNumber>
              <span>views</span>
            </FlowViews>
          )}
      </FlowRow>
    );
  });

  return <div>{flowStatsRows}</div>;
};

MostViewedFlows.propTypes = {
  flows: PropTypes.object,
  flowStats: PropTypes.object,
  steps: PropTypes.object,
};

const FlowRow = styled.div`
  background-color: ${props => props.theme['$gray-1']};
  display: flex;
  margin: 1rem 0;
  padding: 0.5rem;
  width: 100%;
  align-items: center;
  border-radius: 5px;
`;

const FlowImageWrapper = styled.div`
  flex: 0 0 10%;
  margin: 0 1rem;
  height: 60px;
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
    height: 60px;
  }
`;

const FlowViews = styled.div`
  margin: 0 1rem 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: 200;
`;

const FlowViewsNumber = styled.div`
  font-size: 1.75rem;
`;

export default MostViewedFlows;
