export const AUTO_PROFILE_ATTRIBUTES = {
  _appcuesId: {
    description:
      'This property gets passed in if an end-user (most often you or a member of your team) on your site is logged into my.appcues.com. Use this to target to other members of your team.',
  },
  userId: {
    description: 'The unique identifier we use to track your end users.',
  },

  _browser: {
    description: 'The browser your users are viewing your platform in.',
  },

  _hostname: {
    description:
      'The domain your users are viewing your page on. If hostnames look different across your customer accounts, you could target them either through Hostname targeting or URL targeting.',
  },

  _isAnonymous: {
    description:
      "If a user is identified as an anonymous (non-logged in) user, this property will be set to 'True'.",
  },

  _lastBrowserLanguage: {
    description:
      'The primary language setting on your end users browser. Using this you can create multiple versions of flows based on language and target them to the appropriate audience',
  },
  _lastContentShownAt: {
    description:
      'The date when a flow was last shown to an end-user. Use this property to cap the rate at which a user can see a flow.',
  },
  _currentPageTitle: {
    description:
      "The title of this user's most recently visited page. This is the same title that appears in a browser tab for the page.",
  },
  _currentPageUrl: {
    description: "The URL of the user's current page.",
  },

  _lastPageTitle: {
    description:
      "The title of this user's most recently visited page. This is the same title that appears in a browser tab for the page.",
  },
  _lastPageUrl: {
    description: "The URL of the user's most recently visited page.",
  },

  _localId: {
    description: 'A device specific ID used to target anonymous users.',
  },

  _myAppcuesId: {
    description:
      'This property gets passed in if an end-user (most often you or a member of your team) on your site is logged into my.appcues.com. Use this to target to other members of your team.',
  },
  _ABGroup: {
    description:
      'This is a property assigned to each user, to split them into to separate, equal sized groups.',
  },
  _sessionPageviews: {
    description:
      "The total number of pages a user has viewed in a particular 'visit'. The count is reset if the user closes the browser or tab.",
  },
  _sessionRandomizer: {
    description:
      "A random number between 1 and 100, generated every time a user visits your site in a new browser window or tab. \n To use it, set the comparator 'less than' and then input a number from 1-100, which will be the percentage of sessions you'd like to sample.",
  },

  _updatedAt: {
    description: "The date when a user's profile attributes were last updated.",
  },
  _userAgent: {
    description:
      'This property contains relevant browser information for an end-user.',
  },
  _deviceType: {
    description:
      'The device your users are viewing your platform on. Target flows to users on desktop or mobile (which includes tablets) by selecting a specific device.',
  },
  _operatingSystem: {
    description:
      'The operating system your users are viewing your platform on. Target flows to users on Mac, Windows, iOS, Android, etc.',
  },
  _appcuesSatisfaction_NPSLastCollectedAt: {
    description: 'The date when a user last submitted NPS feedback.',
  },
  _appcuesSatisfaction_AskMeLaterSelectedAt: {
    description:
      "The date when a user clicked 'Ask Me Later' on the NPS feedback form.",
  },
  _doNotTrack: {
    description:
      'Do Not Track is a web browser setting that requests that a web application disable its tracking of an individual user.',
  },
  _audienceRandomizer: {
    description:
      'A random number between 1 and 100, assigned automatically the first time a user visits your app. Use this property to split an audience between two or more versions of an experience and compare performance. You can also use this property to limit targeting of a single experience to only a portion of eligible users.',
  },
  _firstSeenAt: {
    description:
      'The time an end-user was first identified by Appcues. For timestamps after Appcues was installed on your app, you can use this property as a proxy for when the end-user signed up on your app, and create segments of "new users", "legacy users", etc.',
  },
};

export default AUTO_PROFILE_ATTRIBUTES;
