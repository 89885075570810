import styled from 'styled-components';
import { Td } from '@studio/legacy-components';

export const TableContainer = styled.div`
  ${Td} {
    border: none;
    vertical-align: top;

    &:nth-of-type(1) {
      font-weight: var(--bold);
      width: 100px;
    }

    &:nth-of-type(2) {
      max-width: 30em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
`;
