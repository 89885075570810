import { createCollectionActions } from 'next/lib/collections';

export const TYPE = 'flows';

export const {
  create,
  flush,
  insert,
  patterns,
  prune,
  read,
  readOne,
  reject,
  remove,
  replace,
  reset,
  resolve,
  update,
} = createCollectionActions(TYPE);

export const FLOW_PUBLISH_REQUESTED = '@flow/PUBLISH_REQUESTED';
export const requestPublishFlow = id => ({
  type: FLOW_PUBLISH_REQUESTED,
  payload: { id },
});

export const FLOW_PUBLISHED = '@flow/PUBLISHED';
export const flowPublished = id => ({
  type: FLOW_PUBLISHED,
  payload: { id },
});

export const FLOW_UNPUBLISH_REQUESTED = '@flow/UNPUBLISH_REQUESTED';
export const requestUnpublishFlow = id => ({
  type: FLOW_UNPUBLISH_REQUESTED,
  payload: { id },
});

export const FLOW_UNPUBLISHED = '@flow/UNPUBLISHED';
export const flowUnpublished = id => ({
  type: FLOW_UNPUBLISHED,
  payload: { id },
});

export const FLOW_CLONED = '@flow/CLONED';
export const clone = id => ({
  type: FLOW_CLONED,
  payload: { id },
});

export const FLOW_ARCHIVED = '@flow/ARCHIVED';
export const archive = id => ({
  type: FLOW_ARCHIVED,
  payload: { id },
});

export const FLOW_UNARCHIVED = '@flow/UNARCHIVED';
export const unarchive = id => ({
  type: FLOW_UNARCHIVED,
  payload: { id },
});

export const FLOW_REVERT_REQUESTED = '@flow/FLOW_REVERT_REQUESTED';
export const requestRevertFlow = (id, version) => ({
  type: FLOW_REVERT_REQUESTED,
  payload: { id, version },
});

export const FLOW_REVERTED = '@flow/REVERTED';
export const flowReverted = id => ({
  type: FLOW_REVERTED,
  payload: { id },
});

export const FLOW_TEST_MODE = '@flow/TEST_MODE';
export const flowTestMode = id => ({
  type: FLOW_TEST_MODE,
  payload: { id },
});
