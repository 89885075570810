import styled, { css } from 'styled-components';
import { Button, Link } from '@studio/legacy-components';

export const ButtonLink = styled(Button).attrs({
  forwardedAs: Link,
})`
  // this hack is needed to force the color in the anchor element
  ${({ kind }) =>
    kind === 'primary' &&
    css`
      && {
        color: var(--white);
      }
      :hover {
        color: var(--white);
      }
    `}
`;
