/* global CRX_ID */

import qs from 'qs';

/**
 * CRX URL origin
 */
const origin = `chrome-extension://${CRX_ID}`;

/**
 * Open in builder creator
 *
 * @param {string} path - CRX path
 * @param {function} params - Param builder
 * @return {function} Open in builder helper for path
 */
const open =
  (path, params) =>
  (...args) => {
    window.open(`${origin}/${path}?${params(...args)}`, '_blank');
  };

/**
 * Open flow in builder
 *
 * NOTE: The CRX expects the flow ID to be sent as `stepGroupId` due to
 *       historical reasons. Ideally, we should update the CRX to accept e.g.
 *       `flow` and `step` to mirror the other open in builder pages.
 *
 * @param {object<string>} params - Builder parameters
 *   @option {string} flow - Flow ID
 *   @option {string} [step] - Optional step child ID
 *   @option {string} url - Build URL
 * @return {void} Open flow (and step if provided) in builder on build URL
 */
export const openFlow = open('static/open.html', ({ flow, step, url }) =>
  qs.stringify({ stepGroupId: flow, stepChildId: step, url })
);

/**
 * Open event in builder
 *
 * @param {object<string>} params - Builder parameters
 *   @option {string} event - Event name
 *   @option {string} url - Build URL
 * @return {void} Open event in builder on build URL
 */
export const openEvent = open('static/open-event.html', ({ event, url }) =>
  qs.stringify({ event, url })
);

/**
 * Open experience in builder
 *
 * @param {object<string>} params - Builder parameters
 *   @option {string} experience - Experience ID
 *   @option {string} [step] - Optional step child ID
 *   @option {string} url - Build URL
 * @return {void} Open event in builder on build URL
 */
export const openExperience = open(
  'static/open-experience.html',
  ({ experience, step, url, pattern }) =>
    qs.stringify({ experience, step, url, pattern })
);
