import { createSelector } from 'reselect';
import { createCollectionReducer } from 'reducers/account/collections';
import {
  createRequestSelector,
  selectIsSynced,
} from 'reducers/account/requests';
import { selectRulesByGoal, selectRulesWithGoal } from './rules';

const FLOWS = 'flows';

export default createCollectionReducer(FLOWS);

export const selectFlows = state => state.account.flows;
export const selectFlow = (state, id) => selectFlows(state)[id];
export const selectFlowsAsList = state => {
  const flowsById = selectFlows(state) || {};
  return Object.keys(flowsById).map(id => flowsById[id]);
};
export const selectNonArchivedFlows = state => {
  const flowsById = selectFlows(state) || {};
  return Object.values(flowsById).filter(flow => flow.state !== 'ARCHIVED');
};
export const selectPublishedFlows = state => {
  const flowsById = selectFlows(state) || {};
  return Object.keys(flowsById).reduce((acc, key) => {
    const flow = flowsById[key];
    if (flow.published) {
      acc[key] = flow;
    }
    return acc;
  }, {});
};

export const selectSortedFlowStepChildren = (state, flowId) => {
  const flow = selectFlow(state, flowId) || {};

  return Object.values(flow.steps || {})
    .sort((a, b) => a.index - b.index)
    .reduce((acc, stepGroup) => {
      const { id: stepId, stepType, steps = {}, hotspots = {} } = stepGroup;
      [...Object.values(steps), ...Object.values(hotspots)]
        .sort((a, b) => a.index - b.index)
        .forEach(stepChild => {
          const { id, createdAt } = stepChild;
          acc.push({ flowId, stepId, id, stepType, createdAt });
        });
      return acc;
    }, []);
};

export const selectFlowsWithTheme = (state, themeId) => {
  const allFlows = selectFlows(state) || {};
  const flowsWithSteps = Object.values(allFlows).reduce((acc, flow) => {
    if (Object.values(flow.steps).length > 0) {
      acc.push(flow);
    }
    return acc;
  }, []);

  return flowsWithSteps.reduce((acc, flow) => {
    const stepsWithStyle = Object.values(flow.steps).filter(
      step => step.style === themeId
    );

    if (stepsWithStyle.length > 0) {
      acc.push(flow);
    }
    return acc;
  }, []);
};

export const selectFlowsWithGoal = (state, { goalId }) =>
  selectRulesByGoal(state, { goalId }).map(({ id }) => selectFlow(state, id));

export const selectFlowsByRule = state =>
  selectRulesWithGoal(state).map(({ id }) => selectFlow(state, id));

export const selectShouldFetch = createRequestSelector(FLOWS);
export const selectAreFlowsSynced = state => selectIsSynced(state, FLOWS);

export const selectStepGroups = createSelector([selectFlows], flows =>
  Object.values(flows || {}).reduce((acc, flow) => {
    Object.values(flow.steps || {}).forEach(step => {
      acc[step.id] = {
        ...step,
        type: step.actionType || step.stepType,
        parentId: flow.id,
      };
    });
    return acc;
  }, {})
);

export const selectStepGroup = (state, { stepId }) => {
  const stepGroups = selectStepGroups(state);
  return stepGroups[stepId];
};

export const selectFlowTags = (state, id) =>
  (selectFlow(state, id).tagIds || []).filter(
    tagId => ![null, 'null'].includes(tagId)
  );

export const selectFlowTargetingOptions = state =>
  Object.values(selectFlows(state))
    .sort((a, b) => (a.name || a.id).localeCompare(b.name || b.id))
    .flatMap(({ name, id, steps = {} }) =>
      Object.values(steps)
        .filter(
          ({ stepType }) =>
            !['redirect', 'wait-for-page', 'action'].includes(stepType)
        )
        .sort((a, b) => a.index - b.index)
        .map(({ id: value, index }) => ({
          label: `${name || id} (Step Group ${index + 1})`,
          value,
        }))
    );
