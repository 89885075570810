import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Link,
  SearchInput,
  H3,
  Panel,
  PanelHeader,
  PanelTitle,
  ExternalLink,
  PanelActions,
  Spinner,
} from '@studio/legacy-components';
import { Filter } from 'next/components/Filter';
import useFilters from 'next/hooks/use-filter';
import useTitle from 'next/hooks/use-title';
import NoData from 'assets/images/no-data.svg';
import NoResults from 'assets/images/no-results.svg';
import {
  LoaderWrapper,
  NoResultsMessage,
  NoResultsMessageEmphasis,
  TableWrapper,
} from 'components/Audience/styled';
import {
  callApi as fetchUserQualifiedContent,
  remove,
} from 'entities/user-qualified-content';
import { selectUserQualifiedContentFlat } from 'selectors/user-qualified-content';
import {
  EligibilityTable,
  EligibilityRow,
  EligibleIcon,
  EligibilityLabel,
  ActivityCell,
} from './styled';
import CriteriaModalManager from './CriteriaModalManager';
import { filterConditions, evaluateConditions } from './utils';

const sortByEligibility = (a, b) => {
  if (a.eligible && !b.eligible) return -1;
  if (!a.eligible && !b.eligible) return 1;
  return 0;
};

const getActivityLink = (activity, id) => {
  switch (activity) {
    case 'checklists':
      return `/checklists/${id}/analytics`;
    case 'satisfaction_survey':
      return `/nps`;
    case 'mobile':
      return `/mobile/flows/${id}/analytics`;
    case 'pin':
      return `/pins/${id}/analytics`;
    case 'journey':
    default:
      return `/flows/${id}/analytics`;
  }
};

export function Eligibility({ userQualifiedContent, onLoad, onUnload }) {
  useTitle('Eligibility | Users | Appcues');
  const [query, setQuery] = useState('');
  const isLoading = !userQualifiedContent;

  const { filters, handleFilterChange, handleFilterClear, filterFunction } =
    useFilters({
      eligibility: {
        name: 'Eligibility',
        options: {
          eligible: {
            label: (
              <EligibilityLabel>
                Eligible <EligibleIcon $eligible icon="check-circle" />
              </EligibilityLabel>
            ),
            enabled: false,
            filterFn: item => item.eligible,
            checkboxProps: {
              'aria-label': 'filter by eligible',
            },
          },
          ineligible: {
            label: (
              <EligibilityLabel>
                Ineligible <EligibleIcon $eligible={false} icon="ban" />
              </EligibilityLabel>
            ),
            enabled: false,
            filterFn: item => !item.eligible,
            checkboxProps: {
              'aria-label': 'filter by ineligible',
            },
          },
        },
      },
      activity: {
        name: 'Experience',
        options: {
          flows: {
            label: 'Flow',
            enabled: false,
            filterFn: item =>
              ['flow', 'mobile'].includes(item.activity.toLowerCase()),
          },
          checklists: {
            label: 'Checklist',
            enabled: false,
            filterFn: item => item.activity.toLowerCase() === 'checklist',
          },
          pins: {
            label: 'Pin',
            enabled: false,
            filterFn: item => item.activity.toLowerCase() === 'pin',
          },
        },
      },
    });

  useEffect(() => {
    onLoad?.();
    return () => {
      onUnload?.();
    };
  }, [onLoad]);

  const filteredQualifiedContent = userQualifiedContent
    ?.map(rule => ({
      eligible: evaluateConditions(filterConditions(rule.conditions)),
      ...rule,
    }))
    ?.filter(
      ({ name }) => name && name.toUpperCase().includes(query.toUpperCase())
    )
    .filter(filterFunction);

  const qualifiedContent = filteredQualifiedContent?.sort(sortByEligibility);

  const hasNoData = userQualifiedContent?.length === 0;
  const hasNoResults =
    !isLoading && !hasNoData && qualifiedContent.length === 0;

  return (
    <Panel>
      <PanelHeader>
        <PanelTitle>
          <H3>Eligibility</H3>
          <span>
            All Flow and Checklist experiences this user could see, according to
            each experience&apos;s settings.{' '}
            <ExternalLink href="https://docs.appcues.com/article/769-user-profile#eligibility">
              Learn more
            </ExternalLink>
          </span>
        </PanelTitle>
        <PanelActions>
          <SearchInput
            placeholder="Search for Experience name"
            value={query}
            onChange={({ target: { value } }) => {
              setQuery(value);
            }}
          />
          <Filter
            filters={filters}
            onChange={handleFilterChange}
            onClear={handleFilterClear}
          />
        </PanelActions>
      </PanelHeader>
      <TableWrapper>
        <EligibilityTable sticky>
          <Thead>
            <Tr>
              <Th>Experience name</Th>
              <Th>Experience</Th>
              <Th>Eligibility</Th>
            </Tr>
          </Thead>
          <Tbody>
            {qualifiedContent?.map(
              ({ name, activity, eligible, id, conditions }) => {
                return (
                  <EligibilityRow key={name}>
                    <Td title={name}>
                      <Link to={getActivityLink(activity, id)} target="_blank">
                        {name}
                      </Link>
                    </Td>
                    <ActivityCell title={activity}>
                      {activity === 'mobile' ? 'flow' : activity}
                    </ActivityCell>
                    <Td>
                      <EligibleIcon
                        $eligible={eligible}
                        icon={eligible ? 'check-circle' : 'ban'}
                      />
                      {eligible ? (
                        'Eligible'
                      ) : (
                        <CriteriaModalManager
                          activity={activity}
                          name={name}
                          conditions={conditions}
                        />
                      )}
                    </Td>
                  </EligibilityRow>
                );
              }
            )}
          </Tbody>
        </EligibilityTable>
        {isLoading && (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        )}
        {hasNoResults && (
          <NoResultsMessage>
            <img src={NoResults} alt="No data found" />
            <NoResultsMessageEmphasis>
              No results found
            </NoResultsMessageEmphasis>
            Try adjusting your filters for results
          </NoResultsMessage>
        )}
        {hasNoData && (
          <NoResultsMessage>
            <img src={NoData} alt="No data found" />
            We don’t have data for you yet
          </NoResultsMessage>
        )}
      </TableWrapper>
    </Panel>
  );
}

Eligibility.propTypes = {
  userQualifiedContent: PropTypes.arrayOf(PropTypes.object),
  onUnload: PropTypes.func,
  onLoad: PropTypes.func,
};

const mapStateToProps = (state, { userId }) => ({
  userQualifiedContent: selectUserQualifiedContentFlat(state, userId),
});

const mapDispatchToProps = (dispatch, { userId }) => ({
  onLoad: () => dispatch(fetchUserQualifiedContent({ userId })),
  onUnload: () => dispatch(remove(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Eligibility);
