import {
  REPLACE_ACCOUNT_THEME,
  REPLACE_ACCOUNT_THEMES,
  UPDATE_ACCOUNT_THEME_AND_FLUSH,
} from 'constants/account/themes';

const initialState = {
  meta: {
    fetching: false,
    synced: false,
    errored: false,
  },
  // themes keyed by theme id
  data: {},
};

export default function themes(state = initialState, action) {
  switch (action.type) {
    case REPLACE_ACCOUNT_THEME: {
      const { theme, themeId } = action.payload;
      if (theme) {
        return {
          ...state,
          data: {
            ...state.data,
            [themeId]: action.payload.theme,
          },
        };
      }
      // remove theme from state
      const { [themeId]: omit, ...themesWithoutTheme } = state.data;
      return { ...state, data: themesWithoutTheme };
    }

    case REPLACE_ACCOUNT_THEMES:
      return {
        meta: {
          fetching: false,
          synced: true,
          errored: false,
        },
        data: action.payload,
      };

    case UPDATE_ACCOUNT_THEME_AND_FLUSH: {
      const { themeId, changes } = action.payload;

      const {
        data: { [themeId]: theme },
      } = state;

      return {
        ...state,
        data: {
          ...state.data,
          [themeId]: { ...theme, ...changes },
        },
      };
    }

    default:
      return state;
  }
}

export const selectAccountThemesSynced = state => {
  return state.account.themes.meta.synced;
};
export const selectAccountTheme = (state, themeId) => {
  return state.account.themes.data[themeId] || null;
};
export const selectAccountThemes = state => {
  return state.account.themes.data;
};
