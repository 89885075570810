import { call, put, getContext, fork, takeEvery } from 'redux-saga/effects';
import {
  callApi,
  send,
  resolve,
  append,
  reject,
} from 'actions/account/segmentMemberships';
import { takeUntil } from 'effects/takeUntil';
import { reportError } from 'helpers/error-reporting';
import { SYNC_ACCOUNT_WITH_FIREBASE } from 'constants/actionTypes';

export function* fetchSegmentMembership({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    const { data: segmentMembership } = yield call(
      api.getSegmentMembership,
      id
    );
    yield put(append({ [id]: segmentMembership }));
  } catch (error) {
    yield call(reportError, error);
  }
}

export function* fetchAllSegmentMemberships() {
  try {
    yield put(send());
    const api = yield getContext('api');
    const { data: segmentMemberships } = yield call(
      api.getAllSegmentMemberships
    );
    yield put(resolve(segmentMemberships));
  } catch (error) {
    yield call(reportError, error);
    yield put(reject(error));
  }
}

export default function* saga() {
  const sequence = yield takeUntil(
    action => {
      return callApi(action) && action.payload && action.payload.id;
    },
    fetchSegmentMembership,
    action => callApi(action) && !action.payload
  );
  yield fork(fetchAllSegmentMemberships, sequence);
  yield takeEvery(SYNC_ACCOUNT_WITH_FIREBASE, fetchAllSegmentMemberships);
}
