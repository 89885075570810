import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.string,
  stepGroupId: PropTypes.string,
  stepChildId: PropTypes.string,
  type: PropTypes.oneOf(['flows']),
});

export const ExperienceScreenshotShape = PropTypes.shape({
  id: PropTypes.string,
  stepId: PropTypes.string,
  type: PropTypes.oneOf(['pins', 'mobile', 'banner']),
});
