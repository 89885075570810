import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  Icon,
  InlineMessage,
  Link,
  Modal,
  Row,
  Stack,
  Text,
} from '@appcues/sonar';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons/faArrowUpRightFromSquare';
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons/faCircleMinus';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import getExtensionImg from 'next/assets/images/get_extension.svg';
import useToggle from 'next/hooks/use-toggle';
import useVerifyExtension from 'next/hooks/use-verify-extension';

import { BUILDER_CHROME_STORE_URL } from 'next/constants';
import { Card, Image } from './styled';

function InstallChromeExtensionModal({
  onExtensionInstalled = () => {},
  onDismiss = () => {},
}) {
  const [isOpen, toggleModal] = useToggle(true);
  const { hasCRXInstalled, hasVerified } = useVerifyExtension();

  const handleDismiss = () => {
    onDismiss();
    toggleModal();
  };

  return (
    <Modal.Root open={isOpen}>
      <Modal.Title>Install the Appcues Builder</Modal.Title>
      <Modal.Close>
        <Icon size="large" icon={faXmark} onClick={handleDismiss} />
      </Modal.Close>
      <Text>Get the Chrome extension to continue.</Text>
      <Card>
        <Row spacing="large" xAlign="center" yAlign="start">
          <Image
            alt="Chrome icon with extension"
            src={getExtensionImg}
            width={124}
          />
          <Stack yAlign="space-between">
            <Row xAlign="space-between">
              <Text size="medium" weight="semibold">
                The Appcues Builder
              </Text>
              {hasCRXInstalled ? (
                <Badge variant="active" icon={faCircleCheck}>
                  Installed
                </Badge>
              ) : (
                <Badge variant="default" icon={faCircleMinus}>
                  Not installed
                </Badge>
              )}
            </Row>
            <Text>
              The Appcues Builder Chrome extension lets you create and edit
              in-app experiences for websites.
            </Text>

            <Link
              href="https://docs.appcues.com/en_US/user-experiences-web-experiences/what-is-the-appcues-builder"
              target="_blank"
              icon={faArrowUpRightFromSquare}
            >
              Why do I need the extension?
            </Link>
          </Stack>
        </Row>
      </Card>
      {hasVerified && !hasCRXInstalled ? (
        <InlineMessage variant="error">
          Chrome extension not found, please try installing again.
        </InlineMessage>
      ) : null}
      <Modal.Actions>
        <Button variant="tertiary" onClick={handleDismiss}>
          Cancel
        </Button>
        {hasCRXInstalled ? (
          <Button onClick={onExtensionInstalled}>Start building</Button>
        ) : (
          <Button
            role="link"
            /* global CRX_ID */
            onClick={() => window.open(`${BUILDER_CHROME_STORE_URL}/${CRX_ID}`)}
            disabled={hasCRXInstalled}
          >
            Install Chrome extension
          </Button>
        )}
      </Modal.Actions>
    </Modal.Root>
  );
}

InstallChromeExtensionModal.propTypes = {
  onExtensionInstalled: PropTypes.func,
  onDismiss: PropTypes.func,
};

export default InstallChromeExtensionModal;
