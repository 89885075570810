import { createCollectionActions } from 'next/lib/collections';

export const TYPE = 'tags';

export const {
  create,
  insert,
  update,
  flush,
  remove,
  prune,
  patterns,
  read,
  reject,
  resolve,
} = createCollectionActions(TYPE);
