import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, Icon } from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';
import { useBuilderStatus } from 'hooks/useBuilderStatus';
import { selectCustomEventByName } from 'reducers/account/customEvents';
import OpenTrackEventModal from 'components/Insights/Explorer/Common/OpenTrackEventModal';

export const OpenTrackEventButton = ({ event, url }) => {
  const { isReady, verify } = useBuilderStatus();
  const [isOpen, toggle] = useToggle();

  const handleClick = async () => {
    try {
      await verify();
      toggle();
    } catch {
      // no-op
    }
  };

  return (
    <>
      {isOpen && (
        <OpenTrackEventModal event={event} url={url} onClose={toggle} />
      )}
      <Button
        kind="secondary"
        disabled={!url || !isReady}
        onClick={handleClick}
      >
        <Icon icon="edit" />
        Open in Builder
      </Button>
    </>
  );
};

OpenTrackEventButton.propTypes = {
  event: PropTypes.string,
  url: PropTypes.string,
};

const mapStateToProps = (state, { event }) => {
  const { buildUrl } =
    selectCustomEventByName(state, { eventName: event }) || {};
  return {
    event,
    url: buildUrl,
  };
};

const ConnectedOpenTrackEventButton =
  connect(mapStateToProps)(OpenTrackEventButton);

ConnectedOpenTrackEventButton.propTypes = {
  event: PropTypes.string,
};

export default ConnectedOpenTrackEventButton;
