import React from 'react';
import PropTypes from 'prop-types';
import { CCard, H3, Flex, Box } from '@appcues/component-library';
import ThreeDotsDropdownButton from 'components/Common/ThreeDotsDropdownButton';

const Card = ({
  header,
  children,
  dropdownOptions,
  onSelectDropdownOption,
  ...divProps
}) => (
  <CCard padding={16} {...divProps}>
    <Flex alignItems="center" paddingBottom={8}>
      <H3
        type="secondary"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {header}
      </H3>
      {dropdownOptions.length > 0 && (
        <Box marginLeft="auto">
          <ThreeDotsDropdownButton
            dropdownOptions={dropdownOptions}
            handleOnSelect={onSelectDropdownOption}
          />
        </Box>
      )}
    </Flex>
    {children}
  </CCard>
);

Card.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
  dropdownOptions: PropTypes.array,
  onSelectDropdownOption: PropTypes.func,
};

Card.defaultProps = {
  dropdownOptions: [],
};

export default Card;
