import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';

import {
  H2,
  Panel,
  PanelHeader,
  PanelBody,
  PanelTitle,
} from '@studio/legacy-components';
import { Text } from '@appcues/sonar';
import useTitle from 'next/hooks/use-title';
import { findOrCreateStripeCustomer } from 'actions/newBilling';
import Plans from 'components/SubscriptionPicker/Plans';
import Controls from 'components/SubscriptionPicker/Controls';
import Loader from 'components/Common/Loader';
import CardLinks from 'components/SubscriptionPicker/CardLinks';
import {
  DEFAULT_PLAN_LIMIT,
  PLAN_TYPES,
  PLAN_TO_TIER_MAPPINGS,
} from 'constants/plans';
import {
  selectAccountMeta,
  selectAccountMetaSynced,
} from 'reducers/account/meta';
import { selectUserSynced } from 'reducers/user';
import { selectSubscriptionEmail } from 'selectors/billing';
import { getSettingsFromPlanId } from 'utils/stripe';
import TermsOfService from 'components/Subscription/TermsOfService';

export const SubscriptionPicker = ({
  account,
  isSynced,
  onLoad,
  subscriptionEmail,
}) => {
  useTitle('Subscription | Settings | Appcues');
  const [planLimit, setPlanLimit] = useState(DEFAULT_PLAN_LIMIT);
  const location = useLocation();

  const { id: accountId, targetPlan } = account;

  useEffect(() => {
    if (accountId && subscriptionEmail) {
      onLoad({
        email: subscriptionEmail,
        accountId,
      });
    }
  }, [accountId, subscriptionEmail]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.get('pl')) {
      const planLimitFromParams = Number.parseInt(params.get('pl'), 10);
      setPlanLimit(planLimitFromParams);
    }
  }, [location.search]);

  useEffect(() => {
    if (account.targetStripePlan) {
      const targetPlanType = PLAN_TYPES.find(
        type => type.id === PLAN_TO_TIER_MAPPINGS[account.targetStripePlan.id]
      );

      if (targetPlanType) {
        let planId = account.targetStripePlan.id;

        if (targetPlanType.pricePlanId) {
          planId = planId.replace(
            targetPlanType.id,
            targetPlanType.pricePlanId
          );
        }

        const targetPlanSettings = getSettingsFromPlanId(planId);

        const startingLimit = targetPlanSettings.limit || DEFAULT_PLAN_LIMIT;

        setPlanLimit(startingLimit);
      }
    }
  }, [account.targetStripePlan]);

  if (!isSynced) {
    return <Loader />;
  }

  return (
    <Panel>
      <PanelHeader>
        <PanelTitle>
          <H2>Plan Options</H2>
        </PanelTitle>
      </PanelHeader>
      <PanelBody>
        <Text>
          Pricing for each plan below scales with your monthly active user
          count.
        </Text>
        {!targetPlan && (
          <Controls
            onChangeLimit={({ value }) => setPlanLimit(value)}
            planLimit={Number.parseInt(planLimit, 10)}
          />
        )}
        <Plans
          planLimit={
            planLimit === 'high-volume'
              ? planLimit
              : Number.parseInt(planLimit, 10)
          }
          targetPlan={targetPlan}
        />
        <CardLinks />
        <TermsOfService />
      </PanelBody>
    </Panel>
  );
};

SubscriptionPicker.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.string,
    targetPlan: PropTypes.string,
  }),
  isSynced: PropTypes.bool,
  onLoad: PropTypes.func,
  subscriptionEmail: PropTypes.string,
};

const mapStateToProps = state => ({
  account: selectAccountMeta(state),
  isSynced: selectUserSynced(state) && selectAccountMetaSynced(state),
  subscriptionEmail: selectSubscriptionEmail(state),
});

const mapDispatchToProps = {
  onLoad: findOrCreateStripeCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPicker);
