import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from '@studio/legacy-components';

import { TableHead, TableRow, TableData } from './Styled';

const TableHeaders = ({ headerGroups }) => {
  return (
    <thead data-testid="table-header">
      {headerGroups.map((group, groupIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableRow key={groupIndex}>
          {group.headers.map(
            (
              {
                maxWidth,
                minWidth,
                sortable,
                wordWrap,
                alignment,
                isSortedDesc,
                isSorted,
                canSort,
                render,
                getSortByToggleProps,
                getHeaderProps,
              },
              colIndex
            ) => (
              <TableHead
                maxWidth={maxWidth}
                minWidth={minWidth}
                // eslint-disable-next-line react/no-array-index-key
                key={`${colIndex}-${groupIndex}`}
                {...getHeaderProps(sortable && getSortByToggleProps())}
              >
                <TableData
                  data-testid="header-cell"
                  wordWrap={wordWrap}
                  alignment={alignment}
                  isSortedDesc={isSortedDesc}
                  className={classnames({
                    headerCell: true,
                    isSorted: isSorted && sortable,
                    canSort: canSort && sortable,
                  })}
                >
                  {render('Header')}
                  {isSorted && sortable && (
                    <Icon icon={isSortedDesc ? `chevron-down` : `chevron-up`} />
                  )}
                </TableData>
              </TableHead>
            )
          )}
        </TableRow>
      ))}
    </thead>
  );
};

TableHeaders.propTypes = {
  headerGroups: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.shape({
        headers: PropTypes.arrayOf(
          PropTypes.shape({
            maxWidth: PropTypes.number,
            minWidth: PropTypes.number,
            getHeaderProps: PropTypes.func,
            getSortByToggleProps: PropTypes.func,
            wordWrap: PropTypes.string,
            alignment: PropTypes.string,
            isSortedDesc: PropTypes.bool,
            isSorted: PropTypes.bool,
            canSort: PropTypes.bool,
            sortable: PropTypes.bool,
            render: PropTypes.func,
          })
        ),
      }),
    })
  ).isRequired,
};

export default TableHeaders;
