import React from 'react';
import styled from 'styled-components';

const CharacterCount = props => {
  const { className, input, maxLength } = props;

  const inputLength = input ? input.length : 0;

  return <span className={className}>{`${inputLength} / ${maxLength}`}</span>;
};

export default styled(CharacterCount)`
  margin-left: 1rem;
`;
