import {
  addClause,
  conditionNames,
  deleteClause,
  propertyOperators,
} from '@appcues/libcues';
import moment from 'moment';
import {
  SATISFACTION_REQUIRED_BASE_PROPERTIES,
  SATISFACTION_TARGETING_OPTIONS,
  SATISFACTION_TARGETING_CATEGORY,
  surveyDelayMaxThreshold,
  defaultNewUserProperty,
  DEFAULT_LIGHT_COLOR_HEX,
} from 'constants/satisfaction/data';
import { updateUserProfile } from 'helpers/api';
import { millisecondsPerDay } from 'constants/account/conditions';
import * as clauseTransforms from 'transforms/clauses';
import { reportError } from 'helpers/error-reporting';

const { hasOwnProperty } = Object.prototype;

export const DEFAULT_EXTRACT_FORMAT = 'json';

export const pageTargetingConstants =
  SATISFACTION_TARGETING_OPTIONS[SATISFACTION_TARGETING_CATEGORY.PAGE];
export const allPageOptions = pageTargetingConstants.URL_OPTIONS;
export const advancedTargetingOption =
  pageTargetingConstants.ADVANCED_TARGETING_OPTION;

export function getPageOptionFromOperator(urlOperator) {
  const pageOption = allPageOptions.find(
    option => option.operator === urlOperator
  );

  return pageOption || advancedTargetingOption;
}

export function getPageOptionFromClauses(urlClauses) {
  // Removes any non-url clauses, and the parent clause
  const urlFilteredClauses = clauseTransforms.getUrlClauses(urlClauses, false);

  if (!urlFilteredClauses || urlFilteredClauses.length <= 0) {
    return getPageOptionFromOperator(propertyOperators.REGEX);
  }

  const isMultipleClauses = urlFilteredClauses.length > 1;
  if (
    isMultipleClauses &&
    !clauseTransforms.allOperatorsMatch(urlFilteredClauses)
  ) {
    return advancedTargetingOption;
  }

  const urlOperator =
    clauseTransforms.getFirstClauseOperator(urlFilteredClauses);
  const [clause] = urlFilteredClauses;
  if (urlOperator === propertyOperators.REGEX && clause.value !== '.*') {
    return advancedTargetingOption;
  }

  return getPageOptionFromOperator(urlOperator);
}

export function detectConditionsTemplate(clauses) {
  const containsRequiredProperties =
    SATISFACTION_REQUIRED_BASE_PROPERTIES.filter(property => {
      return clauses.find(clause => {
        return clause.property === property;
      });
    });

  // If there are clauses missing from any of the required
  // properties, we can assume that the conditions are not
  // from the template.
  if (
    containsRequiredProperties.length <
    SATISFACTION_REQUIRED_BASE_PROPERTIES.length
  ) {
    return false;
  }

  return true;
}

export function getAudienceSettingsSummary(
  option,
  targetedFlowsLength,
  emailsExcludedLength,
  newUserClause,
  newUserDays
) {
  const activatedUserDaysDescription = newUserClause
    ? `${newUserDays} days`
    : '';

  const userDescription = `${option.description}${activatedUserDaysDescription}`;

  const exclusionDescription = `${
    targetedFlowsLength || emailsExcludedLength
      ? option.value === 0
        ? ' except those who'
        : ' and'
      : ''
  }`;

  const excludedFlowsDescription = `${
    targetedFlowsLength ? ' have already seen particular flows' : ''
  }`;

  const bridgingAnd = `${
    emailsExcludedLength && targetedFlowsLength ? ' and' : ''
  }`;

  const excludedEmailsDescription = `${
    emailsExcludedLength ? ' have certain email domains' : ''
  }`;

  return `${userDescription}${exclusionDescription}${excludedFlowsDescription}${bridgingAnd}${excludedEmailsDescription}`;
}

export const addNPSDefaultsToProfile = async (
  accountId,
  userId,
  attributes
) => {
  try {
    await updateUserProfile(accountId, userId, attributes);
  } catch (error) {
    reportError(error, { attributes });
  }
};

const isIdentityObject = key => {
  return typeof key === 'object' && typeof key.value !== 'object';
};

const flattenIdentityData = (identityData = []) => {
  return identityData.reduce((acc, key) => {
    if (isIdentityObject(key)) {
      acc[key.property] = key.value;
    }
    return acc;
  }, {});
};

export const getRecentResponseData = (responses = []) => {
  const firstResponse = responses && responses[0];
  if (!firstResponse) {
    return [];
  }

  const flattenedResponses = responses.map(resp => {
    const { _identity, ...restAttr } = resp.attributes;
    const identity =
      _identity.length > 0 ? flattenIdentityData(_identity) : _identity;
    return {
      ...resp,
      score: restAttr.score,
      feedback: restAttr.feedback,
      identity,
      attributes: {
        ...restAttr,
      },
    };
  });

  return flattenedResponses.sort((a, b) => {
    return b.timestamp - a.timestamp;
  });
};

function isFromToday(date) {
  return moment().diff(date, 'days') === 0;
}

function isFromThisWeek(date) {
  return moment().diff(date, 'weeks') === 0;
}

export const getResponsesBreakout = responses => {
  const responseBreakout = {
    responsesToday: [],
    responsesThisWeek: [],
    olderResponses: [],
  };

  responses.forEach(response => {
    const parsedDate = moment(response.timestamp);
    if (isFromToday(parsedDate)) {
      responseBreakout.responsesToday.push(response);
    } else if (isFromThisWeek(parsedDate)) {
      responseBreakout.responsesThisWeek.push(response);
    } else {
      responseBreakout.olderResponses.push(response);
    }
  });

  return responseBreakout;
};

export function getAutoCollapseSetting(step) {
  return hasOwnProperty.call(step, 'collapseBeforeNextStep')
    ? step.collapseBeforeNextStep
    : true;
}

export function getRolling30DayTimePeriod() {
  return {
    today: moment().format('LL'),
    thirtyDaysAgo: moment().subtract(30, 'days').format('LL'),
  };
}

export function getCtaStyles(backgroundColor) {
  return {
    backgroundColor:
      backgroundColor === DEFAULT_LIGHT_COLOR_HEX
        ? '#D9DDE6'
        : DEFAULT_LIGHT_COLOR_HEX,
    color:
      backgroundColor === DEFAULT_LIGHT_COLOR_HEX
        ? DEFAULT_LIGHT_COLOR_HEX
        : backgroundColor,
  };
}

export function getFrequencySettingsFromClauses(userPropertyClauses) {
  const FREQUENCY_SETTINGS =
    SATISFACTION_TARGETING_OPTIONS[SATISFACTION_TARGETING_CATEGORY.FREQUENCY];

  const surveyFrequencyClause = userPropertyClauses.find(clause => {
    return (
      clause.property === FREQUENCY_SETTINGS.HAS_NOT_SEEN_NPS.clauseProperty &&
      clause.operator === FREQUENCY_SETTINGS.HAS_NOT_SEEN_NPS.operator
    );
  });

  const sessionPageViewClause = userPropertyClauses.find(clause => {
    return (
      clause.property ===
        FREQUENCY_SETTINGS.SESSION_PAGE_VIEWS.clauseProperty &&
      clause.operator === FREQUENCY_SETTINGS.SESSION_PAGE_VIEWS.operator
    );
  });

  const surveyDelayClause = userPropertyClauses.find(clause => {
    return (
      clause.property === FREQUENCY_SETTINGS.ASK_ME_LATER.clauseProperty &&
      clause.operator === FREQUENCY_SETTINGS.ASK_ME_LATER.operator &&
      (clause.value < (surveyFrequencyClause && surveyFrequencyClause.value) ||
        clause.value <= surveyDelayMaxThreshold)
    );
  });

  const surveyDelayExcludeClause = userPropertyClauses.find(clause => {
    return (
      clause.property === FREQUENCY_SETTINGS.ASK_ME_LATER.clauseProperty &&
      clause.operator === FREQUENCY_SETTINGS.ASK_ME_LATER.operator &&
      (clause.value === surveyFrequencyClause.value ||
        clause.value > surveyDelayClause.value ||
        clause.value > surveyDelayMaxThreshold)
    );
  });

  return {
    surveyFrequencyClause,
    sessionPageViewClause,
    surveyDelayClause,
    surveyDelayExcludeClause,
  };
}

function getAudienceTargetingSegmentOption() {
  return SATISFACTION_TARGETING_OPTIONS[
    SATISFACTION_TARGETING_CATEGORY.AUDIENCE
  ].AUDIENCE_OPTIONS[2];
}

function getAudienceTargetingOptionByOperator(operator) {
  return SATISFACTION_TARGETING_OPTIONS[
    SATISFACTION_TARGETING_CATEGORY.AUDIENCE
  ].AUDIENCE_OPTIONS.find(option => {
    return option.operator === operator;
  });
}

export function getAudienceTargetingOption(userOperator, segmentClause) {
  if (segmentClause) {
    return getAudienceTargetingSegmentOption();
  }
  return getAudienceTargetingOptionByOperator(userOperator);
}

export function getNewUserClause(userPropertyClauses, meta) {
  return userPropertyClauses.find(clause => {
    return (
      hasOwnProperty.call(clause, 'conditionName') &&
      clause.conditionName === conditionNames.PROPERTIES &&
      (clause.property === meta.userCreatedAtField ||
        clause.property === defaultNewUserProperty)
    );
  });
}

export function transformClausesForAudienceTargeting(
  clauses,
  audienceOption,
  newUserClause,
  segmentClause,
  userPropertyClausesParentId,
  userCreatedAtField,
  createdWithinDays
) {
  const { operator, value } = audienceOption;
  const isAllUserTargeting = !value;
  const isNewUserTargeting = value === 1;
  const isSegmentTargeting = value === 2;
  let updatedClauses = clauses;

  if (isAllUserTargeting) {
    // Remove new user and segment clauses if they exist.
    // All user targeting is the lack of clauses.
    if (newUserClause) {
      updatedClauses = deleteClause(updatedClauses, newUserClause.id);
    }
    if (segmentClause) {
      updatedClauses = deleteClause(updatedClauses, segmentClause.id);
    }
  } else if (isNewUserTargeting) {
    // Remove segment clause if it exists.
    if (segmentClause) {
      updatedClauses = deleteClause(updatedClauses, segmentClause.id);
    }

    if (!newUserClause) {
      updatedClauses = addClause(updatedClauses, userPropertyClausesParentId, {
        conditionName: conditionNames.PROPERTIES,
        operator,
        property: userCreatedAtField || defaultNewUserProperty,
        value: createdWithinDays || millisecondsPerDay * 30,
      });
    }
  } else if (isSegmentTargeting) {
    // Remove new user clause if it exists.
    if (newUserClause) {
      updatedClauses = deleteClause(updatedClauses, newUserClause.id);
    }

    if (!segmentClause) {
      updatedClauses = addClause(updatedClauses, userPropertyClausesParentId, {
        conditionName: conditionNames.SEGMENTS,
        segment: '',
      });
    }
  }

  return updatedClauses;
}

export function getFlowIdsFromClauses(flowClauses) {
  return flowClauses.map(clause => {
    return clause.content;
  });
}

export function getEmailFilteredClauses(userPropertyClauses, meta) {
  return userPropertyClauses.filter(clause => {
    return (
      hasOwnProperty.call(clause, 'conditionName') &&
      clause.operator === propertyOperators.NOT_CONTAINS &&
      (clause.property === meta.userEmailField || clause.property === 'email')
    );
  });
}

export function getEmailDomainBlackList(emailFilteredClauses) {
  return emailFilteredClauses
    .map(clause => {
      if (
        clause &&
        clause.operator !== propertyOperators.REGEX &&
        clause.value !== '.*'
      ) {
        return clause.value;
      }
      return null;
    })
    .filter(Boolean);
}
