import React from 'react';
import { quantitativeOptionNumbers } from 'constants/satisfaction/data';
import { getCtaStyles } from 'helpers/satisfaction';

export default function SatisfactionQuantitativePreview({
  questionOne,
  backgroundColor,
}) {
  const ctaStyles = getCtaStyles(backgroundColor);

  return (
    <div className="quantitative-question" id="quantitative-question">
      <div className="question-text">{questionOne}</div>
      <div className="nps-options">
        {quantitativeOptionNumbers.map(optionNumber => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            className="nps-number-link"
            key={`option-${optionNumber}`}
            style={ctaStyles}
          >
            {optionNumber}
          </a>
        ))}
      </div>
      <div className="context-hints">
        <div className="not-likely-hint">Not likely</div>
        <div className="very-likely-hint">Very likely</div>
      </div>
    </div>
  );
}
