import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Heading,
  ButtonGroup,
  Button,
  ButtonLink,
  Paragraph,
} from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';
import { resetPassword } from 'actions/user';

const PasswordResetManager = ({ disabled, onReset }) => {
  const [visible, toggleVisible] = useToggle(false);

  const handleResetClick = useCallback(() => {
    onReset();
    toggleVisible();
  }, [onReset, toggleVisible]);

  return (
    <>
      <ButtonLink disabled={disabled} onClick={toggleVisible}>
        Reset password
      </ButtonLink>
      <Modal onClose={toggleVisible} size="s" visible={visible}>
        <Heading>Confirm reset password</Heading>
        <Paragraph>
          We'll send you an email with instructions on how to reset your
          password.
        </Paragraph>
        <ButtonGroup right>
          <Button kind="negative" onClick={handleResetClick}>
            Reset password
          </Button>
          <Button kind="secondary" onClick={toggleVisible}>
            Cancel
          </Button>
        </ButtonGroup>
      </Modal>
    </>
  );
};

const mapDispatchToProps = {
  onReset: resetPassword,
};

export default connect(undefined, mapDispatchToProps)(PasswordResetManager);
