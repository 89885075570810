import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ExternalLink } from '@studio/legacy-components';
import Radio from 'components/Common/UI/Forms/Radio';

const DOC_LINKING_TO_FLOWS =
  'http://docs.appcues.com/article/33-linking-to-flows';
const DOCS_APPCUES_WIDGET = 'http://docs.appcues.com/article/17-in-app-widget';

const FrequencyTargeting = ({
  stepId = '',
  stepFrequency = '',
  setStepFrequency = () => {},
  hideDescriptionText = false,
  showManualTriggerOption = false,
}) => {
  const handleSetShowOnce = useCallback(
    () => setStepFrequency(stepId, 'once'),
    [stepId]
  );

  const handleSetShowEveryTime = useCallback(
    () => setStepFrequency(stepId, 'every_time'),
    [stepId]
  );

  const handleSetShowManually = useCallback(
    () => setStepFrequency(stepId, 'never'),
    [stepId]
  );

  return (
    <>
      {!hideDescriptionText && <h4>And how often?</h4>}
      <Radio
        label="Show once"
        value="once"
        onChange={handleSetShowOnce}
        checked={stepFrequency === 'once'}
      />
      <Radio
        label="Show every time"
        value="every_time"
        onChange={handleSetShowEveryTime}
        checked={stepFrequency === 'every_time'}
      />
      {showManualTriggerOption && (
        <>
          <Radio
            label="Show manually"
            value="never"
            onChange={handleSetShowManually}
            checked={stepFrequency === 'never'}
          />
          {stepFrequency === 'never' && (
            <div className="content">
              <span className="help-text">
                Only show via{' '}
                <ExternalLink href={DOCS_APPCUES_WIDGET}>
                  the Appcues widget
                </ExternalLink>
                , a{' '}
                <ExternalLink href={DOC_LINKING_TO_FLOWS}>
                  permalink or flow action
                </ExternalLink>
                , or by calling{' '}
                <LegacyCodeFormat>
                  {`Appcues.show('${stepId}')`}
                </LegacyCodeFormat>{' '}
                in Javascript.
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
};

FrequencyTargeting.propTypes = {
  stepId: PropTypes.string.isRequired,
  stepFrequency: PropTypes.string.isRequired,
  setStepFrequency: PropTypes.func.isRequired,
  descriptionTextOverride: PropTypes.string,
  showManualTriggerOption: PropTypes.bool.isRequired,
};

const LegacyCodeFormat = styled.code`
  font-family: 'source-code-pro', Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
`;

export default FrequencyTargeting;
