import React, { Suspense, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { getRecommendedFrequency } from '@studio/legacy-components';
import { titleCase } from 'utils';
import { groupByMonth } from 'components/Common/QueryResults/processors';
import QueryResults from 'components/Common/QueryResults';
import Loader from 'components/Common/Loader';
import {
  selectAreRulesSynced,
  selectGoalForRule,
} from 'reducers/account/rules';
import { getFlowVersions } from 'actions/account/flowVersions';
import { selectFlowVersionsByDimension } from 'reducers/account/flowVersions';

const Chart = React.lazy(() => import('components/Common/Chart'));

export const metricColors = {
  flow_users: '#9D9DFF',
  flow_error_users: '#FF97BC',
  flow_completed_users: '#6FDDDB',
  goal_achievements: '#FFBB61',
};

const metricLabels = {
  flow_users: 'Flows shown',
  flow_error_users: 'Issues',
  flow_completed_users: 'Flow completion',
  goal_achievements: 'Goal completion',
};

const ChartLoader = () => <Loader margin="calc((275px - 2em) / 2)" />;

export const FlowActivityChart = ({
  dimension,
  onLoad,
  query,
  results: rawResults,
  versions,
}) => {
  useEffect(() => void onLoad(), []);

  const { metrics } = query ?? {};

  const results = useMemo(
    () =>
      rawResults && dimension === 'month'
        ? groupByMonth(rawResults)
        : rawResults,
    [dimension, rawResults]
  );

  if (!rawResults || !versions) {
    return <ChartLoader />;
  }

  const flowEventSeries = metrics.map(metric => ({
    name: metricLabels[metric],
    color: metricColors[metric],
    fillOpacity: 0.4,
    marker: {
      symbol: 'circle',
      fillColor: '#9D9DFF',
    },
    type: 'area',
    data: results.map(result => ({
      x: Date.parse(result[dimension]),
      y: result[metric],
    })),
  }));

  const flowVersionSeries = [
    {
      clip: false,
      colorByPoint: false,
      data: Object.keys(versions).map(date => ({
        // add 1 millisecond to the version's x-position so that it doesn't
        // share a tooltip with the area chart
        x: Date.parse(date) + 1,
        y: 0,
        title: ' ',
        name: `${versions[date].length} version${
          versions[date].length === 1 ? '' : 's'
        } published ${dimension === 'day' ? 'on' : 'in'} ${moment(date).format(
          dimension === 'day' ? "ddd, MMM D, 'YY" : "MMM 'YY"
        )}`,
      })),
      shape:
        "url(data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='6' cy='6' r='5.75' fill='white' stroke='%237E89A9' stroke-width='0.5'/%3E%3Cpath d='M8.16666 3.33331C8.30555 3.33331 8.42361 3.38192 8.52083 3.47915C8.61805 3.57637 8.66666 3.69442 8.66666 3.83331V6.83331C8.66666 6.9722 8.61805 7.09026 8.52083 7.18748C8.42361 7.2847 8.30555 7.33331 8.16666 7.33331H5.16666C5.02777 7.33331 4.90972 7.2847 4.81249 7.18748C4.71527 7.09026 4.66666 6.9722 4.66666 6.83331V3.83331C4.66666 3.69442 4.71527 3.57637 4.81249 3.47915C4.90972 3.38192 5.02777 3.33331 5.16666 3.33331H8.16666ZM5.16666 7.66665H7.33333V8.16665C7.33333 8.30553 7.28472 8.42359 7.18749 8.52081C7.09027 8.61803 6.97222 8.66665 6.83333 8.66665H3.83333C3.69444 8.66665 3.57638 8.61803 3.47916 8.52081C3.38194 8.42359 3.33333 8.30553 3.33333 8.16665V5.16665C3.33333 5.02776 3.38194 4.9097 3.47916 4.81248C3.57638 4.71526 3.69444 4.66665 3.83333 4.66665H4.33333V6.83331C4.33333 7.06248 4.41493 7.25866 4.57812 7.42185C4.74131 7.58505 4.93749 7.66665 5.16666 7.66665Z' fill='%237E89A9'/%3E%3C/svg%3E%0A)",
      showInLegend: false,
      type: 'flags',
      y: -5,
    },
  ];

  return (
    <Suspense fallback={<ChartLoader />}>
      <Chart
        type="line"
        dimension={dimension}
        height={275}
        titleX={titleCase(dimension)}
        series={[...flowEventSeries, ...flowVersionSeries]}
        tooltipHeaders={['Name', 'Unique users']}
      />
    </Suspense>
  );
};

FlowActivityChart.propTypes = {
  dimension: PropTypes.string,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      flow_completed_users: PropTypes.number,
      flow_users: PropTypes.number,
      flow_error_users: PropTypes.number,
      goal_achievements: PropTypes.number,
      week: PropTypes.string,
    })
  ),
  query: PropTypes.shape({
    metrics: PropTypes.arrayOf(PropTypes.string),
    dimensions: PropTypes.arrayOf(PropTypes.string),
    conditions: PropTypes.arrayOf(PropTypes.array),
    start_time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    end_time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  versions: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.shape({ version: PropTypes.string }))
  ),
  onLoad: PropTypes.func,
};

const FlowActivityChartWithAnalytics = props => {
  const {
    dimension,
    endTime,
    flowId,
    goalId,
    rulesSynced,
    startTime,
    segmentId,
  } = props;

  // to avoid making the query before we know if a goalId will be
  // included, just block until we can tell for sure.
  const query = rulesSynced && {
    metrics: [
      'flow_users',
      'flow_error_users',
      'flow_completed_users',
      ...(goalId ? ['goal_achievements'] : []),
    ],
    dimensions: ['day'],
    conditions: [
      ['flow_id', '==', flowId],
      ...(goalId ? [['goal_id', '==', goalId]] : []),
      ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
    ],
    start_time: startTime,
    end_time: endTime,
    meta: {
      tags: {
        feature: 'Flow analytics',
        page: 'Flow analytics',
        component: 'FlowActivityChart',
        description: 'Flow activity chart',
      },
    },
  };

  return (
    <QueryResults query={query}>
      {results => (
        <FlowActivityChart
          dimension={dimension}
          results={results}
          query={query}
          {...props}
        />
      )}
    </QueryResults>
  );
};

FlowActivityChartWithAnalytics.propTypes = {
  dimension: PropTypes.string,
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flowId: PropTypes.string,
  goalId: PropTypes.string,
  rulesSynced: PropTypes.bool,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = (state, { flowId, startTime, endTime }) => {
  const dimension = getRecommendedFrequency({
    start: new Date(startTime),
    end: new Date(endTime),
  });

  return {
    dimension,
    rulesSynced: selectAreRulesSynced(state),
    goalId: selectGoalForRule(state, flowId),
    versions: selectFlowVersionsByDimension(state, {
      flowId,
      startTime,
      dimension,
    }),
  };
};

const mapDispatchToProps = (dispatch, { flowId }) => ({
  onLoad: () => dispatch(getFlowVersions({ flowId })),
});

const ConnectedFlowActivityChartWithAnalytics = connect(
  mapStateToProps,
  mapDispatchToProps
)(FlowActivityChartWithAnalytics);

ConnectedFlowActivityChartWithAnalytics.propTypes = {
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flowId: PropTypes.string,
  goalId: PropTypes.string,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  segmentId: PropTypes.string,
};

export default ConnectedFlowActivityChartWithAnalytics;
