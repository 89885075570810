import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { replacePagePatternFor } from 'next/entities/page';

import { patterns, reject, resolve } from './actions';

const toCamelCase = rule =>
  // convert the rule keys to camelCase
  Object.keys(rule).reduce((acc, key) => {
    const camelCaseKey = key.replace(/_([a-z])/g, g => g[1].toUpperCase());
    acc[camelCaseKey] = rule[key];
    return acc;
  }, {});

/**
 * Normalize rules summary response as collection
 *
 * @param {Response} response - Raw rules summary response
 * @return {Collection} Rules collection
 */
const transform = response =>
  response.reduce((acc, rule) => {
    acc[rule.id] = toCamelCase(rule);
    return acc;
  }, {});

function* fetchRules() {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getPublishedRules);
    yield put(resolve(transform(response)));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  // Internal actions
  yield takeEvery(patterns.read, fetchRules);

  // Page actions
  yield takeEvery(
    [
      replacePagePatternFor('/mobile/flows/:experienceId/settings'),
      replacePagePatternFor('/flows/:flowId/settings'),
    ],
    fetchRules
  );
}
