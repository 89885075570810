// eslint-disable-next-line no-restricted-imports
import { toast } from 'react-hot-toast';

/**
 * Show message
 *
 * @param {string|React.Element} content - Toast content
 * @return {string} Toast instance ID
 */
const show = content => toast(content);

/**
 * Show successful message
 *
 * @param {string} message - Toast message
 * @return {string} Toast instance ID
 */
const success = message => toast.success(message);

/**
 * Show error message
 *
 * @param {string} message - Toast message
 * @return {string} Toast instance ID
 */
const error = message => toast.error(message);

/**
 * Toast API
 */
export default { show, success, error };
