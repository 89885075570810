import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { Row, Flex, Text, Caption, Column } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';
import { selectAccountUsers } from 'reducers/account/users';

import AvatarImg from 'components/Common/AvatarImg';
import MetricFlowListItem from 'components/Common/MetricFlowListItem';

import { selectFlowsWithGoal } from 'reducers/account/flows';

const FlowsUsingGoal = ({ flows, accountUsers }) => {
  if (flows.length > 0) {
    return (
      <Column gutter={16} alignItems="stretch">
        {flows.map(({ id, updatedAt, updatedBy }) => {
          const updatedByUser =
            updatedBy &&
            accountUsers[updatedBy] &&
            accountUsers[updatedBy].meta &&
            accountUsers[updatedBy].meta.fullname;
          return (
            <MetricFlowListItem key={id} flowId={id} marginBottom="1em">
              <Flex alignItems="center">
                <AvatarImg userId={updatedBy} width={16} marginRight={4} />
                <Caption marginLeft={2} type="tertiary">
                  Updated {moment(updatedAt).format('llll')}
                  {updatedByUser ? ` by ${updatedByUser}` : ''}
                </Caption>
              </Flex>
            </MetricFlowListItem>
          );
        })}
      </Column>
    );
  }

  return (
    <Row>
      <Icon icon="map" />
      <Text marginLeft={16}>None yet</Text>
    </Row>
  );
};

FlowsUsingGoal.propTypes = {
  flows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      updatedAt: PropTypes.number,
      updatedBy: PropTypes.string,
    })
  ),
  accountUsers: PropTypes.shape({
    meta: PropTypes.shape({
      fullname: PropTypes.string,
    }),
  }),
};

const mapStateToProps = (state, { goalId }) => ({
  flows: selectFlowsWithGoal(state, { goalId }),
  accountUsers: selectAccountUsers(state),
});

export default connect(mapStateToProps)(FlowsUsingGoal);
