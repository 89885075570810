import React from 'react';
import { connect } from 'react-redux';
import { ExternalLink } from '@studio/legacy-components';
import GuideStep from 'components/InstallationGuide/GuideStep';
import GuideParagraph from 'components/InstallationGuide/GuideParagraph';
import { updateAccountMeta } from 'actions/account/meta';
import { selectAccountFramework } from 'reducers/account/meta';
import { titleCase } from 'utils/';
import { frameworkIconClassNames } from 'constants/installation/snippets';
import {
  FlexRow,
  Section,
  FrameworkOption,
  FrameworkContainer,
  FrameworkIcon,
  FrameworkName,
  ExternalPartnerSection,
} from './InstallFramework.styled';

const row1 = Object.keys(frameworkIconClassNames).slice(0, 4);
const row2 = Object.keys(frameworkIconClassNames).slice(4, 8);

const getFrameworkName = key => {
  switch (key) {
    case 'HTML':
      return 'HTML (Other)';
    case 'PHP':
      return 'PHP';
    default:
      return titleCase(key);
  }
};

export function InstallFramework({
  accountFramework,
  moveBackward,
  moveForward,
  stepIndex,
  stepMax,
  onSelect,
}) {
  const handleClick = e => {
    onSelect(
      {
        selectedInstallFramework: e.currentTarget.dataset.name,
      },
      false
    );
  };

  const rowElements = row =>
    row.map(frameworkName => {
      return (
        <FrameworkOption
          key={frameworkName}
          data-name={frameworkName}
          selected={accountFramework === frameworkName}
          onClick={handleClick}
        >
          <FrameworkContainer>
            <FrameworkIcon
              icon={frameworkIconClassNames[frameworkName]}
              title={getFrameworkName(frameworkName)}
            />
            <FrameworkName>{getFrameworkName(frameworkName)}</FrameworkName>
          </FrameworkContainer>
        </FrameworkOption>
      );
    });

  return (
    <GuideStep
      stepIndex={stepIndex}
      stepMax={stepMax}
      moveForward={moveForward}
      moveBackward={moveBackward}
      title="Select front-end stack"
    >
      <GuideParagraph>
        Installation snippets will be tailored to the stack you choose.
      </GuideParagraph>
      <Section>
        <h4>Single Page Frameworks</h4>
        <h4>Basic Javascript</h4>
      </Section>
      <FlexRow>{rowElements(row1)}</FlexRow>
      <FlexRow>{rowElements(row2)}</FlexRow>
      <ExternalPartnerSection>
        <GuideParagraph size="full-width" isLastParagraph>
          You can also install Appcues through our integration partners:{' '}
          <ExternalLink href="https://docs.appcues.com/en_US/dev-installing-appcues/installing-with-google-tag-manager-developer">
            Google Tag Manager
          </ExternalLink>
          ,{' '}
          <ExternalLink href="https://docs.appcues.com/en_US/dev-installing-appcues/installing-with-segment">
            Segment
          </ExternalLink>
          ,{' '}
          <ExternalLink href="https://www.rudderstack.com/docs/destinations/streaming-destinations/appcues/">
            RudderStack
          </ExternalLink>
          , and{' '}
          <ExternalLink href="https://documentation.freshpaint.io/integrations/destinations/apps/appcues">
            Freshpaint
          </ExternalLink>
          .
        </GuideParagraph>
      </ExternalPartnerSection>
    </GuideStep>
  );
}

function mapStateToProps(state) {
  return {
    accountFramework: selectAccountFramework(state),
  };
}

const mapDispatchToProps = {
  onSelect: updateAccountMeta,
};

export default connect(mapStateToProps, mapDispatchToProps)(InstallFramework);
