import React from 'react';
import { H2, Paragraph, Button, Link } from '@studio/legacy-components';
import { ComingSoonImage, ComingSoonWrapper } from './styled';

export default function ComingSoon() {
  return (
    <ComingSoonWrapper>
      <ComingSoonImage />
      <H2>Analytics for Pin Experiences are coming soon.</H2>
      <Paragraph>
        In the meantime, you can see this Pin's usage over in Events Explorer.
      </Paragraph>
      <Button forwardedAs={Link} to="/insights/explorer" kind="positive">
        Open Events Explorer
      </Button>
    </ComingSoonWrapper>
  );
}
