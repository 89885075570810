import React from 'react';

const ZapierTemplatesEmbed = () => {
  React.useEffect(() => {
    const s = document.createElement('script');
    s.type = 'application/javascript';
    s.async = true;
    s.src =
      'https://zapier.com/apps/embed/widget.js?services=appcues&limit=3&html_id=zapier-embed';
    document.body.append(s);

    return () => {
      s.remove();
    };
  }, []);

  return <div id="zapier-embed" />;
};

export default ZapierTemplatesEmbed;
