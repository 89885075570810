import { createCollectionReducer } from 'next/lib/collections';
import { selectRequestStatus } from 'next/entities/requests';
import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

export const selectFlows = state => state[TYPE];
export const selectFlow = (state, id) => selectFlows(state)[id];

export const readFlows = state => ({
  ...selectRequestStatus(state, TYPE),
  data: selectFlows(state),
});
export const readFlow = (state, id) => ({
  ...selectRequestStatus(state, TYPE, id),
  data: selectFlow(state, id),
});
