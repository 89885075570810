import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Tr, Td, Switch } from '@appcues/component-library';
import moment from 'moment';
import Textarea from 'components/Common/Forms/Textarea';
import CoolTip from 'components/Common/CoolTip';
import { create, update } from 'entities/event-labels';

const CUSTOM_EVENT_PREFIX = 'appcues_custom:';

const EventTd = styled(Td)`
  vertical-align: center;
  padding: 16px;
  word-wrap: break-word;

  &:first-child {
    padding-left: 32px;
  }

  &:last-child {
    padding-right: 32px;
  }
`;

const Text = styled.div`
  min-width: 150px;
`;

const Name = styled.span`
  word-break: break-word;
`;

const Description = styled(Textarea)`
  min-width: 260px;
  max-height: 100px;
  height: 35px;
`;

const SmallText = styled.div`
  font-size: 12px;
  line-height: 18px;
`;

const Code = styled.pre`
  padding-top: 5px;
`;

const ShowMenu = styled.div`
  text-align: center;
`;

function EventRow({ userEvent, onCreate, onChange }) {
  const { name, description: initial, lastSeenTs, showInUI } = userEvent;

  const $description = useRef();
  const [description, setDescription] = useState(initial);

  useEffect(() => {
    $description.current.style.height = '38px';
    $description.current.style.height = `${$description.current.scrollHeight}px`;
  }, [description]);

  const hasEventLabel = Boolean(userEvent.id);

  const handleChangeEventDescription = e => {
    setDescription(e.target.value);
  };

  const handleBlurEventDescription = e => {
    if (hasEventLabel) {
      onChange(userEvent.id, {
        description: e.target.value,
      });
    } else {
      onCreate({ name: userEvent.name, description: e.target.value });
    }
  };

  const handleChangeShowInUI = show => {
    if (hasEventLabel) {
      onChange(userEvent.id, {
        showInUi: show,
      });
    } else {
      onCreate({ name: userEvent.name, showInUI: show });
    }
  };

  const isBuilderEvent = name.startsWith(CUSTOM_EVENT_PREFIX);
  const displayName = name.replace(CUSTOM_EVENT_PREFIX, '');
  const lastSeen = lastSeenTs
    ? moment(new Date(lastSeenTs)).format('LL')
    : 'Not Seen Yet';

  return (
    <Tr key={`${name}-first`}>
      <EventTd
        // FIXME: No reference to handler defined
        // onClick={ toggleExpandedRow}
        style={{ cursor: 'pointer' }}
      >
        <CoolTip
          tip={
            <>
              <SmallText>EXPORT NAME</SmallText>
              <Code>{name}</Code>
            </>
          }
          position="right"
          width="auto"
        >
          <Name>{displayName}</Name>
        </CoolTip>
      </EventTd>
      <EventTd>
        {isBuilderEvent ? (
          <>
            <Text>Track Event (Builder)</Text>
            <SmallText>created in Builder</SmallText>
          </>
        ) : (
          <>
            <Text>Application Event</Text>
            <SmallText>imported with SDK</SmallText>
          </>
        )}
      </EventTd>
      <EventTd>
        <Description
          ref={$description}
          onChange={handleChangeEventDescription}
          onBlur={handleBlurEventDescription}
          value={description}
          placeholder="Enter description"
        />
      </EventTd>
      <EventTd>{lastSeen}</EventTd>
      <EventTd>
        <ShowMenu>
          <Switch checked={showInUI} onChange={handleChangeShowInUI} />
        </ShowMenu>
      </EventTd>
    </Tr>
  );
}

EventRow.propTypes = {
  userEvent: PropTypes.object.isRequired,
  onChange: PropTypes.object,
};

const mapDispatchToProps = {
  onCreate: create,
  onChange: update,
};

export default connect(null, mapDispatchToProps)(EventRow);
