import React, { Component } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';
import { Button, Icon } from '@studio/legacy-components';

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: 452px;
  padding: 10px 5px;

  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    radial-gradient(
      farthest-side at 50% 0,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
`;

class BaseModal extends Component {
  UNSAFE_componentWillMount() {
    const { modalClass, overrideDocumentBodyDefault } = this.props;
    if (modalClass !== 'pending-modal') {
      window.addEventListener('keyup', this.handleKeyUp);
    }
    if (overrideDocumentBodyDefault) return;
    document.body.style.overflowY = 'hidden';
  }

  componentWillUnmount() {
    const { modalClass } = this.props;
    if (modalClass !== 'pending-modal') {
      window.removeEventListener('keyup', this.handleKeyUp);
    }
    document.body.style.overflowY = '';
  }

  handleKeyUp = e => {
    const { actions } = this.props;
    if (e.keyCode === 27) {
      actions.hideModal();
    }
  };

  handleClickModalBackground = () => {
    const { actions, modalClass } = this.props;
    if (modalClass !== 'pending-modal') {
      actions.hideModal();
    }
  };

  onClickOK = () => {
    const { onClickOK, afterHandleOk, actions } = this.props;
    if (onClickOK) {
      Promise.resolve(onClickOK())
        .then(() => {
          if (afterHandleOk) {
            afterHandleOk();
          } else {
            actions.hideModal();
          }
        })
        .catch(() => {});
    } else {
      actions.hideModal();
    }
  };

  render() {
    const {
      className,
      large,
      xlarge,
      fullScreen,
      actions,
      children,
      okText,
      hideOKButton,
      hideBackground,
      showCancelButton,
      cancelButtonText,
      title,
      isModalDisabled,
      headerHR,
      footerHR,
      scrollBody,
    } = this.props;
    let { modalClass, shouldHideButtonStyle } = this.props;

    modalClass = modalClass || '';
    const isPendingModal = modalClass === 'pending-modal';
    const isGoalModal = modalClass === 'goal-modal';

    const largeClass = large ? 'large' : null;
    const xlargeClass = xlarge ? 'extra-large' : null;
    const fullScreenClass = fullScreen ? 'full-screen' : null;

    shouldHideButtonStyle = shouldHideButtonStyle || false;
    const buttonClass = shouldHideButtonStyle
      ? 'link-button'
      : 'button-primary';

    return (
      <div
        className={classNames('modal', modalClass, className)}
        role="dialog"
        aria-label={title}
      >
        {!hideBackground && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            role="button"
            tabIndex={-1}
            aria-label="Close"
            className={classNames('modal-background', fullScreenClass)}
            onClick={this.handleClickModalBackground}
          />
        )}
        <div
          className={classNames(
            'modal-dialog',
            largeClass,
            xlargeClass,
            fullScreenClass
          )}
        >
          {!isPendingModal && (
            <header>
              <h2>{title}</h2>
              {!isPendingModal && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events
                <a
                  role="button"
                  tabIndex={0}
                  aria-label="Close"
                  className={classNames('close-button')}
                  title="Close Modal"
                  onClick={actions.hideModal}
                >
                  <Icon icon="times" />
                </a>
              )}
            </header>
          )}

          {headerHR && <TopHR />}

          {scrollBody && (
            <ScrollContainer>
              <div className="base-modal-body">{children}</div>
            </ScrollContainer>
          )}

          {!scrollBody && <div className="base-modal-body">{children}</div>}

          {footerHR && <BottomHR />}
          {isGoalModal && <hr />}

          {!isPendingModal && okText && (
            <div className={classNames('modal-buttons')}>
              {showCancelButton && (
                <Button kind="secondary" onClick={actions.hideModal}>
                  {cancelButtonText || 'Cancel'}
                </Button>
              )}

              {!hideOKButton && (
                <Button
                  className={classNames(buttonClass, 'okText')}
                  disabled={isModalDisabled}
                  onClick={this.onClickOK}
                >
                  {okText}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default styled(BaseModal)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme['$gray-70']};
  pointer-events: all;
  z-index: 10000;
  ${props =>
    props.hideBackground ? 'pointer-events: none;' : null} .modal-background {
    position: fixed;
    background-color: ${props => props.theme['$gray-70']};
    opacity: 0.4;
    height: 100%;
    width: 100%;
    pointer-events: all;
    cursor: default;

    &.full-screen {
      display: none;
    }
  }

  .modal-dialog {
    position: relative;
    background-color: ${props => props.theme['$gray-0']};
    min-width: 400px;
    width: 400px;
    padding: 18px 24px;
    border-radius: ${props => props.theme['$border-radius']};
    box-shadow: 0 4px 18px rgba(0, 0, 0, 0.2);

    &.large {
      width: 600px;
      max-height: calc(100vh - 2rem);
      overflow-y: auto;
      margin-bottom: 24px;
      margin-top: 24px;
    }

    &.extra-large {
      width: 1000px;
      margin-bottom: 60px;
      margin-top: 60px;
      max-height: 622px;
      overflow: hidden;
    }

    &.full-screen {
      width: 100%;
      height: 100%;
      opacity: 0.95;
      box-shadow: none;
      box-sizing: border-box;
      padding: 1em 1.5em;
      border-radius: 0;
      box-shadow: none;
      .close-button > i {
        font-size: 20pt;
      }
      overflow-y: auto;
      margin: 0;
    }

    header {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      h2 {
        margin: 0;
        margin-bottom: 0.75rem;
        padding-top: 0;
      }
    }

    .close-button > i {
      margin-top: 2px;
      font-size: 1rem;
      vertical-align: middle;
    }

    .link-button {
      border: none;
    }

    .close-button,
    .link-button {
      color: white;
      text-decoration: none;
      cursor: pointer;
    }

    .modal-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-top: 10px;
      > * {
        margin-left: 10px;
      }
    }

    .base-modal-body {
      display: flex;
      flex-direction: column;
      position: relative;

      h2 {
        margin-top: 11px;
      }
    }

    .modal-divider {
      border-top: 1px solid #e5e5e5;
    }
  }
`;

const HR = styled.hr`
  height: 0;
  border: none;
  border-bottom: 2px solid ${props => props.theme['$gray-1']};
`;

const TopHR = styled(HR)`
  margin-top: 1rem;
  margin-bottom: 0;
`;

const BottomHR = styled(HR)`
  margin-bottom: 1rem;
  margin-top: 0;
`;
