import React from 'react';
import styled from 'styled-components';
import { Switch } from '@appcues/component-library';
import { spacing } from '@appcues/sonar-tokens';
import Input from 'components/Common/Forms/Input';

const ChecklistItemContainer = styled.div`
  width: 100%;
`;

const SwitchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: ${spacing.regular.value};
`;

const SwitchLabel = styled.span`
  padding-right: ${spacing.regular.value};
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const StyledSwitch = styled(Switch)`
  width: 36px;
`;

function ItemActionRedirect({
  pageUrl,
  onUpdateChecklistItemPage,
  onUpdateChecklistItemNewTab,
  redirectNewTab,
}) {
  return (
    <ChecklistItemContainer>
      <StyledInput
        type="text"
        value={pageUrl}
        onChange={onUpdateChecklistItemPage}
        placeholder="Enter a URL or page path..."
      />
      <SwitchContainer>
        <SwitchLabel>Open in new tab</SwitchLabel>
        <StyledSwitch
          checked={redirectNewTab}
          onChange={onUpdateChecklistItemNewTab}
        />
      </SwitchContainer>
    </ChecklistItemContainer>
  );
}

export default ItemActionRedirect;
