import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { PublishingManager } from '@studio/settings-panels';

import {
  requestPublishPin,
  requestRevertPin,
  requestUnpublishPin,
  selectPin,
  Shape as PinShape,
} from 'next/entities/pins';
import { selectUser } from 'next/entities/user';
import { ROLES, selectAccountUser } from 'next/entities/account-users';
import { selectContentStatus } from 'next/entities/content-status';

import ConfirmPushChangesModal from 'next/components/ConfirmPushChangesModal';
import { selectSchedule } from 'next/entities/schedules';
import { ScheduleType } from 'next/components/ExperienceScheduling/types';
import { ExperimentShape } from 'next/entities/experiment';

function PinsPublishingManager({ id, pin, schedule, ...unhandledProps }) {
  return (
    <PublishingManager
      {...unhandledProps}
      renderConfirmPushChangesModal={modalProps => (
        <ConfirmPushChangesModal
          id={id}
          experience={pin}
          schedule={schedule}
          // eslint-disable-next-line @appcues/jsx-props-no-spreading
          {...modalProps}
        />
      )}
    />
  );
}

PinsPublishingManager.propTypes = {
  name: PropTypes.string,
  published: PropTypes.bool,
  hasChanges: PropTypes.bool,
  publishedAt: PropTypes.number,
  canPublish: PropTypes.bool,
  experiment: ExperimentShape,
  renderConfirmPushChangesModal: PropTypes.func,
  onPublish: PropTypes.func,
  onUnpublish: PropTypes.func,
  onDiscardChanges: PropTypes.func,
  children: PropTypes.func,
  flowState: PropTypes.string,
  id: PropTypes.string,
  pin: PinShape,
  schedule: ScheduleType,
};

const mapStateToProps = (state, { id }) => {
  const pin = selectPin(state, id);
  const { id: userId } = selectUser(state);
  const { roleId } = selectAccountUser(state, userId);
  const contentStatus = selectContentStatus(state, id);
  const { published, updatedAt, publishedAt, name } = pin;

  return {
    name,
    published,
    hasChanges: contentStatus?.publishedAndDirty,
    updatedAt,
    publishedAt,
    canPublish: roleId !== ROLES.EDITOR,
    pin,
    schedule: selectSchedule(state),
  };
};

const mapDispatchToProps = (dispatch, { id }) => ({
  onPublish: () => dispatch(requestPublishPin(id)),
  onUnpublish: () => dispatch(requestUnpublishPin(id)),
  onDiscardChanges: version => dispatch(requestRevertPin(id, version)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PinsPublishingManager);
