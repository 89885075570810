import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { CTextInput } from '@appcues/component-library';
import styled from 'styled-components';
import { DEFAULT_COMPONENT_MARGIN_TOP } from 'constants/account/themes';

const lightThemeStyle = `background-color: var(--input-light-bg); border-color: var(--input-light-border);`;
const TextInput = styled(CTextInput)`
  ${({ width }) => width && `width: ${width};`}
  ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
  ${({ styleTheme }) =>
    styleTheme === 'light' &&
    `${lightThemeStyle}
      &:focus{
        ${lightThemeStyle}
      }
    `}
`;

const ThemeInput = ({
  label,
  draftTheme,
  setDraftTheme,
  width,
  minWidth,
  marginTop,
  placeholder,
  suffix,
  handleChangeInputWithSuffix,
  handleOnKeyDown,
  styleTheme,
}) => {
  const handleChange = useCallback(
    value => {
      const labelName = label.name;
      setDraftTheme(prevDraftTheme => {
        return { ...prevDraftTheme, [labelName]: value };
      });
    },
    [setDraftTheme]
  );

  return (
    <TextInput
      value={draftTheme[label.name]}
      onChange={suffix ? handleChangeInputWithSuffix : handleChange}
      marginTop={marginTop ?? DEFAULT_COMPONENT_MARGIN_TOP}
      maxWidth="100%"
      width={width}
      minWidth={minWidth}
      placeholder={placeholder}
      onKeyDown={handleOnKeyDown}
      styleTheme={styleTheme}
    />
  );
};

ThemeInput.propTypes = {
  label: PropTypes.object.isRequired,
  draftTheme: PropTypes.object.isRequired,
  setDraftTheme: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  suffix: PropTypes.string,
  handleChangeInputWithSuffix: PropTypes.func,
  handleOnKeyDown: PropTypes.func,
  styleTheme: PropTypes.string,
};

export default ThemeInput;
