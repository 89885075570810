import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Link } from '@studio/legacy-components';
import {
  queryExperienceGoal,
  queryExperienceUsers,
  queryGoalReachedFromExperienceStarted,
} from 'next/client/queries';
import {
  selectExperienceUsersAnalytics,
  readAnalytics,
  readGoalsReachedFromExperienceStarted,
  selectGoalReachedFromExperienceStarted,
} from 'next/entities/analytics';
import {
  AnalyticsMetricCard,
  AnalyticsMetricGoalPopover,
  AnalyticsMetricTooltip,
  AnalyticsMetricGroup,
  AnalyticsMetricWarning,
} from 'next/components/AnalyticsMetricCard';
import { GoalShape, selectGoal } from 'next/entities/goals';

const calculatePercentage = (numerator, denominator) => {
  const result = numerator / denominator;

  if (denominator === 0 || !Number.isFinite(result)) {
    return '0%';
  }

  return `${Math.floor(result * 100)}%`;
};

export function OverviewMetrics({
  id,
  onLoad,
  usersAnalytics,
  goal,
  goalConversionWindowDays,
  goalsReached,
}) {
  const loadingUsersAnalytics = !usersAnalytics;
  const loadingGoalAnalytics = !goalsReached;

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const { usersMetrics, usersCompletedMetrics } = useMemo(() => {
    const [userQueryResult] = usersAnalytics || [];

    if (!userQueryResult) {
      const emptyMetric = {
        metric: '-',
        description: null,
      };

      return {
        usersMetrics: emptyMetric,
        usersCompletedMetrics: emptyMetric,
      };
    }

    const {
      experience_completed_users: completedUsers,
      experience_users: users,
      experiences_completed: completed,
      experiences_shown: shown,
    } = userQueryResult;

    return {
      usersMetrics: {
        metric: users.toLocaleString(),
        description: `${shown.toLocaleString()} total ${pluralize(
          'time',
          shown
        )} shown`,
      },
      usersCompletedMetrics: {
        metric: calculatePercentage(completedUsers, users),
        description: `${calculatePercentage(
          completed,
          shown
        )} of total users completed`,
      },
    };
  }, [usersAnalytics]);

  const goalMetrics = useMemo(() => {
    if (!goal) {
      return {
        loading: false,
        metric: '-',
        description: 'No goal set',
        additionalInfo: <AnalyticsMetricGoalPopover flowId={id} />,
      };
    }

    const metrics =
      goalsReached?.find(item => item.experience_version === 'all-time') || {};

    const reached = metrics?.goal_reached ?? 0;
    const conversion = metrics?.conversion_rate ?? 0;

    return {
      metric: `${Number.parseInt(100 * conversion, 10)}%`,
      additionalInfo: (
        <AnalyticsMetricTooltip>
          Users who met the goal within {goalConversionWindowDays} days after
          seeing the flow,
        </AnalyticsMetricTooltip>
      ),
      description: (
        <>
          {reached} unique users achieved
          <Link to={`/goals/${goal?.id}`}> {goal?.name}</Link>
        </>
      ),
      warning: goal.disabled ? (
        <AnalyticsMetricWarning>
          Goal is inactive. Go to{' '}
          <Link title="View Goal details" to={`/mobile/flows/${id}/settings`}>
            Flow settings
          </Link>
          <br />
          to choose an active goal.
        </AnalyticsMetricWarning>
      ) : null,
    };
  }, [goal, id, goalsReached, goalConversionWindowDays]);

  return (
    <AnalyticsMetricGroup columns={3}>
      <AnalyticsMetricCard
        title="Unique Users"
        metric={usersMetrics.metric}
        description={usersMetrics.description}
        additionalInfo={
          <AnalyticsMetricTooltip>
            Understand your Flow's reach. This Flow was shown to these unique
            users over the selected time period.
          </AnalyticsMetricTooltip>
        }
        loading={loadingUsersAnalytics}
      />

      <AnalyticsMetricCard
        title="Unique Users Completed"
        metric={usersCompletedMetrics.metric}
        description={usersCompletedMetrics.description}
        loading={loadingUsersAnalytics}
      />

      <AnalyticsMetricCard
        title="Goal"
        metric={goalMetrics.metric}
        description={goalMetrics.description}
        additionalInfo={goalMetrics.additionalInfo}
        loading={loadingGoalAnalytics}
        warning={goalMetrics.warning}
      />
    </AnalyticsMetricGroup>
  );
}

OverviewMetrics.propTypes = {
  id: PropTypes.string,
  onLoad: PropTypes.func,
  usersAnalytics: PropTypes.arrayOf(
    PropTypes.shape({
      experience_completed_users: PropTypes.number,
      users: PropTypes.number,
      experiences_completed: PropTypes.number,
      experiences_shown: PropTypes.number,
    })
  ),
  goal: GoalShape,
  goalConversionWindowDays: PropTypes.number,
  goalsReached: PropTypes.arrayOf(
    PropTypes.shape({
      experience_version: PropTypes.string,
      experience_started: PropTypes.number,
      goal_reached: PropTypes.number,
      conversion_rate: PropTypes.number,
    })
  ),
};

const getQueryParams = ({ id, startTime, endTime, segmentId, goalId }) => ({
  experienceId: id,
  startTime,
  endTime,
  segmentId,
  goalId,
});

const getGoalsParams = ({ accountId, rule, id, goalId }) => ({
  conversionWindow: rule?.goalConversionWindowDays,
  accountId,
  experienceId: id,
  goalId,
});

const mapStateToProps = (state, ownProps) => {
  const { goalId } = ownProps;
  const queryParams = getQueryParams(ownProps);
  const goal = selectGoal(state, goalId);
  const goalsParams = getGoalsParams(ownProps);

  return {
    goalConversionWindowDays: ownProps?.rule?.goalConversionWindowDays,
    usersAnalytics: selectExperienceUsersAnalytics(state, queryParams),
    goalsReached: selectGoalReachedFromExperienceStarted(state, goalsParams),
    goal,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const queryParams = getQueryParams(ownProps);
  const usersQuery = queryExperienceUsers(queryParams);
  const goalQuery = queryExperienceGoal(queryParams);
  const goalsParams = getGoalsParams(ownProps);

  const goalReached = queryGoalReachedFromExperienceStarted(goalsParams);

  return {
    onLoad: () => {
      dispatch(readAnalytics(usersQuery));

      /* Only fetch goal metrics if goal is set */
      if (queryParams.goalId) {
        dispatch(readAnalytics(goalQuery));
        dispatch(readGoalsReachedFromExperienceStarted(goalReached));
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OverviewMetrics);
