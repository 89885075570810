import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { SubTitle, Text, Button, TileGroup, Icon } from '@appcues/sonar';
import SignOut from 'next/components/SignOut';
import ResponsiveWeb from 'next/assets/images/setup/responsive.svg';
import Mobile from 'next/assets/images/setup/mobile.svg';
import Both from 'next/assets/images/setup/both.svg';
import { history } from 'next/lib/history';
import { selectUser } from 'next/entities/user';
import { update } from 'next/entities/account-users';
import {
  Wrapper,
  UserIntentCard,
  Header,
  Actions,
  UserIntentForm,
  Image,
  UserIntentCardWrapper,
  Intent,
} from './styled';

export const USAGE_OPTIONS = [
  {
    label: 'Web app',
    value: 'webapp',
    image: ResponsiveWeb,
  },
  {
    label: 'Native mobile app',
    value: 'native-mobile-apps',
    image: Mobile,
  },
  {
    label: 'Both',
    value: 'both-apps',
    image: Both,
  },
];

export function UsageIntent({ userId, onSubmit }) {
  const [usage, setUsage] = useState('');

  const shouldDisableNext = usage.length === 0;

  const handleUsageChange = newUsage => {
    setUsage(newUsage);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    onSubmit(userId, { usageIntent: usage });
    history.push('/welcome');
  };

  return (
    <Wrapper>
      <UserIntentCardWrapper>
        <UserIntentCard>
          <Header>
            <SubTitle>My product is a:</SubTitle>
            <Text>
              Use Appcues to engage users of your web app, native mobile app, or
              both.
            </Text>
          </Header>
          <UserIntentForm onSubmit={handleSubmit}>
            <TileGroup type="single" onValueChange={handleUsageChange}>
              {USAGE_OPTIONS.map(option => (
                <Intent
                  name="usage"
                  key={option.value}
                  label={option.label}
                  value={option.value}
                >
                  <Image src={option.image} />
                </Intent>
              ))}
            </TileGroup>

            <Actions>
              <Button disabled={shouldDisableNext}>
                Next
                <Icon icon="arrow-right" />
              </Button>
            </Actions>
          </UserIntentForm>
        </UserIntentCard>
      </UserIntentCardWrapper>
      <SignOut />
    </Wrapper>
  );
}

UsageIntent.propTypes = {
  userId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onSubmit: (userId, intent) => dispatch(update(userId, intent)),
});

const mapStateToProps = state => {
  const user = selectUser(state);
  return {
    userId: user.id,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsageIntent);
