import styled from 'styled-components';
import { Tbody } from '@studio/legacy-components';

export const Navigation = styled.nav`
  display: flex;
  flex-direction: row;
  background-color: var(--white);
  box-shadow: var(--level-1);
`;

export const ScrollableTbody = styled(Tbody).attrs({
  as: 'div',
  role: 'rowgroup',
})`
  height: ${props => (props.$rows < 25 ? props.$rows * 35 : 874)}px;
`;
