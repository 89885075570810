import React, { useState } from 'react';
import styled from 'styled-components';
import { propertyOperators } from '@appcues/libcues';
import { CTextInput } from '@appcues/component-library';
import { Button, Select } from '@studio/legacy-components';
import PropertyTextArea from 'components/Common/Clauses/PropertyTextArea';
import BaseClause from 'components/Common/Clauses/Base';

const operatorOptions = [
  { value: propertyOperators.EQUALS, label: 'equals' },
  { value: propertyOperators.NOT_EQUALS, label: "doesn't equal" },
  { value: propertyOperators.CONTAINS, label: 'contains' },
  { value: propertyOperators.NOT_CONTAINS, label: "doesn't contain" },
  { value: propertyOperators.STARTS_WITH, label: 'starts with' },
  {
    value: propertyOperators.NOT_STARTS_WITH,
    label: "doesn't start with",
  },
  { value: propertyOperators.ENDS_WITH, label: 'ends with' },
  { value: propertyOperators.NOT_ENDS_WITH, label: "doesn't end with" },
  { value: propertyOperators.EXISTS, label: 'exists' },
  { value: propertyOperators.NOT_EXISTS, label: "doesn't exist" },
  { value: propertyOperators.IN, label: 'is one of' },
  { value: propertyOperators.NOT_IN, label: "isn't one of" },
  { value: propertyOperators.GREATER_THAN, label: 'is greater than' },
  { value: propertyOperators.LESS_THAN, label: 'is less than' },
  { value: propertyOperators.REGEX, label: 'matches regex' },
];

const InnerAttributeClause = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > :not(:first-child) {
    margin-left: 5px;
  }
`;
const Wrapper = styled.div``;

const ToggleButton = styled(Button)`
  min-width: 186px;
`;

const TextAreaWrapper = styled.div`
  width: 100%;
  margin-top: 4px;
`;

const checkMultiValueOperator = value =>
  [propertyOperators.IN, propertyOperators.NOT_IN].includes(value);

export default function AttributeClause(props) {
  const { clause, updateClause } = props;
  const [showOptions, toggleExpanded] = useState(false);

  const handleToggle = () => {
    toggleExpanded(val => !val);
  };

  const update = change => {
    updateClause(clause.id, change);
    if (checkMultiValueOperator(change?.operator)) {
      toggleExpanded(true);
    }
  };

  const isMultiValueOperator = checkMultiValueOperator(clause.operator);

  return (
    <Wrapper>
      <BaseClause {...props}>
        <InnerAttributeClause>
          <CTextInput
            value={clause.attribute}
            placeholder="Enter attribute"
            onChange={attribute => update({ attribute })}
          />
          <Select
            onChange={({ value }) => update({ operator: value })}
            options={operatorOptions}
            value={operatorOptions.find(
              ({ value }) => value === clause.operator
            )}
          />
          {!isMultiValueOperator && (
            <CTextInput
              value={clause.value}
              placeholder="Enter attribute value"
              onChange={value => update({ value })}
            />
          )}
          {isMultiValueOperator && (
            <ToggleButton kind="secondary" onClick={handleToggle}>
              {showOptions ? 'Hide' : 'Show'} {clause.value?.split('\n').length}{' '}
              values
            </ToggleButton>
          )}
        </InnerAttributeClause>
      </BaseClause>
      <TextAreaWrapper>
        {showOptions && isMultiValueOperator && (
          <PropertyTextArea
            value={clause.value}
            onChange={event => update({ value: event })}
          />
        )}
      </TextAreaWrapper>
    </Wrapper>
  );
}
