import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  BANNER_EVENTS,
  CHECKLIST_EVENTS,
  FLOW_EVENTS,
  GOAL_EVENTS,
  MOBILE_EVENTS,
  PIN_EVENTS,
} from 'components/Insights/constants';
import { selectAccountChecklist } from 'entities/checklists';
import { selectFlow } from 'reducers/account/flows';
import { selectExperience } from 'entities/experiences';
import { selectAccountGoal } from 'reducers/account/goals';

export const getEventLabel = (event, source) => {
  const eventsLabels = {
    pin: PIN_EVENTS,
    banner: BANNER_EVENTS,
    mobile: MOBILE_EVENTS,
    flow: FLOW_EVENTS,
    checklist: CHECKLIST_EVENTS,
    goals: GOAL_EVENTS,
  };

  return (
    ((eventsLabels[source] || []).find(({ type }) => type === event) || {})
      .label || event.replace(/appcues_custom:/, '')
  );
};

const SelectedEventLabel = ({ event, id, name, source }) => {
  if (!event) {
    return '-';
  }

  const eventLabel = getEventLabel(event, source);

  return `${id ? `${name || id}: ` : ''}${eventLabel}`;
};

SelectedEventLabel.propTypes = {
  event: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  source: PropTypes.string,
};

export const mapStateToProps = (state, { id, stepId }) => {
  if (!id) {
    return {};
  }

  const matchingFlow = selectFlow(state, id);
  if (matchingFlow && matchingFlow.name) {
    return {
      name: matchingFlow.name,
    };
  }

  const experience = selectExperience(state, id);
  if (experience) {
    const pin = experience.steps.find(({ id: pinId }) => pinId === stepId);
    return {
      name: experience.name,
      stepName: pin?.name ?? pin?.id,
    };
  }

  const matchingChecklist = selectAccountChecklist(state, id);
  if (matchingChecklist && matchingChecklist.internalName) {
    return {
      name: matchingChecklist.internalName,
    };
  }

  const matchingGoal = selectAccountGoal(state, id);
  if (matchingGoal && matchingGoal.name) {
    return {
      name: matchingGoal.name,
    };
  }

  return {
    name: id,
  };
};

export default connect(mapStateToProps)(SelectedEventLabel);
