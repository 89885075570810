import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ExternalLink, Link } from '@studio/legacy-components';
import Loader from 'components/Common/Loader';
import GuideStep from 'components/InstallationGuide/GuideStep';
import GuideHeading from 'components/InstallationGuide/GuideHeading';
import GuideParagraph from 'components/InstallationGuide/GuideParagraph';
import DomainItem from 'components/Common/DomainItem';
import { selectAccountMeta } from 'reducers/account/meta';
import {
  selectInstalledDomains,
  selectInstalledDomainsSynced,
} from 'reducers/account/installedDomains';
import { DomainStack } from './InstallSummary.styled';

export const InstallSummary = ({
  navigateToInstallDash,
  moveBackward,
  stepMax,
  stepIndex,
  areInstalledDomainsSynced,
  installedDomains = [],
  domains = [],
}) => {
  if (!areInstalledDomainsSynced) {
    return <Loader />;
  }

  // NOTE: Coercing Set to array via spreading currently not supported due to
  //       our babel configurations using core-js for IE support.
  // eslint-disable-next-line unicorn/prefer-spread
  const allDomains = Array.from(
    new Set([
      ...installedDomains.sort((a, b) => a.localeCompare(b)),
      ...domains.filter(Boolean),
    ])
  );

  return (
    <GuideStep
      stepIndex={stepIndex}
      stepMax={stepMax}
      title="Summary"
      moveForward={navigateToInstallDash}
      moveBackward={moveBackward}
      customNextText="Finish Guide"
    >
      <GuideParagraph>
        The last step is to make sure everything is working as expected.
      </GuideParagraph>
      <GuideHeading>Appcues script installation</GuideHeading>
      {allDomains.length > 0 ? (
        <>
          <GuideParagraph>
            We detected the Appcues script on these domains:
          </GuideParagraph>
          <DomainStack>
            {allDomains.map(domain => (
              <DomainItem
                key={domain}
                isInstalled={installedDomains.includes(domain)}
                isInstalledClass="live"
                metaDomain={domain}
                noCheckBox
              />
            ))}
          </DomainStack>
        </>
      ) : (
        <GuideParagraph>
          We haven&apos;t detected the Appcues script in your application yet.
        </GuideParagraph>
      )}

      <GuideParagraph>
        This means Appcues was properly installed on those domains. To confirm
        or test page tracking for any individual page, add{' '}
        <code className="language-markup">?hey_appcues</code> to the end of any
        URL where Appcues is installed. Navigate around and confirm Appcues
        tracks each page change. The diagnostic tool will show that the page is
        tracked with a green label.
      </GuideParagraph>

      <GuideHeading>Identifying users and groups</GuideHeading>
      <GuideParagraph>
        Open any page in your product and add{' '}
        <code className="language-markup">?hey_appcues</code> to the end of the
        url to open the debugger. Confirm the “User identify” section is
        checked. Expand the section to confirm that all properties are accurate.
      </GuideParagraph>

      <GuideHeading>Confirming track events</GuideHeading>
      <GuideParagraph>
        <strong>Using Appcues.track() and integrations</strong>
        <br />
        Trigger one of the Appcues.track() calls you added and confirm it
        appears on the <Link to="/settings/events">Events</Link> page or in{' '}
        <Link to="/insights/explorer">Events Explorer</Link>.
      </GuideParagraph>
      <GuideParagraph>
        <strong>Click-To-Track</strong>
        <br />
        There is a test functionality built right into Click-to-Track as you
        build, but you can also trigger the event by performing the action and
        confirming it appears in{' '}
        <Link to="/insights/explorer">Events Explorer</Link>.
      </GuideParagraph>
      <GuideHeading>Publishing and debugging experiences</GuideHeading>
      <GuideParagraph>
        <ExternalLink href="https://docs.appcues.com/user-experiences-troubleshooting/testing-flows">
          To test if flows are showing live
        </ExternalLink>
        , it's best to publish to staging environment or publish only to
        yourself. If you're having trouble getting experiences to show, the{' '}
        <ExternalLink href="https://docs.appcues.com/dev-troubleshooting/185478-installation-testing-debugging">
          Appcues Debugger
        </ExternalLink>{' '}
        helps you quickly spot a problem.
      </GuideParagraph>
    </GuideStep>
  );
};

InstallSummary.propTypes = {
  navigateToInstallDash: PropTypes.func,
  moveBackward: PropTypes.func,
  stepMax: PropTypes.number,
  stepIndex: PropTypes.number,
  areInstalledDomainsSynced: PropTypes.bool,
  installedDomains: PropTypes.array,
  domains: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => {
  const { domains = [] } = selectAccountMeta(state);
  return {
    areInstalledDomainsSynced: selectInstalledDomainsSynced(state),
    installedDomains: selectInstalledDomains(state),
    domains,
  };
};

export default connect(mapStateToProps)(InstallSummary);
