import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import styled from 'styled-components';

import {
  CForm,
  CButton,
  P,
  CModal,
  ModalProvider,
} from '@appcues/component-library';

import OpenTrackEventModal from 'components/Insights/Explorer/Common/OpenTrackEventModal';

const ModalWrapper = styled.div`
  .modal-panel {
    height: auto !important;
    min-width: 508px;
  }

  &.modal--medium .modal-panel {
    min-width: 600px;
  }

  .modal-panel button.close-button {
    background: transparent;
    height: 24px;
    top: 16px;
    right: 13px;
    width: 24px;

    svg {
      font-size: 12px !important;
      font-weight: 300;
      height: auto;
      line-height: 12px;
      width: 12px;
    }
  }
`;

const StyledInitialModalContent = styled.section`
  padding: 24px 48px;

  label {
    margin-bottom: 0;
    padding-bottom: 26px;

    span {
      font-weight: 700;
    }
  }
`;

const StyledInitialHeading = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 8px;
`;

const StyledInitialFooter = styled.footer`
  margin-bottom: 8px;
  text-align: right;
  width: 100%;
`;

const StyledTextInput = styled(CForm.Field)`
  width: 100%;
`;

const StyledSubmitButton = styled(CForm.SubmitButton)`
  margin-left: 15px;
`;

// this component deliberately leaves out a check to see if chrome status
// has been checked to avoid duplicating boilerplate. This check is handled
// in CreateEventManager, which is responsible for rendering this modal
export const CreateEventModal = ({ onClose }) => {
  const [url, setUrl] = useState('');

  const handleSubmit = values => {
    setUrl(values.url);
  };

  return (
    <ModalWrapper className={url ? 'modal--medium' : 'modal--small'}>
      <ModalProvider>
        {!url && (
          <CModal title="" onClose={onClose} type="small">
            <StyledInitialModalContent>
              <StyledInitialHeading>Create Track Event</StyledInitialHeading>
              <P>Where do you want to create a Track event?</P>
              <CForm
                onSubmit={handleSubmit}
                initialValues={{
                  url: '',
                }}
                validationSchema={yup.object().shape({
                  url: yup
                    .string()
                    .url('Must be a valid URL')
                    .required('Required'),
                })}
              >
                <StyledTextInput
                  label="URL"
                  name="url"
                  placeholder="https://appcues.com"
                />
                <StyledInitialFooter>
                  <CButton onClick={onClose}>Cancel</CButton>
                  <StyledSubmitButton
                    type="primary"
                    disabledUntilDirty={false}
                    ariaLabel="Continue"
                  >
                    Continue
                  </StyledSubmitButton>
                </StyledInitialFooter>
              </CForm>
            </StyledInitialModalContent>
          </CModal>
        )}
        {url && <OpenTrackEventModal event="new" url={url} onClose={onClose} />}
      </ModalProvider>
    </ModalWrapper>
  );
};

CreateEventModal.propTypes = {
  onClose: PropTypes.func,
};
export default CreateEventModal;
