import PropTypes from 'prop-types';

export default PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.string,
  description: PropTypes.string,
  lastSeenTs: PropTypes.number,
  showInUI: PropTypes.bool,
  isStarred: PropTypes.bool,
});
