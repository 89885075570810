import React from 'react';
import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleWrapper = styled.div`
  margin-top: 1rem;
`;

const TestingIcon = styled(Icon)`
  color: ${props => props.theme.$purple};
  margin-right: 0.5rem;
`;

const HeaderText = styled.h2`
  display: inline;
`;

const CloseIcon = styled(Icon)`
  cursor: pointer;
  color: ${props => props.theme['$gray-50']};
  font-size: 20px;
`;

const WizardHeader = props => {
  const { closeHelper } = props;

  return (
    <HeaderWrapper>
      <TitleWrapper>
        <TestingIcon icon="clipboard-check" />
        <HeaderText>Test Page Targeting</HeaderText>
      </TitleWrapper>
      <CloseIcon icon="times" onClick={closeHelper} role="button" />
    </HeaderWrapper>
  );
};

export default WizardHeader;
