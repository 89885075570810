import styled from 'styled-components';

import { Caption, Card, H2, Icon, Input } from '@studio/legacy-components';

export const Header = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  ${H2} {
    margin-right: 24px;
  }

  ${Caption} {
    color: var(--oh-hi-dark);
    font-weight: var(--bold);
  }
`;

export const Description = styled.div`
  max-width: 579px;
`;

export const AddonWrapper = styled(Card)`
  width: 100%;
  flex-direction: column;

  > div {
    padding: 24px 24px 28px 24px;
  }

  ${Description} {
    margin-top: 15px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

// Calculator
export const Operator = styled.button`
  border: solid 1px var(--sharkbait-ooh-la-la);
  background: var(--white);
  border-radius: 6px;
  width: 40px;
  height: 40px;

  &[disabled] {
    border-color: var(--myst);

    ${Icon} {
      color: var(--myst);
    }
  }

  ${Icon} {
    color: var(--sharkbait-ooh-la-la);
  }
`;

export const CalculatorWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  grid-gap: 12px;

  ${Input} {
    background: var(--white);
    border: solid 1px var(--sharkbait-ooh-la-la);
    width: 66px;
    height: 40px;
  }
`;
