import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import styled from 'styled-components';

import { CDialog, CForm, CButton } from '@appcues/component-library';
import { modalStatuses } from 'constants/modalStatuses';
import { CLICKED_BUTTON } from 'constants/events';
import { createProductLedFlow } from 'actions/account/flows';

import { asPromised } from 'utils/as-promised';
import { reportError } from 'helpers/error-reporting';
import OpenInBuilderModal from 'components/OpenInBuilder/OpenInBuilderModal';
import * as persistence from 'helpers/persistence';
import { openFlowInBuilder } from 'utils/url';
import { selectChromeExtensionVersion } from 'reducers/chromeExtension';
import { navigate } from 'actions/routing';
import { canOpenInBuilder } from 'utils/chromeExtension';

class CreateProductLedFlowModal extends Component {
  state = {
    requestState: modalStatuses.PROMPT,
    name: '',
    flowUrl: null,
    flowId: null,
  };

  onFormSubmit = ({ name, previewUrl }) => {
    const {
      actions,
      accountId,
      userLifecycleStage,
      create,
      chromeExtensionVersion,
    } = this.props;

    const dontShowOpenInBuilderModal =
      persistence.getItem('dontShowOpenInBuilderModal') === 'true';

    const isLatestVersion = canOpenInBuilder(chromeExtensionVersion);

    actions.trackEvent(CLICKED_BUTTON, {
      buttonLabel: 'Create in Product Led Flow Modal',
      userLifecycleStage,
    });

    this.setState({ requestState: modalStatuses.PENDING, name });

    create({
      userLifecycleStage,
      previewUrl,
      name,
    })
      .then(id => {
        actions.trackEvent('Created Product Led Flow & Opening In Builder', {
          userLifecycleStage,
        });
        if (isLatestVersion) {
          this.setState({ flowUrl: previewUrl });
          if (dontShowOpenInBuilderModal) {
            openFlowInBuilder(id, null, previewUrl);
            this.hideModal();
          } else {
            this.setState({ requestState: modalStatuses.SUCCESS, flowId: id });
          }
        } else {
          actions.hideModal();
          navigate(`/edit-in-chrome/stepGroup/${id}`, true);
        }
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.setState({ requestState: modalStatuses.ERROR });
        reportError('Could not create PLG flow', {
          err: error,
          accountId,
          userLifecycleStage,
          previewUrl,
          name,
        });
      });
  };

  hideModal = () => {
    const { actions } = this.props;
    actions.hideModal();
  };

  render() {
    const { flowName } = this.props;
    const { requestState, flowUrl, flowId, name } = this.state;

    switch (requestState) {
      case modalStatuses.PROMPT:
        return (
          <CDialog title="Create Flow" components={{ Footer: false }}>
            Just some information first and we&apos;ll start building!
            <GroupWrapper>
              <CForm
                onSubmit={this.onFormSubmit}
                initialValues={{
                  name: flowName,
                }}
                validationSchema={yup.object().shape({
                  name: yup.string().required('Required'),
                  previewUrl: yup
                    .string()
                    .url('Must be a valid URL')
                    .required('Required'),
                })}
              >
                <CForm.Field label="Name" name="name" placeholder={flowName} />
                <CForm.Field
                  label="Start building this flow on"
                  name="previewUrl"
                  placeholder="https://appcues.com"
                />
                <StyledFooter>
                  <CButton onClick={this.hideModal}>Cancel</CButton>
                  <CForm.SubmitButton
                    type="positive"
                    disabledUntilDirty={false}
                  >
                    Create
                  </CForm.SubmitButton>
                </StyledFooter>
              </CForm>
            </GroupWrapper>
          </CDialog>
        );
      // TODO: Pending state? Ask Jen
      case modalStatuses.PENDING:
        return (
          <CDialog title={`Creating "${name}"`} components={{ Footer: false }}>
            <Text>Getting everything set up for you to build!</Text>
          </CDialog>
        );

      // TODO: Error state? Ask Jen
      case modalStatuses.ERROR:
        return (
          <CDialog
            title="Something went wrong"
            primaryActionText="Okay"
            onPrimaryAction={this.hideModal}
          >
            <Text> We weren&apos;t able to create your flow.</Text>
            <Text>
              {' '}
              Try again, and if the problem persists please contact support.
            </Text>
          </CDialog>
        );

      case modalStatuses.SUCCESS:
        return (
          <OpenInBuilderModal
            url={flowUrl}
            onClose={this.hideModal}
            stepGroupId={flowId}
          />
        );

      default:
        return null;
    }
  }
}

const GroupWrapper = styled.div`
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledFooter = styled(CDialog.Footer)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Text = styled.p`
  line-height: 24px;
  padding: 4px 0;
`;

const mapDispatchToProps = dispatch => ({
  create: args => asPromised(dispatch, createProductLedFlow(args)),
});

function mapStateToProps(state) {
  return {
    chromeExtensionVersion: selectChromeExtensionVersion(state),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProductLedFlowModal);
