import {
  takeEvery,
  select,
  call,
  fork,
  put,
  getContext,
} from 'redux-saga/effects';
import { baseTheme, getOpenBuilderTheme } from '@appcues/theme-utilities';
import toast from 'next/lib/toast';
import {
  replaceAccountThemes as replaceThemes,
  replaceAccountTheme as replaceTheme,
} from 'actions/account/themes';
import {
  CLONE_ACCOUNT_THEME,
  CREATE_ACCOUNT_THEME,
  DELETE_ACCOUNT_THEME,
  SET_DEFAULT_THEME,
  UPDATE_ACCOUNT_THEME_AND_FLUSH,
} from 'constants/account/themes';
import { selectAccountId } from 'reducers/account/meta';
import { selectAccountTheme } from 'reducers/account/themes';
import { reportError } from 'helpers/error-reporting';
import { rebundleAccount } from 'helpers/bundler';
import { trackEvent } from 'actions/events';
import { navigate } from 'actions/routing';
import { hideModal } from 'actions/currentModal';
import { hitPublishHook } from 'sagas/account/publishing';
import { CLICKED_BUTTON } from 'constants/events';

/**
 * Themes were not fully normalized when they were moved to
 * Postgres, but rather a few "top level keys" were made into
 * columns and everything else is stored in a jsonb column
 * called `attributes`.
 *
 * To avoid a cascade of UI changes, we'll manage this by
 * encoding/decoding themes on their way in and out of Studio
 */

const TOP_LEVEL_KEYS = new Set([
  'createdAt',
  'createdBy',
  'id',
  'isDefault',
  'name',
  'updatedAt',
  'updatedBy',
]);

const encode = theme => {
  return Object.entries(theme).reduce(
    (acc, [key, value]) => {
      if (TOP_LEVEL_KEYS.has(key)) {
        acc[key] = value;
      } else {
        acc.attributes[key] = value;
      }
      return acc;
    },
    { attributes: {} }
  );
};

const decode = ({ attributes, ...theme }) => ({
  ...attributes,
  ...theme,
});

export function* fetchThemes() {
  const api = yield getContext('api');
  const { data } = yield call(api.getThemes);

  const transformed = data.map(decode).reduce((acc, theme) => {
    acc[theme.id] = theme;
    return acc;
  }, {});

  yield put(replaceThemes(transformed));
}

export function* updateAccountThemeAndFlush(action) {
  try {
    const {
      payload: { themeId },
    } = action;

    const updatedTheme = yield select(selectAccountTheme, themeId);

    // Open builder theme doesn't affect anything on theme UI
    // and that is why we use the getOpenBuilderTheme in the saga.
    // It will basically get the current updatedTheme and generate
    // the open-builder format.
    // Our backend apis will get open builder themes
    // from a theme key saved in firebase
    const openBuilderTheme = getOpenBuilderTheme({
      styleObject: updatedTheme,
    });

    const api = yield getContext('api');
    const { data: response } = yield call(
      api.updateTheme,
      themeId,
      encode({
        ...updatedTheme,
        theme: openBuilderTheme,
      })
    );

    yield put(replaceTheme(themeId, decode(response)));

    yield fork(hitPublishHook);

    const accountId = yield select(selectAccountId);
    yield fork(rebundleAccount, accountId);

    yield call(toast.success, 'Theme saved successfully');

    try {
      yield put(
        trackEvent(CLICKED_BUTTON, {
          buttonLabel: 'Update theme',
          theme_id: themeId,
        })
      );
    } catch (error) {
      yield call(reportError, error);
    }
  } catch (error) {
    yield call(toast.error, 'Theme failed to save');
    yield call(reportError, error);
  }
}

export function* createAccountTheme() {
  const newTheme = {
    ...baseTheme,
    name: 'New Theme',
    isDefault: false,
  };

  try {
    const api = yield getContext('api');
    const { data: theme } = yield call(api.createTheme, encode(newTheme));
    const { id } = theme;

    yield put(replaceTheme(id, decode(theme)));
    yield fork(hitPublishHook);

    try {
      yield put(
        trackEvent(CLICKED_BUTTON, {
          buttonLabel: 'Create theme',
          theme_id: id,
        })
      );
    } catch (error) {
      yield call(reportError, error);
    }

    yield put(navigate(`/themes/${id}/edit`));
  } catch (error) {
    yield call(toast.error, 'Failed to create theme');
    yield call(reportError, error);
  }
}

export function* cloneAccountTheme(action) {
  const {
    payload: { originalThemeId },
  } = action;

  const {
    id,
    name,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    isDefault,
    ...attributes
  } = yield select(selectAccountTheme, originalThemeId);

  const newTheme = {
    ...attributes,
    name: `Clone of ${name}`,
  };

  try {
    const api = yield getContext('api');
    const { data: clonedTheme } = yield call(api.createTheme, encode(newTheme));
    const { id: themeId } = clonedTheme;

    yield put(replaceTheme(themeId, decode(clonedTheme)));
    yield fork(hitPublishHook);

    try {
      yield put(
        trackEvent(CLICKED_BUTTON, {
          buttonLabel: 'Clone theme',
          theme_id: themeId,
        })
      );
    } catch (error) {
      yield call(reportError, error);
    }

    yield put(navigate(`/themes/${themeId}/edit`));
    yield call(toast.success, 'Theme successfully cloned');
  } catch (error) {
    yield call(toast.error, 'Failed to clone theme');
    yield call(reportError, error);
  }
}

export function* deleteAccountTheme(action) {
  const {
    payload: { themeId },
  } = action;

  try {
    yield put(navigate('/themes'));

    const api = yield getContext('api');
    yield call(api.deleteTheme, themeId);
    yield put(hideModal());
    yield put(replaceTheme(themeId));

    yield fork(hitPublishHook);

    try {
      yield put(
        trackEvent(CLICKED_BUTTON, {
          buttonLabel: 'Delete theme',
          theme_id: themeId,
        })
      );
    } catch (error) {
      yield call(reportError, error);
    }

    yield call(toast.success, 'Theme successfully deleted');
  } catch (error) {
    yield call(toast.error, 'Failed to delete theme');
    yield call(reportError, error);
  }
}

export function* setDefaultTheme(action) {
  const {
    payload: { themeId },
  } = action;
  try {
    const api = yield getContext('api');
    yield call(api.setDefaultTheme, themeId);
    yield call(fetchThemes);
    yield call(hitPublishHook);
  } catch (error) {
    yield call(toast.error, 'Failed to update default theme');
    yield call(reportError, error);
  }
}

export default function* themeSagas() {
  yield takeEvery(UPDATE_ACCOUNT_THEME_AND_FLUSH, updateAccountThemeAndFlush);
  yield takeEvery(CREATE_ACCOUNT_THEME, createAccountTheme);
  yield takeEvery(CLONE_ACCOUNT_THEME, cloneAccountTheme);
  yield takeEvery(DELETE_ACCOUNT_THEME, deleteAccountTheme);
  yield takeEvery(SET_DEFAULT_THEME, setDefaultTheme);
}
