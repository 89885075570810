import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { replacePagePatternFor } from 'next/entities/page';

import { patterns, reject, resolve } from './actions';
import { parse } from './shape';

/**
 * Normalize themes summary response as collection
 *
 * @param {Response} response - Raw themes summary response
 * @return {Collection} themes collection
 */
const transform = response =>
  response.reduce((acc, theme) => {
    acc[theme.id] = parse(theme);
    return acc;
  }, {});

function* fetchThemes() {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getThemes);
    yield put(resolve(transform(response)));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  // Internal actions
  yield takeEvery(patterns.read, fetchThemes);

  // External actions
  yield takeEvery(
    [replacePagePatternFor('/pins'), replacePagePatternFor('/banners')],
    fetchThemes
  );
}
