import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ChecklistsIndex from 'components/ChecklistsIndex';
import ChecklistsEdit from 'components/ChecklistsEdit';
import ChecklistAnalytics from 'components/ChecklistAnalytics';

const Checklists = () => (
  <Switch>
    <Route path="/checklists/:checklistId/edit" component={ChecklistsEdit} />
    <Route
      path="/checklists/:checklistId/analytics"
      component={ChecklistAnalytics}
    />
    <Route path="/checklists" component={ChecklistsIndex} />
  </Switch>
);

export default Checklists;
