export function serializeByKey(obj = {}, key) {
  return Object.entries(obj).reduce((acc, [k, v]) => {
    return {
      ...acc,
      [k]: v.reduce(
        (accItem, item) => ({
          ...accItem,
          [item[key]]: item,
        }),
        {}
      ),
    };
  }, {});
}
