import languages from 'constants/languages';

export const getChosenLanguageLabel = currentLanguage => {
  return currentLanguage.code.length === 2 &&
    languages.filter(
      language => language.code.startsWith(currentLanguage.code).length > 1
    )
    ? currentLanguage.desc
    : `${currentLanguage.desc} (all)`;
};

export const getLanguageFromCode = code =>
  languages.find(language => language.code.startsWith(code))?.desc || code;
