import React from 'react';
import PropType from 'prop-types';
import { Icon, Row } from '@appcues/sonar';
import {
  ButtonBody,
  ButtonSubTitle,
  ButtonTitle,
  Container,
  IconContainer,
} from './styled';

function HelpBarButton({ title, subtitle, icon, onClick, ...unhandledProps }) {
  return (
    <Container onClick={onClick} {...unhandledProps}>
      <Row spacing="small">
        <IconContainer>
          <Icon icon={icon} colorToken="color-blue-700" />
        </IconContainer>
        <ButtonBody>
          <ButtonTitle>{title}</ButtonTitle>
          <ButtonSubTitle>{subtitle}</ButtonSubTitle>
        </ButtonBody>
      </Row>
    </Container>
  );
}

HelpBarButton.propTypes = {
  title: PropType.string.isRequired,
  subtitle: PropType.string.isRequired,
  icon: PropType.shape({
    colorToken: PropType.string,
    size: PropType.string,
  }).isRequired,
  onClick: PropType.func.isRequired,
};

export default HelpBarButton;
