export const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;

export const OPERATORS = [
  { label: 'equals', value: '==' },
  { label: "doesn't equal", value: '!=' },
  { label: 'contains', value: '*' },
  { label: "doesn't contain", value: '!*' },
  { label: 'starts with', value: '^' },
  { label: "doesn't start with", value: '!^' },
  { label: 'ends with', value: '$' },
  { label: "doesn't end with", value: '!$' },
  { label: 'exists', value: '?' },
  { label: "doesn't exist", value: '!?' },
  { label: 'is one of', value: 'in' },
  { label: "isn't one of", value: 'not in' },
  { label: 'is greater than', value: '>' },
  { label: 'is less than', value: '<' },
  { label: 'occurred more than', value: '> ago' },
  { label: 'occurred less than', value: '< ago' },
  { label: 'will occur within the next', value: 'within' },
  { label: 'occurred before', value: '< time' },
  { label: 'occurred after', value: '> time' },
  { label: 'matches regex', value: 'regex' },
];

export const DEFAULT_OPERATORS = new Set([
  '==',
  '!=',
  '*',
  '!*',
  '^',
  '!^',
  '$',
  '!$',
  'regex',
]);
export const MULTI_VALUE_OPERATORS = new Set(['in', 'not in']);
export const NUMERIC_OPERATORS = new Set(['>', '<']);
export const RELATIVE_TIME_OPERATORS = new Set(['> ago', '< ago', 'within']);
export const ABSOLUTE_TIME_OPERATORS = new Set(['> time', '< time']);
export const EXISTENTIAL_OPERATORS = new Set(['?', '!?']);
