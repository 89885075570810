import hash from 'object-hash';

export const baseRule = {
  source: 'MY.APPCUES',
  state: 'DRAFT',
  published: false,
  shown: false,
  bookmarkId: null,
  domains: '',
  for: 0,
  when: 0,
  where: '/',
  whereHash: hash('/'),
  isRegex: false,
  languages: '',
  properties: null,
  frequency: 'once',
  startDate: null,
  endDate: null,
  editing: false,
  nextContentId: null,
  redirectUrl: null,
  contentType: 'journey',
};

export const CREATE_RULE = 'CREATE_RULE';
export const FETCH_FLOW_RULE = 'FETCH_FLOW_RULE';
