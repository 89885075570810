import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Select } from '@studio/legacy-components';
import { selectSegmentTargetingOptions } from 'reducers/account/segments';
import { sortAscending } from 'utils';

export function SelectSegmentDropDown({
  blacklist = [],
  onChange,
  placeholder = 'Select a segment...',
  options = [],
  shouldSortSegmentsList = false,
  value,
  ...selectProps
}) {
  const filteredOptions = options.filter(
    ({ value: segmentId }) => !blacklist.includes(segmentId)
  );

  const sortedOptions = shouldSortSegmentsList
    ? filteredOptions.sort((a, b) => {
        const aName = a.label && a.label.toLowerCase();
        const bName = a.label && b.label.toLowerCase();
        return sortAscending(aName, bName);
      })
    : filteredOptions;

  const selected = sortedOptions.find(option => option.value === value);

  return (
    <Select
      icon="users"
      onChange={option => onChange(option?.value ?? null)}
      options={sortedOptions}
      placeholder={placeholder}
      value={selected}
      {...selectProps}
    />
  );
}

SelectSegmentDropDown.propTypes = {
  blacklist: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  segments: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  shouldSortSegmentsList: PropTypes.bool,
  value: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    options: selectSegmentTargetingOptions(state),
  };
}

export default connect(mapStateToProps)(SelectSegmentDropDown);
