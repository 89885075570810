import React from 'react';
import { Button } from '@appcues/component-library';
import styled from 'styled-components';
import BaseModal from 'components/ModalContainer/BaseModal';
import hourglass from 'assets/images/hourglass.png';
import { SUPPORT_EMAIL } from 'constants/externalAppcuesResources';

function NPSTrialExpirationModal(props) {
  const { className, stepId, onClickSubscribeCallback } = props;

  const handleClick = () => {
    if (stepId === 'new' || !stepId) {
      return;
    }

    if (onClickSubscribeCallback) {
      onClickSubscribeCallback();
    }
  };

  return (
    <div className={className}>
      <div className="survey-background" />
      <BaseModal
        overrideDocumentBodyDefault
        hideOKButton
        large
        hideBackground
        modalClass="pending-modal"
        {...props}
      >
        <div className="satisfaction-icon-container">
          <img alt="Hourglass" src={hourglass} />
        </div>
        <h2 className="satisfaction-intro-header">Your Trial is up!</h2>
        <p className="satisfaction-intro-subtext">
          <b>Don&apos;t worry</b>, we&apos;ll keep running your survey and
          collecting data as long as you don&apos;t turn it off!
        </p>
        <p className="satisfaction-intro-subtext paragraph-spacing-tight">
          Purchase a subscription to edit your survey, see your comprehensive
          results dashboard, and export and share your data.
        </p>
        <p className="satisfaction-intro-subtext paragraph-spacing-tight subtext">
          If you have any questions, shoot us an email at{' '}
          <a href={`${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
        </p>
        <Button
          onClick={handleClick}
          className="button-success subscribe-nps-button"
        >
          Subscribe to Appcues
        </Button>
      </BaseModal>
    </div>
  );
}

export default styled(NPSTrialExpirationModal)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  text-align: center;

  .satisfaction-icon-container {
    position: relative;
    top: -150px;
    height: 0px;

    img {
      height: 150px;
      margin: 0px auto;
    }
  }

  .survey-background {
    height: 100%;
    width: 100%;
  }

  .modal-dialog.large {
    width: 750px;
    pointer-events: all;
    overflow: visible;

    .base-modal-body {
      padding: 90px 0px 0px;
    }
  }

  .satisfaction-intro-header {
    margin-bottom: 11px;
    font-weight: 300;
    font-size: 36px;
    color: ${props => props.theme['$gray-50']};
  }

  .satisfaction-intro-subtext {
    font-size: 18px;
    margin: 20px 40px 20px 50px;
    text-align: left;
    color: #717171;
  }

  .paragraph-spacing-tight {
    margin-top: 0px;
  }

  .subtext {
    font-style: italic;
  }

  .subscribe-nps-button {
    margin: 15px auto;
    padding: 10px 15px;
  }
`;
