import { useEffect, useState } from 'react';

import { PLAN_TYPES, PRICES, BILLING_INTERVALS } from 'constants/plans';

export const calculatePlanPrice = ({ planInterval, plan, planLimit }) => {
  // Prices will be undefined for legacy plans
  const prices =
    (PRICES[planInterval] && PRICES[planInterval][planLimit]) ?? {};
  const isHighVolume = planLimit === 'high-volume';
  const isEnterprise = plan.name === 'Enterprise';

  const hasPrice = !isEnterprise && !isHighVolume;

  return hasPrice
    ? plan.price || prices[plan.pricePlanId || plan.id]
    : "Let's talk";
};

/**
 * This hook reads the current URL params and finds all the plan's data
 * based on these parameters
 * - pi: Plan Interval
 * - pp: Plan Type
 * - pl: Plan Limit
 *
 * Setting these values in the URL allows us to keep the state between route changes.
 */
const usePlanDataFromURL = location => {
  const [planData, setPlanData] = useState();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const planInterval = params.get('pi');
    const planType = params.get('pp');
    const planLimit = Number.parseInt(params.get('pl'), 10);

    const plan = PLAN_TYPES.find(p => p.id === planType);
    const { multiplier } = BILLING_INTERVALS[planInterval];

    const rawPrice = Number.parseFloat(
      calculatePlanPrice({ planInterval, plan, planLimit }),
      10
    );

    setPlanData({
      ...plan,
      planType,
      planLimit,
      planInterval,
      price: rawPrice * multiplier,
      period: BILLING_INTERVALS[planInterval].displayPeriod,
      cadence: BILLING_INTERVALS[planInterval].displayName,
    });
  }, [location]);

  return {
    planData,
    setPlanData,
  };
};

export default usePlanDataFromURL;
