import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NegativeNotice, TextArea } from '@studio/legacy-components';

const Wrapper = styled.div`
  ${NegativeNotice} {
    margin-bottom: 10px;
  }

  ${TextArea} {
    width: 100%;
  }
`;

const PropertyTextArea = ({ value, onChange }) => {
  const [isErrored, setIsErrored] = useState(false);

  /**
   * NOTE: It's possible that the value saved is not a string in rare cases
   *       which would cause the `String#split` to fail. So as a precaution, the
   *       value is cast into a `String` which would allow the method to be
   *       called and ultimately fix the persisted data type as well.
   */
  const numValues = useMemo(() => String(value)?.split('\n').length, [value]);

  const handleChange = ({ target: { value: nextValue } }) => {
    const values = nextValue
      .replace(/(\s*,\s*)|\n/g, ',\n')
      .replace(/\s*,\s*/g, ',')
      .replace(/(^[\t ]*\n*\s+$)/gm, '')
      .split(',');
    setIsErrored(values.length > 1000);
    onChange(values.slice(0, 1000).join('\n'));
  };

  const handleErrorDismissed = () => setIsErrored(false);

  return (
    <Wrapper>
      {isErrored && (
        <NegativeNotice onDismiss={handleErrorDismissed}>
          Only the maximum 1,000 values will be saved.
        </NegativeNotice>
      )}
      <TextArea
        resize
        name="value"
        aria-label="value"
        value={value}
        placeholder="Enter values separated by commas or line breaks"
        onChange={handleChange}
      />
      <p>{`${numValues}/1000`}</p>
    </Wrapper>
  );
};

PropertyTextArea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default PropertyTextArea;
