import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PublishingManager } from '@studio/settings-panels';
import ConfirmPushChangesModal from 'next/components/ConfirmPushChangesModal';
import {
  requestPublishExperience,
  requestRevertExperience,
  requestUnpublishExperience,
  selectExperience,
  Shape as ExperienceShape,
} from 'next/entities/experiences';
import { selectUser } from 'next/entities/user';
import { ROLES, selectAccountUser } from 'next/entities/account-users';
import { selectContentStatus } from 'next/entities/content-status';
import { selectSchedule } from 'next/entities/schedules';

import { ScheduleType } from 'next/components/ExperienceScheduling/types';
import { selectIsInstalled } from 'next/entities/installed';
import { ExperimentShape } from 'next/entities/experiment';

function ExperiencePublishingManager({
  id,
  experience,
  schedule,
  ...unhandledProps
}) {
  return (
    <PublishingManager
      {...unhandledProps}
      renderConfirmPushChangesModal={modalProps => (
        <ConfirmPushChangesModal
          id={id}
          experience={experience}
          schedule={schedule}
          // eslint-disable-next-line @appcues/jsx-props-no-spreading
          {...modalProps}
        />
      )}
    />
  );
}

ExperiencePublishingManager.propTypes = {
  name: PropTypes.string,
  published: PropTypes.bool,
  hasChanges: PropTypes.bool,
  publishedAt: PropTypes.number,
  canPublish: PropTypes.bool,
  experiment: ExperimentShape,
  renderConfirmPushChangesModal: PropTypes.func,
  onPublish: PropTypes.func,
  onUnpublish: PropTypes.func,
  onDiscardChanges: PropTypes.func,
  children: PropTypes.func,
  flowState: PropTypes.string,
  experience: ExperienceShape,
  id: PropTypes.string,
  schedule: ScheduleType,
};

const mapStateToProps = (state, { id }) => {
  const experience = selectExperience(state, id);
  const { id: userId } = selectUser(state);
  const { roleId } = selectAccountUser(state, userId);
  const contentStatus = selectContentStatus(state, id);

  const { published, updatedAt, publishedAt, name } = experience;

  return {
    name,
    published,
    hasChanges: contentStatus?.publishedAndDirty,
    updatedAt,
    publishedAt,
    canPublish: roleId !== ROLES.EDITOR,
    experience,
    schedule: selectSchedule(state),
    installed: selectIsInstalled(state),
  };
};

const mapDispatchToProps = (dispatch, { id }) => ({
  onPublish: () => dispatch(requestPublishExperience(id)),
  onUnpublish: () => dispatch(requestUnpublishExperience(id)),
  onDiscardChanges: version => dispatch(requestRevertExperience(id, version)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExperiencePublishingManager);
