import { local } from 'next/lib/storage';
import { Search } from './listing/use-controls';

export default function listener(pathname) {
  // Clear pins listing search and filter
  if (!/\/pins/.test(pathname)) {
    local.remove(Search.key);
    local.remove('appcues:pins:filter');
  }
}
