import React, { useCallback, useState } from 'react';
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useHistory, useLocation } from 'react-router';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import toast from 'next/lib/toast';
import { Shape as UserShape } from 'next/entities/user';
import { Plan as PlanShape } from 'components/Subscription/shape';
import {
  EditModeFormSchema,
  ViewModeFormSchema,
} from 'components/Subscription/schema';
import { createSubscription } from 'actions/newBilling';
import useEntitlementsFromURL from 'hooks/useEntitlementsFromURL';
import usePlanPricing from 'hooks/usePlanPricing';
import { selectUser } from 'reducers/user';
import {
  selectAccountMeta,
  selectHasSubscription,
} from 'reducers/account/meta';
import { createPlanId, getPaymentMethod } from 'utils/stripe';
import { formatAddonsForPurchase } from 'utils/billing';
import { asPromised } from 'utils/as-promised';
import usePaymentInfoValues from 'hooks/usePaymentInfoValues';

import BillingAddressForm from './BillingAddressForm';
import PaymentMethodForm from './PaymentMethodForm';
import CheckoutSummary from './CheckoutSummary';
import BillingDetails from './BillingDetails';
import FormSpinner from './FormSpinner';

import { CheckoutLayout, PaymentSectionWrapper } from './styled';

export const CheckoutForm = ({
  accountMeta,
  entitlementsProcessed,
  hasSubscription,
  onCreateSubscription,
  planData,
  user,
}) => {
  const stripe = useStripe();
  const history = useHistory();
  const location = useLocation();
  const elements = useElements();
  const [subscriptionLoading, setSubscriptionLoading] = useState(null);
  const [editingBillingDetails, setEditingBillingDetails] = useState(false);
  const [completedForms, setCompletedForms] = useState({
    paymentMethod: false,
    billing: false,
  });
  const [formSchema, setFormSchema] = useState(EditModeFormSchema);

  const { selectedEntitlements, selectedEntitlementsCount } =
    useEntitlementsFromURL(location);

  const planPrice = usePlanPricing({
    selectedEntitlements,
    entitlementsProcessed,
    hasSubscription,
    plan: planData,
  });

  const { paymentInfo, isLoading, initialValues } = usePaymentInfoValues({
    onLoadPaymentInfo: () => setCompletedForms(s => ({ ...s, billing: true })),
    stripeId: accountMeta.stripeId,
    planPrice,
  });

  if (
    (hasSubscription || paymentInfo?.paymentMethodId) &&
    !editingBillingDetails &&
    formSchema !== ViewModeFormSchema
  ) {
    setFormSchema(ViewModeFormSchema);
  }

  const handleFormSubmit = useCallback(async formValues => {
    setSubscriptionLoading(true);

    const planId = createPlanId(
      planData.price,
      planData.id,
      planData.planInterval
    );

    try {
      let paymentMethod;
      let billingAddress;

      if (Object.values(completedForms).every(Boolean)) {
        const card = elements.getElement(CardNumberElement);

        billingAddress = {
          line1: formValues.line1,
          country: formValues.country.value,
          postalCode: formValues.postalCode,
          city: formValues.city,
        };

        paymentMethod = await getPaymentMethod(
          card,
          formValues,
          stripe,
          user,
          billingAddress
        );
      } else if (paymentInfo?.paymentMethodId) {
        paymentMethod = { id: paymentInfo?.paymentMethodId };
        billingAddress = {
          city: paymentInfo?.billingAddress?.city,
          country: paymentInfo?.billingAddress?.country,
          line1: paymentInfo?.billingAddress?.line1,
          postalCode: paymentInfo?.billingAddress?.postalCode,
        };
      }

      const formattedAddons = formatAddonsForPurchase(
        selectedEntitlements,
        entitlementsProcessed
      );

      /**
       * The paymentMethodId is only mandatory when we fill out the payment details.
       * In other cases, we'll reuse the payment method that Stripe has on its record.
       */
      const newSubscription = await onCreateSubscription({
        addOns: formattedAddons,
        billingAddress,
        email: user.email,
        planId: planId.split('-')[1],
        mau: planData.planLimit,
        stripeId: accountMeta.stripeId,
        stripePlanId: planId,
        userId: user.id,
        ...(paymentMethod ? { paymentMethodId: paymentMethod.id } : {}),
      });

      if (
        newSubscription.latest_invoice.payment_intent.status ===
        'requires_action'
      ) {
        const {
          paymentIntent: confirmedPaymentIntent,
          error: paymentIntentError,
        } = await stripe.confirmCardPayment(
          newSubscription.latest_invoice.payment_intent.client_secret
        );

        if (paymentIntentError) {
          toast.error(
            'Verification failed. Check that your information is entered correctly and try again. If the problem persists, contact us at support@appcues.com.'
          );
          setSubscriptionLoading(false);
          return null;
        }

        if (confirmedPaymentIntent.status === 'succeeded') {
          toast.success('3DS verified successfully.');
        }
      }

      setSubscriptionLoading(false);
      history.push(`/settings/subscription?id=${newSubscription.id}`);
      return newSubscription.id;
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
      setSubscriptionLoading(false);
    }

    return null;
  });

  if (!planPrice) return null;

  return isLoading ? (
    <FormSpinner />
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={handleFormSubmit}
      enableReinitialize
    >
      {({ errors, values, dirty, handleSubmit }) => {
        return (
          <Form
            onSubmit={e => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <CheckoutLayout>
              <PaymentSectionWrapper>
                {(hasSubscription || paymentInfo?.paymentMethodId) &&
                !editingBillingDetails ? (
                  <BillingDetails
                    onEdit={() => setEditingBillingDetails(true)}
                  />
                ) : (
                  <>
                    <BillingAddressForm
                      errors={errors}
                      formValues={values}
                      onCompleteForm={newFormCompleted => {
                        setCompletedForms({
                          ...completedForms,
                          ...newFormCompleted,
                        });
                      }}
                      planPrice={planPrice}
                    />
                    <PaymentMethodForm
                      dirty={dirty}
                      formValues={values}
                      onCompleteForm={newFormCompleted => {
                        setCompletedForms({
                          ...completedForms,
                          ...newFormCompleted,
                        });
                      }}
                    />
                  </>
                )}
              </PaymentSectionWrapper>
              <CheckoutSummary
                completedForms={completedForms}
                entitlementsProcessed={entitlementsProcessed}
                editingBillingDetails={editingBillingDetails}
                formValues={values}
                isSubmitting={subscriptionLoading}
                hasPaymentMethodId={!!paymentInfo?.paymentMethodId}
                planData={planData}
                planPrice={planPrice}
                selectedEntitlements={selectedEntitlements}
                selectedEntitlementsCount={selectedEntitlementsCount}
              />
            </CheckoutLayout>
          </Form>
        );
      }}
    </Formik>
  );
};

CheckoutForm.propTypes = {
  accountMeta: PropTypes.object,
  entitlementsProcessed: PropTypes.object,
  hasSubscription: PropTypes.bool,
  onCreateSubscription: PropTypes.func,
  planData: PlanShape,
  user: UserShape,
};

const mapStateToProps = state => ({
  accountMeta: selectAccountMeta(state),
  hasSubscription: selectHasSubscription(state),
  user: selectUser(state),
});

const mapDispatchToProps = dispatch => ({
  onCreateSubscription: payload =>
    asPromised(dispatch, createSubscription(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);
