import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@studio/legacy-components';
import { LabelWrapper, UpgradeBadge } from './styled';

const UpgradeTag = ({ tooltipContent }) => {
  return (
    <Tooltip
      delay={0}
      placement="top"
      timeout={200}
      persist
      disabled={!tooltipContent}
      label={<LabelWrapper>{tooltipContent}</LabelWrapper>}
      wrapped
    >
      <UpgradeBadge label="Upgrade" icon="magic" />
    </Tooltip>
  );
};

UpgradeTag.propTypes = {
  tooltipContent: PropTypes.node,
};

export default UpgradeTag;
