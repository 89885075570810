import React, { useState } from 'react';
import StepPreviewContainer from 'components/Common/StepThumbnail/StepPreviewContainer';
import StepPreviewFooter from 'components/Common/StepThumbnail/StepPreviewFooter';
import StepPreviewHeader from 'components/Common/StepThumbnail/StepPreviewHeader';
import StepContainer from 'components/Common/StepThumbnail/StepContainer';

export function StepPreviewThumbnail({
  childIndex,
  children,
  stepType,
  totalSteps,
  onHoverComponent,
  className,
}) {
  const [show, setShow] = useState(false);

  return (
    stepType && (
      <StepPreviewContainer
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        className={className}
      >
        <StepPreviewHeader
          childIndex={childIndex}
          stepTypeId={stepType && stepType.id}
          totalSteps={totalSteps}
        />

        <StepContainer>
          {show && onHoverComponent}
          {children}
        </StepContainer>
        <StepPreviewFooter
          stepTypeId={stepType && stepType.id}
          childIndex={childIndex}
        />
      </StepPreviewContainer>
    )
  );
}

export default StepPreviewThumbnail;
