/* global MOBILE_BUILDER_URL */
import React from 'react';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import styled from 'styled-components';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { Button, Form, FormField, Icon, Label, Modal } from '@appcues/sonar';
import { InputClickToCopy } from '@studio/legacy-components';

export const QRCodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px;
`;

export const InputWrapper = styled.div`
  span {
    overflow: hidden;
    text-wrap: nowrap;
    padding: 7px 14px;
  }
`;

function DebuggerModal({ appId, visible, onClose }) {
  const launchDebuggerUrl = `${MOBILE_BUILDER_URL}?type=debugger&appId=${appId}`;

  return (
    <Modal.Root open={visible} onOpenChange={onClose} size="small">
      <Modal.Title>Launch debugger</Modal.Title>
      <Form aria-label="launch debugger form" role="form">
        <FormField>
          <Label>Scan this QR Code:</Label>
          <QRCodeWrapper>
            <QRCodeSVG
              aria-label="launch debugger URL QR Code"
              size={225}
              value={launchDebuggerUrl}
            />
          </QRCodeWrapper>
          <Label>Or open this URL on your device:</Label>
          <InputWrapper>
            <InputClickToCopy text={launchDebuggerUrl} icon />
          </InputWrapper>
        </FormField>
        <Button onClick={onClose} fluid>
          Done
        </Button>
        <Modal.Close aria-label="Close">
          <Icon size="large" icon={faXmark} />
        </Modal.Close>
      </Form>
    </Modal.Root>
  );
}

DebuggerModal.propTypes = {
  appId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DebuggerModal;
