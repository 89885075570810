import { createCollectionReducer } from 'reducers/account/collections';

const TYPE = 'images';

export default createCollectionReducer(TYPE);

const selectAccountImages = state => (state.account || {}).images || {};
export const selectAccountImage = (
  state,
  { stepChildId, versionId = 'latest', size = 'medium', localeId }
) =>
  (selectAccountImages(state) || {})[
    `${stepChildId}+${versionId}+${size}${localeId ? `+${localeId}` : ''}`
  ] || {};
