import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  width: 100%;
  margin-top: 20px;
`;

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  width: 64%;
  align-items: left;
  font-weight: 600;
  line-height: 26px;
  font-size: 16px;
  margin-bottom: -16px;
`;

export const FrameworkOption = styled.div`
  width: 23%;
  height: 95%;
  display: block;
  cursor: pointer;
  justify-content: left;
  align-items: left;
  border: ${props =>
    props.selected ? '0' : `2px solid ${props.theme['$gray-2']}`};
  color: ${props =>
    props.selected ? props.theme['$gray-0'] : props.theme['$gray-5']};
  box-sizing: border-box;
  border-radius: 6px;
  line-height: 26px;
  font-weight: 600;
  white-space: nowrap;
  background-color: ${props =>
    props.selected ? props.theme.$purple : props.theme['$gray-0']};

  i {
    color: ${props => (props.selected ? props.theme['$gray-0'] : 'inherit')};
  }

  &:hover {
    border: ${props =>
      props.selected ? '0' : `2px solid ${props.theme['$gray-5']}`};
    color: ${props =>
      props.selected ? props.theme['$gray-0'] : props.theme['$gray-8']};
    i {
      color: ${props =>
        props.selected ? props.theme['$gray-0'] : props.theme['$gray-7']};
    }
  }
`;

export const FrameworkContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 24px;
`;

export const FrameworkName = styled.div`
  width: 100%;
`;

export const FrameworkIcon = styled(Icon)`
  margin-right: 16px;
  font-size: 32px;
`;

export const ExternalPartnerSection = styled.div`
  margin-top: 24px;
`;
