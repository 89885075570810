import React from 'react';

const TooltipIcon = () => {
  return (
    <svg viewBox="0 0 18 18" width="18" height="18">
      <path d="M7.62087194,11.8575712 L16.9044821,11.8575712 C17.5092082,11.8575712 18,11.36711 18,10.7620955 L18,5.44000343 C18,4.83458138 17.50952,4.34452774 16.9044821,4.34452774 L1.0955179,4.34452774 C0.490791779,4.34452774 0,4.83498891 0,5.44000343 L0,10.7620955 C0,11.3675176 0.490480071,11.8575712 1.0955179,11.8575712 L3.10492727,11.8575712 L5.33052795,14.6316706 L7.62087194,11.8575712 Z" />
    </svg>
  );
};

export default TooltipIcon;
