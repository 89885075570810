import { conditionNames } from '@appcues/libcues';
import {
  FLOW_STATE,
  FLOW_STATUS,
  FLOW_ACTION_TYPES,
  FLOW_ACTION_TYPES_ARCHIVED,
  FLOW_ACTIONS,
  FLOW_ACTIONS_ARCHIVED,
  SORT_TYPES,
  SORT_TYPES_VALUES,
} from 'constants/view';
import { getJourneySteps } from 'utils/steps';
import { sortAscending, sortDescending, buildFlowActionPath } from 'utils';

const UNNAMED_FLOW = 'untitled flow';

export const SORT_OPTIONS = {
  [SORT_TYPES.NAME_AZ]: {
    label: 'Name (A-Z)',
    value: SORT_TYPES.NAME_AZ,
    sortSteps: steps => {
      return steps.sort((a, b) => {
        const aName = a.name ? a.name.toLowerCase() : UNNAMED_FLOW;
        const bName = b.name ? b.name.toLowerCase() : UNNAMED_FLOW;
        return sortAscending(aName, bName);
      });
    },
  },
  [SORT_TYPES.RECENT_UPDATED]: {
    label: 'Recently Updated',
    value: SORT_TYPES.RECENT_UPDATED,
    sortSteps: steps => {
      return steps.sort((a, b) => {
        const aUpdatedAt = a.updatedAt || a.createdAt;
        const bUpdatedAt = b.updatedAt || b.createdAt;
        return sortDescending(aUpdatedAt, bUpdatedAt);
      });
    },
  },
  [SORT_TYPES.RECENT_CREATED]: {
    label: 'Recently Created',
    value: SORT_TYPES.RECENT_CREATED,
    sortSteps: steps => {
      return steps.sort((a, b) => {
        return sortDescending(a.createdAt, b.createdAt);
      });
    },
  },
};

export const getFlowStatusDropdownItems = () => Object.values(FLOW_STATUS);

function getUserMetaData(user) {
  return user && user.meta ? user.meta : {};
}

export const getFlowCreatedByDropdownItems = users =>
  Object.keys(users)
    .filter(userId => {
      const userMeta = getUserMetaData(users[userId]);
      if (!userMeta.fullname && !userMeta.email) {
        return false;
      }

      return true;
    })
    .map(userId => {
      const userMeta = getUserMetaData(users[userId]);

      return {
        label: userMeta.fullname || userMeta.email,
        value: userId,
      };
    })
    .sort((a, b) => {
      const aName = a.label.toLowerCase();
      const bName = b.label.toLowerCase();
      return sortAscending(aName, bName);
    });

function getUserMetaDataFromUsers(userId, users) {
  const user = users[userId];
  if (!user) {
    return {};
  }

  return getUserMetaData(user);
}

const defaultUserProperties = userMeta => {
  return {
    userName: userMeta.fullname || userMeta.email || 'Unknown User',
    avatarUrl: userMeta.avatarUrl,
  };
};

export const getCreatedByUserFromStep = (step, users) => {
  const createdUserMeta = getUserMetaDataFromUsers(step.createdBy, users);
  const createdAtDate = new Date(step.createdAt).toLocaleDateString();
  return {
    ...defaultUserProperties(createdUserMeta),
    createdDate: createdAtDate,
  };
};

export const getUpdatedByUserFromStep = (step, users) => {
  const updatedUserMeta = getUserMetaDataFromUsers(step.updatedBy, users);
  const updatedAtDate = step.updatedAt
    ? new Date(step.updatedAt).toLocaleDateString()
    : null;

  return {
    ...defaultUserProperties(updatedUserMeta),
    updatedDate: updatedAtDate,
  };
};

export const getTagsDropdownItems = tags => {
  const dropdownList = Object.values(tags).reduce((prev, tag) => {
    if (typeof tag === 'object') {
      return [
        ...prev,
        {
          label: tag.name,
          value: tag.id,
        },
      ];
    }
    return prev;
  }, []);

  return dropdownList.sort((a, b) => {
    const aName = a.label && a.label.toLowerCase();
    const bName = b.label && b.label.toLowerCase();
    return sortAscending(aName, bName);
  });
};

export const getStepsForListView = steps => {
  const journeysList = getJourneySteps(steps);
  return [...journeysList];
};

export const getTargetedSegmentFromConditions = (
  conditions = [],
  segments = {}
) => {
  if (conditions.length === 0) return null;

  const segmentClause = conditions.filter(condition => {
    return (
      condition.conditionName === conditionNames.SEGMENTS && !condition.negative
    );
  });

  if (segmentClause.length > 0 && Object.keys(segments).length > 0) {
    const segmentId = segmentClause[0].segment;
    return segments[segmentId];
  }
  return null;
};

export const getStepsWithTargetedSegment = (
  targetedSegment,
  steps,
  conditions
) => {
  if (!targetedSegment) {
    return steps;
  }

  const segmentSteps = steps.reduce((prev, step) => {
    const stepConditions = conditions[step.id] || [];
    const segmentClauses = stepConditions.filter(condition => {
      return (
        condition.conditionName === conditionNames.SEGMENTS &&
        !condition.negative
      );
    });
    const matchingSegment = segmentClauses.find(clause => {
      return clause.segment === targetedSegment;
    });

    if (matchingSegment) {
      return [...prev, step];
    }
    return prev;
  }, []);

  return segmentSteps;
};

export const getGoalFromRule = (rule = {}, goals = {}) => {
  const goalId = rule.goals ? rule.goals[0] : null;

  if (goalId) {
    return goals[goalId];
  }
  return null;
};

export const getAllTimeStatsForStep = stats => {
  if (stats && stats['all-time-unique']) {
    const allTimeUniqueStats = stats['all-time-unique'];
    return {
      reached: allTimeUniqueStats.reached || 0,
      completed: allTimeUniqueStats.completed || 0,
    };
  }
  return null;
};

export const getFlowActionsDropdownItems = (
  stepType,
  stepId,
  stepState,
  stepLocked
) => {
  if (stepState === FLOW_STATE.ARCHIVED) {
    return Object.values(FLOW_ACTION_TYPES_ARCHIVED).reduce(
      (prev, archivedAction) => {
        return [
          ...prev,
          {
            label: FLOW_ACTIONS_ARCHIVED[archivedAction].label,
            icon: FLOW_ACTIONS_ARCHIVED[archivedAction].icon,
            value: {
              ...FLOW_ACTIONS_ARCHIVED[archivedAction],
              stepType,
              stepId,
            },
          },
        ];
      },
      []
    );
  }
  return Object.values(FLOW_ACTION_TYPES).reduce((prev, flowAction) => {
    if (FLOW_ACTIONS[flowAction].excludeOnFlows) return prev;

    if (stepLocked && FLOW_ACTIONS[flowAction].excludeOnLockedFlows)
      return prev;

    return [
      ...prev,
      {
        label: FLOW_ACTIONS[flowAction].label,
        icon: FLOW_ACTIONS[flowAction].icon,
        href: FLOW_ACTIONS[flowAction].hasHref
          ? buildFlowActionPath(stepType, stepId, flowAction)
          : undefined,
        value: {
          ...FLOW_ACTIONS[flowAction],
          stepType,
          stepId,
        },
      },
    ];
  }, []);
};

export const getStepsBySearchString = (searchString, steps = []) => {
  if (!searchString) return steps;

  return steps.filter(step => {
    if (step && !step.name) return false;
    const stepName = step && step.name.toLowerCase();
    const transformedSearchString = searchString.toLowerCase().trim();
    return stepName.includes(transformedSearchString);
  });
};

export const getSortOption = sortOption => {
  return SORT_TYPES_VALUES.includes(sortOption)
    ? sortOption
    : SORT_TYPES.RECENT_UPDATED;
};

export const getSortOptionsDropdownItems = () => {
  return Object.values(SORT_OPTIONS).map(sortOption => {
    return {
      label: sortOption.label,
      value: sortOption.value,
    };
  });
};

export const getSortedSteps = (steps, sortOption) => {
  if (!sortOption) return steps;

  const option = getSortOption(sortOption);
  return SORT_OPTIONS[option].sortSteps(steps);
};

/**
 * Given a step-like object and a list of tag ids,
 * returns true if the step uses any of the tags
 *
 * @param {Object} step
 * @param {Array} tagsFiltersList
 * @returns {*}
 */

export const doesStepHaveTagFilters = ({ tagIds = [] }, tagsFiltersList) => {
  return (
    Array.isArray(tagIds) &&
    tagIds.some(tagId => tagsFiltersList.includes(tagId))
  );
};

export const getFilteredSteps = (steps, conditions, filters) => {
  const { statusFilter, createdByFilter, tagsFilter, segmentFilter } = filters;

  const statusFilteredSteps = steps.filter(step => {
    switch (statusFilter) {
      case 'PUBLISHED':
        return step.published;
      case 'ARCHIVED':
      case 'DRAFT':
        return step.state === statusFilter && !step.published;
      default:
        return step.state !== 'ARCHIVED';
    }
  });
  const segmentFilteredSteps = getStepsWithTargetedSegment(
    segmentFilter,
    statusFilteredSteps,
    conditions
  );

  const createdByFilteredSteps = segmentFilteredSteps.filter(filteredStep => {
    return createdByFilter
      ? filteredStep.createdBy === createdByFilter
      : filteredStep;
  });

  const tagsList = tagsFilter || [];
  const tagsFilteredSteps = createdByFilteredSteps.filter(tagStep => {
    const stepHasTag = doesStepHaveTagFilters(tagStep, tagsList);
    const stepHasTagOrCategory =
      stepHasTag || tagsList.includes(tagStep.category);
    return tagsList && tagsList.length > 0 ? stepHasTagOrCategory : tagStep;
  });
  return tagsFilteredSteps;
};
