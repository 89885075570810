import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';
import { selectAssociationsForTag } from 'next/lib/selectors';
import useToggle from 'next/hooks/use-toggle';
import Editing from './Editing';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { Edit, Editable, Name, Wrapper } from './styled';

function BaseTag({
  associations = [],
  error,
  id,
  name,
  editing,
  onDelete,
  onToggle,
  onUpdate,
  readOnly = false,
  title,
  ...unhandledProps
}) {
  const [deleting, toggle] = useToggle();

  if (readOnly) {
    return (
      <Wrapper {...unhandledProps}>
        <Name title={title || name}>{name}</Name>
      </Wrapper>
    );
  }

  // If there are no associations for the given tag, delete the tag without
  // confirmation. Otherwise, toggle the confirmation prompt before deleting.
  const handleDelete = associations.length === 0 ? onDelete : toggle;

  if (editing) {
    return (
      <Wrapper {...unhandledProps}>
        <Editing
          error={error}
          onClose={onToggle}
          onConfirm={onUpdate}
          onDelete={handleDelete}
          value={name}
        />

        <DeleteConfirmationModal
          associations={associations}
          name={name}
          onClose={toggle}
          onDelete={onDelete}
          visible={deleting}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper {...unhandledProps}>
      <Editable>
        <Name title={title || name}>{name}</Name>
        <Edit aria-label="Edit" onClick={onToggle}>
          <Icon icon="pen" />
        </Edit>
      </Editable>
    </Wrapper>
  );
}

BaseTag.propTypes = {
  associations: PropTypes.arrayOf(PropTypes.string),
  editing: PropTypes.bool,
  error: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onDelete: PropTypes.func,
  onToggle: PropTypes.func,
  onUpdate: PropTypes.func,
  readOnly: PropTypes.bool,
  title: PropTypes.string,
};

export const Tag = styled(BaseTag)``;

const mapStateToProps = (state, { id }) => ({
  associations: selectAssociationsForTag(state, id),
});

export default connect(mapStateToProps)(Tag);
