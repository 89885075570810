import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { faPresentationScreen } from '@fortawesome/pro-solid-svg-icons/faPresentationScreen';
import { faArrowsTurnToDots } from '@fortawesome/pro-solid-svg-icons/faArrowsTurnToDots';
import { faBooks } from '@fortawesome/pro-solid-svg-icons/faBooks';
import { Row } from '@appcues/sonar';
import { isTrialAccount } from '@studio/navigation';
import { HelpBarButton } from 'next/components/HelpBarButton';
import { selectTrialStatus as selectTrialStatusNext } from 'next/entities/trial-status';
import { selectAccount } from 'next/entities/account';
import { selectTrialStatus } from 'entities/trial-status';
import { selectAccountMeta } from 'reducers/account/meta';

export const ACTIONS_MAP = {
  flows: {
    WORKFLOW_ID: '562474b8-c4c0-4da5-816c-3d5fdb348db0',
    DOC: 'https://docs.appcues.com/en_US/user-experiences-web-experiences/what-is-a-flow',
  },
  pins: {
    WORKFLOW_ID: 'c057b46a-6476-43de-8573-4e7ab9e10c90',
    DOC: 'https://docs.appcues.com/user-experiences-building/pins',
  },
  checklists: {
    WORKFLOW_ID: 'c64ec798-80f8-461a-bd4c-0dfc4ec02a9c',
    DOC: 'https://docs.appcues.com/user-experiences-building/checklists-overview#still-curious-how-our-checklist-feature-works-12',
  },
  nps: {
    WORKFLOW_ID: '6823e7b6-2f70-43bb-8788-39ffa52b2090',
    DOC: 'https://docs.appcues.com/user-experiences-building/nps-overview?from_search=120995897',
  },
  banners: {
    WORKFLOW_ID: 'e04ebd96-e8e0-4318-af0a-fc0327db6074',
    DOC: 'https://docs.appcues.com/user-experiences-building/banners?from_search=123109651',
  },
};

const Wrapper = styled(Row)`
  margin-bottom: var(--spacing-large);
`;

export const HelpBar = ({ isTrial, section }) => {
  if (!isTrial || !ACTIONS_MAP[section]) {
    return null;
  }

  return (
    <Wrapper spacing="medium" id="help-bar">
      <HelpBarButton
        icon={faPresentationScreen}
        title="Expert Help"
        subtitle="Learn from a product expert"
        onClick={() => {
          window.open(
            'https://www.appcues.com/demo-request/schedule-demo',
            '_blank'
          );
        }}
        id="help-bar-schedule-demo"
      />

      <HelpBarButton
        icon={faArrowsTurnToDots}
        title="Feature Tour"
        subtitle="Take a guided tour"
        onClick={() => {
          window.Appcues?.show(ACTIONS_MAP[section].WORKFLOW_ID);
        }}
        id="help-bar-feature-tour"
      />

      <HelpBarButton
        icon={faBooks}
        title="Learn More"
        subtitle="Read the help doc"
        onClick={() => {
          window.open(ACTIONS_MAP[section].DOC, '_blank');
        }}
        id="help-bar-help-doc"
      />
    </Wrapper>
  );
};

HelpBar.propTypes = {
  isTrial: PropTypes.bool,
  section: PropTypes.oneOf([
    'flows',
    'checklists',
    'pins',
    'nps',
    'banners',
    'launchpads',
  ]).isRequired,
};

const mapStateToProps = state => {
  const trialStatusNext = selectTrialStatusNext(state);
  const trialStatus = trialStatusNext || selectTrialStatus(state);
  const account = trialStatusNext
    ? selectAccount(state)
    : selectAccountMeta(state);

  return {
    isTrial: isTrialAccount(account, trialStatus),
  };
};

export default connect(mapStateToProps)(HelpBar);
