import { call, getContext, put, takeEvery, select } from 'redux-saga/effects';
import { IMAGE_SERVICE_LAMBDA, selectFeature } from 'next/entities/features';
import {
  insert,
  READ_ONE_EXPERIENCE,
  READ_ONE_STEP_CHILD,
  reject,
} from './actions';
import { buildScreenshotUrlFor, getImageHashKeys } from './lib';

export function* fetchScreenshot({
  payload: { force, locale, size, step, version },
}) {
  try {
    const api = yield getContext('api');
    const isLambdaServiceEnabled = yield select(
      selectFeature,
      IMAGE_SERVICE_LAMBDA
    );

    let image;

    if (isLambdaServiceEnabled) {
      image = yield call(api.getLambdaImage, {
        flowId: step.id,
        flowStepId: step.stepGroupId,
        flowStepChildId: step.stepChildId,
        localeId: locale,
      });
    } else {
      // Build screenshot data and generate URL builder
      const screenshotAttrs = { locale, size, step, version };
      const url = buildScreenshotUrlFor(screenshotAttrs);

      // If image generation is forced, purge any existing cache
      if (force) {
        yield call(api.purgeImage, url('purge'));
      }

      // Presign the generated image and retrieve the image path
      const path = yield call(api.presignImage, url('presign'));

      image = yield call(api.getPresignedImage, path);
    }

    yield put(
      insert({
        id: getImageHashKeys({ step, locale, size, version }),
        url: URL.createObjectURL(image),
      })
    );
  } catch (error) {
    yield put(reject(error));
  }
}

export function* fetchExperienceScreenshot({
  payload: { locale, size, step, version, state },
}) {
  try {
    const { id, stepId } = step;
    const api = yield getContext('api');
    const isLambdaServiceEnabled = yield select(
      selectFeature,
      IMAGE_SERVICE_LAMBDA
    );

    const image = yield isLambdaServiceEnabled
      ? call(api.getLambdaExperienceImage, id, stepId)
      : call(api.getExperienceImage, id, stepId, state, version);

    yield put(
      insert({
        id: getImageHashKeys({ step, locale, size, version, state }),
        url: URL.createObjectURL(image),
      })
    );
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  yield takeEvery(READ_ONE_STEP_CHILD, fetchScreenshot);
  yield takeEvery(READ_ONE_EXPERIENCE, fetchExperienceScreenshot);
}
