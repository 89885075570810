import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Flex, H1, H4, P, CCard } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';

const SpecialIcon = styled(Icon)`
  color: rgba(255, 255, 255, 0.7);
  font-size: 36px;
  margin-left: auto;
  margin-right: 11px;
`;

export default function SpecialCard({
  name,
  description,
  value,
  iconClassName,
  isLoading = true,
  ...cardProps
}) {
  return (
    <CCard
      width="100%"
      paddingX={24}
      paddingTop={24}
      paddingBottom={21}
      minHeight={140}
      textDecoration="none"
      is={cardProps.to ? Link : undefined}
      hoverElevation="$box-shadow-3"
      {...cardProps}
    >
      <H4
        lineHeight="24px"
        fontSize={13}
        letterSpacing="0.08em"
        light
        type="secondary"
      >
        {name}
      </H4>
      <Flex marginTop={3}>
        <H1 light lineHeight="36px">
          {isLoading ? '…' : value}
        </H1>
        {iconClassName && <SpecialIcon icon={iconClassName} />}
      </Flex>
      <P
        light
        type="secondary"
        fontWeight={600}
        lineHeight="23px"
        fontSize={14}
        marginTop={9}
        opacity={isLoading ? 0 : 1}
      >
        {description}
      </P>
    </CCard>
  );
}
SpecialCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.node,
  iconClassName: PropTypes.string,
  isLoading: PropTypes.bool,
};
