import React from 'react';
import QueryResults from 'components/Common/QueryResults';

const ONE_DAY_MS = 1000 * 60 * 60 * 24;
const THIRTY_DAYS_MS = ONE_DAY_MS * 30;

const NpsQueryResults = ({
  npsId,
  startTime: unDefaultedStartTime,
  endTime,
  segmentId,
  children,
  page,
}) => {
  if (!npsId) {
    return children(null);
  }

  // 30 days is the api's default for `start_time` if not supplied
  // defaulting in this component in part because it's still used by legacy
  // components which don't vary the time frame
  const startTime = unDefaultedStartTime || endTime - THIRTY_DAYS_MS;

  const queryWindow = Math.round((endTime - startTime) / ONE_DAY_MS) || 1;

  const computedScoreConditions = [
    ['flow_id', '==', npsId],
    ['window', '==', queryWindow],
  ];

  if (segmentId) {
    computedScoreConditions.push(['user_segment_id', '==', segmentId]);
  }

  // in this query we use the endTime as both start and end time because of
  // what they mean in the context of an `nps_computed_score` query.
  // start_time & end_time are the days you want scores for (e.g. 30 days, 30
  // computed scores). `window` is how we specify how far back we want
  // the score calculation to be based on
  return (
    <QueryResults
      query={{
        metrics: [
          'nps_computed_score',
          'nps_respondents',
          'nps_promoters',
          'nps_detractors',
          'nps_neutrals',
        ],
        dimensions: ['day'],
        conditions: computedScoreConditions,
        start_time: endTime,
        end_time: endTime,
        meta: {
          tags: {
            feature: 'NPS',
            page,
            component: 'NpsQueryResults',
            description: 'Computed NPS score and respondents',
          },
        },
      }}
    >
      {computedScoreResults => {
        const metricsConditions = [['flow_id', '==', npsId]];
        if (segmentId) {
          metricsConditions.push(['user_segment_id', '==', segmentId]);
        }
        return (
          <QueryResults
            query={{
              metrics: ['nps_feedbacks_submitted', 'nps_survey_started'],
              conditions: metricsConditions,
              start_time: startTime,
              end_time: endTime,
              meta: {
                tags: {
                  feature: 'NPS',
                  page,
                  component: 'NpsQueryResults',
                  description: 'Promoter/detractor/neutral breakdown',
                },
              },
            }}
          >
            {metricsResults => {
              if (!computedScoreResults || !metricsResults) {
                return children(null);
              }

              const {
                nps_computed_score: npsScore = 0,
                nps_respondents: responseCount = 0,
                nps_promoters: promoters = 0,
                nps_detractors: detractors = 0,
                nps_neutrals: neutrals = 0,
              } = computedScoreResults[0] || {};

              const {
                nps_feedbacks_submitted: qualitativeCount = 0,
                nps_survey_started: shownCount = 0,
              } = metricsResults[0] || {};

              return children({
                npsScore,
                responseCount,
                promoters,
                detractors,
                neutrals,
                qualitativeCount,
                shownCount,
              });
            }}
          </QueryResults>
        );
      }}
    </QueryResults>
  );
};

export default NpsQueryResults;
