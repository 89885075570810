import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Column, CPage, CButton, H1, H3, H4 } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';
import { DocumentTitle } from 'next/hooks/use-title';
import { navigate } from 'actions/routing';
import { callApi as fetchSegmentMembership } from 'actions/account/segmentMemberships';
import {
  selectAccountSegments,
  selectAccountSegmentsSynced,
} from 'reducers/account/segments';
import {
  selectFlows,
  selectStepGroups,
  selectAreFlowsSynced,
} from 'reducers/account/flows';
import { selectUserRole } from 'reducers/account/users';
import { selectUserId } from 'reducers/user';
import {
  selectSegmentMembership,
  selectSegmentSampleUserIds,
} from 'reducers/account/segmentMemberships';
import { selectAccountChecklists } from 'entities/checklists';
import { ACCOUNT_PERMISSIONS } from 'constants/accountManagement/permissions';
import { shouldUseEstimateData as getShouldUseEstimateData } from 'utils/segments';
import CreatedUpdatedHeader from 'components/Common/CreatedUpdatedHeader';
import UserSampleTable from 'components/Common/UserSampleTable';
import SegmentWarningNotice from 'components/nps/dashboard/SegmentWarningNotice';
import ExportModalManager from 'components/Audience/SegmentList/ExportModalManager';
import { selectAccountFeature } from 'reducers/account/features';
import { SEGMENT_EXPORT } from 'constants/features';
import ConditionsEstimatePanel from './ConditionsEstimatePanel';
import FlowsUsingSegment from './FlowsUsingSegment';
import UserCountPanel from './UserCountPanel';
import DescriptionPanel from './DescriptionPanel';
import ClausePanel from './ClausePanel';

const FlowListContainer = styled.div`
  width: 100%;
  > div {
    margin-bottom: 16px;
  }
`;

const WarningWrapper = styled.div`
  align-self: stretch;
`;

// NOTE: Sigh... Remove the top margin (why would you space the top and not the
//       bottom...) from the immediate CPanel sibling of the warning. And of
//       course the actual CPanel can't be targeted so use the general selector
//       for now...
const PageContainer = styled(CPage.Container)`
  ${WarningWrapper} + * {
    margin-top: 0;
  }
`;

// This is a temporary hack to make old page headers look like new one
const TitleBar = styled(CPage.TitleBar)`
  position: sticky;
  top: 0;
  z-index: 1;
`;

export class SegmentsView extends Component {
  state = {
    showSegmentDataWarning: false,
  };

  flowsInSegmentRef = React.createRef();

  componentDidMount() {
    const { onLoad, location } = this.props;
    onLoad();

    if (location && location.state && location.state.segmentAlert)
      this.setState({ showSegmentDataWarning: true });

    if (location && location.hash === '#flows-using-segment') {
      this.navigateToFlowsList();
    }
  }

  navigateToFlowsList = () => {
    if (this.flowsInSegmentRef) {
      setTimeout(() => {
        this.flowsInSegmentRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }, 200);
    }
  };

  switchToEdit = () => {
    const { segmentId, onNavigate } = this.props;
    onNavigate(`/segments/${segmentId}/edit`);
  };

  render() {
    const {
      segmentId,
      segments,
      steps,
      segmentMembership,
      segmentUserSample,
      role,
      loaded,
      checklists,
      hasSegmentExport,
    } = this.props;
    const { showSegmentDataWarning } = this.state;
    const segment = (segments || {})[segmentId];
    const description =
      typeof segment !== 'undefined' ? segment.description : '';
    const meta = typeof segment !== 'undefined' ? segment.meta : {};
    const isLoaded = loaded && segment && segmentId;

    let shouldUseEstimateData = false;
    if (isLoaded && segment) {
      shouldUseEstimateData = getShouldUseEstimateData(segment);
    }

    const canEditSegments = role !== ACCOUNT_PERMISSIONS.EDITOR;

    if (isLoaded) {
      return (
        <CPage isLoading={!segment.conditions}>
          <DocumentTitle title={`${segment.name} | Segments | Appcues`} />
          <TitleBar>
            <CPage.TitleBar.BreadcrumbLink href="/segments">
              Segments
            </CPage.TitleBar.BreadcrumbLink>
            <H1>{segment.name}</H1>
            <CPage.TitleBar.Actions>
              {hasSegmentExport && <ExportModalManager id={segmentId} />}
              {canEditSegments && (
                <CButton type="primary" onClick={this.switchToEdit}>
                  <Icon icon="edit" /> Edit
                </CButton>
              )}
            </CPage.TitleBar.Actions>
            <CreatedUpdatedHeader
              createdByUserId={segment.createdBy}
              createdAt={segment.createdAt}
              updatedByUserId={segment.updatedBy}
              updatedAt={segment.updatedAt}
            />
          </TitleBar>

          <PageContainer>
            <Column>
              {showSegmentDataWarning && (
                <WarningWrapper>
                  <SegmentWarningNotice start={0} segmentId={segmentId} />
                </WarningWrapper>
              )}

              <DescriptionPanel
                segmentId={segmentId}
                description={description}
                meta={meta}
              />
              <ClausePanel
                conditions={segment.conditions}
                segments={segments}
                steps={steps}
                checklists={checklists}
              />
              {segment && shouldUseEstimateData ? (
                <ConditionsEstimatePanel conditions={segment.conditions} />
              ) : (
                <UserCountPanel
                  userCount={segmentMembership.count}
                  activeUsers={segmentMembership.basis}
                  percentOfAllUsers={
                    segmentMembership.count / segmentMembership.basis
                  }
                />
              )}
              {segment && !shouldUseEstimateData && (
                <UserSampleTable
                  title={<H4 type="tertiary">25 most recent users</H4>}
                  userSample={segmentUserSample}
                />
              )}
              <H3 id="flows-using-segment" marginTop={48}>
                <span ref={this.flowsInSegmentRef}>
                  Flows using this segment
                </span>
              </H3>
              <FlowListContainer>
                <FlowsUsingSegment
                  segmentId={segmentId}
                  segmentName={segment.name}
                />
              </FlowListContainer>
            </Column>
          </PageContainer>
        </CPage>
      );
    }
    return <CPage title="" isLoading />;
  }
}

SegmentsView.propTypes = {
  segmentId: PropTypes.string,
  segments: PropTypes.object,
  segmentMembership: PropTypes.shape({
    count: PropTypes.number,
    basis: PropTypes.number,
  }),
  segmentUserSample: PropTypes.shape({
    meta: PropTypes.shape({
      fetching: PropTypes.bool,
      synced: PropTypes.bool,
      errored: PropTypes.bool,
    }),
    data: PropTypes.arrayOf(
      PropTypes.shape({
        user_id: PropTypes.string,
        last_seen_timestamp: PropTypes.string,
      })
    ),
  }),
  loaded: PropTypes.bool,
  onNavigate: PropTypes.func,
  onLoad: PropTypes.func,
  hasSegmentExport: PropTypes.bool,
};

function mapStateToProps(state, routeProps) {
  const { segmentId } = routeProps.match.params;

  const stepsLoaded = selectAreFlowsSynced(state);
  return {
    segmentId,
    segments: selectAccountSegments(state),
    role: selectUserRole(state, selectUserId(state)),
    steps: { ...selectStepGroups(state), ...selectFlows(state) },
    segmentMembership: selectSegmentMembership(state, { segmentId }),
    segmentUserSample: selectSegmentSampleUserIds(state, { segmentId }),
    checklists: selectAccountChecklists(state),
    loaded: selectAccountSegmentsSynced(state) && stepsLoaded,
    hasSegmentExport: selectAccountFeature(state, SEGMENT_EXPORT),
  };
}

function mapDispatchToProps(
  dispatch,
  {
    match: {
      params: { segmentId },
    },
  }
) {
  return {
    onLoad: () => fetchSegmentMembership({ id: segmentId }),
    onNavigate: path => dispatch(navigate(path)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SegmentsView);
