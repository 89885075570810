import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Text, H2, H4, CPanel, Row } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';
import CoolTip from 'components/Common/CoolTip';
import PercentOfUsersDisplay from 'components/Common/PercentOfUsersDisplay';
import { numberWithAbbrevMillions } from 'utils/textFormatting';

const StyledCoolTip = styled(CoolTip)`
  margin-left: 8px;
  &:hover {
    color: #475872;
  }
  .tooltip-pos-top {
    right: calc(-210px + 25%);
  }
`;

export default function UserCountPanel({
  userCount,
  activeUsers,
  percentOfAllUsers,
}) {
  const hasDataToShow = userCount && activeUsers && percentOfAllUsers;

  const metric =
    !userCount || !activeUsers || !percentOfAllUsers
      ? 'No data yet'
      : numberWithAbbrevMillions(userCount);

  return (
    <CPanel>
      <Row>
        <H4 type="tertiary">
          User Count
          <StyledCoolTip
            tip="Every time a user visits your app, we check if they meet this segment's criteria. If they do, we add them to this count"
            icon={<Icon icon="question-circle" />}
            width="422px"
          />
        </H4>
      </Row>
      <H2 marginTop={6}>{metric}</H2>
      <Text type="tertiary" marginTop={5}>
        {hasDataToShow && (
          <PercentOfUsersDisplay
            userPercentage={percentOfAllUsers}
            totalUsers={activeUsers}
            loaded
          />
        )}
        {!hasDataToShow && 'waiting for users'}
      </Text>
    </CPanel>
  );
}

UserCountPanel.propTypes = {
  userCount: PropTypes.number,
  activeUsers: PropTypes.number,
  percentOfAllUsers: PropTypes.number,
};
