import { STEP_TYPES } from 'constants/stepTypes';
import { getFilteredSteps } from 'helpers/view';
import { sortAscending } from 'utils/';

export function getTagsForStep(step, tags) {
  const stepTagIds = new Set();

  const { tagIds: stepTagsFromFirebase = [], tags: legacyStyleTags = {} } =
    step;

  [
    ...stepTagsFromFirebase,
    ...Object.keys(legacyStyleTags).filter(tagId => !!legacyStyleTags[tagId]),
  ].forEach(stepTagId => {
    if (tags[stepTagId]) {
      stepTagIds.add(stepTagId);
    }
  });

  // NOTE: Coercing Set to array via spreading currently not supported due to
  //       our babel configurations using core-js for IE support.
  // eslint-disable-next-line unicorn/prefer-spread
  return Array.from(stepTagIds).map(tagId => tags[tagId]);
}

export function getStepsWithTag(steps, tagId) {
  return getFilteredSteps(steps, [], {
    tagsFilter: [tagId],
  });
}

export function getFilteredTagsSelectionForStep(
  stepTagsList = [],
  tagsDropdownItems = []
) {
  const stepTagIds = new Set(
    stepTagsList.map(stepTag => {
      return stepTag.id;
    })
  );

  return tagsDropdownItems
    .filter(tagsDropdownItem => {
      return tagsDropdownItem && !stepTagIds.has(tagsDropdownItem.value);
    })
    .sort((a, b) => {
      const aName = a.label && a.label.toLowerCase();
      const bName = b.label && b.label.toLowerCase();
      return sortAscending(aName, bName);
    });
}

export function getLastPublishedTimestamp(step) {
  return step.updatedAt || step.createdAt;
}

export function getStepType(step) {
  if (!step) {
    return null;
  }

  if (step.type) {
    return step.type;
  }

  if (step.stepType) {
    return step.stepType;
  }

  return null;
}

export function getContentTypeFromStep(step) {
  const stepType = step.type || step.stepType;
  return STEP_TYPES[stepType] && STEP_TYPES[stepType].contentType;
}
