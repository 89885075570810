import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ThemesIndex from 'components/Themes/ThemesIndex';
import ThemeEdit from 'components/Themes/ThemeEdit';

const Themes = () => {
  return (
    <Switch>
      <Route path="/themes/:themeId/edit" component={ThemeEdit} />
      <Route path="/themes" component={ThemesIndex} />
    </Switch>
  );
};

export default Themes;
