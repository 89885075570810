import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import LocalizationPrompt from 'components/StepSettings/Localization/LocalizationPrompt';
import LocalizationPanel from 'components/StepSettings/Localization/LocalizationPanel';

import { selectLocales } from 'reducers/account/locales';
import { fetchTranslations } from 'actions/account/translations';
import { FLOW_LOCALIZATION, LOCALIZATION_RELEASED } from 'constants/features';

import FeatureGate from 'components/Common/FeatureGate/FeatureGate';

import EmptyState from './EmptyState';

export const Localization = ({ onLoad, flowId, locales }) => {
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  // If locales exist, just short circuit and render the table without all the logic checking
  if (Object.keys(locales).length > 0) {
    return <LocalizationPanel locales={locales} flowId={flowId} />;
  }

  return (
    <FeatureGate feature={FLOW_LOCALIZATION}>
      {enabled =>
        enabled ? (
          <EmptyState />
        ) : (
          <FeatureGate feature={LOCALIZATION_RELEASED}>
            {released => released && <LocalizationPrompt />}
          </FeatureGate>
        )
      }
    </FeatureGate>
  );
};

Localization.propTypes = {
  onLoad: PropTypes.func,
  flowId: PropTypes.string.isRequired,
  locales: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      conditions: PropTypes.object,
    })
  ),
};

Localization.defaultProps = {
  locales: [],
};

const mapStateToProps = state => ({
  locales: selectLocales(state),
});

const mapDispatchToProps = (dispatch, { flowId }) => ({
  onLoad: () => dispatch(fetchTranslations({ flowId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Localization);
