import React from 'react';
import { CSkeleton } from '@appcues/component-library';

export const withSkeleton = (WrappedComponent, skeletonProps) => {
  const Wrapped = props => {
    const { draftTheme } = props;

    return draftTheme ? (
      <WrappedComponent {...props} />
    ) : (
      <CSkeleton {...skeletonProps} />
    );
  };

  return Wrapped;
};
