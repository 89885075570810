import * as actionTypes from 'constants/actionTypes';

const initialState = null;

export default function currentModal(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SHOW_MODAL:
      return action.payload;
    case actionTypes.HIDE_MODAL:
      return null;
    case actionTypes.CLEAR_ACCOUNT_DATA:
      return initialState;
    default:
      return state;
  }
}

export const selectUiCurrentModalState = state => state.currentModal;
export const selectUiCurrentModalKey = state =>
  state.currentModal && state.currentModal.key;
