import React, { useState, useMemo, useCallback } from 'react';
import {
  H1,
  PageActions,
  PageHeader,
  PageTitle,
  PageBody,
} from '@studio/legacy-components';
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import { Button, toast } from '@appcues/sonar';
import { selectUser } from 'next/entities/user';
import { history } from 'next/lib/history';
import usePaymentInfoValues from 'hooks/usePaymentInfoValues';
import BillingAddressForm from 'components/SubscriptionCheckout/BillingAddressForm';
import PaymentMethodForm from 'components/SubscriptionCheckout/PaymentMethodForm';
import { EditModeFormSchema } from 'components/Subscription/schema';
import FormSpinner from 'components/SubscriptionCheckout/FormSpinner';
import { selectCurrentPlanData } from 'selectors/billing';
import { asPromised } from 'utils/as-promised';
import { createSetupIntent, updatePaymentInfo } from 'actions/newBilling';

import { getPaymentMethod } from 'utils/stripe';
import { selectAccountMeta } from 'reducers/account/meta';
import { BackArrow } from './styled';

const handleBackAction = () => {
  history.push('/settings/subscription');
};

export function BillingUpdateForm({
  user,
  accountMeta,
  planData,
  onCreateSetupIntent,
  onUpdateCreditCard,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [subscriptionLoading, setSubscriptionLoading] = useState(null);
  const [completedForms, setCompletedForms] = useState({
    paymentMethod: false,
    billing: false,
  });

  const { paymentInfo, isLoading, initialValues } = usePaymentInfoValues({
    stripeId: accountMeta.stripeId,
    planPrice: planData.price,
  });

  const isButtonDisabled = useMemo(() => {
    return !Object.values(completedForms).every(Boolean);
  }, [completedForms]);

  const handleFormSubmit = useCallback(async formValues => {
    setSubscriptionLoading(true);

    try {
      let paymentMethod;
      let billingAddress;

      if (Object.values(completedForms).every(Boolean)) {
        const card = elements.getElement(CardNumberElement);

        billingAddress = {
          line1: formValues.line1,
          country: formValues.country.value,
          postalCode: formValues.postalCode,
          city: formValues.city,
        };

        paymentMethod = await getPaymentMethod(
          card,
          formValues,
          stripe,
          user,
          billingAddress
        );

        const intent = await onCreateSetupIntent({
          stripeId: accountMeta.stripeId,
          paymentMethodId: paymentMethod.id,
        });

        const { setupIntent, error } = await stripe.confirmCardSetup(
          intent.clientSecret
        );

        if (setupIntent?.status === 'succeeded') {
          await onUpdateCreditCard({
            stripeId: accountMeta.stripeId,
            paymentMethodId: paymentMethod.id,
            billingAddress,
          });

          history.push('/settings/subscription');
          toast.success('Credit card updated successfully');
        } else {
          toast.danger(
            error?.message ||
              'Verification failed. Check that your information is entered correctly and try again. If the problem persists, contact us at support@appcues.com.'
          );
        }
      }

      setSubscriptionLoading(false);
      return null;
    } catch (error) {
      toast.danger(error?.message || 'Something went wrong');
      setSubscriptionLoading(false);
    }

    return null;
  });

  return isLoading ? (
    <FormSpinner />
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={EditModeFormSchema}
      onSubmit={handleFormSubmit}
      enableReinitialize
    >
      {({ errors, values, dirty, handleSubmit }) => {
        return (
          <Form
            onSubmit={e => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <PageHeader>
              <PageTitle>
                <BackArrow icon="chevron-left" onClick={handleBackAction} />
                <H1>
                  {paymentInfo?.paymentMethodId ? 'Edit' : 'Save'} Billing
                  Details
                </H1>
              </PageTitle>

              <PageActions>
                <Button
                  type="button"
                  onClick={handleBackAction}
                  variant="secondary"
                >
                  Cancel
                </Button>
                <Button
                  disabled={isButtonDisabled}
                  loading={subscriptionLoading}
                  type="submit"
                >
                  Save
                </Button>
              </PageActions>
            </PageHeader>
            <PageBody>
              <BillingAddressForm
                errors={errors}
                formValues={values}
                onCompleteForm={newFormCompleted => {
                  setCompletedForms({
                    ...completedForms,
                    ...newFormCompleted,
                  });
                }}
                planPrice={planData.price}
              />
              <PaymentMethodForm
                dirty={dirty}
                formValues={values}
                onCompleteForm={newFormCompleted => {
                  setCompletedForms({
                    ...completedForms,
                    ...newFormCompleted,
                  });
                }}
              />
            </PageBody>
          </Form>
        );
      }}
    </Formik>
  );
}

const mapStateToProps = state => ({
  user: selectUser(state),
  planData: selectCurrentPlanData(state),
  accountMeta: selectAccountMeta(state),
});

const mapDispatchToProps = dispatch => ({
  onCreateSetupIntent: payload =>
    asPromised(dispatch, createSetupIntent(payload)),
  onUpdateCreditCard: payload =>
    asPromised(dispatch, updatePaymentInfo(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingUpdateForm);
