import { selectAccountUsers } from 'reducers/account/users';
import { selectAccountSegments } from 'reducers/account/segments';
import { selectUserSegments } from 'entities/user-segments';

export const selectUserSegmentsDetails = (state, userId) => {
  const accountSegments = selectAccountSegments(state);
  const userSegments = selectUserSegments(state, userId);
  const users = selectAccountUsers(state);

  return (
    userSegments &&
    Object.entries(userSegments)
      .filter(
        ([, isUserMember]) => isUserMember && typeof isUserMember === 'boolean'
      )
      .map(([id]) => {
        const segment = accountSegments[id];
        const creatorId = accountSegments[id]?.createdBy;
        const userName =
          users[creatorId]?.meta?.fullname || users[creatorId]?.meta?.email;
        return { ...segment, userName };
      })
  );
};
