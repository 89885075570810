import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  H2,
  PageSubheader,
  PageReturn,
  PageActions,
  Link,
} from '@studio/legacy-components';
import { Text, Tooltip } from '@appcues/sonar';
import { RESET_BETA } from 'next/entities/features';
import { selectUserProfileAttributes } from 'selectors/profile-attributes';
import { fetchUserProfile } from 'actions/userProfiles';
import { selectAccountUserProfile } from 'reducers/account/userProfiles';
import { selectAccountMeta } from 'reducers/account/meta';
import { selectUserRole } from 'reducers/account/users';
import { selectUserId } from 'reducers/user';
import { ACCOUNT_PERMISSIONS } from 'constants/accountManagement/permissions';
import { selectAccountFeature } from 'reducers/account/features';
import {
  selectGroupProfileProperties,
  callApi as fetchGroupProfileProperties,
} from 'entities/group-profile-properties';
import { asPromised } from 'utils/as-promised';
import { getPropertyDisplayName } from 'utils';
import ResetFlowModal from './ResetFlowModal';
import {
  IdentifierValue,
  IdentifierName,
  IdentifierListItem,
  IdentifiersListSection,
  IdentifierText,
  ProfileTitle,
  HeaderDescription,
  AudiencePageHeader,
} from './styled';
import UserIdentifierHelper from './UserIdentifierHelper';

export function Header({
  userId,
  groupId,
  groupIdentifier,
  onLoad,
  fetchGroupProperties,
  timestamp,
  userProfileIdentifierValue: identifierValue,
  hasResetFlowHistoryBeta,
  canResetFlowHistory,
}) {
  useEffect(() => {
    onLoad?.();
  }, [onLoad, userId]);

  useEffect(() => {
    if (groupId && groupIdentifier) {
      fetchGroupProperties(groupId, groupIdentifier);
    }
  }, [groupId, groupIdentifier?.property, fetchGroupProperties]);

  const lastSeen =
    moment().diff(timestamp, 'month') >= 1
      ? moment(timestamp).format('llll')
      : moment(timestamp).fromNow();

  return (
    <AudiencePageHeader>
      <HeaderDescription>
        <ProfileTitle>
          <PageReturn to="/audience/users" title="All users" />
          <H2>
            User profile
            {identifierValue && (
              <IdentifierText title={identifierValue}>
                {' '}
                - {identifierValue}
              </IdentifierText>
            )}
          </H2>
          <UserIdentifierHelper />
        </ProfileTitle>
        <PageSubheader>
          <IdentifiersListSection>
            <IdentifierListItem>
              <IdentifierName>User ID:</IdentifierName>
              <IdentifierValue>{userId}</IdentifierValue>
            </IdentifierListItem>

            {groupId && (
              <IdentifierListItem>
                <IdentifierName>
                  {getPropertyDisplayName(groupIdentifier.property)}:
                </IdentifierName>
                <IdentifierValue>
                  <Tooltip
                    content={
                      <Text
                        size="small"
                        lineHeight="large"
                        colorToken="foreground-tooltip"
                      >
                        Open Account Profile
                      </Text>
                    }
                  >
                    <Link to={`/audience/accounts/${groupId}/overview`}>
                      {groupIdentifier.value}
                    </Link>
                  </Tooltip>
                </IdentifierValue>
              </IdentifierListItem>
            )}

            {timestamp && (
              <IdentifierListItem>
                <IdentifierName>Last seen:</IdentifierName>
                <IdentifierValue>{lastSeen}</IdentifierValue>
              </IdentifierListItem>
            )}
          </IdentifiersListSection>
        </PageSubheader>
      </HeaderDescription>
      <PageActions>
        {hasResetFlowHistoryBeta && canResetFlowHistory && (
          <ResetFlowModal userId={userId} />
        )}
      </PageActions>
    </AudiencePageHeader>
  );
}

Header.propTypes = {
  userId: PropTypes.string,
  groupId: PropTypes.string,
  groupIdentifier: PropTypes.shape({
    property: PropTypes.string,
    value: PropTypes.string,
  }),
  timestamp: PropTypes.number,
  userProfileIdentifierValue: PropTypes.string,
  onLoad: PropTypes.func,
  fetchGroupProperties: PropTypes.func,
  hasResetFlowHistoryBeta: PropTypes.bool,
  canResetFlowHistory: PropTypes.bool,
};

const mapStateToProps = (state, { userId }) => {
  const userProfileIdentifier = selectAccountMeta(state)?.userProfileIdentifier;
  const userProfile = selectAccountUserProfile(state, userId);
  const userRole = selectUserRole(state, selectUserId(state));
  const userProperties = selectUserProfileAttributes(state, userId);
  const groupProfileIdentifier =
    selectAccountMeta(state)?.groupProfileIdentifier;
  const groupId = userProperties?.find(
    ({ name }) => name === '_lastGroupId'
  )?.value;
  const groupIdentifier = {
    property:
      groupProfileIdentifier && groupProfileIdentifier !== ''
        ? groupProfileIdentifier
        : 'group_id',
    value: groupProfileIdentifier
      ? selectGroupProfileProperties(state)?.[0]?.value
      : groupId,
  };

  return {
    userProfileIdentifierValue: userProfileIdentifier
      ? userProfile?.[userProfileIdentifier]
      : null,

    // eslint-disable-next-line no-underscore-dangle
    timestamp: userProfile?._updatedAt,
    hasResetFlowHistoryBeta: selectAccountFeature(state, RESET_BETA),
    canResetFlowHistory: userRole !== ACCOUNT_PERMISSIONS.EDITOR,
    groupId,
    groupIdentifier,
  };
};

const mapDispatchToProps = (dispatch, { userId }) => {
  return {
    onLoad: () => dispatch(fetchUserProfile(userId)),

    fetchGroupProperties: (groupId, groupIdentifier) => {
      asPromised(
        dispatch,
        fetchGroupProfileProperties({
          groupId,
          searchString: groupIdentifier.property,
        })
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
