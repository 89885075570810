import * as actionTypes from 'constants/chromeExtension/actionTypes';

export function checkForChromeExtension() {
  return {
    type: actionTypes.CHECK_FOR_CHROME_EXTENSION,
    payload: {},
  };
}

export function startBuilderInChromeExtension(url) {
  return {
    type: actionTypes.START_BUILDER_IN_CHROME_EXTENSION,
    payload: {
      url,
    },
  };
}

export function editStepInChromeExtension(stepGroupId, stepChildId) {
  return {
    type: actionTypes.EDIT_STEP_IN_CHROME_EXTENSION,
    payload: {
      stepGroupId,
      stepChildId,
    },
  };
}

export function setIsSpoofingAccountInChromeExtension(
  isSpoofingAccount,
  spoofAccountId
) {
  return {
    type: actionTypes.SET_IS_SPOOFING_ACCOUNT_IN_CHROME_EXTENSION,
    payload: {
      isSpoofingAccount,
      spoofAccountId,
    },
  };
}

export function logoutUserFromChromeExtension() {
  return {
    type: actionTypes.LOGOUT_USER_FROM_CHROME_EXTENSION,
  };
}

export function updateChromeExtensionStatus({
  hasChrome,
  hasExtensionInstalled,
  version,
}) {
  return {
    type: actionTypes.CHROME_EXTENSION_STATUS_UPDATED,
    payload: {
      hasChrome,
      hasExtensionInstalled,
      version,
    },
  };
}
