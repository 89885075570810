import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H3, Panel } from '@studio/legacy-components';
import useIsMounted from 'next/hooks/use-is-mounted';
import QueryResults from 'components/Common/QueryResults';
import Loader from 'components/Common/Loader';
import Pagination, {
  PaginationContainer,
  PaginationMessage,
} from 'components/Common/UI/Pagination/Pagination';
import ResponsesTable from './ResponsesTable';
import ResponsesFilter, { FILTER_OPTIONS } from './ResponsesFilter';

/**
 * Creates an NPS responses conditions array depending on the selected values by group.
 * Selecting all values in a group equals having no filter whatsoever
 * @param {Object} selectedFilters - object whose each key represents a group
 * and its value an array of the selected conditions i.e. for promoters and detractors
 * {
 *  "score": [["nps_raw_score", ">=", 9], ["nps_raw_score", "<=", 6]]
 * }
 * @returns an array of query conditions
 */
export const buildFilterConditions = selectedFilters => {
  if (!selectedFilters) {
    return [];
  }

  const filterConditions = Object.entries(selectedFilters).reduce(
    (accConditions, [groupKey, values]) => {
      const groupConfig = FILTER_OPTIONS.find(item => item.key === groupKey);

      if (values.length === 1) {
        accConditions.push(...values);
      } else if (
        values.length > 0 &&
        values.length !== groupConfig.options.length
      ) {
        accConditions.push(['or', ...values]);
      }

      return accConditions;
    },
    []
  );

  return filterConditions;
};

export const filterIsEmpty = selectedFilters => {
  if (!selectedFilters) {
    return true;
  }

  return Object.values(selectedFilters).every(filters => filters.length === 0);
};

const TotalsLoader = styled(Loader)`
  height: 300px;
`;

const ResultsLoader = styled(Loader)`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const Wrapper = styled(Panel)`
  min-height: 450px;
  min-width: 870px;
  position: relative;
  padding: 24px 0;
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 24px;
`;

const PaginationWrapper = styled(PaginationContainer)`
  margin: 0 24px;
`;

const NpsResponses = ({
  npsId,
  startTime,
  endTime,
  segmentId,
  pageSize = 25,
}) => {
  const isMounted = useIsMounted();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [orderByColumn, setOrderByColumn] = useState('timestamp');
  const [orderByDirection, setOrderByDirection] = useState('desc');
  const [filters, setFilters] = useState({});
  const panelRef = useRef();

  useEffect(() => {
    setCurrentIndex(0);
  }, [startTime, endTime, segmentId, orderByColumn, orderByDirection, filters]);

  useEffect(() => {
    // We don't want to scroll to this side of the page the first time
    if (!isMounted) {
      return;
    }

    panelRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [currentIndex]);

  const handleSort = column => {
    if (column === orderByColumn) {
      setOrderByDirection(orderByDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderByColumn(column);
    }
  };

  const handleFilter = (groupId, isChecked, value) => {
    setFilters(currentFilters => {
      if (isChecked) {
        return {
          ...currentFilters,
          [groupId]: [...(currentFilters[groupId] ?? []), value],
        };
      }
      return {
        ...currentFilters,
        [groupId]: currentFilters[groupId].filter(item => item !== value),
      };
    });
  };

  const handleClearFilter = () => {
    setFilters({});
  };

  const conditions = [
    ['flow_id', '==', npsId],
    ['event', '==', 'appcues:nps_score_and_feedback'],
    ...buildFilterConditions(filters),
  ];

  if (segmentId) {
    conditions.push(['user_segment_id', '==', segmentId]);
  }

  const orderBy = [[orderByColumn, orderByDirection]];

  if (orderByColumn !== 'timestamp') {
    orderBy.push(['timestamp', 'desc']);
  }

  const totalsQuery = {
    metrics: ['nps_scores_submitted'],
    conditions,
    start_time: startTime,
    end_time: endTime,
    meta: {
      tags: {
        feature: 'NPS',
        page: 'NPS',
        component: 'NpsResponses',
        description: 'NPS responses',
      },
    },
  };

  const eventsQuery = {
    columns: [
      'nps_raw_score',
      'nps_feedback',
      'user_id',
      'timestamp',
      'a:_identity',
    ],
    conditions,
    order_by: orderBy,
    offset: currentIndex * pageSize,
    limit: pageSize,
    start_time: startTime,
    end_time: endTime,
    meta: {
      tags: {
        feature: 'NPS',
        page: 'NPS',
        component: 'NpsResponses',
        description: 'NPS responses',
      },
    },
  };

  return (
    <QueryResults query={totalsQuery}>
      {totalResults => {
        const [{ nps_scores_submitted: total }] = totalResults ?? [
          { nps_scores_submitted: 0 },
        ];
        const emptyFilter = filterIsEmpty(filters);

        // No responses for the given period / segment
        // shows nothing. We need to know is totals are done fetching
        // filters in responses are not considered
        if (totalResults && total === 0 && emptyFilter) {
          return null;
        }

        return (
          <QueryResults query={eventsQuery}>
            {results => {
              // Display this loader only when changing segment / timeframe
              // When the table is sorted / filtered, use the inner loader instead
              if (!results && total === 0 && emptyFilter) {
                return (
                  <Panel>
                    <TotalsLoader />
                  </Panel>
                );
              }

              return (
                <Wrapper ref={panelRef}>
                  <Header>
                    <H3>Responses</H3>
                    <ResponsesFilter
                      values={filters}
                      onChange={handleFilter}
                      onClear={handleClearFilter}
                    />
                  </Header>
                  {!results && <ResultsLoader />}
                  <ResponsesTable
                    responses={results}
                    orderByColumn={orderByColumn}
                    orderByDirection={orderByDirection}
                    onSort={handleSort}
                  />
                  {results && results.length > 0 && (
                    <PaginationWrapper>
                      <Pagination
                        pageCount={Math.ceil(total / pageSize)}
                        currentIndex={currentIndex}
                        onChange={setCurrentIndex}
                      />
                      <PaginationMessage
                        currentIndex={currentIndex}
                        pageSize={pageSize}
                        total={total}
                      />
                    </PaginationWrapper>
                  )}
                </Wrapper>
              );
            }}
          </QueryResults>
        );
      }}
    </QueryResults>
  );
};

NpsResponses.propTypes = {
  npsId: PropTypes.string,
  startTime: PropTypes.number,
  endTime: PropTypes.number,
  segmentId: PropTypes.string,
  pageSize: PropTypes.number,
};

export default NpsResponses;
