import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Page, PageBody, Spinner } from '@studio/legacy-components';
import {
  ConditionsPropsShape,
  SelectOptionsShape,
  Shape,
  getLaunchpadTargetingSections,
} from '@studio/conditions';

import {
  AudiencePanel,
  ExperienceDetails,
  UrlTargeting,
} from '@studio/settings-panels';
import { Shape as FlowShape } from 'next/entities/flows';
import { selectRule, update as updateRule } from 'next/entities/rules';
import { selectUser } from 'next/entities/user';
import { selectAccountUser } from 'next/entities/account-users';
import { replace as replacePage } from 'next/entities/page';
import { drop as dropContentStatus } from 'next/entities/content-status';
import { create as createSegment } from 'next/entities/segments';
import { selectSegmentTargetingOptions } from 'next/lib/selectors-options';
import {
  LAUNCHPADS_V2_ANALYTICS,
  MOBILE_FLOWS,
  selectFeature,
} from 'next/entities/features';

import {
  selectExperience,
  update as updateExperience,
} from 'next/entities/experiences';

import ExperienceHeader, {
  HeaderActions,
} from 'next/components/ExperienceHeader';
import ExperienceBanner from 'next/components/ExperienceBanner';
import ExperienceContext from 'next/components/ExperienceContext';
import { selectAccount } from 'next/entities/account';
import { selectInstalled } from 'next/entities/installed';
import { selectConditionsOptions } from 'next/lib/selector-conditions-options';
import { navigate } from 'next/lib/history';
import { Loading } from './styled';

export const SettingsPage = ({
  id,
  loaded,
  experience,
  rule,
  onLoad,
  onUnload,
  onExperienceChange,
  onRuleChange,
  domains,
  installedDomains,
  conditionsOptions,
  segmentsOptions = [],
  onSegmentCreate,
  hasMobileFlows,
  hasLaunchpadAnalyticsEnabled,
}) => {
  const routePath = '/launchpads';
  const experienceData = useMemo(
    () => ({
      sections: getLaunchpadTargetingSections(rule?.conditions),
      type: 'launchpad',
      routePath,
    }),
    [rule, routePath]
  );

  useEffect(() => {
    onLoad();
    return () => onUnload();
  }, [onLoad, onUnload]);

  if (!loaded) {
    return (
      <Loading>
        <Spinner aria-label="Loading" />
      </Loading>
    );
  }

  const handleSectionChange = section => newConditions =>
    void onRuleChange({
      conditions: {
        and: Object.values({
          ...experienceData.sections,
          [section]: newConditions,
        }).filter(branch => branch && (branch.and || branch.or).length > 0),
      },
    });

  const { sections } = experienceData;

  const openStandaloneBuilderInStudio = () => {
    navigate(`${routePath}/${id}/builder`);
  };

  return (
    <ExperienceContext.Provider value={experienceData}>
      <Page>
        <ExperienceHeader
          id={id}
          currentPage="settings"
          renderActions={({ updatedAt, published }) => (
            <HeaderActions
              id={id}
              updatedAt={updatedAt}
              published={published}
              onClickOpenInBuilder={openStandaloneBuilderInStudio}
              hideScheduling
              hideOpenInBuilderBtn={hasLaunchpadAnalyticsEnabled}
              hideDropdown={!hasLaunchpadAnalyticsEnabled}
            />
          )}
        />
        <PageBody>
          <ExperienceBanner id={id} />
          <ExperienceDetails
            name={experience.name}
            previewUrl={experience.previewUrl}
            onChange={onExperienceChange}
            namePlaceholder="Launchpad Name"
            nameAriaLabel="Launchpad Name"
            hideBuildUrlCaption
          />
          <UrlTargeting
            domains={domains}
            installedDomains={installedDomains}
            experienceName="Launchpad"
            urlConditions={sections.url}
            domainConditions={sections.domains}
            onUrlConditionChange={handleSectionChange('url')}
            onDomainConditionChange={handleSectionChange('domains')}
            conditionsOptions={conditionsOptions}
          />
          <AudiencePanel
            conditions={sections.audience}
            conditionsOptions={conditionsOptions}
            experienceName="Launchpad"
            hasMobileFlows={hasMobileFlows}
            onChange={handleSectionChange('audience')}
            onSegmentCreate={onSegmentCreate}
            segmentsOptions={segmentsOptions}
            hideSpecificUsersOption
            hasLaunchpad
          />
        </PageBody>
      </Page>
    </ExperienceContext.Provider>
  );
};

SettingsPage.propTypes = {
  id: PropTypes.string,
  loaded: PropTypes.bool,
  experience: FlowShape,
  rule: Shape,
  onLoad: PropTypes.func,
  onUnload: PropTypes.func,
  onExperienceChange: PropTypes.func,
  onRuleChange: PropTypes.func,
  domains: PropTypes.arrayOf(PropTypes.string),
  installedDomains: PropTypes.objectOf(
    PropTypes.shape({
      hostname: PropTypes.string,
    })
  ),
  conditionsOptions: ConditionsPropsShape,
  segmentsOptions: SelectOptionsShape,
  onSegmentCreate: PropTypes.func,
  hasMobileFlows: PropTypes.bool,
  hasLaunchpadAnalyticsEnabled: PropTypes.bool,
};

const mapStateToProps = (
  state,
  {
    match: {
      params: { experienceId },
    },
  }
) => {
  const experience = selectExperience(state, experienceId);
  const rule = selectRule(state, experienceId);
  const { id: userId } = selectUser(state);
  const accountUser = selectAccountUser(state, userId);
  const { domains = [] } = selectAccount(state);

  return {
    segmentsOptions: selectSegmentTargetingOptions(state),
    hasMobileFlows: selectFeature(state, MOBILE_FLOWS),
    hasLaunchpadAnalyticsEnabled: selectFeature(state, LAUNCHPADS_V2_ANALYTICS),
    domains,
    conditionsOptions: selectConditionsOptions(state),
    installedDomains: selectInstalled(state),
    id: experienceId,
    loaded: Boolean(experience && accountUser && rule),
    experience,
    rule,
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      path,
      params: { experienceId: id },
    },
  }
) => ({
  onLoad: () => dispatch(replacePage({ path, id })),
  onUnload: () => dispatch(dropContentStatus(id)),
  onExperienceChange: delta => dispatch(updateExperience(id, delta)),
  onRuleChange: delta => dispatch(updateRule(id, delta)),
  onSegmentCreate: data => dispatch(createSegment(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
