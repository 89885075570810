import styled from 'styled-components';
import { Icon, Table, Td, Th, Tr, Skeleton } from '@studio/legacy-components';

export const TooltipIcon = styled(Icon).attrs({
  icon: 'info-circle',
})`
  color: var(--text-secondary);
  vertical-align: top;
  margin-top: 3px;
`;

export const SegmentsTable = styled(Table)`
  --segment-name-cell-max-width: 350px;
  --creator-cell-max-width: 150px;
  --remaining-width: calc
    (
      100% -
        (var(--segment-name-cell-max-width) + var(--creator-cell-max-width))
    );

  ${Th}, ${Td} {
    padding: 8px;

    &:nth-child(1) {
      width: var(--segment-name-cell-max-width);
      max-width: var(--segment-name-cell-max-width);
    }

    &:nth-child(2) {
      width: var(--creator-cell-max-width);
      max-width: var(--creator-cell-max-width);
    }

    &:nth-child(3) {
      max-width: calc(var(--remaining-width) * 0.15);
    }

    &:nth-child(4) {
      max-width: calc(var(--remaining-width) * 0.15);
    }

    &:nth-child(5) {
      max-width: calc(var(--remaining-width) * 0.3);
    }

    &:nth-child(6) {
      max-width: calc(var(--remaining-width) * 0.3);
    }

    &:nth-child(7) {
      max-width: calc(var(--remaining-width) * 0.1);
    }
  }

  ${Th} {
    &:nth-child(4),
    &:nth-child(5) {
      justify-content: space-around;
    }
  }
`;

export const SegmentsRow = styled(Tr)`
  ${Td} {
    &:nth-child(1),
    &:nth-child(2) {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const TableWrapper = styled.div`
  --table-wrapper-min-height: 388px;
  --table-wrapper-max-height: 1140px; // 26 rows of 35px height
  min-height: var(--table-wrapper-min-height);
  max-height: var(--table-wrapper-max-height);
  overflow-y: auto;
  position: relative;
`;

export const LifeCycleIcon = styled.img`
  margin-left: 5px;
  vertical-align: middle;
  height: 17px;
`;

export const Dots = styled(Icon)`
  color: var(--dark-resting);
`;

export const TooltipContentWrapper = styled.div`
  width: 200px;
  white-space: initial;
  font-weight: var(--normal);
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;

  ${Icon} {
    margin-right: 8px;
  }
`;

export const EstimateLoadingSkeleton = styled(Skeleton).attrs({
  'data-testid': 'estimate-loading-skeleton',
})`
  height: 20px;
`;
