import { getTimingInstructions } from 'utils/timing';

export default function actionTimingMiddleware() {
  return next => action => {
    const timingInstructions = getTimingInstructions(action);
    if (timingInstructions) {
      switch (timingInstructions.type) {
        case 'add_mark': {
          const startMark = timingInstructions.addMark;
          window.performance.clearMarks(startMark);
          window.performance.mark(startMark);
          break;
        }

        case 'add_measure': {
          const endMark = timingInstructions.addMark;
          window.performance.mark(endMark);
          try {
            window.performance.measure(
              timingInstructions.addMeasure,
              timingInstructions.measureFromMark,
              endMark
            );
          } catch (error) {
            // eslint-disable-next-line no-console
            console.warn(
              'Could not add performance measure for',
              timingInstructions.addMeasure,
              '-',
              error.message
            );
          }
          window.performance.clearMarks(endMark);
          break;
        }

        default:
          // eslint-disable-next-line no-console
          console.warn('Unknown timing instruction type', timingInstructions);
      }
    }
    return next(action);
  };
}
