import styled from 'styled-components';

const CenteredPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding-top: 100px;

  p {
    max-width: 480px;
    color: ${props => props.theme['$gray-50']};
  }
`;

export default CenteredPage;
