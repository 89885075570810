import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CPanel } from '@appcues/component-library';
import { Text } from '@studio/legacy-components';
import { updateAccountMeta } from 'actions/account/meta';
import {
  selectAccountMeta,
  selectAccountMetaSynced,
} from 'reducers/account/meta';
import CredentialsManager from './CredentialsManager';
import { Wrapper } from './styled';

export const CustomerSupportPanel = ({
  exists = false,
  loaded = false,
  onSubmit,
}) => {
  if (!loaded) {
    return null;
  }

  return (
    <CPanel title="Customer support">
      <Wrapper>
        <Text>
          To improve your support experience, you can provide our team with a
          demo login for your application. We use that info to troubleshoot
          issues in your test environment and verify any flows you&apos;ve
          created look extra beautiful.
        </Text>

        <CredentialsManager exists={exists} onSubmit={onSubmit} />
      </Wrapper>
    </CPanel>
  );
};

CustomerSupportPanel.propTypes = {
  exists: PropTypes.bool,
  loaded: PropTypes.bool,
  onSubmit: PropTypes.func,
};

const mapStateToProps = state => {
  const { testUrl, testUsername, testPassword } = selectAccountMeta(state);

  const exists = [testUrl, testUsername, testPassword].some(Boolean);

  return {
    exists,
    loaded: selectAccountMetaSynced(state),
  };
};

const mapDispatchToProps = {
  onSubmit: values => updateAccountMeta(values),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerSupportPanel);
