import React from 'react';
import styled from 'styled-components';
import { Notice, NOTICE_TYPES } from '@appcues/component-library';
import Panel from 'components/Common/Panel';
import QualifiedContentNotice from 'components/Diagnostics/QualifiedContentNotice';
import { FrequencyCapBanner } from 'components/Diagnostics/FrequencyCapBanner';

const FlowEligibility = props => {
  const {
    accountId,
    allContent,
    userId,
    url,
    rules,
    conditions,
    isInstalled,
    segments,
    meta,
    userProfile,
    navigate,
    qualifiedSteps,
    unqualifiedSteps,
    hasFrequencyBuffer,
    checklists,
  } = props;

  return (
    <StyledFlowEligibility>
      {userProfile && meta && hasFrequencyBuffer && (
        <FrequencyCapBanner
          buffer={meta.buffer}
          userProfile={userProfile}
          navigateToAccount={() => navigate('/account')}
        />
      )}

      {!isInstalled && (
        <Notice type={NOTICE_TYPES.warning}>
          Appcues is not yet installed on this account.
        </Notice>
      )}
      <Panel
        name="Eligible Flows"
        description="Flows the given user should be able to see (highest priority at the top)."
      >
        {qualifiedSteps.map(step => (
          <QualifiedContentNotice
            key={step.id}
            stepId={step.id}
            accountId={accountId}
            userId={userId}
            url={url}
            qualified
            steps={allContent}
            rule={rules[step.id] || {}}
            clauses={conditions[step.id] || []}
            segments={segments}
            name={step.name}
            checklists={checklists}
          />
        ))}
      </Panel>
      <Panel
        name="Ineligible Flows"
        description="Flows that the given user is not eligible to see."
      >
        {unqualifiedSteps.map(step => (
          <QualifiedContentNotice
            key={step.id}
            stepId={step.id}
            accountId={accountId}
            userId={userId}
            url={url}
            qualified={null}
            steps={allContent}
            rule={rules[step.id]}
            clauses={conditions[step.id]}
            segments={segments}
            name={step.name}
            checklists={checklists}
          />
        ))}
      </Panel>
    </StyledFlowEligibility>
  );
};

const StyledFlowEligibility = styled.div`
  margin-top: 1rem;
`;

export default FlowEligibility;
