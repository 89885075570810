import { createLifecycleFor } from 'actions/account/collections';

const TYPE = 'goals';

const actions = createLifecycleFor(TYPE);

export const GOAL_DELETED = 'GOAL_DELETED';
export function deleteGoal(id) {
  return {
    type: GOAL_DELETED,
    payload: {
      id,
    },
  };
}

export const GOAL_CREATED_FOR_RULE = 'GOAL_CREATED_FOR_RULE';
export function createGoalForRule(name, conditions, ruleId) {
  return {
    type: GOAL_CREATED_FOR_RULE,
    payload: {
      name,
      conditions,
      ruleId,
    },
  };
}

export const {
  callApi,
  send,
  resolve,
  reject,
  create,
  insert,
  update,
  replace,
  flush,
  remove,
  patterns,
} = actions;
