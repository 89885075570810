import {
  addClause,
  conditionNames,
  deleteMatchingClauses,
  filterAudienceTargetingClauses,
  propertyOperators,
} from '@appcues/libcues';
import { defaultOperators } from 'constants/account/conditions';
import {
  filterUrlTargetingClauses,
  filterPropertyClauses,
} from 'utils/conditions';
import { replaceClauseBranch } from 'transforms/clauses';

export function setDisplayAnyPage(clauses) {
  return addClause(
    deleteMatchingClauses(clauses, filterUrlTargetingClauses),
    null,
    {
      conditionName: conditionNames.URL,
      operator: propertyOperators.REGEX,
      value: '.*',
    }
  );
}

export function setDisplaySpecificPage(_clauses, replacementClauses) {
  let clauses = deleteMatchingClauses(_clauses, filterUrlTargetingClauses);
  clauses = replacementClauses
    ? replaceClauseBranch(clauses, replacementClauses)
    : addClause(clauses, null, {
        conditionName: conditionNames.URL,
        operator: defaultOperators.CONTAINS,
        value: '',
      });
  return clauses;
}

export function setAudienceAllUsers(clauses) {
  return deleteMatchingClauses(clauses, filterAudienceTargetingClauses);
}

export function setAudienceSegment(clauses, segmentId) {
  return addClause(
    deleteMatchingClauses(clauses, filterAudienceTargetingClauses),
    null,
    {
      conditionName: conditionNames.SEGMENTS,
      segment: segmentId,
    }
  );
}

export function setAudienceCustomSegment(
  _clauses,
  replacementClauses,
  defaultProperty = ''
) {
  let clauses = deleteMatchingClauses(_clauses, filterAudienceTargetingClauses);
  clauses = replacementClauses
    ? replaceClauseBranch(clauses, replacementClauses)
    : addClause(clauses, null, {
        conditionName: conditionNames.PROPERTIES,
        property: defaultProperty,
        operator: propertyOperators.CONTAINS,
        value: '',
      });
  return clauses;
}

export function setChecklistFlowCompleted(clauses, flowId) {
  return addClause(
    deleteMatchingClauses(clauses, filterPropertyClauses),
    null,
    {
      conditionName: conditionNames.CONTENT,
      content: flowId,
    },
    false
  );
}

export function setDefaultPropertyClause(clauses) {
  return addClause(
    deleteMatchingClauses(clauses, filterPropertyClauses),
    null,
    {
      conditionName: conditionNames.PROPERTIES,
      property: '',
      operator: propertyOperators.CONTAINS,
      value: '',
    },
    false
  );
}

// This is the clause that is used when the user clicks the "When the item is clicked" radio button
// The SDK will read in this setting and send the user property when the item is clicked if this setting is true
export function setItemClickedClause(clauses, checklistItemId) {
  return addClause(
    deleteMatchingClauses(clauses, filterPropertyClauses),
    null,
    {
      operator: '==',
      property: `_checklist_item_${checklistItemId}_completed`,
      value: 'true',
      conditionName: 'properties',
    },
    false
  );
}

export function ruleHasGoal(rule = {}) {
  if (!rule) {
    return false;
  }

  return rule.goals && Array.isArray(rule.goals) && rule.goals[0];
}

export function getGoalIdFromRule(rule = {}) {
  if (!ruleHasGoal(rule)) {
    return null;
  }

  return rule.goals[0];
}
