/* global APPCUES_API_URL */

// eslint-disable-next-line no-restricted-imports
import authClient from 'helpers/auth-client';

/**
 * Returns auth header that uses our JWT
 * @param  {String} jwt Appcues-issued custom JWT
 * @return {Object}     Header object with Authorization header
 */
export const getJwtAuthHeaders = async () => {
  const token = await authClient.getToken();

  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
};

/**
 * Query the API to determine which flows a particular user is eligible to see
 * optionally given additional/modified identified properties.
 *
 * @param accountId
 * @param userId
 * @param url
 * @param profileUpdate
 * @returns {Promise}
 */

export const getQualifiedFlows = async (
  accountId,
  userId,
  url,
  profileUpdate = {}
) => {
  /*
   * Encode the userId in cases where IDs may have pound symbols or other
   * non-alphanumeric characters in them.
   */
  const encodedId = encodeURIComponent(userId);
  const headers = await getJwtAuthHeaders();

  const response = await fetch(
    `${APPCUES_API_URL}/v1/accounts/${accountId}/users/${encodedId}/diagnostics`,
    {
      method: 'post',
      headers,
      body: JSON.stringify({
        request_id: 'request-id',
        profile_update: profileUpdate,
        events: [
          {
            name: 'appcues:page_view',
            attributes: {
              url,
            },
            timestamp: Date.now(),
          },
        ],
      }),
    }
  );

  if (response.status !== 200) {
    throw new Error('Qualify request failed to fetch flows');
  }

  return response.json();
};

/**
 * Queries the API to determine if a user is qualified for a specific subset
 * of a conditions tree
 *
 * @param accountId
 * @param userId
 * @param url
 * @param conditions
 * @returns {Promise}
 */

export const qualifyPartialRule = async (
  accountId,
  userId,
  url,
  conditions = {}
) => {
  const encodedId = encodeURIComponent(userId);
  const headers = await getJwtAuthHeaders();

  const response = await fetch(
    `${APPCUES_API_URL}/v1/accounts/${accountId}/users/${encodedId}/qualify/partial`,
    {
      method: 'post',
      headers,
      body: JSON.stringify({
        condition: conditions,
        context: {
          url,
        },
      }),
    }
  );

  if (response.status !== 200) {
    throw new Error(
      `Failed to partially qualify conditions ${JSON.stringify(conditions)}`
    );
  }

  const { qualified } = await response.json();
  return qualified;
};

/**
 * Requests the specified user's profile from the API's user/profile endpoint
 *
 * @param accountId
 * @param userId
 * @returns {Promise}
 */

export const getUserProfile = async (accountId, userId) => {
  const encodedId = encodeURIComponent(userId);
  const headers = await getJwtAuthHeaders();

  const response = await fetch(
    `${APPCUES_API_URL}/v1/accounts/${accountId}/users/${encodedId}/profile`,
    {
      method: 'get',
      headers,
    }
  );

  if (response.status !== 200) {
    throw new Error(
      `Failed to retrieve profile for Account: ${accountId}, User: ${userId}`
    );
  }

  return response.json();
};

/**
 * Updates the specified user's profile from the API's activity endpoint
 *
 * @param accountId
 * @param userId
 * @param attributes - an object containing user properties and values
 * @param events - an optional array containing event objects
 * @returns {Promise}
 */

export const updateUserProfile = async (
  accountId,
  userId,
  attributes,
  events
) => {
  const profileData = {
    request_id: 'request-id',
    profile_update: attributes,
    ...(events ? { events } : {}),
  };
  const encodedId = encodeURIComponent(userId);
  const response = await fetch(
    `${APPCUES_API_URL}/v1/accounts/${accountId}/users/${encodedId}/activity`,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(profileData),
    }
  );

  if (response.status !== 200 && response.status !== 202) {
    throw new Error(
      `Failed to retrieve profile for Account: ${accountId}, User: ${userId}`
    );
  }

  return response.json();
};

/**
 * Retrieves the Event rollups stored in Postgres from the API
 * @param accountId
 * @returns {Promise<*>}
 */

export const getUserEvents = async accountId => {
  const headers = await getJwtAuthHeaders();
  const response = await fetch(
    `${APPCUES_API_URL}/v1/accounts/${accountId}/event_names`,
    {
      method: 'get',
      headers,
    }
  );

  if (response.status === 200) {
    return response.json();
  }

  throw new Error(
    `${response.status} response from api when fetching user events.`
  );
};

/**
 * Invokes the API's publishing hook, which transfers everything in Firebase's
 * /public/users/:account-id into Postgres for delivery to end-users
 *
 * @param accountId
 * @param accountUUID
 * @returns {Promise<Response>}
 */

export const hitPublishingHook = async accountId => {
  const headers = await getJwtAuthHeaders();
  return fetch(`${APPCUES_API_URL}/v1/accounts/${accountId}/publish`, {
    method: 'post',
    headers,
  });
};

export default {
  getQualifiedFlows,
  qualifyPartialRule,
  getUserProfile,
  updateUserProfile,
  getUserEvents,
  hitPublishingHook,
};
