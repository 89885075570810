import PropTypes from 'prop-types';
import pick from 'lodash.pick';

/**
 * Full shape of tag data model
 */
const Shape = {
  createdAt: PropTypes.number,
  createdBy: PropTypes.string,
  id: PropTypes.string,
  index: PropTypes.number,
  name: PropTypes.string,
};

/**
 * Allowed fields for storage
 */
export const allowed = ['id', 'name'];

/**
 * Tag parsing function
 *
 * @param {Object<keyof Tag>} tag - Object with tag fields
 * @return {Object<keyof Tag>} Object with parsed tag fields
 */
export const parse = tag => pick(tag, allowed);

export default PropTypes.shape(parse(Shape));
