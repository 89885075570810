import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import {
  CDialog,
  CButton,
  H2,
  H4,
  Caption,
  Flex,
} from '@appcues/component-library';
import SpecialCard from 'components/Common/SpecialCard';
import NpsSpecialCard from 'components/Common/SpecialCard/NpsSpecialCard';
import { SECOND_CANCELLATION_MODAL } from 'constants/globals';

import { getJourneyStepsMap } from 'utils/steps';
import { numberWithCommas } from 'utils/textFormatting';
import { hideModal, showModal } from 'actions/currentModal';
import { navigate } from 'actions/routing';
import { selectFlows, selectAreFlowsSynced } from 'reducers/account/flows';
import {
  selectAccountChecklists,
  selectAccountChecklistsSynced,
} from 'entities/checklists';
import { selectAccountMetaSynced } from 'reducers/account/meta';
import {
  selectAccountFirstSatisfactionId,
  selectSatisfactionState,
} from 'selectors/satisfaction';
import { selectAccountStats } from 'reducers/account/stats';

class FirstCancellationModal extends Component {
  ModalFooter = ({ closeWithCallback }) => (
    <ButtonFooter>
      <CButton onClick={closeWithCallback(this.hideModal)}>
        Back to Appcues
      </CButton>
      <CButton type="negative" onClick={this.handleNextModal}>
        Continue to cancel
      </CButton>
    </ButtonFooter>
  );

  hideModal = () => {
    const { actions } = this.props;
    actions.hideModal();
  };

  handleNextModal = () => {
    const { actions } = this.props;
    actions.showModal(SECOND_CANCELLATION_MODAL);
  };

  render() {
    const {
      dashboardStats,
      steps,
      checklists,
      hasCreatedNPS,
      stepsSynced,
      checklistsSynced,
      satisfactionId,
    } = this.props;

    const usersReached = numberWithCommas(
      dashboardStats.all_time_users_reached
    );
    const flowsShown = numberWithCommas(dashboardStats.all_time_flows_shown);

    const numLiveFlows = Object.values(steps).filter(
      step => step.published && step.type === 'journey'
    ).length;

    const numLiveChecklists = Object.values(checklists).filter(
      checklist => checklist.published
    ).length;

    return (
      <CDialog
        title="Before you go..."
        components={{ Footer: this.ModalFooter }}
        width={920}
      >
        Take a moment to celebrate your Appcues story so far
        <H4 marginTop={24} paddingBottom={8}>
          Since you joined Appcues
        </H4>
        <Flex alignItems="baseline" paddingBottom={8}>
          <H2 type="secondary">{flowsShown}</H2>
          <Caption type="secondary">&nbsp; flows seen by users</Caption>
        </Flex>
        <Flex alignItems="baseline" paddingBottom={8}>
          <H2 type="secondary">{usersReached}</H2>
          <Caption type="secondary">
            &nbsp; users who saw Appcues content
          </Caption>
        </Flex>
        <H4 marginTop={24} paddingBottom={8}>
          Live in your application
        </H4>
        <Flex justifyContent="space-between" marginBottom={12}>
          <SpecialCard
            name="Flows"
            value={numLiveFlows || 'Create one'}
            description={
              numLiveFlows ? 'Live flows on your site' : 'No flows yet'
            }
            backgroundImage="$blurple-haze"
            iconClassName="map"
            to="/flows"
            flexShrink={2}
            onClick={this.hideModal}
            isLoading={!stepsSynced}
          />
          <SpecialCard
            name="Checklists"
            value={numLiveChecklists || 'Create one'}
            description={
              numLiveChecklists
                ? 'Live checklists on your site'
                : 'No checklists yet'
            }
            backgroundImage="$daba-dee-daba-die"
            iconClassName="check-circle"
            to="/checklists"
            flexShrink={2}
            marginLeft={20}
            onClick={this.hideModal}
            isLoading={!checklistsSynced}
          />

          <NpsSpecialCard
            npsId={satisfactionId}
            hasCreatedNPS={hasCreatedNPS}
            page="Cancellation modal"
          />
        </Flex>
      </CDialog>
    );
  }
}

function mapStateToProps(state) {
  const satisfactionState = selectSatisfactionState(state);

  const satisfactionId = satisfactionState.satisfaction
    ? selectAccountFirstSatisfactionId(state)
    : null;

  return {
    satisfactionId,
    dashboardStats: selectAccountStats(state),
    accountMetaSynced: selectAccountMetaSynced(state),
    steps: getJourneyStepsMap(selectFlows(state)),
    checklists: selectAccountChecklists(state),
    hasCreatedNPS: !!selectAccountFirstSatisfactionId(state),
    stepsSynced: selectAreFlowsSynced(state),
    checklistsSynced: selectAccountChecklistsSynced(state),
  };
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        hideModal,
        showModal,
        navigate,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FirstCancellationModal);

const ButtonFooter = styled(CDialog.Footer)`
  display: flex;
  justify-content: space-between;
`;
