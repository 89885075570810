import React from 'react';
import styled from 'styled-components';
import StatCard from 'components/SubscriptionStory/StatCard';
import { numberWithCommas } from 'utils/textFormatting';

const AllTimeStats = props => {
  const { allTimeFlowsShown, allTimeUsersReached } = props;

  const formattedFlowsShown = numberWithCommas(allTimeFlowsShown);

  const formattedUsersReached = numberWithCommas(allTimeUsersReached);

  return (
    <CardsWrapper>
      <StatCard
        textColor="white"
        numberAcross={2}
        background="linear-gradient(-90deg, #5C5CFF 0%, #8960FF 100%);"
        header="Flows Shown"
        stat={formattedFlowsShown}
        description="All time flows"
      />
      <StatCard
        textColor="white"
        numberAcross={2}
        background="linear-gradient(90deg, #4980FF 0%, #2CB4FF 100%)"
        header="Users Reached by Appcues"
        stat={formattedUsersReached}
        description="All time users reached"
      />
    </CardsWrapper>
  );
};

const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default AllTimeStats;
