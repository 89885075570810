import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as yup from 'yup';
import styled from 'styled-components';
import {
  CButton,
  CDialog,
  CForm,
  CRadio,
  CTextArea,
  H2,
  Text,
} from '@appcues/component-library';
import { hideModal, showModal } from 'actions/currentModal';
import { trackCancelSubscription } from 'actions/billing';
import { deleteSubscription } from 'actions/newBilling';
import { THIRD_CANCELLATION_MODAL } from 'constants/globals';

const cancellationReasons = [
  'Business changes - Shutting down, mergers, development delays, etc',
  "Missing features - Appcues doesn't have everything our team needs",
  'Technical issues - Bugs, integrations, installation challenges, etc',
  'Usability issues - Appcues is too difficult to use or adopt',
  'Value - I have little evidence that Appcues had a positive impact',
  "Cost - Appcues solves a valuable problem for us, but it's too expensive",
  'No longer needed - I no longer have the problem that Appcues solves',
  'Other',
];

const ButtonFooter = styled(CDialog.Footer)`
  display: flex;
  justify-content: space-between;
`;

const StyledCField = styled(CForm.Field)`
  margin-top: ${props => props.marginTop};
`;

export class SecondCancellationModal extends Component {
  state = {
    submitAttempted: false,
    formComplete: false,
  };

  formRef = React.createRef();

  ModalHeader = () => (
    <div>
      <H2 paddingBottom={16}>We&apos;re sorry to see you go</H2>
      We&apos;d love to learn from your experience.
    </div>
  );

  ModalFooter = ({ closeWithCallback }) => {
    const { formComplete, submitAttempted } = this.state;
    return (
      <ButtonFooter>
        <CButton onClick={closeWithCallback(this.hideModal)}>
          Back to Appcues
        </CButton>
        {!formComplete && submitAttempted && (
          <Text color="$red">Complete all required fields</Text>
        )}
        <CButton
          type="negative"
          onClick={this.formRef.current && this.formRef.current.submitForm}
        >
          <Text onClick={this.onButtonClick} color="$gray-0" paddingY={10}>
            Continue to cancel
          </Text>
        </CButton>
      </ButtonFooter>
    );
  };

  hideModal = () => {
    const { actions } = this.props;
    actions.hideModal();
  };

  onButtonClick = () => {
    this.setState({ submitAttempted: true });
  };

  onFormSubmit = ({
    leaveReason,
    qualitativeFeedback,
    replacementInfo,
    competitorInfo,
  }) => {
    const { actions } = this.props;
    this.setState({ formComplete: true });

    const cancellationInfo = {
      leaveReason,
      qualitativeFeedback,
      replacementInfo,
      competitorInfo,
    };
    if (!cancellationInfo.competitorInfo) {
      cancellationInfo.competitorInfo = '';
    }
    actions.trackCancelSubscription(cancellationInfo, Date.now());
    actions.showModal(THIRD_CANCELLATION_MODAL);
  };

  render() {
    return (
      <CDialog
        components={{ Header: this.ModalHeader, Footer: this.ModalFooter }}
        width={920}
      >
        <CForm
          ref={this.formRef}
          onSubmit={this.onFormSubmit}
          initialValues={{
            leaveReason: '',
            qualitativeFeedback: '',
            replacementInfo: '',
          }}
          validationSchema={yup.object().shape({
            leaveReason: yup.string().required('Required'),
            qualitativeFeedback: yup.string().required('Required'),
            replacementInfo: yup.string().required('Required'),
          })}
          marginTop={40}
        >
          {({ values }) => {
            return <FormContents values={values} />;
          }}
        </CForm>
      </CDialog>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        hideModal,
        showModal,
        trackCancelSubscription,
        deleteSubscription,
      },
      dispatch
    ),
  };
};

export default connect(null, mapDispatchToProps)(SecondCancellationModal);

export const FormContents = ({ values }) => {
  return (
    <CForm.Element>
      <CForm.Field
        name="leaveReason"
        label="What best describes why you are canceling? (required)"
        maxWidth={600}
      >
        <CRadio.Group>
          {cancellationReasons.map(reason => (
            <CRadio label={reason} value={reason.toLowerCase()} key={reason} />
          ))}
        </CRadio.Group>
      </CForm.Field>
      {/* when the user selects 'other' we treat this text box as the explanation,
        otherwise we ask it as a separate question */}
      <StyledCField
        name="qualitativeFeedback"
        label={
          values.leaveReason !== 'other'
            ? 'Please tell us more about where we missed the mark. (required)'
            : ''
        }
        placeholder="Feedback for the Appcues team"
        maxWidth={500}
        marginTop={values.leaveReason === 'other' ? '-30px' : 0}
      >
        <CTextArea />
      </StyledCField>
      <CForm.Field
        name="replacementInfo"
        label="How will you be replacing Appcues? (required)"
      >
        <CRadio.Group>
          <CRadio value="no replacement" label="No replacement" />
          <CRadio value="building in-house" label="Building in-house" />
          <CRadio value="another product" label="Another Product" />
        </CRadio.Group>
      </CForm.Field>
      {values.replacementInfo === 'another product' && (
        <CForm.Field
          name="competitorInfo"
          label="Would you mind telling us which product?"
          placeholder="Product name..."
          maxWidth={500}
        >
          <CTextArea />
        </CForm.Field>
      )}
    </CForm.Element>
  );
};
