import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@studio/legacy-components';
import CoolTip from 'components/Common/CoolTip';
import MetricCard from 'components/Common/Metric/MetricCard';
import QueryResults from 'components/Common/QueryResults';

import {
  getQueryConditionsForSelectedEvents,
  removeRecoveredErrors,
  getEventTotalsFromResults,
} from 'components/Insights/utils';

import { numberWithCommas } from 'utils/textFormatting';

const TotalsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: minmax(120px, 100%);
  grid-gap: 1rem;
`;

const StyledCoolTip = styled(CoolTip)`
  display: inline-block;
  margin-bottom: 8px;
  font-weight: 600;

  ${Icon} {
    margin-left: 1em;
  }
`;

const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TOOLTIP_TEXT = {
  default: {
    totalUsers: 'The number of total event occurrences.',
    uniqueUsers: 'The number of unique users who triggered the event.',
    percentUsers:
      'The percentage of users who triggered this event compared to the total number of visitors.',
  },
  goals: {
    totalUsers: 'The number of times this goal was achieved.',
    uniqueUsers: 'The number of unique users who achieved this goal.',
    percentUsers:
      'The percentage of users who achieved this goal compared to the total number of visitors',
  },
};

export const EventTotals = ({
  id,
  stepId,
  eventName,
  results,
  totalUsers,
  source,
}) => {
  const unrecoveredResults = results && removeRecoveredErrors(results);
  const { events, users } = getEventTotalsFromResults(
    unrecoveredResults || [],
    {
      eventId: id,
      eventStepId: stepId,
      eventName,
    }
  );

  const percentUsers = users && totalUsers && 100 * (users / totalUsers);

  return (
    <TotalsWrapper>
      <MetricCard
        loaded={!!results}
        name={
          <StyledCoolTip
            tip={
              TOOLTIP_TEXT[source]?.totalUsers ??
              TOOLTIP_TEXT.default.totalUsers
            }
          >
            TOTAL EVENTS
            <Icon icon="info-circle" />
          </StyledCoolTip>
        }
        metric={events ? events.toLocaleString() : '-'}
      />
      <MetricCard
        loaded={!!results}
        name={
          <StyledCoolTip
            tip={
              TOOLTIP_TEXT[source]?.uniqueUsers ??
              TOOLTIP_TEXT.default.uniqueUsers
            }
          >
            UNIQUE USERS
            <Icon icon="info-circle" />
          </StyledCoolTip>
        }
        metric={users ? users.toLocaleString() : '-'}
      />
      <MetricCard
        loaded={!!results}
        name={
          <StyledCoolTip
            tip={
              <TooltipWrapper>
                <b>
                  {TOOLTIP_TEXT[source]?.percentUsers ??
                    TOOLTIP_TEXT.default.percentUsers}
                </b>
                {totalUsers && (
                  <span>
                    Number of Visitors: {numberWithCommas(totalUsers)}
                  </span>
                )}
              </TooltipWrapper>
            }
          >
            % USERS
            <Icon icon="info-circle" />
          </StyledCoolTip>
        }
        metric={
          percentUsers
            ? `${
                percentUsers < 1
                  ? '< 1'
                  : percentUsers > 100
                  ? '100'
                  : Math.round(percentUsers)
              }%`
            : '-'
        }
      />
    </TotalsWrapper>
  );
};

EventTotals.propTypes = {
  eventName: PropTypes.string,
  id: PropTypes.string,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      events: PropTypes.number,
      users: PropTypes.number,
      flow_id: PropTypes.string,
      checklist_id: PropTypes.string,
    })
  ),
  totalUsers: PropTypes.number,
};

const EventTotalsWithAnalytics = ({
  startTime,
  endTime,
  segmentId,
  id,
  stepId,
  eventName,
  source,
}) => {
  return (
    <QueryResults
      query={{
        conditions: [
          ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
        ],
        metrics: ['users'],
        start_time: startTime,
        end_time: endTime,
        meta: {
          tags: {
            feature: 'Single metrics',
            page: 'Single metrics page',
            component: 'EventTotals',
            description: 'Total segment users for period',
          },
        },
      }}
    >
      {([{ users: totalUsers }] = [{}]) => (
        <QueryResults
          query={{
            conditions: [
              ...getQueryConditionsForSelectedEvents([
                { id, stepId, event: eventName, source },
              ]),
              ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
            ],
            dimensions: ['name', 'flow_id', 'experience_id', 'checklist_id'],
            metrics: ['events', 'users'],
            start_time: startTime,
            end_time: endTime,
            meta: {
              tags: {
                feature: 'Single metrics',
                page: 'Single metrics page',
                component: 'EventTotals',
                description: 'Selected event consolidated results',
              },
            },
          }}
        >
          {results => (
            <EventTotals
              results={results}
              totalUsers={totalUsers}
              eventName={eventName}
              id={id}
              stepId={stepId}
              source={source}
            />
          )}
        </QueryResults>
      )}
    </QueryResults>
  );
};

EventTotalsWithAnalytics.propTypes = {
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  segmentId: PropTypes.string,
  eventName: PropTypes.string,
  source: PropTypes.string,
  id: PropTypes.string,
  stepId: PropTypes.string,
};

export default EventTotalsWithAnalytics;
