import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CCheckbox, CTextInput } from '@appcues/component-library';

const RedirectActionSetting = ({
  redirectUrl = '',
  onBlur,
  onClear,
  labelText = 'Redirect on flow completion',
  placeholderText = 'Redirect location',
}) => {
  const [showRedirectInput, setShowRedirectInput] = useState(false);
  const [url, setUrl] = useState(redirectUrl);
  const showRedirectAction = showRedirectInput || !!redirectUrl;

  useEffect(() => {
    setUrl(redirectUrl || '');
    setShowRedirectInput(!!redirectUrl);
  }, [redirectUrl]);

  const handleClickCheckbox = () => {
    if (showRedirectAction) {
      onClear();
      setShowRedirectInput(false);
    } else {
      setShowRedirectInput(true);
    }
  };

  const handleBlur = () => onBlur(url);

  return (
    <>
      <CCheckbox
        onClick={handleClickCheckbox}
        value="redirect-action"
        label={labelText}
        checked={showRedirectAction}
      />
      {showRedirectAction && (
        <CTextInput
          marginBottom="10px"
          value={url}
          placeholder={placeholderText}
          onChange={setUrl}
          onBlur={handleBlur}
        />
      )}
    </>
  );
};

RedirectActionSetting.propTypes = {
  onBlur: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  redirectUrl: PropTypes.string,
  labelText: PropTypes.string,
  placeholderText: PropTypes.string,
};

export default RedirectActionSetting;
