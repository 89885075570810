import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Icon, H1 } from '@studio/legacy-components';

const GreyBackgroundPanel = props => {
  const {
    backDestination,
    backText,
    children,
    header,
    renderHeaderSecondaryComponent,
  } = props;

  return (
    <PageWrapper>
      <HeaderWrapper>
        <div>
          {backDestination && backText && (
            <LinkWrapper>
              <BackIcon icon="arrow-left" title="Back" />
              <BackLink to={backDestination}>{backText}</BackLink>
            </LinkWrapper>
          )}
          <H1>{header}</H1>
        </div>
        {renderHeaderSecondaryComponent()}
      </HeaderWrapper>
      {children}
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  background-color: ${props => props.theme['$gray-1']};
  min-height: 100vh;
`;

const BackIcon = styled(Icon)`
  margin-right: 12px;
`;

const LinkWrapper = styled.div`
  color: ${props => props.theme['$gray-5']};
  display: flex;
  margin-bottom: 8px;
  align-items: center;
`;

const BackLink = styled(Link)`
  color: ${props => props.theme['$gray-5']};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme['$gray-0']};
  padding: 40px;

  h1 {
    margin: 0;
  }
`;

export default GreyBackgroundPanel;
