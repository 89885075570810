import moment from 'moment';
import { createSelector } from 'reselect';
import { hasMatchingSegment } from '@studio/conditions';
import { selectSegmentMemberships } from 'reducers/account/segmentMemberships';
import { selectAccountSegments } from 'reducers/account/segments';
import { selectAccountUsers } from 'reducers/account/users';
import { selectAccountChecklists } from 'entities/checklists';
import { getCacheKey } from 'utils/caching';
import {
  selectPins,
  selectMobileFlows,
  selectBanners,
} from 'entities/experiences';
import { selectAllConditionsEstimates } from 'entities/conditions-estimates';
import { selectRulesAndConditions } from './ruleAndConditions';

const getNumOfExperiences = (experiences, segmentId) => {
  return Object.values(experiences).reduce((acc, experience) => {
    // Rule property check is for checklists
    if (
      hasMatchingSegment(
        experience.rule?.conditions || experience.conditions,
        segmentId
      )
    ) {
      return acc + 1;
    }
    return acc;
  }, 0);
};

const filterExperiences = (rules, filterFn) =>
  Object.values(rules ?? {}).reduce((acc, current) => {
    if (filterFn(current)) {
      acc[current.id] = current;
    }
    return acc;
  }, {});

export const selectSegmentsAsTableRows = createSelector(
  [
    selectAccountSegments,
    selectSegmentMemberships,
    selectAccountUsers,
    selectRulesAndConditions,
    selectAccountChecklists,
    selectAllConditionsEstimates,
    selectPins,
    selectMobileFlows,
  ],
  (
    segments,
    memberships,
    users,
    flows,
    checklists,
    conditionsEstimates,
    pins,
    mobileFlows
  ) => {
    if (!segments) return null;
    return Object.values(segments).map(
      ({ id, name, updatedAt, createdBy: userId, meta, conditions }) => {
        const creator = users[userId];

        const numberOfExperiences = getNumOfExperiences(
          {
            ...filterExperiences(
              flows,
              rule =>
                rule.contentType === 'journey' ||
                Boolean(pins?.[rule.id]) ||
                Boolean(mobileFlows?.[rule.id])
            ),
            ...checklists,
          },
          id
        );

        let count;
        let basis;
        const useEstimate =
          moment.duration(moment().diff(moment(updatedAt))).asDays() < 7;

        if (useEstimate) {
          const cacheKey = getCacheKey(conditions);
          const { pctQualifiedUsers, weeklyWebsiteUsers } =
            conditionsEstimates?.[cacheKey] ?? {};

          count =
            typeof pctQualifiedUsers === 'number' &&
            typeof weeklyWebsiteUsers === 'number'
              ? Math.round(pctQualifiedUsers * weeklyWebsiteUsers)
              : null;
          basis =
            typeof weeklyWebsiteUsers === 'number' ? weeklyWebsiteUsers : null;
        } else {
          const userCounts = memberships[id] ?? {};
          count = userCounts.count;
          basis = userCounts.basis;
        }
        const percentOfUsers =
          typeof count === 'number' && typeof basis === 'number'
            ? basis
              ? Math.round((count / basis) * 100)
              : 0
            : null;

        return {
          id,
          name,
          updatedAt,
          userLifecycleStage: meta?.userLifecycleStage,
          count,
          basis,
          percentOfUsers,
          numberOfExperiences,
          creator: creator?.meta?.fullname,
          conditions,
          useEstimate,
        };
      }
    );
  }
);

export const selectMaxSegmentBasis = createSelector(
  selectSegmentMemberships,
  memberships => {
    if (Object.values(memberships).length > 0) {
      return Math.max.apply(
        null,
        Object.values(memberships ?? {}).map(({ basis }) => basis)
      );
    }

    return null;
  }
);

export const selectNumberOfMatchingBanners = (state, segmentId) => {
  const banners = selectBanners(state);
  const rules = filterExperiences(selectRulesAndConditions(state), rule => {
    return Boolean(banners?.[rule.id]);
  });
  return getNumOfExperiences(rules, segmentId);
};
export const selectNumberOfMatchingPins = (state, segmentId) => {
  const pins = selectPins(state);
  const rules = filterExperiences(selectRulesAndConditions(state), rule =>
    Boolean(pins?.[rule.id])
  );
  return getNumOfExperiences(rules, segmentId);
};

export const selectNumberOfMatchingFlows = (state, segmentId) => {
  // Remove NPS survey before count
  const rules = filterExperiences(
    selectRulesAndConditions(state),
    rule => rule.contentType === 'journey'
  );
  return getNumOfExperiences(rules, segmentId);
};

export const selectNumberOfMobileFlows = (state, segmentId) => {
  const mobileFlows = selectMobileFlows(state);
  const rules = filterExperiences(selectRulesAndConditions(state), rule =>
    Boolean(mobileFlows?.[rule.id])
  );
  return getNumOfExperiences(rules, segmentId);
};

export const selectNumberOfMatchingChecklists = (state, segmentId) => {
  const checklists = selectAccountChecklists(state);
  return getNumOfExperiences(checklists, segmentId);
};

export const selectNumberOfMatchingSegments = (state, segmentId) => {
  const segments = selectAccountSegments(state);
  return getNumOfExperiences(segments, segmentId);
};

export const selectMatchingPins = (state, segmentId) => {
  const pins = selectPins(state);
  const rules =
    filterExperiences(selectRulesAndConditions(state), rule =>
      Boolean(pins?.[rule.id])
    ) || {};

  const pinsUsingSegment = Object.values(rules).filter(rule =>
    hasMatchingSegment(rule.conditions, segmentId)
  );

  return pinsUsingSegment?.map(({ id }) => pins[id]);
};

export const selectMatchingMobileFlows = (state, segmentId) => {
  const mobileFlows = selectMobileFlows(state);
  const rules =
    filterExperiences(selectRulesAndConditions(state), rule =>
      Boolean(mobileFlows?.[rule.id])
    ) || {};

  const mobileFlowsUsingSegment = Object.values(rules).filter(rule =>
    hasMatchingSegment(rule.conditions, segmentId)
  );

  return mobileFlowsUsingSegment?.map(({ id }) => mobileFlows[id]);
};
