import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Notice, Button } from '@appcues/component-library';
import {
  filterAudienceTargetingClauses,
  getFilteredClauseTree,
  getNestedConditionsFromFlattenedClauses,
  clauseKeys,
  getConditionsFriendlyDescription,
} from '@appcues/libcues';
import { Icon } from '@studio/legacy-components';
import StatusIcon from 'components/Diagnostics/StatusIcon';
import QualifiedSection from 'components/Diagnostics/QualifiedSection';
import { qualifyPartialRule } from 'helpers/api';
import {
  filterABGroupClauses,
  filterDomainTargetingClauses,
  filterUrlTargetingClauses,
} from 'utils/conditions';
import { getFrequencyConditions } from 'transforms/migrations';
import { STEP_TYPES } from 'constants/stepTypes';
import { navigate } from 'actions/routing';
import { reportError } from 'helpers/error-reporting';

const getFriendlyDescriptionFromStatus = content => {
  const userOptOut =
    content.status === 'skipped' || content.status === 'dismissed';

  if (content && userOptOut) {
    return (
      <div>
        <i>Criteria for Status Eligibility</i>
        <ul>
          <li>
            {content.status
              ? `The user ${content.status} this list.`
              : 'The user has not dismissed this checklist.'}
          </li>
        </ul>
      </div>
    );
  }
  return <span>The user has not dismissed this checklist.</span>;
};

const StyledIcon = styled(Icon)`
  color: ${props => props.theme['$gray-20']};
`;

export function QualifiedContentNotice({
  accountId,
  checklist,
  className,
  clauses,
  hideABGroup,
  hideFrequency,
  name,
  navigate: navigateTo,
  qualified,
  rule,
  segments,
  stepId,
  steps,
  url,
  userId,
  checklists,
}) {
  const [showDetails, setShowDetails] = useState(false);

  /**
   * Returns a Promise that can be used by QualifiedSection for a query to the
   * partial rule evaluator on the API
   *
   * @param branchFilter
   * @returns {function(): Promise}
   */

  const getBranchQualifier = branchFilter => {
    const filteredClauses = getFilteredClauseTree(branchFilter, clauses);
    const nestedConditions = getNestedConditionsFromFlattenedClauses(
      filteredClauses,
      null,
      clauseKeys
    );

    return () =>
      qualifyPartialRule(accountId, userId, url, nestedConditions).catch(
        error => reportError(error, { url, clauses, accountId, userId })
      );
  };

  const getFriendlyDescriptionFromClauses = (
    branchFilter,
    section,
    omitTailPropertyNames
  ) => {
    const matchingClauses = getFilteredClauseTree(branchFilter, clauses);
    if (matchingClauses.length > 0) {
      return (
        <div>
          <i>Criteria for {section} Eligibility</i>
          <ul>
            <li
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: getConditionsFriendlyDescription(
                  null,
                  matchingClauses,
                  steps,
                  segments,
                  checklists,
                  omitTailPropertyNames
                ),
              }}
            />
          </ul>
        </div>
      );
    }
    return <span>There is no {section} criteria for viewing this flow.</span>;
  };

  const step = steps[stepId];
  const stepProperties = step ? STEP_TYPES[step.type] || {} : {};

  const targetingLink = checklist
    ? `/checklists/${checklist.id}/edit`
    : `/${stepProperties.contentType}/${stepId}/conditions`;

  return (
    <div>
      <div className={className} role="tab">
        <Notice
          onClick={() => setShowDetails(!showDetails)}
          actions={[
            <StyledIcon
              key="caret"
              icon={`caret-${showDetails ? 'up' : 'down'}`}
            />,
          ]}
        >
          <StatusIcon pending={false} qualified={qualified} />
          {name}
        </Notice>
        {showDetails && <hr className="expandedDivider" />}
        <div className={`details ${showDetails ? 'visible' : 'hidden'}`}>
          <div className="by-section" role="tabpanel">
            <QualifiedSection
              name="Page"
              qualifier={getBranchQualifier(filterUrlTargetingClauses)}
              description={getFriendlyDescriptionFromClauses(
                filterUrlTargetingClauses,
                'Page',
                true
              )}
              hidden={!showDetails}
            />
            <QualifiedSection
              name="Domain"
              qualifier={getBranchQualifier(filterDomainTargetingClauses)}
              description={getFriendlyDescriptionFromClauses(
                filterDomainTargetingClauses,
                'Domain',
                true
              )}
              hidden={!showDetails}
            />
            {!hideFrequency && (
              <QualifiedSection
                name="Frequency"
                qualifier={() =>
                  qualifyPartialRule(
                    accountId,
                    userId,
                    url,
                    getFrequencyConditions(rule.id, rule.frequency)
                  ).catch(error =>
                    reportError(error, { accountId, userId, url })
                  )
                }
                description={(() => {
                  switch (rule.frequency) {
                    case 'once':
                      return `Users are only allowed to see this flow once`;
                    case 'every_time':
                      return `Users will see this flow every time they match all of the criteria`;
                    case 'never':
                    default:
                      return `This flow will never show automatically`;
                  }
                })()}
                hidden={!showDetails}
              />
            )}
            <QualifiedSection
              name="Audience Rules"
              qualifier={getBranchQualifier(filterAudienceTargetingClauses)}
              description={getFriendlyDescriptionFromClauses(
                filterAudienceTargetingClauses,
                'Audience'
              )}
              hidden={!showDetails}
            />
            {!hideABGroup && (
              <QualifiedSection
                name="A/B Group"
                qualifier={getBranchQualifier(filterABGroupClauses)}
                description={getFriendlyDescriptionFromClauses(
                  filterABGroupClauses,
                  'A/B Group'
                )}
                hidden={!showDetails}
              />
            )}
            {checklist && (
              <QualifiedSection
                name="Status"
                qualifier={() =>
                  checklist.status !== 'dismissed' &&
                  checklist.status !== 'skipped'
                }
                description={getFriendlyDescriptionFromStatus(checklist)}
                hidden={!showDetails}
              />
            )}
          </div>
          <Button
            className="button-primary"
            onClick={() => navigateTo(targetingLink)}
          >
            View Targeting
          </Button>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { navigate })(
  styled(QualifiedContentNotice)`
    background-color: ${props => props.theme['$gray-1']};
    border-radius: 0 4px;
    .notice p {
      display: flex;
    }
    hr.expandedDivider {
      margin: -1rem 0 0 0;
      border-top: none;
      border-color: ${props => props.theme['$gray-3']};
    }
    .details {
      margin-left: calc(1rem + 1.75em);
      padding: 1em 0;
      position: relative;
      display: none;
      &.visible {
        display: block;
      }
      .button {
        position: absolute;
        top: 1em;
        right: 1em;
      }
      .by-section {
        .section {
          &:not(:last-child) {
            margin-bottom: 0.75rem;
          }
        }
        .dirty-content {
          font-size: 0.75em;
          font-style: italic;
          color: ${props => props.theme['$gray-50']};
        }
      }
    }
  `
);
