import {
  BANNER_EVENTS,
  CHECKLIST_EVENTS,
  FLOW_EVENTS,
  MOBILE_EVENTS,
  PIN_EVENTS,
  GOAL_EVENTS,
} from './constants';

/**
 * Check if field is a property on the object
 *
 * @param {Object} object - Object to check field
 * @param {string} field - Field to check existence
 * @return {boolean} Whether the field exists on the object
 */
const hasOwnProperty = (object, field) =>
  Object.prototype.hasOwnProperty.call(object, field);

/**
 * Our intention is to chart only "unrecovered" step child errors,
 * but since errors and recoveries are discrete events, we must query
 * for them both and perform the adjustment here in the frontend.
 *
 * @param results
 * @returns {*}
 */

export const removeRecoveredErrors = (results = []) =>
  results
    .filter(({ name }) => name !== 'appcues:step_child_recovered')
    .map(result => {
      const { name, flow_id, events = 0, users = 0 } = result;
      if (name === 'appcues:step_child_error') {
        const { events: recoveryEvents = 0, users: recoveredUsers = 0 } =
          results.find(
            ({ name: recoveryEventName, flow_id: recoveryEventFlowId }) =>
              recoveryEventName === 'appcues:step_child_recovered' &&
              recoveryEventFlowId === flow_id
          ) || {};
        return {
          ...result,
          events: events - recoveryEvents,
          users: users - recoveredUsers,
        };
      }
      return result;
    });

const BUILDER_EVENT_PATTERN = /^appcues_custom:.*$/;
const VALID_CHECKLIST_EVENTS = new Set(
  CHECKLIST_EVENTS.map(({ type }) => type)
);
const VALID_FLOW_EVENTS = new Set(FLOW_EVENTS.map(({ type }) => type));
const VALID_MOBILE_EVENTS = new Set(MOBILE_EVENTS.map(({ type }) => type));
const VALID_PIN_EVENTS = new Set(PIN_EVENTS.map(({ type }) => type));
const VALID_BANNER_EVENTS = new Set(BANNER_EVENTS.map(({ type }) => type));
const VALID_GOALS_EVENTS = new Set(GOAL_EVENTS.map(({ type }) => type));

const VALID_EXPERIENCE_EVENTS = {
  pin: VALID_PIN_EVENTS,
  banner: VALID_BANNER_EVENTS,
};

export const validateSelectedEvents = events => {
  return events.every(event => {
    if (!hasOwnProperty(event, 'event')) return false;

    switch (event.source) {
      case 'application':
        return Object.keys(event).length === 2;
      case 'builder':
        return (
          BUILDER_EVENT_PATTERN.test(event.event) &&
          Object.keys(event).length === 2
        );
      case 'checklist':
        return (
          VALID_CHECKLIST_EVENTS.has(event.event) &&
          hasOwnProperty(event, 'id') &&
          Object.keys(event).length === 3
        );
      case 'flow':
        return (
          VALID_FLOW_EVENTS.has(event.event) &&
          hasOwnProperty(event, 'id') &&
          Object.keys(event).length === 3
        );
      case 'mobile':
        return (
          VALID_MOBILE_EVENTS.has(event.event) &&
          hasOwnProperty(event, 'id') &&
          Object.keys(event).length === 3
        );
      case 'goals':
        return (
          VALID_GOALS_EVENTS.has(event.event) &&
          hasOwnProperty(event, 'id') &&
          Object.keys(event).length === 3
        );
      case 'pin':
      case 'banner':
        return (
          VALID_EXPERIENCE_EVENTS[event.source].has(event.event) &&
          hasOwnProperty(event, 'id') &&
          hasOwnProperty(event, 'stepId') &&
          Object.keys(event).length === 4
        );
      default:
        return false;
    }
  });
};

export function parseEvents(events) {
  return Object.entries(events).reduce((acc, [key, values]) => {
    switch (key) {
      case 'a':
        return [
          ...acc,
          ...values.map(event => ({ source: 'application', event })),
        ];
      case 'b':
        return [
          ...acc,
          ...values.map(event => ({
            source: 'builder',
            event: `appcues_custom:${event}`,
          })),
        ];
      case 'cc':
        return [
          ...acc,
          ...values.map(id => ({
            source: 'checklist',
            event: 'appcues:checklist_completed',
            id,
          })),
        ];
      case 'cs':
        return [
          ...acc,
          ...values.map(id => ({
            source: 'checklist',
            event: 'appcues:checklist_shown',
            id,
          })),
        ];
      case 'csk':
        return [
          ...acc,
          ...values.map(id => ({
            source: 'checklist',
            event: 'appcues:checklist_skipped',
            id,
          })),
        ];
      case 'fc':
        return [
          ...acc,
          ...values.map(id => ({
            source: 'flow',
            event: 'appcues:flow_completed',
            id,
          })),
        ];
      case 'fs':
        return [
          ...acc,
          ...values.map(id => ({
            source: 'flow',
            event: 'appcues:flow_started',
            id,
          })),
        ];
      case 'fe':
        return [
          ...acc,
          ...values.map(id => ({
            source: 'flow',
            event: 'appcues:step_child_error',
            id,
          })),
        ];
      case 'ec':
        return [
          ...acc,
          ...values.map(id => ({
            source: 'mobile',
            event: 'appcues:v2:experience_completed',
            id,
          })),
        ];
      case 'es':
        return [
          ...acc,
          ...values.map(id => ({
            source: 'mobile',
            event: 'appcues:v2:experience_started',
            id,
          })),
        ];
      case 'ei':
        return [
          ...acc,
          ...values.map(id => ({
            source: 'mobile',
            event: 'appcues:v2:step_error',
            id,
          })),
        ];
      case 'ss':
        return [
          ...acc,
          ...values.map(({ id, stepId, source }) => ({
            source,
            event: 'appcues:v2:step_seen',
            id,
            stepId,
          })),
        ];
      case 'si':
        return [
          ...acc,
          ...values.map(({ id, stepId, source }) => ({
            source,
            event: 'appcues:v2:step_interaction',
            id,
            stepId,
          })),
        ];
      case 'ed':
        return [
          ...acc,
          ...values.map(({ id, stepId, source }) => ({
            source,
            event: 'appcues:v2:experience_dismissed',
            id,
            stepId,
          })),
        ];
      case 'gr':
        return [
          ...acc,
          ...values.map(({ id, source }) => ({
            source,
            event: 'appcues:goal_reached',
            id,
          })),
        ];
      default:
        return acc;
    }
  }, []);
}

export function serializeEvents(events) {
  return events.reduce((acc, item) => {
    switch (item.source) {
      case 'application': {
        acc.a = acc.a || [];
        acc.a.push(item.event);
        return acc;
      }

      case 'builder': {
        acc.b = acc.b || [];
        acc.b.push(item.event.replace('appcues_custom:', ''));
        return acc;
      }
      case 'checklist':
      case 'flow': {
        switch (item.event) {
          case 'appcues:checklist_completed': {
            acc.cc = acc.cc || [];
            acc.cc.push(item.id);
            return acc;
          }
          case 'appcues:checklist_shown': {
            acc.cs = acc.cs || [];
            acc.cs.push(item.id);
            return acc;
          }
          case 'appcues:checklist_skipped': {
            acc.csk = acc.csk || [];
            acc.csk.push(item.id);
            return acc;
          }

          case 'appcues:flow_completed': {
            acc.fc = acc.fc || [];
            acc.fc.push(item.id);
            return acc;
          }
          case 'appcues:flow_started': {
            acc.fs = acc.fs || [];
            acc.fs.push(item.id);
            return acc;
          }
          case 'appcues:step_child_error': {
            acc.fe = acc.fe || [];
            acc.fe.push(item.id);
            return acc;
          }
          default:
            return acc;
        }
      }
      case 'mobile': {
        switch (item.event) {
          case 'appcues:v2:experience_completed': {
            acc.ec = acc.ec || [];
            acc.ec.push(item.id);
            return acc;
          }
          case 'appcues:v2:experience_started': {
            acc.es = acc.es || [];
            acc.es.push(item.id);
            return acc;
          }
          case 'appcues:v2:step_error': {
            acc.ei = acc.ei || [];
            acc.ei.push(item.id);
            return acc;
          }
          default:
            return acc;
        }
      }
      case 'goals': {
        acc.gr = acc.gr || [];
        acc.gr.push({ id: item.id, source: item.source });
        return acc;
      }
      case 'pin':
      case 'banner': {
        switch (item.event) {
          case 'appcues:v2:step_seen': {
            acc.ss = acc.ss || [];
            acc.ss.push({
              id: item.id,
              stepId: item?.stepId,
              source: item?.source,
            });
            return acc;
          }
          case 'appcues:v2:step_interaction': {
            acc.si = acc.si || [];

            // Banner only has one step, so we don't need to push another item in the array
            if (item.source === 'banner') {
              acc.si.push({
                id: item.id,
                stepId: item?.stepId,
                source: item?.source,
              });
              return acc;
            }

            acc.si.push(item.id, item?.stepId, item?.source);
            return acc;
          }
          case 'appcues:v2:experience_dismissed': {
            acc.ed = acc.ed || [];
            acc.ed.push({
              id: item.id,
              stepId: item?.stepId,
              source: item?.source,
            });
            return acc;
          }
          default:
            return acc;
        }
      }
      default:
        return acc;
    }
  }, {});
}

/**
 * Given an array of events in the form [{source,event,id}], returns the
 * correctly formatted set of conditions for the analytics-api
 *
 * @param selectedEvents
 * @returns {*[][]}
 */

export const getQueryConditionsForSelectedEvents = selectedEvents => {
  return [
    [
      'or',
      ...selectedEvents.map(({ source, event, ...restProps }) => {
        switch (source) {
          case 'flow':
            return [
              'and',
              [
                'event',
                'in',
                [
                  event,
                  // if any of the selectedEvents are errors, we need to also
                  // select corresponding recoveries for subtraction
                  ...(event === 'appcues:step_child_error'
                    ? ['appcues:step_child_recovered']
                    : []),
                ],
              ],
              ['flow_id', '==', restProps.id],
            ];
          case 'mobile':
            return [
              'and',
              [
                'event',
                'in',
                [
                  event,
                  // if any of the selectedEvents are errors, we need to also
                  // select corresponding recoveries for subtraction
                  ...(event === 'appcues:step_error'
                    ? ['appcues:step_recovered']
                    : []),
                ],
              ],
              ['experience_id', '==', restProps.id],
            ];
          case 'checklist':
            return [
              'and',
              ['event', '==', event],
              ['checklist_id', '==', restProps.id],
            ];
          case 'pin':
            return [
              'and',
              ['event', '==', event],
              ['experience_id', '==', restProps.id],
              ['step_id', '==', restProps.stepId],
              ['experience_type', '==', 'persistent'],
            ];
          case 'banner':
            return [
              'and',
              ['event', '==', event],
              ['experience_id', '==', restProps.id],
              ['step_id', '==', restProps.stepId],
              ['experience_type', '==', source],
            ];
          case 'goals':
            return [
              'and',
              ['event', '==', event],
              ['goal_id', '==', restProps.id],
            ];
          default:
            return ['event', '==', event];
        }
      }),
    ],
  ];
};

export const getEventTotalsFromResults = (
  results,
  { eventId, eventStepId, eventName }
) => {
  const { events, users } =
    (results || []).find(
      ({ name, flow_id, checklist_id, experience_id, step_id, goal_id }) =>
        name === eventName &&
        (flow_id
          ? eventId === flow_id
          : checklist_id
          ? eventId === checklist_id
          : experience_id && step_id
          ? eventId === experience_id && step_id === eventStepId
          : experience_id
          ? eventId === experience_id
          : goal_id
          ? eventId === goal_id
          : true)
    ) || {};
  return { events, users };
};

export const isChecklistEvent = eventName =>
  CHECKLIST_EVENTS.map(({ type }) => type).includes(eventName);

/**
 * Returns a short format for event names to be used in single metrics urls
 * @param {string} eventName - The appcues event name to be shorten
 * @returns a short event name equivalent
 */
export const encodeEventNameUrl = (eventName, source) => {
  const events = [
    ...FLOW_EVENTS,
    ...CHECKLIST_EVENTS,
    ...MOBILE_EVENTS,
    ...GOAL_EVENTS,
  ];

  switch (source) {
    case 'pin': {
      events.push(...PIN_EVENTS);
      break;
    }

    case 'banner': {
      events.push(...BANNER_EVENTS);
      break;
    }

    default:
  }

  const { abbrev = eventName?.replace('appcues_custom:', '') } =
    events.find(event => event.type === eventName) || {};

  return abbrev;
};

/**
 * Transforms the given short event name format to its appcues long format
 * @param {string} source - The event source (flow|checklist|application|builder)
 * @param {string} abbrev - The short event name
 * @returns - The appcues event format
 */
export const decodeEventNameUrl = (source, abbrev) => {
  if (source === 'builder') {
    return `appcues_custom:${abbrev}`;
  }

  const events = [
    ...FLOW_EVENTS,
    ...CHECKLIST_EVENTS,
    ...PIN_EVENTS,
    ...MOBILE_EVENTS,
    ...BANNER_EVENTS,
    ...GOAL_EVENTS,
  ];

  const { type = abbrev } = events.find(item => item.abbrev === abbrev) || {};
  return type;
};
