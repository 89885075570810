import * as actionTypes from 'constants/actionTypes';

const initialState = {
  synced: false,
  integrations: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REPLACE_ACCOUNT_INTEGRATIONS:
      return {
        synced: true,
        integrations: action.payload,
      };
    case actionTypes.ACTIVATE_INTEGRATION_IN_STORE: {
      return {
        ...state,
        integrations: {
          ...state.integrations,
          ...action.payload,
        },
      };
    }
    case actionTypes.REMOVE_INTEGRATION_IN_STORE: {
      const { integrationId } = action.payload;
      const { [integrationId]: omit, ...restIntegrations } = state.integrations;
      return {
        ...state,
        integrations: restIntegrations,
      };
    }
    default:
      return state;
  }
}

export const selectIntegrations = state =>
  state.account.integrations.integrations;
export const selectAreIntegrationsSynced = state =>
  state.account.integrations.synced;
export const selectAccountIntegration = (state, integrationId) =>
  state.account.integrations[integrationId];
