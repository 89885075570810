import { selectFlow } from 'reducers/account/flows';
import { selectAccountChecklist } from 'entities/checklists';
import { selectUserQualifiedContent } from 'entities/user-qualified-content';
import { selectPin, selectMobile } from 'entities/experiences';

export const selectUserQualifiedContentFlat = (state, userId) => {
  const userQualifiedContent = selectUserQualifiedContent(state, userId);
  return userQualifiedContent
    ? [
        ...(userQualifiedContent.flows || []).map(({ id, conditions }) => {
          const flow = selectFlow(state, id);

          return {
            id,
            conditions,
            activity: 'flow',
            name: flow?.name,
          };
        }),
        ...(userQualifiedContent.experiences || []).map(
          ({ id, conditions }) => {
            const experience = selectMobile(state, id);

            return {
              id,
              conditions,
              activity: 'mobile',
              name: experience?.name,
            };
          }
        ),
        ...(userQualifiedContent.experiences || []).map(
          ({ id, conditions }) => {
            const experience = selectPin(state, id);

            return {
              id,
              conditions,
              activity: 'pin',
              name: experience?.name,
            };
          }
        ),
        ...(userQualifiedContent.checklists || []).map(({ id, conditions }) => {
          const checklist = selectAccountChecklist(state, id);
          return {
            id,
            conditions,
            activity: 'checklist',
            name: checklist?.internalName,
          };
        }),
      ]
    : null;
};
