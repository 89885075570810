import React, { useState } from 'react';
import { Button, Icon, Popover, Stack, Text } from '@appcues/sonar';
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import { navigate } from 'next/lib/history';

export default function UserIdentifierHelper() {
  const [open, setOpen] = useState(false);
  const toggleSetOpen = () => setOpen(isOpen => !isOpen);
  return (
    <Popover.Root
      open={open}
      onOpenChange={setOpen}
      trigger={
        <Button onClick={toggleSetOpen} variant="tertiary" iconOnly>
          <Icon icon={faPencil} />
        </Button>
      }
    >
      <Popover.Title>User identifier</Popover.Title>
      <Popover.Description>
        <Stack>
          <Text>
            To select which user property you want to display throughout Appcues
            to quickly identify your users, go to the Accounts & Users settings
            page.
          </Text>
          <Text>
            <Text weight="bold" forwardedAs="span">
              Pro tip:{' '}
            </Text>
            Use a property like &ldquo;Full Name&rdquo; or &ldquo;email&rdquo;
            instead of the generic User ID.
          </Text>
        </Stack>
      </Popover.Description>
      <Popover.Action>
        <Button
          variant="secondary"
          onClick={() => {
            navigate('/settings/identifier');
            setOpen(false);
          }}
        >
          Update user identifier
        </Button>
      </Popover.Action>
      <Popover.Close>
        <Popover.IconClose />
      </Popover.Close>
    </Popover.Root>
  );
}
