import React from 'react';
import styled from 'styled-components';
import { CPanel, H4, P, Flex } from '@appcues/component-library';
import { ExternalLink, Icon } from '@studio/legacy-components';
import { SUPPORT_EMAIL } from 'constants/externalAppcuesResources';
import TermsOfService from 'components/Subscription/TermsOfService';

const IconWrapper = styled.div`
  color: ${props => props.theme['$gray-5']};
  font-size: 32px;
  margin-bottom: 12px;
`;

const InvoiceMessage = () => {
  const invoiceHeader = 'You pay via invoice';

  return (
    <CPanel>
      <CPanel.Header>
        <H4 type="secondary">Billing Details</H4>
      </CPanel.Header>
      <Flex flexDirection="column" justifyContent="center" textAlign="center">
        <IconWrapper>
          <Icon icon="envelope" />
        </IconWrapper>
        <P bold>{invoiceHeader}</P>
        <P>
          If you would like a copy, email us at{' '}
          <ExternalLink href={`mailto:${SUPPORT_EMAIL}`}>
            {SUPPORT_EMAIL}
          </ExternalLink>{' '}
          and we&apos;ll send it your way!
        </P>
        <TermsOfService />
      </Flex>
    </CPanel>
  );
};

export default InvoiceMessage;
