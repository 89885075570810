import React from 'react';
import styled from 'styled-components';
import { conditionNames, getFilteredClauseTree } from '@appcues/libcues';

import { ExternalLink, StatusLabel } from '@studio/legacy-components';
import { UpgradeTag } from 'next/components/UpgradeTag';

import { filterTriggerClauses } from 'utils/conditions';
import Radio, { RadioWrapper } from 'components/Common/UI/Forms/Radio';
import FrequencyTargeting from 'components/Common/FrequencyTargeting';
import { BeaconLink } from 'components/Common/HelpScout';
import ConfigureCustomEventTrigger from './ConfigureCustomEventTrigger';

const StyledConfigureCustomEventTrigger = styled(ConfigureCustomEventTrigger)`
  margin-left: 23px;
`;

const RadioUpgradeTagWrapper = styled.div`
  display: flex;
  align-items: center;
  ${RadioWrapper} {
    margin-top: 0;
  }

  ${StatusLabel} {
    margin-left: 5px;
    margin-bottom: 6px;
  }
`;

export default function EventTriggering({
  addClause,
  clauses,
  deleteMatchingClauses,
  hasEventTriggering,
  hasEventTriggeringWithAttributes,
  rule,
  setStepFrequency,
  stepId,
  updateClause,
}) {
  const getTriggerClause = () => {
    const triggerClauses = getFilteredClauseTree(filterTriggerClauses, clauses);
    return triggerClauses.find(
      clause => clause.conditionName === conditionNames.TRIGGER
    );
  };

  const handleClickPageTrigger = () => {
    deleteMatchingClauses(stepId, filterTriggerClauses);
    setStepFrequency(stepId, 'once');
  };

  const handleClickEventTrigger = () => {
    setStepFrequency(stepId, 'once');
    deleteMatchingClauses(stepId, filterTriggerClauses);
    addClause(stepId, null, {
      conditionName: conditionNames.TRIGGER,
      event: '',
    });
  };

  const handleClickManualTrigger = () => {
    deleteMatchingClauses(stepId, filterTriggerClauses);
    setStepFrequency(stepId, 'never');
  };

  const updateTriggerClause = changes => {
    const triggerClause = getTriggerClause();
    updateClause(stepId, triggerClause.id, changes);
  };

  const triggerClause = getTriggerClause();
  const isManualTrigger = rule.frequency === 'never';
  const isEventTrigger = !isManualTrigger && !!triggerClause;
  const isPageTrigger = !isManualTrigger && !triggerClause;

  const pageTriggered = (
    <Radio
      label="When the user reaches the page"
      onClick={handleClickPageTrigger}
      id="page-trigger-radio"
      checked={isPageTrigger}
    />
  );

  const eventTriggered = (
    <>
      <RadioUpgradeTagWrapper>
        <Radio
          disabled={!hasEventTriggering}
          label="When an event occurs"
          onClick={handleClickEventTrigger}
          id="event-trigger-radio"
          checked={isEventTrigger}
        />

        {!hasEventTriggering && (
          <UpgradeTag
            tooltipContent={
              <>
                <BeaconLink
                  aria-label="Upgrade to trigger Flows when a specific event occurs"
                  subject="Interested in event triggering for Flows"
                  message="I'd like to enable event triggering for Flows."
                >
                  Upgrade
                </BeaconLink>{' '}
                to trigger Flows when a specific event occurs. <br />
                <ExternalLink href="https://docs.appcues.com/article/450-event-triggering">
                  Learn more
                </ExternalLink>
              </>
            }
          />
        )}
      </RadioUpgradeTagWrapper>
      {isEventTrigger && (
        <StyledConfigureCustomEventTrigger
          triggerClause={triggerClause}
          updateTriggerClause={updateTriggerClause}
          hasEventTriggeringWithAttributes={hasEventTriggeringWithAttributes}
        />
      )}
    </>
  );

  const manuallyTriggered = (
    <Radio
      label="Only manually (from permalink, Launchpad, or checklist)"
      onClick={handleClickManualTrigger}
      checked={isManualTrigger}
      id="manual-trigger-radio"
    />
  );

  const eventTriggeredComponents = hasEventTriggering
    ? [pageTriggered, eventTriggered, manuallyTriggered]
    : [pageTriggered, manuallyTriggered, eventTriggered];

  return (
    <div>
      <h4>When should this flow launch?</h4>
      <div className="targeting-container">
        <div className="conditions-container">{eventTriggeredComponents}</div>
      </div>
      {!isManualTrigger && (
        <FrequencyTargeting
          setStepFrequency={setStepFrequency}
          stepId={rule.id}
          stepFrequency={rule.frequency}
          showManualTriggerOption={false}
        />
      )}
    </div>
  );
}
