import React from 'react';
import { CBanner } from '@appcues/component-library';

const FlowStatusBanner = ({ isPublished, isPublishedAndDirty, showBanner }) => {
  if (!isPublished || !showBanner) {
    return null;
  }
  return isPublishedAndDirty ? (
    <CBanner type="warning" marginBottom={16}>
      <CBanner.Content>
        <strong>Heads up!</strong> You&apos;ve made changes, but users
        won&apos;t see them until you hit <i>Push Changes</i>. Or you can
        discard changes via the dropdown.
      </CBanner.Content>
    </CBanner>
  ) : (
    <CBanner type="positive" marginBottom={16}>
      <CBanner.Content>
        <strong>This flow is live!</strong> You can make changes and publish
        them when you are ready.
      </CBanner.Content>
    </CBanner>
  );
};

export default FlowStatusBanner;
