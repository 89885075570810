import React from 'react';
import styled from 'styled-components';
import {
  Panel,
  PanelBody,
  H2,
  ExternalLink,
  Text,
} from '@studio/legacy-components';
import { UpgradeTag } from 'next/components/UpgradeTag';
import { BeaconLink } from 'components/Common/HelpScout';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export default function PrioritizationPrompt() {
  return (
    <Panel>
      <PanelBody>
        <TitleWrapper>
          <H2>Flow priority</H2>
          <UpgradeTag
            tooltipContent={
              <>
                <BeaconLink
                  aria-label="Upgrade to prioritize your Flows and provide the best user experience to your audiences."
                  message="I'd like to enable Flow priority."
                  subject="Interested in Flow priority"
                >
                  Upgrade
                </BeaconLink>{' '}
                to prioritize your Flows and provide the best user experience to
                your audiences.
              </>
            }
          />
        </TitleWrapper>
        <Text secondary>
          If multiple flows qualify to be shown to a user, the one with the
          greatest weight will be shown first.{' '}
          <ExternalLink href="https://docs.appcues.com/article/744-flow-priorities">
            Learn More
          </ExternalLink>
        </Text>
      </PanelBody>
    </Panel>
  );
}
