import styled from 'styled-components';
import { FormField } from '@appcues/sonar';

export const TimeZoneTag = styled.span`
  padding: 4px 8px;
  border-radius: 6px;
  background: #edf2fa;
  width: max-content;
  display: flex;
  gap: 4px;
  align-items: center;
  svg {
    color: #425678;
  }
`;

// Extending FormField
// due to lack of support for side by side inputs with error messages
export const TimeFormField = styled(FormField)`
  min-width: 123px;
  padding-bottom: 0;
`;

export const DateFormField = styled(FormField)`
  padding-bottom: 0;
`;

export const DateContainer = styled.div`
  padding-left: 44px;
`;
