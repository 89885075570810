import React from 'react';

import { Button } from '@appcues/component-library';
import SatisfactionSurveyCard from 'components/SatisfactionSurveys/SatisfactionSurveyCard';

export default function ContentSummary({
  questionOne,
  questionTwo,
  updateSatisfaction,
}) {
  return (
    <SatisfactionSurveyCard
      disableHover
      className="satisfaction-form-card"
      header="Content"
    >
      <div className="satisfaction-card-top">
        <hr />
        <p>
          <b>Survey:</b> {questionOne}
        </p>
        <p>
          <b>Feedback:</b> {questionTwo}
        </p>
        <Button
          onClick={() => {
            updateSatisfaction({ currentStep: 1, isTestMode: false });
          }}
          className="button"
        >
          Edit
        </Button>
      </div>
    </SatisfactionSurveyCard>
  );
}
