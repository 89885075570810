/**
 * Returns a string of any integer with commas to separate
 * at every thousandth interval
 *
 * @param number
 * @returns {string}
 */

export const numberWithCommas = number => {
  return Number(number || 0).toLocaleString('en');
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const numberWithAbbrevMillions = number => {
  if (number > 1000000) {
    const numOfMils = number / 1000000;
    return `${Math.floor(numOfMils * 100) / 100}M`;
  }
  return numberWithCommas(number);
};
