export const METRIC_TYPE = {
  NUMBER: 'number',
  DATE: 'date',
  UNKNOWN: 'unknown',
};

export function formatMetricValue(
  metricValue,
  metricType = METRIC_TYPE.UNKNOWN
) {
  switch (metricType) {
    case METRIC_TYPE.NUMBER:
      return Number.parseInt(metricValue, 10).toLocaleString('en');
    case METRIC_TYPE.DATE:
      return metricValue;
    default:
      return metricValue;
  }
}
