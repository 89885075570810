import { fork } from 'redux-saga/effects';

/**
 * NOTE: These imports DO NOT go through the index.js for each entity
 *
 *       THIS IS INTENTIONAL!
 *
 *       The reducers ARE NOT a public API unlike actions and selectors which
 *       ARE appropriate to import in components.
 */
/* eslint-disable no-restricted-imports */
import trialStatus from 'next/entities/trial-status/saga';
import experienceVersions from 'next/entities/experience-versions/saga';
import account from 'next/entities/account/saga';
import accounts from 'next/entities/accounts/saga';
import accountUsers from 'next/entities/account-users/saga';
import apps from 'next/entities/apps/saga';
import checklists from 'next/entities/checklists/saga';
import contentStatus from 'next/entities/content-status/saga';
import eventNameLabels from 'next/entities/event-name-labels/saga';
import experiences from 'next/entities/experiences/saga';
import schedules from 'next/entities/schedules/saga';
import screenshots from 'next/entities/screenshots/saga';
import flows from 'next/entities/flows/saga';
import goals from 'next/entities/goals/saga';
import installed from 'next/entities/installed/saga';
import pins from 'next/entities/pins/saga';
import profileAttributeLabels from 'next/entities/profile-attribute-labels/saga';
import rules from 'next/entities/rules/saga';
import publishedRules from 'next/entities/published-rules/saga';
import segments from 'next/entities/segments/saga';
import spoofing from 'next/entities/spoofing/saga';
import surveys from 'next/entities/surveys/saga';
import eventUserProfiles from 'next/entities/event-user-profiles/saga';
import tags from 'next/entities/tags/saga';
import themes from 'next/entities/themes/saga';
import user from 'next/entities/user/saga';
import users from 'next/entities/users/saga';
import versionedExperiences from 'next/entities/versioned-experiences/saga';
import analytics from 'next/entities/analytics/saga';
import identifier from 'next/root/identifier';
import locales from 'next/entities/locales/saga';
import targeting from 'next/entities/targeting/saga';
import translations from 'next/entities/translations/saga';
import suggestions from 'next/entities/suggestions/saga';
import entitlements from 'next/entities/entitlements/saga';
/* eslint-enable no-restricted-imports */

export default function* saga() {
  yield fork(account);
  yield fork(accounts);
  yield fork(accountUsers);
  yield fork(trialStatus);
  yield fork(analytics);
  yield fork(apps);
  yield fork(checklists);
  yield fork(contentStatus);
  yield fork(experiences);
  yield fork(experienceVersions);
  yield fork(eventNameLabels);
  yield fork(screenshots);
  yield fork(flows);
  yield fork(goals);
  yield fork(installed);
  yield fork(pins);
  yield fork(profileAttributeLabels);
  yield fork(rules);
  yield fork(publishedRules);
  yield fork(segments);
  yield fork(spoofing);
  yield fork(suggestions);
  yield fork(surveys);
  yield fork(tags);
  yield fork(themes);
  yield fork(user);
  yield fork(users);
  yield fork(versionedExperiences);
  yield fork(identifier);
  yield fork(schedules);
  yield fork(locales);
  yield fork(targeting);
  yield fork(translations);
  yield fork(eventUserProfiles);
  yield fork(entitlements);
}
