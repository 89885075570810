import React from 'react';
import styled, { withTheme } from 'styled-components';
import { VictoryPie } from 'victory';
import { Box } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';
import UpdatedTable from 'components/Common/UpdatedTable';
import OpenInBuilderButton from 'components/OpenInBuilder/OpenInBuilderButton';

// 0 is falsy, but a valid value to show!
export const valueOrNotAvailable = val => {
  if (val != null && typeof val !== 'string' && !Number.isNaN(val)) {
    return `${val}`;
  }
  if (typeof val === 'undefined' || Number.isNaN(val)) {
    return '--';
  }
  return 'N/A';
};

const renderChecklistRow =
  (checklistItems, checklistAnalytics, theme) => (itemId, index) => {
    const {
      data: { checklistShown: totalSeen, items },
    } = checklistAnalytics;
    const itemStats = (items && items[itemId]) || {};
    const {
      checklistItemStarted: itemStarted,
      checklistItemCompleted: itemCompleted,
    } = itemStats;
    const percentClicked =
      totalSeen !== 0 ? Math.round((itemStarted / totalSeen) * 100) : 0;
    const percentCompleted =
      totalSeen !== 0 ? Math.round((itemCompleted / totalSeen) * 100) : 0;
    const hasFlowAction =
      checklistItems[itemId].actions &&
      checklistItems[itemId].actions.find(
        action => action.attributes.actionType === 'show_flow'
      );

    const hasFlowChosen =
      hasFlowAction &&
      hasFlowAction.attributes &&
      hasFlowAction.attributes.params &&
      hasFlowAction.attributes.params.flowId;

    return (
      <tr key={itemId}>
        <TableContent>{`Item ${index + 1}`}</TableContent>
        <TableContent>{checklistItems[itemId].label}</TableContent>
        <TableContent>
          {' '}
          {hasFlowChosen ? (
            <OpenInBuilderButton
              stepGroupId={hasFlowAction.attributes.params.flowId}
              render={handleClick => (
                <FlowLink onClick={handleClick}>
                  <Icon icon="external-link-alt" />
                </FlowLink>
              )}
            />
          ) : (
            'N/A'
          )}
        </TableContent>
        <RightAlignedCell>{valueOrNotAvailable(itemStarted)}</RightAlignedCell>
        <TableContent>
          <PieChartContainer>
            <span>{`${valueOrNotAvailable(percentClicked)}%`}</span>
            <PieChartWrapper>
              <VictoryPie
                colorScale={[theme.$purple, theme['$gray-20']]}
                labels={() => null}
                data={[{ y: percentClicked }, { y: 100 - percentClicked }]}
              />
            </PieChartWrapper>
          </PieChartContainer>
        </TableContent>
        <RightAlignedCell>
          {valueOrNotAvailable(itemCompleted)}
        </RightAlignedCell>
        <TableContent>
          <PieChartContainer>
            <span>{`${valueOrNotAvailable(percentCompleted)}%`}</span>
            <PieChartWrapper>
              <VictoryPie
                colorScale={[theme.$purple, theme['$gray-20']]}
                labels={() => null}
                data={[{ y: percentCompleted }, { y: 100 - percentCompleted }]}
              />
            </PieChartWrapper>
          </PieChartContainer>
        </TableContent>
      </tr>
    );
  };

const StyledTable = styled(UpdatedTable)`
  tr {
    border: none;
  }
`;

const StepAnalysisSection = props => {
  const {
    checklist: { items },
    checklistAnalytics,
    theme,
  } = props;

  // Sort by index so that analytics items appear in the correct order
  const checklistItemsSorted = Object.values(items || {})
    .sort((a, b) => {
      return a.index > b.index ? 1 : -1;
    })
    .map(i => i.id);

  const headers = [
    { text: '', align: 'left' },
    { text: 'Label', align: 'left' },
    { text: 'Flow', align: 'left' },
    { text: 'Clicked', align: 'right' },
    { text: '% Clicked', align: 'right' },
    { text: 'Completed', align: 'right' },
    { text: 'Completion Rate', align: 'right' },
  ];

  return (
    <Box width="100%">
      <StyledTable
        headers={headers}
        rowData={checklistItemsSorted}
        renderRow={renderChecklistRow(items, checklistAnalytics, theme)}
      />
    </Box>
  );
};

const PieChartContainer = styled.div`
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
`;

const PieChartWrapper = styled.div`
  height: 20px;
  margin-left: 0.5rem;
  width: 20px;
`;

const TableContent = styled.td`
  padding: 1rem 0;
`;

const RightAlignedCell = styled.td`
  text-align: right;
`;

const FlowLink = styled.a`
  cursor: pointer;
  color: ${props => props.theme['$gray-50']};
`;

export default withTheme(StepAnalysisSection);
