import decode from 'jwt-decode';

import { createApiClient } from 'next/lib/clients/api';
import { getFeatureFlags } from 'next/lib/clients/features';

// eslint-disable-next-line no-restricted-imports
import authClient from 'helpers/auth-client';

import { callPromiseWithRetry } from './lib';

/**
 * Determine if this is the initial login for the user by checking the JWT
 * claim for first login.
 *
 * @param {string} jwt - JSON web Token
 * @return {boolean} Whether this is the first login ever
 */
export const initial = jwt => {
  const { claims } = decode(jwt);
  return Boolean(claims?.first_login);
};

/**
 * TODO: Consider parsing the bootstrapped entities based on their allowed
 *       shapes to ensure we only store the fields used by the application
 */

/**
 * Studio initialization. Ensures that mission critical data is available before
 * any rendering begins. Specifically:
 *
 * 1. Checks that an authenticated user has logged in
 * 2. Request metadata for account and authenticated user
 * 3. Fetch all feature flags
 *
 * It will then return those values as part of the bootstrapping payload for the
 * rest of the application to use during startup.
 *
 * @return {object} Bootstrapping payload
 */
export default async function initialize() {
  // Login to fetch authenticated user
  const jwt = await authClient.getToken();
  const userId = await authClient.getUserId();

  // Whether user is currently spoofing
  const spoofing = await authClient.isSpoofing();

  // Whether this is the first ever login from the user
  const first = initial(jwt);

  // Create API client from auth
  const { getAccount, getAccounts, getUser } = createApiClient();

  // Generate list of initial requests to make
  const requests = [
    getAccount(),
    callPromiseWithRetry(getUser, [userId], 3, 500),
    // Only request for accounts for first ever login
    ...(first ? [getAccounts()] : []),
  ];

  // Fetch account, user meta, and maybe accounts
  const [account, user, { accounts } = {}] = await Promise.all(requests);

  // Redirect to account switcher if first login and has multiple accounts
  const redirectToAccSwitcher = first && Object.keys(accounts).length > 1;

  const isSignedUp = user?.signedUp ?? false;

  // Fetch feature flags
  const features = await getFeatureFlags(account);

  // Return bootstrapped payload
  return {
    account,
    features,
    spoofing,
    user,
    redirectToAccSwitcher,
    isSignedUp,
  };
}
