import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { faChartColumn } from '@fortawesome/pro-solid-svg-icons';

import {
  CPage,
  CPanel,
  H2,
  H3,
  H4,
  Caption,
  CSkeleton,
} from '@appcues/component-library';
import { getFlattenedClausesFromNestedConditions } from '@appcues/libcues';
import { Button, Icon } from '@appcues/sonar';

import { GOALS_EVENTS_EXPLORER } from 'next/entities/features';
import { DocumentTitle } from 'next/hooks/use-title';
import {
  selectAccountSegments,
  selectAccountSegmentsSynced,
} from 'reducers/account/segments';
import {
  selectAreFlowsSynced,
  selectFlows,
  selectStepGroups,
} from 'reducers/account/flows';
import {
  selectGoalReachedUserSample,
  selectGoalsFirstReachedThisWeek,
} from 'reducers/analytics';
import {
  fetchGoalReachedUserSample,
  fetchGoalsFirstReachedThisWeek,
} from 'actions/analytics';
import {
  selectAccountGoals,
  selectAccountGoalsSynced,
} from 'reducers/account/goals';
import { selectAccountFeature } from 'reducers/account/features';
import { selectAccountChecklists } from 'entities/checklists';

import ChildPage from 'components/Common/ChildPage';
import Loader from 'components/Common/Loader';
import UserSampleTable from 'components/Common/UserSampleTable';
import HumanReadableCriteria from 'components/Common/HumanReadableCriteria';
import PercentOfUsersDisplay from 'components/Common/PercentOfUsersDisplay';
import { GOAL_SINGLE_METRIC_URL } from 'components/Insights/Explorer/Sources/GoalRow';
import { numberWithCommas } from 'utils/textFormatting';
import FlowsUsingGoal from './FlowsUsingGoal';

class GoalsShow extends Component {
  componentDidMount() {
    const { goalId, actions } = this.props;

    actions.fetchGoalsFirstReachedThisWeek();
    actions.fetchGoalReachedUserSample(goalId);
  }

  render() {
    const {
      loaded,
      selectedGoal,
      steps,
      segments,
      goalsFirstReachedThisWeek,
      userSample,
      goalId,
      checklists,
      hasGoalsExplorer,
    } = this.props;

    if (!loaded) {
      return <Loader />;
    }

    const clauses =
      selectedGoal &&
      getFlattenedClausesFromNestedConditions(selectedGoal.conditions, null);

    const goalsFirstReachedThisWeekSynced =
      goalsFirstReachedThisWeek && goalsFirstReachedThisWeek.meta.synced;

    let numGoalFirstReachedThisWeek = 0;
    let numActiveUsers = 0;
    let pctFirstAchievers = 0;

    if (goalsFirstReachedThisWeekSynced) {
      const goalReachedStats = (goalsFirstReachedThisWeek.data || []).find(
        goalStats => goalStats.goal_id === selectedGoal.id
      );

      if (goalReachedStats) {
        numGoalFirstReachedThisWeek = goalReachedStats.num_first_achievers;
        numActiveUsers = goalReachedStats.num_active_users;
        pctFirstAchievers = goalReachedStats.pct_first_achievers;
      }
    }

    const goalFirstReachedThisWeek = goalsFirstReachedThisWeekSynced ? (
      numberWithCommas(numGoalFirstReachedThisWeek)
    ) : (
      <CSkeleton width="1.5em" height="1.5em" marginBottom="0.25em" />
    );

    return (
      <ChildPage
        title={selectedGoal.name}
        breadcrumbContents={{
          label: 'Goals',
          path: '/goals',
          child: {
            label: 'View',
          },
        }}
        subHeaderActions={
          hasGoalsExplorer ? (
            <Button
              variant="secondary"
              forwardedAs={Link}
              to={`${GOAL_SINGLE_METRIC_URL}?id=${goalId}`}
              style={{ textDecoration: 'none' }}
            >
              <Icon icon={faChartColumn} />
              View analytics
            </Button>
          ) : null
        }
      >
        <DocumentTitle title={`${selectedGoal.name} | Goal | Appcues`} />
        <CPage.Container>
          <CPanel>
            <H4 type="tertiary" marginBottom={16}>
              Conditions
            </H4>

            <p>
              <strong>Users for whom...</strong>
            </p>
            <HumanReadableCriteria
              clauses={clauses}
              steps={steps}
              segments={segments}
              checklists={checklists}
            />
          </CPanel>
          <CPanel marginTop={16} marginBottom={16}>
            <H4 type="tertiary">USER COUNT</H4>
            <H2 type="primary" marginTop={5}>
              {goalFirstReachedThisWeek}
            </H2>
            <Caption type="tertiary">
              <PercentOfUsersDisplay
                userPercentage={pctFirstAchievers}
                totalUsers={numActiveUsers}
                loaded={!!goalsFirstReachedThisWeekSynced}
              />
            </Caption>
          </CPanel>
          <UserSampleTable
            userSample={userSample}
            title={<H4 type="tertiary">10 MOST RECENT USERS</H4>}
            timestampHeader="Goal Achieved"
            emptyStateText="No users seen yet"
            linkToAllUsers={
              hasGoalsExplorer ? `${GOAL_SINGLE_METRIC_URL}?id=${goalId}` : ''
            }
          />

          <H3 marginTop={48} marginBottom={16}>
            Flows using this goal
          </H3>
          <StyledFlowListContainer>
            <FlowsUsingGoal goalId={goalId} key={goalId} />
          </StyledFlowListContainer>
        </CPage.Container>
      </ChildPage>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { goalId } = ownProps.match.params;
  return {
    loaded:
      selectAreFlowsSynced(state) &&
      selectAccountGoalsSynced(state) &&
      selectAccountSegmentsSynced(state),
    goalId,
    allGoals: selectAccountGoals(state),
    selectedGoal: selectAccountGoals(state)[goalId],
    steps: { ...selectStepGroups(state), ...selectFlows(state) },
    segments: selectAccountSegments(state),
    goalsFirstReachedThisWeek: selectGoalsFirstReachedThisWeek(state),
    userSample: selectGoalReachedUserSample(state, goalId),
    checklists: selectAccountChecklists(state),
    hasGoalsExplorer: selectAccountFeature(state, GOALS_EVENTS_EXPLORER),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { fetchGoalsFirstReachedThisWeek, fetchGoalReachedUserSample },
      dispatch
    ),
  };
}

const StyledFlowListContainer = styled.div`
  width: 100%;
  > div {
    margin-bottom: 16px;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(GoalsShow);
