import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import useToggle from 'next/hooks/use-toggle';
import EndUserProfiles from 'next/components/EndUserProfiles/EndUserProfiles';
import {
  selectEndUserProfiles,
  callApi as fetchEndUserProfiles,
} from 'entities/user-profiles';
import { asPromised } from 'utils/as-promised';

export function EndUserProfilesProvider({
  onLoad,
  endUserProfiles,
  identifier,
}) {
  const [loading, toggleLoading] = useToggle();
  useEffect(() => {
    toggleLoading();
    (async () => {
      await onLoad();
      toggleLoading();
    })();
    return () => toggleLoading();
  }, [onLoad]);

  return (
    <EndUserProfiles
      users={endUserProfiles}
      identifier={identifier}
      loading={loading}
    />
  );
}

const mapStateToProps = state => ({
  endUserProfiles: selectEndUserProfiles(state),
});

const mapDispatchToProps = (dispatch, { identifier }) => ({
  onLoad: () =>
    asPromised(
      dispatch,
      fetchEndUserProfiles({
        propertyNames: identifier
          ? ['_lastSeenAt', 'userId', 'avatarUrl', identifier]
          : ['_lastSeenAt', 'userId', 'avatarUrl'],
        sortKey: '_lastSeenAt',
        direction: 'DESC',
        limit: 5,
      })
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EndUserProfilesProvider);
