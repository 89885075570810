import { createCollectionReducer } from 'next/lib/collections';

import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

export const selectSchedules = state => state[TYPE] ?? [];
export const selectHasSchedule = state =>
  Object.keys(state[TYPE] ?? []).length > 0;

// Currently we will only support one schedule per experience, so we can just
// return the first schedule in the collection.
// when creating a schedule we will remove all existing schedules, so we can
// assume that there will only be one schedule in the collection.
export const selectSchedule = state =>
  selectHasSchedule(state) ? Object.values(state[TYPE])[0] : null;
