/* global APPCUES_API_URL */
import { takeEvery, getContext } from 'redux-saga/effects';
import { HIT_PUBLISH_HOOK } from 'constants/actionTypes';
import { patterns as segmentPatterns } from 'actions/account/segments';
import { patterns as goalPatterns } from 'actions/account/goals';
import { UPDATE_ACCOUNT_META_SUCCESS } from 'constants/account/meta';

export function* hitPublishHook() {
  const auth = yield getContext('auth');
  const token = yield auth.getToken();
  const id = yield auth.getAccountId();
  const response = yield fetch(`${APPCUES_API_URL}/v1/accounts/${id}/publish`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
}

export default function* saga() {
  yield takeEvery(
    [
      HIT_PUBLISH_HOOK,
      segmentPatterns.insert,
      segmentPatterns.replace,
      segmentPatterns.remove,
      goalPatterns.insert,
      goalPatterns.replace,
      goalPatterns.remove,
      UPDATE_ACCOUNT_META_SUCCESS,
    ],
    hitPublishHook
  );
}
