import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { promisaga } from 'utils/as-promised';
import { resolve, reject, patterns } from './actions';

const resolveDataFormat = response => {
  return response?.data?.data ?? response?.data;
};

function* fetchGroupsProfileProperties({ payload: { groupId, searchString } }) {
  try {
    const api = yield getContext('api');
    const response = yield call(
      api.getGroupProfileProperties,
      groupId.toString(),
      searchString
    );

    const data = resolveDataFormat(response);
    yield put(resolve({ properties: data.groupProfile?.properties || [] }));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  yield takeEvery(patterns.callApi, promisaga(fetchGroupsProfileProperties));
}
