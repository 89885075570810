import normalize from 'normalize-url';
import HttpError from './exceptions/http';

/**
 * Create an authenticated fetch API client for a resource
 *
 * @param {string} url - Base URL for the client
 * @param {object} auth - Auth client instance
 * @param {string} auth.getAccountId - Account ID
 * @param {string} auth.getToken - Account JWT
 * @return {function} Authenticated fetch API client
 */
export function createFetch(url, { getAccountId, getToken }) {
  /**
   * Create authenticated fetch client for provided base URL
   *
   * @param {string} url - Base URL of resource
   * @param {object} options - Fetch options
   * @param {boolean} options.account - Whether to account scope the request
   * @param {string} options.responseType - Response type like text, json or blob
   * @param {object} [options.body] - Request body
   * @return {object} JSON parsed response body
   */
  return async (
    path,
    { account = true, jwt = true, body, responseType, ...options } = {}
  ) => {
    // If the request is account scoped, then automatically append the account root
    // to the request URL. Otherwise only use the base URL and provided path
    const base = account ? `${url}/accounts/${await getAccountId()}` : url;

    // Normalize URL
    const normalized = normalize(`${base}/${path ?? ''}`);

    // Make authenticated HTTP request
    const response = await fetch(normalized, {
      ...options,

      // Automatically stringify the request body
      ...(body != null ? { body: JSON.stringify(body) } : {}),
      ...(options.form && { body: options.form }),

      // Append the JSON content type and authorization headers
      headers: {
        ...(!options.form && { 'Content-Type': 'application/json' }),
        ...options.headers,
        ...(jwt && { Authorization: `Bearer ${await getToken()}` }),
      },
    });

    // Throw HTTP error for failed responses
    if (!response.ok) {
      const data = await response.json();
      throw new HttpError({ ...response, data });
    }

    switch (responseType) {
      case 'text':
        return response.text();
      case 'blob':
        return response.blob();
      case 'json':
      default:
        return response.json();
    }
  };
}
