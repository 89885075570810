import qs from 'qs';
import { domain } from 'utils/opscues';
import HTTPError from 'errors/HTTPError';

const createAccountsClient = auth => {
  /* global APPCUES_ENV */
  const baseUrl = `https://${domain({
    app: 'accounts-service',
    env: APPCUES_ENV,
    facet: 'main',
  })}`;

  /**
   * accounts api request
   *
   * @params {string} path - endpoint path
   * @params {object} query - query params
   * @params {object} body - request body
   * @returns {Promise<object>} response object
   * @throws {HTTPError}
   */
  const request = async ({ path, query = {}, body }) => {
    const url = `${baseUrl}/${path}?${qs.stringify(query)}`;
    const jwt = await auth.getToken();

    const response = await fetch(url, {
      method: body ? 'POST' : 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      ...(body && { body: JSON.stringify(body) }),
    });

    if (!response.ok) {
      throw new HTTPError(response);
    }

    return response.json();
  };

  return {
    exists: ({ email }) =>
      request({ path: 'user/accounts', query: { email } })
        .then(() => true)
        .catch(error => {
          if (error.response && error.response.status === 404) {
            return false;
          }
          throw error;
        }),
    inviteUser: ({ email, name, role, inviter }) =>
      auth.getAccountId().then(account_id =>
        request({
          path: 'user/invite',
          body: { account_id, email, name, role, inviter },
        }).then(({ user }) => user)
      ),
    addUser: ({ email, role, inviterUid }) =>
      auth.getAccountId().then(account_id =>
        request({
          path: 'user/add',
          body: { account_id, user_id: inviterUid, email, role },
        }).then(({ success }) => success)
      ),
    acceptTransfer: ({ accountId, userId }) =>
      request({
        path: 'transfer/accept',
        body: { account_id: accountId, user_id: userId },
      }).then(({ success }) => success),
    create: () =>
      request({
        path: `user/accounts/create`,
        body: {},
      }).then(({ accountId }) => accountId),
    approveAdd: () =>
      auth
        .getAccountId()
        .then(account_id =>
          request({ path: 'user/add/approve', body: { account_id } })
        )
        .then(({ success }) => success),
    updateUserRole: ({ userId, role }) =>
      auth.getAccountId().then(accountId =>
        request({
          path: 'user/accounts/update',
          body: {
            account_id: accountId,
            user_id: userId,
            role,
          },
        })
      ),
  };
};

export default createAccountsClient;
