import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { object, string, array } from 'yup';
import isEmpty from 'lodash.isempty';

import { Button, ButtonGroup, Form } from '@studio/legacy-components';
import { FormikShape } from 'next/lib/shapes';
import {
  AppField,
  BuildUrlField,
  NameField,
  TagsField,
  TypeOptions,
  TemplatesField,
} from 'next/components/CreateExperienceForms';
import { MOBILE_DEFAULT_TYPE } from 'next/entities/experiences';
import { WEB_APP, IONIC } from 'next/entities/apps';

/* eslint-disable unicorn/no-thenable */
const VALIDATION_SCHEMA = object().shape({
  appId: string().required('Choose an installed app from the list'),
  name: string().required('Name is required'),
  previewUrl: string().when('appId', {
    is: WEB_APP,
    then: schema =>
      schema.required('Build URL is required').url('Must be a valid URL'),
  }),
  template: string().when('appId', {
    is: WEB_APP,
    then: schema => schema,
  }),
  tagIds: array().of(string()),
});
/* eslint-enable unicorn/no-thenable */

export const CreateExperienceForm = ({
  errors = {},
  handleBlur,
  handleChange,
  onClose,
  onSubmit,
  setFieldValue,
  setFieldTouched,
  touched = {},
  values = {},
  disabled,
  hasMobile,
}) => {
  const [platform, setPlatform] = useState('');
  const isValid = !isEmpty(touched) && isEmpty(errors);
  const isWebApp = values.appId === WEB_APP;
  const isIonic = platform === IONIC;

  return (
    <Form disabled={disabled} onSubmit={e => onSubmit(e, values)}>
      {hasMobile && (
        <>
          <AppField
            value={values.appId}
            error={errors.appId}
            onBlur={handleBlur}
            onChange={({ value: appId, platform: platformValue }) => {
              setFieldTouched('appId');
              setFieldValue('appId', appId);
              setPlatform(platformValue);

              // We need to reset the type when app is Ionic
              // so it doesn't keep the previous type if already chosen
              if (platformValue === IONIC) {
                setFieldValue('type', MOBILE_DEFAULT_TYPE);
              }
            }}
          />

          {values.appId && !isWebApp && !isIonic && (
            <TypeOptions
              type={values.type || MOBILE_DEFAULT_TYPE}
              onClick={type => {
                setFieldTouched('type');
                setFieldValue('type', type);
              }}
            />
          )}
        </>
      )}
      <NameField
        error={errors.name}
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched.name}
        label="Flow name"
      />
      {isWebApp && (
        <>
          <BuildUrlField
            error={errors.previewUrl}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched.previewUrl}
          />
          <TemplatesField
            value={values.template}
            onChange={({ value: template }) => {
              setFieldTouched('template');
              setFieldValue('template', template);
            }}
          />
        </>
      )}
      <TagsField
        value={values.tagIds}
        onChange={tagIds => setFieldValue('tagIds', tagIds)}
      />
      <ButtonGroup right>
        <Button kind="primary" type="submit" disabled={disabled || !isValid}>
          Create
        </Button>
        <Button kind="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ButtonGroup>
    </Form>
  );
};

const formShape = PropTypes.shape({
  appId: PropTypes.string,
  name: PropTypes.string,
  previewUrl: PropTypes.string,
  template: PropTypes.string,
  theme: PropTypes.string,
  tagIds: PropTypes.arrayOf(PropTypes.string),
});

CreateExperienceForm.propTypes = {
  ...FormikShape,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  values: formShape,
  hasMobile: PropTypes.bool,
};

const enhanceForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ hasMobile }) => ({
    appId: hasMobile ? '' : WEB_APP,
    ...(hasMobile && { type: '' }),
    name: '',
    previewUrl: '',
    template: '',
    tagIds: [],
  }),
  validationSchema: VALIDATION_SCHEMA,
});

export default enhanceForm(CreateExperienceForm);
