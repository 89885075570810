import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, Caption, ExternalLink, Link } from '@studio/legacy-components';

import { YEARLY_INTERVAL } from 'constants/plans';
import { ENTITLEMENTS } from 'constants/features';
import { PLANS_AND_PACKAGES_PRICING_URL } from 'constants/entitlements';
import PlanBasicInfo from 'components/SubscriptionPicker/PlanBasicInfo';
import {
  selectAccountMeta,
  selectAccountMetaSynced,
} from 'reducers/account/meta';
import { selectAccountFeature } from 'reducers/account/features';
import { buildQueryParams, calculatePlanPrice } from 'utils/billing';
import { Plan as PlanShape } from 'components/Subscription/shape';

import { PlanWrapper, MausBase, StyledButton } from './styled';

const SALES_PURCHASE_MODAL_APPCUES_FLOW_ID = '-LerJ1l3alV9we_zskMj';

export const Plan = ({
  accountMeta,
  hasEntitlements,
  index,
  isSynced,
  isViewMode = false,
  plan,
  planLimit,
  selfService,
}) => {
  const isHighVolumePlan = planLimit === 'high-volume';

  const upgradeDisabled = useMemo(() => {
    return isViewMode || !accountMeta.stripeId;
  }, [isViewMode, accountMeta.stripeId]);

  const price = useMemo(() => {
    return calculatePlanPrice({
      planInterval: YEARLY_INTERVAL,
      plan,
      planLimit,
    });
  }, [calculatePlanPrice, planLimit]);

  if (!isSynced) return null;

  return (
    <PlanWrapper key={`${plan.id}-${index}`} className="planWrapper">
      <PlanBasicInfo
        isViewMode={isViewMode}
        plan={plan}
        planLimit={planLimit}
        price={price}
        selfService={selfService}
      />

      <ExternalLink href={PLANS_AND_PACKAGES_PRICING_URL} icon={false}>
        View full feature comparison
      </ExternalLink>

      {selfService && !isHighVolumePlan ? (
        <StyledButton
          disabled={upgradeDisabled}
          kind="positive"
          forwardedAs={Link}
          to={
            hasEntitlements
              ? `/subscription/upgrade?${buildQueryParams({
                  id: plan.id,
                  planLimit,
                  planInterval: YEARLY_INTERVAL,
                })}`
              : `/subscription/${plan.id}?${buildQueryParams({
                  id: plan.id,
                  planLimit,
                  planInterval: YEARLY_INTERVAL,
                })}`
          }
        >
          {isViewMode ? 'Current plan' : 'Upgrade'}
        </StyledButton>
      ) : (
        <Button
          kind="positive"
          onClick={() =>
            window.Appcues?.show(SALES_PURCHASE_MODAL_APPCUES_FLOW_ID)
          }
        >
          Contact Sales
        </Button>
      )}
      {isViewMode && selfService && (
        <MausBase>
          <Caption>*based on {planLimit} MAUs</Caption>
        </MausBase>
      )}
    </PlanWrapper>
  );
};

Plan.propTypes = {
  accountMeta: PropTypes.shape({
    stripeId: PropTypes.string,
  }),
  index: PropTypes.number,
  isViewMode: PropTypes.bool,
  isSynced: PropTypes.bool,
  plan: PlanShape,
  planLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selfService: PropTypes.bool,
};

const mapStateToProps = state => ({
  accountMeta: selectAccountMeta(state),
  hasEntitlements: selectAccountFeature(state, ENTITLEMENTS),
  isSynced: selectAccountMetaSynced(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Plan);
