import { createEntityReducer } from 'next/lib/collections';
import { selectRequestStatus } from 'next/entities/requests';
import { TYPE } from './actions';

export default createEntityReducer(TYPE);

export const selectUser = state => state[TYPE];

export const readUser = (state, id) => ({
  ...selectRequestStatus(state, TYPE, id),
  data: selectUser(state),
});
