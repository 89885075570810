import React from 'react';
import styled from 'styled-components';
import { Spinner, Td } from '@studio/legacy-components';

export const Loading = styled.div.attrs({
  children: <Spinner aria-label="Loading" />,
})`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

export const TableContainer = styled.div`
  ${Td} {
    border: none;
    vertical-align: top;

    &:nth-of-type(1) {
      font-weight: var(--bold);
      width: 100px;
    }

    &:nth-of-type(2) {
      max-width: 30em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
`;
