import { createCollectionActions } from 'next/lib/collections';

export const TYPE = 'apps';

export const {
  patterns,
  read,
  readOne,
  reject,
  resolve,
  create,
  insert,
  update,
  replace,
  reset,
} = createCollectionActions(TYPE);
