import React from 'react';
import styled from 'styled-components';
import { Spinner } from '@studio/legacy-components';

export const Loading = styled.div.attrs({
  children: <Spinner aria-label="Loading" />,
})`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;
