import React, { useState } from 'react';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import { Button, ButtonGroup, Icon, Switch } from '@studio/legacy-components';
import { Shape as FeaturesShape } from 'next/entities/features';
import useToggle from 'next/hooks/use-toggle';
import useFeatureOverrides from './use-feature-overrides';
import {
  Actions,
  Cell,
  Collapse,
  Controls,
  Description,
  Name,
  Row,
  Subtitle,
  Tool,
  Unregistered,
  Warning,
} from './styled';

/**
 * TODO: Remove legacy account features entity and also
 * hard refresh on spoof to prevent out-of-sync feature flag updates.
 */

export default function Features({ features = {} }) {
  const [collapsed, toggle] = useToggle(true);

  const { editable, overrides, reset, save, uneditable } =
    useFeatureOverrides(features);

  // Initialize state with original and overridden flags
  const [patch, update] = useState({ ...editable, ...overrides });

  // Create change handler for a given feature flag
  const handleChangeFor = flag => value => {
    update({ ...editable, ...patch, [flag]: value });
  };

  const overridden = !isEmpty(overrides);
  const changed = !isEqual(editable, patch);

  const orderedEditable = Object.entries(editable).sort(([a], [b]) =>
    a.localeCompare(b)
  );
  const orderedUneditable = Object.entries(uneditable).sort(([a], [b]) =>
    a.localeCompare(b)
  );

  return (
    <Tool>
      <Subtitle>Feature Flags</Subtitle>

      <Description>
        The settings here will temporarily override any feature flags provided
        by LaunchDarkly on this domain. Only feature flags that have been
        registered as editable may be overridden.
      </Description>

      <Controls>
        {orderedEditable.map(([feature, checked]) => (
          <Row key={feature}>
            <Name>{feature}</Name>
            <Cell>
              <Switch checked={checked} onChange={handleChangeFor(feature)} />
            </Cell>
          </Row>
        ))}

        <Unregistered>
          <Collapse onClick={toggle} role="button">
            <Icon icon={collapsed ? 'chevron-up' : 'chevron-down'} />
            Show unregistered feature flags
          </Collapse>

          {!collapsed &&
            orderedUneditable.map(([feature, checked]) => (
              <Row key={feature}>
                <Name>{feature}</Name>
                <Cell>
                  <Switch checked={checked} disabled />
                </Cell>
              </Row>
            ))}
        </Unregistered>
      </Controls>

      <Actions>
        {(changed || overridden) && (
          <Warning>Changes will reload Studio</Warning>
        )}

        <ButtonGroup right>
          {overridden && (
            <Button kind="negative" onClick={reset}>
              Reset
            </Button>
          )}

          <Button
            disabled={!changed}
            kind="primary"
            onClick={() => save(patch)}
          >
            Apply changes
          </Button>
        </ButtonGroup>
      </Actions>
    </Tool>
  );
}

Features.propTypes = {
  features: FeaturesShape,
};
