import React from 'react';
import styled, { css } from 'styled-components';

const StyledGuideParagraph = styled.p`
  line-height: 26px;
  max-width: 80%;

  ${({ $size }) =>
    $size === 'full-width' &&
    css`
      max-width: 100%;
    `}

  &.content-paragraph {
    margin-bottom: ${props => (props.isLastParagraph ? 0 : '24px')};
  }
`;

const GuideParagraph = ({ children, isLastParagraph, size }) => (
  <StyledGuideParagraph
    $size={size}
    isLastParagraph={isLastParagraph}
    className="content-paragraph"
  >
    {children}
  </StyledGuideParagraph>
);

export default GuideParagraph;
