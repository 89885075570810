import {
  call,
  getContext,
  put,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';

import { takeEveryPromised } from 'next/lib/effects';
import { replacePagePatternFor } from 'next/entities/page';
import toast from 'next/lib/toast';

import {
  patterns,
  reject,
  replace,
  resolve,
  flush,
  prune,
  readOne,
  insert,
  EXPERIENCE_CLONED,
  experiencePublished,
  EXPERIENCE_PUBLISH_REQUESTED,
  EXPERIENCE_PUBLISHED,
  experienceReverted,
  EXPERIENCE_REVERT_REQUESTED,
  EXPERIENCE_REVERTED,
  experienceUnpublished,
  EXPERIENCE_UNPUBLISH_REQUESTED,
  EXPERIENCE_UNPUBLISHED,
  EXPERIENCE_ARCHIVED,
  EXPERIENCE_UNARCHIVED,
  ANALYTICS_EXPORT_REQUESTED,
  ANALYTICS_EXPORT_SUCCESS,
  exportAnalyticsSuccess,
  exportAnalyticsError,
  ANALYTICS_EXPORT_ERROR,
} from './actions';
import { parse } from './shape';

/**
 * Normalize experiences summary response as collection
 *
 * @param {Response} response - Raw experiences summary response
 * @return {Collection} Experiences collection
 */
const transform = response =>
  response.reduce((acc, { experience }) => {
    acc[experience.id] = parse(experience);
    return acc;
  }, {});

function* fetchExperiences() {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getExperiences);
    yield put(resolve(transform(response)));
  } catch (error) {
    yield put(reject(error));
  }
}

function* fetchExperience({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    const { experience } = yield call(api.getExperience, id);
    yield put(replace(experience));
  } catch (error) {
    yield put(reject(error));
  }
}

function* updateExperience({ payload: { id, delta } }) {
  try {
    const api = yield getContext('api');
    yield call(api.updateExperience, id, delta);
    yield put(flush(id));
  } catch (error) {
    yield put(reject(error));
  }
}
function* deleteExperience({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    yield call(api.deleteExperience, id);
    yield put(prune(id));
  } catch (error) {
    yield put(reject(error));
  }
}
export function* cloneExperience({ payload: { id, body } }) {
  try {
    const api = yield getContext('api');

    const { new_content_id: newId } = yield call(api.cloneExperience, id, body);
    yield put(readOne(newId));
  } catch (error) {
    yield put(reject(error));
  }
}

function* publishExperience({ payload: { id } }) {
  const api = yield getContext('api');
  yield call(api.publishExperience, id);
  yield put(experiencePublished(id));
}

function* revertExperience({ payload: { id, version } }) {
  try {
    const api = yield getContext('api');
    yield call(api.revertExperience, id, version);
    yield put(experienceReverted(id));
  } catch (error) {
    yield put(reject(error));
  }
}

function* unpublishExperience({ payload: { id } }) {
  const api = yield getContext('api');
  yield call(api.unpublishExperience, id);
  yield put(experienceUnpublished(id));
}

function* showToast() {
  yield call(toast.success, 'Changes saved.');
}

function* archiveExperience({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    yield call(api.archiveExperience, id);
    yield put(flush(id));
  } catch (error) {
    yield put(reject(error));
  }
}

function* unarchiveExperience({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    yield call(api.unarchiveExperience, id);
    yield put(flush(id));
  } catch (error) {
    yield put(reject(error));
  }
}

function* createExperience({ payload }) {
  try {
    const { id: omit, ...data } = payload;
    const api = yield getContext('api');
    const { experience } = yield call(api.createExperience, data);
    yield put(insert(experience));
    return experience;
  } catch (error) {
    yield put(reject(error));
    throw error;
  }
}

function* successExport({ payload: email }) {
  yield call(toast.success, `File sent to ${email}`);
}

function* failedExport() {
  yield call(toast.error, 'Your export failed, please try again');
}

function* exportExperience({ payload }) {
  try {
    const api = yield getContext('api');
    yield call(api.exportAnalytics, payload);
    yield put(exportAnalyticsSuccess(payload.email));
  } catch (error) {
    yield put(reject(error));
    yield put(exportAnalyticsError());
  }
}

export default function* saga() {
  yield takeEveryPromised([patterns.read, patterns.flush], fetchExperiences);
  yield takeEvery(
    [
      patterns.readOne,
      EXPERIENCE_PUBLISHED,
      EXPERIENCE_REVERTED,
      EXPERIENCE_UNPUBLISHED,
    ],
    fetchExperience
  );
  yield takeEvery(patterns.update, updateExperience);
  yield takeEvery(patterns.remove, deleteExperience);
  yield takeLatest(patterns.flush, showToast);
  yield takeEveryPromised(patterns.create, createExperience);

  yield takeLeading(EXPERIENCE_CLONED, cloneExperience);

  yield takeLeading(EXPERIENCE_ARCHIVED, archiveExperience);
  yield takeLeading(EXPERIENCE_UNARCHIVED, unarchiveExperience);

  yield takeEvery(ANALYTICS_EXPORT_REQUESTED, exportExperience);
  yield takeEvery(ANALYTICS_EXPORT_SUCCESS, successExport);
  yield takeEvery(ANALYTICS_EXPORT_ERROR, failedExport);
  yield takeEveryPromised(EXPERIENCE_PUBLISH_REQUESTED, publishExperience);
  yield takeEveryPromised(EXPERIENCE_REVERT_REQUESTED, revertExperience);
  yield takeEveryPromised(EXPERIENCE_UNPUBLISH_REQUESTED, unpublishExperience);

  // Page actions
  yield takeEvery(
    [
      replacePagePatternFor('/flows'),
      replacePagePatternFor('/banners'),
      replacePagePatternFor('/launchpads'),
    ],
    fetchExperiences
  );

  yield takeEvery(
    [
      replacePagePatternFor('/mobile/flows/:experienceId/analytics'),
      replacePagePatternFor('/mobile/flows/:experienceId/settings'),
      replacePagePatternFor('/banners/:experienceId/settings'),
      replacePagePatternFor('/banners/:experienceId/analytics'),
      replacePagePatternFor('/launchpads/:experienceId/settings'),
    ],
    fetchExperience
  );
}
