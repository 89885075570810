import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { CDialog } from '@appcues/component-library';
import { hideModal } from 'actions/currentModal';
import { navigate } from 'actions/routing';

const Text = styled.p`
  line-height: 26px;
  padding: 4px 0;
`;

const TextWrapper = styled.p`
  margin: 16px 0;
`;

function InstallToIntegrateModal({ actions }) {
  const navigateToInstallation = () => {
    actions.navigate('/settings/installation');
    actions.hideModal();
  };

  return (
    <CDialog
      type="primary"
      title="Install to integrate"
      primaryActionText="Install now"
      secondaryActionText="Back to integrations"
      onPrimaryAction={navigateToInstallation}
      onSecondaryAction={() => actions.hideModal()}
      small
    >
      <TextWrapper>
        <Text>
          Appcues needs to be installed in your product before we can send data
          to integrations.
        </Text>
        <Text>
          Once you install Appcues, you can turn on integrations with the click
          of a button!
        </Text>
      </TextWrapper>
    </CDialog>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        hideModal,
        navigate,
      },
      dispatch
    ),
  };
};

export default connect(null, mapDispatchToProps)(InstallToIntegrateModal);
