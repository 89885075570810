/* global APPCUES_ENV */

import { domain } from 'utils/opscues';

export default function createEstimationClient(auth) {
  const baseUrl = `https://${domain({
    app: 'estimation-api',
    env: APPCUES_ENV,
    facet: 'main',
  })}`;

  return {
    getConditionsEstimate: async conditions => {
      const jwt = await auth.getToken();
      const accountId = Number.parseInt(await auth.getAccountId(), 10);
      const url = encodeURI(`${baseUrl}/accounts/${accountId}/conditions`);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          account_id: accountId,
          conditions,
        }),
      });

      if (response.status !== 200) {
        throw new Error(`Failed to fetch conditions estimate ${url}`);
      }

      return response.json();
    },
  };
}
