import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ImageServiceImage from 'components/Common/ImageServiceImage';
import { selectSortedFlowStepChildren } from 'reducers/account/flows';

/**
 * Determines the first stepChild of the first step in a flow and
 * uses the Image Service to render a picture of it.
 *
 * @param stepId
 * @param steps
 * @returns {*}
 * @constructor
 */

const StyledImageServiceImage = styled(ImageServiceImage)`
  max-width: 300px;
  height: auto;
  max-height: 100%;
  overflow: hidden;
`;

const StepImage = ({ stepChild, className, versionId }) => {
  if (!stepChild) {
    return false;
  }
  const { flowId, stepId, id: stepChildId, stepType } = stepChild;
  return (
    <div className={className}>
      <StyledImageServiceImage
        flowId={flowId}
        stepId={stepId}
        contentType={stepType}
        stepChildId={stepChildId}
        size="small"
        versionId={versionId}
      />
    </div>
  );
};

StepImage.propTypes = {
  stepChild: PropTypes.shape({
    flowId: PropTypes.string,
    stepId: PropTypes.string,
    id: PropTypes.string,
    stepType: PropTypes.string,
  }),
};

const mapStateToProps = (state, { stepId: flowId }) => ({
  stepChild: selectSortedFlowStepChildren(state, flowId)[0],
});

export default connect(mapStateToProps)(
  styled(StepImage)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${props => props.heightOverride || '125px'};
    overflow: hidden;
  `
);
