import { createLifecycleFor } from 'actions/account/collections';

export const TYPE = 'checklists';

export const {
  send,
  reject,
  resolve,
  callApi,
  remove,
  update,
  create,
  insert,
  flush,
  replace,
  patterns,
} = createLifecycleFor(TYPE);

export const CHECKLIST_DELETED = '@checklist/DELETED';
export const deleteChecklist = id => ({
  type: CHECKLIST_DELETED,
  payload: { id },
});

export const CHECKLIST_CLONED = '@checklist/CLONED';
export const cloneChecklist = id => ({
  type: CHECKLIST_CLONED,
  payload: { id },
});

export const ITEM_UPDATED = '@checklist/ITEM_UPDATED';
export const updateItem = (id, itemId, delta) => ({
  type: ITEM_UPDATED,
  payload: { id, itemId, delta },
});

export const ITEM_REMOVED = '@checklist/ITEM_REMOVED';
export const removeItem = (id, itemId) => ({
  type: ITEM_REMOVED,
  payload: { id, itemId },
});

export const ITEMS_SWAPPED = '@checklist/ITEMS_SWAPPED';
export const swapItems = (id, item1Id, item2Id) => ({
  type: ITEMS_SWAPPED,
  payload: { id, item1Id, item2Id },
});

export const ITEM_ADDED = '@checklist/ITEM_ADDED';
export const addItem = id => ({
  type: ITEM_ADDED,
  payload: { id },
});
