import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CCard, Flex } from '@appcues/component-library';
import { ContentStatus, H3 } from '@studio/legacy-components';
import { selectFlow } from 'reducers/account/flows';

import StepImage from 'components/Common/StepImage';

const StyledStepContentContainer = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
  padding: 16px 24px 15px;
  min-width: 0;
`;

const StyledStepImageContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 130px;
  min-height: 100%;
  background-color: ${props => props.theme['$gray-3']};
  padding: 8px 15px;
  border-radius: 6px 0 0 6px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const FlowTitle = styled(H3)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const MetricFlowListItem = ({ flow, children }) => {
  return (
    <StyledLink
      to={`/flows/${flow.id}/analytics`}
      title={`Navigate to ${flow.name || 'Untitled Flow'}`}
    >
      <CCard height={90}>
        <Flex height="100%">
          <StyledStepImageContainer>
            <StepImage
              maxWidth="100%"
              maxHeight="100%"
              heightOverride="100%"
              stepId={flow.id}
            />
          </StyledStepImageContainer>
          <StyledStepContentContainer>
            <FlowTitle>{flow.name || 'Untitled Flow'}</FlowTitle>
            <Flex
              alignItems="center"
              marginTop="auto"
              justifyContent="space-between"
            >
              {children}
              <ContentStatus published={flow.published} state={flow.state} />
            </Flex>
          </StyledStepContentContainer>
        </Flex>
      </CCard>
    </StyledLink>
  );
};

const mapStateToProps = (state, { flowId }) => ({
  flow: selectFlow(state, flowId) || {},
});

MetricFlowListItem.propTypes = {
  flow: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    published: PropTypes.bool,
  }),
  children: PropTypes.node,
};

const ConnectedMetricFlowListItem =
  connect(mapStateToProps)(MetricFlowListItem);

ConnectedMetricFlowListItem.propTypes = {
  flowId: PropTypes.string,
};

export default ConnectedMetricFlowListItem;
