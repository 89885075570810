import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Dropdown,
  SquareButton,
  Checkbox,
  Icon,
} from '@studio/legacy-components';
import useToggle from 'next/hooks/use-toggle';

export const FILTER_OPTIONS = [
  {
    key: 'score',
    name: 'Score',
    options: [
      {
        label: 'Promoters',
        value: ['nps_raw_score', '>=', 9],
      },
      {
        label: 'Passives',
        value: ['nps_raw_score', 'between', 7, 8],
      },
      {
        label: 'Detractors',
        value: ['nps_raw_score', '<=', 6],
      },
    ],
  },
  {
    key: 'feedback',
    name: 'Feedback',
    options: [
      {
        label: 'Has feedback',
        value: ['nps_feedback', 'is not null'],
      },
      {
        label: 'No feedback',
        value: ['nps_feedback', 'is null'],
      },
    ],
  },
];

const OpenerWrapper = styled.div`
  position: relative;
`;

const Badge = styled.span`
  align-items: center;
  border-radius: 50%;
  background-color: var(--primary);
  color: var(--white);
  display: flex;
  font-size: var(--xx-small);
  height: 16px;
  justify-content: center;
  position: absolute;
  right: -4px;
  top: -4px;
  width: 16px;
`;

const ClearButton = styled.button`
  background: none;
  border: none;
  color: var(--link);
  position: absolute;
  right: 24px;
  top: 22px;

  &:hover,
  &:focus {
    color: var(--link-hover);
  }
`;

const FilterWrapper = styled.div`
  margin: -8px;
  min-width: 250px;
  position: relative;
`;

const Group = styled.div`
  border-bottom: 1px solid var(--ui-vertical-separator);
  padding: 20px 20px 8px;
`;

const GroupOption = styled.div`
  margin-bottom: 16px;
`;

const GroupName = styled.h4`
  color: var(--heading-primary);
  font-weight: var(--bold);
  font-size: var(--regular);
  margin-bottom: 16px;
`;

const FilterButton = styled(SquareButton)`
  ${Icon} {
    color: var(--icon);
  }

  ${({ active }) =>
    active &&
    `
      border-color: var(--primary);

      ${Icon} {
        color: var(--primary);
      }
    `}
`;

const ResponsesFilter = ({ values, onChange, onClear }) => {
  const [isOpen, toggleOpen] = useToggle(false);
  const selectedValues = values || {};
  const filtersCount = Object.values(selectedValues).reduce((total, items) => {
    return items.length + total;
  }, 0);

  return (
    <Dropdown
      onShow={toggleOpen}
      onHide={toggleOpen}
      attachment={
        <FilterWrapper>
          {filtersCount > 0 && (
            <ClearButton onClick={onClear}>Clear</ClearButton>
          )}
          {FILTER_OPTIONS.map(({ key, name, options }, groupIndex) => (
            <Group key={key}>
              <GroupName>{name}</GroupName>
              {options.map(({ label, value }, optionIndex) => {
                const checked = (selectedValues[key] ?? []).includes(value);

                return (
                  <GroupOption>
                    <Checkbox
                      key={label}
                      autoFocus={groupIndex === 0 && optionIndex === 0}
                      checked={checked}
                      onChange={isChecked => {
                        onChange(key, isChecked, value);
                      }}
                    >
                      {label}
                    </Checkbox>
                  </GroupOption>
                );
              })}
            </Group>
          ))}
        </FilterWrapper>
      }
    >
      <OpenerWrapper>
        {filtersCount > 0 && !isOpen && (
          <Badge aria-label={`Number of selected filters: ${filtersCount}`}>
            {filtersCount}
          </Badge>
        )}
        <FilterButton
          aria-label="Open filter menu"
          icon="sliders-h"
          kind="secondary"
          active={isOpen}
        />
      </OpenerWrapper>
    </Dropdown>
  );
};

ResponsesFilter.propTypes = {
  values: PropTypes.shape({
    feedback: PropTypes.arrayOf(PropTypes.array),
    score: PropTypes.arrayOf(PropTypes.array),
  }),
  onChange: PropTypes.func,
  onClear: PropTypes.func,
};

export default ResponsesFilter;
