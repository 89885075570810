import { REPLACE_ACCOUNT_STATS } from 'actions/account/stats';
import { CLEAR_ACCOUNT_DATA } from 'constants/actionTypes';

const initialState = {
  synced: false,
  stats: {},
};

export default function stats(state = initialState, action) {
  switch (action.type) {
    case REPLACE_ACCOUNT_STATS:
      return {
        synced: true,
        stats: action.payload,
      };
    case CLEAR_ACCOUNT_DATA:
      return initialState;
    default:
      return state;
  }
}

export const selectAccountStats = state => state.account.stats.stats;
export const selectAccountStatsSynced = state => state.account.stats.synced;
