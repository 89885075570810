import styled from 'styled-components';
import {
  Table,
  Th,
  Tr,
  Td,
  Icon,
  Overflow,
  Heading,
} from '@studio/legacy-components';

export const EligibleIcon = styled(Icon)`
  margin-right: 8px;
  font-size: var(--small);
  color: ${props =>
    props.$eligible
      ? 'var(--eligible-icon-color)'
      : 'var(--ineligible-icon-color)'};
`;

export const EligibilityTable = styled(Table)`
  ${Tr}:nth-child(10) {
    ${Td} {
      border-bottom: none;
    }
  }
  ${Th}:nth-child(1) {
    width: 50%;
  }
  ${Th}:nth-child(2) {
    width: 15%;
  }
  ${Th}:nth-child(3) {
    width: 35%;
  }
`;

export const EligibilityRow = styled(Tr)`
  ${Td} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 300px;
  }
`;

export const EligibilityLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  ${EligibleIcon} {
    font-size: 10px;
  }
`;

export const CriteriaOverflow = styled(Overflow)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ModalFooter = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`;

export const Criteria = styled.div``;

export const CriteriaName = styled.p`
  font-weight: var(--bold);
  margin-bottom: 2px;
`;

export const CriteriaWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const CriteriaIcon = styled(Icon)`
  font-size: var(--medium-large);
  color: ${props =>
    props.$eligible ? 'var(--eligible-icon-color)' : 'var(--ineligible-color)'};
  && {
    width: 16px !important;
    height: 16px;
  }
`;

export const CriteriaCount = styled.span`
  margin-bottom: 32px;
`;

export const Inelegible = styled.p`
  display: inline;
  color: var(--ineligible-color);
`;

export const ActivityCell = styled(Td)`
  ::first-letter {
    text-transform: uppercase;
  }
`;

export const CriteriaTitle = styled(Heading)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
`;
