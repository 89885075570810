import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Conditions, CLAUSE_TYPES } from '@studio/conditions';

import {
  BANNERS_CONDITIONS,
  CHECKLIST_TARGETING,
  EMAIL_WORKFLOWS,
  selectFeature,
} from 'next/entities/features';
import {
  selectProfileAttributeOptions,
  selectFlowTargetingOptions,
  selectEventTargetingOptions,
  selectSegmentTargetingOptions,
  selectChecklistTargetingOptions,
  selectGroupAttributeOptions,
  selectPinTargetingOptions,
  selectMobileFlowTargetingOptions,
  selectBannerTargetingOptions,
  selectEmailTargetingOptions,
} from 'next/lib/selectors-options';
import { SelectOptionsShape } from 'next/lib/shapes';

export const ConditionsWrapper = ({
  pinOptions = [],
  bannerOptions = [],
  propertyOptions = [],
  checklistOptions = [],
  eventOptions = [],
  flowOptions = [],
  mobileFlowOptions = [],
  groupOptions = [],
  emailOptions = [],
  hasChecklistTargeting,
  segmentOptions = [],
  validClauseTypes = Object.keys(CLAUSE_TYPES),
  hasBannerTargeting,
  hasEmailTargeting,
  ...unhandledProps
}) => {
  return (
    <Conditions
      propertyOptions={propertyOptions}
      checklistOptions={checklistOptions}
      eventOptions={eventOptions}
      flowOptions={flowOptions}
      pinOptions={pinOptions}
      bannerOptions={bannerOptions}
      emailOptions={emailOptions}
      mobileFlowOptions={mobileFlowOptions}
      groupOptions={groupOptions}
      hasChecklistTargeting={hasChecklistTargeting}
      hasBannerTargeting={hasBannerTargeting}
      hasEmailTargeting={hasEmailTargeting}
      segmentOptions={segmentOptions}
      validClauseTypes={validClauseTypes}
      {...unhandledProps}
    />
  );
};

ConditionsWrapper.propTypes = {
  checklistOptions: SelectOptionsShape,
  eventOptions: SelectOptionsShape,
  flowOptions: SelectOptionsShape,
  mobileFlowOptions: SelectOptionsShape,
  groupOptions: SelectOptionsShape,
  emailOptions: SelectOptionsShape,
  hasChecklistTargeting: PropTypes.bool,
  propertyOptions: SelectOptionsShape,
  segmentOptions: SelectOptionsShape,
  validClauseTypes: PropTypes.arrayOf(
    PropTypes.oneOf(Object.keys(CLAUSE_TYPES))
  ),
  pinOptions: SelectOptionsShape,
  bannerOptions: SelectOptionsShape,
  hasBannerTargeting: PropTypes.bool,
  hasEmailTargeting: PropTypes.bool,
};

const mapStateToProps = state => ({
  hasChecklistTargeting: selectFeature(state, CHECKLIST_TARGETING),
  hasBannerTargeting: selectFeature(state, BANNERS_CONDITIONS),
  hasEmailTargeting: selectFeature(state, EMAIL_WORKFLOWS),
  checklistOptions: selectChecklistTargetingOptions(state),
  eventOptions: selectEventTargetingOptions(state),
  flowOptions: selectFlowTargetingOptions(state),
  mobileFlowOptions: selectMobileFlowTargetingOptions(state),
  groupOptions: selectGroupAttributeOptions(state),
  propertyOptions: selectProfileAttributeOptions(state),
  segmentOptions: selectSegmentTargetingOptions(state),
  pinOptions: selectPinTargetingOptions(state),
  bannerOptions: selectBannerTargetingOptions(state),
  emailOptions: selectEmailTargetingOptions(state),
});

export default styled(connect(mapStateToProps)(ConditionsWrapper))``;
