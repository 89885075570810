import React from 'react';
import pluralize from 'pluralize';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';

import { Caption, Text } from '@studio/legacy-components';
import { format, add } from 'next/lib/date';
import LoadingIcon from 'assets/images/checkout-loading.svg';
import { Plan as PlanShape } from 'components/Subscription/shape';
import { MONTHLY_INTERVAL } from 'constants/plans';
import { formatCurrency, formatNumber } from 'utils/billing';

import PlanSavings from './PlanSavings';

import {
  StyledHeading,
  StyledText,
  CheckoutRow,
  DueTodayText,
  IntervalInfo,
  LoadingSpinner,
  NextInvoiceInfo,
} from './styled';

const NewSubscriptionCheckout = ({
  completedForms,
  formValues,
  planData,
  planPrice,
  selectedEntitlementsCount,
  subscriptionTaxes = 0,
  taxesLoading,
}) => {
  return (
    <>
      <StyledHeading>Appcues {planData?.name ?? ''}</StyledHeading>

      <StyledText noMargin={selectedEntitlementsCount > 0}>
        Up to {formatNumber(planData?.planLimit) ?? ''} Monthly active users
        (MAUs)
      </StyledText>

      {selectedEntitlementsCount > 0 && (
        <StyledText>
          + {selectedEntitlementsCount}{' '}
          {pluralize('add-ons', selectedEntitlementsCount)}
        </StyledText>
      )}

      <CheckoutRow layout="vertical">
        <IntervalInfo spaced={planData.planInterval === 'yearly'}>
          <Text>Billed {planData.cadence}</Text>
          <Text bold>
            {formatCurrency(planPrice) || ''}/{planData.period}
          </Text>
        </IntervalInfo>

        <PlanSavings planData={planData} spaced={false} />
      </CheckoutRow>

      {!isEmpty(formValues) && !!subscriptionTaxes && subscriptionTaxes !== 0 && (
        <CheckoutRow>
          <Text>Sales tax</Text>
          {taxesLoading ? (
            <LoadingSpinner src={LoadingIcon} alt="stripe icon" />
          ) : (
            <Text bold>{formatCurrency(subscriptionTaxes) || '-'}</Text>
          )}
        </CheckoutRow>
      )}

      <CheckoutRow borderless>
        <DueTodayText bold>Due Today</DueTodayText>
        <DueTodayText bold>
          {completedForms.billing
            ? formatCurrency(planPrice + subscriptionTaxes)
            : '-'}
        </DueTodayText>
      </CheckoutRow>

      <NextInvoiceInfo>
        {completedForms.billing && (
          <Text>
            <Caption>
              We will bill you {formatCurrency(planPrice + subscriptionTaxes)}{' '}
              (taxes included) at the start of your next{' '}
              {planData.planInterval.replace('yearly', 'annual')} billing period
              on{' '}
              {format(
                add(
                  new Date(),
                  1,
                  planData.planInterval === MONTHLY_INTERVAL
                    ? 'months'
                    : 'years'
                ),
                'MM/DD/YYYY'
              )}
              .
            </Caption>
          </Text>
        )}

        <Text>
          <Caption>
            Accounts that exceed their MAU limit will be upgraded for the next
            billing period.
          </Caption>
        </Text>
      </NextInvoiceInfo>
    </>
  );
};

NewSubscriptionCheckout.propTypes = {
  completedForms: PropTypes.object,
  formValues: PropTypes.object,
  planData: PlanShape,
  planPrice: PropTypes.number,
  selectedEntitlementsCount: PropTypes.number,
  subscriptionTaxes: PropTypes.number,
  taxesLoading: PropTypes.bool,
};

export default NewSubscriptionCheckout;
