import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import countryList from 'react-select-country-list';
import { selectPaymentInfo } from 'reducers/newBilling';
import { fetchPaymentInfo, fetchPaymentTaxes } from 'actions/newBilling';
import { asPromised } from 'utils/as-promised';

const FORM_INITIAL_VALUES = {
  city: '',
  country: '',
  line1: '',
  name: '',
  postalCode: '',
};

function usePaymentInfoValues({
  stripeId,
  planPrice,
  onLoadPaymentInfo = () => null,
}) {
  const dispatch = useDispatch();
  const paymentInfo = useSelector(selectPaymentInfo);
  const [isLoading, setIsLoading] = useState(true);

  const onFetchPaymentInfo = payload => {
    return asPromised(dispatch, fetchPaymentInfo(payload));
  };

  const onCalculateTaxes = payload => {
    return asPromised(dispatch, fetchPaymentTaxes(payload));
  };

  const loadPaymentInfo = async () => {
    setIsLoading(true);
    try {
      await onFetchPaymentInfo({ stripeId });
    } catch {
      setIsLoading(false);
    }
    onLoadPaymentInfo();
  };

  const loadPaymentTaxes = async () => {
    if (paymentInfo?.billingAddress && planPrice) {
      await onCalculateTaxes({
        billingAddress: paymentInfo?.billingAddress,
        amount: planPrice,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (stripeId) {
      loadPaymentInfo();
    }
  }, [stripeId]);

  useEffect(() => {
    if (paymentInfo?.billingAddress) {
      loadPaymentTaxes();
    }
  }, [paymentInfo?.billingAddress]);

  const country = useMemo(() => {
    const countryValue = paymentInfo?.billingAddress?.country ?? '';
    return {
      value: countryValue,
      label: countryValue ? countryList().getLabel(countryValue) : '',
    };
  }, [paymentInfo?.billingAddress?.country]);

  const initialValues = useMemo(
    () => ({
      ...FORM_INITIAL_VALUES,
      ...paymentInfo?.billingAddress,
      country,
    }),
    [paymentInfo?.billingAddress]
  );

  return {
    paymentInfo,
    initialValues,
    isLoading,
  };
}

export default usePaymentInfoValues;
