import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  ButtonGroup,
  Modal,
  Heading,
  ModalFooter,
  Overflow,
  Table,
  Tr,
  Td,
  Tbody,
} from '@studio/legacy-components';
import { Shape } from '@studio/conditions';

import { Shape as ExperienceShape } from 'next/entities/experiences';
import { selectRule } from 'next/entities/rules';
import ReadableConditions from 'next/components/ReadableConditions';
import FlowStepsCarousel from 'next/components/FlowStepsCarousel';
import SchedulingStartAlert from 'next/components/ExperienceScheduling/SchedulingStartAlert';
import { ScheduleType } from 'next/components/ExperienceScheduling/types';
import { selectSchedule } from 'next/entities/schedules';
import { formatScheduleDate } from 'next/components/ExperienceScheduling/dates';
import { selectFlow } from 'next/entities/flows';

import { getFlowTargetingSections } from './utils/flow-targeting-sections';
import { FrequencyRule } from './FrequencyRule';
import { TableContainer } from './styled';

export function ConfirmPushChangesModal({
  flow = {},
  rule = {},
  onPublish,
  onClose,
  visible,
  schedule,
}) {
  const { conditions, frequency } = rule;
  const sections = getFlowTargetingSections(conditions);

  return (
    <Modal visible={visible} onClose={onClose} size="l">
      <Heading>Confirm Publish</Heading>
      <Overflow>
        <SchedulingStartAlert schedule={schedule} experienceType="Flow" />
        <FlowStepsCarousel flow={flow} />
        <TableContainer>
          <Table>
            <Tbody>
              <Tr>
                <Td>Page</Td>
                <Td>
                  {sections.url ? (
                    <ReadableConditions conditions={sections.url} />
                  ) : (
                    'Any page'
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td>Frequency</Td>
                <Td>
                  <FrequencyRule value={frequency} />
                </Td>
              </Tr>
              <Tr>
                <Td>Domains</Td>
                <Td>
                  {sections.domains ? (
                    <ReadableConditions conditions={sections.domains} />
                  ) : (
                    'Everywhere my Appcues script is installed'
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td>Audience</Td>
                <Td>
                  {sections.audience ? (
                    <ReadableConditions conditions={sections.audience} />
                  ) : (
                    'All users'
                  )}
                </Td>
              </Tr>

              {schedule?.startDate && (
                <Tr>
                  <Td>Publish date</Td>
                  <Td>{formatScheduleDate(schedule?.startDate)}</Td>
                </Tr>
              )}
              {schedule?.endDate && (
                <Tr>
                  <Td>Unpublish date</Td>
                  <Td>{formatScheduleDate(schedule?.endDate)}</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Overflow>
      <ModalFooter>
        <ButtonGroup>
          <Button kind="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onPublish}>Confirm</Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
}

ConfirmPushChangesModal.propTypes = {
  flow: ExperienceShape,
  rule: Shape,
  onPublish: PropTypes.func,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  schedule: ScheduleType,
};

const mapStateToProps = (state, { id }) => ({
  flow: selectFlow(state, id),
  rule: selectRule(state, id),
  schedule: selectSchedule(state),
});

export default connect(mapStateToProps)(ConfirmPushChangesModal);
