import { createCollectionReducer } from 'next/lib/collections';
import { selectRequestStatus } from 'next/entities/requests';
import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

export const selectApps = state => state[TYPE];
export const selectApp = (state, id) => selectApps(state)[id];

export const readApps = state => ({
  ...selectRequestStatus(state, TYPE),
  data: selectApps(state),
});

export const readApp = (state, id) => ({
  ...selectRequestStatus(state, TYPE, id),
  data: selectApp(state, id),
});

export const selectEnabledApps = state =>
  Object.entries(selectApps(state) ?? {})
    .filter(([, { state: appState }]) => appState === 'enabled')
    .reduce((acc, [id, app]) => {
      acc[id] = app;
      return acc;
    }, {});
