import { connect } from 'react-redux';
import {
  selectResponse,
  read as fetchSurveyResponses,
} from 'next/entities/surveys';
import { readAccount } from 'next/entities/account';
import SurveyResponses from './SurveyResponses';

const mapStateToProps = (state, { flowId: id }) => {
  return {
    flowId: id,
    surveys: selectResponse(state, id),
    currentIdentifier:
      readAccount(state)?.data?.userProfileIdentifier || 'userId',
  };
};
const mapDispatchToProps = (dispatch, { flowId: id, start, end }) => ({
  onLoad: (identifier = '') =>
    dispatch(
      fetchSurveyResponses({
        id,
        start,
        end,
        contentType: 'EXPERIENCE',
        propertyNames: [identifier],
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyResponses);
