import { createCollectionActions } from 'next/lib/collections';

export const TYPE = 'experiences';

export const {
  flush,
  patterns,
  read,
  readOne,
  reject,
  replace,
  resolve,
  prune,
  remove,
  update,
  create,
  insert,
} = createCollectionActions(TYPE);

export const EXPERIENCE_CLONED = 'EXPERIENCE_CLONED';
export const clone = (id, body) => ({
  type: EXPERIENCE_CLONED,
  payload: { id, body },
});

export const EXPERIENCE_PUBLISH_REQUESTED = '@experience/PUBLISH_REQUESTED';
export const requestPublishExperience = id => ({
  type: EXPERIENCE_PUBLISH_REQUESTED,
  payload: { id },
});

export const EXPERIENCE_PUBLISHED = '@experience/PUBLISHED';
export const experiencePublished = id => ({
  type: EXPERIENCE_PUBLISHED,
  payload: { id },
});

export const EXPERIENCE_REVERT_REQUESTED =
  '@experience/EXPERIENCE_REVERT_REQUESTED';
export const requestRevertExperience = (id, version) => ({
  type: EXPERIENCE_REVERT_REQUESTED,
  payload: { id, version },
});

export const EXPERIENCE_REVERTED = '@experience/REVERTED';
export const experienceReverted = id => ({
  type: EXPERIENCE_REVERTED,
  payload: { id },
});

export const EXPERIENCE_UNPUBLISH_REQUESTED = '@experience/UNPUBLISH_REQUESTED';
export const requestUnpublishExperience = id => ({
  type: EXPERIENCE_UNPUBLISH_REQUESTED,
  payload: { id },
});

export const EXPERIENCE_UNPUBLISHED = '@experience/UNPUBLISHED';
export const experienceUnpublished = id => ({
  type: EXPERIENCE_UNPUBLISHED,
  payload: { id },
});

export const EXPERIENCE_ARCHIVED = '@experience/ARCHIVED';
export const archive = id => ({
  type: EXPERIENCE_ARCHIVED,
  payload: { id },
});

export const EXPERIENCE_UNARCHIVED = '@experience/UNARCHIVED';
export const unarchive = id => ({
  type: EXPERIENCE_UNARCHIVED,
  payload: { id },
});

export const ANALYTICS_EXPORT_REQUESTED =
  '@experience/ANALYTICS_EXPORT_REQUESTED';
export const exportAnalytics = payload => ({
  type: ANALYTICS_EXPORT_REQUESTED,
  payload,
});
export const ANALYTICS_EXPORT_SUCCESS = '@experience/ANALYTICS_EXPORT_SUCCESS';
export const exportAnalyticsSuccess = payload => ({
  type: ANALYTICS_EXPORT_SUCCESS,
  payload,
});
export const ANALYTICS_EXPORT_ERROR = '@experience/ANALYTICS_EXPORT_ERROR';
export const exportAnalyticsError = () => ({
  type: ANALYTICS_EXPORT_ERROR,
});
