import PropTypes from 'prop-types';
import { ConditionsShape } from '@studio/conditions';
import { ContentStateShape } from 'next/lib/shapes';

const ChecklistItemShape = PropTypes.shape({
  conditions: ConditionsShape,
  id: PropTypes.string,
  index: PropTypes.number,
  label: PropTypes.string,
});

const ChecklistStylesShape = PropTypes.shape({
  beaconBackgroundColor: PropTypes.string,
  beaconHorizontalOffset: PropTypes.number,
  beaconIcon: PropTypes.string,
  beaconTextColor: PropTypes.string,
  beaconVerticalOffset: PropTypes.number,
  completionIcon: PropTypes.string,
  headerIcon: PropTypes.string,
  position: PropTypes.string,
  primaryColor: PropTypes.string,
  successColor: PropTypes.string,
  successImageCode: PropTypes.string,
  type: PropTypes.string,
});

const ChecklistTextTokensShape = PropTypes.shape({
  beaconText: PropTypes.string,
  completionDismissText: PropTypes.string,
  completionText: PropTypes.string,
  completionTitle: PropTypes.string,
  descriptionText: PropTypes.string,
  dismissCancelText: PropTypes.string,
  dismissConfirmText: PropTypes.string,
  dismissPromptText: PropTypes.string,
  dismissText: PropTypes.string,
  headerText: PropTypes.string,
});

export default PropTypes.shape({
  completionFlowId: PropTypes.string,
  createdAt: PropTypes.number,
  id: PropTypes.string,
  internalName: PropTypes.string,
  introFlowId: PropTypes.string,
  isSequential: PropTypes.bool,
  isDismissable: PropTypes.bool,
  items: PropTypes.objectOf(ChecklistItemShape),
  published: PropTypes.bool,
  rule: PropTypes.shape({
    conditions: ConditionsShape,
  }),
  state: ContentStateShape,
  styles: ChecklistStylesShape,
  textTokens: ChecklistTextTokensShape,
  title: PropTypes.string,
  type: PropTypes.oneOf(['checklist']),
  updatedAt: PropTypes.number,
  updatedBy: PropTypes.string,
});
