import styled from 'styled-components';
import { CRow } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';

export const BackArrow = styled(Icon)`
  position: absolute;
  left: 25px;
  top: 36px;
  color: var(--blurple);

  &:hover {
    cursor: pointer;
  }
`;

export const Header = styled(CRow)`
  > ${BackArrow} {
    width: 20px;
    height: 20px;
  }
`;
