import { createCollectionReducer } from 'next/lib/collections';
import { selectRequestStatus } from 'next/entities/requests';
import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

export const selectThemes = state => state[TYPE];
export const selectTheme = (state, id) => selectTheme(state)[id];

export const readThemes = state => ({
  ...selectRequestStatus(state, TYPE),
  data: selectThemes(state),
});

export const readTheme = (state, id) => ({
  ...selectRequestStatus(state, TYPE, id),
  data: selectTheme(state, id),
});

export const selectDefaultTheme = state =>
  Object.values(selectThemes(state) ?? {}).find(({ isDefault }) =>
    Boolean(isDefault)
  );
