import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Tooltip } from '@studio/legacy-components';
import { UpdateButton } from './styled';

export const ButtonWrapper = styled.div``;

const DebouncedButton = ({ waitMs, disabled, onClick, ...unhandledProps }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const timeoutDisable = useRef();

  useEffect(() => {
    return () => {
      clearTimeout(timeoutDisable.current);
    };
  }, []);

  const handleClick = event => {
    if (isDisabled) {
      return;
    }

    setIsDisabled(true);
    timeoutDisable.current = setTimeout(() => {
      setIsDisabled(false);
    }, waitMs);
    onClick?.(event);
  };

  return (
    <Tooltip
      position="top"
      disabled={!(isDisabled || disabled)}
      label="Limited to once per minute."
    >
      <ButtonWrapper>
        <UpdateButton
          disabled={isDisabled || disabled}
          onClick={handleClick}
          {...unhandledProps}
        >
          Update
        </UpdateButton>
      </ButtonWrapper>
    </Tooltip>
  );
};

DebouncedButton.propTypes = {
  waitMs: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default DebouncedButton;
