import styled from 'styled-components';
import { BeaconLink } from '@studio/navigation';
import { selectUser } from 'next/entities/user';
import { provide } from 'next/components/Hacks';

const mapStateToProps = state => ({
  user: selectUser(state),
});

const Connected = provide(mapStateToProps)(BeaconLink);

export default styled(Connected)``;
