import React from 'react';

import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  H1,
  Page,
  PageBody,
  PageHeader,
  PageTitle,
  Panel,
} from '@studio/legacy-components';
import FlowFrequencyModalManager from 'next/components/FlowFrequencyModalManager';
import { DocumentTitle } from 'next/hooks/use-title';
import { updateAccountMeta } from 'actions/account/meta';
import FlowTable from 'components/Priorities/FlowTable';
import { FLOW_FREQUENCY } from 'constants/features';

import { selectAccountFeature } from 'reducers/account/features';
import { selectAccountMeta } from 'reducers/account/meta';

const FrequencyLimitWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px 16px 30px;
`;

export const Priorities = ({
  hasFrequency,
  throttleEnabled,
  throttleContentLimit,
  throttleContentTimespanMs,
  onUpdateAccount,
}) => (
  <Page>
    <PageHeader>
      <PageTitle>
        <DocumentTitle title="Flow Priority | Appcues" />
        <H1>Flow priority</H1>
      </PageTitle>
    </PageHeader>
    <PageBody>
      {hasFrequency && (
        <Panel>
          <FrequencyLimitWrapper>
            You can limit how often users can see a Flow.
            <FlowFrequencyModalManager
              throttleEnabled={throttleEnabled}
              throttleContentLimit={throttleContentLimit}
              throttleContentTimespanMs={throttleContentTimespanMs}
              onSave={onUpdateAccount}
              trigger={
                <Button kind="secondary" key="show">
                  Frequency limits
                </Button>
              }
            />
          </FrequencyLimitWrapper>
        </Panel>
      )}
      <FlowTable />
    </PageBody>
  </Page>
);

Priorities.propTypes = {
  hasFrequency: PropTypes.bool,
  throttleContentLimit: PropTypes.number,
  throttleContentTimespanMs: PropTypes.number,
  throttleEnabled: PropTypes.bool,
  onUpdateAccount: PropTypes.func,
};

function mapStateToProps(state) {
  const meta = selectAccountMeta(state);

  return {
    hasFrequency: selectAccountFeature(state, FLOW_FREQUENCY),
    throttleEnabled: meta?.throttleEnabled,
    throttleContentLimit: meta?.throttleContentLimit,
    throttleContentTimespanMs: meta?.throttleContentTimespanMs,
  };
}

const mapDispatchToProps = {
  onUpdateAccount: updateAccountMeta,
};

export default connect(mapStateToProps, mapDispatchToProps)(Priorities);
