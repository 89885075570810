import styled from 'styled-components';

import {
  Caption,
  Button,
  ExternalLink,
  H2,
  Icon,
  PageBody,
  Text,
} from '@studio/legacy-components';
import {
  PlansWrapper,
  PlanWrapper,
} from 'components/SubscriptionPicker/styled';

// Upgrade page
export const BackArrow = styled(Icon)`
  color: var(--blurple);
  margin-right: 20px;
  &:hover {
    cursor: pointer;
  }
`;

export const CustomPageBody = styled(PageBody)`
  display: flex;
  flex-direction: column;
  padding: 24px 79px;
  > ${H2} {
    margin-bottom: 10px;
  }
  ${PlansWrapper} {
    margin-top: 0px;
  }
  ${PlanWrapper} {
    background: var(--white);
  }
  ${ExternalLink} {
    color: var(--blurple);
    margin-top: 13px;
  }
`;

// Checkout Preview
export const CheckoutButton = styled(Button)`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    color: var(--black);
  }
`;

export const PopoverWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const Count = styled(Text)`
  color: var(--blurple);
  font-size: var(--regular);
  font-weight: var(--bold);
  cursor: pointer;
`;

export const LineItem = styled(Text)`
  margin-top: 16px;
  &:first-child {
    margin-top: 0;
  }
  ${Caption} {
    font-weight: var(--bold);
    color: var(--black);
  }
`;

export const Subtotal = styled.div`
  min-width: 250px;
  box-sizing: border-box;
`;

export const SelectedPlan = styled.div`
  min-width: 185px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-right: solid 1px var(--tint-black);
`;

export const PlanSummary = styled.div`
  display: flex;
  ${SelectedPlan},
  ${Subtotal} {
    padding: 0 45px;
  }
`;

export const PlustaxText = styled(Text)`
  font-style: italic;
`;

export const PlusIcon = styled(Icon)`
  color: var(--blurple);
  vertical-align: top;
  cursor: pointer;
`;

export const TriggerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  ${PlusIcon} {
    width: 0.7em;
  }
`;
