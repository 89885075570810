import { call, getContext, put, take, race, delay } from 'redux-saga/effects';
import { patterns as checklistPatterns } from 'entities/checklists';
import {
  CONTENT_REVERTED,
  CONTENT_PUBLISHED,
  CONTENT_UNPUBLISHED,
  CONTENT_PUSHED,
} from 'actions/publishing';

import { reject, replace, patterns, CONTENT_STATUS_DROPPED } from './actions';

export const POLLING_INTERVAL = 6 * 1000;

const POLL_ACTIONS = [patterns.callApi];

const RE_POLL_ACTIONS = [
  checklistPatterns.flush,
  CONTENT_REVERTED,
  CONTENT_PUBLISHED,
  CONTENT_UNPUBLISHED,
  CONTENT_PUSHED,
];

const CANCEL_ACTIONS = [CONTENT_STATUS_DROPPED];

function* poll({ payload: id }) {
  const api = yield getContext('api');
  while (true) {
    try {
      // delay an extra 500ms to give the cloud functions a chance to sync
      yield delay(500);
      const { data: contentStatus } = yield call(api.getChecklistStatus, id);
      yield put(replace({ ...contentStatus, id }));
    } catch (error) {
      yield put(reject(error));
    } finally {
      yield race([delay(POLLING_INTERVAL), take(RE_POLL_ACTIONS)]);
    }
  }
}

function* dropContentStatus() {
  yield put(replace(null));
}

export default function* saga() {
  while (true) {
    const action = yield take(POLL_ACTIONS);
    yield race({
      task: call(poll, action),
      cancel: take(CANCEL_ACTIONS),
    });
    yield call(dropContentStatus, action);
  }
}
