import {
  actionStartMarkName,
  actionEndMarkName,
  actionMeasureName,
} from './timingNames';

export function supportsUserTimingApi() {
  return (
    window.performance &&
    typeof window.performance.mark === 'function' &&
    typeof window.performance.measure === 'function' &&
    typeof window.performance.getEntriesByType === 'function'
  );
}

// Helper for adding timing instructions that will be read by the
// actionTimingMiddleware to record timing marks using the User Timing API
function addTimingInstructionsToAction(action, instructions) {
  return {
    ...action,
    _timing: {
      ...instructions,
    },
  };
}

// Adds timing instructions to an action that tells the actionTimingMiddleware
// to add a mark using the User Timing API with the given action's type
export function addTimingMarkInstructionsToAction(action) {
  return addTimingInstructionsToAction(action, {
    type: 'add_mark',
    addMark: actionStartMarkName(action.type),
  });
}

// Adds timing instructions to an action that tells the actionTimingMiddleware
// to add a mark and a measure using the User Timing API with the given action's
// type
export function addTimingMeasureInstructionsToAction(action, startAction) {
  return addTimingInstructionsToAction(action, {
    type: 'add_measure',
    measureFromMark: actionStartMarkName(startAction),
    addMark: actionEndMarkName(action.type),
    addMeasure: actionMeasureName(startAction, action.type),
  });
}

export function getTimingInstructions({ _timing: timing }) {
  return timing;
}
