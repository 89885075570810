import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';
import toast from 'next/lib/toast';

const ClickContainer = styled.div`
  position: relative;
  display: inline-flex;
  max-width: 100%;
  background: white;
  line-height: 1.5em;
  &:hover {
    cursor: pointer;
  }
  .svg-inline--fa {
    margin-left: 5px;
    color: ${({ theme }) => theme.$green};
  }
`;

const ClickToCopy = ({ content, children, ...rest }) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCopied(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [isCopied]);

  const handleClick = () => {
    navigator.clipboard.writeText(content);
    setIsCopied(true);
    toast.success('Copied to clipboard');
  };

  return (
    <ClickContainer
      role="button"
      tabeindex="0"
      isCopied={isCopied}
      onClick={handleClick}
      onKeyDown={handleClick}
    >
      <Text title={content} {...rest}>
        {children}
      </Text>
      {isCopied && <Icon title="Text Copied" icon="check" />}
    </ClickContainer>
  );
};

ClickToCopy.propTypes = {
  content: PropTypes.string,
  children: PropTypes.node,
};

export default ClickToCopy;
