import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Heading,
  Paragraph,
  ModalFooter,
  Button,
} from '@studio/legacy-components';
import { MOBILE_PUBLISHING } from 'next/entities/features';
import { selectAccountFeature } from 'reducers/account/features';
import { asPromised } from 'utils/as-promised';
import { resetFlowHistory } from 'entities/user-qualified-content';

export function ResetFlowModal({
  hasMobilePublishing,
  visible,
  onClose,
  userId,
  onReset,
}) {
  const [inFlight, setInFlight] = useState(false);

  const handleResetFlowHistory = async () => {
    setInFlight(true);
    try {
      await onReset(userId);
      onClose();
    } finally {
      setInFlight(false);
    }
  };
  return (
    <Modal visible={visible} onClose={onClose} size="m">
      <Heading>Reset Flow history</Heading>
      <Paragraph>
        Reset this user's history of all {hasMobilePublishing ? 'Web' : ''}{' '}
        Flows they have seen. This action is helpful for retesting live Flows
        with a “Show once” frequency setting. Reporting will not be affected.
      </Paragraph>
      {hasMobilePublishing && (
        <Paragraph>
          Resetting Flow history does not apply to Mobile Flows.
        </Paragraph>
      )}
      <Paragraph>
        Are you sure you want to reset the Flow history for User ID {userId}?
      </Paragraph>
      <ModalFooter>
        <Button kind="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={inFlight}
          kind="negative"
          onClick={handleResetFlowHistory}
          aria-label="Confirm Reset Flow history"
        >
          Reset
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const mapStateToProps = state => ({
  hasMobilePublishing: selectAccountFeature(state, MOBILE_PUBLISHING),
});

const mapDispatchToProps = dispatch => ({
  onReset: id => asPromised(dispatch, resetFlowHistory(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetFlowModal);
