import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import {
  OptionWrapper,
  OptionImage,
  Image,
  OptionLabelWrapper,
  OptionLabel,
  SubLabel,
} from './styled';

export const TemplateOption = ({ data, innerProps, ...unhandledProps }) => (
  <components.Option
    data={data}
    innerProps={{
      ...innerProps,
      role: 'option',
    }}
    // the underlying  component from react-select requires to pass certain props
    {...unhandledProps}
  >
    <OptionWrapper>
      <OptionImage>
        <Image src={data.screenshotUrl} size={data.size} />
      </OptionImage>
      <OptionLabelWrapper>
        <OptionLabel>{data.label}</OptionLabel>
        <SubLabel> {data.description}</SubLabel>
      </OptionLabelWrapper>
    </OptionWrapper>
  </components.Option>
);

TemplateOption.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    description: PropTypes.string,
    screenshotUrl: PropTypes.string,
    size: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }),
  innerProps: PropTypes.shape({ role: PropTypes.string }),
};

export const TemplateSingleValue = ({ data, ...unhandledProps }) => (
  // the underlying  component from react-select requires to pass certain props
  <components.SingleValue data={data} {...unhandledProps}>
    {data.label}
  </components.SingleValue>
);

TemplateSingleValue.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
  }),
};
