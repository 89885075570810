import React from 'react';
import styled from 'styled-components';
import { Button, NOTICE_TYPES } from '@appcues/component-library';
import ExportModalStatusMessage from 'components/ModalContainer/ExportModal/ExportModalStatusMessage';

const ExportSuccessMessage = props => {
  const { email, hideModal, resetModal } = props;

  const boldedSection = 'Just a heads up: ';
  const statusText =
    'Depending on the size of the CSV, it might take 10-15 minutes to arrive in your inbox.';

  return (
    <div>
      <SuccessTextWrapper>
        <ChartImage>📈</ChartImage>
        <SuccessMessage className="message">
          Your CSV is on its way to {email}!
        </SuccessMessage>
        <ExportModalStatusMessage
          status={NOTICE_TYPES.warning}
          statusText={statusText}
        >
          <span>
            <strong>{boldedSection}</strong>
            {statusText}
          </span>
        </ExportModalStatusMessage>
      </SuccessTextWrapper>
      <ButtonWrapper>
        <Button onClick={resetModal}>Export Another CSV</Button>
        <Button onClick={hideModal} className="button-primary">
          Okay
        </Button>
      </ButtonWrapper>
    </div>
  );
};

const SuccessTextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const ChartImage = styled.span`
  font-size: 75px;
  margin-top: 2rem;
`;

const SuccessMessage = styled.p`
  width: 50%;
  text-align: center;

  &.message {
    margin: 5px 0 0 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 6px;

  .button {
    display: inline-block;
  }

  .button-primary {
    margin-left: 16px;
  }
`;

export default ExportSuccessMessage;
