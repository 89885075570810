import styled from 'styled-components';
import { ButtonGroup, Text, WarningNotice } from '@studio/legacy-components';

export const Wrapper = styled.div`
  > ${Text} {
    margin-bottom: 24px;
  }
`;

export const Note = styled.span`
  font-weight: var(--bold);
`;

export const Form = styled.form`
  margin-top: 12px;

  ${ButtonGroup} {
    margin-top: 16px;
  }
`;

export const Disclaimer = styled(WarningNotice)`
  margin-top: 16px;
`;
