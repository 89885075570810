import PropTypes from 'prop-types';

export const OptionShape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
});

const OptionsListShape = PropTypes.arrayOf(OptionShape);

export const SelectOptionsShape = PropTypes.oneOfType([
  OptionsListShape,
  PropTypes.shape({
    label: PropTypes.string,
    options: OptionsListShape,
  }),
]);

export const AttributeSuggestionsShape = PropTypes.objectOf(
  PropTypes.arrayOf(PropTypes.string)
);

export const FormikShape = {
  errors: PropTypes.objectOf(PropTypes.string),
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  resetForm: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  touched: PropTypes.objectOf(PropTypes.bool),
};

export const ContentStateShape = PropTypes.oneOf([
  'DRAFT',
  'ARCHIVED',
  'PUBLISHED',
  'VERSIONED',
]);
