import { call, put, takeEvery, getContext, select } from 'redux-saga/effects';
import merge from 'lodash.merge';

import { ENTITLEMENTS } from 'constants/features';
import { reportError } from 'helpers/error-reporting';
import { patterns, replace, reject, resolve } from 'actions/entitlements';
import { selectAccountFeature } from 'reducers/account/features';

const transform = response =>
  response.reduce((acc, entitlement) => {
    acc[entitlement.id] = entitlement;
    return acc;
  }, {});

export function* fetchEntitlements() {
  try {
    const isFeatureEnabled = yield select(selectAccountFeature, ENTITLEMENTS);

    if (isFeatureEnabled) {
      const api = yield getContext('api');
      const { data: accountEntitlements } = yield call(
        api.getAccountEntitlements
      );
      const { data: planEntitlements } = yield call(
        api.getPlanEntitlements,
        'essentials'
      );

      yield put(
        resolve(
          merge(transform(accountEntitlements), transform(planEntitlements))
        )
      );
    }
  } catch (error) {
    yield call(reportError, error);
    yield put(reject(error));
  }
}

export function* fetchAccountEntitlement({ payload: { id } }) {
  try {
    const isFeatureEnabled = yield select(selectAccountFeature, ENTITLEMENTS);

    if (isFeatureEnabled) {
      const api = yield getContext('api');
      const { data } = yield call(api.getAccountEntitlement, id);
      yield put(replace(data));
    }
  } catch (error) {
    yield call(reportError, error);
    yield put(reject(error));
  }
}

export default function* saga() {
  yield takeEvery([patterns.read, patterns.flush], fetchEntitlements);
  yield takeEvery(patterns.readOne, fetchAccountEntitlement);
}
