import { call } from 'redux-saga/effects';

const hasPromise = (meta = {}) =>
  typeof meta.resolve === 'function' && typeof meta.reject === 'function';

/**
 * action enhancer for tracking saga status with a promise
 *
 * @param {function} dispatch - Redux `store.dispatch`
 * @param {Action} action - an FSA-compliant Redux action
 *
 * @return {Promise} - the resulting promise will resolve when the appropriate
 *                     wrapped saga resolves so that the UI can update
 */
export function asPromised(dispatch, { type, payload, meta = {}, error }) {
  return new Promise((resolve, reject) => {
    dispatch({ type, payload, meta: { ...meta, resolve, reject }, error });
  });
}

/**
 * saga enhancer for consuming promise-enhanced actions
 *
 * @param {Generator} saga - the saga to enhance
 *
 * @return {Generator} - an intermediate saga which delegates to the provided saga
 *                       and consumes the promise
 */
export function promisaga(saga) {
  return function* generator(action) {
    const { meta = {} } = action;
    if (hasPromise(meta)) {
      try {
        const value = yield call(saga, action);
        yield meta.resolve(value);
      } catch (error) {
        yield meta.reject(error);
      }
    } else {
      yield* saga(action);
    }
  };
}
