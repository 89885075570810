import { takeEvery, put, select, take, call } from 'redux-saga/effects';
import { reportError } from 'helpers/error-reporting';

import * as userProfileActions from 'actions/userProfiles';
import { FETCH_USER_PROFILE } from 'constants/actionTypes';
import { selectAccountUserProfileMeta } from 'reducers/account/userProfiles';
import {
  selectAccountId,
  selectAccountMetaSynced,
  selectAccountUuid,
} from 'reducers/account/meta';
import { getUserProfile } from 'helpers/api';
import { REPLACE_ACCOUNT_META } from 'constants/account/meta';

export function* fetchUserProfile(action) {
  const { userId } = action.payload;

  try {
    if (!userId) {
      throw new Error('no user id provided to fetch user profile');
    }

    const userProfileMeta = yield select(selectAccountUserProfileMeta, userId);
    const { fetching, errored, synced } = userProfileMeta;

    if (errored || (!synced && !fetching)) {
      const accountMetaSynced = yield select(selectAccountMetaSynced);

      if (!accountMetaSynced) {
        yield take(REPLACE_ACCOUNT_META);
      }

      const accountId = yield select(selectAccountId);
      const accountUuid = yield select(selectAccountUuid);

      yield put(userProfileActions.setUserProfileFetching(userId));

      const profile = yield call(
        getUserProfile,
        accountId,
        userId,
        accountUuid
      );

      yield put(userProfileActions.setUserProfileSuccess(userId, profile));
    }
  } catch (error) {
    yield put(userProfileActions.setUserProfileErrored(userId, error));
    yield call(reportError, error);
  }
}

export default function* userProfile() {
  yield takeEvery(FETCH_USER_PROFILE, fetchUserProfile);
}
