import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Link, Td, Tr } from '@studio/legacy-components';
import { encodeEventNameUrl } from 'components/Insights/utils';
import { PIN_BUTTON_TYPE } from 'components/Insights/constants';
import EventToggle from 'components/Insights/Explorer/Common/EventToggle';

import { SelectColumn } from 'components/Insights/Common/Styled';

export const PinExpandedRow = ({
  id,
  stepId,
  stepName,
  type,
  eventType,
  parentName,
  events,
  users,
  most_recent_timestamp,
  source,
  enableSelect,
  checked,
  onSelect,
}) => {
  const singleMetricUrl = `/insights/explorer/pin/${encodeEventNameUrl(
    eventType,
    source
  )}`;

  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  if (!searchParams.has('id')) {
    searchParams.append('id', id);
  }

  if (!searchParams.has('stepId')) {
    searchParams.append('stepId', stepId);
  }

  const eventValue = events
    ? `${events.toLocaleString()} ${
        type === PIN_BUTTON_TYPE
          ? `click${events !== 1 ? 's' : ''}`
          : `open${events !== 1 ? 's' : ''}`
      }`
    : '-';

  return (
    <Tr>
      <SelectColumn>
        <EventToggle
          label={`Select  Pin Event: ${parentName} ${stepName}`}
          checked={checked}
          disabled={!checked && !enableSelect}
          onClick={() => {
            onSelect({
              source,
              event: eventType,
              id,
              stepId,
            });
          }}
        />
      </SelectColumn>
      <Td>
        <Link
          to={{
            pathname: singleMetricUrl,
            search: searchParams.toString(),
          }}
          title={`${parentName} ${stepName} detail page`}
        >
          {stepName}: {type === PIN_BUTTON_TYPE ? 'Button' : 'Tooltip'}
        </Link>
      </Td>
      <Td />
      <Td>{eventValue}</Td>
      <Td>{users ? users.toLocaleString() : '-'}</Td>
      <Td>
        {most_recent_timestamp
          ? moment(most_recent_timestamp).format('lll')
          : '-'}
      </Td>
    </Tr>
  );
};

PinExpandedRow.propTypes = {
  id: PropTypes.string,
  stepId: PropTypes.string,
  stepName: PropTypes.string,
  type: PropTypes.string,
  source: PropTypes.string,
  eventType: PropTypes.string,
  parentName: PropTypes.string,
  events: PropTypes.number,
  users: PropTypes.number,
  most_recent_timestamp: PropTypes.string,
  enableSelect: PropTypes.bool,
  checked: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default PinExpandedRow;
