import {
  getFlattenedClausesFromNestedConditions,
  propertyOperators,
} from '@appcues/libcues';
import { ESTIMATED_DATA_PERIOD } from 'constants/segments';

export const getMatchingSegments = (segmentId, segments) =>
  Object.keys(segments || {}).filter(currentSegmentId => {
    const clauses = getFlattenedClausesFromNestedConditions(
      segments[currentSegmentId].conditions || {}
    );
    return clauses.find(({ segment }) => segment === segmentId);
  });

export const getMatchingChecklists = (segmentId, checklists) =>
  Object.keys(checklists || {}).filter(checklistId => {
    const clauses = getFlattenedClausesFromNestedConditions(
      checklists[checklistId].rule.conditions || {}
    );
    return clauses.find(({ segment }) => segment === segmentId);
  });

/* currently does not do exhaustive check for empty segments,
  just checks to see if, based on its conditions, it appears 
  to be a newly-created segment */
export const conditionsAreEmpty = segment => {
  const { conditions } = segment;
  if (
    !conditions ||
    typeof conditions !== 'object' ||
    Object.keys(conditions).length === 0
  ) {
    return true;
  }

  const valuelessOperators = {
    [propertyOperators.EXISTS]: true,
    [propertyOperators.NOT_EXISTS]: true,
  };

  const clauseArrs = Object.values(conditions);
  return clauseArrs.reduce((isEmptyBool, clauseArr) => {
    // if not empty, bail early
    if (!isEmptyBool) {
      return false;
    }

    return clauseArr.reduce((isEmptyArr, clauseObj) => {
      if (!isEmptyArr) {
        return false;
      }

      if (!clauseObj.properties) {
        // rule has nested rules -- assume it has been set up by user
        // @todo recurse through nested rules and verify all conditions
        return false;
      }

      const { property, value, operator } = clauseObj.properties;
      return !(property && (valuelessOperators[operator] || value));
    }, true);
  }, true);
};

export const segmentUsersArrToObj = segmentUsersArr => {
  const userCountsBySegment = {};
  segmentUsersArr.forEach(segmentStatsObj => {
    userCountsBySegment[segmentStatsObj.segment_id] = segmentStatsObj;
  });
  return userCountsBySegment;
};

// segments younger than 7 days use estimated data
export const shouldUseEstimateData = segment => {
  return Date.now() - segment.updatedAt < ESTIMATED_DATA_PERIOD;
};

export const isLifecycleSegment = segment => {
  return !!(segment && segment.meta && segment.meta.userLifecycleStage);
};

export default {
  getMatchingSegments,
  getMatchingChecklists,
  conditionsAreEmpty,
  segmentUsersArrToObj,
  shouldUseEstimateData,
  isLifecycleSegment,
};
