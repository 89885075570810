import React from 'react';
import { connect } from 'react-redux';
import { SALESFORCE_INTEGRATION } from 'constants/features';
import { selectAccountFeature } from 'reducers/account/features';
import SalesForceIntegrationModal from './SalesForceIntegrationModal';
import SalesforceInformationModal from './SalesforceInformationModal';

const SalesforceBetaIntegrationModal = props => {
  const { hasSalesforceIntegration } = props;

  return hasSalesforceIntegration ? (
    <SalesForceIntegrationModal {...props} />
  ) : (
    <SalesforceInformationModal {...props} />
  );
};

const mapStateToProps = state => ({
  hasSalesforceIntegration: selectAccountFeature(state, SALESFORCE_INTEGRATION),
});

export default connect(mapStateToProps)(SalesforceBetaIntegrationModal);
