import React from 'react';
import styled from 'styled-components';
import FriendlyDescription from 'components/Common/FriendlyDescription';

const Cell = styled.td`
  padding: 0.35em 1em;
  vertical-align: bottom;
  &:first-of-type {
    font-weight: bold;
  }
`;

const TargetingDetailsRow = ({ label, clauses, omitTailPropertyNames }) => (
  <tr>
    <Cell>{label}:</Cell>
    <Cell>
      <FriendlyDescription
        lines={3}
        clauses={clauses}
        omitTailPropertyNames={omitTailPropertyNames}
      />
    </Cell>
  </tr>
);

export default TargetingDetailsRow;
