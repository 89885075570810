import React from 'react';

import { Link } from '@studio/legacy-components';
import GuideStep from 'components/InstallationGuide/GuideStep';
import GuideParagraph from 'components/InstallationGuide/GuideParagraph';
import GuideHeading from 'components/InstallationGuide/GuideHeading';
import CopyableCodeSnippet from 'components/Common/CopyableCodeSnippet';

export const InstallOverview = ({
  accountId,
  moveForward,
  moveBackward,
  stepMax,
  stepIndex,
}) => (
  <GuideStep
    stepIndex={stepIndex}
    title="Installation overview"
    stepMax={stepMax}
    customNextText="Next"
    moveForward={moveForward}
    moveBackward={moveBackward}
  >
    <GuideParagraph>
      To see and publish Appcues experiences, you (or your engineering team)
      need to add code to your product. This guide walks you through the most
      common paths to install Appcues properly.
    </GuideParagraph>

    <GuideHeading>Step 1) Add the Appcues SDK snippet</GuideHeading>
    <GuideParagraph>
      Place the following code snippet at the top of the document. The Appcues
      ID is in your settings page:{' '}
      <Link href="/settings/account">
        https://studio.appcues.com/settings/account
      </Link>{' '}
      in the <strong>“Account ID”</strong> field. Make sure to add the script on
      every page you want to use Appcues.
    </GuideParagraph>
    <CopyableCodeSnippet language="markup">
      {`
        <script type="text/javascript">
          window.AppcuesSettings = { enableURLDetection: true };
        </script>
        <script src="//fast.appcues.com/${accountId}.js">
        </script>
      `}
    </CopyableCodeSnippet>

    <GuideHeading>Step 2) Identify users</GuideHeading>
    <GuideParagraph>
      An identification call is required to target content and review activity
      for an individual user based on identifying information like email, user
      ID, role, created date, etc.
    </GuideParagraph>

    <GuideHeading>Step 3) Establish accounts</GuideHeading>
    <GuideParagraph>
      A group identification call is required to target content and review
      activity for an account based on identifying information like account id,
      plan, renewal date, created date, etc.
    </GuideParagraph>

    <GuideHeading>Step 4) Track events</GuideHeading>
    <GuideParagraph>
      To see and message based on actions people take in your product, you need
      to track events. There are multiple ways to do this, with and without
      adding code to your product.
    </GuideParagraph>
    <GuideParagraph>
      Installing the Appcues SDK snippet, identifying audiences, and tracking
      events should take a developer an hour or more of work. After you complete
      those steps, you’ll have a successful Appcues implementation and can start
      publishing and reviewing activity.
    </GuideParagraph>
  </GuideStep>
);

export default InstallOverview;
