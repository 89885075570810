import React, { useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getBannerTargetingSections,
  getLeafNodes,
  getSegments,
} from '@studio/conditions';
import {
  readDomainsForExperience,
  readSegmentsForExperience,
  readExperienceTags,
} from 'next/lib/selectors';
import { selectRule } from 'next/entities/rules';
import { openExperience } from 'next/lib/builder';
import { Shape as ExperienceShape, update } from 'next/entities/experiences';
import useToggle from 'next/hooks/use-toggle';
import { Experience } from 'next/components/Listing';
import { OpenInBuilderManager } from 'next/components/OpenInBuilder';
import { EditTagsModal } from 'next/components/Tags';
import ExperienceContext from 'next/components/ExperienceContext';
import { navigate } from 'next/lib/history';
import { selectFeature, LAUNCHPADS_V2_ANALYTICS } from 'next/entities/features';
import Actions from './Actions';
import { ExperienceCardWrapper } from './styled';

export function ExperienceCard({
  domains,
  experience,
  segments,
  tags,
  view,
  updateExperience,
  style,
  deferred,
  hasLaunchpadAnalyticsEnabled,
}) {
  const { id, previewUrl, state } = experience;
  const { type, routePath } = useContext(ExperienceContext);

  const [editingTags, toggleEditingTags] = useToggle();
  const [openBuilder, toggleOpenBuilder] = useToggle();

  const handleOpenInBuilder = useCallback(() => {
    openExperience({ experience: id, url: previewUrl, pattern: type });
  }, [id, previewUrl, type]);

  const handleTagsUpdate = tagIds => {
    updateExperience(id, { tagIds });
  };

  const openStandaloneBuilderInStudio = () => {
    navigate(`${routePath}/${id}/builder`);
  };

  const redirectPath =
    experience.published &&
    (routePath !== '/launchpads' || hasLaunchpadAnalyticsEnabled)
      ? `${routePath}/${id}/analytics`
      : `${routePath}/${id}/settings`;

  const settingsLink = `${routePath}/${id}/settings`;

  return (
    <ExperienceCardWrapper style={style}>
      <Experience
        actions={
          <Actions
            archived={state === 'ARCHIVED'}
            experienceId={id}
            onOpenBuilder={
              routePath !== '/launchpads'
                ? toggleOpenBuilder
                : openStandaloneBuilderInStudio
            }
            onOpenEditTags={toggleEditingTags}
          />
        }
        experience={experience}
        domains={domains}
        segments={segments}
        tags={tags}
        type={type}
        view={view}
        deferred={deferred}
        redirectTo={redirectPath}
        settingsLink={settingsLink}
      />

      <OpenInBuilderManager
        onClose={toggleOpenBuilder}
        onNavigate={handleOpenInBuilder}
        type={type}
        visible={openBuilder}
      />
      <EditTagsModal
        tags={tags?.data}
        onClose={toggleEditingTags}
        visible={editingTags}
        label={experience.name}
        onChange={handleTagsUpdate}
      />
    </ExperienceCardWrapper>
  );
}

ExperienceCard.propTypes = {
  ...Experience.propTypes,
  experience: ExperienceShape,
  hasLaunchpadAnalyticsEnabled: PropTypes.bool,
};

const mapDispatchToProps = dispatch => ({
  updateExperience: (id, delta) => dispatch(update(id, delta)),
});

const mapStateToProps = (state, { experience }) => {
  const rule = selectRule(state, experience.id) ?? {};
  const sections = getBannerTargetingSections(rule?.conditions);
  const audiences = getLeafNodes(sections.audience);
  const hasSegments = !sections.audience || getSegments(audiences).length > 0;

  return {
    domains: readDomainsForExperience(state, experience.id),
    tags: readExperienceTags(state, experience.id),
    hasLaunchpadAnalyticsEnabled: selectFeature(state, LAUNCHPADS_V2_ANALYTICS),
    ...(hasSegments && {
      segments: readSegmentsForExperience(state, experience.id),
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExperienceCard);
