import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button, H3 } from '@studio/legacy-components';
import {
  NotPublishedWrapper,
  NotPublishedImage,
  NotPublishedDescription,
} from './styled';

export default function NotPublished({
  title,
  description,
  action: { link, label },
}) {
  return (
    <NotPublishedWrapper>
      <NotPublishedImage />
      <H3>{title}</H3>
      <NotPublishedDescription>{description}</NotPublishedDescription>
      <Button aria-label="Settings" forwardedAs={Link} to={link}>
        {label}
      </Button>
    </NotPublishedWrapper>
  );
}

NotPublished.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.exact({
    label: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};
