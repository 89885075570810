import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TooltipWrapper, TooltipHeader, TooltipTitle } from '@studio/charts';
import { numberWithAbbrevMillions } from 'utils/textFormatting';

const Grid = styled.div`
  display: grid;
  padding: 12px 24px 12px 24px;
  grid-template-columns: auto auto auto;
`;

const EventClamp = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  max-height: 3.4em;
  line-height: 1.4em;
  margin: 0;
`;

const GridItem = styled.div`
  margin: 0px 8px 8px 0px;
  display: flex;
  align-items: center;
`;

const NameHeader = styled(GridItem)`
  font-weight: bold;
  grid-column: 1 / 3;
`;

const ValueHeader = styled(GridItem)`
  font-weight: bold;
  grid-column: 3 / 4;
  text-align: center;
  justify-content: center;
`;

const Bullet = styled(GridItem)`
  font-size: 20px;
  margin-top: -4px;
  color: ${props => props.color};
`;

const Name = styled(GridItem)`
  width: 250px;
`;

const Value = styled(GridItem)`
  font-weight: bold;
  text-align: center;
  justify-content: center;
`;

const Tooltip = ({
  label,
  headers: [header1, header2] = ['Name', 'Total'],
  series = [],
}) => {
  return (
    <TooltipWrapper>
      <TooltipHeader>
        <TooltipTitle size="xs">{label}</TooltipTitle>
      </TooltipHeader>
      <Grid>
        <NameHeader>{header1}</NameHeader>
        <ValueHeader>{header2}</ValueHeader>
        {series.map(({ color, name, value }) => (
          <>
            <Bullet color={color}>&#8226;&nbsp;</Bullet>
            <Name>
              <EventClamp>{name}</EventClamp>
            </Name>
            <Value>{numberWithAbbrevMillions(value)}</Value>
          </>
        ))}
      </Grid>
    </TooltipWrapper>
  );
};

Tooltip.propTypes = {
  label: PropTypes.number,
  headers: PropTypes.arrayOf(PropTypes.string),
  series: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ),
};

export default Tooltip;
