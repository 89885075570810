import React from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { selectAccountChecklist } from 'entities/checklists';
import ColorPicker from 'components/Common/ColorPicker';
import FieldGroup from 'components/Common/Forms/FieldGroup';

const ColorCustomizationContainer = styled.div`
  border: 2px dashed ${props => props.theme['$gray-3']};
  border-radius: ${props => props.theme['$border-radius']};
  padding: 1rem;
  margin-right: 25px;
  margin-top: 1rem;
`;

const Header = styled.h2`
  margin: 0 0 1rem 0 !important;
`;

export function ExtraColorCustomization({
  customBackgroundColor,
  customFontColor,
  customHoverStateColor,
  updateChecklistStyles,
  updateChecklistStylesSlider,
}) {
  const handleBackgroundColorSliderChange = hex => {
    updateChecklistStylesSlider('customBackgroundColor', hex);
  };

  const handleFontColorSliderChange = hex => {
    updateChecklistStylesSlider('customFontColor', hex);
  };

  const handleHoverColorSliderChange = hex => {
    updateChecklistStylesSlider('customHoverStateColor', hex);
  };

  const handleChecklistBackgroundColorUpdate = input => {
    updateChecklistStyles('customBackgroundColor', input);
  };

  const handleChecklistFontColorUpdate = input => {
    updateChecklistStyles('customFontColor', input);
  };

  const handleChecklistHoverColorUpdate = input => {
    updateChecklistStyles('customHoverStateColor', input);
  };

  return (
    <ColorCustomizationContainer>
      <Header>Custom Color Configuration</Header>
      <FieldGroup title="Background Color" className="configuration-item">
        <ColorPicker
          className="test-background-color"
          color={customBackgroundColor}
          onChangeInput={handleChecklistBackgroundColorUpdate}
          onChangeSlider={handleBackgroundColorSliderChange}
        />
      </FieldGroup>
      <FieldGroup title="Font Color" className="configuration-item">
        <ColorPicker
          className="test-font-color"
          color={customFontColor}
          onChangeInput={handleChecklistFontColorUpdate}
          onChangeSlider={handleFontColorSliderChange}
        />
      </FieldGroup>
      <FieldGroup title="Hover Background Color" className="configuration-item">
        <ColorPicker
          className="test-hover-color"
          color={customHoverStateColor}
          onChangeInput={handleChecklistHoverColorUpdate}
          onChangeSlider={handleHoverColorSliderChange}
        />
      </FieldGroup>
    </ColorCustomizationContainer>
  );
}

const mapStateToProps = (state, { checklistId }) => {
  const checklist = selectAccountChecklist(state, checklistId);
  const customBackgroundColor =
    (checklist.styles && checklist.styles.customBackgroundColor) || '#ffffff';
  const customFontColor =
    (checklist.styles && checklist.styles.customFontColor) || '#555555';
  const customHoverStateColor =
    (checklist.styles && checklist.styles.customHoverStateColor) || '#eeeeee';

  return {
    customBackgroundColor,
    customFontColor,
    customHoverStateColor,
  };
};

export default connect(mapStateToProps)(withTheme(ExtraColorCustomization));
