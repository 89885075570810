import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  Panel,
  H3,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  PanelHeader,
  PanelTitle,
} from '@studio/legacy-components';
import Loader from 'components/Common/Loader';
import { callApi } from 'actions/account/published-rules';
import {
  selectArePublishedRulesSynced,
  selectPublishedFlowRules,
} from 'reducers/account/published-rules';
import { selectAreFlowsSynced } from 'reducers/account/flows';
import FlowTableRow from './FlowTableRow';

const TableContainer = styled.div`
  position: relative;

  ${props => !props.loaded && `min-height: 240px`};

  ${Table} {
    ${Th}:nth-child(1), ${Th}:nth-child(2) {
      width: 300px;
    }
  }

  ${Loader} {
    position: absolute;
    height: 100%;
    width: 100%;
    padding-top: 24px;
  }
`;

export const FlowTable = ({ loaded, publishedRules, onLoad }) => {
  useEffect(() => {
    onLoad?.();
  }, []);

  const publishedFlowRules = Object.values(publishedRules ?? {}).filter(
    rule => rule.content_type !== 'satisfaction-survey'
  );

  return (
    <Panel>
      <PanelHeader>
        <PanelTitle>
          <H3>Live Flows</H3>
          <span>
            If a user qualifies to see multiple Flows on a page, they will see
            one Flow per page-view following the order of this Flow priority
            table, top-down.
          </span>
        </PanelTitle>
      </PanelHeader>
      <TableContainer loaded={loaded}>
        {!loaded && <Loader />}
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Page</Th>
              <Th>Flow weight</Th>
              <Th>Last published</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loaded &&
              publishedFlowRules.map(
                ({
                  id: flowId,
                  conditions,
                  sort_priority: sortPriority,
                  updated_at: updatedAt,
                }) => (
                  <FlowTableRow
                    key={flowId}
                    flowId={flowId}
                    conditions={conditions}
                    sortPriority={sortPriority}
                    updatedAt={updatedAt}
                  />
                )
              )}
          </Tbody>
        </Table>
      </TableContainer>
    </Panel>
  );
};

FlowTable.propTypes = {
  loaded: PropTypes.bool,
  publishedRules: PropTypes.objectOf(
    PropTypes.shape({
      conditions: PropTypes.object,
      id: PropTypes.string,
      published_at: PropTypes.string,
      sort_priority: PropTypes.number,
    })
  ),
  onLoad: PropTypes.func,
};

const mapStateToProps = state => {
  const flowsLoaded = selectAreFlowsSynced(state);
  const publishedRulesLoaded = selectArePublishedRulesSynced(state);
  return {
    loaded: flowsLoaded && publishedRulesLoaded,
    publishedRules: selectPublishedFlowRules(state),
  };
};

const mapDispatchToProps = {
  onLoad: callApi,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlowTable);
