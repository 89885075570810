import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { usePagination } from '@appcues/sonar';
import {
  selectEventUserProfileResults,
  selectTotalResults,
  read as fetchEventUserProfiles,
} from 'next/entities/event-user-profiles';
import { getCacheKey } from 'next/lib/caching';
import { readAccount } from 'next/entities/account';
import { RecentUsersNew } from './RecentUsersNew';

export const SORT_DIRECTION = {
  /** Ascending sort */
  Asc: 'ASC',
  /** Descending sort */
  Desc: 'DESC',
};

export const CONTENT_TYPE = {
  Pin: 'PIN',
  Banner: 'BANNER',
  'Mobile Flow': 'MOBILE_FLOW',
  'Web Flow': 'WEB_FLOW',
  Goal: 'GOAL',
  Checklist: 'CHECKLIST',
};

export const EVENT_NAMES_FOR_TYPE = {
  Pin: ['appcues:v2:step_seen', 'appcues:v2:step_interaction'],
  Banner: ['appcues:v2:step_seen', 'appcues:v2:step_interaction'],
  'Mobile Flow': [
    'appcues:v2:experience_started',
    'appcues:v2:experience_started',
  ],
};

export function RecentUsersNewProvider({
  type,
  id,
  endTime,
  startTime,
  limit,
  userProfileIdentifier,
  onLoad,
  getTotalResults,
  getRecentUsers,
}) {
  const [loading, setLoading] = useState(false);

  const { pageIndex, pageSize, setPagination, deriveAriaLabel } =
    usePagination(limit);

  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.Desc);

  const toggleSortDirection = useCallback(() => {
    setSortDirection(prev =>
      prev === SORT_DIRECTION.Asc ? SORT_DIRECTION.Desc : SORT_DIRECTION.Asc
    );

    setPagination(prev => ({ ...prev, pageIndex: 0 }));
  }, [setPagination]);

  const queryParams = useMemo(
    () => ({
      eventNames: EVENT_NAMES_FOR_TYPE[type],
      propertyNames: [userProfileIdentifier],
      contentId: id,
      contentType: CONTENT_TYPE[type],
      startTime,
      endTime,
      direction: sortDirection,
      limit: pageSize,
      offset: pageIndex * pageSize,
    }),
    [
      id,
      userProfileIdentifier,
      type,
      startTime,
      endTime,
      sortDirection,
      pageSize,
      pageIndex,
    ]
  );

  const cacheKey = getCacheKey(queryParams);

  const totalResults = getTotalResults?.(cacheKey);
  const recentUsers = getRecentUsers?.(cacheKey);

  // derive the pagination aria label for the table
  const ariaLabel = deriveAriaLabel(totalResults);

  useEffect(() => {
    const load = async () => {
      // use the cached data if it exists
      if (recentUsers) return;

      setLoading(true);
      await onLoad?.(queryParams, cacheKey);
      setLoading(false);
    };

    if (cacheKey) load();

    return () => setLoading(false);
  }, [recentUsers, onLoad, queryParams, cacheKey]);

  // usersToDisplay is used so that when paginating, the list of users remains visible
  // behind a loading state overlay while the fetch of the next page is happening
  const [displayValues, setDisplayValues] = useState({
    recentUsers,
    totalResults,
  });

  useEffect(() => {
    setDisplayValues({ recentUsers, totalResults });
  }, [recentUsers, totalResults]);

  return (
    <RecentUsersNew
      type={type}
      recentUsers={displayValues.recentUsers}
      totalResults={displayValues.totalResults}
      userProfileIdentifier={userProfileIdentifier}
      loading={loading}
      sortDirection={sortDirection}
      toggleSortDirection={toggleSortDirection}
      pageIndex={pageIndex}
      pageSize={pageSize}
      setPagination={setPagination}
      ariaLabel={ariaLabel}
    />
  );
}

RecentUsersNewProvider.propTypes = {
  id: PropTypes.string,
  endTime: PropTypes.string,
  startTime: PropTypes.string,
  limit: PropTypes.number,
  type: PropTypes.string,
  userProfileIdentifier: PropTypes.string,
  onLoad: PropTypes.func,
  getTotalResults: PropTypes.func,
  getRecentUsers: PropTypes.func,
};

const mapStateToProps = state => ({
  userProfileIdentifier:
    readAccount(state)?.data?.userProfileIdentifier || 'userId',
  getRecentUsers: cacheKey => selectEventUserProfileResults(state, cacheKey),
  getTotalResults: cacheKey => selectTotalResults(state, cacheKey),
});

const mapDispatchToProps = dispatch => ({
  onLoad: (queryParams, cacheKey) =>
    dispatch(fetchEventUserProfiles({ queryParams, cacheKey })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecentUsersNewProvider);
