import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { CButton, H2, H4 } from '@appcues/component-library';
import Prism from 'prismjs';
import { navigate } from 'actions/routing';
import 'themes/a11y-dark.css';

export class GuideStep extends Component {
  componentDidMount() {
    window.scroll(0, 0);
    Prism.highlightAll();
  }

  componentDidUpdate() {
    Prism.highlightAll();
  }

  handleFinishGuide = () => {
    const { actions } = this.props;

    actions.navigate('/settings/installation');
  };

  render() {
    const {
      children,
      customNextText,
      customPrevText,
      moveBackward,
      moveForward,
      stepMax,
      stepIndex,
      title,
    } = this.props;

    const firstStep = stepIndex === 0;
    const stepNumber = stepIndex + 1;

    return (
      <StepWrapper className="language-javascript">
        <ContentBox>
          <HeaderWrapper>
            <H2>{title}</H2>
            <H4 type="secondary">
              STEP {stepNumber} OF {stepMax}
            </H4>
          </HeaderWrapper>
          {children}
        </ContentBox>
        <ButtonWrapper firstStep={firstStep}>
          {!firstStep && (
            <CButton
              className="long-button"
              onClick={moveBackward}
              type="secondary"
            >
              {customPrevText || 'Back'}
            </CButton>
          )}
          <CButton
            className="long-button"
            type="primary"
            onClick={
              customNextText === 'Finish Guide'
                ? this.handleFinishGuide
                : moveForward
            }
          >
            {customNextText || 'Next'}
          </CButton>
        </ButtonWrapper>
      </StepWrapper>
    );
  }
}

const StepWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentBox = styled.div`
  margin-top: 2rem;
  width: 90%;
  max-width: 1200px;
  background-color: ${props => props.theme['$gray-0']};
  box-shadow: 0 2px 2px rgba(71, 88, 114, 0.08);
  padding: 40px;
  border-radius: 6px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const ButtonWrapper = styled.div`
  width: 90%;
  display: flex;
  max-width: 1200px;
  justify-content: ${props => (props.firstStep ? 'flex-end' : 'space-between')};
  margin-top: 24px;
`;

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        navigate,
      },
      dispatch
    ),
  };
}

export default connect(null, mapDispatchToProps)(GuideStep);
