import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  H3,
  Paragraph,
  Link,
  StatusLabel,
  Card,
  Button,
  ExternalLink,
} from '@studio/legacy-components';
import { history } from 'next/lib/history';
import WebIcon from './WebIcon';
import { Footer, WebBody, UpperContent } from './styled';

const IconColumn = styled.div`
  margin-right: 8px;
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  height: 100%;

  ${H3} {
    margin-bottom: 8px;
  }

  ${Link} {
    color: var(--primary);

    &:hover {
      color: var(--primary-dark);
    }
  }
`;

const WebCard = ({ installed }) => (
  <Card>
    <Content>
      <IconColumn>
        <WebIcon />
      </IconColumn>
      <WebBody>
        <UpperContent>
          <H3>Web Applications</H3>
          <Paragraph>
            Install Appcues on your web domains, add user properties or events,
            and more. Or, use an alternate way to install like{' '}
            <ExternalLink href="https://docs.appcues.com/en_US/dev-installing-appcues/installing-with-segment">
              Segment
            </ExternalLink>{' '}
            or{' '}
            <ExternalLink href="https://docs.appcues.com/dev-installing-appcues/installing-with-google-tag-manager-developer">
              Google Tag manager
            </ExternalLink>
            .
          </Paragraph>
        </UpperContent>
        <Footer>
          <Button
            kind="primary"
            onClick={() =>
              history.push('/settings/installation/guide/overview')
            }
          >
            Install
          </Button>
          <StatusLabel
            active={installed}
            icon={installed ? 'check-circle' : 'minus-circle'}
            label={installed ? 'Installed' : 'Not installed'}
          />
        </Footer>
      </WebBody>
    </Content>
  </Card>
);

WebCard.propTypes = {
  installed: PropTypes.bool,
};

export default WebCard;
