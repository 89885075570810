import { call, getContext, put, takeLatest, select } from 'redux-saga/effects';
import { navigate } from 'next/lib/history';
import { INITIALIZE } from 'next/root/actions';
import { switchAccount } from 'next/lib/auth';
import { selectUser } from 'next/entities/user';

import { reject, resolve, ACCOUNT_SWITCHED } from './actions';

/**
 * TODO: Add toast notifications on success/failure
 */

/**
 * Normalize accounts response as collection
 *
 * @param {Response} response - Raw accounts response
 * @return {Collection} Accounts collection
 */
const normalize = ({ accounts }) => accounts;

function* fetchAccounts() {
  try {
    const { id } = yield select(selectUser);
    const api = yield getContext('api');
    const response = yield call(api.getAccounts, id);
    yield put(resolve(normalize(response)));
  } catch (error) {
    yield put(reject(error));
  }
}

function* changeAccount({ payload: id }) {
  try {
    yield call(switchAccount, id);
    yield call(navigate, '/');
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  yield takeLatest(ACCOUNT_SWITCHED, changeAccount);
  yield takeLatest(INITIALIZE, fetchAccounts);
}
