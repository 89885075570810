import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  getNestedConditionsFromFlattenedClauses,
  clauseKeys,
} from '@appcues/libcues';
import BaseModal from 'components/ModalContainer/BaseModal';
import FieldGroup from 'components/Common/Forms/FieldGroup';
import Input from 'components/Common/Forms/Input';
import Loader from 'components/Common/Loader';
import ItemAction from 'components/ChecklistsEdit/ItemAction';
import ItemCompletion from 'components/ChecklistsEdit/ItemCompletion';
import {
  getItemClauses,
  getItemAction,
  determineFlowCompletedOnly,
  determineFlowCompletionStepId,
} from 'helpers/checklist-item-presets';
import { setChecklistFlowCompleted } from 'helpers/rules';
import { updateItem } from 'entities/checklists';
import { hideModal } from 'actions/currentModal';

export function EditChecklistItemModal(props) {
  const {
    checklistItemId,
    className,
    currentChecklist,
    stepGroups,
    steps: flows,
    onChange,
    onClose,
  } = props;

  const autoupdateFlowCompleted = params => {
    const checklistId = currentChecklist.id;
    const checklistItem = currentChecklist.items[checklistItemId];

    const clauses = getItemClauses(checklistItem);
    const currentFlow = params && flows[params.flowId];
    const completionId = determineFlowCompletionStepId(currentFlow, stepGroups);
    const conditions =
      params &&
      getNestedConditionsFromFlattenedClauses(
        setChecklistFlowCompleted(clauses, completionId),
        null,
        clauseKeys
      );
    onChange(checklistId, checklistItem.id, {
      conditions,
    });
  };

  /**
   * Takes in a string representing the name of any top-level checklist item
   * property and returns a function that can be used as an <input>
   * onChange listener to update that property.
   *
   * @param property
   * @returns {function(event)}
   */
  const updateChecklistItemProperty =
    property =>
    ({ target: { value } }) => {
      const checklistId = currentChecklist.id;
      const checklistItem = currentChecklist.items[checklistItemId];
      onChange(checklistId, checklistItem.id, {
        [property]: value,
      });
    };

  const updateChecklistItemLabel = updateChecklistItemProperty('label');

  const checklistId = currentChecklist.id;
  const checklistItem = currentChecklist.items[checklistItemId];

  if (!checklistItem) {
    return <Loader />;
  }

  const clauses = getItemClauses(checklistItem);
  const pageAction = getItemAction(checklistItem, 'redirect');
  const flowAction = getItemAction(checklistItem, 'show_flow');
  const pageActionChecked = !!pageAction;
  const flowActionChecked = !!flowAction;

  const pageUrl =
    pageAction && pageAction.attributes && pageAction.attributes.params
      ? pageAction.attributes.params.url
      : '';
  const flowId =
    flowAction && flowAction.attributes && flowAction.attributes.params
      ? flowAction.attributes.params.flowId
      : null;

  const currentFlow = flowId && flows[flowId];

  const flowCompleted = determineFlowCompletedOnly(
    checklistItem,
    currentFlow,
    stepGroups
  );

  return (
    <div role="dialog">
      <BaseModal
        title={`Item ${checklistItem.index + 1}: ${checklistItem.label}`}
        className={className}
        okText="Done"
        modalClass="edit-checklist-item-modal"
        onClickOK={onClose}
        xlarge
        headerHR
        footerHR
        scrollBody
        {...props}
      >
        <FieldGroup title="Label" className="item" disableMargin>
          <Input
            className="checklist-item-input"
            type="text"
            value={checklistItem.label}
            onChange={updateChecklistItemLabel}
            placeholder="Enter a label (what users will see)..."
          />
        </FieldGroup>
        <FieldGroup title="When clicked..." disableMargin>
          <ItemAction
            autoupdateFlowCompleted={autoupdateFlowCompleted}
            checklistId={checklistId}
            checklistItem={checklistItem}
            flowActionChecked={flowActionChecked}
            flowCompleted={flowCompleted}
            flowId={flowId}
            pageActionChecked={pageActionChecked}
            pageUrl={pageUrl}
            updateChecklistItem={onChange}
          />
        </FieldGroup>
        <FieldGroup title="Mark completed..." disableMargin>
          <ItemCompletion
            autoupdateFlowCompleted={autoupdateFlowCompleted}
            checklistItem={checklistItem}
            checklistId={checklistId}
            currentFlow={currentFlow}
            clauses={clauses}
            flowCompleted={flowCompleted}
            flowId={flowId}
            updateChecklistItem={onChange}
          />
        </FieldGroup>
      </BaseModal>
    </div>
  );
}

const mapDispatchToProps = {
  onChange: updateItem,
  onClose: hideModal,
};

export default styled(
  connect(null, mapDispatchToProps)(EditChecklistItemModal)
)`
  .checklist-item-text-short {
    width: 200px;
  }

  .checklist-item-input {
    width: 100%;
  }

  .panel-heading {
    color: #a9a9a9;
    background-color: #eee;
  }

  .indent-items {
    border-left: 4px solid ${props => props.theme['$gray-1']};
    padding-left: 24px;
  }

  .item {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    .field-group-header {
      width: 240px;
    }
    .field-group-fields {
      display: flex;
      flex-grow: 1;
    }
  }

  .modal-dialog .extra-large {
    padding: 18px 0;
  }

  header h2 {
    margin: 0;
  }
`;
