import { all, call, getContext, select } from 'redux-saga/effects';
import {
  addClause,
  clauseKeys,
  conditionNames,
  getFlattenedClausesFromNestedConditions,
  getNestedConditionsFromFlattenedClauses,
} from '@appcues/libcues';
import { reportError } from 'helpers/error-reporting';
import { selectGate, ONLY_CUSTOM_BUTTONS } from 'entities/gates';

/**
 * Since the flow-builder-api is not able to accept a complete flow and
 * deeply re-key its stepGroups and stepChildren, we must here feed it the
 * template flow one stepGroup and stepChild at a time as if we
 * were a user of the CRX, using the methods it does provide.
 *
 * Note that many of the arguments are slightly quirky here (e.g. adding
 * parentId, empty array to step groups) because the flow-builder-api
 * will choke without them.
 *
 * @param action
 * @returns {Flow}
 */

export function* createFromTemplate(action) {
  try {
    const {
      payload: { name, previewUrl, segmentId, stepGroups },
    } = action;

    const customButtonsEnabled = yield select(selectGate, ONLY_CUSTOM_BUTTONS);

    const api = yield getContext('api');
    const {
      data: {
        flow: { id },
        rule,
      },
    } = yield call(api.createFlow, {
      name,
      previewUrl,
      formatVersion: customButtonsEnabled ? 2 : 1,
    });

    yield all(
      Object.values(stepGroups).map(
        ({ steps, hotspots, stepGroupType, ...stepGroup }, index) => {
          const [firstStepChild, ...restStepChildren] = Object.values(
            steps || hotspots
          );
          return api
            .createStepGroups(id, {
              stepGroups: [
                {
                  stepGroup: {
                    ...stepGroup,
                    [steps ? 'steps' : 'hotspots']: [],
                    parentId: id,
                    index,
                  },
                  stepChild: firstStepChild,
                  stepGroupTypePathParam: stepGroupType,
                },
              ],
              index,
            })
            .then(
              ({
                data: {
                  stepGroups: [
                    {
                      stepGroup: { id: stepGroupId },
                    },
                  ],
                },
              }) =>
                restStepChildren.reduce(
                  (prev, stepChild) =>
                    /**
                     * Step Children must be created sequentially lest the
                     * flow-builder-api will reject them, so we must
                     * block between each call
                     */
                    prev.then(() =>
                      api.createStepChild(
                        id,
                        stepGroupType,
                        stepGroupId,
                        stepChild
                      )
                    ),
                  Promise.resolve()
                )
            );
        }
      )
    );

    if (segmentId) {
      yield call(api.updateRule, id, {
        ...rule,
        conditions: getNestedConditionsFromFlattenedClauses(
          addClause(
            getFlattenedClausesFromNestedConditions(rule.conditions),
            null,
            {
              conditionName: conditionNames.SEGMENTS,
              segment: segmentId,
            }
          ),
          null,
          clauseKeys
        ),
      });
    }

    return yield call(api.getFlow, id);
  } catch (error) {
    yield call(reportError, error);
    throw error;
  }
}
