import styled from 'styled-components';
import { Input, Td } from '@studio/legacy-components';
import { Conditions } from 'next/components/Conditions';

export const Loading = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${Conditions}:last-child {
    margin-bottom: 16px;
  }
`;

export const SectionTitle = styled.span`
  font-weight: var(--bold);
  color: var(--heading-primary);
`;

export const HelpText = styled.p`
  color: var(--sharkbait-ooh-la-la);
`;

export const HelpTextEmphasis = styled.span`
  color: var(--gradient-green);
  font-weight: var(--bold);
`;

export const Targeting = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0 0 24px;
`;

export const SegmentTargeting = styled.div`
  display: flex;
  gap: 12px;
`;

export const FieldInput = styled(Input)`
  margin-bottom: ${props => (props.error ? '' : '24px')};
`;

export const TableContainer = styled.div`
  ${Td} {
    border: none;
    vertical-align: top;

    &:nth-of-type(1) {
      font-weight: var(--bold);
      width: 100px;
    }

    &:nth-of-type(2) {
      max-width: 30em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
`;
