import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import { selectAccountFeature } from 'reducers/account/features';
import { SAVED_CHARTS } from 'constants/features';
import { SelectedEventsTransition } from 'components/Insights/Common/Styled';

import {
  EventsChart,
  CustomChart,
  SelectedEventsTable,
  ChartContainer,
  ChartWrapper,
} from './index';

/**
 * @deprecated
 * This one was the old <Chart> component.
 * As it started to grow and be shared accross multiple places, new props were
 * needed in order to handle the logic to decide whether or not display
 * certain things, prop drilling to its children and bloating the original component.
 * From now, if a chart is needed, it can be assembled by using just the
 * needed components from the Chart/index source. See single metric as example.
 */
export const DeprecatedChart = ({
  startTime,
  endTime,
  segmentId,
  selectedEvents = [],
  chartType = 'line',
  onSelectEvent = () => null,
  onSaveChart,
  onSelectChartType = () => null,
  hasSavedCharts,
  shouldHideCheckbox = false,
}) => {
  const hasSelectedEvents = selectedEvents.length > 0;
  return (
    <ChartContainer
      chartType={chartType}
      onSelectChartType={hasSelectedEvents ? onSelectChartType : null}
      onSaveChart={
        hasSelectedEvents && hasSavedCharts && onSaveChart ? onSaveChart : null
      }
    >
      <ChartWrapper>
        {hasSelectedEvents ? (
          <CustomChart
            type={chartType}
            startTime={startTime}
            endTime={endTime}
            segmentId={segmentId}
            selectedEvents={selectedEvents}
          />
        ) : (
          <EventsChart
            startTime={startTime}
            endTime={endTime}
            segmentId={segmentId}
          />
        )}
      </ChartWrapper>
      <CSSTransition
        appear
        in={hasSelectedEvents}
        timeout={200}
        classNames="fade"
      >
        <SelectedEventsTransition>
          <SelectedEventsTable
            startTime={startTime}
            endTime={endTime}
            segmentId={segmentId}
            selectedEvents={selectedEvents}
            onSelectEvent={onSelectEvent}
            shouldHideCheckbox={shouldHideCheckbox}
          />
        </SelectedEventsTransition>
      </CSSTransition>
    </ChartContainer>
  );
};

DeprecatedChart.propTypes = {
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  segmentId: PropTypes.string,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  chartType: PropTypes.string,
  onSelectEvent: PropTypes.func,
  onSelectChartType: PropTypes.func,
  onSaveChart: PropTypes.func,
  hasSavedCharts: PropTypes.bool,
  shouldHideCheckbox: PropTypes.bool,
};

const mapStateToProps = state => ({
  hasSavedCharts: selectAccountFeature(state, SAVED_CHARTS),
});

export default connect(mapStateToProps)(DeprecatedChart);
