import React, { useMemo } from 'react';
import { Page } from '@studio/legacy-components';
import { Elements } from '@stripe/react-stripe-js';
import useTitle from 'next/hooks/use-title';
import { setupStripe } from 'utils/stripe';
import BillingUpdateForm from './BillingUpdateForm';

export function Billing() {
  useTitle('Subscription | Settings | Appcues');

  const stripePromise = useMemo(() => setupStripe());

  return (
    <Elements stripe={stripePromise}>
      <Page>
        <BillingUpdateForm />
      </Page>
    </Elements>
  );
}

export default Billing;
