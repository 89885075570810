import { createGlobalStyle } from 'styled-components';
import variables from './variables.css';
import scrollbars from './scrollbars.css';

const GlobalStyle = createGlobalStyle`
  ${variables}
  ${scrollbars}
`;

export default GlobalStyle;
