export const WEB_APP = 'WEB_APP';

export const IOS = 'ios';
export const ANDROID = 'android';
export const REACT_NATIVE = 'react-native';
export const FLUTTER = 'flutter';
export const XAMARIN = 'xamarin';
export const IONIC = 'ionic';

export const MOBILE_PLATFORM_ICONS = {
  [IOS]: ['fab', 'apple'],
  [ANDROID]: ['fab', 'android'],
  [REACT_NATIVE]: ['fab', 'react'],
  [FLUTTER]: ['fa', 'mobile'],
  [XAMARIN]: ['fa', 'mobile'],
  [IONIC]: ['fa', 'mobile'],
};
