import styled from 'styled-components';

import {
  Button,
  Caption,
  ExternalLink,
  Icon,
  Text,
} from '@studio/legacy-components';

// INTERVAL SELECTOR
const BasicPlan = styled.div`
  background: ${({ selected }) => (selected ? 'var(--luna)' : 'var(--white)')};
  border: solid 1px
    ${({ selected }) => (selected ? 'var(--blurple)' : 'var(--pleather)')};
  color: ${({ selected }) => (selected ? 'var(--blurple)' : 'var(--pleather)')};
  width: 50%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const MonthlyPlan = styled(BasicPlan)`
  border-radius: 6px 0px 0px 6px;
  border-right: none;
`;

export const YearlyPlan = styled(BasicPlan)`
  border-radius: 0px 6px 6px 0px;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 180px;
  box-sizing: border-box;
  min-height: 40px;
  margin-bottom: 12px;

  ${MonthlyPlan},
  ${YearlyPlan} {
    cursor: ${({ isViewMode }) => (isViewMode ? 'not-allowed' : 'pointer')};
  }
`;

// PLAN BASIC INFO
export const GradientBanner = styled.div`
  position: absolute;
  top: -1px;
  width: 100.5%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: 7px;
  background-color: transparent;
  background-image: ${props =>
    props.gradient ? props.theme[props.gradient] : 'var(--galaxy)'};
`;

export const PlanName = styled.h2`
  color: ${props =>
    props.color ? props.theme[props.color] : props.theme['$gray-7']};
  font-size: var(--regular);
  font-weight: var(--extra-bold);
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 40px;
`;

export const PlanPrice = styled.h3`
  font-weight: var(--light);
  font-size: var(--xx-large);
  line-height: 1em;
  margin: 0;
`;

export const PricePostCaption = styled.div`
  position: relative;
  color: var(--dark-resting);
  width: 100%;

  & > span {
    position: absolute;
    left: 0;
    white-space: nowrap;
    width: 100%;
    font-size: var(--regular);
    margin-top: 5px;
  }
`;

export const PricePreCaption = styled(PricePostCaption)`
  & > span {
    bottom: 1px;
  }
`;

export const PlanDescription = styled.div`
  margin: 20px 0;
  position: relative;
  height: 52px;
  width: 100%;

  & > span {
    position: absolute;
    left: 0;
    right: 0;

    color: var(--pleather);
    font-weight: var(--bold);
    line-height: 1.5rem;
  }
`;

export const StyledFeaturesCaption = styled.div`
  font-weight: var(--bold);
  font-size: var(--regular);
  color: var(--pleather);
  line-height: 1.5rem;
  width: 100%;
  text-align: ${({ isViewMode }) => (isViewMode ? 'center' : 'left')};
`;

export const FeatureList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
  text-align: left;
  width: 100%;
  min-height: 161px;
`;

export const FeatureItem = styled.div`
  margin: 8px 0;
  font-size: var(--regular);
  display: flex;
  color: var(--pleather);
  align-items: baseline;
`;

export const FeatureCheck = styled(Icon)`
  color: ${props =>
    props.color ? props.theme[props.color] : 'var(--blurple)'};
  margin-right: 10px;
`;

// PLANS
export const PlansWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;

  div:first-of-type {
    margin-left: 0;
  }
`;

// SINGLE PLAN
export const PlanWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
  min-width: 258px;
  padding: 32px 28px 24px;
  margin-left: 20px;
  border: 1px solid var(--light-gray);
  border-radius: 6px;
  text-align: center;

  ${Button} {
    margin-top: 16px;
    width: 100%;
    padding: 8px 0;
    border: none;

    &:hover {
      color: var(--white);
    }
  }

  ${ExternalLink} {
    color: var(--blurple);
  }
`;

export const MausBase = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

// CONTROLS
export const ControlsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const PlanSelectSection = styled.div`
  width: 50%;
`;

export const PlanSelectHeader = styled.div`
  display: flex;

  span {
    color: var(--sharkbait-ooh-la-la);
    margin-left: 8px;
  }
`;

export const SelectWrapper = styled.div`
  margin: 16px 0;
  width: 75%;
`;

export const MonthlyUsersCount = styled(Text)`
  font-size: var(--regular);

  ${Caption} {
    color: var(--black);
    font-size: inherit;
    font-weight: var(--bold);
  }
`;

export const HorizontalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
  margin-bottom: 9px;
  min-height: 21px;
`;
