import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Select } from '@studio/legacy-components';
import { selectFlows } from 'reducers/account/flows';

const SelectFlow = ({ value, options = [], onChange }) => {
  const dropdownOptions = [
    ...options,
    ...(value && !options.some(({ value: optValue }) => optValue === value)
      ? [{ label: value, value }]
      : []),
  ];

  const selected = dropdownOptions.find(option => option.value === value);

  return (
    <Select
      isDisabled={dropdownOptions.length === 0}
      onChange={option => onChange(option.value)}
      options={dropdownOptions}
      placeholder="Select a Flow"
      value={selected}
    />
  );
};

SelectFlow.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ),
  onChange: PropTypes.func,
};

function mapStateToProps(state, { shouldFilterArchived }) {
  const flows = selectFlows(state);
  return {
    options: Object.values(flows)
      .filter(({ state: flowState }) =>
        shouldFilterArchived ? flowState !== 'ARCHIVED' : true
      )
      .map(({ id, name }) => {
        return { label: name || id, value: id };
      })
      .sort((a, b) => a.label.localeCompare(b.label)),
  };
}

export default connect(mapStateToProps)(SelectFlow);
