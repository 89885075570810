import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import {
  CIconButton,
  CButton,
  CBanner,
  Text,
  Flex,
  Box,
} from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';

class DropdownButton extends Component {
  state = {
    isMenuOpen: false,
  };

  menuRef = React.createRef();

  toggle = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(({ isMenuOpen }) => ({ isMenuOpen: !isMenuOpen }));
  };

  collapse(callback) {
    this.setState({ isMenuOpen: false });
    if (callback && callback.call) callback();
  }

  render() {
    const {
      children,
      className,
      options,
      onSelect,
      optionDetail,
      shouldDropUp,
      useIconButton,
      ...buttonProps
    } = this.props;
    const { isMenuOpen } = this.state;
    const dropUpClass = shouldDropUp ? 'menu--dropup' : '';

    const ButtonComponent = useIconButton ? CIconButton : CButton;

    const buttonClassNames = classNames(className, {
      'dropdown-button-button': true,
      'dropdown-button-menu--state-open': isMenuOpen,
    });

    return (
      <div
        // FIXME: look into better solution for handling outer click...
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex="0"
        className={classNames(className, 'dropdown-button')}
        data-testid="dropdown-button"
        onBlur={e => {
          if (
            e.currentTarget !== e.relatedTarget &&
            this.menuRef.current &&
            !this.menuRef.current.contains(e.relatedTarget)
          ) {
            this.collapse();
          }
        }}
        title="Show options"
      >
        <ButtonComponent
          border="none"
          height="auto"
          paddingX={8}
          paddingY={12}
          {...buttonProps}
          className={buttonClassNames}
          onClick={this.toggle}
        >
          {children}
        </ButtonComponent>
        {isMenuOpen && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
          <ul
            ref={this.menuRef}
            className={classNames('menu', dropUpClass)}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {optionDetail && (
              <li className="option-detail-container">
                <StyledCBanner>{`Updated ${optionDetail}`}</StyledCBanner>
              </li>
            )}
            {options.map(({ label, value, href, icon, disabled }) => (
              <Box
                is={href ? Link : 'li'}
                to={href}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (!disabled) {
                    this.collapse.bind(this)(() => onSelect(value));
                  }
                }}
                key={label}
                className={classNames(
                  value,
                  disabled && 'disabled',
                  icon && 'hasIcon'
                )}
                title=""
              >
                {icon && (
                  <IconContainer>
                    <Icon icon={icon} />
                  </IconContainer>
                )}
                <Text type="secondary">{label}</Text>
              </Box>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

DropdownButton.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
  optionDetail: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  useIconButton: PropTypes.bool,
};

DropdownButton.defaultProps = {
  useIconButton: false,
};

const IconContainer = styled(Flex)`
  position: absolute;
  left: 24px;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme['$gray-5']};
`;

const StyledCBanner = styled(CBanner)`
  text-transform: capitalize;
  min-width: 0 !important;
  font-size: 14px;
`;

export default styled(DropdownButton)`
  position: relative;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &:focus {
    z-index: 1;
  }

  .dropdown-ellipses {
    letter-spacing: 0.05em;
  }

  .dropdown-button-button {
    display: flex;
    flex-direction: row;
  }

  ul.menu {
    background-color: white;
    position: absolute;
    padding: 16px 0;
    top: calc(100% + 3px);
    margin-top: 0px;
    margin-right: 0;
    list-style: none;
    border-radius: 4px;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: ${props => props.theme['$box-shadow-3']};
    z-index: 3;
    width: 256px;
    cursor: default;

    &.menu--dropup {
      bottom: calc(100% - 9px);
      top: auto;
    }

    .option-detail-container {
      display: block;
      box-sizing: border-box;
      padding: 9px 16px;
      cursor: default;
    }

    & li:not(.option-detail-container),
    & a {
      display: block;
      position: relative;
      padding: 7px 24px 6px;

      line-height: 26px;
      font-size: ${props => props.theme['$font-size-base']};
      white-space: nowrap;
      cursor: pointer;

      text-decoration: none;

      &.hasIcon {
        padding-left: 56px;
      }

      &:hover {
        background-color: ${props => props.theme['$gray-2']};
      }

      &.disabled {
        color: ${props => props.theme['$gray-30']};
        cursor: not-allowed;
      }
    }
  }
`;
