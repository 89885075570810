import PropTypes from 'prop-types';

export const ScheduleDateType = PropTypes.string;

export const ScheduleType = PropTypes.shape({
  id: PropTypes.string,
  contentId: PropTypes.string,
  enabled: PropTypes.bool,
  startDate: ScheduleDateType,
  endDate: ScheduleDateType,
});
