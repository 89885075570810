import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Creatable } from '@studio/legacy-components';
import { selectAccountProfileAttributeByName } from 'selectors/profile-attributes';

export function AutosuggestProfileAttribute({
  onChange,
  portal,
  profileAttribute,
  value,
  ...props
}) {
  const options = useMemo(() => {
    const list = profileAttribute?.values || [];
    const values = value && !list.includes(value) ? [...list, value] : list;
    return values.map(label => ({ label, value: label }));
  }, [profileAttribute, value]);

  const placeholder = options.length > 0 ? `e.g. ${options[0].label}` : '';
  const selected = options.find(option => option.value === value);

  return (
    <Creatable
      createOptionPosition="first"
      formatCreateLabel={label => label}
      onChange={next => onChange(next.value)}
      options={options}
      placeholder={placeholder}
      portal={portal}
      value={selected}
      {...props}
    />
  );
}

AutosuggestProfileAttribute.propTypes = {
  onChange: PropTypes.func,
  portal: PropTypes.bool,
  profileAttribute: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.string),
  }),
  value: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  return {
    profileAttribute: selectAccountProfileAttributeByName(
      state,
      ownProps.property
    ),
  };
}

export default styled(connect(mapStateToProps)(AutosuggestProfileAttribute))``;
