import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { logout, selectUser } from 'next/entities/user';
import { selectFeature, WELCOME_LOGOUT_BUTTON } from 'next/entities/features';
import { enabled } from 'next/lib/devtools';
import { Emoji, SignOutButton, SignOutButtonWrapper } from './styled';

export const SignOut = ({
  isDevToolsEnabled,
  hasWelcomeLogoutButton,
  onLogout,
}) => {
  return hasWelcomeLogoutButton ? (
    <SignOutButtonWrapper isDevToolsEnabled={isDevToolsEnabled}>
      <SignOutButton onClick={onLogout}>
        Logout <Emoji>👋</Emoji>
      </SignOutButton>
    </SignOutButtonWrapper>
  ) : null;
};

SignOut.propTypes = {
  isDevToolsEnabled: PropTypes.bool,
  hasWelcomeLogoutButton: PropTypes.bool,
  onLogout: PropTypes.func,
};

const mapStateToProps = state => {
  const user = selectUser(state);

  return {
    isDevToolsEnabled: enabled(user),
    hasWelcomeLogoutButton: selectFeature(state, WELCOME_LOGOUT_BUTTON),
  };
};

const mapDispatchToProps = {
  onLogout: logout,
};
export default connect(mapStateToProps, mapDispatchToProps)(SignOut);
