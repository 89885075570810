import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { CDialog, P } from '@appcues/component-library';
import { Link } from '@studio/legacy-components';
import { deleteSegment } from 'actions/account/segments';
import { selectConditionsBySegmentId } from 'reducers/account/conditions';
import { selectAccountChecklists } from 'entities/checklists';
import { getMatchingSegments, getMatchingChecklists } from 'utils/segments';
import { selectAccountSegments } from 'reducers/account/segments';
import { selectAccountSatisfactionSegmentId } from 'selectors/satisfaction';

import { hideModal } from 'actions/currentModal';
import { callApi as fetchRules } from 'actions/account/rules';

const StyledCDialog = styled(CDialog)`
  ${CDialog.Content} {
    overflow: hidden;
  }
`;

export class DeleteSegmentModal extends Component {
  componentDidMount() {
    const { onLoad } = this.props;
    onLoad();
  }

  handleSegmentDeletion = () => {
    const { segmentId, onDelete, onClose } = this.props;

    onDelete(segmentId);
    onClose();
  };

  pluralize = (word, qty) => `${qty} ${word}${qty > 1 ? 's' : ''}`;

  render() {
    const {
      segmentId,
      matchingFlows,
      segments,
      checklists,
      onClose,
      isUsedInNps,
    } = this.props;

    const numberOfMatchingFlows = matchingFlows.length;
    const numberOfMatchingSegments = getMatchingSegments(
      segmentId,
      segments
    ).length;
    const numberOfMatchingChecklists = getMatchingChecklists(
      segmentId,
      checklists
    ).length;

    if (
      numberOfMatchingFlows ||
      numberOfMatchingSegments ||
      numberOfMatchingChecklists ||
      isUsedInNps
    ) {
      const links = [];

      if (numberOfMatchingFlows > 0) {
        links.push(
          <Link
            key="flows"
            to={`/segments/${segmentId}/view/#flows-using-segment`}
            onClick={onClose}
          >
            {this.pluralize('flow', numberOfMatchingFlows)}
          </Link>,
          ', '
        );
      }

      if (numberOfMatchingChecklists > 0) {
        links.push(
          <Link key="checklists" to="/checklists" onClick={onClose}>
            {this.pluralize('checklist', numberOfMatchingChecklists)}
          </Link>,
          ', '
        );
      }

      if (numberOfMatchingSegments > 0) {
        links.push(
          <Link key="segments" to="/segments" onClick={onClose}>
            {this.pluralize('segment', numberOfMatchingSegments)}
          </Link>,
          ', '
        );
      }

      if (isUsedInNps) {
        links.push(
          <Link key="nps" to="/nps" onClick={onClose}>
            NPS
          </Link>,
          ', '
        );
      }
      links.pop();

      return (
        <StyledCDialog
          title="Unable to delete segment"
          primaryActionText="Got it"
          onPrimaryAction={onClose}
        >
          <P>
            To delete, you will need to remove it from the targeting conditions
            for {links.map(link => link)}.
          </P>
        </StyledCDialog>
      );
    }

    return (
      <CDialog
        title="Delete this segment?"
        type="negative"
        primaryActionText="Delete"
        onPrimaryAction={this.handleSegmentDeletion}
        secondaryActionText="Cancel"
        onSecondaryAction={onClose}
      >
        <P>
          Are you sure you want to delete this segment?
          <br />
          This cannot be undone.
        </P>
      </CDialog>
    );
  }
}

function mapStateToProps(state, { segmentId }) {
  return {
    matchingFlows: selectConditionsBySegmentId(state, { segmentId }),
    segments: selectAccountSegments(state),
    checklists: selectAccountChecklists(state),
    isUsedInNps: selectAccountSatisfactionSegmentId(state) === segmentId,
  };
}

const mapDispatchToProps = {
  onLoad: fetchRules,
  onDelete: deleteSegment,
  onClose: hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSegmentModal);
