import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecommendedFrequency } from '@studio/legacy-components';
import { makeUniqueSelectAugmentedChartSeries } from 'selectors/analytics-charts';
import Chart from 'components/Common/Chart';
import Loader from 'components/Common/Loader';
import { getQueryConditionsForSelectedEvents } from 'components/Insights/utils';
import { getAnalytics } from 'actions/analytics-v2';
import { getCacheKey } from 'utils/caching';

const seriesShape = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
    fillOpacity: PropTypes.number,
    marker: PropTypes.objectOf(PropTypes.string),
    type: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({ x: PropTypes.instanceOf(Date), y: PropTypes.number })
    ),
  })
);

export const CustomChart = ({ frequency = 'day', series, type }) => {
  if (!series) {
    return <Loader margin="calc(275px / 2)" />;
  }

  return (
    <Chart
      type={type}
      dimension={frequency}
      height={275}
      titleX="Date"
      series={series}
    />
  );
};

CustomChart.propTypes = {
  frequency: PropTypes.oneOf(['day', 'month']),
  series: seriesShape,
  type: PropTypes.string,
};

export const CustomChartQueryProvider = ({
  frequency,
  onQueryChange,
  query,
  series,
  type,
}) => {
  const cacheKey = query && getCacheKey(query);

  useEffect(() => {
    if (query && cacheKey && !series) {
      onQueryChange(query);
    }
  }, [cacheKey]);

  return <CustomChart frequency={frequency} series={series} type={type} />;
};

CustomChartQueryProvider.propTypes = {
  frequency: PropTypes.oneOf(['day', 'month']),
  onQueryChange: PropTypes.func,
  series: seriesShape,
  type: PropTypes.string,
  query: PropTypes.object,
};

const mapStateToProps = () => {
  const selectAugmentedChartSeries = makeUniqueSelectAugmentedChartSeries();

  return (state, { frequency, query }) => {
    return {
      series: selectAugmentedChartSeries(state, { frequency, query }),
    };
  };
};

const mapDispatchToProps = {
  onQueryChange: query => getAnalytics({ query }),
};

const ConnectedCustomChart = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomChartQueryProvider);

/**
 * This component serves as a query builder.
 * This is in order to be able to fetch and grab the results
 * since the query serves as the key.
 * It also prevents query for mutating when no need to
 */
const CustomChartQueryBuilder = ({
  type,
  startTime,
  endTime,
  segmentId,
  selectedEvents = [],
}) => {
  const frequency = useMemo(() => {
    return getRecommendedFrequency({
      start: new Date(startTime),
      end: new Date(endTime),
    });
  }, [startTime, endTime]);

  const query = {
    conditions: [
      ...getQueryConditionsForSelectedEvents(selectedEvents),
      ...(segmentId ? [['user_segment_id', '==', segmentId]] : []),
    ],
    metrics: ['events', 'users'],
    dimensions: [
      'day',
      'name',
      'flow_id',
      'checklist_id',
      'experience_id',
      'step_id',
      'experience_type',
      'goal_id',
    ],
    start_time: startTime,
    end_time: endTime,
    meta: {
      tags: {
        feature: 'Event analytics',
        page: 'Event explorer',
        component: 'CustomChart',
        description: 'Chart of user-selected events',
      },
    },
  };

  return (
    <ConnectedCustomChart frequency={frequency} query={query} type={type} />
  );
};

CustomChartQueryBuilder.propTypes = {
  type: PropTypes.string,
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  segmentId: PropTypes.string,
  selectedEvents: PropTypes.arrayOf(
    PropTypes.shape({ source: PropTypes.string, event: PropTypes.string })
  ),
};

export default CustomChartQueryBuilder;
