import React from 'react';
import styled from 'styled-components';

const StatCard = props => {
  const { header, stat, description, numberAcross, background, textColor } =
    props;

  return (
    <StatCardWrapper
      numberAcross={numberAcross}
      background={background}
      textColor={textColor}
    >
      <StatHeader>{header}</StatHeader>
      <StatBox>
        <StatNumber>{stat}</StatNumber>
        <StatDescription>{description}</StatDescription>
      </StatBox>
    </StatCardWrapper>
  );
};

const StatCardWrapper = styled.div`
  flex: 0 0 ${props => 100 / props.numberAcross - 2}%;
  background: ${props => props.background};
  color: ${props => props.textColor};
  padding: 1rem;
  border-radius: 5px;
`;

const StatHeader = styled.h3`
  text-transform: uppercase;
  margin: 0 !important;
`;

const StatBox = styled.div`
  display: flex;
  align-items: flex-end;
`;

const StatNumber = styled.span`
  font-size: 3rem;
  font-weight: 200;
  line-height: 2.6rem;
  margin: 1rem 1rem 1rem 0;
`;

const StatDescription = styled.span`
  margin: 1rem 0;
`;

export default StatCard;
