import React from 'react';
import PropTypes from 'prop-types';
import { ExternalLink, Select } from '@studio/legacy-components';
import Input from 'components/Common/Forms/Input';
import FieldGroup from 'components/Common/Forms/FieldGroup';

const CHECKLIST_DOC_URL =
  'https://docs.appcues.com/article/359-adding-a-custom-font-checklist';

const CUSTOM_FONT = {
  label: 'Custom...',
  value: 'custom',
};

const defaultFonts = [
  {
    label: 'Helvetica Neue',
    value: "'Helvetica Neue', Helvetica, Arial, sans-serif",
  },
  { label: 'Arial', value: "Arial, 'Helvetica Neue', Helvetica, sans-serif" },
  { label: 'Futura', value: "Futura, 'Trebuchet MS', Arial, sans-serif" },
  { label: 'Tahoma', value: 'Tahoma, Verdana, Segoe, sans-serif' },
  { label: 'Verdana', value: 'Verdana,Geneva,sans-serif' },
  { label: 'Georgia', value: "Georgia, Times, 'Times New Roman', serif" },
  { label: 'Lucida Bright', value: "'Lucida Bright', Georgia, serif" },
  {
    label: 'Palatino',
    value:
      "Palatino, 'Palatino Linotype', 'Palatino LT STD', 'Book Antiqua', Georgia, serif",
  },
  {
    label: 'Open Sans',
    value: "'Open Sans', sans-serif",
    importUrl:
      'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700,700i',
  },
  {
    label: 'Libre Baskerville',
    value: "'Libre Baskerville', serif",
    importUrl:
      'https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700',
  },
  {
    label: 'Josefin Slab',
    value: "'Josefin Slab', serif",
    importUrl:
      'https://fonts.googleapis.com/css?family=Josefin+Slab:300,300i,400,400i,700,700i',
  },
  {
    label: 'Lato',
    value: "'Lato', sans-serif",
    importUrl:
      'https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700,700i',
  },
  {
    label: 'Droid Sans',
    value: "'Droid Sans', sans-serif",
    importUrl: 'https://fonts.googleapis.com/css?family=Droid+Sans:400,700',
  },
  {
    label: 'Arvo',
    value: "'Arvo', serif",
    importUrl: 'https://fonts.googleapis.com/css?family=Arvo:400,400i,700,700i',
  },
  CUSTOM_FONT,
];

const FontPicker = ({ onChange, urlValue, value }) => {
  const isCustom =
    defaultFonts.every(font => font.value !== value && value) ||
    value === 'custom' ||
    value === '';

  const selected = isCustom
    ? CUSTOM_FONT
    : defaultFonts.find(font => font.value === value);

  return (
    <div>
      <FieldGroup title="Font Family">
        <Select
          onChange={onChange}
          options={defaultFonts}
          placeholder="Choose a font..."
          value={selected}
        />
      </FieldGroup>
      {isCustom && (
        <div>
          <FieldGroup title="Custom Font CSS">
            <Input
              type="text"
              value={value}
              onChange={event =>
                onChange({ value: event.target.value, importUrl: urlValue })
              }
              placeholder="'Abril Fatface', cursive"
            />
          </FieldGroup>
          <FieldGroup title="Custom Font URL">
            <Input
              type="text"
              value={urlValue}
              onChange={event =>
                onChange({ value, importUrl: event.target.value })
              }
              placeholder="https://fonts.googleapis.com/css?family=Abril+Fatface"
            />
          </FieldGroup>
          <ExternalLink href={CHECKLIST_DOC_URL}>
            Not sure where to start?
          </ExternalLink>
        </div>
      )}
    </div>
  );
};

FontPicker.propTypes = {
  onChange: PropTypes.func,
  urlValue: PropTypes.string,
  value: PropTypes.string,
};

export default FontPicker;
