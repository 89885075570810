import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, CPage, CButton } from '@appcues/component-library';
import { IconWithinCButton } from '@studio/legacy-components';
import useTitle from 'next/hooks/use-title';
import IndexPage from 'components/Common/IndexPage';
import Loader from 'components/Common/Loader';
import { createAccountTheme } from 'actions/account/themes';
import {
  selectAccountThemesSynced,
  selectAccountThemes,
} from 'reducers/account/themes';
import ThemesList from './ThemesList';

const ThemesIndex = () => {
  useTitle('Themes | Appcues');
  const isSynced = useSelector(state => {
    return selectAccountThemesSynced(state);
  });
  const themes = useSelector(state => {
    return selectAccountThemes(state);
  });
  const dispatch = useDispatch();
  const onClickCreate = useCallback(() => {
    dispatch(createAccountTheme());
  }, [dispatch]);

  return (
    <IndexPage
      className="themes_index"
      title="Themes"
      subHeaderActions={[
        <CButton
          key="themesHelp"
          href="https://docs.appcues.com/article/515-styling-flows"
          isExternal
          is="a"
        >
          <IconWithinCButton icon="info-circle" title="info icon" gray />
          Help
        </CButton>,
        <CButton type="positive" onClick={onClickCreate} key="themesCreate">
          Create Theme
        </CButton>,
      ]}
    >
      <CPage.Container>
        {!isSynced && <Loader />}
        <div role="grid">
          <Grid>
            <ThemesList themes={themes} itemRole="gridcell" />
          </Grid>
        </div>
      </CPage.Container>
    </IndexPage>
  );
};

export default ThemesIndex;
