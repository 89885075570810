import { useEffect } from 'react';

export default function useEscape(handler, capture = false) {
  useEffect(() => {
    const onKeyDown = event => {
      if (event.key === 'Escape' && typeof handler === 'function') {
        handler(event);
      }
    };

    document.addEventListener('keydown', onKeyDown, capture);

    return () => {
      document.removeEventListener('keydown', onKeyDown, capture);
    };
  }, [capture, handler]);
}
