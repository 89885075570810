import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PublishingManager } from '@studio/settings-panels';
import {
  requestPublishExperience,
  requestRevertExperience,
  requestUnpublishExperience,
  selectExperience,
} from 'next/entities/experiences';
import { selectUser } from 'next/entities/user';
import { ROLES, selectAccountUser } from 'next/entities/account-users';
import { selectContentStatus } from 'next/entities/content-status';
import { MOBILE_PUBLISHING, selectFeature } from 'next/entities/features';
import { ExperimentShape } from 'next/entities/experiment';
import ConfirmPushChangesModal from './ConfirmPushChangesModal';

function MobilePublishingManager({ id, children, ...unhandledProps }) {
  return (
    <PublishingManager
      {...unhandledProps}
      renderConfirmPushChangesModal={modalProps => (
        // eslint-disable-next-line @appcues/jsx-props-no-spreading
        <ConfirmPushChangesModal id={id} {...modalProps} />
      )}
    >
      {children}
    </PublishingManager>
  );
}

MobilePublishingManager.propTypes = {
  name: PropTypes.string,
  published: PropTypes.bool,
  hasChanges: PropTypes.bool,
  publishedAt: PropTypes.number,
  canPublish: PropTypes.bool,
  experiment: ExperimentShape,
  renderConfirmPushChangesModal: PropTypes.func,
  onPublish: PropTypes.func,
  onUnpublish: PropTypes.func,
  onDiscardChanges: PropTypes.func,
  children: PropTypes.func,
  flowState: PropTypes.string,
  id: PropTypes.string,
};

const mapStateToProps = (state, { id }) => {
  const {
    published,
    updatedAt,
    publishedAt,
    name,
    experiment,
    state: flowState,
  } = selectExperience(state, id);
  const { id: userId } = selectUser(state);
  const { roleId } = selectAccountUser(state, userId);
  const contentStatus = selectContentStatus(state, id);
  const hasMobilePublishing = selectFeature(state, MOBILE_PUBLISHING);

  return {
    name,
    published,
    experiment,
    hasChanges: contentStatus?.publishedAndDirty,
    updatedAt,
    publishedAt,
    canPublish: roleId !== ROLES.EDITOR && hasMobilePublishing,
    flowState,
  };
};

const mapDispatchToProps = (dispatch, { id }) => ({
  onPublish: () => dispatch(requestPublishExperience(id)),
  onUnpublish: () => dispatch(requestUnpublishExperience(id)),
  onDiscardChanges: version => dispatch(requestRevertExperience(id, version)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobilePublishingManager);
