import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from '@studio/legacy-components';
import { useBoundingBox } from 'hooks/useBoundingBox';

import { getThemeFor } from 'utils';

export const ITEM_WIDTH = 150;

export const Arrow = styled.button`
  position: absolute;
  display: block;
  top: calc(60% - 40px);
  ${({ type }) => (type === 'back' ? 'left: 10px' : 'right: 10px')};
  z-index: 3;
  overflow: hidden;
  border-width: 0;
  height: 40px;
  width: 40px;
  outline: none;
  border-radius: 40px;
  background-color: white;
  box-shadow: ${getThemeFor('$box-shadow-3')};
  transition: box-shadow 0.5 ease-in-out;
  border: 1px solid ${getThemeFor('$gray-2')};
  &:focus,
  &:hover {
    cursor: pointer;
    box-shadow: ${getThemeFor('$box-shadow-3')};
    transition: box-shadow 0.5 ease-in-out;
  }
  &:active {
    background-color: ${getThemeFor('$gray-1')};
    box-shadow: ${getThemeFor('$box-shadow-2')};
    transition: box-shadow 0.5 ease-in-out;
  }
  &:active .svg-inline--fa {
    color: ${getThemeFor('$blue')};
  }
`;

// We use dynamic bounding box behavior here, which is better handled by attributes
export const Slide = styled.div.attrs(
  ({ translateX, transitionLengthInMillis }) => ({
    style: {
      transform: `translateX(${translateX}px)`,
      transition: `all ${transitionLengthInMillis}ms ease-in-out`,
    },
  })
)`
  width: auto;
  display: inline-flex;
`;

export const SlideItem = styled.div`
  width: ${ITEM_WIDTH}px;
  margin: 20px;
  height: 100%;
  padding-left: ${props => props.itemPadding}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 2;
`;

export const Container = styled.div`
  position: relative;
  height: ${props => props.height}px;
  width: 100%;
  max-width: 1240px;
  background-color: ${props => props.theme[props.background]};
`;

export const SliderContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: ${({ whitespace }) => whitespace || 'nowrap'};
  overflow: hidden;
  padding: 25px 0px 25px 0px;
`;

const Slider = ({ children, height = 250 }) => {
  const [currentX, setCurrentX] = useState(0);
  const [containerBox, containerRef] = useBoundingBox();
  const [slideBox, slideRef] = useBoundingBox();

  const reachedEnd = useMemo(() => {
    // For the sake of calculating prior to image loading we can default to the containers width plus 1px
    const slideWidth = slideBox.width || containerBox.width + 1;
    const contentFits = containerBox.width >= slideWidth;
    const difference = Math.abs(containerBox.width - slideBox.width);
    const offSet = Math.abs(currentX);
    return offSet > difference || contentFits;
  }, [currentX, containerBox.width, slideBox.width]);

  const moveBack = () => {
    setCurrentX(currentX + ITEM_WIDTH);
  };

  const moveForward = () => {
    setCurrentX(currentX - ITEM_WIDTH);
  };

  const hasScrolled = currentX < 0;
  return (
    <Container
      ref={containerRef}
      height={height}
      className={`width-${containerBox.width} ${classNames({
        reachedEnd,
        hasScrolled,
      })}`}
      data-testid="slider-container"
    >
      {!reachedEnd && (
        <Arrow type="forward" onClick={moveForward}>
          <Icon icon="chevron-right" />
        </Arrow>
      )}
      {hasScrolled && (
        <Arrow type="back" onClick={moveBack}>
          <Icon icon="chevron-left" />
        </Arrow>
      )}

      <SliderContainer>
        <Slide
          ref={slideRef}
          translateX={currentX}
          transitionLengthInMillis={200}
        >
          {children}
        </Slide>
      </SliderContainer>
    </Container>
  );
};

Slider.Item = SlideItem;

Slider.propTypes = {
  height: PropTypes.number,
  children: PropTypes.node,
};

export default Slider;
