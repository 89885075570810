import React from 'react';
import PropTypes from 'prop-types';
import { CDialog, P } from '@appcues/component-library';

const DeleteLocaleFailedDialog = ({ onClose }) => {
  return (
    <CDialog
      title="Sorry, we can't delete this language"
      onPrimaryAction={onClose}
      primaryActionText="Ok, got it"
    >
      <P>
        This language is being used on live flows, so it can&apos;t be deleted.
      </P>
    </CDialog>
  );
};

DeleteLocaleFailedDialog.propTypes = {
  onClose: PropTypes.func,
};

export default DeleteLocaleFailedDialog;
