import { createLifecycleFor } from 'actions/account/collections';

const TYPE = 'segments';

const actions = createLifecycleFor(TYPE);

export const SEGMENT_CREATED_FROM_RULE = 'SEGMENT_CREATED_FROM_RULE';
export function createSegmentFromRule(name, ruleId) {
  return {
    type: SEGMENT_CREATED_FROM_RULE,
    payload: {
      name,
      ruleId,
    },
  };
}

export const SEGMENT_CLONED = 'SEGMENT_CLONED';
export function cloneSegment(id) {
  return {
    type: SEGMENT_CLONED,
    payload: {
      id,
    },
  };
}

export const SEGMENT_DELETED = 'SEGMENT_DELETED';
export function deleteSegment(id) {
  return {
    type: SEGMENT_DELETED,
    payload: {
      id,
    },
  };
}
export const SEGMENT_EXPORTED = 'SEGMENT_EXPORTED';
export function exportSegment(id, data) {
  return {
    type: SEGMENT_EXPORTED,
    payload: {
      id,
      data,
    },
  };
}

export const SEGMENT_USER_IDS_UPLOADED = 'SEGMENT_USER_IDS_UPLOADED';
export function uploadSegmentUserIds(body) {
  return {
    type: SEGMENT_USER_IDS_UPLOADED,
    payload: {
      data: body,
    },
  };
}

export const SEGMENT_PRE_SIGN_CSV = 'SEGMENT_PRE_SIGN_CSV';
export function preSignSegmentCsv(segmentId) {
  return {
    type: SEGMENT_PRE_SIGN_CSV,
    payload: {
      segmentId,
    },
  };
}

export const {
  callApi,
  send,
  resolve,
  reject,
  update,
  replace,
  create,
  insert,
  flush,
  remove,
  patterns,
} = actions;
