import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Icon } from '@studio/legacy-components';
import DropdownButton from 'components/Common/DropdownButton';
import ButtonWithTooltip from 'components/Common/ButtonWithTooltip';
import { PUBLISH_ACTIONS } from 'constants/modalStatuses';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
`;

const OptionButton = styled(DropdownButton)`
  button {
    padding: 11px 8px;
  }

  a.publish-btn-option {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-left: 4px;
    padding-right: 4px;

    ul.menu {
      white-space: nowrap;
    }
  }
`;

export default function PublishDropdown(props) {
  const { confirmAction, disabledTooltipTxt, publishDisabled } = props;

  const handleSelect = option => {
    confirmAction(option);
  };

  const confirmPublishAction = () => {
    confirmAction(PUBLISH_ACTIONS.PUBLISH);
  };

  return (
    <Wrapper>
      <ButtonWithTooltip
        isDisabled={publishDisabled}
        buttonText="Push Changes"
        tooltipText={disabledTooltipTxt}
        onClick={confirmPublishAction}
        buttonProps={{
          borderTopRightRadius: '0px',
          borderBottomRightRadius: '0px',
        }}
        coolTipProps={{
          marginRight: '12px',
          width: '275px',
        }}
      />
      <OptionButton
        {...props}
        onSelect={handleSelect}
        isDisabled={publishDisabled}
        useIconButton
        type="positive"
        borderTopLeftRadius={0}
        borderBottomLeftRadius={0}
        paddingY={9}
        className={classNames(
          'button-success',
          'option-button',
          'publish-btn-option'
        )}
      >
        <Icon icon="caret-down" title="Select a publishing option" />
      </OptionButton>
    </Wrapper>
  );
}

PublishDropdown.propTypes = {
  disabledTooltipTxt: PropTypes.string,
  confirmAction: PropTypes.func,
  publishDisabled: PropTypes.bool,
};
