import PropTypes from 'prop-types';
import pick from 'lodash.pick';
/**
 * Full shape of experience data model
 */
const Shape = {
  publishedAt: PropTypes.number,
  unpublishedAt: PropTypes.number,
  unpublishedBy: PropTypes.string,
  updatedAt: PropTypes.number,
  updatedBy: PropTypes.string,
  version: PropTypes.number,
};

/**
 * Allowed fields for storage
 */
const allowed = ['publishedAt', 'version'];

/**
 * Experience parsing function
 *
 * @param {Object<keyof ExperienceVersion>} experienceVersion - Object with experience fields
 * @return {Object<keyof ExperienceVersion>} Object with parsed experience fields
 */
export const parse = experienceVersion => pick(experienceVersion, allowed);

export default PropTypes.shape(parse(Shape));
