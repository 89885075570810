import PropertyConstants from 'constants/profileAttributes';

export const isAutoProperty = property => {
  if (Object.keys(PropertyConstants).includes(property.name)) {
    return true;
  }

  if (property.name.slice(0, 1) === '_') {
    return true;
  }
  return false;
};
