import React from 'react';
import { Link } from '@appcues/sonar';
import { Link as LegacyLink } from '@studio/legacy-components';
import { TruncatedText } from './styled';

export const UserIdentifierCell = ({
  userId,
  properties,
  userProfileIdentifier,
}) => {
  let identifierValue = userId;
  if (userProfileIdentifier) {
    identifierValue = properties?.[userProfileIdentifier];
  }

  return (
    <TruncatedText>
      <Link
        forwardedAs={LegacyLink}
        to={`/users/${encodeURIComponent(userId)}`}
      >
        {identifierValue ?? '-'}
      </Link>
    </TruncatedText>
  );
};
