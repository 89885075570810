import PropTypes from 'prop-types';

const Shape = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Error)]),
};

/**
 * Higher order function to wrap data shape with request status
 *
 * @param {PropType} data - Data prop type shape
 */
export const asRequest = data => PropTypes.shape({ ...Shape, data });

export default PropTypes.shape(Shape);
