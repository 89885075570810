import iconExplorerUrl from 'assets/images/lifecycleStageIcons/evaluatorsIcon.svg';
import iconBeginnerUrl from 'assets/images/lifecycleStageIcons/beginnersIcon.svg';
import iconRegularUrl from 'assets/images/lifecycleStageIcons/regularsIcon.svg';
import iconChampionUrl from 'assets/images/lifecycleStageIcons/championsIcon.svg';

import illusExplorerUrl from 'assets/images/lifecycleStageIllus/illustration-evaluators.svg';
import illusBeginnerUrl from 'assets/images/lifecycleStageIllus/illustration-beginners.svg';
import illusRegularUrl from 'assets/images/lifecycleStageIllus/illustration-regulars.svg';
import illusChampionUrl from 'assets/images/lifecycleStageIllus/illustration-champions.svg';

import explorersIcon from 'assets/images/lifecycleStageIllustrations/flywheel-highlight-evaluators.svg';
import beginnersIcon from 'assets/images/lifecycleStageIllustrations/flywheel-highlight-beginners.svg';
import regularsIcon from 'assets/images/lifecycleStageIllustrations/flywheel-highlight-regulars.svg';
import championsIcon from 'assets/images/lifecycleStageIllustrations/flywheel-highlight-champions.svg';

export const EXPLORERS = 'explorers';
export const BEGINNERS = 'beginners';
export const REGULARS = 'regulars';
export const CHAMPIONS = 'champions';

export const PLACEHOLDER_SEGMENTS = [
  {
    name: 'Explorers',
    id: 'placeholder-explorers',
    conditions: {},
    meta: {
      userLifecycleStage: 'explorers',
    },
  },
  {
    name: 'Beginners',
    id: 'placeholder-beginners',
    conditions: {},
    meta: {
      userLifecycleStage: 'beginners',
    },
  },
  {
    name: 'Regulars',
    id: 'placeholder-regulars',
    conditions: {},
    meta: {
      userLifecycleStage: 'regulars',
    },
  },
  {
    name: 'Champions',
    id: 'placeholder-champions',
    conditions: {},
    meta: {
      userLifecycleStage: 'champions',
    },
  },
];

export const GRAPHICS_BY_LIFECYCLE = {
  explorers: {
    icon: iconExplorerUrl,
    illustration: illusExplorerUrl,
    flywheel: explorersIcon,
  },
  beginners: {
    icon: iconBeginnerUrl,
    illustration: illusBeginnerUrl,
    flywheel: beginnersIcon,
  },
  regulars: {
    icon: iconRegularUrl,
    illustration: illusRegularUrl,
    flywheel: regularsIcon,
  },
  champions: {
    icon: iconChampionUrl,
    illustration: illusChampionUrl,
    flywheel: championsIcon,
  },
};

// 7 days, in ms
export const ESTIMATED_DATA_PERIOD = 7 * 24 * 60 * 60 * 1000;
export { default as iconAllUrl } from 'assets/images/lifecycleStageIcons/allIcon.svg';
export { default as iconExplorerUrl } from 'assets/images/lifecycleStageIcons/evaluatorsIcon.svg';
export { default as iconBeginnerUrl } from 'assets/images/lifecycleStageIcons/beginnersIcon.svg';
export { default as iconRegularUrl } from 'assets/images/lifecycleStageIcons/regularsIcon.svg';
export { default as iconChampionUrl } from 'assets/images/lifecycleStageIcons/championsIcon.svg';
