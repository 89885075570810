import {
  call,
  put,
  takeLeading,
  getContext,
  takeEvery,
} from 'redux-saga/effects';
import toast from 'next/lib/toast';
import {
  CHART_CREATED,
  insert,
  replace,
  patterns,
  send,
  resolve,
  reject,
  flush,
  remove,
  CHART_DELETED,
} from 'actions/account/charts';
import { promisaga } from 'utils/as-promised';
import DuplicateChartNameError from 'errors/DuplicateChartNameError';
import { reportError } from 'helpers/error-reporting';

export function* createChart({
  payload: {
    chartType,
    endTime,
    events,
    mode,
    name,
    segmentId,
    startTime,
    trailingDays,
  },
}) {
  try {
    const dates =
      mode === 'FIXED'
        ? { mode, start_time: startTime, end_time: endTime }
        : { trailing_days: trailingDays };

    const api = yield getContext('api');
    const { data } = yield call(api.createChart, {
      name,
      events,
      segment_id: segmentId,
      type: chartType,
      ...dates,
    });
    yield put(insert(data));
    yield call(toast.success, 'Chart created');
  } catch (error) {
    const isValidationError = error.response && error.response.status === 409;
    if (isValidationError) {
      throw new DuplicateChartNameError(error.message);
    }
    yield call(reportError, error);
    throw error;
  }
}

export function* getAllCharts() {
  try {
    const api = yield getContext('api');
    yield put(send());
    const { data } = yield call(api.getAllCharts);

    const result = data.reduce((acc, chart) => {
      acc[chart.id] = chart;
      return acc;
    }, {});

    yield put(resolve(result));
  } catch (error) {
    yield call(reportError, error);
    yield put(reject(error));
  }
}

export function* getChart(action) {
  try {
    const { id } = action.payload;
    const api = yield getContext('api');
    const { data } = yield call(api.getChart, id);
    const chart = { id, ...data };

    yield put(replace(chart));
  } catch (error) {
    yield call(reportError, error);
    throw error;
  }
}

export function* flushChart({ payload: { id, delta } }) {
  try {
    const api = yield getContext('api');
    const { data: updatedChart } = yield call(api.updateChart, id, delta);
    yield put(replace(updatedChart));
    yield put(flush(id));
    yield call(toast.success, 'Chart saved');
  } catch (error) {
    yield call(reportError, error);
    yield call(toast.error, 'Failed to save chart');
  }
}

export function* deleteChart(action) {
  const { id } = action.payload;
  try {
    const api = yield getContext('api');
    yield call(api.deleteChart, id);
    yield put(remove(id));
    yield call(toast.success, 'Chart deleted');
  } catch (error) {
    yield call(reportError, error);
    throw error;
  }
}

export default function* saga() {
  yield takeLeading(CHART_CREATED, promisaga(createChart));
  yield takeLeading(CHART_DELETED, promisaga(deleteChart));
  yield takeEvery(patterns.callApi, function* listener(action) {
    if (action.payload && action.payload.id) {
      yield call(getChart, action);
      return;
    }

    yield call(getAllCharts, action);
  });
  yield takeEvery(patterns.update, flushChart);
}
