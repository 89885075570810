import styled from 'styled-components';

export const SegmentMetadataBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 70%;
  border: 1px solid #e7ecf3;
  border-radius: var(--border-radius-small);
  padding: var(--spacing-small) var(--spacing-large);
  gap: var(--spacing-large);
`;

export const FileInfo = styled.div`
  flex: 1;
`;
