import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { CPage } from '@appcues/component-library';
import { Link, SortDropdown } from '@studio/legacy-components';
import useQueryString from 'next/hooks/use-query-string';
import useTitle from 'next/hooks/use-title';
import IndexPage from 'components/Common/IndexPage';
import Loader from 'components/Common/Loader';
import {
  CHART_SORT_TYPES,
  selectChartsSynced,
  selectChartsAsList,
  CHART_SORT_OPTIONS,
} from 'reducers/account/charts';
import { getChart as getAllCharts } from 'actions/account/charts';
import EventSearchBar from 'components/Insights/Common/EventSearchBar';
import EmptyCharts from './EmptyCharts';
import ChartList from './ChartList';

const RightHeaderContainer = styled.div`
  margin: -10px 0 10px;
`;

const StyledEmptyWrapper = styled.div`
  margin-top: 48px;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Charts = ({
  isLoading = false,
  isEmpty = false,
  onLoad = () => {},
}) => {
  useTitle('Saved Charts | Appcues');
  const endTime = useMemo(() => Date.now(), []);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useQueryString(
    'ob',
    CHART_SORT_TYPES.NAME_AZ,
    {
      validate: val => CHART_SORT_OPTIONS.find(opt => opt.value === val),
    }
  );

  useEffect(() => {
    onLoad();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <IndexPage
      title="Saved Charts"
      subHeaderActions={
        <RightHeaderContainer>
          <Link to="/insights/explorer">Explore Events</Link> to create a chart
        </RightHeaderContainer>
      }
    >
      <CPage.Container>
        {isEmpty ? (
          <StyledEmptyWrapper>
            <EmptyCharts />
          </StyledEmptyWrapper>
        ) : (
          <>
            <Actions>
              <EventSearchBar
                placeholder="Search Charts"
                onChange={setSearchTerm}
                isLightTheme
              />

              <SortDropdown
                onChange={({ value }) => setSortOption(value)}
                options={CHART_SORT_OPTIONS}
                value={sortOption}
              />
            </Actions>

            <ChartList
              searchTerm={searchTerm}
              sortOption={sortOption}
              endTime={endTime}
            />
          </>
        )}
      </CPage.Container>
    </IndexPage>
  );
};

Charts.propTypes = {
  isLoading: PropTypes.bool,
  isEmpty: PropTypes.bool,
  onLoad: PropTypes.func,
};

const mapStateToProps = state => {
  const charts = selectChartsAsList(state);

  return {
    isLoading: !selectChartsSynced(state),
    isEmpty: charts.length === 0,
  };
};

const mapDispatchToProps = dispatch => ({
  onLoad: () => dispatch(getAllCharts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Charts);
