import React from 'react';
import styled from 'styled-components';
import { Notice } from '@appcues/component-library';

const StatusMessageWrapper = styled.div`
  .notice {
    margin: 24px 0;
  }
`;

const ExportModalStatusMessage = props => {
  const { children, status, statusText } = props;

  return (
    <StatusMessageWrapper>
      {statusText && <Notice type={status}>{children}</Notice>}
    </StatusMessageWrapper>
  );
};

export default ExportModalStatusMessage;
