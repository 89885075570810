/* global CRX_ID */

/**
 * Send message to CRX
 *
 * @param {string} action - Action name
 * @param {any} value - Data payload for action
 * @return {Promise<Reply>} Message reply
 */
export const message = (action, value) =>
  new Promise((resolve, reject) => {
    try {
      const payload = { action, value };
      window.chrome.runtime.sendMessage(CRX_ID, payload, {}, resolve);
    } catch (error) {
      reject(error);
    }
  });

/**
 * Send message notifying CRX of current account
 *
 * @param {Auth} auth - Auth information
 * @param {string} auth.accountId - Current account ID
 * @param {string} auth.jwt - JSON web token
 * @return {Promise<Reply>} Message reply
 */
export const setAccount = ({ accountId, jwt }) =>
  message('set-account', { accountId, token: jwt });

/**
 * Post message to CRX about spoofing
 *
 * @param {object} payload - Spoof payload
 * @param {string} payload.accountId - Current account ID
 * @param {boolean} payload.spoofing - Whether spoofing
 * @return {Promise<Reply>} Message reply
 */
export const setSpoof = ({ accountId, spoofing }) =>
  message('set-is-spoofing-account', {
    spoofAccountId: accountId,
    isSpoofingAccount: spoofing,
  });

/**
 * Post message to CRX to logout user
 *
 * @return {Promise<Reply>} Message reply
 */
export const postLogout = () =>
  message('log-out', {
    source: 'webapp',
  });

/**
 * Post message to CRX to check if user has give us domain permission
 *
 * @return {Promise<Boolean>} Message boolean
 */
export const checkDomainPermission = () => message('check-domain-permission');

/**
 * Post message to CRX to request the user to give us domain permission
 *
 * @return {Promise<Boolean>} Message boolean
 */
export const requestDomainPermission = () =>
  message('request-domain-permission');
