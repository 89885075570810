import React from 'react';
import { Badge, Link, Text, Tooltip } from '@appcues/sonar';
import { faFlask } from '@fortawesome/pro-solid-svg-icons/faFlask';
import PropTypes from 'prop-types';

const ExperimentBadge = ({
  experimentId,
  experimentName,
  experimentState,
  flowState,
}) => {
  const shouldShowExperimentBadge =
    Boolean(experimentState) &&
    flowState === 'DRAFT' &&
    ['NOT_STARTED', 'LIVE', 'PAUSED'].includes(experimentState.toUpperCase());

  if (!shouldShowExperimentBadge) return null;

  return (
    <Tooltip
      content={
        <>
          <Text size="small" lineHeight="large" colorToken="foreground-tooltip">
            Connected to experiment:
          </Text>
          <Link
            size="small"
            href={`/experiments/${experimentId}/settings`}
            colorToken="foreground-tooltip-link"
            activeColorToken="foreground-tooltip-link-active"
          >
            {experimentName}
          </Link>
        </>
      }
    >
      <Badge
        variant="attention"
        icon={faFlask}
        aria-label="Flow is linked to an experiment"
      />
    </Tooltip>
  );
};

ExperimentBadge.propTypes = {
  experimentId: PropTypes.string,
  experimentName: PropTypes.string,
  experimentState: PropTypes.string,
  flowState: PropTypes.string,
};

export default ExperimentBadge;
