import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { connect } from 'react-redux';

import {
  Panel,
  PanelHeader,
  PanelTitle,
  H3,
  PanelBody,
  Spinner,
} from '@studio/legacy-components';
import { Chart } from '@studio/charts';

import { queryBannerAnalyticsChart } from 'next/client/queries';
import { readAnalytics, selectBannerEvents } from 'next/entities/analytics';
import { asRequest } from 'next/entities/requests';
import {
  Shape as ExperienceShape,
  selectExperience,
} from 'next/entities/experiences';

import { SpinnerWrapper } from './styled';

const bannersMap = {
  'appcues:v2:step_seen': {
    color: '#9D9DFF',
    label: 'Banner seen',
  },
  'appcues:v2:step_interaction': {
    color: '#6FDDDB',
    label: 'Banner click-through',
  },
  'appcues:v2:experience_dismissed': {
    color: '#FF97BC',
    label: 'Banner dismissed',
  },
};

export const ActivityChart = ({ banner, events, onLoad }) => {
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const bannerLoading = !banner;

  const dates = [...new Set(events?.map(({ day }) => day))];

  const getEventSeries = eventName => ({
    name: bannersMap[eventName].label,
    color: bannersMap[eventName].color,
    fillOpacity: 0.4,
    marker: {
      symbol: 'circle',
      fillColor: bannersMap[eventName].color,
    },
    type: 'line',
    data: dates.map(date => {
      const value = events?.find(
        ({ day, event }) => day === date && event === eventName
      );
      return {
        x: Date.parse(date),
        y: value?.events ?? 0,
      };
    }),
  });

  return (
    <Panel>
      <PanelHeader>
        <PanelTitle>
          <H3>Banner activity</H3>
        </PanelTitle>
      </PanelHeader>
      <PanelBody>
        {bannerLoading || isEmpty(events) ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <Chart
            series={[
              getEventSeries('appcues:v2:step_seen'),
              getEventSeries('appcues:v2:step_interaction'),
              getEventSeries('appcues:v2:experience_dismissed'),
            ]}
            options={{ headers: ['Name', 'Count'] }}
            tooltipHeaders={['Name', 'Count']}
          />
        )}
      </PanelBody>
    </Panel>
  );
};

ActivityChart.propTypes = {
  banner: ExperienceShape,
  events: asRequest(PropTypes.arrayOf(PropTypes.object)),
  onLoad: PropTypes.func,
};

const getQueryParams = ({ id, startTime, endTime, segmentId, goalId }) => ({
  experienceId: id,
  startTime,
  endTime,
  segmentId,
  goalId,
});

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const queryParams = getQueryParams(ownProps);
  const banner = selectExperience(state, id) || {};

  return {
    banner,
    events: selectBannerEvents(state, queryParams),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const queryParams = getQueryParams(ownProps);
  const query = queryBannerAnalyticsChart(queryParams);

  return {
    onLoad: () => dispatch(readAnalytics(query)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityChart);
