import { call, getContext, put } from 'redux-saga/effects';
import { takeEveryPromised } from 'next/lib/effects';
import { patterns, resolve, reject } from './actions';

export const transform = (response, id) =>
  response.reduce(acc => {
    acc[id] = response;
    return acc;
  }, {});

const resolveDataFormat = response => {
  return response?.data?.data ?? response?.data;
};

function* fetchResponses({
  payload: { id, start, end, contentType, propertyNames },
}) {
  try {
    const api = yield getContext('api');
    const response = yield call(
      api.getSurveyResponses,
      id,
      start,
      end,
      contentType,
      propertyNames
    );
    const data = resolveDataFormat(response);

    yield put(resolve({ [id]: data.surveyAnswers }));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  yield takeEveryPromised(patterns.read, fetchResponses);
}
