import { getContext, call, put } from 'redux-saga/effects';
import { takeLeadingByProp } from 'effects/takeLeadingByProp';

import { replace, patterns } from 'actions/account/flowVersions';
import { reportError } from 'helpers/error-reporting';

export function* getFlowVersions({ payload: { flowId } }) {
  try {
    const { getAllFlowVersions } = yield getContext('api');
    const { data: versions } = yield call(getAllFlowVersions, flowId);
    yield put(replace({ id: flowId, versions }));
  } catch (error) {
    yield call(reportError, error);
  }
}

export default function* flowVersions() {
  yield takeLeadingByProp(
    ({ payload: { flowId } }) => flowId,
    patterns.callApi,
    getFlowVersions
  );
}
