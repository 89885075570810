import { local } from 'next/lib/storage';
import { Search } from './listing/use-controls';

export default function listener(pathname) {
  // Clear flows listing search and filter when navigating away from flows
  // We still have to support the old /journeys/:flowId routes
  if (!/\/(flows|journeys)/.test(pathname)) {
    local.remove(Search.key);
    local.remove('appcues:flows:filter');
  }
}
