import React from 'react';
import styled, { css } from 'styled-components';
import { Separator } from '@radix-ui/react-separator';
import {
  Card,
  ContentStatus,
  H3,
  Icon,
  IconButton,
  MenuSection,
  Skeleton,
  Spinner,
} from '@studio/legacy-components';

const ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

/**
 * Page
 */
export const List = styled.ul`
  ${({ $kind }) => {
    switch ($kind) {
      case 'grid':
        return `
          display: grid;
          gap: 24px;
          grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));

          ${Card} {
            height: 280px;
          }
        `;

      case 'list':
      default:
        return `
          ${Card} {
            height: 120px;
          }
        `;
    }
  }}
`;

export const Loading = styled.div.attrs({
  children: <Spinner />,
})`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

export const Controls = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 16px;
`;

export const ControlSeparator = styled(Separator)`
  flex-grow: 1;
`;

export const Control = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;

  & + & {
    margin-left: 12px;
  }
`;

export const NoResults = styled.div`
  color: var(--pleather);
  padding: 8px 0;
`;

/**
 * Cards
 */
const stopPropagation = event => {
  event.stopPropagation();
};

export const Name = styled(H3).attrs(() => ({
  onClick: stopPropagation,
}))`
  ${ellipsis}

  display: block;
  text-decoration: none;
`;

export const Property = styled.div`
  ${ellipsis}
  color: var(--pleather);
  line-height: 1.5;
`;

export const TeaserWrapper = styled.span`
  align-items: center;
  display: flex;
  min-width: 0;

  ${Property}:first-of-type {
    ${ellipsis}
  }

  ${Property}:last-of-type {
    flex-shrink: 0;
    margin-left: 4px;
  }
`;

export const PropertySeparator = styled(Separator).attrs({
  orientation: 'vertical',
})`
  background: var(--pleather);
  flex-shrink: 0;
  height: 12px;
  margin: 0 4px;
  width: 1px;
  display: inline-block;
  vertical-align: middle;
`;

export const Properties = styled.div`
  align-items: center;
  display: flex;
  font-size: var(--regular);

  ${Property} {
    color: var(--pleather);
  }

  ${({ secondary }) =>
    secondary &&
    `
      ${Property} {
        color: var(--sharkbait-ooh-la-la);
      }

      ${PropertySeparator} {
        background: var(--sharkbait-ooh-la-la);
      }
    `}
`;

export const Tags = styled(Properties)`
  gap: 12px;
`;

export const PlatformIcon = styled(Icon)`
  color: var(--sharkbait-ooh-la-la);
  margin-right: 6px;
  margin-left: 2px;
  font-size: ${({ icon }) => (icon === 'desktop' ? '12px' : '14px')};
`;

export const PlatformLabel = styled.span`
  color: var(--sharkbait-ooh-la-la);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Actions = styled.div`
  align-items: center;
  display: flex;

  > * + * {
    margin-left: 8px;
  }
`;

export const ActionButton = styled(IconButton).attrs(() => ({
  onClick: stopPropagation,
}))`
  background: none;
  border: 1px solid var(--sharkbait-ooh-la-la);
  border-radius: 6px;
  font-size: var(--x-small);
  width: 32px;

  ${({ icon }) =>
    icon === 'ellipsis-h' &&
    `
      font-size: var(--medium-large);
    `}
`;

export const ActionsMenu = styled(MenuSection).attrs(() => ({
  onClick: stopPropagation,
}))``;

/**
 * List Row View
 */
export const RowWrapper = styled.div`
  align-items: stretch;
  display: flex;
  height: 100%;
  justify-content: space-between;

  ${Name} {
    margin-bottom: 4px;
  }
`;

export const PropertyLoading = styled(Skeleton)`
  height: ${({ tag }) => (tag ? '24px' : '12px')};
  width: 64px;
`;

export const RowColumn = styled.div`
  ${ellipsis}

  display: flex;
  flex-direction: column;

  :first-of-type {
    flex-basis: 66.67%;

    > *:last-child {
      align-items: flex-end;
      display: flex;
      flex-grow: 1;
    }
  }

  :last-of-type {
    align-items: flex-end;
    flex-basis: 33.33%;

    ${Properties} {
      justify-content: flex-end;
      margin-top: 6px;
      width: 100%;
    }
  }

  ${Actions} {
    padding-bottom: 4px;
  }
`;

export const RowIcon = styled.span.attrs(({ icon }) => ({
  children: <Icon icon={icon} />,
}))`
  align-items: center;
  background: var(--myst);
  border-radius: 50%;
  color: var(--icon);
  display: flex;
  flex-shrink: 0;
  font-size: var(--xx-small);
  height: 24px;
  justify-content: center;
  margin-left: 8px;
  width: 24px;

  ${({ icon }) =>
    icon === 'thumbtack' &&
    `
      transform: rotate(45deg);
    `}
`;

/**
 * Grid Cell View
 */
export const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`;

export const CellRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  :last-of-type {
    justify-content: flex-start;
    margin-top: 8px;
  }

  ${Name} {
    flex-basis: 75%;
  }

  ${ContentStatus} {
    margin-right: 8px;
  }
`;

export const DateTimeWrapper = styled.span``;
