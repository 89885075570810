import { createSelector } from 'reselect';
import { createCollectionReducer, composeReducers } from 'next/lib/collections';
import { getCacheKey } from 'next/lib/caching';
import {
  queryFlowsMetricsAnalytics,
  queryExperienceMetricsAnalytics,
  queryExperienceStepAnalytics,
  queryExperienceRecentUsers,
  queryExperienceUsers,
  queryExperienceGoal,
  queryMobileAnalyticsEvents,
  queryExperienceStepBreakdown,
  queryMobileAnalyticsGoals,
  queryExperienceIssues,
  queryExperienceTotalInteraction,
  queryExperienceTotalDismissed,
  queryBannerAnalyticsChart,
  queryGoalReachedFromExperienceStarted,
} from 'next/client/queries';
import { TYPE, ANALYTICS_CACHE_CLEARED } from './actions';

const reducer = (state, action) => {
  switch (action.type) {
    case ANALYTICS_CACHE_CLEARED:
      return {};
    default:
      return state;
  }
};

export default composeReducers(reducer, createCollectionReducer(TYPE));

export const selectAccountAnalytics = (state, query = '') =>
  state[TYPE]?.[query] || [];

// Duplicating this to be possible to have loading and no result states
export const selectAccountAnalyticsNullable = (state, cacheKey = '') =>
  state[TYPE]?.[cacheKey];

const selectFlowAnalytics = createSelector(
  selectAccountAnalytics,
  analytics => {
    return (
      (analytics ?? [])?.reduce((acc, result) => {
        acc[result.flow_id] = result;
        return acc;
      }, {}) || {}
    );
  }
);

const selectExperiencesAnalytics = createSelector(
  selectAccountAnalytics,
  analytics => {
    return (
      (analytics ?? [])?.reduce((acc, result) => {
        acc[result.experience_id] = result;
        return acc;
      }, {}) || {}
    );
  }
);

export const selectFlowAnalyticsById = (state, flowId) =>
  selectFlowAnalytics(state, getCacheKey(queryFlowsMetricsAnalytics()))?.[
    flowId
  ] ||
  selectExperiencesAnalytics(
    state,
    getCacheKey(queryExperienceMetricsAnalytics())
  )?.[flowId];

export const selectExperienceStepAnalytics = (state, queryParams) =>
  selectAccountAnalyticsNullable(
    state,
    getCacheKey(queryExperienceStepAnalytics(queryParams))
  );

export const selectExperienceRecentUsersAnalytics = (state, queryParams) =>
  selectAccountAnalyticsNullable(
    state,
    getCacheKey(queryExperienceRecentUsers(queryParams))
  );

export const selectExperienceUsersAnalytics = (state, queryParams) =>
  selectAccountAnalyticsNullable(
    state,
    getCacheKey(queryExperienceUsers(queryParams))
  );

export const selectExperienceGoalAnalytics = (state, queryParams) =>
  selectAccountAnalyticsNullable(
    state,
    getCacheKey(queryExperienceGoal(queryParams))
  );

export const selectMobileAnalyticsEvents = (state, queryParams) =>
  selectAccountAnalyticsNullable(
    state,
    getCacheKey(queryMobileAnalyticsEvents(queryParams))
  );

export const selectMobileAnalyticsGoals = (state, queryParams) =>
  selectAccountAnalyticsNullable(
    state,
    getCacheKey(queryMobileAnalyticsGoals(queryParams))
  );

export const selectExperienceStepBreakdownAnalytics = (state, queryParams) =>
  selectAccountAnalyticsNullable(
    state,
    getCacheKey(queryExperienceStepBreakdown(queryParams))
  );

export const selectPinIssuesAnalytics = (state, queryParams) =>
  selectAccountAnalyticsNullable(
    state,
    getCacheKey(queryExperienceIssues(queryParams))
  );

export const selectExperienceTotalInteraction = (state, queryParams) =>
  selectAccountAnalyticsNullable(
    state,
    getCacheKey(queryExperienceTotalInteraction(queryParams))
  );

export const selectExperienceTotalDismissed = (state, queryParams) =>
  selectAccountAnalyticsNullable(
    state,
    getCacheKey(queryExperienceTotalDismissed(queryParams))
  );

export const selectBannerEvents = (state, queryParams) =>
  selectAccountAnalyticsNullable(
    state,
    getCacheKey(queryBannerAnalyticsChart(queryParams))
  );

export const selectGoalReachedFromExperienceStarted = (state, queryParams) =>
  selectAccountAnalyticsNullable(
    state,
    getCacheKey(queryGoalReachedFromExperienceStarted(queryParams))
  );
