import React from 'react';
import PropTypes from 'prop-types';
import { CButton } from '@appcues/component-library';
import { IconWithinCButton } from '@studio/legacy-components';

import useToggle from 'next/hooks/use-toggle';
import UploadTranslationModal from 'components/StepSettings/Localization/UploadTranslationModal';
import CoolTip from 'components/Common/CoolTip';

export const UploadManager = ({ flowId, localeId, disabled }) => {
  const [isUploadModalShowing, toggleUploadModal] = useToggle();
  return (
    <>
      <CoolTip
        tip="Your flow must be localized and unpublished to upload translations."
        disabled={!disabled}
      >
        <CButton disabled={disabled} onClick={toggleUploadModal} key={localeId}>
          <IconWithinCButton icon="upload" gray />
          Upload Translation
        </CButton>
      </CoolTip>
      {isUploadModalShowing && (
        <UploadTranslationModal
          onClose={toggleUploadModal}
          localeId={localeId}
          flowId={flowId}
        />
      )}
    </>
  );
};

UploadManager.propTypes = {
  flowId: PropTypes.string,
  disabled: PropTypes.bool,
  localeId: PropTypes.string,
};

export default UploadManager;
