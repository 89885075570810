import { createLifecycleFor } from 'actions/account/collections';
import { TYPE } from './constants';

export const {
  callApi,
  resolve,
  create: upsert,
  insert,
  patterns,
} = createLifecycleFor(TYPE);
