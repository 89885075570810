import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import { transformStyle } from '@appcues/libcues/dist/steps/transforms';
import { stylePageCss } from 'constants/account/themes';

export const appcuesContentMissing = currentIframeDocument => {
  const appcuesContainer =
    currentIframeDocument && currentIframeDocument.querySelector('.appcues');
  return !(appcuesContainer && appcuesContainer.innerHTML);
};

const PreviewContainer = ({ theme, content, height, title }) => {
  const $sdkScriptContainer = useRef(null);
  const [appcuesLoaded, setAppcuesLoaded] = useState(false);
  const [iframeKey, setIframeKey] = useState(Math.random());

  const handleRefresh = useCallback(() => {
    if ($sdkScriptContainer.current) {
      $sdkScriptContainer.current.contentWindow.location.reload();
    }
    setAppcuesLoaded(false);
    setIframeKey(Math.random());
  }, [$sdkScriptContainer]);

  // inject content
  useEffect(() => {
    const currentIframeDocument = $sdkScriptContainer.current.contentDocument;
    const headEl = currentIframeDocument.head;
    const sdkScriptEl = currentIframeDocument.createElement('script');
    sdkScriptEl.type = 'text/javascript';
    sdkScriptEl.addEventListener('load', () => {
      const appcues = $sdkScriptContainer.current.contentWindow.Appcues;
      appcues.injectContent(content);
      setAppcuesLoaded(true);
      appcues.on('all', event => {
        if (event === 'flow_completed' || event === 'flow_skipped') {
          handleRefresh();
        }
      });
    });
    /* global APPCUES_INJECTABLE_SDK_URL */
    sdkScriptEl.src = APPCUES_INJECTABLE_SDK_URL;
    headEl.append(sdkScriptEl);
    setTimeout(() => {
      if (appcuesContentMissing(currentIframeDocument)) {
        handleRefresh();
      }
    }, 5000);
  }, [$sdkScriptContainer, content, iframeKey]);

  // inject theme
  useEffect(() => {
    if (theme) {
      const formattedTheme = transformStyle(theme);
      formattedTheme.globalStyling += stylePageCss;
      if (appcuesLoaded) {
        $sdkScriptContainer.current.contentWindow.Appcues.injectStyles(
          formattedTheme,
          {
            [theme.id]: formattedTheme,
          }
        );
      }
    }
  }, [$sdkScriptContainer, theme, appcuesLoaded]);

  return (
    <PreviewWrapper title={title}>
      <iframe
        key={iframeKey}
        height={height}
        className="sdk-script-container"
        ref={$sdkScriptContainer}
        title="SDK script container"
      />
    </PreviewWrapper>
  );
};

const PreviewWrapper = styled.div`
  .sdk-script-container {
    border: none;
    width: 100%;
    min-width: 400px;
  }
`;

PreviewContainer.defaultProps = {
  height: '400px',
};

export default PreviewContainer;
