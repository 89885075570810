import React, { useCallback, useState } from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-css';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Flex, Box, CButton } from '@appcues/component-library';
import { IconWithinCButton } from '@studio/legacy-components';

const highlightContent = code => highlight(code, languages.css);

const Css = ({ draftTheme, setDraftTheme, onExpand }) => {
  const [expandButtonText, setExpandButtonText] = useState('Expand');

  const handleChange = useCallback(
    value => {
      const labelName = 'customCss';
      setDraftTheme(prevDraftTheme => {
        return { ...prevDraftTheme, [labelName]: value };
      });
    },
    [setDraftTheme]
  );

  const expandCSS = () => {
    const newButtonText = expandButtonText !== 'Expand' ? 'Expand' : 'Collapse';
    setExpandButtonText(newButtonText);
    onExpand(expandButtonText);
  };

  return (
    <Box width="100%" backgroundColor="$gray-8">
      <Flex justifyContent="space-between" padding={12}>
        <CButton
          is="a"
          isExternal
          href="http://docs.appcues.com/category/120-styling-customization"
        >
          CSS Help Docs
        </CButton>
        <CButton onClick={expandCSS}>
          <IconWithinCButton icon="expand" gray />
          {expandButtonText}
        </CButton>
      </Flex>
      <Box maxHeight="90vh" overflowY="auto">
        <StyledEditor
          value={draftTheme.customCss}
          onValueChange={handleChange}
          highlight={highlightContent}
        />
      </Box>
    </Box>
  );
};

Css.propTypes = {
  draftTheme: PropTypes.object.isRequired,
  setDraftTheme: PropTypes.func.isRequired,
};

export default Css;

const StyledEditor = styled(Editor)`
  margin: 1rem;
  caret-color: ${props => props.theme['$gray-0']};
  color: ${props => props.theme['$gray-0']};
  font-family: 'Fira code', 'Fira Mono', monospace;

  /* purposely !important because the react-simple-code-editor uses in-line styling */
  pre,
  textarea {
    overflow-wrap: normal !important;
    word-break: break-all !important;
  }
`;
