import React from 'react';
import PropTypes from 'prop-types';
import TextInput from './ThemeInput';

const isValidNumber = value => /^-?[\d.]+(?:e-?\d+)?$/.test(value);

function InputWithSuffix({
  placeholder,
  suffix,
  label,
  draftTheme,
  setDraftTheme,
  width,
  minWidth,
  marginTop,
  onBeforeSetDraft,
  styleTheme,
}) {
  const handleSetDraftTheme = value => {
    const { name } = label;
    const borderWidth = value ? `${value}${suffix}` : '';
    const styles = onBeforeSetDraft
      ? onBeforeSetDraft({ borderWidth, label, draftTheme })
      : {};
    setDraftTheme(prevDraftTheme => {
      return {
        ...prevDraftTheme,
        [name]: borderWidth,
        ...styles,
      };
    });
  };

  const handleValueValidation = value => {
    let tempValue = value;
    if (value.includes(suffix)) {
      tempValue = value.replace(suffix, '');
    }
    // only set value if value entered is a valid number or is empty
    // also value can't be greater than 3
    if ((isValidNumber(tempValue) || !tempValue) && tempValue?.length < 4) {
      handleSetDraftTheme(tempValue);
    }
  };

  const handleOnKeyDown = e => {
    // handle when user deletes a value
    // The handleOnKeyDown function is specifically for when the user intends to delete the value entered.
    // So the newNumberValue contains the the value after the deletion has occurred.
    if (e.key === 'Backspace') {
      const {
        target: { value },
      } = e;
      const numberValue = value.replace(suffix, '');
      const newNumberValue = numberValue.slice(0, -1);
      handleSetDraftTheme(newNumberValue);
    }
  };

  return (
    <TextInput
      handleChangeInputWithSuffix={handleValueValidation}
      width={width}
      minWidth={minWidth}
      placeholder={placeholder}
      marginTop={marginTop}
      suffix={suffix}
      label={label}
      draftTheme={draftTheme}
      handleOnKeyDown={handleOnKeyDown}
      styleTheme={styleTheme}
    />
  );
}

InputWithSuffix.propTypes = {
  placeholder: PropTypes.string,
  suffix: PropTypes.string.isRequired,
  label: PropTypes.object.isRequired,
  draftTheme: PropTypes.object.isRequired,
  setDraftTheme: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBeforeSetDraft: PropTypes.func,
  styleTheme: PropTypes.string,
};

export default InputWithSuffix;
