import {
  FLUSH_INTEGRATION_TO_FIREBASE,
  REMOVE_INTEGRATION_IN_STORE,
  ACTIVATE_INTEGRATION_IN_STORE,
  ACTIVATE_INTEGRATION_IN_STORE_ROLLBACK,
  TOGGLE_INTEGRATION_STATE,
  FETCH_INTEGRATION,
  REPLACE_ACCOUNT_INTEGRATIONS,
  REMOVE_INTEGRATION_AND_FLUSH_INTEGRATION,
  ACTIVATE_INTEGRATION_AND_FLUSH_INTEGRATION,
} from 'constants/actionTypes';

export function flushIntegrationToFirebase(integrationId) {
  return {
    type: FLUSH_INTEGRATION_TO_FIREBASE,
    payload: {
      integrationId,
    },
  };
}

export function removeIntegrationInStore(integrationId) {
  return {
    type: REMOVE_INTEGRATION_IN_STORE,
    payload: {
      integrationId,
    },
  };
}

export function getIntegrationData(integrationId) {
  return {
    type: FETCH_INTEGRATION,
    payload: {
      integrationId,
    },
  };
}

export function activateIntegrationInStore(integrationData) {
  return {
    type: ACTIVATE_INTEGRATION_IN_STORE,
    payload: {
      [integrationData.integrationId]: {
        isEnabled: true,
        id: integrationData.integrationId,
        integrationId: `${integrationData.accountId}:${integrationData.integrationId}`,
        createdBy: integrationData.userId,
        createdAt: Date.now(),
      },
    },
  };
}

export function activateIntegrationInStoreRollback(rollbackData) {
  return {
    type: ACTIVATE_INTEGRATION_IN_STORE_ROLLBACK,
    payload: { rollbackData },
  };
}

export function toggleIntegrationState(integrationId, instanceId, enabled) {
  return {
    type: TOGGLE_INTEGRATION_STATE,
    payload: { integrationId, instanceId, enabled },
  };
}

export function replaceAccountIntegrations(integrations) {
  return {
    type: REPLACE_ACCOUNT_INTEGRATIONS,
    payload: integrations,
  };
}

export function removeAndFlushIntegration(integrationId) {
  return {
    type: REMOVE_INTEGRATION_AND_FLUSH_INTEGRATION,
    payload: { integrationId },
  };
}

export function activateAndFlushIntegration(integrationId) {
  return {
    type: ACTIVATE_INTEGRATION_AND_FLUSH_INTEGRATION,
    payload: { integrationId },
  };
}
