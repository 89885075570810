import { call, put, getContext, takeEvery } from 'redux-saga/effects';
import toast from 'next/lib/toast';
import { promisaga } from 'utils/as-promised';
import { reportError } from 'helpers/error-reporting';
import {
  patterns,
  replace,
  resetFlowHistorySuccess,
  RESET_FLOW_HISTORY_SUCCESS,
  RESET_FLOW_HISTORY,
  remove,
} from './actions';

export function* getUserQualifiedContent({ payload: { userId } }) {
  try {
    const api = yield getContext('endUserApi');
    const { data } = yield call(api.getUserQualifiedContent, userId);
    yield put(replace({ id: userId, ...data }));
  } catch (error) {
    yield call(reportError, error);
  }
}

export function* cleanUserQualifiedContent({ payload: userId }) {
  yield put(remove(userId));
}

export function* resetUserFlowHistory({ payload: userId }) {
  try {
    const api = yield getContext('endUserApi');
    yield call(api.resetUserFlowHistory, userId);
    yield put(resetFlowHistorySuccess(userId));
    yield call(toast.success, 'Flow history reset');
  } catch (error) {
    yield call(reportError, error);
    yield call(toast.error, 'Failed to reset Flow history');
  }
}

export default function* saga() {
  yield takeEvery(patterns.callApi, getUserQualifiedContent);
  yield takeEvery(RESET_FLOW_HISTORY_SUCCESS, cleanUserQualifiedContent);
  yield takeEvery(RESET_FLOW_HISTORY, promisaga(resetUserFlowHistory));
}
