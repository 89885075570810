import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H3 } from '@appcues/component-library';

import NoEventsInSource from 'assets/images/no-events-in-source.svg';

const NoResultsWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 30px 30px 30px 30px;
  align-items: center;
  > * {
    margin: 1em 0;
  }
`;

const NoResults = ({ type }) => (
  <NoResultsWrapper>
    <img src={NoEventsInSource} alt="No events matching filters" />
    <H3>No results found{type ? ` for ${type}` : ''}</H3>
  </NoResultsWrapper>
);

NoResults.propTypes = {
  type: PropTypes.string,
};

export default NoResults;
