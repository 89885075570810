import React, { useCallback, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  ErrorMsg,
  Heading,
  Icon,
  Modal,
  WarningNotice,
} from '@studio/legacy-components';
import { asRequest } from 'next/entities/requests';
import {
  create,
  update,
  remove,
  readTags,
  Shape as TagShape,
} from 'next/entities/tags';

import Tag from './Tag';
import Create from './Create';
import {
  Content,
  Footer,
  Loading,
  MsgWrap,
  Search,
  SuccessNoticeWithIcon,
  Tags,
  TruncatedName,
} from './styled';

const CREATE = Symbol('@tag/create');

const MESSAGE_ERROR = 'error';
const MESSAGE_SUCCESS = 'success';
const MESSAGE_WARNING = 'warn';

const initial = {
  editing: null,
  error: false,
  message: '',
  messageType: MESSAGE_SUCCESS,
  search: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    // Toggle state of current tag or set new tag as editing
    case '@toggled': {
      const { id } = action.payload;
      const editing = state.editing === id ? null : id;
      return { ...state, editing, error: false, message: '' };
    }

    // Close edited tag if valid
    // If not valid, show duplicate error and keep editor open
    case '@validated': {
      const { name, valid } = action.payload;
      return valid
        ? {
            ...state,
            editing: null,
            messageType: MESSAGE_SUCCESS,
          }
        : {
            ...state,
            error: true,
            messageType: MESSAGE_ERROR,
            message: (
              <MsgWrap>
                A tag by the name <TruncatedName>&quot;{name}</TruncatedName>
                &quot; already exists.
              </MsgWrap>
            ),
          };
    }

    // Close any editing tag while searching
    case '@searched': {
      const { search } = action.payload;
      return { search, editing: null, error: false, message: '' };
    }

    // Show message if tag successfully created
    case '@created': {
      const { name } = action.payload;
      return {
        ...state,
        message: (
          <MsgWrap>
            Added <TruncatedName>&quot;{name}</TruncatedName>&quot; to your
            tags.
          </MsgWrap>
        ),
      };
    }

    // Change to tag was unable to complete
    case '@failed': {
      return {
        ...state,
        messageType: MESSAGE_WARNING,
        message: 'An error occurred changing your tag.  Please try again.',
      };
    }

    // Show message if tag successfully updated
    case '@updated': {
      const { name } = action.payload;
      return {
        ...state,
        message: (
          <MsgWrap>
            Changed tag name to <TruncatedName>&quot;{name}</TruncatedName>
            &quot;.
          </MsgWrap>
        ),
      };
    }

    // Show message if tag successfully deleted
    case '@deleted': {
      const { name } = action.payload;
      return {
        ...state,
        message: (
          <MsgWrap>
            You have successfully deleted the tag{' '}
            <TruncatedName>&quot;{name}</TruncatedName>&quot;.
          </MsgWrap>
        ),
        messageType: MESSAGE_SUCCESS,
      };
    }

    // Reset state, specifically on modal close
    case '@resetted':
      return initial;

    default:
      return state;
  }
};

const Message = ({ children, messageType }) => {
  switch (messageType) {
    case MESSAGE_SUCCESS:
      return (
        <SuccessNoticeWithIcon>
          <Icon icon="check-circle" />
          {children}
        </SuccessNoticeWithIcon>
      );
    case MESSAGE_WARNING:
      return <WarningNotice>{children}</WarningNotice>;
    case MESSAGE_ERROR:
      return <ErrorMsg>{children}</ErrorMsg>;
    default:
      return null;
  }
};

Message.propTypes = {
  children: PropTypes.node,
  messageType: PropTypes.oneOf([
    MESSAGE_ERROR,
    MESSAGE_SUCCESS,
    MESSAGE_WARNING,
  ]),
};

export function TagsModal({
  onClose,
  onCreate,
  onDelete,
  onUpdate,
  tags = {},
  visible,
}) {
  const [state, dispatch] = useReducer(reducer, initial);

  const { editing, error, message, messageType } = state;

  // Close modal and reset state values
  const handleClose = useCallback(() => {
    onClose();
    dispatch({ type: '@resetted' });
  }, [onClose]);

  // Create bounded editing open/close handler
  const handleToggleFor = useCallback(
    id => () => {
      dispatch({ type: '@toggled', payload: { id } });
    },
    []
  );

  // Validate whether new/updated name is already taken. If so, set the name as
  // a duplicate for error messaging and keep the editing state open. Otherwise,
  // clear the error and close the editor
  const validate = useCallback(
    name => {
      const valid = !Object.values(tags.data).some(
        tag => tag.name.trim() === name
      );

      dispatch({ type: '@validated', payload: { name, valid } });

      return valid;
    },
    [tags.data]
  );

  const handleSearch = useCallback(({ target: { value: search } }) => {
    dispatch({ type: '@searched', payload: { search } });
  }, []);

  const handleCreate = useCallback(
    async (event, name) => {
      if (validate(name)) {
        try {
          await onCreate({ name });
          dispatch({ type: '@created', payload: { name } });
        } catch {
          dispatch({ type: '@failed' });
        }
      }
    },
    [onCreate, validate]
  );

  const handleUpdateFor = useCallback(
    id => async (event, name) => {
      if (validate(name)) {
        try {
          await onUpdate(id, { name });
          dispatch({ type: '@updated', payload: { name } });
        } catch {
          dispatch({ type: '@failed' });
        }
      }
    },
    [onUpdate, validate]
  );

  const handleDeleteFor = useCallback(
    (id, name) => async () => {
      try {
        await onDelete(id);
        dispatch({ type: '@deleted', payload: { name } });
      } catch {
        dispatch({ type: '@failed' });
      }
    },
    [onDelete]
  );

  const processed = useMemo(
    () =>
      Object.values(tags.data)
        .filter(({ name }) => {
          return new RegExp(state.search, 'i').test(name);
        })
        .sort(({ name: a }, { name: b }) => {
          return a?.localeCompare(b, 'en', {
            sensitivity: 'base',
            numeric: true,
          });
        }),
    [tags.data, state.search]
  );

  return (
    <Modal onClose={handleClose} size="m" visible={visible}>
      <Heading>Manage tags</Heading>

      <Content>
        {tags.loading && <Loading aria-label="Loading tags" />}

        {!tags.loading && (
          <>
            <Search onChange={handleSearch} value={state.search} />
            {message && <Message messageType={messageType}>{message}</Message>}
            <Tags>
              {!state.search && (
                <Create
                  editing={editing === CREATE}
                  error={error}
                  onCreate={handleCreate}
                  onToggle={handleToggleFor(CREATE)}
                />
              )}

              {processed.map(({ id, name }) => (
                <Tag
                  key={id}
                  editing={editing === id}
                  id={id}
                  error={error}
                  name={name}
                  onDelete={handleDeleteFor(id, name)}
                  onToggle={handleToggleFor(id)}
                  onUpdate={handleUpdateFor(id)}
                />
              ))}
            </Tags>
          </>
        )}
      </Content>

      <Footer>
        <Button kind="primary" onClick={handleClose}>
          Done
        </Button>
      </Footer>
    </Modal>
  );
}

TagsModal.propTypes = {
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  tags: asRequest(PropTypes.objectOf(TagShape)),
  visible: PropTypes.bool,
};

const mapStateToProps = state => ({
  tags: readTags(state),
});

const mapDispatchToProps = {
  onCreate: data => create(data),
  onDelete: id => remove(id),
  onUpdate: (id, delta) => update(id, delta),
};

export default connect(mapStateToProps, mapDispatchToProps)(TagsModal);
