import {
  BRAZE_INTEGRATION,
  FULLSTORY_INTEGRATION,
  HOTJAR_INTEGRATION,
  LOGROCKET_INTEGRATION,
} from 'next/entities/features';
import { selectAccountFeature } from 'reducers/account/features';
import {
  SALESFORCE_INTEGRATION,
  HUBSPOT_TWO_WAY_INTEGRATION,
  ZENDESK_SUPPORT_INTEGRATION,
  SLACK_INTEGRATION,
  MARKETO_INTEGRATION,
  RUDDERSTACK_INTEGRATION,
} from 'constants/features';
import INTEGRATION_TYPES from 'constants/integration-types';

export const selectAvailableIntegrationTypes = state => {
  const allIntegrations = Object.values(INTEGRATION_TYPES);

  const integrationToFeatureFlag = {
    salesforce: SALESFORCE_INTEGRATION,
    'hubspot-two-way-integration': HUBSPOT_TWO_WAY_INTEGRATION,
    'slack-integration': SLACK_INTEGRATION,
    'zendesk-support-two-way-integration': ZENDESK_SUPPORT_INTEGRATION,
    marketo: MARKETO_INTEGRATION,
    rudderstack: RUDDERSTACK_INTEGRATION,
    braze: BRAZE_INTEGRATION,
    fullstory: FULLSTORY_INTEGRATION,
    hotjar: HOTJAR_INTEGRATION,
    logrocket: LOGROCKET_INTEGRATION,
  };

  return allIntegrations
    .map(integration => {
      const available = integration.featureFlagged
        ? selectAccountFeature(state, integrationToFeatureFlag[integration.id])
        : true;

      const visible = available ? true : integration.shouldDisplayCard;

      return {
        ...integration,
        available,
        visible,
      };
    })
    .filter(({ visible }) => visible);
};
