import PropTypes from 'prop-types';
import pick from 'lodash.pick';

/**
 * Full shape of content-status model
 */
const Shape = {
  publishedAndDirty: PropTypes.bool,
  publishedAndDirtyUpdatedAt: PropTypes.number,
  status: PropTypes.object,
};

/**
 * Valid fields for reading
 */
const allowed = ['publishedAndDirty'];

/**
 *
 * @param {Object<keyof ContentStatus>} contentStatus - Object with content-status fields
 * @returns {Object<keyof ContentStatus>>} Object with parsed content-status fields
 */
export const parse = contentStatus => pick(contentStatus, allowed);

export default PropTypes.shape(parse(Shape));
