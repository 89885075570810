import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@studio/legacy-components';
import { Remove, Editable, Name, Wrapper } from './styled';

export default function ExperienceTag({ name, onRemove, className }) {
  return (
    <Wrapper className={className}>
      <Editable>
        <Name title={name}>{name}</Name>
        <Remove aria-label={`Remove tag ${name}`} onClick={onRemove}>
          <Icon icon="times" />
        </Remove>
      </Editable>
    </Wrapper>
  );
}

ExperienceTag.propTypes = {
  name: PropTypes.string,
  onRemove: PropTypes.func,
  className: PropTypes.string,
};
