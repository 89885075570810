import { channel } from 'redux-saga';
import { fork, put, takeEvery, takeLeading } from 'redux-saga/effects';

/**
 * Implements `takeLatest` handling per unique value returned by `scopeSelector`.
 */
export function takeLeadingByProp(scopeSelector, pattern, worker) {
  return fork(function* job() {
    const channels = {};

    yield takeEvery(pattern, function* listener(action) {
      const scope = scopeSelector(action);
      if (!channels[scope]) {
        channels[scope] = channel();
        yield takeLeading(channels[scope], worker);
      }
      yield put(channels[scope], action);
    });
  });
}
