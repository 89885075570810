import { createCollectionReducer } from 'next/lib/collections';
import { selectRequestStatus } from 'next/entities/requests';
import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

export const selectUsers = state => state[TYPE];
export const selectUser = (state, id) => selectUsers(state)?.[id];

export const readUsers = state => ({
  ...selectRequestStatus(state, TYPE),
  data: selectUsers(state),
});
