import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, Tooltip } from '@studio/legacy-components';
import { InfoIcon } from 'components/Insights/Common/Styled';

const Message = styled.div`
  white-space: normal;
  width: 220px;

  ${Icon} {
    color: var(--white);
  }
`;

const PlusIcon = styled(Icon)`
  color: var(--icon);
`;

export const AddEventTooltip = () => (
  <Tooltip
    placement="right"
    label={
      <Message>
        Compare up to 4 events from any event source by clicking the{' '}
        <Icon icon="plus-circle" /> to add events to the chart above.
      </Message>
    }
    wrapped
  >
    <PlusIcon icon="plus" />
  </Tooltip>
);

export const TitleTooltip = ({ tip = '', title = '' }) => (
  <Tooltip label={tip} placement="top" size="m" wrapped>
    <InfoIcon /> {title}
  </Tooltip>
);

TitleTooltip.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
};
