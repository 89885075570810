import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { H2, P } from '@appcues/component-library';
import { read as getApps, readApps, AppShape } from 'next/entities/apps';
import { PUSH_WORKFLOWS, selectFeature } from 'next/entities/features';
import { asRequest } from 'next/entities/requests';
import useTitle from 'next/hooks/use-title';
import WebCard from './WebCard';
import AddAppCard from './AddAppCard';
import AppCard from './AppCard';
import { AppsLayout } from './styled';
import { APP_ENABLED, APP_DISABLED } from './lib';

const AppsInstallation = ({ apps = {}, installed, hasPushEnabled, onLoad }) => {
  useTitle('Installation | Settings | Appcues');
  useEffect(() => {
    onLoad?.();
  }, [onLoad]);

  // TODO: This is temporal until we fetch real data and determine
  // where the installed flag from apps is going to come from.
  const [enabledApps, disabledApps] = useMemo(() => {
    return Object.values(apps.data)
      .map(app => ({
        ...app,
        // Fake it for now, set all installed so we can see properly
        // installed / disabled badges until we have the analytics query
        installed: true,
      }))
      .sort((a, b) => {
        return a.createdAt <= b.createdAt ? 1 : -1;
      })
      .reduce(
        // Split apps by state
        (acc, app) => {
          let [enabled, disabled] = acc;
          if (app?.state === APP_ENABLED) enabled = [...enabled, app];
          if (app?.state === APP_DISABLED) disabled = [...disabled, app];

          return [enabled, disabled];
        },
        [[], []]
      );
  }, [apps.data]);

  return (
    <>
      <H2 type="secondary">Install Appcues to publish live experiences!</H2>
      <P marginTop={24} marginBottom={16} type="secondary">
        In order to publish live in-app experiences that your users can engage
        with, you’ll need to install Appcues directly into your application’s
        codebase. Whether you want to install Appcues on your web app, mobile
        app, or both, we’ve got you covered with these installation guides!
      </P>
      <AppsLayout>
        <WebCard installed={installed} />
        <AddAppCard />
        {!apps.loading && (
          <>
            {enabledApps.map(app => (
              <AppCard
                key={app.id}
                app={app}
                installed={app.installed}
                hasPushEnabled={hasPushEnabled}
              />
            ))}
            {disabledApps.map(app => (
              <AppCard
                key={app.id}
                app={app}
                installed={app.installed}
                hasPushEnabled={hasPushEnabled}
              />
            ))}
          </>
        )}
      </AppsLayout>
    </>
  );
};

AppsInstallation.propTypes = {
  apps: asRequest(PropTypes.objectOf(AppShape)),
  installed: PropTypes.bool,
  hasPushEnabled: PropTypes.bool,
  onLoad: PropTypes.func,
};

const mapStateToProps = state => ({
  apps: readApps(state),
  hasPushEnabled: selectFeature(state, PUSH_WORKFLOWS),
});

const mapDispatchToProps = {
  onLoad: getApps,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppsInstallation);
