import React from 'react';
import { CDialog } from '@appcues/component-library';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledImage = styled.img`
  max-width: 400px;
`;

const IntegrationImage = ({ alt, src }) => {
  return (
    <CDialog.Header>
      {src && <StyledImage alt={alt} src={src} />}
    </CDialog.Header>
  );
};

IntegrationImage.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
};

export default IntegrationImage;
