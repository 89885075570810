import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Flex, H3, Text } from '@appcues/component-library';
import { Icon } from '@studio/legacy-components';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: ${props => props.theme['$gray-2']};
  height: 64px;
  width: 64px;
  margin-bottom: 16px;
`;

const LocalizationEmptyState = ({ children }) => {
  return (
    <Flex
      width={400}
      flexDirection="column"
      alignItems="center"
      lineHeight="150%"
      paddingTop={56}
    >
      <IconWrapper>
        <Icon icon="globe" fontSize={24} />
      </IconWrapper>
      <H3 paddingBottom={8} fontWeight={600}>
        No additional languages
      </H3>
      <Text>If you plan to translate your content for multiple languages,</Text>
      <Text>add the ones you wish to support here.</Text>
      {children}
    </Flex>
  );
};

LocalizationEmptyState.propTypes = {
  children: PropTypes.node,
};

export default LocalizationEmptyState;
