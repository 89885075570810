import { createCollectionReducer } from 'reducers/account/collections';
import { selectIsSynced } from 'reducers/account/requests';

export const TYPE = 'segmentMemberships';

export default createCollectionReducer(TYPE);

export const selectSegmentMembershipsSynced = state =>
  selectIsSynced(state, TYPE);
export const selectSegmentMemberships = state =>
  (state.account || {})[TYPE] || {};
export const selectSegmentMembership = (state, { segmentId }) =>
  (selectSegmentMemberships(state) || {})[segmentId] || {};
export const selectSegmentSampleUserIds = (state, { segmentId }) => {
  const segmentMembership = selectSegmentMembership(state, { segmentId });
  return {
    meta: { synced: selectSegmentMembershipsSynced(state) },
    data: (segmentMembership.sample_user_ids || []).map(user_id => ({
      user_id,
    })),
  };
};
export const selectSegmentMembershipsComputedAt = state =>
  Math.min(
    ...Object.values(selectSegmentMemberships(state)).map(({ computed_at }) =>
      Date.parse(computed_at)
    )
  );
