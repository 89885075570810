import React from 'react';
import styled from 'styled-components';

import { Flex, H4 } from '@appcues/component-library';
import { ExternalLink } from '@studio/legacy-components';
import {
  modalPreviewContent,
  tooltipPreviewContent,
  slideoutPreviewContent,
} from 'constants/account/themes';
import PreviewContainer from 'components/Common/PreviewContainer';

const HELP_URL = `https://docs.appcues.com/article/515-styling-flows`;

const PreviewPanel = ({ theme }) => {
  return (
    <PreviewWrapper>
      <Flex
        backgroundColor="#242A35"
        height="64px"
        alignItems="center"
        paddingLeft="24px"
        justifyContent="space-between"
      >
        <H4 type="secondary" light>
          Preview
        </H4>
        <HelpText>
          Not seeing your changes?
          <ExternalLink href={HELP_URL}>Learn More</ExternalLink>
        </HelpText>
      </Flex>
      <PreviewContainer
        theme={theme}
        content={modalPreviewContent}
        height="480px"
        title="Modal Preview"
      />
      <PreviewContainer
        theme={theme}
        content={tooltipPreviewContent}
        height="256px"
        title="Tooltip Preview"
      />
      <PreviewContainer
        theme={theme}
        content={slideoutPreviewContent}
        height="360px"
        title="Slideout Preview"
      />
    </PreviewWrapper>
  );
};

const PreviewWrapper = styled.div`
  height: 100%;
  background-color: ${props => props.theme['$gray-50']};
  border-left: 1px solid ${props => props.theme['$gray-50']};
  border-radius: 6px;
  position: relative;
  z-index: 2;
  overflow: hidden;
`;

const HelpText = styled.div`
  color: var(--white);
  font-size: var(--regular);
  margin: 1px 10px 0px 10px;

  ${ExternalLink} {
    --link-color: inherit;
    --link-visited-color: var(--white);

    margin-left: 4px;
  }
`;

export default PreviewPanel;
