import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CButton, CPanel, H3 } from '@appcues/component-library';

import Reports from 'assets/images/reports.svg';

const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px 90px 30px 30px;
  align-items: center;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  > * {
    margin: 1.5em 0;
  }
`;

const EmptyState = () => (
  <CPanel>
    <EmptyStateWrapper>
      <Description>
        <H3>Track flow performance</H3>
        <p>
          Once this flow is being seen by users, we’ll surface key performance
          stats. You’ll know which flows are being seen most, where there are
          errors, and how your flows are impacting your goals.
        </p>
        <p>
          <CButton type="positive" is={Link} to="./settings">
            Publish in settings
          </CButton>
        </p>
      </Description>
      <img src={Reports} alt="Reporting" />
    </EmptyStateWrapper>
  </CPanel>
);

export default EmptyState;
