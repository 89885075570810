import {
  groupOperatorValues,
  conditionNames,
  propertyOperators,
} from '@appcues/libcues';

export const friendlyNames = {
  [conditionNames.URL]: 'path',
  [conditionNames.DOMAINS]: 'environment',
  [conditionNames.PROPERTIES]: 'property',
  [conditionNames.LANGUAGES]: 'language',
  [conditionNames.CONTENT]: 'step group',
  [conditionNames.EVENT]: 'event',
  [conditionNames.SEGMENTS]: 'segment',
  [conditionNames.FREQUENCY]: 'frequency',
};

export const defaultOperators = {
  STARTS_WITH: propertyOperators.STARTS_WITH,
  NOT_STARTS_WITH: propertyOperators.NOT_STARTS_WITH,
  ENDS_WITH: propertyOperators.ENDS_WITH,
  NOT_ENDS_WITH: propertyOperators.NOT_ENDS_WITH,
  CONTAINS: propertyOperators.CONTAINS,
  NOT_CONTAINS: propertyOperators.NOT_CONTAINS,
  EXACT: propertyOperators.EQUALS,
  NOT_EXACT: propertyOperators.NOT_EQUALS,
  REGEX: propertyOperators.REGEX,
};

export const millisecondsPerDay = 1000 * 60 * 60 * 24;

// the following operators denote values stored in milliseconds.  they must therefore be multiplied or
// divided to work seamlessly when the clause operator changes

export const millisecondOperators = [
  propertyOperators.GREATER_THAN_X_DAYS_AGO,
  propertyOperators.LESS_THAN_X_DAYS_AGO,
  propertyOperators.WITHIN_X_DAYS_AGO,
];

export const datePickerOperators = [
  propertyOperators.LESS_THAN_TIME,
  propertyOperators.GREATER_THAN_TIME,
];

export const baseTriggerClauseAttributeConditions = {
  [groupOperatorValues.OR]: [
    {
      [conditionNames.ATTRIBUTES]: {
        attribute: '',
        operator: propertyOperators.CONTAINS,
        value: '',
      },
    },
  ],
};

export const REPLACE_ACCOUNT_CONDITIONS = 'REPLACE_ACCOUNT_CONDITIONS';
export const REPLACE_ACCOUNT_STEP_CONDITIONS =
  'REPLACE_ACCOUNT_STEP_CONDITIONS';
export const UPDATE_ACCOUNT_STEP_CONDITIONS = 'UPDATE_ACCOUNT_STEP_CONDITIONS';
export const ADD_CLAUSE_AND_FLUSH_RULE = 'ADD_CLAUSE_AND_FLUSH_RULE';
export const ADD_GROUP_CLAUSE_AND_FLUSH_RULE =
  'ADD_GROUP_CLAUSE_AND_FLUSH_RULE';
export const UPDATE_CLAUSE_AND_FLUSH_RULE = 'UPDATE_CLAUSE_AND_FLUSH_RULE';
export const REPLACE_CLAUSE_AND_FLUSH_RULE = 'REPLACE_CLAUSE_AND_FLUSH_RULE';
export const DISSOLVE_GROUP_CLAUSE_AND_FLUSH_RULE =
  'DISSOLVE_GROUP_CLAUSE_AND_FLUSH_RULE';
export const DELETE_CLAUSE_AND_FLUSH_RULE = 'DELETE_CLAUSE_AND_FLUSH_RULE';
export const DELETE_MATCHING_CLAUSES_AND_FLUSH_RULE =
  'DELETE_MATCHING_CLAUSES_AND_FLUSH_RULE';
export const SET_DISPLAY_ANY_PAGE_AND_FLUSH_RULE =
  'SET_DISPLAY_ANY_PAGE_AND_FLUSH_RULE';
export const SET_DISPLAY_SPECIFIC_PAGE_AND_FLUSH_RULE =
  'SET_DISPLAY_SPECIFIC_PAGE_AND_FLUSH_RULE';
export const SET_AUDIENCE_ALL_USERS_AND_FLUSH_RULE =
  'SET_AUDIENCE_ALL_USERS_AND_FLUSH_RULE';
export const SET_AUDIENCE_SEGMENT_AND_FLUSH_RULE =
  'SET_AUDIENCE_SEGMENT_AND_FLUSH_RULE';
export const SET_AUDIENCE_CUSTOM_SEGMENT_AND_FLUSH_RULE =
  'SET_AUDIENCE_CUSTOM_SEGMENT_AND_FLUSH_RULE';
