import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { EmptyState } from '@studio/legacy-components';
import { Button } from '@appcues/sonar';
import EmptyLaunchpadImage from './EmptyLaunchpadImage';

const Wrapper = styled.div`
  max-width: 620px;
  margin: 48px auto;
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
  display: inline-block;
  text-decoration: none;
`;

const Empty = () => (
  <Wrapper>
    <EmptyState
      action={
        <StyledButton forwardedAs={Link} to="/launchpads/builder">
          Create Launchpad
        </StyledButton>
      }
      image={<EmptyLaunchpadImage />}
      headline="Build a Launchpad"
    >
      The Launchpad is a self-service content center that lets your customers
      find help when they need it the most.
    </EmptyState>
  </Wrapper>
);

export default Empty;
