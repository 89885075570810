import { Component } from 'react';
import PropTypes from 'prop-types';
import { reportError } from 'helpers/error-reporting';

class ErrorBoundary extends Component {
  state = {
    error: null,
    info: null,
    hasError: false,
  };

  componentDidCatch(error, info) {
    reportError(error, info);
    this.setState({ error, info, hasError: true });
  }

  render() {
    const { children, render } = this.props;
    const { error, info, hasError } = this.state;

    if (hasError) {
      return render({ error, info });
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  render: PropTypes.elementType.isRequired,
};

export default ErrorBoundary;
