import React from 'react';
import PropTypes from 'prop-types';
import {
  CForm,
  CRadio,
  CCheckbox,
  CButton,
  P,
} from '@appcues/component-library';
import * as yup from 'yup';

import Modal from 'components/Common/UI/Modal/Modal';

export default function DownloadManagerModal({ onClose, onSubmit }) {
  return (
    <Modal title="Download content for translation" onClose={onClose}>
      <Modal.Content paddingX={5}>
        <P>
          We&apos;ll grab the text and images from your flow and place it neatly
          into a file format of your choosing. From there, add your translated
          text, and re-upload
        </P>
        <CForm
          onSubmit={onSubmit}
          initialValues={{
            format: 'csv',
            agreed: [],
          }}
          validationSchema={yup.object().shape({
            format: yup
              .string()
              .matches(
                /(csv|json)/,
                'Please select your desired output format.'
              )
              .required(),
            agreed: yup
              .string()
              .oneOf(
                ['agreed'],
                "Please acknowledge that downloading content will also lock this flow's content."
              )
              .required(),
          })}
        >
          <CForm.Field label="Format" name="format">
            <CRadio.Group>
              <CRadio label="CSV" value="csv" />
              <CRadio label="JSON" value="json" />
            </CRadio.Group>
          </CForm.Field>
          <CForm.Field label="Before you download..." name="agreed">
            <CCheckbox.Group>
              <CCheckbox
                label="I understand that downloading will lock my flow content and only allow me to edit positioning and styling."
                value="agreed"
              />
            </CCheckbox.Group>
          </CForm.Field>
          <Modal.Footer>
            <CButton onClick={onClose}>Cancel</CButton>
            <CForm.SubmitButton type="primary" disabledUntilDirty={false}>
              Download
            </CForm.SubmitButton>
          </Modal.Footer>
        </CForm>
      </Modal.Content>
    </Modal>
  );
}

DownloadManagerModal.propTypes = {
  flowId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

DownloadManagerModal.defaultProps = {
  onSubmit: () => false,
};
