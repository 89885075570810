import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  ButtonGroup,
  Modal,
  Heading,
  ModalFooter,
  Overflow,
  Table,
  Tr,
  Td,
  Tbody,
} from '@studio/legacy-components';
import { Shape, getMobileTargetingSections } from '@studio/conditions';

import {
  selectExperience,
  Shape as ExperienceShape,
} from 'next/entities/experiences';
import { selectRule } from 'next/entities/rules';
import ReadableConditions from 'next/components/ReadableConditions';
import StepsCarousel from 'next/components/StepsCarousel';
import SchedulingStartAlert from 'next/components/ExperienceScheduling/SchedulingStartAlert';
import { ScheduleType } from 'next/components/ExperienceScheduling/types';
import { selectSchedule } from 'next/entities/schedules';
import { formatScheduleDate } from 'next/components/ExperienceScheduling/dates';

import { FrequencyRule } from './FrequencyRule';
import { TableContainer } from './styled';

export function ConfirmPushChangesModal({
  experience = {},
  rule = {},
  onPublish,
  onClose,
  visible,
  schedule,
}) {
  const { id, steps = [] } = experience;
  const { conditions, frequency } = rule;

  const sections = getMobileTargetingSections(conditions);
  return (
    <Modal visible={visible} onClose={onClose} size="l">
      <Heading>Confirm Publish</Heading>
      <Overflow>
        <SchedulingStartAlert
          schedule={schedule}
          experienceType="Mobile Experience"
        />

        {steps.length > 0 && (
          <StepsCarousel experienceId={id} steps={steps} type="mobile" />
        )}
        <TableContainer>
          <Table>
            <Tbody>
              <Tr>
                <Td>App version</Td>
                <Td>
                  {sections.version ? (
                    <ReadableConditions conditions={sections.version} />
                  ) : (
                    'Any app version'
                  )}
                </Td>
              </Tr>
              <Tr>
                <Td>Frequency</Td>
                <Td>
                  <FrequencyRule value={frequency} />
                </Td>
              </Tr>
              <Tr>
                <Td>Audience</Td>
                <Td>
                  {sections.audience ? (
                    <ReadableConditions conditions={sections.audience} />
                  ) : (
                    'All users'
                  )}
                </Td>
              </Tr>
              {schedule?.startDate && (
                <Tr>
                  <Td>Publish date</Td>
                  <Td>{formatScheduleDate(schedule?.startDate)}</Td>
                </Tr>
              )}
              {schedule?.endDate && (
                <Tr>
                  <Td>Unpublish date</Td>
                  <Td>{formatScheduleDate(schedule?.endDate)}</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Overflow>
      <ModalFooter>
        <ButtonGroup>
          <Button kind="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onPublish}>Confirm</Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
}

ConfirmPushChangesModal.propTypes = {
  experience: ExperienceShape,
  rule: Shape,
  onPublish: PropTypes.func,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  schedule: ScheduleType,
};

const mapStateToProps = (state, { id }) => ({
  experience: selectExperience(state, id),
  rule: selectRule(state, id),
  schedule: selectSchedule(state),
});

export default connect(mapStateToProps)(ConfirmPushChangesModal);
