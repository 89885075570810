import React from 'react';
import styled from 'styled-components';
import { Card, Paragraph, H3, ExternalLink } from '@studio/legacy-components';
import AppIcon from './AppIcon';
import CreateAppManager from './CreateAppManager';
import { Footer } from './styled';

const ActionColumn = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const IconColumn = styled.div`
  margin-right: 8px;
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 8px;

  ${H3} {
    margin-bottom: 8px;
  }
`;

const AddAppCard = () => {
  return (
    <Card>
      <Content>
        <IconColumn>
          <AppIcon />
        </IconColumn>
        <ActionColumn>
          <H3>Mobile Apps</H3>
          <Paragraph>
            Add your apps to deliver mobile-optimized Appcues Flows to your
            native iOS or Android apps. After you add apps, you can{' '}
            <ExternalLink href="https://docs.appcues.com/mobile-installation-overview/installing-the-mobile-sdk">
              install the mobile SDK
            </ExternalLink>{' '}
            on your app.
          </Paragraph>

          <Footer>
            <CreateAppManager />
          </Footer>
        </ActionColumn>
      </Content>
    </Card>
  );
};

export default AddAppCard;
