import * as actionTypes from 'constants/actionTypes';

export const hitPublishHook = () => {
  return {
    type: actionTypes.HIT_PUBLISH_HOOK,
  };
};

export default {
  hitPublishHook,
};
