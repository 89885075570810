import React, { useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PLAN_TYPES, PLAN_TO_TIER_MAPPINGS } from 'constants/plans';
import Plan from 'components/SubscriptionPicker/Plan';

import { PlansWrapper } from './styled';

const isSelfService = ({ plan, targetPlan }) =>
  !!targetPlan || plan.id === 'essentials';

export default function Plans({ isViewMode = false, planLimit, targetPlan }) {
  const plans = PLAN_TYPES.filter(plan =>
    targetPlan ? plan.id === PLAN_TO_TIER_MAPPINGS[targetPlan.id] : plan.visible
  ).map((plan, index) => {
    return (
      <Plan
        key={plan.id}
        index={index}
        isViewMode={isViewMode}
        plan={plan}
        planLimit={planLimit}
        selfService={isSelfService({ targetPlan, plan })}
      />
    );
  });

  /* global STRIPE_PUBLIC_KEY */
  const stripePromise = useMemo(() => loadStripe(`${STRIPE_PUBLIC_KEY}`));

  return (
    <Elements stripe={stripePromise}>
      <PlansWrapper className="plans-wrapper">{plans}</PlansWrapper>
    </Elements>
  );
}
