import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { CButton, Caption, Text } from '@appcues/component-library';
import { ButtonLink, Icon } from '@studio/legacy-components';
import { asPromised } from 'utils/as-promised';
import ThreeDotsLoader from 'components/Common/ThreeDotsLoader';
import { uploadTranslation } from 'actions/account/translations';
import { selectLocale } from 'reducers/account/locales';
import UploadTranslationModalError from 'components/StepSettings/Localization/UploadTranslationModalError';
import Modal from 'components/Common/UI/Modal/Modal';

const INITIAL = 'initial';
const PENDING = 'pending';
// const COMPLETE = "complete";

export const UploadTranslationModal = ({
  onClose,
  flowId,
  upload,
  localeId,
  name,
}) => {
  const [status, setStatus] = useState(INITIAL);
  const [error, setError] = useState(null);

  const handleDrop = async ([file]) => {
    setStatus(PENDING);
    setError(null);
    try {
      await upload({ flowId, localeId, translation: file });
    } catch (error_) {
      setError(error_);
      setStatus(INITIAL);
    }

    // this is called late due to rerender from the page
    // @todo maybe it shouldn't?
    // setStatus(COMPLETE);
  };

  return (
    <Modal
      title={`Upload translation for ${name}`}
      subtitle="CSV or JSON file types supported."
      onClose={onClose}
      type="medium"
    >
      <Modal.Content>
        {error && (
          <UploadTranslationModalError
            errors={error.response.data?.errors}
            message={error.response.data?.message}
          />
        )}
        {status === PENDING ? (
          <ThreeDotsLoader dotColor="#848484" />
        ) : (
          <StyledDropzone onDrop={handleDrop}>
            <Icon icon="upload" fontSize={24} marginBottom={8} />
            <Text bold marginBottom={4}>
              Drag and drop
            </Text>
            <Caption type="tertiary">
              or <ButtonLink>browse</ButtonLink> files
            </Caption>
          </StyledDropzone>
        )}
      </Modal.Content>
      <StyledFooter>
        <CButton onClick={onClose}>Cancel</CButton>
      </StyledFooter>
    </Modal>
  );
};

const StyledDropzone = styled(Dropzone)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #5c5cff;
  border-radius: 2px;
  width: 80%;
  height: 250px;
  margin: 0 auto;
`;

// We only need to do this because of a Chrome bug, until the component library is patched
const StyledFooter = styled(Modal.Footer)`
  position: absolute;
  bottom: 10px;
`;

UploadTranslationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  flowId: PropTypes.string.isRequired,
  localeId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  upload: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { localeId }) => ({
  name: selectLocale(state, localeId).name,
});

const mapDispatchToProps = dispatch => ({
  upload: file => asPromised(dispatch, uploadTranslation(file)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadTranslationModal);
