import React from 'react';
import PropTypes from 'prop-types';
import {
  MenuSection,
  Checkbox,
  CheckboxGroup,
  MenuItem,
} from '@studio/legacy-components';
import { GroupName, FilterWrapper, ClearButton } from './styled';
import FiltersShape from './FiltersShape';

export const FilterViewDefault = ({
  enabledFiltersCount,
  onClear,
  filters,
  onChange,
}) => {
  return (
    <FilterWrapper>
      <MenuSection>
        {enabledFiltersCount > 0 && (
          <ClearButton aria-label="clear filters" onClick={() => onClear()}>
            Clear
          </ClearButton>
        )}
        {Object.entries(filters).map(
          ([groupKey, { name, options }], groupIndex) => (
            <CheckboxGroup key={groupKey}>
              <GroupName>{name}</GroupName>
              {Object.entries(options).map(
                (
                  [optionKey, { label, enabled, checkboxProps }],
                  optionIndex
                ) => {
                  return (
                    // We need this onSelect to prevent menu to close when clicking on checkbox
                    <MenuItem
                      key={optionKey}
                      onSelect={e => void e.preventDefault()}
                      role="checkbox"
                      asChild
                    >
                      <Checkbox
                        onChange={isChecked =>
                          void onChange(groupKey, optionKey, isChecked)
                        }
                        aria-label={`filter by ${label}`}
                        checked={enabled}
                        autoFocus={groupIndex === 0 && optionIndex === 0}
                        // eslint-disable-next-line @appcues/jsx-props-no-spreading
                        {...checkboxProps}
                      >
                        {label}
                      </Checkbox>
                    </MenuItem>
                  );
                }
              )}
            </CheckboxGroup>
          )
        )}
      </MenuSection>
    </FilterWrapper>
  );
};

FilterViewDefault.propTypes = {
  enabledFiltersCount: PropTypes.number,
  onClear: PropTypes.func,
  filters: FiltersShape,
  onChange: PropTypes.func,
};
