import React from 'react';
import PropTypes from 'prop-types';
import {
  H3,
  HorizontalScroll,
  Panel,
  PanelBody,
  PanelHeader,
  PanelSection,
  PanelSectionTitle,
  PanelTitle,
  RadioGroup,
  RadioControl,
  Radio,
  RadioLabel,
} from '@studio/legacy-components';
import { ConditionsShape } from '@studio/conditions';
import { Conditions } from 'next/components/Conditions';
import { Targeting } from './styled';

export const DEFAULT_CONDITION = { version: { operator: '==', value: '' } };

export const Version = ({ conditions, onChange }) => {
  const selectedMode = conditions ? 'specific' : 'all';

  const handleModeChange = mode => {
    if (mode === 'all') {
      onChange(null);
    } else {
      onChange({
        or: [DEFAULT_CONDITION],
      });
    }
  };

  return (
    <Panel>
      <PanelHeader>
        <PanelTitle>
          <H3>App version</H3>
        </PanelTitle>
      </PanelHeader>
      <PanelBody>
        {' '}
        <PanelSection>
          <PanelSectionTitle>
            What app versions should show this content?
          </PanelSectionTitle>
          <RadioGroup
            name="version"
            value={selectedMode}
            onValueChange={handleModeChange}
          >
            <RadioControl>
              <Radio id="all" value="all" />
              <RadioLabel htmlFor="all">All versions</RadioLabel>
            </RadioControl>

            <RadioControl>
              <Radio id="specific" value="specific" />
              <RadioLabel htmlFor="specific">Specific versions</RadioLabel>
            </RadioControl>

            {selectedMode === 'specific' && (
              <Targeting>
                <HorizontalScroll>
                  <Conditions
                    conditions={conditions}
                    onChange={onChange}
                    validClauseTypes={['version']}
                  />
                </HorizontalScroll>
              </Targeting>
            )}
          </RadioGroup>
        </PanelSection>
      </PanelBody>
    </Panel>
  );
};

Version.propTypes = {
  conditions: ConditionsShape,
  onChange: PropTypes.func,
};
