import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Select } from '@studio/legacy-components';
import { defaultOperators } from 'constants/account/conditions';
import { isValidRegex } from 'utils';
import BaseClause from 'components/Common/Clauses/Base';
import Input from 'components/Common/Forms/Input';

const options = [
  { value: defaultOperators.STARTS_WITH, label: 'starts with' },
  { value: defaultOperators.NOT_STARTS_WITH, label: "doesn't start with" },
  { value: defaultOperators.ENDS_WITH, label: 'ends with' },
  { value: defaultOperators.NOT_ENDS_WITH, label: "doesn't end with" },
  { value: defaultOperators.CONTAINS, label: 'contains' },
  { value: defaultOperators.NOT_CONTAINS, label: "doesn't contain" },
  { value: defaultOperators.EXACT, label: 'equals' },
  { value: defaultOperators.NOT_EXACT, label: "doesn't equal" },
  { value: defaultOperators.REGEX, label: 'matches regex' },
];

const SelectWrapper = styled.div`
  ${Select} {
    margin-right: 10px;
    max-width: 300px;
    min-width: 225px;
  }
`;

const getPrefixedValue = (operator, value) => {
  const prefixedOperators = [
    defaultOperators.STARTS_WITH,
    defaultOperators.NOT_STARTS_WITH,
    defaultOperators.EXACT,
    defaultOperators.NOT_EXACT,
  ];

  if (prefixedOperators.includes(operator) && !value.startsWith('/')) {
    return `/${value}`;
  }
  return value;
};

export default function URLClause(props) {
  const { clause, rule, updateClause, deleteClause } = props;

  const { id, operator: currentOperator, value: currentValue = '' } = clause;
  const $input = useRef();

  useEffect(() => {
    if ($input.current) {
      $input.current.value = currentValue;
    }
  }, [currentValue]);

  const handleChange = ({ value: operator }) => {
    updateClause(id, {
      operator,
      value: getPrefixedValue(operator, currentValue),
    });
  };

  const handleBlur = e => {
    const trimmedValue = (
      e.nativeEvent.target.textContent || e.target.value
    ).trim();
    const prefixedValue = getPrefixedValue(currentOperator, trimmedValue);
    $input.current.value = prefixedValue;
    updateClause(id, {
      value: prefixedValue,
    });
  };

  let placeholder = '';
  try {
    placeholder = `e.g. ${new URL(rule.previewUrl).pathname}`;
  } catch {
    placeholder = 'e.g. /account';
  }

  const isRegex = currentOperator === defaultOperators.REGEX;
  const isRegexValid = isRegex && isValidRegex(currentValue);

  const selected = options.find(({ value }) => value === currentOperator);

  return (
    <BaseClause {...props} deleteClause={deleteClause}>
      <SelectWrapper>
        <Select value={selected} options={options} onChange={handleChange} />
      </SelectWrapper>
      <Input
        ref={$input}
        invalid={isRegex && !isRegexValid}
        onBlur={handleBlur}
        placeholder={placeholder}
        type="text"
        defaultValue={currentValue}
        aria-label="URL targeting input"
      />
    </BaseClause>
  );
}

URLClause.propTypes = {
  clause: PropTypes.shape({
    id: PropTypes.string,
    operator: PropTypes.string,
    value: PropTypes.string,
  }),
  deleteClause: PropTypes.func,
  rule: PropTypes.shape({
    previewUrl: PropTypes.string,
  }),
  updateClause: PropTypes.func,
};
