import {
  call,
  getContext,
  put,
  select,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';
import { reportError } from 'helpers/error-reporting';
import { promisaga } from 'utils/as-promised';

import {
  patterns,
  reject,
  resolve,
  send,
  insert,
  replace,
  remove,
  KEY_ACTIVATED,
  KEY_REVOKED,
  KEY_DELETED,
} from './actions';

import { selectKey } from './reducer';

export function* getAllKeys() {
  try {
    const api = yield getContext('api');
    yield put(send());

    const { data } = yield call(api.getAllKeys);

    const result = data.reduce((acc, key) => {
      acc[key.key] = key;
      return acc;
    }, {});

    yield put(resolve(result));
  } catch (error) {
    yield call(reportError, error);
    yield put(reject(error));
  }
}

export function* createKey({ payload: { name, role_id } }) {
  try {
    const api = yield getContext('api');
    const { data } = yield call(api.createKey, {
      name,
      role_id,
    });

    // The key attribute is the one used to identify an API key
    // instead of the id which is not returned
    const createdKey = {
      ...data,
      id: data.key,
    };

    yield put(insert(createdKey));

    return createdKey.key;
  } catch (error) {
    yield put(reject(error));
    yield call(reportError, error);
    throw error;
  }
}

export function* activateKey({ payload: { key } }) {
  try {
    const api = yield getContext('api');
    yield call(api.activateKey, key);

    // Normally we shouldn't be using a selector inside a saga
    // but since the selected piece lives within the same context
    // we are using this so we can stick to the action patterns
    // and call `replace`.
    // Also the key is the ID in this collection
    const currentKey = yield select(selectKey, key);

    const updatedKey = {
      ...currentKey,
      state: 'active',
      id: key,
    };

    yield put(replace(updatedKey));
  } catch (error) {
    yield put(reject(error));
    yield call(reportError, error);
    throw error;
  }
}

export function* revokeKey({ payload: { key } }) {
  try {
    const api = yield getContext('api');
    yield call(api.revokeKey, key);

    const currentKey = yield select(selectKey, key);

    const updatedKey = {
      ...currentKey,
      state: 'revoked',
      id: key,
    };

    yield put(replace(updatedKey));
  } catch (error) {
    yield put(reject(error));
    yield call(reportError, error);
    throw error;
  }
}

export function* deleteKey({ payload: { id } }) {
  try {
    const api = yield getContext('api');
    yield call(api.deleteKey, id);
    yield put(remove(id));
  } catch (error) {
    yield put(reject(error));
    yield call(reportError, error);
    throw error;
  }
}

export default function* keySaga() {
  yield takeEvery(patterns.callApi, getAllKeys);
  yield takeLeading(patterns.create, promisaga(createKey));
  yield takeLeading(KEY_ACTIVATED, promisaga(activateKey));
  yield takeLeading(KEY_REVOKED, promisaga(revokeKey));
  yield takeLeading(KEY_DELETED, promisaga(deleteKey));
}
