import { selectAccountStepConditions } from 'reducers/account/conditions';
import { getSegmentClause } from 'utils/conditions';

export const selectSatisfactionState = state => state.account.satisfaction;
export const selectAccountSatisfaction = state =>
  state.account.satisfaction.satisfaction;
export const selectAccountSatisfactionSurvey = (state, satisfactionId) =>
  selectAccountSatisfaction(state) &&
  selectAccountSatisfaction(state)[satisfactionId];
export const selectAccountFirstSatisfactionId = state =>
  selectAccountSatisfaction(state)
    ? Object.keys(selectAccountSatisfaction(state))[0]
    : null;
export const selectAccountFirstSatisfactionSurvey = state =>
  selectAccountSatisfaction(state)
    ? Object.values(selectAccountSatisfaction(state))[0]
    : null;

export const selectAccountSatisfactionSegmentId = state => {
  const satisfactionId = selectAccountFirstSatisfactionId(state);

  if (!satisfactionId) {
    return null;
  }

  const conditions = selectAccountStepConditions(state, satisfactionId);
  const { segment = null } = getSegmentClause(conditions) || {};

  return segment;
};
