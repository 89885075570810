import styled from 'styled-components';
import {
  StarCheckbox,
  Table,
  Td,
  Th,
  Tr,
  Icon,
  Thead,
  Tbody,
} from '@studio/legacy-components';

export const PropertiesTable = styled(Table)`
  display: block;
  ${Thead}, ${Tbody} {
    display: block;
  }
  ${Tr} {
    display: block;
    width: 100%;
    ${StarCheckbox} {
      margin-right: auto;
      margin-left: auto;
    }
  }
  ${Td}, ${Th} {
    display: inline-block;
    &:nth-child(1) {
      width: 10%;
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      width: 30%;
    }
  }
`;

export const PropertiesRow = styled(Tr)`
  display: inline-block;
  ${Td} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const StarredIcon = styled(Icon).attrs({
  icon: 'star',
})`
  font-size: 9.33px;
  vertical-align: middle;
  color: var(--checkbox-star-checked-color);
`;

export const NotStarredIcon = styled(Icon).attrs({
  icon: ['far', 'star'],
})`
  font-size: 9.33px;
  color: var(--checkbox-star-color);
`;

export const FilterOption = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TypeCell = styled(Td)`
  text-transform: capitalize;
`;
