import React from 'react';
import { connect } from 'react-redux';
import ColorPicker from 'components/Common/ColorPicker';
import FontPicker from 'components/Common/FontPicker';
import FieldGroup from 'components/Common/Forms/FieldGroup';
import Input from 'components/Common/Forms/Input';
import { selectAccountChecklist } from 'entities/checklists';

const defaultBeaconBackgroundColor = '#0D2C6D';

export function OverallData({
  checklistId,
  updateChecklist,
  beaconBackgroundColor,
  font,
  fontUrl,
  internalName,
  updateChecklistFontDropdown,
  updateChecklistStyles,
  updateChecklistStylesSlider,
}) {
  const updateChecklistProperty =
    property =>
    ({ target: { value } }) => {
      updateChecklist(checklistId, {
        [property]: value,
      });
    };

  const updateChecklistInternalName = updateChecklistProperty('internalName');

  const updateChecklistBeaconBackgroundColor = input => {
    updateChecklistStyles('beaconBackgroundColor', input);
  };

  const updateBeaconBackgroundColor = hex => {
    updateChecklistStylesSlider('beaconBackgroundColor', hex);
  };

  return (
    <div>
      <FieldGroup title="Name" className="configuration-item">
        <Input
          type="text"
          className="checklist-text-short test-internal-name"
          value={internalName}
          placeholder="e.g. Onboarding Checklist"
          onChange={updateChecklistInternalName}
        />
      </FieldGroup>
      <FieldGroup className="configuration-item" title="Primary Color">
        <ColorPicker
          className="test-beacon-color"
          color={beaconBackgroundColor}
          defaultColor={defaultBeaconBackgroundColor}
          onChangeSlider={updateBeaconBackgroundColor}
          onChangeInput={updateChecklistBeaconBackgroundColor}
        />
      </FieldGroup>
      <FontPicker
        className="test-font-picker"
        value={font}
        urlValue={fontUrl}
        onChange={updateChecklistFontDropdown}
      />
    </div>
  );
}

const mapStateToProps = (state, { checklistId }) => {
  const checklist = selectAccountChecklist(state, checklistId);
  const internalName = checklist && checklist.internalName;
  const styles = checklist && checklist.styles;
  const beaconBackgroundColor = styles && styles.beaconBackgroundColor;
  const font = styles && styles.font;
  const fontUrl = styles && styles.fontUrl;

  return {
    beaconBackgroundColor,
    font,
    fontUrl,
    internalName,
  };
};

export default connect(mapStateToProps)(OverallData);
