import { takeEvery, call, put, getContext } from 'redux-saga/effects';
import { replacePagePatternFor } from 'next/entities/page';
import { resolve, reject, patterns } from './actions';

export function* fetchLocales() {
  try {
    const api = yield getContext('api');
    const response = yield call(api.getLocales);

    const result = response.reduce((acc, locale) => {
      acc[locale.id] = locale;
      return acc;
    }, {});

    yield put(resolve(result));
  } catch (error) {
    yield put(reject(error));
  }
}

export default function* saga() {
  // Internal actions
  yield takeEvery(patterns.read, fetchLocales);

  // Page actions
  yield takeEvery(
    replacePagePatternFor('/flows/:flowId/settings'),
    fetchLocales
  );
}
