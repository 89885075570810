/* global FIREBASE_API_KEY */
export default function createFirebaseAuthClient() {
  return {
    resetPassword: email =>
      fetch(
        `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${FIREBASE_API_KEY}`,
        {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            requestType: 'PASSWORD_RESET',
            email,
          }),
        }
      ),
  };
}
