import React, { useState } from 'react';
import { connect } from 'react-redux';
import { InstallChromeExtensionModal } from 'next/components/InstallChromeExtensionModal';
import useVerifyExtension from 'next/hooks/use-verify-extension';
import OpenInBuilderModal from 'components/OpenInBuilder/OpenInBuilderModal';
import { selectFlow, selectFlows } from 'reducers/account/flows';
import { buildDeepLinkToStepChild } from 'utils/steps';
import { openFlowInBuilder } from 'utils/url';
import { canOpenInBuilder } from 'utils/chromeExtension';
import * as persistence from 'helpers/persistence';
import { selectChromeExtensionVersion } from 'reducers/chromeExtension';

const OpenInBuilderButton = ({
  stepGroupId,
  stepChildId,
  render,
  url,
  chromeExtensionVersion,
}) => {
  const { hasCRXInstalled } = useVerifyExtension();
  const [isOpen, setIsOpen] = useState(false);
  const dontShowOpenInBuilderModal =
    persistence.getItem('dontShowOpenInBuilderModal') === 'true';

  const isLatestVersion = canOpenInBuilder(chromeExtensionVersion);
  const shouldShowInstallExtensionModal =
    !hasCRXInstalled || (chromeExtensionVersion && !isLatestVersion);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    if (shouldShowInstallExtensionModal) {
      setIsOpen(true);
      return;
    }

    if (dontShowOpenInBuilderModal) {
      openFlowInBuilder(stepGroupId, stepChildId, url);
      return;
    }

    setIsOpen(true);
  };

  return (
    <>
      {isOpen &&
        (shouldShowInstallExtensionModal ? (
          <InstallChromeExtensionModal onDismiss={handleClose} />
        ) : (
          <OpenInBuilderModal
            stepGroupId={stepGroupId}
            stepChildId={stepChildId}
            url={url}
            onClose={handleClose}
          />
        ))}
      {render(handleOpen)}
    </>
  );
};

function mapStateToProps(state, { stepGroupId, stepChildId }) {
  const stepGroup = selectFlow(state, stepGroupId) || {};
  const allSteps = selectFlows(state);
  const url = buildDeepLinkToStepChild(stepGroup, stepChildId, allSteps);

  return {
    url,
    chromeExtensionVersion: selectChromeExtensionVersion(state),
  };
}

export default connect(mapStateToProps)(OpenInBuilderButton);
