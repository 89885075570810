export const getHubspotFormsUrl = queryParams => {
  /* global HUBSPOT_FORMS_URL, HUBSPOT_ACCOUNT_ID, HUBSPOT_SIGNUP_FORM_ID */
  return `${HUBSPOT_FORMS_URL}/${HUBSPOT_ACCOUNT_ID}/${HUBSPOT_SIGNUP_FORM_ID}${queryParams}`;
};

export const prepareHubspotContextData = () => {
  const hsContext = {
    pageUrl: window.location.href,
    pageName: document.title,
  };

  let hutk =
    document.cookie.match(/hutk=(.*?)(;|$)/gi) ||
    document.cookie.match(/hubspotutk=(.*?)(;|$)/gi);
  if (hutk) {
    hutk = hutk[0].split('=')[1].replace(';', '');
    hsContext.hutk = hutk;
  }
  return hsContext;
};

export const buildHbsptQueryString = data => {
  return `?email=${encodeURIComponent(
    data.email
  )}&hs_context=${encodeURIComponent(data.hsContext)}`;
};
