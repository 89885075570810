import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import InsightsExplorer from 'components/Insights/Explorer';
import {
  ApplicationMetric,
  BuilderMetric,
  ChecklistMetric,
  FlowMetric,
  PinMetric,
  MobileMetric,
  BannerMetric,
  GoalMetric,
} from 'components/Insights/Explorer/SingleMetric';
import ChartView from 'components/Insights/Charts/View';
import ChartIndex from 'components/Insights/Charts';

/**
 * NOTE: Because event names can contain '/', the param tokens below are
 *       appended with '+' to allow `path-to-regexp` - the underlying library
 *       that handles extracting and parsing param tokens - to include parts
 *       after any '/' as part of the same token.
 */

export const Insights = () => (
  <Switch>
    <Route
      path="/insights/explorer/application/:encodedEventName+"
      component={ApplicationMetric}
    />
    <Route
      path="/insights/explorer/builder/:encodedEventName+"
      component={BuilderMetric}
    />
    <Route
      path="/insights/explorer/checklist/:encodedEventName+"
      component={ChecklistMetric}
    />
    <Route
      path="/insights/explorer/flow/:encodedEventName+"
      component={FlowMetric}
    />
    <Route
      path="/insights/explorer/pin/:encodedEventName+"
      component={PinMetric}
    />
    <Route
      path="/insights/explorer/banner/:encodedEventName+"
      component={BannerMetric}
    />
    <Route
      path="/insights/explorer/goals/:encodedEventName+"
      component={GoalMetric}
    />
    <Route
      path="/insights/explorer/mobile/:encodedEventName+"
      component={MobileMetric}
    />
    <Route path="/insights/explorer" component={InsightsExplorer} />
    <Route path="/insights/charts/:chartId" component={ChartView} />
    <Route path="/insights/charts" component={ChartIndex} />

    <Redirect from="/insights/dashboard" to="/insights/charts" />
    <Redirect from="/insights" to="/insights/explorer" />

    <Redirect path="/events/explore" to="/insights/explorer" />
    <Redirect path="/events/charts/dashboard" to="/insights/charts" />
    <Redirect path="/events/charts/:chartId" to="/insights/charts/:chartId" />
    <Redirect from="/events" to="/insights" />
  </Switch>
);

export default Insights;
