export const TYPE = 'spoofing';

export const SPOOF_STARTED = 'SPOOF_STARTED';
export const start = id => ({
  type: SPOOF_STARTED,
  payload: id,
});

export const SPOOF_STOPPED = 'SPOOF_STOPPED';
export const stop = () => ({
  type: SPOOF_STOPPED,
});
