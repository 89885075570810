import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Flex, Column, Caption } from '@appcues/component-library';
import MetricFlowListItem from 'components/Common/MetricFlowListItem';
import AvatarImg from 'components/Common/AvatarImg';
import { getSortedSteps, getFilteredSteps } from 'helpers/view';
import { getLastPublishedTimestamp } from 'helpers/steps';
import { METRIC_TYPE, formatMetricValue } from 'helpers/metrics';
import { formatDate } from 'utils';
import { navigate } from 'actions/routing';

export function FilteredFlowList({ className, sort, steps, limit: initial }) {
  const getFlows = () => {
    const limit = initial || 5;

    const stepsList = Object.values(steps);
    const filteredSteps = getFilteredSteps(stepsList, {}, {});

    if (!sort) {
      return filteredSteps;
    }

    const sortedSteps = getSortedSteps(filteredSteps, sort);
    return sortedSteps.slice(0, limit);
  };

  const flows = getFlows();

  return (
    <Column className={className} gutter={16} alignItems="stretch">
      {flows.map(step => {
        const metricValue = formatDate(
          getLastPublishedTimestamp(step),
          'M/DD/YYYY'
        );

        return (
          <MetricFlowListItem key={step.id} flowId={step.id}>
            <Flex alignItems="center">
              <AvatarImg userId={step.updatedBy} width={16} marginRight={4} />
              <Caption marginLeft={2} type="tertiary">
                Updated {formatMetricValue(metricValue, METRIC_TYPE.DATE)}
              </Caption>
            </Flex>
          </MetricFlowListItem>
        );
      })}
    </Column>
  );
}

FilteredFlowList.propTypes = {
  sort: PropTypes.string,
  steps: PropTypes.object.isRequired,
  limit: PropTypes.number,
};

const mapDispatchToProps = {
  navigate,
};

export default connect(null, mapDispatchToProps)(FilteredFlowList);
