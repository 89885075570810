import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { selectChart } from 'reducers/account/charts';
import ExportManager from './ExportManager';

const mapStateToProps = (state, { chartId }) => ({
  events: selectChart(state, chartId)?.events,
});

const ChartExportManager = connect(mapStateToProps)(ExportManager);

ChartExportManager.propTypes = {
  chartId: PropTypes.string,
  trailingDays: PropTypes.string,
  endDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  segmentId: PropTypes.string,
};

export default ChartExportManager;
