/* global APPCUES_ENV, APPCUES_API_URL */

import AppcuesMetricsClient from '@appcues/metrics-client';
import { takeEvery } from 'redux-saga/effects';

import * as actionTypes from 'constants/actionTypes';
import { supportsUserTimingApi } from 'utils/timing';
import { actionStartMarkName } from 'utils/timingNames';

const metricsClient = new AppcuesMetricsClient(
  APPCUES_ENV,
  // TODO: Rename to `studio` after confirming behavior
  'reactcues',
  'main',
  { appcuesApiUrl: APPCUES_API_URL }
);

// eslint-disable-next-line require-yield
function* trackMetric({ payload: { metricName, duration } }) {
  metricsClient.time(metricName, duration);
}

// eslint-disable-next-line require-yield
function* addPerformanceMark({ payload: { metricName } }) {
  if (supportsUserTimingApi()) {
    window.performance.mark(actionStartMarkName(metricName));
  }
}

// eslint-disable-next-line require-yield
function* addPerformanceMeasure({ payload: { metricName } }) {
  if (supportsUserTimingApi()) {
    const startMarkName = actionStartMarkName(metricName);
    const measureName = metricName;
    try {
      window.performance.measure(measureName, startMarkName);
      window.performance.clearMarks(startMarkName);
    } catch {
      window.performance.measure(measureName);
    }
  }
}

function* metrics() {
  yield takeEvery(actionTypes.ADD_PERFORMANCE_MARK, addPerformanceMark);
  yield takeEvery(actionTypes.ADD_PERFORMANCE_MEASURE, addPerformanceMeasure);
  yield takeEvery(actionTypes.TRACK_METRIC, trackMetric);
}

export default metrics;
