import pick from 'lodash.pick';
import PropTypes from 'prop-types';

import { ROLES } from './constants';

/**
 * Full shape of account-user model
 */
const Shape = {
  accountId: PropTypes.string,
  accountUserId: PropTypes.string,
  enabled: PropTypes.bool,
  enabledAt: PropTypes.string,
  id: PropTypes.string,
  lastSeenAt: PropTypes.string,
  role: PropTypes.oneOf(Object.keys(ROLES)),
  roleId: PropTypes.oneOf(Object.values(ROLES)),
  userIntent: PropTypes.arrayOf(PropTypes.string),
  userUsageIntent: PropTypes.arrayOf(PropTypes.string),
};

/**
 * Account User parsing function
 *
 * @param {Object<keyof AccountUser>} accountUser - Object with accountUser fields
 * @return {Object<keyof AccountUser>} Object with parsed accountUser fields
 */
export const parse = accountUser => pick(accountUser, Object.keys(Shape));

export default PropTypes.shape(parse(Shape));
