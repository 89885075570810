import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { OPM_NEW_NAV, selectFeature } from 'next/entities/features';
import MainNavigation from './MainNavigation';
import SettingsNavigationProvider from './SettingsNavigationProvider';

const NavigationProvider = ({ hasOPMNewNav }) => {
  const { pathname } = useLocation();
  const isSettings = pathname.startsWith('/settings');
  const isIntegrations = pathname.startsWith('/integrations');
  const isThemes = pathname.startsWith('/themes');

  if (isSettings || isIntegrations || (hasOPMNewNav && isThemes)) {
    return <SettingsNavigationProvider />;
  }

  return <MainNavigation />;
};

NavigationProvider.propTypes = {
  hasOPMNewNav: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    hasOPMNewNav: selectFeature(state, OPM_NEW_NAV),
  };
};

export default connect(mapStateToProps)(NavigationProvider);
