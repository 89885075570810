import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BeaconLink } from 'next/components/BeaconLink';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function HistoricalLimitExceeded({ limit = 0 }) {
  // Humanized label for historical limit
  const label =
    limit <= 90 ? `${limit} days` : `${Math.floor(limit / 30)} months`;

  return (
    <Wrapper>
      Your plan is limited to {label} of historical data.
      <BeaconLink
        subject="Upgrading plan limits"
        message="I'd like to upgrade my Appcues plan to have a greater range in my date range reporting."
      >
        Contact sales to upgrade
      </BeaconLink>
    </Wrapper>
  );
}

HistoricalLimitExceeded.propTypes = {
  limit: PropTypes.number,
};
