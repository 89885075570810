import {
  addClause,
  conditionNames,
  deleteMatchingClauses,
  filterAudienceTargetingClauses,
  propertyOperators,
} from '@appcues/libcues';
import { PLAN_TO_TIER_MAPPINGS, TIER_LIMITS } from 'constants/plans';

export function setGoalSegment(clauses, segmentId) {
  return addClause(
    deleteMatchingClauses(clauses, filterAudienceTargetingClauses),
    null,
    {
      conditionName: conditionNames.SEGMENTS,
      segment: segmentId,
    },
    false
  );
}

export function setGoalCustomSegment(clauses, defaultProperty = '') {
  return addClause(
    deleteMatchingClauses(clauses, filterAudienceTargetingClauses),
    null,
    {
      conditionName: conditionNames.PROPERTIES,
      property: defaultProperty,
      operator: propertyOperators.CONTAINS,
      value: '',
    },
    false
  );
}

export function computeGoalsLimit(accountMetadata) {
  const planTier = PLAN_TO_TIER_MAPPINGS[accountMetadata.stripePlanId];
  if (
    accountMetadata.planLimitOverrides &&
    accountMetadata.planLimitOverrides.maxGoals
  ) {
    return accountMetadata.planLimitOverrides.maxGoals;
  }
  if (accountMetadata.isTrial) {
    return TIER_LIMITS.trial.maxGoals;
  }
  if (TIER_LIMITS[planTier]) {
    return TIER_LIMITS[planTier].maxGoals;
  }
  return TIER_LIMITS.enterprise.maxGoals;
}
