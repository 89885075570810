import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Loader from 'components/Common/Loader';
import NoResults from './NoResults';
import EmptyEventSource from './EmptyEventSource';
import EmptyEventsByUser from './EmptyEventsByUser';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableOverlay = ({ isEmpty = false, results, type }) => {
  if (!results) {
    return (
      <Wrapper>
        <Loader margin="1em" />
      </Wrapper>
    );
  }

  if (isEmpty) {
    if (type === 'events-by-user') {
      return (
        <Wrapper>
          <EmptyEventsByUser />
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <EmptyEventSource type={type} />
      </Wrapper>
    );
  }

  if (results.length === 0) {
    return (
      <Wrapper>
        <NoResults type={type} />
      </Wrapper>
    );
  }

  return null;
};

TableOverlay.propTypes = {
  isEmpty: PropTypes.bool,
  results: PropTypes.array,
  type: PropTypes.string,
};

export default TableOverlay;
