import {
  SET_USER_PROFILE_FETCHING,
  SET_USER_PROFILE_SUCCESS,
  SET_USER_PROFILE_ERRORED,
} from 'constants/actionTypes';

const defaultMeta = {
  fetching: false,
  errored: false,
  synced: false,
};

const initialState = {};

export default function userProfiles(state = initialState, action) {
  switch (action.type) {
    case SET_USER_PROFILE_FETCHING:
      return {
        ...state,
        [action.payload.userId]: {
          meta: {
            fetching: true,
            errored: false,
            synced: false,
          },
        },
      };
    case SET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        [action.payload.userId]: {
          meta: {
            fetching: false,
            errored: false,
            synced: true,
          },
          userProfile: action.payload.result,
        },
      };
    case SET_USER_PROFILE_ERRORED:
      return {
        ...state,
        [action.payload.userId]: {
          meta: {
            fetching: false,
            errored: true,
            synced: true,
          },
        },
      };
    default:
      return state;
  }
}

export const selectAccountUserProfileMeta = (state, userId) => {
  const userProfile = state.account.userProfiles[userId];
  return userProfile ? userProfile.meta : defaultMeta;
};

export const selectAccountUserProfile = (state, userId) => {
  const userProfile = state.account.userProfiles[userId];
  return userProfile ? userProfile.userProfile : {};
};
