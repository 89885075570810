import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Text } from '@appcues/component-library';

import { useBoundingBox } from 'hooks/useBoundingBox';

const HiddenWrapper = styled.span`
  display: inline-flex;
  width: 100%;
  position: relative;
  overflow: visible;
  &:hover {
    cursor: pointer;
  }
`;

const Flyout = styled.div`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  position: absolute;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme['$box-shadow-3']};
  margin-left: auto;
  margin-right: auto;
  background: white;
  border-radius: 6px;
  width: ${props => `${props.width}px`};
  height: auto;
  overflow: hidden;
`;

const FlyoutHeader = styled.header`
  padding: 10px;
  background-color: ${({ theme }) => theme['$gray-1']};
`;

const FlyoutContent = styled.main`
  padding: 15px;
`;

const HoverBox = ({
  width = 256,
  children,
  content,
  space = 8,
  contentTitle,
}) => {
  const [isVisible, setVisibility] = useState(false);
  const [wrapperBox, wrapperRef] = useBoundingBox();
  const [flyOutBox, flyOutRef] = useBoundingBox();
  const [style, setStyle] = useState({});

  const showTooltip = () => {
    const left = wrapperBox.width / 2 - width / 2;
    const top = -Math.abs(flyOutBox.height + space);

    setStyle({ left, top });
    setVisibility(true);
  };

  const hideTooltip = () => {
    setVisibility(false);
  };

  return (
    <HiddenWrapper
      ref={wrapperRef}
      onMouseOver={showTooltip}
      onFocus={showTooltip}
      onMouseOut={hideTooltip}
      onBlur={hideTooltip}
    >
      <Flyout
        tabindex={isVisible ? '0' : '-1'}
        role="tooltip"
        ref={flyOutRef}
        width={width}
        style={style}
        isVisible={isVisible}
      >
        {contentTitle && (
          <FlyoutHeader>
            <Text bold truncate type="secondary">
              {contentTitle}
            </Text>
          </FlyoutHeader>
        )}
        <FlyoutContent>{content}</FlyoutContent>
      </Flyout>
      {children}
    </HiddenWrapper>
  );
};

HoverBox.propTypes = {
  width: PropTypes.number,
  children: PropTypes.any.isRequired,
  content: PropTypes.any.isRequired,
  contentTitle: PropTypes.string,
  space: PropTypes.number,
};

export default HoverBox;
