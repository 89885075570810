import { delay, put } from 'redux-saga/effects';
import { trackMetric } from 'actions/metrics';

export default function* periodicallySendTimingEvents() {
  while (true) {
    // Periodically tell the client to send performance metrics to DataDog
    yield delay(10000);
    const measures = window.performance.getEntriesByType('measure');
    if (measures.length > 0) {
      window.performance.clearMeasures();
      yield measures.map(measure =>
        put(trackMetric(measure.name, measure.duration))
      );
    }
  }
}
